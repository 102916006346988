import React from "react";
import { APILogsForm, FormReducerAction } from "../../AdminLogs.data";
import { ACTION_TYPES } from "./actions/actions";

export const INITIAL_STATE: APILogsForm = {
    DateFrom: undefined,
    DateTo: undefined,
    TargetUserName: "",
    ApiEvent: ""
};

export const formReducer = (state: APILogsForm, action: FormReducerAction) => {
    switch (action.type) {
        case ACTION_TYPES.CHANGE_INPUT:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case ACTION_TYPES.CHANGE_DATE:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        default:
            return state;
    }
};