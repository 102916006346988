import { DefaultButton, IconButton, IIconProps, IMessageBarStyles, Link, mergeStyleSets, MessageBar, PrimaryButton, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ProfileType } from '../../../context/AppContext';
import { isEmail } from '../../../Helpers/Utils';
import { useDocument } from '../../../hooks/useDocument';
import AuthService, { CaptchaRequest } from '../../../services/auth';
import UserService from '../../../services/User';

const refreshIcon: IIconProps = { iconName: 'Refresh' };

type Props = {
    targetUser: string
    setAccessValid: (val: boolean) => void,
    onClickCancel: () => void
    setAuthCodeLoading: (val: boolean) => void,
    messageHandler: {
        setShowErrorMessage: (val: boolean) => void,
        setShowSuccessMessage: (val: boolean) => void,
        setMessage: (val: string) => void
    }
    captchaRequest: CaptchaRequest
}

export type CaptchaEnabledOptions = {
    enabled: true
    captchaImage: string
    captchaId: string
}
type CaptchaOptions = CaptchaEnabledOptions | {
    enabled: false
}
export default function Captcha({ targetUser, setAccessValid, messageHandler, onClickCancel, captchaRequest, setAuthCodeLoading }: Props) {

    const [captchaValue, setCaptchaValue] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [reloadCaptcha, setReloadCaptcha] = useState(true)
    const { setProfile } = useDocument()
    const [captcha, setCaptcha] = useState<CaptchaOptions>({
        enabled: false
    })
    const [captchaIsValid, setCaptchaIsValid] = useState({
        isValid: false,
        message: ''
    })

    useEffect(() => {
        if (!reloadCaptcha) return

        setIsLoading(true)
        AuthService.getCaptcha(targetUser).then(({ data }: any) => {
            if (data.Success) {
                setCaptchaValue('')
                return setCaptcha({
                    enabled: true,
                    captchaId: data.Id,
                    captchaImage: data.Result,
                })
            }
            setCaptcha({ enabled: false })
        }).finally(() => {
            setIsLoading(false)
            setReloadCaptcha(false)
        })

    }, [reloadCaptcha, targetUser])

    const validateCaptcha = () => {
        if (!captcha.enabled) return
        const { OnBehalfUser } = captchaRequest
        if (!isEmail(OnBehalfUser)) {
            messageHandler.setShowErrorMessage(true)
            messageHandler.setMessage("Email must be valid.")
            return;
        }
        messageHandler.setShowErrorMessage(false)
        setIsLoading(true)
        AuthService.validateCaptcha({
            ...captchaRequest,
            CaptchaGuid: captcha.captchaId,
            CaptchaValue: captchaValue
        }).then(({ data }: any) => {
            if (data.Success) {
                setCaptchaIsValid({
                    isValid: true,
                    message: data.Result
                })
                return
            }
            setIsLoading(false)
            messageHandler.setShowErrorMessage(true)
            messageHandler.setMessage(data.ExceptionMessage)
            setReloadCaptcha(true)
            setCaptchaValue('')
        })
    }

    useEffect(() => {
        if (!captchaIsValid.isValid) return
        messageHandler.setShowErrorMessage(false)
        messageHandler.setShowSuccessMessage(true)
        messageHandler.setMessage(captchaIsValid.message)

        const timeout = setTimeout(() => {
            setProfile({
                Email: captchaRequest.OnBehalfUser
            } as ProfileType)
            setAccessValid(true)
        }, 500)

        return () => {
            clearTimeout(timeout)
        }
    }, [captchaIsValid.isValid])

    if (isLoading) {
        return <div className={contentStyles.content}>
            <Spinner size={SpinnerSize.large} ariaLive="assertive" />
        </div>
    }
    if (captcha.enabled) {
        return <div className={contentStyles.content}>
            <div className={contentStyles.captchaContainer}>
                <div>
                    <img src={`data:image/png;base64, ${captcha.captchaImage}`} alt="Captcha validation" style={{ maxWidth: '100%' }} />
                </div>
                <div className={contentStyles.link}>
                    <Link onClick={() => setReloadCaptcha(true)} underline>
                        Refresh image
                    </Link>
                </div>
            </div>
            <TextField autoComplete={'off'} value={captchaValue} placeholder="Type captcha value" onChange={(e) => setCaptchaValue((e.target as HTMLInputElement).value)} />
            <div className={contentStyles.actions}>
                <DefaultButton text={"Cancel"} title={"Cancel."} onClick={onClickCancel} />
                <PrimaryButton onClick={validateCaptcha} text={'Validate'} disabled={captchaValue.length === 0} />
            </div>
        </div>
    } else {
        return <MessageBar styles={messageBarFailedStyles}>An error has occured</MessageBar>
    }
}
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5",
    },
}
const contentStyles = mergeStyleSets({
    content: {
        padding: '10px',
        display: 'grid',
        gap: '0.5rem',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: isMobile ? 'center' : 'normal',
        minHeight: '200px'
    },
    captchaContainer: {
        textAlign: 'center'
    },
    actions: {
        display: 'flex',
        justifyContent: isMobile ? 'normal' : 'end',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        gap: isMobile ? '0.7rem' : '0.5rem'
    },
    link: {
        display: 'flex',
        justifyContent: 'right'
    }

})