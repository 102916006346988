import React, { useEffect, useState } from "react";
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IconButton, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDragDropEvents, IDropdownOption, IRenderFunction, ScrollablePane, ScrollbarVisibility, SelectionMode, Sticky, StickyPositionType, TooltipHost } from "@fluentui/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RHFTextBoxControl } from "../../../RHFControls/RHFTextBoxControl";
import { Const_SurrogateFilter } from "../../Models/Consts";
import { RHFDropDownListSingleSelectControl } from "../../../RHFControls/RHFDropDownListSingleSelectControl";
import Helper from "../../../../Helpers/Helper";
import OutboundServices from "../../Services/OutboundServices";
import _ from "lodash";
import { RHFCheckBoxControl } from "../../../RHFControls/RHFChekBoxControl";
import { useBoolean } from "@fluentui/react-hooks";

export interface SurrogateFilters {
    templateId: number;
    outboundIntegrationId: number;
    isConfigurationTemplateReadMode?: boolean;
}

export const SurrogateFilters = (props: SurrogateFilters) => {
    const { control, setValue, getValues, trigger, register, resetField, unregister } = useFormContext();
    const [outboundIntegrations, setOutboundIntegrations] = useState<IDropdownOption[]>([])
    const [isMapInputKeyNameHidden, { setTrue: hideMapInputKeyName, setFalse: showMapInputKeyName }] = useBoolean(true);
    const surrogateFiltersList = useFieldArray({ control, name: "surrogateFiltersList" });

    useEffect(() => {
        loadIntegrations()
    }, [])

    useEffect(() => {
        if (getValues("MapInputUseParentSurrogateSubrecord") === undefined) {
            hideMapInputKeyName()
        } else {
            getValues("MapInputUseParentSurrogateSubrecord") ? showMapInputKeyName() : hideMapInputKeyName()
        }
    }, [isMapInputKeyNameHidden])

    useEffect(() => {

    }, [surrogateFiltersList.fields])

    const loadIntegrations = async () => {
        let path = await Helper.getSite()
        OutboundServices.GetEvents({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: path,
            SK: Helper.getSessionKey(),
            TemplateId: props.templateId,
            UserName: Helper.getUsername(),
        }).then(res => {
            if (res.length > 0) {
                let tempOutboundIntegrations: IDropdownOption[] = [];
                res.forEach(event => {
                    event.Integrations.forEach(integration => {
                        tempOutboundIntegrations.push({
                            key: integration.Id!.toString(),
                            text: `(${event.Name}) ${integration.Name!} (${integration.Description})`
                        })
                    })
                })
                setOutboundIntegrations(tempOutboundIntegrations)
            } else {
                setOutboundIntegrations([])
            }
        })
    }

    const getDragDropEvents = () => {
        return {
            canDrop: (dropContext: any, dragContext?: any) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                if (draggedItem) {

                    insertBeforeItem(item);
                }
            },
            onDragStart: (
                item?: any,
                itemIndex?: number,
                selectedItems?: any[],
                event?: MouseEvent
            ) => {
                if (typeof item.startIndex === "undefined") {
                    // skip group item
                    draggedItem = item;
                    draggedIndex = itemIndex!;
                }
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                draggedItem = undefined;
                draggedIndex = -1;
            }
        };
    };

    const dragDropEvents: IDragDropEvents = getDragDropEvents();
    let draggedItem: any | undefined;
    let draggedIndex: number = -1;

    const insertBeforeItem = (item: any): void => {
        surrogateFiltersList.move(draggedIndex, surrogateFiltersList.fields.indexOf(item))
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
            <TooltipHost {...tooltipHostProps} />
        );
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    const onMapInputUseParentSurrogateSubrecordChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined): void => {
        if (checked) {
            resetField("MapInputFilterKeyName")
            register("MapInputFilterKeyName", { required: "Key Name is required." })
            showMapInputKeyName()
        } else {
            resetField("MapInputFilterKeyName")
            unregister("MapInputFilterKeyName")
            hideMapInputKeyName()
        }
    };

    return (
        <>
            <RHFCheckBoxControl
                id={"MapInputUseParentSurrogateSubrecord"}
                label={"Use parent identifier for subrecords"}
                control={control}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
                onCallback={onMapInputUseParentSurrogateSubrecordChange}
                disabled={props.isConfigurationTemplateReadMode}
            />
            {isMapInputKeyNameHidden ? null :
                <RHFTextBoxControl
                    id={`MapInputFilterKeyName`}
                    disabled={props.isConfigurationTemplateReadMode ? true : false}
                    label={"Key Name:"}
                    title={"Enter key name."}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    defaultValue={""}
                />}
            <div style={{
                height: '300px',
                position: 'relative',
                backgroundColor: 'white',
            }} >
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsList
                        onRenderDetailsHeader={onRenderDetailsHeader}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        constrainMode={ConstrainMode.unconstrained}
                        compact={true}
                        items={surrogateFiltersList.fields.map(item => item)}
                        columns={[
                            {
                                key: 'FilterName',
                                name: 'Name',
                                fieldName: 'FilterName',
                                minWidth: 250,
                                maxWidth: 250,
                                isResizable: false,
                                onRender: (item, index, column) => {
                                    return <RHFTextBoxControl
                                        key={item.id}
                                        id={`surrogateFiltersList.${index!}.FilterName`}
                                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                                        title="Enter variable name."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        defaultValue={""}
                                        onCallback={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                            if (index === surrogateFiltersList.fields.length - 1 && newValue.length > 0 && getValues("surrogateFiltersList")[index].IntegrationId.key !== undefined) {
                                                surrogateFiltersList.append(Const_SurrogateFilter)
                                            }
                                        }}
                                    />
                                }
                            },
                            {
                                key: 'IntegrationId',
                                name: 'Integration',
                                fieldName: 'IntegrationId',
                                minWidth: 400,
                                maxWidth: 400,
                                isResizable: false,
                                onRender: (item, index, column) => {
                                    return <RHFDropDownListSingleSelectControl
                                        id={`surrogateFiltersList.${index}.IntegrationId`}
                                        options={outboundIntegrations}
                                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                                        title="Select param type."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        onCallback={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, dropdownIndex?: number | undefined) => {
                                            if (index === surrogateFiltersList.fields.length - 1 && getValues("surrogateFiltersList")[index].FilterName.length > 0 && option !== undefined) {
                                                surrogateFiltersList.append(Const_SurrogateFilter)
                                            }
                                        }}
                                    />
                                }
                            },
                            /*{
                                key: 'SortOrder',
                                name: 'SortOrder',
                                fieldName: 'SortOrder',
                                minWidth: 100, maxWidth: 200,
                                isResizable: false,
                                onRender: (item, index, column) => {
                                    return <RHFTextBoxControl
                                        key={item.id}
                                        onCallback={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                            if (index === surrogateFiltersList.fields.length - 1 && getValues("surrogateFiltersList")[index].Key.length > 0 && newValue.length >= 0) {
                                                surrogateFiltersList.append(Const_SurrogateFilter)
                                            }
                                        }}
                                        id={`mapInputVariableList.${index}.SortOrder`}
                                        disabled={false}
                                        title="Enter variable value."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        defaultValue={""}
                                    />
                                }
                            },
                            {
                                key: 'RecordFilter',
                                name: 'RecordFilter',
                                fieldName: 'RecordFilter',
                                minWidth: 100, maxWidth: 200,
                                isResizable: false,
                                onRender: (item, index, column) => {
                                    return <RHFTextBoxControl
                                        key={item.id}
                                        onCallback={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                            if (index === surrogateFiltersList.fields.length - 1 && getValues("surrogateFiltersList")[index].Key.length > 0 && newValue.length >= 0) {
                                                surrogateFiltersList.append(Const_SurrogateFilter)
                                            }
                                        }}
                                        id={`mapInputVariableList.${index}.RecordFilter`}
                                        disabled={false}
                                        title="Enter variable value."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        defaultValue={""}
                                    />
                                }
                            },
                            {
                                key: 'SourceRecordId',
                                name: 'SourceRecordId',
                                fieldName: 'SourceRecordId',
                                minWidth: 100, maxWidth: 200,
                                isResizable: false,
                                onRender: (item, index, column) => {
                                    return <RHFTextBoxControl
                                        key={item.id}
                                        onCallback={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                            if (index === surrogateFiltersList.fields.length - 1 && getValues("surrogateFiltersList")[index].Key.length > 0 && newValue.length >= 0) {
                                                surrogateFiltersList.append(Const_SurrogateFilter)
                                            }
                                        }}
                                        id={`mapInputVariableList.${index}.SourceRecordId`}
                                        disabled={false}
                                        title="Enter variable value."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        defaultValue={""}
                                    />
                                }
                            },*/
                            {
                                key: 'actions',
                                name: 'Actions',
                                onRender: (item?: any, index?: number) => {
                                    return <IconButton disabled={props.isConfigurationTemplateReadMode} onClick={() => {
                                        surrogateFiltersList.remove(index!)
                                        if ((index === 0 && surrogateFiltersList.fields.length - 1 === 0) || index === surrogateFiltersList.fields.length - 1) {
                                            surrogateFiltersList.append(Const_SurrogateFilter)
                                        }
                                    }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" />
                                },
                                minWidth: 100,
                                maxWidth: 100,
                                isResizable: false
                            }
                        ]}
                        selectionMode={SelectionMode.none}
                        dragDropEvents={dragDropEvents}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                    />
                </ScrollablePane>
            </div>
        </>
    );
};