import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { Separator } from '@fluentui/react/lib/Separator';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { IIconProps } from '@fluentui/react/lib/Icon';
import '../../Custom.css'
import Helper, { IDeleteDocumentOnlySignature, IDocumentOnlyAudienceDetail } from '../../Helpers/Helper';
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { DetailsListLayoutMode, IContextualMenuItem, IContextualMenuProps, IMessageBarStyles, Label, MessageBar, SelectionMode } from '@fluentui/react';
import { MarqueeSelection, Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import { Modal } from "@fluentui/react/lib/Modal"
import { decode } from 'base64-arraybuffer';
import PreviewScreen from '../Preview/PreviewScreen';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { isMobile } from 'react-device-detect';
import { getRecordDescription } from '../../Helpers/functions';
import { formatFromUTC } from '../../Helpers/DateUtils';
import {useTranslation} from "react-i18next";
import {useBranding} from "../../hooks/useBranding";


const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
            class: 'ms-Icon ms-Icon--Mail'
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackTokens = { childrenGap: 50 };

function Executed(props: any) {
    const { branding } = useBranding()
    const {t} = useTranslation(['common', 'recordsign']);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [message, setMessage] = useState("");
    const [showPreview, setShowPreview] = useState(false)
    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const [executed, setExecuted] = useState([]);
    const [selectedExecuted, setSelectedExecuted] = useState<any>();
    const [previewURL, setPreviewURL] = useState("")
    const [selectionExecuted] = useState(new Selection({
        onSelectionChanged: () => {
            const selectionCount = selectionExecuted.getSelectedCount();
            switch (selectionCount) {
                case 1: {
                    setSelectedExecuted(selectionExecuted.getSelection()[0])
                }
            }
        }
    }))
    const [isDeleteExecutedModalVisible, setIsDeleteExecutedModalVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [spinnerTitle, setSpinnerTitle] = useState("");

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '60vw',
            height: '60vh',
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },

        User: {
            width: '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 500,

        },
        containerMail: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '50vw'
        },
    });

    const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: t('label.subject', { ns: 'recordsign'}),
            fieldName: 'Description',
            minWidth: 100,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordsign'}),
            sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordsign'}),
            data: 'string',
            isPadded: true
        },
        {
            key: 'column6',
            name: t('label.version', { ns: 'recordsign'}),
            fieldName: 'Version',
            minWidth: 50,
            maxWidth: 50,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordsign'}),
            sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordsign'}),
            data: 'string',
            isPadded: true
        },
        {
            key: 'column2',
            name: t('label.creator', { ns: 'recordsign'}),
            fieldName: 'CreatedUser',
            minWidth: 100,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordsign'}),
            sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordsign'}),
            data: 'string',
            isPadded: true
        },
        {
            key: 'column3',
            name: t('label.signedDate', { ns: 'recordsign'}),
            fieldName: 'SignedDate',
            minWidth: 75,
            maxWidth: 145,
            isResizable: true,
            isSorted: true,
            isSortedDescending: true,
            sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordsign'}),
            sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordsign'}),
            data: 'datetime',
            onRender: (item: any) => {

                return <span title={item.SignedDate !== null ? Helper.getLocateDate(item.SignedDate, 2)   : ""}>{item.SignedDate !== null ? Helper.getLocateDate(item.SignedDate, 2) : ""}</span>;
            },
            isPadded: true,
        }, {
            key: 'column4',
            name: t('label.eSignProvider', { ns: 'recordsign'}),
            fieldName: 'ESignProvier',
            minWidth: 100,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordsign'}),
            sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordsign'}),
            data: 'string',
            isPadded: true
        }, {
            key: 'column5',
            name: t('label.action', { ns: 'recordsign'}),
            fieldName: 'Actions',
            minWidth: 20,
            maxWidth: 20,
            isResizable: false,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return <div style={{ width: "25px" }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    aria-haspopup
                    aria-label={t('ariaLabel.action', { ns: 'recordsign'})}
                    styles={iconListButtonStyles}
                    menuProps={menuProps}
                /></div>
            },
            isPadded: true
        }
    ]

    const menuItems: IContextualMenuItem[] = [
        /*{
            key: 'delete',
            text: 'Delete',
            onClick: () => {
                setIsDeleteExecutedModalVisible(true);
            },
        },*/
        {
            key: 'download',
            text: t('label.download', { ns: 'recordsign'}),
            onClick: () => {
                const download = async () => {
                    var data = await Helper.GetDocumentOnlySignatureWithContentByDocAndSignature(props.documentId.toString(), selectedExecuted.Id)
                    var res = JSON.parse(data.response)
                    try {
                        const decoded = decode(res.Content);
                        const file = new Blob(
                            [decoded], { type: "application/pdf" });
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(file);
                        console.log(res)
                        a.download = res.DocumentName.trim() + ".pdf";// res.FIleExtn.trim();
                        a.click();
                        setShowSuccessMessage(true)
                        setMessage(t('messages.downloadSuccess', { ns: 'recordsign'}))
                        setTimeout(function () {
                            setShowSuccessMessage(false)
                        }, 5000);
                        //showNotification("Attach document successfully downloaded. Please check Downloads folder!", "success")
                    } catch (error) {
                        setShowErrorMessage(true)
                        setMessage(t('message.error', { ns: 'common', Error: error }))
                        setTimeout(function () {
                            setShowErrorMessage(false)
                        }, 5000);
                        //showNotification("An error occurred: " + error, "error")
                    }
                }
                download()
            },
        },
        {
            key: 'preview',
            text: t('label.preview', { ns: 'recordsign'}),
            onClick: () => {
                const download = async () => {
                    setShowPreview(true);
                    var res = await Helper.GetDocumentOnlySignatureWithContentByDocAndSignature(props.documentId.toString(), selectedExecuted.Id)
                    try {
                        setPreviewURL(res.UrlPath)
                        setLayoutLoaded(true);
                    } catch (error) {
                    }
                }
                download()
            },
        }
    ];
    const menuItemsMobile: IContextualMenuItem[] = [
        {
            key: 'preview',
            text: t('label.preview', { ns: 'recordsign'}),
            onClick: () => {
                const download = async () => {
                    setShowPreview(true);
                    var res = await Helper.GetDocumentOnlySignatureWithContentByDocAndSignature(props.documentId.toString(), selectedExecuted.Id)
                    try {
                        setPreviewURL(res.UrlPath)
                        setLayoutLoaded(true);
                    } catch (error) {
                    }
                }
                download()
            },
        }
    ];

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: isMobile && sessionStorage.getItem("entityId") !== "Not In Teams" ? menuItemsMobile : menuItems,
    };

    const loadExecute = async () => {
        setIsLoading(true)
        await Helper.GetDocumentOnlySignatureWithOutContentByDoc(props.documentId).then(data => {
            setExecuted(data)
            setIsLoading(false)
        })
    }

    const getKey = (item: IDocumentOnlyAudienceDetail, index?: number): string => {
        if (item !== undefined)
            return item.Id.toString()
        return ""
    }

    const hideExecutedModal = () => {
        setExecuted([])
        setIsDeleteExecutedModalVisible(false)
        props.hideExecuted()
    }


    const hideDeleteExecutedModal = () => {
        setIsDeleteExecutedModalVisible(false)
    }

    const deleteExecuted = async () => {
        const req: IDeleteDocumentOnlySignature = {
            AccountId: Helper.getDefaultAccount(),
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            DocumentId: props.documentId.toString(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            Id: selectedExecuted.Id
        }

        Helper.DeteleDocumentOnlySignatureById(req).then(data => {
            loadExecute();
            hideDeleteExecutedModal()
        })
    }


    useEffect(() => {
        if (props.showExecuted) {
            setSpinnerTitle(t('text.spinner', { ns: 'common'}))
            
            if (!executed.length) {
                loadExecute();
            }
        }
    }, [props.showExecuted])

    const hidePreview = () => {
        setShowPreview(false)
        setLayoutLoaded(false)
    }

    return (
        <>
            <PreviewScreen
                showModal={showPreview}
                hideModal={hidePreview}
                modalTitle={t('label.preview', { ns: 'recordsign'})}
                spinnerText={t('text.spinner', { ns: 'common'})}
                layoutLoaded={layoutLoaded}
                URLPath={previewURL}
                mimeType={"application/pdf"}
                documentId={props.documentId.toString()}
                Id={selectedExecuted?.Id}
                signatures={true}
            />
            <Modal allowTouchBodyScroll={false}
                isOpen={props.showExecuted}
                containerClassName={contentStyles.container}
                isBlocking={true}>
                <Stack tokens={stackTokens}>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.header}>
                                    <span> {getRecordDescription(props.modalTitle, props.documentDescription)}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                        onClick={hideExecutedModal}
                                        title={t('Common.Button.Close', { ns: 'common'})}
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                {showSuccessMessage ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.body}>
                                    {!isLoading ?
                                        <ScrollablePane>
                                            {
                                                <Stack tokens={stackTokens}>
                                                    <MarqueeSelection selection={selectionExecuted}>
                                                        <DetailsList
                                                            items={executed}
                                                            compact={false}
                                                            columns={columns}
                                                            getKey={getKey}
                                                            setKey="set"
                                                            layoutMode={DetailsListLayoutMode.justified}
                                                            isHeaderVisible={true}
                                                            selection={selectionExecuted}
                                                            selectionMode={SelectionMode.single}
                                                            selectionPreservedOnEmptyClick={false}
                                                            enterModalSelectionOnTouch={true}
                                                            checkboxVisibility={CheckboxVisibility.hidden}
                                                        />
                                                    </MarqueeSelection>
                                                </Stack>
                                            }
                                        </ScrollablePane>
                                        : <Stack tokens={stackTokens}>
                                            <label />
                                            <Spinner size={SpinnerSize.large} label={spinnerTitle} />
                                        </Stack>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <DefaultButton onClick={hideExecutedModal} text={t('Common.Button.Close', { ns: 'common'})} title={t('Common.Button.Close', { ns: 'common'})} />
                                </DialogFooter>
                            </div>
                        </div>
                    </div>
                </Stack>
            </Modal >
            <Modal
                isOpen={isDeleteExecutedModalVisible}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id="deleteModal">{t('text.deleteUser', { ns: 'recordsign'})}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                        onClick={hideDeleteExecutedModal}
                                        title={t('Common.Button.Close', { ns: 'common'})}
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.bodyDelete}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className="ms-Grid-row">
                                                    <Label>{t('label.modal', { ns: 'recordsign', User: selectedExecuted?.UserName })}</Label>
                                                </div>
                                                <DialogFooter>
                                                    <PrimaryButton onClick={deleteExecuted} text={t('Common.Button.Delete', { ns: 'common'})} title={t('Common.Button.Delete', { ns: 'common'})} />
                                                    <DefaultButton onClick={hideDeleteExecutedModal} text={t('Common.Button.Cancel', { ns: 'common'})} title={t('Common.Button.Cancel', { ns: 'common'})} />
                                                </DialogFooter>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Executed