import { KeepAliveRequest } from '../interfaces/IKeepAlive'
import HttpRequest from "./HttpRequest";


const KeepAliveService = {
    ping: async (request: KeepAliveRequest) => {

        let { result }: any = await HttpRequest.postWithSession('DocumentOnlyExtendSession', { ...request })

        //let { data }: any = await HttpRequest.postWithSession('DocumentOnlyLogging/KeepAlive', { ...request })
        //console.log(`Keep Alive Response: ${JSON.stringify(data)}`)
    }
}

export default KeepAliveService