import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { Text } from '@fluentui/react/lib/Text';
import { IconButton, DefaultButton, ActionButton, IButtonStyles } from '@fluentui/react/lib/Button';
import {
	ComboBox,
	FontIcon,
	IComboBox,
	IComboBoxOption,
	IContextualMenuItem,
	IContextualMenuProps,
	Link,
	MessageBar, MessageBarType,
	ProgressIndicator,
	StackItem,
	Toggle
} from '@fluentui/react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import Helper, { IAudienceWithOwnerResponse, ICreateApplicationServiceEmail, IDocumentOnlyAudienceDetail, IDocumentOnlyAudienceRequest, IRequest, IRequestGeneral, IServiceEmailRequest, ITemplateGeneral } from '../../Helpers/Helper';
import { useConstCallback } from '@uifabric/react-hooks';
import { IParam } from '../../Helpers/Helper';
import { Modal } from '@fluentui/react/lib/Modal';
import Audiences from '../Settings/Audiences';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { isMobile } from 'react-device-detect';
import { TagPicker, ITag, IInputProps, IBasePickerSuggestionsProps } from '@fluentui/react/lib/Pickers';
import { IIconProps, Icon } from '@fluentui/react/lib/Icon';
import { useLocation } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import { formatString } from '../../Helpers/functions';
import { useBranding } from '../../hooks/useBranding';
import AudienceService from "../../services/audience";
import GraphApiService, {IResendRequest, ITestRequest} from "../../services/GraphApi";
import AppService from "../../services/app";
import HttpRequest from "../../services/HttpRequest";

const stackTokens = { childrenGap: 10 };

const theme = getTheme();
const iconButtonStyles = {
	root: {
		marginLeft: 'auto',
		marginTop: '4px',
		marginRight: '2px',

	},

};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const UniqueReferenceIcon: IIconProps = { iconName: 'Copy' };


const tempTemplate: ITemplateGeneral = {
	templateName: "",
	Description: "",
	DisplayName: "",
	Tooltip: "",
	Category: "",
	Audience: "",
	fileName: "",
	mimeType: "",
	file: undefined,
	allowesign: false,
	allowcheckinout: false,
	eSignAccountId: "",
	eSignIntegrationKey: "",
	eSignPrivateKey: "",
	eSignUrl: "",
	eSignUserId: "",
	eSignProvider: "",
	emailMoniker: "",
	PropertyBag: "",
	allowNotification: false,
	allowUpdate: false,
	allowSendMail: false,
	defaultDescription: "",
	IsSubForm: false
}

function TemplateGeneral(props: any) {
	const { branding } = useBranding()
	const [audiences, setAudiences] = useState<IAudienceWithOwnerResponse[]>([]);
	const [audienceOptions, setAudienceOptions] = useState<IDropdownOption[]>([]);
	const [optionsCategories, setoptionsCategories] = useState<IComboBoxOption[]>([]);
	const [requiredCategory, setRequiredCategory] = useState(false)
	const [requiredUploadFile, setRequiredUploadFile] = useState(false)
	const [uploadingFile, setUploadingFile] = useState(false);
	const [disabledCopyUniqueReference, setDisabledCopyUniqueReference] = useState(true);
	const [isNotApplicationAllowed, setIsNotApplicationAllowed] = useState(true)
	const [modalAudienceOpen, setModalAudienceOpen] = useState(false)
	const getProp = (obj: any, key: string) => key.split('.').reduce((o, x) => (typeof o == "undefined" || o === null) ? o : o, obj);
	const [cultures, setCultures] = useState<IDropdownOption[]>([]);
	const [validForm, setValidForm] = useState(false)
	const [cultureTags, setCultureTags] = useState<ITag[]>([]);
	const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'left', height: 'inherit' } };
	const { t } = useTranslation(['common', 'properties']);

	const iconButtonStylesTag = {
		root: {
			color: branding.theme.palette.themePrimary,
			marginLeft: 'auto',
			marginTop: '4px',
			marginRight: '2px',
			selectors: {
				':hover': {
					cursor: 'pointer',
					backgroundColor: '#c3c3c3'
				}
			},
		},
		rootHovered: {
			color: theme.palette.black,
		},
	};

	const contentStyles = mergeStyleSets({
		container: {
			display: 'flex',
			flexFlow: 'column nowrap',
			alignItems: 'stretch',
		},
		header: [
			theme.fonts.xLarge,
			{
				flex: '1 1 auto',
				borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
				color: theme.palette.black,
				display: 'flex',
				alignItems: 'center',
				fontWeight: FontWeights.semibold,
				padding: '12px 12px 14px 24px',
			},
		],
		body: {
			width: '80vw',
			height: '100vh',
			flex: '4 4 auto',
			padding: '0 24px 24px 24px',
			maxHeight: '500px',
			selectors: {
				p: { margin: '14px 0' },
				'p:first-child': { marginTop: 0 },
				'p:last-child': { marginBottom: 0 },
			},
		},
		bodyMail: {
			flex: '4 4 auto',
			padding: '0 24px 24px 24px',
			overflowY: 'hidden',
			minHeight: 500,

		},
		containerMail: {
			display: 'flex',
			flexFlow: 'column nowrap',
			alignItems: 'stretch',
			width: '50vw'
		},
	});


	const menuItems: IContextualMenuItem[] = [
		{
			key: 'resendConsentEmail',
			text: t('text.resentEmail', { ns: 'properties' }),
			title: t('text.resentEmail', { ns: 'properties' }),
			onClick: () => {
				integrateGraphApi();
			},
		},
		{
			key: 'testEmailConnection',
			text: t('text.testEmail', { ns: 'properties' }),
			title: t('text.testEmail', { ns: 'properties' }),
			onClick: () => {
				testEmailConnection()
			},
		},
	];

	const menuProps: IContextualMenuProps = {
		shouldFocusOnMount: true,
		items: menuItems,
	};

	useEffect(() => {
		if (props.txtUniqueReference != null && props.txtUniqueReference.trim().length != 0) setDisabledCopyUniqueReference(false)
		else setDisabledCopyUniqueReference(true)

	}, [props.txtUniqueReference])

	useEffect(() => {
		if (props.showTemplateGeneral) {

			if (props.AudienceSelectedKey === "") {
				loadSubscriptionTemplate().then((data) => {
					if (data) {
						if (audienceOptions.length === 0) {
							loadAudience();
						}
						loadCategories().then((response: any) => {
							setoptionsCategories(response.map((i: any) => {
								return { key: i.Value, text: i.Value }
							}))
						}).then(r => {
							loadCultures()
						})
					} else {

					}
				})
			} else {
				setIsNotApplicationAllowed(false)
				props.callbackMaxApplicationAllowed("", false)
				if (audienceOptions.length === 0) {
					loadAudience();
				}
				loadCategories().then((response: any) => {
					setoptionsCategories(response.map((i: any) => {
						return { key: i.Value, text: i.Value }
					}))
				}).then(r => {
					loadCultures()
				})
			}
		}
	}, [props.isEdit])


	/**
	 * Verify the MaxApplicationAllowed value
	 */
	const loadSubscriptionTemplate = async () => {
		let result: boolean = false
		setIsNotApplicationAllowed(true)
		props.callbackMaxApplicationAllowed("", false)
		props.callbackLoading(true)
		await AudienceService.GetTemplateUsage()
			.then((data) => {
				if (data.Success) {
					result = true
					setIsNotApplicationAllowed(false)
				} else {
					props.callbackMaxApplicationAllowed(data.ExceptionMessage, true)
				}
			})
			.catch((error) => {
				props.callbackMaxApplicationAllowed(error, true)
			})
			.finally(() => {
				props.callbackLoading(false)
			})

		return result
	}


	const loadAudience = async () => {
		const req: IRequest = {
			UserName: Helper.getUsername(),
			AccountId: Helper.getDefaultAccount(),
			ApiKey: "-1",
			Autoprovision: true,
			Path: await Helper.getSite(),
			SK: Helper.getSessionKey(),
			SessionKey: Helper.getSessionKey()
		}
		await Helper.GetDocumentOnlyAudienceDetailWithOwner(req).then(data => {
			setAudiences(data)
			props.setSelectedAudience(data.find(f => f.Id == props.AudienceSelectedKey));
			if (data.length !== undefined) {
				setAudienceOptions(data.map<IDropdownOption>(item => {
					var itemText = item.Name
					if (item.Name.trim().toLowerCase() === "default") {
						itemText = itemText + (item.OwnerFullName !== null ? " (" + item.OwnerUserName + "(" + item.OwnerFullName + ")" + ")" : "")
					}
					else {
						itemText = itemText + " (" + item.Description + " )"
					}
					var textAudience = itemText !== "" ? itemText : item.Name
					return { key: item.Id, text: textAudience }
				}))
			}
		})
	}

	const loadCategories = async () => {
		const req: IRequestGeneral = {
			UserName: Helper.getUsername(),
			SK: Helper.getSessionKey(),
			AccountId: Helper.getDefaultAccount(),
			AccountIds: Helper.getDefaultAccount(),
			ApiKey: "-1",
			Autoprovision: true,
			Site: await Helper.getSite(),
		}
		return await Helper.getCategoriesTemplate(req)
	}

	const onAudienceChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
		tempTemplate.Audience = option?.key
		let parameter: IParam = { Value: option?.key, Name: "Audience" }
		loadtempTemplate(parameter)
		props.setAudienceSelectedKey(option?.key)
		props.setSelectedAudience(audiences.find(f => f.Id == option?.key));

		props.setIsModified(true)

	};

	const onChange = React.useCallback((event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
		let key = option?.key;
		var cat = "";
		if (value) {
			key = value;
			setoptionsCategories(prevOptions => [...prevOptions, { key: key!, text: value }]);
			props.setSelectedCategory({ key: key!, text: value });
			props.setSelectedKey(key);
			props.setIsModified(true)
			let parameter: IParam = { Value: option?.key, Name: "Category" }
			loadtempTemplate(parameter)

		}
		else {
			props.setSelectedCategory(option);
			props.setSelectedKey(option?.key);
			let parameter: IParam = { Value: option?.key, Name: "Category" }
			loadtempTemplate(parameter)

		}
		if (key != null && key != "")
			setRequiredCategory(true)

		else
			setRequiredCategory(false)
	},
		[true],

	);

	const loadtempTemplate = (item?: IParam) => {
		if (sessionStorage.getItem("temTemplate") !== undefined) {
			let tempTemplate: ITemplateGeneral
			if (sessionStorage.getItem("temTemplate") !== "") {
				tempTemplate = JSON.parse(sessionStorage.getItem("temTemplate")!)// as ITemplateGeneral
			}
			else {
				tempTemplate = {
					templateName: "",
					Description: "",
					DisplayName: "",
					Tooltip: "",
					Category: "",
					Audience: "",
					fileName: "",
					mimeType: "",
					file: undefined,
					allowesign: false,
					allowcheckinout: false,
					eSignAccountId: "",
					eSignIntegrationKey: "",
					eSignPrivateKey: "",
					eSignUrl: "",
					eSignUserId: "",
					eSignProvider: "",
					PropertyBag: "",
					emailMoniker: "",
					allowNotification: false,
					allowUpdate: false,
					allowSendMail: false,
					defaultDescription: "",
					IsSubForm: false
				}
			}

			if (item !== undefined) {
				var value = item.Name

				try {
					switch (value) {
						case 'templateName': {
							tempTemplate.templateName = item.Value?.toString()
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'Description': {
							tempTemplate.Description = item.Value?.toString()
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'DisplayName': {
							tempTemplate.DisplayName = item.Value?.toString()
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}

						case 'Tooltip': {
							tempTemplate.Tooltip = item.Value?.toString()
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'Category': {
							tempTemplate.Category = item.Value?.toString()
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'Audience': {
							tempTemplate.Audience = item.Value?.toString()
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}

						case 'emailMoniker': {
							tempTemplate.emailMoniker = item.Value?.toString()
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}

						case 'DefaultDescription': {
							tempTemplate.defaultDescription = item.Value?.toString()
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}

					}
				} catch (error) {
					console.log(error)
				}
			}
			else {
				return tempTemplate
			}
		}
		else { return null }
	}

	const _onChangeDescription = useConstCallback(
		(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
			props.setDescription(newValue!)
			props.setIsModified(true)
			let parameter: IParam = { Value: newValue, Name: "Description" }
			loadtempTemplate(parameter)
		},
	);
	const _onChangeDefalutDescription = useConstCallback(
		(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
			if (newValue !== undefined) {
				let formatvalue = formatString(newValue!)
				newValue = formatvalue
				props.setDefaultDescription(newValue)
				props.setIsModified(true)
				let parameter: IParam = { Value: newValue, Name: "DefaultDescription" }
				loadtempTemplate(parameter)
			}
		},
	);
	const _onChangeDisplayName = useConstCallback(
		(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
			if (newValue !== undefined) {
				let value = newValue!;
				newValue = formatString(value!)
				props.setDisplayName(newValue!)
				props.setIsModified(true)
				let parameter: IParam = { Value: newValue, Name: "DisplayName" }
				loadtempTemplate(parameter)
			}
		},
	);
	const _onChangeTooltip = useConstCallback(
		(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
			props.setTooltip(newValue!)
			props.setIsModified(true)
			let parameter: IParam = { Value: newValue, Name: "Tooltip" }
			loadtempTemplate(parameter)
		},
	);
	const getBase64 = (file: File, propToSet: React.Dispatch<React.SetStateAction<string>>) => {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
		};
		reader.onloadend = () => {
			setRequiredUploadFile(true)
			propToSet(reader.result!.toString().split(',')[1]);
		}

	}
	async function uploadFile(ev: React.ChangeEvent<HTMLInputElement>) {
		setUploadingFile(true)
		if (ev.target.files != null && ev.target.files.length > 0) {
			var mimeType = ev.target.files![0].type;
			console.log(mimeType)
			if (mimeType.indexOf("vnd.openxmlformats-officedocument.wordprocessingml.document") >= 0) {
				var name = ev.target.files![0].name;
				var file = ev.target.files![0];
				props.setMimeType(mimeType)
				props.setFileName(name);
				props.setTemplateName(name!)
				tempTemplate.fileName = name
				tempTemplate.mimeType = mimeType
				tempTemplate.file = ev.target.files![0]
				props.settempTemplate(tempTemplate)
				setRequiredUploadFile(true)
				props.toggleMessage("", false)
				getBase64(file, props.setFile);
			}
			else {
				setRequiredUploadFile(false)
				props.toggleMessage(t('text.invalidFile', { ns: 'properties' }), true)
			}
		}
		else {
			setRequiredUploadFile(false)
		}
		setUploadingFile(false)
	}

	function goToAudiences() {
		toggleAudienceModal(true)
	}

	const toggleAudienceModal = useConstCallback((open: boolean) => {
		setModalAudienceOpen(open)
		if (!open)
			loadAudience()
	})

	async function loadCultures() {

		const list: IDropdownOption[] = []
		const listC: ITag[] = []
		await AppService.getCultures()
			.then(({data}: any) => {
				data.forEach((e: any) => {
					list.push({
						key: e.Key,
						text: e.Value
					})
					listC.push({
						key: e.Key,
						name: e.Value
					})
				});
			})
			.finally(() => {
				setCultures(list)
				setCultureTags(listC)
			})
	}

	const _onChangeEmailMoniker = useConstCallback(
		(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {

			props.setEmailMoniker(newValue!)
			props.setIsModified(true)
			let parameter: IParam = { Value: newValue, Name: "emailMoniker" }
			loadtempTemplate(parameter)
		},
	);

	const getDisplayLabel = (title: string, description: string): string => {

		var label = title + " " + description;
		if (isMobile) {
			label = title;
		}

		return label;
	};

	const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
		if (!tagList || !tagList.length || tagList.length === 0) {
			return false;
		}
		return tagList.some(compareTag => compareTag.key === tag.key);
	};
	const filterSuggestedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
		return filterText
			? cultureTags.filter(
				tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
			)
			: [];
	};

	const getTextFromItem = (item: ITag) => item.name;
	const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
		suggestionsHeaderText: t('text.suggestedCultures', { ns: 'properties' }),
		noResultsFoundText: t('text.noCultures', { ns: 'properties' }),
	};
	const inputProps: IInputProps = {
		onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onBlur called'),
		onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onFocus called'),
	};
	const onCultureChangeTags = async (items?: ITag[]) => {
		if (items!.length > 0) {
			props.setCultureSelectedKey(items![0].key)
			props.setSelectedCulture(items!);
			props.setIsModified(true)
		}
	};

	const onRenderItemTag = (props: any) => {
		return (
			<>
				<div style={{ paddingLeft: "5px" }}>
					{props.item.name}
				</div>
				<div style={{ paddingLeft: "5px" }}>
					<Icon
						styles={iconButtonStylesTag}
						iconName={"Cancel"}
						title={t('Common.Button.Remove', { ns: 'common' })}
						onClick={RemoveTags} />
				</div>
			</>
		);
	};
	function RemoveTags() {
		props.setCultureSelectedKey("")
		props.setSelectedCulture([])
	}

	const onServiceAccountChange = (event?: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => {

		props.setIsModified(true)
		if (checked) {
			props.setUseServiceAccount(true)
			props.setServiceAccountEmailToggleState(true)
		} else {
			props.setUseServiceAccount(false)
			props.setServiceAccountEmailToggleState(false)
		}
	}

	const integrateGraphApi = async () => {

		if (props.serviceAccountEmail !== "" && Helper.isEmailValid(props.serviceAccountEmail)) {

			const req: IResendRequest = {
				Approver: props.serviceAccountEmail,
				Owner: props.selectedAudience.OwnerUser,
				AudienceId: props.selectedAudience.DocumentOnlyAudienceMasterId ?? props.selectedAudience.Id,
				ServiceEmail: props.serviceAccountEmail,
				TemplateId: props.templateId.toString(),
				ClientID: props.serviceAccountClientID,
				ClientSecret: props.serviceAccountClientSecret,
				RedirectURL: props.serviceAccountRedirectURL
			}
			await GraphApiService.Resend({ ...req, AudienceId: req.AudienceId?.toString() }).then(() => {
				props.showNotification(t('text.emailSent', { ns: 'properties' }), true)
			}).catch((error) => {
				props.showNotification(error.message, false)
			})
		}
	}

	const testEmailConnection = async () => {

		if (props.serviceAccountEmail !== "" && Helper.isEmailValid(props.serviceAccountEmail)) {

			const request: ITestRequest = {
				TemplateId: props.templateId.toString()
			}
			await GraphApiService.Test(request)
				.then((data) => {
					props.showNotification(t('text.emailSent', {ns: 'properties'}), true)
				})
				.catch((error) => {
					props.showNotification(error.message ? error.message.toString() : error.toString(), false)
				})
				.finally()
		}
	}
		
	const copyUniqueReference = async () => {
		navigator.clipboard.writeText(window.location.origin + "/app?import=" + props.txtUniqueReference).then(
			() => { },
			() => {
				const input = document.createElement('input');
				input.style.position = 'absolute';
				input.style.left = '-100';
				input.style.zIndex = "-1000";
				document.body.appendChild(input);
				input.value = window.location.origin + "/app?import=" + props.txtUniqueReference;
				input.focus();
				input.select();
				const result = document.execCommand('copy');
				if (!result) {
					console.error('Failed to copy text.');
				}
				input.remove();
			}
		)
	}

	const uploadImageInfo = (ev: React.ChangeEvent<HTMLInputElement>) => {
		setUploadingFile(true)
		props.setImageInfo("");
		if (ev.target.files != null && ev.target.files.length > 0) {
			var mimeType = ev.target.files![0].type;
			var name = ev.target.files![0].name;
			var file = ev.target.files![0];
			var sizevalid = 200
			var fileSize = ev.target.files![0].size
			var Sizekb = fileSize / 1024
			let IsValidSize = Sizekb <= sizevalid
			if (mimeType.includes("image/png")) {
				if (IsValidSize) {
					getBase64(file, props.setImageInfo)
					props.toggleMessage("", false)
				}
				else {
					props.toggleMessage(t('text.invalidImageSize', { ns: 'properties' }), true)
				}
			}
			else {
				props.toggleMessage(t('text.invalidImageFile', { ns: 'properties' }), true)
			}
		}
		setUploadingFile(false)
	}

	return (
		<>
			<label className="ms-fontSize-18">{t('label.title', { ns: 'properties' })}</label>

			<Stack tokens={stackTokens} styles={{ root: { paddingRight: "10px" } }}>
				<label />
				<label />
				<div key="grid1" className="ms-Grid" dir="ltr">
					<div key="row1" className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg8">
							<TextField id="txtUniqueReference" label={getDisplayLabel(t('title.uniqueReference', { ns: 'properties' }), "(" + t('text.uniqueReference', { ns: 'properties' }) + ")")} disabled value={props.txtUniqueReference} title={t('title.uniqueReference', { ns: 'properties' })} />
						</div>
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
							<p style={{ marginBottom: "5px" }}>{t('title.copy', { ns: 'properties' })}</p>
							<ActionButton iconProps={UniqueReferenceIcon} allowDisabledFocus disabled={disabledCopyUniqueReference} checked={undefined} onClick={() => { copyUniqueReference() }}>
								{t('text.copy', { ns: 'properties' })}
							</ActionButton>
						</div>


					</div>
				</div>

				<TextField
					id="txtDisplayName"
					autoFocus
					label={getDisplayLabel(t('label.name', { ns: 'properties' }), t('label.nameDescription', { ns: 'properties' }))}
					maxLength={30}
					required
					value={props.txtDisplayName}
					onChange={_onChangeDisplayName}
					onBlur={_onChangeDisplayName}
					disabled={isNotApplicationAllowed}
					title={t('title.name', { ns: 'properties' })}
				/>
				<TextField
					id="txtDisplayNameTooltip"
					label={getDisplayLabel(t('label.tooltip', { ns: 'properties' }), t('label.tooltipDescription', { ns: 'properties' }))}
					maxLength={300}
					required
					value={props.txtTooltip}
					disabled={isNotApplicationAllowed}
					onChange={_onChangeTooltip}
					title={t('title.tooltip', { ns: 'properties' })}
				/>
				<TextField
					id="txtDescription"
					required maxLength={1000}
					label={getDisplayLabel(t('label.description', { ns: 'properties' }), t('label.descriptionDescription', { ns: 'properties' }))}
					value={props.txtDescription}
					disabled={isNotApplicationAllowed}
					onChange={_onChangeDescription}
					title={t('title.description', { ns: 'properties' })}
				/>
				<TextField
					id="txtDefaultDescription"
					maxLength={40}
					label={getDisplayLabel(t('label.default', { ns: 'properties' }), "")}
					value={props.txtDefaultDescription}
					disabled={isNotApplicationAllowed}
					onChange={_onChangeDefalutDescription}
					onBlur={_onChangeDefalutDescription}
					title={t('title.default', { ns: 'properties' })}
				/>
				{props.isEdit ?
					<>
						<div>

							<label>
								<Trans>
									{t('label.emailInformation', { ns: 'properties', AppName: branding.appName, escapeInterpolation: false })}
								</Trans>
							</label>

						</div>
						<div style={{ display: 'flex' }}>
							<div style={{ marginLeft: 'auto' }}>
								<Link href="/Kim-Document-Email-Integration.pdf" target="_blank" title={t('text.linkEmailIntegration', { ns: 'properties', AppName: branding.appName })}>{t('text.linkEmailIntegration', { ns: 'properties', AppName: branding.appName })}</Link>
							</div>
						</div>
						<div style={{ display: 'flex' }}>
							<div style={{ marginLeft: 'auto' }}>
								<Toggle id='checkServiceAccount' checked={props.useServiceAccount} label="" onText={t('text.toggleOn', { ns: 'properties' })} offText={t('text.toggleOff', { ns: 'properties' })} onChange={onServiceAccountChange} defaultChecked={props.useServiceAccount} />
							</div>
						</div>

					</> : null}
				{!props.useServiceAccount ?
					<TextField id="txtEmailMoniker" required disabled={isNotApplicationAllowed} maxLength={250} label={getDisplayLabel(t('label.email', { ns: 'properties' }), t('label.emailDescription', { ns: 'properties' }))} value={props.txtEmailMoniker} onChange={_onChangeEmailMoniker} title={t('title.email', { ns: 'properties' })} />
					: <div key="grid" className="ms-Grid" dir="ltr">
						<div key="row" className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm8">
								<TextField
									id="txtServiceEmailAccount"
									label={t('label.emailService', { ns: 'properties' })}
									maxLength={250}
									disabled={props.serviceAccountEmailDisabled}
									required onGetErrorMessage={(v) => { return Helper.isEmailValid(v) ? "" : t('label.invalidEmail', { ns: 'properties' }) }}
									placeholder={t('placeholder.emailService', { ns: 'properties' })} title={t('label.emailService', { ns: 'properties' })}
									onChange={(_, v) => { props.setServiceAccountEmail(v!); }} value={props.serviceAccountEmail} />
							</div>
							<div className="ms-Grid-col ms-sm2" style={{ top: '38px' }}>
								{props.serviceAccountEmailDisabled ?
									<><IconButton
										menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
										role="button"
										title={t('title.emailActions', { ns: 'properties' })}
										aria-haspopup
										aria-label={t('label.emailActions', { ns: 'properties' })}
										styles={iconListButtonStyles}
										menuProps={menuProps}
									/><Link href="" onClick={(v) => { props.setServiceEmailAccountConfirmationMessage(t('text.emailActions', { ns: 'properties', AppName: branding.appName })); props.setServiceAccountEmailToggleState(false); props.setShowServiceEmailAccountConfirmation(true) }} style={{ marginTop: '1px' }}>{t('Common.Button.Delete', { ns: 'common' })}</Link>
									</>
									:
									<Link href="" onClick={() => { props.setServiceEmailAccountConfirmationMessage(t('text.emailSave', { ns: 'properties' })); props.setShowServiceEmailAccountConfirmation(true) }} id="saveEmail" style={{ marginTop: '1px' }}>{t('Common.Button.Save', { ns: 'common' })}</Link>}
							</div>
						</div>
						{/*<div key="row" className="ms-Grid-row">*/}
						{/*	<div className="ms-Grid-col ms-sm4">*/}
						{/*		<TextField id="txtClientID" label="Client ID" type="password" maxLength={2500} disabled={props.serviceAccountEmailDisabled} placeholder="Client ID." title="If you have your own Azure Active Directroy Application, please provide the ClientId, Otherwise leave empty for default application." onChange={(_, v) => { props.setServiceAccountClientID(v!); }} value={props.serviceAccountClientID} />*/}
						{/*	</div>*/}
						{/*	<div className="ms-Grid-col ms-sm4">*/}
						{/*		<TextField id="txtClientSecret" label="Client Secret" type="password" maxLength={2500} disabled={props.serviceAccountEmailDisabled} placeholder="Client Secret." title="If you have your own Azure Active Directroy Application, please provide the ClientSecret, Otherwise leave empty for default application." onChange={(_, v) => { props.setServiceAccountClientSecret(v!); }} value={props.serviceAccountClientSecret} />*/}
						{/*	</div>*/}
						{/*	<div className="ms-Grid-col ms-sm4">*/}
						{/*		<TextField id="txtRedirectURL" label="Redirect URL" maxLength={2500} disabled={props.serviceAccountEmailDisabled} placeholder="Redirect URL." title="Redirection after confirming the authorization of permissions, this can be your own url." onChange={(_, v) => { props.setServiceAccountRedirectURL(v!); }} value={props.serviceAccountRedirectURL} />*/}
						{/*	</div>*/}
						{/*</div>*/}
					</div>
				}
				<ComboBox
					id="cbxCategory"
					selectedKey={props.selectedKey}
					label={getDisplayLabel(t('label.category', { ns: 'properties' }), t('label.categoryDescription', { ns: 'properties' }))}
					autoComplete="on"
					disabled={isNotApplicationAllowed}
					allowFreeform={true}
					options={optionsCategories}
					onChange={onChange}
					title={t('title.category', { ns: 'properties' })}
					required />
				<div className="ms-Grid" dir="ltr">
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
							<Dropdown
								id="ddlAudience"
								label={getDisplayLabel(t('label.audience', { ns: 'properties' }), t('label.audienceDescription', { ns: 'properties' }))}
								options={audienceOptions}
								selectedKey={props.AudienceSelectedKey}
								errorMessage={props.selectedAudience == undefined ? t('text.fieldRequired', { ns: 'common' }) : ""}
								onChange={onAudienceChange}
								disabled={isNotApplicationAllowed}
								title={t('title.audience', { ns: 'properties' })}
							/>
						</div>
						<div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1" style={{ paddingTop: "20px", right: "5px", top: "5px" }}>
							<ActionButton disabled={isNotApplicationAllowed} id="aAddAudience" title={t('title.newAudience', { ns: 'properties' })} iconProps={{ iconName: 'Add' }} onClick={goToAudiences} />
						</div>
					</div>
				</div>
				<label style={{ fontSize: "14px", color: "#323130", fontWeight: 600 }}>{t('label.culture', { ns: 'properties' })}<span style={{ color: "#973434" }}>*</span></label>
				<TagPicker
					removeButtonAriaLabel={t('Common.Button.Remove', { ns: 'common' })}
					onRenderItem={onRenderItemTag}
					disabled={isNotApplicationAllowed}
					selectedItems={props.selectedCulture}//selectedTags}
					onResolveSuggestions={filterSuggestedTags}
					getTextFromItem={getTextFromItem}
					pickerSuggestionsProps={pickerSuggestionsProps}
					onChange={onCultureChangeTags}
					itemLimit={1}
					inputProps={{
						...inputProps,
						id: 'tpCulture',
					}}
				/>
				{props.profile !== undefined && props.profile.AdminBilling ? <><label style={{ fontSize: "14px", color: "#323130", fontWeight: 600 }}>{t('label.image', { ns: 'properties' })}</label>
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" >
							<div style={{ float: "left" }}>
								<label htmlFor="imageFile"><div style={{ padding: "6px", backgroundColor: branding.theme.palette.themePrimary, color: branding.theme.palette.white }} className="divHover divToolBoxItem" >Choose Image</div></label>
								<input accept="image/png" disabled={isNotApplicationAllowed} className="fileButton" id="imageFile" type="file" name="imageFile" onChange={uploadImageInfo} title="Select file that represents this application." />
							</div>
							<div style={{ float: "left" }}>
								<FontIcon style={{ padding: "6px" }} iconName='Cancel' onClick={() => { props.setImageInfo("") }} />
							</div>
						</div>
						<div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10" hidden={props.imageInfo == ""}>
							<img alt="" src={`data:image/png;base64,${props.imageInfo}`} style={{ width: "16vw", height: "16vh", border: "1px solid rgb(0 120 212)" }} />
						</div>
					</div>
				</> : null}

				<label />
				<label />
				{props.newTemplate ?
					<>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
								<label htmlFor="file"><div className="divHover divToolBoxItem" style={{ color: branding.theme.palette.white, backgroundColor: branding.theme.palette.themePrimary }}>{t('Common.Input.File', { ns: 'common' })}</div></label>
								<input className="fileButton" id="file" type="file" name="file" onChange={uploadFile} disabled={props.isEdit || isNotApplicationAllowed} placeholder={t('text.inputFile', { ns: 'common' })} />
							</div>
							<div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" >
								<TextField id="txtFileName" value={props.fileName} disabled={props.isEdit || isNotApplicationAllowed} title={t('title.newApplication', { ns: 'properties' })} />
							</div>
						</div>
						<label style={{ fontSize: "14px", color: "#973434", fontWeight: 600 }}>{t('label.newApplication', { ns: 'properties' })}</label>
					</> :
					<> </>
				}
				<label />
				<label />

				{uploadingFile ?
					<ProgressIndicator description={t('text.spinnerUploading', { ns: 'common' })} />
					: null}
			</Stack>
			<Modal
				isOpen={modalAudienceOpen}
				isBlocking={true}
				containerClassName={contentStyles.containerMail}>
				<div className="ms-Grid-row">

					<Stack horizontal className={contentStyles.header}>
						<StackItem grow={1}>
							<Text variant={"xLarge"} id="myAudience">
								{t('title.audience', { ns: 'properties' })}
							</Text>

						</StackItem>

						<StackItem>
							{!props.fromModal ?
								<HelpComponentRelative
									headline={t('title.audienceHelp', { ns: 'properties' })}
									text={t('text.audienceHelp', { ns: 'properties' })}
									right="50px"
									top="15px"
									location="audiences"
								/>
								: null
							}
							<IconButton
								styles={iconButtonStyles}
								iconProps={cancelIcon}
								ariaLabel={t('Common.Button.Close', { ns: 'common' })}
								onClick={() => {
									toggleAudienceModal(false)
								}}
								title={t('Common.Button.Close', { ns: 'common' })}
							/>
						</StackItem>

					</Stack>


				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<div className={contentStyles.bodyMail}>
							<div className="ms-Grid" >
								<Audiences
									toggleSettings={props.toggleSettings}
									siteOptions={props.siteOptions}
									updateSiteSelected={props.updateSiteSelected}
									siteSelected={Helper.getSiteSelected()}
									accountId={Helper.getDefaultAccount()}
									username={Helper.getUsername()}
									sessionKey={Helper.getSessionKey()}
									fromModal={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<DialogFooter>
							<DefaultButton onClick={() => { toggleAudienceModal(false) }} text={t('Common.Button.Close', { ns: 'common' })} title={t('Common.Button.Close', { ns: 'common' })} />
						</DialogFooter>
					</div>
				</div>
			</Modal>
		</>
	)
}
export default TemplateGeneral