import { IconButton, IScrollablePaneStyles, mergeStyleSets, ScrollablePane } from "@fluentui/react";
import React from "react";
import { IColumn, IList, IRow } from "./Models";
import Row from "./Row";
import './GridStyles.css';
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {ILayout} from "../../../CustomTemplate";

export interface ITableModel {
    id: string;
    rootLayout: ILayout
    columns: IColumn[];
    list: IList;
    locale: string
    getJSXElement: (type: string, id: string, value: string, rowIndex: string, form: UseFormReturn<any, any>, labelVisible: boolean) => JSX.Element
    onAdd: (form: UseFormReturn<any, any>, data: any) => void;
    onUpdate: (row: IRow, cellIdToEdit: string, form: UseFormReturn<any, any>, data: any) => void;
    onDelete: (row: IRow, form: UseFormReturn<any, any>) => void;
    gridForm: UseFormReturn<any, any>
}

const classNamex = mergeStyleSets({
    pane: {
        maxWidth: '100%',
        position: "initial",
    }
});
const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNamex.pane, contentContainer: { position: "initial" } };

const Table = (props: ITableModel) => {

    const handleAddClick = () => {
        props.gridForm.handleSubmit((data) => {

            props.onAdd(props.gridForm, data);
        },
            (error) => {

            })()
    };

    const { t } = useTranslation(['common', 'wizard']);

    return (

        <div className="container scrollVisibleLogs" style={{ overflow: "auto", overflowX: "auto", maxWidth: "100%" }}>
            <table className="GridList">
                <thead style={{zIndex: "10"}}>
                    <tr style={{ backgroundColor: '#f0f0f0', color: "#000" }}>
                        {props.columns.map(item => {
                            return (<th key={`${item.Id}-header`}>{item.Label}</th>)
                        })}
                        <th key={"options-header"}>
                            {t('text.options', { ns: 'wizard' })}
                        </th>
                    </tr>
                    <tr style={{ backgroundColor: '#f0f0f0', verticalAlign: "top" }}>
                        {props.columns.map(item => {
                            return (<th style={{ color: "#f0f0f0"}} key={`${item.Id}-control`}>
                                
                                {props.getJSXElement(item.Type, item.Id, item.Value, "", props.gridForm, false)}
                                </th>)
                        })}
                        <th key={"add-control"}>
                            <IconButton disabled={props.rootLayout.Validations.ReadOnly}  onClick={handleAddClick} iconProps={{ iconName: 'BoxAdditionSolid', styles: { root: { color: props.rootLayout.Validations.ReadOnly? '#DDDDDD' : "#666666" } } }} title={t('title.btnAdd', { ns: 'wizard' })} ariaLabel={t('ariaLabel.btnAdd', { ns: 'wizard' })} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.list?.Rows.map((row: IRow) => {
                        const handleUpdate = (cellIdToEdit: string, data: any) => {
                            props.onUpdate(row, cellIdToEdit, props.gridForm, data);
                        };
                        const handleDelete = () => {
                            props.onDelete(row, props.gridForm);
                        };

                        return (
                            <Row
                                key={row.Index}
                                columns={props.columns}
                                locale={props.locale}
                                getJSXElement={props.getJSXElement}
                                row={row}
                                onUpdate={handleUpdate}
                                onDelete={handleDelete}
                                gridForm={props.gridForm}
                                rootLayout={props.rootLayout}

                            />
                        );
                    }
                    )}
                </tbody>
            </table>
        </div>

    );
};

export default Table