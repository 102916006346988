import {INavLink} from "@fluentui/react";
import {IFormControl, IRequest, OperationType} from "../../../Helpers/Helper";
import {IRule} from "../../CustomTemplates/CustomForm/RHFControls/RHFUploadRunTimeControl";
import HttpRequest from "../../../services/HttpRequest";

export interface ICUDDocumentDraftRequest extends IRequest {
    Id: number,
    TemplateId: number,
    TemplateVersion: number,
    Description: string,
    ParentId: number,
    EndUserName: string,
    Operation: OperationType,
    TemplateXML: string,
    Content: IFormControl[],
    Lists: string
    CampaignUserGUID?: string,
    Rules: IRule[]
}

export interface IGetDocumentDraftRequest extends IRequest {
    Id: number,
    EndUserName: string
    CampaignUserGUID?: string
    DocSource?:string
    TemplateId?: string
}

export interface IDocumentDraft {
    Id: number,
    TemplateId: number,
    TemplateVersion: number,
    Username: string,
    DateCreated: string,
    DateUpdated: string,
    Description: string,
    ParentId: number,
    BLOB?: string,
    JsonBLOB?: string,
    Template?: INavLink
}

const DraftServices = {
    GetDocumentDrafts: async (request: IGetDocumentDraftRequest) : Promise<IDocumentDraft[]> => {
        const _request = {
            UserName: request.UserName,
            EndUserName: request.EndUserName,
            TemplateId: request.TemplateId ?? '',
            DocSource: request.DocSource,
            Id: request.Id,
            ...(request.CampaignUserGUID !== undefined && {CampaignUserGUID: request.CampaignUserGUID})
        }

        let {data}: any = await HttpRequest.getWithSession('DocumentDraft', _request)
        return data
    },
    CUDDocumentDraft: async (request: ICUDDocumentDraftRequest): Promise<any> => {
        let {data}: any = await HttpRequest.postWithSession('DocumentDraft', request)
        return data
    }
}

export default DraftServices

