import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IChoiceGroupOption, Pivot, PivotItem } from "@fluentui/react";
import { RHFTextBoxAreaControl } from "../../../RHFControls/RHFTextBoxAreaControl";
import FormDataParamsList from "./FormDataParamsList";
import { KimBaseMessage } from "./KimBaseMessage";
import { RHFChoiceGroupControl } from "../../../RHFControls/RHFChoiceGroupControl";
import { BodyType, MethodType } from "../../Models/Enums";

export interface BodyProps {
    test: JSX.Element;
    surrogate: JSX.Element;
    isConfigurationTemplateReadMode?: boolean;
}

export const Body = (props: BodyProps) => {
    const { getValues, control, setValue, trigger, register } = useFormContext();
    const [selectedBodyType, setSelectedBodyType] = useState<IChoiceGroupOption>();

    useEffect(() => {
        register("BodyType", { required: "Body Type is required." });
        if (getValues("BodyType") !== undefined)
            setSelectedBodyType(getValues("BodyType"));
    }, []);

    useEffect(() => { }, [selectedBodyType]);

    const onChangeBodyType = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
        setSelectedBodyType(option);
    };

    const method = getValues("Method") !== undefined ? getValues("Method").key : "";

    return (
        <>
            <RHFChoiceGroupControl
                id="BodyType"
                styles={{ root: { paddingLeft: "10px" }, flexContainer: { display: "flex" } }}
                options={Object.entries(BodyType).map((item) => {
                    return {
                        text: item[0],
                        key: item[1],
                        styles: { field: { marginRight: "10px" } },
                        disabled: MethodType.GET === method ? BodyType.MapInput === item[0] ? false : true : false
                    };
                })}
                onCallback={onChangeBodyType}
                control={control}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
            />
            {selectedBodyType !== undefined &&
                selectedBodyType.key === BodyType.KimBaseMessage ? (<KimBaseMessage isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode} />) : null}
            {selectedBodyType !== undefined &&
                selectedBodyType.key === BodyType.FormData ? (<FormDataParamsList isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode} />) : null}
            {selectedBodyType !== undefined &&
                selectedBodyType.key === BodyType.Raw ? (
                <div style={{ paddingTop: "30px" }}>
                    <RHFTextBoxAreaControl
                        id={"Raw"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter raw."}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={""}
                        rows={10}
                    />{" "}
                </div>
            ) : null}
            {selectedBodyType !== undefined &&
                selectedBodyType.key === BodyType.MapInput ? (
                <PivotItem headerText="Mapping">
                    <Pivot styles={{ root: { width: "13%", float: "left", paddingTop: "15px" }, link: { display: "block" } }}>
                        {MethodType.GET === method ? null : <PivotItem style={{ width: "87%", float: "right", paddingTop: "30px" }} headerText="Payload">
                            <RHFTextBoxAreaControl
                                id={"MapInputPayload"}
                                disabled={props.isConfigurationTemplateReadMode ? true : false}
                                title={"Enter payload."}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                trigger={trigger}
                                defaultValue={""}
                                rows={10}
                            />
                        </PivotItem>}
                        {MethodType.GET === method ? null : <PivotItem style={{ width: "87%", float: "right", paddingTop: "30px" }} headerText="Script">
                            <RHFTextBoxAreaControl
                                id={"MapInputScript"}
                                disabled={props.isConfigurationTemplateReadMode ? true : false}
                                title={"Enter script."}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                trigger={trigger}
                                defaultValue={""}
                                rows={10}
                            />
                        </PivotItem>}
                        <PivotItem style={{ width: "87%", float: "right", paddingTop: "15px" }} headerText="Key Filter">
                            {props.surrogate}
                        </PivotItem>
                        {MethodType.GET === method ?
                            null : <PivotItem style={{ width: "87%", float: "right", paddingTop: "15px" }} headerText="Test Script">
                                {props.test}
                            </PivotItem>}
                    </Pivot>
                </PivotItem>
            ) : null}
        </>
    );
};
