import React from "react";
import {IColumn} from "@fluentui/react";
import i18n from "../../../../i18n/config"

export const getColumns = (): IColumn[] => {
    const {t} = i18n
    return [

        // {
        //     key: 'column0',
        //     name: "Preview",
        //     iconName: 'PreviewLink',
        //     fieldName: 'DisplayName',
        //     isIconOnly: true,
        //     minWidth: 16,
        //     maxWidth: 16,
        //
        //     onRender: (item: IDocument) => {
        //         return <IconButton iconProps={{iconName: 'PreviewLink'}}
        //                            style={{cursor: 'pointer'}}
        //                            title={t('title.gridDocumentIcon', {ns: 'workspace'})}
        //                            className={contentStyles.fileIconImg}
        //                            alt={t('title.gridDocumentIcon', {ns: 'workspace'})}
        //                            onClick={() => {
        //                                // see customTemplate 1957, 
        //                                console.log("Preview item")
        //                                console.log(item)
        //                            }}
        //         />
        //     },
        //
        // },
        {
            key: 'column1',
            name: t('label.colName', {ns: 'automaticallyCreateSubrecords'}),
            ariaLabel: t('ariaLabel.colName', {ns: 'automaticallyCreateSubrecords'}),
            fieldName: 'DisplayName',
            minWidth: 150,
            maxWidth: 175,
            isRowHeader: true,
            isCollapsible: false,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <span title={item.DisplayName}>{item.DisplayName}</span>;
            }
        },
        {
            key: 'column2',
            name: t('label.colDescription', {ns: 'automaticallyCreateSubrecords'}),
            ariaLabel: t('ariaLabel.colDescription', {ns: 'automaticallyCreateSubrecords'}),
            fieldName: 'Description',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isCollapsible: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <span title={item.Description}>{item.Description}</span>
            }
        }

    ]

}
