import React, { useEffect, useState } from "react";
import SubFormService from "../../../../services/subform";
import { DefaultButton, PrimaryButton, Separator, ShimmeredDetailsList, Spinner, SpinnerSize, Stack, StackItem } from "@fluentui/react";
import { CheckboxVisibility, DetailsListLayoutMode, Selection, SelectionMode } from "office-ui-fabric-react/lib/DetailsList";
import { Trans, useTranslation } from "react-i18next";
import { Text } from "@fluentui/react/lib/Text";
import StatusMessage, { StatusEnum } from "../../../../share/StatusMessage";
import { getColumns } from "./RestrictSubForms.data";
import { nonShrinkingStackItemStyles } from "./Style";
import RestrictSubFormsService, { ISubFormsResponse, SubFormRestrictionResponse, Template } from "./RestrictSubForms.service";

type ManageSubFormsProperties = {
    titleId: string;
    cancel: any;
    getLinkGroup: any;
    option: string;
    setIsOpenMenu: any;
    showModal: any;
    onLinkClick: any;
    item?: any;
    isOpenMenu: any;
};

const RestrictSubForms = ({ showModal, option, item, getLinkGroup, titleId, cancel, isOpenMenu, onLinkClick, setIsOpenMenu }: ManageSubFormsProperties) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showEmptySubForms, setShowEmptySubForms] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [hasTimer, setHasTimer] = useState(false);
    const [truncated, setTruncated] = useState(true);
    const [isMultiline, setIsMultiline] = useState(false);
    const [dismiss, setDismiss] = useState(true);
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info);
    const [message, setMessage] = useState("");
    const [items, setItems] = useState<ISubFormsResponse[]>([]);
    const { t } = useTranslation(["restrictsubforms", "common"]);

    let [selection] = useState(new Selection({ selectionMode: SelectionMode.multiple }));

    const showStatusMessage = (message: string, status: StatusEnum, hasTimer = true, truncated = true, isMultiline = true, dismiss = true) => {
        setMessage(message);
        setHasTimer(hasTimer);
        setTruncated(truncated);
        setIsMultiline(isMultiline);
        setDismiss(dismiss);
        setStatusMessageEnum(status);
        setShowMessage(true);
    };

    useEffect(() => {
        if (isLoading) {
            SubFormService.getSubForms()
                .then(({ data }: any) => {
                    if (Array.isArray(data)) {
                        setIsLoaded(true);

                        if (data.length === 0) {
                            setItems([]);
                            setShowEmptySubForms(true);
                        } else {
                            const dataList = data.sort((a: ISubFormsResponse, b: ISubFormsResponse) => {
                                return a.DisplayName!.toLowerCase() < b.DisplayName!.toLowerCase() ? -1 : 1;
                            })
                            setItems(dataList);
                            selection.setItems(data, true);
                            RestrictSubFormsService.GetSubFormRestriction({ TemplateId: Number(item?.key) }).then((res: SubFormRestrictionResponse[]) => {
                                res.forEach((subform) => {
                                    const index = dataList.findIndex((x: ISubFormsResponse) => x.TemplateId === subform.SubformTemplateId);
                                    selection.setIndexSelected(index, true, false);
                                });
                            });
                        }
                    } else {
                        showStatusMessage(t("message.error", { ns: "common", Error: items }), StatusEnum.Error);
                    }
                    
                })
                .catch((error) => {
                    showStatusMessage(error, StatusEnum.Error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [isLoading]);

    const save = async () => {
        setIsSaving(true);
        const templates: Template[] = selection.getSelection().map((item: any) => {
            return { TemplateId: item.TemplateId, TemplateName: item.Category + " - " + item.DisplayName };
        });
        await RestrictSubFormsService.CreateSubFormRestriction({
            TemplateId: Number(item?.key),
            Templates: templates,
        })
            .then((data) => {
                if (!data.Success) {
                    showStatusMessage(data.ExceptionMessage ? data.ExceptionMessage : "Request error", StatusEnum.Error)
                } else {
                    showStatusMessage(t('messages.success', {ns: 'restrictsubforms'}), StatusEnum.Success)
                }
            })
            .catch(() => {
                showStatusMessage("Request error", StatusEnum.Error);
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    return (
        <>
            {/*-- START CONTENT --*/}
            <Stack grow={1} tokens={{ padding: 8 }} style={{ overflow: "hidden" }}>
                <StackItem>
                    <Text id={titleId} variant={"large"}>
                        {t("title.titleSection", { ns: "restrictsubforms" })}
                    </Text>
                    <Text variant={"medium"}>
                        <p style={{padding:"14px 0px"}}>{t("text.help", { ns: "restrictsubforms" })}</p>
                    </Text>
                    {showMessage && (
                        <>
                            <StatusMessage status={statusMessageEnum} hasTimer={hasTimer} truncated={truncated} isMultiline={isMultiline} dismiss={dismiss} setShowMessage={setShowMessage}>
                                <div>{message}</div>
                            </StatusMessage>
                            <Separator />
                        </>
                    )}
                </StackItem>
                <StackItem grow={1} className="scrollVisible" data-is-scrollable="true" style={{ overflow: "hidden", overflowY: "auto", height: "40vh" }}>
                    <ShimmeredDetailsList
                        items={items}
                        enableShimmer={!isLoaded}
                        compact={false}
                        columns={getColumns()}
                        selectionMode={SelectionMode.multiple}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        isSelectedOnFocus={false}
                        setKey={"TemplateId"}
                        selection={selection}
                        selectionPreservedOnEmptyClick={true}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn={t("ariaLabel.selectionColumn", { ns: "restrictsubforms" })}
                        ariaLabelForSelectAllCheckbox={t("ariaLabel.selectAllCheckbox", { ns: "restrictsubforms" })}
                        checkButtonAriaLabel={t("ariaLabel.checkButton", { ns: "restrictsubforms" })}
                        checkboxVisibility={CheckboxVisibility.always}
                    />
                    {showEmptySubForms && (
                        <p style={{ textAlign: "center" }}>
                            <Text variant={"small"}>
                                <Trans>{t("text.emptySubforms", { ns: "restrictsubforms" })}</Trans>
                            </Text>
                        </p>
                    )}
                </StackItem>

                {/*-- START FOOTER --*/}
                <Separator></Separator>
                <Stack horizontal style={{ overflow: "hidden" }} styles={nonShrinkingStackItemStyles}>
                    <StackItem grow={1}>&nbsp;</StackItem>
                    {isLoading && (
                        <>
                            <StackItem>
                                <Spinner size={SpinnerSize.medium} labelPosition="left" label={t("text.spinner", { ns: "common" })} />
                            </StackItem>
                        </>
                    )}
                    {isSaving && (
                        <>
                            <StackItem>
                                <Spinner size={SpinnerSize.medium} labelPosition="left" label={t("text.spinnerSaving", { ns: "common" })} />
                            </StackItem>
                        </>
                    )}

                    <StackItem>
                        <PrimaryButton disabled={!isLoaded || items.length === 0 || isSaving} style={{ marginLeft: 8 }} onClick={save} text={t("Common.Button.Update", { ns: "common" })} title={t("Common.Button.Update", { ns: "common" })} />
                        <DefaultButton onClick={cancel} style={{ marginLeft: 8 }} text={t("Common.Button.Close", { ns: "common" })} title={t("Common.Button.Close", { ns: "common" })} />
                    </StackItem>
                </Stack>
                {/*-- END FOOTER --*/}
            </Stack>
            {/*-- END CONTENT --*/}
        </>
    );
};

export default RestrictSubForms;
