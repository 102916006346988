
import React from 'react'
import { Modal } from '@fluentui/react/lib/Modal';
import { Panel, PanelType, Label, Stack, mergeStyleSets, PrimaryButton } from '@fluentui/react';
import { panelHeader } from '../Preview/Style';
import { InputType } from './Validations';

type Props = {
    dismissPanel: any
    isOpen: string | null
    ccId: any
    onRenderFooterContent: any
    selectedType: any
}
const stackTokens = { childrenGap: 4 };
const PropertiesMenu: React.FC<Props> = ({ onRenderFooterContent, selectedType, dismissPanel, isOpen, ccId, children }) => {


    return (
        <Panel
            headerText={"Properties"}
            headerTextProps={panelHeader}
            isBlocking={false}
            isOpen={Boolean(isOpen)}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Close."
            type={PanelType.smallFixedFar}
            styles={{ content: { padding: 0, overflow: "auto", overflowX: "hidden" } }}
            onRenderFooterContent={(props, defaultRender) => {

                return onRenderFooterContent(selectedType);
            }}
            isFooterAtBottom={true}
        >
            <Stack tokens={{ padding: 16 }}>
                <Label
                    className={contentStyles.panelHeaderSubtitle}
                    title="Content mapping control tag name binding form wizard to document."
                >
                    {ccId}
                </Label>
            </Stack>
            <Stack tokens={stackTokens}>
                <Label></Label>
                {children}
            </Stack>
        </Panel>
    )
}

const contentStyles = mergeStyleSets({

    panel: {
        marginLeft: '10px'
    },
    panelHeaderSubtitle: {
        fontFamily: "Calibri",
        fontWeight: "bold",
        fontSize: 18,
    },
});
export default PropertiesMenu