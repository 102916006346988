import { IMessageBarStyles, MessageBar } from "@fluentui/react";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IStatusType } from "../interfaces/IApp";

const successMessage: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac",
    },
};
const errorMessage: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5",
    },
};
const warningMessage: IMessageBarStyles = {
    root: {
        backgroundColor: "#fff9d5",
    },
};
const infoMessage: IMessageBarStyles = {
    root: {
        backgroundColor: "#d5fbff",
    },
};
export enum StatusEnum {
    Info = "info",
    Warning = "warning",
    Error = "error",
    Success = "success",
}
type StatusMessageProps = {
    setShowMessage: any
    children: string | ReactElement,
    status: StatusEnum | IStatusType
    hasTimer?: boolean
    isMultiline?: boolean
    truncated?: boolean
    dismiss?: boolean
}
const StatusMessage = ({ setShowMessage, children, status, hasTimer = true, isMultiline = false, truncated = false, dismiss = false }: StatusMessageProps) => {
    const { t } = useTranslation(['common']);
    const statuses: { [id: string]: any; } = {
        'info': infoMessage,
        'warning': warningMessage,
        'error': errorMessage,
        'success': successMessage
    }
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);

    useEffect(() => {
        if (hasTimer) {
            const timeId = setTimeout(function () {
                setShowMessage(false);
            }, 5000);

            return () => {
                clearInterval(timeId)
                //setShowMessage(false)
            }
        }
    }, [hasTimer, setShowMessage]);

    return (
        <MessageBar styles={statuses[status]} isMultiline={isMultiline} truncated={truncated}
            onDismiss={dismiss ? () => { setShowMessage(false) } : undefined}
            dismissButtonAriaLabel={t('Common.Button.Close', { ns: 'common' })}
            overflowButtonAriaLabel={t('Common.Button.See more', { ns: 'common' })}>
            {children}
        </MessageBar>
    );
};
export default StatusMessage;
