import React, { useState, useEffect } from 'react';
import { Modal } from '@fluentui/react/lib/Modal';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { MarqueeSelection, Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import '../../Custom.css';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import { IContextualMenuProps, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import Helper, { OperationType } from '../../Helpers/Helper';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { IconButton, IButtonStyles, DefaultButton, ActionButton } from '@fluentui/react/lib/Button';
import { isMobile } from 'react-device-detect';
import { Spinner, SpinnerSize } from '@fluentui/react';
import DraftServices, { IDocumentDraft } from './Services/DraftServices';
import DraftCustomForm from './DraftDocuments';
import { useTranslation } from 'react-i18next';
import DraftDocument from "./DraftDocument";
import {useBranding} from "../../hooks/useBranding";


const classNames = mergeStyleSets({
    hideMenu: {
        display: 'none'
    },
    showMenu: {
        display: 'inline'
    },
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
        color: 'rgb(96, 94, 92)'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
    nameText: {
        fontWeight: 'bold',
    },
});

const theme = getTheme();

const iconModalButtonStyles = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackTokens = { childrenGap: 50 };

type DraftListProps = {
    TemplateId?: string,
    onCreateUpdateDraft: Function
    handleLoadDraft?: Function
    UserName?: string
    EndUserName?: string
    CurrentDraftId?: number
}

function DraftList(props: DraftListProps) {
    const { branding } = useBranding()
    const [showDocumentDrafts, setShowDocumentDrafts] = useState(false);
    const [documentDrafts, setDocumentDrafts] = useState<IDocumentDraft[]>([])
    const [showDraftCustomForm, setShowDraftCustomForm] = useState(false)
    const [jsonBLOB, setJsonBLOB] = useState<string | undefined>(undefined)
    const [draftBLOB, setDraftBLOB] = useState<string | undefined>(undefined)
    const [documentDraftSelected, setDocumentDraftSelected] = useState<IDocumentDraft>();
    const { t } = useTranslation(['common', 'draft']);
    const [selectionDocumentDraft] = useState(new Selection({
        onSelectionChanged: () => {
            const selectionCount = selectionDocumentDraft.getSelectedCount();
            switch (selectionCount) {
                case 0: {
                    setDocumentDraftSelected({} as IDocumentDraft)
                    break;
                }
                case 1: {
                    setDocumentDraftSelected(selectionDocumentDraft.getSelection()[0] as IDocumentDraft)
                    break;
                }
                default: {
                    setDocumentDraftSelected({} as IDocumentDraft)
                    break;
                }
            }
        }
    }))
    const [loadingDrafts, setLoadingDrafts] = useState(false)
    const [hiddenLoadingDraft, setHiddenLoadingDraft] = useState(true)
    const [spinnerMessage, setSpinnerMessage] = useState("")

    const contentStyles = mergeStyleSets({
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? '98%' : '60vw',
            height: '60vh',
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    useEffect(() => {
        if (showDocumentDrafts) {
            setSpinnerMessage(t('text.spinner', { ns: 'common' }))
            loadDocumentDrafts()
        }
    }, [showDocumentDrafts])

    const loadDocumentDrafts = async () => {
        setLoadingDrafts(true)
        let path = await Helper.getSite()
        DraftServices.GetDocumentDrafts({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            EndUserName: props.EndUserName ?? Helper.getUsername(),
            Id: -1,
            Path: path,
            SK: Helper.getSessionKey(),
            UserName: props.UserName ?? Helper.getUsername(),
            TemplateId: props.TemplateId?.trim()

        }).then(res => {
            setDocumentDrafts(res)
            setLoadingDrafts(false)
        })
    }

    const deleteDraftDocument = async () => {
        setSpinnerMessage(t('text.spinnerDeleting', { ns: 'common' }))
        setLoadingDrafts(true)
        DraftServices.CUDDocumentDraft({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Content: [],
            Description: "Description",
            EndUserName: props.EndUserName ?? Helper.getUsername(),
            Id: documentDraftSelected !== undefined ? documentDraftSelected.Id : 0,
            ParentId: -1,
            Operation: OperationType.delete,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            TemplateId: documentDraftSelected !== undefined ? documentDraftSelected.TemplateId : 0,
            TemplateVersion: -1,
            TemplateXML: "",
            UserName: props.UserName ?? Helper.getUsername(),
            Lists: "",
            SessionKey: Helper.getSessionKey(),
            Rules: []
        }).then(res => {
            loadDocumentDrafts()
            setSpinnerMessage(t('text.spinner', { ns: 'common' }))
        })
    }

    const getDraft = (draftId: number) => {


        setHiddenLoadingDraft(false)

        Helper.getDocumentOnlyTemplateId(documentDraftSelected!.TemplateId.toString(), true, props.UserName ?? documentDraftSelected!.Username).then(data => {
            let tempDocumentDraftSelected = documentDraftSelected
            tempDocumentDraftSelected!.Template = {
                key: data.TemplateId.toString(),
                name: data.DisplayName,
                url: "",
                title: data.DisplayNameToolTip,
                DocumentOnlyAudienceId: data.DocumentOnlyAudienceId,
                Owner: data.OwnerUserName === Helper.getUsername() ? true : false,
                CoOwner: data.CoOwner,
                CreateDocumen: data.CreateDocument,
                AllowCheckInOut: data.AllowCheckInOut,
                culture: JSON.parse(data.PropertyBag).Culture,
                cultureName: JSON.parse(data.PropertyBag).CultureName,
                released: JSON.parse(data.PropertyBag).Released,
                category: data.Category,
                audienceName: data.AudienceName,
                ownerUsername: data.OwnerUserName,
                ownerFullName: data.OwnerFullName,
                emailMoniker: JSON.parse(data.PropertyBag).emailMoniker,
                documentCreation: JSON.parse(data.PropertyBag).DocumentCreation,
                documentDelete: JSON.parse(data.PropertyBag).DocumentDelete,
                documentShare: JSON.parse(data.PropertyBag).DocumentShare,
                templateDelete: JSON.parse(data.PropertyBag).TemplateDelete,
                templateEdit: JSON.parse(data.PropertyBag).TemplateEdit,
                templateShare: JSON.parse(data.PropertyBag).TemplateShare,
                defaultDescription: JSON.parse(data.PropertyBag).DefaultDescription,
                activeSubscription: data.ActiveSubscription,
                templateRemove: JSON.parse(data.PropertyBag).TemplateRemove
            }

            var itemSubscriptionStatusMessage = data.SubscriptionStatusMessage != null && data.SubscriptionStatusMessage !== undefined && data.SubscriptionStatusMessage !== "" ? data.SubscriptionStatusMessage : ""
            tempDocumentDraftSelected!.Template.SubscriptionStatusMessage = sessionStorage.getItem("entityId") !== 'Not In Teams' ? t('text.documentNotAvailable', { ns: 'draft' }) : (data.CoOwner ? itemSubscriptionStatusMessage + " " + t('text.upgradePlan', { ns: 'draft' }) : data.SubscriptionStatusMessage)

            setDocumentDraftSelected(tempDocumentDraftSelected)
        }).then(res => {
            DraftServices.GetDocumentDrafts({
                AccountId: Helper.getDefaultAccount(),
                ApiKey: "-1",
                Autoprovision: true,
                EndUserName: props.EndUserName ?? Helper.getUsername(),
                Id: draftId,
                Path: Helper.getSiteSelected(),
                SK: Helper.getSessionKey(),
                UserName: props.UserName ?? Helper.getUsername(),
            }).then(res => {
                if (props.TemplateId) {
                    props.handleLoadDraft?.(res[0], closeDraftModal)
                    return
                }
                setDraftBLOB(res[0].BLOB!)
                setJsonBLOB(res[0].JsonBLOB!)
                setShowDraftCustomForm(true)
            })
        })
            .finally(() => {
                setHiddenLoadingDraft(true)

            })
    }

    const getKey = (item: any, index?: number): string => {
        if (item !== undefined)
            return item.Id
        return ""
    }

    const columns: IColumn[] = [
        {
            key: 'column2',
            name: 'Form Wizard',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Form Wizard',
            iconName: 'officeFormsLogo',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 20,
            maxWidth: 20,
            onRender: (item: IDocumentDraft) => {
                return <div> <IconButton iconProps={{ iconName: 'officeFormsLogo' }}
                    allowDisabledFocus
                    style={{ cursor: 'pointer' }}
                    title={t('title.updateDraft', { ns: 'draft' })}
                    className={classNames.fileIconImg}
                    onClick={() => {
                        getDraft(item.Id)
                    }}
                /></div>
            }
        },
        {
            key: 'dateUpdated',
            name: t('title.dateUpdated', { ns: 'draft' }),
            fieldName: 'DateUpdated',
            minWidth: isMobile ? 50 : 100,
            maxWidth: isMobile ? 80 : 150,
            isRowHeader: true,
            data: 'date',
            onRender: (item: IDocumentDraft) => {
                const myDate: string = Helper.getLocateDate(item.DateUpdated)


                return <span title={item.DateUpdated !== null ? myDate : ""}>
                    {item.DateUpdated !== null ? myDate : null}
                </span>;
            },
            isPadded: true,
        },
        {
            key: 'description',
            name: t('title.description', { ns: 'draft' }),
            fieldName: 'Description',
            minWidth: isMobile ? 70 : 125,
            maxWidth: isMobile ? 100 : 300,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column6',
            name: t('title.action', { ns: 'draft' }),
            fieldName: 'Options',
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            onRender: (item: IDocumentDraft) => {
                return (
                    props.CurrentDraftId !== undefined && props.CurrentDraftId !== item.Id ?
                        <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                            menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                            role="button"
                            aria-haspopup
                            title={t('title.clickAction', { ns: 'draft' })}
                            aria-label={t('label.clickAction', { ns: 'draft' })}
                            styles={iconListButtonStyles}
                            menuProps={menuProps}
                        /></div>
                        : null
                )
            },
            isPadded: true,
        },
    ];

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: t('title.delete', { ns: 'draft' }),
            title: t('text.delete', { ns: 'draft' }),
            onClick: () => {
                deleteDraftDocument()
                _deselect()
            },
        },
    ];

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    const closeDraftModal = () => {
        _deselect()

        setDocumentDrafts([])

        setJsonBLOB(undefined)
        setDraftBLOB(undefined)
        setShowDocumentDrafts(false)
    }

    const _deselect = () => {
        if (!isMobile) {
            if (selectionDocumentDraft.getSelectedIndices().length >= 1) {
                selectionDocumentDraft.toggleIndexSelected(selectionDocumentDraft.getSelectedIndices()[0]);
            }
            selectionDocumentDraft.setAllSelected(false)
        }
        setShowDraftCustomForm(false)
    }

    return (
        <>
            <ActionButton onClick={() => { setShowDocumentDrafts(true) }}
                style={{
                    backgroundColor: "#fff", fontSize: "18px",
                    fontFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif"
                }}
                iconProps={{ iconName: 'FabricDocLibrary' }}>
                {isMobile ? null : t("Common.Button.Draft", { ns: 'common' })}
            </ActionButton>
            <Modal
                isOpen={showDocumentDrafts}
                isBlocking={true}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span>{t("Common.Button.Draft", { ns: 'common' })}</span>
                                    <IconButton
                                        styles={iconModalButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                        onClick={closeDraftModal}
                                        title={t('Common.Button.Close', { ns: 'common' })}
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                <div className="ms-Grid-col ms-sm12 ms-lg12">
                                    {loadingDrafts ?
                                        <Spinner size={SpinnerSize.medium} label={spinnerMessage} ariaLive="assertive" /> :
                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "50vh" }}>
                                            <Stack tokens={stackTokens}>
                                                <MarqueeSelection selection={selectionDocumentDraft}>
                                                    <DetailsList
                                                        items={documentDrafts}
                                                        compact={false}
                                                        columns={columns}
                                                        getKey={getKey}
                                                        setKey="set"
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        isHeaderVisible={true}
                                                        selection={selectionDocumentDraft}
                                                        selectionMode={SelectionMode.single}
                                                        selectionPreservedOnEmptyClick={false}
                                                        enterModalSelectionOnTouch={!isMobile}
                                                        checkboxVisibility={CheckboxVisibility.hidden}
                                                    />
                                                </MarqueeSelection>
                                            </Stack>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <DefaultButton onClick={closeDraftModal} text={t('Common.Button.Close', { ns: 'common' })} title={t('Common.Button.Close', { ns: 'common' })} />
                            </DialogFooter>
                        </div>
                    </div>
                    <br />
                </div>
            </Modal>

            <Dialog
                hidden={hiddenLoadingDraft}
                dialogContentProps={{ title: "", showCloseButton: false }}
                modalProps={{ isAlert: true, }}
            >
                <Spinner size={SpinnerSize.medium} label={t('text.spinnerVerifying', { ns: 'common' })}
                    ariaLive="assertive" />
            </Dialog>

            {showDraftCustomForm && <DraftCustomForm
                loadDocumentDrafts={loadDocumentDrafts}
                onCreateUpdateDraft={props.onCreateUpdateDraft}
                documentDraftSelected={documentDraftSelected}
                jsonBLOB={jsonBLOB}
                draftBLOB={draftBLOB}
                clearSelection={_deselect}
                closeDraftModal={closeDraftModal}
            />
            }
        </>
    )
}

export default DraftList