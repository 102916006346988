import React, {useEffect, useState} from 'react';
import { Stack, IStackStyles, IStackProps } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import Helper, {
	DocumentListRequest,
	ICUManageClientPlansRequest, IDocumentOnlyAudienceRequest,
	IPlan,
	IRequest,
	ITemplateLayoutRequest,
	Registration
} from '../../Helpers/Helper';
import {IMessageBarStyles, Link, MessageBar, Separator, Text} from 'office-ui-fabric-react';
import '../../Custom.css';
import {INavLinkGroup, Label, Spinner, SpinnerSize, StackItem} from '@fluentui/react';
import {useTranslation} from "react-i18next";
import {useBranding} from "../../hooks/useBranding";
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import useAuth from '../../hooks/useAuth';
import HttpRequest from '../../services/HttpRequest';
import axios from 'axios';
import CustomerSupport from "../CustomerSupport";
import ManageClientPlansService, {ICreateUpdateManageClientPlansRequest} from "../../services/ManageClientPlans";
import MerchantService from "../../services/Merchant";
import BillingStateService, {ICreateUpdateOwnerNameRequest} from "../../services/BillingState";

initializeIcons(/* optional base url */);

const stackTokens = { childrenGap: 50 };
const stackStyles: Partial<IStackStyles> = { root: { width: "100%", height: "100%", paddingRight: "10px" } }
const columnProps: Partial<IStackProps> = {
	tokens: { childrenGap: 15 },
	styles: { root: { width: "100%" } },
}
const columnPropsInputOverride: Partial<IStackProps> = {
	tokens: { childrenGap: 15 },
	styles: { root: { width: "80px" } },
}
const messageBarFailedStyles: IMessageBarStyles = {
	root: {
		backgroundColor: "#ffd5d5"
	}
};

let isChecked = false
function RegistrationAuth0(props: any) {
	const { branding } = useBranding()
	const [activationGuid, setActivationGuid] = useState("")
	const [disableValidation, setDisableValidation] = useState(true)
	const [ispolicy, setIsPolicy] = useState(false)
	const [isApply, setisApply] = React.useState(false);
	const [openContactUs, setOpenContactUs] = useState(false)
	const [resendActivationCode, setResendActivationCode] = useState(false)
	const [showMessage, setShowMessage] = useState(false)
	const [showSuccessMessage, setShowSuccessMessage] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [spinnerText, setSpinnerText] = useState("")
	const [message, setMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [statusOfMessage, setStatusOfMessage] = useState<StatusEnum>(StatusEnum.Info);
	const [planList, setPlanList] = useState<IPlan[]>([]);
	const [MerchantId, setMerchantId] = useState(0)
    const { t, i18n } = useTranslation(['common', 'registration'])
	const { isAuthenticated } = useAuth();
	
	const reqManageClientPlan: ICreateUpdateManageClientPlansRequest = {
		Id: -1,
		OwnerName: Helper.getUsername(),
		Active: true,
		Comment: "NA",
		ContactEmailAddress: Helper.getUsername(),
		ContactFirstName: "NA",
		ContactLastName: "NA",
		ContactPhoneNumber: "1111111111",
		OrganizationAddress: "NA",
		OrganizationCity: "NA",
		OrganizationCountry: "NA",
		OrganizationName: "NA",
		OrganizationPostalCode: "NA",
		SubscriptionId: "-1",
		BillingPlanId: -1,
		OrganizationState: "NA",
		Operation: "update",
		Updated: Date.now().toString(),
		Created: Date.now().toString(),
	}

	useEffect(() => {
		 i18n.changeLanguage(navigator.language.split("-")[0]);
		setResendActivationCode(false)
		setSuccessMessage("")
		setShowSuccessMessage(false)
		setStatusOfMessage(StatusEnum.Info)
	}, []);
	
	const validateGuid = () => {
		setIsLoading(true)
		props.hideLogOut(true)
		setSpinnerText(t('text.spinner', { ns: 'common'}))
		setisApply(true)
		let reg = new Registration()

		reg.email = props.email
		reg.firstName = props.firstName
		reg.lastName = props.lastName
		reg.timeZone = props.timeZone
		reg.activationGuid = activationGuid
		reg.culture = props.culture
		
		setSpinnerText(t('text.spinnerValidating', { ns: 'registration'}))
		Helper.setRegistration(reg).then(async r => {
			setSpinnerText(t('text.spinnerValidated', { ns: 'registration'}))
			if (r.Success === false) {
				setSpinnerText(t('text.spinnerError', { ns: 'registration'}))
				setisApply(false)
				setShowMessage(true)
				setMessage(r.ExceptionMessage)
				setTimeout(function () {
                    setShowMessage(false)
                    setSpinnerText("")
                    setisApply(false)
                    setIsLoading(false)
				}, 5000);
				return
			}
			else if (r.UserStatus === "REGISTERED") {


				//This call is needed because the user is valid (Auth0Valid),
				//but no token until confirm account
				const headers = await HttpRequest.getAxiosHeaders();
				const { data: user } = await axios.get("isAuthenticated", {
					method: "GET",
					headers: headers
				})

				if (user.token !== null) {
					sessionStorage.setItem("x-identity", user.token)
				}
				
				setSpinnerText(t('text.spinnerRegistered', { ns: 'registration'}))

				var userName = Helper.getUsername()
				var defaultAccount = Helper.getDefaultAccount()
				var sessionKey = Helper.getSessionKey()
				
				
				if (userName == undefined || userName == null) {
					sessionStorage.setItem("username", props.authUser?.email!)
				}
				setisApply(true)
			
				await updateProfile(reg)

				await loadPlan()
		
				
				// --------------------------------------------
				let site = await Helper.getSite()
				// ---------------------------------------------
				// Audiences

				const req: IDocumentOnlyAudienceRequest = {
					UserName: userName,
					SessionKey: sessionKey,
					AccountId: defaultAccount,
					ApiKey: "-1",
					Autoprovision: true,
					FromRegistration: true,
					Path: site,
					AudienceMasterId: "-1"
				}
				await Helper.GetDocumentOnlyAudienceDetailByUserName(req).then(data => {

					let req = new DocumentListRequest()
					req.UserName = userName
					req.SessionKey = sessionKey
					req.AccountId = defaultAccount
					req.PageNo = "1"
					req.RecordsPerPage = "1"
					req.Assigned = true

					 Helper.getDocumentList(req)
						.then(documents => {

							if(Array.isArray(data) && Array.isArray(documents)) {
								if(data.filter(item => item.Owner === false).length === 0 && documents.length === 0  && branding.createExampleApplication)
								{
									sessionStorage.setItem("importExternalPackage","true")
									sessionStorage.setItem("importExamples", branding.exampleUniqueReferenceToImport)									
								}								
							}

							props.onClose()
							setisApply(false)

						})
						.catch(error => {
							console.log("error", error)
						})					
					
				})				
			}
		})
	}

	const checkValidationCodeLength = () => {

		if (isChecked) {
			setDisableValidation(false)
		}
		else {
			setDisableValidation(true)
		}
	}

	const checkRequiredfields = (step: string) => {
		if (step === "405") {
			
			if (isChecked) {
				setDisableValidation(false)
			} else {
				setDisableValidation(true)
			}
		}
		return false
	}

	/**
	 * Resend Activation code
	 */
	const resendCode = () => {
		setResendActivationCode(true)
		Helper.resendCode(props.email).then(
			(data) => {
				if (data == null) {
					setStatusOfMessage(StatusEnum.Error)
					setSuccessMessage(t('message.error', {ns: 'common', Error: t("messages.resendActivationCodeError",{ns: 'registration'})}))
					setShowSuccessMessage(true)
				} else {
					setStatusOfMessage(StatusEnum.Info)
					setSuccessMessage(t('messages.resendActivationCode', {ns: 'registration'}))
					setShowSuccessMessage(true)
				}
			}
		).catch((error) => {
			setStatusOfMessage(StatusEnum.Error)
			setSuccessMessage(t('message.error', {ns: 'common', Error: error}))
			setShowSuccessMessage(true)
		})
			.finally(
				() => {
					setResendActivationCode(false)
				}
			)
	}
	
	const _openSupportForm = () => {
		setOpenContactUs(true)
	}
	const hideContactUs = () => {
		setOpenContactUs(false)
	}
	const getTermsOfServiceLabel = () => {
		return t('text.readTerms', { ns: 'registration'});
	}

	const _renderLabelWithLink = () => {
		return (
			<span>
				{getTermsOfServiceLabel()} 
				<Link href="/toc.html" target="_blank" title={t('title.terms', { ns: 'registration'})}> {t('title.terms', { ns: 'registration'})}</Link>.
			</span>
		);
	}
	
	const updateProfile = async (req: Registration) => {

		const site = await Helper.getSite()!
		const userName = Helper.getUsername()

		var res = await Helper.authenticate(Helper.getUsername(), site)
		if (res!.hasOwnProperty('SessionKey')) {

			const request = {
				UserName: userName,
				SK: Helper.getSessionKey(),
				AccountId: Helper.getDefaultAccount(),
				Path: site,
				Autoprovision: true,
				Profile: [{
					"UserName": userName,
					"ProfileInformation": [{
						"Key": "title",
						"Value": req.title
					}, {
						"Key": "givenName",
						"Value": req.firstName !== undefined && req.firstName.trim().length > 0 ? req.firstName : ""
					}, {
						"Key": "middleName",
						"Value": req.middleName !== undefined && req.middleName.trim().length > 0 ? req.middleName : ""
					}, {
						"Key": "sn",
						"Value": req.lastName !== undefined && req.lastName.trim().length > 0 ? req.lastName : ""
					}, {
						"Key": "TimeZonePreference",
						"Value": req.timeZone
					},
						{
							"Key": "SelectedCulture",
							"Value": req.culture
						}
					]
				}]
			}

			let {data}: any = await HttpRequest.postWithSession('UpdateUserProfile', request)
			const result = data

			if (result.Success) {

			}
		}
	}

	const loadPlan = async () => {
		setSpinnerText(t('text.spinnerSettingPlan', { ns: 'registration'}))
		var site = await Helper.getSite()!
		var res = await Helper.authenticate(Helper.getUsername(), site)
		if (res!.hasOwnProperty('SessionKey')) {
			const req: IRequest = {
				UserName: Helper.getUsername(),
				SK: Helper.getSessionKey(),
				AccountId: Helper.getDefaultAccount(),
				ApiKey: "-1",
				Autoprovision: true,
				Path: site,
			}
			
            setSpinnerText(t('text.spinnerConfiguringPlan', { ns: 'registration' }))
            
			await loadMerchant()
			// if (sitelocal !== undefined) {
				await Helper.getAllPlan(req).then(async data => {
					setPlanList(data)
					if (data.length !== undefined && data.length > 0) {
						let DefaultPlan = await Helper.getDefaultPlan()
						let planD = data.filter(x => x.Name.toLowerCase() === DefaultPlan)[0]

						if (planD.Id > 0) {
							await InitializeManageClientPlant(planD.Id)
						}
					}
				})
			// }
		}
	}
	const InitializeManageClientPlant = async (_defaultPlanId: number) => {

		setSpinnerText(t('text.spinnerFinishConfiguringPlan', {ns: 'registration'}))

		reqManageClientPlan.Id = -1
		reqManageClientPlan.Operation = "create"
		reqManageClientPlan.BillingPlanId = _defaultPlanId
		reqManageClientPlan.ContactFirstName = props.firstName
		reqManageClientPlan.ContactLastName = props.lastName
		reqManageClientPlan.OrganizationName = props.firstName + " " + props.lastName

		await ManageClientPlansService.CreateUpdateManageClientPlansOwnerName(reqManageClientPlan)
			.then(async (data) => {
				reqManageClientPlan.Id = Number(data.Id)
				reqManageClientPlan.Operation = "update"
				reqManageClientPlan.Active = true
				await ManageClientPlansService.CreateUpdateManageClientPlansOwnerName(reqManageClientPlan)
					.then(() => {
						createUpdateBillingState("NA")
					})
					.catch((error) => {
						createUpdateBillingState(error.message ? error.message.toString() : error.toString())
					})
					.finally()

			})
			.catch()
			.finally()
	}

	async function createUpdateBillingState(error: string) {
		setSpinnerText(t('text.spinnerActivatingPlan', {ns: 'registration'}))
		setisApply(true)

		const request: ICreateUpdateOwnerNameRequest = {
			Active: reqManageClientPlan.Active,
			BillingClientId: reqManageClientPlan.Id,
			BillingMerchantId: MerchantId,
			BillingPlanId: reqManageClientPlan.BillingPlanId,
			TransactionCode: "Free Plan",
			TransactionDescription: "Free Plan",
			TransactionError: error,
			TransactionId: "-1",
			TransactionName: "Free Plan",
			Operation: "createUpdate",
			NextDueDate: undefined,
			OwnerName: Helper.getUsername()
		}

		await BillingStateService.CreateUpdateOwnerName(request)
			.then((data) => {
				setSpinnerText(t('text.spinnerActivatedPlan', {ns: 'registration'}))
			})
			.catch((error) => {
			})
			.finally(() => {
				setisApply(false)
			})
	}

	const loadMerchant = async () => {
		return await MerchantService.GetAllMerchant()
			.then((data) => {
				if (data.length > 0) {
					const _MerchantActive = data.filter(s => s.Active);
					setMerchantId(Number(_MerchantActive[0].Id))
				}
			})
			.catch()
			.finally()
	}

	return (
		<>
			{showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
			{showSuccessMessage &&
				<StatusMessage status={statusOfMessage} hasTimer={true}
							   truncated={true}
							   isMultiline={true}
							   dismiss={true}
							   setShowMessage={setShowSuccessMessage}>
					<div>{successMessage}</div>
				</StatusMessage>
			}
			{!isLoading ?
                    
					<Stack horizontal tokens={stackTokens} styles={stackStyles}>
						<Stack {...columnProps}>
							<div>
								<p>{t('text.messageCode1', { ns: 'registration'})}</p>
								<p>{t('text.messageCode2', { ns: 'registration'})}</p>
								<Stack horizontal styles={{root: {alignItems: "center"}}}>
									<StackItem {...columnPropsInputOverride}>
										<TextField
											required
											disabled={resendActivationCode}
											title={t('title.activationCode', {ns: 'registration'})}
											onMouseLeave={checkValidationCodeLength}
											onChange={(_, v) => {
												setActivationGuid(v!);
												checkRequiredfields(props.step)
											}}
											onBlur={() => {
												checkRequiredfields(props.step)
											}}/>
									</StackItem>
									{resendActivationCode &&
										<StackItem styles={{root: {paddingLeft: 16}}}>
											<Spinner size={SpinnerSize.medium}
													 title={t('text.spinnerSending', {ns: 'common'})}
													 labelPosition="right"
													 label={t('text.spinnerSending', {ns: 'common'})}
													 ariaLive="assertive"/>
										</StackItem>
									}
								</Stack>

								<Text style={{paddingTop: "5px"}}>
									<Link href="" onClick={resendCode} disabled={resendActivationCode} > 
										{t('text.resendActivationCode', {ns: 'registration'})}
									</Link>									
								</Text>
			
							</div>
							<div>
								<div style={{ float: 'left' }}>
									<Checkbox onRenderLabel={_renderLabelWithLink}
										title={t('title.readTerms', { ns: 'registration'})}
										checked={ispolicy}
										onChange={(_, v) => {
											isChecked = v!; setIsPolicy(isChecked)
											checkRequiredfields(props.step)
										}} />
								</div>
							</div>
							{!isApply ?
								<Text style={{ paddingBottom: "5px" }}>
									<PrimaryButton text={t('text.btnValidate', { ns: 'registration'})} onClick={validateGuid} disabled={disableValidation || activationGuid == ""} />
								</Text>
								:
								<div >
									<br />
									<Spinner size={SpinnerSize.large} label={t('text.spinnerValidating', { ns: 'registration'})} ariaLive="assertive" />
								</div>
							}
							<div>
								<div style={{ float: 'left', paddingBottom:"20px" }}>
									<span style={{ fontSize: "14px" }}>{t('text.contact', { ns: 'registration'})} <a href="#" onClick={(e) => { e.preventDefault(); _openSupportForm() }}>support@kimdocument.com</a></span>
								</div>
							</div>
						</Stack>

						{/*region Customer Support*/}
						{openContactUs &&
							<CustomerSupport showModal={openContactUs} setShowModal={hideContactUs} defaultSubject={"Help with registration"}/>
						}
						{/*endregion*/}
						
					</Stack>
                    :
                    <Stack>
                        <Label />
                        <Label />
                        <Label />
                        <Spinner size={SpinnerSize.medium} label={spinnerText} ariaLive="assertive" />
                    </Stack>
                }
		</>
	);
}

export default RegistrationAuth0