import React, { useEffect, useState } from 'react';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { IconButton, } from '@fluentui/react/lib/Button';
import { Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { Link } from '@fluentui/react';
import Helper, { IDocumentOnlyListClon, ITemplateSystem, TemplateListRequest } from '../../Helpers/Helper';
import '../../Custom.css';
import { Separator } from '@fluentui/react/lib/Separator';
import { IModalStyles } from "@fluentui/react/lib/Modal"
import { getTheme, mergeStyleSets, FontWeights, getFocusStyle } from "@fluentui/react/lib/Styling";
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import {
    DetailsListLayoutMode,
    SelectionMode,
    IContextualMenuItem,
    IContextualMenuProps,
    Dropdown,
    IDropdownStyles,
    IDropdownOption,
    MessageBar,
    IMessageBarStyles,
    MessageBarType,
    StackItem
} from '@fluentui/react';
import { useConstCallback } from '@uifabric/react-hooks';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import PreviewLayout from './PreviewLayout';
import PreviewTemplate from './PreviewTemplate';
import PreviewScreen from '../Preview/PreviewScreen';
import {useTranslation} from "react-i18next";
import {useBranding} from "../../hooks/useBranding";
import ListService, {IClonListMasterRequest, IClonListMasterResponse} from "../../services/List";
import HttpRequest from "../../services/HttpRequest";
const theme = getTheme();
const { palette, fonts, semanticColors } = theme;
  const iconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    
  };
  const modalFormStyles: IModalStyles = {
    main: {
      width: '50vw',
      height: '65vh'
    },
    root: {
  
    },
    scrollableContent: {},
    layer: {},
    keyboardMoveIcon: {},
    keyboardMoveIconContainer: {}
  }
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackTokens = { childrenGap: 15 };


const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
};

const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};

function TemplateSystem(props: any) {
    const { branding } = useBranding()
    const KIM_BUSINESS = 'Kim Business Form'
    const KIM_CONSUMER = 'Kim Consumer Form'
    const [templateArea, setTemplateArea] = useState(KIM_BUSINESS)
    const templatesSystem: ITemplateSystem[] = []
    const [templateSystemList, setTemplateSystemList] = useState(templatesSystem)
    const emptyTemplate: ITemplateSystem = {
        TemplateId: "",
        AccountId: "",
        ButtonText: "",
        Category: "",
        Culture: "",
        Description: "",
        Path: "",
        DisplayName: "",
        EmailMoniker: ""
    }
    const [templateSystem, settemplateSystem] = useState(emptyTemplate)
    let unfilteredList: ITemplateSystem[] = []
    const [mQuery, setMQuery] = React.useState<any>({
        matches: window.innerWidth > 639 ? true : false,
    });

    const [isCreating, setIsCreating] = useState(false)
    const [showPreviewLayout, setShowPreviewLayout] = useState(false)
    const [showPreviewTemplate, setShowPreviewTemplate] = useState(false)
    const [templateName, setTemplateName] = useState("")
    const [templateCategory, setTemplateCategory] = useState("")
    const [templateId, setTemplateId] = useState("")

    const [showSuccess, setShowSuccess] = useState(false)
    const [showMessageError, setshowMessageError] = useState(false)
    const [message, setMessage] = useState("");
    const {t} = useTranslation(['common', 'workspace']);

    const contentTemplateSystemStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        itemCellSmall: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                background: palette.white,
            },
        ],
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                selectors: {
                    '&:hover': { background: palette.neutralLight },
                },
                background: palette.white,
            },
        ],
        itemCellAttachment: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',

                background: palette.white,
            },
        ],
        itemImage: {
            flexShrink: 0,
        },
        itemContent: {
            marginLeft: 10,
            overflow: 'hidden',
            flexGrow: 1,
        },
        itemName: [
            fonts.xLarge,
            {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontFamily: "Arial",
                fontWeight: "bold",
                color: branding.theme.palette.themePrimary
            },
        ],
        itemSecondary: {
            fontFamily: "Calibri",
            fontSize: fonts.large.fontSize
        },
        itemIndex: {
            fontSize: fonts.small.fontSize,
            color: palette.neutralTertiary,
            marginBottom: 10,
        },
        itemIndexDate: {
            fontSize: fonts.small.fontSize,
            color: palette.neutralTertiary,
            marginBottom: 10,
            textAlign: "right"
        },
        itemNameSmall: [
            fonts.medium,
            {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontFamily: "Arial",
                fontWeight: "bold",
                color: branding.theme.palette.themePrimary
            },
        ],
        itemSecondarySmall: {
            fontFamily: "Calibri",
            fontSize: fonts.small.fontSize
        },
        itemSecondarySmallHeader: {
            fontFamily: "Calibri",
            fontSize: fonts.small.fontSize,
            color: branding.theme.palette.themePrimary,
            fontWeight: "bold"
        },
        body: {
            width: '100%',
            height: '85%',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        buttonArea: {

        },
        callout: {
            maxWidth: 300,
        },
        inner: {
            height: '100%',
            padding: '0 24px 20px',
        }
    });
    const classNames = mergeStyleSets({
        hideMenu: {
            display: 'none'
        },
        showMenu: {
            display: 'inline'
        },
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
            },
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
            color: 'rgb(96, 94, 92)'
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },

        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        bodyNote: {
            width: '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
    });

    
    useEffect(() => {

        updateTemplateList()
    }, [props.showTemplateSystem])

    const onClose = () => {
        props.toggleTemplateSystem(false)
        props.cancelStep()
    }

    const cloneTemplate = () => {
        //setIsCloning(true)
        setIsCreating(true)
        goToClone()
    }

    const goToClone = async () => {
        const username = Helper.getUsername();
        await Helper.CreateExample(templateSystem.TemplateId, username, true).then(data => {
            if (data.Success == "true") {
                //setShowSuccess(true)
                //setshowMessageError(false)
                //setnewtemplateId(data.Result)
                //setSuccessmessage("the application was cloned successfully!")
                goToCloneList(data.Result)
            }
            else {
                //setshowMessageError(true)
                //setMessage("An error occurred:     " + data.ExceptionMessage)
                //setTimeout(function () {
                //    setshowMessageError(false)
                //    hideModal()
                //}, 6000);
            }
        })
    }

    const goToCloneList = async (newTemplateId: string) => {

        const request: IClonListMasterRequest = {
            IsExample: true,
            NewtemplateId: newTemplateId,
            templateId: templateSystem.TemplateId.toString(),
            userEmail: Helper.getUsername()
        }

        await ListService.ClonListMaster(request)
            .then((data) => {
                saveLayoutOnStateManagement(newTemplateId, data)
            })
            .catch()
            .finally()

    }

    async function saveLayoutOnStateManagement(newTemplateId: string, LayoutResponse: IClonListMasterResponse) {
        const site = await Helper.getSite()
        const sessionKey = Helper.getSessionKey()
        const accountId = Helper.getDefaultAccount()
        const userName = Helper.getUsername()
        const templateLayout = {
            TemplateId: newTemplateId,
            Owner: userName,
            Layout: LayoutResponse.Layout,
            Pages: LayoutResponse.Pages,
            Released: false
        };

        const request = {
            TemplateId: newTemplateId,
            User: "",
            Template: templateLayout
        }

        let {data}: any = await HttpRequest.postWithSession('statemanagement', request)
        
        if (data.Status == "Success") {
            setMessage(`Template ${templateName} has been added to your applications.  Expand ${templateCategory} to view.`)
            setShowSuccess(true)
            setshowMessageError(false)
            setTimeout(function () {
                setShowSuccess(false)
                setshowMessageError(false)
            }, 7000);
        }
        else {
            setMessage("Something went wrong while saving the layout.")
            setshowMessageError(true)
            setShowSuccess(false)
            setTimeout(function () {
                setShowSuccess(false)
                setshowMessageError(false)
            }, 5000);
        }
        props.reloadApp(site)
        clearSelection()
        setIsCreating(false)
    }


    const menuItems: IContextualMenuItem[] = [
        {
            key: 'create',
            text: 'Create',
            title: 'Create example in your library.',
            onClick: () => {
                cloneTemplate()
            }
        },
    ]

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems
    };

    const onTemplateAreaChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): Promise<any> => {
        setTemplateArea(option!.key.toString())
        filterList(option!.key.toString())
    };

    const filterList = useConstCallback((area:string) => {
        var filterList = unfilteredList.filter(template => template.EmailMoniker === area)
        setTemplateSystemList(filterList)

        clearSelection()
    })

    const clearSelection = useConstCallback(() => {
        if (selectionItem.getSelectedIndices().length >= 1)
            selectionItem.setAllSelected(false)

        if (selectionItem.getSelectedIndices().length >= 1)
            selectionItem.toggleIndexSelected(selectionItem.getSelectedIndices()[0]);
    })

    const columns: IColumn[] = [
    {
        key: 'column1',
        name: 'File Type',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: '',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'name',
        minWidth: 20,
        maxWidth: 20,
            onRender: (item: ITemplateSystem) => {
                return <IconButton iconProps={{ iconName: 'pdf' }}
                    style={{ cursor: 'pointer' }}
                    key={`file ${item.TemplateId}`}
                    title={"This example's document preview."}
                    className={classNames.fileIconImg} alt={'Preview'}
                    onClick={() => {
                        setTemplateId("")
                        openPreviewTemplate(item)
                    }}
                />
        },
    },
    {
        key: 'column4',
        name: 'Form Wizard',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: 'Form Wizard',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'name',
        minWidth: 20,
        maxWidth: 20,
        onRender: (item: ITemplateSystem) => {
            return <IconButton iconProps={{ iconName: 'officeFormsLogo' }}
                style={{ cursor: 'pointer' }}
                key={`wizard ${item.TemplateId}`}
                title={"Click to preview document form wizard."}
                className={classNames.fileIconImg} alt={'Update'}
                onClick={() => {
                    openPreviewLayout(item)
                }}
            />
        },
    },
    {
      key: 'column2',
      name: 'Category',
      fieldName: 'Category',
      minWidth: 50,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onRender: (item: ITemplateSystem) => {
          return <a key={`cat ${item.TemplateId}`}  title={item.Description}>{item.Category}</a>;
      }
    },
    {
        key: 'column3',
        name: 'Description',
        fieldName: 'Description',
        minWidth: 200,
        maxWidth: 400,
        isMultiline: true,
        isRowHeader: true,
        isResizable: false,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item: ITemplateSystem) => {
            return <a key={`desc ${item.TemplateId}`} title={item.Description}>{item.Description}</a>;
        }
    },
    {
        key: 'column8',
        name: 'Action',
        fieldName: 'Options',
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
        onRender: (item: any) => {
            return <div
                key={`action ${item.TemplateId}`}
                    style={{ width:'40px', display: 'block', textAlign: 'right' }}><IconButton
                menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                role="button"
                aria-haspopup
                aria-label="Show actions"
                styles={iconButtonStyles}
                menuProps={menuProps}
                title={"Click to select from available actions."}
            /></div>
        },
        isPadded: true,
    },
    ]

    const getKey = (item: string, index?: number): string => {
        if (item !== undefined)
          return item.toString()

        return ""
    }

    const updateTemplateList = useConstCallback(() => {
        let request = new TemplateListRequest()
        request.UserName = Helper.getUsername()
        request.SessionKey = Helper.getSessionKey()
        request.AccountId = Helper.getDefaultAccount()
        Helper.GetExampleTemplates(request).then(r => {
            unfilteredList = r;
            filterList(KIM_BUSINESS)
        })
    })

    const onSelectionItemChange = () => {
        const selectionCount = selectionItem.getSelectedCount();
        switch (selectionCount) {
            case 0:
                settemplateSystem(emptyTemplate)
                setTemplateName("")
                setTemplateCategory("")
                setTemplateId("")
            break;
            case 1:
                settemplateSystem(selectionItem.getSelection()[0] as ITemplateSystem)
                setTemplateName((selectionItem.getSelection()[0] as ITemplateSystem).DisplayName)
                setTemplateCategory((selectionItem.getSelection()[0] as ITemplateSystem).Category)
                setTemplateId((selectionItem.getSelection()[0] as ITemplateSystem).TemplateId)
            break;
            default:
                settemplateSystem(emptyTemplate)
                setTemplateId("")
                setTemplateName("")
                setTemplateCategory("")
            break;
        }
    }

    const selectionItem = new Selection({
        onSelectionChanged: onSelectionItemChange,
    });

    function openPreviewLayout(item: ITemplateSystem) {
        setTemplateId(item.TemplateId)
        setShowPreviewLayout(true)
    }

    function openPreviewTemplate(item: ITemplateSystem) {
        setTemplateId(item.TemplateId)
        setShowPreviewTemplate(true)
    }

    const closePreviewLayout = useConstCallback((open: boolean) => {
        clearSelection()
        setShowPreviewLayout(open)
    })

    const closePreviewTemplate = useConstCallback((open: boolean) => {
        clearSelection()
        setShowPreviewTemplate(open)
    })

return (
    <>    
        <Separator />
        <Stack tokens={{childrenGap: 8}}>
            <StackItem>
                <Text block variant={"large"} styles={{root: { marginBottom: 32}}}>{t('text.examplesTitle', { ns: 'workspace'})}</Text>
            </StackItem>
            <StackItem>
                <Text block variant={"medium"}>{t('text.examplesText1', { ns: 'workspace'})}</Text>
            </StackItem>
            <StackItem>
                <Text block variant={"medium"}>
                    <ol>
                        <li>
                            {t('text.examplesText2', { ns: 'workspace'})}
                            {" "}
                            <Link href="https://info.kimdocument.com/self-service" underline target="_blank">{t('text.examplesLink1', { ns: 'workspace'})}</Link>
                            {" "}
                            {t('text.examplesText3', { ns: 'workspace'})}
                        
                        </li>
                        <li>
                            {t('text.examplesText4', { ns: 'workspace'})}
                            {" "}
                            <Link href="https://info.kimdocument.com/managing-your-kim-document-application" underline target="_blank">{t('text.examplesLink2', { ns: 'workspace'})}</Link>
                            {" "}
                            {t('text.examplesText5', { ns: 'workspace'})}
                        </li>
                    </ol>
                </Text>
            </StackItem>
          
        </Stack>
    </>
  );
}

export default TemplateSystem


