import {
    FontWeights,
    IButtonStyles,
    IGroup,
    IGroupHeaderStyles,
    IMessageBarStyles,
    ISearchBoxStyles,
    ISpinnerStyles,
    IStackStyles,
    ITextStyles,
    ILabelStyles
} from "@fluentui/react";
import {Theme} from "@fluentui/react-theme-provider";
import {mergeStyles, mergeStyleSets} from "@fluentui/react/lib/Styling";

export const spinnerLoadingStyles = (theme: Theme): ISpinnerStyles => {
    return {
        root: {marginTop: 8},
        label: {color: theme.palette.white},
        circle: {borderTopColor: theme.palette.white}
    }
}

export const messageBarStyles = (theme: Theme): IMessageBarStyles => {
    return {
        root: {
            marginTop: 24,
            width: 320,
            color: theme.palette.white,
            backgroundColor: theme.palette.themeDark
        },
        icon: {
            color: theme.palette.white,
        }
    }
}

export const buttonStyles = (theme: Theme): IButtonStyles => {
    return {
        root: {
            color: theme.palette.white,
            backgroundColor: theme.palette.themeDarker
        },
        labelChecked: {
            color: theme.palette.themeDarker
        },
        labelHovered: {
            color: theme.palette.themeDarker
        }
    }
}

export const stackLoading = (theme: Theme, isLoaded: boolean): IStackStyles => {
    return {
        root: {
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            overflow: "auto",
            backgroundColor: isLoaded ? theme.palette.white : theme.palette.themeDarker,
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease"
        }
    }
}

export const stackContent = (): IStackStyles => {
    return {
        root: {
            overflow: "hidden",
            overflowY: "visible"
        }
    }
}

export const stackEmpty = (): IStackStyles => {
    return {
        root: {
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center"
        }
    }
}

export const onRenderCellStyles = (theme: Theme): IStackStyles => {
    return {
        root: {
            padding: "8px 16px 8px 48px",
            cursor: "pointer",
            selectors: {":hover": {backgroundColor: theme.semanticColors.listItemBackgroundHovered}},
            borderBottom: `solid 2px ${theme.semanticColors.listItemBackgroundHovered}`

        }
    }
}

export const topBarStyles = (theme: Theme): IStackStyles => {
    return {
        root: {
            height: "auto",
            flexShrink: 0,
            alignItems: "center",
            boxShadow: theme.effects.elevation8,
            backgroundColor: theme.palette.themePrimary
        }
    }
}

export const detailsPanelStyles = (theme: Theme): IStackStyles => {
    return {
        root: {
            flexBasis: "320px",
            minWidth: "320px",
            height: "100%",
            borderLeft: `solid 1px ${theme.palette.neutralTertiaryAlt}`,
            backgroundColor: theme.semanticColors.listItemBackgroundHovered
        }
    }
}

export const labelDetailSectionStyles = (theme: Theme): ILabelStyles => {
    return {
        root: {
            borderBottom: `solid 2px ${theme.semanticColors.listItemBackgroundHovered}`,
            paddBottom: 4
        },

    }
}

export const textDetailSectionStyles = (): ITextStyles => {
    return {
        root: {
            display: "flex",
            paddingTop: 8,
            fontWeight: FontWeights.semibold
        }
    }
}

export const countStyles = (theme: Theme): ITextStyles => {
    return {
        root: {
            fontWeight: FontWeights.semibold,
            backgroundColor: theme.palette.themeLighter,
            color: theme.palette.themeDarker,
            padding: 8,
            borderRadius: 16,
            marginRight: 8
        }
    }
}

export const brandingStyles = (theme: Theme): ITextStyles => {
    return {
        root: {marginLeft: "16px", color: theme.palette.white}
    }
}

export const searchBoxStyles = (): ISearchBoxStyles => {
    return {
        root: {minWidth: "240px", width: "100%", maxWidth: "640px"}
    }
}

export const groupHeaderStyles = (theme: Theme, group?: IGroup): IGroupHeaderStyles => {
    return {
        check: {display: "none"},
        expand: {borderLeft: group?.isCollapsed ? "none" : `2px solid ${theme.palette.themeDark}`},

        title: {

            width: "100%",
            display: "flex",
            flexDirection: "row"
        },
        groupHeaderContainer: {
            borderBottom: `solid 2px ${theme.semanticColors.listItemBackgroundHovered}`,
        },
        headerCount: {},
        root: {
            boxShadow: theme.effects.elevation4
        },
        dropIcon: {},
        expandIsCollapsed: {}
    }
}

export const commandButtonStyles = (theme: Theme): IButtonStyles => {
    return {
        root: {
            color: theme.palette.white,
            backgroundColor: theme.palette.themeDarker
        },
        labelChecked: {
            color: theme.palette.white
        },
        labelHovered: {
            color: theme.palette.white
        },
        label: {
            color: theme.palette.white
        },
        icon: {
            color: theme.palette.themeLight
        },
        iconPressed: {
            color: theme.palette.themeLight
        },
        iconHovered: {
            color: theme.palette.themeLight
        },
        iconExpanded: {
            color: theme.palette.themeLight
        },
        menuIcon: {
            color: theme.palette.themeLight
        },
        menuIconExpanded: {
            color: theme.palette.themeLight
        }
    }
}

export const iconEmptystyles = (theme: Theme): string => {
    return mergeStyles({
        display: "flex",
        fontSize: 96,
        height: 96,
        width: 96,
        borderRadius: 100,
        padding: "24px",
        margin: "16px",
        backgroundColor: theme.semanticColors.listItemBackgroundHovered,
        color: theme.palette.white,
    })
}

export const nonShrinkingFooterStackStyles: IStackStyles = {
    root: {
        display: 'flex',
        height: 40,
        overflow: 'hidden',
        alignItems: "center",
    },
}

export interface IContentStyles {
    container: string
    header: string
}

export const contentDetailStyles = (theme: Theme): IContentStyles => {

    return mergeStyleSets({

        container: {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'stretch',
            minWidth: '320px',
            width: '100%',
            height: "100%"
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderBottom: `solid 1px ${theme.semanticColors.listItemBackgroundHovered}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '8px 8px 8px 16px',
            },
        ],

    })
}