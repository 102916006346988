import React, { useEffect, useState } from 'react';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import '../../Custom.css';
import { isMobile } from 'react-device-detect';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IconButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Checkbox, Dropdown } from '@fluentui/react';
import Helper, { DocumentOnlyReassignRecordRequest } from '../../Helpers/Helper';
import { useTranslation } from 'react-i18next';
import {useBranding} from "../../hooks/useBranding";


const theme = getTheme();

const iconButtonStylesPrompt = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
            ':disabled': {
                backgroundColor: theme.palette.white
            },
        },
    },
    
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

function ReassignRecords(props: any) {
    const { branding } = useBranding()
    const [selectedAudience,setSelectedAudience] = useState("")
    const [removeChangeMemberUserName, setRemoveChangeMemberUserName] = useState(false)
    const {t} = useTranslation(['common','audiencecomponent']);
    useEffect(() => {
        
     }, [props.isReassignRecordsVisible])

    const hideReassignRecordsModal = () => {
        props.setIsReassignRecordsVisible(false)
    }

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 150,
        },
        containerSaveModal: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: isMobile ? null : '30vw',
            height: '30vh',
        },
        body: {
            width: '80vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const assignRecords = async() => {
        const req: DocumentOnlyReassignRecordRequest = {
            AudienceId: +props.audienceId,
            SetMemberUserName: selectedAudience,
            ChangeMemberUserName: props.selectedAudienceUser,
            RemoveChangeMemberUserName: removeChangeMemberUserName
        }
        Helper.DocumentOnlyReassignRecord(req).then(data =>{
            hideReassignRecordsModal();
        })
    }

    function _onChangeEnabled(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
		setRemoveChangeMemberUserName(isChecked!)
	}

    return (
        <>
            <Modal
                isOpen={props.isReassignRecordsVisible}
                isBlocking={true}
                containerClassName={contentStyles.containerSaveModal}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id="Confirmation">{t("Audience.ReassignRecords.ReassignRecordsTitle", {ns: 'audiencecomponent'})}</span>
                                <IconButton
                                    styles={iconButtonStylesPrompt}
                                    iconProps={cancelIcon}
                                    ariaLabel={t("Common.Button.CloseTitle", {ns: 'common'})}
                                    onClick={hideReassignRecordsModal}
                                    title={t("Common.Button.Close", {ns: 'common'})}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg12">
                            <div className={contentStyles.bodyMail}>
                            {t("Audience.ReassignRecords.ReassignRecordsfrom", {ns: 'audiencecomponent'})} <strong>{props.selectedAudienceUser} </strong> {t("Audience.ReassignRecords.to", {ns: 'audiencecomponent'})} <Dropdown title={t("Audience.ReassignRecords.DropdownRecordsTitle", {ns: 'audiencecomponent'})} options={props.audienceOptions} onChange={(_, o) => { setSelectedAudience(o!.key.toString());  }}/>
                                <br></br>
                                <Checkbox id="removeChangeMemberUserName" label={t("Audience.ReassignRecords.RemoveChangeMemberUserName", {ns: 'audiencecomponent'})} checked={removeChangeMemberUserName} onChange={_onChangeEnabled} />
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={assignRecords} text= {t("Audience.Button.Reassign", {ns: 'audiencecomponent'})} title={t("Audience.ReassignRecords.ReassignRecordsTitle", {ns: 'audiencecomponent'})} />
                                <DefaultButton onClick={hideReassignRecordsModal} text={t("Audience.Button.No", {ns: 'audiencecomponent'})} title={t("Common.Button.Cancel", {ns: 'common'})} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ReassignRecords