import React, { CSSProperties, useEffect, useState } from 'react';
import { Panel, PanelType, } from '@fluentui/react/lib/Panel';
import { useConstCallback } from '@uifabric/react-hooks';
import { isMobile } from 'react-device-detect';
import { Icon, IContextualMenuItem, IContextualMenuProps, IIconStyles, IStackTokens, Link, Stack } from '@fluentui/react';
import '../../web.css'
import { set } from 'lodash';
import Helper from '../../Helpers/Helper';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react";
import { useBranding } from '../../hooks/useBranding';
import CustomerSupport from "../CustomerSupport";



function Help(props: any) {
    const [isOpen, setIsOpen] = React.useState(true);
    const [user, setUser] = React.useState("");
    const dismissPanel = useConstCallback(() => { setIsOpen(false); props.updateTemplate() });
    const [hash, setHash] = useState("");
    const [openContactUs, setOpenContactUs] = useState(false)
    const { branding, getAppSettings} = useBranding()
    
    const iconStyles: IIconStyles = {
        root: {
            fontSize: '18px',
            height: '18px',
            width: '18px',
            color: 'rgb(16, 110, 190)'
        },
    };
    const iconStyleVideos: IIconStyles = {
        root: {
            fontSize: '18px',
            height: '18px',
            width: '18px',
            color: 'rgb(64, 102, 146)'
        },
    };
    const imgHelp = mergeStyleSets({
        img: {
            '@media(max-width: 600px)': {
                width: '100%',
                height: 'auto',

            },
            display: 'block',
            marginBottom: '24px',
            marginTop: '24px'
        }
    });
    const img_Vertical: CSSProperties = {
        width: "35%",
        height: "55%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    };
    const img_Horizontal: CSSProperties = {
        width: "90%",
        height: "65%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    };
    const img_Medium_Sized_Horizontal: CSSProperties = {
        width: "60%",
        height: "65%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    };
    const img_Small_Horizontal: CSSProperties = {
        width: "25%",
        height: "65%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    };
    const text: CSSProperties = { marginRight: "6%", textAlign: "justify" }

    const img_SmaVertical: CSSProperties = {
        width: "25%",
        height: "55%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    };
    const litext: CSSProperties = { marginRight: "6%", textAlign: "justify", paddingBottom: "1%" }


    function getUser() {
        var url_string = window.location.href
        var url = new URL(url_string);
        var id = url.searchParams.get("id");
        var decoded = atob(id!);
        setUser(decoded!)
        let tagHel = sessionStorage.getItem("tagHelp")!
        if (sessionStorage.getItem("entityId") !== "Not In Teams" && (tagHel !== undefined && tagHel !== "")) {
            setHash("#" + tagHel!)
        }
        else if (window.location.hash !== "") {
            setHash(window.location.hash)

        }
        sessionStorage.removeItem("tagHelp")
    }

    useEffect(() => {
        getUser()
        getAppSettings()
    }, [])

    const getActive = (hash: string, location: string) => {
        if (hash === location) {
            return "active"
        }

        return ""
    }

    const setWindowsHash = (location: string) => {
        setHash(location)
    }
    function openNav() {
        document.getElementById("mySidepanel")!.style.width = "250px";
    }

    /* Set the width of the sidebar to 0 (hide it) */
    function closeNav() {
        document.getElementById("mySidepanel")!.style.width = "0";
    }

    const _openSupportForm = () => {
        setOpenContactUs(true)
    }

    const hideContactUs = () => {
        setOpenContactUs(false)
    }
 
    return (
        <div className="mainContainerWhite" >
            <div className="help__container">
                {!isMobile ?
                    <div className="help__navbar help__thumbnail" style={{ height: "130vh" }}>
                        <div className="help_thumbnail__content text-center">
                            <img style={{ width: "20%", height: "20%" }} src="https://ask.kim/wp-content/uploads/2018/02/kim-trans.png" />
                            <h2 className="headerText heading--secondary">Help Guide</h2>
                            <h3 className="headerText heading--secondary">Click Form Go and Kim Document are Kim Technologies Products</h3>
                        </div>
                        <div className="help_thumbnail__content_nav text-center">
                            <div className="scrollVisible" style={{ overflow: "auto", height: window.innerHeight - 200 }}>
                                <ul className="help_ul">
                                    <li ><a className={getActive(hash, "#home") + " help_li"} href="help#home" onClick={() => { setWindowsHash("#home") }} >Getting Started</a></li>
                                    <li ><a className={getActive(hash, "#manageapplication") + " help_li"} href="help#manageapplication" onClick={() => { setWindowsHash("#manageapplication") }} >Use Downloaded Template</a></li>
                                    <li ><a className={getActive(hash, "#workspace") + " help_li tab1"} href="help#workspace" onClick={() => { setWindowsHash("#workspace") }}>Workspace</a></li>
                                    <li ><a className={getActive(hash, "#applicationlibrary") + " help_li tab1"} href="help#applicationlibrary" onClick={() => { setWindowsHash("#applicationlibrary") }}>Applications</a></li>
                                    <li ><a className={getActive(hash, "#audiences") + " help_li tab1"} href="help#audiences" onClick={() => { setWindowsHash("#audiences") }}>Audiences</a></li>
                                    <li ><a className={getActive(hash, "#campaigns") + " help_li tab1"} href="help#campaigns" onClick={() => { setWindowsHash("#campaigns") }}>Campaigns</a></li>
                                    <li ><a className={getActive(hash, "#clone") + " help_li tab1"} href="help#clone" onClick={() => { setWindowsHash("#clone") }}>Clone</a></li>
                                    <li ><a className={getActive(hash, "#delete") + " help_li tab1"} href="help#delete" onClick={() => { setWindowsHash("#delete") }}>Delete</a></li>
                                    <li ><a className={getActive(hash, "#download") + " help_li tab1"} href="help#download" onClick={() => { setWindowsHash("#download") }}>Download</a></li>
                                    <li ><a className={getActive(hash, "#importexport") + " help_li tab1"} href="help#importexport" onClick={() => { setWindowsHash("#importexport") }}>Export</a></li>
                                    <li ><a className={getActive(hash, "#properties") + " help_li tab1"} href="help#properties" onClick={() => { setWindowsHash("#properties") }}>Properties</a></li>
                                    <li ><a className={getActive(hash, "#publishwebformlink") + " help_li tab1"} href="help#publishwebformlink" onClick={() => { setWindowsHash("#publishwebformlink") }}>Publish Webform Link</a></li>
                                    <li ><a className={getActive(hash, "#version") + " help_li tab1"} href="help#version" onClick={() => { setWindowsHash("#version") }}>Version</a></li>
                                    <li ><a className={getActive(hash, "#update") + " help_li tab1"} href="help#update" onClick={() => { setWindowsHash("#update") }}>Update</a></li>
                                    <li ><a className={getActive(hash, "#webformwwizard") + " help_li tab1"} href="help#webformwwizard" onClick={() => { setWindowsHash("#webformwwizard") }}>Webform Wizard</a></li>
                                    <li ><a className={getActive(hash, "#taggingdocument") + " help_li tab1"} href="help#taggingdocument" onClick={() => { setWindowsHash("#taggingdocument") }}>Tagging a document </a></li>
                                    <li ><a className={getActive(hash, "#uploadingdocument") + " help_li tab1"} href="help#uploadingdocument" onClick={() => { setWindowsHash("#uploadingdocument") }}>Uploading the Document and Creating the Application </a></li>
                                    <li ><a className={getActive(hash, "#configuringapplication") + " help_li tab1"} href="help#configuringapplication" onClick={() => { setWindowsHash("#configuringapplication") }}>Configuring an Application in the Webform Wizard</a></li>
                                    <li ><a className={getActive(hash, "#records") + " help_li tab1"} href="help#records" onClick={() => { setWindowsHash("#records") }}>Records</a></li>
                                    <li ><a className={getActive(hash, "#settings") + " help_li tab1"} href="help#settings" onClick={() => { setWindowsHash("#settings") }}>Settings </a></li>
                                    <li ><a className={getActive(hash, "#search") + " help_li tab1"} href="help#search" onClick={() => { setWindowsHash("#search") }}>Search</a></li>
                                    <li ><a className={getActive(hash, "#usermenu") + " help_li tab1"} href="help#usermenu" onClick={() => { setWindowsHash("#usermenu") }}>User Menu</a></li>
                                    <li ><a className={getActive(hash, "#webbrowserusermenu") + " help_li tab1"} href="help#webbrowserusermenu" onClick={() => { setWindowsHash("#webbrowserusermenu") }}>Web Browser User Menu</a></li>
                                    <li ><a className={getActive(hash, "#microsoftteamsusermenu") + " help_li tab1"} href="help#microsoftteamsusermenu" onClick={() => { setWindowsHash("#microsoftteamsusermenu") }}>Microsoft Teams User Menu</a></li>
                                    <li ><a className={getActive(hash, "#contactus") + " help_li"} href="help#contactus" onClick={() => { setWindowsHash("#contactus") }}>Get Support/Contact Us</a></li>

                                    {/* <li ><a className={getActive(hash, "#addnew") + " help_li tab1"} href="help#addnew" onClick={() => { setWindowsHash("#addnew") }}>Create New Application</a></li>
                                    <li ><a className={getActive(hash, "#settings") + " help_li tab1"} href="help#settings" onClick={() => { setWindowsHash("#settings") }}>Settings</a></li>
                                    <li ><a className={getActive(hash, "#subs") + " help_li"} href="help#subs" onClick={() => { setWindowsHash("#subs") }}>Subforms & Sub Records</a></li>
                                    <li ><a className={getActive(hash, "#activity") + " help_li"} href="help#activity" onClick={() => { setWindowsHash("#activity") }}>Your Activity</a></li>
                                    <li ><a className={getActive(hash, "#generalusecases") + " help_li"} href="help#generalusecases" onClick={() => { setWindowsHash("#generalusecases") }}>General Use Cases</a></li>                    
                                    <li ><a className={getActive(hash, "#automation") + " help_li"} href="help#automation" onClick={() => { setWindowsHash("#automation") }}>Application Automation</a></li> */}

                                    {/* <li ><a className={getActive(hash, "#howdoi") + " help_li"} href="help#howdoi" onClick={() => { setWindowsHash("#howdoi") }}>How Do I?</a></li> */}
                                    {/* <li ><a className={getActive(hash, "#howdoi") + " help_li"} href="help#howdoi" onClick={() => { setWindowsHash("#faqs") }}>FAQs</a></li> */}

                                    {/*{sessionStorage.getItem("entityId") === "Not In Teams" ?*/}
                                    {/*    <li ><a className={getActive(hash, "#videos") + " help_li"} href="help#videos" onClick={() => { setWindowsHash("#videos") }}>Videos</a></li>*/}
                                    {/*    :*/}
                                    {/*    null}*/}

                                </ul>
                            </div>
                        </div>
                        <div className="help__overlay"></div>
                    </div>
                    :
                    <>
                        <div id="mySidepanel" className="sidepanel">
                            <a href="javascript:void(0)" className="closebtn" onClick={() => { closeNav() }}>&times;</a>
                            <div className="scrollVisible" style={{ overflow: "auto", height: window.innerHeight - 90 }}>
                                <ul className="help_ul">
                                    <li ><a className={getActive(hash, "#home") + " help_li"} href="help#home" onClick={() => { setWindowsHash("#home") }} >Getting Started</a></li>
                                    <li ><a className={getActive(hash, "#manageapplication") + " help_li"} href="help#manageapplication" onClick={() => { setWindowsHash("#manageapplication") }} >Use Downloaded Template</a></li>
                                    <li ><a className={getActive(hash, "#workspace") + " help_li"} href="help#workspace" onClick={() => { setWindowsHash("#workspace") }}>Workspace</a></li>
                                    <li ><a className={getActive(hash, "#applicationlibrary") + " help_li tab1"} href="help#applicationlibrary" onClick={() => { setWindowsHash("#applicationlibrary") }}>Applications</a></li>
                                    <li ><a className={getActive(hash, "#audiences") + " help_li"} href="help#audiences" onClick={() => { setWindowsHash("#audiences") }}>Audiences</a></li>
                                    <li ><a className={getActive(hash, "#campaigns") + " help_li"} href="help#campaigns" onClick={() => { setWindowsHash("#campaigns") }}>Campaigns</a></li>
                                    <li ><a className={getActive(hash, "#clone") + " help_li"} href="help#clone" onClick={() => { setWindowsHash("#clone") }}>Clone</a></li>
                                    <li ><a className={getActive(hash, "#delete") + " help_li"} href="help#delete" onClick={() => { setWindowsHash("#delete") }}>Delete</a></li>
                                    <li ><a className={getActive(hash, "#download") + " help_li"} href="help#download" onClick={() => { setWindowsHash("#download") }}>Download</a></li>
                                    <li ><a className={getActive(hash, "#importexport") + " help_li tab1"} href="help#importexport" onClick={() => { setWindowsHash("#importexport") }}>Export</a></li>
                                    <li ><a className={getActive(hash, "#properties") + " help_li tab1"} href="help#properties" onClick={() => { setWindowsHash("#properties") }}>Properties</a></li>
                                    <li ><a className={getActive(hash, "#publishwebformlink") + " help_li tab1"} href="help#publishwebformlink" onClick={() => { setWindowsHash("#publishwebformlink") }}>Publish Webform Link</a></li>
                                    <li ><a className={getActive(hash, "#version") + " help_li tab1"} href="help#version" onClick={() => { setWindowsHash("#version") }}>Version</a></li>
                                    <li ><a className={getActive(hash, "#update") + " help_li tab1"} href="help#update" onClick={() => { setWindowsHash("#update") }}>Update</a></li>
                                    <li ><a className={getActive(hash, "#webformwwizard") + " help_li tab1"} href="help#webformwwizard" onClick={() => { setWindowsHash("#webformwwizard") }}>Webform Wizard</a></li>
                                    <li ><a className={getActive(hash, "#taggingdocument") + " help_li tab1"} href="help#taggingdocument" onClick={() => { setWindowsHash("#taggingdocument") }}>Tagging a document</a></li>
                                    <li ><a className={getActive(hash, "#uploadingdocument") + " help_li tab1"} href="help#uploadingdocument" onClick={() => { setWindowsHash("#uploadingdocument") }}>Uploading the Document and Creating the Application </a></li>
                                    <li ><a className={getActive(hash, "#configuringapplication") + " help_li tab1"} href="help#configuringapplication" onClick={() => { setWindowsHash("#configuringapplication") }}>Configuring an Application in the Webform Wizard</a></li>
                                    <li ><a className={getActive(hash, "#records") + " help_li tab1"} href="help#records" onClick={() => { setWindowsHash("#records") }}>Records</a></li>
                                    <li ><a className={getActive(hash, "#settings") + " help_li tab1"} href="help#settings" onClick={() => { setWindowsHash("#settings ") }}>Settings </a></li>
                                    <li ><a className={getActive(hash, "#search") + " help_li tab1"} href="help#search" onClick={() => { setWindowsHash("#search") }}>Search</a></li>
                                    <li ><a className={getActive(hash, "#usermenu") + " help_li tab1"} href="help#usermenu" onClick={() => { setWindowsHash("#usermenu") }}>User Menu</a></li>
                                    <li ><a className={getActive(hash, "#webbrowserusermenu") + " help_li tab1"} href="help#webbrowserusermenu" onClick={() => { setWindowsHash("#webbrowserusermenu") }}>Web Browser User Menu</a></li>
                                    <li ><a className={getActive(hash, "#microsoftteamsusermenu") + " help_li tab1"} href="help#microsoftteamsusermenu" onClick={() => { setWindowsHash("#microsoftteamsusermenu") }}>Microsoft Teams User Menu</a></li>

                                    {/* <li ><a className={getActive(hash, "#addnew") + " help_li tab1"} href="help#addnew" onClick={() => { setWindowsHash("#addnew") }}>Create New Application</a></li>
                                    <li ><a className={getActive(hash, "#settings") + " help_li tab1"} href="help#settings" onClick={() => { setWindowsHash("#settings") }}>Settings</a></li>
                                    <li ><a className={getActive(hash, "#subs") + " help_li"} href="help#subs" onClick={() => { setWindowsHash("#subs") }}>Subforms & Sub Records</a></li>
                                    <li ><a className={getActive(hash, "#activity") + " help_li"} href="help#activity" onClick={() => { setWindowsHash("#activity") }}>Your Activity</a></li>
                                    <li ><a className={getActive(hash, "#generalusecases") + " help_li"} href="help#generalusecases" onClick={() => { setWindowsHash("#generalusecases") }}>General Use Cases</a></li>
                                    <li ><a className={getActive(hash, "#automation") + " help_li"} href="help#automation" onClick={() => { setWindowsHash("#automation") }}>Application Automation</a></li> */}
                                    {/* <li ><a className={getActive(hash, "#importexport") + " help_li"} href="help#importexport" onClick={() => { setWindowsHash("#importexport") }}>Export</a></li> */}
                                    {/* <li ><a className={getActive(hash, "#howdoi") + " help_li"} href="help#howdoi" onClick={() => { setWindowsHash("#howdoi") }}>How Do I?</a></li> */}
                                    {/* <li ><a className={getActive(hash, "#howdoi") + " help_li"} href="help#howdoi" onClick={() => { setWindowsHash("#faqs") }}>FAQs</a></li> */}

                                    {/*{sessionStorage.getItem("entityId") === "Not In Teams" ?*/}
                                    {/*    <li ><a className={getActive(hash, "#videos") + " help_li"} href="help#videos" onClick={() => { setWindowsHash("#videos") }}>Videos</a></li>*/}
                                    {/*    : null}*/}
                                    <li ><a className={getActive(hash, "#contactus") + " help_li tab1"} href="help#contactus" onClick={() => { setWindowsHash("#contactus") }}>Get Support/Contact Us</a></li>
                                </ul>
                            </div>
                        </div>

                        <button className="openbtn" onClick={() => { openNav() }}>&#9776;</button>
                    </>
                }
                <div className="help__child signup__formHelp">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={text}>
                                <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", height: "100vh" }}>
                                    <h1 id="home" className="headerText" style={{ color: "black" }}>Help Guide</h1>
                                    <article>
                                        <p style={text}>Use preconfigured, modify or create new forms and documents for individual or team use.
                                            Eliminate rekeying and quickly transform manual processes into automated solutions.
                                            See our preconfigured examples for a wide variety of solutions and these generalized areas:</p>
                                        <ul>
                                            <li style={litext}>General agreements and contracts</li>
                                            <li style={litext}>Sales - SoW, Pricing, Receipts, etc.</li>
                                            <li style={litext}>HR - Onboarding, Leave Requests, etc.</li>
                                            <li style={litext}>Compliance - Change, ISO and Legal</li>
                                            <li style={litext}>Letters, Tracking and Notifications</li>
                                        </ul>

                                        <p style={text}>These features are built-in to this product:</p>
                                        <ul>
                                            <li style={litext}>Webform automation</li>
                                            <li style={litext}>Document automation </li>
                                            <li style={litext}>Document management</li>
                                            <li style={litext}>Records management</li>
                                            <li style={litext}>Full text and advanced search</li>
                                            <li style={litext}>Document sharing and collaboration </li>
                                            <li style={litext}>Application and form sharing and collaboration</li>
                                            <li style={litext}>Electronic signature</li>
                                        </ul>
                                    </article>
                                    <article>
                                        <h2 id="workspace">Workspace Overview</h2>
                                        <p style={text}>Within your workspace, work with records, manage and configure applications.
                                            The workspace supports the following:
                                        </p>
                                        <ul>
                                            <li style={litext}>Configuration</li>
                                            <li style={litext}>Applications</li>
                                            <li style={litext}>Records</li>
                                            <li style={litext}>Settings</li>
                                            <li style={litext}>Search</li>
                                            <li style={litext}>Role Based Access Control</li>
                                        </ul>
                                        {!isMobile ? <>
                                            <img src="/images/workspace.png" alt="Workspace layout." style={img_Horizontal} /></> :
                                            null}
                                    </article>
                                    <article>
                                        <h2 id="manageapplication">Use Downloaded Template</h2>
                                        <p style={text}>To use the template you have just downloaded <strong><Link href='https://info.kimdocument.com/managing-your-kim-document-application' target="_blank" title="Managing Your Application">CLICK HERE</Link></strong></p>
                                    </article>
                                    <article>
                                        <h2 id="applicationlibrary">Applications</h2>
                                        <p style={text}>Applications are a core feature of the product.
                                            They are grouped into categories which you configure or import.
                                            From applications use the context menu to access webform Wizard, template, preoperties and settings or
                                            select that application to fill forms from the workspace.</p>

                                        {!isMobile ? <>
                                            <img src="/images/Applications.png" alt="Applications." style={img_Vertical} /></> :
                                            null}
                                    </article>
                                    <article>
                                        <h2 id="audiences">Audience</h2>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/Audience.png" alt="Audiences." style={img_Medium_Sized_Horizontal} /></> :
                                            null}
                                        <ul>
                                            <li style={{ marginRight: "6%", textAlign: "justify" }}>An Application&apos;s Audience has three primary access grants:
                                                <br />
                                                <ol style={litext}>
                                                    <li style={litext}><strong>&quot;Co-owners&quot;</strong> are audience members who can manage applications (update etc.),
                                                        upload lists, manage signatures and other aspects of an application.
                                                    </li>
                                                    <li style={litext}><strong>&quot;Create Document&quot;</strong> are those members who are granted the ability
                                                        to fill forms and manage records that have been created.
                                                    </li >
                                                    <li style={litext}><strong>&quot;Owner&quot;</strong> is applied automatically for user that created the application.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li style={litext}>
                                                <strong>&quot;Default Audience&quot;</strong> is created automatically when a user registers.
                                                The default audience cannot be deleted except in instances when a user deletes their account.
                                            </li>
                                            <li style={litext}>
                                                <strong>&quot;Record audiences&quot;</strong> are the user that created the records,
                                                application audience owners and co-owners associated to the document's template type,
                                                and users that have been invited from the share action on the record menu.
                                            </li>
                                        </ul>
                                        <p><strong>For a step-by-step guide on how to manage audience users, <Link href='/HowTo/How%20To%20Create%20and%20Manage%20Audiences.pdf' target="_blank" title="Manage audience users">CLICK HERE</Link></strong></p>
                                    </article>
                                    {/* <article>
                                        <ul>
                                            <li id="applicationclone" style={{ paddingBottom: "5px" }}>
                                                Clone copies application, webform and lists as a specified user's application by entering email address.
                                                Clone is useful when automating applications for other users or as a basis to create additional applications for yourself.
                                                Clones are assigned to the specified user's default audience and the specified user may change audience from application properties.
                                                This action is logged to permanent record.
                                                Owners and Co-owner(s) have rights to clone applications.
                                            </li>
                                            <li id="applicationdelete" style={{ paddingBottom: "5px" }} >
                                                Delete permanently removes application from library.
                                                User is prompted for confirmation.
                                                This action is logged as permanent record and is not recoverable.
                                                When application detects open documents, deletion is not allowed.
                                                User is prompted to delete or close all documents associated to that application before this action can be performed.
                                                Owner and Co-owner(s) are granted rights to delete applications.
                                            </li>
                                            <li id="applicationremove" style={{ paddingBottom: "5px" }} >
                                                Remove is available to application audience members that only have rights to create document.
                                                Performing this action removes user from all applications associated with that audience.
                                            </li>
                                            <li id="templatedownload" style={{ paddingBottom: "5px" }} >
                                                Download allows you to change format, layout and add tags or content controls to the template used by the application.
                                                When uploaded, subsequent documents generate and display with changes.
                                                Owners and Co-owner(s) are granted rights to download templates.
                                            </li>
                                            <li id="applicationproperties" style={{ paddingBottom: "5px" }} >
                                                Properties are divided into two sections.
                                                Initial page displays application properites including Display Name, Display Name ToolTip, Description, Email From Friendly Name, ToolTip, Category, Audience and Culture.
                                                Seccond page displays application settings that specify advanced application actions such as checkIn/checkout, shared user wizard updates and electronic signature.
                                                Owners and Co-owner(s) are granted rights to edit application properties.
                                                <p>Application Properties</p>
                                                <ul>
                                                    <li style={{ paddingBottom: "5px" }}>Display Name appears when category is expanded and is what users select to create record.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Display Name ToolTip appears when users hover display name.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Description is detailed information for administrators that manage application.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Default Form Wizard Description is used to prefill the description textbox with a default description.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Email from friendly name is what appears combined with user's email address in their inbox. Using Onboard Form as the the example would display as <u>Onboarding Form &lt;share@kimdocument.com&gt;</u> instead of just <u>share@kimdocument.com</u>.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Categories are user defined and relate one or more applications with specified category.  Applications may be moved to other categories by owner or co-owner.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Application Audience is a user collection where Owner, Co-owner(s) and Create Document rights are granted. Application audiences may be assigned to multiple applicatons.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Culture is set per application. Date display according to selected country and locale.</li>
                                                </ul><br />
                                                <p>Application Settings</p>
                                                <div style={{ fontSize: 'small' }}>* note that access is controlled per record for application audience and shared audience members.</div><br />
                                                <ul>
                                                    <li style={{ paddingBottom: "5px" }}>Enable Send Mail Shared Access makes send mail action available on record menu. Activating this feature allows shared users to send emails and is specified per application.  By default, this action is available to administrtators and not available to shared users.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Enable Form Wizard Shared Access makes update action available on record menu. Activiating this feature allows shared users to update a document's data from the form wizard. By default, this action is available to administrtators and not available to shared users.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Enable DocX Availability (not required for checkout) makes DocX selection available from preview and send mail. Activating this feature allows shared users and administrators to select DocX from preview download and from send email include document.  By default, this action is not available to adminstrators or shared users.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Enable Checkout activates checkout action on record menu. Activating this feature allows all users to checkout, download and checkin document for the purpose of performing manual updates.  By default, this action is not available to administrtators or shared users.</li>
                                                    <li style={{ paddingBottom: "5px" }}>Enable Document Notifications instructs email notification to occur for each specified notification. An email will be sent to appropriate audience members when that event occurs. Notifications are set per application and by default notifications are disabled:</li>
                                                    <ul style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                                                        When notifications are enabled, Audience owner is sent emails. If other recipients are required, use email forwarding options.<br />
                                                        <li style={{ paddingBottom: "5px" }}>Select Document Creation for audience owner to be notified by email when a Document is created from this application.</li>
                                                        <li style={{ paddingBottom: "5px" }}>Select Document Delete for audience owner to be notified by email when a Document is deleted for this application type.</li>
                                                        <li style={{ paddingBottom: "5px" }}>Select Document Share for audience owner to be notified by email when a Document or Document Link is shared for this application type.</li>
                                                        <li style={{ paddingBottom: "5px" }}>Select Application Edit for audience owner to be notified by email when this Application is saved from wizard.</li>
                                                        <li style={{ paddingBottom: "5px" }}>Select Application Delete for audience owner to be notified by email when this Application is deleted.</li>
                                                        <li style={{ paddingBottom: "5px" }}>Select Application Share for audience owner to be notified by email when this Application is cloned or user is added to this Application's administrative audience.</li>
                                                    </ul>
                                                    <li style={{ paddingBottom: "5px" }}>Electronic Signature makes electronic signature action available on record menu:</li>
                                                    <ul>
                                                        <li style={{ paddingBottom: "5px" }}>For Docusign, the administrator must have a DocuSign account.</li>
                                                        <li style={{ paddingBottom: "5px" }}>For auto-sign, the template must support docked signatures and the number of auto-signers must be set</li>
                                                    </ul>
                                                    <li style={{ paddingBottom: "5px" }}>Signing feature supports DocuSign, auto-sign or a combination of both.</li>
                                                </ul>
                                            </li>
                                            <li id="applicationSendLink" style={{ paddingBottom: "5px" }} >
                                                Application send webform publish link delivers a create document link to specified user via email.
                                                Link may be saved or sent to other users to create doucments at any time.
                                                Owners and Co-owner(s) are granted rights to share application audiences.
                                            </li>
                                            <li id="applicaitonshare" style={{ paddingBottom: "5px" }} >
                                                Application share allows others to manage application and/or create documents.
                                                Add members and grant rights to manage application as a co-owner and/or grant rights to create document.
                                                Owners and Co-owner(s) are granted rights to share application audiences.
                                            </li>
                                            <li id="applicationversion" style={{ paddingBottom: "5px" }} >
                                                Application version allows restoration of a pervious version.
                                                This action uses the selected version to restore and overwrite the current application and webform with that one.
                                                This action will also take currently active application and webform and store those as a version so that they can be restored.
                                                This action is logged in historical tracking.
                                            </li>
                                            <li id="templateupload" style={{ paddingBottom: "5px" }}>
                                                Template update allows the template downloaded to be edited and re-uploaded.
                                                Edits take affect on new documents created and take affect with new tags, content controls and layout.
                                                Changes in layout and content will not take affect on existing records being updated from that template type.
                                                Tag and content control changes will not occur until applied to form wizard.
                                                Use update to change template layout, fonts, format and add or remove tags and content controls.
                                                Owners and Co-owner(s) are granted rights to upload templates.
                                            </li>
                                            <li id="applicationwizard" style={{ paddingBottom: "5px" }}>
                                                Wizard is a palette that extracts tags and content controls from templates as webform input controls.
                                                From the palette users have ability to organize the webform input controls for data capture and validation.
                                                Owners and Co-owner(s) are granted rights to design webform wizard.
                                            </li>
                                        </ul>
                                        {!isMobile ? <>
                                            <img src="/images/howdoitemplatemenu.png" alt="Application menu." /></> :
                                            null}
                                    </article> 
                                 
                              
                                  
                                    <article>
                                        <h3 id="addnew">Create New Application</h3>
                                        <p>Create a new application using most any Word document as the starting point.  The system will detect all tags and/or content control from the Word document and provide a wizard to configure the input form.  This will result in record and document that will support versioning, updates, notes and sharing of the record, form and document.</p>
                                        Properties are divided into two sections.
                                        Initial page is application properites including Name, Tooltip, Description, Email From Friendly Name, Category, Audience, Culture and Choose File.
                                        Second page is ready to use examples that you may choose to add as your applications.
                                        <p>Application Properties</p>
                                        <ul>
                                            <li style={{ paddingBottom: "5px" }}>Display Name appears when category is expanded and is what a user selects to create record.</li>
                                            <li style={{ paddingBottom: "5px" }}>Display Name ToolTip appears when user hovers display name.</li>
                                            <li style={{ paddingBottom: "5px" }}>Description is the detailed information to help users manage application.</li>
                                            <li style={{ paddingBottom: "5px" }}>Default Form Wizard Description is used to prefill the description textbox with a default description.</li>
                                            <li style={{ paddingBottom: "5px" }}>Email from friendly name is what displays next to the from email in the user's inbox. Without setting, this user would receive from: <u>&lt;share@kimdocument.com&gt;</u> and with this set the user receives <u>[Friendly Name] &lt;share@kimdocument.com&gt;</u>.</li>
                                            <li style={{ paddingBottom: "5px" }}>Category is user defined categories that appear in applications.  Applications appear when expanding categories and are configured from application properties.</li>
                                            <li style={{ paddingBottom: "5px" }}>Audience is a collection of users that are granted rights to administer applications and/or create documents. The same application audience may be assigned to multiple applications.  This association is what drives what appears in a user's applications.</li>
                                            <li style={{ paddingBottom: "5px" }}>Culture controls the date display for specified application.</li>
                                            <li style={{ paddingBottom: "5px" }}>Choose file allows user to upload template to application.</li>
                                        </ul>
                                        <p>Example Applications</p>
                                        <div style={{ fontSize: 'small' }}>Browse through our examples and for the one that you like, add to your applications.  You may use these examples "as is" or change to meet your needs.
                                            Select business or consumer applications and then preview the categories.  When you find one that you like, select menu and then select create.</div>
                                        <ul>
                                            <li style={{ paddingBottom: "5px" }}>Agreemnts</li>
                                            <li style={{ paddingBottom: "5px" }}>Compliance</li>
                                            <li style={{ paddingBottom: "5px" }}>Employment</li>
                                            <li style={{ paddingBottom: "5px" }}>Finance</li>
                                            <li style={{ paddingBottom: "5px" }}>Governemnt</li>
                                            <li style={{ paddingBottom: "5px" }}>Human Resources</li>
                                            <li style={{ paddingBottom: "5px" }}>Legal</li>
                                            <li style={{ paddingBottom: "5px" }}>Sales</li>
                                        </ul>
                                    </article>
                                   
                                    <article>
                                        <h2 id="subs">Sub Forms & Records</h2>
                                        <p>Subforms and subrecords allow association and activation from records.
                                            Any form may be designated as a subform where you have application access.
                                            When sub forms are selected from a record, documents and records are generated and linked to that record.
                                            <br /><br />
                                            Specify that a form may be used as a subform by selecting "Application may be used as subform."
                                            Select application subform property.
                                            {!isMobile ? <>
                                                <br />
                                                <img src="/images/howdoisetsubform.PNG" alt="Set subform." /></> :
                                                null}
                                            <br /><br />
                                            Subforms are available from record menu.
                                            Select subform and then either choose create a new record by selecting Create Webform or send link for user to create from Send Webform Link.
                                            {!isMobile ? <>
                                                <br />
                                                <img src="/images/howdoiselectasubformaddsubrecord.PNG" alt="Add subrecord." /></> :
                                                null}
                                            <br /><br />
                                            Records containing subrecrods have chevron pointing down.
                                            {!isMobile ? <>
                                                <br />
                                                <img src="/images/hodoisubrecordindicator.PNG" alt="Subrecord indicator." /></> :
                                                null}
                                            <br />
                                            Select the chevron to open sub records dialog.
                                            All actions are avavailable to sub records with the same access controls with the exception of creating subforms from sub records.
                                            {!isMobile ? <>
                                                <br />
                                                <img src="/images/howdoisubrecordlist.PNG" alt="Subrecord list." /></> :
                                                null}
                                            <br /><br />

                                        </p>
                                    </article>
                                    <article>
                                        <h2 id="activity">Your Activity</h2>
                                        <p>
                                            Your activity displays your actions across applications and primarily outside of records other than deleted and accessed.
                                            Your activity records application deletion, template download, document download, audience deletion and other activities.
                                            For detailed activities on a record, select status column for that record.</p>
                                    </article>
                                    <article>
                                        <h2 id="generalusecases">General Use Cases</h2>
                                        <ul>
                                            <li style={{ paddingBottom: "5px" }}>
                                                Use for repititious content generation for needs such as status reports, reviews, notifications and others.
                                                Common activities include document generation, emailing, copying to other records and search.
                                            </li>
                                            <li style={{ paddingBottom: "5px" }}>
                                                Use for first draft generation of contracts, agreement, price lists and other content for repeated quality and quick production.
                                                Common activities include document generation, emailing and search.
                                            </li>
                                            <li style={{ paddingBottom: "5px" }}>
                                                Use for content collaboration for needs such as real-estate agreements that will involve multiple partipants.
                                                Common activities include document genration, record sharing, emailing, notes, tracking, versioning and search.
                                            </li>
                                            <li style={{ paddingBottom: "5px" }}>
                                                Use for content collaboration and negotiation such as NDAs or contracts that will involve multiple partipants working on the content.
                                                Common activities include docuemnt generation, record sharing, record editing, checkout/checkin, versioning, rollback, tracking, emailing and search.
                                            </li>
                                            <li style={{ paddingBottom: "5px" }}>
                                                Use for any of the above and incldue electronic signature.
                                            </li>
                                        </ul>
                                    </article> */}
                                    <article>
                                        <h2 id="campaigns">Campaigns</h2>
                                        {!isMobile ? <>
                                            <img src="/images/Campaigns.png" alt="Campaigns." style={img_Medium_Sized_Horizontal} /></> :
                                            null
                                        }

                                        <p style={text}>
                                            Campaigns are frequent or one-time events whereby respondent groups are invited via email to complete a document/form.
                                            When each respondent from the group selects the link (and enters the one-time use access code they receive) they are then able
                                            to access and fill in the form. Upon submission, the completed document is emailed to the respondent and the record (along with the document,
                                            datapoints and audit trail) are recorded and available to the campaign administrator(s) to access.
                                            Campaigns are available for any application.</p>
                                        <br />
                                        <p><strong>For a step-by-step guide on how to create campaigns, <Link href='/HowTo/How%20To%20Create%20a%20Campaign.pdf' target="_blank" title="create campaigns">CLICK HERE</Link></strong></p>
                                    </article>
                                    <article>
                                        <h2 id="clone">Clone</h2>
                                        {!isMobile ? <>
                                            <img src="/images/Clone.png" alt="Clone." style={img_Small_Horizontal} /></> :
                                            null
                                        }
                                        <br />
                                        <p style={text}>
                                            Clone allows a user to clone an application, webform and any relevant lists to another user by entering their email address.
                                            Clone is useful when automating applications for other users or as a basis to create additional applications for yourself.
                                            Clones are assigned to the specified user's default audience and the specified user may change the audience from application
                                            properties or settings. This action is logged to the permanent record. Owners and Co-owner(s) have rights to clone applications.
                                            Campaigns are available for any application.</p>
                                    </article>
                                    <article>
                                        <h2 id="delete">Delete</h2>
                                        <br />
                                        {!isMobile ? <>
                                            <img src="/images/delete.png" alt="Delete." style={img_Small_Horizontal} /></> :
                                            null
                                        }
                                        <p style={text}>
                                            Delete permanently removes an application. When deleting, a user is notified of how many open and closed records are linked to
                                            the application. If there are open documents, the user is not able to delete the application.
                                            The user must delete or close the relevant records before proceeding with the application deletion. This action is logged
                                            as a permanent record and is not recoverable. Owner and Co-owner(s) are granted rights to delete applications.</p>
                                    </article>
                                    <article>
                                        <h2 id="download">Download</h2>
                                        <br />
                                        {!isMobile ? <>
                                            <img src="/images/download.png" alt="Download." style={img_Small_Horizontal} /></> :
                                            null
                                        }
                                        <p style={text}>
                                            Download allows you to change format, layout and add tags or content controls to the template used by the application.
                                            When uploaded, subsequent documents generate and display with changes.
                                            Owners and Co-owner(s) are granted rights to download templates.</p>
                                    </article>
                                    <article>
                                        <h2 id="importexport">Export (Note: this guidance note also explains Import)</h2>
                                        <br />
                                        {!isMobile ? <>
                                            <img src="/images/export.png" alt="Export." style={img_Small_Horizontal} /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>The export functionality allows a user to export an entire application.
                                                The user can either copy the link to the application and send it to another
                                                user via email or download it themselves<br /></li>
                                            <li style={litext}>If elected, you can protect your application’s exported file by applying a password<br /></li>
                                            <li style={litext}>Once an exported package has been emailed to a user, the user can import the application by clicking on the link.
                                                Alternatively, if you would like to upload an import package yourself, you can simply upload it by clicking on the
                                                down arrow next to “Create New Application”.<br /></li>
                                        </ul>
                                        <br />
                                        <p><strong>For a step-by-step guide on how to import/export applications, <Link href='/HowTo/How%20To%20Export%20and%20Import%20Applications.pdf' target="_blank" title="Export ">CLICK HERE</Link></strong></p>
                                    </article>
                                    <article>
                                        <h2 id="properties">Properties</h2>
                                        <br />
                                        <h3>Tab 1 - Properties</h3>
                                        <br />
                                        {!isMobile ? <>
                                            <img src="/images/Tab1-Properties.png" alt="Tab 1 - Properties." style={img_Medium_Sized_Horizontal} /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}><strong>Display Name</strong> is the name of the application that will appear under the main category (see below).<br /></li>
                                            <li style={litext}><strong>Display Name ToolTip </strong> appears when users hover over the display name.<br /></li>
                                            <li style={litext}><strong>Description </strong>is detailed information for the administrators who manage the application.<br /></li>
                                            <li style={litext}><strong>Default Form Wizard Description </strong> is used to prefill the description textbox (located in the top left corner of your webform) with a default description of the application.<br /></li>
                                            <li style={litext}><strong>Service Email Account </strong> allows an administrator to send emails from their own Microsoft Office 365 Account rather than the default Email provider.
                                                Once you enter your email, an email will be sent to the specified email address. You will need to open the email and consent to the use of the specified email.<br /></li>
                                            <li style={litext}><strong>Email From Friendly Name </strong> is additional text you can include when a user receives an email from the system.  For example, specifying
                                                “Onboard Form” would display as Onboard Form (share@kimdocument.com) rather than share@kimdocument.com.<br /></li>
                                            <li style={litext}><strong>Categories </strong> are user defined and can relate to one or more applications. Applications may be moved from one category to
                                                another by the owner or co-owner.  For example, a category name can be “Non-Disclosure Agreements” and the various applications that would sit under
                                                it when expanded would include “NDA-England & Wales”, “NDA-New York” etc.<br /></li>
                                            <li style={litext}><strong>Application Audience </strong> is a user group (that can be managed) where Owner, Co-owner(s) and Create Document rights are granted.
                                                Application audiences may be assigned to multiple applications.<br /></li>
                                            <li style={litext}><strong>Culture  </strong> Culture is automatically set per application. The date format displayed is dependent on   the selected country and locale.<br /></li>
                                        </ul>
                                        <br />
                                        <h3>Tab 2 - Settings (Note: the following fields must be specified per application)</h3>

                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/Tab2-Settings.png" alt="Tab 1 - Properties." style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}><strong>Enable Send Mail Shared Access </strong> enables the “send mail” action available for a record.
                                                Activating this feature allows shared users to send the record via email.
                                                By default, this action is available to administrators and not available to shared users<br /></li>
                                            <li style={litext}><strong>Enable Form Wizard Shared Access  </strong>enables the “update” action available for a record. Activating this feature allows shared users
                                                to update a record’s data from the form wizard. By default, this action is available to administrators and not available to shared users.<br /></li>

                                            <li style={litext}><strong>Enable DocX Availability  </strong> activates the “checkout” action for a record. Activating this feature allows all users to checkout,
                                                download and checkin records for the purpose of performing manual updates. By default, this action is not available to administrators or shared users<br /></li>

                                            <li style={litext}><strong>Enable Checkout  </strong> activates the “checkout” action for a record. Activating this feature allows all users to checkout,
                                                download and checkin records for the purpose of performing manual updates. By default, this action is not available to administrators
                                                or shared users.<br /></li>

                                            <li style={litext}><strong>Enable Document Notifications  </strong> allows a user to enable specific email notifications for each action specified.
                                                When enabled for an action, an email will be sent to the application owner(s) when the action occurs.
                                                Specific notifications include:
                                                <br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Document Creation." /> Select <strong>Document Creation</strong> for notifications when a record is created;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Document Delete." /> Select <strong>Document Delete</strong> for notification when a record is deleted;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Document Share." /> Select <strong>Document Share</strong> for notification when a record or record link is shared;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Application Edit." /> Select <strong>Aplication Edit</strong> for notification when the Application is saved from the webform wizard;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Application Share." /> Select <strong>Application Delete</strong> for notification when the Application is deleted and</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Application Edit." /> Select <strong>Application Share</strong>for notification when the Application is cloned or a user is added to the
                                                        Application's administrative audience.</li>
                                                </ul>
                                                <br />
                                            </li>

                                            <li style={litext}><strong>Electronic Signature  </strong>enables the electronic signature action for a record. This feature supports DocuSign, auto-sign or a combination of both.
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="For DocuSign." />For DocuSign, the administrator must have a DocuSign account. </li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="auto-sign." />For auto-sign, the template must support docked signatures and the number of auto-signers must be set. </li>
                                                    <li style={{ display: "block", padding: "1%" }}>  <p><strong>For a step-by-step guide on how to use electronic signatures <Link href='/HowTo/How%20To%20Use%20DocuSign%20and%20Kim%20AutoSign.pdf' target="_blank" title="Export ">CLICK HERE</Link></strong></p></li>


                                                </ul>
                                                <br />
                                            </li>
                                            <li style={litext}><strong>Subform   </strong>allows a user to specify whether they would like the application to be designated as a subform.
                                                This means that when records are generated from different applications, the relevant record that is generated can be associated with the subform record.
                                                The option to link the record to the subform application is found in the actions menu of a record covered below under "Records"<br />
                                            </li>
                                        </ul>
                                        <br />
                                        <h3 id="inboundintegration">Tab 3 - API Inbound Integration and Outbound Integration</h3>
                                        <ul>
                                            <li style={litext}>This functionality gives you access to Kim's APIs to generate the 'token' that you would typically give to your internal IT
                                                team to make the integration work. </li>
                                            <li style={litext}>In order to generate the token you: <br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Select the actions you want to allow (in this example below we've selected 'Post - Create Record' and 'Post - Update Record');</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Name the token</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Generate the token; and </li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Provide the token to your IT team to do the rest.</li>
                                                </ul>

                                            </li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/Token.png" alt="Token" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <br />
                                        <h3 id="referencenumber">Tab 4 - Reference Number</h3>
                                        <p>The system creates a reference number placeholder for every new application that is created.
                                            Reference numbers generate independently of tags however for special tagging of reference numbers
                                            within webforms, please see the link to the Webform Wizard How To Guide below. </p>
                                        <br />
                                        <ul>
                                            <li style={litext}><strong>TemplateID </strong> is the number given to the template by the system and cannot be amended</li>
                                            <li style={litext}><strong>Format </strong> is defaulted to [Number] however can be amended to either[year], [month] or [day] and supports constants. See the following examples<br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Simple [number] with max number being 99999999 and leading 0 padding   <Icon iconName={"Forward"} style={{ fontSize: "10px" }} /> “00000007”</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Simple [number] with max number being 99999999 and no leading 0 padding    <Icon iconName={"Forward"} style={{ fontSize: "10px" }} /> “7”</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> With [year] [month] [day] [number] reference, max number being 99999999 and leading 0 padding    <Icon iconName={"Forward"} style={{ fontSize: "10px" }} /> “2022 10 15 000000007”</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> With [year]-[month]-[day] [number] and constant in this case hyphen, max number being 99999999 and leading 0 padding     <Icon iconName={"Forward"} style={{ fontSize: "10px" }} /> “2022-10-15 00000007”</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> With Case [year]-[month]-[day] [number] and constant in this case hyphen, max number being 99999999 and leading 0 padding     <Icon iconName={"Forward"} style={{ fontSize: "10px" }} /> “Case 2022-10-15 00000007”</li>
                                                </ul>

                                            </li>
                                            <li style={litext}><strong> Highest Number  </strong>is the largest number you want the reference numbers to go up to</li>
                                            <li style={litext}><strong> Starting Number</strong>is the number you would like the reference numbers to start  at</li>
                                            <li style={litext}><strong> Pad Leading Zeros </strong>is based on the Highest Number you select as the reference.  If you specify 1000 as your highest
                                                number and elect for pad leading zeros, the reference of the first record created will be "0001"</li>
                                            <li style={litext}><strong> Reset Seed Each Year </strong> if elected restarts the reference numbers each  <u>year</u>;</li>
                                            <li style={litext}><strong> Reset Seed Each Month </strong> if elected restarts the reference numbers each month</li>

                                        </ul>
                                        <br />
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/ReferenceNumber.png" alt="Token" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }


                                    </article>
                                    <article>
                                        <h2 id="publishwebformlink">Publish Webform Link</h2>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/publishwebformlink.png" alt="Publish Webform Link" style={img_Small_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <p style={text}>
                                            Publish Webform Link allows a user to send the relevant application link to a specified user via email.
                                            The link may be saved or sent to other users to create records. Owners and Co-owner(s) are granted rights
                                            to share application audiences.
                                        </p>
                                    </article>
                                    <article>
                                        <h2 id="version">Version</h2>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/Version.png" alt="Version" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <p style={text}>
                                            Version allows restoration of a previous version. This action uses the selected version to restore
                                            and overwrite the current application and webform with the one selected.
                                        </p>
                                    </article>
                                    <article>
                                        <h2 id="update">Update</h2>
                                        <br />
                                        {!isMobile ? <>
                                            <img src="/images/Update.png" alt="Version" style={img_Small_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <p style={text}>
                                            Update allows an application that has been downloaded, edited, and saved (see below) to be re-uploaded.
                                            Edits take effect once re-uploaded and can include new tags, content controls and layout changes.
                                            These changes, however, will not take effect on records that have been created from the previous version of the application.
                                            Tag and content control changes will not take effect until applied to the webform wizard.
                                            Owners and Co-owner(s) are granted rights to upload new applications.
                                        </p>


                                    </article>
                                    <article>
                                        <h2 id="webformwwizard">Webform Wizard</h2>
                                        <p style={text}>
                                            Webform Wizard is the customizable webform that extracts the tags and content controls created in documents within Microsoft Word.
                                            From the webform, users can organize the webform tags for data capture and validation.
                                            Owners and Co-owner(s) are granted rights to design the webform wizard.
                                        </p>

                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/WebformWizard.png" alt="Version" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }


                                    </article>
                                    <article>
                                        <h2 id="taggingdocument">Tagging a document for upload into the Webform Wizard</h2>
                                        <ul>
                                            <li style={litext}>All documents must be tagged in Microsoft Word prior to being uploaded as an application. </li>
                                            <li style={litext}>This process is required so that each field that is tagged can be pulled
                                                into the Webform Wizard and configured as required.</li>
                                            <li style={litext}>To tag a document, you simply configure the relevant fixed data in your Microsoft Word document with a tag as follows - [$tag].
                                                As an example, if you are tagging a Confidentiality Agreement and there are various fixed data fields that need
                                                to be populated from the online webform, you would tag your document like this</li>


                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/TaggingDocument.png" alt="Tagging Document" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>It is important to note that only Microsoft Word (docx) documents can be uploaded.
                                                As such, all other types of documents (e.g. PDF) must be converted to Docx prior to being uploaded. </li>

                                        </ul>

                                    </article>
                                    <article>
                                        <h2 id="uploadingdocument">Uploading the Document and Creating the Application </h2>
                                        <ul>
                                            <li style={litext}>To upload the document, simply click on “Create New Application”
                                                and fill in the details required under “Properties” (see the over of the various Application - Properties fields above),
                                                chose the relevant file you tagged in Microsoft Word, and click on “Create”.</li> </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/UploadingDocument.png" alt="Uploading Document" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                    </article>
                                    <article>
                                        <h2 id="configuringapplication">Configuring an Application in the Webform Wizard </h2>
                                        <ul>
                                            <li style={litext}>Once a document has been uploaded and a new application has been created, the wizard automatically opens,
                                                with the relevant tags displayed on the left-side of the screen.   </li> </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/configuringapplication.png" alt="Uploading Document" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>Along with the tags on the left-side of the screen, there are section headers,
                                                paragraph and line breaks that can also be added to design the form.</li>
                                            <li style={litext}>The tags and additional options mentioned above can then be dragged and dropped within the webform wizard,
                                                allowing the user to configure the form layout as well as the relevant date field layout.  </li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/ApplicationProperties.png" alt="Uploading Document" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>Various other options are available to the user including:
                                                <br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Previewing </strong> the form during the build process to see what the layout looks  like;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Saving  </strong> the form during the building  process;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Adding/removing pages   </strong>in the   form;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Build various<strong>expressions </strong>(see the step-by-step guide link below); and </li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Releasing the application  </strong>so other users can access it if required. </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <p><strong>For a step-by-step guide on how to build a webform wizard <Link href='/HowTo/How%20To%20Configure%20a%20Webform%20Wizard.pdf' target="_blank" title="Export ">CLICK HERE</Link></strong></p>
                                    </article>
                                    <article>
                                        <h2 id="records">Records</h2>
                                        <p style={text}>Records are created in a workspace when documents/forms are submitted. Records are also listed in your workspace when they have been shared with you or when
                                            a record has been created from an application that you administer:</p>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/Records.png" alt="Records" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>The PDF icon next to all records is available to all record members. Click the icon to view the document inline.</li>
                                            <li style={litext}>The Form icon is always available to administrators but only available to shared users when
                                                Form Wizard Shared Access is enabled from application properties (see Application guidance above).
                                                Click the icon to open form wizard and update the document data.</li>
                                            <li style={litext}>A record displays various data points in your workspace:
                                                <br />
                                                <ul>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Subject </strong> column displays the description (specified when the application was created),
                                                        the date the record was created, the relevant application name and the user who created the record.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Activity   </strong> displays the most recent record of activity. Click the activity to view the history and further details.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Notes  </strong>displays the most recent note entry. Click the note to view history and add notes.
                                                        Your private notes only appear in your workspace.;</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon><strong>Attachment </strong>displays the number of attachments that have been uploaded to the record.
                                                        Click the attachment number to view and add attachments. The allowed file formats are doc, docx, xls, xlsx, ppt, pptx, txt, gif, png, jpeg, jpg, csv, svg, pdf, rtf, tif and tiff. </li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Version  </strong>displays the current version number. Version count increases for each update or checkin.
                                                        The version history is only available to administrators when clicking on the version number. Administrators may roll-back, preview and delete versions. </li>
                                                </ul>
                                            </li>


                                            <li style={litext}>A record's menu displays various actions that you are authorized to perform on that record (dependent on your authorization):
                                                <ul>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Subject</strong> column displays the description (specified when the application was created),
                                                        the date the record was created, the relevant application name and the user who created the record.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Activity </strong> displays the most recent record of activity. Click the activity to view the history
                                                        and further details.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Notes</strong> displays the most recent note entry. Click the note to view history and add notes.
                                                        Your private notes only appear in your workspace.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Attachment</strong> displays the number of attachments that have been uploaded to the record.
                                                        Click the attachment number to view and add attachments. The allowed file formats are doc, docx, xls,
                                                        xlsx, ppt, pptx, txt, gif, png, jpeg, jpg, csv, svg, pdf, rtf, tif and tiff.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Version </strong> displays the current version number. Version count increases for each update or checkin.
                                                        The version history is only available to administrators when clicking on the version number. Administrators may roll-back, preview and delete versions.</li>
                                                </ul>
                                            </li>
                                            <li style={litext}> A record’s menu displays various actions that you are authorized to perform on that record (dependent on your authorization):
                                                <ul>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Activity </strong> displays the most recent record of activity.
                                                        Click the activity to view the history and further details. This functionality is available to all users.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Attachment </strong> displays the number of attachments that have been uploaded to the record. Click the attachment number
                                                        to view and add attachments. The allowed file formats are doc, docx, xls, xlsx, ppt, pptx, txt, gif, png, jpeg, jpg, csv, svg, pdf, rtf, tif and tiff.
                                                        This functionality is available to all users.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Campaign</strong> is available to administrators only and allows an administrator to set up a campaign
                                                        on an existing record rather than an application as described above under Campaigns</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Checkout </strong> needs to be enabled from the relevant application properties.  When enabled, it supports manual download,
                                                        edit and re-upload. This functionality is available to all users however Administrators also have an undo checkout action available.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Copy </strong> is available to administrators only and creates a new record
                                                        by copying the current record's data into a new record.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Delete </strong> allows administrators to permanently delete document, attachments, notes,
                                                        and activity for a selected record. This action is prompted and permanently logged.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Download  </strong> allows administrators to download datapoints in XML or XLSX format and the Package.
                                                        The package contains all information from the record - document,document versions, datapoints, attachments, notes, activities, and audience..</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Manage   </strong> allows administrators to close and suspend records.
                                                        Close removes record from the default record list without deletion. Closed records may be viewed in record list or reopened by using advanced search to locate.
                                                        Suspend removes record from shared audience record list, disables update/delete/checkout activities. Administrator may reinstate suspended records by selecting resume.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Note </strong> displays the most recent note entry. Click the note to view
                                                        history and add notes. Your private notes only appear in your workspace.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Preview  </strong> is available to all audience members. Displays PDF inline viewer</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Remove   </strong> is available to shared users and permanently removes the record from
                                                        shared user's view - data is not deleted. This action is prompted and permanently logged.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Send Mail </strong> is available by default to administrators. For shared users,
                                                        the application property Enable Send Mail Shared Access must be selected.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Send Webform Update Link </strong> allows administrators to send a link via email to users that will open
                                                        a webform wizard for the purposes of reviewing or updating document data.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Share </strong> is available to administrators and allows inviting shared users to the record by entering their email address.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Sign  </strong> is available to administrators when the Electronic Signature is selected from application properties.
                                                        Signatories and / or auto-signing occurs based on configuration settings.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Subform  </strong> is available to administrators when subform option is specified from application settings.
                                                        Subform will first list all subforms that are available and then each subform has two options.
                                                        Either Create Webform allowing users to create a subrecord from the wizard or Send Webform Link
                                                        to use for custom email invites or preparation for web page links.</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Update Webform   </strong> becomes available to administrators only when electronic signing is activated
                                                        and documents have been signed.  Administrators then have access to those signed documents.</li>
                                                </ul>
                                            </li>
                                        </ul>

                                    </article>
                                    <article>
                                        <h2 id="settings">Settings</h2>
                                        <p>Settings allows a user to manage all authorized audiences, categories, lists and signatures across all applications. </p>
                                        <br />
                                        <p>Furthermore, users may edit the following:</p>

                                        <ul>
                                            <li id="myaudiences" style={litext}>
                                                <strong>My Audiences</strong> allows you to access your membership association and access across applications.
                                                A member's default audience cannot be deleted. Remaining audiences are those that you or other users created for
                                                managing and operating applications. Use application audiences to group applications logically and share those
                                                applications with other members by adding them to the audience..<br /><br />
                                                For instance, if you have one or more human resource type applications, then it may be appropriate to add those
                                                users to a human resource audience. You may then set individual member rights to manage or generate documents
                                                and records. Follow this same approach for various business functions, departments, or any other logical grouping
                                                to manage application access.
                                            </li>
                                            <li id="mycategories" style={litext}>
                                                <strong>My Categories</strong>  allows you to specify application categories. Editing any of these categories to
                                                which you have access will change the category name for all applications that you own or co-own having that name.
                                                If you want to change a category name for an individual application, open that application's properties and change
                                                it there (see Applications above).<br /><br />
                                            </li>
                                            <li id="mylists" style={litext}>
                                                <strong>My Lists</strong> allows the re-use of lists for the purposes of building the Webform Wizard.
                                                For example, it might be beneficial to maintain a consistent list of countries, cities or states that
                                                can be used in various applications rather than uploading a new list per application.<br /><br />
                                            </li>
                                            <li id="mysignatures" style={litext}>
                                                <strong>My Signatures</strong> is designed as a facility to manage signatures associated with application audience groups.
                                                Signatures are either digital or image mapped to template signature tags. When a template is selected for signing, signatures
                                                for that template are retrieved by that application's audience and automatically applied to generated documents where mapped.<br /><br />
                                            </li>
                                            {/* <li id="myprofile" style={{ paddingBottom: "5px" }}>
                                                Profile allows you to manage your name, time zone and delete your account.<br /><br />
                                            </li> */}
                                        </ul>
                                    </article>
                                    <article>
                                        <h2 id="search">Search</h2>
                                        <p style={text}>Within the workspace you can search for various records using the quick search or the advanced search functionality. </p>
                                        <ul>
                                            <li style={litext}>Quick search allows you to search a records content.</li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/Search.png" alt="Records" style={img_Small_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>Advanced Search provides more granular levels of searching:</li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/AdvancedSearch.png" alt="Records" style={img_SmaVertical} />
                                            <br /></> :
                                            null
                                        }
                                        <ul><li style={{ display: "block" }}>
                                            <ul> <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon><strong> Content </strong>searches documents for keywords and phrases. Include attachments when needed.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon><strong> Username  </strong>searches records created by that username.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon><strong> Created From Date </strong>(created and updated) searches records from specified date until current date
                                                    or "to date" when specified</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon><strong> Created To Date  </strong> (created and updated) searches records up to date.
                                                    When "from date" is specified, the records display in the range "from date" "to date".</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Search <strong> subject </strong>by keyword and phrases.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Search <strong> notes </strong>by keyword and phrases.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Searches records by  <strong> application  </strong>category and name.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Search by  <strong> activity  </strong>.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Include  <strong> closed   </strong>searches all records by all criteria and includes closed records.</li>
                                            </ul>
                                        </li></ul>

                                    </article>
                                    <article>
                                        <h2 id="usermenu">User Menu</h2>
                                        <p style={text}>User menu links are located in different locations when using from web browser or Microsoft Teams.
                                            When using web browser, user menu links appear when selecting your username from top-right corner of screen next to
                                            Help (where you can find this guide). When using Microsoft Teams, user menu links appear when selecting Settings from the
                                            bottom-left of screen below Create New Application. Additional operations also appear below settings and those operations are explained below.
                                        </p>
                                    </article>
                                    <article>
                                        <h2 id="webbrowserusermenu">Web Browser User Menu</h2>
                                        <ul>
                                            <li style={litext}> <strong> Bulk Download </strong> allows a user to retrieve datapoints or record packages (<strong>For a step-by-step guide on how to
                                                bulk download, <Link href='/HowTo/How%20To%20Bulk%20Download.pdf' target="_blank" title="Bulk download">CLICK HERE</Link></strong>)
                                            </li>
                                            <li style={litext}><strong> My Profile </strong> allows you to update your name, time zone or delete your account. Here you can also activate Microsoft Office 365 email integration which allows a user to send emails from their own Microsoft Office 365 Account when sharing or emailing a record, rather than the default Email provider.  Once you enter your email, an email will be sent to the specified email address. You will need to open the email and consent to the use of the specified email. </li>
                                            <li style={litext}><strong> My activity </strong> displays your actions across applications and primarily outside of records other
                                                than those that have been deleted and accessed. My Activity records application deletion, template download,
                                                document download, audience deletion and other activities. For detailed activities on a record, select status
                                                column for that record.</li>
                                            <li style={litext}> View Kim Technologies <strong>Privacy Policy</strong>.</li>
                                            <li style={litext}> View Kim Technologies <strong>Terms and Conditions</strong>.</li>
                                            <li style={litext}> View Kim Technologies <strong>Data Processing Agreement</strong>.</li>
                                            <li style={litext}> Use <strong>Contact</strong> Us for Help and Support</li>
                                            <li style={litext}> <strong>Log out </strong>of your account.</li>

                                        </ul>



                                    </article>
                                    <article>
                                        <h2 id="microsoftteamsusermenu"> Microsoft Teams User Menu</h2>
                                        <ul>
                                            <li style={litext}>Use <strong>Bulk Download </strong>as per the above.</li>
                                            <li style={litext}>Use <strong>Profile</strong> as per the above.</li>
                                        </ul>

                                    </article>

                                    {/* <article>
                                        <h2 id="howdoisearch">Search</h2>

                                        {!isMobile ? <>
                                            <img src="/images/howdoisearch.png" alt="Quick search." /></> :
                                            null}
                                        <ul>
                                            <li style={{ paddingBottom: "5px" }}>
                                                From the Workspace, click on the 'Search' box on the top right of the screen.
                                            </li>
                                            <li style={{ paddingBottom: "5px" }}>
                                                Type the keyword or phrase you are looking for and hit enter.
                                            </li>
                                            <li style={{ paddingBottom: "5px" }}>
                                                To activate the advanced search menu, click on the kebab menu on the right.
                                            </li>
                                            <li style={{ paddingBottom: "5px" }}>
                                                Fill out the specific fields available, and click on 'Search'.
                                            </li>
                                        </ul>
                                        {!isMobile ? <>
                                            <img src="/images/howdoiadvancedsearch.png" alt="Advanced search." /></> :
                                            null}
                                    </article>
                                    
                                    <article>
                                        <h2 id="automation">Automate From Document</h2>
                                        <p>
                                            Enable webified business solutions from Word documents.
                                            With webform data capture and validation, document automation & management and records management are easily applied to Word documents.
                                            Use the following procedure to get started:</p>
                                        <p>
                                            <ul>
                                                <li style={{ paddingBottom: "5px" }}>Open document in Microsoft Word. If not docx, please save as docx before continuing.</li>
                                                <li style={{ paddingBottom: "5px" }}>Tag the document by indicating where variable data is located using either Tag syntax [$CompanyName] or content controls. Collectively, these tags and content controls are the datapoints.
                                                    Variable are datapoints such as names, addresses, phone numbers, date, clauses, terms and any other content needed for documents.</li>
                                                <li style={{ paddingBottom: "5px" }}>Upload document by selecting Create New Application.</li>
                                                <li style={{ paddingBottom: "5px" }}>Enter name, tooltip, description, category, audience, culture and then save. Wizard will automatically open.</li>
                                                <li style={{ paddingBottom: "5px" }}>Configure the webform by dragging the datapoints from the left pane and dropping on the right pane.  Once positioned, configure the datapoint labels, types, validations and other properties.  Once configuration is complete, save the webform.</li>
                                                <li style={{ paddingBottom: "5px" }}>The application is now ready with webform, document automation and record's management capabilities.</li>
                                            </ul>
                                        </p>
                                    </article>
                                    <article>
                                        <h2 id="expressionbuilder">Expression Builder</h2>
                                        <p>
                                            Expression Builder performs basic math, date and string concatenation operations.
                                            Operations include Add, Subtract, Mutliply, Divide, AddDate, Concatenate and Sum.
                                            All operations require a Target Content Control to store Results and require Source Content Controls or Constant Values for input.
                                            The Sum operation is used to sum values from Content Controls lists that come from Grid.
                                            Target Content Controls do not have to be placed on Web Form Wizard.
                                            Target Content Controls genarally appear on generated documents and/or retreived from bulk download.
                                            Target Content Controls may be used as inputs for subsequent operations through the use of appropriately setting Order.:</p>
                                        <p>
                                            <ul>
                                                <li style={{ paddingBottom: "5px" }}>Content Controls specified on Word Documents become available in the Expression Builder same as the Form Wizard.</li>
                                                <li style={{ paddingBottom: "5px" }}>Content Controls may be used as Expression Target or Source.</li>
                                                <li style={{ paddingBottom: "5px" }}>Target Control Results from numeric expressions types may be configured with required number of decimal positions.</li>
                                                <li style={{ paddingBottom: "5px" }}>If there is a need to Sum a list of Content Controls, a Grid List must be used and that Control becomes available as a Sum Expression input.</li>
                                                <li style={{ paddingBottom: "5px" }}>Constants may also be used expression inputs.  When it is determined that the input is not a Content Control, that value is literally.  An example such as tax could be ".05".</li>
                                                <li style={{ paddingBottom: "5px" }}>Controlling Expression Order is useful when Target Conent Controls results are needed as inputs for subsequent Expression Operations.  For example, order 1 could be expression to result a Conent Control tagged Sub Total form a Grid List with a Content Control tagged Amount.  Then order 2 could Target a Content Control Total with one input being the Sub Total Content Control and a second input with a Constant Tax Rate instead of a content control.</li>
                                            </ul>
                                        </p>
                                    </article> */}
                                    <article>
                                        <h2 id="contactus">Get Support/Contact Us</h2>
                                        <p style={text}>For further assistance and for ongoing technical support, please contact  <a href="help#contactus" onClick={_openSupportForm}>support@kimdocument.com</a>.</p>
                                        <br />
                                    </article>
                                    {/* <article>
                                        <h2 id="howdoi">How Do I?</h2>
                                        <article>
                                            <h3> <Link href='/1.%20Getting%20Started%20-%20Login%20and%20First%20Time%20User%20Experience%20June%202022.pdf' target="_blank" title="1. Getting Started">1. Getting Started</Link></h3>
                                        </article>
                                        <article>
                                            <h3> <Link href='/2.%20Tag%20It%20-%20How%20to%20Tag%20a%20Document.pdf' target="_blank" title="2. Tag It">2. Tag It</Link></h3>
                                        </article>
                                        <article>
                                            <h3> <Link href='/3.%20Upload%20It%20-%20How%20to%20Upload%20a%20Document.pdf' target="_blank" title="3. Upload It'">3. Upload It</Link></h3>
                                        </article>
                                        <article>
                                            <h3> <Link href='/4.%20Automate%20It%20-%20How%20to%20Automate%20a%20Document.pdf' target="_blank" title="4. Automate It">4. Automate It</Link></h3>
                                        </article>
                                        <article>
                                            <h3> <Link href='/5.%20Kim%20Document%20Workspace%20June%202022.pdf' target="_blank" title="5. Workspace">5. Workspace</Link></h3>
                                        </article>
                                        <article>
                                            <h3> <Link href='/6.%20Collaboration%20in%20Kim%20Document%20May%202022.pdf' target="_blank" title="6. Collaboration">6. Collaboration</Link></h3>
                                        </article>
                                        <article>
                                            <h3> <Link href='/7.%20Compliance%20Campaigns%20in%20Minutes%20with%20Kim%20Document%20May%202022.pdf' target="_blank" title="7. Compliance Campaigns">7. Compliance Campaigns</Link></h3>
                                        </article>
                                        <article>
                                            <h3> <Link href='/8.%20Web%20Applications%20-%20From%20Your%20Docs%20to%20Web%20Applications%20in%20Minutes.pdf' target="_blank" title="3. Automate It - How to Automate a Document.pdf">8. Web Applications</Link></h3>
                                        </article>
                                        <article>
                                            <h3> <Link href='/9.%20APIs%20June%202022.pdf' target="_blank" title="9. APIs">9. APIs</Link></h3>
                                        </article>
                                        </article> */}
                                    {/* 
                                        <article>
                                            <h3 id="mask">Add masks</h3>
                                            <p>
                                                Add masks to an specific control within Wizard design palette using predefined characters as shown below:
                                            </p>
                                            <ul>
                                                <li style={{ paddingBottom: "5px" }}>
                                                    L - Alphabet.
                                                </li>
                                                <li style={{ paddingBottom: "5px" }}>
                                                    9 - Numbers.
                                                </li>
                                                <li style={{ paddingBottom: "5px" }}>
                                                    s - Special Characters.
                                                </li>
                                            </ul>
                                            <p>
                                                Examples: 'L9L-9L9' , 'Price: $99.99', 'Ls99LLL'
                                            </p>
                                        </article>
                                        <article>
                                            <h3 id="pattern">Add regex patterns</h3>
                                            <p>
                                                Add patterns to an specific control within Wizard design palette by simply copying and pasting custom or predefined patterns suchs as:
                                            </p>
                                            <ul>
                                                <li style={{ paddingBottom: "5px" }}>
                                                    {"[A-Za-z]{3}"} - Three letter input.
                                                </li>
                                                <li style={{ paddingBottom: "5px" }}>
                                                    {".{8,}"} - Eight or more characters input.
                                                </li>
                                                <li style={{ paddingBottom: "5px" }}>
                                                    {"(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"} - Input with at least one number and one uppercase and lowercase letter, and at least 8 or more characters.
                                                </li>
                                                <li style={{ paddingBottom: "5px" }}>
                                                    {"[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"} - Email validation input.
                                                </li>
                                                <li style={{ paddingBottom: "5px" }}>
                                                    {"https?://.+"} - Input with a prefix.
                                                </li>
                                            </ul>
                                        </article> */}
                                    {/*
                                        <article>
                                            <article>
                                                <h3 id="howdoiuploadatemplate">Upload a template</h3>
                                                <ul>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        From the Workspace, click on 'Create New Application'.
                                                        {!isMobile ?
                                                            <><br />
                                                                <img src="/images/howDoIAddNewTemplate.png" alt="Add New Application." /></> :
                                                            null}
                                                    </li>
                                                    <li style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                                                        Fill out the information on the Properties window that pops up.
                                                        {!isMobile ? <> <br />
                                                            <img src="/images/howDoIAddnewTemplateProperties.png" alt="Application Properties." /></> :
                                                            null}
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click on 'Choose File' and navigate to your template and select.<br />
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click on 'Create'.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Drag and drop the Fields to create the webform.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click on the save icon on the top left of the screen.
                                                    </li>
                                                </ul>
                                            </article>
                                            <article>
                                                <h3 id="howdoidownloadarecord">Download a record</h3>
                                                <ul>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click on the preview icon on the far left of the record.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        From the preview, click on the download icon.  By default PDF is allowed.  If the application allows DocX, then DocX is also available.
                                                        {!isMobile ? <> <br />
                                                            <img src="/images/howdoidocumentpreviewprint.png" alt="Document download." /></> :
                                                            null}
                                                    </li>
                                                </ul>
                                            </article>
                                            <article>
                                                <h3 id="howdoidownloaddatapoints">Download datapoints</h3>
                                                <ul>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        With this solution, datapoints are collected and available for download.  The datapoints are those same values captured from the input form and used to populate the document.  Datapoints are versioned along with document.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        From browser select your name in the top right corner and select bulk download.  From teams, select settings and then select bulk download.
                                                        {!isMobile ? <> <br />
                                                            <img src="/images/MSTeamsKimDocumentBuildDownloadPage1.PNG" alt="Bulk download filter." /></> :
                                                            null}
                                                        {!isMobile ? <> <br />
                                                            <img src="/images/MSTeamsKimDocumentBuildDownloadPage2.PNG" alt="Bulk download excel results." /></> :
                                                            null}
                                                    </li>
                                                </ul>
                                            </article>
                                            <article>
                                                <h3 id="howdoibuildawebform">Build a webform</h3>
                                                {!isMobile ? <>
                                                    <img src="/images/howdoibuildawebform.png" alt="Build a webform." /></> :
                                                    null}
                                                <ul>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        From the Workspace, go to your application, click 'Wizard' from the menu.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        On the window that opens, drag and drop the controls to the canvas, and place them where you need them to go.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click on the Cog icon of each Control and adjust properties as required.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click apply, and then click on the save icon on the top left of the page.
                                                    </li>
                                                </ul>
                                            </article>
                                            <article>
                                                <h3 id="howdoishareatemplate">Application's Audience</h3>
                                                <ul>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        From the Workspace, click on application menu where you are owner or co-owner.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click on 'Share'.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        On the window that opens, click on 'Add user'.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Type the email of the person with which you want to share the application.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        You can make them Co-owners and give them the ability to create a document by selecting the appropriate box.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click 'Save'.
                                                    </li>
                                                </ul>
                                            </article>

                                            <article>
                                                <h3 id="howdoideletemyaccount">Delete my account</h3>
                                                <ul>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        From the Workspace. Click on 'Settings' at the bottom of the left navigation menu.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Select 'Profile'.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click on the checkbox at the bottom that reads 'Yes, I would like to delete my account and understand that this is unrecoverable.'
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click on 'Delete Account'.
                                                    </li>
                                                </ul>
                                            </article>
                                            <article>
                                                <h3 id="howdoicreateanaudience">Create an audience</h3>
                                                <ul>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        From the Workspace. Click on 'Settings' at the bottom of the left navigation menu.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Select 'My Audiences'.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click on 'Add Audience'.
                                                        {!isMobile ? <>
                                                            <br />
                                                            <img src="/images/howdoiaddaudience.png" alt="Add audience." /></> :
                                                            null}
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Enter audience Name and a description.
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click Add.
                                                    </li>
                                                </ul>
                                            </article>
                                            <article>
                                                <h3 id="howdoireleaseatemplate">Release Application</h3>
                                                {!isMobile ? <>
                                                    <img src="/images/howdoitemplatereleased.png" alt="Application released." /></> :
                                                    null}
                                                <ul>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        From the Workspace, click menu for application with red font (red means not released).
                                                        {!isMobile ? <>
                                                            <br />
                                                            <img src="/images/howdoitemplatereleasedoff.png" alt="Application released off." /></> :
                                                            null}
                                                    </li>
                                                    <li style={{ paddingBottom: "5px" }}>
                                                        Click on 'Wizard' and activate the top toggle switch to release the form.
                                                        {!isMobile ? <>
                                                            <br />
                                                            <img src="/images/howdoitemplatereleasedon.png" alt="Application released on." /></> :
                                                            null}
                                                    </li>
                                                </ul>
                                            </article>
                                           
                                        </article>

                                    </article> */}
                                    {/*{sessionStorage.getItem("entityId") === "Not In Teams" ?*/}
                                    {/*    <article style={{ paddingBottom: '10px' }}>*/}
                                    {/*        <h2 id="videos">Videos</h2>*/}
                                    {/*        <p>*/}
                                    {/*            Select video links below for more information on what is and how to use this solution.*/}
                                    {/*        </p>*/}
                                    {/*        <ul>*/}
                                    {/*            <li>*/}
                                    {/*                <div style={{ paddingBottom: "5px" }}>*/}
                                    {/*                    <Icon iconName="MSNVideos" styles={iconStyleVideos} />*/}
                                    {/*                    */}{/* <a style={{ textDecoration: "none" }} href="https://vimeo.com/644566341" target="_blank"><span title="Getting started video is coming soon." style={{ color: "#406692", fontSize: "18px", fontFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif" }}>&nbsp;&nbsp; Getting Started</span></a> */}
                                    {/*                    <span onClick={() => { onVideoInvoke("644566341", "Getting Started") }} title="Getting started video is coming soon." style={{ color: "#406692", fontSize: "18px", fontFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif", cursor: "pointer" }}>&nbsp;&nbsp; Getting Started</span>*/}
                                    {/*                </div>*/}
                                    {/*            </li>*/}
                                    {/*            <li>*/}
                                    {/*                <div style={{ paddingBottom: "5px" }}>*/}
                                    {/*                    <Icon iconName="MSNVideos" styles={iconStyleVideos} />*/}
                                    {/*                    */}{/* <a style={{ textDecoration: "none" }} href="https://vimeo.com/644143164" target="_blank"><span title="Quick automation video is coming soon." style={{ color: "#406692", fontSize: "18px", fontFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif" }}>&nbsp;&nbsp; Quick Automation</span></a> */}
                                    {/*                    <span onClick={() => { onVideoInvoke("644143164", "Quick Automation") }} title="Quick automation video is coming soon." style={{ color: "#406692", fontSize: "18px", fontFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif", cursor: "pointer" }}>&nbsp;&nbsp; Quick Automation</span>*/}
                                    {/*                </div>*/}
                                    {/*            </li>*/}
                                    {/*        </ul>*/}
                                    {/*    </article>*/}
                                    {/*    : null}*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*region Customer Support*/}
            {openContactUs &&
                <CustomerSupport showModal={openContactUs} setShowModal={hideContactUs} defaultSubject={"Help with configuration"}/>
            }
            {/*endregion*/}
   
        </div>
    );
}

export default Help

