import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IMyListOption } from "../../../../../Helpers/Helper";
import { ActionMeta, MultiValue } from "react-select";
import { useFormContext, UseFormSetValue } from "react-hook-form";
import { string } from "mathjs";

const mapDefaultValue = (
    defaultValues: IDropdownOption[] | string, options: IMyListOption[]
): MultiValue<IMyListOption> => {
    if (!defaultValues) return []
    if (typeof defaultValues === "string") {
        const _defaultValues = defaultValues.split(",").map((value) => value.trim());

        const data: MultiValue<IMyListOption> = _defaultValues.map((value) => {
            return options.find((x) => x.value === value) ?? { value: '', label: '' }
        })
        const filter = data.filter((option) => option.label !== '')
        return filter
    }
    const _defaultValues = defaultValues.map((value) => {
        const option = options.find((x) => x.label === value.text?.toString())
        return {
            value: option?.value ?? '',
            label: option?.label ?? '',
        }
    })

    return _defaultValues
}
const mapDropdownOptions = (
    options: IDropdownOption[]
): IMyListOption[] => {
    return options
        ? options.map((option) => {
            return { value: option.key.toString(), label: option?.text };
        })
        : [];
};
type Props = {
    id: string
    options: IDropdownOption[]
    defaultValue: any
    value: any
}
const useSelectMultipleControl = ({ id, options: _options, defaultValue, value }: Props) => {

    const { getValues, setValue } = useFormContext<any>()
    const options = mapDropdownOptions(_options)
    const [selectedOptions, setSelectedOptions] = useState(() => {
        return mapDefaultValue(value ?? getValues(id), options)
    })


    const mapDropdownValues = (
        newValue: MultiValue<IMyListOption>
    ): IDropdownOption[] => {
        return newValue.map((value) => {
            return { key: value?.value!, text: value?.label! };
        });
    };

    const onChangeDropdown = useCallback((newValue: MultiValue<IMyListOption>) => {
        const value = newValue ? mapDropdownValues(newValue) : undefined
        setSelectedOptions(newValue)
        setValue(id, value)

    }, [id, setValue])

    useEffect(() => {
        if (value !== undefined)
            setSelectedOptions(mapDefaultValue(value, options))
    }, [value])

    return {
        options,
        selectedOptions,
        mapDropdownOptions,
        mapDropdownValues,
        mapDefaultValue,
        onChangeDropdown,
    }
};

export default useSelectMultipleControl;
