import {IDropdownOption} from '@fluentui/react'
import React, {FC, useState} from 'react'
import {IFormControl} from '../../../../Helpers/Helper'
import {IFormAssemblyRule} from '../../../../interfaces/IFormAssembly'
import {ILayout} from '../../CustomTemplate'

type User = {
    isOwner: boolean
    isCoOwner: boolean
    username: string
}

type ContextProps = {
    layouts: ILayout[]
    originalLayouts: Array<any>
    isAdmin: boolean
    setLayouts: React.Dispatch<React.SetStateAction<ILayout[]>>
    templateId: number
    user: User
    data: any
    isSubform: boolean
    locale: string
    documentValues: IFormControl[]
    applicationResponse: any[] | undefined
    integrationDropDownLists: { [key: string]: IDropdownOption[] }
    customDropDownLists: { [key: string]: IDropdownOption[] }
    formRules: IFormAssemblyRule[]
    setFormRules: React.Dispatch<React.SetStateAction<IFormAssemblyRule[]>>

}
type CustomFormProviderProps = {
    setTriggerRules: React.Dispatch<React.SetStateAction<Map<string, number>>>
    setTriggerPages: React.Dispatch<React.SetStateAction<Map<string, boolean>>>
    triggerRules: Map<string, number>
    triggerPages: Map<string, boolean>
    gridAreaString: string
    setGridAreaString: React.Dispatch<React.SetStateAction<string>>

} & ContextProps
export const context = React.createContext<CustomFormProviderProps>({
    triggerPages: new Map<string, boolean>(),
    triggerRules: new Map<string, number>()
} as CustomFormProviderProps)

const CustomFormProvider: FC<ContextProps> = (props) => {
    const [triggerRules, setTriggerRules] = useState<Map<string, number>>(new Map())
    const [triggerPages, setTriggerPages] = useState<Map<string, boolean>>(new Map())
    const [gridAreaString, setGridAreaString] = useState<string>('')
    return (
        <context.Provider value={{
            ...props,
            triggerRules, setTriggerRules, triggerPages, setTriggerPages, gridAreaString, setGridAreaString
        }}>
            {props.children}
        </context.Provider>
    )
}
export default CustomFormProvider