import React, { useState, useCallback } from 'react';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Stack } from '@fluentui/react/lib/Stack';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { useId } from '@uifabric/react-hooks';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles, ActionButton } from '@fluentui/react/lib/Button';
import '../../Custom.css';
import HelpComponent from '../Help/HelpComponent'
import { Modal } from '@fluentui/react/lib/Modal';
import {useBranding} from "../../hooks/useBranding";

const stackTokens = { childrenGap: 10 };
const theme = getTheme();


function SwitchSite(props: any) {
    const { branding } = useBranding()
    const onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined
        , option?: IChoiceGroupOption | undefined) => {
        props.updateSiteSelected(option!.key)
    }

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            width: '50vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyList: {
            width: '70vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <label className="ms-fontSize-18"  >Switch Site</label><br />
                        <div>This allows you to direct this product to a different company site.</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <label className="ms-fontSize-18">Switch Site</label>
                    </div>
                </div>
                : null
            }
            <Stack tokens={stackTokens}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <br/>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                    <ChoiceGroup selectedKey={props.siteSelected}
                                        options={props.siteOptions} onChange={onChange} title="Choose site." />
                                    <DialogFooter>
                                        <PrimaryButton onClick={() => { props.switchSite(props.siteSelected) }} text="Switch" title="Switch site to a different kim hosting source." />
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Cancel" title="Close." />
                                    </DialogFooter>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Stack>
        </>
    );
}

export default SwitchSite