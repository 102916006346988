import {IDropdownOption} from "@fluentui/react";
import i18n from "../../i18n/config";

export const subjects = (): IDropdownOption[] => {

    const {t} = i18n
  
    return [
        {
            key: 'Help with configuration', text: t('text.help1', {ns: 'support'})
        },
        {
            key: 'Help with billing', text: t('text.help2', {ns: 'support'})
        },
        {
            key: 'Help with registration', text: t('text.help3', {ns: 'support'})
        },
        {
            key: 'Help with licensing', text: t('text.help4', {ns: 'support'})
        },
        {
            key: 'Technical difficulty', text: t('text.help5', {ns: 'support'})
        },
        {
            key: 'Other', text: t('text.help6', {ns: 'support'})
        }
    ]
}