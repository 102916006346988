import { IMyListOption } from "../Helpers/Helper";
import { LoggingCatalogRequest, ApplicationLogsRequest, LogCatalog, APIEventLogRequest, APIEventLogStatus } from "../interfaces/IAdminLog";
import HttpRequest from "./HttpRequest";

export const DEFAULT_RECORDSPERPAGE = 20

const AdminLogsService = {
    getCatalog: async (request: LoggingCatalogRequest): Promise<LogCatalog[]> => {
        let { data }: any = await HttpRequest.getWithSession('DocumentOnlyLogging/Catalogs', { ...request })

        return new Promise<LogCatalog[]>((resolve, reject) => {
            let _settings: LogCatalog[] = [] as LogCatalog[]
            try {
                resolve(data)
            } catch {
                resolve(_settings)
            }
        })
    },
    getApplicationLogs: async (request: ApplicationLogsRequest) => {
        return HttpRequest.getWithSession('DocumentOnlyLogging/ApplicationLogs', { ...request, Download: false, RecordsPerPage: DEFAULT_RECORDSPERPAGE })
    },
    downloadApplicationLogs: async (request: ApplicationLogsRequest) => {
        return HttpRequest.getWithSession('DocumentOnlyLogging/ApplicationLogs', { ...request, Download: true })
    },
    getDocumentOnlyTemplates: async (): Promise<IMyListOption[]> => {
        let { data }: any = await HttpRequest.getWithSession('getListDocumentOnlyTemplate', { TemplateId: '', AccountIds: window.sessionStorage.getItem('accountId') })

        return new Promise<IMyListOption[]>((resolve, reject) => {
            var list: IMyListOption[] = []
            data.forEach((cat: any) => {
                cat.links!.forEach((tem: any) => {
                    if (tem.Owner || tem.CoOwner) {
                        let tempItem = list.find(item => item.label === `${tem.name} (${cat.name})`)
                        tempItem === undefined ?
                            list = [...list, { value: tem.key.toString(), label: `${tem.name} (${cat.name})` }] :
                            list = [...list, { value: tem.key.toString(), label: `${tem.name} (${cat.name}) (${tem.key.toString()})` }]
                    }
                })
            })
            resolve(list.sort((a, b) => {
                return a.label.toLocaleLowerCase() < b.label.toLowerCase() ? -1 : 1
            }));
        })
    },
    getAPIEventLogs: async (request: APIEventLogRequest) => {
        return HttpRequest.getWithSession('DocumentOnlyLogging/ApiEventLogs', { ...request, Download: false, RecordsPerPage: DEFAULT_RECORDSPERPAGE })
    },
    getApiEventLogsStatus: async () => {
        let { data }: any = await HttpRequest.getWithSession('DocumentOnlyLogging/ApiEventLogs/Status', { GetAPILogFlagOverride: true })

        return new Promise<APIEventLogStatus[]>((resolve, reject) => {
            let _settings: APIEventLogStatus[] = [] as APIEventLogStatus[]
            try {
                resolve(data)
            } catch {
                resolve(_settings)
            }
        })
    },
    changeApiEventLogsStatus:async (status: boolean) => {
        return await HttpRequest.postWithSession('DocumentOnlyLogging/ApiEventLogs/Activate', { APIActivateLogFlagOverride: status})
    },
    downloadAPIEventLogs: async (request: APIEventLogRequest) => {
        return HttpRequest.getWithSession('DocumentOnlyLogging/ApiEventLogs', { ...request, Download: true })
    }
}

export default AdminLogsService