import React, { useEffect, useState } from 'react';
import { Panel, PanelType, } from '@fluentui/react/lib/Panel';
import { useConstCallback } from '@uifabric/react-hooks';
import { isMobile } from 'react-device-detect';
import { DialogFooter, FontWeights, getTheme, Icon, IconButton, IContextualMenuItem, IContextualMenuProps, IIconProps, IIconStyles, IStackTokens, Label, mergeStyleSets, Modal, PrimaryButton, Separator, Stack } from '@fluentui/react';
import '../../web.css'
import { set } from 'lodash';
import Helper from '../../Helpers/Helper';
import {useBranding} from "../../hooks/useBranding";

const theme = getTheme();


const iconButtonNoteModalStyles = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };


function ErrorPage(props: any) {
    const { branding } = useBranding()
    const [showModalStatistics, setShowModalStatistics] = useState(true)

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        containerSaveModal: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: isMobile ? null : '30vw',
            height: '20vh',
        },
        containerMail: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width:'30vw',
            height: '20vh',
        },
        User: {
            width: '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const iconStyles: IIconStyles = {
        root: {
            fontSize: '18px',
            height: '18px',
            width: '18px',
            color: 'rgb(16, 110, 190)'
        },
    };
    const iconStyleVideos: IIconStyles = {
        root: {
            fontSize: '18px',
            height: '18px',
            width: '18px',
            color: branding.theme.palette.themePrimary,
        },
    };

    useEffect(() => {
        const url = new URL(window.location.href);
        const searchParams = url.searchParams;
        if (searchParams.get('tracking') !== null)
            window.location.assign(window.location.origin + "/app")
            
      }, [])

	const hideModalStatistics = () => {
        setShowModalStatistics(false)
    }

    const returnHome = async () => {
        window.location.assign(window.location.origin);
	}

    return (
<>
<div className="mainContainer">
            Custom Error
        </div>        
        <Modal
                isOpen={showModalStatistics}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.header}>
								<span>Error</span>
                                    <IconButton
                                        styles={iconButtonNoteModalStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideModalStatistics}
                                        title="Close."
                                    />
                                </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                                        
										Page not found
										
                                    </div>
                            </div>
                        </div>
                    </div>
                        <div className="ms-Grid-row">
                            <div style={{ paddingBottom: "10px", paddingRight: "12px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={returnHome} text="Retun to Home Page" title="Return to Home Page" />
                                </DialogFooter>
                            </div>
                        </div> 
                        
                </div>
            </Modal>
            </>
    );
}

export default ErrorPage

