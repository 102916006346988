import {IComboBoxOption, IDropdownOption, Text} from "@fluentui/react";
import React from "react";
import {DropdownMenuItemType} from "@fluentui/react/lib/Dropdown";

export const currencyDecimalLengthOptions = (): IComboBoxOption[] => {
    return (
        [
            {key: 0, text: "0"},
            {key: 1, text: "1"},
            {key: 2, text: "2"},
            {key: 3, text: "3"}
        ]
    )
}

export const currencyDecimalScaleOptions = (): IComboBoxOption[] => {
    return (
        [
            {key: 0, text: "0"},
            {key: 1, text: "1"},
            {key: 2, text: "2"},
            {key: 3, text: "3"}
        ]
    )
}

/**
 * Currency options
 */
export const currencyOptions = (): IComboBoxOption[] => {
    return (
        [
            {key: 'tradedCurrencies', text: 'Most Traded Currencies', itemType: DropdownMenuItemType.Header},
            {
                key: "AUD",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "AUD - Australian Dollar"
            },
            {
                key: "CAD",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "CAD - Canadian Dollar"
            },
            {
                key: "EUR",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "EUR - Euro"
            },
            {
                key: "JPY",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: ",",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000",
                },
                text: "JPY - Japanese Yen"
            },
            {
                key: "MXN",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "MXN - Mexican Peso"
            },
            {
                key: "NZD",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "NZD - New Zealand Dollar"
            },
            //todo check CHF simple quote                                     
            {
                key: "CHF",
                data: {
                    decimal: 2,
                    decimalSeparator: ".",
                    groupSeparator: "'",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100'000.99"
                },
                text: "CHF - Swiss Franc"
            },
            {
                key: "USD",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "USD - United States Dollar"
            },
            {
                key: "CNY",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "CNY - Yuan Renminbi"
            },
            {
                key: "GBP",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "GBP - Pound Sterling"
            },
            {key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider},
            {key: 'otherCurrencies', text: 'Other Currencies', itemType: DropdownMenuItemType.Header},
            //todo check all decimals                                         
            {
                key: "ALL",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: " ",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000"
                },
                text: "ALL - Albanian Lek"
            },
            {
                key: "DZD",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "DZD - Algerian Dinar"
            },
            {
                key: "ARS",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "ARS - Argentine Peso"
            },
            //todo check AMD group separator                                  
            {
                key: "AMD",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "AMD - Armenian Dram"
            },
            {
                key: "AZN",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "AZN - Azerbaijani Manat"
            },
            //todo check BHD, decimals                                        
            {
                key: "BHD",
                data: {
                    decimal: 3,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000.990"
                },
                text: "BHD - Bahraini Dinar"
            },
            //todo check BYN group separator                                  
            {
                key: "BYN",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "BYN - Belarusian Ruble"
            },
            {
                key: "BZD",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "BZD - Belize Dollar"
            },
            {
                key: "BOB",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "BOB - Boliviano"
            },
            {
                key: "BRL",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "BRL - Brazilian Real"
            },
            {
                key: "BND",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "BND - Brunei Dollar"
            },
            //todo BGN no group separator                                     
            {
                key: "BGN",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "BGN - Bulgarian Lev"
            },
            //todo CLP no decimals                                            
            {
                key: "CLP",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000"
                },
                text: "CLP - Chilean Peso"
            },
            {
                key: "COP",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "COP - Colombian Peso"
            },
            //todo CRC group separator                                        
            {
                key: "CRC",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "CRC - Costa Rican Colon"
            },
            //todo CZK group separator                                        
            {
                key: "CZK",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "CZK - Czech Koruna"
            },
            {
                key: "DKK",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "DKK - Danish Krone"
            },
            {
                key: "DOP",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "DOP - Dominican Peso"
            },
            {
                key: "EGP",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000.99"
                },
                text: "EGP - Egyptian pound"
            },
            {
                key: "ETB",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "ETB - Ethiopian Birr"
            },
            //todo GEL group separator                                        
            {
                key: "GEL",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "GEL - Georgian Lari"
            },
            {
                key: "GTQ",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "GTQ - Guatemalan Quetzal"
            },
            {
                key: "HNL",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "HNL - Honduran Lempira"
            },
            {
                key: "HKD",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "HKD - Hong Kong Dollar"
            },
            //todo  HUF group separator                                       
            {
                key: "HUF",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "HUF - Hungarian Forint"
            },
            //todo  ISK group separator and decimals                          
            {
                key: "ISK",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000"
                },
                text: "ISK - Icelandic Kkrona"
            },
            //todo  INR group separator                                       
            {
                key: "INR",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "1,00,000.99"
                },
                text: "INR - Indian Rupee"
            },
            {
                key: "IDR",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "IDR - Indonesian Rupiah"
            },
            //todo  IRR no decimals                                           
            {
                key: "IRR",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000"
                },
                text: "IRR - Iranian Rial"
            },
            //todo  IQD no decimals                                           
            {
                key: "IQD",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000"
                },
                text: "IQD - Iraqi Dinar"
            },
            {
                key: "PKR",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "PKR - Pakistani Rupee"
            },
            {
                key: "ILS",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "ILS - Israeli New Shekel"
            },
            {
                key: "JMD",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "JMD - Jamaican Dollar"
            },
            //todo  JOD three decimals                                        
            {
                key: "JOD",
                data: {
                    decimal: 3,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000.990"
                },
                text: "JOD - Jordanian Dinar"
            },
            //todo KZT group separator                                        
            {
                key: "KZT",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "KZT - Kazakhstani Tenge"
            },
            {
                key: "KES",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "KES - Kenyan Shilling"
            },
            //todo KRW no decimals                                            
            {
                key: "KRW",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000"
                },
                text: "KRW - Korean Won"
            },
            //todo KWD three decimals                                         
            {
                key: "KWD",
                data: {
                    decimal: 3,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000.990"
                },
                text: "KWD - Kuwaiti Dinar"
            },
            //todo kgs group separator                                        
            {
                key: "KGS",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "KGS - Kyrgyzstani Som"
            },
            //todo LBP no decimals                                            
            {
                key: "LBP",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000"
                },
                text: "LBP - Lebanese Pound"
            },
            //todo LYD three decimals                                         
            {
                key: "LYD",
                data: {
                    decimal: 3,
                    decimalSeparator: ".",
                    groupSeparator: ",",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.990"
                },
                text: "LYD - Libyan Dinar"
            },
            //todo  MOP does not exist                                        
            {
                key: "MOP",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "MOP - Macao Pataca"
            },
            {
                key: "MKD",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "MKD - Macedonian Denar"
            },
            {
                key: "MYR",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "MYR - Malaysian Ringgit"
            },
            {
                key: "MVR",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "MVR - Maldivian Rufiyaa"
            },
            {
                key: "MNT",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "MNT - Mongolian Tugrik"
            },
            {
                key: "MAD",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "MAD - Moroccan Dirham"
            },
            {
                key: "NIO",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "NIO - Nicaraguan Cordoba Oro"
            },
            //todo  NOK group separator                                       
            {
                key: "NOK",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "NOK - Norwegian Krone"
            },
            //todo  OMR three decimals                                        
            {
                key: "OMR",
                data: {
                    decimal: 3,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000.990"
                },
                text: "OMR - Omani Rial"
            },
            {
                key: "PAB",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "PAB - Panamanian Balboa"
            },
            //todo check PYG no decimals                                      
            {
                key: "PYG",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000"
                },
                text: "PYG - Paraguayan Guarani"
            },
            {
                key: "PEN",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "PEN - Peruvian Nuevo Sol"
            },
            {
                key: "PHP",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "PHP - Philippine Peso"
            },
            //todo check PLN group separator                                  
            {
                key: "PLN",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "PLN - Polish Złoty"
            },
            {
                key: "QAR",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000.99"
                },
                text: "QAR - Qatari Rial"
            },
            {
                key: "RON",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "RON - Romanian Leu"
            },
            //todo check RUB group separator                                  
            {
                key: "RUB",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "RUB - Russian Ruble"
            },
            {
                key: "SAR",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000.99"
                },
                text: "SAR - Saudi Riyal"
            },
            //todo check RSD no decimals                                      
            {
                key: "RSD",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000"
                },
                text: "RSD - Serbian Dinar"
            },
            {
                key: "SGD",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "SGD - Singapore Dollar"
            },
            //todo check ZAR group separator                                  
            {
                key: "ZAR",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "ZAR - South African Rand"
            },
            //todo check ZAR group separator                                  
            {
                key: "SEK",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "SEK - Swedish Krona"
            },
            //todo check SYP decimals                                         
            {
                key: "SYP",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000"
                },
                text: "SYP - Syrian Pound"
            },
            // todo TWD does exist?                                           
            {
                key: "TWD",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "TWD - New Taiwan Dollar"
            },
            {
                key: "THB",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "THB - Thai Baht"
            },
            {
                key: "TTD",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.99"
                },
                text: "TTD - Trinidad and Tobago Dollar"
            },
            //todo  check TND decimals                                        
            {
                key: "TND",
                data: {
                    decimal: 3,
                    decimalSeparator: ".",
                    groupSeparator: ",",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100,000.990"
                },
                text: "TND - Tunisian Dinar"
            },
            {
                key: "TRY",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "TRY - Turkish Lira"
            },
            //todo  check UAH group separator                                 
            {
                key: "UAH",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "UAH - Ukrainian Hryvnia"
            },
            {
                key: "AED",
                data: {
                    decimal: 2,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000.99"
                },
                text: "AED - United Arab Emirates Dirham"
            },
            {
                key: "UYU",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "UYU - Uruguayan peso"
            },
            //todo  check UZS decimals and  group separator                   
            {
                key: "UZS",
                data: {
                    decimal: 2,
                    decimalSeparator: ",",
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000,99"
                },
                text: "UZS - Uzbekistan Som"
            },
            //todo  check VND decimals                                        
            {
                key: "VND",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: ".",
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100.000"
                },
                text: "VND - Vietnamese Dong"
            },
            //todo  check YER decimals                                        
            {
                key: "YER",
                data: {
                    decimal: 0,
                    decimalSeparator: undefined,
                    groupSeparator: undefined,
                    prefix: undefined,
                    suffix: undefined,
                    CurrencySubstrate: "100٬000"
                },
                text: "YER - Yemeni Rial"
            },

        ])
}

/**
 * Function onRenderOption for Currency DropdownList
 * @param option
 */
export const onCurrencyRenderOption = (option?: IDropdownOption): JSX.Element => {
    return (
        <span><Text variant={"medium"} styles={{root: {fontWeight: "500"}}}>{option?.key}</Text> - <Text
            variant={"medium"}>{option?.text}</Text></span>
    )
}
