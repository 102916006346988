import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupProps } from "@fluentui/react";
import { ReactHookFormControlProps } from "../CustomTemplates/CustomForm/Models/ReactHookFormControlProps";

export const RHFChoiceGroupControl: FC<ReactHookFormControlProps & IChoiceGroupProps> = (props) => {
    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error }
            }) => (
                <>
                    <ChoiceGroup
                        id={props.id}
                        label={props.label}
                        title={props.title}
                        options={props.options}
                        required={props.required}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
                            props.setValue(props.id, Object.assign({}, props.getValues(props.id) as IChoiceGroupOption, option))
                            props.trigger(props.id)
                            if (props.onCallback !== null && props.onCallback !== undefined) {
                                props.onCallback(ev, option)
                            }
                        }}
                        styles={props.styles}
                        selectedKey={props.getValues(props.id) as IChoiceGroupOption ? props.getValues(props.id).key : props.defaultValue}
                        tabIndex={props.tabIndex}
                        hidden={props.hidden}
                    />
                    {props.errors !== undefined && props.errors[props.id] !== undefined && props.errors[props.id].message.length > 0 ?
                        <span style={{ fontSize: "14px", color: "#973434", fontWeight: 600 }}>{props.errors[props.id].message}</span>
                        : null
                    }
                </>
            )}
        />
    );
};