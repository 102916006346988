import React, { useState, useEffect } from 'react';
import Helper, { IChangeOwerRequestRequest, IDocumentOnlyAudienceDetail, IDocumentOnlyAudienceRequest } from '../../Helpers/Helper';
import { DialogFooter, Dropdown, IDropdownOption, IIconProps, IMessageBarStyles, MessageBar, Modal, TextField } from '@fluentui/react';
import { IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { isMobile } from 'react-device-detect';
import ConfirmationModal from '../../Helpers/Modals/ConfirmationModal';
import {useBranding} from "../../hooks/useBranding";


const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};

function ChangeOwner(props: any) {
    const { branding } = useBranding()
    const [selectedClient, setSelectedClient] = useState<IDropdownOption>();
    const [showChangeOwnerConfirmationModal, setShowChangeOwnerConfirmationModal] = useState(false)
    const [requiredEmail, setrequiredEmail] = useState(false)
    const [newOwnerUserName, setNewOwnerUserName] = useState("");
    const [chaneOwnerUserName, setChaneOwnerUserName] = useState("");

    //Messages
    const [errorMessage, setErrorMessage] = useState("")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [message, setMessage] = useState("")


    const [audienceList, setAudienceList] = useState<IDocumentOnlyAudienceDetail[]>([]);
    const [audienceUsers, setAudienceUsers] = useState<IDocumentOnlyAudienceDetail[]>([]);

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '50vw',
            height: '50vh',
            flex: '4 4 auto',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        listDialog: {
            width: isMobile ? null : '30vw',
            height: '50vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        addItemList: {
            width: isMobile ? null : '35vw',
            height: '60vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        buttonArea: {
        },
        callout: {
            maxWidth: 300,
        },
        inner: {
            height: '100%',
            padding: '0 24px 20px',
        }
    });


    useEffect(() => {
        if(props.showChangeOwnerModal) {
        //if (props.audienceList.length == 0) {
        setAudienceList([])
        setSelectedClient({ key: 0, text: "" })
        loadAudience();
        // }
        }
    }, [props.showChangeOwnerModal])

    const loadAudience = async () => {
        const req: IDocumentOnlyAudienceRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceMasterId: "-1",
            FromRegistration: false
        }
        await Helper.GetDocumentOnlyAudienceDetailByUserName(req).then(data => {
            data.forEach(item => {
                loadUsers(item.DocumentOnlyAudienceMasterId)
            })
            setAudienceList(data)
        })
    }

    const loadUsers = async (id: number) => {
        const req: IDocumentOnlyAudienceRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceMasterId: id.toString() || "-1",
            FromRegistration: false
        }
        await Helper.GetDocumentOnlyAudienceDetailByAudienceMasterId(req).then(data => {
            let tempAudiences: any[] = [];
            tempAudiences.concat(data).concat(audienceUsers).filter(function (item) {
                if (item.UserName != Helper.getUsername()) {
                    var i = tempAudiences.findIndex(x => (x.UserName == item.UserName))
                    if (i <= -1) {
                        tempAudiences.push(item);
                    }
                }

                return null;
            });
            setAudienceUsers(tempAudiences)
        })
    }

    const onChangeClient = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setSelectedClient(option)
    };

    const changeAudience = async () => {
        const req: IChangeOwerRequestRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceId: props.audienceMasterId > 0 ? props.audienceMasterId : 0,
            ChangeOwnerUserName: props.isAdministration ? chaneOwnerUserName : Helper.getUsername(),
            NewOwnerUserName: props.isAdministration ? newOwnerUserName : selectedClient?.text.toString(),
        }

        Helper.changeOwner(req).then(data => {
            setShowChangeOwnerConfirmationModal(false)
            props.closeChangeOwnerModal()
        });
    };

    const displayChangeOwnerConfirmationModal = () => {
        setShowChangeOwnerConfirmationModal(true)
    }

    const getErrorMessageEmail = async (value: string): Promise<string> => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length > 0) {
            if (!value.match(mailformat)) {
                setrequiredEmail(false)
                return "Email must be valid.";
            }
            setrequiredEmail(true)
        }
        return ""
    };

    return (
        <>
            <ConfirmationModal
                showConfirmationModal={showChangeOwnerConfirmationModal}
                setShowConfirmationModal={setShowChangeOwnerConfirmationModal}
                callbackConfirmationModal={changeAudience}
                //args={[respondent.Id, props.campaignId]}
                title="Delete Respondent"
                message={"Change Owner will transfer ownership of your audiences(s), records and campaigns to specified target username.  Target username must exist on audience(s) where you want transfer to occur; otherwise, transfer will not occur for audience(s) where specified target username does not exist. This action is permanent and action is recorded to logs.  You will automatically signed-out when this action completes.  Do you want to transfer ownership to " + newOwnerUserName + " and accept these conditions at this time?"}
                titleYesButton="Confirm that you want to delete the respondent."
            />
            {props.isAdministration ?
                <>

                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                            <label className="ms-fontSize-18">Change Owner</label><br />
                            <div style={{ color: "red" }}>Change Owner will transfer ownership of your audiences(s), records and campaigns to specified target username.</div>
                        </div>
                    </div>

                    <TextField label="New Owner User Name" maxLength={100} onGetErrorMessage={getErrorMessageEmail} required onChange={(_, v) => { setNewOwnerUserName(v!); }} value={newOwnerUserName} title="New Owner User Name." />
                    <TextField label="Change Owner User Name" maxLength={100} onGetErrorMessage={getErrorMessageEmail} required onChange={(_, v) => { setChaneOwnerUserName(v!); }} value={chaneOwnerUserName} title="Old Owner User Name." />
                    <br />
                    <PrimaryButton disabled={requiredEmail ? false : true} text="Change" title="Change" onClick={displayChangeOwnerConfirmationModal} />
                </> :
                <Modal
                    isOpen={props.showChangeOwnerModal}
                    isBlocking={true}>
                    <div className={contentStyles.header}>
                        <span id="CloseModalAudience" key="CloseModalAudience">Change Owner</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup "
                            onClick={props.closeChangeOwnerModal}
                            title="Close."
                        />
                    </div>
                    <div className={contentStyles.addItemList}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{errorMessage}</MessageBar> : null}
                            {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                        </div>
                        <div style={{ color: "red" }}>
                            Change Owner will transfer ownership of your audiences(s), records and campaigns to specified target username.
                        </div>

                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", paddingRight: "10px", maxWidth: "100%", height: "60vh" }}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className="ms-Grid-row">
                                            <Dropdown
                                                id="Client"
                                                label="Client"
                                                options={audienceUsers.map<IDropdownOption>((item: any) => {
                                                    return {
                                                        key: item.Id,
                                                        text: item.UserName
                                                    }
                                                })}
                                                onChange={onChangeClient}
                                                selectedKey={selectedClient?.key}
                                                title="Select client."
                                            />
                                        </div>
                                        <div className="ms-Grid-row">
                                            <DialogFooter>
                                                <PrimaryButton text="Change" title="Change" onClick={displayChangeOwnerConfirmationModal} />
                                            </DialogFooter>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>}

        </>

    )
}

export default ChangeOwner