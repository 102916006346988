import React, { useEffect, useState } from 'react';
import { Modal } from '@fluentui/react/lib/Modal';
import { getTheme, FontWeights, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { useId } from '@uifabric/react-hooks';
import '../../Custom.css';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { DialogFooter } from "@fluentui/react/lib/Dialog"
import Helper, { IUpdateDocumentOnly } from '../../Helpers/Helper';
import { IComboBoxOption, IMessageBarStyles, MessageBar, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import {useTranslation} from "react-i18next";
import {useBranding} from "../../hooks/useBranding";


const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
        },
    },
    
};

const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};


let categories: ITemplates[] = []
const cancelIcon: IIconProps = { iconName: 'Cancel' };

export interface ITemplates {
    templateId: number,
    userName: string
    name: string,
    expandAriaLabel: string,
    collapseAriaLabel: string
}
const optionAudience: IComboBoxOption[] = [
    { key: "General", text: "General" },
    { key: "Management", text: "Management" }
];

var INITIAL_OPTIONS: IComboBoxOption[] = []
var classmodal = 'bodyModalMax';
function UploadDocument(props: any) {
    const { branding } = useBranding()
    const [uploadingFile, setUploadingFile] = useState(false);
    const titleId = useId("Upload a custom Template");
    const [fileName, setFileName] = useState("");
    const [requiredUploadFile, setRequiredUploadFile] = useState(false)
    const [base64File, setBase64File] = useState<string>("");
    const [mQuery, setMQuery] = React.useState<any>({ matches: window.innerHeight > 660 ? true : false, });
    const [showMessage, setShowMessage] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [message, setMessage] = useState("");
    const {t} = useTranslation(['common', 'checkinout'])

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '30vw',
            height: '40vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        }
    });


    const getBase64 = (file: File) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64File(reader.result!.toString().split(',')[1]);
          };
          reader.onloadend = () => {
            setRequiredUploadFile(true)
        }
     }

    async function uploadFile(ev: React.ChangeEvent<HTMLInputElement>) {
        setUploadingFile(true)
        if (ev.target.files != null && ev.target.files.length > 0) {
            setRequiredUploadFile(true)
            var name = ev.target.files![0].name;
            setFileName(name);
            getBase64(ev.target.files![0]);
        }
        else {

            setRequiredUploadFile(false)
        }
        setUploadingFile(false)
    }

    async function goToBuilder() {
        setRequiredUploadFile(false)
        
        //props.setToggleUploadDoc(false)
        const request: IUpdateDocumentOnly = {
            ApiKey: "-1",
            AccountId: props.accountId,
            Autoprovision: true,
            Content: base64File!,
            DocumentId: props.documentId.toString(),
            TemplateId: props.templateId.toString(),
            Path: await Helper.getSite(),
            SK: props.sessionKey,
            UserName: props.username
        }

        
        await Helper.updateDocumentOnly(request).then(data => {
            if (data !== undefined && data !== null) {
                try {
                    if (data.Success) {
                        props.checkIncheckOut(false)
                        props.setToggleUploadDoc(false)
                        setFileName("")
                        props.update()
                    }
                    else {
                        setShowMessage(true)
                        setMessage(data.ExceptionMessage)
                        setTimeout(function () {
                            setShowMessage(false)
                        }, 5000);
                    }
                }
                catch (error) {
                    setShowMessage(true)
                    setMessage(error)
                    setTimeout(function () {
                        setShowMessage(false)
                    }, 5000);
                }
            }
        })
    }

    if (mQuery.matches) {
        classmodal = 'bodyModalMin'
    }
    else {
        classmodal = 'bodyModalMax';
    }

    return (
        <Modal
            isOpen={props.showUploadDoc}
            isBlocking={true}>
            <div className={contentStyles.header}>
                <span id={titleId}>{t('title.modalUpload', { ns: 'checkinout'})}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    title={t('Common.Button.Close', { ns: 'common'})}
                    ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                    onClick={() => {
                        setFileName("")
                        props.setToggleUploadDoc(false)
                    }}
                />
            </div>
            <div className={contentStyles.body}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                            {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ paddingTop:"10px", paddingBottom:"10px"}}>
                                    <span>
                                        {t('text.upload', { ns: 'checkinout'})}
                                    </span>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <label htmlFor="file"><div className="divHover divToolBoxItem" style={{color: branding.theme.palette.white, backgroundColor: branding.theme.palette.themePrimary}}>{t('Common.Input.File', { ns: 'common'})}</div></label>
                                    <input className="fileButton" id="file" type="file" name="file" onChange={uploadFile} placeholder={t('placeholder.file', { ns: 'common'})} title={t('title.file', { ns: 'common'})} />
                                </div>
                                <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ paddingRight: "0px" }}>
                                    <TextField disabled={true} value={fileName} title={t('title.upload', { ns: 'checkinout'})} placeholder={t('placeholder.file', { ns: 'common'})} />
                                </div>
                            </div>
                            {uploadingFile ?
                                <ProgressIndicator description={t('text.spinnerUploading', { ns: 'common'})} />
                                : null
                            }
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton disabled={!requiredUploadFile} onClick={goToBuilder} text={t('Common.Button.Upload', { ns: 'common'})} title={t('title.btnUpload', { ns: 'checkinout'})} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default UploadDocument