import { FC } from "react";
import { Controller } from "react-hook-form";
import { DatePicker, IconButton, IDatePickerProps } from "office-ui-fabric-react";
import * as React from "react";
import { HookFormProps } from "./HookFormProps";
import { ITooltipUrl } from "./ControllerRichText";
import { formatSimpleDate } from "../Helpers/DateUtils";

export const ControlledDatePickerField: FC<HookFormProps & IDatePickerProps & ITooltipUrl> = (
  props
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || undefined}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => (
        <>
          <div style={{ display: 'flow-root' }}>
            <label htmlFor={props.name} style={{ float: 'left', fontSize: 14, fontFamily: "Segoe UI Web (West European)", fontWeight: "normal", }}>{props.label}</label>
            {props.isRequired ? <span style={{ float: 'right', color: "#973434" }}>*</span> : null}
            {(props.tooltipUrl === "" || props.tooltipUrl === undefined || props.tooltipUrl === null) ? null :
              <IconButton onClick={() => { window.open(props.tooltipUrl, '_blank') }} id={"icon" + props.id} styles={{ root: { float: 'right', marginBottom: -3 } }} iconProps={{ iconName: 'Info' }} title={props.tooltipUrl} ariaLabel="Info" />}
          </div>
          <DatePicker
            title={props.title}
            formatDate={props.formatDate}
            borderless={props.borderless}
            className={props.className}
            hidden={props.hidden}
            isMonthPickerVisible={props.isMonthPickerVisible}
            tabIndex={props.tabIndex}
            styles={props.styles}

            id={props.id}
            //{...props}
            textField={{
              name: fieldName,
              //onChange,
              //onBlur,
              value: value !== undefined && value !== "" ? formatSimpleDate(value, 'LL', 'en-US') : undefined,
              errorMessage: error && error.message
            }}
            onSelectDate={(date) => {
              onChange(formatSimpleDate(date, 'LL', 'en-US'));
            }}
            //value={new Date()}
            onBlur={onBlur}
            defaultValue={undefined}
          />
        </>
      )}
    />
  );
};
