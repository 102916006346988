import React, { HTMLAttributes, useEffect, useState } from 'react';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Modal } from '@fluentui/react/lib/Modal';
import { Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { Separator } from '@fluentui/react/lib/Separator';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IIconProps, FontIcon } from '@fluentui/react/lib/Icon';
import { DatePicker } from '@fluentui/react/lib/DatePicker';
import { TextField } from '@fluentui/react/lib/TextField';
import {
    Checkbox,
    DirectionalHint,
    IScrollablePaneStyles,
    Label,
    Panel,
    PanelType,
    StackItem,
    TooltipHost
} from '@fluentui/react';
import Helper, { IAdvanceSearch, IMyListOption, SearchTemplateListRequest } from '../../Helpers/Helper';
import HelpComponent from '../Help/HelpComponent';
import { isMobile } from 'react-device-detect';
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import ReactDOM from 'react-dom';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import HelpComponentRelative from "../Help/HelpComponentRelative";
import { formatFromUTC, formatSimpleDate } from '../../Helpers/DateUtils';
import {useBranding} from "../../hooks/useBranding";

const iconButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: '32px', backgroundColor: "white", borderBottom: "1px solid black" } };
const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const iconButtonModalStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};


const stackTokens = { childrenGap: 5 };
function Search(props: any) {
    const { branding } = useBranding()
    const { t } = useTranslation(['common', 'searchComponent']);
    //console.log(props)
    const [showModal, setshowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState("Advanced Search")
    const [endUserName, setEndUserName] = useState("")
    const [dateProcessedFrom, setProcessedFrom] = useState<Date | null>();
    const [dateProcessedTo, setProcessedTo] = useState<Date | null>();
    const [dateCreatedFrom, setCreatedFrom] = useState<Date | null>();
    const [dateCreatedTo, setCreatedTo] = useState<Date | null>();
    const [description, setDescription] = useState("")
    const [template, setTemplate] = useState<IMyListOption | null>(null);
    const [searchNotes, setSearchNotes] = useState("")
    const [includeClosed, setIncludeClosed] = useState(false)
    const [includeAttachments, setIncludeAttachments] = useState(false)
    const [content, setContent] = useState("")
    const [templateList, setTemplateList] = useState<IMyListOption[]>([]);
    const [campaignList, setCampaignList] = useState<IMyListOption[]>([])
    const [campaign, setCampaign] = useState<IMyListOption | null>(null)
    const [clearAdvanced, setclearAdvanced] = useState(false)
    const [activity, setActivity] = useState("")
    const isAdvanceSearch = (sessionStorage.getItem("tempAdvanceSearch") != "" && sessionStorage.getItem("tempAdvanceSearch") != null) ? true : false
    const [searched, setSearched] = useState(isAdvanceSearch)
    //const [templateID, settemplateID] = useState("")


    const panelHeader: HTMLAttributes<HTMLDivElement> = {
        style: {
            fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
            fontWeight: "bolder",
            color: branding.theme.palette.themePrimary,
            fontSize: 20
        }
    }
    
    const customDropdownStyles = {
        control: (styles: any, state: any) => ({
            ...styles,
            borderRadius: 1,
            borderColor: state.isFocused ? "rgb(0, 120, 212)" : "rgb(96, 94, 92)",
            minHeight: 32,
        }),
        option: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? branding.theme.semanticColors.listItemBackgroundChecked
                    :  undefined,
                color:  isSelected
                    ? branding.theme.palette.black
                    :  branding.theme.palette.black
            }
        },
        valueContainer: (styles: any) => ({
            ...styles,
            paddingTop: 0,
        }),
        menu: (styles: any) => ({
            ...styles,
            marginTop: 0,
        }),
        menuList: (styles: any) => ({
            ...styles,
            padding: 0,
        }),
    };


    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '50vw',
            height: '50vh',
            flex: '4 4 auto',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyVWOverride: {
            width: '90%',
            height: '50vh',
            flex: '4 4 auto',
            padding: '0 0px 0px 18px',
        },
        bodyVWOverridePanel: {
            width: '90%',
            height: '62vh',
            flex: '4 4 auto',
            // padding: '5px 0px 0px 5px',.
            padding: '24px 40px 24px 24px',
        },
        User: {
            width: '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        buttonArea: {

        },
        callout: {
            maxWidth: 300,
        },
        inner: {
            height: '100%',
            padding: '0 24px 20px',
        },
        wrapper: {
            height: '62vh',
            position: 'relative',
            maxHeight: 'inherit',

        },
        pane: {
            maxWidth: ' 100%',
            // border: '1px solid ' + theme.palette.neutralLight,
        },
        sticky: {
            color: theme.palette.black,
            padding: '5px 20px 5px 10px',
            fontSize: '13px',
            borderTop: '1px solid ' + theme.palette.black,
            borderBottom: '1px solid ' + theme.palette.black,
        },
    });
    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: contentStyles.pane };


    const hideModal = () => {
        setshowModal(false)
    }
    const cleanFiels = () => {
        if (props.clearAdvanced) {
            var rqAdvanceSearch = sessionStorage.getItem("tempAdvanceSearch");

            if (rqAdvanceSearch !== null && rqAdvanceSearch !== "") {

                var result = JSON.parse(sessionStorage.getItem("tempAdvanceSearch")!) as IAdvanceSearch
                //console.log(result)
                //  if(result.TemplateId!=0){
                //  settemplateID(result.TemplateId.toString())
                //}
                setDescription(result.Description)
                setEndUserName(result.EndUserName)
                if ((result.DateProcessedFrom !== undefined && result.DateProcessedFrom !== null) || (result.DateProcessedTo !== undefined && result.DateProcessedTo !== null)) {
                    var dateProcessedFrom = new Date(result.DateProcessedFrom)
                    var dateProcessedTo = new Date(result.DateProcessedTo)

                    setProcessedFrom(dateProcessedFrom)
                    setProcessedTo(dateProcessedTo)
                }

                if ((result.DateCreatedFrom !== undefined && result.DateCreatedFrom !== null) || (result.DateCreatedTo !== undefined && result.DateCreatedTo !== null)) {
                    var dateCreatedFrom = new Date(result.DateCreatedFrom)
                    var dateCreatedTo = new Date(result.DateCreatedTo)

                    setCreatedFrom(dateCreatedFrom)
                    setCreatedTo(dateCreatedTo)
                }

                setSearchNotes(result.SearchNotes)
                setIncludeClosed(result.IncludeClosed)
                setContent(result.Content)
            }
        }
    }


    async function advancedSearch() {
        await loadTemplates()
        setModalTitle(t("Search.ModalTitle", { ns: 'searchComponent' }))
        setshowModal(true)
        if (isMobile) {
            let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneSearchMobile")) as Element
            if (element !== null) {
                element.children[1].classList.add("scrollVisible")
                element.children[1].classList.add("scrollVisibleMobile")
            }
        }
    }

    const loadTemplates = async () => {
        let request: SearchTemplateListRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
        }

        var list = await Helper.getSearchTemplates(request)

        var arr: IMyListOption[] = []
        list.forEach((e: any) => {
            arr.push({
                value: e.TemplateId.toString(),
                label: e.Category + " - " + e.DisplayName
            })
        });

        arr.sort((a: IMyListOption, b: IMyListOption) => {
            return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
        })


        setTemplateList(arr)
        setTemplate(template)

    }

    const loadCampaignsByTemplateId = async (templateId: string) => {
        const response = await Helper.getCampaignListByTemplateId(templateId, 0, 0)

        var arr: IMyListOption[] = []
        response.sort((a: any, b: any) => {
            return a.Name < b.Name ? -1 : 1;
        }).sort((a: any, b: any) => {
            return formatSimpleDate(a.DateCreated, 'LL') > formatSimpleDate(b.DateCreated, 'LL') ? -1 : 1;
        }).forEach((e: any) => {
            arr.push({
                value: e.Id.toString(),
                label: e.Name + "(" + formatSimpleDate(e.DateCreated,'LL') + ")",
            })
        });

        setCampaignList(arr)
        setCampaign(null)
    }

    const advanceSearch = async () => {
        setSearched(true)
        setclearAdvanced(true)
        sessionStorage.setItem("documentsLoaded", "false")
        var isSearchActive = sessionStorage.getItem("isSearchActive")!
        var isAdvanceSearch = (sessionStorage.getItem("tempAdvanceSearch") != "" && sessionStorage.getItem("tempAdvanceSearch") != null) ? true : false
        var page = 1//parseInt( sessionStorage.getItem("pageCount")!)    
        // if(isAdvanceSearch){
        //     page=page+1
        // }
        // else{
        //     page=1
        // }
        sessionStorage.setItem("pageCount", page.toString())

        sessionStorage.setItem("tempDocs", "")
        var tempId = template?.value !== undefined ? template.value : 0
        props.toggleLoadComplet(true)
        const request: IAdvanceSearch = {
            EndUserName: endUserName,
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            TemplateId: Number(tempId),
            Content: content,
            Description: description,
            DateProcessedFrom: dateProcessedFrom!,
            DateProcessedTo: dateProcessedTo!,
            DateCreatedFrom: dateCreatedFrom!,
            DateCreatedTo: dateCreatedTo!,
            IncludeClosed: includeClosed,
            SearchNotes: searchNotes,
            ApiKey: "-1",
            PageNo: page,
            RecordsPerPage: 20,
            Path: await Helper.getSite(),
            Autoprovision: true,
            ContentSearch: GetContentSearch(),
            CampaignId: campaign !== null ? Number(campaign.value) : -1,
            Activity: activity


        }
        sessionStorage.setItem("tempAdvanceSearch", JSON.stringify(request))
        props.onAdvanceSearch(request)
        //console.log("advanceSearch")
        cleanFiels()
        hideModal()

    }

    const GetContentSearch = (): number => {
        if (content.length == 0 && includeAttachments)
            return 1
        else if (content.length > 0 && includeAttachments)
            return 2
        else
            return 0
    }

    const onFormatDate = (date?: Date): string => {
        const locale = !!sessionStorage.getItem("language") ? sessionStorage.getItem("language")!.toString() : 'en'

        return date !== null ? formatFromUTC(date, 'LL', locale) : null;

        //return date !== null ? Helper.getLocateDate(date!.toLocaleDateString("en-US"), 1) : "";
    };

    function clearSearch() {
        sessionStorage.setItem("pageCount", "0")
        sessionStorage.setItem("tempAdvanceSearch", "")
        props.toggleSearchActive(false)
        setProcessedFrom(null)
        setProcessedTo(null)
        setCreatedFrom(null)
        setCreatedTo(null)
        // settemplateID("")
        setDescription("")
        setEndUserName("")
        setSearchNotes("")
        setIncludeClosed(false)
        setContent("")
        setTemplate(null)
        setCampaignList([])
        setCampaign(null)
        setSearched(false);
        setclearAdvanced(false)
        setActivity("")
        cleanTemplate()
        props.onSearchClear();


    }
    const onTemplateChange = async (newValue: SingleValue<IMyListOption>, actionMeta: ActionMeta<IMyListOption>) => {
        if (newValue !== null) {
            setTemplate(newValue)
            loadCampaignsByTemplateId(newValue.value)
        } else {
            cleanTemplate()
        }

    };

    const onCampaignChange = async (newValue: SingleValue<IMyListOption>, actionMeta: ActionMeta<IMyListOption>) => {
        if (newValue !== null) {
            setCampaign(newValue)
        } else {
            cleanCampaign()
        }

    }
    const onSearchBoxChange = async (value: any) => {
        if (value.trim().length == 0)

            sessionStorage.setItem("documentsLoaded", "false")
        sessionStorage.setItem("pageCount", "0")
        sessionStorage.setItem("tempDocs", "")
        sessionStorage.setItem("isSearchActive", "true")
        props.onSearch(value)
        //console.log("onSearchBoxChange")




    };
    const cleanCreatedFrom = React.useCallback((): void => {
        setCreatedFrom(undefined);
    }, []);
    const cleanCreatedTo = React.useCallback((): void => {
        setCreatedTo(undefined);
    }, []);
    const cleanProcessedFrom = React.useCallback((): void => {
        setProcessedFrom(undefined);
    }, []);

    const cleanProcessedTo = React.useCallback((): void => {
        setProcessedTo(undefined);
    }, []);
    const cleanTemplate = () => {
        setTemplate(null)
        cleanCampaign()
        setCampaignList([])
    };

    const cleanCampaign = () => {
        setCampaign(null)
    }

    const onSearch = async (value: any) => {
        if (value.trim().length !== 0) {
            sessionStorage.setItem("documentsLoaded", "false")
            sessionStorage.setItem("pageCount", "0")
            sessionStorage.setItem("tempDocs", "")
            sessionStorage.setItem("isSearchActive", "true")
            props.setSearchBoxValue(value)
            props.onSearch(value)

        }
    };
    const onChangeSearch = async (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
        props.setSearchBoxValue(newValue)
        if (newValue === "") {
            props.onSearchClear()
        }
    }
    useEffect(() => {
        setSearched(isAdvanceSearch)

    }, [isAdvanceSearch])

    return (
        <>

            {searched ?
                <IconButton
                    id='clearFilter'
                    menuIconProps={{ iconName: 'ClearFilter' }}
                    role="button"
                    aria-haspopup
                    aria-label={t('label.clearSearch', { ns: 'searchComponent' })}
                    styles={iconButtonStyles}
                    onClick={clearSearch}
                    title={t('title.clearSearch', { ns: 'searchComponent' })}
                />
                : null
            }
            <IconButton
                menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                role="button"
                aria-haspopup
                aria-label={t('label.search', { ns: 'searchComponent' })}
                styles={iconButtonStyles}
                onClick={advancedSearch}
                title={t('title.search', { ns: 'searchComponent' })}
                id="advanceSearchButton"
            />
            <SearchBox
                id="Search"
                placeholder={t("Search.Input.Searchplaceholder", { ns: 'searchComponent' })}
                title={t("Search.Input.SearchBoxTitle", { ns: 'searchComponent' })}
                underlined={true}
                onSearch={newValue => onSearch(newValue)}
                onClear={props.onSearchClear}
                maxLength={500}
                onChange={onChangeSearch}
                value={props.searchBoxValue}
            />
            {!isMobile ?
                <Modal
                    isOpen={showModal}
                    isBlocking={true}>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Stack horizontal className={contentStyles.header}>
                                    <StackItem grow={1}>
                                        <Text variant={"xLarge"} id={modalTitle}>{modalTitle}</Text>
                                    </StackItem>
                                    <StackItem>
                                        <HelpComponentRelative
                                            headline={t('title.title', { ns: 'searchComponent' })}
                                            text={t('text.help', { ns: 'searchComponent' })}
                                            right="50px"
                                            top="15px"
                                            location="search"
                                        />
                                        <IconButton
                                            styles={iconButtonModalStyles}
                                            iconProps={cancelIcon}
                                            title={t('Common.Button.Close', { ns: 'common' })}
                                            ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                            onClick={hideModal}
                                        />
                                    </StackItem>

                                </Stack>
                                <Separator />
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <div className={contentStyles.bodyVWOverride}>
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <Stack tokens={stackTokens}>
                                                        <TextField label={t("Search.Input.Searchplaceholder", { ns: 'searchComponent' })} value={content} maxLength={500} onChange={(_, v) => setContent(v!)} title={t("Search.Input.SearchContentTitle", { ns: 'searchComponent' })} />
                                                        <Checkbox styles={{ root: { position: "absolute", paddingLeft: "60px" } }} id="includeAttachments" label={t("Search.Checkbox.includeAttachments", { ns: 'searchComponent' })} title={t("Search.Checkbox.includeAttachmentsTitle", { ns: 'searchComponent' })} checked={includeAttachments} onChange={(_, v) => setIncludeAttachments(v!)} />
                                                        <TextField label={t("Search.Input.Username", { ns: 'searchComponent' })} value={endUserName} placeholder={t("Search.Input.Usernameplaceholder", { ns: 'searchComponent' })} onChange={(_, v) => setEndUserName(v!)} title={t('title.username', { ns: 'searchComponent' })} maxLength={250} />
                                                        <div className="ms-Grid" dir="ltr">
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm5">
                                                                    <DatePicker strings={Helper.getLocateCalendar()} title={t("Search.Input.CreatedFromTitle", { ns: 'searchComponent' })} isMonthPickerVisible={true} id="from" placeholder={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} ariaLabel={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} label={t("Search.Input.CreatedFrom", { ns: 'searchComponent' })} formatDate={onFormatDate} value={dateCreatedFrom!} onSelectDate={setCreatedFrom as (date: Date | null | undefined) => void} />
                                                                </div>
                                                                <div className="ms-Grid-col ms-sm1">
                                                                    <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanCreatedFrom} title={t('Common.Button.Clear', { ns: 'common' })} />
                                                                </div>
                                                                <div className="ms-Grid-col ms-sm5">
                                                                    <DatePicker strings={Helper.getLocateCalendar()} title={t("Search.Input.CreatedToTitle", { ns: 'searchComponent' })} isMonthPickerVisible={true} id="from" placeholder={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} ariaLabel={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} label={t("Search.Input.To", { ns: 'searchComponent' })} formatDate={onFormatDate} value={dateCreatedTo!} onSelectDate={setCreatedTo as (date: Date | null | undefined) => void} />
                                                                </div>
                                                                <div className="ms-Grid-col ms-sm1">
                                                                    <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanCreatedTo} title={t('Common.Button.Clear', { ns: 'common' })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid" dir="ltr">
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm5">
                                                                    <DatePicker strings={Helper.getLocateCalendar()} title={t("Search.Input.UpdatedFromTitle", { ns: 'searchComponent' })} isMonthPickerVisible={true} id="to" placeholder={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} ariaLabel={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} label={t("Search.Input.UpdatedFrom", { ns: 'searchComponent' })} formatDate={onFormatDate} value={dateProcessedFrom!} onSelectDate={setProcessedFrom as (date: Date | null | undefined) => void} />
                                                                </div>
                                                                <div className="ms-Grid-col ms-sm1">
                                                                    <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanProcessedFrom} title={t('Common.Button.Clear', { ns: 'common' })} />
                                                                </div>
                                                                <div className="ms-Grid-col ms-sm5">
                                                                    <DatePicker strings={Helper.getLocateCalendar()} title={t("Search.Input.UpdatedToTitle", { ns: 'searchComponent' })} isMonthPickerVisible={true} id="to" placeholder={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} ariaLabel={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} label={t("Search.Input.To", { ns: 'searchComponent' })} formatDate={onFormatDate} value={dateProcessedTo!} onSelectDate={setProcessedTo as (date: Date | null | undefined) => void} />
                                                                </div>
                                                                <div className="ms-Grid-col ms-sm1">
                                                                    <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanProcessedTo} title={t('Common.Button.Clear', { ns: 'common' })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <TextField label={t("Search.Input.Subject", { ns: 'searchComponent' })} value={description} maxLength={1000} onChange={(_, v) => setDescription(v!)} id="searchAdvanceSubject" title={t("Search.Input.SubjectTitle", { ns: 'searchComponent' })} />
                                                        <TextField title={t("Search.Input.NotesTitle", { ns: 'searchComponent' })} maxLength={1000} label={t("Search.Input.Notes", { ns: 'searchComponent' })} value={searchNotes} onChange={(_, v) => setSearchNotes(v!)} />
                                                        <div className="ms-Grid" dir="ltr">
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12">
                                                                    <TooltipHost content={t("Search.Input.AplicationContent", { ns: 'searchComponent' })} directionalHint={DirectionalHint.bottomCenter}>
                                                                        <Label>{t("Search.Input.Application", { ns: 'searchComponent' })}</Label>
                                                                        <Select
                                                                            className="basic-single"
                                                                            id="AdvanceSearchApplication"
                                                                            aria-label={t('Search.Input.Application', { ns: 'searchComponent' })}
                                                                            defaultValue={template}
                                                                            options={templateList}
                                                                            onChange={onTemplateChange}
                                                                            placeholder={t("Search.Input.Applicationplaceholder", { ns: 'searchComponent' })}
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            menuPlacement='top'
                                                                            styles={customDropdownStyles}
                                                                            components={{
                                                                                IndicatorSeparator: () => null,
                                                                                DropdownIndicator: () => (
                                                                                    <FontIcon
                                                                                        iconName="ChevronDown"
                                                                                        style={{ paddingRight: "7px", fontSize: "12px" }}
                                                                                    />
                                                                                ),
                                                                            }}
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    primary: branding.theme.palette.themePrimary,
                                                                                },
                                                                            })}
                                                                        />
                                                                    </TooltipHost>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div key="lCampaign" className="ms-Grid" dir="ltr">
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12">
                                                                    <TooltipHost content={t("Search.Input.CampaignContent", { ns: 'searchComponent' })} directionalHint={DirectionalHint.bottomCenter}>
                                                                        <Label>{t("Search.Input.Campaign", { ns: 'searchComponent' })}</Label>
                                                                        <Select
                                                                            className="basic-single"
                                                                            id="AdvanceSearchCampaign"
                                                                            aria-label={t('Search.Input.Campaign', { ns: 'searchComponent' })}
                                                                            defaultValue={campaign}
                                                                            value={campaign}
                                                                            options={campaignList}
                                                                            onChange={onCampaignChange}
                                                                            placeholder=""
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            isDisabled={campaignList.length === 0}
                                                                            menuPlacement='top'
                                                                            styles={customDropdownStyles}
                                                                            components={{
                                                                                IndicatorSeparator: () => null,
                                                                                DropdownIndicator: () => (
                                                                                    <FontIcon
                                                                                        iconName="ChevronDown"
                                                                                        style={{ paddingRight: "7px", fontSize: "12px" }}
                                                                                    />
                                                                                ),
                                                                            }}
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    primary: branding.theme.palette.themePrimary,
                                                                                    primary25: branding.theme.semanticColors.listHeaderBackgroundHovered,
                                                                                },
                                                                            })}
                                                                        />
                                                                    </TooltipHost>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <TextField label={t("Search.Input.Activity", { ns: 'searchComponent' })} value={activity} maxLength={1000} onChange={(_, v) => setActivity(v!)} title={t("Search.Input.ActivityTitle", { ns: 'searchComponent' })} />
                                                        <Checkbox title={t("Search.Input.IncludeClosedTitle", { ns: 'searchComponent' })} id="includeClosed" label={t("Search.Input.IncludeClosed", { ns: 'searchComponent' })} checked={includeClosed} onChange={(_, v) => setIncludeClosed(v!)} />

                                                    </Stack>
                                                    <DialogFooter>
                                                        <PrimaryButton id='searchButton' title={t("Search.Button.Search", { ns: 'searchComponent' })} text={t("Search.Button.Search", { ns: 'searchComponent' })} onClick={advanceSearch} />
                                                        <DefaultButton id="closeButtonSearch" title={t('Common.Button.Close', { ns: 'common' })} onClick={hideModal} text={t('Common.Button.Close', { ns: 'common' })} />
                                                    </DialogFooter>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>
                :
                <Panel
                    headerText={modalTitle}
                    headerTextProps={panelHeader}
                    isBlocking={false}
                    isOpen={showModal}
                    onDismiss={hideModal}
                    closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common' })}
                    type={PanelType.custom}
                >
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">

                            <div style={{ padding: "0px", position: "absolute", top: "-2px", right: "5px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <HelpComponent
                                    headline={t('title.title', { ns: 'searchComponent' })}
                                    text={t('text.help', { ns: 'searchComponent' })}
                                    right="50px"
                                    top="15px"
                                    location="search"
                                />
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">


                            <div className={contentStyles.bodyVWOverridePanel}>

                                <div className={contentStyles.wrapper}>
                                    <ScrollablePane


                                        styles={scrollablePaneStyles}
                                        id="ScrollPaneSearchMobile" key="ScrollPaneSearchMobile">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                                            <Stack tokens={stackTokens}>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                        <Label>{t("Search.Input.Content", { ns: 'searchComponent' })}</Label>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                                        <Checkbox styles={{ root: { paddingLeft: "0px" } }} id="includeAttachments" label={t("Search.Checkbox.includeAttachments", { ns: 'searchComponent' })} title={t("Search.Checkbox.includeAttachmentsTitle", { ns: 'searchComponent' })} checked={includeAttachments} onChange={(_, v) => setIncludeAttachments(v!)} />
                                                    </div>
                                                </div>
                                                <TextField value={content} maxLength={500} onChange={(_, v) => setContent(v!)} title={t('Search.Input.SearchContentTitle', { ns: 'searchComponent' })} />


                                                <TextField label={t("Search.Input.Username", { ns: 'searchComponent' })} value={endUserName} placeholder={t("Search.Input.Usernameplaceholder", { ns: 'searchComponent' })} onChange={(_, v) => setEndUserName(v!)} title={t("Search.Input.UsernameTitle", { ns: 'searchComponent' })} maxLength={250} />
                                                <div key="grid" className="ms-Grid" dir="ltr">
                                                    <div key="row" className="ms-Grid-row">
                                                        <div key="col" className="ms-Grid-col ms-sm5">
                                                            <DatePicker strings={Helper.getLocateCalendar()} title={t("Search.Input.CreatedFromTitle", { ns: 'searchComponent' })} isMonthPickerVisible={true} id="from" placeholder={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} ariaLabel={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} label={t("Search.Input.CreatedFrom", { ns: 'searchComponent' })} formatDate={onFormatDate} value={dateCreatedFrom!} onSelectDate={setCreatedFrom as (date: Date | null | undefined) => void} />
                                                        </div>
                                                        <div key="col" className="ms-Grid-col ms-sm1">
                                                            <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanCreatedFrom} title={t('Common.Button.Clear', { ns: 'common' })} />
                                                        </div>
                                                        <div key="col" className="ms-Grid-col ms-sm5">
                                                            <DatePicker strings={Helper.getLocateCalendar()} title={t("Search.Input.CreatedToTitle", { ns: 'searchComponent' })} isMonthPickerVisible={true} id="from" placeholder={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} ariaLabel={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} label={t("Search.Input.To", { ns: 'searchComponent' })} formatDate={onFormatDate} value={dateCreatedTo!} onSelectDate={setCreatedTo as (date: Date | null | undefined) => void} />
                                                        </div>
                                                        <div key="col" className="ms-Grid-col ms-sm1">
                                                            <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanCreatedTo} title={t('Common.Button.Clear', { ns: 'common' })} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div key="grid" className="ms-Grid" dir="ltr">
                                                    <div key="row" className="ms-Grid-row">
                                                        <div key="col" className="ms-Grid-col ms-sm5">
                                                            <DatePicker strings={Helper.getLocateCalendar()} title={t("Search.Input.UpdatedFromTitle", { ns: 'searchComponent' })} isMonthPickerVisible={true} id="to" placeholder={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} ariaLabel={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} label={t("Search.Input.To", { ns: 'searchComponent' })} formatDate={onFormatDate} value={dateProcessedFrom!} onSelectDate={setProcessedFrom as (date: Date | null | undefined) => void} />
                                                        </div>
                                                        <div key="col" className="ms-Grid-col ms-sm1">
                                                            <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanProcessedFrom} title={t('Common.Button.Clear', { ns: 'common' })} />
                                                        </div>
                                                        <div key="col" className="ms-Grid-col ms-sm5">
                                                            <DatePicker strings={Helper.getLocateCalendar()} title={t("Search.Input.UpdatedToTitle", { ns: 'searchComponent' })} isMonthPickerVisible={true} id="to" placeholder={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} ariaLabel={t("Search.Input.Dateplaceholder", { ns: 'searchComponent' })} label={t("Search.Input.To", { ns: 'searchComponent' })} formatDate={onFormatDate} value={dateProcessedTo!} onSelectDate={setProcessedTo as (date: Date | null | undefined) => void} />
                                                        </div>
                                                        <div key="col" className="ms-Grid-col ms-sm1">
                                                            <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanProcessedTo} title={t('Common.Button.Clear', { ns: 'common' })} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <TextField label={t("Search.Input.Subject", { ns: 'searchComponent' })} value={description} maxLength={1000} onChange={(_, v) => setDescription(v!)} title={t("Search.Input.SubjectTitle", { ns: 'searchComponent' })} />
                                                <TextField title={t("Search.Input.NotesTitle", { ns: 'searchComponent' })} maxLength={1000} label={t("Search.Input.Notes", { ns: 'searchComponent' })} value={searchNotes} onChange={(_, v) => setSearchNotes(v!)} />
                                                <div key="ltemplate" className="ms-Grid" dir="ltr">
                                                    <div key="row" className="ms-Grid-row">
                                                        <div key="col" className="ms-Grid-col ms-sm12">
                                                            <TooltipHost content={t('Search.Input.AplicationContent', { ns: 'searchComponent' })} directionalHint={DirectionalHint.bottomCenter}>
                                                                <Label>{t("Search.Input.Application", { ns: 'searchComponent' })}</Label>
                                                                <Select
                                                                    className="basic-single"
                                                                    id="AdvanceSearchApplication"
                                                                    aria-label={t('Search.Input.Application', { ns: 'searchComponent' })}
                                                                    defaultValue={template}
                                                                    options={templateList}
                                                                    onChange={onTemplateChange}
                                                                    placeholder={t("Search.Input.Applicationplaceholder", { ns: 'searchComponent' })}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    menuPlacement='top'
                                                                    styles={customDropdownStyles}
                                                                    components={{
                                                                        IndicatorSeparator: () => null,
                                                                        DropdownIndicator: () => (
                                                                            <FontIcon
                                                                                iconName="ChevronDown"
                                                                                style={{ paddingRight: "7px", fontSize: "12px" }}
                                                                            />
                                                                        ),
                                                                    }}
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary: branding.theme.palette.themePrimary,
                                                                        },
                                                                    })}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div key="lCampaign" className="ms-Grid" dir="ltr">
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12">
                                                            <TooltipHost content={t('Search.Input.CampaignContent', { ns: 'searchComponent' })} directionalHint={DirectionalHint.bottomCenter}>
                                                                <Label>{t("Search.Input.Campaign", { ns: 'searchComponent' })}</Label>
                                                                <Select
                                                                    className="basic-single"
                                                                    id="AdvanceSearchCampaign"
                                                                    aria-label={t('Search.Input.Campaign', { ns: 'searchComponent' })}
                                                                    defaultValue={campaign}
                                                                    value={campaign}
                                                                    options={campaignList}
                                                                    onChange={onCampaignChange}
                                                                    placeholder=""
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    isDisabled={campaignList.length === 0}
                                                                    menuPlacement='top'
                                                                    styles={customDropdownStyles}
                                                                    components={{
                                                                        IndicatorSeparator: () => null,
                                                                        DropdownIndicator: () => (
                                                                            <FontIcon
                                                                                iconName="ChevronDown"
                                                                                style={{ paddingRight: "7px", fontSize: "12px" }}
                                                                            />
                                                                        ),
                                                                    }}
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary: branding.theme.palette.themePrimary,
                                                                        },
                                                                    })}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                    </div>
                                                </div>
                                                <TextField label={t("Search.Input.Activity", { ns: 'searchComponent' })} value={activity} maxLength={1000} onChange={(_, v) => setActivity(v!)} title={t("Search.Input.ActivityTitle", { ns: 'searchComponent' })} />
                                                <Checkbox title={t("Search.Input.IncludeClosedTitle", { ns: 'searchComponent' })} id="includeClosed" label={t("Search.Input.Included", { ns: 'searchComponent' })} checked={includeClosed} onChange={(_, v) => setIncludeClosed(v!)} />

                                            </Stack>



                                        </div>
                                    </ScrollablePane>


                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <PrimaryButton id='searchAdvanceButton' title={t('Common.Button.Search', { ns: 'common' })} text={t('Common.Button.Search', { ns: 'common' })} onClick={advanceSearch} />
                                    </DialogFooter>
                                </div>




                            </div>




                        </div>


                    </div>
                </Panel>
            }
        </>
    );
}

export default Search