import React, {useState, useEffect, useContext, useCallback} from "react";
import {
    mergeStyleSets,
    getTheme,
    FontWeights,
} from "@fluentui/react/lib/Styling";
import {
    IColumn,
    SelectionMode,
    DetailsListLayoutMode,
    DetailsList,
    IDetailsHeaderProps,
    IDetailsColumnRenderTooltipProps,
    DetailsRow,
} from "@fluentui/react/lib/DetailsList";
import {
    IShimmerStyleProps,
    IShimmerStyles,
} from "office-ui-fabric-react/lib/Shimmer";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import Form from "../Form/Form";
import Helper, {
    ITemplateLayoutRequest,
    IDeleteDocumentRequest,
    IDocumentVersionRequest,
    IDocumentRequest,
    ICheckinCheckoutDocumentOnlyRequest,
    ICreateDocumentRequest,
    IFormControl,
    IDocumentOnlyDataPointRequest,
    IDocumentOnlyAudienceRequest,
    IDocumentOnlyPackageRequest,
    IDocumentOnlyAudienceDetail,
} from "../../Helpers/Helper";
import {
    IContextualMenuItem,
    IContextualMenuProps,
} from "@fluentui/react/lib/ContextualMenu";
import {
    IconButton,
    IButtonStyles,
    DefaultButton,
    PrimaryButton,
} from "@fluentui/react/lib/Button";
import VersionScreen, { IDocumentVersion } from "../Version/VersionScreen";
import "../../Custom.css";
import EmailWidget from "../Email/EmailWidget";
import Confirmation from "../Settings/Confirmation";
import PreviewScreen from "../Preview/PreviewScreen";
import { decode } from "base64-arraybuffer";
import {
    ScrollablePane,
    IScrollablePaneStyles,
} from "@fluentui/react/lib/ScrollablePane";
import { IRenderFunction } from "@fluentui/react/lib/Utilities";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { Sticky, StickyPositionType } from "office-ui-fabric-react/lib/Sticky";
import { IDocument, ISubRecord } from "../../App";
import { CustomForm } from "../CustomTemplates/CustomForm/CustomForm";
import {
    IChoiceGroupOption,
    Icon,
    IGroup,
    IGroupDividerProps,
    IIconProps,
    IIconStyles,
    Label,
    Modal,
    Separator, StackItem, Text,
} from "@fluentui/react";
import ShareDocument from "../CustomTemplates/ShareDocument";
import ActivityStatus from "../Activity/ActivityStatus";
import Notes from "./Notes";
import { DialogFooter } from "@fluentui/react/lib/Dialog";
import { TextField } from "@fluentui/react/lib/TextField";
import UploadDocument from "../CustomTemplates/UploadDocument";
import Signers from "./Signers";
import Attachment from "../Attachments/Attachment";
import { useConstCallback, useBoolean } from "@uifabric/react-hooks";
import {
    CheckboxVisibility,
    IMessageBarStyles,
    MessageBar,
} from "office-ui-fabric-react";
import Executed from "../Executed/Executed";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { isMobile } from "react-device-detect";
import {
    AttachmentSettings,
    IPropertyBagSettings,
} from "../../interfaces/IPropertyBagSettings";

import "../../Custom.css";
import { Selection } from "office-ui-fabric-react/lib/MarqueeSelection";
import ReactDOM from "react-dom";
import { CheckoutOptionsVisibilityType } from "../CustomTemplates/Validations";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { getRecordDescription } from "../../Helpers/functions";
import { formatFromUTC } from "../../Helpers/DateUtils";
import { IRecordRenewal } from "../RenewalsAndAlerts/RenewalsAndAlerts.service";
import RenewalsAndAlertsEdit from "../RenewalsAndAlerts/RenewalsAndAlertsEdit";
import { AppContext } from "../Provider";
import { useModal } from "../../hooks/useModal";
import QueueModal from "../QueueModal/QueueModal";
import FormDocumentLink from "../DocumentLink/FormDocumentLink";
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";

export class Version implements IDocumentVersion {
    Id = -1;
    DocumentId = -1;
    Version = -1;
    AccountId = -1;
    TemplateId = -1;
    UserName = "";
    Description = "";
    Data = "";
    DateCreated = "";
    DateUpdated = "";
    Name = "";
    MimeType = "";
    Culture = "";
    IconName = "";
    DisplayName = "";
    DATETIME = "";
}
export interface IPropertyBag {
    UniqueReferences?: string;
    Clon: string;
    emailMoniker: string;
    Released: boolean;
    Culture: string;
    CultureName: string;
    AllowDocX: boolean;
    AllowSendMail: boolean;
    DocumentCreation: boolean;
    DocumentUpdate: boolean;
    DocumentDelete: boolean;
    DocumentShare: boolean;
    TemplateEdit: boolean;
    TemplateShare: boolean;
    TemplateDelete: boolean;
    DefaultDescription: string;
    TemplateRemoves?: boolean;
    UseServiceAccount?: boolean;
    SupressPreview: boolean;
    DocumentDelivery: string;
    RedirectURL: string;
}

const subR: ISubRecord[] = [];
export class Document implements IDocument {
    Executed = false;
    Id = -1;
    AccountId = -1;
    TemplateId = -1;
    UserName = "";
    Description = "";
    Data = "";
    DateCreated = "";
    DateUpdated = "";
    Name = "";
    MimeType = "";
    Culture = "";
    IconName = "";
    Versions = -1;
    IsCustom = false;
    LastActivity = "";
    Closed = false;
    Suspended = false;
    LastNote = "";
    Category = "";
    AllowCheckInOut = false;
    AllowDocumentUpdate = false;
    AllowESign = false;
    AllowNotification = false;
    CheckedOut = false;
    CheckedOutUser = "";
    CheckedOutDateTime = "";
    TemplatePropertyBag = "";
    Owner = false;
    CoOwner = false;
    Tickler = "";
    Attachments = 0;
    AudienceName = "";
    TemplateVersion = "";
    FullName = "";
    OnBehalfUserFullName = "";
    OnBehalfUser = "";
    PrimaryId = -1;
    SubRecords = subR;
    HasSubRecord = false;
    CampaignId = 0;
    ActiveSubscription = true;
    SubscriptionStatusMessage = "";
}

const FileDownload = require("js-file-download");
const theme = getTheme();
const { palette, semanticColors } = theme;

const stackTokens: Partial<IStackTokens> = { childrenGap: 0 };

const classNamex = mergeStyleSets({
    pane: {
        maxWidth: "100%",
    },
});

const scrollablePaneStyles: Partial<IScrollablePaneStyles> = {
    root: classNamex.pane,
};

const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5",
    },
};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac",
    },
};

const iconButtonStyles: Partial<IButtonStyles> = {
    root: { float: "right", height: "inherit" },
};
const iconButtonStylesPrompt = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        selectors: {
            ":hover": {
                cursor: "pointer",
            },
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    
};
const iconButtonNoteModalStyles = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    
};

const cancelIcon: IIconProps = { iconName: "Cancel" };
const helpIcon: IIconProps = { iconName: "Help" };



let isAdvanceSearch = false;
function SubForms(props: any) {
    const { branding } = useBranding()
    const docVersionList: IDocumentVersion[] = [];
    const [modalId, setModalId] = useState(-1);
    const [modalTitle, setModalTitle] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [layout, setLayout] = useState("");
    const [layoutLoaded, setLayoutLoaded] = useState(false);
    const [spinnerText, setSpinnerText] = useState("");
    const [documentId, setDocumentId] = useState(-1);
    const [showVersion, setShowVersion] = useState(false);
    const [versionList, setversionList] = useState(docVersionList);
    const [showEmail, setShowEmail] = useState(false);
    const [documentDescription, setDocumentDescription] = useState("");
    const [username, setUsername] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const [previewURL, setPreviewURL] = useState("");
    const [previewMime, setPreviewMime] = useState("");
    const cacheAudience:(React.MutableRefObject<{
        id: string;
        isInAudience: boolean;
    }[]>) = props.cacheAudience
    const iconStyles: IIconStyles = {
        root: {
            fontSize: "18px",
            height: "18px",
            width: "18px",
            color: branding.theme.palette.themePrimary
        },
    };
    
    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        containerSaveModal: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
            width: isMobile ? null : "30vw",
            height: "20vh",
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 0px 24px",
            },
        ],
        body: {
            width: isMobile ? null : "30vw",
            height: "15vh",
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
        bodyNote: {
            width: isMobile ? null : "25vw",
            height: "20vh",
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
        listDialog: {
            width: isMobile ? "100vw" : "80vw",
            height: "65vh",
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            //overflowY: 'hidden',
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
    });
    const classNames = mergeStyleSets({
        hideMenu: {
            display: "none",
        },
        showMenu: {
            display: "inline",
        },
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: "16px",
        },
        fileIconCell: {
            textAlign: "center",
            selectors: {
                "&:before": {
                    content: ".",
                    display: "inline-block",
                    verticalAlign: "middle",
                    height: "100%",
                    width: "0px",
                    visibility: "hidden",
                },
            },
        },
        fileIconImg: {
            verticalAlign: "middle",
            maxHeight: "16px",
            maxWidth: "16px",
            color: "rgb(96, 94, 92)",
        },
        controlWrapper: {
            display: "flex",
            flexWrap: "wrap",
        },
        exampleToggle: {
            display: "inline-block",
            marginBottom: "10px",
            marginRight: "30px",
        },
        selectionDetails: {
            marginBottom: "20px",
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 0px 24px",
            },
        ],
        exampleRoot: {
            backgroundColor: "white",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "12px",
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            selectors: {
                "&:hover": { background: palette.neutralLight },
            },
        },
        nameText: {
            fontWeight: "bold",
        },
    });
    
    const [currentRenewalItem, setCurrentRenewalItem] = useState<IRecordRenewal>({
        TemplateId: 0,
        Name: "",
        Id: 0,
        RecordId: 0
    });

    const [mQuery, setMQuery] = React.useState<any>({
        matches: window.innerWidth > 639 ? true : false,
    });
    const { context } = useContext(AppContext)
    const sites: IChoiceGroupOption[] = [];
    const [showCustomForm, setShowCustomForm] = useState(false);
    const [siteSelected, setSiteSelected] = useState("");
    const [siteOptions, setSiteOptions] = useState(sites);
    const [templateId, setTemplateId] = useState(-1);
    const [templateName, setTemplateName] = useState("");
    const [data, setData] = useState("");
    const [templateDescription, setTemplateDescription] = useState("");
    const [showShare, setShowShare] = useState(false);
    const [showAttachment, setShowAttachment] = useState(false);
    const [showExecuted, setShowExecuted] = useState(false);
    const [Currentcolumn, setCurrentcolumn] = useState<IDocument>();
    const iconListButtonStyles: Partial<IButtonStyles> = {
        root: { float: "right", height: "inherit" },
    };
    const [showActivity, setshowActivity] = useState(false);
    const [showAddNote, setShowAddNote] = useState(false);
    const [note, setNote] = useState("");
    const [requiredNote, setRequiredNote] = useState(false);
    const [actionTitle, setActionTitle] = useState("");
    const [actionTitleButton, setActionTitleButton] = useState("");
    const [showNotes, setShowNotes] = useState(false);
    const [showSigners, setShowSigners] = useState(false);
    const [showUploadDoc, setShowUploadDoc] = useState(false);
    const [documentName, setDocumentName] = useState("");
    const [documentVersion, setDocumentVersion] = useState("");
    const [showRenewals, setShowRenewals] = useState(false);

    const targetButtonC = React.useRef<HTMLDivElement>(null);
    const targetButtonT = React.useRef<HTMLDivElement>(null);
    const targetButtonG = React.useRef<HTMLDivElement>(null);
    const targetButtonS = React.useRef<HTMLDivElement>(null);
    const [
        isCoachmarkVisibleC,
        { setFalse: hideCoachmarkC, setTrue: showCoachmarkC },
    ] = useBoolean(false);
    const [
        isCoachmarkVisibleT,
        { setFalse: hideCoachmarkT, setTrue: showCoachmarkT },
    ] = useBoolean(false);
    const [
        isCoachmarkVisibleG,
        { setFalse: hideCoachmarkG, setTrue: showCoachmarkG },
    ] = useBoolean(false);
    const [
        isCoachmarkVisibleS,
        { setFalse: hideCoachmarkS, setTrue: showCoachmarkS },
    ] = useBoolean(false);
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] =
        useBoolean(false);
    const [propertyBag, setPropertyBag] = useState<IPropertyBag>();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [downloadDocX, setDownloadDocX] = useState(false);
    //const [docSelected, setDocSelected] = useState<IDocument>()
    const [owner, setIsOwner] = useState(false);
    const [coowner, setIsCoOwner] = useState(false);

    const [ownerName, setOwnerName] = useState("");
    const [ownerUser, setOwnerUser] = useState("");
    const [templateCategory, setTemplateCategory] = useState("");
    const [audienceName, setAudienceName] = useState("");
    const [audienceMasterId, setAudienceMasterId] = useState("");

    const [showFormLink, setshowFormLink] = useState(false);

    const [showModalClone, setShowModalClone] = useState(false);
    const [clonning, setClonning] = useState(false);
    const [shortcut, setShortcut] = useState("");
    const [cloneShortcut, setCloneShortcut] = useState("");
    const [requiredShortcut, setRequiredShortcut] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [removeeMessage, setremoveMessage] = useState("");
    const [actionText, setActionText] = useState("");
    const [version, setVersion] = useState(0);
    const [emailMoniker, setEmailMoniker] = useState("");
    const [allowDocx, setAllowDocx] = useState(false);
    const [allowSendMail, setAllowSendMail] = useState(false);
    const [documentCreation, setDocumentCreation] = useState(false);
    const [documentUpdate, setDocumentUpdate] = useState(false);
    const [documentDelete, setDocumentDelete] = useState(false);
    const [documentShare, setDocumentShare] = useState(false);
    const [templateEdit, setTemplateEdit] = useState(false);
    const [templateShare, setTemplateShare] = useState(false);
    const [templateDelete, setTemplateDelete] = useState(false);
    const [culture, setCulture] = useState("");
    const [supressPreview, setSupressPreview] = useState(false);
    const [templateVersion, setTemplateVersion] = useState("");

    const [subformItems, setSubformItems] = useState<IContextualMenuItem[]>([]);
    const [iscoOwner, setiscoOwner] = useState(false);
    const [isCreator, setisCreator] = useState(false);
    const [isOwner, setisOwner] = useState(false);
    const [isSubform, setIsSubform] = useState(false);
    const [isModalSubform, setIsModalSubform] = useState(false);
    const docList: IDocument[] = [];
    const [documentList, setDocumentList] = useState(docList);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState("");
    const subrecordsList: ISubRecord[] = [];
    const [subrecords, setSubrecords] = useState(subrecordsList);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);

    const [activeSubscription, setActiveSubscription] = useState(false);
    const [subscriptionStatusMessage, setSubscriptionStatusMessage] = useState("");
    const [isRecordUpdate, setIsRecordUpdate] = useState(false);
    const [isRecordError, setIsRecordError] = useState(false);

    const { t } = useTranslation([
        "common",
        "workspace",
        "recordnotes",
        "preview",
        "recordactivity",
        "recorddelete",
        "recordcopy",
    ]);

    const QueueCustomModal = useModal()

    ////////////////Not working
    const [groups, setGroups] = useState<IGroup[]>([]);
    const emptyDoc = new Document();
    const [docSelected, setDocSelected] = useState(emptyDoc);
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectionState] = useState(
        new Selection({
            onSelectionChanged: () => {
                const selectionCount = selectionState.getSelectedCount();
                switch (selectionCount) {
                    case 0:
                        setDocSelected(emptyDoc);
                        break;
                    case 1:
                        const doc =
                            selectionState.getSelection()[0] as IDocument;
                        setDocSelected(doc);
                        if (doc.Versions > 1) {
                            setIsDisabled(false);
                        } else {
                            setIsDisabled(true);
                        }
                        break;
                    default:
                        setDocSelected(emptyDoc);
                        break;
                }
            },
        })
    );
    ////////////////////////////

    useEffect(() => {
        if (props.showModal) GetSubrecords();
        //buidlGroups(props.documentList)

        // setGlobalDateLocale(navigator.language)
        // let mediaQuery = window.matchMedia("(min-width: 768px)");
        // mediaQuery.addListener(setMQuery);

        // return () => mediaQuery.removeListener(setMQuery);
    }, [props.showModal]);

    const onColumnClick = () => { };

    const toggleCustomForm = useConstCallback((open: boolean) => {
        const site = Helper.getSiteSelected();
        setSiteSelected(site);
        setShowCustomForm(open);
    });

    const getAllowDocX = (item: IDocument) => {
        var value = false;

        var propBag = atob(item.TemplatePropertyBag);
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            CultureName: "",
            Released: false,
            emailMoniker: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        try {
            jsonBag = JSON.parse(propBag);
            value = jsonBag.AllowDocX;
        } catch (error) { }
        setAllowDocx(value);
    };

    const onItemPreview = (item: IDocument) => {
        const id = item.Id;
        setModalId(item.Id);
        setLayoutLoaded(false);
        setModalTitle(t("title.modal", { ns: "preview" }));
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setDocumentDescription(`${item.Description}`);
        setShowPreview(true);
        const preview = async () => {
            setAllowDocx(false);

            var jsonBag: IPropertyBagSettings = {
                Clon: "",
                Culture: "",
                CultureName: "",
                Released: false,
                emailMoniker: "",
                AllowDocX: false,
                AllowSendMail: false,
                DocumentCreation: false,
                DocumentUpdate: false,
                DocumentDelete: false,
                DocumentShare: false,
                TemplateEdit: false,
                TemplateShare: false,
                TemplateDelete: false,
                DefaultDescription: "",
                attachment: {} as AttachmentSettings,
            };
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id.toString(),
                IsPreview: true,
                IsDownload: false,
                IsDocx: false,
            };

            // ---------------
            await Helper.getDocumentOnlyTemplateId(item.TemplateId.toString(), true)
                .then((data) => {
                    try {
                        jsonBag = JSON.parse(
                            data.PropertyBag
                        ) as IPropertyBagSettings;
                        setAllowDocx(jsonBag.AllowDocX);

                        Helper.getDocumentPreview(req).then((res) => {
                            if (res.Success) {
                                setDocumentId(item.Id);
                                setLayoutLoaded(true);
                                setPreviewURL(res.UrlPath);
                                setPreviewMime(res.mimeType);
                            }
                        });
                    } catch (reason) {
                        console.log(reason);
                    }
                })
                .catch((reason) => {
                    console.log(reason);
                })
                .finally(() => { });
        };
        preview();
    };

    const toggleQueueModal = useCallback((open: boolean, isUpdate: boolean, isError:boolean)=> {
        if (open) {
            QueueCustomModal.open(true)
            setIsRecordUpdate(isUpdate)
            setIsRecordError(isError)
        }
    },[QueueCustomModal])

    useEffect(()=>{
        if (!QueueCustomModal.isOpen && QueueCustomModal.data) {
            _update()
        }
    },[QueueCustomModal.isOpen])

    const hidePreview = () => {
        setShowPreview(false);
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
    };

    const onItemModalSubform = (item: IDocument) => {
        const id = item.Id;
        setLayoutLoaded(false);
        setIsModalSubform(true);
        setSpinnerText(t("text.spinner", { ns: "common" }));
    };
    const hideModalSubform = () => {
        props.hideSubForm();
    };

    /**
     * on Before Renewal Dismiss
     * @param update
     */
    const onBeforeDismiss = (update: boolean = false) => {
        setShowRenewals(false)
    }

    const onItemDownload = (item: IDocument, isDocx: boolean) => {
        setShowConfirmationModal(false);
        const id = documentId;
        const download = async () => {
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id.toString(),
                IsPreview: false,
                IsDownload: true,
                IsDocx: isDocx,
            };
            var res = await Helper.getDocumentDownload(req);

            if (res.Success === undefined || !res.Success) {
                const name = isDocx
                    ? `${res.Description}.docx`
                    : `${res.Description}.pdf`;
                try {
                    const decoded = decode(res.Content);
                    const file = new Blob([decoded], { type: res.MimeType });
                    var a = document.createElement("a");
                    a.href = window.URL.createObjectURL(file);
                    a.download = name;
                    a.click();
                    setShowSuccessMessage(true);
                    setMessage(
                        t("messages.successDownloaded", { ns: "checkinout" })
                    );
                    setTimeout(function () {
                        setShowSuccessMessage(false);
                    }, 5000);
                } catch (error) {
                    setShowErrorMessage(true);
                    setMessage(
                        t("message.error", { ns: "common", Error: error })
                    );
                    setTimeout(function () {
                        setShowErrorMessage(false);
                    }, 5000);
                }
            }
        };
        download();
    };

    const onItemInvoked = (item: IDocument) => {
        if (!allowUpdate()) {
            return;
        }

        if (item.IsCustom) {
            const id = item.Id;
            const getData = async () => {
                const req: IDocumentRequest = {
                    UserName: Helper.getUsername(),
                    SessionKey: Helper.getSessionKey(),
                    AccountId: Helper.getDefaultAccount(),
                    DocumentId: "-1",
                    Id: id.toString(),
                    IsPreview: false,
                    IsDownload: true,
                    IsDocx: true,
                };
                var res = await Helper.getDocumentDownload(req);

                if (res.Success === undefined || !res.Success) {
                    setTemplateId(docSelected.TemplateId);
                    setTemplateName(docSelected.Name);
                    setTemplateDescription(docSelected.Description);
                    setTemplateVersion(item.TemplateVersion);
                    setData(res.Data);
                    setIsSubform(false);
                    setDocumentId(item.Id);
                    setDocumentDescription(item.Description);
                    var emailMoniker = "";
                    var culture = "";
                    var jsonBag: IPropertyBag = {
                        Clon: "",
                        Culture: "",
                        CultureName: "",
                        Released: false,
                        emailMoniker: "",
                        AllowDocX: false,
                        AllowSendMail: false,
                        DocumentCreation: false,
                        DocumentUpdate: false,
                        DocumentDelete: false,
                        DocumentShare: false,
                        TemplateEdit: false,
                        TemplateShare: false,
                        TemplateDelete: false,
                        DefaultDescription: "",
                        SupressPreview: false,
                        DocumentDelivery: "None",
                        RedirectURL: ""
                    };

                    if (item.TemplatePropertyBag != null) {
                        var propBag = atob(item.TemplatePropertyBag);
                        try {
                            jsonBag = JSON.parse(propBag);
                            culture = jsonBag.Culture;
                            emailMoniker = jsonBag.emailMoniker;
                            setDocumentUpdate(jsonBag.DocumentUpdate)
                            setSupressPreview(jsonBag.SupressPreview)
                        } catch (error) { }
                    }
                    setAudienceName(item!.AudienceName)
                    setAudienceMasterId(await getAudienceMasterId(item!.TemplateId?.toString()))
                    setEmailMoniker(emailMoniker);
                    setOwnerUser(item!.UserName!);
                    setTemplateCategory(item!.Category!);
                    setCulture(culture);
                    toggleCustomForm(true);
                }
            };

            getData();
        } else {
            setLayout("");
            setLayoutLoaded(false);
            setSpinnerText(t("text.spinner", { ns: "common" }));

            const req: ITemplateLayoutRequest = {
                AccountId: Helper.getDefaultAccount(),
                TemplateId: item.TemplateId.toString(),
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                DocumentId: item.Id.toString(),
            };

            Helper.getTemplateLayout(req).then((res) => {
                setLayout(res.Layout);
                setLayoutLoaded(true);
                setSpinnerText("");
            });

            setDocumentId(item.Id);
            setModalId(item.TemplateId);
            setModalTitle(item.Name);
            setShowModal(true);
        }
    };
    const onItemSubformInvoked = (item: any) => {
        var emailMoniker = "";
        var culture = "";
        var documenteDelete = false;
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            CultureName: "",
            Released: false,
            emailMoniker: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        if (item.PropertyBag != null) {
            var propBag = atob(item.PropertyBag);
            try {
                jsonBag = JSON.parse(propBag);
                emailMoniker = jsonBag.emailMoniker;
                documenteDelete = jsonBag.DocumentDelete;
                culture = jsonBag.Culture;
            } catch (error) { }
        }

        setEmailMoniker(emailMoniker);
        setDocumentDelete(documenteDelete);

        setTemplateName(item!.DisplayName);
        setTemplateId(item!.TemplateId);
        setOwnerName(item!.OwnerFullName);
        setOwnerUser(item!.OwnerUserName);
        setTemplateCategory(item!.Category);
        setAudienceName(item!.AudienceName!);
        setiscoOwner(item!.CoOwner);
        //setisOwner(item!.Owner)
        setisCreator(item!.CreateDocument);
        setCulture(culture);
        setEmailMoniker(emailMoniker);
        setIsSubform(true);
        setData("");
        setDocumentId(docSelected.Id);
        //setDocumentCreation(item!.documentCreation)
        setDocumentUpdate(item!.documentUpdate)
        //setDefaultDescription(item!.defaultDescription)
        toggleCustomForm(true);
    };

    const onItemStatusInvoked = (item: IDocument) => {
        setCurrentcolumn(item);
        setModalTitle(t("title.modal", { ns: "recordactivity" }));
        setDocumentDescription(`${item.Description}`);
        setshowActivity(true);
        setDocumentId(item.Id);
    };

    const onItemNotesInvoked = (item: IDocument) => {
        setCurrentcolumn(item);
        setModalTitle(t("title.modal", { ns: "recordnotes" }));
        setShowNotes(true);
        setDocumentDescription(item.Description);
        setDocumentId(item.Id);
        setIsOwner(item.Owner);
        setIsCoOwner(item.CoOwner);
        setUsername(item.UserName);
    };

    const onItemSignInvoked = (item: IDocument) => {
        setModalTitle(t("title.modal", { ns: "recordsign" }));
        setShowSigners(true);
        setDocumentDescription(`${item.Description}`);
        setDocumentId(item.Id);
        setTemplateId(item.TemplateId);
        setDocumentName(item.Description);
        setDocumentVersion(item.Versions.toString());
    };

    const onItemClose = (item: IDocument) => {
        setDocumentId(item.Id);
        if (!item.Closed) {
            setActionTitle(
                getRecordDescription(
                    t("title.btnClose", { ns: "recordmanage" }),
                    item.Description
                )
            );
            setActionTitleButton(t("title.btnClose", { ns: "recordmanage" }));
            setModalId(1);
        } else {
            setActionTitle(
                getRecordDescription(
                    t("title.btnReOpen", { ns: "recordmanage" }),
                    item.Description
                )
            );
            setActionTitleButton(t("title.btnReOpen", { ns: "recordmanage" }));
            setModalId(2);
        }
        setNote("");
        setShowAddNote(true);
    };

    const onItemRemove = (item: IDocument) => {
        setShowRemove(true);
        setModalId(item.Id);
        setLayoutLoaded(false);
        setModalTitle(
            getRecordDescription(
                t("title.modal", { ns: "recordremove" }),
                item.Description
            )
        );
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setremoveMessage(t("text.confirmRemove", { ns: "recordremove" }));
        setActionText(t("text.remove", { ns: "recordremove" }));
        setLayoutLoaded(true);
        setSpinnerText("");
        setShowRemove(true);
    };
    const onItemSuspended = (item: IDocument) => {
        setDocumentId(item.Id);
        if (!item.Suspended) {
            setActionTitle(
                getRecordDescription(
                    t("title.suspend", { ns: "recordmanage" }),
                    item.Description
                )
            );
            setActionTitleButton(t("title.suspend", { ns: "recordmanage" }));
            setModalId(3);
        } else {
            setActionTitle(
                getRecordDescription(
                    t("title.resume", { ns: "recordmanage" }),
                    item.Description
                )
            );
            setActionTitleButton(t("title.resume", { ns: "recordmanage" }));
            setModalId(4);
        }
        setNote("");
        setShowAddNote(true);
    };

    const onItemDelete = (item: IDocument) => {
        setModalId(item.Id);
        setLayoutLoaded(false);
        setDocumentDescription(item.Description);
        setModalTitle(
            getRecordDescription(
                t("title.modal", { ns: "recorddelete" }),
                item.Description
            )
        );
        setSpinnerText(t("text.spinnerDeleting", { ns: "common" }));
        setDeleteId(item.Id.toString());
        setDeleteMessage(t("messages.delete", { ns: "recorddelete" }));
        setActionText(t("Common.Button.Delete", { ns: "common" }));
        setActionTitle(t("title.deleteButton", { ns: "recorddelete" }));

        var emailMoniker = "";
        var documenteDelete = false;
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            CultureName: "",
            Released: false,
            emailMoniker: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        if (item.TemplatePropertyBag != null) {
            var propBag = atob(item.TemplatePropertyBag);
            try {
                jsonBag = JSON.parse(propBag);
                emailMoniker = jsonBag.emailMoniker;
                documenteDelete = jsonBag.DocumentDelete;
            } catch (error) { }
        }

        setEmailMoniker(emailMoniker);
        // setOwnerName(item!.ownerFullName!)
        setOwnerUser(item!.UserName!);
        setTemplateCategory(item!.Category!);
        setTemplateName(item!.Name);
        setDocumentDelete(documenteDelete);
        setAudienceName(item!.AudienceName!);

        setLayoutLoaded(true);
        setShowConfirm(true);
    };

    const hideConfirm = () => {
        setShowConfirm(false);
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        setDeleteMessage("");
        setLayoutLoaded(false);
    };

    const confirmDelete = (id: string) => {
        const deleteDoc = async () => {
            const site = await Helper.getSite()!;
            const sessionKey = Helper.getSessionKey();
            const accountId = Helper.getDefaultAccount();
            const userName = Helper.getUsername();

            const req: IDeleteDocumentRequest = {
                UserName: userName,
                SessionKey: sessionKey,
                AccountId: accountId,
                DocumentId: id,
            };
            var res = await Helper.DeleteDocument(req);

            if (res.Success) {
                if (documentDelete) {
                    Helper.SendNotification({
                        ActionByEmailAddress: userName,
                        ActionDate: formatFromUTC(Date.now(), "ll", "en"),
                        AudienceName: audienceName,
                        AudienceOwnerEmailAddress: ownerUser,
                        RecordDescription: documentDescription.trim(),
                        TargetOfActionEmailAddress: "",
                        TemplateCategory: templateCategory,
                        TemplateName: templateName,
                        Type: "2",
                        UserName: userName,
                        SK: sessionKey,
                        AccountId: accountId,
                        Path: site,
                        Autoprovision: true,
                        ApiKey: "-1",
                        EmailMoniker: emailMoniker,
                        TemplateId: templateId.toString(),
                    });
                }
                context.setRefreshRenewals(true)
                _update();
                hideConfirm();
            } else {
                hideConfirm();
                //showNotification("An error occurred: " + res.ExceptionMessage, "error")
            }
        };
        deleteDoc();
    };

    const onItemVersions = async (item: IDocument) => {
        var ItemData = await GetDataDocument(item).then((x) => {
            setModalId(item.Id);
            setLayoutLoaded(false);
            setModalTitle("Version");
            setDocumentDescription(item.Description);
            setSpinnerText(t("text.spinner", { ns: "common" }));
            // setVersion(item.Versions)
            const req: IDocumentVersionRequest = {
                AccountId: Helper.getDefaultAccount(),
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                DocumentId: item.Id.toString(),
                Id: "",
                IsPreview: false,
                IsDownload: false,
                IsDocx: false,
            };

            Helper.getDocumentVersion(req).then(async (res) => {
                var auxList: IDocumentVersion[] = [];
                if (res != null && res.length > 0) {
                    res.forEach((e: any) => {
                        auxList.push(e);
                    });
                }
                setversionList(auxList);
                setLayoutLoaded(true);
                setSpinnerText("");
            });
            setShowVersion(true);
        });
    };

    const updateVersions = () => {
        onItemVersions(docSelected);
    };

    const hideEmail = () => {
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        setEmailMoniker("");
        setShowEmail(false);
        setDocumentDescription("");
        setDocumentId(-1);
    };

    const hideVersion = () => {
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        setShowVersion(false);
    };
    const hideShare = () => {
        setDocumentId(-1);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        setShowShare(false);
    };
    const hideAttachment = () => {
        setShowAttachment(false);
    };
    const hideExecuted = () => {
        setShowExecuted(false);
    };
    const onDocumentAttachment = (item: IDocument) => {
        setTemplateId(item.TemplateId);
        setDocumentId(item.Id);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setShowAttachment(true);
        setDocumentDescription(`${item.Description}`);
        setActiveSubscription(item.ActiveSubscription);
    };

    const onClone = (item: IDocument) => {
        const id = item.Id;
        const getData = async () => {
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id.toString(),
                IsPreview: false,
                IsDownload: true,
                IsDocx: true,
            };
            //var res = await Helper.getDocumentDownload(req)

            setDocumentId(item.Id);
            setTemplateId(item.TemplateId);
            //setData(item.Data)
            setShortcut(item.Description);
            setTemplateVersion(item.TemplateVersion);
            //sessionStorage.setItem("formControls", res.Data);
            setShowModalClone(true);
        };

        getData();
    };

    const onExecuted = (item: IDocument) => {
        setDocumentId(item.Id);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setShowExecuted(true);
        setModalTitle(t("title.modalExecuted", { ns: "recordsign" }));
        setDocumentDescription(`${item.Description}`);
    };

    const onItemSend = (item: IDocument) => {
        const user = Helper.getUsername();

        var emailMoniker = "";
        var allowDocx = false;
        var sendMail = false;
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            CultureName: "",
            Released: false,
            emailMoniker: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        if (item.TemplatePropertyBag != null) {
            var propBag = atob(item.TemplatePropertyBag);
            try {
                jsonBag = JSON.parse(propBag);
                emailMoniker = jsonBag.emailMoniker;
                allowDocx = jsonBag.AllowDocX;
                sendMail = jsonBag.AllowSendMail;
            } catch (error) { }
        }

        setModalId(item.Id);
        setLayoutLoaded(false);
        setModalTitle("New Mail");
        setSpinnerText("Loading email...");
        setEmailMoniker(emailMoniker);
        setAllowDocx(allowDocx);
        setDocumentDescription(`${item.Description}`);
        setDocumentId(item.Id);
        setTemplateId(item.TemplateId)
        setUsername(user);
        setLayoutLoaded(true);
        setSpinnerText("");
        setShowEmail(true);
    };

    const onItemShare = (item: IDocument) => {
        const user = Helper.getUsername();

        var emailMoniker = "";
        var documentShare = false;
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            Released: false,
            emailMoniker: "",
            CultureName: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        if (item.TemplatePropertyBag != null) {
            var propBag = atob(item.TemplatePropertyBag);
            try {
                jsonBag = JSON.parse(propBag);
                emailMoniker = jsonBag.emailMoniker;
                documentShare = jsonBag.DocumentShare;
            } catch (error) { }
        }

        setPropertyBag(jsonBag);
        setModalId(item.Id);
        setLayoutLoaded(false);
        setModalTitle(t("title.modal", { ns: "recordshare" }));
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setDocumentDescription(`${item.Description}`);
        setDocumentId(item.Id);
        setUsername(user);

        setEmailMoniker(emailMoniker);
        //setOwnerName(item!.ownerFullName!)
        setOwnerUser(item!.UserName!);
        setTemplateCategory(item!.Category!);
        setTemplateName(item!.Name);
        setDocumentShare(documentShare);
        setAudienceName(item!.AudienceName!);
        setTemplateId(item!.TemplateId);
        setLayoutLoaded(true);
        setSpinnerText("");

        setShowShare(true);
    };

    const onItemContextMenu = (
        item?: IDocument,
        index?: number,
        ev?: Event
    ) => {
        console.log(item);
    };

    const hideModal = () => {
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setLayout("");
        setModalTitle("");
        setShowModal(false);
    };
    const hideAddNoteModal = () => {
        setShowAddNote(false);
    };
    const hideModalClone = () => {
        setShowModalClone(false);
    };
    const hideModalActivity = () => {
        setshowActivity(false);
        setModalTitle("");
        setDocumentId(-1);
    };
    const hideModalNotes = () => {
        setShowNotes(false);
        setModalTitle("");
        setDocumentId(-1);
    };
    const hideModalSigners = () => {
        setShowSigners(false);
        setModalTitle("");
        setDocumentId(-1);
    };
    const hideConfirmationModal = () => {
        setShowConfirmationModal(false);
    };
    const getKey = (item: IDocument, index?: number): string => {
        if (item !== undefined) return item.Id.toString();

        return "";
    };

    const onItemVersionInvoked = (item: IDocument) => {
        if (item.Versions > 1) {
            if (allowAdminAccess()) {
                getAllowDocX(item);
                onItemVersions(item);
                _deselect();
            }
        }
    };

    const onItemAttachmentInvoked = (item: IDocument) => {
        if (item.Versions > 1) {
            if (allowAdminAccess()) {
                getAllowDocX(item);
                onItemVersions(item);
                _deselect();
            }
        }
    };

    const confirmRemove = (id: string) => {
        const RemoveDoc = async () => {

            const request = {
                DocumentId: id,
                TargetUserName: Helper.getUsername()
            }

            let {data}: any = await HttpRequest.getWithSession('DeleteAllowedUser', request)
            const response = data

            if (response !== undefined) {
                if (response.Success) {
                    _update();
                }
            }

            setSpinnerText("");
            hideRemove();
        };
        RemoveDoc();
    };
    const hideRemove = () => {
        setShowRemove(false);
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        setremoveMessage("");
        setActionText("");
        setLayoutLoaded(false);
    };

    const getRecordDateTitle = (item: IDocument) => {
        var result;

        item.DateCreated !== null
            ? (result =
                t("text.created", { ns: "workspace" }) +
                Helper.getLocateDate(item.DateCreated, 2))
            : (result = "N/A");
        item.DateUpdated !== null
            ? (result +=
                t("text.updated", { ns: "workspace" }) +
                Helper.getLocateDate(item.DateCreated, 2))
            : (result = result);

        return result;
    };

    const getRecordDescriptionCategoryName = (item: IDocument) => {
        var value = item.Name + "(" + item.Category + ")";
        if (value.length > 39) {
            value = value.substring(0, 40) + "...";
        }
        return value;
    };

    const getRecordDescriptionHTMLTemplate = (item: IDocument) => {

        return (
            <>
                <Stack>
                    {item.Tickler !== null &&
                        item.Tickler.trim().length > 0 &&
                        <StackItem>

                            <Text block variant={"small"}
                                styles={{ root: { color: "#FF0000", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } }}
                            >
                                {item.Tickler}
                            </Text>
                        </StackItem>
                    }

                    <Text block={true} variant={"mediumPlus"} styles={{
                        root: {
                            fontWeight: "500",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            color: item.Tickler !== null &&
                                item.Tickler.trim().length > 0 && item.Tickler.includes("New") ? branding.theme.palette.themePrimary : "default"
                        }
                    }}
                        title={t("title.subject", {
                            ns: "workspace",
                            Description: item.Description,
                            interpolation: { escapeValue: false }
                        })}>{item.Description}
                    </Text>


                    <Text block={true} variant={"medium"}
                        styles={{ root: { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } }}
                        title={t("title.applicationName", {
                            ns: "workspace",
                            Name: item.Name,
                            Category: item.Category,
                            interpolation: { escapeValue: false }
                        })}
                    >{`${item.Category} - ${item.Name}`}</Text>
                    <Text block={true} variant={"medium"} styles={{
                        root: {
                            fontWeight: "300",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }
                    }}
                        title={
                            t("title.origen", {
                                interpolation: { escapeValue: false },
                                ns: "workspace", Name:
                                    item.OnBehalfUser === null || item.OnBehalfUser.trim().length === 0 ?
                                        item.FullName !== null && item.FullName.trim().length !== 0 && item.FullName.toLowerCase().trim() !== item.UserName.toLowerCase().trim() ? `${item.FullName} <${item.UserName}>` : item.UserName
                                        :
                                        item.OnBehalfUserFullName !== null && item.OnBehalfUserFullName.trim().length !== 0 && item.OnBehalfUserFullName.toLowerCase().trim() !== item.OnBehalfUser.toLowerCase().trim() ? `${item.OnBehalfUserFullName} <${item.OnBehalfUser}>` : item.OnBehalfUser
                            })
                        }
                    >
                        {item.OnBehalfUser === null || item.OnBehalfUser.trim().length === 0 ?
                            item.FullName !== null && item.FullName.trim().length !== 0 && item.FullName.toLowerCase().trim() !== item.UserName.toLowerCase().trim() ? item.FullName : item.UserName
                            :
                            item.OnBehalfUserFullName !== null && item.OnBehalfUserFullName.trim().length !== 0 && item.OnBehalfUserFullName.toLowerCase().trim() !== item.OnBehalfUser.toLowerCase().trim() ? item.OnBehalfUserFullName : item.OnBehalfUser
                        }
                    </Text>

                    <StackItem>
                        <Text block
                            variant={"small"}
                            title={getRecordDateTitle(item)}
                            styles={{ root: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } }}
                        >
                            {item.DateUpdated !== null
                                ? Helper.getLocateDate(item.DateUpdated, 3)
                                : item.DateCreated !== null
                                    ? Helper.getLocateDate(item.DateCreated, 3)
                                    : null}

                        </Text>
                    </StackItem>


                </Stack>
            </>
        )


        // return (
        //     <div>
        //         <div className="ms-Grid-row" style={{ paddingBottom: "5px" }}>
        //             <div
        //                 title={t("title.subject", {
        //                     ns: "workspace",
        //                     Description: item.Description,
        //                 })}
        //                 className="ms-Grid-col ms-sm8 ms-md8 ms-lg8"
        //             >
        //                 {item.Description.length > 32
        //                     ? item.Description.substring(0, 31) + "..."
        //                     : item.Description}
        //                 <div
        //                     style={{
        //                         color: "#FF0000",
        //                         fontSize: "x-small",
        //                         float: "right",
        //                     }}
        //                 >
        //                     <sup>
        //                         {item.Tickler !== null &&
        //                         item.Tickler.length > 0
        //                             ? item.Tickler
        //                             : null}
        //                     </sup>
        //                 </div>
        //             </div>
        //             <div
        //                 title={getRecordDateTitle(item)}
        //                 className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"
        //             >
        //                 {item.DateUpdated !== null
        //                     ? Helper.getLocateDate(item.DateUpdated, 3)
        //                     : item.DateCreated !== null
        //                     ? Helper.getLocateDate(item.DateUpdated, 3)
        //                     : null}
        //             </div>
        //         </div>
        //         <div className="ms-Grid-row">
        //             <div
        //                 title={t("title.applicationName", {
        //                     ns: "workspace",
        //                     Name: item.Name,
        //                     Category: item.Category,
        //                 })}
        //                 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
        //             >
        //                 {getRecordDescriptionCategoryName(item)}
        //             </div>
        //         </div>
        //         <div className="ms-Grid-row">
        //             <div
        //                 title={
        //                     t("title.origen", { ns: "workspace" }) +
        //                     (item.OnBehalfUserFullName !== null
        //                         ? item.OnBehalfUserFullName
        //                         : item.FullName) +
        //                     "(" +
        //                     (item.OnBehalfUserFullName !== null
        //                         ? item.OnBehalfUser
        //                         : item.UserName) +
        //                     ")"
        //                 }
        //                 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
        //             >
        //                 {item.OnBehalfUserFullName !== null
        //                     ? item.OnBehalfUserFullName
        //                     : item.FullName}
        //             </div>
        //         </div>
        //     </div>
        // );
    };
    const getRecordDescriptionHTMLTemplateGroup = (item: IDocument) => {
        return (
            <div style={{ minWidth: "280px", maxWidth: "280px" }}>
                <div className="ms-Grid-row" style={{ paddingBottom: "5px" }}>
                    <div
                        title={t("title.subject", {
                            ns: "workspace",
                            Description: item.Description,
                        })}
                        className="ms-Grid-col ms-sm8 ms-md8 ms-lg8"
                    >
                        {item.Description.length > 32
                            ? item.Description.substring(0, 31) + "..."
                            : item.Description}
                        <div
                            style={{
                                color: "#FF0000",
                                fontSize: "x-small",
                                float: "right",
                            }}
                        >
                            <sup>
                                {item.Tickler !== null &&
                                    item.Tickler.length > 0
                                    ? item.Tickler
                                    : null}
                            </sup>
                        </div>
                    </div>
                    <div
                        title={getRecordDateTitle(item)}
                        className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"
                    >
                        {item.DateUpdated !== null
                            ? formatFromUTC(item.DateUpdated, "ll")
                            : item.DateCreated !== null
                                ? formatFromUTC(item.DateCreated, "ll")
                                : null}
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div
                        title={
                            "Application name and (category) - " +
                            item.Name +
                            " (" +
                            item.Category +
                            ")"
                        }
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                    >
                        {getRecordDescriptionCategoryName(item)}
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div
                        title={
                            "Originally submitted by " +
                            (item.OnBehalfUserFullName !== null
                                ? item.OnBehalfUserFullName
                                : item.FullName) +
                            "(" +
                            (item.OnBehalfUserFullName !== null
                                ? item.OnBehalfUser
                                : item.UserName) +
                            ")"
                        }
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                    >
                        {item.OnBehalfUserFullName !== null
                            ? item.OnBehalfUserFullName
                            : item.FullName}
                    </div>
                </div>
            </div>
        );
    };

    const getParsedActivity = (item: IDocument) => {
        var value = item.LastActivity;

        if (item.LastActivity !== null) {
            var location = item.LastActivity.toLowerCase().indexOf("'");
            if (location > -1) {
                value = item.LastActivity.substring(0, location);
            }
        }
        return value;
    };

    const getVersionControlCell = (item: IDocument) => {
        return (
            <Text
                style={{
                    cursor: (allowAdminAccessOnLoad(item) && item.Versions > 1) && !props.isTicklerQueue(item.Tickler) ? "pointer" : "default",
                    display: "block",
                    color: branding.theme.palette.themeDarker,
                }}
                onClick={(allowAdminAccessOnLoad(item) && item.Versions > 1) && !props.isTicklerQueue(item.Tickler) ? () => {
                    onItemVersionInvoked(item);
                } : undefined}
                title={
                    item.UserName === Helper.getUsername() &&
                        item.Versions > 1
                        ?
                        t("title.gridVersions", { ns: "workspace", count: item.Versions })
                        : t("title.gridColVersions", { ns: "workspace", count: item.Versions })
                }
            >
                {item.Versions}

            </Text>
        )
    };
    const getVersionControlCellGroup = (item: IDocument) => {
        if (allowAdminAccessOnLoad(item) && item.Versions > 1) {
            return (
                <div style={{ minWidth: 50, maxWidth: 50 }}>
                    <a
                        style={{
                            cursor: "pointer",
                            display: "block",
                            textAlign: "right",
                            color: branding.theme.palette.themePrimary,
                        }}
                        onClick={() => {
                            onItemVersionInvoked(item);
                        }}
                        title={
                            item.UserName === Helper.getUsername() &&
                                item.Versions > 1
                                ? item.Versions.toString() +
                                t("title.gridVersions", { ns: "workspace" })
                                : t("title.gridColVersions", {
                                    ns: "workspace",
                                }) +
                                item.Versions.toString() +
                                "."
                        }
                    >
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {item.Versions}
                        </div>
                    </a>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        display: "block",
                        textAlign: "right",
                        minWidth: 50,
                        maxWidth: 50,
                        color: branding.theme.palette.themePrimary,
                    }}
                >
                    <div
                        title={
                            item.UserName === Helper.getUsername() &&
                                item.Versions > 1
                                ? item.Versions.toString() +
                                t("title.gridVersions", { ns: "workspace" })
                                : t("title.gridColVersions", {
                                    ns: "workspace",
                                }) +
                                item.Versions.toString() +
                                "."
                        }
                        style={{
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {item.Versions}
                    </div>
                </div>
            );
        }
    };

    const allowAdminAccess = () => {
        if (
            docSelected.Owner ||
            docSelected.CoOwner ||
            docSelected.UserName === Helper.getUsername()
        ) {
            return true;
        } else {
            return false;
        }
    };

    const isOptionDisabled = (
        menuOption: CheckoutOptionsVisibilityType,
        item: IDocument
    ) => {
        switch (menuOption) {
            case CheckoutOptionsVisibilityType.Checkin: {
                if (
                    item.CheckedOut &&
                    item.CheckedOutUser == Helper.getUsername()
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            case CheckoutOptionsVisibilityType.Checkout: {
                if (allowAdminAccess() && item.CheckedOut == false) {
                    return false;
                } else if (
                    allowAdminAccess() == false &&
                    item.CheckedOut == false
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            case CheckoutOptionsVisibilityType.Download: {
                if (
                    item.CheckedOut &&
                    item.CheckedOutUser == Helper.getUsername()
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            case CheckoutOptionsVisibilityType.UndoCheckout: {
                if (
                    (allowAdminAccess() && item.CheckedOut) ||
                    (item.CheckedOut &&
                        item.CheckedOutUser == Helper.getUsername())
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            case CheckoutOptionsVisibilityType.WebFormIcon: {
                if (item.CheckedOut) {
                    return true;
                } else {
                    return false;
                }
            }
            case CheckoutOptionsVisibilityType.Manage: {
                if (item.CheckedOut) {
                    return true;
                } else {
                    return false;
                }
            }
            case CheckoutOptionsVisibilityType.Delete: {
                if (item.Suspended || item.Closed || item.CheckedOut) {
                    return true;
                } else {
                    return false;
                }
            }
            case CheckoutOptionsVisibilityType.WebformLink: {
                if (item.Suspended || item.Closed || item.CheckedOut) {
                    return true;
                } else {
                    return false;
                }
            }
            case CheckoutOptionsVisibilityType.UpdateWebForm: {
                if (item.Suspended || item.Closed || item.CheckedOut) {
                    return true;
                } else {
                    return false;
                }
            }
            default:
                return false;
        }
    };

    const columns: IColumn[] = [
        {
            key: "column1",
            name: "File Type",
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: t("title.gridColDocumentIcon", { ns: "workspace" }),
            iconName: "pdf",
            isIconOnly: true,
            fieldName: "name",
            minWidth: 20,
            maxWidth: 20,
            onColumnClick: onColumnClick,
            onRender: (item: IDocument) => {
                return (
                    props.isTicklerQueue(item.Tickler) ? null :
                    <IconButton
                        iconProps={{ iconName: "pdf" }}
                        style={{ cursor: "pointer" }}
                        title={t("title.gridDocumentIcon", { ns: "workspace" })}
                        className={classNames.fileIconImg}
                        alt={t("title.gridDocumentIcon", { ns: "workspace" })}
                        onClick={() => {
                            onItemPreview(docSelected);
                            _deselect();
                        }}
                    />
                );
            },
        },
        {
            key: "column2",
            name: t("Common.Keys.FormWizard", { ns: "common" }),
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: t("title.gridColFormIcon", { ns: "workspace" }),
            iconName: "officeFormsLogo",
            isIconOnly: true,
            fieldName: "name",
            minWidth: 20,
            maxWidth: 20,
            onColumnClick: onColumnClick,
            onRender: (item: IDocument) => {
                if (allowUpdateOnLoad(item)) {
                    return (
                        props.isTicklerQueue(item.Tickler) ? null :
                        <IconButton
                            iconProps={{ iconName: "officeFormsLogo" }}
                            hidden={isOptionDisabled(
                                CheckoutOptionsVisibilityType.WebFormIcon,
                                item
                            )}
                            disabled={
                                !item.ActiveSubscription &&
                                sessionStorage.getItem("billingActive") ===
                                "true"
                            }
                            style={{ cursor: "pointer" }}
                            title={t("title.gridFormIcon", { ns: "workspace" })}
                            className={classNames.fileIconImg}
                            alt={t("title.gridFormIcon", { ns: "workspace" })}
                            onClick={() => {
                                onItemInvoked(item);
                                _deselect();
                            }}
                        />
                    );
                }
            },
        },
        {
            key: "column3",
            name: t("Common.Keys.Subject", { ns: "common" }),
            fieldName: "Description",
            minWidth: isMobile ? 150 : 280,
            maxWidth: isMobile ? 150 : 280,
            isResizable: false,
            onColumnClick: onColumnClick,
            data: "string",
            isPadded: false,
            onRender: (item: IDocument) => {
                return getRecordDescriptionHTMLTemplate(item);
            },
        },
        {
            key: "column4",
            name: t("Common.Keys.Activity", { ns: "common" }),
            fieldName: "LastActivity",
            minWidth: isMobile ? 0 : 100,
            maxWidth: isMobile ? 0 : 140,
            isResizable: true,
            isCollapsible: true,
            onColumnClick: onColumnClick,
            data: "string",
            isPadded: false,
            onRender: (item: IDocument) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "start",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>

                        <Text
                            styles={{root: { cursor: !props.isTicklerQueue(item.Tickler) ? "pointer" : "default", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",  color: branding.theme.palette.themeDarker}}}
                            onClick={!props.isTicklerQueue(item.Tickler) ? () => {
                                onItemStatusInvoked(item);
                            } : undefined}

                            data-for="main"
                            data-iscapture="true"
                            data-tip={t("title.gridActivityIcon", {
                                ns: "workspace",
                            })}
                        >

                            <ReactTooltip
                                id="main"
                                place="top"
                                type="info"
                                effect="float"
                                backgroundColor={branding.theme.palette.themeDark}
                                multiline={true}
                            />
                            {getParsedActivity(item)}
                        </Text>
                    </StackItem>
                );
            },
        },
        {
            key: "column5",
            name: t("Common.Keys.Note", { ns: "common" }),
            fieldName: "Note",
            minWidth: isMobile ? 0 : 140,
            maxWidth: isMobile ? 0 : 800,
            isCollapsible: true,
            isMultiline: true,
            isResizable: true,
            onRender: (item: IDocument) => {


                return (
                    <Stack styles={{
                        root: {
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                        }
                    }}
                        onClick={!props.isTicklerQueue(item.Tickler) ? () => {
                            onItemNotesInvoked(item);
                        } : undefined}
                        data-for="main"
                        data-iscapture="true"
                        data-tip={
                            item.LastNote !== null
                                ? t("title.gridNoteIcon1", {
                                    ns: "workspace",
                                })
                                : t("title.gridNoteIcon2", {
                                    ns: "workspace",
                                })
                        }

                    >
                        <Text
                            id="titleNotes"
                            styles={{
                                root: {
                                    cursor: !props.isTicklerQueue(item.Tickler) ? "pointer" : "default",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    color: branding.theme.palette.themeDarker,
                                    width: "100%",
                                }
                            }}


                        >
                            {item.LastNote !== null ? item.LastNote : " "}
                        </Text>
                        <ReactTooltip
                            id="main"
                            place="top"
                            type="info"
                            effect="float"
                            backgroundColor={branding.theme.palette.themeDark}
                            multiline={true}
                        />
                    </Stack>
                );
            },
            onColumnClick: onColumnClick,
            data: "string",
            isPadded: false,
        },
        {
            key: "column6",
            name: t("title.attachmentText", { ns: "workspace" }),
            fieldName: "Attachments",
            minWidth: isMobile ? 0 : 70,
            maxWidth: isMobile ? 0 : 70,
            isResizable: false,
            isCollapsible: true,
            onColumnClick: onColumnClick,
            data: "number",
            isPadded: true,
            onRender: (item: IDocument) => {


                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text
                            id="titleNotes"
                            styles={{
                                root: {
                                    cursor: !props.isTicklerQueue(item.Tickler) ? "pointer" : "default",
                                    color: branding.theme.palette.themeDarker,
                                }
                            }}
                            onClick={!props.isTicklerQueue(item.Tickler) ? () => {
                                onDocumentAttachment(item);
                            } : undefined}


                            title={
                                item.UserName === Helper.getUsername() &&
                                    item.Versions > 1
                                    ? item.Attachments.toString() +
                                    t("title.gridAttachment", { ns: "workspace" })
                                    : item.Attachments.toString() +
                                    " " +
                                    t("title.gridAttachment", { ns: "workspace" })
                            }
                        >

                            {item.Attachments}
                        </Text>
                    </StackItem>
                );

            },
        },
        {
            key: "column7",
            name: t("Common.Keys.Version", { ns: "common" }),
            fieldName: "Versions",
            minWidth: isMobile ? 0 : 50,
            maxWidth: isMobile ? 0 : 50,
            isResizable: false,
            isCollapsible: true,
            onColumnClick: onColumnClick,
            data: "number",
            isPadded: true,
            onRender: (item: IDocument) => {

                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        {getVersionControlCell(item)}
                    </StackItem>
                );
            },
        },
        {
            key: "column8",
            name: t("Common.Keys.Action", { ns: "common" }),
            fieldName: "Options",
            minWidth: isMobile ? 20 : 40,
            maxWidth: isMobile ? 20 : 40,
            isResizable: false,
            onColumnClick: onColumnClick,
            onRender: (item: IDocument) => {

                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        {props.isTicklerQueue(item.Tickler) ? null :
                        <IconButton
                            menuIconProps={{
                                iconName: "MoreVertical",
                                style: {
                                    fontSize: 20,
                                    color: "#242424",
                                    textAlign: "right",
                                },
                            }}
                            role="button"
                            aria-haspopup
                            aria-label={t("title.gridColOptions", {
                                ns: "workspace",
                            })}
                            styles={iconButtonStyles}
                            menuProps={
                                isMobile &&
                                    sessionStorage.getItem("entityId") !== "Not In Teams" ?
                                    item.Closed == true ? menuPropsMobileClosed : menuPropsMobile
                                    :
                                    item.Closed == true ? menuPropsClosed : menuProps
                            }
                            title={t("title.gridOptions", {
                                ns: "workspace",
                            })}
                        />}

                    </StackItem>
                )

            },
            isPadded: true,
        },
    ];
    const columnsGroup: IColumn[] = [
        {
            key: "column1",
            name: "File Type",
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: t("title.gridColDocumentIcon", { ns: "workspace" }),
            iconName: "Page",
            isIconOnly: true,
            fieldName: "name",
            minWidth: 20,
            maxWidth: 20,
            onColumnClick: onColumnClick,
            onRender: (item: IDocument) => {
                return (
                    <div style={{ minWidth: 20, maxWidth: 20 }}>
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                onItemPreview(docSelected);
                                _deselect();
                            }}
                            title={"Click to preview document in viewer."}
                            src={"/pdf.svg"}
                            className={classNames.fileIconImg}
                            alt={"Preview"}
                        />
                    </div>
                );
            },
        },
        {
            key: "column2",
            name: t("Common.Keys.FormWizard", { ns: "common" }),
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: t("title.gridColFormIcon", { ns: "workspace" }),
            iconName: "Page",
            isIconOnly: true,
            fieldName: "name",
            minWidth: 20,
            maxWidth: 20,
            onColumnClick: onColumnClick,
            onRender: (item: IDocument) => {
                if (allowUpdateOnLoad(item)) {
                    return (
                        <IconButton
                            iconProps={{ iconName: "officeFormsLogo" }}
                            hidden={isOptionDisabled(
                                CheckoutOptionsVisibilityType.WebFormIcon,
                                item
                            )}
                            disabled={
                                !item.ActiveSubscription &&
                                sessionStorage.getItem("billingActive") ===
                                "true"
                            }
                            style={{ cursor: "pointer" }}
                            title={t("title.gridFormIcon", { ns: "workspace" })}
                            className={classNames.fileIconImg}
                            alt={"Update"}
                            onClick={() => {
                                onItemInvoked(item);
                                _deselect();
                            }}
                        />
                    );
                }
            },
        },
        {
            key: "column3",
            name: t("Common.Keys.Subject", { ns: "common" }),
            fieldName: "Description",
            minWidth: 280,
            maxWidth: 280,
            isResizable: false,
            onColumnClick: onColumnClick,
            data: "string",
            isPadded: false,
            onRender: (item: IDocument) => {
                return getRecordDescriptionHTMLTemplateGroup(item);
            },
        },
        {
            key: "column4",
            name: t("Common.Keys.Activity", { ns: "common" }),
            fieldName: "LastActivity",
            minWidth: 100,
            maxWidth: isMobile ? 140 : 140,
            isResizable: true,
            onColumnClick: onColumnClick,
            data: "string",
            isPadded: false,
            onRender: (item: IDocument) => {
                var value = item.LastActivity;
                return (
                    <div
                        style={{
                            minWidth: 100,
                            maxWidth: isMobile ? 140 : 140,
                        }}
                    >
                        <a
                            style={{
                                cursor: "pointer",
                                color: branding.theme.palette.themePrimary,
                            }}
                            onClick={() => {
                                onItemStatusInvoked(item);
                            }}
                            title={t("title.gridActivityIcon", {
                                ns: "workspace",
                            })}
                        >
                            {getParsedActivity(item)}
                        </a>
                    </div>
                );
            },
        },
        {
            key: "column5",
            name: t("Common.Keys.Note", { ns: "common" }),
            fieldName: "Note",
            minWidth: 140,
            maxWidth: isMobile ? 280 : 800,
            isMultiline: true,
            isResizable: true,
            onRender: (item: IDocument) => {
                return (
                    <div
                        style={{
                            minWidth: 140,
                            maxWidth: isMobile ? 280 : 800,
                        }}
                    >
                        <a
                            style={{
                                cursor: "pointer",
                                color: branding.theme.palette.themePrimary,
                            }}
                            onClick={() => {
                                onItemNotesInvoked(item);
                            }}
                            title={
                                item.LastNote !== null
                                    ? t("title.gridNoteIcon1", {
                                        ns: "workspace",
                                    })
                                    : t("title.gridNoteIcon2", {
                                        ns: "workspace",
                                    })
                            }
                        >
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {item.LastNote !== null &&
                                    item.LastNote.length > 80
                                    ? item.LastNote.substring(0, 79) + "..."
                                    : item.LastNote}
                            </div>
                        </a>
                    </div>
                );
            },
            onColumnClick: onColumnClick,
            data: "string",
            isPadded: false,
        },
        {
            key: "column6",
            name: t("title.attachmentText", { ns: "workspace" }),
            fieldName: "Attachments",
            minWidth: 70,
            maxWidth: 70,
            isResizable: false,
            onColumnClick: onColumnClick,
            data: "number",
            isPadded: true,
            onRender: (item: IDocument) => {
                return (
                    <div style={{ minWidth: 70, maxWidth: 70 }}>
                        <a
                            style={{
                                cursor: "pointer",
                                display: "block",
                                textAlign: "right",
                                color: branding.theme.palette.themePrimary,
                            }}
                            onClick={() => {
                                onDocumentAttachment(item);
                            }}
                            title={
                                item.UserName === Helper.getUsername() &&
                                    item.Versions > 1
                                    ? item.Attachments.toString() +
                                    t("title.gridAttachment", {
                                        ns: "workspace",
                                    })
                                    : item.Attachments.toString() +
                                    " " +
                                    t("Common.Keys.Attachments", {
                                        ns: "common",
                                    })
                            }
                        >
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {item.Attachments}
                            </div>
                        </a>
                    </div>
                );
            },
        },
        {
            key: "column7",
            name: t("Common.Keys.Version", { ns: "common" }),
            fieldName: "Versions",
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            onColumnClick: onColumnClick,
            data: "number",
            isPadded: true,
            onRender: (item: IDocument) => {
                return getVersionControlCellGroup(item);
            },
        },
        {
            key: "column8",
            name: t("Common.Keys.Action", { ns: "common" }),
            fieldName: "Options",
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onColumnClick: onColumnClick,
            onRender: (item: IDocument) => {
                if (item.Closed == true) {
                    return (
                        <div
                            style={{
                                width: "40px",
                                minWidth: 40,
                                maxWidth: 40,
                                display: "block",
                                textAlign: "right",
                            }}
                        >
                            <IconButton
                                menuIconProps={{
                                    iconName: "MoreVertical",
                                    style: {
                                        fontSize: 20,
                                        color: "#242424",
                                        textAlign: "right",
                                    },
                                }}
                                role="button"
                                aria-haspopup
                                aria-label={t("title.gridColOptions", {
                                    ns: "workspace",
                                })}
                                styles={iconButtonStyles}
                                menuProps={menuPropsClosed}
                                title={t("title.gridOptions", {
                                    ns: "workspace",
                                })}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                width: "40px",
                                minWidth: 40,
                                maxWidth: 40,
                                display: "block",
                                textAlign: "right",
                            }}
                        >
                            <IconButton
                                menuIconProps={{
                                    iconName: "MoreVertical",
                                    style: { fontSize: 20, color: "#242424" },
                                }}
                                role="button"
                                aria-haspopup
                                aria-label={t("title.gridColOptions", {
                                    ns: "workspace",
                                })}
                                styles={iconButtonStyles}
                                menuProps={menuProps}
                                title={t("title.gridOptions", {
                                    ns: "workspace",
                                })}
                            />
                        </div>
                    );
                }
            },
            isPadded: true,
        },
    ];
    const disabledCheckOut = (): boolean => {
        if (docSelected.CheckedOut) {
            return true;
        } else {
            return false;
        }
    };
    const disabledCheckIn = (): boolean => {
        if (docSelected.CheckedOut) {
            return false;
        } else {
            return true;
        }
    };

    const setToggleUploadDoc = (state: boolean) => {
        setShowUploadDoc(state);
    };
    const hideCheckInOut = () => {
        return docSelected.AllowCheckInOut ? false : true;
    };

    const allowAdminAccessOnLoad = (item: IDocument) => {
        if (
            item.Owner ||
            item.CoOwner ||
            item.UserName === Helper.getUsername()
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getAllowSendMail = (item: IDocument) => {
        var allowSendMail = false;
        if (
            item.TemplatePropertyBag != "" &&
            item.TemplatePropertyBag !== null
        ) {
            var propBag = atob(item.TemplatePropertyBag);
            var jsonBag = JSON.parse(propBag);
            allowSendMail = jsonBag.AllowSendMail;
        }

        if (
            docSelected.Owner ||
            docSelected.CoOwner ||
            docSelected.UserName === Helper.getUsername() ||
            allowSendMail
        ) {
            return true;
        } else {
            return false;
        }
    };

    const allowUpdateOnLoad = (item: IDocument) => {
        var stop = "";

        if (item.Suspended || item.Closed) {
            return false;
        }

        if (
            item.Owner ||
            item.CoOwner ||
            item.UserName === Helper.getUsername()
        ) {
            return true;
        } else {
            if (item.AllowDocumentUpdate) {
                return true;
            } else {
                return false;
            }
        }
    };

    const allowUpdate = () => {
        if (
            docSelected.Owner ||
            docSelected.CoOwner ||
            docSelected.UserName === Helper.getUsername()
        ) {
            return true;
        } else {
            if (docSelected.AllowDocumentUpdate) {
                return true;
            } else {
                return false;
            }
        }
    };

    const getDocumentOnlyDataPoint = async (
        item: IDocument,
        xmlPayload: boolean
    ) => {
        const req: IDocumentOnlyDataPointRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            DocumentId: item.Id,
            XmlPayload: xmlPayload,
            Path: await Helper.getSite(),
        };

        Helper.getDocumentOnlyDataPoint(req).then((data) => {
            const decoded = decode(data);
            try {
                const fileName = `Data_${item.Name}.${xmlPayload ? "xml" : "xlsx"
                    }`;
                let mimeType;
                if (xmlPayload) {
                    mimeType = "application/xml";
                } else {
                    mimeType =
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                }
                const file = new Blob([decoded], { type: mimeType });
                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(file);
                a.download = fileName;
                a.click();
            } catch (error) { }
        });
    };

    const getDocumentOnlyPackage = async (item: IDocument) => {
        const req: IDocumentOnlyPackageRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            DocumentId: item.Id,
            Path: await Helper.getSite(),
        };

        Helper.getDocumentOnlyPackage(req).then((data) => {
            const decoded = decode(data.PackageBase64String);
            try {
                const fileName = `Package_${data.FileName}`;
                let mimeType = "application/zip";

                const file = new Blob([decoded], { type: mimeType });
                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(file);
                a.download = fileName;
                a.click();
            } catch (error) { }
        });
    };
    const userBelongsToTemplateAudiece = () => {
        let templates = props.templateOnlyList.reduce(
            (acc: any, el: any) => acc.concat(el.links),
            []
        );
        let template = templates.find(
            (item: any) => item.key == docSelected.TemplateId.toString()
        );
        if (template == undefined) return false;
        
        const fromCache = cacheAudience.current.find(a=>a.id === template.DocumentOnlyAudienceId)
        if(fromCache) return fromCache.isInAudience
        const req: IDocumentOnlyAudienceRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: Helper.getSiteSelected(),
            AudienceMasterId: template.DocumentOnlyAudienceId,
            FromRegistration: false
        };
        return Helper.GetDocumentOnlyAudienceDetailByAudienceMasterId(req).then(
            (data) => {
                const isInAudience = (data as IDocumentOnlyAudienceDetail[]).find((item) => item.UserName == Helper.getUsername())
                if (cacheAudience?.current) {
                    cacheAudience.current = [...cacheAudience?.current, { id: template.DocumentOnlyAudienceId, isInAudience: !!isInAudience }]
                } else {
                    cacheAudience.current = [{ id: template.DocumentOnlyAudienceId, isInAudience: !!isInAudience }]
                }
                return !!isInAudience
            }
        );
    };

    const getAudienceMasterId = async (templateId: string) => {
        let templates = props.templateOnlyList.reduce(
            (acc: any, el: any) => acc.concat(el.links),
            []
        );
        let template = templates.find(
            (item: any) => item.key === templateId
        );
        if (template === undefined) {
            const templateResponse = await Helper.getDocumentOnlyTemplateId(templateId, true)
            return templateResponse?.DocumentOnlyAudienceId !== undefined ? templateResponse.DocumentOnlyAudienceId.toString() : ""

        }
        return template.DocumentOnlyAudienceId.toString();
    };

    const menuItems: IContextualMenuItem[] = [
        {
            key: "activity",
            text: t("title.activityText", { ns: "workspace" }),
            title: t("title.activityTitle", { ns: "workspace" }),
            onClick: () => {
                onItemStatusInvoked(docSelected);
                _deselect();
            },
        },
        {
            key: "attachment",
            text: t("title.attachmentText", { ns: "workspace" }),
            title: t("title.attachmentTitle", { ns: "workspace" }),
            onClick: () => {
                onDocumentAttachment(docSelected);
                _deselect();
            },
        },
        {
            key: "checkout",
            text: t("title.checkoutText", { ns: "workspace" }),
            title: t("title.checkoutTitle", { ns: "workspace" }),
            subMenuProps: {
                items: [
                    {
                        key: "checkin",
                        text: t("title.checkinText", { ns: "workspace" }),
                        title: t("title.checkintTitle", { ns: "workspace" }),
                        onClick: () => {
                            setShowUploadDoc(true);
                        },
                        disabled: isOptionDisabled(
                            CheckoutOptionsVisibilityType.Checkin,
                            docSelected
                        ),
                    },
                    {
                        key: "checkout",
                        text: t("title.checkoutText", { ns: "workspace" }),
                        title: t("title.checkoutTitle", { ns: "workspace" }),
                        onClick: () => {
                            checkIncheckOut(true);
                        },
                        disabled: isOptionDisabled(
                            CheckoutOptionsVisibilityType.Checkout,
                            docSelected
                        ),
                    },
                    {
                        key: "download",
                        text: t("title.downloadText", { ns: "workspace" }),
                        title: t("title.downloadTitle", { ns: "workspace" }),
                        onClick: () => {
                            setDownloadDocX(true);
                            setDocSelected(docSelected);
                            setShowConfirmationModal(true);

                            _deselect();
                        },
                        disabled: isOptionDisabled(
                            CheckoutOptionsVisibilityType.Download,
                            docSelected
                        ),
                    },
                    {
                        key: "undoCheckout",
                        text: t("title.undoCheckoutText", { ns: "workspace" }),
                        title: t("title.undoCheckoutTitle", {
                            ns: "workspace",
                        }),
                        onClick: () => {
                            UndoCheckOut(true);
                        },
                        disabled: isOptionDisabled(
                            CheckoutOptionsVisibilityType.UndoCheckout,
                            docSelected
                        ),
                    },
                ],
            },
            disabled:
                docSelected.Suspended || docSelected.Closed ? true : false,
            className: /*docSelected.AllowCheckInOut ? classNames.showMenu : */classNames.hideMenu,
        },
        {
            key: "clone",
            text: t("title.cloneText", { ns: "workspace" }),
            title:
                !docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true"
                    ? docSelected.SubscriptionStatusMessage
                    : t("title.cloneTitle", { ns: "workspace" }),
            onClick: () => {
                onClone(docSelected);
                _deselect();
            },
            disabled:
                !docSelected.ActiveSubscription &&
                sessionStorage.getItem("billingActive") === "true",
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "delete",
            text: t("title.deleteText", { ns: "workspace" }),
            title: t("title.deleteTitle", { ns: "workspace" }),
            onClick: () => {
                onItemDelete(docSelected);
                _deselect();
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.Delete,
                docSelected
            ),
            className:
                allowAdminAccess() && disabledCheckIn()
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "download",
            text: t("title.downloadText2", { ns: "workspace" }),
            title: t("title.downloadTitle2", { ns: "workspace" }),
            subMenuProps: {
                items: [
                    {
                        key: "datapoints",
                        text: t("title.datapointsText", { ns: "workspace" }),
                        subMenuProps: {
                            items: [
                                {
                                    key: "xml",
                                    text: "XML",
                                    onClick: () => {
                                        getDocumentOnlyDataPoint(
                                            docSelected,
                                            true
                                        );
                                    },
                                },
                                {
                                    key: "xlsx",
                                    text: "XLSX",
                                    onClick: () => {
                                        getDocumentOnlyDataPoint(
                                            docSelected,
                                            false
                                        );
                                    },
                                },
                            ],
                        },
                    },
                    {
                        key: "package",
                        text: t("title.packageText", { ns: "workspace" }),
                        onClick: () => {
                            getDocumentOnlyPackage(docSelected);
                        },
                    },
                ],
            },
            disabled:
                docSelected.Suspended || docSelected.Closed ? true : false,
            className:
                userBelongsToTemplateAudiece() && allowAdminAccess()
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "manage",
            text: t("title.manageText", { ns: "workspace" }),
            title: t("title.manageTitle", { ns: "workspace" }),
            subMenuProps: {
                items: [
                    {
                        key: "close",
                        text: docSelected.Closed
                            ? t("title.reOpenText", { ns: "workspace" })
                            : t("title.closeText", { ns: "workspace" }),
                        title: docSelected.Closed
                            ? t("title.reOpenTitle", { ns: "workspace" })
                            : t("title.closeTitle", { ns: "workspace" }),
                        onClick: () => {
                            onItemClose(docSelected);
                            _deselect();
                        },
                    },
                    {
                        key: "suspend",
                        text: docSelected.Suspended
                            ? t("title.resumeText", { ns: "workspace" })
                            : t("title.suspendText", { ns: "workspace" }),
                        title: docSelected.Suspended
                            ? t("title.resumeTitle", { ns: "workspace" })
                            : t("title.suspendTitle", { ns: "workspace" }),
                        onClick: () => {
                            onItemSuspended(docSelected);
                            _deselect();
                        },
                    },
                ],
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.Manage,
                docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "note",
            text: t("title.noteText", { ns: "workspace" }),
            title: t("title.noteTitle", { ns: "workspace" }),
            onClick: () => {
                onItemNotesInvoked(docSelected);
                _deselect();
            },
        },
        {
            key: "preview",
            text: t("title.previewText", { ns: "workspace" }),
            title: t("title.previewTitle", { ns: "workspace" }),
            onClick: () => {
                onItemPreview(docSelected);
                _deselect();
            },
        },
        {
            key: "Remove",
            text: t("title.removeText", { ns: "workspace" }),
            title: t("title.removeTitle", { ns: "workspace" }),
            onClick: () => {
                onItemRemove(docSelected);
                _deselect();
            },
            className:
                docSelected.UserName === Helper.getUsername()
                    ? classNames.hideMenu
                    : classNames.showMenu,
        },
        {
            key: "Renewals",
            text: t("title.renewalsRecord", { ns: "renewalsalerts" }),
            title: t("title.renewalsRecord", { ns: "renewalsalerts" }),
            onClick: () => {

                setCurrentRenewalItem({
                    Name: docSelected.Description,
                    TemplateId: docSelected.TemplateId,
                    Id: 0,
                    RecordId: docSelected.Id

                })

                setShowRenewals(true)
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.WebformLink,
                docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "sendMail",
            text: t("title.sendMailText", { ns: "workspace" }),
            title: t("title.sendMailTitle", { ns: "workspace" }),
            onClick: () => {
                onItemSend(docSelected);
                _deselect();
            },
            className: getAllowSendMail(docSelected)
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "sendLink",
            text: t("title.sendLinkText", { ns: "workspace" }),
            title: t("title.sendLinkTitle", { ns: "workspace" }),
            onClick: () => {
                toggleFormLink(docSelected);
                _deselect();
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.WebformLink,
                docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "shareDoc",
            text: t("title.shareText", { ns: "workspace" }),
            title: t("title.shareTitle", { ns: "workspace" }),
            onClick: () => {
                onItemShare(docSelected);
                _deselect();
            },
            disabled:
                docSelected.Suspended || docSelected.Closed ? true : false,
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "signDocument",
            text: t("title.signDocumentText", { ns: "workspace" }),
            title: t("title.signDocumentTitle", { ns: "workspace" }),
            onClick: () => {
                onItemSignInvoked(docSelected);
                _deselect();
            },
            disabled:
                docSelected.Suspended || docSelected.Closed ? true : false,
            className:
                allowAdminAccess() && docSelected.AllowESign
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "edit",
            text: t("title.editText", { ns: "workspace" }),
            title:
                !docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true"
                    ? docSelected.SubscriptionStatusMessage
                    : t("title.editTitle", { ns: "workspace" }),
            onClick: () => {
                onItemInvoked(docSelected);
                _deselect();
            },
            disabled:
                (!docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true") ||
                isOptionDisabled(
                    CheckoutOptionsVisibilityType.UpdateWebForm,
                    docSelected
                ),
            className: allowUpdate()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "executed",
            text: t("title.executedText", { ns: "workspace" }),
            title: t("title.executedTitle", { ns: "workspace" }),
            onClick: () => {
                onExecuted(docSelected);
                _deselect();
            },
            className:
                allowAdminAccess() && docSelected.Executed === true
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
    ];

    const checkIncheckOut = async (checkIncheckOut: boolean) => {
        const req: ICheckinCheckoutDocumentOnlyRequest = {
            AccountId: Helper.getDefaultAccount(),
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            DocumentId: docSelected.Id,
            ApiKey: "-1",
            Autoprovision: true,
            Checkout: checkIncheckOut,
            Path: await Helper.getSite(),
        };
        Helper.checkinCheckoutDocumentOnly(req).then((data) => {
            //props.CleanDocument()
            //props.updateDocumentList(Helper.getSessionKey(), Helper.getDefaultAccount(), Helper.getUsername())
            _update();
            _deselect();
        });
    };
    const UndoCheckOut = async (checkIncheckOut: boolean) => {
        const req: ICheckinCheckoutDocumentOnlyRequest = {
            AccountId: Helper.getDefaultAccount(),
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            DocumentId: docSelected.Id,
            ApiKey: "-1",
            Autoprovision: true,
            Checkout: checkIncheckOut,
            Path: await Helper.getSite(),
        };
        Helper.UndoCheckoutDocumentOnly(req).then((data) => {
            //props.CleanDocument()
            //props.updateDocumentList(Helper.getSessionKey(), Helper.getDefaultAccount(), Helper.getUsername())
            _update();
            _deselect();
        });
    };

    const menuProps: IContextualMenuProps = {
        onMenuOpened: () => {
            setDocumentId(docSelected.Id);
        },
        shouldFocusOnMount: true,
        items: menuItems,
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
        props,
        defaultRender
    ) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<
            IDetailsColumnRenderTooltipProps
        > = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />;
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    const _deselect = () => {
        selectionState.setAllSelected(false);
    };

    const _update = () => {
        _deselect();

        GetSubrecords();
        props.update();
    };
    const menuPropsClosed: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems.filter(
            (f) =>
                f.key != "signDocument" &&
                f.key != "checkout" &&
                f.key != "edit"
        ), //menuItems.filter(f => f.key!= "checkout" &&  f.key!= "Sign" &&  f.key!= "Update")
    };
    const menuItemsMobile: IContextualMenuItem[] = [
        {
            key: "activity",
            text: t("title.activityText", { ns: "workspace" }),
            title: t("title.activityTitle", { ns: "workspace" }),
            onClick: () => {
                onItemStatusInvoked(docSelected);
                _deselect();
            },
        },
        {
            key: "attachment",
            text: t("title.attachmentText", { ns: "workspace" }),
            title: t("title.attachmentTitle", { ns: "workspace" }),
            onClick: () => {
                onDocumentAttachment(docSelected);
                _deselect();
            },
        },
        {
            key: "checkout",
            text: t("title.checkoutText", { ns: "workspace" }),
            title: t("title.checkoutTitle", { ns: "workspace" }),
            subMenuProps:
                docSelected.CheckedOut == false
                    ? undefined
                    : {
                        items: [
                            {
                                key: "undoCheckout",
                                text: t("title.undoCheckoutText", {
                                    ns: "workspace",
                                }),
                                title: t("title.undoCheckoutTitle", {
                                    ns: "workspace",
                                }),
                                onClick: () => {
                                    UndoCheckOut(true);
                                },
                                disabled: isOptionDisabled(
                                    CheckoutOptionsVisibilityType.UndoCheckout,
                                    docSelected
                                ),
                            },
                        ],
                    },
            disabled:
                docSelected.Suspended || docSelected.Closed ? true : false,
            className: /*docSelected.AllowCheckInOut ? classNames.showMenu : */classNames.hideMenu,
        },
        {
            key: "clone",
            text: t("title.cloneText", { ns: "workspace" }),
            title:
                !docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true"
                    ? docSelected.SubscriptionStatusMessage
                    : t("title.cloneTitle", { ns: "workspace" }),
            onClick: () => {
                onClone(docSelected);
                _deselect();
            },
            disabled:
                !docSelected.ActiveSubscription &&
                sessionStorage.getItem("billingActive") === "true",
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "delete",
            text: t("title.deleteText", { ns: "workspace" }),
            title: t("title.deleteTitle", { ns: "workspace" }),
            onClick: () => {
                onItemDelete(docSelected);
                _deselect();
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.Delete,
                docSelected
            ),
            className:
                allowAdminAccess() && disabledCheckIn()
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "executed",
            text: t("title.executedText", { ns: "workspace" }),
            title: t("title.executedTitle", { ns: "workspace" }),
            onClick: () => {
                onExecuted(docSelected);
                _deselect();
            },
            className:
                allowAdminAccess() && docSelected.Executed === true
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "manage",
            text: t("title.manageText", { ns: "workspace" }),
            title: t("title.manageTitle", { ns: "workspace" }),
            subMenuProps: {
                items: [
                    {
                        key: "close",
                        text: docSelected.Closed
                            ? t("title.reOpenText", { ns: "workspace" })
                            : t("title.closeText", { ns: "workspace" }),
                        title: docSelected.Closed
                            ? t("title.reOpenTitle", { ns: "workspace" })
                            : t("title.closeTitle", { ns: "workspace" }),
                        onClick: () => {
                            onItemClose(docSelected);
                            _deselect();
                        },
                    },
                    {
                        key: "suspend",
                        text: docSelected.Suspended
                            ? t("title.resumeText", { ns: "workspace" })
                            : t("title.suspendText", { ns: "workspace" }),
                        title: docSelected.Suspended
                            ? t("title.resumeTitle", { ns: "workspace" })
                            : t("title.suspendTitle", { ns: "workspace" }),
                        onClick: () => {
                            onItemSuspended(docSelected);
                            _deselect();
                        },
                    },
                ],
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.Manage,
                docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "note",
            text: t("title.noteText", { ns: "workspace" }),
            title: t("title.noteTitle", { ns: "workspace" }),
            onClick: () => {
                onItemNotesInvoked(docSelected);
                _deselect();
            },
        },
        {
            key: "preview",
            text: t("title.previewText", { ns: "workspace" }),
            title: t("title.previewTitle", { ns: "workspace" }),
            onClick: () => {
                onItemPreview(docSelected);
                _deselect();
            },
        },
        {
            key: "Remove",
            text: t("title.removeText", { ns: "workspace" }),
            title: t("title.removeTitle", { ns: "workspace" }),
            onClick: () => {
                onItemRemove(docSelected);
                _deselect();
            },
            className:
                docSelected.UserName === Helper.getUsername()
                    ? classNames.hideMenu
                    : classNames.showMenu,
        },
        {
            key: "Renewals",
            text: t("title.renewalsRecord", { ns: "renewalsalerts" }),
            title: t("title.renewalsRecord", { ns: "renewalsalerts" }),
            onClick: () => {
                setCurrentRenewalItem({
                    Name: docSelected.Description,
                    TemplateId: docSelected.TemplateId,
                    Id: 0,
                    RecordId: docSelected.Id
                })
                setShowRenewals(true)
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.WebformLink,
                docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "sendMail",
            text: t("title.sendMailText", { ns: "workspace" }),
            title: t("title.sendMailTitle", { ns: "workspace" }),
            onClick: () => {
                onItemSend(docSelected);
                _deselect();
            },
            className: getAllowSendMail(docSelected)
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "sendLink",
            text: t("title.sendLinkText", { ns: "workspace" }),
            title: t("title.sendLinkTitle", { ns: "workspace" }),
            onClick: () => {
                toggleFormLink(docSelected);
                _deselect();
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.WebformLink,
                docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "shareDoc",
            text: t("title.shareText", { ns: "workspace" }),
            title: t("title.shareTitle", { ns: "workspace" }),
            onClick: () => {
                onItemShare(docSelected);
                _deselect();
            },
            disabled:
                docSelected.Suspended || docSelected.Closed ? true : false,
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "signDocument",
            text: t("title.signDocumentText", { ns: "workspace" }),
            title: t("title.signDocumentTitle", { ns: "workspace" }),
            onClick: () => {
                onItemSignInvoked(docSelected);
                _deselect();
            },
            disabled:
                docSelected.Suspended || docSelected.Closed ? true : false,
            className:
                allowAdminAccess() && docSelected.AllowESign
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "edit",
            text: t("title.editText", { ns: "workspace" }),
            title:
                !docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true"
                    ? docSelected.SubscriptionStatusMessage
                    : t("title.editTitle", { ns: "workspace" }),
            onClick: () => {
                onItemInvoked(docSelected);
                _deselect();
            },
            disabled:
                (!docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true") ||
                isOptionDisabled(
                    CheckoutOptionsVisibilityType.UpdateWebForm,
                    docSelected
                ),
            className: allowUpdate()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
    ];
    const menuPropsMobile: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItemsMobile.filter((f) => f.key != "download"),
    };
    const menuPropsMobileClosed: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItemsMobile.filter(
            (f) =>
                f.key != "download" &&
                f.key != "signDocument" &&
                f.key != "checkout" &&
                f.key != "edit"
        ), //menuItems.filter(f => f.key!= "checkout" &&  f.key!= "Sign" &&  f.key!= "Update")
    };

    const onCreateUpdate = (id: string) => {
        setModalId(parseInt(id));
        setLayoutLoaded(false);
        setShowPreview(!supressPreview);
        setModalTitle(t("title.modal", { ns: "preview" }));
        setSpinnerText(t("text.spinner", { ns: "common" }));

        const preview = async () => {
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id,
                IsPreview: true,
                IsDownload: false,
                IsDocx: false,
            };
            if (!supressPreview) {
                Helper.getDocumentPreview(req).then(res => {
                    if (res.Success) {
                        setLayoutLoaded(true);
                        setPreviewURL(res.UrlPath);
                        setPreviewMime(res.mimeType);
                    }
                })
            }
        };

        _update();
        preview();
        if (selectionState.getSelectedIndices().length >= 1)
            selectionState.setAllSelected(false);
    };
    const getErrorMessage = (value: string): string => {
        if (value.length > 0) {
            setRequiredNote(true);
        } else {
            setRequiredNote(false);
            return t("text.noteRequired", { ns: "recordmanage" });
        }

        return "";
    };

    const getCloneErrorMessage = (value: string): string => {
        if (value.trim().length > 0) {
            setRequiredShortcut(true);
        } else {
            setRequiredShortcut(false);
            return t("text.required", { ns: "recordcopy" });
        }

        return "";
    };
    async function closeDocument(isClosing: boolean) {
        const request = {
            DocumentId: documentId.toString(),
            Close: isClosing,
            Suspend: false,
            Note: note,
        };

        let {data}: any = await HttpRequest.postWithSession('DocumentOnlyClose', request)
        const response = data

        if (response.Success) {
            _update();
            hideAddNoteModal();
        } else {
            console.log(response.ExceptionMessage);
        }
    }
    async function suspendDocument(isSuspending: boolean) {
        const request = {
            DocumentId: documentId.toString(),
            Close: false,
            Suspend: isSuspending,
            Note: note,
        };

        let {data}: any = await HttpRequest.postWithSession('DocumentOnlySuspend', request)
        const response = data

        if (response.Success) {
            _update();
            hideAddNoteModal();
        } else {
            console.log(response.ExceptionMessage);
        }
    }
    const sendAction = () => {
        switch (modalId) {
            case 1:
                {
                    closeDocument(true);
                }
                break;
            case 2:
                {
                    closeDocument(false);
                }
                break;
            case 3:
                {
                    suspendDocument(true);
                }
                break;
            case 4:
                {
                    suspendDocument(false);
                }
                break;
        }
        hideAddNoteModal();
    };

    async function cloneDocument() {
        setRequiredShortcut(false);
        setClonning(true);

        var formData: IFormControl[] = [];
        // formData = JSON.parse(sessionStorage.getItem("formControls")!)

        formData.push({
            Key: "_shortcutReference",
            Value: cloneShortcut,
        });

        // var lists:IListRow[]=[];

        // formData.forEach(f => {
        //     if(f.Key.includes("List"))
        //     {
        //         lists.push(JSON.parse(f.Value))
        //     }
        // });

        const request = {
            TemplateId: templateId.toString(),
            Content: formData,
            Lists: "",
            TargetCulture: "en-US",
            DocumentId: documentId.toString(),
            TemplateVersion: templateVersion,
            TemplateXML: "",
            onBehalf: "",
            DocumentonlyPrimaryId: "-1",
        };

        let {data}: any = await HttpRequest.postWithSession('CloneDocument', request)
        const result = data

        if (result.Success) {
            onCreateUpdate(result.Id);
            setClonning(false);
            hideModalClone();
        }
    }
    function DownloadDoc() {
        onItemDownload(docSelected!, downloadDocX);
    }

    const hideFormLink = () => {
        setshowFormLink(false);
        setDocumentId(-1);
        setModalTitle("");
        setTemplateName("");
        setEmailMoniker("");
    };
    const toggleFormLink = (item: IDocument) => {
        var emailMoniker = "";
        var allowDocx = false;
        var sendMail = false;
        var documentShare = false;
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            Released: false,
            emailMoniker: "",
            CultureName: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        if (item.TemplatePropertyBag != null) {
            var propBag = atob(item.TemplatePropertyBag);
            try {
                jsonBag = JSON.parse(propBag);
                emailMoniker = jsonBag.emailMoniker;
                allowDocx = jsonBag.AllowDocX;
                sendMail = jsonBag.AllowSendMail;
                documentShare = jsonBag.DocumentShare;
            } catch (error) { }
        }
        setTemplateId(item.TemplateId);
        setDocumentId(item.Id);
        setPropertyBag(jsonBag);
        setModalTitle("FormLink" + item.Id);
        setTemplateName(item.Description);
        setEmailMoniker(emailMoniker);
        setshowFormLink(true);
        setAllowDocx(allowDocx);
        setOwnerUser(item!.UserName!);
        setTemplateCategory(item!.Category!);
        setDocumentShare(documentShare);
        setDocumentDescription(`${item.Description}`);
        setTemplateVersion(item.TemplateVersion);
    };

    const RefreshPage = () => {
        window.location.reload();
    };

    const GetDataDocument = async (item: IDocument) => {
        const req: IDocumentRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            DocumentId: "-1",
            Id: item.Id.toString(),
            IsPreview: false,
            IsDownload: false,
            IsDocx: false,
        };
        var res = await Helper.getDocumentDownload(req);

        if (res.Success === undefined || !res.Success) {
            if (item.Versions < res.Versions) {
                setVersion(res.Versions);
                _update();
                var itemDocument: IDocument = {
                    Id: item.Id,
                    AccountId: item.AccountId,
                    TemplateId: item.TemplateId,
                    UserName: item.UserName,
                    Description: item.Description,
                    Data: item.Data,
                    DateCreated: item.DateCreated,
                    DateUpdated: item.DateUpdated,
                    Name: item.Name,
                    MimeType: item.MimeType,
                    Culture: item.Culture,
                    IconName: item.IconName,
                    Versions: res.Versions,
                    IsCustom: item.IsCustom,
                    LastActivity: item.LastActivity,
                    Closed: item.Closed,
                    Suspended: item.Suspended,
                    LastNote: item.LastNote,
                    Category: item.Category,
                    AllowCheckInOut: item.AllowCheckInOut,
                    AllowESign: item.AllowESign,
                    AllowDocumentUpdate: item.AllowDocumentUpdate,
                    AllowNotification: item.AllowNotification,
                    CheckedOut: item.CheckedOut,
                    CheckedOutUser: item.CheckedOutUser,
                    CheckedOutDateTime: item.CheckedOutDateTime,
                    TemplatePropertyBag: item.TemplatePropertyBag,
                    Owner: item.Owner,
                    CoOwner: item.CoOwner,
                    Tickler: item.Tickler,
                    Attachments: item.Attachments,
                    AudienceName: item.AudienceName,
                    TemplateVersion: item.TemplateVersion,
                    FullName: item.FullName,
                    OnBehalfUserFullName: item.OnBehalfUserFullName,
                    OnBehalfUser: item.OnBehalfUser,
                    PrimaryId: item.PrimaryId,
                    SubRecords: item.SubRecords,
                    Executed: item.Executed,
                    HasSubRecord: item.HasSubRecord,
                    CampaignId: item.CampaignId,
                    ActiveSubscription: item.ActiveSubscription,
                    SubscriptionStatusMessage: item.SubscriptionStatusMessage,
                };

                //props.onDocumentSelected(itemDocument)
                return itemDocument;
            }
        }
        return item;
    };
    const fetchMoreData = () => {
        var page = 0;
        isAdvanceSearch =
            sessionStorage.getItem("tempAdvanceSearch") != "" &&
                sessionStorage.getItem("tempAdvanceSearch") != null
                ? true
                : false;

        //     if(props.isloadComplet && sessionStorage.getItem("updateApp")!=="true"){
        //       if((sessionStorage.getItem("pageCount")!)!==undefined && (sessionStorage.getItem("pageCount")!)!==null){
        //           page=parseInt(sessionStorage.getItem("pageCount")!)
        //       }
        //       page=page+1
        //       sessionStorage.setItem("pageCount", page.toString())

        //             //props.updateDocumentList(Helper.getSessionKey(), Helper.getDefaultAccount(), Helper.getUsername())

        //    }
    };

    const _onToggleCollapse = (props: IGroupDividerProps) => {
        return () => {
            props!.onToggleCollapse!(props!.group!);
        };
    };
    function buidlGroups(items: any) {
        var group: IGroup[] = [];
        items.forEach((e: any, indx: any) => {
            var subGroup: IGroup[] = [];
            // console.log(e.SubRecords)
            // if(e.SubRecords.length>0)
            // {
            //     //console.log("pase")
            //     e.SubRecords.forEach((eG:any,indxG:any) => {
            //         subGroup.push(
            //             {key:eG.Id,
            //             name:eG.Name,
            //             startIndex:indxG,
            //             count:1
            //             }
            //         )
            //     })

            // }
            // console.log(subGroup)
            group.push({
                key: e.Id,
                name: e.Name,
                startIndex: indx,
                count: e.SubRecords.length > 0 ? e.SubRecords.length : 1,
            });
        });

        setGroups(group);
        //console.log(group)
        //return group;
    }
    function buidlGroup(item: any) {
        var group: IGroup = {
            key: item.Id,
            name: item.Name,
            startIndex: 0,
            count: 1,
        };
        //console.log(group)
        return group;
    }
    function buildItems(items: any) {
        var subItems: any[] = [];
        items.forEach((e: any, indx: any) => {
            if (e.SubRecords.length > 0) {
                e.SubRecords.forEach((eG: any, indxG: any) => {
                    subItems.push(eG);
                });
            }
        });

        return subItems;
    }

    const onRenderCell = (
        nestingDepth?: number,
        item?: IDocument,
        itemIndex?: number
    ): React.ReactNode => {
        //  console.log(nestingDepth)
        //console.log(groups)
        //console.log(groups)
        var groupItem = groups.filter(
            (x) => x.key == item?.PrimaryId.toString()
        );
        // console.log(item)
        // console.log(groupItem)
        var indx = 0;
        if (groupItem !== null && groupItem.length > 0) {
            indx = groups.indexOf(groupItem[0]);
            //console.log(itemIndex)
        }
        //console.log(itemIndex)
        return item && typeof itemIndex === "number" && itemIndex > -1 ? (
            <DetailsRow
                columns={columnsGroup}
                groupNestingDepth={nestingDepth}
                item={item}
                itemIndex={itemIndex}
                selection={selectionState}
                selectionMode={SelectionMode.multiple}
                compact={false}
                checkboxVisibility={CheckboxVisibility.hidden}
            //group={buidlGroup(item)}
            />
        ) : null;
    };

    const GetSubrecords = async () => {
        setIsLoading(true);

        const request = {
            DocumentOnlyPrimaryId: props.documentId
        }

        let {data}: any = await HttpRequest.getWithSession('GetSubRecords', request)
        const result = data
                

        setSubrecords(result);
        setTotalRecords(result.length);
        setIsLoading(false);
    };

    return (
        <>
            {showCustomForm && (
                <CustomForm
                    showCustomForm={showCustomForm}
                    toggleCustomForm={toggleCustomForm}
                    siteOptions={siteOptions}
                    siteSelected={siteSelected}
                    accountId={Helper.getDefaultAccount()}
                    username={Helper.getUsername()}
                    targetUser={Helper.getUsername()}
                    sessionKey={Helper.getSessionKey()}
                    templateId={templateId.toString()}
                    templateName={templateName}
                    templateDescription={templateDescription}
                    data={data}
                    documentId={documentId.toString()}
                    update={onCreateUpdate}
                    culture={culture}
                    templateVersion={templateVersion}
                    isCreator={isCreator}
                    isOwner={isOwner}
                    isCoOwner={iscoOwner}
                    ownerName={ownerName}
                    ownerUser={ownerUser}
                    templateCategory={templateCategory}
                    audienceName={audienceName}
                    emailMoniker={emailMoniker}
                    documentCreation={documentCreation}
                    documentUpdate={documentUpdate}
                    templateEdit={templateEdit}
                    isSubform={isSubform}
                    documentDescription={documentDescription}
                    setDocumentDescription={setDocumentDescription}
                    audienceMasterId={audienceMasterId}
                    toggleQueueModal={toggleQueueModal}
                />
            )}
            <PreviewScreen
                showModal={showPreview}
                hideModal={hidePreview}
                modalTitle={modalTitle}
                documentDescription={documentDescription}
                documentId={documentId}
                allowDocX={allowDocx}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                URLPath={previewURL}
                mimeType={previewMime}
            />
            <QueueModal Modal={QueueCustomModal.Modal}  close={QueueCustomModal.close} isUpdate={isRecordUpdate} isLink={false} isError={isRecordError}/>
            <Confirmation
                showModal={showConfirm}
                hideModal={hideConfirm}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                id={deleteId}
                message={deleteMessage}
                confirmDelete={confirmDelete}
                actionText={actionText}
            />
            <EmailWidget
                showModal={showEmail}
                hideModal={hideEmail}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                documentDescription={documentDescription}
                setSpinnerText={setSpinnerText}
                setLayoutLoaded={setLayoutLoaded}
                username={username}
                id={documentId}
                templateId={templateId}
                emailMoniker={emailMoniker}
                allowDocx={allowDocx}
            />
            <VersionScreen
                showModal={showVersion}
                hideModal={hideVersion}
                modalTitle={modalTitle}
                allowDocX={allowDocx}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                documentDescription={documentDescription}
                versionList={versionList}
                update={_update}
                version={version}
            />
            <Form
                modalId={modalId}
                modalTitle={modalTitle}
                showModal={showModal}
                hideModal={hideModal}
                layout={layout}
                layoutLoaded={layoutLoaded}
                update={_update}
                spinnerText={spinnerText}
                setSpinnerText={setSpinnerText}
                setLayoutLoaded={setLayoutLoaded}
                documentId={documentId}
            />
            <ShareDocument
                showModal={showShare}
                showShare={showShare}
                hideModal={hideShare}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                TemplateId={templateId}
                documentId={documentId}
                documentDescription={documentDescription}
                siteSelected={Helper.getSiteSelected()}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                propertyBag={propertyBag}
                ownerName={ownerName}
                ownerUser={ownerUser}
                templateCategory={templateCategory}
                templateName={templateName}
                audienceName={audienceName}
                emailMoniker={emailMoniker}
                documentShare={documentShare}
            />
            <Attachment
                showAttachment={showAttachment}
                hideAttachament={hideAttachment}
                documentId={documentId}
                docSource={"normal"}
                username={Helper.getUsername()}
                documentDescription={documentDescription}
                update={_update}
                templateId={templateId}
                activeSubscription={activeSubscription}
                subscriptionStatusMessage={""}
                showRules={false}
                showAttachments={true}
                targetUser={Helper.getUsername()}
            />
            <RenewalsAndAlertsEdit
                showModal={showRenewals}
                onDismissModal={onBeforeDismiss}
                item={currentRenewalItem}
                lockAtStartup={false}
            />
            <Executed
                showExecuted={showExecuted}
                hideExecuted={hideExecuted}
                spinnerText={spinnerText}
                modalTitle={modalTitle}
                documentDescription={documentDescription}
                documentId={documentId}
                documentName={documentName}
                siteSelected={Helper.getSiteSelected()}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
            />
            <UploadDocument
                modalTitle={modalTitle}
                showUploadDoc={showUploadDoc}
                siteSelected={siteSelected}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                documentId={docSelected.Id}
                templateId={docSelected.TemplateId}
                sessionKey={Helper.getSessionKey()}
                setToggleUploadDoc={setToggleUploadDoc}
                checkIncheckOut={checkIncheckOut}
                update={_update}
            />
            <ActivityStatus
                showActivity={showActivity}
                documentDescription={documentDescription}
                hideModalActivity={hideModalActivity}
                documentId={documentId}
                modalTitle={modalTitle}
            />
            <Notes
                showNotes={showNotes}
                hideModalNotes={hideModalNotes}
                documentId={documentId}
                documentDescription={documentDescription}
                modalTitle={modalTitle}
                siteSelected={Helper.getSiteSelected()}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                update={_update}
                owner={owner}
                coowner={coowner}
                user={username}
            />
            <Signers
                showSigners={showSigners}
                hideModalSigners={hideModalSigners}
                documentId={documentId}
                modalTitle={modalTitle}
                documentDescription={documentDescription}
                siteSelected={Helper.getSiteSelected()}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                update={_update}
                templateId={templateId}
                documentName={documentName}
                version={documentVersion}
            />
            <Confirmation
                showModal={showRemove}
                hideModal={hideRemove}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                id={modalId}
                message={removeeMessage}
                confirmDelete={confirmRemove}
                actionText={actionText}
            />


            <FormDocumentLink
                showModal={showFormLink}
                modalTitle={templateName}
                documentId={documentId.toString()}
                onDismissModal={hideFormLink}
                allowDocx={allowDocx}
                templateId={templateId.toString()}
                templateVersion={templateVersion}
                ownerName={ownerName}
                ownerUser={ownerUser}
                documentDescription={documentDescription}
                isSubform={false}
            />
            
            <Modal isOpen={props.showModal} isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div
                            style={{ padding: "0px" }}
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                        >
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={props.modalTitle}>
                                        {props.modalTitle}
                                    </span>
                                    <IconButton
                                        styles={iconButtonStylesPrompt}
                                        iconProps={cancelIcon}
                                        ariaLabel={t("Common.Button.Close", {
                                            ns: "common",
                                        })}
                                        onClick={hideModalSubform}
                                        title={t("Common.Button.Close", {
                                            ns: "common",
                                        })}
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {showErrorMessage ? (
                            <MessageBar styles={messageBarFailedStyles}>
                                {message}
                            </MessageBar>
                        ) : null}
                        {showSuccessMessage ? (
                            <MessageBar styles={messageBarSuccessStyles}>
                                {message}
                            </MessageBar>
                        ) : null}
                    </div>
                    <div
                        className="ms-Grid-row"
                        style={{ paddingRight: "20px" }}
                    >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className="ms-Grid-col ms-sm4 ms-md6 ms-lg8">
                                <div
                                    className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown"
                                    style={{ backgroundColor: "#fff" }}
                                >
                                    <Icon
                                        iconName="ExploreData"
                                        style={{
                                            position: "absolute",
                                            top: "5px",
                                        }}
                                        styles={iconStyles}
                                    />
                                    <span
                                        title={t("title.recordsAvaliable", {
                                            ns: "workspace",
                                        })}
                                        style={{
                                            backgroundColor: "#fff",
                                            fontSize: "18px",
                                            fontFamily:
                                                '"Segoe UI", "Segoe UI Web(West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                                        }}
                                    >
                                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                        {totalRecords +
                                            " " +
                                            t("DashboardComponent.Records", {
                                                ns: "workspace",
                                            })}{" "}
                                        {/*(<Link href="" style={{fontSize:'14px'}}  onClick={props.update}>Get Updates</Link>)*/}
                                    </span>
                                    <div ref={targetButtonG}></div>
                                </div>
                                <div
                                    className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp"
                                >
                                    <Icon
                                        iconName="ExploreData"
                                        style={{
                                            position: "absolute",
                                            top: "5px",
                                        }}
                                        styles={iconStyles}
                                    />
                                    <Text variant={"large"}
                                        title={t("title.recordsAvaliable", {
                                            ns: "workspace",
                                        })}
                                        style={{
                                            color: branding.theme.palette.black
                                        }}
                                    >
                                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                        {totalRecords}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.listDialog}>
                                <div className="ms-Grid-row">
                                    {/* <InfiniteScroll
                                                dataLength={props.documentList.length}
                                                next={fetchMoreData}
                                                hasMore={props.isloadComplet}
                                                loader={props.documentList.length>0  && !isAdvanceSearch && !props.isloadComplet?<Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" />:null}
                                                scrollableTarget="scrollableDivGeneral"
                                                height="91vh"
                                            > */}
                                    {!isLoading ? (
                                        <ScrollablePane
                                            styles={scrollablePaneStyles}
                                            id="ScrollPaneUserlist"
                                            key="ScrollPaneUserlist"
                                        >
                                            {/* <div className="scrollVisible" style={{overflow:"auto", overflowX:"hidden" , maxWidth:"98%", height:"72vh"}}> */}
                                            <DetailsList
                                                items={subrecords || []}
                                                compact={false}
                                                columns={columns}
                                                getKey={getKey}
                                                setKey="setSub"
                                                layoutMode={
                                                    DetailsListLayoutMode.justified
                                                }
                                                isHeaderVisible={true}
                                                selection={selectionState}
                                                selectionMode={
                                                    SelectionMode.single
                                                }
                                                selectionPreservedOnEmptyClick={
                                                    false
                                                }
                                                //enterModalSelectionOnTouch={mQuery.matches}
                                                checkboxVisibility={
                                                    CheckboxVisibility.hidden
                                                }
                                                onRenderDetailsHeader={
                                                    onRenderDetailsHeader
                                                }
                                                onRowDidMount={() => {
                                                    let element =
                                                        ReactDOM.findDOMNode(
                                                            document.getElementById(
                                                                "ScrollPaneUserlist"
                                                            )
                                                        ) as Element;
                                                    if (element !== null) {
                                                        element.children[1].classList.add(
                                                            "scrollVisible"
                                                        );
                                                    }
                                                }}
                                            />
                                            {/* </div> */}
                                        </ScrollablePane>
                                    ) : (
                                        <Stack>
                                            <Label />
                                            <Label />
                                            <Label />
                                            <Spinner
                                                size={SpinnerSize.medium}
                                                label={t("text.spinner", {
                                                    ns: "common",
                                                })}
                                                ariaLive="assertive"
                                            />
                                        </Stack>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <DefaultButton
                                    onClick={hideModalSubform}
                                    text={t("Common.Button.Close", {
                                        ns: "common",
                                    })}
                                    title={t("Common.Button.Close", {
                                        ns: "common",
                                    })}
                                />
                            </DialogFooter>
                        </div>
                    </div>
                    <br />
                </div>
            </Modal>
            <Modal isOpen={showAddNote} isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div
                                style={{ padding: "0px" }}
                                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                            >
                                <div className={classNames.header}>
                                    <span id="actionTitle">{actionTitle}</span>
                                    <IconButton
                                        styles={iconButtonNoteModalStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel={t("Common.Button.Close", {
                                            ns: "common",
                                        })}
                                        onClick={hideAddNoteModal}
                                        title={t("Common.Button.Close", {
                                            ns: "common",
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.bodyNote}>
                                    <TextField
                                        label={t("label.note", {
                                            ns: "recordmanage",
                                        })}
                                        onGetErrorMessage={getErrorMessage}
                                        maxLength={1000}
                                        id="noteDescription"
                                        title={t("title.note", {
                                            ns: "recordmanage",
                                        })}
                                        placeholder={t("placeholder.note", {
                                            ns: "recordmanage",
                                        })}
                                        required
                                        onChange={(_, v) => {
                                            setNote(v!);
                                        }}
                                        value={note}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={sendAction}
                                        disabled={!requiredNote}
                                        text={actionTitleButton}
                                        title={t("title.close", {
                                            ns: "recordmanage",
                                            Name: actionTitle,
                                        })}
                                    />
                                    <DefaultButton
                                        onClick={hideAddNoteModal}
                                        text={t("Common.Button.Cancel", {
                                            ns: "common",
                                        })}
                                        title={t("Common.Button.Cancel", {
                                            ns: "common",
                                        })}
                                    />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>
            <Modal isOpen={showModalClone} isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div
                            style={{ padding: "0px" }}
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                        >
                            <div className={contentStyles.header}>
                                <span id={"confirmClone"}>
                                    {t("text.copy", { ns: "recordcopy" })}{" "}
                                    {isMobile ? null : "(" + shortcut + ")"}
                                </span>
                                <IconButton
                                    styles={iconButtonNoteModalStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel={t("Common.Button.Close", {
                                        ns: "common",
                                    })}
                                    onClick={hideModalClone}
                                    title={t("Common.Button.Close", {
                                        ns: "common",
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                {!clonning ? (
                                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                                        <TextField
                                            label={t("label.descriptionInput", {
                                                ns: "recordcopy",
                                            })}
                                            autoFocus
                                            required
                                            maxLength={40}
                                            onGetErrorMessage={
                                                getCloneErrorMessage
                                            }
                                            title={t("title.descriptionInput", {
                                                ns: "recordcopy",
                                            })}
                                            onChange={(_, v) => {
                                                setCloneShortcut(v!);
                                            }}
                                            placeholder={t(
                                                "placeholder.descriptionInput",
                                                { ns: "recordcopy" }
                                            )}
                                        />
                                    </div>
                                ) : (
                                    <Stack tokens={stackTokens}>
                                        <label />
                                        <label />
                                        <label />
                                        <Spinner
                                            size={SpinnerSize.large}
                                            label={t("text.spinnerCopying", {
                                                ns: "common",
                                            })}
                                        />
                                    </Stack>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div
                            style={{
                                paddingBottom: "10px",
                                paddingRight: "12px",
                            }}
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                        >
                            <DialogFooter>
                                <PrimaryButton
                                    onClick={cloneDocument}
                                    text={t("Common.Button.Confirm", {
                                        ns: "common",
                                    })}
                                    disabled={!requiredShortcut}
                                    title={t("title.confirmButton", {
                                        ns: "recordcopy",
                                    })}
                                />
                                <DefaultButton
                                    onClick={hideModalClone}
                                    text={t("Common.Button.Cancel", {
                                        ns: "common",
                                    })}
                                    title={t("Common.Button.Cancel", {
                                        ns: "common",
                                    })}
                                />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={showConfirmationModal}
                isBlocking={true}
                containerClassName={contentStyles.containerSaveModal}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div
                            style={{ padding: "0px" }}
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                        >
                            <div className={contentStyles.header}>
                                <span id="Confirmation">
                                    {t("title.modalDownload", {
                                        ns: "checkinout",
                                    })}
                                </span>
                                <IconButton
                                    styles={iconButtonStylesPrompt}
                                    iconProps={cancelIcon}
                                    ariaLabel={t("Common.Button.Close", {
                                        ns: "common",
                                    })}
                                    onClick={hideConfirmationModal}
                                    title={t("Common.Button.Close", {
                                        ns: "common",
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg12">
                            <div
                                id="messageDownloadDocument"
                                className={contentStyles.body}
                            >
                                {t("text.download", { ns: "checkinout" })}
                            </div>
                        </div>
                    </div>
                    <div
                        className="ms-Grid-row"
                        style={{
                            bottom: "10px",
                            right: "12px",
                            position: "absolute",
                        }}
                    >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton
                                    onClick={DownloadDoc}
                                    text={t("Common.Button.Yes", {
                                        ns: "common",
                                    })}
                                    title={t("title.btnYes", {
                                        ns: "checkinout",
                                    })}
                                />
                                <DefaultButton
                                    onClick={hideConfirmationModal}
                                    text={t("Common.Button.No", {
                                        ns: "common",
                                    })}
                                    title={t("Common.Button.No", {
                                        ns: "common",
                                    })}
                                />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default SubForms;
