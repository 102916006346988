import { ConstrainMode, DetailsList, DetailsListLayoutMode, IconButton, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDragDropEvents, IDropdownOption, IRenderFunction, ScrollablePane, ScrollbarVisibility, SelectionMode, Sticky, StickyPositionType, TooltipHost } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RHFDropDownListSingleSelectControl } from "../../../RHFControls/RHFDropDownListSingleSelectControl";
import { RHFNumberControl } from "../../../RHFControls/RHFNumberControl";
import { RHFTextBoxControl } from "../../../RHFControls/RHFTextBoxControl";
import { Const_MapProps } from "../../Models/Consts";
import { IdentifierKeyType, MapOutputSurrogateField, MapOutputValueType } from "../../Models/Enums";
import WizardService from "../../../../services/wizard";
import { ILayout } from "../../../CustomTemplates/CustomTemplate";
import Helper from "../../../../Helpers/Helper";
import { EventType } from "../../Models/EventModel";
import { RHFCheckBoxControl } from "../../../RHFControls/RHFChekBoxControl";

export interface MapOutputMapPropsListProps {
    templateId: number;
    eventType: string;
    isConfigurationTemplateReadMode?: boolean;
}

type SelectedIdentifierKeyName = {
    IdentifierKeyName1: boolean,
    IdentifierKeyName2: boolean,
    IdentifierKeyName3: boolean
}
const defualtValue: SelectedIdentifierKeyName = {
    IdentifierKeyName1: false,
    IdentifierKeyName2: false,
    IdentifierKeyName3: false
}

export const MapOutputMapPropsList = (props: MapOutputMapPropsListProps) => {

    const { control, setValue, getValues, trigger } = useFormContext();
    const [targetFieldList, setTargetFieldList] = useState<any>([]);
    const [updateIdentifierKeyNameColumn, setUpdateIdentifierKeyNameColumn] = useState<boolean>(false)
    const [selectedIdentifierKeyName, setSelectedIdentifierKeyName] = useState<SelectedIdentifierKeyName>(defualtValue)
    const mapOutputMapPropsList = useFieldArray({ control, name: "mapOutputMapPropsList" });

    useEffect(() => {
        setTargetFieldList((targetFieldList: any) => {
            return targetFieldList.map((item: any) => {
                if (item.text === IdentifierKeyType.Identifier1) {
                    return { ...item, disabled: selectedIdentifierKeyName.IdentifierKeyName1 }
                }
                return item
            })
        })

    }, [selectedIdentifierKeyName])

    useEffect(() => {
        loadTemplate();
    }, []);

    async function loadTemplate() {
        const { data }: any = await WizardService.getStatemanagement({
            templateId: props.templateId.toString(),
            Path: Helper.getSiteSelected(),
        });

        if (
            data.Layout === undefined ||
            data.Layout === null ||
            data.Layout === ""
        ) {
            console.log(
                `Critical Error - Template Corrupted. Please Re-upload your original template.`
            );
            return;
        }
        var loadedLayout = JSON.parse(data.Layout) as ILayout[];
        loadTargetFieldList(loadedLayout);
    }

    const getDragDropEvents = () => {
        return {
            canDrop: (dropContext: any, dragContext?: any) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                if (draggedItem) {
                    insertBeforeItem(item);
                }
            },
            onDragStart: (
                item?: any,
                itemIndex?: number,
                selectedItems?: any[],
                event?: MouseEvent
            ) => {
                if (typeof item.startIndex === "undefined") {
                    // skip group item
                    draggedItem = item;
                    draggedIndex = itemIndex!;
                }
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                draggedItem = undefined;
                draggedIndex = -1;
            },
        };
    };

    const dragDropEvents: IDragDropEvents = getDragDropEvents();
    let draggedItem: any | undefined;
    let draggedIndex: number = -1;

    const insertBeforeItem = (item: any): void => {
        mapOutputMapPropsList.move(
            draggedIndex,
            mapOutputMapPropsList.fields.indexOf(item)
        );
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
        props,
        defaultRender
    ) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<
            IDetailsColumnRenderTooltipProps
        > = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />;
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    const loadTargetFieldList = (layout: ILayout[]) => {
        let items: any = []

        items = Object.entries(MapOutputSurrogateField).map((item) => {
            return { text: item[0], key: item[1] as string };
        });

        if (props.eventType.trim() === EventType.OnClick) {
            items = items.concat(
                layout.map((item) => {
                    return { text: item.Label, key: item.Id };
                })
            );
        }

        let identifierKeyTypes: { text: string; key: string; disabled: boolean }[] = [];
        Object.entries(IdentifierKeyType).forEach((item) => {
            const listValue = getValues("mapOutputMapPropsList").find((item: any) => item.TargetField.key === IdentifierKeyType.Identifier1)
            if ((item[0] as string) === IdentifierKeyType.Identifier1) {
                identifierKeyTypes.push({ text: item[0], key: item[1] as string, disabled: listValue === undefined ? false : true })
                setSelectedIdentifierKeyName((currentValue: SelectedIdentifierKeyName) => {
                    return { ...currentValue, IdentifierKeyName1: listValue === undefined ? false : true }
                })
            }
        });

        items = items.concat(identifierKeyTypes)

        setTargetFieldList(items);
    };

    const resetIdentifierKeyName = (id: any) => {
        setValue(id, "")
    }

    const hasIdentifierSelected = (index: number, newValue?: string): boolean => {
        let rows = getValues("mapOutputMapPropsList")

        let isValidTheOneAlreadyExisted = false;

        rows.forEach((element: any) => {
            if ((element.TargetField.key === IdentifierKeyType.Identifier1 ||
                element.TargetField.key === IdentifierKeyType.Identifier2 ||
                element.TargetField.key === IdentifierKeyType.Identifier3) &&
                element.IdentifierKeyName.length > 0) {
                isValidTheOneAlreadyExisted = true;
            }
        });

        if (isValidTheOneAlreadyExisted) {
            return isValidTheOneAlreadyExisted;
        } else {
            if ((rows[index].TargetField.key === IdentifierKeyType.Identifier1 ||
                rows[index].TargetField.key === IdentifierKeyType.Identifier2 ||
                rows[index].TargetField.key === IdentifierKeyType.Identifier3)) {

                const identifierKeyName = newValue !== undefined ? newValue : rows[index].IdentifierKeyName
                if (identifierKeyName.length > 0) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        }
    }

    return (
        <div
            style={{
                height: "300px",
                position: "relative",
                backgroundColor: "white",
            }}
        >
            <ScrollablePane styles={{
                root: {
                    selectors: {
                        ".ms-ScrollablePane--contentContainer": {
                            scrollbarWidth: 6,
                            scrollbarColor: "gray lightgray"
                        },
                        ".ms-ScrollablePane--contentContainer::-webkit-scrollbar": {
                            width: 6,
                            height: 6
                        },
                        ".ms-ScrollablePane--contentContainer::-webkit-scrollbar-track": {
                            background: "lightgray"
                        },
                        ".ms-ScrollablePane--contentContainer::-webkit-scrollbar-thumb": {
                            background: "gray"
                        }
                    }
                }
            }} scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.unconstrained}
                    compact={true}
                    items={mapOutputMapPropsList.fields.map((item) => item)}
                    columns={[
                        {
                            key: "Path",
                            name: "Path",
                            fieldName: "Path",
                            minWidth: 100,
                            maxWidth: 200,
                            isResizable: true,
                            onRender: (item, index, column) => {
                                return (
                                    <RHFTextBoxControl
                                        key={item.id}
                                        id={`mapOutputMapPropsList.${index!}.Path`}
                                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                                        title="Enter path."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        defaultValue={""}
                                        onCallback={(event: React.FormEvent<| HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                            if (index === mapOutputMapPropsList.fields.length - 1 &&
                                                newValue.length > 0 &&
                                                getValues("mapOutputMapPropsList")[index].ValueType.key !== "" && getValues("mapOutputMapPropsList")[index].ValueType.key !== undefined &&
                                                getValues("mapOutputMapPropsList")[index].Index.toString().length > 0 &&
                                                getValues("mapOutputMapPropsList")[index].TargetField.key !== "" && getValues("mapOutputMapPropsList")[index].TargetField.key !== undefined &&
                                                hasIdentifierSelected(index)) {
                                                mapOutputMapPropsList.append(Const_MapProps);
                                            }
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            key: "ValueType",
                            name: "Value Type",
                            fieldName: "ValueType",
                            minWidth: 50,
                            maxWidth: 100,
                            isResizable: true,
                            onRender: (item, index, column) => {
                                return (
                                    <RHFDropDownListSingleSelectControl
                                        id={`mapOutputMapPropsList.${index}.ValueType`}
                                        options={Object.entries(MapOutputValueType).map((item) => {
                                            return {
                                                text: item[0],
                                                key: item[1],
                                            };
                                        })}
                                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                                        title="Select param type."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        onCallback={(event: React.FormEvent<HTMLDivElement>, option?: | IDropdownOption | undefined, dropdownIndex?: number | undefined) => {
                                            if (index === mapOutputMapPropsList.fields.length - 1 &&
                                                getValues("mapOutputMapPropsList")[index].Path.length > 0 &&
                                                option !== undefined &&
                                                getValues("mapOutputMapPropsList")[index].Index.toString().length > 0 &&
                                                getValues("mapOutputMapPropsList")[index].TargetField.key !== "" && getValues("mapOutputMapPropsList")[index].TargetField.key !== undefined &&
                                                hasIdentifierSelected(index)) {
                                                mapOutputMapPropsList.append(Const_MapProps);
                                            }
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            key: "Index",
                            name: "Index",
                            fieldName: "Index",
                            minWidth: 25,
                            maxWidth: 50,
                            isResizable: true,
                            onRender: (item, index, column) => {
                                return (
                                    <RHFNumberControl
                                        id={`mapOutputMapPropsList.${index!}.Index`}
                                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                                        title="Enter index."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        defaultValue={""}
                                        onCallback={(event: React.FormEvent<| HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                            if (index === mapOutputMapPropsList.fields.length - 1 &&
                                                getValues("mapOutputMapPropsList")[index].Path.length > 0 &&
                                                getValues("mapOutputMapPropsList")[index].ValueType.key !== "" && getValues("mapOutputMapPropsList")[index].ValueType.key !== undefined &&
                                                newValue.length > 0 &&
                                                getValues("mapOutputMapPropsList")[index].TargetField.key !== "" && getValues("mapOutputMapPropsList")[index].TargetField.key !== undefined &&
                                                hasIdentifierSelected(index)) {
                                                mapOutputMapPropsList.append(Const_MapProps);
                                            }
                                        }}
                                    />
                                );
                            },
                        },
                        // {
                        //     key: 'TargetField',
                        //     name: 'TargetField',
                        //     fieldName: 'TargetField',
                        //     minWidth: 100,
                        //     maxWidth: 200,
                        //     isResizable: false,
                        //     onRender: (item, index, column) => {
                        //         return <RHFTextBoxControl
                        //             key={item.id}
                        //             id={`mapOutputMapPropsList.${index!}.TargetField`}
                        //             disabled={false}
                        //             title="Enter target field."
                        //             control={control}
                        //             setValue={setValue}
                        //             getValues={getValues}
                        //             trigger={trigger}
                        //             defaultValue={""}
                        //             onCallback={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                        //                 if (index === mapOutputMapPropsList.fields.length - 1 && getValues("mapOutputMapPropsList")[index].Path.length > 0 && getValues("mapOutputMapPropsList")[index].ValueType.key !== undefined && getValues("mapOutputMapPropsList")[index].Index.toString().length > 0 && newValue.length > 0) {
                        //                     mapOutputMapPropsList.append(Const_MapProps)
                        //                 }
                        //             }}
                        //         />
                        //     }
                        // },
                        {
                            key: "TargetField",
                            name: "TargetField",
                            fieldName: "Target Field",
                            minWidth: 80,
                            maxWidth: 150,
                            isResizable: true,
                            onRender: (item, index, column) => {
                                return (
                                    <RHFDropDownListSingleSelectControl
                                        id={`mapOutputMapPropsList.${index}.TargetField`}
                                        options={targetFieldList}
                                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                                        title="Select target field."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        onCallback={(event: React.FormEvent<HTMLDivElement>, option?: | IDropdownOption | undefined, dropdownIndex?: number | undefined) => {
                                            if (index === mapOutputMapPropsList.fields.length - 1 &&
                                                getValues("mapOutputMapPropsList")[index].Path.length > 0 &&
                                                getValues("mapOutputMapPropsList")[index].ValueType.key !== "" && getValues("mapOutputMapPropsList")[index].ValueType.key !== undefined &&
                                                getValues("mapOutputMapPropsList")[index].Index.toString().length > 0 &&
                                                option !== undefined &&
                                                hasIdentifierSelected(index)) {
                                                mapOutputMapPropsList.append(Const_MapProps);
                                            }

                                            if (option?.key === IdentifierKeyType.Identifier1) {
                                                setSelectedIdentifierKeyName((currentValue: SelectedIdentifierKeyName) => {
                                                    return { ...currentValue, IdentifierKeyName1: !currentValue.IdentifierKeyName1 }
                                                })
                                            } else {
                                                setSelectedIdentifierKeyName((currentValue: SelectedIdentifierKeyName) => {
                                                    return getValues("mapOutputMapPropsList").reduce((prev: SelectedIdentifierKeyName, current: any) => {
                                                        if (current.TargetField.key === IdentifierKeyType.Identifier1) {
                                                            return { ...prev, IdentifierKeyName1: true }
                                                        }
                                                        return prev
                                                    }, defualtValue)




                                                })
                                            }



                                            /*setTargetFieldList((targetFieldList: any) => {
                                                return targetFieldList.map((item: any) => {
                                                    if ( item.text === IdentifierKeyType.IdentifierKeyName1) {
                                                        return { ...item, disabled: selectedIdentifierKeyName.IdentifierKeyName1 }
                                                    }
                                                    return item
                                                })
                                            })*/
                                            resetIdentifierKeyName(`mapOutputMapPropsList.${index}.IdentifierKeyName`)
                                            setUpdateIdentifierKeyNameColumn(!updateIdentifierKeyNameColumn)

                                        }}
                                    />
                                );
                            },
                        },
                        {
                            key: 'IsHeader',
                            name: 'Use Header',
                            fieldName: 'IsHeader',
                            minWidth: 61,
                            maxWidth: 61,
                            isResizable: true,
                            onRender: (item, index, column) => {
                                return <RHFCheckBoxControl
                                    id={`mapOutputMapPropsList.${index!}.IsHeader`}
                                    label={""}
                                    disabled={props.isConfigurationTemplateReadMode ? true : false}
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                />
                            }
                        },
                        // {
                        //     key: 'Output',
                        //     name: 'Output',
                        //     fieldName: 'Output',
                        //     minWidth: 25,
                        //     maxWidth: 50,
                        //     isResizable: false,
                        //     onRender: (item, index, column) => {
                        //         return <RHFCheckBoxControl
                        //             id={`mapOutputMapPropsList.${index!}.Output`}
                        //             label={""}
                        //             control={control}
                        //             setValue={setValue}
                        //             getValues={getValues}
                        //             trigger={trigger}
                        //         />
                        //     }
                        // },
                        {
                            key: 'identifierKeyName',
                            name: 'IdentifierKeyName',
                            fieldName: 'IdentifierKeyName',
                            minWidth: 125, maxWidth: 125,
                            isResizable: true,
                            onRender: (item, index, column) => {
                                const id: any = `mapOutputMapPropsList.${index}.TargetField`
                                if (getValues(id) !== "" && (getValues(id).key === IdentifierKeyType.Identifier1 || getValues(id).key === IdentifierKeyType.Identifier2 || getValues(id).key === IdentifierKeyType.Identifier3)) {
                                    return <RHFTextBoxControl
                                        key={item.id}
                                        id={`mapOutputMapPropsList.${index}.IdentifierKeyName`}
                                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                                        title="Enter key name value."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        defaultValue={""}
                                        onCallback={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                            if (index === mapOutputMapPropsList.fields.length - 1 &&
                                                getValues("mapOutputMapPropsList")[index].Path.length > 0 &&
                                                getValues("mapOutputMapPropsList")[index].ValueType.key !== "" && getValues("mapOutputMapPropsList")[index].ValueType.key !== undefined &&
                                                getValues("mapOutputMapPropsList")[index].Index.toString().length > 0 &&
                                                getValues("mapOutputMapPropsList")[index].TargetField.key !== "" && getValues("mapOutputMapPropsList")[index].TargetField.key !== undefined &&

                                                hasIdentifierSelected(index, newValue)) {
                                                //newValue !== "") {
                                                mapOutputMapPropsList.append(Const_MapProps);
                                            }
                                        }}
                                    />
                                } else {
                                    return null
                                }
                            }
                        },
                        {
                            key: "actions",
                            name: "Actions",
                            onRender: (item?: any, index?: number) => {
                                return (
                                    <IconButton
                                        disabled={props.isConfigurationTemplateReadMode}
                                        onClick={() => {
                                            mapOutputMapPropsList.remove(index!);
                                            if ((index === 0 && mapOutputMapPropsList.fields.length - 1 === 0) ||
                                                index === mapOutputMapPropsList.fields.length - 1) {
                                                mapOutputMapPropsList.append(Const_MapProps);
                                            }
                                        }}
                                        iconProps={{ iconName: "Delete" }}
                                        title="Delete"
                                        ariaLabel="Delete"
                                    />
                                );
                            },
                            minWidth: 60,
                            maxWidth: 60,
                            isResizable: false,
                        },
                    ]}
                    selectionMode={SelectionMode.none}
                    dragDropEvents={dragDropEvents}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                />
            </ScrollablePane>
        </div>
    );
};
