import React, { useEffect, useState } from 'react'
import { IContentLibrary, IContentLibraryLanguage } from '../../../interfaces/IDocumentAssembly'
import DocumentAssemblyService from '../../../services/assembly'
import {
    CheckboxVisibility, DefaultButton, DetailsList, DetailsListLayoutMode, FontIcon, IButtonStyles, IColumn, IContextualMenuItem, IContextualMenuProps,
    IconButton, Label, Spinner, SpinnerSize, Stack, mergeStyleSets
} from '@fluentui/react'
import { useModal } from '../../../hooks/useModal'
import { useDialog } from '../../../hooks/useDialog'
import { useTranslation } from 'react-i18next'
import ContentLibraryForm from './ContentLibraryForm'
import { Sizes } from '../../../share/CustomModal'
import Select, { ActionMeta, SingleValue } from 'react-select'
import RichTextEditor, { EditorValue } from 'react-rte'
import { SelectOptions, filterOptionFromValue } from '../../../Helpers/functions'
import StatusMessage from '../../../share/StatusMessage'
import { IStatusMessage } from '../../../interfaces/IApp'
type ContentLibraryProps = {
    templateId: number
}

type SingleValueType = SingleValue<SelectOptions | null>
type ActionMetaType = ActionMeta<SelectOptions | null>
type OptionKeys = 'expressions' | 'edit' | 'enable' | 'up' | 'down' | 'delete'
const ContentLibrary = ({ templateId }: ContentLibraryProps) => {

    const [contentControls, setContentControls] = useState<SelectOptions[]>([])
    const [contentLibraryList, setContentLibraryList] = useState<IContentLibrary[]>([])
    const [libraryOptions, setLibraryOptions] = useState<IContentLibrary | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState<IStatusMessage | undefined>(undefined)
    const [selectedControl, setSelectedControl] = useState<SingleValueType>(null)
    const deleteDialog = useDialog()
    const libraryForm = useModal()


    const { t } = useTranslation('assembly')
    useEffect(() => {
        let isMounted = true
        if (isLoading) {
            DocumentAssemblyService.getContentLibrary(templateId).then(({ data }: any) => {
                if (isMounted) {
                    if (data.Success === false) {
                        setMessage({ Type: 'error', HasTimeOut: false, Message: <>{data.ExceptionMessage}</> })
                        setContentLibraryList([])
                        setContentControls([])
                        setIsLoading(false)
                        return
                    }
                    setContentLibraryList([...data])
                    setContentControls(() => {
                        return data.map((item: IContentLibrary): SelectOptions => {
                            return { value: item.Id.toString(), label: item.ContentControl }
                        })
                    })
                    setIsLoading(false)
                }
            })
        }
        return () => {
            isMounted = false
        }
    }, [isLoading, templateId])

    const handleSelectChange = (newValue: SingleValueType, actionMeta: ActionMetaType) => {
        if (newValue) {
            const item = contentLibraryList.find((item) => item.Id === Number(newValue.value))
            setSelectedControl(newValue)
            return setLibraryOptions(item ? item : undefined)

        }
        setLibraryOptions(undefined)
    }
    const refreshLibrary = () => {
        setLibraryOptions(undefined)
        setIsLoading(true)
    }
    const handleClose = (callback: () => void, loading: boolean, message?: IStatusMessage) => {
        callback?.()
        if (loading) {
            refreshLibrary()
            setMessage(message)
        }
    }
    const handleDelete = (item?: IContentLibraryLanguage) => {
        if (item?.TemplateKnowledgeControlId) {
            DocumentAssemblyService.deleteContentLibrary(templateId, item?.TemplateKnowledgeControlId, item.Id).then(({ data }: any) => {
                if (data.Success === false) {
                    refreshLibrary()
                    return setMessage({ Type: 'error', Message: <>Error deleting Language for <strong>{selectedControl?.label}</strong>: {data.ExceptionMessage}</> })
                }
                refreshLibrary()
                setMessage({ Type: 'success', Message: <>Content library for <strong>{selectedControl?.label}</strong> deleted successfully</> })
                deleteDialog.close()
            })
        }
    }
    const handleMenu = (option: OptionKeys, item?: IContentLibraryLanguage) => {
        const confirm = () => () => handleDelete(item)
        switch (option) {
            case 'edit':
                libraryForm.open(item)
                break
            case 'delete':
                deleteDialog.open({ confirm })
                break
        }
    }

    const wrappedColumns = () => {
        return getColumns(handleMenu)
    }
    const handleShowMessage = () => {
        setMessage(undefined)
    }
    return (
        <>
            <Stack>
                <label className="ms-fontSize-18">Content Library</label>
                <div>
                    <p>This functionality allows users to store and insert frequently used content into documents.
                        With the ability to add and update tags within the library, users can easily insert content based
                        on rules defined within the Document Assembly module.</p>
                </div>
            </Stack>
            {message ? <StatusMessage setShowMessage={handleShowMessage} status={message.Type} hasTimer={message.HasTimeOut}>
                <>{message.Message}</>
            </StatusMessage> : null}
            {isLoading ? <Spinner size={SpinnerSize.medium} label="Loading..." ariaLive="assertive" /> :
                <Stack>
                    <div className={contentStyles.section}>

                        <Label>Select Content Tag</Label>
                        <Select
                            className="basic-single"
                            id="contentControlLibrary"
                            aria-label="Content Tag Library"
                            options={contentControls}
                            onChange={handleSelectChange}
                            defaultValue={null}
                            placeholder="Select Content Tag..."
                            isClearable
                            isSearchable
                            filterOption={filterOptionFromValue}
                            menuPlacement='auto'
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <FontIcon iconName="ChevronDown" style={{ paddingRight: "7px", fontSize: "12px" }} />
                                ),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: { ...theme.colors, primary25: "#EDEBE9", },
                            })}
                        />
                        {libraryOptions ? <>
                            <h3 className={contentStyles.sectionHeader}>
                                <span></span>
                                <DefaultButton key="openForm"
                                    onClick={() => { libraryForm.open(libraryOptions) }}
                                    title='Add'
                                    text={t('settings.addBtn')} />
                            </h3>
                            <div className="scrollVisible" style={{
                                overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "47vh"
                            }}>
                                <DetailsList items={libraryOptions?.LanguageOptions ?? []} columns={wrappedColumns()} layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible
                                    selectionPreservedOnEmptyClick={false}
                                    enterModalSelectionOnTouch
                                    checkboxVisibility={CheckboxVisibility.hidden} />
                            </div>
                        </> : null
                        }
                    </div>
                </Stack>
            }

            <libraryForm.Modal title={selectedControl === null ? `Content Library` : `Content Library (${selectedControl?.value})`} titleId="" size={Sizes.sm}>
                <ContentLibraryForm contentControl={selectedControl} close={(loading, message) => { handleClose(libraryForm.close, loading, message) }} item={libraryForm.data} type="create" templateId={templateId} />
            </libraryForm.Modal>
            <deleteDialog.Dialog title={'Delete'} titleId='deleteModal' actionText={t('Common.Button.Delete', { ns: 'common' })}>
                <>Are you sure you want to delete the <strong>Language</strong>?</>
            </deleteDialog.Dialog>
        </>
    )
}
const getColumns = (handleMenu: (option: OptionKeys, item?: IContentLibraryLanguage) => void): IColumn[] => {
    return [
        {
            key: 'Language',
            name: 'Content',
            ariaLabel: 'Content',
            fieldName: 'Language',
            minWidth: 200,
            isResizable: true,
            onRender: (item) => {
                const decodedString = new TextDecoder().decode(Uint8Array.from(atob(item.Language), c => c.charCodeAt(0)));
                const richValue = EditorValue.createFromString(decodedString, 'html')
                return (<RichTextEditor readOnly={true} value={richValue} />)
            }
        },
        {
            key: 'action',
            name: 'Actions',
            minWidth: 55,
            maxWidth: 55,
            onRender: (item) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}>
                    <MenuItems item={item} handleMenu={handleMenu} />
                </div>
            }
        },
    ]
}
const iconButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } }
type MenuProps = { item: IContentLibraryLanguage, handleMenu: (option: OptionKeys, item?: IContentLibraryLanguage) => void }
const MenuItems = ({ item, handleMenu }: MenuProps) => {

    const items: IContextualMenuItem[] = [
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit attachment.',
            disabled: item?.LanguageDescription === null,
            onClick: () => {
                handleMenu('edit', item)
            },
        },
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete attachment.',
            onClick: () => {
                handleMenu('delete', item)
            },
        },
    ]
    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: items
    }
    return (
        <>
            <IconButton
                menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424', textAlign: 'right' } }}
                role="button"
                aria-haspopup
                aria-label="Show actions"
                styles={iconButtonStyles}
                menuProps={menuProps}
                title={"Click to select from available actions."}
            />
        </>

    )
}
const contentStyles = mergeStyleSets({
    sectionHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    section: {
        padding: '1rem 0'
    }
})

export default ContentLibrary