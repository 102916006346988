import { Modal, Stack, FontWeights, getTheme, mergeStyleSets, PrimaryButton, IconButton, Pivot, PivotItem, Separator, IDropdownOption, MessageBar, IMessageBarStyles, Link, TeachingBubble, DirectionalHint } from "@fluentui/react";
import React, { MutableRefObject, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Helper from "../../Helpers/Helper";
import { RHFDropDownListSingleSelectControl } from "../RHFControls/RHFDropDownListSingleSelectControl";
import { RHFTextBoxControl } from "../RHFControls/RHFTextBoxControl";
import { Body } from "./Tabs/Body/Body";
import { Test } from "./Tabs/Body/Test";
import HeaderList from "./Tabs/HeaderList";
import { BodyFields, Const_FormDataParam, Const_Header, Const_MapProps, Const_SurrogateFilter, Const_UrlParam } from "./Models/Consts";
import { AuthenticationType, BodyType, IdentifierKeyType, MethodType, OAuth20Type, ParamType, ParamValueType, PivotOutbound, SourceType, TypeType } from "./Models/Enums";
import { AuthenticationProperties, BodyTargetNode, MapInputHeader, MapProps, ParamsBodyParams, ParamsURL_Form_Param, OutboundIntegrationModel, SurrogateFilter, CreateOutboundIntegration, UpdateOutboundIntegration } from "./Models/OutboundIntegrationModel";
import Security from "./Tabs/Security/Security";
import OutboundServices from "./Services/OutboundServices";
import UrlParamsList from "./Tabs/UrlParamsList";
import { MapOutput } from "./Tabs/MapOutput/MapOutput";
import { SurrogateFilters } from "./Tabs/Body/SurrogateFilters";
import ConfirmationModal from "../../Helpers/Modals/ConfirmationModal";
import TestRelease from "./Tabs/TestRelease/TestRelease";
import { decode } from "base64-arraybuffer";
import { EventModel, EventType } from "./Models/EventModel";
import { useBoolean } from "@fluentui/react-hooks";
import { ConfigurationTemplateModel } from "./Models/ConfigurationTemplateModel";
import {useBranding} from "../../hooks/useBranding";
//https://codesandbox.io/s/nelec?file=/src/StyleList.tsx

export interface OutboundIntegrationProps {
    templateId: number;
    event: MutableRefObject<EventModel | undefined>;
    outboundIntegration: OutboundIntegrationModel;
    outboundIntegrations: OutboundIntegrationModel[];
    hideOutboundIntegrationModal: () => void;
    isOutboundIntegrationOpen: boolean;
    loadEvents: () => void;
    showSuccessMessage: (message: string) => void;
    configurationTemplate?: React.MutableRefObject<ConfigurationTemplateModel | undefined>
    isConfigurationTemplateReadMode?: boolean;
    setConfigurationTemplateEditMode?: () => void;
}

const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    
};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac",
    },
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5",
    },
};

function OutboundIntegration(props: OutboundIntegrationProps) {
    const { branding } = useBranding()
    const [showInternalErrorMessage, setShowInternalErrorMessage] = useState(false);
    const [showInternalSuccessMessage, setShowInternalSuccessMessage] = useState(false);
    const [internalMessage, setInternalMessage] = useState("");
    const [selectedPivot, setSelectedPivot] = useState(PivotOutbound.Security);

    const [showDeactivatedIntegrationModal, setShowDeactivatedIntegrationModal] = useState(false);
    const [configurationTemplateInstructionsVisible, { toggle: toggleConfigurationTemplateInstructions }] = useBoolean(false);

    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
            },
        ],
        body: {
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            overflowY: "hidden",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
    });


    const outboundIntegrationForm = useForm<any, any>({
        reValidateMode: "onSubmit",
        mode: "all",
        defaultValues: {
            headerList: [Const_Header],
            urlParamsList: [Const_UrlParam],
            formDataParamsList: [Const_FormDataParam],
            mapInputVariableList: [Const_Header],
            mapOutputMapPropsList: [Const_MapProps],
            surrogateFiltersList: [Const_SurrogateFilter]
        },
        shouldUnregister: false
    });

    useEffect(() => {
        outboundIntegrationForm.register("Name", { required: "Name is required." });
        outboundIntegrationForm.register("Description", { required: "Description is required." });
        outboundIntegrationForm.register("Method", { required: "Method is required." });
        outboundIntegrationForm.register("ServiceUrl", { required: "Url is required." });
    }, []);

    useEffect(() => {
        if (props.outboundIntegration !== undefined) {
            loadOutboundIntegrationValues();
        }
    }, [props.outboundIntegration]);

    const showSuccessMessageIntegration = (message: string) => {
        setShowInternalSuccessMessage(true);
        setInternalMessage(message);
        setTimeout(function () {
            setShowInternalSuccessMessage(false);
        }, 5000);
    };

    const showErrorMessageIntegration = (message: string) => {
        setShowInternalErrorMessage(true);
        setInternalMessage(message);
        setTimeout(function () {
            setShowInternalErrorMessage(false);
        }, 5000);
    };

    const hideOutboundIntegrationModal = (reloadIntegrations: boolean) => {
        outboundIntegrationForm.reset();
        props.event.current = undefined;
        if(props.configurationTemplate !== undefined) {
            props.configurationTemplate!.current = undefined;
        }
        
        props.hideOutboundIntegrationModal();
        if(reloadIntegrations) {
            props.loadEvents();
        }
        
        if (props.setConfigurationTemplateEditMode !== undefined) {
            props.setConfigurationTemplateEditMode();
        }
    };

    const loadAssertionContent = (file: File) => {
        let readerContent = new FileReader();
        readerContent.readAsText(file);
        readerContent.onload = function () {
            outboundIntegrationForm.setValue("AssertionContent", readerContent.result);
            outboundIntegrationForm.trigger("AssertionFile");
        };
    };

    const loadAssertionFile = (base64: string, filename: string): File[] => {
        const myBlob = new Blob([decode(base64)], {
            type: filename.endsWith(".txt")
                ? "text/plain"
                : "application/x-pem-file",
        });
        const file = new File([myBlob], filename);
        loadAssertionContent(file);
        return [file];
    };

    const loadOutboundIntegrationValues = (testOutboundIntegration?: OutboundIntegrationModel) => {
        const outboundIntegration = testOutboundIntegration === undefined ? props.outboundIntegration : testOutboundIntegration
        outboundIntegrationForm.setValue("Name", outboundIntegration.Name);
        outboundIntegrationForm.setValue("Description", outboundIntegration.Description);
        if (outboundIntegration.ObjectMapInput.MapInput.Params.Method === "") {
            outboundIntegrationForm.setValue("Method", undefined);
        }
        else {
            outboundIntegrationForm.setValue("Method", {
                text: outboundIntegration.ObjectMapInput.MapInput.Params.Method,
                key: outboundIntegration.ObjectMapInput.MapInput.Params.Method,
            });
        }

        outboundIntegrationForm.setValue("Synchronous", outboundIntegration.ObjectMapInput.MapInput.Params.Synchronous);

        outboundIntegrationForm.setValue("IncludeXMLPayload", outboundIntegration.IncludeXMLPayload);
        outboundIntegrationForm.setValue("IncludeAttachments", outboundIntegration.IncludeAttachments);
        outboundIntegrationForm.setValue("IncludeCaseDocument", outboundIntegration.IncludeCaseDocument);

        if (outboundIntegration.IncludeXMLPayload ||
            outboundIntegration.IncludeAttachments ||
            outboundIntegration.IncludeCaseDocument) {
            outboundIntegrationForm.setValue("BodyType", { text: BodyType.KimBaseMessage, key: BodyType.KimBaseMessage, styles: { field: { marginRight: "10px" } } });
        }
        if (outboundIntegration.ObjectMapInput.MapInput.Params.URL_Form_Params.filter((item) => item.ParamType === ParamType.FORM).length > 0) {
            outboundIntegrationForm.setValue("BodyType", { text: BodyType.FormData, key: BodyType.FormData, styles: { field: { marginRight: "10px" } } });
        }
        if (outboundIntegration.ObjectMapInput.MapInput.Params.BodyParams.BodyValue.length > 0) {
            outboundIntegrationForm.setValue("BodyType", { text: BodyType.Raw, key: BodyType.Raw, styles: { field: { marginRight: "10px" } } });
        }
        if (outboundIntegration.ObjectBodyTargetNode.ScriptVariables.length > 0) {
            outboundIntegrationForm.setValue("BodyType", { text: BodyType.MapInput, key: BodyType.MapInput, styles: { field: { marginRight: "10px" } } });
        }

        if (outboundIntegration.IncludeCaseDocument) {
            outboundIntegrationForm.setValue("DocumentType", { text: outboundIntegration.ObjectMapInput.MapInput.Params.BodyParams.BodyValue, key: outboundIntegration.ObjectMapInput.MapInput.Params.BodyParams.BodyValue });
        }

        outboundIntegrationForm.setValue("ServiceUrl", outboundIntegration.ServiceURL);

        if (outboundIntegration.AuthenticationType === "") {
            outboundIntegrationForm.setValue("AuthenticationType", undefined);
        } else {
            outboundIntegrationForm.setValue("AuthenticationType", { text: outboundIntegration.AuthenticationType, key: outboundIntegration.AuthenticationType });
        }

        outboundIntegrationForm.setValue("BearerToken", outboundIntegration.ObjectAuthenticationProperties.BearerToken);
        outboundIntegrationForm.setValue("Username", outboundIntegration.ObjectAuthenticationProperties.ObjectBasicToken.Username);
        outboundIntegrationForm.setValue("Password", outboundIntegration.ObjectAuthenticationProperties.ObjectBasicToken.Password);
        outboundIntegrationForm.setValue("TokenUrl", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.TokenUrl);
        outboundIntegrationForm.setValue("ClientId", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.ClientId);
        outboundIntegrationForm.setValue("ClientSecret", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.ClientSecret);
        outboundIntegrationForm.setValue("AccessToken", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.AccessToken);
        outboundIntegrationForm.setValue("RefreshToken", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.RefreshToken);
        outboundIntegrationForm.setValue("GrantType", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.GrantType);
        outboundIntegrationForm.setValue("Scope", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.Scope);
        outboundIntegrationForm.setValue("ScopeRequired", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.ScopeRequired);
        outboundIntegrationForm.setValue("RedirectURI", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.RedirectURI);
        outboundIntegrationForm.setValue("AuthUrl", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.AuthUrl);
        outboundIntegrationForm.setValue("ResponseType", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.ResponseType);
        outboundIntegrationForm.setValue("Code", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.code);
        outboundIntegrationForm.setValue("ClientAssertionType", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.client_assertion_type);
        outboundIntegrationForm.setValue("Assertion", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.Assertion);
        if (outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.oAuth20Type === OAuth20Type.ServerToServerFlowAssertion) {
            outboundIntegrationForm.setValue("AssertionFile",
                loadAssertionFile(
                    outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.Assertion,
                    outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.AssertionFileName
                )
            );
        }
        outboundIntegrationForm.setValue("AssertionCertPassword", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.AssertionCertPassword);
        outboundIntegrationForm.setValue("AssertionFileName", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.AssertionFileName);
        outboundIntegrationForm.setValue("CompanyId", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.company_id);
        outboundIntegrationForm.setValue("CodeVerifier", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.code_verifier);
        outboundIntegrationForm.setValue("AssertionKeyName", outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.assertion_keyname);
        outboundIntegrationForm.setValue("AuthorizationHeader", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.AuthorizationHeader);
        if (outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.oAuth20Type === null ||
            outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.oAuth20Type === undefined) {
            outboundIntegrationForm.setValue("OAuth20Type", { text: OAuth20Type.RefreshTokenFlow, key: OAuth20Type.RefreshTokenFlow });
        } else {
            outboundIntegrationForm.setValue("OAuth20Type", {
                text: outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.oAuth20Type,
                key: outboundIntegration.ObjectAuthenticationProperties?.OAuth20Prop.oAuth20Type
            });
        }

        outboundIntegrationForm.setValue("SSFCCUsername", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.Username);
        outboundIntegrationForm.setValue("SSFCCPassword", outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.Password);
        if (outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.ServiceProvider !== null &&
            outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.ServiceProvider.length > 0) {
            outboundIntegrationForm.setValue("ServiceProvider", {
                text: outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.ServiceProvider,
                key: outboundIntegration.ObjectAuthenticationProperties.OAuth20Prop.ServiceProvider
            });
        }

        let tempHeaders = outboundIntegration.ObjectMapInput.MapInput.Headers;
        tempHeaders!.push(Const_Header);
        tempHeaders!.forEach((value: MapInputHeader, index: number, array: MapInputHeader[]) => {
            /*if (value.Key.toLowerCase() === ContentType && SendAsTypeList[value.Value] !== undefined) {
            outboundIntegrationForm.setValue("SendAs", { text: SendAsTypeList[value.Value], key: SendAsTypeList[value.Value] })
        }
        if (value.Key.toLowerCase() === AcceptType) {
            if (EncodeRequestTypeList[value.Value] === undefined && SendAsTypeList[value.Value] !== undefined) {
                outboundIntegrationForm.setValue("EncodeRequest", { text: EncodeRequestType.SendAsSelected, key: EncodeRequestType.SendAsSelected })
            } else if (EncodeRequestTypeList[value.Value] !== undefined) {
                outboundIntegrationForm.setValue("EncodeRequest", { text: EncodeRequestTypeList[value.Value], key: EncodeRequestTypeList[value.Value] })
            }
        }*/
            outboundIntegrationForm.setValue(`headerList.${index!}.Key`, value.Key);
            outboundIntegrationForm.setValue(`headerList.${index!}.Value`, value.Value);
        });

        let tempUrlParams = outboundIntegration.ObjectMapInput.MapInput.Params.URL_Form_Params.filter((item) => item.ParamType === ParamType.URL);
        tempUrlParams!.push(Const_UrlParam);
        tempUrlParams!.forEach((value: ParamsURL_Form_Param, index: number, array: ParamsURL_Form_Param[]) => {
            outboundIntegrationForm.setValue(`urlParamsList.${index!}.ParamName`, value.ParamName);
            outboundIntegrationForm.setValue(`urlParamsList.${index!}.ParamValueType`, { text: value.ParamValueType, key: value.ParamValueType });
            if (value.ParamValueType === ParamValueType.Document) {
                outboundIntegrationForm.setValue(`urlParamsList.${index!}.ParamValueSource`, { text: value.ParamValueSource, key: value.ParamValueSource });
            } else {
                outboundIntegrationForm.setValue(`urlParamsList.${index!}.ParamValueSource`, value.ParamValueSource);
            }
        });

        let tempFormParams = outboundIntegration.ObjectMapInput.MapInput.Params.URL_Form_Params.filter((item) => item.ParamType === ParamType.FORM);
        tempFormParams!.push(Const_FormDataParam);
        tempFormParams!.forEach((value: ParamsURL_Form_Param, index: number, array: ParamsURL_Form_Param[]) => {
            outboundIntegrationForm.setValue(`formDataParamsList.${index!}.ParamName`, value.ParamName);
            outboundIntegrationForm.setValue(`formDataParamsList.${index!}.ParamValueType`, { text: value.ParamValueType, key: value.ParamValueType });
            outboundIntegrationForm.setValue(`formDataParamsList.${index!}.InlineAttachment`, value.InlineAttachment);
            if (value.ParamValueType === ParamValueType.Document) {
                outboundIntegrationForm.setValue(`formDataParamsList.${index!}.ParamValueSource`, { text: value.ParamValueSource, key: value.ParamValueSource });
            } else {
                outboundIntegrationForm.setValue(`formDataParamsList.${index!}.ParamValueSource`, value.ParamValueSource);
            }
        });

        outboundIntegrationForm.setValue("Raw", outboundIntegration.ObjectMapInput.MapInput.Params.BodyParams.BodyValue);

        outboundIntegrationForm.setValue("MapInputPayload", outboundIntegration.ObjectBodyTargetNode.Payload);
        outboundIntegrationForm.setValue("MapInputScript", outboundIntegration.ObjectBodyTargetNode.Script);

        let tempScriptVariables = outboundIntegration.ObjectBodyTargetNode.ScriptVariables.split(";");
        tempScriptVariables.push('=""');
        tempScriptVariables.forEach((value: string, index: number, array: string[]) => {
            if (value.length > 0) {
                let item: MapInputHeader = {
                    Key: value.split("=")[0],
                    Value: value.split("=")[1].substring(1, value.split("=")[1].length - 1)
                };
                outboundIntegrationForm.setValue(`mapInputVariableList.${index!}.Key`, item.Key);
                outboundIntegrationForm.setValue(`mapInputVariableList.${index!}.Value`, item.Value);
            }
        });
        outboundIntegrationForm.setValue("MapInputUseParentSurrogateSubrecord", outboundIntegration.ObjectMapInput.MapInput.Params.ObjectIdentifierFilter.UseParentFilter);
        outboundIntegrationForm.setValue("MapInputFilterKeyName", outboundIntegration.ObjectMapInput.MapInput.Params.ObjectIdentifierFilter.FilterKeyName);

        let tempSurrogateFilters = outboundIntegration.ObjectMapInput.MapInput.Params.ObjectSurrogateFilter;
        tempSurrogateFilters!.push(Const_SurrogateFilter);
        tempSurrogateFilters!.forEach((value: SurrogateFilter, index: number, array: SurrogateFilter[]) => {
            outboundIntegrationForm.setValue(`surrogateFiltersList.${index!}.FilterName`, value.FilterName);
            outboundIntegrationForm.setValue(`surrogateFiltersList.${index!}.IntegrationId`, { text: getIntegrationName(value.IntegrationId), key: value.IntegrationId.toString() });
        });

        outboundIntegrationForm.setValue("MapOutputResponseType", {
            text: outboundIntegration.ObjectMapOutput.ResponseType,
            key: outboundIntegration.ObjectMapOutput.ResponseType,
        });
        outboundIntegrationForm.setValue("MapOutputScript", outboundIntegration.ObjectMapOutput.Script);
        let tempMapOutputMapProps = outboundIntegration.ObjectMapOutput.MapProps;
        tempMapOutputMapProps!.push(Const_MapProps);
        tempMapOutputMapProps!.forEach((value: MapProps, index: number, array: MapProps[]) => {
            outboundIntegrationForm.setValue(`mapOutputMapPropsList.${index!}.Path`, value.Path);
            outboundIntegrationForm.setValue(`mapOutputMapPropsList.${index!}.ValueType`, { text: value.ValueType, key: value.ValueType });
            outboundIntegrationForm.setValue(`mapOutputMapPropsList.${index!}.Index`, value.Index);
            outboundIntegrationForm.setValue(`mapOutputMapPropsList.${index!}.TargetField`, { text: value.TargetField, key: value.TargetField });
            outboundIntegrationForm.setValue(`mapOutputMapPropsList.${index!}.IsHeader`, value.IsHeader);
            outboundIntegrationForm.setValue(`mapOutputMapPropsList.${index!}.Output`, value.Output);
            if (value.TargetField === IdentifierKeyType.Identifier1)
                outboundIntegrationForm.setValue(`mapOutputMapPropsList.${index!}.IdentifierKeyName`, outboundIntegration.ObjectMapOutput.IdentifierKeyName1);
            if (value.TargetField === IdentifierKeyType.Identifier2)
                outboundIntegrationForm.setValue(`mapOutputMapPropsList.${index!}.IdentifierKeyName`, outboundIntegration.ObjectMapOutput.IdentifierKeyName2);
            if (value.TargetField === IdentifierKeyType.Identifier3)
                outboundIntegrationForm.setValue(`mapOutputMapPropsList.${index!}.IdentifierKeyName`, outboundIntegration.ObjectMapOutput.IdentifierKeyName3);
        });

        outboundIntegrationForm.setValue(`ApprovedStatus`, outboundIntegration.ObjectApprovedStatus);
    };

    const getIntegrationName = (integrationId: number) => {
        let integration: OutboundIntegrationModel =
            props.outboundIntegrations.find((f) => f.Id === integrationId)!;
        if (integration === undefined) {
            return "";
        } else {
            return `${integration.Name!} (${integration.Description})`;
        }
    };

    const getAuthenticationProperties = (authenticationType: AuthenticationType, oAuth20Type: OAuth20Type): AuthenticationProperties => {
        let authenticationProperties: AuthenticationProperties = {
            ObjectBasicToken: {
                Password: "",
                Username: "",
            },
            BearerToken: "",
            OAuth20Prop: {
                oAuth20Type: "",
                AccessToken: "",
                ClientId: "",
                ClientSecret: "",
                GrantType: "",
                RedirectURI: "",
                RefreshToken: "",
                Scope: "",
                TokenUrl: "",
                AuthUrl: "",
                ResponseType: "",
                Assertion: "",
                AssertionCertPassword: "",
                assertion_keyname: "",
                AssertionFileName: "",
                client_assertion_type: "",
                code: "",
                code_verifier: "",
                company_id: "",
                new_token: "",
                Password: "",
                State: "",
                Username: "",
                ScopeRequired: false,
                ServiceProvider: "",
                AuthorizationHeader: "",
            },
            BasicToken: "",
        };
        switch (authenticationType) {
            case AuthenticationType.Basic: {
                authenticationProperties.ObjectBasicToken = {
                    Username: outboundIntegrationForm.getValues("Username"),
                    Password: outboundIntegrationForm.getValues("Password"),
                };
                break;
            }
            case AuthenticationType.Bearer: {
                authenticationProperties.BearerToken = outboundIntegrationForm.getValues("BearerToken");
                break;
            }
            case AuthenticationType.OAuth20: {
                authenticationProperties.OAuth20Prop = {
                    AccessToken: [OAuth20Type.RefreshTokenFlow].includes(oAuth20Type) ? outboundIntegrationForm.getValues("AccessToken") : "",
                    RefreshToken: [OAuth20Type.RefreshTokenFlow].includes(oAuth20Type) ? outboundIntegrationForm.getValues("RefreshToken") : "",
                    AuthUrl: [OAuth20Type.RefreshTokenFlow].includes(oAuth20Type) ? outboundIntegrationForm.getValues("AuthUrl") : "",
                    ResponseType: [OAuth20Type.RefreshTokenFlow].includes(oAuth20Type) ? outboundIntegrationForm.getValues("ResponseType") : "",
                    TokenUrl: [OAuth20Type.RefreshTokenFlow, OAuth20Type.ServerToServerFlowCC, OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("TokenUrl") : "",
                    GrantType: [OAuth20Type.RefreshTokenFlow, OAuth20Type.ServerToServerFlowCC, OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("GrantType") : "",
                    ClientId: [OAuth20Type.RefreshTokenFlow, OAuth20Type.ServerToServerFlowCC, OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("ClientId") : "",
                    ClientSecret: [OAuth20Type.RefreshTokenFlow, OAuth20Type.ServerToServerFlowCC].includes(oAuth20Type) ? outboundIntegrationForm.getValues("ClientSecret") : "",
                    RedirectURI: [OAuth20Type.RefreshTokenFlow].includes(oAuth20Type) ? outboundIntegrationForm.getValues("RedirectURI") : "",
                    Scope: [OAuth20Type.RefreshTokenFlow, OAuth20Type.ServerToServerFlowCC, OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("Scope") : "",
                    Username: [OAuth20Type.ServerToServerFlowCC].includes(oAuth20Type) ? outboundIntegrationForm.getValues("SSFCCUsername") : "",
                    Password: [OAuth20Type.ServerToServerFlowCC].includes(oAuth20Type) ? outboundIntegrationForm.getValues("SSFCCPassword") : "",
                    code: [OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("Code") : "",
                    client_assertion_type: [OAuth20Type.RefreshTokenFlow, OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("ClientAssertionType") : "",
                    Assertion: [OAuth20Type.RefreshTokenFlow, OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("Assertion") : "",
                    AssertionCertPassword: [OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("AssertionCertPassword") : "",
                    company_id: [OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("CompanyId") : "",
                    code_verifier: [OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("CodeVerifier") : "",
                    assertion_keyname: [OAuth20Type.RefreshTokenFlow, OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("AssertionKeyName") : "",
                    AssertionFileName: [OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("AssertionFileName") : "",
                    new_token: "",
                    oAuth20Type: oAuth20Type === OAuth20Type.Custom ? "" : oAuth20Type,
                    State: "",
                    ScopeRequired: [OAuth20Type.RefreshTokenFlow, OAuth20Type.ServerToServerFlowCC, OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("ScopeRequired") : false,
                    ServiceProvider: [OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("ServiceProvider").key.toString() : "",
                    AuthorizationHeader: [OAuth20Type.RefreshTokenFlow, OAuth20Type.ServerToServerFlowCC, OAuth20Type.ServerToServerFlowAssertion].includes(oAuth20Type) ? outboundIntegrationForm.getValues("AuthorizationHeader") : "",
                };
                break;
            }
            default: {
                authenticationProperties.ObjectBasicToken = {
                    Username: "",
                    Password: ""
                };
                authenticationProperties.BearerToken = "";
                authenticationProperties.OAuth20Prop = {
                    AccessToken: "",
                    RefreshToken: "",
                    AuthUrl: "",
                    ResponseType: "",
                    TokenUrl: "",
                    GrantType: "",
                    ClientId: "",
                    ClientSecret: "",
                    RedirectURI: "",
                    Scope: "",
                    Username: "",
                    Password: "",
                    code: "",
                    client_assertion_type: "",
                    Assertion: "",
                    AssertionCertPassword: "",
                    company_id: "",
                    code_verifier: "",
                    assertion_keyname: "",
                    AssertionFileName: "",
                    new_token: "",
                    oAuth20Type: "",
                    State: "",
                    ScopeRequired: false,
                    ServiceProvider: "",
                    AuthorizationHeader: "",
                };
                break;
            }
        }
        return authenticationProperties;
    };

    const getHeaders = (): MapInputHeader[] => {
        let headerList: MapInputHeader[] = [];
        headerList = outboundIntegrationForm.getValues("headerList");

        /*let tempSendAs = outboundIntegrationForm.getValues("SendAs") as IChoiceGroupOption;
        if (tempSendAs !== undefined) {
            headerList.push({ Key: "Content-Type", Value: SendAsTypeList[tempSendAs.key] })
        }
        let tempEncodeRequest = outboundIntegrationForm.getValues("EncodeRequest") as IChoiceGroupOption;
        if (tempEncodeRequest !== undefined && tempEncodeRequest.key.length > 0) {
            headerList.push({ Key: "Accept", Value: EncodeRequestTypeList[tempEncodeRequest.key] })
        }*/
        return headerList.filter(
            (item) => item.Key.length > 0 && item.Value.length >= 0
        );
    };

    const getURL_Form_Params = (): ParamsURL_Form_Param[] => {
        let urlParamsList: ParamsURL_Form_Param[] = [];
        let formDataParamsList: ParamsURL_Form_Param[] = [];

        urlParamsList = outboundIntegrationForm.getValues("urlParamsList");
        urlParamsList.forEach((item) => {
            const tempParamValueType: any = item.ParamValueType;
            item.ParamType = ParamType.URL;
            if (tempParamValueType !== "") {
                item.ParamValueType = (
                    tempParamValueType as IDropdownOption
                ).key.toString();
            }
            if (tempParamValueType.key === ParamValueType.Document) {
                const tempParamValueSource: IDropdownOption = item.ParamValueSource as any;
                item.ParamValueSource = tempParamValueSource.key.toString();
            }
            if (tempParamValueType.key === ParamValueType.Payload) {
                item.ParamValueSource = "";
            }
        });

        formDataParamsList = outboundIntegrationForm.getValues("formDataParamsList");
        formDataParamsList.forEach((item) => {
            const tempParamValueType: any = item.ParamValueType;
            item.ParamType = ParamType.FORM;
            if (tempParamValueType !== "") {
                try {
                    item.ParamValueType = (tempParamValueType as IDropdownOption).key.toString();
                } catch (error) {
                    item.ParamValueType = tempParamValueType;
                }
            }
            try {
                if (tempParamValueType.key === ParamValueType.Document) {
                    const tempParamValueSource: IDropdownOption = item.ParamValueSource as any;
                    item.ParamValueSource = tempParamValueSource.key.toString();
                }
                if (tempParamValueType.key === ParamValueType.Payload) {
                    item.ParamValueSource = "";
                }
            } catch (error) { }
        });
        return urlParamsList
            .concat(formDataParamsList)
            .filter(
                (item) =>
                    item.ParamName.length > 0 &&
                    item.ParamType.length > 0 &&
                    item.ParamValueType.length > 0 &&
                    item.ParamValueSource.length >= 0
            );
    };

    const getSurrogateFilters = (): SurrogateFilter[] => {
        let surrogateFilterList: SurrogateFilter[] = [];

        surrogateFilterList = outboundIntegrationForm.getValues("surrogateFiltersList");
        surrogateFilterList.forEach((item) => {
            const tempParamValueType: any = item.IntegrationId;
            if (tempParamValueType !== -1) {
                item.IntegrationId = +(tempParamValueType as IDropdownOption).key.toString();
            }
            item.RecordFilter = "";
            item.SortOrder = "";
            item.SourceRecordId = -1;
            item.TemplateId = props.templateId;
        });

        return surrogateFilterList.filter(
            (item) =>
                item.FilterName.length > 0 &&
                item.IntegrationId.toString().length > 0 &&
                item.TemplateId > 0
        );
    };

    const getType = (): string => {
        const method = outboundIntegrationForm.getValues("Method") !== undefined ? outboundIntegrationForm.getValues("Method").key : "";
        const formDataParamsList = outboundIntegrationForm.getValues("formDataParamsList") !== undefined ? (outboundIntegrationForm.getValues("formDataParamsList") as ParamsURL_Form_Param[]) : [];
        //Body type Form is only send if form data inside the body is fill, for everything send "Body"
        if (method === MethodType.GET) {
            return TypeType.None;
        } else if (formDataParamsList.length > 0 && formDataParamsList[0].ParamName !== "") {
            return TypeType.Form;
        } else {
            return TypeType.Body;
        }
    };

    const getBodyParams = (): ParamsBodyParams => {
        const raw = outboundIntegrationForm.getValues("Raw") !== undefined ? outboundIntegrationForm.getValues("Raw") : "";
        const includeXMLPayload = outboundIntegrationForm.getValues("IncludeXMLPayload") !== undefined ? outboundIntegrationForm.getValues("IncludeXMLPayload") : false;
        const includeAttachments = outboundIntegrationForm.getValues("IncludeAttachments") !== undefined ? outboundIntegrationForm.getValues("IncludeAttachments") : false;
        const includeCaseDocument = outboundIntegrationForm.getValues("IncludeCaseDocument") !== undefined ? outboundIntegrationForm.getValues("IncludeCaseDocument") : false;
        const documentType = outboundIntegrationForm.getValues("DocumentType") !== undefined ? outboundIntegrationForm.getValues("DocumentType").key : "";

        let paramsBodyParams: ParamsBodyParams = {
            BodyValue: "",
            Source: "",
        };
        if (raw.length > 0) {
            paramsBodyParams.Source = SourceType.Fixed;
            paramsBodyParams.BodyValue = raw;
        }
        if (includeXMLPayload || includeAttachments) {
            paramsBodyParams.Source = SourceType.DocumentData;
            paramsBodyParams.BodyValue = "";
        }
        if (includeCaseDocument) {
            paramsBodyParams.Source = SourceType.DocumentData;
            paramsBodyParams.BodyValue = documentType;
        }
        return paramsBodyParams;
    };

    const create = () => {
        outboundIntegrationForm.handleSubmit(
            async (data) => {
                if (props.outboundIntegrations.filter((f) => f.Active).length === 3) {
                    setShowDeactivatedIntegrationModal(true);
                } else {
                    callbackDeactivatedIntegrationConfirmation();
                }
            },
            (errors) => {
                showErrorMessageIntegration(
                    `Error: ${Object.values(errors)
                        .map((item: any) => item.message)
                        .join("|")}`
                );
            }
        )();
    };

    const updateIntegration = () => {
        outboundIntegrationForm.handleSubmit(
            async (data) => {
                OutboundServices.UpdateIntegration(await getUpdatedOutboundIntegration()).then((res) => {
                    if (res.Success !== undefined &&
                        res.Success !== null &&
                        res.Success === true) {
                        props.showSuccessMessage(
                            "Integration has been updated successfully."
                        );
                        hideOutboundIntegrationModal(true);
                    } else {
                        let errorMessage =
                            res.ExceptionMessage !== undefined &&
                                res.ExceptionMessage !== ""
                                ? res.ExceptionMessage
                                : "Something unexpected happened.";
                        showErrorMessageIntegration("Error: " + errorMessage);
                    }
                });
            },
            (errors) => {
                showErrorMessageIntegration(
                    `Error: ${Object.values(errors)
                        .map((item: any) => item.message)
                        .join("|")}`
                );
            }
        )();
    };

    const getUpdatedOutboundIntegration = async (): Promise<CreateOutboundIntegration | UpdateOutboundIntegration> => {
        return {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            EventId: props.event.current !== undefined ? props.event.current!.Id : 0,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            Integration: {
                Id: props.outboundIntegration !== undefined ? props.outboundIntegration.Id : 0,
                Active: props.outboundIntegration !== undefined ? props.outboundIntegration.Active : false,

                Name: outboundIntegrationForm.getValues("Name"),
                Description: outboundIntegrationForm.getValues("Description"),
                ServiceURL: outboundIntegrationForm.getValues("ServiceUrl"),

                AuthenticationType: outboundIntegrationForm.getValues("AuthenticationType") !== undefined ? outboundIntegrationForm.getValues("AuthenticationType").key : "",
                ObjectAuthenticationProperties: getAuthenticationProperties(
                    outboundIntegrationForm.getValues("AuthenticationType") === undefined ? AuthenticationType.Custom : outboundIntegrationForm.getValues("AuthenticationType").key as AuthenticationType,
                    outboundIntegrationForm.getValues("OAuth20Type") === undefined ? OAuth20Type.Custom : (outboundIntegrationForm.getValues("OAuth20Type").key as OAuth20Type)
                ),

                ObjectMapInput: {
                    MapInput: {
                        Params: {
                            Method: outboundIntegrationForm.getValues("Method") === undefined ? "" : outboundIntegrationForm.getValues("Method").key,
                            BodyParams: getBodyParams(),
                            Synchronous: outboundIntegrationForm.getValues("Synchronous"),
                            Type: getType(),
                            URL_Form_Params: getURL_Form_Params(),
                            ObjectSurrogateFilter: getSurrogateFilters(),
                            ObjectIdentifierFilter: {
                                UseParentFilter: outboundIntegrationForm.getValues("MapInputUseParentSurrogateSubrecord"),
                                FilterKeyName: outboundIntegrationForm.getValues("MapInputFilterKeyName")
                            }
                        },
                        Headers: getHeaders(),
                    },
                },
                IncludeAttachments: outboundIntegrationForm.getValues("IncludeAttachments"),
                IncludeCaseDocument: outboundIntegrationForm.getValues("IncludeCaseDocument"),
                IncludeXMLPayload: outboundIntegrationForm.getValues("IncludeXMLPayload"),

                AttachmentTargetsNode: "",
                ObjectBodyTargetNode: getObjectBodyTargetNode(),
                CaseDocumentTargetNode: "",
                CertAuthenticationProperties: "",

                ObjectMapOutput: {
                    MapProps: getMapProps(),
                    ResponseType: outboundIntegrationForm.getValues("MapOutputResponseType") !== undefined && outboundIntegrationForm.getValues("MapOutputResponseType") !== "" ? outboundIntegrationForm.getValues("MapOutputResponseType").key.toString() : "",
                    Script: outboundIntegrationForm.getValues("MapOutputScript") !== undefined ? outboundIntegrationForm.getValues("MapOutputScript") : "",
                    IdentifierKeyName1: getIdentifierKeyName(IdentifierKeyType.Identifier1),
                    IdentifierKeyName2: getIdentifierKeyName(IdentifierKeyType.Identifier2),
                    IdentifierKeyName3: getIdentifierKeyName(IdentifierKeyType.Identifier3)
                },
                Order: props.outboundIntegration !== undefined ? props.outboundIntegration.Order : props.outboundIntegrations.length + 1,
                Surrogate1OutputMapReferenceAttribute: "",
                Surrogate2OutputMapReferenceAttribute: "",
                Surrogate3OutputMapReferenceAttribute: "",
                TargetIntegrationPlatform: "",
                TargetIntegrationProperties: "",
                TemplateId: props.templateId,
                UniqueId: "",
                ObjectApprovedStatus: outboundIntegrationForm.getValues("ApprovedStatus")
            },
        };
    };

    const getIdentifierKeyName = (identifierType: IdentifierKeyType): string => {
        const currentMapOutputProp: MapProps = outboundIntegrationForm.getValues("mapOutputMapPropsList").find((element: MapProps) => element.TargetField === identifierType)
        if (currentMapOutputProp !== undefined) {
            return currentMapOutputProp.IdentifierKeyName!
        } else {
            return "";
        }
    };

    const getMapProps = (): MapProps[] => {
        let mapOutputMapPropsList: MapProps[] = [];
        mapOutputMapPropsList = outboundIntegrationForm.getValues("mapOutputMapPropsList");

        mapOutputMapPropsList.forEach((item) => {
            const tempValueType: any = item.ValueType;
            if (tempValueType !== "") {
                try {
                    item.ValueType = (
                        tempValueType as IDropdownOption
                    ).key.toString();
                } catch (error) {
                    item.ValueType = tempValueType;
                }
            }

            const tempTargetField: any = item.TargetField;
            if (tempTargetField !== "") {
                try {
                    item.TargetField = (
                        tempTargetField as IDropdownOption
                    ).key.toString();
                } catch (error) {
                    item.TargetField = tempTargetField;
                }
            }

            item.Index = item.Index.toString();
        });

        return mapOutputMapPropsList.filter((item) =>
            item.Index.toString().length > 0 &&
            item.Path.length > 0 &&
            item.TargetField.length > 0 &&
            item.ValueType.length > 0
        );
    };

    const getObjectBodyTargetNode = (): BodyTargetNode => {
        let mapInputVariableList: MapInputHeader[] = [];
        mapInputVariableList = outboundIntegrationForm.getValues("mapInputVariableList");
        let objectBodyTargetNode: BodyTargetNode = {
            Payload: outboundIntegrationForm.getValues("MapInputPayload") !== undefined ? outboundIntegrationForm.getValues("MapInputPayload") : "",
            Script: outboundIntegrationForm.getValues("MapInputScript") !== undefined ? outboundIntegrationForm.getValues("MapInputScript") : "",
            ScriptVariables: mapInputVariableList.filter((item) => item.Key.length > 0 && item.Value.length >= 0).map((item) => {
                return `${item.Key}="${item.Value}"`;
            }).join(";") + ";",
        };
        return objectBodyTargetNode;
    };

    const onPivotLinkClick = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        if (item?.props.itemKey === PivotOutbound.Security ||
            selectedPivot === PivotOutbound.Security) {
            outboundIntegrationForm.handleSubmit(
                async (data) => {
                    setSelectedPivot(item?.props.itemKey as PivotOutbound);
                },
                (errors) => {
                    showErrorMessageIntegration(`Error: ${Object.values(errors).map((item: any) => item.message).join("|")}`);
                }
            )();
        } else {
            setSelectedPivot(item?.props.itemKey as PivotOutbound);
        }
    };

    const callApprovedStatus = async (checked: boolean) => {
        OutboundServices.ApprovedStatus({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            Id: props.outboundIntegration.Id,
            IsApproved: checked!,
        }).then((res) => {
            if (res.Success !== undefined &&
                res.Success !== null &&
                res.Success === true) {
                showSuccessMessageIntegration(`Integration has been ${checked ? `approved` : `unapproved`}.`);
                props.loadEvents();
            } else {
                let errorMessage =
                    res.ExceptionMessage !== undefined &&
                        res.ExceptionMessage !== ""
                        ? res.ExceptionMessage
                        : "Something unexpected happened.";
                showErrorMessageIntegration("Error: " + errorMessage);
            }
        });
    };

    const onChangeApprovedStatus = async (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
        callApprovedStatus(checked!);
    };

    const callbackDeactivatedIntegrationConfirmation = async () => {
        let tempOutboundIntegration = await getUpdatedOutboundIntegration();
        tempOutboundIntegration.Integration.Active = props.outboundIntegrations.filter((f) => f.Active).length <= 2 ? true : false;
        OutboundServices.CreateIntegration(tempOutboundIntegration as CreateOutboundIntegration).then((res) => {
            if (res.Success !== undefined &&
                res.Success !== null &&
                res.Success === true) {
                props.showSuccessMessage("Integration has been created successfully.");
                hideOutboundIntegrationModal(true);
            } else {
                let errorMessage =
                    res.ExceptionMessage !== undefined &&
                        res.ExceptionMessage !== ""
                        ? res.ExceptionMessage
                        : "Something unexpected happened.";
                showErrorMessageIntegration("Error: " + errorMessage);
            }
        });
        setShowDeactivatedIntegrationModal(false);
    };

    const updateConfigurationTemplate = async () => {
        let tempConfigurationTemplate = props.configurationTemplate!.current!;
        let tempIntegration = await getUpdatedOutboundIntegration()

        let integrationIndex = tempConfigurationTemplate.ObjectIntegrationConfig.Integrations.findIndex(integration => integration.Id === tempIntegration.Integration.Id)
        tempConfigurationTemplate.ObjectIntegrationConfig.Integrations[integrationIndex] = tempIntegration.Integration
        tempConfigurationTemplate.ObjectIntegrationConfig.Integrations[integrationIndex].ObjectAuthenticationProperties = {
            ObjectBasicToken: {
                Password: "",
                Username: "",
            },
            BearerToken: "",
            OAuth20Prop: {
                oAuth20Type: "",
                AccessToken: "",
                ClientId: "",
                ClientSecret: "",
                GrantType: "",
                RedirectURI: tempConfigurationTemplate.ObjectIntegrationConfig.Integrations[integrationIndex].ObjectAuthenticationProperties.OAuth20Prop.RedirectURI,
                RefreshToken: "",
                Scope: "",
                TokenUrl: "",
                AuthUrl: "",
                ResponseType: "",
                Assertion: "",
                AssertionCertPassword: "",
                assertion_keyname: "",
                AssertionFileName: "",
                client_assertion_type: "",
                code: "",
                code_verifier: "",
                company_id: "",
                new_token: "",
                Password: "",
                State: "",
                Username: "",
                ScopeRequired: false,
                ServiceProvider: "",
                AuthorizationHeader: "",
            },
            BasicToken: "",
        }

        tempConfigurationTemplate.ObjectIntegrationConfig.Integrations[integrationIndex].ObjectMapInput.MapInput.Params.ObjectSurrogateFilter.forEach(surrogate => {
            surrogate.IntegrationId = 0
        })

        OutboundServices.UpdateConfigurationTemplate({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            ConfigurationTemplate: tempConfigurationTemplate!,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername()
        }).then(res => {
            if (res.Success !== undefined && res.Success !== null && res.Success === true) {
                props.showSuccessMessage("Configuration Template has been updated successfully.");
                hideOutboundIntegrationModal(true);
            } else {
                loadOutboundIntegrationValues(tempIntegration.Integration)
                let errorMessage = res.ExceptionMessage !== undefined && res.ExceptionMessage !== "" ? res.ExceptionMessage : "Something unexpected happened.";
                showErrorMessageIntegration("Error: " + errorMessage)
            }
        })
    };

    return (
        <>
            <Modal
                isOpen={props.isOutboundIntegrationOpen}
                onDismiss={() => hideOutboundIntegrationModal(false)}
                isBlocking={true}
                containerClassName={contentStyles.container}
                styles={{ main: { width: "55%", height: "760px" } }}
            >
                <FormProvider {...outboundIntegrationForm}>
                    <div className={contentStyles.header}>
                        <div>Outbound Integration</div>
                        <IconButton styles={iconButtonStyles} iconProps={{ iconName: "Cancel" }} ariaLabel="Close popup modal" onClick={() => hideOutboundIntegrationModal(false)} />
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ paddingRight: "30px", textAlign: "end" }}>
                            {props.configurationTemplate !== undefined ? <Link underline id={'configurationTemplateInstructions'} onClick={toggleConfigurationTemplateInstructions}>Instructions</Link> : null}
                            {configurationTemplateInstructionsVisible ?
                                <TeachingBubble
                                    calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                                    target={"#configurationTemplateInstructions"}
                                    isWide={true}
                                    hasCloseButton={true}
                                    closeButtonAriaLabel="Close"
                                    onDismiss={toggleConfigurationTemplateInstructions}
                                    headline="Instructions">
                                    {<div dangerouslySetInnerHTML={{ __html: props.configurationTemplate!.current!.Instructions }} />}

                                </TeachingBubble> : null}
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {showInternalErrorMessage ? (
                                <MessageBar styles={messageBarFailedStyles}>
                                    {internalMessage}
                                </MessageBar>
                            ) : null}
                            {showInternalSuccessMessage ? (
                                <MessageBar styles={messageBarSuccessStyles}>
                                    {internalMessage}
                                </MessageBar>
                            ) : null}
                        </div>
                    </div>
                    <div className={contentStyles.body}>
                        <Stack
                            horizontal={true}
                            wrap={true}
                            disableShrink={true}
                            horizontalAlign={"start"}
                            verticalAlign={"start"}
                            styles={{ root: { marginTop: 0 } }}
                            tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                        >
                            <Stack.Item grow={2}>
                                <RHFTextBoxControl
                                    id={"Name"}
                                    label={"Name"}
                                    maxLength={100}
                                    disabled={(props.outboundIntegration !== undefined && props.outboundIntegration.Name !== "" && props.outboundIntegration.Id > 0) || props.isConfigurationTemplateReadMode ? true : false}
                                    title="Enter outbound integration name."
                                    control={outboundIntegrationForm.control}
                                    setValue={outboundIntegrationForm.setValue}
                                    getValues={outboundIntegrationForm.getValues}
                                    trigger={outboundIntegrationForm.trigger}
                                    defaultValue={""}
                                    required={true}
                                />
                            </Stack.Item>
                            <Stack.Item grow={5}>
                                <RHFTextBoxControl
                                    id={"Description"}
                                    label={"Description"}
                                    maxLength={100}
                                    disabled={props.isConfigurationTemplateReadMode ? true : false}
                                    title="Enter outbound integration description."
                                    control={outboundIntegrationForm.control}
                                    setValue={outboundIntegrationForm.setValue}
                                    getValues={outboundIntegrationForm.getValues}
                                    trigger={outboundIntegrationForm.trigger}
                                    defaultValue={""}
                                    required={true}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack
                            horizontal={true}
                            wrap={true}
                            disableShrink={true}
                            horizontalAlign={"start"}
                            verticalAlign={"start"}
                            styles={{ root: { marginTop: 0 } }}
                            tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                        >
                            <Stack.Item grow={1}>
                                <RHFDropDownListSingleSelectControl
                                    id={"Method"}
                                    label="Method"
                                    options={Object.entries(MethodType).map((item) => {
                                        return {
                                            text: item[0],
                                            key: item[1],
                                        };
                                    })}
                                    required={true}
                                    disabled={props.isConfigurationTemplateReadMode ? true : false}
                                    title="Select method type."
                                    control={outboundIntegrationForm.control}
                                    setValue={outboundIntegrationForm.setValue}
                                    getValues={outboundIntegrationForm.getValues}
                                    trigger={outboundIntegrationForm.trigger}
                                    onCallback={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => {
                                        BodyFields.forEach((field) => {
                                            outboundIntegrationForm.resetField(field);
                                        });
                                        outboundIntegrationForm.resetField("BodyType");
                                        setSelectedPivot(PivotOutbound.Security)
                                    }}
                                    defaultValue={""}
                                />
                            </Stack.Item>
                            <Stack.Item grow={4}>
                                <RHFTextBoxControl
                                    id={"ServiceUrl"}
                                    label={"Url"}
                                    disabled={props.isConfigurationTemplateReadMode ? true : false}
                                    title="Enter outbound integration url."
                                    control={outboundIntegrationForm.control}
                                    setValue={outboundIntegrationForm.setValue}
                                    getValues={outboundIntegrationForm.getValues}
                                    trigger={outboundIntegrationForm.trigger}
                                    defaultValue={""}
                                    required={true}
                                />
                            </Stack.Item>
                        </Stack>
                        <Separator styles={{ root: [{ selectors: { "::before": { background: "rgb(128,128,128)" } } }] }}></Separator>
                        <Stack
                            styles={{ root: { height: "400px", marginTop: 0 } }}
                            tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                        >
                            <Stack.Item grow>
                                <Pivot selectedKey={selectedPivot} onLinkClick={onPivotLinkClick}>
                                    <PivotItem
                                        itemKey={"Security"}
                                        headerText="Security"
                                        headerButtonProps={{ "data-order": 1 }}
                                    >
                                        <Security
                                            templateId={props.templateId}
                                            showSuccessMessage={showSuccessMessageIntegration}
                                            showErrorMessage={showErrorMessageIntegration}
                                            isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode}
                                        />
                                    </PivotItem>
                                    <PivotItem itemKey={"RequestHeader"} headerText="Request Header">
                                        <HeaderList isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode} />
                                    </PivotItem>
                                    <PivotItem itemKey={"URLParams"} headerText="URL Params">
                                        <UrlParamsList isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode} />
                                    </PivotItem>
                                    <PivotItem itemKey={"Body"} headerText="Body">
                                        <Body
                                            surrogate={<SurrogateFilters isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode} outboundIntegrationId={props.outboundIntegration !== undefined ? props.outboundIntegration.Id : 0} templateId={props.templateId} />}
                                            test={<Test isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode} templateId={props.templateId} />}
                                            isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode}
                                        />
                                    </PivotItem>
                                    <PivotItem itemKey={"MapOutput"} headerText="Map Output">
                                        <MapOutput isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode} eventType={props.event.current !== undefined ? props.event.current!.EventType : EventType.None} templateId={props.templateId} />
                                    </PivotItem>
                                    <PivotItem itemKey={"TestRelease"} headerText="Test & Release" >
                                        <TestRelease
                                            TemplateId={props.templateId}
                                            IntegrationId={props.outboundIntegration?.Id}
                                            onChangeApprovedStatus={onChangeApprovedStatus}
                                            getUpdatedOutboundIntegration={getUpdatedOutboundIntegration}
                                            showErrorMessageIntegration={showErrorMessageIntegration}
                                            ApproveStatus={props.outboundIntegration?.ObjectApprovedStatus}
                                            callApprovedStatus={callApprovedStatus}
                                            loadOutboundIntegrationValues={loadOutboundIntegrationValues}
                                            isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode}
                                        />
                                    </PivotItem>
                                </Pivot>
                            </Stack.Item>
                        </Stack>
                        <Stack
                            styles={{ root: { marginTop: 0, float: "right" } }}
                            tokens={{
                                childrenGap: "5 5",
                                padding: "5px 5px 5px 5px",
                            }}
                        >
                            <Stack horizontal disableShrink>
                                {props.configurationTemplate !== undefined ?
                                    <PrimaryButton id="Update" disabled={props.isConfigurationTemplateReadMode} onClick={updateConfigurationTemplate} text="Update" title="Update Configuration Service." /> :
                                    props.outboundIntegration !== undefined ?
                                        <Stack.Item align="start">
                                            <PrimaryButton disabled={props.isConfigurationTemplateReadMode} id="Update" onClick={updateIntegration} text="Update" title="Update Service." />
                                        </Stack.Item> :
                                        <Stack.Item align="start">
                                            <PrimaryButton disabled={props.isConfigurationTemplateReadMode} id="Create" onClick={create} text="Create" title="Create Service." />
                                        </Stack.Item>}
                            </Stack>
                        </Stack>
                    </div>
                </FormProvider>
            </Modal>
            <ConfirmationModal
                showConfirmationModal={showDeactivatedIntegrationModal}
                setShowConfirmationModal={setShowDeactivatedIntegrationModal}
                callbackConfirmationModal={callbackDeactivatedIntegrationConfirmation}
                title={"Deactivated Integration"}
                textYesButton={"Ok"}
                message={"We only support 3 active integrations, this integration will be created but it's going to be deactivated."}
                titleYesButton={"Ok"}
                HideNoButton={false}
            />
        </>
    );
}

export default OutboundIntegration;