import React, { FC, useState } from 'react'
import { createContext } from "react";
import { ILayout } from '../../CustomTemplate';
import { DEFAULT_EDIT_MODE, FormRuleEditMode, IFormAssemblyRule } from '../../../../interfaces/IFormAssembly';
type ContextProviderProps = {
    templateId: number
    templateName: string
    siteSelected: string
    items: ILayout[]
    formRules: IFormAssemblyRule[]
    editMode: FormRuleEditMode
    setEditMode: React.Dispatch<React.SetStateAction<FormRuleEditMode>>
    setFormRules: React.Dispatch<React.SetStateAction<IFormAssemblyRule[]>>
    setToolBoxItems: (items: ILayout[]) => void
}
type ContextProps = {

} & ContextProviderProps

export const context = createContext({} as ContextProps)

const WizardProvider: FC<ContextProviderProps> = (props) => {

    //const [editMode, setEditMode] = useState<FormRuleEditMode>(DEFAULT_EDIT_MODE)

    return <context.Provider value={{
        ...props
    }}>
        {props.children}
    </context.Provider>
}
export default WizardProvider