import React, { useState } from 'react';
import '../../Custom.css';
import Helper from '../../Helpers/Helper';
import { CustomForm } from '../CustomTemplates/CustomForm/CustomForm';
import { useConstCallback } from '@fluentui/react-hooks';

function DraftCustomForm(props: any) {
    const [showCustomForm, setShowCustomForm] = useState(true)

    const toggleCustomForm = useConstCallback((open: boolean) => {
        if (open === true) {
            setShowCustomForm(open)

        }
        else {
            props.clearSelection()
            setShowCustomForm(open)
        }
    })
    
    return (
            <CustomForm
                allowDrafts={true}
                draftId={props.documentDraftSelected.Id}
                data={props.jsonBLOB}
                draftBLOB={props.draftBLOB}
                loadDocumentDrafts={props.loadDocumentDrafts}
                closeDraftModal={props.closeDraftModal}
                parentId={props.documentDraftSelected.ParentId}
                isSubform={props.documentDraftSelected.ParentId > 0? true: false}
                documentId={props.documentDraftSelected.ParentId > 0 ? props.documentDraftSelected.ParentId.toString() : undefined}
                onBehalf={props.documentDraftSelected.ParentId > 0? Helper.getUsername(): undefined}

                showCustomForm={showCustomForm}
                toggleCustomForm={toggleCustomForm}

                siteSelected={Helper.getSiteSelected()}
                accountId={Helper.getDefaultAccount()}
                targetUser={Helper.getUsername()}
                username={props.documentDraftSelected.ParentId > 0?  props.documentDraftSelected.Username: Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                templateId={props.documentDraftSelected.Template?.key}
                templateName={props.documentDraftSelected.Template?.name}
                update={props.onCreateUpdateDraft}
                isCreator={props.documentDraftSelected.Template?.CreateDocument}
                isOwner={props.documentDraftSelected.Template?.Owner}
                isCoOwner={props.documentDraftSelected.Template?.CoOwner}
                culture={props.documentDraftSelected.Template?.culture}
                ownerName={props.documentDraftSelected.Template?.ownerFullName}
                ownerUser={props.documentDraftSelected.Template?.ownerUsername}
                templateCategory={props.documentDraftSelected.Template?.category}
                audienceName={props.documentDraftSelected.Template?.audienceName}
                emailMoniker={props.documentDraftSelected.Template?.emailMoniker}
                documentCreation={props.documentDraftSelected.Template?.documentCreation}
                templateEdit={props.documentDraftSelected.Template?.templateEdit}
                defaultDescription={props.documentDraftSelected.Description}
            />
    )
}

export default DraftCustomForm