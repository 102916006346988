import { useState, useEffect } from 'react';

const useDebounce = <T extends unknown>(value: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay)

        return () => clearTimeout(debounceTimer)
    }, [value, delay])

    return debouncedValue
}
export default useDebounce