import React from "react";
import {IColumn} from "@fluentui/react";
import i18n from "../../../../i18n/config"

export const getColumns = (): IColumn[] => {
    const {t} = i18n
    return [
        {
            key: 'column1',
            name: t('label.colName', {ns: 'restrictsubforms'}),
            ariaLabel: t('ariaLabel.colName', {ns: 'restrictsubforms'}),
            fieldName: 'DisplayName',
            minWidth: 150,
            maxWidth: 175,
            isRowHeader: true,
            isCollapsible: false,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <span title={item.DisplayName}>{item.DisplayName}</span>;
            }
        },
        {
            key: 'column2',
            name: t('label.colDescription', {ns: 'restrictsubforms'}),
            ariaLabel: t('ariaLabel.colDescription', {ns: 'restrictsubforms'}),
            fieldName: 'Description',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isCollapsible: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <span title={item.Description}>{item.Description}</span>
            }
        }

    ]

}
