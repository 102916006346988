import React, { useEffect, useState } from 'react';
import { Modal } from '@fluentui/react/lib/Modal';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { useConstCallback, useId } from '@uifabric/react-hooks';
import '../../Custom.css';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { DialogFooter } from "@fluentui/react/lib/Dialog"
import { IMessageBarStyles, MessageBar, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import Helper, { IDocumentOnlyTemplateUpdateRequest } from '../../Helpers/Helper';
import { ILayout } from './CustomTemplate';
import { InputType, TextBoxType } from './Validations';
import { useTranslation } from "react-i18next";
import { useBranding } from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";

const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
        },
    },

};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };


export interface ItemplatesIsSubForm {
    IsSubForm: boolean;
}


export interface ITemplates {

    Id: number;
    AccountId: number;
    TemplateId: string;
    DocumentOnlyAudienceId: string;
    AllowEsign: boolean;
    AllowCheckInOut: boolean;
    eSignProvider: any;
    eSignUserId: any;
    eSignPassword: string;
    eSignIntegrationKey: any,
    eSignURL: string;
    PropertyBag: any,
    FileName: string;
    Category: string;
    Description: string;
    DisplayName: string;
    DisplayNameToolTip: string;
    TemplateContent: string;
    culture: string;
    released: boolean;
    emailMoniker: string;
    AllowDocumentUpdate: boolean;
    AllowNotification: boolean;
    AllowDocX: boolean;
    cultureName: string;
    AllowSendEmail: boolean;
    DocumentCreation: boolean;
    DocumentUpdate: boolean,
    DocumentDelete: boolean;
    DocumentShare: boolean;
    TemplateEdit: boolean;
    TemplateShare: boolean;
    TemplateDelete: boolean;
    DefaultDescription: string;
    ServiceAccountEmail: string;
    UseServiceAccount: boolean;
    UniqueReference: string;
    SupressPreview: boolean;
    DocumentDelivery: string;
    RedirectURL: string;
    ImageInfo: string;
    ExternalPublish: boolean
    ExternalPublishContext: string;
    AccessCodeInstructions: string;
    FormDesignObject?: any
    EnabledCaptcha: boolean
    EnabledSSO: boolean
}

export interface IContentControls {
    Alias: string;
    Tag: string;
    Children: IContentControls[];
}

var classmodal = 'bodyModalMax';
function UploadTemplate(props: any) {
    const { branding } = useBranding()
    const [uploadingFile, setUploadingFile] = useState(false);
    const titleId = useId("Upload a custom Template");
    const [fileName, setFileName] = useState("");
    const [requiredUploadFile, setRequiredUploadFile] = useState(false)
    const [base64File, setBase64File] = useState<string>("");
    const [mQuery, setMQuery] = React.useState<any>({ matches: window.innerHeight > 660 ? true : false, });
    const [templateId, setTemplateId] = useState("");
    const [showSuccess, setShowSuccess] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState("");

    const [showFormBuilder, setShowFormBuilder] = useState(false)
    const [siteSelected, setSiteSelected] = useState("")

    const [owner, setOwner] = useState("")
    const [applying, setApplying] = useState(false);
    const [pageCount, setpageCount] = useState("")
    const errorMessage = "Pattern did not match."
    type ResizeHandle = 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne';
    const { t } = useTranslation(['common', 'uploadtemplate']);

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '30vw',
            height: '45vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        }
    });



    const emptyUser: ITemplates & ItemplatesIsSubForm = {
        AccountId: 0,
        AllowCheckInOut: false,
        AllowEsign: false,
        Category: "",
        Description: "",
        DisplayName: "",
        DisplayNameToolTip: "",
        DocumentOnlyAudienceId: "",
        FileName: "",
        Id: 0,
        PropertyBag: "",
        TemplateContent: "",
        TemplateId: "",
        eSignIntegrationKey: "",
        eSignPassword: "",
        eSignProvider: "",
        eSignURL: "",
        eSignUserId: "",
        culture: "",
        released: false,
        emailMoniker: "",
        AllowDocumentUpdate: false,
        AllowNotification: false,
        AllowDocX: false,
        cultureName: "",
        AllowSendEmail: false,
        DocumentCreation: false,
        DocumentUpdate: false,
        DocumentDelete: false,
        DocumentShare: false,
        TemplateEdit: false,
        TemplateShare: false,
        TemplateDelete: false,
        DefaultDescription: "",
        UseServiceAccount: false,
        ServiceAccountEmail: "",
        UniqueReference: "",
        IsSubForm: false,
        SupressPreview: false,
        DocumentDelivery: "None",
        RedirectURL: "",
        ImageInfo: "",
        ExternalPublish: false,
        ExternalPublishContext: "",
        AccessCodeInstructions: "",
        EnabledCaptcha: false,
        EnabledSSO: false
    };
    const [dataTemplate, setdataTemplate] = useState(emptyUser)
    const [layoutOriginal, setlayoutOriginal] = useState<ILayout[]>([])
    useEffect(() => {
        if (props.TemplateId !== undefined && props.TemplateId !== "" && props.showUploadTemplate === true) {
            console.log(`Go Get The Template`)
            setTemplateId(props.TemplateId)
            getTemplateId(props.TemplateId)

        }
    }, [props.TemplateId, props.showUploadTemplate])

    const getTemplateId = async (templateId: string) => {
        await Helper.getDocumentOnlyTemplateId(templateId, true).then(data => {
            Init(data)
        })
    }

    const Init = useConstCallback((data: any) => {
        if (data !== undefined && data !== null) {
            setdataTemplate(data)
            if (data !== undefined && data.FormDesignObject) {
                setpageCount(data.FormDesignObject.Pages)
                setOwner(data.FormDesignObject.Owner)
                setlayoutOriginal(JSON.parse(data.FormDesignObject.Layout) as ILayout[])
            } else {
                setMessage(t('messages.layoutLoadingError', { ns: 'uploadtemplate' }))
                setShowMessage(true)
                setlayoutOriginal([] as ILayout[])
            }
        }
    })

    const getBase64 = (file: File) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64File(reader.result!.toString().split(',')[1]);
        };
        reader.onloadend = () => {
            setRequiredUploadFile(true)
        }
    }

    async function uploadFile(ev: React.ChangeEvent<HTMLInputElement>) {
        setUploadingFile(true)
        if (ev.target.files != null && ev.target.files.length > 0) {
            var mimeType = ev.target.files![0].type;

            if (mimeType.indexOf("vnd.openxmlformats-officedocument.wordprocessingml.document") >= 0) {
                setRequiredUploadFile(true)
                setShowMessage(false)
                setMessage("")
                var name = ev.target.files![0].name;
                setFileName(name);
                getBase64(ev.target.files![0]);
            }
            else {
                setRequiredUploadFile(false)
                setMessage(t('messages.invalidFile', { ns: 'uploadtemplate' }))
                setShowMessage(true)
                setTimeout(function () {
                    setShowMessage(false)
                    setMessage("")
                }, 5000);
            }
        }
        else {
            setRequiredUploadFile(false)
        }
        setUploadingFile(false)
        ev.target.value = "";
    }

    async function goToBuilder() {
        setRequiredUploadFile(false)
        setApplying(true)
        if (base64File !== "") {
            dataTemplate.TemplateContent = base64File
            const site = await Helper.getSite()!
            const sessionKey = Helper.getSessionKey()
            const accountId = Helper.getDefaultAccount()
            const userName = Helper.getUsername()
            let valBase64 = ""
            if (dataTemplate.PropertyBag != null && dataTemplate.PropertyBag !== "") {
                valBase64 = Buffer.from(dataTemplate.PropertyBag!).toString('base64')
            }

            var request: IDocumentOnlyTemplateUpdateRequest =
            {
                ApiKey: "-1",
                SK: sessionKey,
                UserName: userName,
                AccountIds: accountId,
                Path: site,
                AccountId: accountId,
                Autoprovision: true,
                Base64HTMLString: dataTemplate.TemplateContent,
                TemplateId: templateId,
                audience: dataTemplate.DocumentOnlyAudienceId.toString(),
                allowesign: dataTemplate.AllowEsign,
                allowcheckinout: dataTemplate.AllowCheckInOut,
                category: dataTemplate.Category,
                description: dataTemplate.Description,
                buttontext: dataTemplate.DisplayName,
                tooltip: dataTemplate.DisplayNameToolTip,
                filename: fileName,
                displayName: dataTemplate.DisplayName,
                esignurl: dataTemplate.eSignURL,
                esignintegrationkey: dataTemplate.eSignURL,
                esignuserid: dataTemplate.eSignUserId,
                esignpassword: dataTemplate.eSignPassword,
                propertybag: valBase64,
                esignprovider: dataTemplate.eSignProvider,
                culture: dataTemplate.culture,
                released: dataTemplate.released,
                emailMoniker: dataTemplate.emailMoniker,
                documentUpdate: dataTemplate.AllowDocumentUpdate,
                documentNotification: dataTemplate.AllowNotification,
                allowDocx: dataTemplate.AllowDocX,
                cultureName: dataTemplate.cultureName,
                allowSendMail: dataTemplate.AllowSendEmail,
                documentCreation: dataTemplate.DocumentCreation,
                documentUpdateNotification: dataTemplate.DocumentUpdate,
                documentDelete: dataTemplate.DocumentDelete,
                documentShare: dataTemplate.DocumentShare,
                templateEdit: dataTemplate.TemplateEdit,
                templateShare: dataTemplate.TemplateShare,
                templateDelete: dataTemplate.TemplateDelete,
                defaultDescription: dataTemplate.DefaultDescription,
                serviceAccountEmail: dataTemplate.ServiceAccountEmail,
                useServiceAccount: dataTemplate.UseServiceAccount,
                uniqueReference: dataTemplate.UniqueReference,
                attachment: JSON.stringify(dataTemplate.PropertyBag.attachment),
                subform: dataTemplate.IsSubForm,
                docusignTagsRoles: [],
                supressPreview: dataTemplate.SupressPreview,
                documentDelivery: dataTemplate.DocumentDelivery,
                redirectURL: dataTemplate.RedirectURL,
                imageInfo: dataTemplate.ImageInfo,
                externalPublish: dataTemplate.ExternalPublish,
                externalPublishContext: dataTemplate.ExternalPublishContext,
                accessCodeInstructions: dataTemplate.AccessCodeInstructions,
                EnabledCaptcha: dataTemplate.EnabledCaptcha,
                EnabledSSO: dataTemplate.EnabledSSO,
            }
            await Helper.UploadTemplate(request).then(data => {
                if (data !== undefined && data.errors === undefined) {
                    try {
                        if (data.Error == null) {
                            setShowSuccess(true)
                            setMessage(t('messages.fileUpdated', { ns: 'uploadtemplate' }))
                            if (data.contentControls !== null && data.contentControls.length > 0) {
                                if (props.templateEdit) {
                                    Helper.SendNotification({
                                        ActionByEmailAddress: userName,
                                        ActionDate: Helper.getLocateDate(Date.now.toString(), 2),
                                        AudienceName: props.audienceName,
                                        AudienceOwnerEmailAddress: props.ownerUser,
                                        RecordDescription: props.templateName,
                                        TargetOfActionEmailAddress: "",
                                        TemplateCategory: props.templateCategory,
                                        TemplateName: props.templateName,
                                        Type: "5",
                                        Culture: props.culture,
                                        UserName: userName,
                                        SK: sessionKey,
                                        AccountId: accountId,
                                        Path: site,
                                        Autoprovision: true,
                                        ApiKey: "-1",
                                        EmailMoniker: props.emailMoniker,
                                        TemplateId: props.TemplateId.toString()
                                    })
                                }
                                generateLayout(data.contentControls, dataTemplate.FormDesignObject);
                            }
                        }
                        else {
                            setShowMessage(true)
                            setMessage(data.Error)
                            setApplying(false)
                            setTimeout(function () {
                                //hideUpload();                           
                            }, 2000);
                        }
                    }
                    catch (error: any) {
                        setShowMessage(true)
                        setMessage(error)
                        setApplying(false)
                        setTimeout(function () {
                        }, 2000);
                    }
                } else {
                    setShowMessage(true)
                    setMessage(data.title)
                    setApplying(false)
                    setTimeout(function () {
                    }, 2000);
                }
            })
        }
    }

    async function generateLayout(contentControls: IContentControls[], formDesignObject: any) {
        var toolboxArray: ILayout[] = [];
        if (contentControls !== undefined) {
            for (let i = 0; i < contentControls.length; i++) {
                let children: ILayout[] = []
                if (contentControls[i].Children !== null) {
                    contentControls[i].Children.forEach((c: IContentControls) => {
                        children.push({
                            Id: c.Tag,
                            Label: c.Alias,
                            Children: [],
                            ListId: 0,
                            ItemList: "",
                            Layout: {
                                x: 0,
                                y: 0,
                                w: 1,
                                h: 1,
                                i: c.Tag,
                                static: false
                            },
                            Page: 0,
                            TextParagraph: "",
                            TabIndex: 0,
                            AdminOnly: false,
                            TextBoxType: TextBoxType.Normal,
                            Type: InputType.Textbox,
                            Placeholder: '',
                            Tooltip: "",
                            TooltipUrl: "",
                            FontColor: "#000000",
                            FontSize: 12,
                            Align: "left",
                            ShowScrollbar: true,
                            Validations: {
                                MaxLength: 50,
                                Mask: "",
                                Required: false,
                                ReadOnly: false,
                                Regex: "",
                                DefaultValue: "",
                                ErrorMessage: errorMessage
                            },
                            IsBold: false,
                            IsItalic: false,
                            IsUnderlined: false
                        })
                    });
                }

                var layout: ILayout = {
                    Id: contentControls[i].Tag,
                    Label: contentControls[i].Tag,
                    Type: InputType.Textbox,
                    TextBoxType: TextBoxType.Normal,
                    TabIndex: 0,
                    ItemList: "",
                    ListId: 0,
                    Validations: {
                        MaxLength: 50,
                        Mask: "",
                        Required: false,
                        ReadOnly: false,
                        Regex: "",
                        DefaultValue: "",
                        ErrorMessage: errorMessage
                    },
                    Layout: {
                        x: 0,
                        y: 0,
                        w: 1,
                        h: 1,
                        i: contentControls[i].Tag,
                        static: false
                    },
                    Page: 0,
                    TextParagraph: "",
                    Children: children,
                    AdminOnly: false,
                    Placeholder: '',
                    Tooltip: "",
                    TooltipUrl: "",
                    FontColor: "#000000",
                    FontSize: 12,
                    Align: "left",
                    ShowScrollbar: true,
                    IsBold: false,
                    IsItalic: false,
                    IsUnderlined: false
                };
                toolboxArray.push(layout)
            }
            let _layoutOriginal: ILayout[] = [...layoutOriginal]
            if (_layoutOriginal !== undefined) {
                for (let element of toolboxArray) {
                    let indexItem = _layoutOriginal.findIndex((x: ILayout) => x.Id.toLowerCase() === element.Id.toLowerCase())
                    if (indexItem > -1) {
                        const item = _layoutOriginal[indexItem]

                        _layoutOriginal[indexItem] = { ...item, Id: element.Id, Layout: { ...item.Layout, i: element.Id } }
                        if (element.Children.length > 0) {
                            const children: ILayout[] = element.Children.map((_item) => {
                                const originalChild = item?.Children.find(child => child.Id === _item.Id)
                                return originalChild ?? _item
                            })
                            _layoutOriginal[indexItem] = { ...item, Children: children }
                        }
                    } else {
                        _layoutOriginal = [..._layoutOriginal, element]
                    }

                }
                sessionStorage.setItem("layout", JSON.stringify([..._layoutOriginal]))
                saveLayout(contentControls, formDesignObject)
            }
        }
    }

    async function saveLayout(newContentControls: any, formDesignObject: any) {
        const site = await Helper.getSite()!
        const sessionKey = Helper.getSessionKey()
        const accountId = Helper.getDefaultAccount()
        const userName = Helper.getUsername()
        let templateLayout: any = {
            TemplateId: templateId,
            Owner: owner,
            Layout: sessionStorage.getItem("layout"),
            Pages: pageCount,
            Released: false,

        };
        if (formDesignObject.FormRules) {
            templateLayout.FormRules = formDesignObject.FormRules
        }

        const request = {
            TemplateId: templateId,
            User: "",
            Template: templateLayout
        }

        let { data }: any = await HttpRequest.postWithSession('statemanagement', request)

        if (data.Status === "Success") {
            setShowFormBuilder(true)
            props.reloadApp(site)
            props.getContentControls(newContentControls)
            props.toggleFormBuilder(true)
            props.setTemplateName(dataTemplate.DisplayName)
            props.getTemplateId(templateId);
            props.IsUpdate(true)
            setSiteSelected(site)
            setMessage(t('messages.layoutSavedSuccess', { ns: 'uploadtemplate' }))
            setShowSuccess(true)
            setApplying(false)
            setTimeout(function () {
                setApplying(false)
                hideUpload();
            }, 1000);
        }
        else {
            setMessage(t('messages.layoutSavedError', { ns: 'uploadtemplate' }))
            setShowMessage(true)
            setTimeout(function () {
                setShowMessage(false)
            }, 2000);
        }
    }

    if (mQuery.matches) {
        classmodal = 'bodyModalMin'
    }
    else {
        classmodal = 'bodyModalMax';
    }
    const hideUpload = () => {
        setMessage("")
        setShowMessage(false)
        setShowSuccess(false)
        setFileName("")
        setApplying(false)
        setpageCount("1")
        props.hideUploadTemplate()
    }

    return (
        <Modal
            isOpen={props.showUploadTemplate}
            isBlocking={true}>
            <div className={contentStyles.header}>
                <span id={titleId}>{props.modalTitle}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                    onClick={() => {
                        hideUpload()
                    }}
                    title={t('Common.Button.Close', { ns: 'common' })}
                />
            </div>
            <div className={classmodal}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                            {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                            <br />
                            <span>
                                {t('text.p1', { ns: 'uploadtemplate', Name: props.templateName, interpolation: { 'escapeValue': false } })}
                                <ul>
                                    <li>{t('text.p2', { ns: 'uploadtemplate' })}</li>
                                    <li>{t('text.p3', { ns: 'uploadtemplate' })}</li>
                                    <li>{t('text.p4', { ns: 'uploadtemplate' })}</li>
                                </ul>
                            </span>
                        </div>
                    </div>
                    {!applying ? <>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                <label htmlFor="file"><div className="divHover divToolBoxItem" style={{ color: branding.theme.palette.white, backgroundColor: branding.theme.palette.themePrimary }}>{t('label.file', { ns: 'uploadtemplate' })}</div></label>
                                <input className="fileButton" id="file" type="file" name="file" accept=".docx" onChange={uploadFile} placeholder={t('placeholder.file', { ns: 'uploadtemplate' })} />
                            </div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                <TextField autoFocus value={fileName} title={t('title.file', { ns: 'uploadtemplate' })} readOnly />
                            </div>
                        </div> </>
                        : <Spinner size={SpinnerSize.medium} label={t('text.spinnerUploading', { ns: 'common' })} ariaLive="assertive" />
                    }
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton disabled={!requiredUploadFile} onClick={goToBuilder} text={t('Common.Button.Upload', { ns: 'common' })} title={t('title.uploadButton', { ns: 'uploadtemplate' })} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default UploadTemplate