import React, { useEffect, useState } from 'react';
import { IconButton, PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { FontIcon, IIconProps } from '@fluentui/react/lib/Icon';
import '../../Custom.css'
import { Text, ChoiceGroup, DatePicker, IChoiceGroupOption, IMessageBarStyles, Label, MessageBar, TooltipHost } from '@fluentui/react';
import Helper, { IBaseRequest, IBulkDownloadRequest, IMyListOption, ITemplateLayoutRequest } from '../../Helpers/Helper';
import { ReactNotificationOptions, store } from 'react-notifications-component';
import { ActionMeta, SingleValue } from 'react-select';
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import { formatFromUTC, setGlobalDateLocale } from '../../Helpers/DateUtils';
import { useDialog } from '../../hooks/useDialog';
import { useModal } from '../../hooks/useModal';
import { Sizes } from '../../share/CustomModal';
import useAuth from '../../hooks/useAuth';
import {useBranding} from "../../hooks/useBranding";
import UserService, {IGetuserprofileRequest} from "../../services/User";

const theme = getTheme();

const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};


const cancelIcon: IIconProps = { iconName: 'Cancel' };

const downloadOptions: IChoiceGroupOption[] = [
    { key: 'xml', text: 'xml', styles: { field: { paddingRight: '15px' } } },
    { key: 'xlsx', text: 'xlsx', styles: { field: { paddingRight: '15px' } } },
    { key: 'flat', text: 'package', styles: { field: { paddingRight: '15px' } } }
];

type DownloadModalOptions = {
    title: string
    message: string
}
function BulkDownload(props: any) {
    const { branding } = useBranding()
    const [templateList, setTemplateList] = useState<IMyListOption[]>([])
    const [campaignList, setCampaignList] = useState<IMyListOption[]>([])
    const [selectedTemplate, setSelectedTemplate] = useState<IMyListOption | null>(null)
    const [selectedCampaign, setSelectedCampaign] = useState<IMyListOption | null>(null)
    const [selectedDownloadType, setSelectedDownloadType] = useState<IChoiceGroupOption>({ key: "xlsx", text: "xlsx" })
    const [dateProcessedFrom, setProcessedFrom] = useState<Date | null>();
    const [dateProcessedTo, setProcessedTo] = useState<Date | null>();
    const [dateCreatedFrom, setCreatedFrom] = useState<Date | null>();
    const [dateCreatedTo, setCreatedTo] = useState<Date | null>();
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState("");

    const { t } = useTranslation(['common', 'bulkdownload', 'workspace']);

    const [dialogMessage, setDialogMessage] = useState("")
    const [profile, setProfile] = useState<any | null>()

    const [modalDownloadOptions, setModalDownloadOptions] = useState<DownloadModalOptions>({} as DownloadModalOptions)
    const { user } = useAuth()
    const dialog = useDialog()
    const resultDialog = useModal()

    const customDropdownStyles = {
        control: (styles: any, state: any) => ({
            ...styles,
            borderRadius: 1,
            borderColor: state.isFocused ? branding.theme.semanticColors.inputBorderHovered : branding.theme.semanticColors.inputBorder,
            minHeight: 32,
        }),
        option: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? branding.theme.semanticColors.listItemBackgroundChecked
                    :  undefined,
                color:  isSelected
                    ? branding.theme.palette.black
                    :  branding.theme.palette.black
            }
        },
        valueContainer: (styles: any) => ({
            ...styles,
            paddingTop: 0,
        }),
        menu: (styles: any) => ({
            ...styles,
            marginTop: 0,
        }),
        menuList: (styles: any) => ({
            ...styles,
            padding: 0,
        }),
    };


    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
                class: 'ms-Icon ms-Icon--Mail'
            }
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        bodyDelete: {
            width: '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        body: {
            width: '40vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyEdit: {
            width: '50vw',
            height: '60vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyVWOverride: {
            width: '90%',

            flex: '4 4 auto',
            padding: '0 0px 0px 18px',
        },
        bodyEditVWOverride: {
            width: '40vw',

            flex: '4 4 auto',
            padding: '0 18px 0px 18px',
        },
        User: {
            width: '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    function showNotification(Nmessage: string, Ntype: string) {
        var auxType: ReactNotificationOptions;
        switch (Ntype) {
            case "success":
                store.addNotification({
                    title: t('title.success', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "warning":
                store.addNotification({
                    title: t('title.warning', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "error":
                store.addNotification({
                    title: t('title.error', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "info":
                store.addNotification({
                    title: t('title.information', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "successLeft":
                store.addNotification({
                    title: t('title.success', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "errorLeft":
                store.addNotification({
                    title: t('title.error', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            default:
                break;
        }
    }

    useEffect(() => {

        setGlobalDateLocale(navigator.language)
        if (props.fetchTemplates)
            loadTemplates()

        getUserProfile()

    }, [props.fetchTemplates])

    const getUserProfile = async () => {

        const request: IGetuserprofileRequest = {
            UserName: Helper.getUsername(),
            Profile: Helper.getUsername()
        }
        
        await UserService.GetUserProfile(request)
            .then((data) => {
                setProfile(data)
            })
            .catch()
            .finally()

    }

    const loadTemplates = async () => {
        const request: ITemplateLayoutRequest = {
            AccountId: Helper.getDefaultAccount(),
            AccountIds: Helper.getDefaultAccount(),
            UserName: '',
            SessionKey: '',
            TemplateId: '',
            DocumentId: ''
        }
        await Helper.getDocumentOnlyTemplate(request).then((data: any) => {
            var list: IMyListOption[] = []
            data.forEach((cat: any) => {
                cat.links!.forEach((tem: any) => {
                    if (tem.Owner || tem.CoOwner) {
                        let tempItem = list.find(item => item.label == `${cat.name} - ${tem.name}`)
                        tempItem == undefined ?
                            list.push({ value: tem.key.toString(), label: `${cat.name} - ${tem.name}` }) :
                            list.push({ value: tem.key.toString(), label: `${cat.name} - ${tem.name} (${tem.key.toString()})` })
                    }
                })
            })
            setTemplateList(list.sort((a, b) => {
                return a.label.toLocaleLowerCase() < b.label.toLowerCase() ? -1 : 1
            }));
        })
    }

    const closeModal = () => {
        setTemplateList([])
        setSelectedTemplate(null)
        setSelectedDownloadType({ key: "xlsx", text: "xlsx" })
        setProcessedFrom(undefined);
        setProcessedTo(undefined);
        setCreatedFrom(undefined);
        setCreatedTo(undefined);
        setShowErrorMessage(false)
        setMessage("");

        props.hideModal()
    }

    const cleanCreatedFrom = React.useCallback((): void => {
        setCreatedFrom(undefined);
    }, []);

    const cleanCreatedTo = React.useCallback((): void => {
        setCreatedTo(undefined);
    }, []);

    const cleanProcessedFrom = React.useCallback((): void => {
        setProcessedFrom(undefined);
    }, []);

    const cleanProcessedTo = React.useCallback((): void => {
        setProcessedTo(undefined);
    }, []);

    const onTemplateChange = async (newValue: SingleValue<IMyListOption>, actionMeta: ActionMeta<IMyListOption>) => {
        setSelectedTemplate(newValue)
        loadCampaignsByTemplateId(newValue!.value)
    };

    const loadCampaignsByTemplateId = async (templateId: string) => {
        const response = await Helper.getCampaignListByTemplateId(templateId, 0, 0)

        var arr: IMyListOption[] = []
        response.sort((a: any, b: any) => {
            return a.Name < b.Name ? -1 : 1;
        }).sort((a: any, b: any) => {
            const dateA = new Date(a.DateCreated)
            const dateB = new Date(b.DateCreated)

            if (dateA.toString() !== 'Invalid Date' && dateB.toString() !== 'Invalid Date') {
                return dateA > dateB ? -1 : 1
            }
            else {
                return -1
            }

        }).forEach((e: any) => {
            arr.push({
                value: e.Id.toString(),
                label: e.Name + "(" + Helper.getLocateDate(e.DateCreated) + ")"
            })
        });

        setCampaignList(arr)
        setSelectedCampaign(null)
    }

    const onCampaignChange = async (newValue: SingleValue<IMyListOption>, actionMeta: ActionMeta<IMyListOption>) => {
        if (newValue !== null) {
            setSelectedCampaign(newValue)
        } else {
            cleanCampaign()
        }
    }

    const onFormatDate = (date?: Date): string => {
        const locale = !!sessionStorage.getItem("language") ? sessionStorage.getItem("language")!.toString() : 'en'
        return date !== null ? formatFromUTC(date, 'LL', locale) : null;
        //return date !== null ? Helper.getLocateDate(date!.toLocaleDateString("en-US"), 1) : "";

    };

    const submitForm = async (operation: string) => {
        setShowErrorMessage(false)
        setMessage("")
        const requestEmailFile = async () => {
            const req: IBulkDownloadRequest = {
                DateProcessedFrom: dateProcessedFrom!,
                DateProcessedTo: dateProcessedTo!,
                DateCreatedFrom: dateCreatedFrom!,
                DateCreatedTo: dateCreatedTo!,
                TemplateId: parseInt(`${selectedTemplate?.value}`),
                Operation: operation,
                CampaignId: parseInt(`${selectedCampaign?.value}`)
            }
            Helper.bulkDownload(req).then(({ data }: any) => {
                dialog.close()
                if (data.Success == false) {
                    setShowErrorMessage(true)
                    if (data.ExceptionMessage === 'Unknown operation') {
                        setMessage('No records found!')
                        dialog.close()
                    } else {
                        setMessage(data.ExceptionMessage)
                        showNotification(t('text.errorMessage1', { ns: 'common', Error: data.ExceptionMessage }), "error")
                    }
                    dialog.close()
                    return
                }
                if (data.Success && data.ExceptionMessage.length > 0) {
                    setShowErrorMessage(true)
                    setMessage(data.ExceptionMessage)
                    dialog.close()
                    return
                }
                setShowErrorMessage(false)
                setMessage("")
                if (operation === "delete") {
                    setDialogMessage(data.Result)
                } else {
                    setDialogMessage(t('messages.requestSubmitted', { ns: 'bulkdownload' }))
                }
                resultDialog.open()
            })
        }
        requestEmailFile()
    }

    const handleDownloadModal = (option?: string) => {
        if (dateCreatedFrom !== undefined && dateCreatedTo === undefined) {
            setShowErrorMessage(true)
            return setMessage(t('text.creationDateTo', { ns: 'bulkdownload' }))
        }
        if (dateCreatedTo !== undefined && dateCreatedFrom === undefined) {
            setShowErrorMessage(true)
            return setMessage(t('text.creationDateFrom', { ns: 'bulkdownload' }))
        }
        if (dateProcessedFrom !== undefined && dateProcessedTo === undefined) {
            setShowErrorMessage(true)
            return setMessage(t('text.creationProcessingDateTo', { ns: 'bulkdownload' }))
        }
        if (dateProcessedTo !== undefined && dateProcessedFrom === undefined) {
            setShowErrorMessage(true)
            return setMessage(t('text.creationProcessingDateFrom', { ns: 'bulkdownload' }))
        }
        const _option = option === 'delete' ? 'delete' : selectedDownloadType.key
        const callback = () => submitForm(_option)
        switch (_option) {
            case 'flat':
                setModalDownloadOptions({
                    title: t('title.downloadPackage', { ns: 'bulkdownload' }),
                    message: t('messages.downloadPackage', { ns: 'bulkdownload', User: user?.email })
                })
                break
            case 'xlsx':
                setModalDownloadOptions({
                    title: t('title.downloadExcel', { ns: 'bulkdownload' }),
                    message: t('messages.downloadExcel', { ns: 'bulkdownload', User: user?.email })
                })
                break
            case 'xml':
                setModalDownloadOptions({
                    title: t('title.downloadXMl', { ns: 'bulkdownload' }),
                    message: t('messages.downloadXMl', { ns: 'bulkdownload', User: user?.email })
                })
                break
            case 'delete':
                setModalDownloadOptions({
                    title: t('title.deleteDocuments', { ns: 'bulkdownload' }),
                    message: t('messages.delete', { ns: 'bulkdownload' })
                })
                break
        }

        dialog.open({ confirm: () => callback })

    }
    function onChangeDownloadType(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void {
        setSelectedDownloadType(option!)
    }

    const cleanCampaign = () => {
        setSelectedCampaign(null)
    }

    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <Text variant={"xLarge"} styles={{root: {color: branding.theme.palette.black}}}>{t('title.title', { ns: 'bulkdownload' })}</Text><br />
                        <div>{t('text.title', { ns: 'bulkdownload' })}</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <Text variant={"large"} styles={{root: {color: branding.theme.palette.black}}}>{t('title.title', { ns: 'bulkdownload' })}</Text>
                    </div>
                </div>
                : null
            }

            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className={classNames.bodyVWOverride}>
                        {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                    </div>
                </div>
            </div>

            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className={classNames.bodyVWOverride}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12">
                                    <Label>{t('label.application', { ns: 'bulkdownload' })}</Label>
                                    <TooltipHost content={t('tooltip.application', { ns: 'bulkdownload' })}>
                                        <Select
                                            className="basic-single"
                                            id="BulkDownloadApplication"
                                            aria-label={t('label.application', { ns: 'bulkdownload' })}
                                            defaultValue={selectedTemplate}
                                            options={templateList}
                                            onChange={onTemplateChange}
                                            placeholder={t('placeholder.application', { ns: 'bulkdownload' })}
                                            isSearchable={true}
                                            styles={customDropdownStyles}
                                            components={{
                                                IndicatorSeparator: () => null,
                                                DropdownIndicator: () => (
                                                    <FontIcon
                                                        iconName="ChevronDown"
                                                        style={{ paddingRight: "7px", fontSize: "12px" }}
                                                    />
                                                ),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: branding.theme.palette.themePrimary,
                                                },
                                            })}
                                        />
                                    </TooltipHost>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12">
                                    <Label>{t('label.campaign', { ns: 'bulkdownload' })}</Label>
                                    <TooltipHost content={t('tooltip.campaign', { ns: 'bulkdownload' })}>
                                        <Select
                                            className="basic-single"
                                            id="BulkDownloadCampaign"
                                            aria-label={t('label.campaign', { ns: 'bulkdownload' })}
                                            defaultValue={selectedCampaign}
                                            value={selectedCampaign}
                                            options={campaignList}
                                            onChange={onCampaignChange}
                                            placeholder=""
                                            isClearable={true}
                                            isSearchable={true}
                                            isDisabled={campaignList.length === 0}
                                            styles={customDropdownStyles}
                                            components={{
                                                IndicatorSeparator: () => null,
                                                DropdownIndicator: () => (
                                                    <FontIcon
                                                        iconName="ChevronDown"
                                                        style={{ paddingRight: "7px", fontSize: "12px" }}
                                                    />
                                                ),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: branding.theme.palette.themePrimary,
                                                },
                                            })}
                                        />
                                    </TooltipHost>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12">
                                    <ChoiceGroup defaultSelectedKey="xlsx" selectedKey={selectedDownloadType.key} required label={t('label.download', { ns: 'bulkdownload' })} styles={{ flexContainer: { display: "flex" } }} options={downloadOptions} onChange={onChangeDownloadType} />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm5">
                                    <DatePicker styles={{root: {color: branding.theme.palette.black}, textField: {color: branding.theme.palette.black}, icon: {color: branding.theme.palette.themePrimary},  wrapper: {color: branding.theme.palette.black}}} strings={Helper.getLocateCalendar()} title={t('text.titleCreationDateTo', { ns: 'bulkdownload' })} isMonthPickerVisible={true} id="from" placeholder={t('placeholder.date', { ns: 'bulkdownload' })} ariaLabel={t('placeholder.date', { ns: 'bulkdownload' })} label={t('label.creationDateFrom', { ns: 'bulkdownload' })} formatDate={onFormatDate} value={dateCreatedFrom!} onSelectDate={setCreatedFrom as (date: Date | null | undefined) => void} />
                                </div>
                                <div className="ms-Grid-col ms-sm1">
                                    <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanCreatedFrom} title={t('title.clear', { ns: 'bulkdownload' })} />
                                </div>
                                <div className="ms-Grid-col ms-sm5">
                                    <DatePicker strings={Helper.getLocateCalendar()} title={t('text.titleCreationDateFrom', { ns: 'bulkdownload' })} isMonthPickerVisible={true} id="from" placeholder={t('placeholder.date', { ns: 'bulkdownload' })} ariaLabel={t('placeholder.date', { ns: 'bulkdownload' })} label={t('label.To', { ns: 'bulkdownload' })} formatDate={onFormatDate} value={dateCreatedTo!} onSelectDate={setCreatedTo as (date: Date | null | undefined) => void} />
                                </div>
                                <div className="ms-Grid-col ms-sm1">
                                    <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanCreatedTo} title={t('title.clear', { ns: 'bulkdownload' })} />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm5">
                                    <DatePicker strings={Helper.getLocateCalendar()} title={t('text.titleCreationProcessingDateTo', { ns: 'bulkdownload' })} isMonthPickerVisible={true} id="to" placeholder={t('placeholder.date', { ns: 'bulkdownload' })} ariaLabel={t('placeholder.date', { ns: 'bulkdownload' })} label={t('label.updatingDateFrom', { ns: 'bulkdownload' })} formatDate={onFormatDate} value={dateProcessedFrom!} onSelectDate={setProcessedFrom as (date: Date | null | undefined) => void} />
                                </div>
                                <div className="ms-Grid-col ms-sm1">
                                    <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanProcessedFrom} title={t('title.clear', { ns: 'bulkdownload' })} />
                                </div>
                                <div className="ms-Grid-col ms-sm5">
                                    <DatePicker strings={Helper.getLocateCalendar()} title={t('text.titleCreationProcessingDateFrom', { ns: 'bulkdownload' })} isMonthPickerVisible={true} id="to" placeholder={t('placeholder.date', { ns: 'bulkdownload' })} ariaLabel={t('placeholder.date', { ns: 'bulkdownload' })} label={t('label.To', { ns: 'bulkdownload' })} formatDate={onFormatDate} value={dateProcessedTo!} onSelectDate={setProcessedTo as (date: Date | null | undefined) => void} />
                                </div>
                                <div className="ms-Grid-col ms-sm1">
                                    <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel={t('Common.Button.Close', { ns: 'common' })} onClick={cleanProcessedTo} title={t('title.clear', { ns: 'bulkdownload' })} />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <PrimaryButton disabled={selectedTemplate == undefined ? true : false}
                                            title={t('title.title', { ns: 'bulkdownload' })} text={t('text.download', { ns: 'bulkdownload' })} onClick={() => handleDownloadModal()} />
                                        {//profile?.Billing ?
                                            <PrimaryButton disabled={selectedTemplate == undefined ? true : false} title={t('title.deleteButton', { ns: 'bulkdownload' })}
                                                text={t('text.delete', { ns: 'bulkdownload' })} onClick={() => handleDownloadModal('delete')} />//:null
                                        }

                                        {props.fromModal ?
                                            <DefaultButton title={t('Common.Button.Close', { ns: 'common' })} onClick={closeModal} text={t('Common.Button.Close', { ns: 'common' })} /> : null}
                                    </DialogFooter>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <dialog.Dialog title={modalDownloadOptions.title} titleId="dialogMessage"
                actionText={t('Common.Button.Yes', { ns: 'common' })}
                closeText={t('Common.Button.No', { ns: 'common' })} >
                <>{modalDownloadOptions.message}</>
            </dialog.Dialog>

            <resultDialog.Modal titleId="resultDialog" title={modalDownloadOptions.title} size={Sizes.xs}>
                <>
                    {dialogMessage}
                    <DialogFooter>
                        <PrimaryButton onClick={() => { resultDialog.close() }} text={t('Common.Button.Accept', { ns: 'common' })} />
                    </DialogFooter>
                </>
            </resultDialog.Modal>
        </>
    )
}

export default BulkDownload