import React, { useEffect } from 'react';
import { IMessageBarStyles, MessageBar, MessageBarButton } from '@fluentui/react';
import '../../web.css'
import Helper from '../../Helpers/Helper';
import {useTranslation} from "react-i18next";

const messageBarDisclaimerStyles: IMessageBarStyles = {
  root: {
    backgroundColor: "rgb(196 224 241 / 76%)",

  }
};
function Disclaimer(props: any) {
  useEffect(() => { }, [])
  const {t} = useTranslation(['common','workspace']);


  const saveDisclaimer = async () => {
    var expires = "";
    var date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();

    let domain = new URL(window.location.origin) 
    document.cookie = "KimDisclaimer" + Helper.getUsername() + "= " + expires + "; path=/; domain="+  domain.hostname.replace('www.','') +"; SameSite=None; Secure";
    props.setDisplayDisclaimer(false)
  }

  return (
    <div className='ms-Grid-row '>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
          <MessageBar
            dismissButtonAriaLabel="Close"
            styles={messageBarDisclaimerStyles}
            actions={<div><MessageBarButton onClick={saveDisclaimer}>{t('Common.Button.Accept', { ns: 'common'})}</MessageBarButton> </div>}
            isMultiline={false}>
            <strong>{t('text.disclaimer', { ns: 'workspace'})}.</strong> {t('text.disclaimerMsg', { ns: 'workspace'})}
          </MessageBar>
        </div>
      </div>
    </div>
  );
}
export default Disclaimer