import React from "react";
import {IColumn, IComboBoxOption, IconButton, IDropdownOption, StackItem} from "@fluentui/react";
import i18n from "../../i18n/config"
import {ToolbarConfig} from "react-rte";
import {ILayout} from "../CustomTemplates/CustomTemplate";
import {DropdownMenuItemType} from "@fluentui/react/lib/Dropdown";
import {IButtonStyles} from "@fluentui/react/lib/Button";
import {IContextualMenuProps} from "office-ui-fabric-react";
import {Text} from 'office-ui-fabric-react/lib/Text';
import {formatFromUTC, formatSimpleDate} from "../../Helpers/DateUtils";
import {ResponseGetContextRenewals} from "./RenewalsAndAlerts.service";
import {contentStyles} from "./Style";
import {isMobile} from "react-device-detect";
import {IDocument} from "../../App";
import Helper from "../../Helpers/Helper";
import {Theme} from "@fluentui/react-theme-provider";
import {InputType} from "../CustomTemplates/Validations";

/**
 * Email Body RTE Toolbar config
 */
export const toolbarConfigEmailSubject: ToolbarConfig = {
    BLOCK_TYPE_BUTTONS: [], BLOCK_TYPE_DROPDOWN: [], INLINE_STYLE_BUTTONS: [],
    display: []
}

/**
 * Email Body RTE Toolbar config
 */
export const toolbarConfigEmailBody: ToolbarConfig = {
    display: ["INLINE_STYLE_BUTTONS", 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'},
        {label: 'Strikethrough', style: 'STRIKETHROUGH'},
        {label: 'blockquote', style: 'blockquote'},
    ],
    BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
    ],
    BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
    ],
}

/**
 * Set default options for the two Content Control lists
 * @param contentControls
 * @param layout
 */
export const setDefaultOptions = (contentControls: IDropdownOption[], layout: ILayout[]): IDropdownOption[] => {
    const {t} = i18n


    contentControls.push({
        key: `headerRenewals`,
        text: t('text.optionsRenewalsInformation', {ns: 'renewalsalerts'}),
        itemType: DropdownMenuItemType.Header
    })
    contentControls.push({
        key: `[#RenewalName]`,
        text: t('text.optionsName', {ns: 'common', Name: "[#RenewalName]"}),
    })
    contentControls.push({
        key: `[#RenewalDescription]`,
        text: t('text.optionsDescription', {ns: 'common', Name: "[#RenewalDescription]"}),
    })
    contentControls.push({
        key: `[#RenewalMonths]`,
        text: t('text.optionsMonths', {ns: 'renewalsalerts', Name: "[#RenewalMonths]"}),
    })
    contentControls.push({
        key: `[#NotifyTriggerDaysBeforeRenewal]`,
        text: t('text.optionsDays', {ns: 'renewalsalerts', Name: "[#NotifyTriggerDaysBeforeRenewal]"}),
    })
    contentControls.push({
        key: `[#NotifyTriggerFrequency]`,
        text: t('text.optionsFrecuency', {ns: 'renewalsalerts', Name: "[#NotifyTriggerFrequency]"}),
    })


    contentControls.push({
        key: `headerUser`,
        text: t('text.optionsUserInformation', {ns: 'common'}),
        itemType: DropdownMenuItemType.Header
    })


    contentControls.push({
        key: `[#RecordLink]`,
        text: t('text.optionsRecordLink', {ns: 'common', Name: "[#RecordLink]"})
    })
    
    contentControls.push({
        key: `[#Description]`,
        text: t('text.optionsRecordDescription', {ns: 'common', Name: "[#Description]"})
    })
    contentControls.push({
        key: `[#Versions]`,
        text: t('text.optionsRecordVersion', {ns: 'common', Name: "[#Versions]"})
    })
    contentControls.push({
        key: `[#FullName]`,
        text: t('text.optionsRecordCreatorFullname', {ns: 'common', Name: "[#FullName]"})
    })
    contentControls.push({
        key: `[#UserName]`,
        text: t('text.optionsRecordCreatorUsername', {ns: 'common', Name: "[#UserName]"})
    })
    contentControls.push({
        key: `[#ApplicationName]`,
        text: t('text.optionsApplicationName', {ns: 'common', Name: "[#ApplicationName]"})
    })
    contentControls.push({
        key: `[#ApplicationCategory]`,
        text: t('text.optionsApplicationCategory', {ns: 'common', Name: "[#ApplicationCategory]"})
    })
    contentControls.push({
        key: `[#AudienceName]`,
        text: t('text.optionsApplicationAudiencename', {ns: 'common', Name: "[#AudienceName]"})
    })


    if (layout.length !== 0) {
        layout.sort((a, b) => a.Label.localeCompare(b.Label))

        contentControls.push({
            key: `headerCC`,
            text: t('text.optionsTemplateContentControls', {ns: 'common'}),
            itemType: DropdownMenuItemType.Header
        })

        layout.map((item) => {
            if (item.Type !== InputType.GridList && item.Type !== InputType.Paragraph && item.Type !== InputType.SectionHeader && item.Type !== InputType.LineBreak && item.Type !== InputType.HelpLink) {
                contentControls.push({text: `${item.Label} - [$${item.Id}]`, key: `[$${item.Id}]`});
            }        })
    }
    return contentControls
}

/**
 * Calculate Years and months from Total Months
 * @param _months
 */
export const calculateYears = (_months: number): string => {
    const {t} = i18n
    let yearsAndRemainder: number[] = [0, 0]
    if (_months >= 12) {
        yearsAndRemainder[0] = Math.floor(_months / 12)
        yearsAndRemainder[1] = _months % 12
    }
    if (yearsAndRemainder[0] === 0) return t('title.months', {count: _months, ns: 'renewalsalerts'})
    else {
        if (yearsAndRemainder[1] === 0) return t('title.monthsWithCalculationNoMonths', {
            count: _months,
            Years: yearsAndRemainder[0],
            ns: 'renewalsalerts'
        })
        else
            return t('title.monthsWithCalculation', {
                count: _months,
                Years: yearsAndRemainder[0],
                Months: yearsAndRemainder[1],
                ns: 'renewalsalerts'
            })
    }
}

/**
 * Calculate Years and months from Total Months
 * @param _days
 */
export const calculateYearsMonths = (_days: number): string => {
    const {t} = i18n
    let result: number[] = [0, 0, 0]
    let remainder = 0
    let i18nKey = ""

    if (_days > 0) {
        // years
        result[0] = Math.floor(_days / 365)
        remainder = _days % 365
        // Months & days
        result[1] = Math.floor(remainder / 30)
        result[2] = remainder % 30
    }
    // Only Days
    if (result[0] === 0 && result[1] === 0) i18nKey = 'title.days'
    // Only Months
    if (result[0] === 0 && result[1] > 0 && result[2] === 0) i18nKey = 'title.daysWithCalculationM'
    // Only Years
    if (result[0] > 0 && result[1] === 0 && result[2] === 0) i18nKey = 'title.daysWithCalculationY'
    // Month and day
    if (result[0] === 0 && result[1] > 0 && result[2] > 0) i18nKey = 'title.daysWithCalculationMD'
    // Year and day
    if (result[0] > 0 && result[1] === 0 && result[2] > 0) i18nKey = 'title.daysWithCalculationYD'
    // Year and Month
    if (result[0] > 0 && result[1] > 0 && result[2] === 0) i18nKey = 'title.daysWithCalculationYM'
    // Years, Months and Days
    if (result[0] > 0 && result[1] > 0 && result[2] > 0) i18nKey = 'title.daysWithCalculationYMD'

    return t(i18nKey, {
        count: _days,
        Years: result[0],
        Months: result[1],
        Days: result[2],
        ns: 'renewalsalerts'
    })
}

/**
 * Frecuency options
 */

export const frequencyOptions = (): IComboBoxOption[] => {
    const {t} = i18n
    return [
        {key: 'daily', text: t('text.frequencyDaily', {ns: 'renewalsalerts'})},
        {key: 'weekly', text: t('text.frequencyWeekly', {ns: 'renewalsalerts'})},
        {key: 'monthly', text: t('text.frequencyMonthly', {ns: 'renewalsalerts'})}]
}


/**
 * Function onRenderOption for DropdownList of customControls
 * @param option
 */
export const onRenderOption = (option?: IDropdownOption): JSX.Element => {
    return (
        <span>{option!.text}</span>
    )
}

/**
 * remove HTML tags from string
 * @param str
 */
export const removeHTMLTags = (str: string):string => {
    if ((str === null) || (str === ''))
        return "";
    else
        str = str.toString();
    str = str.replace(/(<([^>]+)>)/ig, '');
    return str.replaceAll("&nbsp;", ' ').replaceAll("&lt;", '<').replaceAll("&gt;", '>').replaceAll("&amp;", '&').trim();
}

export const columnsRenewalsView = (onEditViewRenewal: (recordRenewal: ResponseGetContextRenewals) => void,
                                    onPreview: (recordRenewal: ResponseGetContextRenewals) => void,
                                    onRecordForm: (recordRenewal: ResponseGetContextRenewals) => void,
                                    onSetNextRenewal: (recordRenewal: ResponseGetContextRenewals) => void,
                                    theme:Theme
): IColumn[] => {
    const {t} = i18n
    const iconListButtonStyles: Partial<IButtonStyles> = {root: {float: 'left', height: 'inherit'}};

    const menuProps = (item: ResponseGetContextRenewals): IContextualMenuProps => {
        return {
            items: [
                {
                    key: 'RenewalDate',
                    text: t("title.nextRenewalsRecord", {ns: "renewalsalerts"}),
                    title: t("title.nextRenewalsRecord", {ns: "renewalsalerts"}),
                    iconProps: {iconName: 'EventDate'},
                    onClick: () => {
                        onSetNextRenewal(item)
                    }
                },
                {
                    key: 'ViewEdit',
                    text: t("title.renewalsRecord", {ns: "renewalsalerts"}),
                    title: t("title.renewalsRecord", {ns: "renewalsalerts"}),
                    iconProps: {iconName: 'PreviewLink'},
                    onClick: () => {
                        onEditViewRenewal(item)
                    }
                }

            ],
            directionalHintFixed: true,
        }
    };

    /**
     * Check if the document is allowed to Update 
     * @param item
     */
    const allowUpdateOnLoad = (item: IDocument) => {
        if (item.Suspended || item.Closed || item.CheckedOut) {
            return false;
        }

        if (
            item.Owner ||
            item.CoOwner ||
            item.UserName === Helper.getUsername()
        ) {
            return true;
        } else {
            return item.AllowDocumentUpdate;
        }
    };
    
    /**
     * Format date
     * @param date
     * @param format
     * @param utc
     */
    const onFormatDate = (date?: Date, format: string = "ll", utc: boolean = false): string => {
        const _locale = !!sessionStorage.getItem("language")
            ? sessionStorage.getItem("language")!.toString()
            : "en";
        if (utc) return date !== null ? formatFromUTC(date, format, _locale) : null
        else return date !== null ? formatSimpleDate(date, format, _locale) : null
    }


    return [
        {

            key: 'Preview',
            name: t('label.colPreview', {ns: 'renewalsalerts'}),
            ariaLabel: t('ariaLabel.colPreview', {ns: 'renewalsalerts'}),
            fieldName: 'Preview',
            minWidth: 16,
            maxWidth: 16,
            isResizable: false,
            className: contentStyles(theme).fileIconCell,
            iconClassName: contentStyles(theme).fileIconHeaderIcon,
            iconName: "pdf",
            isIconOnly: true,

            onRender: (item: any) => {

                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>

                        {isMobile ? (
                            item?.IsPending ? null :
                            <IconButton
                                iconProps={{iconName: "pdf"}}
                                style={{cursor: "pointer"}}
                                title={t("label.colPreview", {ns: "renewalsalerts"})}
                                alt={t("ariaLabel.colPreview", {ns: "renewalsalerts"})}
                                className={contentStyles(theme).fileIconImg}
                                onTouchEnd={() => {
                                    onPreview(item)
                                }}
                            />)
                            : (
                            item?.IsPending ? null :
                            <IconButton
                                iconProps={{iconName: "pdf"}}
                                style={{cursor: "pointer"}}
                                title={t("label.colPreview", {ns: "renewalsalerts"})}
                                alt={t("ariaLabel.colPreview", {ns: "renewalsalerts"})}
                                className={contentStyles(theme).fileIconImg}
                                onClick={() => {
                                    onPreview(item)
                                }}
                            />)
                        }
                    </StackItem>
                )
            },
            isPadded: true
        },
        {

            key: 'Form',
            name: t("Common.Keys.FormWizard", {ns: "common"}),
            ariaLabel: t("title.gridColFormIcon", {ns: "workspace"}),
            fieldName: 'Form',
            minWidth: 16,
            maxWidth: 16,
            isResizable: false,
            className: contentStyles(theme).fileIconCell,
            iconClassName: contentStyles(theme).fileIconHeaderIcon,
            iconName: "officeFormsLogo",
            isIconOnly: true,

            onRender: (item: any) => {
                if (allowUpdateOnLoad(item)) {
                    return (
                        item?.IsPending ? null :
                            <IconButton
                                iconProps={{iconName: "officeFormsLogo"}}
                                style={{cursor: "pointer"}}
                                title={t("title.gridFormIcon", {
                                    ns: "workspace",
                                })} alt={t("title.gridFormIcon", {
                                ns: "workspace",
                            })}
                                className={contentStyles(theme).fileIconImg}
                                onClick={() => {
                                    onRecordForm(item)
                                }}
                            />
                    )
                }
            },

            isPadded: true
        },
        {
            key: 'Description',
            name: t('label.colName', {ns: 'renewalsalerts'}),
            ariaLabel: t('ariaLabel.colName', {ns: 'renewalsalerts'}),
            fieldName: 'Description',
            minWidth: 100,
            isRowHeader: true,
            isCollapsible: false,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            isResizable: true,
            sortAscendingAriaLabel: t('label.sortedAsc', {ns: 'common'}),
            sortDescendingAriaLabel: t('label.sortedDesc', {ns: 'common'}),
            onRender: (item: ResponseGetContextRenewals) => {
                return <>
                    {item?.IsPending ? 
                    <StackItem>
                        <Text block variant={"small"}
                            styles={{ root: { color: "#FF0000", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } }}
                        >
                            Pending update
                        </Text>
                    </StackItem> : null}
                    <Text block={true} variant={"mediumPlus"} styles={{root: {fontWeight: "500"}}}
                          title={item.Description}>{item.Description}</Text>
                    <Text block={true} variant={"medium"}
                          title={`${item.Category} - ${item.DisplayName}`}>{`${item.Category} - ${item.DisplayName}`}</Text>
                    {item.OnBehalfUser === null || item.OnBehalfUser.trim().length === 0 ? 
                        <Text block={true} variant={"medium"} styles={{root: {fontWeight: "300"}}}
                              title={item.FullName !== null && item.FullName.trim().length !== 0 && item.FullName.toLowerCase().trim() !== item.UserName.toLowerCase().trim() ? `${item.FullName} <${item.UserName}>` : item.UserName}> {item.FullName}</Text>
                        :
                        <Text block={true} variant={"medium"} styles={{root: {fontWeight: "300"}}}
                              title={item.OnBehalfUserFullName !== null && item.OnBehalfUserFullName.trim().length !== 0 && item.OnBehalfUserFullName?.toLowerCase().trim() !== item.OnBehalfUser.toLowerCase().trim() ? `${item.OnBehalfUserFullName} <${item.OnBehalfUser}>` : item.OnBehalfUser}> {item.OnBehalfUserFullName}</Text>
                    }
                </>
            }
        },
        {
            key: 'LastNotificationDate',
            name: t('label.colLastRenewalNotification', {ns: 'renewalsalerts'}),
            ariaLabel: t('ariaLabel.colLastRenewalNotification', {ns: 'renewalsalerts'}),
            sortAscendingAriaLabel: t('label.sortedAsc', {ns: 'common'}),
            sortDescendingAriaLabel: t('label.sortedDesc', {ns: 'common'}),
            fieldName: 'LastNotificationDate',
            minWidth: 160,
            maxWidth: 160,
            isRowHeader: true,
            isSorted: true,
            isCollapsible: true,
            isResizable: true,
            isMultiline: false,
            data: 'date',
            isPadded: true,
            onRender: (item: any) => {
                return <>
                    <StackItem styles={{
                        root: {
                            justifyContent: "start",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text block
                              title={onFormatDate(item.LastNotificationDate, "ll")}>{onFormatDate(item.LastNotificationDate, "ll", true)}</Text>
                    </StackItem>
                </>
            }
        },
        {
            key: 'RenewalDate',
            name: t('label.colRenewalDate', {ns: 'renewalsalerts'}),
            ariaLabel: t('ariaLabel.colRenewalDate', {ns: 'renewalsalerts'}),
            sortAscendingAriaLabel: t('label.sortedAsc', {ns: 'common'}),
            sortDescendingAriaLabel: t('label.sortedDesc', {ns: 'common'}),
            fieldName: 'RenewalDate',
            minWidth: 140,
            maxWidth: 140,
            isRowHeader: true,
            isCollapsible: true,
            isResizable: true,
            isMultiline: false,
            data: 'date',
            isPadded: true,
            onRender: (item: any) => {
                return <>
                    <StackItem styles={{
                        root: {
                            justifyContent: "start",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text block
                              title={onFormatDate(item.RenewalDate, "ll")}>{onFormatDate(item.RenewalDate, "ll")}</Text>
                    </StackItem>
                </>
            }
        },
        {
            key: 'Actions',
            name: t('label.colActions', {ns: 'renewalsalerts'}),
            ariaLabel: t('ariaLabel.colActions', {ns: 'renewalsalerts'}),
            fieldName: 'Actions',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onRender: (item: any) => {
                return <>
                    <StackItem styles={{
                        root: {
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        { item?.IsPending ? null :
                        <IconButton
                            menuIconProps={{iconName: 'MoreVertical', style: {fontSize: 20, color: '#242424'}}}
                            role="button"
                            title={t('label.colShowActions', {ns: 'renewalsalerts'})}
                            data={item}
                            aria-haspopup
                            aria-label={t('label.colShowActions', {ns: 'renewalsalerts'})}
                            styles={iconListButtonStyles}
                            menuProps={menuProps(item)}
                        />}
                    </StackItem>
                </>

            },
            isPadded: true
        },
    ]

}
