import { IStackTokens, Label, mergeStyles, PrimaryButton, Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import Helper from "../../../../Helpers/Helper";
import { RHFCheckBoxControl } from "../../../RHFControls/RHFChekBoxControl";
import { RHFDropDownListSingleSelectControl } from "../../../RHFControls/RHFDropDownListSingleSelectControl";
import { RHFFileControl } from "../../../RHFControls/RHFFileControl";
import { RHFTextBoxControl } from "../../../RHFControls/RHFTextBoxControl";
import { OAuth20Type, ServiceProviderType } from "../../Models/Enums";
import { TestOAuth20Request } from "../../Models/OutboundIntegrationModel";
import OutboundServices from "../../Services/OutboundServices";
import TestIntegrationModal from "../../TestIntegrationModal";

export interface SecurityProps {
    templateId: number;
    showSuccessMessage: (message: string) => void;
    showErrorMessage: (message: string) => void;
    isConfigurationTemplateReadMode?: boolean;
}

const verticalStyle = mergeStyles({ height: "200px" });

const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};

const ServerToServerFlowAssertion = (props: SecurityProps) => {
    const { control, setValue, getValues, trigger } = useFormContext();
    const [isIntegrationTestModalOpen, { setTrue: showIntegrationTestModal, setFalse: hideIntegrationTestModal }] = useBoolean(false);
    const [modalContent, setModalContent] = useState<any>(null);

    const getDefaultValue = (id: any): any => {
        let currentValue: any = getValues(id);
        let tempCurrentValue: any =
            currentValue !== undefined ? currentValue : "";
        setValue(id, tempCurrentValue);
        return tempCurrentValue;
    };

    const testStoSAssertionFlow = async () => {
        OutboundServices.TestOAuth20(await getTestOAuth20Request()).then((res) => {
            showIntegrationTestModal();
            setModalContent(res);
        }
        );
    };

    const getTestOAuth20Request = async (): Promise<TestOAuth20Request> => {
        return {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            TemplateId: props.templateId,
            OAuth20Prop: {
                AccessToken: "",
                RefreshToken: "",
                AuthUrl: "",
                ResponseType: "",
                TokenUrl: getValues("TokenUrl"),
                GrantType: getValues("GrantType"),
                ClientId: getValues("ClientId"),
                ClientSecret: "",
                RedirectURI: "",
                Scope: getValues("Scope"),
                Username: "",
                Password: "",
                code: getValues("Code"),
                client_assertion_type: getValues("ClientAssertionType"),
                Assertion: getValues("Assertion"),
                AssertionCertPassword: getValues("AssertionCertPassword"),
                company_id: getValues("CompanyId"),
                code_verifier: getValues("CodeVerifier"),
                assertion_keyname: getValues("AssertionKeyName"),
                AssertionFileName: getValues("AssertionFileName"),
                new_token: "",
                oAuth20Type: OAuth20Type.ServerToServerFlowAssertion,
                State: "",
                ScopeRequired: getValues("ScopeRequired"),
                ServiceProvider: getValues("ServiceProvider").key.toString(),
                AuthorizationHeader: getValues("AuthorizationHeader"),
            },
        };
    };

    return (
        <>
            <Stack horizontal tokens={stackTokens}>
                <Stack.Item className={verticalStyle}>
                    <RHFTextBoxControl
                        id={"TokenUrl"}
                        label={"Token URL:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Token URL"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("TokenUrl")}
                    />
                    <div style={{ paddingTop: "31px", paddingBottom: "10px" }}>
                        <RHFCheckBoxControl
                            id={"ScopeRequired"}
                            label={"Scope Required:"}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            trigger={trigger}
                        />
                    </div>
                    <RHFTextBoxControl
                        id={"ClientId"}
                        label={"Client Id:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Client Id"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("ClientId")}
                    />
                    <RHFTextBoxControl
                        id={"Code"}
                        label={"Code:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Code"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("Code")}
                    />
                    <RHFTextBoxControl
                        id={"ClientAssertionType"}
                        label={"Client Assertion Type:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Client Assertion Type"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("ClientAssertionType")}
                    />
                </Stack.Item>
                <Stack.Item className={verticalStyle}>
                    <RHFTextBoxControl
                        id={"GrantType"}
                        label={"Grant Type:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Grant Type"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("GrantType")}
                    />
                    <RHFTextBoxControl
                        id={"Scope"}
                        label={"Scope:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Scope"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("Scope")}
                    />
                    <RHFTextBoxControl
                        id={"CompanyId"}
                        label={"Company Id:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Comapny Id"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("CompanyId")}
                    />
                    <RHFTextBoxControl
                        id={"CodeVerifier"}
                        label={"Code Verifier:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Code Verifier"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("CodeVerifier")}
                    />
                    <RHFTextBoxControl
                        id={"AssertionKeyName"}
                        label={"Assertion Key Name:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Assertion Key Name"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("AssertionKeyName")}
                    />
                </Stack.Item>
                <Stack.Item className={verticalStyle}>
                    <RHFDropDownListSingleSelectControl
                        placeholder="Select an option"
                        label="Service Provider:"
                        options={Object.entries(ServiceProviderType).map(
                            (item) => {
                                return { text: item[0], key: item[1] };
                            }
                        )}
                        id={"ServiceProvider"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getValues("ServiceProvider")}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                    />
                    <RHFFileControl
                        id={"AssertionFile"}
                        extensions={".pem,.txt"}
                        label={"Assertion:"}
                        title={"Enter Assertion"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("Assertion")}
                        fileName={getDefaultValue("AssertionFileName")}
                        onCallback={(ev: React.ChangeEvent<HTMLInputElement>) => {
                            if (ev.target.files != null && ev.target.files.length > 0) {
                                let readerBase64 = new FileReader();
                                const files = ev.target.files;
                                setValue("AssertionFile", files);
                                readerBase64.readAsDataURL(files[0]);
                                readerBase64.onload = function () {
                                    setValue("Assertion", readerBase64.result!.toString().split(",")[1]);
                                };

                                let readerContent = new FileReader();
                                readerContent.readAsText(files[0]);
                                readerContent.onload = function () {
                                    setValue("AssertionContent", readerContent.result);
                                    trigger("AssertionFile");
                                };
                                setValue("AssertionFileName", ev.target.files![0].name);
                            }
                        }}
                    />
                    <RHFTextBoxControl
                        id={"AssertionCertPassword"}
                        label={"Certificate Password:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Certificate Password"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("AssertionCertPassword")}
                    />
                    <RHFTextBoxControl
                        id={"AuthorizationHeader"}
                        label={"Authorization Header:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Authorization Header"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("AuthorizationHeader")}
                    />
                </Stack.Item>
                <Stack.Item className={verticalStyle}>
                    <Label />
                    <Label />
                    <Label />
                    <PrimaryButton
                        text={"Test S to S Assertion Flow"}
                        onClick={testStoSAssertionFlow}
                        disabled={props.isConfigurationTemplateReadMode}
                    />
                </Stack.Item>
            </Stack>
            <TestIntegrationModal
                showModal={isIntegrationTestModalOpen}
                hideModal={hideIntegrationTestModal}
                content={modalContent}
                setModalContent={setModalContent}
            />
        </>
    );
};

export default ServerToServerFlowAssertion;
