import React from 'react'
import {
    Stack
} from '@fluentui/react'
import { useTranslation } from 'react-i18next';
type AssemblyProps = {

    cancel: any
    templateId: any
}
const AssemblyRules = (props: AssemblyProps) => {

    const { t } = useTranslation('assembly')

    return (
        <>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">

                <div className="scrollVisible" style={{
                    overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh"
                }}>
                    <label className="ms-fontSize-18">{t('settingsHeader')}</label>
                    <div>
                        <p>
                            The Document Assembly module provides the ability to assemble documents by adding or removing relevant
                            content based on rules.
                        </p>
                        <p>
                            <strong>Rules:</strong>, users can form rules similar to expressions to evaluate
                            input values and action static or input from the Content Library onto the document.
                        </p>
                        <p>
                            <strong>Content Library:</strong> allows users to store and insert frequently used content into documents,
                        </p>
                        <p>
                            <strong>Document Library:</strong> provides a collection of documents that can be appended or prepended based on rules during the assembly
                            process. The module also ensures that page numbering and footers are working as expected despite changes to document size.
                        </p>
                    </div>
                    <Stack styles={{ root: { paddingRight: "10px" } }}>

                        <p></p>
                        <label />
                    </Stack>
                </div>
            </div >
        </>

    )
}
export default AssemblyRules