import { ConstrainMode, DetailsList, DetailsListLayoutMode, IconButton, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDragDropEvents, IRenderFunction, ScrollablePane, ScrollbarVisibility, SelectionMode, Sticky, StickyPositionType, TooltipHost } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { RHFTextBoxControl } from '../../RHFControls/RHFTextBoxControl';
import { Const_Header } from '../Models/Consts';

export interface HeaderListProps {
    isConfigurationTemplateReadMode?: boolean;
}

function HeaderList(props: HeaderListProps) {
    const { control, setValue, getValues, trigger } = useFormContext();
    const headerList = useFieldArray(
        {
            control,
            name: "headerList"
        }
    );

    useEffect(() => {

    }, [headerList.fields])

    const getDragDropEvents = () => {
        return {
            canDrop: (dropContext: any, dragContext?: any) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                if (draggedItem) {

                    insertBeforeItem(item);
                }
            },
            onDragStart: (
                item?: any,
                itemIndex?: number,
                selectedItems?: any[],
                event?: MouseEvent
            ) => {
                if (typeof item.startIndex === "undefined") {
                    // skip group item
                    draggedItem = item;
                    draggedIndex = itemIndex!;
                }
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                draggedItem = undefined;
                draggedIndex = -1;
            }
        };
    };

    const dragDropEvents: IDragDropEvents = getDragDropEvents();
    let draggedItem: any | undefined;
    let draggedIndex: number = -1;

    const insertBeforeItem = (item: any): void => {
        headerList.move(draggedIndex, headerList.fields.indexOf(item))
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
            <TooltipHost {...tooltipHostProps} />
        );
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    return (
        <div style={{
            height: '300px',
            position: 'relative',
            backgroundColor: 'white',
        }} >
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList                
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.unconstrained}
                    compact={true}
                    items={headerList.fields.map(item => item)}
                    columns={[
                        {
                            key: 'key',
                            name: 'Key',
                            fieldName: 'Key',
                            minWidth: 100,
                            maxWidth: 200,
                            isResizable: false,
                            onRender: (item, index, column) => {
                                return <RHFTextBoxControl
                                    key={item.id}
                                    id={`headerList.${index!}.Key`}
                                    disabled={props.isConfigurationTemplateReadMode ? true : false}
                                    title="Enter header key."
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                    defaultValue={""}
                                    onCallback={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                        if (index === headerList.fields.length - 1 && newValue.length > 0 && getValues("headerList")[index].Value.length >= 0) {
                                            headerList.append(Const_Header)
                                        }
                                    }}
                                />
                            }
                        },
                        {
                            key: 'value',
                            name: 'Value',
                            fieldName: 'Value',
                            minWidth: 100, maxWidth: 200,
                            isResizable: false,
                            onRender: (item, index, column) => {
                                return <RHFTextBoxControl
                                    key={item.id}
                                    onCallback={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                        if (index === headerList.fields.length - 1 && getValues("headerList")[index].Key.length > 0 && newValue.length >= 0) {
                                            headerList.append(Const_Header)
                                        }
                                    }}
                                    id={`headerList.${index}.Value`}
                                    disabled={props.isConfigurationTemplateReadMode ? true : false}
                                    title="Enter header value."
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                    defaultValue={""}
                                />
                            }
                        },
                        {
                            key: 'actions',
                            name: 'Actions',
                            onRender: (item?: any, index?: number) => {
                                return <IconButton
                                    disabled={props.isConfigurationTemplateReadMode}
                                    onClick={() => {
                                        headerList.remove(index!)
                                        if ((index === 0 && headerList.fields.length - 1 === 0) || index === headerList.fields.length - 1) {
                                            headerList.append(Const_Header)
                                        }
                                    }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" />
                            },
                            minWidth: 100,
                            maxWidth: 100,
                            isResizable: false
                        }
                    ]}
                    selectionMode={SelectionMode.none}
                    dragDropEvents={dragDropEvents}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                />
            </ScrollablePane>
        </div>
    )
}

export default HeaderList