import { FC, useMemo, useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import * as React from "react";
import { ReactHookFormControlProps } from "../Models/ReactHookFormControlProps";
import { IColumn, IStackTokens, Label, PrimaryButton, ShimmeredDetailsList, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import { CheckboxVisibility, DetailsListLayoutMode, Selection, SelectionMode } from "office-ui-fabric-react/lib/DetailsList";
import { useModal } from "../../../../hooks/useModal";
import OutboundService from "../../../../services/outbound";
import {IExternalControl, IOutputMap} from "../../../../interfaces/IOutbound";
import { ILayout, Integration, PickingList } from "../../CustomTemplate";
import Helper, { IFormControl } from "../../../../Helpers/Helper";
import { InputType } from "../../Validations";
import { Sizes } from "../../../../share/CustomModal";
import StatusMessage, { StatusEnum } from "../../../../share/StatusMessage";
import { useTranslation } from "react-i18next";
import {useBranding} from "../../../../hooks/useBranding";

type RHFIntegrationControlProps = {
    label: string;
    inputs: ILayout[];
    templateId: string;
    integration: Integration | undefined;
    tabIndex?: number;
    display: boolean;
    integrationReload: (data: IFormControl[]) => void;
    updateBeforeIntegration?: (data: IOutputMap[]) => void;
    getCustomFormContentControls: () => IFormControl[];
    getJSXElement: (type: string, id: string, value: string, rowIndex: string, form: UseFormReturn<any, any>, labelVisible: boolean) => JSX.Element;
    username: string;
    setCustomFormErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    isPickingList: boolean;
    pickingList: PickingList | undefined;
    version: number | undefined;
};

interface IIntegrationExecute {
    IntegrationId: number;
    TemplateId: number;
    ExternalControls: IExternalControl[];
    Username: string;
    FromModal: boolean;
    FormDescription: IFormControl | undefined;
}
interface IEventExecute {
    EventId: number;
    TemplateId: number;
    ExternalControls: IExternalControl[];
    Username: string;
    Async: boolean;
    DocumentId: number;
    FromModal: boolean;
    FormDescription: IFormControl | undefined;
}

export const RHFIntegrationControl: FC<ReactHookFormControlProps & RHFIntegrationControlProps> = (props) => {
    const { id, label, integration, control, rules, inputs, templateId, getJSXElement, integrationReload, getCustomFormContentControls, username, setCustomFormErrorMessage, isPickingList, pickingList, version, updateBeforeIntegration } = props;
    const [integrationErrorMessage, setIntegrationErrorMessage] = useState<string | undefined>(undefined);
    const [integrationLoaded, setIntegrationLoaded] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [disableButton, setDisableButton] = useState(false);
    const [detailListColumns, setDetailListColumns] = useState<Array<any>>([]);
    const [detailListItems, setDetailListItems] = useState<Array<any>>([]);
    const [enableShimmer, setEnableShimmer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { Modal, open, close } = useModal();
    const { branding } = useBranding()
    const { t } = useTranslation(["common"]);

    const selection = React.useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    handlePickingList(selection.getSelection()[0]);
                },
                selectionMode: SelectionMode.single,
            }),
        [],
    );

    const modalForm = useForm<any, any>({
        defaultValues: {},
        reValidateMode: "onSubmit",
        mode: "all",
    });
    const stackTokens: IStackTokens = {
        childrenGap: 50 + " " + 20,
        padding: `20px 0px`,
    };

    const getFormControls = (data: any) => {
        const keys = Object.keys(data);
        const values = Object.values(data);
        let formControls: IFormControl[] = [];
        keys.forEach((key, keyIndex) => {
            let formControl: IFormControl = { Key: key, Value: "" };
            //formControl.Key = inputs.find((input) => input.Id === key)?.Label ?? key;
            formControl.Key = version !== undefined && version !== null && version > 1 ? key : inputs.find((input) => input.Id === key)?.Label ?? key;
            formControl.Value = values[keyIndex] !== undefined ? (values[keyIndex] as string).toString().trimEnd() : "";
            formControls.push(formControl);
        });
        return formControls;
    };

    const executeIntegration = ({ IntegrationId, TemplateId, ExternalControls, Username, FromModal, FormDescription }: IIntegrationExecute) => {
        OutboundService.ExecuteIntegrationPost({
            IntegrationId,
            TemplateId,
            ExternalControls,
            Username,
        }).then(({ ErrorMessage, OutputPayload, OutputMap   }) => {
            setDisableButton(false);
            setIsLoading(false);
            
            if (ErrorMessage) {
                setIntegrationErrorMessage("There was an error on the request. Please try again.")
                if (FromModal) {
                    setErrorMessage(ErrorMessage!);
                    return;
                }
                setCustomFormErrorMessage(ErrorMessage!);
                return;
            }
            setIntegrationLoaded(true)
            if (FormDescription !== undefined) {
                OutputPayload = [...OutputPayload, FormDescription];
            }

            if (updateBeforeIntegration) {
                updateBeforeIntegration(OutputMap)
            }
            close();
        });
    };

    const executeEvent = ({ EventId, TemplateId, ExternalControls, Username, Async, DocumentId, FromModal, FormDescription }: IEventExecute) => {
        OutboundService.ExecuteEventPost({
            EventId,
            TemplateId,
            ExternalControls,
            Username,
            Async,
            DocumentId,
        }).then(({ ErrorMessage, OutputPayload, OutputMap }) => {
            setDisableButton(false);
            setIsLoading(false);
            
            if (ErrorMessage) {
                setIntegrationErrorMessage("There was an error on the request. Please try again.")

                if (FromModal) {
                    setErrorMessage(ErrorMessage!);
                    return;
                }
                setCustomFormErrorMessage(ErrorMessage!);
                return;
            }
            setIntegrationLoaded(true)
            if (FormDescription !== undefined) {
                OutputPayload = [...OutputPayload, FormDescription];
            }
            if (updateBeforeIntegration) {
                updateBeforeIntegration(OutputMap)
            }
            
            
            close();
        });
    };


    const handleOnClick = (fromModal: boolean) => {
        setIntegrationErrorMessage(undefined)
        setIntegrationLoaded(false)
        modalForm.handleSubmit(            
            (data) => {
              
                
                try {
                    setDisableButton(true);
                    if (detailListItems.length > 0) {
                        setEnableShimmer(true);
                    } else {
                        setIsLoading(true);
                    }
                    const formControls = getFormControls(data);
                    const IntegrationControls: IExternalControl[] = formControls.map((formControl) => {
                        return {
                            ContentControl: formControl.Key,
                            Value: formControl.Value,
                        };
                    });
                    const customFormCCs = getCustomFormContentControls();
                    let customFormCCsFormatted = customFormCCs.map((cc) => {
                        return {
                            ContentControl: cc.Key,
                            Value: cc.Value,
                        };
                    });

                  
                    customFormCCs.forEach((cc) => {
                        if (cc.CheckedValues !== undefined && cc.CheckedValues.length > 0) {
                            const dropdownsValues = cc.CheckedValues.map((values, index) => {
                                return {
                                    ContentControl: `${cc.Key}-value${index}`,
                                    Value: JSON.stringify([values]),
                                };
                            });
                            customFormCCsFormatted = [...customFormCCsFormatted, ...dropdownsValues];
                        }
                    });
                    const ExternalControls = [...IntegrationControls, ...customFormCCsFormatted];
                    const formDescription = customFormCCs.find((x) => x.Key === "_shortcutReference");

                    let eventId = -1;

                    if (integration?.EventId !== undefined && integration?.EventId !== null && integration?.EventId > 0) {
                        eventId = integration.EventId;
                    }

                    if (isPickingList) {
                        OutboundService.ExecuteEventPost({
                            EventId: eventId,
                            TemplateId: Number(templateId),
                            ExternalControls,
                            Username: username !== undefined && username !== "" ? username : Helper.getUsername(),
                            Async: false,
                            DocumentId: -1,
                        }).then(({ ErrorMessage, OutputMap }) => {
                            setDisableButton(false);
                            setEnableShimmer(false);
                            setIsLoading(false);

                            if (ErrorMessage) {
                                setErrorMessage(ErrorMessage!);
                                return;
                            }

                            const OutputList = OutputMap.find((x) => x.TargetField === "pickingGrid") ?? { TargetValue: "[]" };
                            const TargetValue = JSON.parse(OutputList.TargetValue);

                            if (Array.isArray(TargetValue) && TargetValue.length > 0) {
                                const propertyNames = Object.keys(TargetValue[0]);
                                configureDetailList(propertyNames, TargetValue);
                            } else {
                                setErrorMessage("Error on integration execute.");
                                return;
                            }
                        });
                    } else {
                        
                        if (version !== undefined && version > 1) {
                            executeEvent({
                                EventId: eventId,
                                TemplateId: Number(templateId),
                                ExternalControls,
                                Username: username !== undefined && username !== "" ? username : Helper.getUsername(),
                                Async: false,
                                DocumentId: -1,
                                FromModal: fromModal,
                                FormDescription: formDescription,
                            });
                        } else {
                            executeIntegration({
                                IntegrationId: integration?.Id!,
                                TemplateId: Number(templateId),
                                ExternalControls,
                                Username: username !== undefined && username !== "" ? username : Helper.getUsername(),
                                FromModal: fromModal,
                                FormDescription: formDescription,
                            });
                        }
                    }
                } catch (error) {
                    console.log(error);
                    if (fromModal) {
                        setErrorMessage("Error when loading integrations.");
                        return;
                    }
                    setCustomFormErrorMessage("Error when loading integrations.");
                    return;
                }
            },
            (error) => {
                setDisableButton(true);
                console.log(error);
            },
        )();
    };

    const handlePickingList = (selected: any) => {
        setIsLoading(true);
        setDisableButton(true);

        const propertyNames = Object.keys(selected);

        let IntegrationControls = propertyNames.map((property) => {
            return {
                ContentControl: property,
                Value: selected[property].Value as string,
            };
        });

        const customFormCCs = getCustomFormContentControls();
        let customFormCCsFormatted = customFormCCs.map((cc) => {
            return {
                ContentControl: cc.Key,
                Value: cc.Value,
            };
        });
        customFormCCs.forEach((cc) => {
            if (cc.CheckedValues !== undefined && cc.CheckedValues.length > 0) {
                const dropdownsValues = cc.CheckedValues.map((values, index) => {
                    return {
                        ContentControl: `${cc.Key}-value${index}`,
                        Value: JSON.stringify([values]),
                    };
                });
                customFormCCsFormatted = [...customFormCCsFormatted, ...dropdownsValues];
            }
        });

        IntegrationControls = IntegrationControls.filter((x) => customFormCCsFormatted.find((cc) => cc.ContentControl === x.ContentControl) === undefined);

        const ExternalControls = [...IntegrationControls, ...customFormCCsFormatted];
        const formDescription = customFormCCs.find((x) => x.Key === "_shortcutReference");

        let eventId = -1;
        if (pickingList?.Integration?.EventId !== undefined && pickingList?.Integration?.EventId !== null && pickingList?.Integration?.EventId > 0) {
            eventId = pickingList?.Integration?.EventId;
        }

        OutboundService.ExecuteEventPost({
            EventId: eventId,
            TemplateId: Number(templateId),
            ExternalControls,
            Username: username !== undefined && username !== "" ? username : Helper.getUsername(),
            Async: false,
            DocumentId: -1,
        }).then(({ ErrorMessage, OutputPayload, OutputMap }) => {
            setDisableButton(false);
            setIsLoading(false);
            if (ErrorMessage) {
                setIntegrationErrorMessage("There was an error on the request. Please try again.")
                setErrorMessage(ErrorMessage!);
                return;
            }
            if (formDescription !== undefined) {
                OutputPayload = [...OutputPayload, formDescription];
            }
            if (updateBeforeIntegration) {
                updateBeforeIntegration(OutputMap)
            }
            close();
        });
    };

    const configureDetailList = (propertyNames: string[], targetValue: Array<any>) => {
        const columns = Array.from({ length: propertyNames.length }).map((target, index) => {
            return {
                key: "column" + (index + 1),
                name: targetValue[0][propertyNames[index]].Column,
                fieldName: "test" + (index + 1),
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onRender: (item: any) => {
                    return <span style={{ whiteSpace: "break-spaces" }}>{item[propertyNames[index]].Value}</span>;
                },
            } as IColumn;
        });

        setDetailListColumns(columns);
        setDetailListItems(targetValue);
    };

    const handleOpenModal = () => {
        if (inputs.length > 0) {
            open();
            return;
        }

        if (isPickingList && inputs.length === 0) {
            open();
            handleOnClick(false);
            return;
        }

        handleOnClick(false);
    };

    return (
        <>
            <Controller
                name={id}
                control={control}
                rules={rules}
                defaultValue={integration?.Id}
                render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
                    <>
                        <Stack grow={1}>
                            <Stack>
                                {isLoading ?
                                    <>
                                        <Stack
                                            styles={{
                                                root: {
                                                    width: "100%", height: 30, justifyContent: 'center',
                                                    backgroundColor: branding.theme.palette.themeLighterAlt,
                                                }
                                            }}>
                                            <Spinner size={SpinnerSize.small} labelPosition={'right'}
                                                     label={"Loading integration ..."}                                                 
                                                     title={"Loading integration ..."}/>
                                        </Stack>
                                    </>
                                    :
                                    <>
                                        <PrimaryButton disabled={props.readOnly ?? false} 
                                                       tabIndex={props.tabIndex ? props.tabIndex : -1}
                                                       styles={{ root: { display: props.display ? "inline" : "none"}, label: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }, textContainer: {width: 0} }}
                                                       onClick={handleOpenModal}>
                                            {label}
                                        </PrimaryButton>
                                    </>
                                }
                                 
                                
                            </Stack>
                            <Stack tokens={{padding: 4}}>
                                {integrationErrorMessage !== undefined &&
                                    <Text variant={"small"}
                                          styles={{root: {color: branding.theme.semanticColors.errorText}}}>{integrationErrorMessage}</Text>
                                }
                                {integrationLoaded &&
                                    <Text variant={"small"}
                                          styles={{root: {color: branding.theme.palette.greenDark}}}>{"Integration loaded successfully!"}</Text>
                                }
                            </Stack>
                           
                        </Stack>
                    </>
                )}
            />
            <Modal title={label} titleId={label} size={Sizes.auto}>
                <>
                    {errorMessage !== "" ? (
                        <StatusMessage setShowMessage={() => setErrorMessage("")} status={StatusEnum.Error} isMultiline={true}>
                            {errorMessage}
                        </StatusMessage>
                    ) : (
                        <></>
                    )}
                    <div style={{ display: "grid", gridTemplateColumns: "auto", rowGap: "30px" }}>
                        {inputs.map((item) => {
                            return (
                                <>
                                    <div
                                        style={
                                            item.Type === InputType.GridList
                                                ? {
                                                      width: "100%",
                                                      minHeight: "260px",
                                                      display: "block",
                                                  }
                                                : {
                                                      width: "100%",
                                                      display: "block",
                                                  }
                                        }
                                        className={item.Type === InputType.GridList ? "divGridList ms-Grid-col ms-sm12 ms-md12 ms-lg12" : "ms-Grid-col ms-sm12 ms-md12 ms-lg12"}
                                        key={item.Id}
                                    >
                                        {getJSXElement(item.Type.toString(), item.Id, item.Validations.DefaultValue, "", modalForm, true)}
                                    </div>
                                </>
                            );
                        })}
                        {inputs.length > 0 ? (
                            <div style={{ justifySelf: "end" }}>
                                <PrimaryButton disabled={disableButton} onClick={() => handleOnClick(true)}>
                                    Send
                                </PrimaryButton>
                            </div>
                        ) : null}

                        {!isLoading ? (
                            detailListItems.length > 0 ? (
                                <>
                                    <ShimmeredDetailsList items={detailListItems} enableShimmer={enableShimmer} compact={false} columns={detailListColumns} selectionMode={SelectionMode.single} layoutMode={DetailsListLayoutMode.justified} isHeaderVisible={true} isSelectedOnFocus={false} setKey={"TemplateId"} selection={selection} selectionPreservedOnEmptyClick={true} enterModalSelectionOnTouch={true} checkboxVisibility={CheckboxVisibility.always} />
                                    {/* <div style={{ justifySelf: "end" }}>
                                        <PrimaryButton onClick={() => handlePickingList()}>Select</PrimaryButton>
                                    </div> */}
                                </>
                            ) : null
                        ) : (
                            <Stack>
                                <Label />
                                <Label />
                                <Label />
                                <Spinner size={SpinnerSize.medium} label={t("text.spinner", { ns: "common" })} ariaLive="assertive" />
                            </Stack>
                        )}
                    </div>
                </>
            </Modal>
        </>
    );
};
