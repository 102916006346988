import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import '../../Custom.css';
import {
    CheckboxVisibility,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    IBasePickerSuggestionsProps,
    IPersonaProps,
    Label,
    MarqueeSelection,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    PrimaryButton,
    Selection,
    SelectionMode,
    Shimmer,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem
} from "@fluentui/react";
import {IconButton} from "@fluentui/react/lib/Button";
import {Text} from "@fluentui/react/lib/Text";
import {TextField} from '@fluentui/react/lib/TextField';
import HelpComponentRelative from "../Help/HelpComponentRelative";
import {Separator} from "@fluentui/react/lib/Separator";
import {Modal} from "@fluentui/react/lib/Modal";
import {IIconProps} from "@fluentui/react/lib/Icon";
import {contentStyles, iconButtonStyles, nonShrinkingStackItemStyles} from "./Style"
import StatusMessage from "../../share/StatusMessage";

import {FormProvider, useForm} from "react-hook-form";
import {panelHeader} from "../Preview/Style";
import {isMobile} from "react-device-detect";
import {RHFPeoplePickerControl} from "../RHFControls/RHFPeoplePickerControl"
import {RHFTextBoxControl} from "../RHFControls/RHFTextBoxControl";
import {IPanelProps} from "@fluentui/react/lib/Panel";
import Helper, {IDocumentOnlyAudienceDetail} from "../../Helpers/Helper";
import {
    GetAttachmentsColumns,
    IAudienceIntersectRequest,
    IEmailServiceRequest,
    IGetDocumentOnlyAttachmentByDocumentIdRequest,
    IGetDocumentOnlyAttachmentByDocumentIdResponse,
    IPeople,
    ISendLinkDocumentRequest,
    optionsAttachment
} from "./FormDocumentLink.data";
import {FormDocumentLinkService} from "./FormDocumentLink.service";
import {ChoiceGroup, IChoiceGroupOption} from "@fluentui/react/lib/ChoiceGroup";
import {Checkbox} from "@fluentui/react/lib/Checkbox";
import {number} from "mathjs";
import FormTemplateLinkService, {
    IgetDocumentOnlyTemplateIDBasicRequest
} from "../TemplateLink/FormTemplateLink.service";
import {useBranding} from "../../hooks/useBranding";
import ApplicationServiceEmailService, {IGetServiceEmailByOwnerRequest} from "../../services/ApplicationServiceEmail";
import DOMPurify from "dompurify";

type Properties = {
    showModal: boolean
    onDismissModal: () => void;
    documentId: string
    modalTitle: string
    allowDocx: boolean
    templateId: string
    templateVersion: string
    ownerName: string
    ownerUser: string
    documentDescription: string
    isSubform: boolean
}

function FormDocumentLink({
                              showModal,
                              documentId,
                              modalTitle,
                              onDismissModal,
                              templateId,
                              allowDocx,
                              templateVersion,
                              documentDescription,
                              ownerName,
                              ownerUser,
                              isSubform
                          }: Properties) {
    const { branding } = useBranding()
    const {t} = useTranslation(['recordFormLink', 'common'])
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const [fromApplicationServiceEmail, setFromApplicationServiceEmail] = useState("")
    const [ServiceEmailTemplateId, setServiceEmailTemplateId] = useState(0)
    const formPublishWebform = useForm();
    const [isSent, setIsSent] = useState(false)
    const [isLoadingEmailService, setIsLoadingEmailService] = useState(false)
    const [isLoadingPeople, setIsLoadingPeople] = useState(false)
    const [isLoadingAttachments, setIsLoadingAttachments] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [messageSent, setMessageSent] = useState(0)
    const [txtMessageError, setTxtMessageError] = useState("")
    const [peopleList, setPeopleList] = useState<IPersonaProps[]>([])
    const [selectedPeopleList, setSelectedPeopleList] = useState<IPersonaProps[]>([])
    const [selectedPeopleSharedList, setSelectedPeopleSharedList] = useState<IPeople[]>([])
    const [attachments, setAttachments] = useState<IGetDocumentOnlyAttachmentByDocumentIdResponse[]>([]);
    const [chkDocument, setChkDocument] = useState(false);
    const [showShareConfirm, setShowShareConfirm] = useState(false);
    const [docType, setDocType] = useState("PDF");

    const [selectionAttachment] = useState(new Selection<any>())

    const suggestionProps: IBasePickerSuggestionsProps = {
        mostRecentlyUsedHeaderText: t('text.mostRecentlyUsedHeaderText', {ns: 'recordFormLink'}),
        suggestionsContainerAriaLabel: t('text.suggestionsContainerAriaLabel', {ns: 'recordFormLink'}),
        suggestionsHeaderText: t('text.mostRecentlyUsedHeaderText', {ns: 'recordFormLink'}),
    };

    useEffect(() => {
        if (showModal) {
            initFields().then()
        }
    }, [showModal])

    /**
     * initFields
     */
    const initFields = async () => {
        formPublishWebform.clearErrors()
        formPublishWebform.reset()

        selectionAttachment.setAllSelected(false)
        setIsLoadingPeople(true)
        setIsLoadingEmailService(true)
        setIsLoadingAttachments(true)
        setShowShareConfirm(false)
        setChkDocument(false)
        setDocType("PDF")
        setServiceEmailTemplateId(0)
        setMessageSent(0)
        setIsSent(false)
        setIsSaving(false)
        getApplicationServiceEmail().then()
        getApplicationAudience().then()
        getAttachments().then()
        setSelectedPeopleList([])
        setSelectedPeopleSharedList([])
    }

    /**
     * onBeforeDismiss
     */
    const onBeforeDismiss = () => {
        onDismissModal()
    }

    /**
     * onBeforeDismissShare
     */
    const onBeforeDismissShare = () => {
        setShowShareConfirm(false)
    }

    /**
     * getAttachments
     */
    const getAttachments = async () => {
        setAttachments([])

        const request: IGetDocumentOnlyAttachmentByDocumentIdRequest = {
            DocumentId: documentId
        }
        FormDocumentLinkService.GetDocumentOnlyAttachmentByDocumentId(request)
            .then(async (data) => {

                try {
                    if (Array.isArray(data)) {
                        setAttachments(data)
                    }
                } catch (error:any) {
                    setMessageSent(-1)
                    setTxtMessageError(t('text.errorMessage1', {ns: 'common', Error: error.toString()}))
                }
            })
            .catch((error) => {
                setMessageSent(-1)
                setTxtMessageError(t('text.errorMessage1', {ns: 'common', Error: error.toString()}))
            })
            .finally(() => {
                setIsLoadingAttachments(false)
            })
    }

    /**
     * getApplicationAudience
     */
    const getApplicationAudience = async () => {
        setPeopleList([])

        const request: IAudienceIntersectRequest = {
            DocumentId: documentId
        }
        FormDocumentLinkService.GetAudienceIntersect(request)
            .then(async (data) => {
                try {
                    if (data.length > 0) {
                        const people: IPersonaProps[] = data.map<IPersonaProps>(item => {
                            return {
                                text: item.FullName?.trim().length === 0 ? item.UserName : item.FullName,
                                secondaryText: item.UserName
                            }
                        })
                        setPeopleList(people)
                    }
                } catch (error:any) {
                    setMessageSent(-1)
                    setTxtMessageError(t('text.errorMessage1', {ns: 'common', Error: error.toString()}))
                }
            })
            .catch((error) => {
                setMessageSent(-1)
                setTxtMessageError(t('text.errorMessage1', {ns: 'common', Error: error.toString()}))
            })
            .finally(() => {
                setIsLoadingPeople(false)
            })
    }

    /**
     * getApplicationServiceEmail
     */
    const getApplicationServiceEmail = async () => {
        setFromApplicationServiceEmail(await Helper.getShareEmail())
        setServiceEmailTemplateId(-101)

        let request: IGetServiceEmailByOwnerRequest = {
            TemplateId: null,
            Owner: Helper.getUsername()
        }

        ApplicationServiceEmailService.GetServiceEmailByOwner(request)
            .then(async (data) => {

                try {
                    let found = data.find(item => item.TemplateId === -1);
                    if (found != undefined) {
                        setFromApplicationServiceEmail(found.ServiceEmail)
                        setServiceEmailTemplateId(-1)
                    } else {

                        const requestTemplate: IgetDocumentOnlyTemplateIDBasicRequest = {
                            TemplateId: templateId,
                            Autoprovision: true,
                            ignoreDocument: true
                        }

                        await FormTemplateLinkService.GetDocumentOnlyTemplateIDBasic(requestTemplate)
                            .then(async (data) => {

                                request = {
                                    TemplateId: templateId,
                                    Owner: data.OwnerUserName
                                }

                                await ApplicationServiceEmailService.GetServiceEmailByOwner(request)
                                    .then(async (data) => {
                                        if (data.length > 0) {
                                            setFromApplicationServiceEmail(data[0].ServiceEmail)
                                            setServiceEmailTemplateId(number(templateId))
                                        }
                                    })

                            })

                    }
                } catch {

                }
            })
            .catch((error) => {
                setMessageSent(-1)
                setTxtMessageError(t('text.errorMessage1', {ns: 'common', Error: error.toString()}))

            })
            .finally(() => {
                setIsLoadingEmailService(false)
            })

    }

    /**
     * SendEmail
     * @constructor
     */
    const SendEmail = async (peopleSharedList: IPeople[]) => {
        setIsSaving(true)

        const subject = DOMPurify.sanitize(formPublishWebform.getValues("txtSubject").trim(), { USE_PROFILES: { html: false } });
        const message = DOMPurify.sanitize(formPublishWebform.getValues("txtMessage").trim(), { USE_PROFILES: { html: false } });
        
        const request: ISendLinkDocumentRequest = {
            Attachments: selectionAttachment.getSelection().map(item => item.Id).join(","),
            Peoples: peopleSharedList,
            DocumentId: documentId.toString(),
            Subject: subject,
            Body: message,
            allowDocx: allowDocx,
            TemplateVersion: templateVersion,
            TemplateId: templateId,
            DocumentDelivery: chkDocument ? docType : "",
            DocumentDescription: documentDescription,
            OwnerName: ownerName,
            OwnerUser: ownerUser,
            ApplicationServiceEmailTemplateId: fromApplicationServiceEmail,
            ServiceEmailTemplateId: ServiceEmailTemplateId,
            IsSubform: isSubform
        }

        FormDocumentLinkService.PostSendLinkDocument(request)
            .then(async (data) => {

                if (Array.isArray(data)) {
                    setMessageSent(1)
                    setIsSent(true)
                    setTimeout(function () {
                        onBeforeDismiss();
                    }, 500);

                } else {
                    setMessageSent(-1)
                    setTxtMessageError(t('text.errorAndTry', {ns: 'common'}))

                }

            })
            .catch((error) => {
                setMessageSent(-1)
                setTxtMessageError(t('text.errorMessage1', {ns: 'common', Error: error}))
            })
            .finally(() => {
                setIsSaving(false)
                setShowShareConfirm(false)
            })

        console.log("send email")
    }

    /**
     * ValidateEmails
     */
    const ValidateEmails = async () => {

        await formPublishWebform.handleSubmit(async () => {

                const peopleSharedList: IPeople[] = []

                selectedPeopleList.forEach((item) => {
                    let list = peopleList.filter(x => x.secondaryText?.toLowerCase().trim() === item.secondaryText?.toLowerCase().trim())
                    if (list.length == 0) {

                        peopleSharedList.push({Text: item.text!, SecondaryText: item.secondaryText!, IsShared: false})
                    } else {

                        peopleSharedList.push({Text: item.text!, SecondaryText: item.secondaryText!, IsShared: true})
                    }
                })

                setSelectedPeopleSharedList(peopleSharedList)
                if (peopleSharedList.filter(item => !item.IsShared).length > 0) {

                    setShowShareConfirm(true)

                } else {

                    SendEmail(peopleSharedList).then()
                }
            }, () => {

            }
        )();

    }

    /**
     * getKey of selected item
     * @param item
     */
    const getKey = (item: IDocumentOnlyAudienceDetail): string => {
        if (item !== undefined)
            return item.Id.toString()
        return ""
    }

    /**
     * Messages
     * @constructor
     */
    const Messages = () => {
        return (
            <StackItem>
                {messageSent === 0 &&
                    <StatusMessage status={"info"} hasTimer={false} truncated={true}
                                   isMultiline={true}
                                   dismiss={false}
                                   setShowMessage={undefined}>
                        <Text variant={"medium"}>{t('messages.infoEmail', {ns: 'recordFormLink'})}</Text>
                    </StatusMessage>
                }
                {messageSent === 1 &&

                    <StatusMessage status={"success"} hasTimer={false} truncated={true}
                                   isMultiline={true}
                                   dismiss={true}
                                   setShowMessage={() => {
                                       setMessageSent(0)
                                   }}>
                        <Text variant={"medium"}>{t('messages.successSent', {ns: 'recordFormLink'})}</Text>
                    </StatusMessage>
                }
                {messageSent === -1 &&

                    <StatusMessage status={"error"} hasTimer={false} truncated={true}
                                   isMultiline={true}
                                   dismiss={true}
                                   setShowMessage={() => {
                                       setMessageSent(0)
                                   }}>
                        <Text variant={"medium"}>{t('messages.errorSend', {
                            ns: 'recordFormLink',
                            Description: txtMessageError
                        })}</Text>
                    </StatusMessage>
                }
                <Separator/>
            </StackItem>
        )
    }

    /**
     * Footer
     * @constructor
     */
    const Footer = () => {
        return (
            <>
                {/*-- START FOOTER --*/}
                <Separator></Separator>
                <Stack horizontal style={{overflow: "hidden"}} styles={nonShrinkingStackItemStyles}>
                    <StackItem grow={1}>&nbsp;</StackItem>

                    {isSaving && !showShareConfirm &&
                        <>
                            <StackItem>
                                <Spinner size={SpinnerSize.medium} labelPosition="left"
                                         label={t('text.spinnerSending', {ns: 'common'})}/>
                            </StackItem>
                        </>
                    }

                    <StackItem>

                        <PrimaryButton
                            disabled={isSaving || isSent || isLoadingEmailService || isLoadingPeople || isLoadingAttachments}
                            style={{marginLeft: 8}}
                            onClick={ValidateEmails}
                            text={t('Common.Button.Send', {ns: 'common'})}
                            title={t('title.btnSend', {ns: 'recordFormLink'})}/>


                        <DefaultButton
                            onClick={() => {
                                onBeforeDismiss()
                            }}
                            style={{marginLeft: 8}}
                            text={t('Common.Button.Close', {ns: 'common'})}
                            title={t('Common.Button.Close', {ns: 'common'})}/>
                    </StackItem>
                </Stack>
                {/*-- END FOOTER --*/}
            </>
        )
    }

    /**
     * FormPublishLink
     * @constructor
     */
    const FormPublishLink = () => {
        return (<>


                {isLoadingEmailService || isLoadingPeople || isLoadingAttachments ?
                    <>
                        <Shimmer styles={{root: {display: "block", paddingTop: 8}}}/>
                        <Separator/>
                        <Shimmer styles={{root: {display: "block", paddingTop: 8}}}/>
                        <Shimmer styles={{root: {display: "block", paddingTop: 8}}}/>
                        <Shimmer styles={{root: {display: "block", paddingTop: 8}}}/>
                        <Separator/>
                        <Shimmer height={16} styles={{root: {display: "block", paddingTop: 8}}}/>
                    </>
                    :
                    <>

                        <Pivot aria-label={t('ariaLabel.pivotEmail', {ns: 'recordFormLink'})}>
                            <PivotItem headerText={t('label.pivotEmail', {ns: 'recordFormLink'})}>
                                <FormProvider {...formPublishWebform}>
                                    <form>

                                        <TextField readOnly={true} disabled
                                                   label={t('label.emailFrom', {ns: 'recordFormLink'})}
                                                   value={fromApplicationServiceEmail}/>
                                        <RHFPeoplePickerControl
                                            readOnly={isSaving}
                                            label={t('label.emailTo', {ns: 'recordFormLink'})}
                                            pickerSuggestionsProps={suggestionProps}
                                            control={formPublishWebform.control}
                                            setValue={formPublishWebform.setValue}
                                            getValues={formPublishWebform.getValues}
                                            trigger={formPublishWebform.trigger}
                                            id={"to"}
                                            rules={{
                                                required: t('text.fieldRequired', {ns: 'common'})

                                            }}
                                            onCallback={(personas: IPersonaProps[]) => {
                                                setSelectedPeopleList(personas)
                                            }}
                                            peopleList={peopleList} selectedPeopleList={selectedPeopleList}
                                            setSelectedPeopleList={setSelectedPeopleList}

                                        />


                                        <RHFTextBoxControl id="txtSubject"
                                                           disabled={isSaving}
                                                           readOnly={isSaving}
                                                           control={formPublishWebform.control}
                                                           setValue={formPublishWebform.setValue}
                                                           getValues={formPublishWebform.getValues}
                                                           trigger={formPublishWebform.trigger}
                                                           label={t('label.emailSubject', {ns: 'recordFormLink'})}
                                                           title={t('title.emailSubject', {ns: 'recordFormLink'})}
                                                           placeholder={t('placeholder.emailSubject', {ns: 'recordFormLink'})}
                                                           rules={{
                                                               required: t('text.fieldRequired', {ns: 'common'}),
                                                               validate: {
                                                                   required:
                                                                       (value) => {
                                                                           return !!value.trim() || t('text.fieldRequired', {ns: 'common'})
                                                                       }
                                                               },
                                                               maxLength: 200
                                                           }}

                                        />
                                        <RHFTextBoxControl id="txtMessage"
                                                           disabled={isSaving}
                                                           readOnly={isSaving}
                                                           control={formPublishWebform.control}
                                                           setValue={formPublishWebform.setValue}
                                                           getValues={formPublishWebform.getValues}
                                                           trigger={formPublishWebform.trigger}
                                                           label={t('label.emailMessage', {ns: 'recordFormLink'})}
                                                           multiline
                                                           resizable={false}
                                                           rows={6} maxLength={5000}
                                                           rules={{
                                                               required: t('text.fieldRequired', {ns: 'common'}),
                                                               validate: {
                                                                   required:
                                                                       (value) => {
                                                                           return !!value.trim() || t('text.fieldRequired', {ns: 'common'})
                                                                       }
                                                               },
                                                               maxLength: 5000
                                                           }}
                                                           placeholder={t('placeholder.emailMessage', {ns: 'recordFormLink'})}
                                                           title={t('title.emailMessage', {ns: 'recordFormLink'})}/>

                                        <Label>{t('text.note', {ns: 'recordFormLink'})}</Label>

                                        <Separator/>
                                        <Stack horizontal styles={{root: {alignItems: "center"}}}>
                                            <StackItem tokens={{padding: 8}}>
                                                <Checkbox checked={chkDocument} id="checkDocument"
                                                          hidden={allowDocx ? false : true}
                                                          disabled={isSaving}
                                                          onChange={(ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) => {
                                                              setChkDocument(isChecked!)
                                                          }}
                                                          label={t('label.emailAttachment', {ns: 'recordFormLink'})}/>

                                            </StackItem>
                                            <StackItem grow={1}>

                                                <ChoiceGroup

                                                    hidden={(!(allowDocx && chkDocument))}
                                                    disabled={isSaving}
                                                    selectedKey={docType}
                                                    options={optionsAttachment}
                                                    className="inlineGroup"
                                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                                                        setDocType(option?.key!)
                                                    }}
                                                />
                                            </StackItem>

                                        </Stack>

                                    </form>
                                </FormProvider>
                            </PivotItem>

                            <PivotItem headerText={t('label.pivotAttachment', {ns: 'recordFormLink'})}>
                                <Stack grow={1} data-is-scrollable="true">
                                    <MarqueeSelection isEnabled={!isSaving} selection={selectionAttachment}>
                                        <DetailsList
                                            items={attachments}
                                            compact={false}
                                            columns={GetAttachmentsColumns(t)}
                                            getKey={getKey}
                                            setKey="set"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}

                                            selection={selectionAttachment}
                                            selectionPreservedOnEmptyClick={true}
                                            selectionMode={isSaving ? SelectionMode.none : SelectionMode.multiple}
                                            checkboxVisibility={CheckboxVisibility.always}

                                            onRenderRow={(props, defaultRender) => {

                                                return (
                                                    <div>
                                                        {defaultRender!({...props!, disabled: isSaving})}
                                                    </div>
                                                )
                                            }}

                                        />
                                    </MarqueeSelection>
                                </Stack>
                            </PivotItem>
                        </Pivot>


                    </>
                }


            </>
        )
    }

    return (
        <>


            <Modal
                isOpen={showShareConfirm}
                isBlocking={true}
                containerClassName={contentStyles(branding.theme).containerShare}
            >
                {/*-- START HEADER --*/}
                <Stack horizontal className={contentStyles(branding.theme).header}>


                    <StackItem grow={1}
                               styles={{
                                   root: {
                                       textOverflow: "ellipsis",
                                       whiteSpace: "nowrap",
                                       overflow: "hidden"
                                   }
                               }}>
                        <Text variant={"xLarge"} id={documentId}
                              title={t('title.publishLink', {ns: 'recordFormLink', name: modalTitle})}>
                            {t('title.publishLink', {ns: 'recordFormLink', name: modalTitle})}
                        </Text>
                    </StackItem>
                    <Stack horizontal styles={{root: {alignItems: "center"}}}>

                        <IconButton
                            styles={iconButtonStyles}
                            disabled={isSaving}
                            iconProps={cancelIcon}
                            ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                            onClick={() => {
                                onBeforeDismissShare()
                            }}
                            title={t('Common.Button.Close', {ns: 'common'})}
                        />
                    </Stack>
                </Stack>
                <Separator/>

                {/*-- END HEADER --*/}

                {/*-- START BODY --*/}
                <Stack tokens={{padding: 8}}>

                    <StackItem grow={1} tokens={{padding: 8}}>

                        <Text variant={"medium"}>

                            <Trans components={{1: <br/>}}>

                                {t('text.isShare', {ns: 'recordFormLink'})}
                            </Trans>

                        </Text>

                    </StackItem>

                    {/*-- START FOOTER --*/}
                    <Separator></Separator>
                    <Stack horizontal style={{overflow: "hidden"}} styles={nonShrinkingStackItemStyles}>
                        <StackItem grow={1}>&nbsp;</StackItem>

                        {isSaving &&
                            <>
                                <StackItem>
                                    <Spinner size={SpinnerSize.medium} labelPosition="left"
                                             label={t('text.spinnerSending', {ns: 'common'})}/>
                                </StackItem>
                            </>
                        }

                        <StackItem>

                            <PrimaryButton
                                disabled={isSaving || isSent || isLoadingEmailService || isLoadingPeople || isLoadingAttachments}
                                style={{marginLeft: 8}}
                                onClick={() => {
                                    SendEmail(selectedPeopleSharedList)
                                }}
                                text={t('Common.Button.Continue', {ns: 'common'})}
                                title={t('Common.Button.Continue', {ns: 'common'})}/>


                            <DefaultButton
                                onClick={() => {
                                    onBeforeDismissShare()
                                }}
                                disabled={isSaving}
                                style={{marginLeft: 8, marginRight: 8}}
                                text={t('Common.Button.Cancel', {ns: 'common'})}
                                title={t('Common.Button.Cancel', {ns: 'common'})}/>
                        </StackItem>
                    </Stack>
                    {/*-- END FOOTER --*/}
                </Stack>
                {/*-- END BODY --*/}
            </Modal>


            {!isMobile ?

                <Modal
                    isOpen={showModal}
                    isBlocking={true}
                    containerClassName={contentStyles(branding.theme).containerRecord}
                >
                    {/*-- START HEADER --*/}
                    <Stack horizontal className={contentStyles(branding.theme).header}>


                        <StackItem grow={1}
                                   styles={{
                                       root: {
                                           textOverflow: "ellipsis",
                                           whiteSpace: "nowrap",
                                           overflow: "hidden"
                                       }
                                   }}>
                            <Text variant={"xLarge"} id={documentId}
                                title={t('title.publishLink', {ns: 'recordFormLink', name: modalTitle, interpolation: { 'escapeValue': false }})}>
                                {t('title.publishLink', {ns: 'recordFormLink', name: modalTitle, interpolation: { 'escapeValue': false }})}
                            </Text>
                        </StackItem>
                        <Stack horizontal styles={{root: {alignItems: "center"}}}>
                            <HelpComponentRelative
                                headline={t('title.publishLinkHelp', {ns: 'recordFormLink'})}
                                text={t('text.help', {ns: 'recordFormLink'})}
                                location=""
                            />
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                                onClick={() => {
                                    onBeforeDismiss()
                                }}
                                title={t('Common.Button.Close', {ns: 'common'})}
                            />
                        </Stack>
                    </Stack>
                    <Separator/>

                    {/*-- END HEADER --*/}

                    {/*-- START BODY --*/}
                    <Stack horizontal tokens={{padding: 8}} style={{overflow: "hidden"}}>

                        {/*-- START CONTENT --*/}
                        <Stack grow={1} style={{overflow: "hidden", padding: "0px 8px 8px 8px"}}>

                            <Messages/>
                            <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                                   style={{
                                       overflow: "hidden",
                                       overflowY: "auto",
                                       height: "50vh",
                                   }}>
                                <FormPublishLink/>
                            </Stack>
                            <Footer/>
                        </Stack>
                        {/*-- END CONTENT --*/}

                    </Stack>
                    {/*-- END BODY --*/}

                </Modal>

                :
                <>
                    {/*-- START PANEL --*/}
                    <Panel

                        headerText={t('title.publishLink', {ns: 'recordFormLink', name: modalTitle})}
                        headerTextProps={panelHeader}
                        isBlocking={false}
                        isOpen={showModal}
                        onDismiss={onBeforeDismiss}
                        onRenderHeader={(props?: IPanelProps): JSX.Element | null => {
                            return (<>
                                <Stack horizontal styles={{root: {width: "100%"}}}>
                                    <StackItem grow={1} id={props?.id}
                                               styles={{root: {paddingLeft: 24, paddingRight: 24}}}>
                                        <Text variant={"xLarge"}
                                              styles={{root: {fontWeight: 500, color: branding.theme.palette.themePrimary}}}>
                                            {props?.headerText}
                                        </Text>

                                    </StackItem>
                                    <StackItem>
                                        <HelpComponentRelative
                                            headline={t('title.publishLinkHelp', {ns: 'recordFormLink'})}
                                            text={t('text.help', {ns: 'recordFormLink'})}
                                            location=""
                                        />
                                    </StackItem>

                                </Stack>


                            </>)
                        }}
                        closeButtonAriaLabel={t('Common.Button.Close', {ns: 'common'})}
                        type={PanelType.smallFluid}
                        styles={{
                            content: {
                                display: "flex",
                                flexGrow: 1
                            },
                            scrollableContent: {
                                display: "flex",
                                flexGrow: 1
                            }

                        }}
                    >
                        <Stack grow={1}>
                            <Stack grow={0}>
                                <Separator/>
                            </Stack>
                            <Messages/>
                            <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                                   style={{
                                       overflow: "hidden",
                                       overflowY: "auto",
                                       height: "50vh",
                                   }}>
                                <FormPublishLink/>
                            </Stack>
                            <Footer/>
                        </Stack>
                    </Panel>
                    {/*-- END PANEL --*/}
                </>

            }


        </>
    )
}

export default FormDocumentLink
