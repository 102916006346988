import React from 'react'
import {useFormAssembly} from "./hooks/useFormAssembly"
import {DefaultButton, DefaultEffects, FontIcon, FontSizes, FontWeights, Label, PrimaryButton, Separator, Stack, Text} from '@fluentui/react'
import {conditionColor, falseClauseColor, FormRuleEditMode, trueClauseColor} from '../../../interfaces/IFormAssembly'
import {useBranding} from "../../../hooks/useBranding";

type EditModeBarProps = {
    handleFormAssemblySteps: () => void
}
export default function EditModeBar({handleFormAssemblySteps}: EditModeBarProps) {

    const {editMode, resetEditMode, setEditMode} = useFormAssembly()

    const resetSelected = () => {
        setEditMode(mode => {
            return {...mode, contentControls: []}
        })
    }
    return (
        <>
            {
                editMode.type && editMode.showBar &&
                <EditBar editMode={editMode} resetEditMode={resetEditMode}
                         resetSelected={resetSelected} handleFormAssemblySteps={handleFormAssemblySteps}/>
            }
        </>
    )
}
type EditBarProps = {
    editMode: FormRuleEditMode
    resetSelected?: () => void
    resetEditMode: () => void
    handleFormAssemblySteps?: () => void
}
const EditBar = ({editMode, resetEditMode, handleFormAssemblySteps}: EditBarProps) => {
    const {branding} = useBranding()

    return <>

        <Stack horizontal styles={{
            root: {
                height: 'auto',
                alignItems: "center",
                paddingRight: 8,
                paddingLeft: 8,
            }
        }}>

            <Stack grow={true} styles={{root: {alignItems: 'center', width: 1}}}>
                {editMode.rule?.disabled !== undefined && editMode.rule?.disabled &&
                    <>
                        <Text variant={"medium"} block title={editMode.rule?.ruleName}
                              styles={{
                                  root: {
                                      width: '100px',
                                      textAlign: "center",
                                      backgroundColor: branding.theme.palette.neutralTertiary,
                                      color: branding.theme.palette.white,
                                      borderRadius: 4
                                  }
                              }}>

                            {"Disabled rule"}
                        </Text>
                    </>
                }

                <Text variant={"large"} title={editMode.rule?.ruleName}
                      styles={{
                          root: {
                              fontWeight: FontWeights.semibold,
                              textAlign: "center",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden", width: '100%'
                          }
                      }}>

                    {editMode.rule?.ruleName}
                </Text>

                {editMode.rule?.description !== undefined &&
                    <Text variant={"medium"} title={editMode.rule?.description}
                          styles={{
                              root: {
                                  textAlign: "center",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",

                                  overflow: "hidden", width: '100%',
                                  fontStyle: "italic",

                              }
                          }}>

                        {editMode.rule?.description}
                    </Text>
                }

                <Stack horizontal grow={true} tokens={{childrenGap: 16}}
                       styles={{root: {justifyContent: "center"}}}>

                    <Stack>
                        <Text block variant={"medium"}>
                            <FontIcon iconName="RectangleShapeSolid" style={{color: conditionColor}}/> Conditions:{" "}
                            {editMode.rule?.conditions.length} rules
                        </Text>
                    </Stack>

                    <Stack>
                        <Text block variant={"medium"}>
                            <FontIcon iconName="RectangleShapeSolid" style={{color: trueClauseColor}}/> Actions:{" "}
                            {editMode.rule?.actions.filter((item) => item.type === 'field').length} expressions, {editMode.rule?.actions.filter((item) => item.type === 'tab').length} pages
                        </Text>
                    </Stack>

                </Stack>

            </Stack>
            <Stack horizontal wrap={false}>
                <PrimaryButton onClick={() => handleFormAssemblySteps?.()}
                               styles={{root: {marginRight: 8}}}>
                    Edit
                </PrimaryButton>

                <DefaultButton onClick={resetEditMode}>
                    Cancel
                </DefaultButton>
            </Stack>
        </Stack>
        <Separator/>
    </>
}
