import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Sizes} from "../../share/CustomModal";
import {useModal} from "../../hooks/useModal";
import {_styles, defaultStatistics, nonShrinkingStackItemStyles} from "./index";
import {DefaultButton, Separator, Spinner, SpinnerSize, Stack, StackItem, Text} from "@fluentui/react";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import {useBranding} from "../../hooks/useBranding";
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import {FontSizes, FontWeights} from "@fluentui/react/lib/Styling";
import DocumentService, {
    IDocumentOnlyStatisticsRequest,
    IDocumentOnlyStatisticsResponse
} from "../../services/document";
import UserService, {IDeleteRegistrationRequest} from "../../services/User";
import PlanService, {IPlanResponse} from "../../services/Plan";
import Helper from "../../Helpers/Helper";
import ManageClientPlansService, {
    IGetAllManageClientPlansRequest,
    IGetAllManageClientPlansResponse
} from "../../services/ManageClientPlans";
import useAuth from "../../hooks/useAuth";
import PaymentService, {ICancelSubscriptionRequest} from "../../services/Payment";

type Properties = {
    showModal: boolean
    setShowModal: (status: boolean) => void
}
export const DeleteAccount = ({setShowModal, showModal}: Properties) => {

    const {t} = useTranslation(['common', 'profile'])
    const {branding} = useBranding()
    const myModal = useModal()
    const [message, setMessage] = useState("")
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)
    const [statistics, setStatistics] = useState<IDocumentOnlyStatisticsResponse>(defaultStatistics)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [planList, setPlanList] = useState<IPlanResponse[]>([]);
    const [manageClientPlan, setManageClientPlan] = useState<IGetAllManageClientPlansResponse>();
    const {logout} = useAuth();

    useEffect(() => {
        if (showModal) {
            myModal.open()
            init().then()
        }
    }, [showModal])


    /**
     * Close modal
     */
    const closeModal = () => {
        myModal.close()
        setShowModal(false)
    }

    /**
     * Init
     */
    async function init() {

        setIsLoading(true)
        setIsLoaded(false)
        setIsDeleting(false)
        setMessage("")
        setShowMessage(false)
        setStatusMessageEnum(StatusEnum.Info)

        let promises = [];

        // DocumentOnlyStatistics
        const request: IDocumentOnlyStatisticsRequest = {
            TemplateId: ""
        }

        promises.push(
            DocumentService.DocumentOnlyStatistics(request)
                .then((data) => {
                    if (data.length > 0) {
                        setStatistics(data[0])
                        if (data[0].Creator_Open_Documents !== 0 || data[0].Audience_Open_Documents !== 0) {
                            setMessage(t('label.unableDelete', {
                                ns: 'profile'
                            }))
                            setStatusMessageEnum(StatusEnum.Warning)
                            setShowMessage(true)
                        } else {
                            setMessage(t('label.confirmDelete', {
                                ns: 'profile'
                            }))
                            setStatusMessageEnum(StatusEnum.Info)
                            setShowMessage(true)
                        }
                    } else {
                        throw new Error(t('message.notFound', {ns: 'common'}))
                    }

                })
                .catch((error) => {
                    throw error

                })
        )

        promises.push(
        // Load all plan
        PlanService.GetAllPlan()
            .then((data) => {
                setPlanList(data)
            })
            .catch((error) => {
                throw error
            })
            .finally()
        )

        // Load Manage Client plans        
        const request2: IGetAllManageClientPlansRequest = {
            ownerName: Helper.getUsername()
        }

        promises.push(
        ManageClientPlansService.GetAllManageClientPlans(request2)
            .then((data) => {
                if (data.length > 0) {
                    setManageClientPlan(data[0])
                } else {
                    throw new Error(t('message.notFound', {ns: 'common'}))
                }
            })
            .catch((error) => {
                throw error
            })
            .finally()
        )

        // call all promises
        await Promise.all(promises)
            .then((result) => {
                setIsLoaded(true)
            })
            .catch((error: any) => {
                setMessage(error.toString())
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /**
     * Delete user
     * @constructor
     */
    const DeleteUser = async () => {
        setIsDeleting(true)
        const request: IDeleteRegistrationRequest = {
            onBehalfUser: ""
        }
        await UserService.DeleteRegistration(request)
            .then(async (res: any) => {
                if (res.Success) {
                    await CancelSubscription()
                }
            })
            .catch((error: any) => {
                setMessage(error.toString())
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
            })
            .finally(() => {
                setIsDeleting(false)
            })
    }

    /**
     * logOut
     */
    const logOut = () => {
        sessionStorage.clear()

        // var n = sessionStorage.length;
        // while (n--) {
        //     var key = sessionStorage.key(n);
        //     sessionStorage.removeItem(key!);
        //     sessionStorage.clear()
        // }
        logout()
    }

    /**
     * Cancel Subscription
     * @constructor
     */
    async function CancelSubscription() {
        const currentPlan = planList.filter(p => p.Id == manageClientPlan?.BillingPlanId)[0]
        const _plan = await Helper.getDefaultPlan()
        if (currentPlan.Name.toLowerCase().trim() !== _plan) {

            if (manageClientPlan !== undefined && manageClientPlan.SubscriptionId !== "-1") {

                const request: ICancelSubscriptionRequest = {
                    BillingPlanId: manageClientPlan.BillingPlanId,
                    OwnerName: Helper.getUsername(),
                    PlanName: currentPlan.Name,
                    SubscriptionId: manageClientPlan?.SubscriptionId
                }
                await PaymentService.cancelSubscription(request)
                    .then(() => {
                        closeModal()
                        logOut()
                    })
                    .catch((error) => {
                        setShowMessage(true)
                        setStatusMessageEnum(StatusEnum.Error)
                        setMessage(error.toString)
                    })
                    .finally()
            } else {
                closeModal()
                logOut()
            }
        } else {
            closeModal()
            logOut()
        }
    }

    return (
        <>
            {/*region MODAL TEMPLATE*/}
            <myModal.Modal
                title={t('title.accountStatistics', {'ns': 'profile'})}
                titleId="AccountStatistics" size={Sizes.xs}
                bodyClassName={_styles(branding.theme).body}
                afterClose={closeModal}
            >
                <>
                    {/*region CONTENT*/}
                    <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                           style={{
                               overflow: "hidden",
                               overflowY: "auto",
                               height: "50vh",
                               padding: "8px 16px 24px 16px"
                           }}>
                        <>

                            {/*region MESSAGES*/}
                            {showMessage &&
                                <StackItem styles={{root: {paddingBottom: 16}}}>
                                    <>
                                        <StatusMessage status={statusMessageEnum} hasTimer={false}
                                                       truncated={true}
                                                       isMultiline={true}
                                                       dismiss={true}
                                                       setShowMessage={setShowMessage}>
                                            <div>{message}</div>
                                        </StatusMessage>
                                    </>
                                </StackItem>
                            }
                            {/*endregion*/}


                            {isLoading &&
                                <Stack grow={1} styles={{root: {justifyContent: "center"}}}>
                                    <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})}
                                             ariaLive="assertive"/>
                                </Stack>
                            }

                            {!isLoading && isLoaded &&
                                <>
                                    <Stack>
                                        {statistics.FullName !== undefined && statistics.FullName.trim().length > 0 ?
                                            <Text
                                                variant={"medium"} styles={{root: {fontWeight: FontWeights.semibold}}}
                                                title={`${statistics.FullName} (${statistics.UserName}):`}>
                                                {`${statistics.FullName} (${statistics.UserName}):`}
                                            </Text>
                                            :
                                            <Text
                                                variant={"medium"} styles={{root: {fontWeight: FontWeights.semibold}}}
                                                title={`${statistics.UserName}:`}>
                                                {`${statistics.UserName}:`}
                                            </Text>
                                        }
                                        <Text variant={"medium"}
                                              styles={{root: {paddingTop: 8, fontWeight: FontWeights.semibold}}}>
                                            {t('label.having', {ns: 'profile'})}
                                        </Text>
                                    </Stack>


                                    <Stack styles={{root: {padding: "16px 0px 8px 0px"}}}>

                                        <Stack horizontal tokens={{childrenGap: 8}}>
                                            <Stack styles={{root: {justifyContent: "center"}}}>
                                                <i className="ms-Icon ms-Icon--TextDocument" aria-hidden="true"
                                                   style={{fontSize: FontSizes.size18}}/>
                                            </Stack>

                                            <Stack grow={1}>
                                                <Separator alignContent="start"
                                                           styles={{
                                                               root: {
                                                                   fontWeight: FontWeights.semibold,
                                                                   justifyContent: "center"
                                                               }
                                                           }}>


                                                    {t('label.openDocuments', {
                                                        ns: 'profile',
                                                        count: statistics.Creator_Open_Documents + statistics.Audience_Open_Documents + statistics.Share_Open_Documents
                                                    })}
                                                </Separator>
                                            </Stack>
                                        </Stack>

                                        <Text
                                            block
                                            variant={"medium"}
                                            styles={{
                                                root: {
                                                    padding: "4px 40px",
                                                    color: statistics.Creator_Open_Documents > 0 ? branding.theme.semanticColors.errorText : "inherit"
                                                }
                                            }}
                                        >
                                            {t('label.openDocuments_by_Me', {
                                                ns: 'profile',
                                                count: statistics.Creator_Open_Documents
                                            })}
                                        </Text>

                                        <Text
                                            block
                                            variant={"medium"}
                                            styles={{
                                                root: {
                                                    padding: "4px 40px",
                                                    color: statistics.Creator_Open_Documents > 0 ? branding.theme.semanticColors.errorText : "inherit"
                                                }
                                            }}
                                        >
                                            {t('label.openDocuments_by_Others', {
                                                ns: 'profile',
                                                count: statistics.Audience_Open_Documents
                                            })}
                                        </Text>

                                        <Text
                                            block
                                            variant={"medium"}
                                            styles={{root: {padding: "4px 40px"}}}
                                        >
                                            {t('label.openDocuments_Shared', {
                                                ns: 'profile',
                                                count: statistics.Share_Open_Documents
                                            })}
                                        </Text>


                                    </Stack>

                                    <Stack styles={{root: {padding: "16px 0px 8px 0px"}}}>

                                        <Stack horizontal tokens={{childrenGap: 8}}>
                                            <Stack styles={{root: {justifyContent: "center"}}}>
                                                <i className="ms-Icon ms-Icon--ProtectedDocument" aria-hidden="true"
                                                   style={{fontSize: FontSizes.size18}}/>
                                            </Stack>

                                            <Stack grow={1}>
                                                <Separator alignContent="start"
                                                           styles={{
                                                               root: {
                                                                   fontWeight: FontWeights.semibold,
                                                                   justifyContent: "center"
                                                               }
                                                           }}>


                                                    {t('label.closeDocuments', {
                                                        ns: 'profile',
                                                        count: statistics.Audience_Closed_Documents + statistics.Creator_Closed_Documents + statistics.Share_Closed_Documents
                                                    })}
                                                </Separator>
                                            </Stack>
                                        </Stack>


                                        <Text
                                            block
                                            variant={"medium"}
                                            styles={{
                                                root: {
                                                    padding: "4px 40px",
                                                }
                                            }}
                                        >
                                            {t('label.closeDocuments_by_Me', {
                                                ns: 'profile',
                                                count: statistics.Creator_Closed_Documents
                                            })}
                                        </Text>

                                        <Text
                                            block
                                            variant={"medium"}
                                            styles={{
                                                root: {
                                                    padding: "4px 40px",
                                                }
                                            }}
                                        >
                                            {t('label.closeDocuments_by_Others', {
                                                ns: 'profile',
                                                count: statistics.Audience_Closed_Documents
                                            })}
                                        </Text>

                                        <Text
                                            block
                                            variant={"medium"}
                                            styles={{
                                                root: {
                                                    padding: "4px 40px",
                                                }
                                            }}
                                        >
                                            {t('label.closeDocuments_Shared', {
                                                ns: 'profile',
                                                count: statistics.Share_Closed_Documents
                                            })}
                                        </Text>

                                    </Stack>

                                    <Stack styles={{root: {padding: "16px 0px 8px 0px"}}}>

                                        <Text variant={"medium"} styles={{root: {fontWeight: FontWeights.semibold}}}>
                                            {t('label.being', {ns: 'profile'})}
                                        </Text>


                                        <Text
                                            block
                                            variant={"medium"}
                                            styles={{
                                                root: {
                                                    padding: "4px 40px",
                                                }
                                            }}
                                        >
                                            {t('label.owner', {
                                                ns: 'profile',
                                                count: statistics?.Template_Owner
                                            })}
                                        </Text>

                                        <Text
                                            block
                                            variant={"medium"}
                                            styles={{
                                                root: {
                                                    padding: "4px 40px",
                                                }
                                            }}
                                        >
                                            {t('label.coowner', {
                                                ns: 'profile',
                                                count: statistics?.Template_Co_Owner
                                            })}
                                        </Text>

                                        <Text
                                            block
                                            variant={"medium"}
                                            styles={{
                                                root: {
                                                    padding: "4px 40px",
                                                }
                                            }}
                                        >
                                            {t('label.member', {
                                                ns: 'profile',
                                                count: statistics?.Template_Member
                                            })}
                                        </Text>
                                    </Stack>


                                </>

                            }
                        </>

                    </Stack>
                    {/*endregion*/}

                    {/*region FOOTER*/}
                    <Separator styles={{root: {marginBottom: -8}}}></Separator>
                    <Stack horizontal style={{overflow: "hidden"}} disableShrink tokens={{childrenGap: 8}}
                           styles={nonShrinkingStackItemStyles}>
                        <StackItem grow={1}>&nbsp;</StackItem>
                        {isDeleting &&
                            <>
                                <StackItem>
                                    <Spinner
                                        size={SpinnerSize.medium} labelPosition="left"
                                        label={t('text.spinnerDeleting', {ns: 'common'})}/>
                                </StackItem>
                            </>
                        }
                        <Stack horizontal wrap tokens={{childrenGap: 8}}>
                            <Stack>
                                <PrimaryButton
                                    onClick={DeleteUser}
                                    text={t('Common.Button.Delete', {'ns': 'common'})}
                                    disabled={isDeleting || !isLoaded || (statistics.Creator_Open_Documents !== 0 || statistics.Audience_Open_Documents !== 0)}
                                    title={t('title.Delete', {'ns': 'profile'})}/>
                            </Stack>
                            <Stack>
                                <DefaultButton
                                    onClick={closeModal}
                                    disabled={isDeleting}
                                    text={t('Common.Button.Close', {ns: 'common'})}
                                    title={t('Common.Button.Close', {ns: 'common'})}/>
                            </Stack>
                        </Stack>
                    </Stack>
                    {/*endregion*/}


                </>
            </myModal.Modal>
            {/*endregion*/}
        </>
    )
}

