import { ActionButton, DialogFooter, IButtonStyles, IconButton, IIconProps, IMessageBarStyles, MessageBar, Modal, PrimaryButton, ProgressIndicator, Spinner, SpinnerSize, TextField } from "@fluentui/react"
import React, { useEffect, useState } from "react"
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { isMobile } from "react-device-detect";
import { decode } from "base64-arraybuffer";
import { useConstCallback } from '@uifabric/react-hooks';
import Helper from "../../Helpers/Helper";
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";
const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const CSVlIcon: IIconProps = { iconName: 'ExcelDocument' };


const CSVlIconStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.black,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',

    },
    
    icon: {
        color: theme.palette.greenDark,
        fontSize: '20px'
    },
    iconHovered: {
        color: theme.palette.greenDark
    },
};

function CampaignUserLists(props: any) {
    const { branding } = useBranding()
    const [message, setMessage] = useState("");
    const [showMessage, setShowMessage] = useState(false)
    const [applying, setApplying] = useState(false);
    const [requiredUploadFile, setRequiredUploadFile] = useState(false)
    const [uploadingFile, setUploadingFile] = useState(false);
    const [fileName, setFileName] = useState("");
    const [showSuccess, setShowSuccess] = useState(false)
    const [base64File, setBase64File] = useState<string>("");
    const [messageErrorListCSV, setMessageErrorListCSV] = useState("")
    const [showMessageListCSV, setshowMessageListCSV] = useState(false)
    const [file, setFile] = useState<File>();
    const [campaignMasterId, setCampaignMasterId] = useState(0)
    const [userListSaved, setuserListSaved] = useState(false)
    const hideUploadUserList = () => {
        setMessage("")
        setShowMessage(false)
        setShowSuccess(false)
        setFileName("")
        setApplying(false)
        setMessageErrorListCSV("")
        setshowMessageListCSV(false)
        setShowSuccess(false)
        setMessage("")
        props.hideUploadUserList(false)
    }

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '50vw',
            height: '50vh',
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        listDialog: {
            width: isMobile ? null : '30vw',
            height: '50vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        addItemList: {
            width: isMobile ? null : '25vw',
            height: '25vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        buttonArea: {
        },
        callout: {
            maxWidth: 300,
        },
        inner: {
            height: '100%',
            padding: '0 24px 20px',
        }
    });


    useEffect(() => {
        if (props.CampaignMasterId !== undefined && props.CampaignMasterId > 0) {
            setCampaignMasterId(props.CampaignMasterId)
        }
    }, [])

    const DownloadExample = () => {
        const download = async () => {
            const name = "Example.csv"
            var Base64 = { blob: "77u/VXNlcm5hbWUsRmlyc3ROYW1lLExhc3ROYW1lLFBob25lTnVtYmVyDQpkZW1vQG15LmNvbSwgRGVtbywgRGVtbywgNTU5OTk5OTkNCmRlbW8xQG15LmNvbSwgRGVtbywgRGVtbywgNTU5OTk5OTkNCmRlbW8yQG15LmNvbSwgRGVtbywgRGVtbywgNTU5OTk5OTkNCg==" }
            const decoded = decode(Base64.blob)
            try {
                const file = new Blob(
                    [decoded], { type: "application/vnd.ms-excel" });
                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(file);
                a.download = name;
                a.click();
                setShowSuccess(true)
                setMessage("Example successfully downloaded. Please check Downloads folder!")
                setTimeout(function () {
                    setShowSuccess(false);
                    setMessage("")
                }, 3000);
            } catch (error) {
                console.log(error)

            }
        }
        download()
    }
    const getBase64 = (file: File) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64File(reader.result!.toString().split(',')[1]);
        };
        reader.onloadend = () => {
            setRequiredUploadFile(true)
        }

    }

    const uploadFile = useConstCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        setUploadingFile(true)
        if (ev.target.files != null && ev.target.files.length > 0) {
            setMessageErrorListCSV("")
            setshowMessageListCSV(false)
            var sizevalid = 500
            var mimeType = ev.target.files![0].type;
            var fileSize = ev.target.files![0].size
            var Sizekb = fileSize / 1024
            let IsValidSize = Sizekb <= sizevalid
            if ((mimeType.indexOf("vnd.ms-excel") >= 0 || mimeType.indexOf(".txt") > 0 || mimeType.indexOf("text/csv") >= 0) && IsValidSize) {
                var name = ev.target.files![0].name;
                setRequiredUploadFile(true)
                setFileName(name);
                setFile(ev.target.files![0])
                getBase64(ev.target.files![0]);

            }
            else {
                setshowMessageListCSV(true)
                setMessageErrorListCSV("This type of file is not valid. Must be a CSV with a .csv extenstion.")
                setRequiredUploadFile(false)
            }

        }
        else {
            setShowMessage(true)
            setMessage("File not found")
            setRequiredUploadFile(false)
        }
        setUploadingFile(false)
    });
    async function goToAddUserListCSV() {
        if (props.CampaignMasterId > 0) {
            setRequiredUploadFile(true)
            setApplying(true)

            const request = {
                CampaignMasterId: props.CampaignMasterId.toString(),
                Uri: Helper.getDefaultlocation(),
                Base64File: base64File,
            }

            await HttpRequest.postWithSession('CreateCampaignDetailBatch', request)
                .then((result: any) => {
                    const response = result.data
                    if (response.ErrorDescription !== undefined && response.ErrorDescription === "") {
                        setApplying(false)
                        setMessageErrorListCSV("")
                        setshowMessageListCSV(false)
                        setShowSuccess(true)
                        setMessage("User list saved successfully!")
                        //props.loadUsers(props.audienceMasterId)
                        setuserListSaved(true)
                        setTimeout(function () {
                            hideUploadUserList()
                        }, 2000);

                    } else if (response.ErrorDescription !== undefined) {
                        setApplying(false)
                        setShowSuccess(false)
                        setMessage("")
                        setuserListSaved(false)
                        setMessageErrorListCSV(response.ErrorDescription)
                        setshowMessageListCSV(true)
                        setRequiredUploadFile(false)
                    } else {
                        setShowSuccess(false)
                        setMessage("Internal Server error")
                        setApplying(false)
                        setuserListSaved(false)
                        setMessageErrorListCSV(response.ExceptionMessage)
                        setshowMessageListCSV(true)
                        setRequiredUploadFile(false)
                    }
                })
                .catch(() => {
                    setShowSuccess(false)
                    setMessage("Internal Server error")
                    setApplying(false)
                    setuserListSaved(false)
                    setMessageErrorListCSV("Internal Server error")
                    setshowMessageListCSV(true)
                    setRequiredUploadFile(false)
                })
                .finally(() => {
                })    
        }
    }
    return (
        <>
            <Modal
                isOpen={props.showUploadUserList}
                isBlocking={true}>
                <div className={contentStyles.header}>
                    <span id="CloseUploadList" key="CloseUploadList">{"Upload Respondent List"}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup "
                        onClick={() => {
                            hideUploadUserList()
                        }}
                        title="Close."
                    />
                </div>
                <div className={contentStyles.addItemList}>
                    <div className="ms-Grid" dir="ltr">
                        {!applying ?
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {showMessageListCSV ? <MessageBar styles={messageBarFailedStyles}>{messageErrorListCSV}</MessageBar> : null}
                                    {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                    <div className="ms-Grid-row">
                                        <br />
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{ textAlign: "right" }}></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{ textAlign: "right" }}>
                                            <ActionButton title="Download the example user list to use as a reference." text="Example list" styles={CSVlIconStyles} onClick={DownloadExample} iconProps={CSVlIcon} />
                                        </div>
                                        <br />
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <span>
                                                <ul>
                                                    <li>The moment you Upload a User List or Add Users they will immediately receive an email to participate in the Campaign so please make sure that you have taken all other actions before completing this step</li>
                                                    <li>Lists are limited to 2000 rows.</li>
                                                    <li>Lists must be a CSV text file.</li>
                                                    <li>Lists must be maximum size 500 KB.</li>
                                                    <li>Lists must be in our specified format. Click link for example.</li>
                                                    <li>Phone numbers are optional.</li>
                                                </ul>
                                            </span>
                                        </div>
                                        <br />
                                    </div>
                                    <br />
                                    <br />


                                    <div className="ms-Grid-row">

                                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                            <label htmlFor="file"><div className="divHover divToolBoxItem" style={{color: branding.theme.palette.white, backgroundColor: branding.theme.palette.themePrimary}}>Choose File</div></label>
                                            <input accept=".csv" className="fileButton" id="file" type="file" name="file" onChange={uploadFile} placeholder="Select file to upload." />
                                        </div>
                                        <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ paddingRight: "0px" }}>
                                            <TextField key="tesxtFilename" disabled value={fileName} title="File name." />
                                        </div>
                                    </div>
                                    {uploadingFile ?
                                        <ProgressIndicator description={"Uploading... "} />
                                        : null
                                    }
                                    <DialogFooter>
                                        <PrimaryButton disabled={!requiredUploadFile && props.CampaignMasterId > 0} onClick={goToAddUserListCSV} text="Upload" title="Upload selected list." />
                                    </DialogFooter>
                                    <br />
                                </div>
                            </div>
                            : <Spinner size={SpinnerSize.medium} label="Applying changes..." ariaLive="assertive" />
                        }
                    </div>
                </div>
            </Modal>
        </>


    )
}
export default CampaignUserLists