import { derivative } from 'mathjs';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactPaginateProps } from 'react-paginate';

export type PageItem = { index: number; name: string, disabled?: boolean };

type PaginationConfig = {
    items: PageItem[];
    pageCount: number;
    initialPage?: number;
    pageRangeDisplayed?: number;
    marginPagesDisplayed?: number;
    onClick: (data: any, callback: (result: number | boolean) => void) => void
} & Omit<ReactPaginateProps, "onClick">

type ChangeTypes = 'previous' | 'next' | 'prev-points' | 'next-points'
type CustomPaginateProps = PaginationConfig;

const PageList: React.FC<{
    currentPage: number;
    pageCount: number;
    pageRangeDisplayed: number;
    items: PageItem[];
    onPageChange: (item: PageItem, type?: ChangeTypes) => void;
    activeClassName: string;
}> = ({ currentPage, pageCount, pageRangeDisplayed, items, onPageChange, activeClassName }) => {
    const halfRange = Math.floor(pageRangeDisplayed / 2);
    let start = Math.max(0, currentPage - halfRange);
    let end = Math.min(pageCount - 1, start + pageRangeDisplayed - 1);

    // Adjust the start and end positions if needed
    if (end - start + 1 < pageRangeDisplayed) {
        start = Math.max(0, end - pageRangeDisplayed + 1);
    }

    return (
        <>
            {start > 0 && (
                <li className={`custom-pagination-item`} key={`start`} onClick={() => onPageChange(items[start - 1], 'prev-points')}>
                    <span>{"..."}</span>
                </li>
            )}
            {items.map((page, i) => {
                if (page.index >= start && page.index <= end) {

                    return (
                        <li
                            className={`custom-pagination-item ${page.disabled ? 'disabled' : ''} ${currentPage === page.index ? activeClassName : ''}`}
                            key={i + start}
                            onClick={() => onPageChange(page)}
                        >
                            <span>{page.name}</span>
                        </li>
                    )
                }
                return null;
            })}
            {end < pageCount - 1 && (
                <li className={`custom-pagination-item`} key={`end`} onClick={() => onPageChange(items[end + 1], 'next-points')}>
                    <span>{"..."}</span>
                </li>
            )}
        </>
    );
};



const CustomPaginate: React.FC<CustomPaginateProps> = (props) => {

    const { items, onClick, pageCount,
        initialPage = 0, pageRangeDisplayed = 2, marginPagesDisplayed = 3, forcePage } = props
    const { previousLabel, nextLabel, activeClassName = "active" } = props
    const [currentPage, setCurrentPage] = useState(initialPage);

    const handlePageChange = async (item: PageItem, type?: ChangeTypes) => {
        if (item.disabled && type === undefined)
            return
        const newPage = changePage(item.index, currentPage, type)
        if (currentPage === newPage)
            return
        if (!onClick) {
            setCurrentPage(newPage)
        }
        onClick?.({
            index: 1,
            selected: currentPage,
            nextSelectedPage: newPage,
            event: {},
            isPrevious: type === 'previous',
            isNext: type === 'next',
            isBreak: false,
            isActive: false
        }, (result) => {
            if (result === true) {
                setCurrentPage(newPage)
                return
            } else if (Number.isInteger(result)) {
                const page = changePage(Number(result), currentPage, type)
                setCurrentPage(page)
            }
        })

    }

    const changePage = (pageNumber: number, currentPage: number, type?: ChangeTypes) => {
        if (type === 'next' || type === 'next-points') {
            const nextItem = items.find(x => x.index >= pageNumber && !x.disabled === true)
            if (nextItem)
                return nextItem?.index
        }
        if (type === 'previous' || type === 'prev-points') {
            if (pageNumber === 0)
                return 0
            const prevItem = [...items].reverse().find(x => x.index <= pageNumber && !x.disabled === true)
            if (prevItem)
                return prevItem?.index
        }
        if (type !== undefined) {
            return currentPage
        }
        return pageNumber;
    }

    useEffect(() => {
        const page = items.find(x => x.index === forcePage)
        if (page)
            handlePageChange(page)
    }, [forcePage])

    const handleExtraButtons = (type: ChangeTypes) => {
        switch (type) {
            case "previous":
                const prev = items.find(x => x.index === currentPage - 1)
                if (prev)
                    handlePageChange(prev, 'previous')
                break
            case "next":
                const next = items.find(x => x.index === currentPage + 1)
                if (next)
                    handlePageChange(next, 'next')
                break;
        }
    }
    return (

        <ul className={props.containerClassName}>
            <li className={`custom-pagination-item ${currentPage === 0 ? 'disabled' : ''}`}
                onClick={() => handleExtraButtons('previous')}>
                {previousLabel}
            </li>
            <PageList
                currentPage={currentPage}
                pageCount={pageCount}
                pageRangeDisplayed={pageRangeDisplayed}
                items={items}
                activeClassName={activeClassName}
                onPageChange={handlePageChange}
            />
            <li className={`custom-pagination-item ${currentPage === pageCount - 1 ? 'disabled' : ''}`}
                onClick={() => handleExtraButtons('next')}>
                {nextLabel}
            </li>
        </ul>
    );
};

export default CustomPaginate;
