import { DefaultButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { Stack } from '@fluentui/react/lib/Stack';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';
import EditCategories from './EditCategories';
import {useBranding} from "../../hooks/useBranding";

const theme = getTheme();

const stackTokens = { childrenGap: 10 };

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

function EditCategoriesModal(props: any) {
    const { branding } = useBranding()
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            width: '40vw',
            height: '50vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            marginBottom: '64px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    return (
        <>
            <Modal
                allowTouchBodyScroll={false}
                isOpen={props.showEditCategories}
                isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.header}>
                                    <span id='EditCategories'>Edit Categories</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={() => { props.toggleEditCategories(false) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.body}>
                                    <EditCategories
                                        fetchCategories={true}
                                        reloadApp={props.reloadApp}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row" style={{ bottom: "24px", right: "24px", position: "absolute" }}>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    {/*<PrimaryButton onClick={() => { props.toggleEditCategories(false) }} text="Save" />*/ }
                                    <DefaultButton onClick={() => { props.toggleEditCategories(false) }} text="Close" />
                                </DialogFooter>
                            </div>
                        </div>
                    </div>
                </Stack>
            </Modal>
        </>
    )
}

export default EditCategoriesModal