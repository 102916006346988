import { decode } from "base64-arraybuffer";
import moment from "moment-with-locales-es6";
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useScroll } from "../../../../hooks/useScroll";
import { APIEventLog } from "../../../../interfaces/IAdminLog";
import AdminLogsService, { DEFAULT_RECORDSPERPAGE } from "../../../../services/adminlogs";
import { APILogsForm, FormReducerAction } from "../../AdminLogs.data";
import { formReducer, INITIAL_STATE } from "../reducers/formReducer";

type FilterContextType = {
    state: APILogsForm,
    dispatch: React.Dispatch<FormReducerAction>
}
const filterContext = React.createContext({} as FilterContextType)
export const FilterProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
    
    return (
        <filterContext.Provider value={{ state, dispatch}}>{children}</filterContext.Provider>
    )
}

const useFilterLogs = () => {
    const { state, dispatch } = useContext(filterContext)
    const scrollDiv = useRef<HTMLDivElement>(null)
    const { toTop } = useScroll({ scrollDiv })
    const pageNumber = useRef(1)
    const [logList, setLogList] = useState<APIEventLog[]>([]);
    const [hasMore, setHasMore] = useState(true)
    const [numberOfRecords, setNumberOfRecords] = useState(0)
    const [notResultsMessage, setNotResultsMessage] = useState("")

    const handleSearch = () => {
        pageNumber.current = 1
        AdminLogsService.getAPIEventLogs({ ...state, PageNumber: 1 }).then(({ data }: any) => {
            if (!data?.Success) {
                let response = data as APIEventLog[]
                setLogList(() => response)
                if (response.length > 0) {
                    setNumberOfRecords(() => response[0].RecordsAffected)
                    setNotResultsMessage("")
                }
                else {
                    setNumberOfRecords(() => 0)
                    setNotResultsMessage("Not Results Found!")
                }
                toTop()
            } else {
                console.log(data.ExceptionMessage)
            }
        })
    }

    const fetchMoreData = () => {
        pageNumber.current += 1

        AdminLogsService.getAPIEventLogs({ ...state, PageNumber: pageNumber.current }).then(({ data }: any) => {
            try {
                if (!data?.Success) {
                    let dataArray = data as APIEventLog[]
                    setLogList((prev) => { return [...prev, ...dataArray] })

                    if (dataArray.length < DEFAULT_RECORDSPERPAGE) {
                        setHasMore(false)
                    }
                } else {
                    console.log(data.ExceptionMessage)
                }
            } catch (error) {
                console.log(error)
            }

        })
    }

    useEffect(() => {
        AdminLogsService.getAPIEventLogs({
            ...INITIAL_STATE,
            PageNumber: pageNumber.current
        }).then(({ data }: any) => {
            if (!data?.Success) {
                let response = data as APIEventLog[]
                setLogList(() => response)
                if (response.length > 0) {
                    setNumberOfRecords(() => response[0].RecordsAffected)
                    setNotResultsMessage("")
                }
                else {
                    setNumberOfRecords(() => 0)
                    setNotResultsMessage("Not Results Found!")
                }
            } else {
                console.log(data.ExceptionMessage)
            }
        })
    }, [])

    const downloadExcel = async () => {
        AdminLogsService.downloadAPIEventLogs({ ...state }).then(({ data }: any) => {
            try {
                if (data.Success === undefined) {
                    const decoded = decode(data)
                    const fileName = `Data.xlsx`
                    const mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

                    const file = new Blob(
                        [decoded], { type: mimeType });
                    var a = document.createElement("a");
                    a.href = window.URL.createObjectURL(file);
                    a.download = fileName;
                    a.click();

                } else {
                    console.log(data.ExceptionMessage)
                }


            } catch (error) {
                console.log(error)
            }
        })
    }


    const getKey = (item: any, index?: number): string => {
        if (item !== undefined)
            return item.Id!.toString()
        return ""
    }
    const onFormatDate = (date?: Date): string => {
        return date !== null ? moment.utc(date).local().format('LL') : null;
    }
    const handleDateChange = (date: Date | null | undefined, state: string) => {
        dispatch({
            type: "CHANGE_DATE",
            payload: { name: state, value: date! },
        });
    }
    const handleTextFieldChange = (inputValue: string, state: string) => {
        dispatch({
            type: "CHANGE_INPUT",
            payload: { name: state, value: inputValue },
        });
    }
    const onTexfieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        const target = event.target as HTMLTextAreaElement;
        handleTextFieldChange(value || '', target.name);
    }

    return { filter: state, handleDateChange, handleTextFieldChange, onTexfieldChange, onFormatDate, scrollDiv, pageNumber, hasMore, numberOfRecords, logList, getKey, handleSearch, fetchMoreData, notResultsMessage, downloadExcel }
}

export default useFilterLogs;