import { ActionButton, DialogFooter,  IButtonStyles, IconButton, IDropdownOption, IIconProps, IMessageBarStyles, MessageBar, Modal, PrimaryButton, ProgressIndicator, Spinner, SpinnerSize, TextField } from "@fluentui/react"
import React, { useEffect, useState } from "react"
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { isMobile } from "react-device-detect";
import { store } from "react-notifications-component";
import { decode } from "base64-arraybuffer";
import { useBoolean,useConstCallback } from '@uifabric/react-hooks';
import Helper from "../../Helpers/Helper";
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";
const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};


const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
            class:'ms-Icon ms-Icon--Mail'
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});  
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
var classmodal = 'bodyModalMax';
function showNotification(Nmessage:string, Ntype:string)
{
 
  switch (Ntype) {
    case "success":
      store.addNotification({
        title: "Success",
        message: Nmessage,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      break;
      case "warning":
        store.addNotification({
          title: "Warning",
          message: Nmessage,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        break;
        case "error":
      store.addNotification({
        title: "Error",
        message: Nmessage,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      break;
      case "info":
      store.addNotification({
        title: "Information",
        message: Nmessage,
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      break;

    default:
      break;
  }

  
}
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const CSVlIcon: IIconProps = { iconName: 'ExcelDocument' };


const CSVlIconStyles: Partial<IButtonStyles> =  {
    root: {
        color: theme.palette.black,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    
    },
    
    icon:{
        color:theme.palette.greenDark,
        fontSize:'20px'
    },
    iconHovered:{
        color:theme.palette.greenDark
    },
};


export interface ITemplateAudience {
    templateId:string,
    Owners? :string[],
    CoOwner? :string[],
    groupName: string,
    audience: string
    documentCreator :string,
  }
  
   const TemplateAudience: ITemplateAudience[] = [];  

export interface ITemplates {
    templateId:number,
    userName:string
    name: string,
    expandAriaLabel: string,
    collapseAriaLabel: string,
    links:ITemplatesCustom[]
  }
  export interface ITemplatesCustom{
    isCustom:boolean,
    key:string
    name: string,
    expandAriaLabel: string,
    url: string
  }
  export interface IUsers {
    index?:number
    templateId:number,
    name: string,
    role: string
  }
  export interface ISMTemplate {
    TemplateId:number,
    CoAuthor: string[],
    Users: string[],
    Layout:any,
    Pages:any,
    Owner:string
  }

  export class Users implements IUsers { 
    templateId =-1
    name = "";
    role= "";
    index?=-1
    }
 export class SMTemplate implements ISMTemplate {      
    TemplateId=-1;
    CoAuthor=[""];
    Users=[""];
    Layout=null;
    Pages=null;
    Owner=""
    }

export interface IAllowedUser {
    Id: number
    AccountId: number
    TemplateId: number
    DocumentOnlyId: number
    UserName:string
}

export interface IItemList {
    Id: number
    DocumentOnlyListMasterId: number
    Key: string
    Value: string
}




export interface IAudience {
    Id: number
    DocumentOnlyAudienceMasterId: number
    Owner:boolean
    CoOwner:boolean
    CreateDocument:boolean
    Name:string
    Description:string
}
  


var optionsuser: IUsers[] = []
	
  var SMtemplatecustom = new SMTemplate() 
  var usersCoAuthor: string[]=[]
  var userspublic:string[]=[]  


let optionTemplateAudience: IDropdownOption[] = []

 
const stackTokens = { childrenGap: 15 };


function UserLists(props: any) {
    const { branding } = useBranding()
const [message, setMessage] = useState("");
const [showMessage, setShowMessage] = useState(false)
const [showMessageError, setshowMessageError] = useState(false) 
const [applying, setApplying] = useState(false);
const [requiredUploadFile, setRequiredUploadFile] = useState(false)
const [uploadingFile, setUploadingFile] = useState(false);
const [fileName, setFileName] = useState("");
const [showSuccess, setShowSuccess] = useState(false)   
const [base64File, setBase64File] = useState<string>("");

const [messageErrorListCSV, setMessageErrorListCSV] = useState("")
const [showMessageListCSV, setshowMessageListCSV] = useState(false)
const [file, setFile] = useState<File>(); 
const [audienceMasterIdUser, setaudienceMasterIdUser] = useState(0)
const [userListSaved, setuserListSaved] = useState(false)

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '50vw',
            height: '50vh',
            flex: '4 4 auto',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        listDialog: {
            width: isMobile ? null : '30vw',
            height: '50vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        addItemList: {
            width: isMobile ? null : '25vw',
            height: '25vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        buttonArea: {
        },
        callout: {
            maxWidth: 300,
        },
        inner: {
            height: '100%',
            padding: '0 24px 20px',
        }
    });
    
const hideUploadUserList = () => {
    setMessage("")
    setShowMessage(false)
    setShowSuccess(false)
    setFileName("")
    setApplying(false)  
    setMessageErrorListCSV("")
    setshowMessageListCSV(false)
    setShowSuccess(false)
    setMessage("")
    props.hideUploadUserList(false)

   


    //setBase64File("") 
    }
    useEffect(() => {
      if(props.audienceMasterId!==undefined &&props.audienceMasterId>0){
        setaudienceMasterIdUser(props.audienceMasterId)
      }
    }, [])
const DownloadExample = () => {
    
    const download = async () => {
        const name = "Example.csv"
            var Base64={blob:"77u/VXNlck5hbWUsQ29Pd25lcixDcmVhdGVEb2N1bWVudA0KZW1haWwxQG15LmNvbSxGQUxTRSxUUlVFDQplbWFpbDJAbXkuY29tLEZBTFNFLFRSVUUNCmVtYWlsM0BteS5jb20sRkFMU0UsVFJVRQ0KZW1haWw0QG15LmNvbSxUUlVFLFRSVUUNCmVtYWlsNUBteS5jb20sVFJVRSxGQUxTRQ0KZW1haWw2QG15LmNvbSxUUlVFLFRSVUUNCmVtYWlsN0BteS5jb20sRkFMU0UsRkFMU0UNCg=="}
            const decoded = decode(Base64.blob)         
            try {
                const file = new Blob(
                    [decoded], { type: "application/vnd.ms-excel"});
                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(file);
                a.download = name;
                a.click();
                setShowSuccess(true)
                setMessage("Example successfully downloaded. Please check Downloads folder!")
                setTimeout(function () {
                    setShowSuccess(false);
                    setMessage("")
                }, 3000);
            } catch (error) {
                console.log(error)
                
            }
    }
    download()
}
const getBase64 = (file: File) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);        
    reader.onload = function () {
        setBase64File(reader.result!.toString().split(',')[1]);
      };
    reader.onloadend = () => {
        setRequiredUploadFile(true)
    }
    
 }

const uploadFile = useConstCallback((ev: React.ChangeEvent<HTMLInputElement>) => { 
    setUploadingFile(true)
    if (ev.target.files != null && ev.target.files.length > 0) {           
        setMessageErrorListCSV("")
        setshowMessageListCSV(false)   
        var sizevalid = 500      
       var mimeType = ev.target.files![0].type;
       var fileSize = ev.target.files![0].size
       var Sizekb = fileSize / 1024
       let IsValidSize = Sizekb <= sizevalid
        if ((mimeType.indexOf("vnd.ms-excel") >= 0 || mimeType.indexOf(".txt") > 0 || mimeType.indexOf("text/csv") >= 0)&& IsValidSize){
        var name = ev.target.files![0].name;         
        setRequiredUploadFile(true)            
        setFileName(name);
        setFile(ev.target.files![0])
        getBase64(ev.target.files![0]);
      
       }
       else{
        setshowMessageListCSV(true)
        setMessageErrorListCSV("This type of file is not valid. Must be a CSV with a .csv extenstion.")
           setRequiredUploadFile(false)
       }
        
    }
    else {
        setShowMessage(true)
        setMessage("File not found")
        setRequiredUploadFile(false)
    }
    setUploadingFile(false)
  });
async function goToAddUserListtoAudienceCSV() {
    console.log(props.AudienceOwnerName)
    if(props.audienceMasterId>0){
      setRequiredUploadFile(true)
      setApplying(true)

        const request = {
            audienceMasterId: props.audienceMasterId,
            base64File: base64File,
            AudienceOwnerName: props.AudienceOwnerName
        }

        let {data}: any = await HttpRequest.postWithSession('AddUserListtoAudienceCSV', request)
        const response = data
             
            if (response.ErrorDescription!==undefined && response.ErrorDescription==="") {
                setApplying(false)
                setMessageErrorListCSV("")
                setshowMessageListCSV(false)
                setShowSuccess(true)
                setMessage("User list saved successfully!")
                props.loadUsers(props.audienceMasterId)
                setuserListSaved(true)
                setTimeout(function () {
                   hideUploadUserList()
                }, 2000);

            } 
            else if(response.ErrorDescription!==undefined) {
                setApplying(false)
                setShowSuccess(false)
                setMessage("")
                setuserListSaved(false)
                setMessageErrorListCSV(response.ErrorDescription)
                setshowMessageListCSV(true)
                setRequiredUploadFile(false)
            }
            else  {
                setShowSuccess(false)
                setMessage("")
                setApplying(false)
                setuserListSaved(false)
                setMessageErrorListCSV(response.ExceptionMessage)
                setshowMessageListCSV(true)
                setRequiredUploadFile(false)
            }
    }
  
}
return(
    <Modal
    isOpen={props.showUploadUserList}
    isBlocking={true}>
    <div className={contentStyles.header}>
        <span id="CloseUploadList" key="CloseUploadList">{"Upload Items"}</span>
        <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup "
            onClick={() => {
                hideUploadUserList()
            }}
            title="Close."
        />
    </div>
    <div className={contentStyles.addItemList}>
        <div className="ms-Grid" dir="ltr">
        {!applying?
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {showMessageListCSV ? <MessageBar styles={messageBarFailedStyles}>{messageErrorListCSV}</MessageBar> : null}
                {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                <div className="ms-Grid-row">
                     <br/> 
                     <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"style={{ textAlign: "right" }}></div>
                     <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{ textAlign: "right" }}>
                     <ActionButton title="Download the example user list to use as a reference." text="Example list" styles={CSVlIconStyles} onClick={DownloadExample} iconProps={CSVlIcon} />                               
                     </div>
                     <br/> 
                     <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <span>
                        <ul>
                            <li>Lists are limited to 200 rows.</li>
                            <li>Lists must be a CSV text file.</li>
                            <li>Lists must be maximum size 500 KB.</li>
                            <li>Lists must be in our specified format. Click link for example.</li>
                        </ul>
                     </span>
                     </div>
                     <br/>
                </div>
                <br/>                      
                <br/>


                 <div className="ms-Grid-row">                               

                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <label htmlFor="file"><div className="divHover divToolBoxItem" style={{color: branding.theme.palette.white, backgroundColor: branding.theme.palette.themePrimary}}>Choose File</div></label>
                        <input accept=".csv" className="fileButton" id="file" type="file" name="file"    onChange={uploadFile}  placeholder="Select file to upload."/>
                    </div>
                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ paddingRight: "0px" }}>
                        <TextField  key="tesxtFilename" disabled value={fileName} title="File name."/>
                    </div>
                </div>
                    {uploadingFile ?
                        <ProgressIndicator description={"Uploading... "} />
                        : null
                    }
                        <DialogFooter>
                            <PrimaryButton disabled={!requiredUploadFile && props.audienceMasterId>0}  onClick={goToAddUserListtoAudienceCSV}text="Upload" title="Upload selected list." />
                                </DialogFooter>
                                <br/>
                            </div>
            </div>
            : <Spinner size={SpinnerSize.medium} label="Applying changes..." ariaLive="assertive" />
        }
        </div>
    </div>
</Modal>


)
}
export default UserLists