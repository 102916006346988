import HttpRequest from "./HttpRequest"

const AppService = {
    getCultures: async () => {
        return await HttpRequest.getWithSession('GetCultures', {})
    },
    getBrandingInformation: async ({ UserName, AudienceMasterId }: { UserName: string, AudienceMasterId: string }) => {
        return await HttpRequest.getWithSession('GetAudienceMasterById', { Username:UserName, AudienceMasterId })
    },
}

interface IHttpClientResponse {
    messageError: string;
    result: IApplicationContextResult;
    bag: string;
    success: boolean;
}

export interface IApplicationContextResult {
    AppName: string
    AppTagline: string
    AppiconLogo: string
    AppTheme: string
    AppLogo: string
}

export default AppService