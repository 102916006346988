import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { TextField, ITextFieldProps } from "@fluentui/react/lib/TextField";
import { ReactHookFormControlProps } from "../CustomTemplates/CustomForm/Models/ReactHookFormControlProps";
import { IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles } from "@fluentui/react";
import { formatString } from "../../Helpers/functions";

export interface RHFTextBoxControlReactHookFormControlProps {
    styles?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> | undefined
}
type RHFTextBoxControlProps = ReactHookFormControlProps & ITextFieldProps & {
    cleanValue?: boolean
}
export const RHFTextBoxControl: FC<RHFTextBoxControlProps> = (props) => {
    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            shouldUnregister={props.shouldUnregister !== undefined && props.shouldUnregister ? true : false}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error }
            }) => (
                <TextField
                    {...props}
                    prefix={props.prefix}
                    styles={props.styles}
                    onKeyDown={props.onKeyDown}
                    id={props.id}
                    name={fieldName}
                    label={props.label}
                    onRenderLabel={props.onRenderLabel}
                    title={props.title}
                    errorMessage={error && error.message}
                    required={props.required}
                    value={value}
                    onChange={(event, newValue) => {
                        let value = newValue
                        if (props.cleanValue) {
                            value = formatString(newValue ?? '')
                        }
                        props.setValue(props.id, value)
                        onChange(value)
                        if (props.onCallback !== null && props.onCallback !== undefined) {
                            props.onCallback(event, value)
                        }
                    }}
                    onBlur={onBlur}
                    resizable={false}
                    defaultValue={undefined}
                    tabIndex={props.tabIndex}
                    disabled={props.disabled !== undefined ? props.disabled : false}
                    maxLength={props.maxLength}
                    placeholder={props.placeholder}
                />
            )}
        />
    );
};