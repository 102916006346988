import React, { useEffect, useState } from 'react'
import { IAssemblyActions, IDocumentAssembly } from '../../../interfaces/IDocumentAssembly'
import DocumentAssemblyService from '../../../services/assembly'
import {
    Stack, DefaultButton, Spinner, SpinnerSize, DetailsList, DetailsListLayoutMode, CheckboxVisibility, IContextualMenuItem,
    IContextualMenuProps, FontWeights, IColumn, IconButton, mergeStyleSets, IButtonStyles
} from '@fluentui/react'
import { t } from 'i18next'
import StatusMessage from '../../../share/StatusMessage'
import { useModal } from '../../../hooks/useModal'
import { IStatusMessage } from '../../../interfaces/IApp'
import { theme } from '../../AdminLogs/AdminLogs.data'
import RulesActionsForm from './RulesActionsForm'
import { Sizes } from '../../../share/CustomModal'
import { useTranslation } from 'react-i18next'
import { useDialog } from '../../../hooks/useDialog'
import { sortArray } from '../../../Helpers/functions'
import {useBranding} from "../../../hooks/useBranding";

type AssemblyActionsProps = {
    ruleItem?: IDocumentAssembly
    templateId: number
    close: () => void
}
type OptionKeys = 'edit' | 'delete' | 'up' | 'down'
const AssemblyActions = ({ ruleItem, templateId, close }: AssemblyActionsProps) => {
    const { branding } = useBranding()
    const [actionList, setActionList] = useState<IAssemblyActions[]>(() => {
        return sortArray(ruleItem?.DocumentAssemblyActions ?? [], 'ActionOrder')
    })
    const [message, setMessage] = useState<IStatusMessage | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const actionForm = useModal()
    const deleteDialog = useDialog()
    const { t } = useTranslation('assembly')

    const contentStyles = mergeStyleSets({
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        sectionHeader: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        section: {
            padding: '1rem 0',
        }
    })


    useEffect(() => {
        if (isLoading && ruleItem && ruleItem.RuleId) {
            DocumentAssemblyService.getAssemblyByRuleId(templateId, ruleItem.RuleId).then(({ data }: any) => {
                if (data.length > 0) {
                    const documentAssembly = data[0] as IDocumentAssembly
                    setActionList(sortArray(documentAssembly.DocumentAssemblyActions ?? [], 'ActionOrder'))
                }
                setIsLoading(false)
            })
        }
    }, [isLoading, ruleItem, templateId])


    const handleDelete = (item?: IAssemblyActions) => {
        if (item) {
            DocumentAssemblyService.deleteRuleAction(templateId, Number(ruleItem?.RuleId), item.ActionId).then(({ data }: any) => {
                const callback = () => {
                    if (data.Success === false) {
                        return setMessage({ Type: 'error', Message: <>Error deleting <strong>{item?.ActionDescription}</strong>: {data.ExceptionMessage}</> })
                    }
                    setIsLoading(true)
                    setMessage({ Type: 'success', Message: <>Action <strong>{item?.ActionDescription}</strong> deleted successfully</> })
                }
                deleteDialog.close(callback)
            })
        }
    }

    const handleMenu = (option: OptionKeys, item?: IAssemblyActions) => {
        const confirm = () => () => handleDelete(item)
        switch (option) {
            case 'edit':
                actionForm.open(item)
                break
            case 'delete':
                deleteDialog.open({ confirm })
                break
            case 'up':
                DocumentAssemblyService.orderAssemblyAction(templateId, ruleItem?.RuleId ?? -1, item?.ActionId ?? -1, 'orderup').then(() => {
                    setIsLoading(true)
                })
                break
            case 'down':
                DocumentAssemblyService.orderAssemblyAction(templateId, ruleItem?.RuleId ?? -1, item?.ActionId ?? -1, 'orderdown').then(() => {
                    setIsLoading(true)
                })
                break
        }
    }

    const wrappedColumns = (actionList: IAssemblyActions[]) => {
        return getColumns(actionList, handleMenu)
    }

    const handleShowMessage = (status: boolean) => {
        setMessage(undefined)
    }
    const handleClose = (callback: () => void, loading: boolean, message?: IStatusMessage) => {
        setIsLoading(true)
        callback?.()
        setMessage(message)
    }
    return (
        <>
            {message ? <StatusMessage setShowMessage={handleShowMessage} status={message.Type} hasTimer={message.HasTimeOut}>
                <>{message.Message}</>
            </StatusMessage> : null}
            <Stack>
                <div>
                    <h3 className={contentStyles.sectionHeader}>
                        <span></span>
                        <DefaultButton key="openForm"
                            onClick={() => { actionForm.open({}) }}
                            title='Add'
                            text={t('settings.addBtn')} />
                    </h3>
                    <div className="scrollVisible" style={{
                        overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh"
                    }}>
                        {isLoading ? <Spinner size={SpinnerSize.medium} label="Loading..." ariaLive="assertive" /> :
                            <DetailsList columns={wrappedColumns(actionList)} items={actionList} layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                selectionPreservedOnEmptyClick={false}
                                enterModalSelectionOnTouch={true}
                                checkboxVisibility={CheckboxVisibility.hidden} />}
                    </div>
                </div>
            </Stack>
            <actionForm.Modal title="Rule" titleId="" size={Sizes.sm}>
                <RulesActionsForm close={(loading, message) => { handleClose(actionForm.close, loading, message) }} item={actionForm.data}
                    ruleId={ruleItem?.RuleId ?? -1} ruleType={`${ruleItem?.RuleType}`} type='create' templateId={templateId} />
            </actionForm.Modal>
            <deleteDialog.Dialog title={'Delete'} titleId="deleteModal" actionText={t('Common.Button.Delete', { ns: 'common' })}>
                <>Are you sure you want to delete the <strong>Rule Action</strong>?</>
            </deleteDialog.Dialog>
        </>
    )
}
const iconButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
type MenuProps = { item: IAssemblyActions, items: IAssemblyActions[], handleMenu: (option: OptionKeys, item?: IAssemblyActions) => void }
const MenuItems = ({ item, items, handleMenu }: MenuProps) => {

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit.',
            onClick: () => {
                handleMenu('edit', item)
            },
        },
        {
            key: 'up',
            text: 'Up',
            title: 'Up.',
            disabled: item.ActionOrder === 1,
            onClick: () => {
                handleMenu('up', item)
            },
        },
        {
            key: 'down',
            text: 'Down',
            title: 'Down.',
            disabled: isLastAction(item, items),
            onClick: () => {
                handleMenu('down', item)
            },
        },
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete.',
            onClick: () => {
                handleMenu('delete', item)
            },
        },
    ]
    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems
    };
    return (
        <>
            <IconButton
                menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424', textAlign: 'right' } }}
                role="button"
                aria-haspopup
                aria-label="Show actions"
                styles={iconButtonStyles}
                menuProps={menuProps}
                title={"Click to select from available actions."}
            />
        </>

    )
}
const getColumns = (items: IAssemblyActions[], handleMenu: (option: OptionKeys, item?: IAssemblyActions) => void): IColumn[] => {
    return [
        {
            key: 'ActionType',
            name: 'Type',
            ariaLabel: 'Type',
            fieldName: 'ActionType',
            minWidth: 80,
            maxWidth: 80,
        },
        {
            key: 'ActionDescription',
            name: 'Description',
            ariaLabel: 'Description',
            fieldName: 'ActionDescription',
            minWidth: 100,
        },
        {
            key: 'Order',
            name: 'Order',
            ariaLabel: 'Order',
            fieldName: 'ActionOrder',
            minWidth: 50,
            maxWidth: 50,
        },
        {
            key: 'action',
            name: 'Actions',
            minWidth: 55,
            maxWidth: 55,
            onRender: (item) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}>
                    <MenuItems item={item} items={items} handleMenu={handleMenu} />
                </div>
            }
        },

    ]
}
const isLastAction = (item: IAssemblyActions, items: IAssemblyActions[]) => {
    const lastIndex = items.length - 1
    return item.ActionId === items[lastIndex].ActionId
}
export default AssemblyActions