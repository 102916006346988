import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import React, { useEffect, useState } from "react";
import MapInputVariableList from "../Body/MapInputVariableList";
import { ActionButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import OutboundService from "../../../../services/outbound";
import { useBoolean } from "@fluentui/react-hooks";
import TestIntegrationModal from "../../TestIntegrationModal";
import { useFormContext } from "react-hook-form";
import { RHFCheckBoxControl } from "../../../RHFControls/RHFChekBoxControl";
import { CreateOutboundIntegration, MapInputHeader, OutboundIntegrationModel, UpdateOutboundIntegration } from "../../Models/OutboundIntegrationModel";
import { IExternalControl } from "../../../../interfaces/IOutbound";

interface TestReleaseProps {
    TemplateId: number;
    IntegrationId: number;
    onChangeApprovedStatus: (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => Promise<void>;
    getUpdatedOutboundIntegration: () => Promise<CreateOutboundIntegration | UpdateOutboundIntegration>;
    showErrorMessageIntegration: (message: string) => void;
    ApproveStatus: boolean;
    callApprovedStatus: (checked: boolean) => Promise<void>;
    loadOutboundIntegrationValues: (testOutboundIntegration?: OutboundIntegrationModel) => void;
    isConfigurationTemplateReadMode?: boolean;
}

const TestRelease = (props: TestReleaseProps) => {
    const { TemplateId, IntegrationId, onChangeApprovedStatus, getUpdatedOutboundIntegration, showErrorMessageIntegration, ApproveStatus, callApprovedStatus } = props;
    const [modalContent, setModalContent] = useState<any>(null);
    const [isLoadingTest, setIsLoadingTest] = useState(false);
    const [isDisableApprove, setIsDisableApprove] = useState(true);
    const [resetCheckbox, setResetCheckbox] = useState(false);
    const { control, setValue, getValues, trigger, handleSubmit } =
        useFormContext();
    const [
        isIntegrationTestModalOpen,
        {
            setTrue: showIntegrationTestModal,
            setFalse: hideIntegrationTestModal,
        },
    ] = useBoolean(false);

    const getVariables = (): IExternalControl[] => {
        let mapInputVariableList: MapInputHeader[] = [];
        let externalControls: IExternalControl[] = [];
        mapInputVariableList = getValues("mapInputVariableList");
        mapInputVariableList.forEach((variable) => {
            if (variable.Key !== "") {
                externalControls = [
                    ...externalControls,
                    {
                        ContentControl: variable.Key.replace(/\[/g, "")
                            .replace(/\]/g, "")
                            .replace(/\$/g, ""),
                        Value: variable.Value,
                    },
                ];
            }
        });
        return externalControls;
    };

    const execute = () => {
        setIsLoadingTest(true);
        handleSubmit(
            async (data) => {
                const hola = await getUpdatedOutboundIntegration();
                OutboundService.UpdateIntegration(
                    hola//await getUpdatedOutboundIntegration()
                ).then(({ data }) => {
                    if (
                        data.Success !== undefined &&
                        data.Success !== null &&
                        data.Success === true
                    ) {
                        OutboundService.ExecuteIntegration({
                            IntegrationId,
                            TemplateId,
                            ExternalControls: getVariables(),
                        }).then(({ data }) => {
                            showIntegrationTestModal();
                            setModalContent(data);
                            setIsLoadingTest(false);
                        });
                    } else {
                        let errorMessage =
                            data.ExceptionMessage !== undefined &&
                                data.ExceptionMessage !== ""
                                ? data.ExceptionMessage
                                : "Something unexpected happened.";
                        showErrorMessageIntegration("Error: " + errorMessage);
                        setIsLoadingTest(false);
                    }

                    props.loadOutboundIntegrationValues(hola.Integration)
                });
            },
            (errors) => {
                setIsLoadingTest(false);
                showErrorMessageIntegration(
                    `Error: ${Object.values(errors)
                        .map((item: any) => item.message)
                        .join("|")}`
                );
            }
        )();
    };

    const handleCheckboxInput = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        checked?: boolean | undefined
    ) => {
        onChangeApprovedStatus(ev, checked);
        setIsDisableApprove(!checked);
    };

    const fromModal = (isDisable: boolean) => {
        setResetCheckbox(true);
        setIsDisableApprove(isDisable);
        setValue("ApprovedStatus", !isDisable);
        callApprovedStatus(!isDisable);
        setTimeout(() => {
            setResetCheckbox(false);
        }, 200);
    };

    useEffect(() => {
        if (ApproveStatus) {
            setIsDisableApprove(false);
        }
    }, [ApproveStatus]);

    return (
        <>
            {!isLoadingTest ? (
                <PivotItem headerText="Mapping">
                    <Pivot
                        styles={{
                            root: {
                                width: "13%",
                                float: "left",
                                paddingTop: "15px",
                            },
                            link: { display: "block" },
                        }}
                    >
                        <PivotItem
                            style={{ width: "87%", float: "right" }}
                            headerText="Variables"
                        >
                            <Stack tokens={{ childrenGap: 8 }} horizontal>
                                <ActionButton
                                    iconProps={{ iconName: "FlickLeft" }}
                                    onClick={execute}
                                    disabled={isLoadingTest || IntegrationId === undefined || (props.isConfigurationTemplateReadMode)? true : false}
                                    title={IntegrationId === undefined ? "Create integration to enable" : ""}
                                >
                                    Test
                                </ActionButton>
                                <Stack.Item
                                    align="start"
                                    styles={{
                                        root: { paddingTop: "0.7em" },
                                    }}
                                >
                                    {!resetCheckbox ? (
                                        <RHFCheckBoxControl
                                            id={"ApprovedStatus"}
                                            label={"Approve"}
                                            control={control}
                                            setValue={setValue}
                                            getValues={getValues}
                                            trigger={trigger}
                                            onCallback={handleCheckboxInput}
                                            disabled={IntegrationId === undefined || (props.isConfigurationTemplateReadMode)? true : false}
                                            title={isDisableApprove ? "Perform Test to enable" : ""}
                                        />
                                    ) : null}
                                </Stack.Item>
                            </Stack>
                            <MapInputVariableList isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode}/>
                        </PivotItem>
                    </Pivot>
                </PivotItem>
            ) : (
                <Stack tokens={{ childrenGap: 50 }}>
                    <label />
                    <label />
                    <label />
                    <Spinner
                        size={SpinnerSize.large}
                        label={"Loading integration..."}
                    />
                </Stack>
            )}

            <TestIntegrationModal
                showModal={isIntegrationTestModalOpen}
                hideModal={hideIntegrationTestModal}
                content={modalContent}
                setModalContent={setModalContent}
                setIsDisableApprove={fromModal}
            />
        </>
    );
};

export default TestRelease;
