import { IComboBoxOption, IDropdownOption } from "@fluentui/react"
import Secure from "./Crypto";
import { IApplicationV1 } from "../Components/ImportTemplate/ImportTemplate";
import { IRule } from "../Components/CustomTemplates/CustomForm/RHFControls/RHFUploadRunTimeControl";
import * as microsoftTeams from "@microsoft/teams-js";
import { getTokenClaims, isInTeams } from "./Utils";
import { IDocusignTagsRoles } from "../Components/DataPanel/Signers";
import HttpRequest from "../services/HttpRequest";
import { GroupBase } from "react-select";
import AuthService from "../services/auth";
import ConfigurationService, {IConfigurationObject, IConfigurationResponse} from "../services/Configuration"
import { IConsentUrlResponse } from "../services/GraphApi";
import { AxiosRequestConfig } from "axios";
import {IFormAssemblyRule} from "../interfaces/IFormAssembly";

export enum RegistrationStatus {
    Registered = 0,
    NotRegistered = 404,
    ValidationNeeded = 405,
    ToSNeeded = 406,
    AuthCode = 415,
    Error = 500,
    ValidationFailed = 9001
}

export enum OperationType {
    create,
    update,
    delete
}

export class Registration {
    email: string = ""
    title: string = ""
    firstName: string = ""
    middleName: string = ""
    lastName: string = ""
    timeZone: string = ""
    activationGuid: string = ""
    culture: string = "en"
}

export class TemplateListRequest {
    UserName: string = ""
    SessionKey: string = ""
    AccountId: string = ""
    AccountIds: string = ""
}

export class SearchTemplateListRequest {
    UserName: string = ""
    SessionKey: string = ""
    AccountId: string = ""
}

export interface ITemplateLayoutRequest {
    UserName: string
    SessionKey: string
    AccountId: string
    TemplateId: string
    DocumentId: string
    AccountIds?: string,
    Site?: string
}
export interface IDocumentOnlyAudienceRequest {
    ApiKey: string
    SessionKey: string
    UserName: string
    AccountId: string
    Path: string
    Autoprovision: boolean
    AvoidCache?: boolean
    AudienceMasterId: string
    FromRegistration: boolean
}

export interface IDocumentOnlyAudienceDetailRequest {
    ApiKey: string
    SK: string
    UserName: string
    AccountId: string
    Path: string
    AudienceMasterId?: string
    AudienceDetailId?: string
    TargetUserName?: string
    Coowner?: boolean
    CreateDocument?: boolean,
    Autoprovision: boolean
    TargetSite?: string,
    AudienceOwnerName?: string,
    TemplateId: string
}

export interface IHttpClientResponseBag {
    MessageError: string;
    ExceptionMessage: string;
    ErrorDescription: string;
    Result: string;
    Bag: string;
    Success: boolean;
}

export interface IDownloadDocumentOnlyTemplateRequest {
    ApiKey: string
    SessionKey: string
    UserName: string
    AccountId: string
    TemplateId: number;
    Autoprovision: boolean
    Path: string
}

export class TemplateChildListRequest {
    UserName: string = ""
    SessionKey: string = ""
    AccountId: string = ""
    ListId: string = ""
    ColIndex: string = ""
    FilterValue: string = ""
    ParentColIndex: string = ""
    ParentFilterValue: string = ""
    Culture: string = "All"
    PageNumber: number = 1
    PageSize: number = 100
}

export class DocumentListRequest {
    UserName: string = ""
    SessionKey: string = ""
    AccountId: string = ""
    //TemplateId: string = ""
    PageNo: string = ""
    RecordsPerPage: string = ""
    Assigned: boolean = true
}

export interface IDownloadFormAssemblyDocumentationRequest extends IRequest {
    Title: string
    Description:string
    Category:string
    CreationDate:string
    Rules: IFormAssemblyRule[]
}

export class ActivityListRequest {
    UserName: string = ""
    SessionKey: string = ""
    AccountId: string = ""
    PageNumber: string = ""
    RecordsPerPage: string = ""
    dateFrom: string = ""
    dateTo: string = ""
}

export interface ICreateDocumentRequest {
    UserName: string;
    SK: string;
    AccountId: string;
    TemplateId: string;
    TargetCulture: string;
    Content: IFormControl[];
    Path: string;
    Autoprovision: boolean;
    DocumentId: string;
    Lists: string;
    TemplateVersion: string;
    TemplateXML: string;
    onBehalf: string;
    DocumentonlyPrimaryId: string;
    Rules?: IRule[]
}

export interface IFormControl {
    Key: string;
    Value: string
    CheckedValues?: ICheckedValues[];
}

export interface ICheckedValues {
    Key: string | number;
    Text: string;
}

export interface IContentControls {
    alias: string;
    tag: string;
    children: IContentControls[];
}

export interface IDeleteDocumentRequest {
    UserName: string;
    SessionKey: string;
    AccountId: string;
    DocumentId: string;
}
export interface IDocumentVersionRequest {
    UserName: string;
    SessionKey: string;
    AccountId: string;
    DocumentId: string;
    Id: string;
    IsPreview: boolean;
    IsDownload: boolean;
    IsDocx: boolean;
}

export interface IDocumentRequest {
    UserName: string;
    SessionKey: string;
    AccountId: string;
    DocumentId: string;
    Id: string;
    IsPreview: boolean;
    IsDownload: boolean;
    IsDocx: boolean;
    context?: string
    code?: string
    templateId?: string
}

export interface INotificationRequest {
    TemplateName: string;
    TemplateCategory: string;
    RecordDescription: string;
    ActionByEmailAddress: string;
    AudienceName: string;
    AudienceOwnerEmailAddress: string;
    TargetOfActionEmailAddress: string;
    ActionDate: string;
}
export interface ISendMailRequest {
    DocumentId: string;
    Id: string;
    FromEmail: string;
    ToEmail: string;
    EmailSubject: string;
    EmailBody: string;
    AttachmentIds: string;
    SendDocument: boolean;
    EmailMoniker: string;
    TemplateId: string;
    DocumentDelivery: string;
    FullMoniker?: string;
}
export interface ICustomerSupportRequest {
    From: string
    Message: string
    Subject: string

}
export interface IUnsubscribeRequest {
    Message: string
    Subject: string
}
export interface ISendSMTPEmailRequest {
    ApiKey: string;
    SK: string;
    AccountId: string;
    UserName: string;
    Path: string;
    Autoprovision: boolean;
    ToEmail: string;
    FromEmail: string;
    EmailSubject: string;
    EmailBody: string;
    IsHTML: boolean;
    FilingId: string;
    DocumentId: string;
    SendCaseDocument: boolean;
    AttachmentIds: string;
    SendPDF: boolean;
    DocumentOnly: boolean;
    EmailMoniker: string;
    TemplateId: string;
}

export interface IBaseRequest {
    UserName: string;
    SessionKey: string;
    AccountId: string;
    Profile: string;
}

export interface IDocumentOnlyAudienceDetail extends IDropdownOption {
    Id: number;
    DocumentOnlyAudienceMasterId: number;
    UserName: string;
    Owner: boolean;
    CoOwner: boolean;
    CreateDocument: boolean;
    Name: string;
    Description: string;
    FullName: string;
    OwnerFullName?: string;
    OwnerUser?: string;
    DateCreated: string;
    DateMasterCreated: string;
}

export interface IAudienceWithOwnerResponse extends IDropdownOption {
    Id: number;
    AccountId: number;
    Name: string;
    Description: string
    CompanyName: string;
    CompanyGeography: string;
    CompanyDepartment: string;
    Logo: string;
    BackgroundColor: string;
    CompanyForeColor: string;
    OwnerUserName: string;
    OwnerFullName: string;
}



export interface IApplicationSMTPServiceEmails {
    Id: number
    TemplateId: number
    ServiceEmail: string
    Host: string
    Port: number
    Username: string
    Pwd: string
}

export interface IApplicationGRAPHAPIServiceEmails {
    ServiceEmail: string
    ClientId: string
    ClientSecret: string
    RedirectURL: string
    AccessToken: string
    RefreshToken: string
}

export interface IDocumentOnlyAudienceMaster {
    Success: boolean,
    ExceptionMessage: string,
    Result: string
}

export interface IHttpClientResponse {
    Success: boolean,
    ExceptionMessage: string,
    Result: string
}

export interface IDownloadDocumentOnlyTemplate {
    TemplateXSD: string;
    TemplateContent: string;
    Id: number;
    AccountId: number;
    TemplateId: number;
    DocumentOnlyAudienceId: number;
    AllowEsign: boolean;
    AllowCheckInOut: boolean;
    eSignProvider: any;
    eSignUserId: any;
    eSignPassword: string;
    eSignIntegrationKey: any,
    eSignURL: string;
    PropertyBag: any,
    FileName: string;
    Category: string;
    Description: string;
    DisplayName: string;
    DisplayNameToolTip: string;
}

export interface IRequestGeneral {
    ApiKey: string
    SK: string
    UserName: string
    AccountId: string
    AccountIds: string
    Site: string
    Autoprovision: boolean

}

export interface IOrderRequest {
    AccountId: string
    ApiKey: string
    Autoprovision: boolean
    Path: string
    SK: string
    UserName: string
    SessionId: string
}

export interface DocumentOnlyCategoryRequest {
    ApiKey: string
    SessionKey: string
    UserName: string
    AccountId: string
    Site: string
    Autoprovision: boolean
    OwnerOnlyList: boolean
}
export interface ICategoriesTemplate extends IComboBoxOption {
    Id: number;
    Description: string;
    Value: string;
}
export interface ITemplateGeneral {
    ActiveSubscription?: boolean | undefined
    SubscriptionStatusMessage?: string | undefined
    CoOwner?: boolean | undefined
    OwnerUserName?: string | undefined
    templateName: string | undefined
    Description: string | undefined
    DisplayName: string | undefined
    Tooltip: string | undefined
    Category: string | number | undefined
    Audience: string | number | undefined
    fileName: string
    mimeType?: string
    file?: File | undefined
    allowesign: string | boolean | undefined
    allowcheckinout: string | boolean | undefined
    eSignUrl: string | undefined
    eSignIntegrationKey: string | undefined
    eSignAccountId: string | undefined
    eSignUserId: string | undefined
    eSignPrivateKey: string | undefined
    eSignProvider: string | undefined
    PropertyBag: string | undefined
    emailMoniker: string | undefined
    allowUpdate: boolean | undefined
    allowNotification: boolean | undefined
    allowSendMail: boolean | undefined
    defaultDescription: string | undefined
    IsSubForm: string | boolean | undefined
    AudienceName?: string | undefined
    DocumentOnlyAudienceId?: number | undefined

}
export interface IParam {
    Name: string
    Value: string | number | boolean | undefined

}

export interface ICheckinCheckoutDocumentOnlyRequest {
    ApiKey: string
    UserName: string
    SessionKey: string
    AccountId: string
    DocumentId: number
    Checkout: boolean
    Autoprovision: boolean
    Path: string
}

export interface IAdvanceSearch {
    ApiKey: string
    AccountId: string
    UserName: string
    EndUserName: string
    SK: string
    PageNo: number
    RecordsPerPage: number
    DateProcessedFrom: Date
    DateProcessedTo: Date
    DateCreatedFrom: Date
    DateCreatedTo: Date
    Description: string
    TemplateId: number
    SearchNotes: string
    IncludeClosed: boolean
    Content: string
    Path: string
    Autoprovision: boolean
    ContentSearch: number
    CampaignId: number
    Activity?: string
}
export interface IDocumentOnlyTemplate {
    TemplateContent: string;
    Id: number;
    AccountId: number;
    TemplateId: string;
    DocumentOnlyAudienceId: number;
    AllowEsign: boolean;
    AllowCheckInOut: boolean;
    eSignProvider: any;
    eSignUserId: any;
    eSignPassword: string;
    eSignIntegrationKey: any,
    eSignURL: string;
    PropertyBag: any,
    FileName: string;
    Category: string;
    Description: string;
    DisplayName: string;
    DisplayNameToolTip: string;
    emailMoniker: string;
    AllowDocumentUpdate: boolean;
    AllowNotification: boolean;
    AllowSendMail: boolean;
    DefaultDescription: string;
    subform: boolean;
    ActiveSubcription?: boolean
    Billing?: boolean
    TotalAutosign?: number;
    CheckBoxCCs: string[];

}
export interface IRequestDocumentOnlyTemplateId {
    ApiKey: string
    SessionKey: string
    UserName: string
    AccountId: string
    AccountIds: string
    Site: string
    Autoprovision: boolean
    TemplateId: number
}
export interface IDocumentOnlyTemplateUpdateRequest {

    AccountId: string;
    ApiKey: string
    SK: string
    UserName: string
    AccountIds: string
    Path: string
    Autoprovision: boolean
    TemplateId: string;
    Base64HTMLString: string;
    filename: string;
    description: string;
    audience: string;
    allowesign: boolean;
    allowcheckinout: boolean;
    category: string;
    buttontext: string;
    tooltip: string;
    displayName: string;
    esignurl: string;
    esignintegrationkey: string;
    esignuserid: string;
    esignpassword: string;
    propertybag: string;
    esignprovider: string;
    culture: string;
    cultureName: string;
    released: boolean;
    emailMoniker: string;
    documentUpdate: boolean;
    documentNotification: boolean;
    allowDocx: boolean;
    allowSendMail: boolean;
    documentCreation: boolean;
    documentUpdateNotification: boolean;
    documentDelete: boolean;
    documentShare: boolean;
    templateEdit: boolean;
    templateShare: boolean;
    templateDelete: boolean;
    defaultDescription: string;
    subform?: boolean;
    templateRemove?: boolean;
    useServiceAccount: boolean;
    serviceAccountEmail: string;
    uniqueReference: string;
    attachment: string;
    docusignTagsRoles: IDocusignTagsRoles[];
    supressPreview: boolean;
    documentDelivery: string;
    redirectURL: string;
    imageInfo: string;
    externalPublish: boolean;
    externalPublishContext: string;
    accessCodeInstructions: string;
    EnabledCaptcha: boolean
    EnabledSSO: boolean
}

export interface IUpdateDocumentOnly {
    ApiKey: string
    AccountId: string
    UserName: string
    SK: string
    TemplateId: number
    Content: string
    Path: string
    Autoprovision: boolean
    DocumentId: string
}

export interface IAudienceIntersect {
    ApiKey: string
    AccountId: string
    UserName: string
    SessionKey: string
    Path: string
    Autoprovision: boolean
    DocumentId: string
}

export interface IDocumentOnlyNoAttachmentRequest {
    ApiKey: string;
    AccountId: string;
    UserName: string;
    SessionKey: string;
    DocumentId: number;
    Path: string;
    Autoprovision: boolean;
}

export interface IDocumentOnlyAttachmentRequest {
    ApiKey: string;
    AccountId: string;
    UserName: string;
    SessionKey: string;
    Id?: string;
    GetBlobData: boolean
    Path: string;
    Autoprovision: boolean;
}

export interface IDeleteDocumentOnlyAttachmentRequest {
    ApiKey: string;
    AccountId: string;
    UserName: string;
    SessionKey: string;
    Id?: string;
    Path: string;
    Autoprovision: boolean;
    Operation: string;
}

export interface ICreateDocumentOnlyAttachmentRequest {
    ApiKey: string
    SK: string
    UserName: string
    AccountId: string
    Path: string
    DocumentId: string
    AttachName: string
    AttachDescription: string
    MimeType: string
    FileExt: string,
    Autoprovision: boolean
}

export interface IDeleteDocumentOnlySignature {
    ApiKey: string;
    AccountId: string;
    UserName: string;
    SessionKey: string;
    Id?: string;
    DocumentId?: string;
    Path: string;
    Autoprovision: boolean;
}


export interface IDocumentOnlyCustomUserSignature {
    Id: string
    AccountId: string
    DocumentOnlyAudienceMasterId: string
    Description: string
    FileExt: string
    MimeType: string
    Name: string
    SignatureImage: string


}

export interface ITemplateSystem {
    EmailMoniker: string;
    TemplateId: string;
    AccountId: string;
    ButtonText: string;
    Category: string;
    Culture: string;
    Description: string
    Path: string;
    DisplayName: string;
}
export interface IDocumentOnlyListClon {
    ApiKey: string
    UserName: string
    SK: string
    AccountId: string
    Autoprovision: boolean
    Path: string
    userEmail: string
    templateId: string
    NewtemplateId: string
    IsExample: boolean
}


/**
 * Object IDocumentOnlyImport to Import an Application 
 */
export interface IDocumentOnlyImport {
    ApiKey: string
    UserName: string
    SK: string
    AccountId: string
    Autoprovision: boolean
    Path: string
    ApplicationContent: IApplicationV1
    Password: string
    IsExample: boolean
    clientDate: string
}

export interface IListClonResponse {
    DocumentOnlyListMasterId: string
    DocumentOnlyAudienceMaster: string
    Name: string
    Description: string
    ExceptionMessage: string
    Result: string
    Success: boolean
    ResponseType: string
}

export interface INotification {
    TemplateName: string;
    TemplateCategory: string;
    RecordDescription: string;
    ActionByEmailAddress: string;
    AudienceName: string;
    AudienceOwnerEmailAddress: string;
    TargetOfActionEmailAddress: string;
    ActionDate: string;
    Type: string;
    ApiKey: string;
    SK: string;
    UserName: string;
    Culture?: string;
    AccountId: string;
    Path: string;
    Autoprovision: boolean;
    EmailMoniker: string;
    TargetSite?: string;
    TemplateId: string;
    AudienceUsers?: string;
    DocumentId?: string;
}




export interface ITemplateSignatureRequest {
    ApiKey: string
    UserName: string
    SessionKey: string
    AccountId: string
    Path: string
    TemplateId: string
}
export interface ITemplateVersionRequest {
    UserName: string;
    SessionKey: string;
    AccountId: string;
    TemplateId: string;
}

export interface IDocumentOnlyStatisticsRequest {
    ApiKey: string;
    SessionKey: string;
    AccountId: string;
    UserName: string;
    Autoprovision: boolean;
    TemplateId: string;
}

export interface ISaveDisclaimerRequest {
    ApiKey: string;
    SessionKey: string;
    AccountId: string;
    UserName: string;
    Path: string;
    Autoprovision: boolean;
    IsAccepted: boolean;
}

export interface IGetDisclaimerRequest {
    UserName: string;
    SK: string;
    AccountId: string;
    Path: string;
}

export interface IRequest {
    UserName: string,
    SK: string,
    AccountId: string,
    ApiKey: string,
    Autoprovision: boolean,
    Path: string,
}

export interface ICUPlanRequest extends IRequest, IPlan {
    Operation: string
}

export interface IDPlanRequest extends IRequest {
    Operation: string
    Id: number
}

export interface IPlanByTypeRequest extends IRequest {
    Type: string
}

export interface IPlanByIdRequest extends IRequest {
    Type: string,
    UseCache: boolean,
    Id: string
}


export interface IPlan {
    Id: number,
    Name: string,
    Description: string,
    SubmissionLimit: number,
    StorageLimit: number,
    UserLimit: number,
    Type?: string,
    TransactionUnit?: string,
    TransactionLength: number,
    TransactionAmount: number,
    PaymentProviderProductId: string,
    MaxApplicationAllowed?: number,
    WorkspaceOnlyUsers?: number
}

export interface IDMerchantRequest extends IRequest {
    Operation: string
    Id: number
}

export interface IDManageClientPlanRequest extends IRequest {
    Operation: string
    Id: number
}

export interface IMerchant {
    Id: number,
    MerchantId: string,
    Active: boolean,
    Comment: string,
    ContactEmailAddress: string,
    ContactEmailAddressSecondary: string,
    ContactFirstName: string,
    ContactLastName: string,
    ContactPhoneNumber: string,
    ContactPhoneNumberSecondary: string,
    OrganizationAddress: string,
    OrganizationCity: string,
    OrganizationCountry: string,
    OrganizationEIN: string,
    OrganizationName: string,
    OrganizationPostalCode: string,
    OrganizationState: string,
    Created?: Date
}
export interface IManageClientPlans {

    Active: boolean,
    BillingPlanId: number,
    Comment: string,
    ContactEmailAddress: string,
    ContactFirstName: string,
    ContactLastName: string,
    ContactPhoneNumber: string,
    Id: number,

    OrganizationAddress: string,
    OrganizationCity: string,
    OrganizationCountry: string,
    OrganizationName: string,
    OrganizationPostalCode: string,
    OrganizationState: string,
    OwnerName: string,
    SubscriptionId: string,
    Updated?: string
    Created?: string


}
export interface IManageClientPlansRequest extends IRequest {
    ownerName: string
}
export interface ICUManageClientPlansRequest extends IRequest, IManageClientPlans {
    Operation: string

}
export interface IManageClientPlans {
    Id: number,
    BillingPlanId: number,
    OwnerName: string,
    OrganizationName: string,
    OrganizationAddress: string,
    OrganizationCity: string,
    OrganizationState: string,
    OrganizationPostalCode: string,
    OrganizationCountry: string,
    ContactFirstName: string,
    ContactLastName: string,
    ContactEmailAddress: string,
    ContactPhoneNumber: string,
    SubscriptionId: string,
    Updated?: string
    Created?: string
    Active: boolean,
    Comment: string
}

export interface ISubscriptionUpdateRequest extends IRequest {
    PriceId: string,
    subscriptionId: string
}

export interface BillingStatebyOwnerNameRequest extends IRequest {
    ownerName: string
}

export interface IRequest {
    UserName: string,
    SK: string,
    AccountId: string,
    ApiKey: string,
    Autoprovision: boolean,
    Path: string,
    SessionKey?: string
}

export interface IDocumentOnlyDataPointRequest extends IRequest {
    DocumentId: number,
    XmlPayload: boolean
}

export interface IDocumentOnlyPackageRequest extends IRequest {
    DocumentId: number
}

export interface IBulkDownloadRequest {
    Operation: string
    DateProcessedFrom: Date
    DateProcessedTo: Date
    DateCreatedFrom: Date
    DateCreatedTo: Date
    TemplateId: number
    CampaignId: number
}

export interface DocumentOnlyReassignRecordRequest {
    AudienceId: number
    SetMemberUserName: string
    ChangeMemberUserName: string
    RemoveChangeMemberUserName: boolean
}

export interface IEvaluatePlanRequest extends IRequest {
    Operation: string
    Id: number
    OwnerName: string
}
export interface IEvaluatePlanResponse {
    Response: string,
    CurrentStorageUsedGB: number,
    CurrentUsers: number,
    TargetStorageLimitGB: number,
    TargetUserLimit: number,
    DowngradeAllowed: boolean
}

export interface IUserProfile {
    Email: string,
    Title: string,
    FirstName: string,
    MiddleName: string,
    LastName: string,
    TimeZone: string,
    Success: boolean,
    AdminBilling: boolean,
    Billing: boolean,
    UserName?: string
}

export interface IChangeOwerRequestRequest extends IRequest {
    AudienceId: number,
    NewOwnerUserName?: string,
    ChangeOwnerUserName: string
}

export interface IAuthenticateRequest {
    Identity: string,
    Path: string,
    Autoprovision: boolean
}
export interface IRequestPayment {
    UserName: string,
    SessionKey: string,
    AccountId: string,
    ApiKey: string,
    Autoprovision: boolean,
    Path: string,
}
export interface ICreateApplicationServiceEmail extends IRequest {
    Owner: string,
    AudienceId: string,
    TemplateId: string,
    Approver: string,
    ServiceEmail: string,
    ClientID: string,
    ClientSecret: string,
    RedirectURL: string
}

export interface IGraphApiUserConcent extends IRequest {
    State: string,
    Code: string
}

export interface IGraphApiConsentUrlRequest {
    ClientId: string,
    RedirectUrl: string,
    Scope: string,
    State: string
}

export interface IServiceEmailRequest extends IRequest {
    TemplateId: string
    Owner?: string
}

export interface IServiceEmail {
    Id: number,
    DateTimeCreated: string,
    Owner: string,
    AudienceId: number,
    TenantGUID: string,
    TemplateId: number,
    DateTimeApproved: string,
    Approver: string,
    ServiceEmail: string,
    AccessToken: string,
    RefreshToken: string,
    ClientId: string,
    ClientSecret: string,
    RedirectURL: string
}

export interface IDeleteApplicationServiceEmail extends IRequest {
    Owner: string,
    AudienceId: string,
    TemplateId: string
}

export interface IUpdateApplicationServiceEmail extends IRequest {
    Id: number,
    Context: string,
    Value: string
}
export interface ICCDropDown {
    Id: string;
    Value: string;
}
export interface ISMTPEmailRequest extends IRequest {
    ServiceEmail: string,
    TemplateId: number,
    Host: string,
    Pwd: string,
    Port: string,
    WithEncrypt: boolean,
    operation: string,
    UsernameSMTP: string
}
export interface ISMTPEmailbyTemplateId extends IRequest {
    TemplateId: string
}
export interface IDeleteSMTPEmail extends IRequest {
    TemplateId: number,
    Id: number
}
export interface ICancelSubscriptionRequest extends IRequest {
    SubscriptionId: string,
    OwnerName: string,
    PlanName: string,
    BillingPlanId: number
}

export interface IListItem extends IRequest {
    DocumentOnlyListMasterId: string,
    DocumentOnlyListDetailId: string,
    DocumentOnlyAudienceMaster: string,
    Key: string,
    Value: string,
    Name: string,
    Description: string,
    OwnerCoOwnerUser: string
}
export interface ReferenceNumberRequest extends IRequest, IReferenceNumber {
    Autoprovision: boolean
}
export interface IReferenceNumber {
    TemplateId: string,
    Operation: string,
    Id: number,
    ResetAnnually: boolean,
    ResetDateTime?: string,
    ResetMonthly: boolean,
    SeedMaxNumber: string,
    SeedValue: string,
    SeedZeroPadding: boolean
    Pattern: ""
}
export interface IDocumentOnlyAudience {
    Id: number;
    DocumentOnlyAudienceMasterId: number;
    UserName: string;
    Owner: boolean;
    CoOwner: boolean;
    CreateDocument: boolean;
    Name: string;
    Description: string;
    FullName: string;
    OwnerFullName?: string;
    OwnerUser?: string;
    DateCreated: string;
    DateMasterCreated: string;
}
export interface IAudienceRequest extends IRequest {
    AudienceMasterId: string,
    TargetUser: string
    Autoprovision: boolean
}
export interface IMyListOption {
    value: string;
    label: string;
}

export interface IAsyncSelect<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
    > {
    readonly value: string
    readonly label: string
    readonly options: string[]

}
export interface IDocumentOnlyTemplateResponsebyId {
    Id: number;
    AccountId: number;
    TemplateId: number;
    FileName: string;
    Description: string;
    DisplayName: string;
    DocumentOnlyAudienceId: number;
    AllowEsign?: boolean;
    AllowCheckInOut?: boolean;
    eSignProvider?: string;
    eSignUserId: string;
    eSignPassword: string;
    eSignIntegrationKey: string,
    PropertyBag: string,
    Category: string
    DisplayNameToolTip: string;
    TemplateContent: string
    AllowDocumentUpdate?: boolean;
    AllowNotification?: boolean;
    EmailMoniker: string;
    OwnerFullName: string;
    OwnerDisplayName: string;
    OwnerUserName: string
    CoOwner: boolean
    CreateDocument: boolean
    AudienceName: string
    IsSubForm: boolean
    ActiveSubscription: boolean;
    SubscriptionStatusMessage: string
    IsDocusign?: boolean
    AllowSendMail: boolean;
    DefaultDescription: string;
    isClixsignature: boolean
    TotalAutosign?: number;
    Billing: boolean;

}

export interface IDocumentOnlyLoggingRequest extends IRequest {
    Type: string,
    TemplateId: string,
    FilingId?: string,
    Section: string,
    Area: string,
    Action: string,
    ActionStatus: string,
    CaseNumber?: string,
    Message: string,
    Autoprovision: boolean
}


const Helper = {
    authenticate: async (username: string, site: string) => {
        try {
            var urlVerify = `Verify?path=${site}`
            const codeRaw = await fetch(urlVerify, {
                method: "GET",
                headers: await Helper.getHeaders()
            })
            const verify = await codeRaw.text()

            var data = Secure.encrypt(username, verify)
            var username64 = btoa(username);
            var identity = btoa(`${username64}.${data}.${verify}`)

            const request: IAuthenticateRequest = {
                Identity: identity,
                Path: site,
                Autoprovision: true
            }

            var url = `Authenticate`
            const raw = await fetch(url, {
                method: "POST",
                headers: await Helper.getHeaders({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(request)
            }
            )
            const result = await raw.json()
            //console.log(result)
            if (result.hasOwnProperty('SessionKey')) {
                const accoutnId = Helper.getDefaultAccount()
                const sessionKey = Helper.getSessionKey()
                return { Status: RegistrationStatus.Registered, SessionKey: sessionKey, AccountId: accoutnId, Message: "" }
            } else if (result.hasOwnProperty('ErrorCode')) {
                switch (result.ErrorCode?.toString()) {
                    case "404":
                        return { Status: RegistrationStatus.NotRegistered, SessionKey: "", AccountId: "", Message: result.ExceptionMessage }
                    case "405":
                        return { Status: RegistrationStatus.ValidationNeeded, SessionKey: "", AccountId: "", Message: result.ExceptionMessage }
                    case "415":
                        return { Status: RegistrationStatus.AuthCode, SessionKey: "", AccountId: "", Message: result.ExceptionMessage }
                    case "406":
                    case "407":
                    case "410":
                    case "411":
                    case "412":
                    case "420":
                    case "421":
                    case "422":
                    case "423":
                    case "425":
                    case "500":
                        return { Status: RegistrationStatus.Error, SessionKey: "", AccountId: "", Message: result.ExceptionMessage }
                    case "9001":
                        return { Status: RegistrationStatus.ValidationFailed, SessionKey: "", AccountId: "", Message: result.ExceptionMessage }
                    default:
                        throw new Error('No Error Code Found');
                }
            }
        } catch (e) {
            throw new Error(e)
        }
    },
    getRegistration: async (registration: Registration) => {
        const site = await Helper.getSite()!
        const request = {
            username: registration.email,
            TargetUserName: registration.email,
            path: site,
            autoprovision: true
        }

        let { data }: any = await HttpRequest.getWithSession('GetRegistration', request)
        return data
    },
    setRegistration: async (registration: Registration) => {
        const site = await Helper.getSite()!

        const request = {
            UserName: registration.email,
            Title: registration.title,
            FirstName: registration.firstName,
            MiddleName: registration.middleName,
            LastName: registration.lastName,
            TimeZonePreference: registration.timeZone,
            ActivationGuid: registration.activationGuid,
            path: site,
            autoprovision: true,
            Culture: registration.culture
        }

        let { data }: any = await HttpRequest.getWithSession('SetRegistration', request)
        return data
    },
    getSearchTemplates: async (request: SearchTemplateListRequest) => {
        var site = await Helper.getSite()!

        const _request = {
            UserName: request.UserName,
            SK: request.SessionKey,
            AccountId: request.AccountId,
            path: site,
            autoprovision: true
        }
        let result: any
        await HttpRequest.getWithSession("GetSearchTemplatesList", _request)
            .then((data: any) => {
                result = data.data
            })
        return result;
    },
    getTemplateLayout: async (request: ITemplateLayoutRequest) => {
        const _request = {
            TemplateId: request.TemplateId,
            DocumentId: request.DocumentId
        }

        let { data }: any = await HttpRequest.getWithSession('gettemplatelayout', _request)
        return data
    },
    downloadFormAssemblyDocumentation: async (request: IDownloadFormAssemblyDocumentationRequest, config?: AxiosRequestConfig): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('DownloadFormAssemblyDocumentation', request, config)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },
    getDocumentList: async (request: DocumentListRequest): Promise<any> => {
        const _request = {
            PageNo: request.PageNo,
            RecordsPerPage: request.RecordsPerPage,
            Assigned: request.Assigned
        }

        let { data }: any = await HttpRequest.getWithSession('GetDocumentList', _request)
        return data
    },
    DeleteDocument: async (request: IDeleteDocumentRequest) => {
        const _request = {
            UserName: request.UserName,
            DocumentId: request.DocumentId,
            Operation: 'delete'
        }

        let { data }: any = await HttpRequest.getWithSession('DeleteDocument', _request)
        return data
    },
    getDocumentVersion: async (request: IDocumentVersionRequest) => {
        const _request = {
            UserName: request.UserName,
            DocumentId: request.DocumentId
        }

        let { data }: any = await HttpRequest.getWithSession('GetDocumentVersion', _request)
        return data
    },
    deleteDocumentVersion: async (request: IDocumentVersionRequest) => {
        const _request = {
            UserName: request.UserName,
            DocumentId: request.DocumentId,
            Id: request.Id
        }

        let { data }: any = await HttpRequest.getWithSession('DeleteDocumentVersion', _request)
        return data
    },
    getDocumentVersionPreview: async (request: IDocumentVersionRequest) => {
        const _request = {
            UserName: request.UserName,
            DocumentId: request.DocumentId,
            Id: request.Id,
            IsPreview: request.IsPreview,
            IsDownload: request.IsDownload,
            IsDocx: request.IsDocx,
        }

        let { data }: any = await HttpRequest.getWithSession('GetDocumentVersionPreview', _request)
        return data
    },
    getDocumentPreview: async (request: IDocumentRequest) => {
        const _request = {
            UserName: request.UserName,
            DocumentId: request.DocumentId,
            Id: request.Id,
            IsPreview: request.IsPreview,
            IsDownload: request.IsDownload,
            IsDocx: request.IsDocx,
            Context: request.context ?? '',
            Code: request.code ?? '',
            TemplateId: request.templateId ?? '',
            nonce: new Date().getTime()
        }

        let { data }: any = await HttpRequest.getWithSession('GetDocumentPreview', _request)
        return data
    },
    getDocumentPreviewBeforeSubmit: async (request: ICreateDocumentRequest, released: boolean) => {
        let { data }: any = await HttpRequest.postWithSession('GetPreviewDocumentBeforeSubmit', { Released: released, ...request })
        return data
    },
    getDocumentDownload: async (request: IDocumentRequest) => {

        const _request = {
            UserName: request.UserName,
            DocumentId: request.DocumentId,
            Id: request.Id,
            IsPreview: request.IsPreview,
            IsDownload: request.IsDownload,
            IsDocx: request.IsDocx,
            nonce: new Date().getTime()
        }

        let { data }: any = await HttpRequest.getWithSession('GetDocument', _request)
        return data

    },
    getUsername: (originType = "default") => {
        if (originType === "default") {
            return sessionStorage.getItem("username")!
        } else {
            return sessionStorage.getItem("RecordSubmitUser")!
        }
    },
    getCurrencySymbol: () => {
        const settings = sessionStorage.getItem("config")!
        const values = getTokenClaims(settings)
        if (values !== undefined) {
            return values.CurrencySymbol
        }
        return ""
    },
    getSessionKey: () => {
        return "-1";
    },
    getSite: async () => {
        const settings = sessionStorage.getItem("config")!
        const values = getTokenClaims(settings)
        if (values !== undefined) {
            return values.Path.trim()
        }
        else {
            var site = await Helper.loadSites()
            return site !== null ? site.Path.trim() : ""
        }
    },
    getAdminToEmail: async () => {
        const settings = sessionStorage.getItem("config")!
        const values = getTokenClaims(settings)
        if (values !== undefined) {
            return values.AdminToEmail
        }
        else {
            const site = await Helper.loadSites()
            return site !== null ? site.AdminToEmail : ""
        }
    },
    getDefaultAccount: () => {
        return "-1";
    },
    loadSites: async () => {
        let values: IConfigurationObject = {
            AdminToEmail: "",
            Billing: false,
            CurrencySymbol: "",
            Default: false,
            DefaultPlan: "",
            NotificationEmail: "",
            Path: "",
            ShareEmail: "",
            Site: "",
            SupportEmail: "",
            TeamsId: ""
        }

        await ConfigurationService.Configuration()
            .then((data) => {

                const token = getTokenClaims(data.Settings) as IConfigurationObject

                if (token !== undefined) {
                    values = token
                    sessionStorage.setItem("config", data.Settings)
                    sessionStorage.setItem("site", token.Path)
                }
            })
            .catch()
            .finally()

        return values
    },
    getDocuSignIntegrationKey: async () => {
        let { data }: any = await HttpRequest.getWithSession('GetIntegrationKey', {})
        return data
    },
    getConfig: () => {
        const settings = sessionStorage.getItem("config")!
        const values = getTokenClaims(settings)
        if (values !== undefined) {
            return values
        }
        return null
    },
    getSiteSelected: () => {
        const settings = sessionStorage.getItem("config")!
        const values = getTokenClaims(settings)
        if (values !== undefined) {
            return values.Path.trim()
        }
        return ""
    },
    setSite: async (site: string) => {
        var _site = await Helper.loadSites()
        return _site !== null ? _site.Path.trim() : ""
    },
    resendCode: async (email: string) => {
        const site = await Helper.getSite()!
        const request = {
            username: email,
            path: site,
            autoprovision: true
        }
        let result = null

        await HttpRequest.getWithSession('ResendCode', request)
            .then((data: any) => {
                result = data.data
            })
            .catch((error) => {
                result = null
            })
            .finally(() => {
            })

        return result
    },
    teamsLogin: async () => {
        var url = 'teamslogin'
        const raw = await fetch(url, {
            method: 'GET',
            headers: await Helper.getHeaders()
        })
        const result = await raw.text()

        return result;
    },
    getHeaders: (headers?: Record<string, string>): Promise<Record<string, string>> => {

        //TODO: Makesure all calls are calling this method....
        const url_string = window.location.href
        const url = new URL(url_string);
        const token = url.searchParams.get("token")

        let x_Identity: Record<string, string> = { "x-identity": "" }
        if (sessionStorage.getItem("x-identity") !== null && sessionStorage.getItem("x-identity") !== undefined) {
            x_Identity = { "x-identity": sessionStorage.getItem("x-identity")!.toString() }
        }

        let acceptLanguage: Record<string, string> = { "Accept-Language": !!sessionStorage.getItem("language") ? sessionStorage.getItem("language")!.toString() : 'en' }

        //TODO: APPEND userinfo to headers
        // x-email
        // x-sessionkey
        // x-sid ???

        return new Promise((resolve, reject) => {
            if (isInTeams()) {
                microsoftTeams.initialize();
                var authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
                    successCallback: function (result) {
                        resolve({ ...headers, ...x_Identity, "Authorization": `Bearer ${result}` });
                    },
                    failureCallback: function (error) {
                        console.log("Error getting token: " + error);
                        reject(error);
                    },
                    resources: []
                };
                microsoftTeams.authentication.getAuthToken(authTokenRequest);
            } else if (token !== null) {
                resolve({ ...headers, ...x_Identity, ...acceptLanguage, "Authorization": `Bearer ${token}` });
            }
            else {
                resolve({ ...headers, ...x_Identity, ...acceptLanguage });
            }
        });
    },
    getAuthorizationHeader: (): any => {
        let token: any = {};
        var authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
            successCallback: function (result: string) {
                token = { 'Authorization': 'Bearer ' + result }
            },
            failureCallback: function (error: string) {
                console.log("Error getting token: " + error);
            },
            resources: []
        };
        microsoftTeams.authentication.getAuthToken(authTokenRequest);
        return token;
    },
    getDocumentOnlyTemplate: async (request: ITemplateLayoutRequest) => {
        const { data }: any = await HttpRequest.getWithSession('getListDocumentOnlyTemplate', { AccountId: request.AccountId, AccountIds: request.AccountIds })

        const customTemplates = data.reduce((acc: any, el: any) =>
            acc.concat(el.links), []).reduce((acc: any, el: any) => ({
                ...acc,
                [el.key]: el
            }), { "-1": { "key": "-1", "name": "", "url": "", "DocumentOnlyAudienceId": 0, "Owner": false, "CoOwner": false, "CreateDocument": false, "AllowCheckInOut": true } })

        sessionStorage.setItem("customTemplateList", JSON.stringify(customTemplates))
        return data;
    },
    GetDocumentOnlyAudienceDetailByUserName: async (request: IDocumentOnlyAudienceRequest): Promise<IDocumentOnlyAudienceDetail[]> => {

        const _request = {
            UserName: request.UserName,
            FromRegistration: request.FromRegistration
        }
        let { data }: any = await HttpRequest.getWithSession('DocumentOnlyAudienceDetailByUserName', _request)
        if (Array.isArray(data)) return data
        else return []

    },
    GetDocumentOnlyAudienceDetailWithOwner: async (request: IRequest): Promise<IAudienceWithOwnerResponse[]> => {
        let { data }: any = await HttpRequest.getWithSession('DocumentOnlyAudienceDetailWithOwner', {})
        return data
    },
    GetDocumentOnlyAudienceDetailByAudienceMasterId: async (request: IDocumentOnlyAudienceRequest): Promise<IDocumentOnlyAudienceDetail[]> => {

        const _request = {
            UserName: request.UserName,
            AudienceMasterId: request.AudienceMasterId,
            AvoidCache: request.AvoidCache
        }

        let { data }: any = await HttpRequest.getWithSession('DocumentOnlyAudienceDetailByAudienceMasterId', _request)
        return data


    },

    UpdateDocumentOnlyAudienceDetail: async (request: IDocumentOnlyAudienceDetailRequest): Promise<IDocumentOnlyAudienceMaster> => {

        const _request = {
            AudienceMasterId: request.AudienceMasterId,
            AudienceDetailId: request.AudienceDetailId,
            TargetUserName: request.TargetUserName,
            Coowner: request.Coowner,
            CreateDocument: request.CreateDocument,
            AudienceOwnerName: request.AudienceOwnerName
        }

        let { data }: any = await HttpRequest.getWithSession('UpdateDocumentOnlyAudienceDetail', _request)
        return data


    },
    DeleteDocumentOnlyAudienceDetail: async (request: IDocumentOnlyAudienceDetailRequest): Promise<IDocumentOnlyAudienceMaster | null> => {

        let result = null
        const _request = {
            AudienceMasterId: request.AudienceMasterId,
            AudienceDetailId: request.AudienceDetailId,
            TargetUserName: request.TargetUserName,
            Coowner: request.Coowner,
            CreateDocument: request.CreateDocument,
            AudienceOwnerName: request.AudienceOwnerName
        }

        await HttpRequest.getWithSession('DeleteDocumentOnlyAudienceDetail', _request)
            .then((response: any) => {
                result = response.data
            })
            .catch(() => {
                result = null
            })
            .finally(() => {
            })
        return result


    },
    downloadDocumentOnlyTemplate: async (request: IDownloadDocumentOnlyTemplateRequest) => {
        const _request = {
            TemplateId: request.TemplateId
        }

        let { data }: any = await HttpRequest.getWithSession('DownloadDocumentOnlyTemplate', _request)
        return data
    },

    getCategoriesTemplate: async (request: IRequestGeneral): Promise<ICategoriesTemplate[]> => {
        const _request = {
            AccountIds: request.AccountIds
        }

        let { data }: any = await HttpRequest.getWithSession('getCategoriesTemplate', _request)
        return data
    },
    getDocumentOnlyCategory: async (request: DocumentOnlyCategoryRequest): Promise<string[]> => {
        const _request = {
            ownerOnlyList: request.OwnerOnlyList
        }

        let { data }: any = await HttpRequest.getWithSession('getDocumentOnlyCategories', _request)
        return data
    },
    checkinCheckoutDocumentOnly: async (request: ICheckinCheckoutDocumentOnlyRequest) => {
        const _request = {
            DocumentId: request.DocumentId,
            Checkout: request.Checkout
        }

        let { data }: any = await HttpRequest.getWithSession('CheckinCheckoutDocumentOnly', _request)
        return data
    },
    UndoCheckoutDocumentOnly: async (request: ICheckinCheckoutDocumentOnlyRequest) => {
        const _request = {
            DocumentId: request.DocumentId,
            Checkout: request.Checkout
        }

        let { data }: any = await HttpRequest.getWithSession('UndoCheckoutDocumentOnly', _request)
        return data
    },
    advanceSearch: async (request: IAdvanceSearch) => {
        let result: any[] = []

        await HttpRequest.postWithSession("AdvanceSearch", request)
            .then((data: any) => {
                result = data.data
            })

        return result;
    },
    getDocumentOnlyTemplateId: async (templateId: string, ignoreDocument: boolean, user?: string, docSource?: string) => {

        const request = {
            UserName: user !== undefined ? user : Helper.getUsername(),
            templateId: templateId,
            ignoreDocument: ignoreDocument,
            DocSource: docSource
        }

        let { data }: any = await HttpRequest.getWithSession('getDocumentOnlyTemplateID', request)
        const response = data

        if (response !== undefined) {
            try {
                var template = response as IDocumentOnlyTemplate;
                var tempTemplate: ITemplateGeneral;
                var emailMoni = ""
                if (template.emailMoniker !== null) {
                    emailMoni = template.emailMoniker
                }
                tempTemplate = {
                    Audience: template.DocumentOnlyAudienceId.toString(),
                    Category: template.Category.toString(),
                    Description: template.Description.toString(),
                    DisplayName: template.DisplayName.toString(),
                    Tooltip: template.DisplayNameToolTip.toString(),
                    allowcheckinout: template.AllowCheckInOut.toString().toLowerCase() == 'true',
                    allowesign: template.AllowEsign.toString().toLowerCase() == 'true',
                    templateName: template.Description.toString(),
                    fileName: template.FileName.toString(),
                    eSignAccountId: template.eSignPassword.toString(),
                    eSignIntegrationKey: template.eSignIntegrationKey.toString(),
                    eSignPrivateKey: template.PropertyBag?.toString(),
                    eSignUrl: template.eSignURL.toString(),
                    eSignUserId: template.eSignUserId.toString(),
                    eSignProvider: template.eSignProvider.toString(),
                    PropertyBag: template.PropertyBag,
                    emailMoniker: emailMoni,
                    allowNotification: template.AllowNotification,
                    allowUpdate: template.AllowDocumentUpdate,
                    allowSendMail: template.AllowSendMail,
                    defaultDescription: template.DefaultDescription,
                    IsSubForm: template.subform

                }

                sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))



            }
            catch (e) {
                console.log(e)
                return null
            }


        }
        return response
    },
    UpdateTemplate: async (request: IDocumentOnlyTemplateUpdateRequest) => {
        let { data }: any = await HttpRequest.postWithSession('UpdateTemplate', request)
        return data
    },
    updateDocumentOnly: async (request: IUpdateDocumentOnly) => {
        const _request = {
            TemplateId: request.TemplateId,
            Content: request.Content,
            DocumentId: request.DocumentId
        }

        let { data }: any = await HttpRequest.postWithSession('UpdateDocumentOnly', _request)
        return data
    },
    getAudienceIntersect: async (request: IAudienceIntersect) => {
        const _request = {
            UserName: request.UserName,
            DocumentId: request.DocumentId
        }

        let { data }: any = await HttpRequest.getWithSession('AudienceIntersect', _request)
        return data
    },
    getDefaultlocation: () => {
        return window.location.origin;
    },
    GetDocumentOnlySignatureWithOutContentByDoc: async (documentId: string) => {
        const request = {
            DocumentId: documentId
        }

        let { data }: any = await HttpRequest.getWithSession('GetDocumentOnlySignatureWithOutContentByDoc', request)
        return data
    },
    GetDocumentOnlySignatureWithContentByDocAndSignature: async (documentId: string, id: string) => {
        const request = {
            DocumentId: documentId,
            Id: id
        }

        let { data }: any = await HttpRequest.getWithSession('GetDocumentOnlySignatureWithContentByDocAndSignature', request)
        return data
    },
    DeteleDocumentOnlySignatureById: async (request: IDeleteDocumentOnlySignature): Promise<any> => {
        const _request = {
            DocumentId: request.DocumentId,
            Id: request.Id,
            Operation: 'delete'
        }

        let { data }: any = await HttpRequest.getWithSession('DeteleDocumentOnlySignatureById', _request)
        return data
    },
    UploadTemplate: async (request: IDocumentOnlyTemplateUpdateRequest) => {
        let { data }: any = await HttpRequest.postWithSession('UploadTemplate', request)
        return data
    },
    getCloneTemplate: async (templateId: string, targetEmail: string, isExample: boolean = false) => {

        const request = {
            AccountIds: Helper.getDefaultAccount(),
            TemplateId: templateId,
            targetEmail: targetEmail,
            IsExample: isExample
        }

        let { data }: any = await HttpRequest.getWithSession('getCloneTemplate', request)
        return data
    },

    /**
     * Get external Application
     * @param _url
     * @param abortController
     */
    getExternalApplication: async (_url: string, abortController: AbortController) => {

        const data = fetch(_url, {
            method: "GET",
            signal: abortController.signal,
            headers: {
                'Content-Type': 'text/html; charset=utf-8'
            }
        })
        return await data;
    },

    /**
     * Valdiate E-mail format
     * @param input
     */
    validateEmail: (input: string): boolean => {
        if (input === undefined || input.trim().length === 0) return false

        const mailformat = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/);

        return mailformat.test(input.toLowerCase().trim());
    },

    /**
     * Valdiate if the parameter is a valid URL
     * @param _url
     */
    validateURL: (_url: string): boolean => {
        const regex = new RegExp('^(https?):\\/\\/[^\\s$.?#].[^\\s]*$');

        if (_url === null || _url.trim().length === 0) {
            return false;
        }
        else {
            return regex.test(_url);
        }
    },

    /**
     * Get Import GUID Application
     * @param guid
     * @param templateId
     * @param abortController
     */
    getImportGUIDTemplate: async (guid: string, templateId: string, abortController: AbortController): Promise<any> => {

        const _request = {
            GUID: guid,
            TemplateId: templateId,
        }

        const { data }: any = await HttpRequest.getWithSession("Package/ImportGUID", _request, abortController.signal)

        return new Promise<any>((resolve, reject) => {
            if (!data.success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * * post Import Application
     * @param applicatioContent
     * @param password
     * @param isExample
     */
    postImportTemplate: async (password: string, applicatioContent: IApplicationV1, clientDate: string, isExample: boolean = false) => {

        const request: IDocumentOnlyImport =
        {
            ApiKey: "-1",
            ApplicationContent: applicatioContent,
            Password: password,
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            Autoprovision: true,
            Path: await Helper.getSite(),
            IsExample: isExample,
            clientDate: clientDate
        };


        const { data }: any = await HttpRequest.postWithSession("Package/Import", request)

        return new Promise<any>((resolve, reject) => {
            if (!data.success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * get Exported package
     * @param templateId
     * @param isExample
     * @param PF
     */
    getExportTemplate: async (templateId: string, isExample: boolean = false, PF: string) => {

        const request = {
            AccountIds: Helper.getDefaultAccount(),
            TemplateId: templateId,
            PF: PF,
            IsExample: isExample
        }

        let { data }: any = await HttpRequest.getWithSession('ExportApplication', request)
        return data

    },


    /***
     * Get current user culture
     */
    getCulture: () => {
        const language = !!sessionStorage.getItem("language") ? sessionStorage.getItem("language")!.toString() : 'en'
        var splitted = language.split("-")[0]
        switch (language.split("-")[0]) {
            case "en": {
                return "en"
            }
            case "es": {
                return "es"
            }
            default:
                return "en"
        }
    },

    /***
     * Get the calendar items according the user localization
     */
    getLocateCalendar: () => {

        const en_dayPickerStrings = {
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            goToToday: 'Go to today',
            weekNumberFormatString: 'Week number {0}',
            prevMonthAriaLabel: 'Previous month',
            nextMonthAriaLabel: 'Next month',
            prevYearAriaLabel: 'Previous year',
            nextYearAriaLabel: 'Next year',
            prevYearRangeAriaLabel: 'Previous year range',
            nextYearRangeAriaLabel: 'Next year range',
            closeButtonAriaLabel: 'Close',
            monthPickerHeaderAriaLabel: '{0}, select to change the year',
            yearPickerHeaderAriaLabel: '{0}, select to change the month',
        }

        const es_dayPickerStrings = {
            months: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
            ],
            shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            shortDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
            goToToday: 'Ir al día de Hoy',
            weekNumberFormatString: 'Número de semana {0}',
            prevMonthAriaLabel: 'Mes anterior',
            nextMonthAriaLabel: 'Siguiente mes',
            prevYearAriaLabel: 'Año anterior',
            nextYearAriaLabel: 'Siguiente año',
            prevYearRangeAriaLabel: 'Rango anterior de año',
            nextYearRangeAriaLabel: 'Siguiente rango de año',
            closeButtonAriaLabel: 'Cerrar',
            monthPickerHeaderAriaLabel: '{0}, seleccione para cambiar el año',
            yearPickerHeaderAriaLabel: '{0}, seleccione para cambiar el mes',
        }

        const language = !!sessionStorage.getItem("language") ? sessionStorage.getItem("language")!.toString() : 'en'
        var splitted = language.split("-")[0]
        switch (language.split("-")[0]) {
            case "en": {
                return en_dayPickerStrings
            }
            case "es": {
                return es_dayPickerStrings
            }
            default:
                return en_dayPickerStrings
        }


    },

    /***
     * Get locate date
     * @param myDate
     */
    getLocateDate: (myDate: string, format: number = 1): string => {

        if (myDate === null || myDate.trim().length === 0) return ""
        const utcDate = new Date(myDate)
        if (utcDate.toString() === "Invalid Date") return ""

        let formatOption
        switch (format) {
            case 1:
                formatOption = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour12: true,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,

                }
                break
            case 2:
                formatOption = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                    second: 'numeric',
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,

                }
                break
            case 3:
                formatOption = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour12: true,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,

                }
                break
            // Thu, Jan 26, 2023, 4:45 PM
            case 4:
                formatOption = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: 'numeric',
                    hour12: true,
                    minute: 'numeric',
                    weekday: "short",
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                }
                break
            default:
                formatOption = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour12: true,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,

                }
                break
        }

        const language = !!sessionStorage.getItem("language") ? sessionStorage.getItem("language")!.toString() : 'en'
        let itemDate
        try {
            itemDate = new Date(Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(), utcDate.getHours(), utcDate.getMinutes(), utcDate.getSeconds(), utcDate.getMilliseconds())).toLocaleDateString(
                language,
                formatOption
            )
        } catch {

            try {
                itemDate = new Date(Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(), utcDate.getHours(), utcDate.getMinutes(), utcDate.getSeconds(), utcDate.getMilliseconds())).toLocaleDateString(
                    "en",
                    formatOption
                )
            }
            catch {
                itemDate = ""
            }
        }
        return itemDate
    },


    CreateExample: async (templateId: string, targetEmail: string, isExample: boolean = false) => {

        const request = {
            AccountIds: Helper.getDefaultAccount(),
            TemplateId: templateId,
            targetEmail: targetEmail,
            IsExample: isExample
        }

        let { data }: any = await HttpRequest.getWithSession('CreateExample', request)
        return data

    },
    GetExampleTemplates: async (request: TemplateListRequest) => {
        let { data }: any = await HttpRequest.getWithSession('GetExampleTemplates', {})
        return data
    },
    GetTemplateSignature: async (request: ITemplateSignatureRequest) => {
        const _request = {
            TemplateId: request.TemplateId,
            Indicator: 'template',
            Signatures: undefined,
        }

        let { data }: any = await HttpRequest.getWithSession('GetTemplateSignature', _request)
        return data
    },
    SendNotification: async (request: INotification) => {
        let { data }: any = await HttpRequest.postWithSession('SendNotification', request)
        return data

    },
    getTemplateVersion: async (request: ITemplateVersionRequest) => {

        const _request = {
            TemplateId: request.TemplateId
        }

        let { data }: any = await HttpRequest.getWithSession('RetrieveTemplateVersion', _request)
        return data

    },
    getDocumentOnlyStatistics: async (request: IDocumentOnlyStatisticsRequest) => {
        const _request = {
            TemplateId: request.TemplateId
        }

        let { data }: any = await HttpRequest.getWithSession('DocumentOnlyStatistics', _request)
        return data
    },
    saveDisclaimer: async (request: ISaveDisclaimerRequest) => {
        const _request = {
            IsAccepted: request.IsAccepted
        }
        let { data }: any = await HttpRequest.postWithSession('SaveDisclaimer', _request)
        return data
    },
    getDisclaimer: async (request: IGetDisclaimerRequest) => {
        let { data }: any = await HttpRequest.getWithSession('GetDisclaimer', {})
        return data
    },
    createUpdatePlan: async (request: ICUPlanRequest) => {

        let result: any
        await HttpRequest.postWithSession("Plan/CreateUpdate", request)
            .then((data: any) => {
                result = data.data
            })

        return result;
    },
    getAllPlan: async (request: IRequest): Promise<IPlan[]> => {
        const _request = {}
        let result: any[] = []
        return await HttpRequest.getWithSession("Plan/GetAllPlan", _request)
            .then((data: any) => {
                return data.data
            })
    },
    getPlanById: async (request: IPlanByIdRequest): Promise<IPlan[]> => {

        const _request = {
            Type: request.Type,
            UseCache: request.UseCache,
            Id: request.Id
        }

        return await HttpRequest.getWithSession("Plan/GetPlanById", _request)
            .then((data: any) => {
                const response: IPlan[] = data.data
                return response
            })
    },

    deletePlan: async (request: IDPlanRequest) => {

        const _request = {
            Id: request.Id,
            Operation: request.Operation
        }
        let result: any

        await HttpRequest.getWithSession("Plan/Delete", _request)
            .then((data: any) => {
                result = data.data
            })

        return result;
    },

    getDocumentOnlyDataPoint: async (request: IDocumentOnlyDataPointRequest) => {
        return await HttpRequest.getWithSession("Download/DocumentOnlyDataPoint", request)
            .then((data: any) => {
                return data.data
            })
    },
    getDocumentOnlyPackage: async (request: IDocumentOnlyPackageRequest) => {
        return await HttpRequest.getWithSession("Download/DocumentOnlyPackage", request)
            .then((data: any) => {
                return data.data
            })
    },
    bulkDownload: async (request: IBulkDownloadRequest) => {
        return await HttpRequest.postWithSession('Download/BulkDownload', request)
    },
    getCampaignDefaults: async (request: any) => {

        const _request = {
            Id: -1,
            Operation: request.Operation,
            TemplateId: request.TemplateId
        }

        let { data }: any = await HttpRequest.getWithSession('GetCampaignDefaults', _request)
        return data
    },
    CreateCampaign: async (request: any): Promise<null | any> => {
        let result: any = null
        await HttpRequest.postWithSession('CreateCampaign', request)
            .then((response: any) => {
                result = response.data
            })
            .catch((error) => {
                result = null
            })
            .finally(() => {
            })
        return result
    },
    UpdateCampaign: async (request: any): Promise<null | any> => {
        let result: any = null
        await HttpRequest.postWithSession('UpdateCampaign', request)
            .then((response: any) => {
                result = response.data
            })
            .catch((error) => {
                result = null
            })
            .finally(() => {
            })
        return result
    },
    deleteCampaign: async (request: any) => {

        const _request = {
            Id: request.Id,
            Operation: request.Operation
        }

        let { data }: any = await HttpRequest.getWithSession('DeleteCampaign', _request)
        return data
    },
    deleteRespondent: async (request: any) => {
        const _request = {
            Id: request.Id,
            Operation: request.Operation
        }

        let { data }: any = await HttpRequest.getWithSession('DeleteCampaignDetail', _request)
        return data
    },
    UpdateCampaignDetail: async (request: any) => {
        let { data }: any = await HttpRequest.postWithSession('UpdateCampaignDetail', request)
        return data
    },
    getCampaignListByTemplateId: async (TemplateId: string, PageNumber: number = 0, RecordsPerPage: number = 0) => {
        const { data }: any = await HttpRequest.getWithSession('GetCampaignByTemplateId', { TemplateId, PageNumber, RecordsPerPage })
        return data;
    },
    GetOrder: async (orderRequest: IOrderRequest) => {
        var params = "Apikey=-1&AccountId=" + Helper.getDefaultAccount() + "&SK=" + Helper.getSessionKey() +
            "&UserName=" + Helper.getUsername() +
            "&Path=" + await Helper.getSite() +
            "&Autoprovision=true" + "&SessionId=" + orderRequest.SessionId;

        var url = "payment/orderSuccess?" + params;
        const data = await fetch(url, {
            method: 'GET',
            headers: await Helper.getHeaders()
        })

        const response = await data.json()
        return response;
    },
    updateSubscription: async (request: ISubscriptionUpdateRequest) => {
        var url = "payment/updateSubscription"
        const raw = await fetch(url, {
            method: 'POST',
            headers: await Helper.getHeaders({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(request)
        })
        const result = await raw.json()

        return result;
    },
    PlanUsageHistoryByMonthYear: async (year: string, month: string, billingClientId: string) => {

        const _request = {
            BillingClientId: billingClientId,
            Year: year.toString(),
            Month: month
        }
        let response: any
        await HttpRequest.getWithSession("GetPlanUsageByYearMonth", _request)
            .then((data: any) => {
                response = data.data
            })
        return response;
    },
    DocumentOnlyReassignRecord: async (request: DocumentOnlyReassignRecordRequest) => {
        let result: any
        await HttpRequest.postWithSession("DocumentOnlyReassignRecord", request)
            .then((data: any) => {
                result = data.data
            })

        return result;
    },
    getPlanEvaluation: async (request: IEvaluatePlanRequest): Promise<IEvaluatePlanResponse[]> => {

        const _request = {
            ownerName: request.OwnerName,
            operation: request.Operation,
            id: request.Id
        }

        return await HttpRequest.getWithSession("Plan/Evaluate", _request)
            .then((data: any) => {
                return data.data as IEvaluatePlanResponse[]
            })
            .catch((error: any) => {
                throw new Error(error.toString())
            })

    },
    changeOwner: async (request: IChangeOwerRequestRequest) => {
        let result: any
        await HttpRequest.postWithSession("ChangeOwner", request)
            .then((data: any) => {
                result = data.data
            })

        return result;
    },
    isEmailValid: (value: string): boolean => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value.match(mailformat)) {
            return true;
        } else {
            return false;
        }
    },

    isPortValid: (value: string): boolean => {
        var format = /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
        if (value.match(format)) {
            return true;
        } else {
            return false;
        }
    },
    getTeamsId: async () => {
        const settings = sessionStorage.getItem("config")!
        const values = getTokenClaims(settings)
        if (values !== undefined) {
            return values.TeamsId
        }
        else {
            var site = await Helper.loadSites()
            return site !== null ? site.TeamsId : ""
        }
    },
    getDefaultPlan: async () => {
        const settings = sessionStorage.getItem("config")!
        const values = getTokenClaims(settings)
        if (values !== undefined) {
            return values.DefaultPlan
        }
        else {
            var site = await Helper.loadSites()
            return site !== null ? site.DefaultPlan : ""
        }
    },
    getNotificationEmail: async () => {
        const settings = sessionStorage.getItem("config")!
        const values = getTokenClaims(settings)
        if (values !== undefined) {
            return values.NotificationEmail
        }
        else {
            var site = await Helper.loadSites()
            return site !== null ? site.NotificationEmail : ""
        }
    },
    getShareEmail: async () => {
        const settings = sessionStorage.getItem("config")!
        const values = getTokenClaims(settings)
        if (values !== undefined) {
            return values.ShareEmail
        }
        else {
            var site = await Helper.loadSites()
            return site !== null ? site.ShareEmail : ""
        }
    },
    getSupportEmail: async () => {
        const settings = sessionStorage.getItem("config")!
        const values = getTokenClaims(settings)
        if (values !== undefined) {
            return values.SupportEmail
        }
        else {
            var site = await Helper.loadSites()
            return site !== null ? site.SupportEmail : ""
        }
    },
    CreateUpdateSMTPEmail: async (request: ISMTPEmailRequest) => {
        let { data }: any = await HttpRequest.postWithSession('CreateUpdateSMTPEmail', request)
        return data
    },
    SendSMTPEmail: async (request: ISendSMTPEmailRequest) => {

        const _request = {
            ToEmail: request.ToEmail,
            FromEmail: request.FromEmail,
            EmailSubject: request.EmailSubject,
            EmailBody: request.EmailBody,
            IsHTML: request.IsHTML,
            FilingId: request.FilingId,
            DocumentId: request.DocumentId,
            SendCaseDocument: request.SendCaseDocument,
            AttachmentIds: request.AttachmentIds,
            SendPDF: request.SendPDF,
            DocumentOnly: request.DocumentOnly,
            EmailMoniker: request.EmailMoniker,
            TemplateId: request.TemplateId,
        }

        let { data }: any = await HttpRequest.postWithSession('SendSMTPEmail', _request)
        return data
    },
    GetSMTPEmailTemplateId: async (request: ISMTPEmailbyTemplateId) => {

        const _request = {
            TemplateId: request.TemplateId
        }

        let { data }: any = await HttpRequest.getWithSession('GetSMTPEmailTemplateId', _request)
        return data
    },
    DeleteSMTPEmail: async (request: IDeleteSMTPEmail): Promise<any> => {
        const _request = {
            TemplateId: request.TemplateId,
            Id: request.Id
        }

        let { data }: any = await HttpRequest.postWithSession('DeleteSMTPEmail', _request)
        return data
    },
    SendMailHtml: async (request: ISendMailRequest): Promise<any> => {

        let result: any
        await HttpRequest.postWithSession("SendMailHtml", request)
            .then((data: any) => {
                result = data.data
            })

        return result;
    },
    SendSMTPMailHtml: async (request: ISendMailRequest): Promise<any> => {

        let result: any
        await HttpRequest.postWithSession("SendSMTPMailHtml", request)
            .then((data: any) => {
                result = data.data
            })

        return result;
    },
    GetReferenceNumber: async (request: ReferenceNumberRequest): Promise<any> => {
        return await HttpRequest.getWithSession(`DocumentOnlyReferenceNumber`, request)
            .then((data: any) => {
                return data.data
            })
    },
    CreateUpdateReferenceNumber: async (request: ReferenceNumberRequest): Promise<any> => {
        let result: any
        await HttpRequest.postWithSession(`DocumentOnlyReferenceNumber/${request.Operation}`, request)
            .then((data: any) => {
                result = data.data
            })

        return result;
    },
    DeleteReferenceNumber: async (request: ReferenceNumberRequest) => {
        let result: any
        await HttpRequest.getWithSession("DocumentOnlyReferenceNumber/Delete", request)
            .then((data: any) => {
                result = data.data
            })
        return result;
    },
    getuserFullName: () => {
        return sessionStorage.getItem("userFullName")!;

    },
    getuserFirstName: () => {
        return sessionStorage.getItem("userFirstName")!;

    },
    getuserLastName: () => {
        return sessionStorage.getItem("userLastName")!;

    },
    GetAudiencebyOwner: async (request: IAudienceRequest) => {

        const _request = {
            AudienceMasterId: request.AudienceMasterId,
            TargetUser: request.TargetUser,
            UserName: request.UserName
        }

        let { data }: any = await HttpRequest.getWithSession('GetAudienceMasterByOwner', _request)
        return data
    },
    CreateDocumentOnlyLog: async (request: IDocumentOnlyLoggingRequest): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('DocumentOnlyLogging', request)
        return data
    },
    /**
     * Format a number using mile separator
     * @param _number
     */
    FormatNumber: (_number?: number): string => {
        if (_number !== undefined && _number !== null) {
            try {
                const nformat = new Intl.NumberFormat("en-US").format(Number(_number))
                return nformat.toString()
            } catch {
                return "0"
            }
        } else {
            return "0"
        }
    },
    /**
     * return error parsed from HTTP response
     * @param data
     * @constructor
     */

    GetErrorFromResponse: (data: any): any => {
        let message = data
        if (data.MessageError) message = data.MessageError
        if (data.ExceptionMessage) message = data.ExceptionMessage
        if (data.ErrorDescription) message = data.ErrorDescription
        if (data.Error) message = data.Error
        if (data.error) message = data.error
        return { message: `${message}` }
    },

    /**
     * return error from catch
     * @param data
     * @constructor
     */
    GetErrorMessage: (error: any): any => {
        let message = error.toString()
        if (error.message) message = error.message.toString()
        return message
    },

    /**
     * Sanitizes a given file name by replacing invalid characters with underscores.
     * Invalid characters include: <, >, :, ", /, \, |, ?, *, and characters in the range \x00-\x1F.
     *
     * @param {string} fileName - The file name to be sanitized.
     * @returns {string} - The sanitized file name.
     */
    SanitizeFileName: (fileName: string): string => {
        const invalidChars = /[<>:"\/\\|?*.\x00-\x1F]/g;
        return fileName.replace(invalidChars, '_');
    }

}

export default Helper