import React, { useEffect, useState } from 'react';
import '../../Custom.css'
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { useId } from '@uifabric/react-hooks';
import { Modal } from '@fluentui/react/lib/Modal';
import { Stack } from '@fluentui/react/lib/Stack';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { IconButton, PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Separator } from '@fluentui/react/lib/Separator';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { isMobile } from 'react-device-detect';
import {useTranslation} from "react-i18next";
import {useBranding} from "../../hooks/useBranding";



const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const stackTokens = { childrenGap: 50 };
type ConfirmationProps = {
    confirmDelete: any
    id: any
    modalId: any
    showModal: any
    modalTitle: any
    hideModal: any
    message: any
    spinnerText: any
    layoutLoaded?: any
    actionText: any
}
function Confirmation(props: any) {
    const { branding } = useBranding()
    const [confirmation, setConfirmation] = useState(true)
    const titleId = useId(props.modalId);
    const {t} = useTranslation(['common', 'recorddelete']);

    useEffect(() => {
        setConfirmation(true);
    })

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '25vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const confirm = () => {
        setConfirmation(false)
        props.confirmDelete(props.id)
    }

    return (
        <Modal
            isOpen={props.showModal}
            isBlocking={true}>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Stack>
                            <div className={contentStyles.header}>
                                <span id={titleId}>{props.modalTitle}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                    onClick={props.hideModal}
                                    title={t('Common.Button.Close', { ns: 'common'})}
                                />
                            </div>
                            <Separator />
                        </Stack>
                    </div>
                </div>
                <div className={contentStyles.bodyNote}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className="ms-Grid-row">
                                {props.layoutLoaded ?
                                    <label>{props.message}</label>
                                    :
                                    <Stack tokens={stackTokens}>
                                        <label />
                                        <Spinner size={SpinnerSize.large} label={props.spinnerText} />
                                        <label />
                                    </Stack>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {props.layoutLoaded ?
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={confirm} disabled={!confirmation} text={props.actionText} title={props.actionText} />
                                <DefaultButton onClick={props.hideModal} text={t('Common.Button.Cancel', { ns: 'common'})} title={t('Common.Button.Cancel', { ns: 'common'})} />
                            </DialogFooter>
                        </div>
                    </div> :
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                            </DialogFooter>
                        </div>
                    </div>}
            </div>
        </Modal>
    )

}

export default Confirmation