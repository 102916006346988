import TeamsBaseComponent, { ITeamsBaseComponentState } from 'msteams-react-base-component';
import * as microsoftTeams from "@microsoft/teams-js";
import React from 'react'
import {Content} from "../MyActivity";

export interface IActivityWrapper extends ITeamsBaseComponentState {
    entityId?: string;
    isLoaded: boolean;
    isLoggedIn: boolean;
}

class ActivityWrapper extends TeamsBaseComponent<any, IActivityWrapper>
{
    
    constructor(props: any) {
        super(props);
        this.state = {
            isLoaded: false,
            theme: {
                siteVariables: {
                    fontSizes: {}
                },
                componentStyles: {},
                componentVariables: {},
                animations: {},
                fontFaces: [],
                staticStyles: [],
            },
            entityId: "",
            isLoggedIn: false
        };
    }


    public async componentDidMount() {
        this.updateTheme(this.getQueryVariable("theme"));
        const userRegistration = sessionStorage.getItem("userRegistration")

        this.inTeams()
            .then(x => {
                if (x) {
                    microsoftTeams.initialize();
                    microsoftTeams.registerOnThemeChangeHandler(this.updateTheme);

                    microsoftTeams.getContext((context) => {
                        if (context !== undefined && context.userPrincipalName !== undefined) {
                            sessionStorage.setItem("username", context.userPrincipalName.toString())
                            sessionStorage.setItem("entityId", context.entityId)
                            this.setState({
                                isLoaded: true,
                                isLoggedIn: true
                            });
                        }
                    });
                } else {

                    if (userRegistration !== null && userRegistration !== "") {
                        console.log("user Registration")
                        sessionStorage.setItem("username", userRegistration!)
                        sessionStorage.setItem("entityId", "Not In Teams")
                        sessionStorage.setItem("userRegistration", "");
                        this.setState({
                            isLoaded: true,
                            isLoggedIn: false
                        });
                    } else {
                        this.setState({
                            isLoaded: true,
                            isLoggedIn: false
                        });
                    }
                }
            })
    }


    /**
     * The render() method to create the UI of the tab
     */
    public render() {
        if (this.state.isLoaded) {
            return (
                <Content isLoggedIn={this.state.isLoggedIn}/>
            );
        } else {
            return (
                <>
                </>
            );

        }
    }
}

export default ActivityWrapper