import { ActionButton, DefaultButton, DialogFooter, getTheme, IMessageBarStyles, Label, MessageBar, PrimaryButton, Separator, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RHFTextBoxControl } from './CustomForm/RHFControls/RHFTextBoxControl';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { RHFToggleControl } from '../RHFControls/RHFToggleControl';
import Helper, { IReferenceNumber, ReferenceNumberRequest } from '../../Helpers/Helper';
import axios from 'axios';
import { setGlobalDateLocale } from '../../Helpers/DateUtils';
import HttpRequest from '../../services/HttpRequest';
import { Link } from 'react-router-dom';
import {useBranding} from "../../hooks/useBranding";

const theme = getTheme();
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};

const deleteIcon: IIconProps = { iconName: 'Delete' };
function ReferenceNumber(props: any) {
    const { branding } = useBranding()
    const { handleSubmit, control, setValue, getValues, reset, clearErrors, trigger, formState, resetField, register, unregister } = useForm<any, any>({
        defaultValues: {},
        reValidateMode: "onSubmit",
        mode: "all",
    });
    const emptyReferenceNumber: IReferenceNumber = {
        Id: 0,
        TemplateId: props.templateId,
        Operation: "Create",
        ResetAnnually: false,
        ResetMonthly: false,
        SeedMaxNumber: "",
        SeedValue: "",
        SeedZeroPadding: false,
        Pattern: ""
    };
    const [referenceNumber, setReferenceNumber] = useState<IReferenceNumber>(emptyReferenceNumber)
    const accountId = Helper.getDefaultAccount()
    const sessionKey = Helper.getSessionKey()
    const userName = Helper.getUsername()
    const [referenceNumberRequest, setReferenceNumberRequest] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const stackTokens = { childrenGap: 15 };
    const [showMessage, setShowMessage] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [loadingReferenceNumber, setLoadingReferenceNumber] = useState(false)

    const [message, setMessage] = useState("The field's are  required!");
    const onSaveReferenceNumber = () => {
        handleSubmit(
            (data) => { setReferenceNumberRequest(data) },
            (error) => {
                console.log(error)
            })();
        SaveReferenceNumber()
    };
    const SaveReferenceNumber = () => {
        setisLoading(true)
        const CUReferenceNumber = async () => {
            const req: ReferenceNumberRequest = {
                Path: props.site,
                ApiKey: "-1",
                UserName: userName,
                AccountId: accountId,
                SK: sessionKey,
                Pattern: getValues("Pattern") != undefined ? getValues("Pattern").toString().trimEnd() : "",
                TemplateId: props.templateId,
                Operation: referenceNumber.Id > 0 ? "Update" : "Create",
                Id: getValues("Id"),
                ResetAnnually: getValues("ResetAnnually"),
                // ResetDateTime: getValues("ResetDateTime"), //referenceNumberRequest?.ResetDateTime!,
                ResetMonthly: getValues("ResetMonthly"),
                SeedMaxNumber: getValues("SeedMaxNumber") != undefined ? getValues("SeedMaxNumber").toString() : "",//referenceNumberRequest?.SeedMaxNumber!,
                SeedValue: getValues("SeedValue") != undefined ? getValues("SeedValue").toString() : "",//referenceNumberRequest?.SeedValue!,
                SeedZeroPadding: getValues("SeedZeroPadding"),
                Autoprovision: true
            }


            const result = await Helper.CreateUpdateReferenceNumber(req)
            if (result.Success) {

                setShowSuccess(true)
                setShowMessage(false)
                setMessage("Reference number saved successfully!")
                GetReferenceNumber()

                setTimeout(function () {
                    setShowSuccess(false)
                    setShowMessage(false)
                    setMessage("")
                    setisLoading(false)



                }, 6000);

            } else {

                setShowSuccess(false)
                setShowMessage(true)
                setMessage("Something went wrong!")
                setisLoading(false)

                setTimeout(function () {
                    setShowSuccess(false)
                    setShowMessage(false)
                    setMessage("")
                    setisLoading(false)


                }, 6000);
            }

        }
        CUReferenceNumber();

    }
    useEffect(() => { GetReferenceNumber(); }, [props.showReferenceNumber])

    useEffect(() => {

    }, [])
    const mapReferenceNumber = (res: IReferenceNumber[]) => {
        let rn = res != undefined && res.length > 0 ? res[0] : emptyReferenceNumber
        setReferenceNumber(rn!)
        if (rn != undefined) {
            for (const [key, value] of Object.entries(rn)) {
                //  let Defaultvalue=( key!=undefined && key !="")  && key.includes("Pattern")? (value!==undefined && value!="")? value.toString().trimEnd():value:value
                setValue(key, value)
            }
        }
    };
    const onDeleteReferenceNumber = () => {
        setisLoading(true)
        if (props.templateId > 0) {
            const Delete = async () => {
                let ReferenceNumberDelete = emptyReferenceNumber
                ReferenceNumberDelete.TemplateId = props.templateId;
                ReferenceNumberDelete.Id = getValues("Id") != undefined && getValues("Id") > 0 ? getValues("Id") : 0
                const req: ReferenceNumberRequest = {
                    Path: props.site,
                    ApiKey: "-1",
                    UserName: userName,
                    AccountId: accountId,
                    SK: sessionKey,
                    Pattern: emptyReferenceNumber.Pattern,
                    TemplateId: emptyReferenceNumber.TemplateId,
                    Operation: "Delete",
                    Id: emptyReferenceNumber.Id,
                    ResetAnnually: emptyReferenceNumber.ResetAnnually,
                    //  ResetDateTime: emptyReferenceNumber.ResetDateTime, //referenceNumberRequest?.ResetDateTime!,
                    ResetMonthly: emptyReferenceNumber.ResetMonthly,
                    SeedMaxNumber: emptyReferenceNumber.SeedMaxNumber,
                    SeedValue: emptyReferenceNumber.SeedValue,
                    SeedZeroPadding: emptyReferenceNumber.SeedZeroPadding,
                    Autoprovision: true
                }


                const result = await Helper.DeleteReferenceNumber(req)
                if (result.Success) {

                    setShowSuccess(true)
                    setShowMessage(false)
                    setMessage("Reference number removed successfully!")
                    GetReferenceNumber()
                    setisLoading(false)
                    setTimeout(function () {
                        setShowSuccess(false)
                        setShowMessage(false)
                        setMessage("")
                        setisLoading(false)
                    }, 6000);
                } else {

                    setShowSuccess(false)
                    setShowMessage(true)
                    setMessage("Something went wrong!")
                    setisLoading(false)
                    setTimeout(function () {
                        setShowSuccess(false)
                        setShowMessage(false)
                        setMessage("")
                        setisLoading(false)
                    }, 6000);
                }

            }
            Delete();
        }



    }
    const GetReferenceNumber = async () => {
        setLoadingReferenceNumber(true)
        const req: ReferenceNumberRequest = {
            Path: props.site,
            ApiKey: "-1",
            UserName: userName,
            AccountId: accountId,
            SK: sessionKey,
            TemplateId: props.templateId,
            Operation: "Get",
            Id: 0,
            Pattern: "",
            ResetAnnually: false,
            ResetMonthly: false,
            SeedMaxNumber: "",
            SeedValue: "",
            SeedZeroPadding: false,
            Autoprovision: true
        }

        await Helper.GetReferenceNumber(req)
            .then((data) => {
                setLoadingReferenceNumber(false)
                mapReferenceNumber(data)
            })
            .catch((err) => {
                setLoadingReferenceNumber(false)
                mapReferenceNumber([])
                console.log(err)
            })

    }
    return (<>
        {!props.fromModal ?
            <div className="ms-Grid-row" >
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                    <label className="ms-fontSize-18">Manage Reference Number</label><br />
                    <div>{branding.appName} creates a reference number placeholder for every new application that is created. Reference numbers generate independently of tags however for special tagging of reference numbers within webforms, please see the link to the Webform Wizard How To Guide.</div>
                    <p>For more information check out <Link target='_blank' to={`${branding.helpPath}#referencenumber}`}>Reference Number Documentation</Link></p>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                    <label className="ms-fontSize-18">Manage Reference Number</label>
                </div>
            </div>
            : null
        }
        <Separator />
        <Stack tokens={stackTokens}>
            {!loadingReferenceNumber ?


                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                            {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9"> </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                            <ActionButton iconProps={deleteIcon} id="btnDeleteReferenceNumber" onClick={onDeleteReferenceNumber} text={"Reset reference number"} title="Reset reference number." />

                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9" style={{ paddingTop: "4%", paddingBottom: "0%" }}>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <RHFTextBoxControl
                                    id="TemplateId"
                                    maxLength={300}
                                    disabled={true}
                                    value={props.templateId}
                                    label="TemplateId"
                                    title="Enter Template id."
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                    required={true}
                                    readOnly={true}
                                />

                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <RHFTextBoxControl
                                    id="Pattern"
                                    maxLength={300}
                                    disabled={false}
                                    label="Format"
                                    title="Enter Format."
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                />
                            </div>

                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9" style={{ paddingTop: "4%", paddingBottom: "0%" }}>

                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <RHFTextBoxControl
                                    id="SeedMaxNumber"
                                    maxLength={19}
                                    disabled={false}
                                    label="Highest Number"
                                    title="Enter seed highest number."
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <RHFTextBoxControl
                                    id="SeedValue"
                                    maxLength={19}
                                    disabled={false}
                                    label="Starting Number"
                                    title="Enter starting number."
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                />
                            </div>


                        </div>
                        {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <RHFTextBoxControl
                                    id="ResetDateTime"
                                    maxLength={300}
                                    disabled={true}
                                    value={"01/01/2023"}
                                    label="Reset DateTime"
                                    title="Enter Pattern."
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                />
                            </div> 
                           

                        </div> */}

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9" style={{ paddingTop: "4%", paddingBottom: "4%" }}>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">

                                <Label title='Seed Zero Padding'>Pad Leading Zeros</Label>
                                <RHFToggleControl
                                    id="SeedZeroPadding"
                                    disabled={false}
                                    title={"Available to Seed Zero Padding."}
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger} />

                            </div>

                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <Label title='Reset AnnuallyS'>Reset Seed each Year</Label>
                                <RHFToggleControl
                                    id="ResetAnnually"
                                    disabled={false}
                                    title={"Reset Annually."}
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger} />
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <Label title='Reset Monthlyg'>Reset Seed each Month</Label>
                                <RHFToggleControl
                                    id="ResetMonthly"
                                    disabled={false}
                                    title={"Reset Monthly."}
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger} />

                            </div>

                        </div>

                    </div>
                    <Separator theme={theme}></Separator>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">

                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                {!isLoading ? <>

                                    <PrimaryButton id="btnSaveReferenceNumber" onClick={onSaveReferenceNumber} text={referenceNumber.Id > 0 ? "Update" : "Save"} title="Generate reference number." /> :
                                    <DefaultButton id="btnCancelReferenceNumber" onClick={() => {
                                        setGlobalDateLocale(navigator.language)
                                        reset({}, { keepDirty: false, keepErrors: false, keepIsValid: false })
                                        clearErrors()
                                    }} text="Cancel" title="Close." />
                                </> : <Spinner size={SpinnerSize.medium} label={referenceNumber.Id == 0 ? "Creating application..." : "Updating application..."} ariaLive="assertive" />}
                            </DialogFooter>
                        </div>
                    </div>

                </div>
                : <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                            <Spinner size={SpinnerSize.medium} label={"Loding data..."} ariaLive="assertive" />

                        </div>
                    </div>
                </div>
            }
        </Stack>
    </>)

}
export default ReferenceNumber