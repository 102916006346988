import {IStackStyles, mergeStyleSets} from "@fluentui/react";
import {ITextStyles} from "office-ui-fabric-react/lib/components/Text/Text.types";
import {Theme} from "@fluentui/react-theme-provider";
import {FontWeights} from "@fluentui/react/lib/Styling";

export const _styles = mergeStyleSets({
    body: {
        flex: '4 4 auto',
        padding: 0,
    }
})

export const nonShrinkingStackItemStyles: IStackStyles = {
    root: {
        padding: 12,
        overflow: 'hidden',
        alignItems: "center",
    },
}

export const detailInfoStyles = (theme: Theme): ITextStyles => {
    return {
        root: {
            padding: 6,
            borderBottom: `solid 2px ${theme.semanticColors.listHeaderBackgroundHovered}`
        }
    }
}

export const detailInfoStylesTitle = (theme: Theme): ITextStyles => {
    return {
        root: {
            fontWeight: FontWeights.semibold,
            padding: 6,
            borderBottom: `solid 2px ${theme.semanticColors.listHeaderBackgroundHovered}`
        }
    }
}
