import React, { CSSProperties, useEffect, useState } from 'react';
import { Panel, PanelType, } from '@fluentui/react/lib/Panel';
import { useConstCallback } from '@uifabric/react-hooks';
import { isMobile } from 'react-device-detect';
import { Icon, IContextualMenuItem, IContextualMenuProps, IIconStyles, IStackTokens, Link, Stack } from '@fluentui/react';
import '../../web.css'
import { set } from 'lodash';
import Helper from '../../Helpers/Helper';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react";
import { useBranding } from '../../hooks/useBranding';
import CustomerSupport from "../CustomerSupport";



function HelpMarketplace(props: any) {
    const [isOpen, setIsOpen] = React.useState(true);
    const [user, setUser] = React.useState("");
    const dismissPanel = useConstCallback(() => { setIsOpen(false); props.updateTemplate() });
    const [hash, setHash] = useState("");
    const [openContactUs, setOpenContactUs] = useState(false)
    const { branding, getAppSettings} = useBranding()
    
    const iconStyles: IIconStyles = {
        root: {
            fontSize: '18px',
            height: '18px',
            width: '18px',
            color: 'rgb(16, 110, 190)'
        },
    };
    const iconStyleVideos: IIconStyles = {
        root: {
            fontSize: '18px',
            height: '18px',
            width: '18px',
            color: 'rgb(64, 102, 146)'
        },
    };
    const imgHelp = mergeStyleSets({
        img: {
            '@media(max-width: 600px)': {
                width: '100%',
                height: 'auto',

            },
            display: 'block',
            marginBottom: '24px',
            marginTop: '24px'
        }
    });
    const img_Vertical: CSSProperties = {
        width: "35%",
        height: "55%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    };
    const img_Horizontal: CSSProperties = {
        width: "90%",
        height: "65%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    };
    const img_Medium_Sized_Horizontal: CSSProperties = {
        width: "60%",
        height: "65%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    };
    const img_Small_Horizontal: CSSProperties = {
        width: "25%",
        height: "65%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    };
    const text: CSSProperties = { marginRight: "6%", textAlign: "justify" }

    const img_SmaVertical: CSSProperties = {
        width: "25%",
        height: "55%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    };
    const litext: CSSProperties = { marginRight: "6%", textAlign: "justify", paddingBottom: "1%" }


    function getUser() {
        var url_string = window.location.href
        var url = new URL(url_string);
        var id = url.searchParams.get("id");
        var decoded = atob(id!);
        setUser(decoded!)
        let tagHel = sessionStorage.getItem("tagHelp")!
        if (sessionStorage.getItem("entityId") !== "Not In Teams" && (tagHel !== undefined && tagHel !== "")) {
            setHash("#" + tagHel!)
        }
        else if (window.location.hash !== "") {
            setHash(window.location.hash)

        }
        sessionStorage.removeItem("tagHelp")
    }

    useEffect(() => {
        getUser()
        getAppSettings()
    }, [])

    const getActive = (hash: string, location: string) => {
        if (hash === location) {
            return "active"
        }

        return ""
    }

    const setWindowsHash = (location: string) => {
        setHash(location)
    }
    function openNav() {
        document.getElementById("mySidepanel")!.style.width = "250px";
    }

    /* Set the width of the sidebar to 0 (hide it) */
    function closeNav() {
        document.getElementById("mySidepanel")!.style.width = "0";
    }

    const _openSupportForm = () => {
        setOpenContactUs(true)
    }

    const hideContactUs = () => {
        setOpenContactUs(false)
    }
 
    return (
        <div className="mainContainerWhite" >
            <div className="help__container">
                {!isMobile ?
                    <div className="help__navbar help__thumbnail" style={{ height: "130vh" }}>
                        <div className="help_thumbnail__content text-center">
                            <img style={{ width: "20%", height: "20%" }} src="https://ask.kim/wp-content/uploads/2018/02/kim-trans.png" />
                            <h2 className="headerText heading--secondary">Help Guide</h2>
                            <h3 className="headerText heading--secondary">Kim Technologies Product</h3>
                        </div>
                        <div className="help_thumbnail__content_nav text-center">
                            <div className="scrollVisible" style={{ overflow: "auto", height: window.innerHeight - 200 }}>
                                <ul className="help_ul">
                                    <li ><a className={getActive(hash, "#home") + " help_li"} href="helpmarketplace#home" onClick={() => { setWindowsHash("#home") }} >Getting Started</a></li>
                                    <li ><a className={getActive(hash, "#manageapplication") + " help_li"} href="helpmarketplace#manageapplication" onClick={() => { setWindowsHash("#manageapplication") }} >Use Downloaded Template</a></li>
                                    <li ><a className={getActive(hash, "#workspace") + " help_li tab1"} href="helpmarketplace#workspace" onClick={() => { setWindowsHash("#workspace") }}>Workspace</a></li>
                                    <li ><a className={getActive(hash, "#applicationlibrary") + " help_li tab1"} href="helpmarketplace#applicationlibrary" onClick={() => { setWindowsHash("#applicationlibrary") }}>Applications</a></li>
                                    <li ><a className={getActive(hash, "#audiences") + " help_li tab1"} href="helpmarketplace#audiences" onClick={() => { setWindowsHash("#audiences") }}>Audiences</a></li>
                                    <li ><a className={getActive(hash, "#campaigns") + " help_li tab1"} href="helpmarketplace#campaigns" onClick={() => { setWindowsHash("#campaigns") }}>Campaigns</a></li>
                                    <li ><a className={getActive(hash, "#clone") + " help_li tab1"} href="helpmarketplace#clone" onClick={() => { setWindowsHash("#clone") }}>Clone</a></li>
                                    <li ><a className={getActive(hash, "#delete") + " help_li tab1"} href="helpmarketplace#delete" onClick={() => { setWindowsHash("#delete") }}>Delete</a></li>
                                    <li ><a className={getActive(hash, "#download") + " help_li tab1"} href="helpmarketplace#download" onClick={() => { setWindowsHash("#download") }}>Download</a></li>
                                    <li ><a className={getActive(hash, "#importexport") + " help_li tab1"} href="helpmarketplace#importexport" onClick={() => { setWindowsHash("#importexport") }}>Export</a></li>
                                    <li ><a className={getActive(hash, "#properties") + " help_li tab1"} href="helpmarketplace#properties" onClick={() => { setWindowsHash("#properties") }}>Properties</a></li>
                                    <li ><a className={getActive(hash, "#publishwebformlink") + " help_li tab1"} href="helpmarketplace#publishwebformlink" onClick={() => { setWindowsHash("#publishwebformlink") }}>Publish Webform Link</a></li>
                                    <li ><a className={getActive(hash, "#version") + " help_li tab1"} href="helpmarketplace#version" onClick={() => { setWindowsHash("#version") }}>Version</a></li>
                                    <li ><a className={getActive(hash, "#update") + " help_li tab1"} href="helpmarketplace#update" onClick={() => { setWindowsHash("#update") }}>Update</a></li>
                                    <li ><a className={getActive(hash, "#webformwwizard") + " help_li tab1"} href="helpmarketplace#webformwwizard" onClick={() => { setWindowsHash("#webformwwizard") }}>Webform Wizard</a></li>
                                    <li ><a className={getActive(hash, "#taggingdocument") + " help_li tab1"} href="helpmarketplace#taggingdocument" onClick={() => { setWindowsHash("#taggingdocument") }}>Tagging a document </a></li>
                                    <li ><a className={getActive(hash, "#uploadingdocument") + " help_li tab1"} href="helpmarketplace#uploadingdocument" onClick={() => { setWindowsHash("#uploadingdocument") }}>Uploading the Document and Creating the Application </a></li>
                                    <li ><a className={getActive(hash, "#configuringapplication") + " help_li tab1"} href="helpmarketplace#configuringapplication" onClick={() => { setWindowsHash("#configuringapplication") }}>Configuring an Application in the Webform Wizard</a></li>
                                    <li ><a className={getActive(hash, "#records") + " help_li tab1"} href="helpmarketplace#records" onClick={() => { setWindowsHash("#records") }}>Records</a></li>
                                    <li ><a className={getActive(hash, "#settings") + " help_li tab1"} href="helpmarketplace#settings" onClick={() => { setWindowsHash("#settings") }}>Settings </a></li>
                                    <li ><a className={getActive(hash, "#search") + " help_li tab1"} href="helpmarketplace#search" onClick={() => { setWindowsHash("#search") }}>Search</a></li>
                                    <li ><a className={getActive(hash, "#usermenu") + " help_li tab1"} href="helpmarketplace#usermenu" onClick={() => { setWindowsHash("#usermenu") }}>User Menu</a></li>
                                    <li ><a className={getActive(hash, "#webbrowserusermenu") + " help_li tab1"} href="helpmarketplace#webbrowserusermenu" onClick={() => { setWindowsHash("#webbrowserusermenu") }}>Web Browser User Menu</a></li>
                                    <li ><a className={getActive(hash, "#microsoftteamsusermenu") + " help_li tab1"} href="helpmarketplace#microsoftteamsusermenu" onClick={() => { setWindowsHash("#microsoftteamsusermenu") }}>Microsoft Teams User Menu</a></li>
                                    <li ><a className={getActive(hash, "#contactus") + " help_li"} href="helpmarketplace#contactus" onClick={() => { setWindowsHash("#contactus") }}>Get Support/Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="help__overlay"></div>
                    </div>
                    :
                    <>
                        <div id="mySidepanel" className="sidepanel">
                            <a href="javascript:void(0)" className="closebtn" onClick={() => { closeNav() }}>&times;</a>
                            <div className="scrollVisible" style={{ overflow: "auto", height: window.innerHeight - 90 }}>
                                <ul className="help_ul">
                                    <li ><a className={getActive(hash, "#home") + " help_li"} href="helpmarketplace#home" onClick={() => { setWindowsHash("#home") }} >Getting Started</a></li>
                                    <li ><a className={getActive(hash, "#manageapplication") + " help_li"} href="helpmarketplace#manageapplication" onClick={() => { setWindowsHash("#manageapplication") }} >Use Downloaded Template</a></li>
                                    <li ><a className={getActive(hash, "#workspace") + " help_li"} href="helpmarketplace#workspace" onClick={() => { setWindowsHash("#workspace") }}>Workspace</a></li>
                                    <li ><a className={getActive(hash, "#applicationlibrary") + " help_li tab1"} href="helpmarketplace#applicationlibrary" onClick={() => { setWindowsHash("#applicationlibrary") }}>Applications</a></li>
                                    <li ><a className={getActive(hash, "#audiences") + " help_li"} href="helpmarketplace#audiences" onClick={() => { setWindowsHash("#audiences") }}>Audiences</a></li>
                                    <li ><a className={getActive(hash, "#campaigns") + " help_li"} href="helpmarketplace#campaigns" onClick={() => { setWindowsHash("#campaigns") }}>Campaigns</a></li>
                                    <li ><a className={getActive(hash, "#clone") + " help_li"} href="helpmarketplace#clone" onClick={() => { setWindowsHash("#clone") }}>Clone</a></li>
                                    <li ><a className={getActive(hash, "#delete") + " help_li"} href="helpmarketplace#delete" onClick={() => { setWindowsHash("#delete") }}>Delete</a></li>
                                    <li ><a className={getActive(hash, "#download") + " help_li"} href="helpmarketplace#download" onClick={() => { setWindowsHash("#download") }}>Download</a></li>
                                    <li ><a className={getActive(hash, "#importexport") + " help_li tab1"} href="helpmarketplace#importexport" onClick={() => { setWindowsHash("#importexport") }}>Export</a></li>
                                    <li ><a className={getActive(hash, "#properties") + " help_li tab1"} href="helpmarketplace#properties" onClick={() => { setWindowsHash("#properties") }}>Properties</a></li>
                                    <li ><a className={getActive(hash, "#publishwebformlink") + " help_li tab1"} href="helpmarketplace#publishwebformlink" onClick={() => { setWindowsHash("#publishwebformlink") }}>Publish Webform Link</a></li>
                                    <li ><a className={getActive(hash, "#version") + " help_li tab1"} href="helpmarketplace#version" onClick={() => { setWindowsHash("#version") }}>Version</a></li>
                                    <li ><a className={getActive(hash, "#update") + " help_li tab1"} href="helpmarketplace#update" onClick={() => { setWindowsHash("#update") }}>Update</a></li>
                                    <li ><a className={getActive(hash, "#webformwwizard") + " help_li tab1"} href="helpmarketplace#webformwwizard" onClick={() => { setWindowsHash("#webformwwizard") }}>Webform Wizard</a></li>
                                    <li ><a className={getActive(hash, "#taggingdocument") + " help_li tab1"} href="helpmarketplace#taggingdocument" onClick={() => { setWindowsHash("#taggingdocument") }}>Tagging a document</a></li>
                                    <li ><a className={getActive(hash, "#uploadingdocument") + " help_li tab1"} href="helpmarketplace#uploadingdocument" onClick={() => { setWindowsHash("#uploadingdocument") }}>Uploading the Document and Creating the Application </a></li>
                                    <li ><a className={getActive(hash, "#configuringapplication") + " help_li tab1"} href="helpmarketplace#configuringapplication" onClick={() => { setWindowsHash("#configuringapplication") }}>Configuring an Application in the Webform Wizard</a></li>
                                    <li ><a className={getActive(hash, "#records") + " help_li tab1"} href="helpmarketplace#records" onClick={() => { setWindowsHash("#records") }}>Records</a></li>
                                    <li ><a className={getActive(hash, "#settings") + " help_li tab1"} href="helpmarketplace#settings" onClick={() => { setWindowsHash("#settings ") }}>Settings </a></li>
                                    <li ><a className={getActive(hash, "#search") + " help_li tab1"} href="helpmarketplace#search" onClick={() => { setWindowsHash("#search") }}>Search</a></li>
                                    <li ><a className={getActive(hash, "#usermenu") + " help_li tab1"} href="helpmarketplace#usermenu" onClick={() => { setWindowsHash("#usermenu") }}>User Menu</a></li>
                                    <li ><a className={getActive(hash, "#webbrowserusermenu") + " help_li tab1"} href="helpmarketplace#webbrowserusermenu" onClick={() => { setWindowsHash("#webbrowserusermenu") }}>Web Browser User Menu</a></li>
                                    <li ><a className={getActive(hash, "#microsoftteamsusermenu") + " help_li tab1"} href="helpmarketplace#microsoftteamsusermenu" onClick={() => { setWindowsHash("#microsoftteamsusermenu") }}>Microsoft Teams User Menu</a></li>
                                    <li ><a className={getActive(hash, "#contactus") + " help_li tab1"} href="helpmarketplace#contactus" onClick={() => { setWindowsHash("#contactus") }}>Get Support/Contact Us</a></li>
                                </ul>
                            </div>
                        </div>

                        <button className="openbtn" onClick={() => { openNav() }}>&#9776;</button>
                    </>
                }
                <div className="help__child signup__formHelp">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={text}>
                                <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", height: "100vh" }}>
                                    <h1 id="home" className="headerText" style={{ color: "black" }}>Help Guide</h1>
                                    <article>
                                        <p>Use preconfigured, modify or create new applications with forms and documents for individual or team use.
                                            Eliminate rekeying and quickly transform manual processes into automated solutions.
                                        </p>
                                        <ul>
                                            <li style={litext}>General agreements and contracts</li>
                                            <li style={litext}>Sales - SoW, Pricing, Receipts, etc.</li>
                                            <li style={litext}>HR - Onboarding, Leave Requests, etc.</li>
                                            <li style={litext}>Compliance - Change, ISO and Legal</li>
                                            <li style={litext}>Letters, Tracking and Notifications</li>
                                        </ul>

                                        <p style={text}>These features are built-in to this product:</p>
                                        <ul>
                                            <li style={litext}>Webform automation</li>
                                            <li style={litext}>Document automation </li>
                                            <li style={litext}>Document management</li>
                                            <li style={litext}>Records management</li>
                                            <li style={litext}>Full text and advanced search</li>
                                            <li style={litext}>Document sharing and collaboration </li>
                                            <li style={litext}>Application and form sharing and collaboration</li>
                                            <li style={litext}>Electronic signature</li>
                                        </ul>
                                    </article>
                                    <article>
                                        <h2 id="workspace">Workspace Overview</h2>
                                        <p style={text}>Within your workspace, work with records, manage and configure Applications.
                                            The workspace supports the following:
                                        </p>
                                        <ul>
                                            <li style={litext}>Configuration</li>
                                            <li style={litext}>Applications</li>
                                            <li style={litext}>Records</li>
                                            <li style={litext}>Settings</li>
                                            <li style={litext}>Search</li>
                                            <li style={litext}>Role Based Access Control</li>
                                        </ul>
                                        {!isMobile ? <>
                                            <img src="/images/WorkspaceMarketplace.png" alt="Workspace layout." style={img_Horizontal} /></> :
                                            null}
                                    </article>
                                    <article>
                                        <br/>
                                        <h2 id="applicationlibrary">Applications</h2>
                                        <p style={text}>Applications are configurable Webforms and documents that create records.
                                            They are grouped into categories which you configure or import.
                                            From Applications use the context menu to access Webform Wizard, template, preoperties and settings or
                                            select that Application to fill forms from the workspace.</p>

                                        {!isMobile ? <>
                                            <img src="/images/ApplicationsMarketplace.png" alt="Applications." style={img_Vertical} /></> :
                                            null}
                                    </article>
                                    <article>
                                        <h2 id="audiences">Audience</h2>
                                        <ul>
                                            <li style={{ marginRight: "6%", textAlign: "justify" }}>An Application&apos;s Audience has three primary access grants:
                                                <br /><br />
                                                <ol style={litext}>
                                                    <li style={litext}><strong>&quot;Owner&quot;</strong> is applied automatically for user that created the Application.
                                                    </li>
                                                    <li style={litext}><strong>&quot;Co-Owners&quot;</strong> are audience members who can manage Applications (update etc.),
                                                        upload lists, manage signatures and other aspects of an Application.
                                                    </li>
                                                    <li style={litext}><strong>&quot;Create Document&quot;</strong> are those members who are granted the ability
                                                        to fill forms and manage Records that have been created.
                                                    </li >
                                                </ol>
                                            </li>
                                            <li style={litext}>
                                                <strong>&quot;Default Audience&quot;</strong> is created automatically when a user registers.
                                                The default audience cannot be deleted except in instances when a user deletes their account.
                                            </li>
                                            <li style={litext}>
                                                <strong>&quot;   audiences&quot;</strong> are the user that created the Records,
                                                Application Audience Owners and Co-Owners associated to the document's template type,
                                                and users that have been invited from the share action on the Record menu.
                                            </li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/AudienceMarketplace.png" alt="Audiences." style={img_Medium_Sized_Horizontal} /></> :
                                            null}
                                        <p><strong>For a step-by-step guide on how to manage audience users, <Link href='/HowTo/How%20To%20Create%20and%20Manage%20Audiences.pdf' target="_blank" title="Manage audience users">CLICK HERE</Link></strong></p>
                                    </article>
                                    {}
                                    <article>
                                        <h2 id="campaigns">Campaigns</h2>
                                        <p style={text}>
                                            Campaigns are frequent or one-time events whereby respondent groups are invited via email to complete a request.
                                            When each respondent from the group selects the link (and enters the one-time passcode they receive) they are then able
                                            to access and fill in the form. Upon submission, the completed document is emailed to the respondent and the Record (along with the document,
                                            datapoints and audit trail) are recorded and available for the Campaign administrator(s) to access.
                                            Campaigns are available for any Application.</p>
                                        <br />
                                        <p><strong>For a step-by-step guide on how to create Campaigns, <Link href='/HowTo/How%20To%20Create%20a%20Campaign.pdf' target="_blank" title="Create Campaigns">CLICK HERE</Link></strong></p>
                                        {!isMobile ? <>
                                            <img src="/images/Campaigns.png" alt="Campaigns." style={img_Medium_Sized_Horizontal} /></> :
                                            null
                                        }
                                    </article>
                                    <article>
                                        <h2 id="clone">Clone</h2>
                                        <p style={text}>
                                            Clone allows a user to clone an Application, Webform and any related lists to another user by entering their email address.
                                            Clone is useful when automating Applications for other users or as a basis to create additional Applications for yourself.
                                            Clones are assigned to the specified user's default audience and the specified user may change the audience from Application
                                            properties or settings. This action is logged to the permanent record. Owners and Co-Owner(s) have rights to clone Applications.
                                            Campaigns are available for any Application.<br /><br /> ** Clone does not copy import integrataion or document assembly.</p>
                                        {!isMobile ? <>
                                            <img src="/images/Clone.png" alt="Clone." style={img_Small_Horizontal} /></> :
                                            null
                                        }
                                        <br />
                                    </article>
                                    <article>
                                        <h2 id="delete">Delete</h2>
                                        <p style={text}>
                                            Delete permanently removes an Application. When deleting, a user is notified of how many open and closed Records are linked to
                                            the Application. If there are open Records, the user is not able to delete the Application.
                                            The user must delete or close the relevant Records before proceeding with the Application deletion. This action is logged
                                            as a permanent record and is not recoverable. Owner and Co-Owner(s) are granted rights to delete Applications.</p>
                                        <br />
                                        {!isMobile ? <>
                                            <img src="/images/delete.png" alt="Delete." style={img_Small_Horizontal} /></> :
                                            null
                                        }
                                    </article>
                                    <article>
                                        <h2 id="download">Download</h2>
                                        <p style={text}>
                                            Download allows you to change format, layout and add tags to the document template that is used by the Application.
                                            When changes have been made and the document template is uploaded, new Records will generate documents with those changes.
                                            Owners and Co-Owner(s) are granted rights to download and upload document templates.</p>
                                        <br />
                                        {!isMobile ? <>
                                            <img src="/images/download.png" alt="Download." style={img_Small_Horizontal} /></> :
                                            null
                                        }
                                    </article>
                                    <article>
                                        <h2 id="importexport">Export (Note: this guidance note also explains Import)</h2>
                                        <ul>
                                            <li style={litext}>The export functionality allows a user to export an entire Application.
                                                The user can either copy the link to the Application and send it to another
                                                user via email or download it themselves<br /></li>
                                            <li style={litext}>If elected, you can protect your Application’s exported file by applying a password<br /></li>
                                            <li style={litext}>Once an exported package has been emailed to a user, the user can import the Application by clicking on the link.
                                                Alternatively, if you would like to upload an import package yourself, you can simply upload it by clicking on the
                                                down arrow next to “Create New Application”.<br /></li>
                                        </ul>
                                        <br />
                                        {!isMobile ? <>
                                            <img src="/images/export.png" alt="Export." style={img_Small_Horizontal} /></> :
                                            null
                                        }
                                        <br />
                                        <p><strong>For a step-by-step guide on how to import/export Applications, <Link href='/HowTo/How%20To%20Export%20and%20Import%20Applications.pdf' target="_blank" title="Export ">CLICK HERE</Link></strong></p>
                                        <br/>
                                    </article>
                                    <article>
                                        <h2 id="properties">Properties</h2>
                                        <h3>Tab 1 - Properties</h3>
                                        <ul>
                                            <li style={litext}><strong>Display Name</strong> is the name of the Application that will appear under the main category (see below).<br /></li>
                                            <li style={litext}><strong>Display Name ToolTip </strong> appears when users hover over the Application's displayed name.<br /></li>
                                            <li style={litext}><strong>Description </strong>is detailed information for the administrators who manage the Application.<br /></li>
                                            <li style={litext}><strong>Default Form Wizard Description </strong> is used to prefill the description textbox (located in the top left corner of your Webform) with a default description of the Application.<br /></li>
                                            <li style={litext}><strong>Service Email Account </strong> allows an administrator to send emails from their own Microsoft Office 365 Account rather than the default Email provider.
                                                Once you enter your email, an email will be sent to the specified email address. You will need to open the email and consent to the use of the specified email.<br /></li>
                                            <li style={litext}><strong>Email From Friendly Name </strong> is additional text you can include when a user receives an email from the system.  For example, specifying
                                                “Onboard Form” would display as Onboard Form ({branding.shareEmail}) rather than {branding.shareEmail}.<br /></li>
                                            <li style={litext}><strong>Categories </strong> are user defined and can relate to one or more Applications. Applications may be moved from one category to
                                                another by the owner or co-Owner.  For example, a category name can be “Non-Disclosure Agreements” and the various Applications that would sit under
                                                it when expanded would include “NDA-England & Wales”, “NDA-New York” etc.<br /></li>
                                            <li style={litext}><strong>Application Audience </strong> is a user group (that can be managed) where Owner, Co-Owner(s) and Create Document rights are granted.
                                                Application Audiences may be assigned to multiple Applications.<br /></li>
                                            <li style={litext}><strong>Culture  </strong> Culture is automatically set per Application. The date format displayed is dependent on the selected country and locale.<br /></li>
                                        </ul>
                                        <br />
                                        <br />
                                        {!isMobile ? <>
                                            <img src="/images/Tab1-Properties.png" alt="Tab 1 - Properties." style={img_Medium_Sized_Horizontal} /></> :
                                            null
                                        }
                                        <h3>Tab 2 - Settings (Note: the following fields must be specified per Application)</h3>
                                        <ul>
                                            <li style={litext}><strong>Enable Send Mail Shared Access </strong> enables the “send mail” action available for a Record.
                                                Activating this feature allows shared users to send the Record via email.
                                                By default, this action is available to administrators and not available to shared users.<br /></li>
                                            <li style={litext}><strong>Enable Form Wizard Shared Access  </strong>enables the “update” action available for a Record. Activating this feature allows shared users
                                                to update a Record’s data from the Webform Wizard. By default, this action is available to administrators and not available to shared users.<br /></li>
                                            <li style={litext}><strong>Enable DocX Availability  </strong> activates the “checkout” action for a Record. Activating this feature allows all users to checkout,
                                                download and checkin Records for the purpose of performing manual updates. By default, this action is not available to administrators or shared users.<br /></li>
                                            <li style={litext}><strong>Webform Link Document Delivery on Submit  </strong> sets behavior when the end user submits from a publish Webform or send Webform update link and receives
                                                a PDF, DocX, both or no attachment on the response email.<br /></li>
                                            <li style={litext}><strong>Return URL for Publish Webform and Send Webform Update Link (Optional)  </strong> sets the location of the redirect after the end user submits a
                                                Publish Webform or Send Webform Update Link.<br /></li>
                                            <li style={litext}><strong>Enable to suppress Document Preview after submit  </strong> will bypass the document preview when end user submits a form.<br /></li>
                                            <li style={litext}><strong>Publish List  </strong> allows the Application listing to be available for external publishing to other systems including your private Large Language Model (LLM).
                                                Enter context to specify collective grouping of the search results and / or to be used as filters.<br /></li>
                                            <li style={litext}><strong>Enable Document Notifications  </strong> allows a user to enable specific email notifications for each action specified.
                                                When enabled for an action, an email will be sent to the Application Owner(s) when the action occurs.
                                                Specific notifications include:
                                                <br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Document Creation." /> Select <strong>Document Creation</strong> for notifications when a Record is created;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Document Update." /> Select <strong>Document Update</strong> for notifications when a Record is updated;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Document Delete." /> Select <strong>Document Delete</strong> for notification when a Record is deleted;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Document Share." /> Select <strong>Document Share</strong> for notification when a Record or Record link is shared;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Application Edit." /> Select <strong>Aplication Edit</strong> for notification when the Application is saved from the Webform Wizard;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Application Share." /> Select <strong>Application Delete</strong> for notification when the Application is deleted and</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Application Remove." /> Select <strong>Application Remove</strong> for notification when the Application is removed and</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="Select Application Edit." /> Select <strong>Application Share</strong>for notification when the Application is cloned or a user is added to the
                                                        Application's administrative audience.</li>
                                                </ul>
                                                <br />
                                            </li>

                                            <li style={litext}><strong>Electronic Signature  </strong>enables the electronic signature action for a Record. This feature supports DocuSign, auto-sign or a combination of both.
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="For DocuSign." />For DocuSign, the administrator must have a DocuSign account. </li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} title="auto-sign." />For auto-sign, the template must support docked signatures and the number of auto-signers must be set. </li>
                                                    <li style={{ display: "block", padding: "1%" }}>  <p><strong>For a step-by-step guide on how to use electronic signatures <Link href='/HowTo/How%20To%20Use%20DocuSign%20and%20Kim%20AutoSign.pdf' target="_blank" title="Export ">CLICK HERE</Link></strong></p></li>
                                                </ul>
                                                <br />
                                            </li>
                                            <li style={litext}><strong>Subform   </strong>allows a user to specify whether they would like the Application to be designated as a subform.
                                                This means that when Records are generated from different Applications, the relevant Record that is generated can be associated with the subform Record.
                                                The option to link the Record to the subform Application is found in the actions menu of a Record covered below under "Records"<br />
                                            </li>
                                        </ul>

                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/Tab2-Settings1Marketplace.png" alt="Tab 1 - Properties." style={img_Medium_Sized_Horizontal} />
                                            <img src="/images/Tab2-Settings2Marketplace.png" alt="Tab 1-2 - Properties." style={img_Medium_Sized_Horizontal} />
                                          <br /></> :
                                            null
                                        }
                                        <br />
                                        <h3 id="inboundintegration">Tab 3 - API Inbound Integration</h3>
                                        <ul>
                                            <li style={litext}>This functionality gives you access to Kim's APIs to generate the 'token' that you would typically give to your internal IT
                                                team to make the integration work. </li>
                                            <li style={litext}>In order to generate the token you: <br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Select the actions you want to allow (in this example below we've selected 'Post - Create Record' and 'Post - Update Record');</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Name the token</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Generate the token; and </li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Provide the token to your IT team to do the rest.</li>
                                                </ul>

                                            </li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/TokenMarketplace.png" alt="Token" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <br />
                                        <h3 id="inboundintegration">Tab 4 - API Outbound Integration</h3>
                                        <ul>
                                            <li style={litext}>This functionality provides the ability to configure integrations that activate when events occur.
                                                                These can be push or pull of data and push of generated documents and attachments.</li>
                                            <li style={litext}>Either choose from a configuration template or create outbound from scratch: <br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Select the Event;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Provide a name and description;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Configure the OAuth interface properties;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Configure the map;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Apply any script when required</li>
                                                </ul>

                                            </li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/ApplicationPropertiesOutboundIntMarketplace.png" alt="Token" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <br />
                                        <h3 id="inboundintegration">Tab 5 - Attachments</h3>
                                        <ul>
                                            <li style={litext}>This functionality provides the ability to configure integrations that activate when events occur.
                                                These can be push or pull of data and push of generated documents and attachments.</li>
                                            <li style={litext}>Either choose from a configuration template or create outbound from scratch: <br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Select the Event;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Provide a name and description;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Configure the OAuth interface properties;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Configure the map;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Apply any script when required</li>
                                                </ul>

                                            </li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/ApplicationPropertiesAttachmentsMarketplace.png" alt="Token" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <br />
                                        <h3 id="inboundintegration">Tab 6 - Manage Subforms and Subrecords</h3>
                                            Any application that is available to your audiences can be selected as a Subform.
                                            The selected Subforms appear from the Records menu for those Applications that have been selected and are available to those audience members.
                                            <br/><br/>
                                            Subrecords can be automatically created when creating a new Record using this Application.
                                            Select those Subforms that will automatically create the Subrecords.
                                            Note that Subforms selected and filled from the Record menu also generate Subrecords.
                                        <ul>
                                            <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Automatically Generate Subrecords <br />
                                                When a Record for this Application is generated, all selected Subform types automatically generate Records mapping Data Points with same names from this Application to those Records.
                                            </li>
                                            <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Restrict Subforms in Menu <br />
                                                When there is a need for end users to create Subrecords manually from the Subforms by selecting from the Records action menu, select those Subforms that are available.
                                                When the end user selects the Subform, the Subform will open mapping Data Points with same name from this Application.
                                            </li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/ManageSubformandsubrecordsMarketplace.png" alt="Token" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <br />
                                        <h3 id="inboundintegration">Tab 7 - Manage Subforms and Subrecords</h3>
                                        The Document Assembly module provides the ability to assemble documents by adding or removing relevant content based on rules.<br/><br/>
                                        <b>Rules:</b>, users can form rules similar to expressions to evaluate input values and action static or input from the Content Library onto the document.<br/><br/>
                                        <b>Content Library:</b> allows users to store and insert frequently used content into documents,<br/><br/>
                                        <b>Document Library:</b> provides a collection of documents that can be appended or prepended based on rules during the assembly process.
                                        The module also ensures that page numbering and footers are working as expected despite changes to document size.
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/DocumentAssemblyMarketplace.png" alt="Token" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <br />
                                        <h3 id="inboundintegration">Tab 8 - Logs</h3>
                                        Use the logs for evaluating configuration, activity, troubleshooting and information on any errors.
                                        The logs can report across any part of the Application's Sections, Areas and Actions performed along with Date Ranges and any combinations of these properties.
                                        <ul>
                                            <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Review the activities that a user has been performing over the past week or month.</li>
                                            <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Review the changes applied to Template.</li>
                                            <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Review the connection messages or errors being reported while connecting an integration.</li>
                                            <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> When the logs are needed for circulation or storage, then download the log to Excel.</li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/ApplicationPropertiesLogsMarketplace.png" alt="Token" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <br />
                                        <h3 id="referencenumber">Tab 9 - Reference Number</h3>
                                        <p>The system creates a reference number placeholder for every new Application that is created.
                                            Reference numbers generate independently of tags however for special tagging of reference numbers
                                            within webforms, please see the link to the Webform Wizard How To Guide below. </p>
                                        <br />
                                        <ul>
                                            <li style={litext}><strong>TemplateID </strong> is the number given to the template by the system and cannot be amended</li>
                                            <li style={litext}><strong>Format </strong> is defaulted to [Number] however can be amended to either[year], [month] or [day] and supports constants. See the following examples<br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Simple [number] with max number being 99999999 and leading 0 padding   <Icon iconName={"Forward"} style={{ fontSize: "10px" }} /> “00000007”</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Simple [number] with max number being 99999999 and no leading 0 padding    <Icon iconName={"Forward"} style={{ fontSize: "10px" }} /> “7”</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> With [year] [month] [day] [number] reference, max number being 99999999 and leading 0 padding    <Icon iconName={"Forward"} style={{ fontSize: "10px" }} /> “2022 10 15 000000007”</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> With [year]-[month]-[day] [number] and constant in this case hyphen, max number being 99999999 and leading 0 padding     <Icon iconName={"Forward"} style={{ fontSize: "10px" }} /> “2022-10-15 00000007”</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> With Case [year]-[month]-[day] [number] and constant in this case hyphen, max number being 99999999 and leading 0 padding     <Icon iconName={"Forward"} style={{ fontSize: "10px" }} /> “Case 2022-10-15 00000007”</li>
                                                </ul>

                                            </li>
                                            <li style={litext}><strong> Highest Number  </strong>is the largest number you want the reference numbers to go up to</li>
                                            <li style={litext}><strong> Starting Number</strong>is the number you would like the reference numbers to start  at</li>
                                            <li style={litext}><strong> Pad Leading Zeros </strong>is based on the Highest Number you select as the reference.  If you specify 1000 as your highest
                                                number and elect for pad leading zeros, the reference of the first Record created will be "0001"</li>
                                            <li style={litext}><strong> Reset Seed Each Year </strong> if elected restarts the reference numbers each  <u>year</u>;</li>
                                            <li style={litext}><strong> Reset Seed Each Month </strong> if elected restarts the reference numbers each month</li>

                                        </ul>
                                        <br />
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/ReferenceNumberMarketplace.png" alt="Token" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <br />
                                        <h3 id="referencenumber">Tab 10 - Alerts</h3>
                                        <p>Renewals and Alerts provide admins with capability to configure record evaluation and email notifications for records.</p>
                                        <br />
                                        <ul>
                                            <li style={litext}><strong>Configure </strong> the Name, Descirption and Timing Trigger.<br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Name the alert.</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Provide a good Description.</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"} /> Set the Renewal Months before the alert be activated and then when the alert will begin and how often it will alert.</li>
                                                </ul>

                                            </li>
                                            <li style={litext}><strong> Create Email Alert Template  </strong>Using a combination of text and Data Point tags, create an email that will inform your staff of what action they need to take on this alert.</li>
                                        </ul>
                                        <br />
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/applicationpropertiesalertsMarketplace.png" alt="Token" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                    </article>
                                    <article>
                                        <h2 id="publishwebformlink">Publish Webform Link</h2>
                                        <p style={text}>
                                            Publish Webform Link allows a user to send the relevant Application link to a specified user via email.
                                            The link may be saved or sent to other users to create Records. Owners and Co-Owner(s) are granted rights
                                            to share Application Audiences.
                                        </p>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/publishwebformlink.png" alt="Publish Webform Link" style={img_Small_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                    </article>
                                    <article>
                                        <h2 id="version">Version</h2>
                                        <p style={text}>
                                            Version allows restoration of a previous version. This action uses the selected version to restore
                                            and overwrite the current Application and Webform with the one selected.
                                        </p>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/Version.png" alt="Version" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                    </article>
                                    <article>
                                        <h2 id="update">Update</h2>
                                        <p style={text}>
                                            Update allows an Application that has been downloaded, edited, and saved (see below) to be re-uploaded.
                                            Edits take effect once re-uploaded and can include new tags, content controls and layout changes.
                                            These changes, however, will not take effect on Records that have been created from the previous version of the Application.
                                            Tag and content control changes will not take effect until applied to the Webform Wizard.
                                            Owners and Co-Owner(s) are granted rights to upload new Applications.
                                        </p>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/Update.png" alt="Version" style={img_Small_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                    </article>
                                    <article>
                                        <h2 id="webformwwizard">Webform Wizard</h2>
                                        <p style={text}>
                                            Webform Wizard is the customizable Webform that extracts the tags and content controls created in documents within Microsoft Word.
                                            From the Webform, users can organize the Webform tags for data capture and validation.
                                            Owners and Co-Owner(s) are granted rights to design the Webform Wizard.
                                        </p>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/WebformWizardMarketplace.png" alt="Version" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                    </article>
                                    <article>
                                        <h2 id="taggingdocument">Tagging a document for upload into the Webform Wizard</h2>
                                        <ul>
                                            <li style={litext}>All documents must be tagged in Google Docs or Microsoft Word and saved as DocX prior to being uploaded as an Application. </li>
                                            <li style={litext}>This process is required so that each field that is tagged can be pulled
                                                into the Webform Wizard and configured as required.</li>
                                            <li style={litext}>To tag a document, you simply configure the relevant fixed data in your Google Docs or Microsoft Word document with a tag as follows - [$tag].
                                                As an example, if you are tagging a Confidentiality Agreement and there are various fixed data fields that need
                                                to be populated from the online webform, you would tag your document like this</li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/TaggingDocument.png" alt="Tagging Document" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>It is important to note that only Google Docs or Microsoft Word (docx) documents can be uploaded.
                                                As such, all other types of documents (e.g. PDF) must be converted to Docx prior to being uploaded. </li>
                                        </ul>

                                    </article>
                                    <article>
                                        <h2 id="uploadingdocument">Uploading the Document and Creating the Application </h2>
                                        <ul>
                                            <li style={litext}>To upload the document, simply click on “Create New Application”
                                                and fill in the details required under “Properties” (see the over of the various Application - Properties fields above),
                                                chose the relevant file you tagged in Google Docs or Microsoft Word, and click on “Create”.</li> </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/UploadingDocumentmarketplace.png" alt="Uploading Document" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                    </article>
                                    <article>
                                        <h2 id="configuringapplication">Configuring an Application in the Webform Wizard </h2>
                                        <ul>
                                            <li style={litext}>Once a document has been uploaded and a new Application is generated, the Webform Wizard automatically opens,
                                                with the relevant tags displayed on the left-side of the screen.   </li> </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/WebformWizardMarketplace.png" alt="Uploading Document" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>Along with the tags on the left-side of the screen, there are section headers,
                                                paragraph and line breaks that can also be added to design the form.</li>
                                            <li style={litext}>The tags and additional options mentioned above can then be dragged and dropped within the Webform Wizard,
                                                allowing the user to configure the form layout as well as the relevant date field layout.  </li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/ApplicationPropertiesMarketplace.png" alt="Uploading Document" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>Various other options are available to the user including:
                                                <br />
                                                <ul>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Previewing </strong> the form during the build process to see what the layout looks  like;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Saving  </strong> the form during the building  process;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Adding/removing pages   </strong>in the   form;</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Build various<strong>expressions </strong>(see the step-by-step guide link below); and </li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Releasing the Application  </strong>so other users can access it if required. </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <p><strong>For a step-by-step guide on how to build a Webform Wizard <Link href='/HowTo/How%20To%20Configure%20a%20Webform%20Wizard.pdf' target="_blank" title="Export ">CLICK HERE</Link></strong></p>
                                    </article>
                                    <article>
                                        <h2 id="records">Records</h2>
                                        <p style={text}>Records are created in a workspace when documents/forms are submitted. Records are also listed in your workspace when they have been shared with you or when
                                            a Record has been created from an Application that you administer:</p>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/RecordsMarketplace.png" alt="Records" style={img_Medium_Sized_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>The PDF icon next to all Records is available to all Record members. Click the icon to view the document inline.</li>
                                            <li style={litext}>The Form icon is always available to administrators but only available to shared users when
                                                Form Wizard Shared Access is enabled from Application Properties (see Application guidance above).
                                                Click the icon to open Webform Wizard and update the document data.</li>
                                            <li style={litext}>A Record displays various Data Points in your workspace:
                                                <br />
                                                <ul>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Subject </strong> column displays the description (specified when the Application was created),
                                                        the date the Record was created, the relevant Application name and the user who created the Record.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Activity   </strong> displays the most recent Record of activity. Click the activity to view the history and further details.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Notes  </strong>displays the most recent note entry. Click the note to view history and add notes.
                                                        Your private notes only appear in your workspace.;</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon><strong>Attachment </strong>displays the number of attachments that have been uploaded to the Record.
                                                        Click the attachment number to view and add attachments. The allowed file formats are doc, docx, xls, xlsx, ppt, pptx, txt, gif, png, jpeg, jpg, csv, svg, pdf, rtf, tif and tiff. </li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong>Version  </strong>displays the current version number. Version count increases for each update or checkin.
                                                        The version history is only available to administrators when clicking on the version number. Administrators may roll-back, preview and delete versions. </li>
                                                </ul>
                                            </li>
                                            <li style={litext}>A Record's menu displays various actions that you are authorized to perform on that Record (dependent on your authorization):
                                                <ul>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Subject</strong> column displays the description (specified when the Application was created),
                                                        the date the Record was created, the relevant Application name and the user who created the Record.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Activity </strong> displays the most recent Record of activity. Click the activity to view the history
                                                        and further details.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Notes</strong> displays the most recent note entry. Click the note to view history and add notes.
                                                        Your private notes only appear in your workspace.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Attachment</strong> displays the number of attachments that have been uploaded to the Record.
                                                        Click the attachment number to view and add attachments. The allowed file formats are doc, docx, xls,
                                                        xlsx, ppt, pptx, txt, gif, png, jpeg, jpg, csv, svg, pdf, rtf, tif and tiff.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Version </strong> displays the current version number. Version count increases for each update or checkin.
                                                        The version history is only available to administrators when clicking on the version number. Administrators may roll-back, preview and delete versions.</li>
                                                </ul>
                                            </li>
                                            <li style={litext}> A Record’s menu displays various actions that you are authorized to perform on that Record (dependent on your authorization):
                                                <ul>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Activity </strong> displays the most recent Record of activity.
                                                        Click the activity to view the history and further details. This functionality is available to all users.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Attachment </strong> displays the number of attachments that have been uploaded to the Record. Click the attachment number
                                                        to view and add attachments. The allowed file formats are doc, docx, xls, xlsx, ppt, pptx, txt, gif, png, jpeg, jpg, csv, svg, pdf, rtf, tif and tiff.
                                                        This functionality is available to all users.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Campaign</strong> is available to administrators only and allows an administrator to set up a campaign
                                                        on an existing Record rather than an Application as described above under Campaigns</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Checkout </strong> needs to be enabled from the relevant Application Properties.  When enabled, it supports manual download,
                                                        edit and re-upload. This functionality is available to all users however Administrators also have an undo checkout action available.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Copy </strong> is available to administrators only and creates a new Record
                                                        by copying the current Record's data into a new Record.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Delete </strong> allows administrators to permanently delete document, attachments, notes,
                                                        and activity for a selected Record. This action is prompted and permanently logged.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Download  </strong> allows administrators to download datapoints in XML or XLSX format and the Package.
                                                        The package contains all information from the Record - document,document versions, datapoints, attachments, notes, activities, and audience..</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Manage   </strong> allows administrators to close and suspend Records.
                                                        Close removes Record from the default Record list without deletion. Closed Records may be viewed in Record list or reopened by using advanced search to locate.
                                                        Suspend removes Record from shared audience Record list, disables update/delete/checkout activities. Administrator may reinstate suspended Records by selecting resume.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Note </strong> displays the most recent note entry. Click the note to view
                                                        history and add notes. Your private notes only appear in your workspace.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Preview  </strong> is available to all audience members. Displays PDF inline viewer</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Remove   </strong> is available to shared users and permanently removes the Record from
                                                        shared user's view - data is not deleted. This action is prompted and permanently logged.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Send Mail </strong> is available by default to administrators. For shared users,
                                                        the Application property Enable Send Mail Shared Access must be selected.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Send Webform Update Link </strong> allows administrators to send a link via email to users that will open
                                                        a Webform Wizard for the purposes of reviewing or updating document data.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Share </strong> is available to administrators and allows inviting shared users to the Record by entering their email address.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Sign  </strong> is available to administrators when the Electronic Signature is selected from Application Properties.
                                                        Signatories and / or auto-signing occurs based on configuration settings.</li>
                                                    <li style={{ display: "block", paddingBottom: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Subform  </strong> is available to administrators when subform option is specified from Application Settings.
                                                        Subform will first list all subforms that are available and then each subform has two options.
                                                        Either Create Webform allowing users to create a Subrecord from the Wizard or Send Webform Link
                                                        to use for custom email invites or preparation for web page links.</li>
                                                    <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> <strong> Update Webform   </strong> becomes available to administrators only when electronic signing is activated
                                                        and documents have been signed.  Administrators then have access to those signed documents.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </article>
                                    <article>
                                        <h2 id="settings">Settings</h2>
                                        <p>Settings allows a user to manage all authorized audiences, categories, lists and signatures across all Applications. </p>
                                        <p>Furthermore, users may edit the following:</p>
                                        <ul>
                                            <li id="myaudiences" style={litext}>
                                                <strong>My Audiences</strong> allows you to access your membership association and access across Applications.
                                                A member's default audience cannot be deleted. Remaining audiences are those that you or other users created for
                                                managing and operating Applications. Use Application Audiences to group Applications logically and share those
                                                Applications with other members by adding them to the audience..<br /><br />
                                                For instance, if you have one or more human resource type Applications, then it may be appropriate to add those
                                                users to a human resource audience. You may then set individual member rights to manage or generate documents
                                                and Records. Follow this same approach for various business functions, departments, or any other logical grouping
                                                to manage Application access.
                                            </li>
                                            <li id="mycategories" style={litext}>
                                                <strong>My Categories</strong>  allows you to specify Application Categories. Editing any of these categories to
                                                which you have access will change the category name for all Applications that you own or co-own having that name.
                                                If you want to change a category name for an individual Application, open that Application's Properties and change
                                                it there (see Applications above).<br /><br />
                                            </li>
                                            <li id="mylists" style={litext}>
                                                <strong>My Lists</strong> allows the re-use of lists for the purposes of building the Webform Wizard.
                                                For example, it might be beneficial to maintain a consistent list of countries, cities or states that
                                                can be used in various Applications rather than uploading a new list per Application.<br /><br />
                                            </li>
                                            <li id="mysignatures" style={litext}>
                                                <strong>My Signatures</strong> is designed as a facility to manage signatures associated with Application Audience groups.
                                                Signatures are either digital or image mapped to template signature tags. When a template is selected for signing, signatures
                                                for that template are retrieved by that Application's Audience and automatically applied to generated documents where mapped.<br /><br />
                                            </li>
                                        </ul>
                                    </article>
                                    <article>
                                        <h2 id="search">Search</h2>
                                        <p style={text}>Within the workspace you can search for various Records using the quick search or the advanced search functionality. </p>
                                        <ul>
                                            <li style={litext}>Quick search allows you to search a Records content.</li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/Search.png" alt="Records" style={img_Small_Horizontal} />
                                            <br /></> :
                                            null
                                        }
                                        <ul>
                                            <li style={litext}>Advanced Search provides more granular levels of searching:</li>
                                        </ul>
                                        {!isMobile ? <>
                                            <br />
                                            <img src="/images/AdvancedSearch.png" alt="Records" style={img_SmaVertical} />
                                            <br /></> :
                                            null
                                        }
                                        <ul><li style={{ display: "block" }}>
                                            <ul> <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon><strong> Content </strong>searches documents for keywords and phrases. Include attachments when needed.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon><strong> Username  </strong>searches Records created by that username.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon><strong> Created From Date </strong>(created and updated) searches Records from specified date until current date
                                                    or "to date" when specified</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon><strong> Created To Date  </strong> (created and updated) searches Records up to date.
                                                    When "from date" is specified, the Records display in the range "from date" "to date".</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Search <strong> subject </strong>by keyword and phrases.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Search <strong> notes </strong>by keyword and phrases.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Searches Records by  <strong> Application  </strong>category and name.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Search by  <strong> activity  </strong>.</li>
                                                <li style={{ display: "block", padding: "1%" }}> <Icon iconName={"Send"}></Icon> Include  <strong> closed   </strong>searches all Records by all criteria and includes closed Records.</li>
                                            </ul>
                                        </li></ul>

                                    </article>
                                    <article>
                                        <h2 id="usermenu">User Menu</h2>
                                        <p style={text}>User menu links are located in different locations when using from web browser or Microsoft Teams.
                                            When using web browser, user menu links appear when selecting your username from top-right corner of screen next to
                                            Help (where you can find this guide). When using Microsoft Teams, user menu links appear when selecting Settings from the
                                            bottom-left of screen below Create New Application. Additional operations also appear below settings and those operations are explained below.
                                        </p>
                                    </article>
                                    <article>
                                        <h2 id="webbrowserusermenu">Web Browser User Menu</h2>
                                        <ul>
                                            <li style={litext}> <strong> Bulk Download </strong> allows a user to retrieve datapoints or Record packages (<strong>For a step-by-step guide on how to
                                                bulk download, <Link href='/HowTo/How%20To%20Bulk%20Download.pdf' target="_blank" title="Bulk download">CLICK HERE</Link></strong>)
                                            </li>
                                            <li style={litext}><strong> My Profile </strong> allows you to update your name, time zone or delete your account. Here you can also activate Microsoft Office 365 email integration which allows a user to send emails from their own Microsoft Office 365 Account when sharing or emailing a Record, rather than the default Email provider.  Once you enter your email, an email will be sent to the specified email address. You will need to open the email and consent to the use of the specified email. </li>
                                            <li style={litext}><strong> My activity </strong> displays your actions across Applications and primarily outside of Records other
                                                than those that have been deleted and accessed. My Activity Records Application deletion, template download,
                                                document download, audience deletion and other activities. For detailed activities on a Record, select status
                                                column for that Record.</li>
                                            <li style={litext}> View Kim Technologies <strong>Privacy Policy</strong>.</li>
                                            <li style={litext}> View Kim Technologies <strong>Terms and Conditions</strong>.</li>
                                            <li style={litext}> View Kim Technologies <strong>Data Processing Agreement</strong>.</li>
                                            <li style={litext}> Use <strong>Contact</strong> Us for Help and Support</li>
                                            <li style={litext}> <strong>Log out </strong>of your account.</li>
                                        </ul>
                                    </article>
                                    <article>
                                        <h2 id="microsoftteamsusermenu"> Microsoft Teams User Menu</h2>
                                        <ul>
                                            <li style={litext}>Use <strong>Bulk Download </strong>as per the above.</li>
                                            <li style={litext}>Use <strong>Profile</strong> as per the above.</li>
                                        </ul>
                                    </article>
                                    <article>
                                        <h2 id="contactus">Get Support/Contact Us</h2>
                                        <p style={text}>For further assistance and for ongoing technical support, please contact  <a href="helpmarketplace#contactus" onClick={_openSupportForm}>{branding.supportEmail}</a>.</p>
                                        <br />
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*region Customer Support*/}
            {openContactUs &&
                <CustomerSupport showModal={openContactUs} setShowModal={hideContactUs} defaultSubject={"Help with configuration"}/>
            }
            {/*endregion*/}
    
        </div>
    );
}

export default HelpMarketplace

