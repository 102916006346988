import React, {useEffect, useState} from 'react'
import RGL, {Responsive, WidthProvider} from "react-grid-layout";
import {useFormContext, UseFormReturn} from 'react-hook-form';
import {IDropdownOption} from '@fluentui/react';
import {ILayout} from '../../CustomTemplate';
import {IList} from "./Grid/Models";
import {IFormControl} from '../../../../Helpers/Helper';
import {DisplayType} from '../../../OutboundIntegration/Models/Enums';
import {useTranslation} from 'react-i18next';
import {IContentLibrary} from '../../../../interfaces/IDocumentAssembly';
import {CustomElement} from './utils/ElementList';
import {IFormAssemblyRule} from '../../../../interfaces/IFormAssembly';
import {useCustomForm} from '../hooks/useCustomForm';
import {useDocument} from '../../../../hooks/useDocument';

type ListControlsType = {
    layouts: ILayout[]
    integrationDropDownLists: { [key: string]: IDropdownOption[] }
    imageValues: any;
    imageValuesDefault: any;
    getFormControls: (data: any) => IFormControl[]
    setImageValues: any;
    gridListRows: { [key: string]: IList }
    locale: any;
    getValidations: any;
    setGridListRows: React.Dispatch<React.SetStateAction<{ [key: string]: IList }>>
    integrationReload: any;
    applicationResponse: any;
    getCustomFormContentControls: any;
    documentValues: IFormControl[]
    isOwner: any;
    isSubform: any;
    docSource: any;
    isAdmin: any;
    getContentLibraryOptions: any;
    contentLibrary: IContentLibrary[]
    data: any;
    isCoOwner: any;
    templateId: any;
    username: any;
    setErrorMessage: any;
    customDropDownLists: { [key: string]: IDropdownOption[] }
    formRules: IFormAssemblyRule[]
    currentPage: number
    draftId: number
    isUpdate: boolean
    getDefaultValue: (layout: ILayout, form: UseFormReturn<any, any> | undefined, defaultValue?: string | undefined) => any
    getFormatedValue: (layout: ILayout, valueToFormat: IFormControl) => any
};

export default function ListControls(props: ListControlsType) {
    const {getTextboxValue} = useDocument()
    const {
        layouts, integrationDropDownLists, imageValues, imageValuesDefault, getFormControls,
        setImageValues, gridListRows, locale, getValidations, setGridListRows,
        integrationReload, applicationResponse, contentLibrary, getDefaultValue, getFormatedValue,
        getCustomFormContentControls, documentValues, isOwner, isSubform, data, currentPage, formRules, draftId,
        isCoOwner, templateId, username, setErrorMessage, customDropDownLists, isUpdate
    } = props

    const {getWatchedConditionsRules, isPresentInConditions, validateRules, gridAreaString, CreateGridAreasStyle} = useCustomForm()
    const {t} = useTranslation(["common", "wizard", "preview"]);
    const form = useFormContext()

    const [onExecutedInitRules, setOnExecutedInitRules] = useState(false)


    /**
     * Check if the rule should be executed according to the provided props.
     *
     * @returns {boolean} Returns true if the rule should be executed, otherwise false.
     */
    const excuteRule = (): boolean => {
        if (props.docSource === 'link') return false
        return props.isAdmin != undefined
            ? props.isAdmin
            : !!(props.isOwner || props.isCoOwner)
    }

    const getLabel = (adminOnly: boolean, label: string): string => {
        if (adminOnly) {
            if (isOwner || isCoOwner) {
                return label;
            } else {
                return "";
            }
        } else {
            return label;
        }
    }

    useEffect(() => {
        CreateGridAreasStyle(currentPage)
    },[currentPage])
    
    /**
     * Execute rules on init
     */
    useEffect(() => {

        const initRules = getWatchedConditionsRules()
        if (!isUpdate && draftId <= 0) {
            validateRules(initRules, form, excuteRule(), false, 'OnInit', currentPage)
        } else {
            validateRules(initRules, form, excuteRule(), true, 'OnInit', currentPage)
        }
        setOnExecutedInitRules(true)
    }, [])

    const displayIntegration = (layout: ILayout) => {
        if (layout.Integration?.DisplayType === DisplayType.Always || (layout.Integration?.DisplayType === DisplayType.OnCreate && (data === null || data === "" || data === undefined)) || (layout.Integration?.DisplayType === DisplayType.OnUpdate && data !== null && data !== "" && data !== undefined)) {
            return true;
        } else {
            return false;
        }
    };

    const utils = {

        getLabel,
        getDefaultValue,
        getFormatedValue,
        displayIntegration,
        integrationDropDownLists,
        customDropDownLists,
        contentLibrary,
        locale,
        gridListRows,
        getFormControls,
        setGridListRows,
        getValidations,
        imageValues,
        imageValuesDefault,
        setImageValues,
        templateId,
        integrationReload,
        getCustomFormContentControls,
        username,
        setErrorMessage
    }


    const showControl = (adminOnly: boolean): boolean => {
        if (adminOnly && props.docSource === 'link') return false

        if (adminOnly) {
            return props.isAdmin != undefined
                ? props.isAdmin
                : !!(props.isOwner || props.isCoOwner)
        } else {
            return true;
        }
    }


    const ResponsiveReactGridLayout = WidthProvider(Responsive)

    return (
        onExecutedInitRules ?
            <>
                <ResponsiveReactGridLayout
                    className="form-layout"
                    layouts={{
                        lg: layouts.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), []),
                        md: layouts.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), []),
                        sm: layouts.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), []),
                        xs: layouts.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), []),
                        xxs: layouts.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), []),
                    }}
                    cols={{lg: 4, md: 4, sm: 4, xs: 1, xxs: 1}}
                    rowHeight={35}
                    useCSSTransforms={false}
                    isResizable={false}
                    isDraggable={false}
                    compactType={null}
                    style={{
                        gridTemplateAreas: gridAreaString
                    }}
                >

                    {layouts
                        .filter((item) => +item.Page === currentPage)
                        .sort((a, b) => (a.Layout.y - b.Layout.y) || (a.Layout.x - b.Layout.x))
                        .map((control) => {
                            if (showControl(control.AdminOnly)) {

                                return (

                                    <CustomElement form={form} utils={utils} labelVisible={true}
                                                   val={control} key={control.Id}
                                                   AdminOnly={excuteRule()}
                                                   formRules={formRules}
                                                   currenPage={currentPage}
                                                   isInConditions={isPresentInConditions(control.Id)}
                                    />
                                )
                            } else {
                                return <></>
                            }
                        })}

                </ResponsiveReactGridLayout>

            </>
            :
            <>
            </>

    )
}

