import React from 'react';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import {useBranding} from "../../hooks/useBranding";

const theme = getTheme();


function ErrorScreen(props: any) {
    const { branding } = useBranding()
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            padding: '12px 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
                <div className={contentStyles.header}>
                    <span className="ms-font-su">System Information</span>
                </div>
                <div className={contentStyles.body}>
                    {props.errorMessage}
                </div>
            </div>
        </div>
        )
}

export default ErrorScreen