import * as React from "react";
import {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from "react";
import {Controller} from "react-hook-form";
import {
    IconButton,
    IContextualMenuProps,
    IInputProps,
    Image,
    ImageFit,
    ITextFieldProps,
    PrimaryButton,
    Stack,
    Text,
    TextField
} from "@fluentui/react";
import {ReactHookFormControlProps} from "../Models/ReactHookFormControlProps";
import {InputType, RuleActionType} from "../../Validations";
import {textFieldStyle} from "./Styles";
import i18n from "../../../../i18n/config";
import {useBranding} from "../../../../hooks/useBranding";

export interface IRule {
    RuleAction: RuleActionType,
    CCName: string,
    CCType: InputType,
    CCVaue: string
    ImageName?: string
}

export interface IUploadControlProps {
    setImageValues: Dispatch<SetStateAction<{ [key: string]: IRule }>>
    imageValues: { [key: string]: IRule }
    imageValuesDefault: { [key: string]: IRule }
    onRenderLabel: () => JSX.Element
}

export const RHFUploadRunTimeControl: FC<ReactHookFormControlProps & ITextFieldProps & IInputProps & IUploadControlProps> = (props) => {

    const {t} = i18n
    const {branding} = useBranding()
    const inputFileRef = useRef<HTMLInputElement>(null);

    const [fileName, setFileName] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imagePreview, setImagePreview] = useState<string | undefined>(undefined)
    const [hasDefault, setHasDefault] = useState(false)
    let image: any | null = null
    let imageName: string = ""


    useEffect(() => {
        Init()
    }, [props.id])

    /**
     * Clear field
     */
    const Clear = () => {
        // todo check IRules 

        props.setValue(props.id, "")
        if (inputFileRef.current !== null) inputFileRef.current.value = ""
        imageName = ""
        setFileName("");
        setImageLoaded(false)
        setImagePreview(undefined)

        let tempValues = props.imageValues;
        tempValues[props.id] = {
            RuleAction: RuleActionType.DELETE,
            CCName: props.id,
            CCType: InputType.Image,
            CCVaue: "",
            ImageName: imageName
        }
        props.setImageValues(tempValues)

    }

    /**
     * Init
     * @constructor
     */
    const Init = () => {

        image = null
        imageName = ""
        if (inputFileRef.current !== null) inputFileRef.current.value = ""

        if (props.imageValuesDefault[props.id] !== undefined &&
            props.imageValuesDefault[props.id].CCVaue !== null &&
            props.imageValuesDefault[props.id].CCVaue.trim().length > 0) {
            setHasDefault(true)
        } else {
            setHasDefault(false)
        }

        if (props.imageValues[props.id] !== undefined &&
            props.imageValues[props.id].CCVaue !== null &&
            props.imageValues[props.id].CCVaue.trim().length > 0) {

            setFileName(t('text.imageLoaded', {ns: 'wizard'}));
            imageName = t('text.imageLoaded', {ns: 'wizard'})
            setImagePreview(`data:image/jpeg;base64, ${props.imageValues[props.id].CCVaue}`)
            setImageLoaded(true)
            props.setValue(props.id, props.imageValues[props.id].CCVaue)
        } else {
            setFileName("");
            setImagePreview(undefined)
            setImageLoaded(false)
            props.setValue(props.id, "")
        }
    }

    /**
     * Use Default
     * @constructor
     */
    const UseDefault = () => {

        image = null
        imageName = ""
        if (inputFileRef.current !== null) inputFileRef.current.value = ""

        if (props.imageValuesDefault[props.id] !== undefined &&
            props.imageValuesDefault[props.id].CCVaue !== null &&
            props.imageValuesDefault[props.id].CCVaue.trim().length > 0) {

            let tempValues = props.imageValues;
            tempValues[props.id] = {
                RuleAction: RuleActionType.REPLACE,
                CCName: props.id,
                CCType: InputType.Image,
                CCVaue: props.imageValuesDefault[props.id].CCVaue.trim(),
                ImageName: t('text.imageLoaded', {ns: 'wizard'})
            }
            props.setValue(props.id, props.imageValuesDefault[props.id].CCVaue.trim())
            props.setImageValues(tempValues)

            setHasDefault(true)
            setFileName(t('text.imageLoaded', {ns: 'wizard'}));
            imageName = t('text.imageLoaded', {ns: 'wizard'})
            setImagePreview(`data:image/jpeg;base64, ${props.imageValuesDefault[props.id].CCVaue}`)
            setImageLoaded(true)
        } else {
            setHasDefault(false)
            setFileName("");
            setImagePreview(undefined)
            setImageLoaded(false)
            props.setValue(props.id, "")
        }
    }

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'default',
                text: t("Common.Input.ImageDefault", {ns: 'common'}),
                onClick: UseDefault
            }
        ]
    }

    /**
     * Read the image
     */

    const PreviewImage = () => {
        const reader = new FileReader();
        let imageString = ""

        reader.onload = (_) => {
            imageString = reader.result!.toString().split(',')[1]
        }

        reader.onloadend = () => {
            // todo check IRules 

            let tempValues = props.imageValues;
            tempValues[props.id] = {
                RuleAction: RuleActionType.REPLACE,
                CCName: props.id,
                CCType: InputType.Image,
                CCVaue: imageString,
                ImageName: imageName
            }
            props.setValue(props.id, imageString)
            props.setImageValues(tempValues)

            setImagePreview(`data:image/jpeg;base64, ${imageString}`)
            setImageLoaded(true)
            props.trigger(props.id).then()
        }

        reader.onerror = () => {
            // todo check IRules 

            props.setValue(props.id, "")
            setImagePreview(undefined)
            setImageLoaded(false)
        }

        if (image !== null) reader.readAsDataURL(image);
    }


    const onUploadFile = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.files != null && ev.target.files.length > 0) {

            const _imageName = ev.target.files![0].name
            setFileName(_imageName)
            imageName = _imageName

            image = ev.target.files[0]
            props.setValue(props.id, image)
            const result = await props.trigger(props.id)
            if (result) {
                PreviewImage()
            } else {
                props.setValue(props.id, "")
                setImagePreview(undefined)
            }

        } else {

        }
    }

    return (
        <>
            <Stack>
                {props.onRenderLabel()}
            </Stack>
            <Stack
                tokens={{childrenGap: 8}}
                title={props.title}
                styles={{
                    root: {
                        height: 180,
                        justifyContent: "center",
                        alignItems: "center",
                        border: props.readOnly ? 'none' : `solid 1px ${branding.theme.palette.neutralLight}`
                    }
                }}>

                {imagePreview === undefined ?
                    <>
                        <i className="ms-Icon ms-Icon--Photo2"

                           aria-hidden="true" style={{
                            fontSize: "64px",
                            color: branding.theme.palette.neutralLight
                        }}>
                        </i>

                        <Text variant={"medium"}
                              title={props.title}
                              styles={{root: {color: branding.theme.palette.neutralDark}}}>
                            {"No Image"}
                        </Text>
                    </>
                    :
                    <>
                        <Image
                            src={imagePreview}
                            imageFit={ImageFit.centerContain}
                            alt={props.title}
                            title={props.title}
                            width={"100%"}
                            height={"100%"}
                        />
                    </>
                }
            </Stack>

            <Controller
                name={props.id}
                control={props.control}
                rules={props.rules}
                defaultValue={props.defaultValue}
                render={({
                             field: {name: fieldName},
                             fieldState: {error}
                         }) => (
                    <>
                        {!props.readOnly &&
                            <Stack horizontal>
                                <Stack grow={1}>
                                    <TextField
                                        id={`${props.id}_Textbox`}
                                        name={fieldName}
                                        disabled={true}
                                        value={imageLoaded ?
                                            fileName ? fileName
                                                : t('text.imageLoaded', {ns: 'wizard'})
                                            :
                                            props.extraText ? props.extraText
                                                : t('Common.Input.File', {ns: 'common'})
                                        }
                                        title={props.title}
                                        required={props.required}
                                        resizable={false}
                                        borderless
                                        styles={textFieldStyle}
                                        tabIndex={props.tabIndex}
                                        maxLength={props.maxLength}
                                        placeholder={t('Common.Input.File', {ns: 'common'})}/>
                                </Stack>

                                <Stack>
                                    <PrimaryButton
                                        htmlFor={"file"}
                                        disabled={props.readOnly}
                                        onClick={() => {
                                            inputFileRef.current?.click()
                                        }} text={t('Common.Input.File', {ns: 'common'})}
                                        split={hasDefault}
                                        menuProps={hasDefault ? menuProps : undefined}
                                        allowDisabledFocus/>

                                    <input
                                        accept=".bmp, .tiff, .jpg, .png, .jpeg, .gif, .svg,"
                                        className={`fileButton`}
                                        id={props.id}
                                        type="file"
                                        name={fieldName}
                                        ref={inputFileRef}
                                        onChange={(event) => {
                                            onUploadFile(event).then()
                                        }}
                                        placeholder={t('Common.Input.File', {ns: 'common'})}
                                        title={props.title}
                                        disabled={props.readOnly}
                                        tabIndex={props.tabIndex}
                                    />
                                </Stack>

                                {imageLoaded &&
                                    <Stack>
                                        <IconButton iconProps={{iconName: "Clear"}} title="Clear" ariaLabel="Clear"
                                                    styles={{
                                                        root: {border: `solid 1px ${branding.theme.palette.black}`},
                                                        icon: {color: branding.theme.palette.black}
                                                    }}
                                                    onClick={Clear}/>
                                    </Stack>
                                }

                            </Stack>
                        }

                        {error && props.getValues(`${props.id}_Textbox`) === undefined &&
                            <Stack styles={{root: {paddingTop: 8, paddingBottom: 8}}}>
                                <Text variant={"smallPlus"}
                                      styles={{root: {color: branding.theme.semanticColors.errorText}}}>
                                    {error.message}
                                </Text>
                            </Stack>
                        }
                    </>
                )}
            />

        </>
    )
}
