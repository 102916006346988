import React, { useEffect, useState } from 'react';
import { Modal } from '@fluentui/react/lib/Modal';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { getTheme, FontWeights, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IconButton, PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { Separator } from '@fluentui/react/lib/Separator';
import { ReactNotificationOptions, store } from 'react-notifications-component';
import Helper,{IDocumentOnlyStatisticsRequest} from '../../Helpers/Helper';
import {Label} from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import {useTranslation} from "react-i18next";
import { formatFromUTC } from '../../Helpers/DateUtils';
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";

const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};



const cancelIcon: IIconProps = { iconName: 'Cancel' };


const stackTokens = { childrenGap: 50 };
function FormDeleteTemplate(props: any) {
    const { branding } = useBranding()
    const [spinnerText, setSpinnerText] = useState("")
    const onClose = () => {props.hideModal()}
    const [showModalStatistics, setShowModalStatistics] = useState(false)
    const [statistics, setStatistics] = useState<any>();
    const {t} = useTranslation(['common', 'deleteTemplate']);

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '60vw',
            height: '60vh',
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyStatistics: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 500,

        },
        containerMail: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '50vw'
        },
    });


    useEffect(() => {
        if(props.showDeleteModal)
		    getTemplateStatistics()

	}, [props.showDeleteModal])

    function showNotification(Nmessage:string, Ntype:string)
    {
        var auxType:ReactNotificationOptions;
        switch (Ntype) {
            case "success":
                store.addNotification({
                    title: t('title.success', { ns: 'common'}),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;
            case "warning":
                store.addNotification({
                    title: t('title.warning', { ns: 'common'}),
                    message: Nmessage,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;
            case "error":
                store.addNotification({
                    title: t('title.error', { ns: 'common'}),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;
            case "info":
                store.addNotification({
                    title: t('title.information', { ns: 'common'}),
                    message: Nmessage,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;

            default:
                break;
        }
    }    
    
    async function Delete() {

        setSpinnerText(t('text.spinnerDeleting', { ns: 'common'}))

        const sessionKey = Helper.getSessionKey()
        const accountId = Helper.getDefaultAccount()
        const userName = Helper.getUsername()

        const request = {
            templateId: props.templateId
        }

        let {data}: any = await HttpRequest.getWithSession('DeleteDocumentOnlyTemplate', request)
        const response = data
         
         if (response!==undefined) {   
           try {
            if(response.Success)
            {
                let site = await Helper.getSite()

                if(props.templateDelete)
                {
                    Helper.SendNotification({
                        ActionByEmailAddress:userName,
                        ActionDate: formatFromUTC(Date.now(), "ll" ,  'en' ),
                        AudienceName:props.audienceName,
                        AudienceOwnerEmailAddress:props.ownerUser,
                        RecordDescription:props.templateName,
                        TargetOfActionEmailAddress:"",
                        TemplateCategory:props.templateCategory,
                        TemplateName:props.templateName,
                        Culture:props.culture,
                        Type:"4",
                        UserName: userName,
                        SK: sessionKey,
                        AccountId: accountId,
                        Path: site,
                        Autoprovision: true,
                        ApiKey:"-1",
                        EmailMoniker:props.emailMoniker,
                        TemplateId: props.templateId.toString()
                    })
                }
              props.reloadApp(site)
              showNotification(t('message.success', { ns: 'common'}),"success")
            }
            else 
            {
              showNotification(response.ExceptionMessage,"error")
            }
                   
               }
            catch (error) {
               console.log(error)
               showNotification(t('message.error', { ns: 'common', Error : error}), "error")
           }
          }
          
      setSpinnerText("")
      console.log(props.templateId)
      props.hideModal()
    }
    const hideModalStatistics = () => {
       
        props.hideModal()

    }
    const getTemplateStatistics = () => {
		setShowModalStatistics(true);
		const req: IDocumentOnlyStatisticsRequest = {
			UserName: Helper.getUsername(),
			SessionKey: Helper.getSessionKey(),
			AccountId: Helper.getDefaultAccount(),
			ApiKey: "-1",
			Autoprovision: true,
			TemplateId:props.templateId
		}
		Helper.getDocumentOnlyStatistics(req).then(data => {
			setStatistics(data[0])
		})
		
	}

    return (
        <>
        <Modal
                isOpen={props.showDeleteModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.header}>
                                <span dangerouslySetInnerHTML={{ __html:props.modalTitle }}></span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                        onClick={hideModalStatistics}
                                        title={t('Common.Button.Close', { ns: 'common'})}
                                    />
                                </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.bodyStatistics}>
                                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                                        <Label>
                                            {t('label.having', { ns: 'deleteTemplate', Fullname: statistics?.FullName, Username: statistics?.UserName })}
                                        </Label>
										<Label>
                                            {t('label.documents', { ns: 'deleteTemplate',Open: statistics?.Audience_Open_Documents+statistics?.Creator_Open_Documents+statistics?.Share_Open_Documents, Closed: statistics?.Audience_Closed_Documents+statistics?.Creator_Closed_Documents+statistics?.Share_Closed_Documents})}
                                        </Label>
										{statistics?.Template_Owner>0?<Label>{t('label.owner', { ns: 'deleteTemplate'})}</Label>:null}
                                        {statistics?.Template_Co_Owner>0?<Label>{t('label.coowner', { ns: 'deleteTemplate'})}</Label>:null}
										{/* <Label>Current DateTime: {formatFromUTC(statistics?.CurrentDateTime)}</Label> */}
										<Label id="messageDeleteApplication" style={{color:"red"}}>{t('message.confirmDelete', { ns: 'deleteTemplate'})}</Label>
										
                                    </div>
                            </div>
                        </div>
                    </div>
                        <div className="ms-Grid-row">
                            <div style={{ paddingBottom: "10px", paddingRight: "12px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={Delete} text={t('Common.Button.Confirm', { ns: 'common'})} title={t('Common.Button.Confirm', { ns: 'common'})} />
                                    <DefaultButton onClick={hideModalStatistics} text={t('Common.Button.Cancel', { ns: 'common'})} title={t('Common.Button.Cancel', { ns: 'common'})} />
                                </DialogFooter>
                            </div>
                        </div> 
                        
                </div>
            </Modal>
        {/* <Modal
        isOpen={props.showDeleteModal}
        isBlocking={true}>
        <div className="ms-Grid" dir="ltr">
           <div className="ms-Grid-row">
                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Stack>
                        <div className={contentStyles.header}>
                            <span id={props.modalTitleId}>{props.modalTitle}</span>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={onClose}
                                title="Close."
                            />
                        </div>
                        <Separator />
                    </Stack>
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className={contentStyles.bodyDelete}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <div className="ms-Grid-row">
                                        {props.showText}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
             {spinnerText === ""?
            <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <DialogFooter>
                        <PrimaryButton onClick={Delete} text="Delete" title="Delete" />
                        <DefaultButton onClick={onClose} text="Cancel" title="Close." />
                    </DialogFooter>
                </div>
             </div>
             :
             <Spinner size={SpinnerSize.medium} label="Cloning application..." ariaLive="assertive" />}
        </div>
     </Modal> */}
     </>
    );
}

export default FormDeleteTemplate


