import { IStackTokens, Label, mergeStyles, PrimaryButton, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { RHFTextBoxControl } from "../../../RHFControls/RHFTextBoxControl";
import * as microsoftTeams from "@microsoft/teams-js";
import Helper, { IDocumentOnlyLoggingRequest } from "../../../../Helpers/Helper";
import { RHFCheckBoxControl } from "../../../RHFControls/RHFChekBoxControl";
import TestIntegrationModal from "../../TestIntegrationModal";
import { useBoolean } from "@fluentui/react-hooks";
import OutboundServices from "../../Services/OutboundServices";
import { TestOAuth20Request } from "../../Models/OutboundIntegrationModel";
import { OAuth20Type } from "../../Models/Enums";

export interface SecurityProps {
    templateId: number;
    showSuccessMessage: (message: string) => void;
    showErrorMessage: (message: string) => void;
    isConfigurationTemplateReadMode?: boolean;
}

const verticalStyle = mergeStyles({
    height: "200px",
});

const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};

const RefreshTokenFlow = (props: SecurityProps) => {
    const { control, setValue, getValues, trigger } = useFormContext();
    const [isIntegrationTestModalOpen, { setTrue: showIntegrationTestModal, setFalse: hideIntegrationTestModal }] = useBoolean(false);
    const [modalContent, setModalContent] = useState<any>(null);

    const getDefaultValue = (id: any): any => {
        let currentValue: any = getValues(id);
        let tempCurrentValue: any =
            currentValue !== undefined ? currentValue : "";
        setValue(id, tempCurrentValue);
        return tempCurrentValue;
    };

    useEffect(() => {
        setValue("RedirectURI", `${window.origin}/oauth2/callback`);
    }, []);

    const getAccessToken = async (authCode: any) => {
        let token_url = getValues("TokenUrl");
        let client_id = getValues("ClientId");
        let client_secret = getValues("ClientSecret");
        let redirect_uri = getValues("RedirectURI");
        let grant_type = getValues("GrantType");
        let client_assertion_type = "";
        let assertion = "";
        let code = "";
        let client_assertion = "";

        if (getValues("ClientAssertionType") && getValues("ClientAssertionType").length > 0) {
            client_assertion_type = getValues("ClientAssertionType");
            assertion = authCode.code;
        } else {
            code = authCode.code;
        }
        if (getValues("Assertion") && getValues("Assertion").length > 0) {
            client_assertion = getValues("Assertion");
        }

        OutboundServices.GetOAuth20Token({
            TokenUrl: token_url,
            ClientId: client_id,
            ClientSecret: client_secret,
            RedirectUri: redirect_uri,
            GrantType: grant_type,
            ClientAssertionType: client_assertion_type,
            Assertion: assertion,
            Code: code,
            ClientAssertion: client_assertion,
        }).then(async (response) => {
            setValue("AccessToken", response.access_token);
            setValue("RefreshToken", response.refresh_token);

            if (response.scope !== undefined && response.scope !== null && response.scope !== "") {
                setValue("Scope", response.scope);
            }

            const req: IDocumentOnlyLoggingRequest = {
                UserName: Helper.getUsername(),
                SK: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                ApiKey: "-1",
                Autoprovision: true,
                Path: await Helper.getSite(),
                Type: "1",
                Section: "Properties",
                Area: "API Outbound",
                Action: "New Access Token",
                ActionStatus: "Success",
                Message: "New Access Token Generated",
                TemplateId: props.templateId.toString(),
            };

            Helper.CreateDocumentOnlyLog(req);

            props.showSuccessMessage("New Access Token Generated.");
        }).catch(async (response) => {
            props.showErrorMessage("Error when obtaining the access token: " + response);

            const req: IDocumentOnlyLoggingRequest = {
                UserName: Helper.getUsername(),
                SK: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                ApiKey: "-1",
                Autoprovision: true,
                Path: await Helper.getSite(),
                Type: "0",
                Section: "Properties",
                Area: "API Outbound",
                Action: "New Access Token",
                ActionStatus: "Error",
                Message: "Error when obtaining the access token: " + response,
                TemplateId: props.templateId.toString(),
            };
            Helper.CreateDocumentOnlyLog(req);
            //ERROR: NO RESPONSE
        });
    };

    const getAuthCode = () => {
        let rawOAuth = localStorage.getItem("local.oauth");
        if (rawOAuth !== undefined && rawOAuth !== null && rawOAuth !== "") {
            getAccessToken(getHashParameters(rawOAuth));
            localStorage.removeItem("local.oauth");
        } else {
            //ERROR: NO ACCESS CODE
        }
    };

    function getHashParameters(params: string) {
        let hashParams: any = {};
        params.split("&").forEach(function (item) {
            let s = item.split("="),
                k = s[0],
                v = s[1] && decodeURIComponent(s[1]);
            hashParams[k] = v;
        });
        return hashParams;
    }

    const onGetNewAccessToken = () => {
        let redirect_uri = getValues("RedirectURI");
        let auth_url = getValues("AuthUrl");

        if (auth_url !== undefined && auth_url !== "") {
            let state = "";

            let response_type = getValues("ResponseType");
            let client_id = getValues("ClientId");

            let authUrlArray = auth_url.split("?");
            let authUrl = `${authUrlArray[0]}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}`;
            if (authUrlArray.length > 1) {
                authUrl += `&${authUrlArray[1]}`;
            }

            let scope = getValues("Scope");
            if (scope !== undefined && scope !== "") {
                authUrl += `&scope=${scope}`;
            }

            if (state !== undefined && state !== "") {
                authUrl += `&state=${state}`;
            }

            microsoftTeams.initialize();
            microsoftTeams.authentication.authenticate({
                url: authUrl,
                width: 550,
                height: 650,
                successCallback: async function (result: any) {
                    getAuthCode();
                },
                failureCallback: async function (result: any) {
                    getAuthCode();
                },
            });
        } else {
            //ERROR: PARAMETERS CANNOT BE EMPTY
        }
    };

    const testRefreshTpkenFlow = async () => {
        OutboundServices.TestOAuth20(await getTestOAuth20Request()).then(
            (res) => {
                showIntegrationTestModal();
                setModalContent(res);
            }
        );
    };

    const getTestOAuth20Request = async (): Promise<TestOAuth20Request> => {
        return {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            TemplateId: props.templateId,
            OAuth20Prop: {
                AccessToken: (getValues("RefreshToken") === undefined || getValues("RefreshToken") === "") && getValues("AccessToken") !== undefined && getValues("AccessToken") !== "" ? getValues("AccessToken") : "",
                RefreshToken: getValues("RefreshToken") !== undefined && getValues("RefreshToken") !== "" ? getValues("RefreshToken") : "",
                AuthUrl: getValues("AuthUrl"),
                ResponseType: getValues("ResponseType"),
                TokenUrl: getValues("TokenUrl"),
                GrantType: getValues("GrantType"),
                ClientId: getValues("ClientId"),
                ClientSecret: getValues("ClientSecret"),
                RedirectURI: getValues("RedirectURI"),
                Scope: getValues("Scope"),
                Username: "",
                Password: "",
                code: "",
                client_assertion_type: getValues("ClientAssertionType"),
                Assertion: getValues("Assertion"),
                AssertionCertPassword: "",
                company_id: "",
                code_verifier: "",
                assertion_keyname: getValues("AssertionKeyName"),
                AssertionFileName: "",
                new_token: "",
                oAuth20Type: OAuth20Type.RefreshTokenFlow,
                State: "",
                ScopeRequired: getValues("ScopeRequired"),
                ServiceProvider: "",
                AuthorizationHeader: getValues("AuthorizationHeader"),
            },
        };
    };

    return (
        <>
            <Stack horizontal tokens={stackTokens}>
                <Stack.Item className={verticalStyle}>
                    <RHFTextBoxControl
                        id={"AccessToken"}
                        label={"Access Token:"}
                        disabled={true}
                        title={"Enter Access Token"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("AccessToken")}
                    />
                    <RHFTextBoxControl
                        id={"AuthUrl"}
                        label={"Auth URL:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Auth Token URL"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("AuthUrl")}
                    />
                    <RHFTextBoxControl
                        id={"ResponseType"}
                        label={"Response Type:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Response Type"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("ResponseType")}
                    />
                    <RHFTextBoxControl
                        id={"ClientId"}
                        label={"Client Id:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Client Id"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("ClientId")}
                    />
                    <RHFTextBoxControl
                        id={"ClientAssertionType"}
                        label={"Client Assertion Type:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Client Assertion Type"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("ClientAssertionType")}
                    />
                    <RHFTextBoxControl
                        id={"AuthorizationHeader"}
                        label={"Authorization Header:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Authorization Header"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("AuthorizationHeader")}
                    />
                </Stack.Item>
                <Stack.Item className={verticalStyle}>
                    <RHFTextBoxControl
                        id={"RefreshToken"}
                        label={"Refresh Token:"}
                        disabled={true}
                        title={"Enter Refresh Token"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("RefreshToken")}
                    />
                    <RHFTextBoxControl
                        id={"TokenUrl"}
                        label={"Token URL:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Token URL"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("TokenUrl")}
                    />
                    <RHFTextBoxControl
                        id={"GrantType"}
                        label={"Grant Type:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Grant Type"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("GrantType")}
                    />
                    <RHFTextBoxControl
                        id={"ClientSecret"}
                        label={"Client Secret:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Client Secret"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("ClientSecret")}
                    />
                    <RHFTextBoxControl
                        id={"Assertion"}
                        label={"Assertion:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Assertion"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("Assertion")}
                    />
                </Stack.Item>
                <Stack.Item className={verticalStyle}>
                    <RHFTextBoxControl
                        id={"RedirectURI"}
                        label={"Redirect URI:"}
                        disabled={true}
                        title={"Redirect URI"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("RedirectURI")}
                    />
                    <div style={{ paddingTop: "31px", paddingBottom: "10px" }}>
                        <RHFCheckBoxControl
                            id={"ScopeRequired"}
                            label={"Scope Required:"}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            trigger={trigger}
                        />
                    </div>
                    <RHFTextBoxControl
                        id={"Scope"}
                        label={"Scope:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Scope"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("Scope")}
                    />
                    <Label />
                    <Label />
                    <Label />
                    <Label />
                    <Label />
                    <Label />
                    <RHFTextBoxControl
                        id={"AssertionKeyName"}
                        label={"Assertion Key Name:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Assertion Key Name"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("AssertionKeyName")}
                    />
                </Stack.Item>
                <Stack.Item className={verticalStyle}>
                    <Label />
                    <Label />
                    <Label />
                    <PrimaryButton
                        disabled={props.isConfigurationTemplateReadMode}
                        text={"Get New Access Token"}
                        onClick={onGetNewAccessToken}
                    />
                    <Label />
                    <Label />
                    <Label />
                    <PrimaryButton
                        disabled={props.isConfigurationTemplateReadMode}
                        text={"Test Refresh Token Flow"}
                        onClick={testRefreshTpkenFlow}
                    />
                </Stack.Item>
            </Stack>
            <TestIntegrationModal
                showModal={isIntegrationTestModalOpen}
                hideModal={hideIntegrationTestModal}
                content={modalContent}
                setModalContent={setModalContent}
            />
        </>
    );
};

export default RefreshTokenFlow;
