import React, { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const Profile = () => {
    const { user, isAuthLoaded } = useAuth();

    useEffect(() => {

        console.log("Loading")
    }, [])

    if (!isAuthLoaded) {
        return <>Loading...</>
    } else {
        return (
            <>
                <pre>
                    {JSON.stringify(user, null, 2)}
                </pre>
            </>
        );
    }
};

export default Profile