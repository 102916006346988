import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import '../../Custom.css';
import {DatePicker, getTheme, Label, Stack, StackItem} from "@fluentui/react";
import {DefaultButton, IconButton, PrimaryButton} from "@fluentui/react/lib/Button";
import {Text} from "@fluentui/react/lib/Text";
import {Separator} from "@fluentui/react/lib/Separator";
import {Modal} from "@fluentui/react/lib/Modal";
import {IIconProps} from "@fluentui/react/lib/Icon";
import {contentStyles, iconButtonStyles, nonShrinkingStackItemStyles} from "./Style"
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import {Spinner, SpinnerSize} from "@fluentui/react/lib/Spinner";
import RenewalsAndAlertsService, {
    IRecordRenewal,
    ResponseGetContextRecord,
    ResponseGetContextTemplate
} from "./RenewalsAndAlerts.service";

import {FormProvider, useForm} from "react-hook-form";
import {Shimmer} from "office-ui-fabric-react/lib/Shimmer";
import moment from "moment-with-locales-es6";
import Helper from "../../Helpers/Helper";
import {AppContext} from "../Provider";
import {IContextualMenuProps} from "office-ui-fabric-react";
import {formatFromUTC, formatSimpleDate} from "../../Helpers/DateUtils";
import {useBranding} from "../../hooks/useBranding";


type Properties = {
    showModal: boolean
    onDismissModal: (update?: boolean, preview?: boolean) => void;
    previewPDF?: boolean
    item: IRecordRenewal

}

function RenewalsAndAlertsRenewal({
                                      showModal,
                                      item,
                                      onDismissModal,
                                      previewPDF
                                  }: Properties) {
    const { branding } = useBranding()
    const {t} = useTranslation(['renewalsalerts', 'common'])
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const [isLoadingRecordContext, setIsLoadingRecordContext] = useState(true)
    const [tryAgain, setTryAgain] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [dateCreatedFrom, setCreatedFrom] = useState<Date>(new Date());

    const [isLoadedContext, setIsLoadedContext] = useState(false)

    const [messagePivot1, setMessagePivot1] = useState("")
    const [currentRenewalDate, setCurrentRenewalDate] = useState("")

    const [statusMessageEnumPivot1, setStatusMessageEnumPivot1] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessagePivot1, setShowMessagePivot1] = useState(false)

    const formRenewals = useForm();
    const {context} = useContext(AppContext)
    const [updated, setUpdated] = useState(false)


    const setError = (message: string) => {
        showStatusMessage(t('message.error', {
            ns: 'common',
            Error: message
        }), StatusEnum.Error)
        setTryAgain(true)
    }

    /**
     * Submenu
     */
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'close',
                text: t('Common.Button.Close', {ns: 'common'}),
                onClick: () => {
                    onDismissModal(updated, false)
                }
            },
        ],
    };

    const load = async (clearmessages: boolean = false) => {

        setIsLoadedContext(false)
        setIsLoadingRecordContext(true)
        setTryAgain(false)

        if (clearmessages) {
            setMessagePivot1("")
            setShowMessagePivot1(false)
        }
        // START GET Context
        formRenewals.reset()
        formRenewals.setValue("notifyTriggerFrequency", {
            key: "daily",
            label: "Daily"
        })
        formRenewals.setValue("active", true)
        formRenewals.setValue("hiddenRenewalsMonths", 0)
        formRenewals.setValue("hiddenNotifytriggerDays", 0)
        setCreatedFrom(new Date());

        RenewalsAndAlertsService.getContextTemplate({
            Id: Number(item.TemplateId)
        }).then(async templateData => {

            try {
                let renewalMonths = 0
                const items: ResponseGetContextTemplate[] = JSON.parse(templateData.result)
                if (items.length != 0) {
                    renewalMonths = items[0].RenewalMonths
                    await RenewalsAndAlertsService.getContextRecord({
                        Id: item !== undefined ? item.RecordId : 0
                    }).then(recordData => {

                        const items: ResponseGetContextRecord[] = JSON.parse(recordData.result)
                        if (items.length > 0) {

                            const now = new Date(items[0].RenewalDate);
                            setCurrentRenewalDate(onFormatDate(now, false))

                            now.setMonth(now.getMonth() + renewalMonths)
                            setCreatedFrom(now)

                            formRenewals.setValue("notifyTriggerFrequency", {
                                key: items[0].NotifyTriggerFrequency.toLowerCase(),
                                label: items[0].NotifyTriggerFrequency
                            })

                            formRenewals.setValue("hiddenRenewalsMonths", items[0].RenewalMonths)
                            formRenewals.setValue("hiddenNotifytriggerDays", items[0].NotifyTriggerDaysBeforeRenewal)
                            formRenewals.setValue("active", items[0].Active)

                            setIsLoadedContext(true)
                        } else {
                            setError(items.toString())
                        }
                    }).catch((error) => {
                        setError(error.toString())
                    })
                } else {
                    setError(items.toString())
                }

            } catch (error:any) {
                setError(error.toString())
            }
        }).catch((error) => {
            setError(error.toString())
        }).finally(() => {
            setIsLoadingRecordContext(false)
        })
    }

    useEffect(() => {
        if (showModal) {
            load(true).then()
        }
    }, [showModal])

    /**
     * Notify message if date is less than today's date
     * @param date
     */
    const onSelectDate = (date: Date | null | undefined) => {
        if (date !== null && date !== undefined) {

            const now = new Date();

            now.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);

            if (date < now) {
                showStatusMessage(t('messages.renewalDateLesstoToday', {ns: 'renewalsalerts'}), StatusEnum.Warning)
            } else {
                setShowMessagePivot1(false)
            }
            setCreatedFrom(date)
        }
    }

    /**
     * Update
     */
    const update = async () => {
        await formRenewals.handleSubmit(async () => {
                setIsSaving(true)
                console.log(dateCreatedFrom)
                await RenewalsAndAlertsService.postUpdateRecordDefinition({

                    TemplateId: Number(item.TemplateId),
                    RecordId: Number(item.RecordId),
                    NotifyTriggerDaysBeforeRenewal: Number(formRenewals.getValues("hiddenNotifytriggerDays")),
                    RenewalMonths: Number(formRenewals.getValues("hiddenRenewalsMonths")),
                    NotifyTriggerFrequency: formRenewals.getValues("notifyTriggerFrequency").key,
                    RenewalDate: moment(dateCreatedFrom).locale('en-US').format('MM/DD/YYYY'),
                    Active: Boolean(formRenewals.getValues("active"))

                }).then(data => {

                    if (!data.success) {
                        showStatusMessage(data.messageError ? data.messageError : "", StatusEnum.Error)
                    } else {
                        showStatusMessage(t('message.successUpdated', {ns: 'common'}), StatusEnum.Success)
                        setUpdated(true)

                    }
                }).catch((reason) => {
                    showStatusMessage(`${reason.data.ExceptionMessage}: ${reason.data.AxiosError}`, StatusEnum.Error)
                }).finally(() => {
                        setIsSaving(false)
                        context.setRefreshRenewals(true)
                    }
                )

            }, () => {

            }
        )();
    }

    const showStatusMessage = (message: string, status: StatusEnum) => {
        setMessagePivot1(message)
        setStatusMessageEnumPivot1(status)
        setShowMessagePivot1(true)
    }


    /**
     * Format Date using moment
     * @param date
     * @param utc
     */
    const onFormatDate = (date?: Date, utc: boolean = false): string => {

        const language = !!sessionStorage.getItem("language")
            ? sessionStorage.getItem("language")!.toString()
            : "en";

        if (utc) {
            var now_utc = Date.UTC(date!.getFullYear(), date!.getMonth(),
                date!.getDate(), date!.getHours(),
                date!.getMinutes(), date!.getSeconds());

            return date !== null ? formatFromUTC(now_utc, "LL", language) : null
        } else return date !== null ? formatSimpleDate(date, "LL", language) : null
    }

    const onBeforeDismiss = () => {
        // setIsReadOnly(true)
        setUpdated(false)
    }

    return (
        <>
            <Modal
                isOpen={showModal}
                isBlocking={true}
                containerClassName={contentStyles(branding.theme).containerRecord}
            >
                {/*-- START HEADER --*/}
                <Stack horizontal className={contentStyles(branding.theme).header}>
                    <StackItem grow={1}
                               styles={{root: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}}>
                        <Text variant={"xLarge"} id={item.RecordId.toString()}
                              title={t('title.modalRenew', {ns: 'renewalsalerts', Name: item.Name})}>
                            {t('title.modalRenew', {ns: 'renewalsalerts', Name: item.Name})}
                        </Text>
                    </StackItem>
                    <Stack horizontal>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                            onClick={() => {
                                onBeforeDismiss()
                                onDismissModal(updated, false)
                            }}
                            title={t('Common.Button.Close', {ns: 'common'})}
                        />
                    </Stack>
                </Stack>
                <Separator/>

                {/*-- END HEADER --*/}

                {/*-- START BODY --*/}
                <Stack horizontal tokens={{padding: 8}} style={{overflow: "hidden"}}>

                    {/*-- START CONTENT --*/}
                    <Stack grow={1} style={{overflow: "hidden", padding: "0px 8px 8px 8px"}}>
                        <StackItem>

                            {showMessagePivot1 &&
                                <>
                                    <StackItem styles={{root: {marginBottom: 16}}}>

                                        <StatusMessage status={statusMessageEnumPivot1} hasTimer={false}
                                                       truncated={true}
                                                       isMultiline={true}
                                                       dismiss={true}
                                                       setShowMessage={setShowMessagePivot1}>
                                            <div>{messagePivot1}</div>
                                        </StatusMessage>
                                    </StackItem>

                                </>
                            }
                        </StackItem>

                        <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                               style={{
                                   overflow: "hidden",
                                   overflowY: "auto",
                                   height: "240px",
                               }}>


                            <FormProvider {...formRenewals}>
                                <form>


                                    {tryAgain &&
                                        <>
                                            {t('text.errorAndTry', {ns: 'common'})}
                                            <DefaultButton onClick={() => {
                                                load(true).then()
                                            }} style={{marginLeft: 8}}
                                                           text={t('Common.Button.Try', {ns: 'common'})}
                                                           title={t('Common.Button.Try', {ns: 'common'})}/>
                                        </>
                                    }
                                    {isLoadingRecordContext && <>

                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>

                                    </>}
                                    {isLoadedContext &&
                                        <>
                                            <Stack tokens={{padding: 16}}
                                                   styles={{
                                                       root: {
                                                           marginTop: 16,
                                                           backgroundColor: getTheme().semanticColors.listItemBackgroundHovered
                                                       }
                                                   }}>
                                                <Text block
                                                      variant={"mediumPlus"}>{t('text.nextRenewalDate', {ns: 'renewalsalerts'})}</Text>
                                                <Separator/>
                                                <Label>{t('text.currentRenewalDate', {
                                                    ns: 'renewalsalerts',
                                                    Date: currentRenewalDate
                                                })}</Label>

                                                <DatePicker
                                                    strings={Helper.getLocateCalendar()}
                                                    today={new Date()}
                                                    title={t('title.ctrlNextRenewalDate', {ns: 'renewalsalerts'})}
                                                    isMonthPickerVisible={true} id="renewalDate"
                                                    placeholder={t('placeholder.ctrlNextRenewalDate', {ns: 'renewalsalerts'})}
                                                    ariaLabel={t('ariaLabel.ctrlNextRenewalDate', {ns: 'renewalsalerts'})}
                                                    label={t('label.ctrlNextRenewalDate', {ns: 'renewalsalerts'})}
                                                    formatDate={onFormatDate}
                                                    value={dateCreatedFrom!}
                                                    onSelectDate={onSelectDate}/>
                                            </Stack>
                                        </>
                                    }
                                </form>
                            </FormProvider>

                        </Stack>


                        {/*-- START FOOTER --*/}
                        <Separator></Separator>
                        <Stack horizontal style={{overflow: "hidden"}} styles={nonShrinkingStackItemStyles}>
                            <StackItem grow={1}>&nbsp;</StackItem>
                            {isLoadingRecordContext &&
                                <>
                                    <StackItem>
                                        <Spinner size={SpinnerSize.medium} labelPosition="left"
                                                 label={t('text.spinner', {ns: 'common'})}/>
                                    </StackItem>
                                </>
                            }
                            {isSaving &&
                                <>
                                    <StackItem>
                                        <Spinner size={SpinnerSize.medium} labelPosition="left"
                                                 label={t('text.spinnerSaving', {ns: 'common'})}/>
                                    </StackItem>
                                </>
                            }

                            <StackItem>


                                <>
                                    <PrimaryButton disabled={!isLoadedContext || isSaving}
                                                   style={{marginLeft: 8}}
                                                   onClick={update}
                                                   text={t('Common.Button.Renew', {ns: 'common'})}
                                                   title={t('Common.Button.Renew', {ns: 'common'})}/>
                                </>


                                <DefaultButton
                                    onClick={() => {
                                        onBeforeDismiss()
                                        onDismissModal(updated, previewPDF!)
                                    }}
                                    split={!!previewPDF}
                                    menuProps={previewPDF ? menuProps : undefined}

                                    style={{marginLeft: 8}}
                                    text={previewPDF ? t('Common.Button.CloseAndPreview', {ns: 'common'}) : t('Common.Button.Close', {ns: 'common'})}
                                    title={previewPDF ? t('Common.Button.CloseAndPreview', {ns: 'common'}) : t('Common.Button.Close', {ns: 'common'})}/>
                            </StackItem>
                        </Stack>
                        {/*-- START FOOTER --*/}

                    </Stack>
                    {/*-- END CONTENT --*/}

                </Stack>
                {/*-- END BODY --*/}

            </Modal>
        </>
    )
}


export default RenewalsAndAlertsRenewal
