
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { decode } from 'base64-arraybuffer';
import { Checkbox, CheckboxVisibility, DefaultButton, DetailsList, DetailsListLayoutMode, IButtonStyles, IColumn, IconButton, IContextualMenuItem, IContextualMenuProps, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, StackItem, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { ICertificateModel, IGetCertificateRequest, ICreateCertificate, IUpdateDeleteCertificate, ApiIntegrationServices } from "./Models/CertificateModels";
import Helper from '../../Helpers/Helper';
import InboundServices from './Services/InboundServices';
import ReactDOM from 'react-dom';
import React from 'react';
import ConfirmationModal from '../../Helpers/Modals/ConfirmationModal';
import { setGlobalDateLocale } from '../../Helpers/DateUtils';
import { Sizes } from '../../share/CustomModal';
import { useModal } from '../../hooks/useModal';
import StatusMessage, { StatusEnum } from '../../share/StatusMessage';
import {useBranding} from "../../hooks/useBranding";

const stackTokens = { childrenGap: 10 };
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };

function InboundIntegration(props: any) {
    const { branding } = useBranding()
    const emptyCertificate: ICertificateModel = {
        Name: "",
        IsActive: false,
        PublicKey: "",
        Id: "",
        Display: false,
        Params: "",
        Methods: []
    }
    const [certificateList, setCertificateList] = useState<ICertificateModel[]>([]);
    const [selectedCertificate, setSelectedCertificate] = useState<ICertificateModel>(emptyCertificate);
    const [selectedServices, setSelectedServices] = useState<ApiIntegrationServices[]>([]);
    const [currentCertificate, setCurrentCertificate] = useState<ICertificateModel>(emptyCertificate)
    const [successModalMessage, setSuccessModalMessage] = useState("")


    const [tokenInputName, setTokenInputName] = useState("")
    const [applying, setApplying] = useState(false);

    const [isNameRequired, setIsNameRequired] = useState(false)
    const [isCreatingToken, setIsCreatingToken] = useState(false)
    const [showTokenServices, setShowTokenServices] = useState(false)
    const [textYesButton, setTextYesButton] = useState("Ok")
    const [tokenServicesMessage, setTokenServicesMessage] = useState(<></>)
    const [toggleWithDelta, setToggleWithDelta] = useState(false)
    const [toggleRetrieveBulkDownload, setToggleRetrieveBulkDownload] = useState(false)
    const { Modal, open, close } = useModal()



    useEffect(() => {
        setGlobalDateLocale(navigator.language)

        if (certificateList.length == 0) {
            setApplying(true)
            setCertificateList([])
            loadCertificates();
        }
    }, [])

    const loadCertificates = async () => {
        const getCertificateRequest: IGetCertificateRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            TemplateId: parseInt(props.templateId)
        }

        await InboundServices.getCertificates(getCertificateRequest).then(data => {
            data.forEach((certificate) => {
                certificate.Display = false
            })
            setSelectedServices([])
            setCertificateList(data)
            setApplying(false)
            setToggleWithDelta(false)
            setToggleRetrieveBulkDownload(false)
            let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneAudiencelist")) as Element
            if (element !== null) { element.children[1].classList.add("scrollVisible") }
        })
    }

    function getMenuItems(item: ICertificateModel): IContextualMenuItem[] {
        return [
            {
                key: 'services',
                text: 'Services',
                title: 'View Token Services.',
                onClick: () => {
                    setShowTokenServices(true);
                    setTokenServicesMessage(<>
                        {getCertificateServicesHTML(item)}
                    </>);
                    _deselect();
                },
            },
            {
                key: 'download',
                text: 'Service Description',
                title: 'Service Description',
                onClick: () => {
                    const documentText = createCertificateDocument(item)
                    downloadTxt(documentText, item.Name + ".txt")
                },
            },
            {
                key: 'disable',
                text: 'Disable',
                title: 'Disable Certificate.',
                onClick: () => {
                    UpdateCertificate(item, false);
                },
            },
            {
                key: 'delete',
                text: 'Delete',
                title: 'Delete Certificate.',
                onClick: () => {
                    DeleteCertificate(item)
                },
            }
        ]
    }

    function getMenuItems2(item: ICertificateModel): IContextualMenuItem[] {
        return [
            {
                key: 'enable',
                text: 'Enable',
                title: 'Enable Certificate.',
                onClick: () => {
                    UpdateCertificate(item, true);
                },
            },
            {
                key: 'delete',
                text: 'Delete',
                title: 'Delete Certificate.',
                onClick: () => {
                    DeleteCertificate(item)
                },
            }
        ]
    }

    function getMenuProps(item: ICertificateModel): IContextualMenuProps {
        return {
            shouldFocusOnMount: true,
            items: item.IsActive ? getMenuItems(item) : getMenuItems2(item),
        }
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Name',
            fieldName: 'Name',
            minWidth: 170,
            maxWidth: 170,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true
        },
        {
            key: 'column2',
            name: 'Certificate',
            fieldName: 'PublicKey',
            minWidth: 80,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: ICertificateModel) => {
                return <DefaultButton id="btnShowToken" onClick={() => { tokenDisplay(item) }} text="Show" title="Show." />

            }
        },
        {
            key: 'column3',
            name: 'Status',
            fieldName: 'IsActive',
            minWidth: 50,
            maxWidth: 50,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return item.IsActive ?
                    <span title="Enable">Enable</span>
                    :
                    <span title="Disable">Disable</span>
            }
        },
        {
            key: 'column4',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 20,
            maxWidth: 20,
            isResizable: false,
            onRender: (item: ICertificateModel) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    title={"Click to select from available actions."}
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={getMenuProps(item)}
                /></div>
            },
            isPadded: true
        },
    ]

    const tokenDisplay = (item: ICertificateModel) => {
        setCurrentCertificate(item)
        open()
    }

    const copyToken = (item: ICertificateModel) => {
        navigator.clipboard.writeText(item.PublicKey).then(
            () => { },
            () => {
                const input = document.createElement('input');
                input.style.position = 'absolute';
                input.style.left = '-100';
                input.style.zIndex = "-1000";
                document.body.appendChild(input);
                input.value = item.PublicKey;
                input.focus();
                input.select();
                const result = document.execCommand('copy');
                if (!result) {
                    console.error('Failed to copy text.');
                }
                input.remove();
            }
        )
        setSuccessModalMessage("Token copied!")
    }

    const showTokenServicesCallback = async (args: any) => {
        setShowTokenServices(false)
        setTokenServicesMessage(<></>)
    }

    const onSelectionChange = () => {
        const selectionCount = selectionCertificate.getSelectedCount();
        switch (selectionCount) {
            case 0:
                setSelectedCertificate(emptyCertificate)
                break;
            case 1:
                setSelectedCertificate(selectionCertificate.getSelection()[0] as ICertificateModel)
                break;
            default:
                setSelectedCertificate(emptyCertificate)
                break;
        }
    }

    const _deselect = () => {
        selectionCertificate.setAllSelected(false);
    }

    const selectionCertificate = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    const getKey = (item: ICertificateModel, index?: number): string => {
        if (item !== undefined)
            return item.Id!.toString()
        return ""
    }

    const GenerateCertificate = async () => {
        const request: ICreateCertificate = {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            TemplateId: parseInt(props.templateId),
            Name: tokenInputName,
            IsActive: true,
            Methods: selectedServices
        }

        const getCertificateRequest: IGetCertificateRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            TemplateId: parseInt(props.templateId)
        }

        await InboundServices.getCertificates(getCertificateRequest).then(data => {
            let repeatedToken = data.find(x => x.Name.toUpperCase() === tokenInputName.toUpperCase())
            if (repeatedToken === undefined) {
                setIsCreatingToken(true)
                InboundServices.createCertificate(request).then(data => {
                    loadCertificates();
                    setTokenInputName('')
                    setIsNameRequired(false)
                    setIsCreatingToken(false)
                    setToggleWithDelta(false)
                    setToggleRetrieveBulkDownload(false)
                    props.showSuccessMessage("Token created successfully!")
                })
            } else {
                props.showErrorMessage("Certificate " + tokenInputName + " already exist.")
            }
        })
    }

    const UpdateCertificate = async (item: ICertificateModel, isActive: boolean) => {
        const request: IUpdateDeleteCertificate = {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            TemplateId: parseInt(props.templateId),
            Id: item.Id,
            IsActive: isActive,
            Name: item.Name,
            PublicKey: item.PublicKey
        }

        InboundServices.updateCertificate(request).then(data => {
            _deselect();
            loadCertificates();
            props.showSuccessMessage("Certificate updated successfully!")
        })
    }

    const DeleteCertificate = async (item: ICertificateModel) => {
        const request: IUpdateDeleteCertificate = {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            UserName: Helper.getUsername(),
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            TemplateId: parseInt(props.templateId),
            Id: item.Id,
            IsActive: item.IsActive,
            Name: item.Name,
            PublicKey: item.PublicKey,
        }

        InboundServices.deleteCertificate(request).then(data => {
            _deselect();
            loadCertificates();
            props.showSuccessMessage("Certificate deleted successfully!")
        })
    }

    const onTokenInputNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        setTokenInputName(value || '');
        if (value!.length > 0) {
            setIsNameRequired(true)
        } else {
            setIsNameRequired(false)
        }
    }

    const downloadTxt = (text: string, fileName?: string) => {
        const download = async () => {
            const name = fileName ?? "ApiIntegration.txt"
            var Base64 = { blob: btoa(text) }
            const decoded = decode(Base64.blob)
            try {
                const file = new Blob(
                    [decoded], { type: "text/plain" });
                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(file);
                a.download = name;
                a.click();
                _deselect();
            } catch (error) {
                console.log(error)
            }
        }
        download()
    }

    const createCertificateDocument = (item: ICertificateModel) => {
        let text = "";
        text += introductionText();
        text += createToken(item.PublicKey, item.Params);
        text += templateXml(item.PublicKey);
        text += getDocumentTemplate(item.PublicKey);

        item?.Methods.forEach(service => {
            switch (service) {
                case ApiIntegrationServices.Create_Record:
                    text += createRecordTemplate(item.PublicKey)
                    break;
                case ApiIntegrationServices.Update_Record:
                    text += updateRecordTemplate(item.PublicKey)
                    break;
                case ApiIntegrationServices.Retreive_Bulk_Download:
                    text += retriveBulkDownloadTemplate(item.PublicKey)
                    break;
                case ApiIntegrationServices.Record_Status:
                    text += recordStatusTemplate(item.PublicKey)
                    break;
                case ApiIntegrationServices.Enterprise_Search_Record:
                    text += enterpriseSearchTemplate(item.PublicKey)
                    break;
                case ApiIntegrationServices.Create_Record_Note:
                    text += createRecordNoteTemplate(item.PublicKey)
                    break;
                case ApiIntegrationServices.Record_Note:
                    text += recordNoteTemplate(item.PublicKey)
                    break;
                case ApiIntegrationServices.Create_Record_Attachment:
                    text += createRecordAttachmentTemplate(item.PublicKey)
                    break;
                case ApiIntegrationServices.Record_Attachment:
                    text += recordAttachmentTemplate(item.PublicKey)
                    break;
                case ApiIntegrationServices.Delete_Record_Attachment:
                    text += deleteRecordAttachmentTemplate(item.PublicKey)
                    break;
                case ApiIntegrationServices.Retrieve_Bulk_Download_With_Delta:
                    text += retrieveBulkDownloadWithDeltaTemplate(item.PublicKey)
                    break;
                default:
                    break;
            }
        });
        return text
    }

    const introductionText = () => {
        return `Get Started!
For more examples, check out the documentation at https://documenter.getpostman.com/view/21048320/Uz5FJbfC
To tryout the endpoints, go to the documentation above and click on "Run in Postman".

`
    }

    const createToken = (certificate: string, params: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Create Token
Method: POST 
Url: ` + url + `/CreateToken
Content-Type: text/plain
Headers:{
    "Certificate" : "${certificate}",
}
Body: "${params}"
}


`
        return text
    }

    const templateXml = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Get Template
Method: GET
Url: ${url}/TemplateXml?Format={{format}}
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}}
}
Url Parameters: 
{
    format: string
}


`
        return text
    }

    const getDocumentTemplate = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Get Record
Method: GET 
Url: ` + url + `/Document?DocumentId={{documentId}}
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}}
}
Url Parameters: {
    documentId: string
}


`
        return text
    }

    const createRecordTemplate = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Create Record
Method: POST 
Url: ` + url + `/CreateDocument
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}},
    "Content-Type": application/json
}
Body:
{
    "TemplateXml": string,
    "Description": string,
    "TargetCulture": string,
    "OnBehalfUser": string
}


`

        return text
    }

    const updateRecordTemplate = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Update Record
Method: POST 
Url: ` + url + `/UpdateDocument
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}},
    "Content-Type": application/json
}
Content-Type: application/json
Body:
{
    "DocumentId" : string,
    "DocumentXml": string,
    "TargetCulture": string,
    "OnBehalfUser": string
}


`
        return text
    }

    const retriveBulkDownloadTemplate = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Retrieve Bulk Download
Method: POST 
Url: ` + url + `/BulkDownload
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}},
    "Content-Type": application/json
}
Content-Type: application/json
Body:
{
    "Operation" : string,
    "DateCreatedFrom": datetime,
    "DateCreatedTo": datetime,
    "DateProcessedFrom": datetime,
    "DateProcessedTo": datetime
}


`

        return text
    }

    const retrieveBulkDownloadWithDeltaTemplate = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Retrieve Bulk Download With Delta
Method: POST 
Url: ` + url + `/BulkDownloadDelta
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}},
    "Content-Type": application/json
}
Content-Type: application/json
Body:
{
    "Operation" : string
}


`
        return text
    }

    const recordStatusTemplate = (certificate?: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Record Status Endpoint
Method: GET 
Url: ` + url + `/DocumentOnlyStatus?DocumentId={{documentId}}
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}}
}
Url Parameters: {
    documentId: integer
}


`
        return text
    }

    const enterpriseSearchTemplate = (certificate?: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Enterprise Search Record
Method: POST 
Url: ` + url + `/AdvanceSearch
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}},
    "Content-Type": application/json
}
Content-Type: application/json
Body:
{
    "EndUserName": string,
    "Content": string,
    "Description": string,
    "DateProcessedFrom": datetime,
    "DateProcessedTo": datetime,
    "DateCreatedFrom": datetime,
    "DateCreatedTo": datetime,
    "IncludeClosed": boolean,
    "SearchNotes": string,
    "PageNo": integer,
    "RecordsPerPage": integer,
    "ContentSearch": integer,
    "Activity": string
}


`
        return text
    }

    const createRecordNoteTemplate = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Create Record Note
Method: POST 
Url: ` + url + `/DocumentOnlyAddNote
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}},
    "Content-Type": application/json
}
Content-Type: application/json
Body:
{
    "DocumentId": string,
    "Note": string,
    "Visibility": string
}


`
        return text
    }

    const recordNoteTemplate = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Record Note
Method: GET 
Url: ` + url + `/DocumentOnlyNote?DocumentId={{documentId}}
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}}
}
Url Parameters: {
    documentId: integer
}


`
        return text
    }

    const createRecordAttachmentTemplate = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Create Record Attachment
Method: POST 
Url: ` + url + `/AddDocumentAttachment
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}},
    "Content-Type": application/json
}
Content-Type: application/json
Body:
{
    "DocumentId": integer,
    "AttachmentName": string,
    "AttachmentDescription": string,
    "File": string,
    "FileExt" : string,
    "MimeType": string,
    "FileExt": string,
    "Private": boolean
}


`
        return text
    }

    const recordAttachmentTemplate = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Record Attachment
Method: GET 
Url: ` + url + `/GetDocumentOnlyAttachmentByDocumentId?DocumentId={{documentId}}
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}}
}
Url Parameters: {
    documentId: integer
}


`
        return text
    }

    const deleteRecordAttachmentTemplate = (certificate: string) => {
        var url = window.location.protocol + "//" + window.location.host + "/ApiIntegration";

        const text = `Delete Record Attachment
Method: GET 
Url: ` + url + `/DeleteDocumentOnlyAttachment?AttachmentId={{attachmentId}}&Operation=delete&DocumentId={{documentId}}
Headers:{
    "Certificate" : "${certificate}",
    "Authorization": {{token}}
}
Url Parameters: {
    attachmentId: integer,
    documentId: integer,
    operation: string
}


`
        return text
    }

    const _onChangeServices = async (isChecked?: boolean, service?: ApiIntegrationServices) => {
        let currentServices = service === ApiIntegrationServices.Retreive_Bulk_Download || service === ApiIntegrationServices.Retrieve_Bulk_Download_With_Delta ? await fillterService(service!) : selectedServices
        if (isChecked) {
            currentServices.push(service!);
            setSelectedServices(currentServices);
        } else {
            setSelectedServices(currentServices.filter(d => d != service))
        }
    }

    const fillterService = async (service: ApiIntegrationServices): Promise<ApiIntegrationServices[]> => {
        return selectedServices.filter(d => d !== ApiIntegrationServices.Retreive_Bulk_Download && d !== ApiIntegrationServices.Retrieve_Bulk_Download_With_Delta)
    }

    const getCertificateServicesHTML = (item: ICertificateModel) => {
        var certificateServices: ApiIntegrationServices[] = [...item.Methods]
        var certificateServicesNames: string[] = []
        certificateServices.forEach(element => {
            certificateServicesNames.push(ApiIntegrationServices[element])
        });
        const myArrMadeFromForEach: JSX.Element[] = [];
        certificateServicesNames.forEach((item, i) => myArrMadeFromForEach.push(<span key={item + i}>{item}<br /></span>));
        return myArrMadeFromForEach
    }

    return (
        <>
            <label className="ms-fontSize-18">API Inbound Integration (applied to selected services)</label>
            <span>
                <p>Use Kim's Inbound Integration for application administrators to generate and provision service descriptions for clients to
                    integrate with {branding.appName} application. (i) Select the required services, (ii) enter a name, (iii) and generate the
                    certificate.  The service description will then be available for download from the menu to provide clients.</p>


            </span>
            <br />
            <span>    - Download technical services information from the action menu, once you generate a certificate.</span>
            <br />
            <span>    - For services information and examples, check out the <a href='https://documenter.getpostman.com/view/21048320/Uz5FJbfC' target="_blank">Documentation</a>.</span>
            <br />
            <span>    - To tryout the token, go to the documentation above and click on "Run in Postman" at the top right corner.</span>
            <br />
            <br />
            {!isCreatingToken ?
                <Stack horizontal>
                    <StackItem grow>
                        <Checkbox label="Post - Create Record" onChange={(_, v) => { _onChangeServices(v!, ApiIntegrationServices.Create_Record) }} />
                        <br />
                        <Checkbox label="Post - Update Record" onChange={(_, v) => { _onChangeServices(v!, ApiIntegrationServices.Update_Record) }} />
                        <br />
                        <Checkbox label="Post - Retrieve Bulk Download" checked={toggleRetrieveBulkDownload} onChange={(_, v) => { setToggleWithDelta(false); setToggleRetrieveBulkDownload(v!); _onChangeServices(v!, ApiIntegrationServices.Retreive_Bulk_Download) }} />
                        <br />
                        <Checkbox label="Post - Retrieve Bulk Download With Delta." checked={toggleWithDelta} onChange={(_, v) => { setToggleRetrieveBulkDownload(false); setToggleWithDelta(v!); _onChangeServices(v!, ApiIntegrationServices.Retrieve_Bulk_Download_With_Delta) }} />
                        <br />
                        <Checkbox label="Get  - Record Status" onChange={(_, v) => { _onChangeServices(v!, ApiIntegrationServices.Record_Status) }} />
                        <br />
                        <Checkbox label="Post - Enterprise Search Record" onChange={(_, v) => { _onChangeServices(v!, ApiIntegrationServices.Enterprise_Search_Record) }} />
                    </StackItem>
                    <StackItem grow>
                        <Checkbox label="Post - Create Record Note" onChange={(_, v) => { _onChangeServices(v!, ApiIntegrationServices.Create_Record_Note) }} />
                        <br />
                        <Checkbox label="Get  - Record Note" onChange={(_, v) => { _onChangeServices(v!, ApiIntegrationServices.Record_Note) }} />
                        <br />
                        <Checkbox label="Post - Create Record Attachment" onChange={(_, v) => { _onChangeServices(v!, ApiIntegrationServices.Create_Record_Attachment) }} />
                        <br />
                        <Checkbox label="Get  - Record Attachment" onChange={(_, v) => { _onChangeServices(v!, ApiIntegrationServices.Record_Attachment) }} />
                        <br />
                        <Checkbox label="Get  - Delete Record Attachment" onChange={(_, v) => { _onChangeServices(v!, ApiIntegrationServices.Delete_Record_Attachment) }} />
                    </StackItem>
                </Stack>
                :
                null}
            <Stack styles={{ root: { paddingRight: "10px" } }} tokens={stackTokens}>
                <label />
                {!isCreatingToken ?
                    <>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                                <TextField acceptCharset='' label="Certificate Name (Max. 25 characters)" maxLength={25} onChange={onTokenInputNameChange} value={tokenInputName} placeholder="Example: Demo Token" required title="Enter Token Name." />
                            </div>
                        </div>
                        <div key="button" className="ms-Grid-col ms-sm2">
                            <PrimaryButton disabled={!isNameRequired} key="generateToken" onClick={() => { GenerateCertificate() }} title="Generate Certificate" text="Generate Certificate" style={{ float: 'left', marginTop: '3vh' }} />
                        </div>
                    </>
                    :
                    <Spinner size={SpinnerSize.medium} label="Creating Token..." ariaLive="assertive" />
                }
                <label />
                <label />
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                    {!applying ?
                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", paddingRight: "10px", maxWidth: "100%" }}>
                            <DetailsList
                                items={certificateList}
                                compact={false}
                                columns={columns}
                                getKey={getKey}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                selection={selectionCertificate}
                                selectionMode={SelectionMode.single}
                                selectionPreservedOnEmptyClick={false}
                                enterModalSelectionOnTouch={true}
                                checkboxVisibility={CheckboxVisibility.hidden}
                            />
                        </div>
                        : <Spinner size={SpinnerSize.medium} label="Loading data, please wait..." ariaLive="assertive" />
                    }
                </div>
            </Stack>
            <ConfirmationModal
                showConfirmationModal={showTokenServices}
                setShowConfirmationModal={setShowTokenServices}
                callbackConfirmationModal={showTokenServicesCallback}
                title="Certificate Services"
                textYesButton={textYesButton}
                message={tokenServicesMessage}
                titleYesButton=""
                HideNoButton={true}
            />
            <Modal title={"Certificate (" + currentCertificate.Name + ")"} titleId={"Certificate"} size={Sizes.xs}>
                <>
                    {successModalMessage !== '' ? <StatusMessage setShowMessage={() => setSuccessModalMessage('')} status={StatusEnum.Success} >
                        {successModalMessage}
                    </StatusMessage> : <></>}

                    <div style={{ padding: "20px 0px" }}>
                        <span style={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}>{currentCertificate.PublicKey}</span>
                        <br></br>
                    </div>
                    <div style={{ float: 'right' }}>
                        <DefaultButton id="btnCopyToken" onClick={() => { copyToken(currentCertificate) }} text="Copy" title="Copy." />
                    </div>
                </>
            </Modal>
        </>
    )
}

export default InboundIntegration