import React, { useEffect, useState } from 'react';
import { getTheme, FontWeights, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { useId } from '@uifabric/react-hooks';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IShimmerStyleProps, IShimmerStyles } from 'office-ui-fabric-react/lib/Shimmer';
import Helper, { } from '../../Helpers/Helper';
import '../../Custom.css';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Separator } from '@fluentui/react/lib/Separator';
import { Modal, IModalStyles } from "@fluentui/react/lib/Modal"
import { Label } from '@fluentui/react/lib/Label';
import { List } from '@fluentui/react/lib/List';
import { useBoolean } from '@uifabric/react-hooks';
import { MessageBar, IMessageBarStyles } from '@fluentui/react/lib/MessageBar';
import { Icon, IIconProps } from '@fluentui/react/lib/Icon';
import * as microsoftTeams from "@microsoft/teams-js";
import { Checkbox, Callout, StackItem } from '@fluentui/react';
import { Link } from '@fluentui/react/lib/Link';
import { useForm } from 'react-hook-form';
import { RHFTextBoxControl } from "../RHFControls/RHFTextBoxControl";
import { getRecordDescription } from '../../Helpers/functions';
import { useTranslation } from "react-i18next";
import { Stack } from '@fluentui/react/lib/Stack';
import { Text } from "@fluentui/react/lib/Text";
import { useBranding } from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";


const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },

};
const getShimmerStyles = (props: IShimmerStyleProps): IShimmerStyles => {
    return {
        shimmerWrapper: [
            {
                backgroundColor: `${theme.palette.themeTertiary}`,
                marginLeft: "5%",
                marginTop: "1%"
            },
        ],
        shimmerGradient: [
            {
                backgroundColor: `${theme.palette.themeTertiary}`,
                backgroundImage:
                    'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #c7e0f4 50%, rgba(255, 255, 255, 0) 100%)',
            },
        ],
    };
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackTokens = { childrenGap: 50 };
const modalFormStyles: IModalStyles = {
    main: {
        width: '40vw',

    },
    root: {

    },
    scrollableContent: {},
    layer: {},
    keyboardMoveIcon: {},
    keyboardMoveIconContainer: {}
}
const messageBarStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#e0f2ff"
    }
};

const messageBarCompletedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    },
    innerText: {
        width: '100%'
    }
};
const messageBarVoidedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    },
    innerText: {
        width: '100%'
    }
};
const messageBarDeliveredStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#f9c94a"
    },
    innerText: {
        width: '100%'
    }
};
const messageBarEsignStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#f9c94a"
    },
    innerText: {
        width: '100%'
    }
};

const messageBarErrorStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#f9c94a"
    },
    innerText: {
        width: '100%'
    }
};
const actionButtonStyles: IButtonStyles = {
    root: {
        border: 'none',
        color: '#333333',
        height: 'auto',
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        float: 'right',
        cursor: 'pointer',
        selectors: {
            ':hover': {
                color: '#0078D4',
            },
        },
    },
    textContainer: {
        fontSize: 12,
        fontWeight: FontWeights.semibold,
    },
};

export interface IDocusignTagsRoles {
    RecipientId: string;
    RoleDescription: string;
}

export interface IDocuSignSigners {
    creationReason: string
    isBulkRecipient: string
    requireUploadSignature: string
    name: string
    firstName: string
    lastName: string
    email: string
    recipientId: string
    recipientIdGuid: string
    requireIdLookup: string
    userId: string
    routingOrder: string
    note: string
    roleName: string
    roleDescription: string
    status: string
    completedCount: string
    declinedReason: string
    deliveryMethod: string
    recipientType: string
    tags: string
}
export interface ISigners {
    Name: string;
    Email: string;
    RoutingOrder: string;
    RecipientId: string;
    Tags: string;
}

export interface ISignature {
    ClixSignatureTag: string
    DocumentOnlyCustomUserSignatureId: string
    Id: string
    AccountId: number
    TemplateId: string
    DocumentOnlyAudienceMasterId: string
    CustomSignatureName: string
    CustomSignatureDescription: string
    SignatureImage: string
    MimeType: string
    FileExt: string
}

function Signers(props: any) {
    const { branding } = useBranding()
    const { t } = useTranslation(['common', 'recordsign']);
    var tempSigners: IDocuSignSigners[] = [];
    const [listenerLoaded, setListenerLoaded] = useState(false)
    const [docuSignSigners, setdocuSignSigners] = useState<IDocuSignSigners[]>();
    const [docuSignStatus, setDocuSignStatus] = useState("");
    const [docuSignEnvelopeId, setDocuSignEnvelopeId] = useState("");
    const [docuSignSentDatetime, setDocuSignSentDatetime] = useState("");
    const [docuSignNotes, setDocuSignNotes] = useState("");
    const [isLoadingSigners, setIsLoadingSigners] = useState(false)
    const [spinnerTitle, setSpinnerTitle] = useState("");
    const [eSignMessage, seteSignMessage] = useState("");
    const [isEsignMessage, setisEsignMessage] = useState(false);
    const [signatures, setSignatures] = useState<ISignature[]>();
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [providerSelectedKey, setProviderSelectedKey] = useState("");
    const titleId = useId(props.modalId);
    const [isApply, setisApply] = React.useState(true);
    const [showErrorMessage, setshowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [selectedSigningOrder, setSelectedSigningOrder] = useState(false);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const { setValue, trigger, clearErrors, reset, getValues, control, handleSubmit, formState: { errors } } = useForm();
    const [docusignTagsRoles, setDocusignTagsRoles] = useState<IDocusignTagsRoles[]>([]);

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minWidth: '320px',
            width: '620px',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        footer: [
            {
                flex: '1 1 auto',
                // color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '0px 12px 24px 24px',
            },
        ],
        badget: {
            borderRadius: 8,
            padding: '2px 4px',
            fontSize: "11px",
            backgroundColor: 'gainsboro',
            marginLeft: 4,
            whiteSpace: 'nowrap'
        },
        body: {
            flex: '4 4 auto',
            margin: '0px',
            height: '50vh',
            justifyContent: 'center',
            display: 'flex',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        callout: {
            width: 320,
            maxWidth: '90%',
            padding: '20px 24px',
        },
    });

    useEffect(() => {
        const getTemplateId = async (templateId: string) => {

            await Helper.getDocumentOnlyTemplateId(templateId, true).then(data => {
                setProviderSelectedKey(data.eSignProvider)

                if (data.PropertyBag != null && data.PropertyBag.length > 0) {
                    try {
                        var jsonBag = JSON.parse(data.PropertyBag)
                        if (jsonBag !== null) {
                            setDocusignTagsRoles(jsonBag.DocusignTagsRoles !== undefined && jsonBag.DocusignTagsRoles !== null && Array.isArray(jsonBag.DocusignTagsRoles) ? jsonBag.DocusignTagsRoles : [])
                        }
                    } catch (error) {
                    }
                }

                getSignatures(data.eSignProvider)
            })

        }
        if (props.showSigners) {
            setSpinnerTitle(t('text.spinner', { ns: 'common' }))
            getTemplateId(props.templateId)
        }

    }, [props.showSigners])

    const onClose = () => {
        setListenerLoaded(false)
        setisEsignMessage(false);
        setSpinnerTitle(t('text.spinner', { ns: 'common' }))
        setIsLoggedIn(false)
        setSelectedSigningOrder(false)
        setisApply(true)
        setdocuSignSigners([])
        setDocuSignStatus("")
        setDocuSignEnvelopeId("")
        setDocuSignSentDatetime("")
        setDocuSignNotes("")
        setErrorMessage("")
        setshowErrorMessage(false)
        clearErrors()
        reset()
        props.hideModalSigners()
        props.update(props.documentId)
        props.hideModalSigners()
    }
    const getSigners = async () => {
        setIsLoadingSigners(true)
        
        const request = {
            DocumentId: props.documentId,
            Signers: undefined,
            TemplateId: undefined
        }

        const {data}: any = await HttpRequest.getWithSession('GetSigners', request)
        const result = data
       
        if (result.signers != null) {

            setdocuSignSigners(result.signers);
            //setIsDocuSignTemplateLoaded(true);
            sessionStorage.setItem("tempSigners", JSON.stringify(result.signers))

        }        
        
        setIsLoadingSigners(false)
    };

    function onRenderESignCell(props: any) {
        const Role = docusignTagsRoles.find(element => element.RecipientId === props.recipientId)?.RoleDescription
        return (
            <>
                <StackItem >
                    <Text variant={"medium"} styles={{ root: { margin: 8, display: "block", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } }}>
                        <Icon iconName="PencilReply" />
                        <strong style={{ paddingLeft: 8 }}>{t('text.signer', { interpolation: { 'escapeValue': false }, ns: 'recordsign', Role, Name: props.routingOrder })}</strong>
                    </Text>
                </StackItem>


                <Label>{t('label.tags', { ns: 'recordsign' })}
                    {props.tags.sn != undefined ? <span className={contentStyles.badget}>{t('label.signature', { ns: 'recordsign' })}</span> : ""}
                    {props.tags.in != undefined ? <span className={contentStyles.badget}>{t('label.initials', { ns: 'recordsign' })}</span> : ""}
                    {props.tags.fln != undefined ? <span className={contentStyles.badget}>{t('label.fullName', { ns: 'recordsign' })}</span> : ""}
                    {props.tags.fn != undefined ? <span className={contentStyles.badget}>{t('label.firstName', { ns: 'recordsign' })}</span> : ""}
                    {props.tags.ln != undefined ? <span className={contentStyles.badget}>{t('label.lastName', { ns: 'recordsign' })}</span> : ""}
                    {props.tags.ds != undefined ? <span className={contentStyles.badget}>{t('label.dateSigned', { ns: 'recordsign' })}</span> : ""}
                    {props.tags.ti != undefined ? <span className={contentStyles.badget}>{t('label.titleSign', { ns: 'recordsign' })}</span> : ""}
                    {props.tags.ea != undefined ? <span className={contentStyles.badget}>{t('label.emailAddress', { ns: 'recordsign' })}</span> : ""}
                </Label>

                <RHFTextBoxControl
                    id={"txtSignerEmail_" + props.recipientId}
                    control={control}
                    label={t('label.email', { ns: 'recordsign' })}
                    getValues={getValues}
                    rules={{ required: t('text.required', { ns: 'recordsign' }), pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i, message: t('text.emailNoValid', { ns: 'common' }) } }}
                    setValue={setValue}
                    errorMessage={errors["txtSignerEmail_" + props.recipientId]?.message?.toString()}
                    trigger={trigger}>
                </RHFTextBoxControl>
                <RHFTextBoxControl
                    id={"txtSignerName_" + props.recipientId}
                    control={control}
                    label={t('label.name', { ns: 'recordsign' })}
                    getValues={getValues}
                    rules={{ required: t('text.required', { ns: 'recordsign' }), maxLength: 80 }}
                    setValue={setValue}
                    trigger={trigger}>
                </RHFTextBoxControl>
                <br />
            </>

        );
    };

    const submitEsignMail = async () => {
        await handleSubmit((data) => {

            tempSigners = []
            tempSigners = JSON.parse(sessionStorage.getItem("tempSigners")!)
            if (tempSigners !== undefined && tempSigners.length > 0) {
                for (const [key, value] of Object.entries(data)) {

                    const inputArray = key.split("_");
                    const signer = tempSigners.filter(x => x.recipientId == inputArray[1]);

                    if (inputArray[0] === "txtSignerEmail") {
                        tempSigners[tempSigners.indexOf(signer[0])].email = value
                    }
                    if (inputArray[0] === "txtSignerName") {
                        tempSigners[tempSigners.indexOf(signer[0])].name = value
                    }
                }
                sessionStorage.setItem("tempSigners", JSON.stringify(tempSigners))
            }
            sendEsignMail()

        },
            (errors) => {

            }
        )();
    };

    const sendEsignMail = async () => {
        setIsLoadingSigners(true)
        setSpinnerTitle(t('text.spinnerSending', { ns: 'common' }))
        tempSigners = []
        tempSigners = JSON.parse(sessionStorage.getItem("tempSigners")!)

        var signersList: ISigners[] = []
        for (var i = 0; i < tempSigners.length; i++) {
            signersList.push({
                Email: tempSigners[i].email,
                Name: tempSigners[i].name,
                RoutingOrder: tempSigners[i].routingOrder,
                RecipientId: tempSigners[i].recipientId,
                Tags: JSON.stringify(tempSigners[i].tags)
            })
        }
        
        const request = {
            DocumentId: props.documentId.toString(),
            Version: props.version.toString(),
            SigningOrder: selectedSigningOrder,
            Signers: signersList,
            TemplateId: props.templateId.toString(),
            EnvelopeId: "",
            DocumentName: props.documentName,
            DocumentDescription: props.documentDescription,
            URI: window.location.origin,
            AuthToken: sessionStorage.getItem("DocuSignToken")
        }

        let {data}: any = await HttpRequest.postWithSession('SendMailEsign', request)
        const response = data

        if (response.Status == "Unauthorize") {
            seteSignMessage(t('messages.getAuthorization', { ns: 'recordsign', Url: response.Url }));
            setisEsignMessage(true);
        }
        else {
            if (response.Status == "sent") {
                setTimeout(function () {
                    onClose()
                }, 7000)
            }


            if (response.Status == "Error") {
                if (response.Message.includes("USER_AUTHENTICATION_FAILED")) {
                    await cleanDocusignTokenOnStateManagement()
                    docuSignLogin(1)
                }
                else if (response.Message != undefined && response.ErrorMessage !== null && response.Message.includes("internal_server_error")) {
                    await cleanDocusignTokenOnStateManagement()
                    setIsLoadingSigners(false)
                    setshowErrorMessage(true)
                    setErrorMessage(t('messages.openAuthorization', { ns: 'recordsign' }))
                }
                else {
                    await cleanDocusignTokenOnStateManagement()
                    setIsLoadingSigners(false)
                    setshowErrorMessage(true)
                    setErrorMessage(response.Message)
                }
            }
            else {
                verifyLoginG()
            }
        }
        setTimeout(function () {

            onClose()

        }, 7000)


    }
    async function verifyLoginA() {
        docuSignLogin(2)
    }
    async function verifyLoginS() {
        docuSignLogin(1)
    }
    async function verifyLoginG() {
        docuSignLogin(3)
    }
    const abortEsign = async () => {
        setIsLoadingSigners(true)
        setSpinnerTitle(t('text.spinnerAborting', { ns: 'common' }))
        tempSigners = []
        tempSigners = JSON.parse(sessionStorage.getItem("tempSigners")!)

        var signersList: ISigners[] = []
        
        
        const request = {
            DocumentId: props.documentId.toString(),
            Signers: signersList,
            TemplateId: props.templateId.toString(),
            EnvelopeId: docuSignEnvelopeId,
            AuthToken: sessionStorage.getItem("DocuSignToken")
        }

        let {data}: any = await HttpRequest.postWithSession('AbortEsign', request)
        const response =  data

        if (response.Status == "Unauthorize") {
            seteSignMessage(t('messages.getAuthorization', { ns: 'recordsign', Url: response.Url }));
            setisEsignMessage(true);
        }
        else {
            if (response.Status == "Error") {
                if (response.Message.includes("USER_AUTHENTICATION_FAILED")) {
                    await cleanDocusignTokenOnStateManagement()
                    docuSignLogin(2)
                }
            }
            else {
                verifyLoginG()
            }
        }

    }
    const getDocuSignEnvelopeStatus = async () => {

        setIsLoadingSigners(true)
        setSpinnerTitle(t('text.spinner', { ns: 'common' }))

        
        const request = {
            DocumentId: props.documentId,
            KimTemplateId: props.templateId,
            DocumentName: props.documentName,
            Version: props.version,
            AuthToken: sessionStorage.getItem("DocuSignToken")
        }


        const {data}: any = await HttpRequest.getWithSession('GetStatus', request)
        const result = data
        
       
        if (result.Status == "Success") {

            if (result.ESignEnvelopeId.length > 0) {
                setDocuSignStatus(result.ESignStatus)
                setDocuSignEnvelopeId(result.ESignEnvelopeId)
                setDocuSignSentDatetime(result.SentDateTime)
                setDocuSignNotes(result.Notes)
                setIsLoadingSigners(false)

                if (result.ESignStatus != "sent" && result.ESignStatus != "completed" && result.ESignStatus != "delivered") {
                    getSigners()
                } else if (result.ESignStatus === "completed") {
                    props.update(props.documentId)

                }
            }
            else {
                setdocuSignSigners([])
                setDocuSignStatus("")
                setDocuSignEnvelopeId("")
                setDocuSignSentDatetime("")
                setDocuSignNotes("")
                getSigners()
            }
        }
        else {
            if (result.Message.includes("USER_AUTHENTICATION_FAILED")) {
                await cleanDocusignTokenOnStateManagement()
                docuSignLogin(3)
            } else {
                setIsLoadingSigners(false)
                setshowErrorMessage(true)
                setErrorMessage(result.Message)
            }

            //showNotification(result.Message,"errorLeft");
        }

    };

    async function cleanDocusignTokenOnStateManagement() {

        const request = {
            User: Helper.getUsername(),
            Token: ""
        }

        let {data}: any = await HttpRequest.postWithSession('SetTokenDocuSign', request)
        sessionStorage.setItem("DocuSignToken", "")
    }

    const EnvelopeMessage = () => (
        <MessageBar styles={docuSignStatus == "sent" ? messageBarStyles : docuSignStatus == "completed" ? messageBarCompletedStyles : docuSignStatus == "delivered" ? messageBarDeliveredStyles : messageBarVoidedStyles}>
            <div>{t('text.envelopeId', { ns: 'recordsign' })} <b>{docuSignEnvelopeId}</b>
                <Icon id={"syncDocuSign"} title={t('title.envelopeId', { ns: 'recordsign' })} iconName="Sync"
                    styles={actionButtonStyles} onClick={verifyLoginG} />
            </div>
            <div>{t('text.envelopeStatus', { ns: 'recordsign' })} <b>{docuSignStatus}</b></div>
            <div>{t('text.envelopeDatetime', { ns: 'recordsign' })} <b>{docuSignSentDatetime}</b>
            </div>
        </MessageBar>

    );
    const EsignMessage = () => (
        <MessageBar styles={messageBarEsignStyles}>
            {eSignMessage}
        </MessageBar>
    );
    const ErrorMessage = () => (
        <MessageBar styles={messageBarErrorStyles}>
            {errorMessage}
        </MessageBar>
    );

    const getSignatures = async (eSignProvider: string) => {
        setIsLoadingSigners(true)
        setisApply(false)
        setSpinnerTitle(t('text.spinnerVerifying', { ns: 'common' }))
        
        
        const request = {
            TemplateId: props.templateId,
            Indicator: 'template',
            Signatures: undefined
        }

        const {data}: any = await HttpRequest.getWithSession('GetAllSignaturesImages', request)
        
        if (Array.isArray(data)) {
            setSignatures(data)
            if (data.length > 0) {
                setSpinnerTitle(t('text.spinnerSigning', { ns: 'common' }))
                applySignature(data)
                props.update(props.documentId)
            }
            else if (eSignProvider !== "" && eSignProvider !== "None") {
                if (sessionStorage.getItem("entityId") !== 'Not In Teams') {
                    setIsLoadingSigners(false)
                    setshowErrorMessage(true)
                    setErrorMessage(t('messages.openNotInTeams', { ns: 'recordsign' }))
                }
                else {
                    verifyLoginG()
                }
                //getDocuSignEnvelopeStatus()

                //setIsLoadingSigners(false)
            }
            else {
                setIsLoadingSigners(false)
                setshowErrorMessage(true)
                setErrorMessage(t('messages.notAutoSignatures', { ns: 'recordsign' }))


            }
        }
        else if (eSignProvider !== "" && eSignProvider !== "None") {
            //getDocuSignEnvelopeStatus()
            if (sessionStorage.getItem("entityId") !== 'Not In Teams') {
                setIsLoadingSigners(false)
                setshowErrorMessage(true)
                setErrorMessage(t('messages.openNotInTeams', { ns: 'recordsign' }))
            }
            else {
                verifyLoginG()
            }
            //setIsLoadingSigners(false)
        }
        // else{
        //     setIsLoggedIn(false)
        //     setIsLoadingSigners(false)
        // }


    };

    async function docuSignLogin(option: number) {
        if (sessionStorage.getItem("DocuSignToken") === null || sessionStorage.getItem("DocuSignToken") === "") {
            var user = Helper.getUsername()
            var sessionKey = Helper.getSessionKey()
            var accountId = Helper.getDefaultAccount()
            var path = props.siteSelected
            var tokenAuth = await Helper.getHeaders()
            var base64String = btoa(user + "|" + sessionKey + "|" + accountId + "|" + path + "|" + tokenAuth["x-identity"]);
            var intKey = await Helper.getDocuSignIntegrationKey()

            microsoftTeams.initialize();
            microsoftTeams.authentication.authenticate(
                {
                    url: intKey.AuthEndpoint + "?response_type=token&scope=signature&client_id=" + intKey.Key + "&state=" + base64String + "&redirect_uri=" + window.origin + "/docuSignLogin.html",
                    //url: "/docuSignLogin.html",
                    width: 600,
                    height: 535,
                    successCallback: async function (result) {
                        await getDocuSignToken()
                        if (sessionStorage.getItem("DocuSignToken") != null && sessionStorage.getItem("DocuSignToken") != "") {
                            setIsLoggedIn(true)
                            switch (option) {
                                case 1:
                                    submitEsignMail()
                                    break;
                                case 2:
                                    abortEsign()
                                    break;
                                case 3:
                                    getDocuSignEnvelopeStatus()
                                    break;

                            }
                        }
                        else {

                            setIsLoggedIn(false)
                            setIsLoadingSigners(false)
                        }

                    },
                    failureCallback: async function (reason) {

                        await getDocuSignToken()
                        if (sessionStorage.getItem("DocuSignToken") != null && sessionStorage.getItem("DocuSignToken") != "") {
                            setIsLoggedIn(true)
                            switch (option) {
                                case 1:
                                    submitEsignMail()
                                    break;
                                case 2:
                                    abortEsign()
                                    break;
                                case 3:
                                    getDocuSignEnvelopeStatus()
                                    break;

                            }
                        }
                        else {
                            setIsLoggedIn(false)
                            setIsLoadingSigners(false)
                        }
                    }
                }
            )
        }
        else {
            setIsLoggedIn(true)
            switch (option) {
                case 1:
                    submitEsignMail()
                    break;
                case 2:
                    abortEsign()
                    break;
                case 3:
                    getDocuSignEnvelopeStatus()
                    break;

            }
        }

    }

    const applySignature = async (sign: ISignature[]) => {
        var docVersion = props.version != undefined ? props.version.toString() : "1"
        
        
        const request = {
            DocumentId: props.documentId.toString(),
            Signatures: sign,
            FileName: props.modalTitle.toString(),
            TemplateId: props.templateId.toString(),
            Version: docVersion
        }
        await HttpRequest.postWithSession('ApplySignature', request)
            .then((data: any) => {
                if (data.data.Success) {
                    props.update(props.documentId)
                } else {
                    setIsLoadingSigners(false)
                    setshowErrorMessage(true)
                    setErrorMessage(data.data.ExceptionMessage)
                }

                setTimeout(function () {
                    onClose()
                }, 7000)
            })
            .catch((error)=> {})
            .finally(() => {
                setIsLoadingSigners(false)
            })        
    };

    async function getDocuSignToken() {

        const request = {
            TemplateId: props.templateId.toString(),
            User: props.username
        }

        let {data}: any = await HttpRequest.getWithSession('GetTokenDocuSign', request)
        const response = data

        if (response != null) {
            if (response.Token != null) {
                sessionStorage.setItem("DocuSignToken", response.Token)
            }
            else {
                setshowErrorMessage(true)
                setErrorMessage(t('messages.openAuthorization', { ns: 'recordsign' }))
            }

        }
    }

    function onChangeSigningOrder(ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) {
        setSelectedSigningOrder(isChecked!)
    }

    function _renderLabelWithLink(props: any) {
        return (
            <span>
                {props.label}{" "}
                <Link underline onClick={toggleIsCalloutVisible} id={'signingOrderMore'}>{t('text.learnMore', { ns: 'recordsign' })}</Link>

            </span>
        );
    }

    const onSubmit = (data: any) => console.log(data);

    return (
        <Modal
            isOpen={props.showSigners}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            {/*Header*/}
            <div className={contentStyles.header}>
                <span id={titleId}>{getRecordDescription(props.modalTitle, props.documentDescription)}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                    onClick={onClose}
                    title={t('Common.Button.Close', { ns: 'common' })}
                />
            </div>
            <Separator />
            {/*End Header*/}

            {/*Body*/}
            <Stack styles={{ root: { overflow: "hidden", overflowY: "auto" } }} className={`scrollVisible ${contentStyles.body}`} data-is-scrollable  >
                <Stack styles={{ root: { margin: 24, height: "100%" } }}>
                    {isEsignMessage && <EsignMessage />}
                    {isLoadingSigners ?
                        <>
                            <Spinner size={SpinnerSize.large} label={spinnerTitle} />
                        </>
                        :
                        <>
                            {showErrorMessage ?
                                <>
                                    <h5>{<ErrorMessage />}</h5>
                                </>
                                :
                                <>
                                    {docuSignEnvelopeId.length > 0 ?
                                        <div hidden={isApply === true}>
                                            <p>{t('text.signedText1', { ns: 'recordsign' })} <strong>{t('text.signedText2', { ns: 'recordsign' })}</strong>
                                            </p>
                                            <EnvelopeMessage></EnvelopeMessage>
                                            <Separator></Separator>
                                        </div>
                                        : null
                                    }
                                    <div hidden={isApply === true}>
                                        {docuSignStatus != "sent" && docuSignStatus != "delivered" && docuSignStatus != "completed" ?
                                            (docuSignSigners != undefined && docuSignSigners.length > 0 ?
                                                <>
                                                    <p>{t('text.signedText3', { ns: 'recordsign' })}</p>
                                                    <Checkbox id={"signingOrder"}
                                                        label={t('label.signerOrder', { ns: 'recordsign' })}
                                                        checked={selectedSigningOrder}
                                                        onRenderLabel={_renderLabelWithLink}
                                                        onChange={onChangeSigningOrder} />
                                                    {isCalloutVisible && (
                                                        <Callout
                                                            className={contentStyles.callout}
                                                            ariaLabelledBy={"signingOrder-ariaLabelledBy"}
                                                            ariaDescribedBy={"signingOrder-ariaDescribedBy"}
                                                            role="dialog"
                                                            gapSpace={0}
                                                            target={`#signingOrderMore`}
                                                            onDismiss={toggleIsCalloutVisible}
                                                        >
                                                            <p>{t('text.help1', { ns: 'recordsign' })}</p>
                                                            <p>{t('text.help2', { ns: 'recordsign' })}</p>
                                                            <p>{t('text.help3', { ns: 'recordsign' })}</p>
                                                        </Callout>
                                                    )}
                                                    <Separator />
                                                    <form>
                                                        <List items={docuSignSigners} onRenderCell={onRenderESignCell} />
                                                    </form>
                                                </>
                                                : signatures != undefined && signatures!.length > 0 ?
                                                    <Label className="divNotFound">{t('label.signedSuccessful', { ns: 'recordsign' })}</Label>
                                                    : isLoggedIn ?
                                                        <Label className="divNotFound">{t('messages.notDocuSignSignatures', { ns: 'recordsign' })}</Label>
                                                        :
                                                        <Label className="divNotFound">{t('messages.loginError', { ns: 'recordsign' })}</Label>
                                            )
                                            : null}
                                    </div>
                                </>
                            }
                        </>
                    }
                </Stack>
            </Stack>
            {/*End Body*/}

            {/*Footer*/}
            <Separator />
            <div className={contentStyles.footer}>
                <DialogFooter>
                    {docuSignStatus != "completed" && (signatures == undefined || signatures!.length == undefined || signatures!.length == 0) ?
                        <PrimaryButton
                            onClick={isLoggedIn ? docuSignStatus == "sent" || docuSignStatus == "delivered" ? verifyLoginA : verifyLoginS : verifyLoginG}
                            text={isLoggedIn ? docuSignStatus == "sent" || docuSignStatus == "delivered" ? t('Common.Button.Abort', { ns: 'common' }) : t('Common.Button.Send', { ns: 'common' }) : t('Common.Button.Login', { ns: 'common' })}
                            title={isLoggedIn ? docuSignStatus == "sent" || docuSignStatus == "delivered" ? t('Common.Button.Abort', { ns: 'common' }) : t('Common.Button.Send', { ns: 'common' }) : t('Common.Button.Login', { ns: 'common' })} />
                        : null}
                    <DefaultButton onClick={onClose} text={t('Common.Button.Close', { ns: 'common' })} title={t('Common.Button.Close', { ns: 'common' })} />
                </DialogFooter>
            </div>
            {/*End Footer*/}
        </Modal>
    );
}

export default Signers


