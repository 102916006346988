import React, { useEffect } from 'react';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { useId } from '@uifabric/react-hooks';
import { IIconProps } from '@fluentui/react/lib/Icon';
import {Stack, StackItem, Text } from '@fluentui/react';
import { IconButton, PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import '../../Custom.css';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import HelpComponent from '../Help/HelpComponent'
import { Modal } from '@fluentui/react/lib/Modal';
import { INavLinkGroup, Nav, INavLink } from '@fluentui/react/lib/Nav';
import SwitchSite from '../Settings/SwitchSite';
import { useState } from 'react';
import Lists from './Lists';
import Helper, { IBaseRequest } from '../../Helpers/Helper';
import Audiences from './Audiences';
import Signatures from './Signatures';
import EditCategories from './EditCategories';
import { useBoolean } from '@uifabric/react-hooks';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { isMobile } from 'react-device-detect';
import { Separator } from '@fluentui/react/lib/Separator';
import BulkDownload from './BulkDownload';
import Plan from './Plan';
import Merchant from './Merchant';
import ManageClientPlans from './ManageClientPlans';
import BillingState from '../BillingAndPlan/BillingState';
import DeleteUserAccount from './DeleteUserAccount';
import ChangeOwner from './ChangeOwner';
import ServiceEmail from './ServiceEmail';
import { useTranslation } from 'react-i18next';
import ApplicationLogs from '../AdminLogs/ApplicationLogs';
import ApiLogs from '../AdminLogs/ApiLogs/ApiLogs';
import useAuth from '../../hooks/useAuth';
import {useBranding} from "../../hooks/useBranding";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import {Content} from "../MyBillingPlan";
import {Content as ContentProfile} from "../MyProfile"
import UserService, {IGetuserprofileRequest} from "../../services/User";

const theme = getTheme();

const iconButtonStylesPrompt = {
    root: {
        color: theme.palette.black,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
            ':disabled': {
                backgroundColor: theme.palette.white
            },
        },
    },
    
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const menuIcon: IIconProps = { iconName: 'GlobalNavButton' };

function FullSettings(props: any) {
    const { branding } = useBranding()
    const { t } = useTranslation(['settings', 'common']);
    const [option, setOption] = useState("1")
    const titleId = useId("Properties");
    const [showSignature, setshowSignature] = useState(false)
    const [isOpenMenu, { toggle: setIsOpenMenu }] = useBoolean(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [onCloseProfileModal, setOnCloseProfileModal] = useState(false)

    const [profile, setProfile] = useState<any>()
    const { logout } = useAuth();

    const iconButtonStyles = {
        root: {
            color: branding.theme.palette.themePrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
    }

    /**
     * Close profile modal function
     * @param cancel
     */
    const closeProfileModal = (cancel: boolean = false) => {
        if (!cancel) {
            props.hideProfileModal(false)
        }

        setOnCloseProfileModal(false)
    }

    const settingLinks: INavLinkGroup[] = [
        {
            name: '',
            links: [
                { name: t("FullsettingComponent.SideBar.MyAudiences", { ns: 'settings' }), url: '', key: '1', icon: 'ContactList', title: t("FullsettingComponent.SideBar.MyAudiencestitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.MyCategories", { ns: 'settings' }), url: '', key: '6', icon: 'AllApps', title: t("FullsettingComponent.SideBar.MyCategorieTitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.MyLists", { ns: 'settings' }), url: '', key: '4', icon: 'ClipboardList', title: t("FullsettingComponent.SideBar.MyListsTitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.MySignatures", { ns: 'settings' }), url: '', key: '5', icon: 'InsertSignatureLine', title: t("FullsettingComponent.SideBar.MySignaturesTitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.Profile", { ns: 'settings' }), url: '', key: '3', icon: 'AccountManagement', title: t("FullsettingComponent.SideBar.ProfileTitle", { ns: 'settings' }) },
                //{ name: 'Switch Site', url: '', key: '2', icon: 'CloudImportExport', title: 'Switch to other available regions.' },
                { name: t("FullsettingComponent.SideBar.BulkDownload", { ns: 'settings' }), url: '', key: '8', icon: 'Download', title: t("FullsettingComponent.SideBar.BulkDownload", { ns: 'settings' }) },
                /*{ name: 'Privacy Policy', url: '/privacypolicy2021.html', key: '20', icon: 'ProtectionCenterLogo32', target: '_blank' },
                { name: 'Terms and Conditions', url: '/toc.html', key: '21', icon: 'Questionnaire', target: '_blank' },*/
                { name: t("FullsettingComponent.SideBar.ChangeTransferOwner", { ns: 'settings' }), url: '', key: '28', icon: 'ChangeEntitlements', title: t("FullsettingComponent.SideBar.ChangeTransferOwner", { ns: 'settings' }) }
            ],
        }
    ];
    const settingLinksExt: INavLinkGroup[] = [
        {
            name: '',
            links: [
                { name: t("FullsettingComponent.SideBar.MyAudiences", { ns: 'settings' }), url: '', key: '1', icon: 'ContactList', title: t("FullsettingComponent.SideBar.MyAudiencestitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.MyCategories", { ns: 'settings' }), url: '', key: '6', icon: 'AllApps', title: t("FullsettingComponent.SideBar.MyCategorieTitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.MyLists", { ns: 'settings' }), url: '', key: '4', icon: 'ClipboardList', title: t("FullsettingComponent.SideBar.MyListsTitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.MySignatures", { ns: 'settings' }), url: '', key: '5', icon: 'InsertSignatureLine', title: t("FullsettingComponent.SideBar.MySignaturesTitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.MyBillingPlan", { ns: 'settings' }), url: '', key: '25', icon: 'M365InvoicingLogo', title: t("FullsettingComponent.SideBar.MyBillingPlanTitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.MyBillingStatus", { ns: 'settings' }), url: '', key: '26', icon: 'M365InvoicingLogo', title: t("FullsettingComponent.SideBar.MyBillingStatusTitle", { ns: 'settings' }) },
                //{ name: 'Profile', url: '', key: '3', icon: 'AccountManagement', title: 'Manage your profile name and time zone or delete your account.'},
                //{ name: 'Switch Site', url: '', key: '2', icon: 'CloudImportExport', title: 'Switch to other available regions.' },
                //{ name: 'Log Out', url: '', key: '7', icon: 'SignOut', title: 'User account log out .'},
                //{ name: 'Privacy Policy', url: '/privacypolicy2021.html', key: '20', icon: 'WorkFlow', target: '_blank' },
                //{ name: 'Terms and Conditions', url: '/toc.html', key: '21', icon: 'WorkFlow', target: '_blank' },
                { name: t("FullsettingComponent.SideBar.ManageBillingMerchant", { ns: 'settings' }), url: '', key: '22', icon: 'Bank', title: t("FullsettingComponent.SideBar.ManageBillingMerchantTitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.ManageBillingPlans", { ns: 'settings' }), url: '', key: '23', icon: 'TaskManager', title: t("FullsettingComponent.SideBar.ManageBillingPlansTitle", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.ManageBillingClientPlans", { ns: 'settings' }), url: '', key: '24', icon: 'ContactList', title: t("FullsettingComponent.SideBar.ManageBillingClientPlans", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.DeleteAccount", { ns: 'settings' }), url: '', key: '27', icon: 'UserRemove', title: t("FullsettingComponent.SideBar.DeleteAccount", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.ChangeTransferOwner", { ns: 'settings' }), url: '', key: '28', icon: 'ChangeEntitlements', title: t("FullsettingComponent.SideBar.ChangeTransferOwner", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.ServiceEmail", { ns: 'settings' }), url: '', key: '29', icon: 'MailOptions', title: t("FullsettingComponent.SideBar.ServiceEmail", { ns: 'settings' }) },
                { name: t("FullsettingComponent.SideBar.ApplicationLog", { ns: 'settings' }), url: '', key: '30', icon: 'BacklogList', title: t("FullsettingComponent.SideBar.ApplicationLog", { ns: 'settings' }) },
                { name: "Kim Platform API Logs", url: '', key: '31', icon: 'BacklogList', title: "Kim Platform API Logs" }

            ],
        }
    ];

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        containerSaveModal: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: isMobile ? null : '30vw',
            height: '20vh',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            width: '50vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyList: {
            width: '70vw',
            height: 'auto',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    useEffect(() => {
        if (props.selectedOption !== undefined) setOption(props.selectedOption.toString())
        getUserProfile()
    }, [props.showSettings])

    useEffect(() => {
        if (props.isSubscriptionDisabled) {
            getUserProfile()
            setOption('25')
            props.onSubscriptionDisabled()
        }
    }, [props.isSubscriptionDisabled])


    const getUserProfile = async () => {
        const request: IGetuserprofileRequest = {
            UserName: Helper.getUsername(),
            Profile: Helper.getUsername()
        }
        await UserService.GetUserProfile(request)
            .then((data) => {
                setProfile(data)
            })
            .catch()
            .finally()
    }

    const getLinkGroup = () => {
        let linkGroup = (sessionStorage.getItem("entityId") === "Not In Teams") ? settingLinksExt : settingLinks
        if (profile !== undefined) {
            if (profile.Billing === false) {
                linkGroup[0].links = linkGroup[0].links.filter(f => f.key !== '26' && f.key !== '25')
            }
            if (profile.AdminBilling === false) {
                linkGroup[0].links = linkGroup[0].links.filter(f => f.key !== '24' && f.key !== '23' && f.key !== '22' && f.key !== '27' && f.key !== '28' && f.key !== '29' && f.key !== '30' && f.key !== '31')
            }
        }
        return linkGroup
    }

    const handleProfileEdit = (value: boolean) => {
        props.setProfileEdited(value)
    }

    const onLinkClick = (ev?: React.MouseEvent<HTMLElement, MouseEvent>, item?: INavLink) => {
        if (item!.key === '5') {
            setshowSignature(true)
        }
        else if (item!.key === '7') {
            setShowConfirmationModal(true)
        }
        setOption(item!.key!)
    }

    const hideConfirmationModal = () => {
        setShowConfirmationModal(false)
    }
    function ConfiramtionModal() {

        return (
            <Modal
                isOpen={showConfirmationModal}
                isBlocking={true}
                containerClassName={contentStyles.containerSaveModal}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <Text variant={"xLarge"} id="Confirmation">{"Log Out Confirmation"}</Text>
                                <IconButton
                                    styles={iconButtonStylesPrompt}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={hideConfirmationModal}
                                    title="Close."
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg12">
                            <div className={contentStyles.body}>
                                Are you sure you want to log out?
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={logout} text="Yes" title="Confirm that you want to log out." />
                                <DefaultButton onClick={hideConfirmationModal} text="No" title="Cancel." />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    switch (option) {
        case "1": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>Settings</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel={t("Common.Button.CloseTitle", { ns: 'common' })}
                                onClick={() => { props.toggleSettings(false) }}
                                title={t("Common.Button.Cancel", { ns: 'common' })}
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="My Audiences"
                                text="My Audiences lists audiences that you have rights to manage and access."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="1"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="1"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <Audiences
                                            toggleSettings={props.toggleSettings}
                                            siteOptions={props.siteOptions}
                                            updateSiteSelected={props.updateSiteSelected}
                                            siteSelected={Helper.getSiteSelected()}
                                            accountId={Helper.getDefaultAccount()}
                                            username={Helper.getUsername()}
                                            sessionKey={Helper.getSessionKey()}
                                            fromModal={false}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close" />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "2": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel={t("Common.CloseTitle", { ns: 'common' })}
                                onClick={() => { props.toggleSettings(false) }}
                                title={t("Common.Close", { ns: 'settings' })}
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Switch Site"
                                text="Switch allows this application to point to different hosting locations for server resources."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="2"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="2"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <SwitchSite
                                            siteSelected={props.siteSelected}
                                            toggleSettings={props.toggleSettings}
                                            siteOptions={props.siteOptions}
                                            updateSiteSelected={props.updateSiteSelected}
                                            fromModal={false}
                                            switchSite={props.switchSite}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close. test" />
                                    </DialogFooter>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        }
        case "3": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => { props.hideProfileModal(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="User Profile"
                                text="Manage your name and time zone.  From this location you can also delete your account."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="3"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="3"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", paddingRight: "10px", maxWidth: "100%", height: "70vh" }}>

                                            <ContentProfile
                                                fromModal={false}
                                                triggerOnCloseParentModal={onCloseProfileModal}
                                                onCloseParentModal={closeProfileModal}
                                            />
                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <DialogFooter>
                                            <DefaultButton onClick={() => { setOnCloseProfileModal(true) }} text="Close" title="Close.asdas" />
                                        </DialogFooter>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </Modal>
                </>
            );
        }
        case "4": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="My Lists"
                                text="My Lists facilit is designed to provide list reuse across Form Wizard.  
For example, it could be required for a list of states, countries, services or maybe a repeatable way to answer yes/no, true/false and others to help users with input.
From the Form Wizard design palette, set control type to dropdown and follow the prompts."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="4"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="4"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <Lists
                                            toggleSettings={props.toggleSettings}
                                            siteOptions={props.siteOptions}
                                            updateSiteSelected={props.updateSiteSelected}
                                            modalTitle={"My Lists"}
                                            templateList={props.templateList}
                                            layoutLoaded={true}
                                            siteSelected={Helper.getSiteSelected()}
                                            accountId={Helper.getDefaultAccount()}
                                            username={Helper.getUsername()}
                                            sessionKey={Helper.getSessionKey()}
                                            fromModal={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row" >
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close." />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "5": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="My Signatures"
                                text="My Signatures is designed as a facility to manage signatures associated with application audience groups.  
Signatures are either digital or image and are mapped to application template's signature tags.
When a docuemnt based on that template is selected for signing, signatures for that template are retrieved by that application's audience and automatically applied to generated documents where they are mapped."
                                right="50px"
                                top="15px"
                                location="mysettings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="5"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="5"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <Signatures
                                            toggleSettings={props.toggleSettings}
                                            siteOptions={props.siteOptions}
                                            updateSiteSelected={props.updateSiteSelected}
                                            siteSelected={Helper.getSiteSelected()}
                                            accountId={Helper.getDefaultAccount()}
                                            username={Helper.getUsername()}
                                            sessionKey={Helper.getSessionKey()}
                                            fromModal={false}
                                            showSignature={showSignature}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row" >
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close." />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "6": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="My Categories"
                                text="My categories are used to group and classify applications.  
                                        Application authorization is controlled through application audience membership."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="6"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="6"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">

                                        <EditCategories
                                            fetchCategories={true}
                                            reloadApp={props.reloadApp}
                                            fromModal={false}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row" >
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <DialogFooter>
                                            <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close." />
                                        </DialogFooter>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        }
        case "7": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.LogOut", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="7"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                    </div>
                                </div>
                                <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <DialogFooter>
                                            <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close." />
                                        </DialogFooter>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        }
        case "8": {
            return (
                <>
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Bulk download"
                                text="Use this interface to download datapoints into an Excel or XML formatted file or to download the entire contents from records."
                                right="50px"
                                top="15px"
                                location="mysettings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="8"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="5"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <BulkDownload
                                            fromModal={false}
                                            fetchTemplates={true}
                                            update={props.update}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row" >
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close." />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            )
        }
        case "20": {
            return (
                <>
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal."
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="20"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="20"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "21": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal."
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="21"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="21"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "22": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal."
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Merchant"
                                text={`Merchant establishes the relationship between ${branding.appName} and the Payment provider. Normally, there is only one merchant records. The information that is collected for the merchant records relates to Kim Technologies ${branding.appName} application for the Payment Provider to collect funds from ${branding.appName} subscribers.`}
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="8"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="8"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <Merchant
                                            toggleSettings={props.toggleSettings}
                                            siteOptions={props.siteOptions}
                                            updateSiteSelected={props.updateSiteSelected}
                                            siteSelected={Helper.getSiteSelected()}
                                            accountId={Helper.getDefaultAccount()}
                                            username={Helper.getUsername()}
                                            sessionKey={Helper.getSessionKey()}
                                            fromModal={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close" />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "23": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal."
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Plans"
                                text="Kim has a few normal plans such as Starter, Standard and Professional.  We want most clients to be on one of these plans; however, we also have the ability to setup Free for 2 weeks, Free Perpituity (which Kim Employess will be using) and the ability to setup Custom Plans on an approved by client bases."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="9"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="9"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <Plan
                                            toggleSettings={props.toggleSettings}
                                            siteOptions={props.siteOptions}
                                            updateSiteSelected={props.updateSiteSelected}
                                            siteSelected={Helper.getSiteSelected()}
                                            accountId={Helper.getDefaultAccount()}
                                            username={Helper.getUsername()}
                                            sessionKey={Helper.getSessionKey()}
                                            fromModal={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close" />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "24": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal."
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Manage Client Plans"
                                text="Client plan list of all clients on platform with their selected billing plan.  Only Kim Adminsitrators are allowed access to this page and extreme care must be taken making changes to any client plans as it will change their billing amounts."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="10"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="10"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <ManageClientPlans
                                            toggleSettings={props.toggleSettings}
                                            siteOptions={props.siteOptions}
                                            updateSiteSelected={props.updateSiteSelected}
                                            siteSelected={Helper.getSiteSelected()}
                                            accountId={Helper.getDefaultAccount()}
                                            username={Helper.getUsername()}
                                            sessionKey={Helper.getSessionKey()}
                                            fromModal={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close" />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "25": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>

                        <Stack horizontal className={contentStyles.header}>
                            <StackItem grow={1}
                                       styles={{
                                           root: {
                                               textOverflow: "ellipsis",
                                               whiteSpace: "nowrap",
                                               overflow: "hidden"
                                           }
                                       }}>

                                <Text variant={"xLarge"} id={titleId}
                                      title={t("FullsettingComponent.ModalTitle", {ns: 'settings'})}>
                                    {t("FullsettingComponent.ModalTitle", {ns: 'settings'})}
                                </Text>
                            </StackItem>
                            <Stack horizontal styles={{root: {alignItems: "center"}}}>
                                <HelpComponentRelative
                                    headline="Billing Plan"
                                    text="View or select your current billing plan."
                                    location="settings"
                                />
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={() => { props.toggleSettings(false) }}
                                    title="Close."
                                />
                            </Stack>
                        </Stack>
                        
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="25"
                                            />
                                        </div>
                                        {!isMobile &&
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="25"
                                                />
                                            </Panel>
                                        </div>
                                        }
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                                               style={{
                                                   overflow: "hidden",
                                                   overflowY: "auto",
                                                   height: "50vh",
                                                   padding: "16px 16px 24px 16px"
                                               }}>
                                            <Content showContent={props.showSettings}/>
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row" >
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close." />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "26": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Client Plan"
                                text="View or select your current billing plan."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="26"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="26"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <BillingState
                                            toggleSettings={props.toggleSettings}
                                            siteOptions={props.siteOptions}
                                            updateSiteSelected={props.updateSiteSelected}
                                            siteSelected={Helper.getSiteSelected()}
                                            accountId={Helper.getDefaultAccount()}
                                            username={Helper.getUsername()}
                                            sessionKey={Helper.getSessionKey()}
                                            fromModal={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row" >
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close." />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "27": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal."
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Delete Account"
                                text="Delete account of a client  specific.  Only Kim Adminsitrators are allowed access to this page."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="10"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="10"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <DeleteUserAccount
                                            toggleSettings={props.toggleSettings}
                                            siteOptions={props.siteOptions}
                                            updateSiteSelected={props.updateSiteSelected}
                                            siteSelected={Helper.getSiteSelected()}
                                            accountId={Helper.getDefaultAccount()}
                                            username={Helper.getUsername()}
                                            sessionKey={Helper.getSessionKey()}
                                            fromModal={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close" />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "28": {
            return (
                <>
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal."
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Change Owner"
                                text="Change Owner will transfer ownership of your audiences(s), records and campaigns to specified target username."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="28"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="28"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <ChangeOwner
                                            isAdministration={true}
                                            audienceMasterId={0}
                                            showChangeOwnerModal={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close" />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "29": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Service Email"
                                text="Manage the Email Services. Only Kim Adminsitrators are allowed access to this page."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="29"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="29"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <ServiceEmail
                                            toggleSettings={props.toggleSettings}
                                            siteOptions={props.siteOptions}
                                            updateSiteSelected={props.updateSiteSelected}
                                            modalTitle={"Service Email"}
                                            templateList={props.templateList}
                                            layoutLoaded={true}
                                            siteSelected={Helper.getSiteSelected()}
                                            accountId={Helper.getDefaultAccount()}
                                            username={Helper.getUsername()}
                                            sessionKey={Helper.getSessionKey()}
                                            fromModal={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row" >
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close." />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "30": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Application Logs"
                                text="Manage Application logs."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="30"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="30"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh" }}>
                                            <ApplicationLogs TemplateId={-1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row" >
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close." />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        case "31": {
            return (
                <>
                    <ConfiramtionModal />
                    <Modal
                        isOpen={props.showSettings}
                        isBlocking={true}>
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id={titleId}>{t("FullsettingComponent.ModalTitle", { ns: 'settings' })}</Text>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => { props.toggleSettings(false) }}
                                title="Close."
                            />
                        </div>
                        {!props.fromModal ?
                            <HelpComponent
                                headline="Kim Platform API Logs"
                                text="Manage Kim Platform API Logs."
                                right="50px"
                                top="15px"
                                location="settings"
                            />
                            : null
                        }
                        <div className={contentStyles.bodyList}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey="31"
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey="31"
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh" }}>
                                            <ApiLogs />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row" >
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={() => { props.toggleSettings(false) }} text="Close" title="Close." />
                                    </DialogFooter>
                                </div>
                            </div>
                            <br />
                        </div>
                    </Modal>
                </>
            );
        }
        default: {
            return (
                <>
                </>
            );
        }
    }
}

export default FullSettings