import { useContext } from 'react';
import { appSourceContext } from '../context/AuthContext';


export const useAppSourceContext = () => {
    const sourceContext = useContext(appSourceContext)

    // const teams= useTeams()

    return {
        ...sourceContext,
        // teams
    }
}