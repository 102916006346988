import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Sizes} from "../../share/CustomModal";
import {useModal} from "../../hooks/useModal";
import {_styles, nonShrinkingStackItemStyles, subjects} from "./index";
import {
    DefaultButton,
    Pivot,
    PivotItem,
    PrimaryButton,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem
} from "@fluentui/react";
import Helper from '../../Helpers/Helper';
import {FormProvider, useForm} from "react-hook-form";
import {RHFTextBoxControl} from "../RHFControls/RHFTextBoxControl";
import {RHFDropDownListSingleSelectControl} from "../RHFControls/RHFDropDownListSingleSelectControl";
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import EmailService, {IContactSupportRequest,IContactSupportEmail} from "../../services/Email";
import HttpRequest from '../../services/HttpRequest';


type Properties = {
    showModal: boolean
    setShowModal: (status: boolean) => void
    defaultSubject: string
}
export const CustomerSupport = ({setShowModal, showModal, defaultSubject}: Properties) => {

    const {t} = useTranslation(['common', 'workspace', 'support'])
    const myModal = useModal()
    const [message, setMessage] = useState("")
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isSent, setIsSent] = useState(false)
    const [isDisableFrom, setIsDisableFrom] = useState(true)
    const [isDisableUserName, setIsDisableUserName] = useState(true)
    const [fullName, setfullName] = useState("")

    

    const myForm = useForm();

    useEffect(() => {
        if (showModal) {
            myModal.open()
        }
    }, [showModal])

    useEffect(() => {
        init().then()
    }, [])


    async function init() {

       
        setIsLoaded(false)
        setIsDisableFrom(true)
        
        setStatusMessageEnum(StatusEnum.Info)
        setMessage(t('text.message1', {ns: 'support'}))
        setShowMessage(true)
        setIsSaving(false)
        setIsSent(false)

        const username = Helper.getUsername()             
        myForm.reset()
        myForm.clearErrors()
    
        let contactsupportEmail="";
        if(username === undefined || username === null) {                            
            contactsupportEmail= await LoadContactSupport();
            setIsDisableFrom(false)
            }
        else{
                myForm.setValue("from", username)
                const se = await Helper.getSupportEmail() 
                contactsupportEmail=se;
         
            }       
        
        const userFullName=Helper.getuserFullName();
        myForm.setValue("username", userFullName)
        setfullName(userFullName!==undefined && userFullName!==null?userFullName:"");
        setIsDisableUserName(userFullName!==undefined && userFullName!==null?true:false);

        

     
        myForm.setValue("to", contactsupportEmail)  
        myForm.setValue("from", username) 
        myForm.setValue("subject", {
            label: defaultSubject,
            key: defaultSubject
        })
  
       
        setIsLoaded(true)
    }

    /**
     * Close modal
     */
    const closeModal = () => {
        myModal.close()
        setShowModal(false)
    }

    /**
     * Send email Request
     * @constructor
     */
    const SendRequest = async () => {
       
        setStatusMessageEnum(StatusEnum.Info)
        setMessage(t('text.message1', {ns: 'support'}))
        setShowMessage(true)
        await myForm.handleSubmit(async () => 
         {  
             myForm.clearErrors()
            setIsSaving(true)
                const request: IContactSupportRequest = 
                {
                    From: myForm.getValues("from"),
                    Message: `Email Address: ${myForm.getValues("from") }<br>User: ${myForm.getValues("username")}</br></br><br>${myForm.getValues("txtMessage")}`,
                    Subject: `${myForm.getValues("subject").key } : ${myForm.getValues("username")}`,
                    To: ""
                }     
               
            await EmailService.ContactSupport(request)
                .then(() => {

                    setStatusMessageEnum(StatusEnum.Success)
                    setMessage(t('text.message2', {ns: 'support'}))
                    setShowMessage(true)
                    setIsSent(true)
                    setTimeout(() => {
                        closeModal()
                    }, 3000);


                })
                .catch((error: any) => {
                    setStatusMessageEnum(StatusEnum.Error)
                    setMessage(t('message.error', {ns: 'common', Error: error.toString()}))
                    setShowMessage(true)
                })
                .finally(() => {
                    setIsSaving(false)
                })

        }, () => {

            setStatusMessageEnum(StatusEnum.Error)
            setMessage(t('message.fieldsRequired', {ns: 'common'}))
            setShowMessage(true)

        })()
    }
    const LoadContactSupport= async ():Promise<string> => {
        let cs="";
        await HttpRequest.getWithoutParams('/ContactSupport')
        .then((result: any) => {
            const response = result.data
            if (response.ErrorDescription === undefined || response.ErrorDescription === "") {
                const response=  result.data as IContactSupportEmail;
                cs=response.ContactSupport;                               
            }
                       
        })
        .catch(() => {
            setIsDisableFrom(false)
            myForm.setValue("from", "") 
        })  
        return cs; 
    }

    return (
        <>
            {/*region MODAL TEMPLATE*/}
            <myModal.Modal
                title={t('title.support', {ns: 'support'})} titleId="CustomerSupport" size={Sizes.xs}
                afterClose={() => {
                    closeModal()
                }}
                bodyClassName={_styles.body}
                additionalHeader={undefined}
            >
                <>
                    {/*region MESSAGES*/}
                    <StackItem>
                        {showMessage &&
                            <StatusMessage status={statusMessageEnum} hasTimer={false}
                                           truncated={true}
                                           isMultiline={true}
                                           dismiss={true}
                                           setShowMessage={setShowMessage}>
                                <div>{message}</div>
                            </StatusMessage>
                        }
                    </StackItem>
                    {/*endregion*/}


                    {/*region CONTENT*/}
                    <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                           style={{
                               overflow: "hidden",
                               overflowY: "auto",
                               height: "100%",
                               maxHeight: "60vh",
                               padding: "16px 16px 24px 16px"
                           }}>
                        <>
                            <Pivot aria-label={t('label.email', {ns: 'support'})}>
                                <PivotItem headerText={t('label.email', {ns: 'support'})}>

                                    {isLoaded &&
                                        <FormProvider {...myForm}>
                                            <form>
                                            <RHFTextBoxControl
                                                    id={"username"}
                                                    label={t('label.username', {ns: 'support'})}
                                                    rules={{
                                                        required: t('text.fieldRequired', {ns: 'common'}),                                                       
                                                        maxLength: 320
                                                    }}
                                                    placeholder={t('placeholder.username', {ns: 'support'})}
                                                    title={t('c', {ns: 'support'})}
                                                    ariaLabel={t('placeholder.username', {ns: 'support'})}
                                                    maxLength={320}
                                                    control={myForm.control}
                                                    setValue={myForm.getValues}
                                                    getValues={myForm.getValues}
                                                    trigger={myForm.trigger}
                                                    disabled={isDisableUserName}
                                                    required
                                                />
                                                <RHFTextBoxControl
                                                    id={"from"}
                                                    label={t('label.from', {ns: 'support'})}
                                                    rules={{
                                                        required: t('text.fieldRequired', {ns: 'common'}),
                                                        validate: {
                                                            required:
                                                                (value) => {
                                                                    return Helper.validateEmail(value) ? true : t('text.emailNoValid', {ns: 'common'})
                                                                }
                                                        },
                                                        maxLength: 320
                                                    }}
                                                    placeholder={t('placeholder.email', {ns: 'support'})}
                                                    title={t('placeholder.email', {ns: 'support'})}
                                                    ariaLabel={t('placeholder.email', {ns: 'support'})}
                                                    maxLength={320}
                                                    control={myForm.control}
                                                    setValue={myForm.getValues}
                                                    getValues={myForm.getValues}
                                                    trigger={myForm.trigger}
                                                    disabled={isSaving || isDisableFrom}
                                                    required
                                                />

                                                <RHFTextBoxControl
                                                    id={"to"}
                                                    disabled
                                                    label={t('label.to', {ns: 'support'})}
                                                    placeholder={t('label.email', {ns: 'support'})}
                                                    title={t('label.email', {ns: 'support'})}
                                                    ariaLabel={t('label.email', {ns: 'support'})}
                                                    maxLength={320}
                                                    control={myForm.control}
                                                    setValue={myForm.getValues}
                                                    getValues={myForm.getValues}
                                                    trigger={myForm.trigger}
                                                />


                                                <RHFDropDownListSingleSelectControl
                                                    id={"subject"}
                                                    label={t('label.subject', {ns: 'support'})}
                                                    title={t('label.subject', {ns: 'support'})}

                                                    options={subjects()}
                                                    control={myForm.control}
                                                    setValue={myForm.setValue}
                                                    getValues={myForm.getValues}
                                                    disabled={isSaving}
                                                    trigger={myForm.trigger}
                                                    defaultValue={myForm.getValues("subject").key!}
                                                />


                                                <RHFTextBoxControl
                                                    id={"txtMessage"}
                                                    label={t('label.message', {ns: 'support'})}
                                                    rules={{
                                                        required: t('text.fieldRequired', {ns: 'common'}),
                                                        validate: {
                                                            required:
                                                                (value) => {
                                                                    return !!value.trim() || t('text.fieldRequired', {ns: 'common'})
                                                                }
                                                        },
                                                        maxLength: 5000
                                                    }}
                                                    placeholder={t('placeholder.message', {ns: 'support'})}
                                                    title={t('placeholder.message', {ns: 'support'})}
                                                    ariaLabel={t('placeholder.message', {ns: 'support'})}
                                                    maxLength={5000}
                                                    multiline
                                                    rows={5}
                                                    control={myForm.control}
                                                    setValue={myForm.getValues}
                                                    getValues={myForm.getValues}
                                                    trigger={myForm.trigger}
                                                    disabled={isSaving}
                                                    required
                                                />
                                            </form>
                                        </FormProvider>
                                    }
                                </PivotItem>
                            </Pivot>

                        </>
                    </Stack>
                    {/*endregion*/}

                    {/*region FOOTER*/}
                    <Separator styles={{root: {marginBottom: -8}}}></Separator>
                    <Stack horizontal style={{overflow: "hidden"}} disableShrink styles={nonShrinkingStackItemStyles}>
                        <StackItem grow={1}>&nbsp;</StackItem>
                        {isSaving &&
                            <>
                                <StackItem>
                                    <Spinner size={SpinnerSize.medium} labelPosition="left"
                                             label={t('text.spinnerSending', {ns: 'common'})}/>
                                </StackItem>
                            </>
                        }
                        <StackItem>
                            <PrimaryButton onClick={SendRequest} style={{marginLeft: 8}}
                                           disabled={isSaving || isSent}
                                           text={t('Common.Button.Send', {ns: 'common'})}
                                           title={t('Common.Button.Send', {ns: 'common'})}/>
                            <DefaultButton onClick={() => {
                                closeModal()
                            }} style={{marginLeft: 8}}
                                           text={t('Common.Button.Close', {ns: 'common'})}
                                           title={t('Common.Button.Close', {ns: 'common'})}/>
                        </StackItem>
                    </Stack>
                    {/*endregion*/}


                </>
            </myModal.Modal>
            {/*endregion*/}
        </>
    )
}