import { DialogFooter, IconButton, IIconProps, IMessageBarStyles, MessageBar, Modal, PrimaryButton, ProgressIndicator, Spinner, SpinnerSize, TextField } from "@fluentui/react"
import React, { useEffect, useState } from "react"
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { isMobile } from "react-device-detect";
import Helper from "../../Helpers/Helper";
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";

const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};


const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };


function CampaignUser(props: any) {
    const { branding } = useBranding()
    const [message, setMessage] = useState("");
    const [applying, setApplying] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false)
    const [messageErrorListCSV, setMessageErrorListCSV] = useState("")
    const [showMessageListCSV, setshowMessageListCSV] = useState(false)
    const [campaignMasterId, setCampaignMasterId] = useState(0)
    const [username, setUsername] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [requiredEmail, setrequiredEmail] = useState(false)
    const [requiredName, setrequiredName] = useState(false)
    const [requiredLastName, setrequiredLastName] = useState(false)

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '50vw',
            height: '50vh',
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        listDialog: {
            width: isMobile ? null : '30vw',
            height: '50vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        addItemList: {
            width: isMobile ? null : '25vw',
            height: '25vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        buttonArea: {
        },
        callout: {
            maxWidth: 300,
        },
        inner: {
            height: '100%',
            padding: '0 24px 20px',
        }
    });


    const hideAddEditRespondent = (toogle: boolean, modified: boolean) => {
        setMessage("")
        setShowSuccess(false)
        setApplying(false)
        setMessageErrorListCSV("")
        setshowMessageListCSV(false)
        setShowSuccess(false)
        setMessage("")
        setUsername("")
        setFirstName("")
        setLastName("")
        setPhone("")
        setrequiredLastName(false)
        setrequiredName(false)
        setrequiredEmail(false)
        // props.setEditRespondent(false)
        props.hideAddEditUser(toogle, modified)
    }

    useEffect(() => {
        if (props.CampaignMasterId !== undefined && props.CampaignMasterId > 0) {
            setCampaignMasterId(props.CampaignMasterId)
            setrequiredEmail(false)
            setrequiredLastName(false)
            setrequiredName(false)
        }
        if (props.respondent.Id !== undefined && props.respondent.Id > 0 && props.editRespondent) {
            setLastName(props.respondent.LastName)
            setFirstName(props.respondent.FirstName)
            setUsername(props.respondent.Username)
            setPhone(props.respondent.PhoneNumber)
            setrequiredEmail(true)
            setrequiredLastName(true)
            setrequiredName(true)
        }
    }, [props.showAddEditUser])

    async function goToAddEditUser() {

        if (props.editRespondent) {

            const request = {
                Id: props.respondent.Id.toString(),
                CampaignMasterId: props.respondent.CampaignMasterId.toString(),
                RespondentUsername: props.respondent.Username,
                FirstName: firstName,
                LastName: lastName,
                PhoneNumber: phone,
                DateCompleted: props.respondent.DateCompleted,
                DateReminded: props.respondent.DateReminded,
                DateCreated: props.respondent.DateCreated,
                RespondentAccountId: props.respondent.AccountId.toString(),
                Operation: "update",
            }

            Helper.UpdateCampaignDetail(request)
            hideAddEditRespondent(false, true)
        } else {

            var file = "Username,FirstName,LastName,PhoneNumber\r\n"
            file += `${username},${firstName},${lastName},${phone}`
            var base64File = btoa(file)

            setApplying(true)

            const request = {
                CampaignMasterId: props.CampaignMasterId.toString(),
                Uri: Helper.getDefaultlocation(),
                Base64File: base64File,
            }

            let {data}: any = await HttpRequest.postWithSession('CreateCampaignDetailBatch', request)
            const response = data

            if (response.ErrorDescription !== undefined && response.ErrorDescription === "") {
                setApplying(false)
                setMessageErrorListCSV("")
                setshowMessageListCSV(false)
                setShowSuccess(true)
                setMessage("User saved successfully!")
                setTimeout(function () {
                    hideAddEditRespondent(false, true)
                }, 2000);

            } else if (response.ErrorDescription !== undefined) {
                setApplying(false)
                setShowSuccess(false)
                setMessage("")
                setMessageErrorListCSV(response.ErrorDescription)
                setshowMessageListCSV(true)
            } else {
                setShowSuccess(false)
                setMessage("")
                setApplying(false)
                setMessageErrorListCSV(response.ExceptionMessage)
                setshowMessageListCSV(true)
            }
     
        }
    }
    const getErrorMessage = (value: string): string => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length > 0) {
            if (!value.match(mailformat)) {
                setrequiredEmail(false)
                return "Email must be valid.";
            }

            setrequiredEmail(true)
        }

        return ""
    };


    return (
        <>
            <Modal
                isOpen={props.showAddEditUser}
                isBlocking={true}>
                <div className={contentStyles.header}>
                    <span id="CloseAddEditRespondent" key="CloseAddEditRespondent">{props.editRespondent ? "Edit Respondent" : "Add Respondent"}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup "
                        onClick={() => {
                            hideAddEditRespondent(false, false)
                        }}
                        title="Close."
                    />
                </div>
                <div className={contentStyles.addItemList}>
                    <div className="ms-Grid" dir="ltr">
                        {!applying ?
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {showMessageListCSV ? <MessageBar styles={messageBarFailedStyles}>{messageErrorListCSV}</MessageBar> : null}
                                    {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                    {props.editRespondent == false ? <span>
                                        <ul>
                                            <li>The moment you Upload a User List or Add Users they will immediately receive an email to participate in the Campaign so please make sure that you have taken all other actions before completing this step</li>
                                        </ul>
                                    </span> : null}
                                    <div className="ms-Grid-row">
                                        <TextField disabled={props.editRespondent} autoFocus label={"Email Address"} maxLength={300} onGetErrorMessage={getErrorMessage} required value={username} onChange={(_, v) => { setUsername(v!) }} title="" id="txtEmailAddress" />
                                        <TextField label={"First Name"} maxLength={300} required value={firstName} onChange={(_, v) => {
                                            setFirstName(v!)
                                            if (v?.length! > 0)
                                                setrequiredName(true)
                                            else
                                                setrequiredName(false)
                                        }} title="" id="txtFirstName" />
                                        <TextField label={"Last Name"} maxLength={300} required value={lastName} onChange={(_, v) => {
                                            setLastName(v!)
                                            if (v?.length! > 0)
                                                setrequiredLastName(true)
                                            else
                                                setrequiredLastName(false)
                                        }} title="" id="txtLastName" />
                                        <TextField maxLength={12} label={"Phone (Optional)"} value={phone} onChange={(_, v) => { setPhone(v!) }} title="" />
                                    </div>
                                    {uploadingFile ?
                                        <ProgressIndicator description={"Uploading... "} />
                                        : null
                                    }
                                    <DialogFooter>
                                        <PrimaryButton disabled={!requiredEmail || !requiredName || !requiredLastName} onClick={goToAddEditUser} text={props.editRespondent ? "Update" : "Add"} id="updateAddButton" title={props.editRespondent ? "Update User" : "Add User"} />
                                    </DialogFooter>
                                    <br />
                                </div>
                            </div>
                            : <Spinner size={SpinnerSize.medium} label="Applying changes..." ariaLive="assertive" />}
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default CampaignUser