import React, { useEffect } from 'react'
import { isInTeams } from '../../../Helpers/Utils'
import useAuth from '../../../hooks/useAuth'
import { Spinner, SpinnerSize, Stack } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useBranding } from '../../../hooks/useBranding'
const Logout = () => {

    const { isAuthenticated, logout } = useAuth()
    const { t, i18n } = useTranslation(['common']);
    useEffect(() => {
        if (isAuthenticated && !isInTeams()) {
            logout()
            window.location.assign(window.location.origin + "/InternalLogout")
        } else {

        }
    }, [isAuthenticated, logout])

    return (
        <Stack grow={1} styles={{ root: { height: "100%", alignItems: "center", justifyContent: "center" } }}>
            <Spinner size={SpinnerSize.medium} label={t('text.spinnerLogout', { ns: 'common' })} ariaLive="assertive" />
        </Stack>
    )
}
export default Logout