import React, { HTMLAttributes, useEffect, useState } from 'react';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { IconButton, PrimaryButton, DefaultButton, ActionButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { useId } from '@uifabric/react-hooks';
import { Stack } from '@fluentui/react/lib/Stack';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IShimmerStyleProps, IShimmerStyles } from 'office-ui-fabric-react/lib/Shimmer';
import Helper, {  } from '../../Helpers/Helper';
import '../../Custom.css';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ScrollablePane, IScrollablePaneStyles } from '@fluentui/react/lib/ScrollablePane';
import { Separator } from '@fluentui/react/lib/Separator';
import { Modal, IModalStyles } from "@fluentui/react/lib/Modal"
import { getTheme, mergeStyleSets, FontWeights, getFocusStyle } from "@fluentui/react/lib/Styling";
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { DetailsListLayoutMode, SelectionMode, IContextualMenuItem, IContextualMenuProps, ITextFieldStyles, ILabelStyleProps, ILabelStyles, Panel, PanelType } from '@fluentui/react';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { useBoolean, useConstCallback } from '@uifabric/react-hooks';
import { TextField } from '@fluentui/react/lib/TextField';
import { Label } from '@fluentui/react/lib/Label';
import { Checkbox } from "@fluentui/react/lib/Checkbox"
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';
import {useTranslation} from "react-i18next";
import { getRecordDescription } from '../../Helpers/functions';
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";



const theme = getTheme();
const { palette, fonts, semanticColors } = theme;
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const textFieldStyle: ITextFieldStyles = {
    root: {
    },
    revealButton: "",
    revealSpan: "",
    revealIcon: "",
    prefix: "",
    suffix: "",
    description: "",
    icon: "",
    field: {
        height: "30vh",
        selectors: {
            '::-webkit-scrollbar': {
                width: "13px",
                height: "10px",
                backgroundColor: "#F5F5F5"
            },
            '::-webkit-scrollbar-track': {
                backgroundColor: "#F5F5F5",
                boxShadow: "inset 0 0 6px rgba(141, 140, 140, 0.3)",
                borderRadius: "10px"
            },
            '::-webkit-scrollbar-thumb': {
                backgroundColor: "#c7c7c7",
                border: "#888",
                borderRadius: "10px"
            }

        },
    },
    errorMessage: "",
    fieldGroup: "",
    subComponentStyles: {
        label: getLabelStylesCC
    },
    wrapper: ""
};

const getShimmerStyles = (props: IShimmerStyleProps): IShimmerStyles => {
    return {
        shimmerWrapper: [
            {
                backgroundColor: `${theme.palette.themeTertiary}`,
                marginLeft: "5%",
                marginTop: "1%"
            },
        ],
        shimmerGradient: [
            {
                backgroundColor: `${theme.palette.themeTertiary}`,
                backgroundImage:
                    'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #c7e0f4 50%, rgba(255, 255, 255, 0) 100%)',
            },
        ],
    };
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackTokens = { childrenGap: 50 };
const stackTokensNotes = { childrenGap: 15 };
const modalFormStyles: IModalStyles = {
    main: {
        width: isMobile ? '' : '60vw',
        height: '65vh'
    },
    root: {
    },
    scrollableContent: {},
    layer: {},
    keyboardMoveIcon: {},
    keyboardMoveIconContainer: {}
}
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };

export interface INote {
    AccountId: number
    TemplateId: number
    DocumentOnlyId: number
    Note: string
    CreatedByUserName: string
    Visibility: string
    DateTime: string
    Id: number
    FullName: string
}
function getLabelStylesCC(props: ILabelStyleProps): ILabelStyles {
    return {
        root: {
            //color: props.theme.palette.themePrimary,"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif
            fontSize: 14,
            fontFamily: "Segoe UI Web (West European)",
            fontWeight: "bold"
        },
    };
}
function Notes(props: any) {
    const { branding } = useBranding()
    const itemList: INote[] = []
    const emptyNote: INote = {
        AccountId: 0,
        CreatedByUserName: "",
        DateTime: "",
        DocumentOnlyId: 0,
        Id: 0,
        Note: "",
        TemplateId: 0,
        Visibility: "",
        FullName: ""
    }
    const {t} = useTranslation(['common', 'recordnotes']);
    const [listenerLoaded, setListenerLoaded] = useState(false)
    const [historyActivities, setHistoryActivities] = useState([]);
    const [listItems, setlistItems] = useState(itemList)
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [note, setNote] = useState("")
    const [noteSelected, setNoteSelected] = useState(emptyNote)
    const [isDisabled, setIsDisabled] = useState(false)
    const [mQuery, setMQuery] = React.useState<any>({
        matches: window.innerWidth > 639 ? true : false,
    });
    const [isLoading, setIsLoading] = useState(false)
    const [chkPrivate, setChkPrivate] = useState(false);

    const getDateTimeDisplaySpan = (item: any) => {

        var result

        isMobile ?
            result = <span title={item.DateTime !== null ? Helper.getLocateDate(item.DateTime, 2) : ""}>{item.DateTime !== null ? Helper.getLocateDate(item.DateTime, 1) : ""}</span>
            :
            result = <span title={item.DateTime !== null ? Helper.getLocateDate(item.DateTime, 2)  : ""}>{item.DateTime !== null ? Helper.getLocateDate(item.DateTime, 4) : "}"}</span>

        return result
    }

    const panelHeader: HTMLAttributes<HTMLDivElement> = {
        style: {
            fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
            fontWeight: "bolder",
            color: branding.theme.palette.themePrimary,
            fontSize: 20
        }
    }
    
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        itemCellSmall: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                background: palette.white,
            },
        ],
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                selectors: {
                    '&:hover': { background: palette.neutralLight },
                },
                background: palette.white,
            },
        ],
        itemCellAttachment: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',

                background: palette.white,
            },
        ],
        itemImage: {
            flexShrink: 0,
        },
        itemContent: {
            marginLeft: 10,
            overflow: 'hidden',
            flexGrow: 1,
        },
        itemName: [
            fonts.xLarge,
            {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontFamily: "Arial",
                fontWeight: "bold",
                color: branding.theme.palette.themePrimary
            },
        ],
        itemSecondary: {
            fontFamily: "Calibri",
            fontSize: fonts.large.fontSize
        },
        itemIndex: {
            fontSize: fonts.small.fontSize,
            color: palette.neutralTertiary,
            marginBottom: 10,
        },
        itemIndexDate: {
            fontSize: fonts.small.fontSize,
            color: palette.neutralTertiary,
            marginBottom: 10,
            textAlign: "right"
        },
        itemNameSmall: [
            fonts.medium,
            {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontFamily: "Arial",
                fontWeight: "bold",
                color: branding.theme.palette.themePrimary
            },
        ],
        itemSecondarySmall: {
            fontFamily: "Calibri",
            fontSize: fonts.small.fontSize
        },
        itemSecondarySmallHeader: {
            fontFamily: "Calibri",
            fontSize: fonts.small.fontSize,
            color: branding.theme.palette.themePrimary,
            fontWeight: "bold"
        },
        body: {
            width: isMobile ? null : '60vw',
            height: '39vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        buttonArea: {

        },
        callout: {
            maxWidth: 300,
        },
        inner: {
            height: '100%',
            padding: '0 24px 20px',
        },
        pane: {
            maxWidth: '98%',

        },

    });
    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: contentStyles.pane };

    const titleId = useId(props.modalId);
    const columns: IColumn[] = [
        {
            key: 'column3',
            name: t('label.created', { ns: 'recordnotes'}),
            fieldName: 'DateTime',
            minWidth: 100,
            maxWidth: isMobile ? 100 : 200,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordnotes'}),
            sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordnotes'}),
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return getDateTimeDisplaySpan(item);
            },
        },
        {
            key: 'column2',
            name: t('label.username', { ns: 'recordnotes'}),
            fieldName: 'CreatedByUserName',
            minWidth:isMobile ? 90 : 125,
            maxWidth: isMobile ? 90 :150,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordnotes'}),
            sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordnotes'}),
            data: 'string',
            isPadded: true,
            onRender: (item: INote) => {
                return <span title={item.CreatedByUserName.toString()}>{item.FullName}</span>;
            },
        },
        {
            key: 'column1',
            name: t('label.note', { ns: 'recordnotes'}),
            fieldName: 'Note',
            minWidth: 280,
            maxWidth: isMobile ? 0 : 800,
            isRowHeader: true,
            isResizable: false,
            isMultiline: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordnotes'}),
            sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordnotes'}),
            data: 'string',
            isPadded: true,
            onRender: (item: INote) => {
                return <span title={item.Note}>{item.Note}</span>;
            },
        },


        {
            key: 'column4',
            name: t('label.action', { ns: 'recordnotes'}),
            fieldName: '',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            isPadded: true,
            onRender: (item: INote) => {
                return (item.Visibility.trim() === "Private" && props.user === Helper.getUsername()) || (Helper.getUsername() === item.CreatedByUserName) ?
                    <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                        menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                        role="button"
                        aria-haspopup
                        aria-label={t('label.showAction', { ns: 'recordnotes'})}
                        styles={iconListButtonStyles}
                        menuProps={menuProps}
                    /></div>
                    : " "
            },
        }
    ]
    const menuItems: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: t('label.delete', { ns: 'recordnotes'}),
            onClick: () => {
                deleteNote()
                //DeleteUser(userSelected)
            },
        },
    ];
    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    useEffect(() => {
        if (props.showNotes)
            loadNotes()

    }, [props.showNotes])
    

    // useEffect(() => {
    //     if (props.showNotes)
    //         loadNotes()
    //
    // }, [props.documentId])
    const onClose = () => {
        setListenerLoaded(false)
        props.update(0)
        props.hideModalNotes()
        if (isCalloutVisible)
            toggleIsCalloutVisible()
    }
    const onSelectionItemChange = () => {
        const selectionCount = selectionItem.getSelectedCount();
        switch (selectionCount) {
            case 0:
                setNoteSelected(emptyNote)
                break;
            case 1:
                setNoteSelected(selectionItem.getSelection()[0] as INote)
                break;
            default:
                setNoteSelected(emptyNote)
                break;
        }
    }
    const selectionItem = new Selection({
        onSelectionChanged: onSelectionItemChange,
    });
    const getKey = (item: string, index?: number): string => {
        if (item !== undefined)
            return item.toString()

        return ""
    }
    const onChangeNote = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setNote(newValue!)
        },
    );
    async function addNote() {

        setIsDisabled(true)

        const request = {
            DocumentId: props.documentId.toString(),
            Note: note,
            Visibility: chkPrivate ? "Private" : "",
            DocumentNoteId: "",
            Close: false,
            Suspend: false
        };

        let {data}: any = await HttpRequest.postWithSession('DocumentOnlyAddNote', request)
        const response = data

        // var url = "DocumentOnlyAddNote"
        // const result = await fetch(url, {
        //     method: "POST",
        //     headers: await Helper.getHeaders({ 'Content-Type': 'application/json' }),
        //     body: JSON.stringify(data)
        // })
        // const response = await result.json()

        if (response.Success) {
            // var aux: IItemList[]=[]
            // aux = listItems
            // aux.push({
            //     DocumentOnlyListMasterId:0,
            //     Id:0,
            //     Key:keyText,
            //     Value:valueText
            // })

            //setlistItems(aux)
            loadNotes()
            setNote("")
            toggleIsCalloutVisible()
            setIsDisabled(false)
        }
        else {
            console.log(response.ExceptionMessage)
        }

    }
    async function deleteNote() {

        const request = {
            DocumentId: props.documentId,
            DocumentNoteId: noteSelected.Id
        }

        let {data}: any = await HttpRequest.getWithSession('DocumentOnlyDeleteNote', request)
        const response = data
       
        if (response !== undefined) {
            if (response.Success) {
                loadNotes()
            }
            else {
                //
            }
        }

    }
    async function loadNotes() {
        setIsLoading(true)

        const request = {
            DocumentId: props.documentId,
            key: undefined,
        }

        let {data}: any = await HttpRequest.getWithSession('DocumentOnlyNote', request)
        const response = data
        
        if (response !== undefined) {

            var listNote: INote[] = []
            response.forEach((e: any) => {
                var visibility = e.Visibility.trim()

                if (visibility === "Private" && e.CreatedByUserName === Helper.getUsername())
                    listNote.push(e)
                else if (visibility === "")//&& allowAdminAccess())
                    listNote.push(e)
            });

            setlistItems(listNote)
            setIsLoading(false)

            let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneUserlist")) as Element
            if (element !== null) { element.children[1].classList.add("scrollVisible") }
        }

    }

    const openNote = useConstCallback(() => {
        setNote("")
        setIsDisabled(false)
        toggleIsCalloutVisible()
    })
    function onClickCheckPrivate(ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) {

        if (isChecked != undefined)
            setChkPrivate(isChecked)
    }
    const allowAdminAccess = () => {
        if (props.owner ||
            props.coowner ||
            (props.user === Helper.getUsername())) {
            return true
        }
        else {
            return false;
        }
    }
    
    return !isMobile?(
        <Modal
            isOpen={props.showNotes}
            isBlocking={true}
            containerClassName={contentStyles.container}>
            <Stack>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id={titleId}>{getRecordDescription(props.modalTitle,props.documentDescription)}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                    onClick={onClose}
                                    title={t('Common.Button.Close', { ns: 'common'})}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className={contentStyles.buttonArea + " ms-Grid-col ms-sm12 ms-md12 ms-lg12"} style={{ width: 200 }}>
                            <ActionButton id='addNoteButton' title={!isCalloutVisible ? t('title.buttonAdd', { ns: 'recordnotes'}) : t('title.buttonBack', { ns: 'recordnotes'})} text={!isCalloutVisible ? t('text.buttonAdd', { ns: 'recordnotes'}) : t('text.buttonBack', { ns: 'recordnotes'})}
                                iconProps={{ iconName: !isCalloutVisible ? 'Add' : 'ChevronLeft' }} onClick={openNote} />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                {!isLoading ?
                                    !isCalloutVisible ?
                                        <ScrollablePane styles={scrollablePaneStyles} id="ScrollPaneUserlist" key="ScrollPaneUserlist">
                                            {/* <div className="scrollVisible" style={{overflow:"auto", overflowX:"hidden" , maxWidth:"100%", height:"40vh"}}> */}
                                            <DetailsList
                                                items={listItems || []}
                                                compact={false}
                                                columns={columns}
                                                getKey={getKey}
                                                setKey="set"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                isHeaderVisible={true}
                                                selection={selectionItem}
                                                selectionMode={SelectionMode.single}
                                                selectionPreservedOnEmptyClick={false}
                                                enterModalSelectionOnTouch={mQuery.matches}
                                                checkboxVisibility={CheckboxVisibility.hidden}
                                            />
                                            {/* </div> */}
                                        </ScrollablePane>
                                        :
                                        <Stack tokens={stackTokensNotes}>
                                            <TextField id='enterNoteTextArea' styles={textFieldStyle} value={note} onChange={onChangeNote} label={t('label.note', { ns: 'recordnotes'})} title={t('placeholder.note', { ns: 'recordnotes'})} placeholder={t('placeholder.note', { ns: 'recordnotes'})} maxLength={2000} multiline resizable={false} />
                                            <Checkbox boxSide="end" checked={chkPrivate} id="checkDocument" onChange={onClickCheckPrivate} label={t('label.private', { ns: 'recordnotes'})} title={t('title.private', { ns: 'recordnotes'})} />
                                        </Stack>
                                    :
                                    <Stack>
                                        <Label />
                                        <Label />
                                        <Label />
                                        <Spinner size={SpinnerSize.medium} label={t('text.spinner', { ns: 'common'})} ariaLive="assertive" />
                                    </Stack>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                {!isCalloutVisible ?
                                    <DefaultButton onClick={onClose} text={t('Common.Button.Close', { ns: 'common'})} title={t('Common.Button.Close', { ns: 'common'})} />
                                    : <PrimaryButton text={t('Common.Button.Save', { ns: 'common'})} onClick={addNote} id="saveNoteButton" title={t('title.buttonSave', { ns: 'recordnotes'})} disabled={isDisabled} />
                                }
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Stack>
            
        </Modal>
    ):
    (
        <Panel
        headerText={getRecordDescription(props.modalTitle,props.documentDescription)}
        headerTextProps={panelHeader}
        isBlocking={false}
        isOpen={props.showNotes}
        onDismiss={onClose}
        closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common'})}
        type={PanelType.smallFluid}
      >
       <Stack>
            <div className="ms-Grid" dir="ltr">
                <Separator />
                <div className="ms-Grid-row">
                    <div className={contentStyles.buttonArea + " ms-Grid-col ms-sm12 ms-md12 ms-lg12"} style={{ width: 200 }}>
                        <ActionButton title={!isCalloutVisible ? t('title.buttonAdd', { ns: 'recordnotes'}) : t('title.buttonBack', { ns: 'recordnotes'})} text={!isCalloutVisible ? t('text.buttonAdd', { ns: 'recordnotes'}) : t('text.buttonBack', { ns: 'recordnotes'})}
                            iconProps={{ iconName: !isCalloutVisible ? 'Add' : 'ChevronLeft' }} onClick={openNote} />
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.body}>
                            {!isLoading ?
                                !isCalloutVisible ?
                                    <ScrollablePane styles={scrollablePaneStyles} id="ScrollPaneUserlist" key="ScrollPaneUserlist">
                                        {/* <div className="scrollVisible" style={{overflow:"auto", overflowX:"hidden" , maxWidth:"100%", height:"40vh"}}> */}
                                        <DetailsList
                                            items={listItems || []}
                                            compact={false}
                                            columns={isMobile?columns.filter(value=> value.fieldName!=="Note"):columns}
                                            getKey={getKey}
                                            setKey="set"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            selection={selectionItem}
                                            selectionMode={SelectionMode.single}
                                            selectionPreservedOnEmptyClick={false}
                                            enterModalSelectionOnTouch={mQuery.matches}
                                            checkboxVisibility={CheckboxVisibility.hidden}
                                        />
                                        {/* </div> */}
                                    </ScrollablePane>
                                    :
                                    <Stack tokens={stackTokensNotes}>
                                        <TextField styles={textFieldStyle} value={note} onChange={onChangeNote} label={t('label.note', { ns: 'recordnotes'})} title={t('title.note', { ns: 'recordnotes'})} placeholder={t('placeholder.note', { ns: 'recordnotes'})} maxLength={2000} multiline resizable={false} />
                                        <Checkbox boxSide="end" checked={chkPrivate} id="checkDocument" onChange={onClickCheckPrivate} label={t('label.private', { ns: 'recordnotes'})} title={t('title.private', { ns: 'recordnotes'})}/>
                                    </Stack>
                                :
                                <Stack>
                                    <Label />
                                    <Label />
                                    <Label />
                                    <Spinner size={SpinnerSize.medium} label={t('text.spinner', { ns: 'common'})} ariaLive="assertive" />
                                </Stack>
                            }
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <DialogFooter>
                            {!isCalloutVisible ?
                                null
                                : <PrimaryButton text={t('Common.Button.Save', { ns: 'common'})} onClick={addNote} title={t('title.buttonSave', { ns: 'recordnotes'})} disabled={isDisabled} />
                            }
                        </DialogFooter>
                    </div>
                </div>
            </div>
        </Stack>
      </Panel>
    )
}

export default Notes


