import React, { useState } from 'react';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { getTheme, FontWeights, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IconButton, PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { useId } from '@uifabric/react-hooks';
import { Stack } from '@fluentui/react/lib/Stack';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { Shimmer, IShimmerStyleProps, IShimmerStyles } from 'office-ui-fabric-react/lib/Shimmer';
import Helper, { ICreateDocumentRequest, IFormControl } from '../../Helpers/Helper';
import '../../Custom.css';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import { Separator } from '@fluentui/react/lib/Separator';
import {IModalProps, Modal, IDragOptions, IModalStyles} from "@fluentui/react/lib/Modal"
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";




const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const getShimmerStyles = (props: IShimmerStyleProps): IShimmerStyles => {
    return {
        shimmerWrapper: [
            {
                backgroundColor: `${theme.palette.themeTertiary}`,
                marginLeft: "5%",
                marginTop: "1%"
            },
        ],
        shimmerGradient: [
            {
                backgroundColor: `${theme.palette.themeTertiary}`,
                backgroundImage:
                    'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #c7e0f4 50%, rgba(255, 255, 255, 0) 100%)',
            },
        ],
    };
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackTokens = { childrenGap: 50 };
const modalFormStyles: IModalStyles ={
    main:{
      width:'80vw',
      height:'100vh'
    },
    root:{
      
    },
    scrollableContent:{},
    layer:{},
    keyboardMoveIcon:{},
    keyboardMoveIconContainer:{}
  }

function Form(props: any) {
    const { branding } = useBranding()
    const [listenerLoaded, setListenerLoaded] = useState(false)

    const titleId = useId(props.modalId);

    const setListener = () => {
        if (!listenerLoaded) {
            const els = document.getElementById("inputForm")!.getElementsByTagName("SELECT")
            console.log(els)
            for (var i = 0; i < els.length; i++) {
                console.log(els?.item(i)?.id!)
                document.getElementById(els?.item(i)?.id!)!.addEventListener("change", changeDropdownFunction)
            }
            document.getElementById("inputForm")!.addEventListener("submit", function (e) {
                e.preventDefault();
                createDocument(this)
            });
            setListenerLoaded(true)
        }
    }

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '100%',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const changeDropdownFunction = async (param: any) => {

        var elements = document.getElementById("inputForm")!.getElementsByTagName("SELECT");
        console.log(elements)
        var child = null;
        for (var i = 0; i < elements.length; i++) {
            console.log(elements?.item(i))
            if (elements?.item(i)?.getAttribute("parentcc") == "cl_ddl_" + param.target.id) {
                child = document.getElementById(elements?.item(i)?.id!)
                break;
            }
        }

        if (child != null && child != undefined) {
            for (let j = (child.children.length - 1); j > 0; j--) {
                child.removeChild(child.children[j])
            }

            const request = {
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                UserName: Helper.getUsername(),
                ListId: child?.getAttribute("listid")!,
                ColIndex: child?.getAttribute("col-index")!,
                FilterValue: param.target.value,
                ParentColIndex: child?.getAttribute("parentcol-index")!
            }

            let {data}: any = await HttpRequest.getWithSession('gettemplatechildlist', request)
            const response = data

            if (response != null) {
                for (var i = 0; i < response.length; i++) {
                    var val = response[i].Value;
                    var el = document.createElement("option");
                    el.textContent = val;
                    el.value = val;
                    child.appendChild(el);
                }
            }
        }
    }

    function getFormValues(el: HTMLElement): IFormControl[] {
        var obj: IFormControl[] = [];

        var elements = el.getElementsByTagName("INPUT");
        for (var i = 0; i < elements.length; i++) {
            obj.push({
                Key: elements?.item(i)?.id!,
                Value: (elements?.item(i) as HTMLInputElement).value
            })
        }

        elements = el.getElementsByTagName("SELECT");
        for (var i = 0; i < elements.length; i++) {
            obj.push({
                Key: elements?.item(i)?.id!,
                Value: (elements?.item(i) as HTMLSelectElement).value
            })
        }

        return obj
    }

    const createDocument = async (el: HTMLElement) => {
        //if (validateForm()) {
        if (props.documentId !== -1) {
            props.setSpinnerText("Please wait, updating document...")
        } else {
            props.setSpinnerText("Please wait, creating document...")
        }
        props.setLayoutLoaded(false)
        const formData = getFormValues(el)

        const site = await Helper.getSite()!
        const sessionKey = Helper.getSessionKey()
        const accountId = Helper.getDefaultAccount()
        const userName = Helper.getUsername()
        

        //let documentId = ""
        //documentId = (props.documentId != undefined || props.documentId != null) ? props.documentId : ""

        const request: ICreateDocumentRequest = {
            UserName: userName,
            SK: sessionKey,
            AccountId: accountId,
            TemplateId: props.modalId.toString(),
            Content: formData,
            Path: site,
            TargetCulture: "en-US",
            Autoprovision: true,
            DocumentId: props.documentId.toString(),
            Lists: "",
            TemplateVersion: "1",
            TemplateXML: "",
            onBehalf: "",
            DocumentonlyPrimaryId: "-1"
        }

        let {data}: any = await HttpRequest.postWithSession('CreateDocument', request)
        const result = data

        if (result.Success) {
            props.update(result.Id)
            onClose()
        }
       // }
    } 

    const onClose = () => {
        setListenerLoaded(false)
        props.hideModal()
    }

    return (
        <Modal
            isOpen={props.showModal}
            isBlocking={true}
            containerClassName={contentStyles.container}
            styles={modalFormStyles}
            >
            {props.layoutLoaded ?(
             <Stack>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id={titleId}>{props.modalTitle}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                        onClick={onClose}
                                        title="Close."
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                <ScrollablePane>
                                        <div
                                            onMouseMove={setListener}
                                            dangerouslySetInnerHTML={{ __html: props.layout }} />
                                </ScrollablePane> 
                            </div>
                        </div>
                    </div>
                        <div className="ms-Grid-row" style={{bottom:"10px", right:"12px",position:"relative"}}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton form="inputForm" type="Submit" text="Submit" title="Generate document." />
                                <DefaultButton onClick={onClose} text="Cancel" title="Close." />
                            </DialogFooter>
                            </div>
                        </div>
                </div>
            </Stack>
        ):
        <Stack tokens={stackTokens}>
            <label />
            <label />
            <label />
            <Spinner size={SpinnerSize.large} label={props.spinnerText} />
        </Stack>
        }
        </Modal>
    );
}

export default Form


