import React, { useEffect } from 'react';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { useBoolean } from '@uifabric/react-hooks';
import { IButtonProps, IconButton } from '@fluentui/react/lib/Button';
import { getTheme } from '@fluentui/react/lib/Styling';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { useTranslation } from 'react-i18next'
import { useBranding } from '../../hooks/useBranding';


const theme = getTheme();

function HelpComponentRelative(props: any) {
    const { branding } = useBranding()
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
    const { t } = useTranslation('common');
    const PrimaryButtonProps: IButtonProps = React.useMemo(
        () => ({
            children: t("Common.Button.See more"),
            onClick: goToHelp,
        }),
        [],
    );


    const iconButtonStyles = {
        root: {
            marginLeft: 'auto',
            marginRight: '2px',
        },
    };
    const helpIcon: IIconProps = { iconName: 'Help' };

    function showHelp() {
        toggleTeachingBubbleVisible()
    }

    function goToHelp() {
        if (props.location === undefined
            || props.location === null
            || props.location === "") {
            window.open(`${branding.helpPath}`, '_blank');
        } else {
            window.open(`${branding.helpPath}#${props.location}`, '_blank');
        }

    }

    useEffect(() => {
        setTimeout(function () {
            const e = document.getElementsByClassName("indexTabHelp") as HTMLCollectionOf<HTMLElement>;
            for (let i = 0; i < e.length; i++) {
                e[i].tabIndex = 1;
            }
        }, 500);
    }, [])

    return (
        <>
            <IconButton
                className="indexTabHelp"
                id={props.target ?? "help"}
                styles={iconButtonStyles}
                iconProps={helpIcon}
                ariaLabel={t("Common.Button.Help")}
                onClick={showHelp}
                title={t("Common.Button.Help")}
            />
            {teachingBubbleVisible && (
                <TeachingBubble
                    target={"#" + (props.target ?? "help")}
                    headline={props.headline}
                    hasCloseButton
                    closeButtonAriaLabel={t("Common.Button.Close")}
                    primaryButtonProps={sessionStorage.getItem("entityId") === "Not In Teams" ? PrimaryButtonProps : undefined}
                    onDismiss={toggleTeachingBubbleVisible}
                >
                    <span style={{ whiteSpace: "pre-line" }}> {props.text}</span>
                </TeachingBubble>
            )}
        </>
    );
}

export default HelpComponentRelative

