import React, { useState, useEffect } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import Helper, { IDocumentOnlyAudienceDetail, IRequest, IDMerchantRequest } from '../../Helpers/Helper';
import { DetailsListLayoutMode, IContextualMenuItem, IContextualMenuProps, SelectionMode, Toggle } from '@fluentui/react';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles, IButtonProps } from '@fluentui/react/lib/Button';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField } from '@fluentui/react/lib/TextField';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { setGlobalDateLocale } from '../../Helpers/DateUtils';
import {useBranding} from "../../hooks/useBranding";
import MerchantService, {ICreateUpdateRequest, IDeleteRequest, IGetAllMerchantResponse} from "../../services/Merchant";

const stackTokens = { childrenGap: 10 };
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

enum ModalType {
    None = 0,
    Add = 1,
    Edit = 2,
    Delete = 3
}

function Merchant(props: any) {
    const { branding } = useBranding()

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
                class: 'ms-Icon ms-Icon--Mail'
            }
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        bodyDelete: {
            width: '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        body: {
            width: '45vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyEdit: {
            width: '50vw',
            height: '60vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyVWOverride: {
            width: '90%',

            flex: '4 4 auto',
            padding: '0 0px 0px 18px',
        },
        bodyEditVWOverride: {
            width: '70vw',

            flex: '4 4 auto',
            padding: '0 18px 0px 18px',
        },
        User: {
            width: '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const emptyMerchant: IGetAllMerchantResponse = {
        Id: 0,
        MerchantId: "",
        Active: false,
        Comment: "",
        ContactEmailAddress: "",
        ContactEmailAddressSecondary: "",
        ContactFirstName: "",
        ContactLastName: "",
        ContactPhoneNumber: "",
        ContactPhoneNumberSecondary: "",
        OrganizationAddress: "",
        OrganizationCity: "",
        OrganizationCountry: "",
        OrganizationEIN: "",
        OrganizationName: "",
        OrganizationPostalCode: "",
        OrganizationState: "",
        Created: new Date()
    };

    const [merchantList, setMerchantList] = useState<IGetAllMerchantResponse[]>([]);
    const [selectedMerchant, setSelectedMerchant] = useState<IGetAllMerchantResponse>(emptyMerchant);
    const [showAddEditMerchantModal, setShowAddEditMerchantModal] = useState<boolean>(false)
    const [showDeleteMerchantModal, setShowDeleteMerchantModal] = useState(false)
    const [modalType, setModalType] = useState<ModalType>(ModalType.None)


    const [merchantId, setMerchantId] = useState("");
    const [active, setActive] = useState(false);
    const [comment, setComment] = useState("");
    const [contactEmailAddress, setContactEmailAddress] = useState("");
    const [contactEmailAddressSecondary, setContactEmailAddressSecondary] = useState("");
    const [contactFirstName, setContactFirstName] = useState("");
    const [contactLastName, setContactLastName] = useState("");
    const [contactPhoneNumber, setContactPhoneNumber] = useState("");
    const [contactPhoneNumberSecondary, setContactPhoneNumberSecondary] = useState("");
    const [organizationAddress, setOrganizationAddress] = useState("");
    const [organizationCity, setOrganizationCity] = useState("");
    const [organizationCountry, setOrganizationCountry] = useState("");
    const [organizationEIN, setOrganizationEIN] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationPostalCode, setOrganizationPostalCode] = useState("");
    const [organizationState, setOrganizationState] = useState("");
    const [showMessageError, setshowMessageError] = useState(false)
    const [message, setMessage] = useState("");

    const [applying, setApplying] = useState(false);

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Organization Name',
            fieldName: 'OrganizationName',
            minWidth: 150,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true
        },
        {
            key: 'column2',
            name: 'Contact Phone',
            fieldName: 'ContactPhoneNumber',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
        },
        {
            key: 'column3',
            name: 'Contact Email',
            fieldName: 'ContactEmailAddress',
            minWidth: 225,
            maxWidth: 225,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true
        },
        {
            key: 'column4',
            name: 'Contact ',
            fieldName: 'ContactFirstName',
            minWidth: 225,
            maxWidth: 225,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IGetAllMerchantResponse) => {
                return item.ContactFirstName ?
                    <a style={{ cursor: 'pointer' }}
                        title={item.ContactFirstName + " " + item.ContactLastName} >
                        <div style={{ width: "100%", height: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {item.ContactFirstName + " " + item.ContactLastName}</div></a>
                    : null;
            },
        },
        {
            key: 'column5',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    title={"Click to select from available actions."}
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={menuProps}
                /></div>
            },
            isPadded: true
        },
    ]

    const menuItems: IContextualMenuItem[] = [
        /*{
            key: 'delete',
            text: 'Delete',
            title: 'Delete merchant.',
            onClick: () => {
                setShowDeleteMerchantModal(true)
                setModalType(ModalType.Delete)
                selectionMerchant.setAllSelected(false)
            },
        },*/
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit merchant.',
            onClick: () => {

                setShowAddEditMerchantModal(true)

                setMerchantId(selectedMerchant.MerchantId)
                setActive(selectedMerchant.Active);
                setComment(selectedMerchant.Comment);
                setContactEmailAddress(selectedMerchant.ContactEmailAddress);
                setContactEmailAddressSecondary(selectedMerchant.ContactEmailAddressSecondary);
                setContactFirstName(selectedMerchant.ContactFirstName);
                setContactLastName(selectedMerchant.ContactLastName);
                setContactPhoneNumber(selectedMerchant.ContactPhoneNumber);
                setContactPhoneNumberSecondary(selectedMerchant.ContactPhoneNumberSecondary);
                setOrganizationAddress(selectedMerchant.OrganizationAddress);
                setOrganizationCity(selectedMerchant.OrganizationCity);
                setOrganizationCountry(selectedMerchant.OrganizationCountry);
                setOrganizationEIN(selectedMerchant.OrganizationEIN);
                setOrganizationName(selectedMerchant.OrganizationName);
                setOrganizationPostalCode(selectedMerchant.OrganizationPostalCode);
                setOrganizationState(selectedMerchant.OrganizationState);
                setModalType(ModalType.Edit)
            },
        }
    ];

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    const PrimaryButtonProps: IButtonProps = React.useMemo(
        () => ({
            children: 'More info',
            onClick: goToHelp,
        }),
        [],
    );

    useEffect(() => {
        setGlobalDateLocale(navigator.language)

        if (merchantList.length == 0) {
            setApplying(true)
            setMerchantList([])
            loadMerchant();
        }
    }, [])

    const onSelectionChange = () => {
        const selectionCount = selectionMerchant.getSelectedCount();
        switch (selectionCount) {
            case 0:
                setSelectedMerchant(emptyMerchant)
                break;
            case 1:
                setSelectedMerchant(selectionMerchant.getSelection()[0] as IGetAllMerchantResponse)
                break;
            default:
                setSelectedMerchant(emptyMerchant)
                break;
        }
    }

    const selectionMerchant = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    const loadMerchant = async () => {
        
        await MerchantService.GetAllMerchant()
            .then((data)=> {
                setMerchantList(data.filter(s => s.Active == true))
                setApplying(false)
            })
            .catch()
            .finally()
      
    }

    const getKey = (item: IGetAllMerchantResponse, index?: number): string => {
        if (item !== undefined)
            return item.Id!.toString()
        return ""
    }

    const addMerchant = () => {
        setShowAddEditMerchantModal(true)
        setModalType(ModalType.Add)
        selectionMerchant.setAllSelected(false)
        setMerchantId("")
    }

    const deleteMerchant = async () => {
        
        const request:IDeleteRequest = {
            Id: selectedMerchant.Id,
            Operation: "delete"
        }
        
        await MerchantService.Delete(request)
            .then(()=> {
                setMerchantList([])
                loadMerchant()
                hideDeleteMerchantModal()
            })
            .catch()
            .finally()
    }

    const hideAddEditMerchantModal = () => {
        setShowAddEditMerchantModal(false)
    }

    const hideDeleteMerchantModal = () => {
        setShowDeleteMerchantModal(false)
        resetForm()
    }

    const resetForm = () => {
        setSelectedMerchant(emptyMerchant)
        setModalType(ModalType.None)

        setMerchantId("")
        setActive(false);
        setComment("");
        setContactEmailAddress("");
        setContactEmailAddressSecondary("");
        setContactFirstName("");
        setContactLastName("");
        setContactPhoneNumber("");
        setContactPhoneNumberSecondary("");
        setOrganizationAddress("");
        setOrganizationCity("");
        setOrganizationCountry("");
        setOrganizationEIN("");
        setOrganizationName("");
        setOrganizationPostalCode("");
        setOrganizationState("");

        setApplying(false);
    }

    const getErrorMessage = (value: string, message: string): string => {
        if (value.length > 0 && value != "0") {
            return ""
        }
        else {
            return message;
        }
    };
    const getErrorMessageEmail = (value: string): string => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length > 0) {
            if (!value.match(mailformat)) {

                return "Email must be valid.";
            }
        }
        return ""
    };

    const getErrorMessagePhone = (value: string): string => {
        var string = value.replace(/[^0-9]/g, '')
        if (string.length == 0 || string == "0" || string.length > 10) {

            return "The phone number must be valid.";
        }
        return ""
    };


    async function addEditMerchant() {
        setApplying(true)

        const request: ICreateUpdateRequest = {
            Id: modalType == ModalType.Add ? 0 : selectedMerchant.Id,
            MerchantId: merchantId,
            Active: active,
            Comment: comment,
            ContactEmailAddress: contactEmailAddress,
            ContactEmailAddressSecondary: contactEmailAddressSecondary,
            ContactFirstName: contactFirstName,
            ContactLastName: contactLastName,
            ContactPhoneNumber: contactPhoneNumber,
            ContactPhoneNumberSecondary: contactPhoneNumberSecondary,
            OrganizationAddress: organizationAddress,
            OrganizationCity: organizationCity,
            OrganizationCountry: organizationCountry,
            OrganizationEIN: organizationEIN,
            OrganizationName: organizationName,
            OrganizationPostalCode: organizationPostalCode,
            OrganizationState: organizationState,
            Operation: modalType == ModalType.Add ? "create" : "update",
        }

        await MerchantService.CreateUpdate(request)
            .then(() => {
                setMessage("Added  item successfully")
                loadMerchant()
                setTimeout(function () {
                    hideAddEditMerchantModal()
                }, 3000);
            })
            .catch((error)=> {
                setshowMessageError(true)
                setMessage("An error occurred: " + Helper.GetErrorMessage(error))
                setTimeout(function () {
                    setshowMessageError(false)
                }, 5000);
            })
            .finally(()=> {
                setApplying(false)
            })
    }

    function goToHelp() {
        window.open(`${branding.helpPath}`, '_blank');
    }

    const validForm = () => {


        if (modalType === ModalType.Add) {
            if (comment.length > 0 &&
                contactEmailAddress.length > 0 &&
                contactEmailAddressSecondary.length > 0 &&
                contactFirstName.length > 0 &&
                contactLastName.length > 0 &&
                contactPhoneNumber.length > 0 &&
                contactPhoneNumberSecondary.length > 0 &&
                organizationAddress.length > 0 &&
                organizationCity.length > 0 &&
                organizationCountry.length > 0 &&
                organizationEIN.length > 0 &&
                organizationName.length > 0 &&
                organizationPostalCode.length > 0 &&
                organizationState.length > 0) {
                return true;
            }
        }
        else if (modalType === ModalType.Edit) {

            if (merchantId.length > 0 &&
                comment.length > 0 &&
                contactEmailAddress.length > 0 &&
                contactEmailAddressSecondary.length > 0 &&
                contactFirstName.length > 0 &&
                contactLastName.length > 0 &&
                contactPhoneNumber.length > 0 &&
                contactPhoneNumberSecondary.length > 0 &&
                organizationAddress.length > 0 &&
                organizationCity.length > 0 &&
                organizationCountry.length > 0 &&
                organizationEIN.length > 0 &&
                organizationName.length > 0 &&
                organizationPostalCode.length > 0 &&
                organizationState.length > 0) {
                return true;
            }
        }
        else {
            return false
        }
    }

    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <label className="ms-fontSize-18">Merchants</label><br />
                        <div>Form, document and records management all-in-one.<br />(There is usually only one Merchant and this is the relationship between Kim Technologies and the Payment Provider on behalf of {branding.appName}.  The information that is collected on this page relates to Kim Technologies {branding.appName} application for the Payment Provider to collect funds from {branding.appName} subscribers.)</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <label className="ms-fontSize-18">Merchants</label>
                    </div>
                </div>
                : null
            }

            <Stack tokens={stackTokens}>
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {/*<ActionButton title="Add new merchant." text="Add Merchant"
                            iconProps={{ iconName: 'Add' }} onClick={addMerchant} />*/}
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                    {!applying ?
                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", paddingRight: "10px", maxWidth: "100%", height: "40vh" }}>
                            <DetailsList
                                items={merchantList}
                                compact={false}
                                columns={columns}
                                getKey={getKey}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                selection={selectionMerchant}
                                selectionMode={SelectionMode.single}
                                selectionPreservedOnEmptyClick={false}
                                enterModalSelectionOnTouch={true}
                                checkboxVisibility={CheckboxVisibility.hidden}
                            />
                        </div>
                        : <Spinner size={SpinnerSize.medium} label="Loading data, please wait..." ariaLive="assertive" />
                    }
                </div>
            </Stack>
            <Modal isOpen={showAddEditMerchantModal} isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Create">{modalType == ModalType.Add ? "New Merchant" : "Edit Merchant"}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideAddEditMerchantModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.body}>
                                    {!applying ?
                                        <div key="grid" className="ms-Grid" dir="ltr">
                                            <div key="row1" className="ms-Grid-row">
                                                <div key="col1" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Merchant" maxLength={200} onGetErrorMessage={(val) => getErrorMessage(val, "Transaction Length must not be 0.")} required onChange={(_, v) => { setMerchantId(v!); }} value={merchantId!.toString()} title="The merchant  is necessary to specify the integration of the payment provider" />
                                                </div>
                                            </div>
                                            <div key="row2" className="ms-Grid-row">
                                                <div key="col1" className="ms-Grid-col ms-sm4">
                                                    <Toggle label="Active" checked={active} onText="Active" offText="Inactive" onChange={(_, v) => { setActive(v!); }} title="Merchant is active" />
                                                </div>
                                                <div key="col2" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization Name" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationName(v!); }} value={organizationName} title="Organization name." />
                                                </div>
                                                <div key="col3" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Comment" maxLength={100} onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setComment(v!); }} value={comment} title="Comment." />
                                                </div>
                                            </div>
                                            <div key="row3" className="ms-Grid-row">
                                                <div key="col1" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Contact First Name" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setContactFirstName(v!); }} value={contactFirstName} title="Contact first name." />
                                                </div>
                                                <div key="col2" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Contact Last Name" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setContactLastName(v!); }} value={contactLastName} title="Contact last name." />
                                                </div>
                                                <div key="col3" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Contact Phone Number" type="text" onGetErrorMessage={getErrorMessagePhone} required onChange={(_, v) => { setContactPhoneNumber(v!); }} value={contactPhoneNumber} title="Contact phone number." />
                                                </div>
                                            </div>
                                            <div key="row4" className="ms-Grid-row">
                                                <div key="col1" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Contact Phone Number Secondary" type="text" onGetErrorMessage={getErrorMessagePhone} onChange={(_, v) => { setContactPhoneNumberSecondary(v!); }} value={contactPhoneNumberSecondary} title="Contact phone number secondary." />
                                                </div>
                                                <div key="col2" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Contact Email Address" type="text" maxLength={100} onGetErrorMessage={getErrorMessageEmail} required onChange={(_, v) => { setContactEmailAddress(v!); }} value={contactEmailAddress} title="Contact email address." />
                                                </div>
                                                <div key="col3" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Contact Email Address Secondary" type="text" onGetErrorMessage={getErrorMessageEmail} required onChange={(_, v) => { setContactEmailAddressSecondary(v!); }} value={contactEmailAddressSecondary} title="Contact email address secondary." />
                                                </div>
                                            </div>
                                            <div key="row5" className="ms-Grid-row">
                                                <div key="col1" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization Country" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationCountry(v!); }} value={organizationCountry} title="Organization country." />
                                                </div>
                                                <div key="col2" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization City" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationCity(v!); }} value={organizationCity} title="Organization city." />
                                                </div>
                                                <div key="col3" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization State" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationState(v!); }} value={organizationState} title="Organization state." />
                                                </div>
                                            </div>
                                            <div key="row6" className="ms-Grid-row">
                                                <div key="col1" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization Address" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationAddress(v!); }} value={organizationAddress} title="Organization address." />
                                                </div>
                                                <div key="col2" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization Postal Code" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationPostalCode(v!); }} value={organizationPostalCode} title="Organization postal code." />
                                                </div>
                                                <div key="col3" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization EIN" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationEIN(v!); }} value={organizationEIN} title="Organization EIN." />
                                                </div>
                                            </div>

                                        </div>
                                        : <Spinner size={SpinnerSize.medium} label="Applying changes..." ariaLive="assertive" />}
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                <DialogFooter>
                                    <PrimaryButton onClick={addEditMerchant} disabled={!validForm()} text={modalType == ModalType.Add ? "Add" : "Update"} title={modalType == ModalType.Add ? "Create merchant." : "Update merchant."} />
                                    <DefaultButton onClick={hideAddEditMerchantModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>

            <Modal isOpen={showDeleteMerchantModal} isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Delete">Delete Merchant</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeleteMerchantModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div>
                                    Are you sure you want to delete merchant?
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={deleteMerchant} text="Delete" title="Delete merchant." />
                                    <DefaultButton onClick={hideDeleteMerchantModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>
        </>
    );
}

export default Merchant