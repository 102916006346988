import React, { } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import TeamsBaseComponent, { ITeamsBaseComponentState } from "msteams-react-base-component";
import Helper from '../Helpers/Helper';
import AppMain from './AppMain';
import Provider from "./Provider";
export interface IInitComponent extends ITeamsBaseComponentState {
    entityId?: string;
    isLoaded: boolean
    isLoggedIn: boolean;
    isCookieSetUp: boolean;
}

export class InitComponent extends TeamsBaseComponent<any, IInitComponent> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLoaded: false, theme: {
                siteVariables: {
                    fontSizes: {}
                },
                componentStyles: {},
                componentVariables: {},
                animations: {},
                fontFaces: [],
                staticStyles: [],
            }, entityId: "", isLoggedIn: false,
            isCookieSetUp: false
        };
    }


    public async componentDidMount() {
        this.updateTheme(this.getQueryVariable("theme"));
        var userRegistration = sessionStorage.getItem("userRegistration")
        sessionStorage.setItem("tempAdvanceSearch", "")
        sessionStorage.setItem("DocuSignToken", "")

        if (await this.inTeams()) {


            this.inTeams()
                .then(x => {
                    if (x) {
                        microsoftTeams.initialize();
                        microsoftTeams.registerOnThemeChangeHandler(this.updateTheme);
                        //TODO: don't store or log the token, call get token everytime the token is needed
                        var authTokenRequest:microsoftTeams.authentication.AuthTokenRequest = {
                            successCallback: async (result:string) => { 
                                await Helper.teamsLogin();
                                microsoftTeams.getContext((context) => {
                                    if (context !== undefined && context.userPrincipalName !== undefined) {
                                        sessionStorage.setItem("username", context.userPrincipalName.toString())
                                        sessionStorage.setItem("entityId", context.entityId)
                                        this.setState({
                                            ...this.state,
                                            isCookieSetUp: true,
                                            isLoaded: true,
                                            isLoggedIn: true
                                        });
                                    }
                                });
                                
                                console.log("Success: " + result); },
                            failureCallback: function (error: string) { console.log("Error getting token: " + error); },
                            resources: []
                        };
                        microsoftTeams.authentication.getAuthToken(authTokenRequest);
                        //TODO: call /teamslogin with bearer token
                        
                    }

                    else {

                        if (userRegistration !== null && userRegistration !== "") {
                            console.log("user Registration")
                            sessionStorage.setItem("username", userRegistration!)
                            sessionStorage.setItem("entityId", "Not In Teams")
                            sessionStorage.setItem("userRegistration", "");
                            this.setState({
                                ...this.state,
                                isLoaded: true,
                                isLoggedIn: false
                            });
                        }
                        else {
                            this.setState({
                                ...this.state,
                                isLoaded: true,
                                isLoggedIn: false
                            });
                        }
                    }
                })
        }
        else if (userRegistration !== null && userRegistration !== "") {
            sessionStorage.setItem("username", userRegistration!)
            sessionStorage.setItem("entityId", "Not In Teams")
            sessionStorage.setItem("userRegistration", "");
            this.setState({
                ...this.state,
                isLoaded: true,
                isLoggedIn: false
            });
        }
        else {
            this.setState({
                ...this.state,
                isLoaded: true,
                isLoggedIn: false
            });
        }

    }

    /**
     * The render() method to create the UI of the tab
     */
    public render() {
        if (this.state.isLoaded) {
            return (
                <Provider>
                    <AppMain isLoggedIn={this.state.isLoggedIn} isCookieSetUp={this.state.isCookieSetUp}/>
                </Provider>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }
}
