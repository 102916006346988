import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { useDocument } from '../../hooks/useDocument';
import UserService, { IUpdateuserProfileRequest } from '../../services/User';
import useAuth from '../../hooks/useAuth';
import { RHFTextBoxControl } from '../RHFControls/RHFTextBoxControl';
import { useForm } from 'react-hook-form';
import { IFormControl } from '../../Helpers/Helper';
type Props = {
    setLoading: (value: boolean) => void
    close: () => void
    reloadForm: () => void
}
type Form = {
    FirstName: string
    LastName: string
    Email: string
}
export const UpdateFormProfile = ({ reloadForm, close }: Props) => {

    const { t } = useTranslation(['common', 'profile'])
    const [isLoading, setIsLoading] = useState(true)
    const [isProfileUpdated, setIsProfileUpdated] = useState(false)
    const { user } = useAuth()
    const { profile, setProfile } = useDocument()
    const form = useForm<Form>({
        defaultValues: {
            Email: user.email ?? '',
            FirstName: '',
            LastName: ''
        }
    })

    useEffect(() => {
        if (!profile?.Email) {
            return
        }
        UserService.GetUserProfile({
            UserName: user?.email,
            Profile: profile?.Email
        }).then((response) => {
            form.reset(response as Form)
            setIsLoading(false)
        })
    }, [form, profile, user])

    const updateProfile = (data: Form) => {
        if (!profile?.Email) {
            return
        }
        const request: IUpdateuserProfileRequest = {
            Profile: [{
                "UserName": profile?.Email,
                "ProfileInformation": [
                    {
                        "Key": "givenName",
                        "Value": data.FirstName
                    },

                    {
                        "Key": "sn",
                        "Value": data.LastName
                    },
                ]
            }]
        }
        setIsLoading(true)
        UserService.UpdateUserProfile(request).then(async (response) => {
            await setProfile({
                Email: data.Email,
                FirstName: data.FirstName,
                LastName: data.LastName,
                AdminBilling: false,
                Billing: false,
                Language: 'en',
                MiddleName: ''
            })
            setIsProfileUpdated(true)
            //setReloadForm(true)
            //setLoading(false)
            //window.location.reload()
            //integrationReload()
        })
    }
    useEffect(() => {
        if (isProfileUpdated) {
            reloadForm()
            close()
        }
    }, [isProfileUpdated])

    if (isLoading) {
        return <Spinner size={SpinnerSize.medium} ariaLive="assertive" />
    }

    return (
        <form onSubmit={form.handleSubmit(updateProfile)}>
            <Stack className={contentStyles.form}>
                <RHFTextBoxControl
                    id={"FirstName"}

                    label={t('label.firstName', { ns: 'profile' })}
                    ariaLabel={t('title.firstName', { ns: 'profile' })}
                    placeholder={t('title.firstName', { ns: 'profile' })}
                    title={t('title.firstName', { ns: 'profile' })}

                    control={form.control}
                    setValue={form.getValues}
                    trigger={form.trigger}
                    getValues={form.getValues}

                    maxLength={75}
                    rules={{
                        required: t('text.fieldRequired', { ns: 'common' }),
                        maxLength: 75
                    }}

                    required
                    autoFocus={true}
                />
                <RHFTextBoxControl
                    id={"LastName"}

                    label={t('label.lastName', { ns: 'profile' })}
                    placeholder={t('title.lastName', { ns: 'profile' })}
                    ariaLabel={t('title.lastName', { ns: 'profile' })}
                    title={t('title.lastName', { ns: 'profile' })}

                    control={form.control}
                    setValue={form.getValues}
                    trigger={form.trigger}
                    getValues={form.getValues}

                    maxLength={50}
                    rules={{
                        required: t('text.fieldRequired', { ns: 'common' }),
                        maxLength: 50
                    }}
                    required
                />
                <div className={contentStyles.button}>
                    <PrimaryButton type="submit" text={t('Common.Button.Save', { ns: 'common' })} onClick={() => { }} />
                </div>
            </Stack>
        </form>
    )
}

const contentStyles = mergeStyleSets({
    form: {
        gap: '0.5rem'
    },
    button: {
        display: 'flex',
        justifyContent: 'right'
    }
})