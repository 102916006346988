import { Checkbox, IButtonStyles, IColumn, IconButton, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react"
import React from 'react'
const iconButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };

interface Attachment {
    name: string
    description?: string
    maxSize: number
    order: number
    doc: boolean
    pdf: boolean
    xls: boolean
    rtf: boolean
    image: boolean
    isRequired: boolean
}
const MenuItems = ({ attachment, deleteAttachment,openForm }: any) => {

    const items: IContextualMenuItem[] = [
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit attachment.',
            onClick: () => {
                openForm(attachment)
            },
        },
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete attachment.',
            onClick: () => {
                deleteAttachment(attachment?.id)
            },
        },
    ]
    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: items
    };
    return (
        <>
            <IconButton
                menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424', textAlign: 'right' } }}
                role="button"
                aria-haspopup
                aria-label="Show actions"
                styles={iconButtonStyles}
                menuProps={menuProps}
                title={"Click to select from available actions."}
            />
        </>

    )
}
const renderColumns = ({ deleteAttachment,openForm }: any): IColumn[] => {

    return [
        {
            key: 'columnOrder',
            name: '#',
            fieldName: 'count',
            minWidth: 16,
            maxWidth: 16,
        },
        {
            key: 'columnName',
            name: 'Name',
            fieldName: 'name',
            minWidth: 80,
            maxWidth: 120,
            isResizable:true
        },

        {
            key: 'columnDesc',
            name: 'Description',
            fieldName: 'description',
            minWidth: 80,
            maxWidth: 120,
            isCollapsible:true,
            isResizable:true
            
        },
        {
            key: 'columnRequired',
            name: 'Is Required',
            fieldName: 'required',
            minWidth: 60,
            maxWidth: 80,
            isResizable:true,
            onRender: (item) => {
                return item.required ?
                    <Checkbox disabled={true} checked title="Is required." /> :
                    <Checkbox disabled={true} checked={false} title="Is not required." />;
            }
        },
        {
            key: 'formats',
            name: 'Formats Allowed',
            minWidth: 80,
            maxWidth: 100,
            isCollapsible:true,
            isResizable:true,
            fieldName:'supportedTypes'
        },
        {
            key: 'action',
            name: 'Actions',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item: Attachment) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}>
                    <MenuItems attachment={item} deleteAttachment={deleteAttachment} openForm={openForm} />
                </div>
            }
        },
    ]
}

export {  renderColumns }
