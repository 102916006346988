import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    CommandButton,
    DefaultButton,
    FontIcon,
    GroupedList,
    IGroup,
    Image,
    Link,
    MessageBar,
    SearchBox,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem,
    Text
} from "@fluentui/react";
import useAuth from "../../hooks/useAuth";
import {useBranding} from "../../hooks/useBranding";
import {
    brandingStyles,
    buttonStyles,
    commandButtonStyles,
    compareFn,
    ESort,
    IApplication,
    iconEmptystyles,
    ITemplateItem,
    menuProps,
    messageBarStyles,
    onRenderCell,
    onRenderHeader,
    searchBoxStyles,
    spinnerLoadingStyles,
    stackContent,
    stackEmpty,
    stackLoading,
    topBarStyles
} from "./index";
import {FontWeights} from "@fluentui/react/lib/Styling";
import {cloneDeep} from 'lodash';
import Helper, { IHttpClientResponse } from "../../Helpers/Helper";
import {ExternalLinkService} from "./ExternalLinkAccess.services";
import { IExternalLinks, IExternalLinksRequest, normalizeString } from './ExternalLinkAccess.data';
import { useHistory } from 'react-router-dom';
import AuthService from '../../services/auth';
import { useSSO } from '../../hooks/useSSO';



type Properties = {}

const ExternalLinkAccess = ({}: Properties) => {

    const {t} = useTranslation(['common'])
    const {branding, getAppSettings} = useBranding()
    const {isAuthenticated} = useAuth()
    const [filter, setFilter] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isLoaded, setIsLoaded] = useState(false)
    const [showTryAgain, setShowTryAgain] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [sortCategories, setSortCategories] = useState<ESort>(ESort.ascending)
    const [categories, setCategories] = useState<IGroup[]>([])
    const [originCategories, setOriginCategories] = useState<IGroup[]>([])
    const [applications, setApplications] = useState<IExternalLinks[]>([])
    const [originApplications, setOriginApplications] = useState<IExternalLinks[]>([])
    const [currentApplication, setCurrentApplication] = useState<IExternalLinks | null>(null)
    const [message, setMessage] = useState("")
    const { setSSO, enabled: ssoEnabled, validateUser, validateSSO, isLoaded: _isSSOLoaded, isValid: isSSOValid, samlProvider, getImanageContext } = useSSO()


    let timeout: any = undefined

    useEffect(() => {
        if (isAuthenticated) {
            getAppSettings()
            AuthService.SAMLValidate({}).then((data: IHttpClientResponse | any) => {
                if (data.data.success) {
                    loadData()
                } else {
                    getImanageContext({ Provider: "iManage" }).then( (data: Boolean) => {
                        if (data) {
                            loadData()
                        } else {
                            samlProvider({ Provider: "iManage" })
                        }
                    }).catch(() => {
                        samlProvider({ Provider: "iManage" })
                    })
                }
            })
        } else {
            //TODO add logic 
        }

    }, [isAuthenticated])

    /**
     * Load data on the Page loading
     */
    const loadData = async () => {
        setIsLoading(true)
        setShowTryAgain(false)
        setShowMessage(false)
        setMessage("")
        setIsLoaded(false)

        let site = await ((Helper.getSite()).then(r => r))!
        if (site === "") {
            site = sessionStorage.getItem("site")

        }
        const request: IExternalLinksRequest = {
            Username: Helper.getUsername()
        }
        await ExternalLinkService.getExternalLinks(request).then(result => {
            if (Array.isArray(result) && result.every(item => typeof item === 'object')) {

                let newCategories: IGroup[] = []
                let newApplications: IExternalLinks[] = []

                result.forEach((application, index) => {
                    newCategories.push(
                        {
                            name: application.Category,
                            key: application.Category,
                            count: 1,
                            data: {remove: true},
                            isCollapsed: true,
                            startIndex: index
                        })

                    newApplications.push({
                        Category: application.Category,
                        Description: application.Description,
                        DisplayName: application.DisplayName,
                        WebformLink: application.WebformLink,
                        AccountId: application.AccountId,
                        TemplateId: application.TemplateId
                    })
                })

                let arrayWithoutDuplicates: IGroup[] = []
                let tempArray = Array.from(
                    new Set(newCategories.map(obj => obj.name))
                ).map(name => newCategories.find(obj => obj.name === name));

                if(arrayWithoutDuplicates !== undefined){
                    arrayWithoutDuplicates = tempArray as IGroup[]
                }

                newCategories = arrayWithoutDuplicates

                // Fix the category item count
                newCategories.forEach(item => {
                    item.count = newApplications.filter(a => a.Category === item.name).length
                })

                newCategories.sort(compareFn("name", ESort.ascending))
                newApplications.sort()

                setOriginCategories(newCategories)
                setOriginApplications(newApplications)
                setCategories(newCategories)
                setApplications(newApplications)

            } else {
                setCategories([])
                setApplications([])
            }
            setIsLoaded(true)

            //todo check Suscription status
            // if (r.length !== undefined) {
            //     r.forEach((itemDoc: any) => {
            //         itemDoc.links.forEach((itemLink: any) => {
            //             var itemSubscriptionStatusMessage = itemLink!.SubscriptionStatusMessage != null && itemLink!.SubscriptionStatusMessage != undefined && itemLink!.SubscriptionStatusMessage != "" ? itemLink!.SubscriptionStatusMessage : ""
            //             itemLink.SubscriptionStatusMessage = sessionStorage.getItem("entityId") !== 'Not In Teams' ? "Document not available" : (itemLink.CoOwner ? itemSubscriptionStatusMessage + " Contact the owner of the application to upgrade plan." : itemLink.SubscriptionStatusMessage)
            //         });
            //       
            //     });
            //     var settingLinks: INavLinkGroup[] = [];
            //     settingLinks = r
            //
            // }
        })
        .catch((error) => {
            //todo check 'Invalid session or expired session.'
            setShowTryAgain(true)
            setShowMessage(true)
            setMessage(error.error.toString())
        })
        .finally(() => {
            setIsLoading(false)
        })

    }

    /**
     * Callback Application Click
     * @param item
     */
    const callbackItemClick = (item: IExternalLinks) => {
        //setCurrentApplication(item)
        //history.push(item.WebformLink);
        window.location.assign(item.WebformLink);
    }

    /**
     * Sort categories by ESort
     * @param sort
     */
    const sortCategoriesFn = (sort: ESort) => {
        setCategories([...categories.sort(compareFn("name", sort))])
        setOriginCategories([...originCategories.sort(compareFn("name", sort))])
        setSortCategories(sort)
    }

    /**
     * Normalize string
     * @param value
     */


    /**
     * Filter value
     * @param filter
     */
    const filterFn = (filter: string) => {
        if (filter !== undefined && filter.trim().length > 0) {
            setFilter(filter)

            let _applications: IExternalLinks[] = cloneDeep(originApplications)
            let _categories: IGroup[] = cloneDeep(originCategories)

            // remove applications 
            let newapps =_applications.filter(item => {
                return normalizeString(item.DisplayName).includes(normalizeString(filter))
                    || normalizeString(item.Description).includes(normalizeString(filter))
            })

            // mark Category if has applications
            newapps.forEach((_application, index, _) => {
                const _categoriesIndex = _categories.findIndex(item => item.key === _application.Category.toString())
                    if (_categoriesIndex > -1) _categories[_categoriesIndex].data.remove = false
            })

            // filter categories
            _categories = _categories.filter(item => {
                    return item.data.remove === false || normalizeString(item.name).includes(normalizeString(filter))
                }
            )

            // expand if results
            _categories.forEach(item => {
                item.count = newapps.filter(a => a.Category === item.name).length
                item.isCollapsed = false
            })

            setCategories(_categories)
            setApplications(_applications)
        } else {
            setFilter("")
        }
    }

    /**
     * Filter the data
     * @param filter
     */
    const searchOnChange = (filter: string) => {
        clearTimeout(timeout)
        timeout = setTimeout(function () {
            filterFn(filter)
        }, 240)
    }

    /**
     * Reset the filter
     */
    const resetFilter = () => {
        setTimeout(function () {
            setFilter("")
            setApplications(originApplications)
            setCategories(originCategories)
        }, 240)
    }

    return (
        <>


            {isLoaded &&
                <>
                    <Stack styles={{root: {height: "100%"}}}>
                        <Stack horizontal
                               styles={topBarStyles(branding.theme)}>
                            <StackItem tokens={{padding: 8}} shrink={0} className={"ms-hiddenSm"}>
                                <Text variant={"xLarge"} block
                                      styles={brandingStyles(branding.theme)}>
                                    {branding.appName}
                                </Text>
                            </StackItem>
                            <Stack grow={1} tokens={{padding: 8}} styles={{root: {alignItems: "center"}}}>
                                <SearchBox placeholder={t("label.search", {ns: "common"})}
                                           value={filter}
                                           onEscape={resetFilter}
                                           onClear={resetFilter}
                                           onSearch={newValue => {
                                               if (newValue !== undefined && newValue.trim().length > 0) filterFn(newValue)
                                           }
                                           }
                                           onChange={(event, newValue) => {
                                               if (newValue) searchOnChange(newValue)
                                               else resetFilter()
                                           }}

                                           styles={searchBoxStyles}/>
                            </Stack>
                            <StackItem tokens={{padding: 8}}>
                                <CommandButton
                                    iconProps={{iconName: "SortLines"}}
                                    styles={commandButtonStyles(branding.theme)}
                                    text={t("label.sort", {ns: "common"})}
                                    menuProps={menuProps(sortCategories, sortCategoriesFn)}/>
                            </StackItem>

                        </Stack>

                        {categories.length === 0 ?
                            <Stack
                                styles={stackEmpty}>
                                <FontIcon iconName="DocumentSearch" className={iconEmptystyles(branding.theme)}/>
                                <Text variant={"mediumPlus"} block
                                      styles={{
                                          root: {
                                              fontWeight: FontWeights.semibold,
                                              color: branding.theme.palette.neutralSecondaryAlt
                                          }
                                      }}>
                                    {t("label.noItems", {ns: "common"})}
                                </Text>

                                {filter.trim().length !== 0 &&
                                    <Link onClick={resetFilter} underline>
                                        {t("label.clearFilter", {ns: "common"})}
                                    </Link>
                                }
                            </Stack>
                            :
                            <Stack tokens={{padding: 16}} grow={1} className="scrollVisible" styles={stackContent}>
                                <StackItem>
                                    <GroupedList
                                    items={applications}
                                    onRenderCell={(nestingDepth, item) => {
                                        return onRenderCell(callbackItemClick, branding.theme
                                            , nestingDepth, item, applications, filter)
                                    }}
                                    selectionMode={SelectionMode.none}
                                    groupProps={{
                                        onRenderHeader: (props) => {
                                            return onRenderHeader(branding.theme, props)
                                        }
                                    }}
                                    groups={categories}
                                    compact={true}
                                    onShouldVirtualize={() => true }
                                    />
                                </StackItem>

                            </Stack>
                        }
                    </Stack>
                </>
            }

            {!isLoaded &&
                <>
                    <Stack styles={stackLoading(branding.theme, isLoaded)}>
                        <Image
                            src={branding.appLogo}
                            alt="Kim Technologies"
                            height={44}
                        />

                        {isLoading &&
                            <Stack>
                                <Spinner size={SpinnerSize.large} labelPosition={"top"}
                                         styles={spinnerLoadingStyles(branding.theme)}
                                         label={t('text.spinner', {ns: 'common'})} ariaLive="assertive"/>
                            </Stack>
                        }
                        {showTryAgain &&
                            <>
                                <DefaultButton
                                    style={{marginTop: 24}}
                                    styles={buttonStyles(branding.theme)}
                                    onClick={loadData}
                                    text={t('Common.Button.Try', {ns: 'common'})}
                                    title={t('Common.Button.Try', {ns: 'common'})}/>

                                {showMessage &&
                                    <Stack>
                                        <MessageBar styles={messageBarStyles(branding.theme)}>
                                            <Text variant={"medium"}>{message} </Text>
                                        </MessageBar>
                                    </Stack>
                                }
                            </>
                        }
                    </Stack>
                </>
            }
        </>
    )
}

export default ExternalLinkAccess