import React, { ReactElement } from "react"
import { Modal } from '@fluentui/react/lib/Modal';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { IIconProps, Text, Separator, Stack, StackItem } from "@fluentui/react";
import {useBranding} from "../hooks/useBranding";
import { useTranslation } from "react-i18next";

export enum Sizes {
    xxs = '20vw', xs = '30vw', xsPlus='40vw', sm = '50vw', smPlus='60vw', md = '75vw', lg = '95vw', settings = '65vw', auto = 'auto', absolute480 = "480px"
}
export type CustomModalProps = {
    className?: string | undefined
    bodyClassName?: string | undefined
    title: string
    titleId: string
    children: ReactElement,
    close?: () => void
    isOpen?: boolean
    additionalHeader?: any
    size?: Sizes
    afterClose?: any
    beforeClose?: any
}
const cancelIcon: IIconProps = { iconName: 'Cancel' }
const CustomModal = ({ className, bodyClassName, title = 'Modal', titleId = 'modal-title', children, additionalHeader, close, isOpen, size = Sizes.xs }: CustomModalProps): JSX.Element => {
    const { branding } = useBranding()
    const {t} = useTranslation([ 'common'])

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.large,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],

    });

    const _styles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minWidth: 320,
            width: size,
            maxWidth: size,
        },
        body: {
            flex: '4 4 auto',
            padding: size === Sizes.settings ? '0px 15px 24px' : '0 24px 24px 24px',

            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            }
        }
    })
    return (
        <Modal className={className} isOpen={isOpen} isBlocking={true} containerClassName={_styles.container}>
            {/*-- START HEADER --*/}
            <Stack horizontal className={contentStyles.header}>
                <StackItem grow={1}
                           styles={{root: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}}>
                    <Text variant={"xLarge"} id={titleId}
                          title={title}>
                        {title}
                    </Text>
                </StackItem>
                <Stack horizontal styles={{root: {alignItems: "center", paddingLeft: 8}}}>
                    {additionalHeader}
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                        onClick={close}
                        title={t('Common.Button.Close', {ns: 'common'})}
                    />
                </Stack>
            </Stack>
            {/*-- END HEADER --*/}
            <Separator styles={{root: {marginTop: "-12px"}}}/>
            <div className={bodyClassName ? bodyClassName : _styles.body}>
                {children}
            </div>
        </Modal>
    )
}
const theme = getTheme()

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
}
export default CustomModal