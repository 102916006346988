import HttpRequest from "./HttpRequest";
import { IPropertyBagSettings } from "../interfaces/IPropertyBagSettings";

const AttachmentService = {

    getSettings: async (request: RequestSettings): Promise<IPropertyBagSettings> => {
        let { data }: any = await HttpRequest.getWithSession('getDocumentOnlyTemplateID', request)

        return new Promise<IPropertyBagSettings>((resolve, reject) => {
            if (data.PropertyBag === undefined) reject({ err: 'Server error' })
            let _settings: IPropertyBagSettings = {} as IPropertyBagSettings
            try {
                let json = JSON.parse(data.PropertyBag)
                if (!json.attachment) {
                    json.attachment = { enable: false, maxSize: 1, attachments: [] }

                } else if (!json.attachment.attachments) {
                    json.attachment.attachments = []
                }
                resolve(json)
            } catch {
                resolve(_settings)
            }
        })
    },
    getRuleAttachments: async (request: RequestAttachment): Promise<any> => {
        const requestSettings: RequestSettings = {
            DocSource: request.DocSource,
            EndUserName: request.EndUserName,
            IgnoreDocument: true,
            TemplateId: request.TemplateId,
            Username: request.Username
        }
        let _settings: any = await AttachmentService.getSettings(requestSettings)

        if (_settings?.attachment?.enable && _settings.attachment?.attachments?.length > 0) {
            return AttachmentService.GetDocumentOnlyAttachmentsByDocumentId(request).then(({ data }: any) => {
                let attachments = _settings.attachment?.attachments.map((rule: any) => {
                    let _attachment = data.attachments?.find((d: any) => {
                        const fileName = d.Description.length > 0 ? d.Description.split('.')[0] : ''
                        return rule.name.trim() === fileName.trim()
                    })
                    if (_attachment) {
                        _attachment.rule = rule
                        return _attachment
                    }
                    return { Id: -1, Description: `${rule.name} ${rule?.required ? '*' : ''}`, required: rule.required, rule: rule }
                })
                if (attachments.length > 0) {
                    attachments.sort((prev: any, curr: any) => {
                        return prev.rule.count - curr.rule.count
                    })
                }
                return new Promise<any>((resolve, reject) => {
                    try {
                        resolve({ data: { attachments, showData: true } })
                    } catch {
                        resolve({ data: { attachments: [], showData: true } })
                    }
                })
            })

        }
        return new Promise(resolve => resolve({ data: { showData: false } }))


    },
    getValidationAttachments: async (request: RequestAttachment) => {

        return AttachmentService.getRuleAttachments(request).then(({ data }) => {
            if (data.attachments) {
                let validations = data.attachments?.map((attachment: any) => {
                    if (attachment.rule.required && attachment.Id === -1) {
                        return `Attachment '${attachment.rule?.name}' is required`
                    }
                })
                return new Promise((resolve) => {
                    resolve(validations.filter(Boolean))
                })
            } else {
                new Promise((resolve) => {
                    resolve([])
                })
            }

        })
    },
    updateSettings: async (templateId: number, settings: string) => {
        return await HttpRequest.postWithSession('updatePropertyBag', { templateId: Number(templateId), PropertyBag: settings })
    },
    GetDocumentOnlyAttachmentsByDocumentId: async (request: RequestAttachment): Promise<any> => {
        if (request.DocumentDraftId && request.DocumentDraftId !== -1) {
            return HttpRequest.getWithSession('GetDocumentDraftAttachmentById', request).then(({ data }: any) => {
                return new Promise((resolve) => {
                    resolve({ data: { attachments: data, showData: true } })
                })
            }).catch((error) => { return error })
        } else if (Number(request.DocumentId) !== -1) {
            return HttpRequest.getWithSession('GetDocumentOnlyAttachmentByDocumentId', request).then(({ data }: any) => {
                return new Promise((resolve) => {
                    resolve({ data: { attachments: data, showData: true } })
                })
            })
        } else {
            return new Promise((resolve) => resolve({ data: { attachments: [], showData: true } }))
        }
    },
    makePublicPrivate: async (request: RequestActions) => {
        return HttpRequest.getWithSession('DeleteDocumentOnlyAttachment', { ...request, Operation: 'changescope' })
    },
    deleteAttachment: async (request: RequestActions) => {
        const url = request.DocumentDraftId && request.DocumentDraftId !== -1 ? 'DeleteDocumentDraftAttachment' : 'DeleteDocumentOnlyAttachment'

        return HttpRequest.getWithSession(url, { ...request, Operation: 'delete' })

    },
    downloadAttachment: async (request: RequestActions) => {

        if (request.DocumentDraftId && request.DocumentDraftId !== -1) {
            //change prop to DocumentDraftId
            return HttpRequest.getWithSession('GetDocumentDraftAttachmentBlob', { ...request, attachmentDraftId: request.Id })
        } else {
            return HttpRequest.getWithSession('GetDocumentOnlyAttachmentById', { ...request, GetBlobData: true })
        }
    },
    uploadFile: async (request: RequestAttachment, attachment: AttachmentFile) => {
        const needDraft = (request.DocumentDraftId && request.DocumentDraftId !== -1) || (request.DocumentDraftId === -1 && request.DocSource.includes('link'))
        if (needDraft) {
            return HttpRequest.postWithSession('AddDocumentDraftAttachment', {
                ...request, ...attachment
            })
        } else {
            return HttpRequest.postWithSession('AddDocumentAttachment', {
                ...request, ...attachment, DocumentId: Number(request.DocumentId), TemplateId: `${request.TemplateId}`
            })
        }
    }
}
interface RequestActions {
    Id: number
    attachmentDraftId?: number
    DocumentDraftId?: number
    EndUserName: string
    DocSource: string
    Username: string
}
interface RequestSettings {
    TemplateId: number
    DocSource: string
    EndUserName: string
    Username: string
    IgnoreDocument: boolean,
    Cache?: boolean
}
export interface RequestAttachment {
    DocumentId: number
    TemplateId: number
    DocumentDraftId?: number
    DocSource: string
    EndUserName: string
    Username: string
}
type AttachmentFile = {
    File: string
    AttachmentName: string
    AttachmentDescription: string
    MimeType: string
    FileExt: string
    Private: boolean
    RuleId: number
    EndUserName: string
}
export interface IAttachmentResponse {
    Id: number;
    AccountId: number;
    DocumentId: number;
    MimeType: string;
    Name: string;
    Description: string;
    CreatedBy: string;
    UpdatedBy: string;
    DateTime: Date;
    FileExt: string;
    AllowEsignFromManualUpload: boolean;
}
export default AttachmentService