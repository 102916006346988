import React, { FC, useMemo } from "react";
import { useState } from "react";
import CustomModal, { CustomModalProps } from "../share/CustomModal";

export const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(undefined)

    const open = (data?: any) => {
        setData(data)
        setIsOpen(true);
    }

    const close = (afterClose?: Function, beforeClose?: Function) => {
        if (typeof beforeClose === 'function') {
            beforeClose()
            return
        }
        
        if (typeof afterClose === 'function') {
            afterClose()
            setIsOpen(false)
        }
        
        setIsOpen(false)    
    }

    const Modal: FC<CustomModalProps> = useMemo(() => {
        return ({ title, bodyClassName, titleId, size, children, additionalHeader, afterClose = undefined, beforeClose = undefined}: CustomModalProps) =>
            <CustomModal title={title} titleId={titleId} size={size}
                         bodyClassName={bodyClassName}
                         close={() => {
                             close(afterClose, beforeClose)
                         }} isOpen={isOpen}
                         children={children}
                         additionalHeader={additionalHeader}/>
    }, [isOpen])

    return useMemo(
        () => ({
            isOpen, Modal, open, close, data
        }),
        [Modal, data, isOpen],
    )
}