import { FontWeights, IIconProps, IconButton, getTheme, mergeStyleSets, SpinnerSize, Spinner } from '@fluentui/react';
import { t } from 'i18next';
import React, { useState } from 'react'
import HelpComponentRelative from '../../Help/HelpComponentRelative';
import { isMobile } from "react-device-detect";
import DraftList from '../../DraftDocuments/DraftList';
import { useId } from "@uifabric/react-hooks";
import { getRecordDescription } from './CustomForm';
import { useBranding } from "../../../hooks/useBranding";
import { useDocument } from '../../../hooks/useDocument';
import UserService from '../../../services/User';
import { useModal } from '../../../hooks/useModal';
import { UpdateFormProfile } from '../../MyProfile/UpdateFormProfile';
import { IFormControl } from '../../../Helpers/Helper';
import { useSSO } from '../../../hooks/useSSO';
import { useDialog } from '../../../hooks/useDialog';

const theme = getTheme();

const previewIcon: IIconProps = { iconName: "DocumentSearch" };
const attachIcon: IIconProps = { iconName: "Attach" };
const cancelIcon: IIconProps = { iconName: "Cancel" };
type FormHeaderProps = {
    setApplying: (applying: boolean) => void;
    setLoading: (loading: boolean) => void;
    linkDraftId: number;
    handleCloseModal: () => void;
    isAppLinkDraft: boolean;
    allowDrafts: boolean;
    showAttachmentBtn: boolean;
    handleLoadDraft: (draft: any, callback: Function) => void
    draftId: number;
    templateId: string;
    username: string;
    isWizardPreview: boolean;
    onBehalf: string;
    createDraft: (draftId: number) => void
    preview: () => void;
    setShowAttachment: (showAttachment: boolean) => void;
    isUpdate: boolean
    recordDescription: string
    isAccessCodeValid: boolean
    setIsAccessCodeValid: (isAccessCodeValid: boolean) => void
    reloadProfile: () => void
};

export default function FormHeader({ setApplying, linkDraftId, handleCloseModal, isAppLinkDraft, setLoading, reloadProfile,
    allowDrafts, showAttachmentBtn, handleLoadDraft, draftId, templateId, username, isWizardPreview, recordDescription,
    onBehalf, createDraft, preview, setShowAttachment, isAccessCodeValid }: FormHeaderProps) {
    const { branding } = useBranding()
    const { isValid: isSSOValid } = useSSO()
    const { profile } = useDocument()
    const profileModal = useModal()
    const logoutDialog = useDialog()
    const [isLogginOut, setIsLoggingOut] = useState(false)
    const titleId = useId("CustomTemplate")

    const buttonHelpActionsStyles = {
        root: {
            color: branding.theme.palette.themePrimary,
            selectors: {
                ":hover": {
                    cursor: "pointer",
                },
            },
        },

    };

    const toolbarStyles = mergeStyleSets({
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                color: branding.theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
            },
        ],
        container: {
            display: "flex",
            justifyContent: isMobile ? 'flex-end' : "space-between",
        },
        draftButton: {
            paddingRight: isMobile ? '0px' : '25px',
            display: 'flex',
            height: '32px',
            alignItems: 'center'
        },
        actionsBar: {
            display: "flex",
            gap: "50px",
            alignItems: "center",
        },
        actions: {
            display: "flex",
            gap: "2px",
        },
        helpActions: {
            display: "flex",
            marginRight: '20px'
        }
    })

    const logoutSSO = () => () => {
        if (!profile?.Email) {
            return
        }
        setIsLoggingOut(true)
        UserService.Logout(profile?.Email).then(() => {
        }).finally(() => {
            window.location.reload()
        })
    }

    return (
        <>

            <div className={toolbarStyles.container}>
                {!isMobile && <div>
                    <div className={toolbarStyles.header}>
                        <span id={titleId}>{recordDescription}</span>
                    </div>
                </div>
                }
                <div className={toolbarStyles.actionsBar}>
                    {!isWizardPreview && isAccessCodeValid ? (
                        <div className={toolbarStyles.actions}>

                            <div>
                                {isAppLinkDraft ?
                                    <div className={toolbarStyles.draftButton}>
                                        <DraftList CurrentDraftId={linkDraftId} TemplateId={templateId.toString()}
                                            handleLoadDraft={handleLoadDraft} onCreateUpdateDraft={() => { }}
                                            UserName={username} EndUserName={onBehalf} />
                                    </div>
                                    : null
                                }
                            </div>
                            {isSSOValid ? <>
                                <div>
                                    <IconButton
                                        className="tabIndexClose"
                                        styles={iconButtonStyles}
                                        iconProps={{ iconName: "EditContact", }}
                                        title={t("title.btnUpdateProfile", { ns: "wizard" })}
                                        onClick={() => {
                                            profileModal.open()
                                        }}
                                    />
                                </div>
                                <div>
                                    <IconButton
                                        className="tabIndexClose"
                                        styles={iconButtonStyles}
                                        iconProps={{ iconName: "SignOut", }}
                                        title={t("Common.Button.Logout", { ns: "common" })}
                                        onClick={() => {
                                            logoutDialog.open({ confirm: logoutSSO })
                                        }}
                                    />
                                </div>
                            </> : null}
                            <div>

                                {allowDrafts && (
                                    <IconButton
                                        className="tabIndexClose"
                                        styles={iconButtonStyles}
                                        iconProps={{ iconName: "SaveTemplate", }}
                                        title={t("title.btnSaveDraft", { ns: "wizard" })}

                                        onClick={() => {
                                            setApplying(true);
                                            createDraft(linkDraftId > 0 ? linkDraftId : draftId);
                                        }}
                                    />
                                )}
                            </div>
                            <div>
                                <IconButton
                                    className="tabIndexClose"
                                    styles={iconButtonStyles}
                                    iconProps={previewIcon}
                                    title={t("title.btnPreview", { ns: "wizard", })}
                                    ariaLabel={t("Common.Button.Close", { ns: "common" })}
                                    onClick={() => {
                                        preview();
                                    }}
                                />
                            </div>
                            <div>
                                {showAttachmentBtn && (

                                    <IconButton
                                        className="tabIndexClose"
                                        styles={iconButtonStyles}
                                        iconProps={attachIcon}
                                        title={t("title.btnAddAttachments", { ns: "wizard" })}
                                        ariaLabel={t("Common.Button.Close", { ns: "common" })}
                                        onClick={() => {
                                            setShowAttachment(true);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    ) : null}
                    {!isMobile && <div className={toolbarStyles.helpActions}>
                        <IconButton
                            className="tabIndexClose"
                            styles={buttonHelpActionsStyles}
                            iconProps={cancelIcon}
                            title={t("Common.Button.Close", { ns: "common" })}
                            ariaLabel={t("Common.Button.Close", { ns: "common" })}
                            onClick={handleCloseModal}
                        />
                    </div>
                    }
                </div>
            </div>
            <profileModal.Modal title="Update profile" titleId="updateProfile_customForm">
                <UpdateFormProfile setLoading={setLoading} close={profileModal.close} reloadForm={reloadProfile} />
            </profileModal.Modal>
            {logoutDialog.isOpen && <logoutDialog.Dialog title={'Logout'} titleId='logoutSSOModal' showAction={!isLogginOut} actionText={t('Common.Button.Logout', { ns: 'common' })}>
                {isLogginOut ?
                    <Spinner size={SpinnerSize.large} ariaLive="assertive" />
                    : <>Are you sure you want to logout?</>}
            </logoutDialog.Dialog>}
        </>
    )
}

const iconButtonStyles = {

    root: {
        backgroundColor: isMobile ? 'transparent' : "lightgray",
        selectors: {
            ":hover": {
                cursor: "pointer",
            },
        },
    },
    rootHovered: {
        color: theme.palette.black,
        backgroundColor: isMobile ? 'transparent' : "lightgray",
    },
};