import React, { useState } from 'react';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { Stack, IStackProps } from '@fluentui/react/lib/Stack';
import { MaskedTextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { Link, Text } from 'office-ui-fabric-react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { useConstCallback } from '@uifabric/react-hooks';
import Helper from '../../Helpers/Helper';
import { ReactNotificationOptions, store } from 'react-notifications-component';
import ReactDOM from 'react-dom';
import App from '../../App';
import { Label, Spinner, SpinnerSize } from '@fluentui/react';
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";



const theme = getTheme();

const narrowTextFieldStyles: Partial<ITextFieldStyles> = {
    fieldGroup: {
        width: 255

    },
    field: {
        fontSize: 26,
        textAlign: "center"
    }
};

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 10 },
    styles: { root: { width: 300 } },
}

function ErrorAuthCode(props: any) {

    const { branding } = useBranding()
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '12px 12px 14px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    function showNotification(Nmessage: string, Ntype: string) {
        var auxType: ReactNotificationOptions;
        switch (Ntype) {
            case "success":
                store.addNotification({
                    title: "Success",
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "warning":
                store.addNotification({
                    title: "Warning",
                    message: Nmessage,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "error":
                store.addNotification({
                    title: "Error",
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "info":
                store.addNotification({
                    title: "Information",
                    message: Nmessage,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "successLeft":
                store.addNotification({
                    title: "Success",
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "errorLeft":
                store.addNotification({
                    title: "Error",
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            default:
                break;
        }
    }

    const [accessCode, setAccessCode] = useState("")
    const [disableValidation, setDisableValidation] = useState(true)
    const [isLoading, setIsLoading] = useState(false)


    const _onChangeAccessCode = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setAccessCode(newValue!)

            if (newValue != null && newValue != "")
                setDisableValidation(false)
            else
                setDisableValidation(true)
        },
    );

    const validate = () => {

        setIsLoading(true)
        var username = Helper.getUsername();

        const verify = async () => {

            const request = {
                UserName: username,
                AccessCode: accessCode
            }

            let {data}: any = await HttpRequest.getWithSession('AccessCode', request)
            const res = data
         
            if (res.Success) {
                //props.reloadApp(site)
                const e = <App />
                ReactDOM.render(e, document.getElementById('root'));
            } else {
                setIsLoading(false)
                showNotification("An error occurred: " + res, "error")
            }
        }

        verify().catch(e => console.log(e))
    }

    const resendCode = () => {

        const resend = async () => {
            var username = Helper.getUsername()
            var site = await Helper.getSite()!
            Helper.authenticate(username, site)
        }

        resend().catch(e => console.log(e))
    }

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
                <div className={contentStyles.header}>
                    <span className="ms-font-su">System Information</span>
                </div>
                <div className={contentStyles.body}>
                    
                {!isLoading ?
                    <Stack {...columnProps} style={{
                        position: 'absolute', top: '25%', left: '50%', marginRight: '-50%', transform:'translate(-50%, -50%)'}}>
                        <label>{props.errorMessage}</label>
                        <p>Enter activation code sent to your email address and accept terms of service to validate.</p>
                        <p>If you do not receive an email, please check your junk folder. If you still cannot see your activation email please use the contact email address at the bottom of the screen.</p>
                        <MaskedTextField onChange={_onChangeAccessCode} styles={narrowTextFieldStyles} label="Access code:" mask="999 - 999" />
                        <Text style={{ textAlign: "right" }}>
                            <Link href="" onClick={resendCode}>
                                Resend Activation Code
							</Link>
                        </Text>
                        <br />
                        <Text style={{ textAlign: "right" }}>
                            <PrimaryButton text="Validate" onClick={validate} disabled={disableValidation} />
                        </Text>
                    </Stack>
                    :
                    <Stack>
                        <Label />
                        <Label />
                        <Label />
                        <Spinner size={SpinnerSize.medium} label="Loading data,please wait..." ariaLive="assertive" />
                    </Stack>
                }
                </div>
            </div>
        </div>
    )
}

export default ErrorAuthCode