import React, { useState, useEffect } from 'react';
import '../../Custom.css';

import { useConstCallback, useBoolean } from '@uifabric/react-hooks';
import {
    Separator, Stack,
    IStyleFunctionOrObject,
    IContextualMenuProps, IContextualMenuItem, ISeparatorStyleProps, ISeparatorStyles
} from 'office-ui-fabric-react';

import HelpComponent from '../Help/HelpComponent'
import moment from 'moment-with-locales-es6';
import { isMobile } from 'react-device-detect';

import {
    List, IColumn, IButtonStyles, IconButton, Modal, IModalStyles,
    IIconProps, CheckboxVisibility, DetailsList, DetailsListLayoutMode, SelectionMode, Selection,
    FontWeights, getTheme, mergeStyleSets, getFocusStyle, DefaultButton, PrimaryButton, ScrollablePane, IScrollablePaneStyles,
    Label, DialogFooter, Dropdown, IDropdownOption,
    Spinner, SpinnerSize, Checkbox, TextField, ActionButton
} from '@fluentui/react';

import ReactDOM from 'react-dom';
import WizardService from '../../services/wizard';
import { ILayout } from './CustomTemplate';
import { InputType } from './Validations';
import {useBranding} from "../../hooks/useBranding";

const theme = getTheme();
const { fonts, semanticColors } = theme;
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const classNamex = mergeStyleSets({
    pane: {
        maxWidth: '100%',
    },
    toolbox: {

        maxWidth: '100%',
        marginTop: '45px',
        marginRight: '5px'
    }
});

const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNamex.pane };
const modalFormStyles: IModalStyles = {
    main: {
        width: '80vw',
        height: '100vh'
    },
    root: {

    },
    scrollableContent: {},
    layer: {},
    keyboardMoveIcon: {},
    keyboardMoveIconContainer: {}
}
const stackTokens = { childrenGap: 15 };
const emptyExpression: IExpressionBuilder = {
    AccountId: 0,
    Id: 0,
    TemplateId: 0,
    CreatedBy: "",
    DateCreated: "",
    DateUpdated: "",
    Description: "",
    Enabled: true,
    Expression: "",
    Name: "",
    Order: 0,
    UpdatedBy: "",
    PostAssembly: false
}
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
            ':disabled': {
                backgroundColor: theme.palette.white
            },
        },
    },
    
};
const separatorStyle: IStyleFunctionOrObject<ISeparatorStyleProps, ISeparatorStyles> = {
    root: {
        backgroundColor: "gainsboro"
    },
    content: {

    }
};
const emptyList: ISourceControls[] = []
const itemList: IExpressionBuilder[] = []
export interface IOperation {
    Name: string
    Function: string
}
export interface ISourceControls {
    Id: string
    Name: string
}

export interface IExpressionBuilder {
    Id: number
    AccountId: number
    TemplateId: number
    CreatedBy: string
    UpdatedBy: string
    DateCreated: string
    DateUpdated: string
    Name: string
    Description: string
    Order: number
    Enabled: boolean
    Expression: string
    PostAssembly: boolean
}
export enum ExpressionType {
    None = 0,
    Sum = 1,
    Add = 2,
    Subtract = 3,
    Multiply = 4,
    Divide = 5,
    DateAdd = 6,
    Concatenate = 7
}
type ExpressionBuilderProps = {
    modalExpBuilder: boolean
    toggleExpBuilder: any
    templateId: any
    templateName: string
    siteSelected: any
    layouts: ILayout[]
}
const ExpressionBuilder = ({ toggleExpBuilder, templateId, modalExpBuilder, templateName, siteSelected, layouts }: ExpressionBuilderProps) => {
    const { branding } = useBranding()
    const [isUpdateExp, setIsUpdateExp] = useState(false)
    const [requiredName, setRequiredName] = useState(true)
    const [requiredDesc, setRequiredDesc] = useState(true)
    const [requiredType, setRequiredType] = useState(true)
    const [requiredTarget, setRequiredTarget] = useState(true)
    const [requiredSource1, setRequiredSource1] = useState(true)
    const [requiredSource2, setRequiredSource2] = useState(true)
    const [requiredDateFormat, setRequiredDateFormat] = useState(true)
    const [requiredUnit, setRequiredUnit] = useState(true)
    const [requiredSourceG, setRequiredSourceG] = useState(true)
    const [expressionOrder, setExpressionOrder] = useState(0);
    const [expressionEnabled, setExpressionEnabled] = useState(true);
    const [expressionIsPostAssembly, setExpressionIsPostAssembly] = useState(false);
    const [expressionSeparator, setExpressionSeparator] = useState("");
    const [expressionUnit, setExpressionUnit] = useState("");
    const [operations, setOperations] = useState<IOperation[]>([]);
    const [unitOptions, setUnitOptions] = useState<IDropdownOption[]>([]);
    const [dateFormatOptions, setDateFormatOptions] = useState<IDropdownOption[]>([]);
    const [contentControlGridOptions, setContentControlGridOptions] = useState<IDropdownOption[]>([]);
    const [selectedTypeExp, setSelectedTypeExp] = useState<ExpressionType>();
    const [selectedTarget, setSelectedTarget] = useState<IDropdownOption>();
    const [selectedSource1, setSelectedSource1] = useState<IDropdownOption>();
    const [selectedSource2, setSelectedSource2] = useState<IDropdownOption>();
    const [selectedSourceN, setSelectedSourceN] = useState<IDropdownOption>();
    const [selectedSourceGrid, setSelectedSourceGrid] = useState<IDropdownOption>();
    const [selectedDecimalPrecision, setSelectedDecimalPrecision] = useState(2);
    const [selectedUnit, setSelectedUnit] = useState<IDropdownOption>();
    const [selectedFormat, setSelectedFormat] = useState<IDropdownOption>();
    const [expressionName, setExpressionName] = useState("");
    const [expressionDescription, setExpressionDescription] = useState("");
    const [addingSource, setAddingSource] = useState(false);
    const [expressionSelected, setExpressionSelected] = useState(emptyExpression)
    const [sourceControls, setSourceControls] = useState<ISourceControls[]>(emptyList);
    const [showDeleteItemModal, setshowDeleteItemModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [expressionList, setExpressionList] = useState(itemList)
    const [isAddVisible, { toggle: toggleIsAddVisible }] = useBoolean(false);
    const [typeOptions, setTypeOptions] = useState<IDropdownOption[]>([]);
    const [contentControlOptions, setContentControlOptions] = useState<IDropdownOption[]>([]);
    const [targetItem, setTargetItem] = useState<ILayout | undefined>(undefined)
    const [mQuery, setMQuery] = React.useState<any>({
        matches: window.innerWidth > 639 ? true : false,
    });

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        bodyExp: {
            width: '98%',
            height: '62vh',
            flex: '4 4 auto',
            padding: '0 15px 5px 15px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyConfirmation: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        itemCellAttachment: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',

                background: theme.palette.white,
            },
        ],
        itemContent: {
            marginLeft: 10,
            overflow: 'hidden',
            flexGrow: 1,
        },
        itemSecondarySmallHeader: {
            fontFamily: "Calibri",
            fontSize: fonts.medium,
            color: branding.theme.palette.themePrimary,
            fontWeight: "bold"
        },
        disabledHelpLabel: {
            marginTop: '5px!important',
            color: '#a5a3a1'
        }
    });


    function deleteExp() {
        setshowDeleteItemModal(true)
    }
    const getDateTimeDisplaySpan = (item: any) => {

        var result

        isMobile ?
            result = <span title={item !== null ? moment.utc(item).local().format('lll') : null}>{item !== null ? moment.utc(item).local().format('ll') : null}</span>
            :
            result = <span title={item !== null ? moment.utc(item).local().format('lll') : null}>{item !== null ? moment.utc(item).local().format('llll') : null}</span>

        return result
    }
    async function getDateFormatList() {
        const { data }: any = await WizardService.getDateFormats({ TemplateId: templateId, Path: siteSelected })

        if (data !== undefined) {
            if (data.Success == null) {
                try {
                    var auxLists: IDropdownOption[] = []
                    data.map((x: any) => {
                        auxLists.push({
                            key: x.Value,
                            text: x.Name
                        })
                    })
                    setDateFormatOptions(auxLists)
                }
                catch (error) {
                    console.log(error)
                }
            }
        }

    }
    async function getUnitList() {
        const { data }: any = await WizardService.getDateUnits({ TemplateId: templateId, Path: siteSelected })

        if (data !== undefined) {
            if (data.Success == null) {
                try {
                    var auxLists: IDropdownOption[] = []
                    data.map((x: any) => {
                        auxLists.push({
                            key: x.Value,
                            text: x.Name
                        })
                    })
                    setUnitOptions(auxLists)
                }
                catch (error) {
                    console.log(error)
                }
            }
        }


    }
    async function getCCGridList() {
        const { data }: any = await WizardService.getCCGridList({ TemplateId: templateId })
        if (data !== undefined) {
            if (data.Success == null) {
                try {
                    var auxLists: IDropdownOption[] = []
                    data.map((x: any) => {
                        auxLists.push({
                            key: x.Control,
                            text: x.Control
                        })
                    })
                    setContentControlGridOptions(auxLists)
                }
                catch (error) {
                    console.log(error)
                }
            }
        }
    }
    const getContentControlOptions = (): IDropdownOption[] => {
        return contentControlOptions.filter(item => item.key.toString().endsWith("List") === false)
    }

    function setContentControlsDropDowns() {
        let cc = JSON.parse(sessionStorage.getItem("layout")!) as ILayout[]
        var opt: IDropdownOption[] = []
        cc = cc.filter(x => x.Type !== 9 && x.Type !== 10 && x.Type !== 12)

        cc.forEach(e => {
            //findItemInWizard(e.Id)
            opt.push({
                key: e.Id,
                text: e.Label
            })
        });

        setContentControlOptions(opt.sort((one, two) => (one.text.toLowerCase() > two.text.toLowerCase() ? 1 : -1)))
    }
    useEffect(() => {
        if (modalExpBuilder) {
            openExpBuilder()
        }
    }, [modalExpBuilder])

    function openExpBuilder() {
        setContentControlsDropDowns()
        getExpressionsByTemplate()
        getExpressionList()
        getUnitList()
        getCCGridList()
        getDateFormatList()

        if (isAddVisible)
            toggleIsAddVisible()

        toggleExpBuilder(true)

        setTimeout(function () {
            let element1 = ReactDOM.findDOMNode(document.getElementById("ScrollPaneUserlistEx")) as Element
            if (element1 !== null) { element1.children[1].classList.add("scrollVisible", "scrollVisibleXHidden") }
        }, 500);
    }
    const columnsExp: IColumn[] = [
        {
            key: 'type',
            name: 'Type',
            fieldName: 'Expression',
            minWidth: 40,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <a style={{ cursor: 'pointer' }}
                    title={item.Expression}>{item.Expression}</a >
            }
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'Name',
            minWidth: 125,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true
        },
        {
            key: 'description',
            name: 'Description',
            fieldName: 'Description',
            minWidth: 50,
            maxWidth: 280,
            isRowHeader: true,
            isResizable: false,
            isMultiline: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true
        },
        {
            key: 'order',
            name: 'Order',
            fieldName: 'Order',
            minWidth: 40,
            maxWidth: 40,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true
        },
        {
            key: 'createdBy',
            name: 'Create Date',
            fieldName: 'DateCreated',
            minWidth: 100,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return getDateTimeDisplaySpan(item.DateCreated);
            },
        },
        {
            key: 'updatedBy',
            name: 'Update Date',
            fieldName: 'DateUpdated',
            minWidth: 100,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return getDateTimeDisplaySpan(item.DateUpdated);
            },
        },
        {
            key: 'enabled',
            name: 'Enabled',
            fieldName: 'Enabled',
            minWidth: 40,
            maxWidth: 40,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IExpressionBuilder) => {
                return item.Enabled ? <Checkbox checked disabled={true} title="Expression would be executed." /> : <Checkbox disabled={true} checked={false} title="Expression wouldn't be executed." />;
            },
        },

        {
            key: 'column4',
            name: 'Action',
            fieldName: '',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            isPadded: true,
            onRender: (item: IExpressionBuilder) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={menuProps}
                /></div>
            },
        }
    ]
    const menuItems: IContextualMenuItem[] = [
        {
            key: 'edit',
            text: 'Edit',
            onClick: () => {
                editExp(expressionSelected)
            },
        },
        {
            key: 'delete',
            text: 'Delete',
            onClick: () => {
                deleteExp()
            },
        },
    ];
    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };
    const onSelectionItemChange = () => {
        const selectionCount = selectionItem.getSelectedCount();
        switch (selectionCount) {
            case 0:
                setExpressionSelected(emptyExpression)
                break;
            case 1:
                setExpressionSelected(selectionItem.getSelection()[0] as IExpressionBuilder)
                break;
            default:
                setExpressionSelected(emptyExpression)
                break;
        }
    }
    const selectionItem = new Selection({
        onSelectionChanged: onSelectionItemChange,
    });
    const hideDeleteItemModal = () => {
        setshowDeleteItemModal(false)
    }
    async function getExpressionsByTemplate() {
        setIsLoading(true)

        const { data }: any = await WizardService.getExpressionsByTemplate({ TemplateId: templateId, Path: siteSelected })

        setExpressionList(data)
        setIsLoading(false)

    }
    async function deleteExpression() {

        const { data }: any = await WizardService.deleteExpresion({
            Path: siteSelected,
            TemplateId: templateId,
            Id: expressionSelected.Id.toString()
        })
        if (data.Success) {
            hideDeleteItemModal()
            getExpressionsByTemplate()
        }
        else {
            console.log(data.ExceptionMessage)
        }


    }
    const onFormatChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setSelectedFormat(option)

        if (option !== null)
            setRequiredDateFormat(false)
        else
            setRequiredDateFormat(true)
    };
    const onSeparatoChange = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setExpressionSeparator(newValue!)
        },
    );
    const onTypeChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {

        setSelectedTypeExp(option?.key as ExpressionType)

        if (option?.key !== null && option?.key !== 0)
            setRequiredType(false)
        else
            setRequiredType(true)

    }
    const onTargetChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setSelectedTarget(option)

        if (option !== null)
            setRequiredTarget(false)
        else
            setRequiredTarget(true)
        findItemInWizard(`${option?.key}`)
    }

    const findItemInWizard = (id: string) => {
        const item = layouts.find(item => item.Id === id && item.Type === InputType.DatePicker && item.Page.toString() !== '0')
        setTargetItem(item)

        const format = dateFormatOptions.find(format => format.key.toString().toLowerCase() === item?.Validations?.Regex?.toLowerCase())
        if (format) {
            setSelectedFormat({ ...format })
        }
    }

    const onChangeOrder = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (newValue !== "")
                setExpressionOrder(parseInt(newValue!))
            else
                setExpressionOrder(0)
        },
    );
    const onChangeDecimalPrecision = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (newValue !== "") {
                if (parseInt(newValue!) > 6)
                    setSelectedDecimalPrecision(6)
                else
                    setSelectedDecimalPrecision(parseInt(newValue!))
            }
            else
                setSelectedDecimalPrecision(0)
        },
    );
    const onSource1Change = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setSelectedSource1(option)

        if (option !== null)
            setRequiredSource1(false)
        else
            setRequiredSource1(true)
    };
    const onSource2Change = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setSelectedSource2(option)
        if (option !== null)
            setRequiredSource2(false)
        else
            setRequiredSource2(true)
    };
    const onSourceNChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setSelectedSourceN(option)

    };
    const onSourceGChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setSelectedSourceGrid(option)

        if (option !== null)
            setRequiredSourceG(false)
        else
            setRequiredSourceG(true)
    };
    const onUnitChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setSelectedUnit(option)
        if (option !== null)
            setRequiredUnit(false)
        else
            setRequiredUnit(true)
    };
    function _onChangeEnabled(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        setExpressionEnabled(isChecked!)
    }

    function _onChangeIsPostAssembly(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        setExpressionIsPostAssembly(isChecked!)
    }

    const onChangeUnitValue = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setExpressionUnit(newValue!)
        },
    );
    function onRenderSourceCell(props: any) {
        return (
            <Stack >
                <div className={contentStyles.itemCellAttachment} id={"A" + props.Id} title={props.Name} data-is-focusable={false}>
                    <div className={contentStyles.itemContent}>
                        <div className={contentStyles.itemSecondarySmallHeader}>{props.Name}</div>
                    </div>
                    <div id="moreOptions">
                        <IconButton key={'P' + props.Id}
                            title={"Delete source control"}
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => { removeSourceControl(props.Id) }}
                        />
                    </div>

                </div>
            </Stack>
        );
    };
    const _onChangeName = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setExpressionName(newValue!)

            if (newValue !== null && newValue !== "")
                setRequiredName(false)
            else
                setRequiredName(true)

        },
    );
    const _onChangeDescription = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setExpressionDescription(newValue!)

            if (newValue !== null && newValue !== "")
                setRequiredDesc(false)
            else
                setRequiredDesc(true)
        },
    );
    function addSourceControl() {
        if (selectedSourceN !== undefined) {
            setAddingSource(true)
            setTimeout(function () {
                var auxListSource: ISourceControls[] = []

                auxListSource = sourceControls;

                auxListSource.push({
                    Id: selectedSourceN?.key.toString()!,
                    Name: selectedSourceN?.text!
                })

                setSourceControls(auxListSource)
                setAddingSource(false)
            }, 500);
        }
    }
    function editExp(item: IExpressionBuilder) {
        setIsUpdateExp(true)
        setExpressionName(item.Name)
        setExpressionOrder(item.Order)
        setExpressionDescription(item.Description)
        setExpressionEnabled(item.Enabled)
        splitExpression(item.Expression)
        setExpressionIsPostAssembly(item.PostAssembly)
        toggleIsAddVisible()

        setTimeout(function () {
            let element1 = ReactDOM.findDOMNode(document.getElementById("ScrollPaneUserlistEx1")) as Element
            if (element1 !== null) { element1.children[1].classList.add("scrollVisible", "scrollVisibleXHidden") }
        }, 500);

    }
    function splitExpression(exp: string) {
        var params = exp.split(",")
        if (exp.startsWith("Add") || exp.startsWith("Subtract") || exp.startsWith("Divide") || exp.startsWith("Multiply")) {
            var auxSplit = params[0].split("(")

            var selTarget = contentControlOptions.filter(x => x.key === auxSplit[1])
            var decimal = params[1]
            var selSource1 = contentControlOptions.filter(x => x.key === params[2])

            var auxString = ""
            if (params.length > 4)
                auxString = params[3]
            else
                auxString = params[3].replace(")", "")

            var selSource2 = contentControlOptions.filter(x => x.key === auxString)

            setSelectedTarget(selTarget[0])
            setSelectedDecimalPrecision(parseInt(decimal))
            setSelectedSource1(selSource1[0])
            setSelectedSource2(selSource2[0])

            switch (auxSplit[0]) {
                case "Add":
                    setSelectedTypeExp(ExpressionType.Add)
                    break;
                case "Subtract":
                    setSelectedTypeExp(ExpressionType.Subtract)
                    break;
                case "Divide":
                    setSelectedTypeExp(ExpressionType.Divide)
                    break;
                case "Multiply":
                    setSelectedTypeExp(ExpressionType.Multiply)
                    break;

            }
            if (params.length > 4) {
                setAddingSource(true)
                setTimeout(function () {
                    var auxListSource: ISourceControls[] = []

                    for (let i = 4; i < params.length; i++) {

                        var auxSource = params[i]
                        if (i === params.length - 1) {
                            auxSource = params[i].replace(")", "")
                        }

                        auxListSource.push({
                            Id: auxSource,
                            Name: auxSource
                        })

                    }

                    setSourceControls(auxListSource)
                    setAddingSource(false)
                }, 500);
            }

        }
        else if (exp.startsWith("Concatenate")) {

            let auxSplit = params[0].split("(")
            let selTarget = contentControlOptions.filter(x => x.key === auxSplit[1])
            let separator = params[1]
            let selSource1 = contentControlOptions.filter(x => x.key === params[2])
            let auxString = params[3].replace(")", "")
            let selSource2 = contentControlOptions.filter(x => x.key === auxString)

            setSelectedTarget(selTarget[0])
            setExpressionSeparator(separator)
            setSelectedSource1(selSource1[0])
            setSelectedSource2(selSource2[0])

            setSelectedTypeExp(ExpressionType.Concatenate)
        }
        else if (exp.startsWith("DateAdd")) {
            let auxSplit = params[0].split("(")
            let selTarget = contentControlOptions.filter(x => x.key === auxSplit[1])
            let dateFormat = dateFormatOptions.filter(x => x.key === params[1])
            let selSource1 = contentControlOptions.filter(x => x.key === params[2])
            let unit = unitOptions.filter(x => x.key === params[3])
            let auxString = params[4].replace(")", "")


            setSelectedTarget(selTarget[0])
            setSelectedFormat(dateFormat[0])
            findItemInWizard(`${selTarget[0].key}`)
            setSelectedSource1(selSource1[0])
            setSelectedUnit(unit[0])
            setExpressionUnit(auxString)

            setSelectedTypeExp(ExpressionType.DateAdd)
        }
        else if (exp.startsWith("Sum")) {
            let auxSplit = params[0].split("(")
            let selTarget = contentControlOptions.filter(x => x.key === auxSplit[1])
            let selSource1 = contentControlGridOptions.filter(x => x.key === params[2].replace(")", ""))
            setSelectedTarget(selTarget[0])
            setSelectedSourceGrid(selSource1[0])
            setSelectedTypeExp(ExpressionType.Sum)
        }
    }
    async function getExpressionList() {
        const { data }: any = await WizardService.getExpressionList({ TemplateId: templateId, Path: siteSelected })

        if (data !== undefined) {
            if (data.Success == null) {
                try {
                    var op: IOperation[] = []
                    var auxLists: IDropdownOption[] = []
                    data.map((x: any) => {
                        var auxKey = 0;
                        switch (x.Name) {
                            case "Sum":
                                auxKey = ExpressionType.Sum
                                break;
                            case "Add":
                                auxKey = ExpressionType.Add
                                break;
                            case "Subtract":
                                auxKey = ExpressionType.Subtract
                                break;
                            case "Multiply":
                                auxKey = ExpressionType.Multiply
                                break;
                            case "Divide":
                                auxKey = ExpressionType.Divide
                                break;
                            case "DateAdd":
                                auxKey = ExpressionType.DateAdd
                                break;
                            case "Concatenate":
                                auxKey = ExpressionType.Concatenate
                                break;
                        }
                        auxLists.push({
                            key: auxKey,
                            text: x.Name
                        })

                        op.push({
                            Name: x.Name,
                            Function: x.Function
                        })


                    })
                    setTypeOptions(auxLists)
                    setOperations(op)
                }
                catch (error) {
                    console.log(error)
                }
            }
        }

    }
    function buildExpression() {
        var selKey = typeOptions.filter(x => x.key === selectedTypeExp)
        var op = null
        var f = null
        var arrExp: string[] = []
        var exp = ""
        if (selKey.length > 0) {
            op = operations.filter(x => x.Name === selKey[0].text)
            if (op.length > 0) {
                f = op[0].Function
                arrExp = f.split(",")

                switch (selectedTypeExp) {
                    case ExpressionType.Add:
                    case ExpressionType.Subtract:
                    case ExpressionType.Multiply:
                    case ExpressionType.Divide:

                        var additionalSource = ""
                        if (sourceControls.length > 0) {
                            sourceControls.forEach(e => {
                                additionalSource += "," + e.Id
                            });
                        }

                        exp += arrExp[0] + selectedTarget?.key + "," + selectedDecimalPrecision.toString() + "," + selectedSource1?.key + "," + selectedSource2?.key + additionalSource + ")";

                        break;
                    case ExpressionType.Sum:
                        exp += arrExp[0] + selectedTarget?.key + "," + selectedDecimalPrecision.toString() + "," + selectedSourceGrid?.key + ")";

                        break;
                    case ExpressionType.DateAdd:
                        exp += arrExp[0] + selectedTarget?.key + "," + selectedFormat?.key + "," + selectedSource1?.key + "," + selectedUnit?.key + "," + expressionUnit + ")";
                        break;
                    case ExpressionType.Concatenate:
                        exp += arrExp[0] + selectedTarget?.key + "," + expressionSeparator + "," + selectedSource1?.key + "," + selectedSource2?.key + ")";
                        break;

                }

            }
        }
        return exp;

    }
    const getKey = (item: string, index?: number): string => {
        if (item !== undefined)
            return item.toString()

        return ""
    }

    async function addExpression() {
        setIsLoading(true)
        var exp = buildExpression()

        const { data }: any = await WizardService.addExpression({
            Path: siteSelected,
            TemplateId: templateId,
            Name: expressionName,
            Description: expressionDescription,
            Order: expressionOrder,
            Enabled: expressionEnabled,
            Expression: exp,
            IsPostAssembly: expressionIsPostAssembly,
            Id: "",
        })

        if (data.Success) {
            getExpressionsByTemplate()
            toggleIsAddVisible()
        } else {
            console.log(data.ExceptionMessage)
        }
        setIsLoading(false)

    }
    async function editExpression() {
        setIsLoading(true)

        var exp = buildExpression()

        const { data }: any = await WizardService.updateExpression({
            Path: siteSelected,
            TemplateId: templateId,
            Name: expressionName,
            Description: expressionDescription,
            Order: expressionOrder,
            Enabled: expressionEnabled,
            Expression: exp,
            Id: expressionSelected.Id.toString(),
            IsPostAssembly: expressionIsPostAssembly
        })

        if (data.Success) {
            getExpressionsByTemplate()
            toggleIsAddVisible()
        }
        else {
            console.log(data.ExceptionMessage)
        }
        setIsLoading(false)

    }
    const changeToAdd = useConstCallback(() => {
        clearFields()
        setIsUpdateExp(false)
        toggleIsAddVisible()



        setTimeout(function () {
            let element1 = ReactDOM.findDOMNode(document.getElementById("ScrollPaneUserlistEx1")) as Element
            if (element1 !== null) { element1.children[1].classList.add("scrollVisible", "scrollVisibleXHidden") }
        }, 500);
    })
    function clearFields() {
        setSelectedTypeExp(ExpressionType.None)
        setExpressionName("")
        setExpressionOrder(0)
        setExpressionDescription("")
        setExpressionSeparator("")
        setSelectedTarget(undefined)
        setTargetItem(undefined)
        setSelectedSource1(undefined)
        setSelectedSource2(undefined)
        setSelectedSourceN(undefined)
        setSelectedSourceGrid(undefined)
        setSelectedFormat(undefined)
        setSelectedUnit(undefined)
        setSourceControls([])
        setExpressionEnabled(true)
        setRequiredDesc(true)
        setRequiredName(true)
        setRequiredTarget(true)
        setRequiredType(true)
        setRequiredDateFormat(true)
        setRequiredUnit(true)
        setRequiredSource1(true)
        setRequiredSource2(true)
        setRequiredSourceG(true)
        setExpressionIsPostAssembly(false)
    }

    function removeSourceControl(key: string) {
        setAddingSource(true)
        setTimeout(function () {
            var auxListSource: ISourceControls[] = []

            auxListSource = sourceControls;

            var sc = auxListSource.findIndex(x => x.Id === key)
            auxListSource.splice(sc, 1)
            setSourceControls(auxListSource)
            setAddingSource(false)
        }, 500);

    }
    useEffect(() => {
        if (expressionName !== null && expressionName !== "")
            setRequiredName(false)
        else
            setRequiredName(true)
    }, [expressionName])

    useEffect(() => {
        if (expressionDescription !== null && expressionDescription !== "")
            setRequiredDesc(false)
        else
            setRequiredDesc(true)
    }, [expressionDescription])

    useEffect(() => {
        if (selectedTypeExp !== null && selectedTypeExp !== undefined)
            setRequiredType(false)
        else
            setRequiredType(true)
    }, [selectedTypeExp])

    useEffect(() => {
        if (selectedSource1 !== null && selectedSource1 !== undefined)
            setRequiredSource1(false)
        else
            setRequiredSource1(true)
    }, [selectedSource1])

    useEffect(() => {
        if (selectedSource2 !== null && selectedSource2 !== undefined)
            setRequiredSource2(false)
        else
            setRequiredSource2(true)
    }, [selectedSource2])

    useEffect(() => {
        if (selectedTarget !== null && selectedTarget !== undefined)
            setRequiredTarget(false)
        else
            setRequiredTarget(true)
    }, [selectedTarget])

    useEffect(() => {
        if (selectedSourceGrid !== null && selectedSourceGrid !== undefined)
            setRequiredSourceG(false)
        else
            setRequiredSourceG(true)
    }, [selectedSourceGrid])

    useEffect(() => {
        if (selectedUnit !== null && selectedUnit !== undefined)
            setRequiredUnit(false)
        else
            setRequiredUnit(true)
    }, [selectedUnit])

    useEffect(() => {
        if (selectedFormat !== null && selectedFormat !== undefined)
            setRequiredDateFormat(false)
        else
            setRequiredDateFormat(true)
    }, [selectedFormat])
    return (
        <>
            <Modal
                isOpen={modalExpBuilder}
                isBlocking={true}
                containerClassName={contentStyles.container}
                styles={modalFormStyles}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id={"expTitle"}>{"Expression Builder (" + templateName + ")"}</span>
                                <HelpComponent
                                    headline="Form Wizard Exression Builder"
                                    text={`Expression Builder performs basic math, date and string concatenation operations.
                    Operations include Add, Subtract, Mutliply, Divide, AddDate, Concatenate and Sum.
                    All operations require a Target Content Control to store Results and require Source Content Controls or Constant Values for input.
                    The Sum operation is used to sum values from Content Controls lists that come from ${branding.appName} Grid.
                    Target Content Controls do not have to be placed on Web Form Wizard.
                    Target Content Controls genarally appear on generated documents and/or retreived from bulk download.
                    Target Content Controls may be used as inputs for subsequent operations through the use of appropriately setting Order.`}
                                    right="50px"
                                    location="expressionbuilder"
                                />
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={() => {
                                        clearFields()
                                        toggleExpBuilder(false)
                                    }}
                                    title="Close."
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className={"ms-Grid-col ms-sm12 ms-md12 ms-lg12"} style={{ width: 200 }}>
                            <ActionButton title={!isAddVisible ? "Add a new expression." : "Go back to list"} text={!isAddVisible ? "Add" : "Go back to list"}
                                iconProps={{ iconName: !isAddVisible ? 'Add' : 'ChevronLeft' }} onClick={changeToAdd} />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.bodyExp}>
                                {!isLoading ?
                                    !isAddVisible ?
                                        <ScrollablePane styles={scrollablePaneStyles} id="ScrollPaneUserlistEx" key="ScrollPaneUserlistEx">
                                            <DetailsList
                                                key="exp"
                                                items={expressionList || []}
                                                compact={false}
                                                columns={columnsExp}
                                                getKey={getKey}
                                                setKey="set"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                isHeaderVisible={true}
                                                selection={selectionItem}
                                                selectionMode={SelectionMode.single}
                                                selectionPreservedOnEmptyClick={false}
                                                enterModalSelectionOnTouch={mQuery.matches}
                                                checkboxVisibility={CheckboxVisibility.hidden}
                                            />
                                        </ScrollablePane>
                                        :
                                        <>
                                            <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
                                                <Stack tokens={stackTokens}>
                                                    <Label>General</Label>
                                                    <TextField id="txtName" required errorMessage={expressionName == null || expressionName === "" ? "This field is required" : ""} maxLength={250} label={"Name:"} value={expressionName} onChange={_onChangeName} title="Name of the expression." />
                                                    <TextField id="txtDescription" required errorMessage={expressionDescription == null || expressionDescription === "" ? "This field is required" : ""} maxLength={250} label={"Description:"} value={expressionDescription} onChange={_onChangeDescription} title="Name of the expression." />
                                                    <Dropdown
                                                        id="ddlExpressionType"
                                                        label={"Expression Type:"}
                                                        options={typeOptions}
                                                        errorMessage={selectedTypeExp != null && selectedTypeExp === ExpressionType.None ? "Expression type required" : ""}
                                                        onChange={onTypeChange}
                                                        selectedKey={selectedTypeExp}
                                                        title="Choose a type for the expression."
                                                        required
                                                        placeholder={"Choose a type for the expression."}
                                                    />
                                                    <TextField id="txtOrder" value={expressionOrder.toString()} maxLength={3} onChange={onChangeOrder} inputMode="numeric" label="Order:" title="Enter the order for the expression." />



                                                    <Stack horizontal={true} tokens={stackTokens}>
                                                        <Stack.Item grow={2}>
                                                            <Checkbox label="Enable expression" checked={expressionEnabled} onChange={_onChangeEnabled} />
                                                        </Stack.Item>
                                                        <Stack.Item grow={2}>
                                                            <Checkbox label="Post Assembly" checked={expressionIsPostAssembly} onChange={_onChangeIsPostAssembly} />
                                                        </Stack.Item>
                                                    </Stack>





                                                </Stack>
                                            </div>
                                            <div style={{ height: "62vh", paddingLeft: "3%" }} className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                                <Separator vertical styles={separatorStyle} />
                                            </div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                <Label>Properties</Label>

                                                <ScrollablePane style={{ height: "57vh", marginTop: "42px" }} styles={scrollablePaneStyles} id="ScrollPaneUserlistEx1" key="ScrollPaneUserlistEx1">
                                                    <Stack style={{ paddingRight: "10px" }} tokens={stackTokens}>
                                                        <Dropdown
                                                            id="ddlExpressionTarget"
                                                            label={"Target control:"}
                                                            options={getContentControlOptions()}
                                                            errorMessage={selectedTarget === undefined || selectedTarget?.key === "" ? "Target control required" : ""}
                                                            onChange={onTargetChange}
                                                            selectedKey={selectedTarget?.key}
                                                            title="Select the target control for the expression."
                                                            required
                                                        />
                                                        {selectedTypeExp === ExpressionType.Add || selectedTypeExp === ExpressionType.Multiply || selectedTypeExp === ExpressionType.Divide || selectedTypeExp === ExpressionType.Subtract ?
                                                            <>
                                                                <TextField id="txtDecimal" value={selectedDecimalPrecision.toString()} maxLength={1} min={0} max={6} onChange={onChangeDecimalPrecision} type="number" label="Decimal Precision" title="Enter number for decimal precision (0 to 6)." />
                                                                <Dropdown
                                                                    id="ddlSource1"
                                                                    label={"Source Control 1:"}
                                                                    options={getContentControlOptions()}
                                                                    errorMessage={selectedSource1 === undefined || selectedSource1?.key === "" ? "This field is required" : ""}
                                                                    onChange={onSource1Change}
                                                                    selectedKey={selectedSource1?.key}
                                                                    title="Select source control 1 for the expression."
                                                                    required
                                                                />
                                                                <Dropdown
                                                                    id="ddlSource1"
                                                                    label={"Source Control 2:"}
                                                                    options={getContentControlOptions()}
                                                                    errorMessage={selectedSource2 === undefined || selectedSource2?.key === "" ? "This field is required" : ""}
                                                                    onChange={onSource2Change}
                                                                    selectedKey={selectedSource2?.key}
                                                                    title="Select source control 2 for the expression."
                                                                    required
                                                                />
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                                                        <Dropdown
                                                                            id="ddlSourceN"
                                                                            label={"Add More Source Controls (optional):"}
                                                                            options={getContentControlOptions()}
                                                                            errorMessage={selectedSourceN?.key === "" ? "This field is required" : ""}
                                                                            onChange={onSourceNChange}
                                                                            selectedKey={selectedSourceN?.key}
                                                                            title="Select source control for add to the expression."
                                                                        />
                                                                    </div>
                                                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1" style={{ paddingTop: "20px", right: "5px", top: "5px" }}>
                                                                        <ActionButton title={"Add source control"} iconProps={{ iconName: 'PageAdd' }} onClick={addSourceControl} />
                                                                    </div>
                                                                </div>
                                                                {!addingSource ?
                                                                    <List items={sourceControls} onRenderCell={onRenderSourceCell} />
                                                                    :
                                                                    <Spinner size={SpinnerSize.medium} label="Adding source control..." ariaLive="assertive" />
                                                                }


                                                            </>
                                                            : selectedTypeExp === ExpressionType.Sum ?
                                                                <>
                                                                    <TextField id="txtDecimal" value={selectedDecimalPrecision.toString()} maxLength={1} min={0} max={6} onChange={onChangeDecimalPrecision} type="number" label="Decimal Precision" title="Enter number for decimal precision (0 to 6)." />
                                                                    <Dropdown
                                                                        id="ddlSourceG"
                                                                        label={"Source Control:"}
                                                                        options={contentControlGridOptions}
                                                                        errorMessage={selectedSourceGrid === undefined || selectedSourceGrid?.key === "" ? "This field is required" : ""}
                                                                        onChange={onSourceGChange}
                                                                        selectedKey={selectedSourceGrid?.key}
                                                                        title="Select source control for the expression."
                                                                        required
                                                                    />
                                                                </>
                                                                : selectedTypeExp === ExpressionType.DateAdd ?
                                                                    <>
                                                                        <Dropdown
                                                                            id="ddlDateFormat"
                                                                            label={"Date Format:"}
                                                                            options={dateFormatOptions}
                                                                            errorMessage={selectedFormat === undefined || selectedFormat?.key === "" ? "This field is required" : ""}
                                                                            onChange={onFormatChange}
                                                                            disabled={Boolean(targetItem)}
                                                                            selectedKey={selectedFormat?.key}
                                                                            title="Select the date format for the expression."
                                                                            required
                                                                        />
                                                                        {Boolean(targetItem) ?
                                                                            <span className={contentStyles.disabledHelpLabel}>Target control is configured on webform wizard's and uses that format</span> : null}

                                                                        <Dropdown
                                                                            id="ddlSource1"
                                                                            label={"Source Control:"}
                                                                            options={getContentControlOptions()}
                                                                            errorMessage={selectedSource1 === undefined || selectedSource1?.key === "" ? "This field is required" : ""}
                                                                            onChange={onSource1Change}
                                                                            selectedKey={selectedSource1?.key}
                                                                            title="Select source control 1 for the expression."
                                                                            required
                                                                        />
                                                                        <Dropdown
                                                                            id="ddlUnit"
                                                                            label={"Unit:"}
                                                                            options={unitOptions}
                                                                            errorMessage={selectedUnit === undefined || selectedUnit?.key === "" ? "This field is required" : ""}
                                                                            onChange={onUnitChange}
                                                                            selectedKey={selectedUnit?.key}
                                                                            title="Select the unit for the expression."
                                                                            required
                                                                        />
                                                                        <TextField id="txtUnit" value={expressionUnit} onChange={onChangeUnitValue} max={9999} type="number" label="Add/Subtract:" title="Enter number for add/subtract units." />
                                                                    </>
                                                                    : selectedTypeExp === ExpressionType.Concatenate ?
                                                                        <>
                                                                            <Dropdown
                                                                                id="ddlSource1"
                                                                                label={"Source Control 1:"}
                                                                                options={getContentControlOptions()}
                                                                                errorMessage={selectedSource1 === undefined || selectedSource1?.key === "" ? "This field is required" : ""}
                                                                                onChange={onSource1Change}
                                                                                selectedKey={selectedSource1?.key}
                                                                                title="Select source control 1 for the expression."
                                                                                required
                                                                            />
                                                                            <Dropdown
                                                                                id="ddlSource1"
                                                                                label={"Source Control 2:"}
                                                                                options={getContentControlOptions()}
                                                                                errorMessage={selectedSource2 === undefined || selectedSource2?.key === "" ? "This field is required" : ""}
                                                                                onChange={onSource2Change}
                                                                                selectedKey={selectedSource2?.key}
                                                                                title="Select source control 2 for the expression."
                                                                                required
                                                                            />
                                                                            <TextField id="txtSeparator" maxLength={1} label={"String separator (optional):"} value={expressionSeparator} onChange={onSeparatoChange} title="Name of the expression." />
                                                                        </>
                                                                        : null
                                                        }
                                                    </Stack>
                                                </ScrollablePane>


                                            </div>

                                        </>
                                    :
                                    <Stack>
                                        <Label />
                                        <Label />
                                        <Label />
                                        <Spinner size={SpinnerSize.medium} label="Loading expressions..." ariaLive="assertive" />
                                    </Stack>
                                }
                            </div>
                        </div>

                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                {!isAddVisible ?
                                    <DefaultButton onClick={() => {
                                        clearFields()
                                        toggleExpBuilder(false)
                                    }} text="Close" title="Close." />

                                    : <PrimaryButton text={isUpdateExp ? "Update" : "Save"} disabled={requiredName || requiredDesc || requiredType || requiredTarget ||
                                        ((selectedTypeExp === ExpressionType.Add || selectedTypeExp === ExpressionType.Subtract || selectedTypeExp === ExpressionType.Divide || selectedTypeExp === ExpressionType.Multiply || selectedTypeExp === ExpressionType.Concatenate) && (requiredSource1 || requiredSource2)) ||
                                        (selectedTypeExp === ExpressionType.DateAdd && (requiredDateFormat || requiredSource1 || requiredUnit)) ||
                                        (selectedTypeExp === ExpressionType.Sum && requiredSourceG)
                                    } onClick={isUpdateExp ? editExpression : addExpression} title="Save expression." />
                                }
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={showDeleteItemModal}
                isBlocking={true}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id={"confirmation"}>{"Confirmation"}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={hideDeleteItemModal}
                                    title="Close."
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.bodyConfirmation}>
                                <Label >Are you sure you want to delete the expression?</Label>
                                <Label style={{ color: "red" }}>{expressionSelected?.Expression}</Label>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "15px", right: "12px", position: "relative" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={deleteExpression} text="Delete" title="Delete expression." />
                                <DefaultButton onClick={hideDeleteItemModal} text="Cancel" title="Close." />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default ExpressionBuilder