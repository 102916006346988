import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { ReactHookFormControlProps } from "../CustomTemplates/CustomForm/Models/ReactHookFormControlProps";
import { IInputProps, ITextFieldProps, Label, TextField } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface IFileControlProps {
    extensions: string;
    fileName: string;
}

export const RHFFileControl: FC<ReactHookFormControlProps & ITextFieldProps & IInputProps & IFileControlProps> = (props) => {

    const onFileChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.files != null && ev.target.files.length > 0) {
            let tempFileName = ev.target.files![0].name
            setFilename(tempFileName)
            props.setValue(props.id, ev.target.files)
            setFileUpdated(true)
        }
        
        if (props.onCallback !== null && props.onCallback !== undefined) {
            props.onCallback(ev)
        }
    }

    const [filename, setFilename] = useState("");
    const [fileUpdated, setFileUpdated] = useState(false);

    const { t } = useTranslation(['common', 'wizard'])

    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error }
            }) => (
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                            <Label htmlFor={props.id}>{props.label}</Label>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ paddingRight: "0px" }}>
                            <TextField
                                id={props.id}
                                name={fieldName}
                                disabled={true}
                                value={(props.fileName !== undefined && props.fileName !== "" && !fileUpdated) ? props.fileName : filename}
                                title={props.title}
                                placeholder={t('Common.Input.File', { ns: 'common' })} />
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                            <label htmlFor={`file${props.id}`}><div className="divHover divToolBoxItem">File</div></label>
                            <input
                                accept={props.extensions}
                                className="fileButton"
                                id={`file${props.id}`}
                                type="file"
                                name={`file${props.id}`}
                                onChange={(event) => { onFileChange(event) }}
                                placeholder={t('Common.Input.File', { ns: 'common' })}
                                title={props.title} />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                            {(error && error.message) &&
                                <span>
                                    <div role="alert">
                                        <p className="ms-TextField-errorMessage errorMessage-Wizard" ><span >{error.message}</span></p>
                                    </div>
                                </span>
                            }
                        </div>
                    </div>
                </div>
            )}
        />
    );
};