import HttpRequest from "./HttpRequest";
import { IPropertyBagSettings } from "../interfaces/IPropertyBagSettings";
import { IHttpClientResponse, IHttpClientResponseBag } from "../Helpers/Helper";

const AudienceService = {

    /**
     * Get Template Usage
     * @constructor
     */
    GetTemplateUsage: async (): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.getWithSession('GetTemplateUsage', {})

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (data.Success === undefined) reject({ err: 'Server error' })
            else resolve(data)

        })
    },

    /**
     * DocumentOnlyAudienceDetailByUserName
     * @constructor
     */
    GetBillingClientAudiences: async (): Promise<any> => {
        let { data }: any = await HttpRequest.getWithSession('DocumentOnlyAudienceDetailByUserName', {})

        return new Promise<any>((resolve, reject) => {
            if (!Array.isArray(data)) reject({ err: 'Server error' })
            else resolve(data)

        })
    },
    userBelongsToTemplateAudiece: async (AudienceMasterId: string) => {
        return await HttpRequest.getWithSession('DocumentOnlyAudienceDetailByAudienceMasterId', {
            AudienceMasterId:`${AudienceMasterId}`, FromRegistration: false
        })
    }
}

export default AudienceService