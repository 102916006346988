import HttpRequest from "./HttpRequest";
import Helper, {IHttpClientResponseBag, IRequest} from "../Helpers/Helper";


export interface IGetAllMerchantResponse {
    Id: number,
    MerchantId: string,
    Active: boolean,
    Comment: string,
    ContactEmailAddress: string,
    ContactEmailAddressSecondary: string,
    ContactFirstName: string,
    ContactLastName: string,
    ContactPhoneNumber: string,
    ContactPhoneNumberSecondary: string,
    OrganizationAddress: string,
    OrganizationCity: string,
    OrganizationCountry: string,
    OrganizationEIN: string,
    OrganizationName: string,
    OrganizationPostalCode: string,
    OrganizationState: string,
    Created?: Date
}

export interface ICreateUpdateRequest {
    Id: number,
    MerchantId: string,
    Active: boolean,
    Comment: string,
    ContactEmailAddress: string,
    ContactEmailAddressSecondary: string,
    ContactFirstName: string,
    ContactLastName: string,
    ContactPhoneNumber: string,
    ContactPhoneNumberSecondary: string,
    OrganizationAddress: string,
    OrganizationCity: string,
    OrganizationCountry: string,
    OrganizationEIN: string,
    OrganizationName: string,
    OrganizationPostalCode: string,
    OrganizationState: string,
    Operation: string,
    Created?: Date
}


export interface IDeleteRequest {
    Operation: string
    Id: number
}


const MerchantService = {

    /**
     * GetAllMerchant
     * @constructor
     */
    GetAllMerchant: async (): Promise<IGetAllMerchantResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('Merchant/GetAllMerchant', {})

        return new Promise<IGetAllMerchantResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * CreateUpdate
     * @param request
     * @constructor
     */
    CreateUpdate: async (request:ICreateUpdateRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('Merchant/CreateUpdate', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * Delete
     * @constructor
     */
    Delete: async (request:IDeleteRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.getWithSession('Merchant/Delete', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

}

export default MerchantService