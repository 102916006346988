import React, { useEffect } from 'react';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Modal } from '@fluentui/react/lib/Modal';
import { Text } from '@fluentui/react';
import { Separator } from '@fluentui/react/lib/Separator';
import '../../Custom.css';
import { isMobile } from 'react-device-detect';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IconButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import {useTranslation} from "react-i18next";
import {useBranding} from "../../hooks/useBranding";


const theme = getTheme();

const iconButtonStylesPrompt = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
            ':disabled': {
            },
        },
    },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

function ConfirmationModal(props: any) {
    const { branding } = useBranding()
    const {t} = useTranslation(['common']);
    useEffect(() => {

    }, [props.showConfirmationModal])

    const hideConfirmationModal = () => {
        props.setShowConfirmationModal(false)
    }

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 40,
        },
        containerSaveModal: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: isMobile ? null : '30vw',
            height: '42vh',
        },
        body: {
            width: '80vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    const hideNoButton = props.HideNoButton ?? false

    return (
        <>
            <Modal
                isOpen={props.showConfirmationModal}
                isBlocking={true}
                containerClassName={props.containerClassName === undefined? contentStyles.containerSaveModal : props.containerClassName}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id="Confirmation">{props.title}</span>
                                <IconButton
                                    styles={iconButtonStylesPrompt}
                                    iconProps={cancelIcon}
                                    ariaLabel={t("Common.Button.Close", {"ns": "common"})}
                                    onClick={hideConfirmationModal}
                                    title={t("Common.Button.Close", {"ns": "common"})}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg12">
                            <div className={contentStyles.bodyMail}>
                                <Text variant={"medium"} styles={{root: {color: branding.theme.palette.black}}}>
                                    {props.message}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={() => { props.callbackConfirmationModal(props.args) }} text={props.textYesButton ?? t("Common.Button.Yes", {"ns": "common"})} title={props.titleYesButton} />
                                { hideNoButton ? null : <DefaultButton onClick={hideConfirmationModal} text={t("Common.Button.Cancel", {"ns": "common"})} title={t("Common.Button.Cancel", {"ns": "common"})} /> }
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ConfirmationModal