import {FontWeights, getTheme, mergeStyleSets} from "@fluentui/react/lib/Styling";
import {Theme} from "@fluentui/react-theme-provider";

const theme = getTheme();


export const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

export interface IContentStyles {
    containerRecordPreview: string
    header: string
}

export const contentStyles = (theme: Theme): IContentStyles => {

    return mergeStyleSets({

        containerRecordPreview: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            maxWidth: '480px',
            width: '480px',
        },

        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],

    })
};