import {IStackStyles, mergeStyleSets} from "@fluentui/react";

export const _styles = mergeStyleSets({
    body: {
        flex: '4 4 auto',
        padding: 0
    }
})

export const nonShrinkingStackItemStyles: IStackStyles = {
    root: {
        padding: 12,
        overflow: 'hidden',
        alignItems: "center",
    },
}