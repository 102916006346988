import { DefaultButton, DialogFooter, Dropdown, IDropdownOption, Label, mergeStyleSets, PrimaryButton, Stack, TextField } from '@fluentui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IAssemblyActions, IContentLibrary, IDocumentLibrary } from '../../../interfaces/IDocumentAssembly'
import DocumentAssemblyService from '../../../services/assembly'
import { IStatusMessage } from '../../../interfaces/IApp'
import RichTextEditor, { EditorValue } from 'react-rte'
import { DEFAULT_RICHTOOLBAR } from '../../../Helpers/Utils'

type ExpressionsFormType = {
    type: 'create' | 'update'
    close: (loading: boolean, message?: IStatusMessage) => void
    templateId: number
    item?: IAssemblyActions
    ruleId: number
    ruleType: string
}
const actionInlineOptions: IDropdownOption[] = [
    { key: '', text: '' },
    { key: 'DELETE', text: 'DELETE' },
    { key: 'REPLACE', text: 'REPLACE' },
    { key: 'INSERT', text: 'INSERT' },
    { key: 'APPEND', text: 'APPEND' },
]
const actionMergeOptions: IDropdownOption[] = [
    { key: '', text: '' },
    { key: 'APPEND', text: 'APPEND' },
    { key: 'PREPEND', text: 'PREPEND' },
]
const defaultFormValues: IAssemblyActions = {
    ActionId: -1,
    ActionType: '',
    ActionOrder: 0,
    ActionDescription: '',
    TargetId: '',
    TargetAnchor: '',
    TargetPosition: '',
    sourceId: '',
    playbookId: -1,
    caseData: true,
    sourceContent: '',
    startAssembly: false,
    targetTemplateId: 0,
    templateIdentifier: '',
    continuePageNumber: true,
    formatSource: 'PARENT'
}
const defaultLayout: { [key: string]: boolean } = {
    TargetAnchor: false,
    TargetId: false,
    playbookId: false,
    sourceId: false,
    sourceContent: false,
    targetTemplateId: false
}
type DropdownFieldsType = 'TargetId' | 'playbookId' | 'sourceId' | 'targetTemplateId'
const AssemblyActionsForm = ({ ruleId, ruleType, close, templateId, item }: ExpressionsFormType) => {

    const [contentControls, setContentControls] = useState<IDropdownOption[]>([])
    const [contentTargetControls, setContentTargetControls] = useState<IDropdownOption[]>([])
    const [documentLibrary, setDocumentLibrary] = useState<IDropdownOption[]>([])
    const [contentLibrary, setContentLibrary] = useState<IContentLibrary[]>([])
    const [contentLanguages, setContentLanguages] = useState<IDropdownOption[]>([])

    const [editorState, setEditorState] = useState<EditorValue>(EditorValue.createEmpty())
    const formType: 'create' | 'update' = useMemo(() => { return Object.keys(item ?? {}).length === 0 ? 'create' : 'update' }, [item])
    const { t } = useTranslation('assembly')
    const { control, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm<IAssemblyActions>({
        defaultValues: useMemo(() => {

            return Object.keys(item ?? {}).length === 0 ? { ...defaultFormValues } : item
        }, [item])
    })
    const [layoutControls, setLayoutControls] = useState(defaultLayout)
    const handleDropdown = useCallback((field: ControllerRenderProps<IAssemblyActions, any> | undefined, fieldName: DropdownFieldsType, event: React.FormEvent, option?: IDropdownOption | undefined) => {
        if (option) {
            setValue(fieldName, option?.key as typeof defaultFormValues[typeof fieldName])
        }

        field?.onChange(option?.key)

        if (fieldName === 'sourceId') {
            const sourceContent = option?.key === ''
           
            if(sourceContent) setValue("playbookId", -1)
           
            setLayoutControls((prev) => { return { ...prev, sourceContent, playbookId: !sourceContent } })
            if (!sourceContent) {
                const language = contentLibrary.find((cc) => option?.key === cc.ContentControl.trim())
                if (language) {
                    let languages = language.LanguageOptions.map((lang) => {
                        return { key: lang.Id, text: lang.LanguageDescription }
                    })
                    languages = [{ key: -1, text: 'Use Source Tag as content' }, ...languages]
                    if(!language.LanguageOptions.find(x => x.Id === getValues("playbookId"))) {
                        setValue("playbookId", -1)
                    }
                                            
                    setContentLanguages(languages)
                }
                else {
                    //todo check
                    setValue("playbookId", -1)
                }
            }
            //reset(item)
        }

    }, [contentLibrary, setValue])
    const generateLayout = useCallback((data: { [key: string]: boolean }, values: IAssemblyActions = defaultFormValues) => {
        setLayoutControls(data)
        Object.keys(defaultLayout).forEach((key: string) => {
            if (key === 'targetTemplateId') {
                setValue(key, values?.targetTemplateId)
            } else if (key === 'playbookId') {
                setValue(key, values?.playbookId)
            } else if(key === 'sourceContent'){
                setEditorState(EditorValue.createFromString(values?.sourceContent, 'html'))
                setValue(key as DropdownFieldsType, `${values[key as DropdownFieldsType]}`)
            }else{
                //handleDropdown('sourceId', {} as React.FormEvent, { key: `${item?.sourceId}`, text: `${item?.sourceId}` })
                setValue(key as DropdownFieldsType, `${values[key as DropdownFieldsType]}`)
            }
        })
        //const isEmptySourceId = getValues('sourceId') === ''
        //const sourceContent = isEmptySourceId && data.sourceContent
        //setLayoutControls((prev) => { return { ...prev, sourceContent, playbookId: !isEmptySourceId } })
    }, [setValue])

    const getLayout = useCallback((option: string, values: { [key: string]: boolean } = defaultLayout): { [key: string]: boolean } => {

        switch (option) {
            case '':
                return values
            case 'DELETE':
                return { TargetId: true }
            case 'REPLACE':
                return { TargetId: true, sourceId: true, sourceContent: true }
            case 'INSERT':
                return { TargetAnchor: true, sourceId: true, sourceContent: true }
            case 'APPEND':
                const targetTemplateId = ruleType === 'MERGE'
                return { sourceId: !targetTemplateId, sourceContent: !targetTemplateId, targetTemplateId }
            case 'PREPEND':
                return { targetTemplateId: true }
            default:
                return values
        }
    }, [ruleType])

    const handleTypeDropdown = useCallback((event: React.FormEvent, option?: IDropdownOption | undefined) => {
        if (option) {
            setValue('ActionType', `${option.key}`)
            const layout: { [key: string]: boolean } = getLayout(`${option.key}`)
            generateLayout(layout)
        }
    }, [generateLayout, getLayout, setValue])


    function saveForm(formData: IAssemblyActions) {
        if (item && ruleId) {
            formData.caseData = formData.sourceId !== ''
            formData.ActionDescription = formData.ActionDescription?.trim()
                      
            if (!layoutControls.sourceContent) {
                formData.sourceContent = ''
            }
            if (ruleType !== 'MERGE') {
                formData.targetTemplateId = templateId
            }
            if (formType === 'create') {
                DocumentAssemblyService.createAssemblyAction(templateId, ruleId, JSON.stringify(formData)).then(({ data }: any) => {
                    if (data.Success === false) {
                        close(true, { Type: 'error', Message: <>Error creating <strong>{formData.ActionDescription}</strong>: {data.ExceptionMessage}</> })
                        return
                    }
                    close(true, { Type: 'success', Message: <>Action <strong>{formData.ActionDescription}</strong> added successfully</> })
                })
            } else {
                DocumentAssemblyService.updateAssemblyAction(templateId, ruleId, item.ActionId, JSON.stringify(formData)).then(({ data }: any) => {
                    if (data.Success === false) {
                        close(true, { Type: 'error', Message: <>Error updating <strong>{formData.ActionDescription}</strong>: {data.ExceptionMessage}</> })
                        return
                    }
                    close(true, { Type: 'success', Message: <>Action <strong>{formData.ActionDescription}</strong> added successfully</> })
                })
            }
        }
    }
    const modalClose = () => {
        close(false)
    }

    const handleRichTextChange = (value: EditorValue) => {
        setValue('sourceContent', value.toString('html'))
        setEditorState(value)
    }
    const validateActionType = (value: any) => {
        if (!value) {
            return "Required field";
        }
    }

    useEffect(() => {
        DocumentAssemblyService.getContentControls(templateId).then((controls) => {
            setContentControls([{ key: '', text: 'Use custom content' }, ...controls])
            setContentTargetControls([{ key: '', text: '' }, ...controls])
        })
        DocumentAssemblyService.getDocumentLibrary(templateId).then(({ data }: any) => {
            if (data.Success !== false) {
                setDocumentLibrary(() => {
                    const _documents: IDropdownOption[] = (data as IDocumentLibrary[]).map((doc) => {
                        return {
                            key: doc.TemplateId, text: doc.FileName
                        }
                    })
                    return [{ key: '', text: '' }, ..._documents]
                })
            }
        })
        DocumentAssemblyService.getContentLibrary(templateId).then(({ data }: any) => {
            if (data.Success !== false) {
                setContentLibrary(data as IContentLibrary[])
            }
        })
    }, [item, templateId])
    useEffect(() => {
        if (formType === 'update') {
            const playbookId = item?.sourceId !== ''
            const initLayout = getLayout(`${item?.ActionType}`)
            const typeLayout = ruleType === 'INLINE' ? { ...initLayout, playbookId: playbookId, sourceContent: !playbookId } : initLayout
            generateLayout(typeLayout, item)
            if (playbookId) {
                handleDropdown(undefined, 'sourceId', {} as React.FormEvent, { key: `${item?.sourceId}`, text: `${item?.sourceId}` })
            }

            //handleDropdown()
            //generateLayout(data, item)
            if (item?.sourceContent) {
                setEditorState(EditorValue.createFromString(item?.sourceContent, 'html'))
            }
        }
    }, [formType, generateLayout, getLayout, handleDropdown, item, ruleType])

    return (
        <form onSubmit={handleSubmit(saveForm)}>
            <div>

                <div className="scrollVisible" style={{
                    overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh"
                }}>
                    <Stack styles={{ root: { paddingRight: "10px" } }}>
                        <div className={contentStyles.section}>
                            <Label required>Description</Label>
                            <Controller name="ActionDescription" control={control} rules={{ required: true, pattern: /\S/ }}
                                render={({ field }) => <TextField onChange={field.onChange} value={field.value} />}
                            />
                            <span className={contentStyles.error}>{errors.ActionDescription && <p>This field is required</p>}</span>
                        </div>
                        {formType === 'update' ?
                            <div className={contentStyles.section}>
                                <Label required>Order</Label>
                                <Controller name="ActionOrder" control={control} rules={{ required: true }}
                                    render={({ field }) => <TextField type="number" onChange={field.onChange} value={`${field.value}`} />}
                                />
                                <span className={contentStyles.error}>{errors.ActionOrder && <p>This field is required</p>}</span>
                            </div> : null
                        }

                        <div className={contentStyles.section}>
                            <Label required>Action Type</Label>
                            <Controller name="ActionType" control={control} rules={{ required: true }}
                                render={({ field }) => <Dropdown selectedKey={field.value}
                                    {...field} onChange={(event, option) => {
                                        field.onChange(option?.key)
                                        handleTypeDropdown(event, option)
                                    }}
                                    options={ruleType === 'INLINE' ? actionInlineOptions : actionMergeOptions}
                                />}
                            />
                            <span className={contentStyles.error}>{errors.ActionType && <p>This field is required</p>}</span>
                        </div>
                        {layoutControls.TargetAnchor ? <div className={contentStyles.section}>
                            <Label required>Anchor</Label>
                            <Controller name="TargetAnchor" control={control} rules={{ required: true }}
                                render={({ field }) => <TextField prefix=".anchor_" onChange={field.onChange} value={field.value} />}
                            />
                        </div> : null}
                        {layoutControls.TargetId ? <div className={contentStyles.section}>
                            <Controller name="TargetId" control={control} rules={{ required: true }}
                                render={({ field }) => <Dropdown selectedKey={field.value}
                                    {...field} onChange={(event, option) => { return handleDropdown(field, 'TargetId', event, option) }} required label={'Target Tag'}
                                    options={contentTargetControls}
                                />}
                            />
                            <span className={contentStyles.error}>{errors.TargetId && <p>This field is required</p>}</span>
                        </div> : null}
                        {layoutControls.sourceId ? <div className={contentStyles.section}>
                            <Controller name="sourceId" control={control} rules={{ required: !layoutControls.sourceContent }}
                                render={({ field }) => <Dropdown selectedKey={field.value}
                                    {...field} onChange={(event, option) => { return handleDropdown(field, 'sourceId', event, option,) }} required={!layoutControls.sourceContent} label={'Source Tag'}
                                    options={contentControls}
                                />}
                            />
                            <span className={contentStyles.error}>{errors.sourceId && <p>This field is required</p>}</span>
                        </div> : null}

                        {layoutControls.playbookId ? <div className={contentStyles.section}>
                            <Controller name="playbookId" control={control}
                                render={({ field }) => <Dropdown selectedKey={field.value}
                                    {...field} onChange={(event, option) => { return handleDropdown(field, 'playbookId', event, option) }} label={'Content Library'}
                                    options={contentLanguages}
                                />}
                            />
                        </div> : null}
                        {layoutControls.targetTemplateId ? <div className={contentStyles.section}>
                            <Label required>Document Library</Label>
                            <Controller name="targetTemplateId" control={control} rules={{ required: true, min: 1 }}
                                render={({ field }) => <Dropdown selectedKey={field.value}
                                    {...field} onChange={(event, option) => { return handleDropdown(field, 'targetTemplateId', event, option) }}
                                    options={documentLibrary}
                                />}
                            />
                            <span className={contentStyles.error}>{errors.targetTemplateId && <p>This field is required</p>}</span>
                        </div> : null}
                        {layoutControls.sourceContent ? <div className={contentStyles.section}>
                            <Label>Content</Label>
                            <Controller name="sourceContent" control={control}
                                render={({ field }) =>
                                    <div id={field.name} style={{ height: "300px", paddingBottom: "10px" }}>
                                        <RichTextEditor editorClassName="richText" className="richText" toolbarConfig={DEFAULT_RICHTOOLBAR} value={editorState} onChange={handleRichTextChange} />
                                    </div>
                                }
                            />
                        </div> : null}
                    </Stack>
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <DialogFooter>
                        <PrimaryButton id="btnCreateUpdate" type="submit" text={formType === 'create' ? 'Create Action' : 'Update Action'} disabled={false} title={formType === 'create' ? 'Create Action' : 'Update Action'} />
                        <DefaultButton id="btnCancel" onClick={modalClose} text={t('formSettings.btnCancel')} title={t('formSettings.btnCancel')} />
                    </DialogFooter>
                </div>
            </div>
        </form>
    )
}
const contentStyles = mergeStyleSets({
    section: {
        padding: '0.5rem 0'
    },
    table: {
        width: '100%'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    checkBox: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '30px',
    },
    error: {
        color: '#a4262c'
    }

})
export default AssemblyActionsForm
