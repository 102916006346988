import { IChoiceGroupOption, IDropdownOption, IStackStyles, IStackTokens, mergeStyles, Separator, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { RHFDropDownListSingleSelectControl } from "../../../RHFControls/RHFDropDownListSingleSelectControl";
import { OAuth20Type, AuthenticationType } from "../../Models/Enums";
import { RHFChoiceGroupControl } from "../../../RHFControls/RHFChoiceGroupControl";
import RefreshTokenFlow from "./RefreshTokenFlow";
import ServerToServerFlowCC from "./ServerToServerFlowCC";
import ServerToServerFlowAssertion from "./ServerToServerFlowAssertion";
import Bearer from "./Bearer";
import Basic from "./Basic";

export interface SecurityProps {
    templateId: number;
    showSuccessMessage: (message: string) => void;
    showErrorMessage: (message: string) => void;
    isConfigurationTemplateReadMode?: boolean;
}

const verticalStyle = mergeStyles({
    height: "200px"
});

const stackStyles: IStackStyles = {
    root: {
        height: "350px"
    }
};

const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10
};

function Security(props: SecurityProps) {
    const { control, setValue, getValues, trigger, register, unregister, resetField } = useFormContext();
    const [authenticationType, setAuthenticationType] = useState<string>();
    const [oAuth20Type, setOAuth20Type] = useState<string>();

    useEffect(() => {
        register("AuthenticationType", { required: "Authentication Type is required." });
    }, []);

    useEffect(() => {
        if (getValues("AuthenticationType") !== undefined) {
            setUpAuthenticationType(getValues("AuthenticationType").key as AuthenticationType);
        }
        if (getValues("OAuth20Type") !== undefined) {
            setUpAuth20Type(getValues("OAuth20Type").key as OAuth20Type);
        }
    }, [authenticationType, oAuth20Type]);

    const onAuthenticationTypeChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        setValue("OAuth20Type", undefined);
        setUpAuth20Type(OAuth20Type.Custom);
        setUpAuthenticationType(item!.key as AuthenticationType);
    };

    const onOAuth20TypeChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
        setUpAuthenticationType(AuthenticationType.Custom);
        setOAuth20Type(option?.key as OAuth20Type);
        setUpAuth20Type(option?.key as OAuth20Type);
    };

    const setUpAuthenticationType = (authenticationType: AuthenticationType) => {
        if (props.isConfigurationTemplateReadMode) {
            register("BearerToken");
            register("Username");
            register("Password");
        } else {
            switch (authenticationType) {
                case AuthenticationType.Bearer:
                    register("BearerToken", { required: "Bearer Token is required." });
                    resetField("Username");
                    resetField("Password");
                    unregister(["Username", "Password"]);
                    break;
                case AuthenticationType.Basic:
                    resetField("BearerToken");
                    register("Username", { required: "User is required." });
                    register("Password", { required: "Password is required." });
                    unregister(["BearerToken"]);
                    break;
                default:
                    unregister(["BearerToken", "Username", "Password"]);
                    break;
            }
        }
        setAuthenticationType(authenticationType.toString());
    };

    const setUpAuth20Type = (oAuth20Type: OAuth20Type) => {
        if (props.isConfigurationTemplateReadMode) {
            register("AccessToken");
            register("RefreshToken");
            register("RedirectURI");
            register("AuthUrl");
            register("ResponseType");
            register("TokenUrl");
            register("GrantType");
            register("ClientId");
            register("ClientSecret");
            register("Scope");
            register("AuthorizationHeader");
            register("ScopeRequired");
            register("SSFCCUsername");
            register("SSFCCPassword");
            register("CompanyId");
            register("Code");
            register("CodeVerifier");
            register("ClientAssertionType");
            register("ClientAssertion");
            register("AssertionKeyName");
            register("Assertion");
            register("AssertionFile");
            register("AssertionContent");
            register("AssertionFileName");
            register("ServiceProvider");
        } else {
            switch (oAuth20Type) {
                case OAuth20Type.RefreshTokenFlow:
                    register("AccessToken", { required: "Access Token is required." });
                    register("RefreshToken");
                    register("RedirectURI", { required: "Redirect URI is required." });
                    register("AuthUrl", { required: "Auth URL is required." });
                    register("ResponseType", { required: "Response Type is required." });
                    register("TokenUrl", { required: "Token Url is required." });
                    register("GrantType", { required: "Grant Type is required." });
                    register("ClientId", { required: "Client Id is required." });
                    register("ClientSecret", { required: "Client Secret is required." });
                    register("Scope", { required: "Scope is required." });
                    register("AuthorizationHeader");
                    register("ScopeRequired");
                    resetField("SSFCCUsername");
                    resetField("SSFCCPassword");
                    resetField("CompanyId");
                    resetField("Code");
                    resetField("CodeVerifier");
                    register("ClientAssertionType");
                    register("ClientAssertion");
                    register("AssertionKeyName");
                    register("Assertion");
                    resetField("AssertionFile");
                    resetField("AssertionContent");
                    resetField("AssertionFileName");
                    resetField("ServiceProvider");
                    unregister(["SSFCCUsername", "SSFCCPassword", "CompanyId", "Code", "CodeVerifier", "AssertionFileName", "ServiceProvider"]);
                    break;
                case OAuth20Type.ServerToServerFlowCC:
                    resetField("AccessToken");
                    resetField("RefreshToken");
                    resetField("RedirectURI");
                    resetField("AuthUrl");
                    resetField("ResponseType");
                    register("TokenUrl", { required: "Token Url is required." });
                    register("GrantType", { required: "Grant Type is required." });
                    register("ClientId", { required: "Client Id is required." });
                    register("ClientSecret", { required: "Client Secret is required." });
                    register("Scope", { required: "Scope is required." });
                    register("ScopeRequired");
                    register("SSFCCUsername", { required: "User Name is required." });
                    register("SSFCCPassword", { required: "Password is required." });
                    register("AuthorizationHeader");
                    resetField("CompanyId");
                    resetField("Code");
                    resetField("CodeVerifier");
                    resetField("ClientAssertionType");
                    resetField("AssertionKeyName");
                    resetField("Assertion");
                    resetField("AssertionFile");
                    resetField("AssertionContent");
                    resetField("AssertionFileName");
                    resetField("ServiceProvider");
                    unregister(["AccessToken", "RefreshToken", "RedirectURI", "AuthUrl", "ResponseType", "CompanyId", "Code", "CodeVerifier", "ClientAssertionType", "AssertionKeyName", "Assertion", "AssertionFileName", "AssertionFile", "AssertionContent", "ServiceProvider"]);
                    break;
                case OAuth20Type.ServerToServerFlowAssertion:
                    resetField("AccessToken");
                    resetField("RefreshToken");
                    resetField("RedirectURI");
                    resetField("AuthUrl");
                    resetField("ResponseType");
                    register("TokenUrl", { required: "Token Url is required." });
                    register("GrantType", { required: "Grant Type is required." });
                    register("ClientId", { required: "Client Id is required." });
                    resetField("ClientSecret");
                    register("Scope", { required: "Scope is required." });
                    register("ScopeRequired");
                    resetField("SSFCCUsername");
                    resetField("SSFCCPassword");
                    register("CompanyId");
                    register("Code");
                    register("CodeVerifier");
                    register("ClientAssertionType", { required: "Client Assertion Type is required." });
                    register("AssertionKeyName", { required: "Assertion Key Name is required." });
                    register("Assertion", { required: "Assertion is required." });
                    register("AssertionFile", {
                        required: "Assertion is required.",
                        validate: {
                            validFile: (files: any) => {
                                let file = files[0];
                                if (file === null && file.size <= 0) {
                                    return "The Min file size is 1 kb and the max file size is 300 kb";
                                }

                                let filevalid = false;
                                const sizevalid = 300;
                                const extensions = ["pem", "txt"];
                                const typefile = file.name;
                                const type = typefile.split(".")[1];
                                filevalid = extensions.includes(type);
                                if (!filevalid) {
                                    return "The supported types are: .pem, .txt";
                                }

                                const fileSize = file.size;
                                const sizekb = fileSize / 1024;
                                const IsValidSize = sizekb <= sizevalid && fileSize > 0;
                                if (!IsValidSize) {
                                    return "The Max file size is 300 kb";
                                }

                                if (type === "pem") {
                                    const content = getValues("AssertionContent");
                                    const iBeginCertificate = content.indexOf("BEGIN CERTIFICATE");
                                    const iEndCertificate = content.indexOf("END CERTIFICATE");
                                    const iBeginPrivateKey = content.indexOf("BEGIN PRIVATE KEY");
                                    const iEndPrivateKey = content.indexOf("END PRIVATE KEY");
                                    const iBeginRSAPrivateKey = content.indexOf("BEGIN RSA PRIVATE KEY");
                                    const iEndRSAPrivateKey = content.indexOf("END RSA PRIVATE KEY");

                                    if ((iBeginCertificate < 0 || iEndCertificate < 0) &&
                                        (iBeginPrivateKey < 0 || iEndPrivateKey < 0)) {
                                        return "Invalid PEM file";
                                    }

                                    if ((iBeginCertificate < 0 || iEndCertificate < 0) &&
                                        (iBeginRSAPrivateKey < 0 || iEndRSAPrivateKey < 0)) {
                                        return "Invalid PEM file";
                                    }
                                }

                                if (type === "txt") {
                                    const content = getValues("AssertionContent");
                                    const iBeginRSAPrivateKey = content.indexOf("BEGIN RSA PRIVATE KEY");
                                    const iEndRSAPrivateKey = content.indexOf("END RSA PRIVATE KEY");

                                    if (iBeginRSAPrivateKey < 0 || iEndRSAPrivateKey < 0) {
                                        return "Invalid TXT file";
                                    }
                                }

                                if (filevalid && IsValidSize) {
                                    return true;
                                }
                            },
                        },
                    });
                    register("AssertionContent", { required: "Assertion is required." });
                    register("AssertionFileName");
                    register("ServiceProvider", { required: "Service Provider is required." });
                    register("AuthorizationHeader");
                    unregister(["AccessToken", "RefreshToken", "RedirectURI", "AuthUrl", "ResponseType", "ClientSecret", "SSFCCUsername", "SSFCCPassword"]);
                    break;
                default:
                    unregister(["AccessToken", "RefreshToken", "RedirectURI", "AuthUrl", "ResponseType", "TokenUrl", "GrantType", "ClientId", "ClientSecret", "Scope", "ScopeRequired", "CompanyId", "Code", "CodeVerifier", "ClientAssertionType", "ClientAssertion", "AssertionKeyName", "Assertion", "AssertionFileName", "AssertionFile", "AssertionContent", "ServiceProvider"]);
                    break;
            }
        }
        setOAuth20Type(oAuth20Type);
    };

    return (
        <Stack horizontal styles={stackStyles} tokens={stackTokens}>
            <Stack.Item className={verticalStyle}>
                <RHFDropDownListSingleSelectControl
                    placeholder="Select an option"
                    label="Authentication Type:"
                    options={Object.entries(AuthenticationType).map((item) => {
                        return { text: item[0], key: item[1] };
                    })}
                    id={"AuthenticationType"}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    onCallback={onAuthenticationTypeChange}
                    defaultValue={getValues("AuthenticationType")}
                    disabled={props.isConfigurationTemplateReadMode}
                />
            </Stack.Item>
            <Stack.Item className={verticalStyle}>
                <Separator vertical styles={{ root: [{ selectors: { "::before": { background: "rgb(128,128,128)" } } }] }}></Separator>
            </Stack.Item>
            <Stack.Item style={{ overflow: "auto" }}>
                {authenticationType === AuthenticationType.OAuth20 ? (
                    <Stack horizontal tokens={stackTokens}>
                        <Stack.Item>
                            <RHFChoiceGroupControl
                                id={"OAuth20Type"}
                                options={Object.entries(OAuth20Type)
                                    .filter((f) => f[0] !== OAuth20Type.Custom)
                                    .map((item) => {
                                        return { text: item[0], key: item[1] };
                                    })}
                                styles={{
                                    root: { paddingLeft: "10px" },
                                    flexContainer: { display: "flex" },
                                }}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                trigger={trigger}
                                onCallback={onOAuth20TypeChange}
                                defaultValue={getValues("OAuth20Type")}
                            />
                        </Stack.Item>
                    </Stack>
                ) : null}
                {authenticationType === AuthenticationType.OAuth20 &&
                    oAuth20Type === OAuth20Type.RefreshTokenFlow ? (
                    <RefreshTokenFlow
                        showSuccessMessage={props.showSuccessMessage}
                        showErrorMessage={props.showErrorMessage}
                        templateId={props.templateId}
                        isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode}
                    />
                ) : null}
                {authenticationType === AuthenticationType.OAuth20 &&
                    oAuth20Type === OAuth20Type.ServerToServerFlowCC ? (
                    <ServerToServerFlowCC
                        showSuccessMessage={props.showSuccessMessage}
                        showErrorMessage={props.showErrorMessage}
                        templateId={props.templateId}
                        isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode}
                    />
                ) : null}
                {authenticationType === AuthenticationType.OAuth20 &&
                    oAuth20Type === OAuth20Type.ServerToServerFlowAssertion ? (
                    <ServerToServerFlowAssertion
                        showSuccessMessage={props.showSuccessMessage}
                        showErrorMessage={props.showErrorMessage}
                        templateId={props.templateId}
                        isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode}
                    />
                ) : null}
                {authenticationType === AuthenticationType.Bearer ? (
                    <Bearer
                        showSuccessMessage={props.showSuccessMessage}
                        showErrorMessage={props.showErrorMessage}
                        templateId={props.templateId}
                        isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode}
                    />
                ) : null}
                {authenticationType === AuthenticationType.Basic ? (
                    <Basic
                        showSuccessMessage={props.showSuccessMessage}
                        showErrorMessage={props.showErrorMessage}
                        templateId={props.templateId}
                        isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode}
                    />
                ) : null}
            </Stack.Item>
        </Stack>
    );
}

export default Security;
