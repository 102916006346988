import { IDatePickerStrings } from "@fluentui/react";
import localization, { DateLocalizationType } from '../i18n/dates'

export const getDateStrings = (locale: string): IDatePickerStrings => {

    const { locale: _locale, localization: localeData } = getLocaleValid(locale)

    return {
        months: localeData.months,
        shortMonths: localeData.monthsShort,
        days: localeData.days,
        shortDays: localeData.daysShort,
        goToToday: localeData.today,
        prevMonthAriaLabel: ' ',
        nextMonthAriaLabel: ' ',
        prevYearAriaLabel: ' ',
        nextYearAriaLabel: ' ',
        closeButtonAriaLabel: ' ',
        monthPickerHeaderAriaLabel: ' ',
        yearPickerHeaderAriaLabel: ' ',
    }
}

export const getLocaleValid = (locale: string): { locale: string, localization: DateLocalizationType } => {
    const [baseLocale] = locale.split('-')


    let _locale = localization[locale] ? locale : baseLocale

    if (localization[_locale] === undefined) {
        _locale = 'en'
    }

    return { locale: _locale, localization: localization[_locale] }
}

/* Format example for IDatePickerStrings
const stringsBase: IDatePickerStrings = {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],

    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

    goToToday: new Date().toISOString(),
    prevMonthAriaLabel: '',
    nextMonthAriaLabel: '',
    prevYearAriaLabel: '',
    nextYearAriaLabel: '',
    closeButtonAriaLabel: '',
    monthPickerHeaderAriaLabel: '',
    yearPickerHeaderAriaLabel: '',
}

*/