import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import useAuth from "./useAuth"
import { isValidIdleTime, deleteIdleTime, validateIdleTime, setIdleTime } from "./useIdle"
import { isInTeams } from "../Helpers/Utils"


const exludedPaths = ['/campaign']
const useAppIdle = () => {
    const { logout } = useAuth()
    const location = useLocation()
    useEffect(() => {
        if (!isInTeams()) {
            if (exludedPaths.includes(location.pathname)) {
                return
            }
            if (location.pathname === '/' && !isValidIdleTime()) {
                deleteIdleTime()
            }
            validateIdleTime(logout)
            // window.addEventListener('beforeunload', handleTabClose);

            // return () => {
            //     window.removeEventListener('beforeunload', handleTabClose);
            // }
        }

    }, [location.pathname])

    useEffect(() => {
        
        setIdleTime()
        const interval = setInterval(() => {
            setIdleTime()
        }, 1000 * 60 * 5)

        return () => {
            clearInterval(interval)
        }
    })
}
export default useAppIdle;