import React, {useCallback, useEffect, useState} from 'react';
import {useId} from '@uifabric/react-hooks';
import {IIconProps} from '@fluentui/react/lib/Icon';
import {Text} from "@fluentui/react/lib/Text";

import {Modal} from '@fluentui/react/lib/Modal';
import {Stack} from '@fluentui/react/lib/Stack';
import {Separator} from '@fluentui/react/lib/Separator';
import {Spinner, SpinnerSize} from '@fluentui/react/lib/Spinner';
import '../../Custom.css';
import {LocalizationContext, LocalizationMap, SpecialZoomLevel, Viewer, Worker} from '@react-pdf-viewer/core';
import {toolbarPlugin, ToolbarSlot} from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import {DefaultButton, IconButton} from '@fluentui/react/lib/Button';
import {Panel, PanelType, StackItem} from '@fluentui/react';
import {useTranslation} from "react-i18next";
import en_ from './locales/en_US.json';
import es_ from './locales/es_ES.json';
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import {contentStyles, iconButtonStyles, panelHeader} from "./Style"
import Helper, {IDocumentRequest} from "../../Helpers/Helper";
import {thumbnailPlugin} from "@react-pdf-viewer/thumbnail";
import {isMobile} from "react-device-detect";
import {IPropertyBag} from "../DataPanel/DataPanel";
import {AttachmentSettings} from "../../interfaces/IPropertyBagSettings";
import ConfirmationModal from "../../Helpers/Modals/ConfirmationModal";
import {decode} from "base64-arraybuffer";
import {useBranding} from "../../hooks/useBranding";

const toolbarPluginInstance = toolbarPlugin();
const thumbnailPluginInstance = thumbnailPlugin();
const cancelIcon: IIconProps = {iconName: 'Cancel'};

type Properties = {
    title: string
    context: string
    id: number
    templateId: number
    showModal: boolean
    hideModal: any
}

function SimplePreviewScreen({showModal, hideModal, id, title, context, templateId}: Properties) {
    const { branding } = useBranding()
    const titleId = useId(id.toString());
    const {t} = useTranslation(['common', 'preview']);
    const localizationContextEs = es_ as any as LocalizationMap;
    const localizationContextEn = en_ as any as LocalizationMap;
    const [message, setMessage] = useState("")
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [downloadType, setDownloadType] = useState("pdf")
    const [showDonwloadConfirm, setShowDonwloadConfirm] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [tryAgain, setTryAgain] = useState(false)
    const [allowDocx, setAllowDocx] = useState(false);
    const {Toolbar} = toolbarPluginInstance;
    const [fileUrl, setFileUrl] = useState("")

    /**
     * Dismiss Panel
     */
    const dismissPanel = () => {
        setIsLoaded(false)
        setIsLoading(false)
        setShowMessage(false)
        setShowDonwloadConfirm(false)
        setMessage("")
        setTryAgain(false)
        setStatusMessageEnum(StatusEnum.Info)
        hideModal()
    }

    /**
     * Load record Preview
     */
    const loadRecordPreview = async () => {
        setTryAgain(false)
        setMessage("")
        setShowMessage(false)
        setIsLoading(true)
        setIsLoaded(false)
        const req: IDocumentRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            DocumentId: "-1",
            Id: id.toString(),
            IsPreview: true,
            IsDownload: false,
            IsDocx: false,
        };


        await Helper.getDocumentOnlyTemplateId(templateId.toString(), true)
            .then(async (data) => {

                let jsonBag: IPropertyBag = {
                    DocumentDelivery: "",
                    Clon: "",
                    Culture: "",
                    CultureName: "",
                    Released: false,
                    emailMoniker: "",
                    AllowDocX: false,
                    AllowSendMail: false,
                    DocumentCreation: false,
                    DocumentUpdate: false,
                    DocumentDelete: false,
                    DocumentShare: false,
                    TemplateEdit: false,
                    TemplateShare: false,
                    TemplateDelete: false,
                    DefaultDescription: "",
                    attachment: {} as AttachmentSettings,
                    SupressPreview: false,
                    RedirectURL: ""
                };


                try {
                    jsonBag = JSON.parse(data.PropertyBag) as IPropertyBag;
                    setAllowDocx(jsonBag.AllowDocX);
                    await Helper.getDocumentPreview(req).then(res => {
                        try {
                            if (res.Success) {

                                setFileUrl(`/Download/${res.UrlPath}?mimetype=${res.mimeType}`)
                                setIsLoaded(true)
                            } else {

                                if (res.ExceptionMessage !== undefined && res.ExceptionMessage === "No documents found") {
                                    setMessage(t('message.error', {
                                        ns: 'common',
                                        Error: t('message.notFound', {ns: 'common'})
                                    }))
                                } else {
                                    setMessage(t('message.blockError', {ns: 'common'}))
                                }

                                setStatusMessageEnum(StatusEnum.Error)
                                setShowMessage(true)
                                setTryAgain(true)
                            }

                        } catch (error:any) {
                            setMessage(t('message.error', {ns: 'common', Error: error.toString()}))
                            setStatusMessageEnum(StatusEnum.Error)
                            setShowMessage(true)
                        }

                    }).catch((error) => {
                        setMessage(t('message.error', {ns: 'common', Error: error.toString()}))
                        setStatusMessageEnum(StatusEnum.Error)
                        setShowMessage(true)

                    }).finally(() => {
                        setIsLoading(false)
                    })

                } catch (error:any) {
                    setMessage(t('message.error', {ns: 'common', Error: error.toString()}))
                    setStatusMessageEnum(StatusEnum.Error)
                    setShowMessage(true)
                }
            })
            .catch((error) => {
                setMessage(t('message.error', {ns: 'common', Error: error}))
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (showModal) {
            switch (context) {
                case "record":
                    loadRecordPreview().then()
                    break;
                default:
                    dismissPanel()
                    break;
            }
        }

    }, [showModal])


    const onDownload = () => {


        const download = async () => {
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id.toString(),
                IsPreview: false,
                IsDownload: true,
                IsDocx: downloadType === "docx"
            }
            setShowDonwloadConfirm(false)
            const res = await Helper.getDocumentDownload(req)

            if (res.Success === undefined || !res.Success) {

                const name = downloadType === "docx" ? `${res.Description}.docx` : `${res.Description}.pdf`
                try {
                    const decoded = decode(res.Content);
                    const file = new Blob(
                        [decoded], {type: res.MimeType});
                    const a = document.createElement("a");
                    a.href = window.URL.createObjectURL(file);
                    a.download = name
                    a.click();
                    setStatusMessageEnum(StatusEnum.Success)
                    setMessage(t('messages.success', {ns: 'preview'}))
                    setShowMessage(true)
                  
                } catch (error) {
                    setMessage(t('messages.error', {ns: 'preview', Error: error}))
                    setStatusMessageEnum(StatusEnum.Error)
                    setShowMessage(true)
                  
                }
            }
        }

        download().then()
    }

    /**
     * Loading PDF
     */
    const LoadPDFDocument = useCallback(() => {
        return (<>
            {showMessage &&
                <>
                    <Stack>
                        <StatusMessage status={statusMessageEnum} hasTimer={false}
                                       truncated={true}
                                       isMultiline={true}
                                       dismiss={true}
                                       setShowMessage={setShowMessage}>
                            <div>{message}</div>
                        </StatusMessage>
                    </Stack>
                </>
            }

            {tryAgain &&
                <>
                    <Stack grow={1} styles={{root: {justifyContent: "center", alignItems: "center"}}}>
                        <Text variant={"medium"} styles={{root: {marginBottom: 16}}}>
                            {t('text.errorAndTry', {ns: 'common'})}
                        </Text>
                        <DefaultButton onClick={() => {
                            loadRecordPreview().then()
                        }} style={{marginLeft: 8}}
                                       text={t('Common.Button.Try', {ns: 'common'})}
                                       title={t('Common.Button.Try', {ns: 'common'})}/>
                    </Stack>
                </>
            }

            {isLoading &&
                <>
                    <Stack grow={1} styles={{root: {justifyContent: "center", alignItems: "center"}}}>
                        <Spinner size={SpinnerSize.large} labelPosition="left"
                                 label={t('text.spinnerPreview', {ns: 'common'})}/>

                    </Stack>
                </>
            }

            {isLoaded && <>

                <LocalizationContext.Provider
                    value={Helper.getCulture() === "es" ? localizationContextEs : localizationContextEn}>

                    <Worker workerUrl="../pdf.worker.min.js">

                        <Stack horizontal disableShrink={false} styles={{
                            root: {
                                backgroundColor: '#eeeeee',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                alignItems: "center",
                                height: 48
                            }
                        }}>

                            <Toolbar>
                                {
                                    (props: ToolbarSlot) => {
                                        const {
                                            CurrentPageInput, GoToNextPage, GoToPreviousPage,
                                            NumberOfPages, ShowSearchPopover, Zoom, ZoomIn,
                                            ZoomOut,
                                        } = props;
                                        return (
                                            <>
                                                <StackItem styles={{root: {padding: "0px 2px"}}}>
                                                    <ShowSearchPopover/>
                                                </StackItem>
                                                <StackItem className="ms-hiddenMdDown"
                                                           styles={{root: {padding: "0px 2px"}}}>
                                                    <ZoomOut/>
                                                </StackItem>
                                                <StackItem styles={{root: {padding: "0px 2px"}}}>
                                                    <Zoom/>
                                                </StackItem>
                                                <StackItem styles={{root: {padding: "0px 2px"}}}>
                                                    <ZoomIn/>
                                                </StackItem>


                                                {!isMobile && sessionStorage.getItem("entityId") &&
                                                    <>
                                                        <StackItem styles={{root: {padding: "0px 0px"}}}>

                                                            <IconButton
                                                                iconProps={{iconName: "pdf"}}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    marginTop: -4,
                                                                    color: "#C80A0A"
                                                                }}
                                                                title={t("title.downloadPDF", {ns: "preview"})}
                                                                alt={t("title.downloadPDF", {ns: "preview"})}
                                                                className={contentStyles(branding.theme).fileIconImg}
                                                                onClick={!isMobile ? ((event) => {

                                                                    event.stopPropagation()
                                                                    event.preventDefault()
                                                                    setDownloadType("pdf")
                                                                    setShowDonwloadConfirm(true)
                                                                }) : undefined}
                                                                onTouchEnd={isMobile ? ((event) => {
                                                                    event.stopPropagation()
                                                                    setDownloadType("pdf")
                                                                    setShowDonwloadConfirm(true)
                                                                }) : undefined}
                                                            />

                                                        </StackItem>
                                                        {allowDocx &&
                                                            <StackItem styles={{root: {padding: "0px 8px"}}}>

                                                                <IconButton
                                                                    iconProps={{iconName: "WordDocument"}}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginTop: -4,
                                                                        color: "#2F5B98"
                                                                    }}
                                                                    title={t("title.downloadDOCX", {ns: "preview"})}
                                                                    alt={t("title.downloadDOCX", {ns: "preview"})}
                                                                    className={contentStyles(branding.theme).fileIconImg}
                                                                    onClick={!isMobile ? (() => {
                                                                        setDownloadType("docx")
                                                                        setShowDonwloadConfirm(true)
                                                                    }) : undefined}
                                                                    onTouchEnd={isMobile ? (() => {
                                                                        setDownloadType("docx")
                                                                        setShowDonwloadConfirm(true)

                                                                    }) : undefined}
                                                                />

                                                            </StackItem>
                                                        }
                                                    </>
                                                }


                                                <StackItem grow={1}>&nbsp;</StackItem>
                                                <StackItem styles={{root: {padding: "0px 2px"}}}>
                                                    <GoToPreviousPage/>
                                                </StackItem>
                                                <StackItem styles={{root: {padding: "0px 2px"}}}>
                                                    <CurrentPageInput/> / <NumberOfPages/>
                                                </StackItem>
                                                <StackItem styles={{root: {padding: "0px 2px"}}}>
                                                    <GoToNextPage/>
                                                </StackItem>
                                            </>
                                        )
                                    }
                                }
                            </Toolbar>
                        </Stack>
                        <Stack styles={{
                            root: {
                                borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
                                alignItems: "center",
                                height: "1000px"
                            }
                        }}
                               grow={1}
                        >

                            <Viewer
                                fileUrl={fileUrl}
                                defaultScale={SpecialZoomLevel.PageWidth}
                                plugins={[
                                    toolbarPluginInstance,
                                    thumbnailPluginInstance,
                                ]}
                            />
                        </Stack>


                    </Worker>
                </LocalizationContext.Provider>
            </>}

        </>)
    }, [showMessage, isLoaded, isLoading, allowDocx, tryAgain])

    return (

        <>

            <ConfirmationModal
                showConfirmationModal={showDonwloadConfirm}
                setShowConfirmationModal={setShowDonwloadConfirm}
                callbackConfirmationModal={onDownload}
                title={t('label.downloadConfirmation', {ns: 'preview'})}
                message={t('text.downloadConfirmation', {ns: 'preview'})}
                titleYesButton={t('title.yesButton', {ns: 'preview'})}
                containerClassName={contentStyles(branding.theme).downloadConfirmation}
            />

            <>
                <Modal
                    isOpen={showModal && !isMobile}
                    isBlocking={true}

                    styles={{

                        scrollableContent: {
                            display: "flex",
                            flexFlow: 'column',
                            width: '75vw',
                            height: '80vh'
                        }
                    }}
                >
                    {/*-- START HEADER --*/}

                    <Stack>
                        <Stack horizontal className={contentStyles(branding.theme).header}>

                            <StackItem grow={1}
                                       styles={{
                                           root: {
                                               textOverflow: "ellipsis",
                                               whiteSpace: "nowrap",
                                               overflow: "hidden",
                                           }
                                       }}>
                                <Text variant={"xLarge"} id={titleId}
                                      title={t('title.modalWithDocumentName', {ns: 'preview', Name: title, interpolation: {'escapeValue': false}})}>
                                    {t('title.modalWithDocumentName', {ns: 'preview', Name: title, interpolation: {'escapeValue': false}})}
                                </Text>
                            </StackItem>
                            <Stack horizontal>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                                    onClick={dismissPanel}
                                    title={t('Common.Button.Close', {ns: 'common'})}
                                />
                            </Stack>

                        </Stack>
                        <Separator/>
                    </Stack>
                    {/*-- END HEADER --*/}

                    {/*-- START BODY --*/}
                    <Stack style={{overflow: "hidden"}} tokens={{padding: 16}} grow={1}>
                        <LoadPDFDocument/>
                    </Stack>
                    {/*-- END BODY --*/}

                    {/*-- START FOOTER --*/}
                    <Stack>
                        <Separator/>


                        <Stack horizontal style={{overflow: "hidden"}} tokens={{padding: 16}}>

                            <Stack grow={1}>&nbsp;</Stack>
                            <Stack>

                                <DefaultButton onClick={dismissPanel} style={{marginLeft: 8}}
                                               text={t('Common.Button.Close', {ns: 'common'})}
                                               title={t('Common.Button.Close', {ns: 'common'})}/>
                            </Stack>
                        </Stack>
                    </Stack>
                    {/*-- END FOOTER --*/}

                </Modal>
            </>


            <>
                {/*-- START FOOTER --*/}
                <Panel
                    headerText={t('title.modalWithDocumentName', {ns: 'preview', Name: title})}
                    headerTextProps={panelHeader}
                    isBlocking={false}
                    isOpen={showModal && isMobile}
                    onDismiss={dismissPanel}
                    closeButtonAriaLabel={t('Common.Button.Close', {ns: 'common'})}
                    type={PanelType.smallFluid}
                    styles={{
                        content: {
                            display: "flex",
                            flexGrow: 1
                        },
                        scrollableContent: {
                            display: "flex",
                            flexGrow: 1
                        }

                    }}
                >
                    <Stack grow={1}>
                        <Stack grow={0}>
                            <Separator/>
                        </Stack>
                        <LoadPDFDocument/>
                    </Stack>
                </Panel>
                {/*-- END PANEL --*/}
            </>
        </>
    )
}


export default SimplePreviewScreen