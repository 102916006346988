import { IRequest } from "../../../Helpers/Helper";

export interface ICertificateModel {
    Id: string;
    Name: string;
    IsActive: boolean;
    PublicKey: string;
    Display: boolean;
    Params: string;
    Methods: number[];
}

export interface IGetCertificateRequest extends IRequest {
    TemplateId: number;
}

export interface ICreateCertificate extends IRequest {
    TemplateId: number;
    Name: string;
    IsActive: boolean;
    Methods: number[];
}

export interface IUpdateDeleteCertificate extends IRequest {
    TemplateId: number;
    Id: string;
    Name: string;
    IsActive: boolean;
    PublicKey: string;
}

export enum ApiIntegrationServices {
    Default,
    Create_Record,
    Update_Record,
    Retreive_Bulk_Download,
    Record_Status,
    Enterprise_Search_Record,
    Create_Record_Note,
    Record_Note,
    Create_Record_Attachment,
    Record_Attachment,
    Delete_Record_Attachment,
    Retrieve_Bulk_Download_With_Delta = 11
}