import { Toggle } from "@fluentui/react"
import React, { useEffect } from "react"
import useToggleLogs from "../hooks/useToggleLogs"

const ToggleLogs = () => {
    const { handleToggle, toggleStatus, toggleValue } = useToggleLogs()
    useEffect(() => {
        toggleStatus()
    }, [])
    return (
        <>
            {toggleValue !== null ?
                <Toggle id="toggleLogs" onText="Enable" offText="Disable" onChange={handleToggle} defaultChecked={toggleValue} title="Enable/Disable Kim Platform API Logs." />
                :
                null
            }

        </>
    )
}

export default ToggleLogs