import { mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import {Theme} from "@fluentui/react-theme-provider";

export interface IContentStyles {
    container: string
    footer: string
    header: string
    body: string
    callout: string
    importedList: string
   
}

export const contentStyles = (theme: Theme): IContentStyles => {

    return mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minWidth: '320px',
            width: '480px'
        },
        footer: [
            {
                flex: '1 1 auto',
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '0px 12px 24px 24px',
            },
        ],
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 12px 24px',
            overflowY: 'hidden',
            selectors: {
                p: {margin: '14px 0'},
                'p:first-child': {marginTop: 0},
                'p:last-child': {marginBottom: 0},
            },
        },
        callout: {
            width: 240,
            padding: '16px 8px',
        },
        importedList: [
            {
                paddingLeft: 8,
                listStyle: 'none',
                li: {
                    marginBottom: 8,
                    span: {
                        color: 'gray'
                    }
                }
            }
        ]

    })
}

export const confirmDialog = {  main: { maxWidth: 450 }  }


export const iconButtonStyles = {
    root: {
        marginLeft: '0px',
        marginTop: '4px',
        marginRight: '2px',
    },
    

}