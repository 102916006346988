import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import '../../../Custom.css';
import {Spinner, SpinnerSize, Stack, StackItem} from "@fluentui/react";
import {Text} from "@fluentui/react/lib/Text";
import {Separator} from "@fluentui/react/lib/Separator";
import {contentStyles, iconButtonStyles} from "./Style";
import {DefaultButton, IconButton} from "@fluentui/react/lib/Button";
import {Modal} from "@fluentui/react/lib/Modal";
import {IIconProps} from "@fluentui/react/lib/Icon";
import RenewalsAndAlertsService, {
    loadRecord,
    loadTemplate,
    ResponseGetContextRenewals
} from "../../RenewalsAndAlerts/RenewalsAndAlerts.service";
import Helper, {IHttpClientResponse} from "../../../Helpers/Helper";
import {CustomForm} from "./CustomForm";
import {IPropertyBag} from "../../DataPanel/DataPanel";
import RenewalsAndAlertsRenewal from "../../RenewalsAndAlerts/RenewalsAndAlertsRenewal";
import SimplePreviewScreen from "../../Preview/SimplePreviewScreen";
import {useModal} from '../../../hooks/useModal';
import QueueModal from '../../QueueModal/QueueModal';
import {IDocument} from "../../../App";
import {useBranding} from "../../../hooks/useBranding";

type Properties = {
    documentId: number
    showModal: boolean,
    onDismissModal: (update: boolean) => void,
    update: any
    selectionState: any
    onUpdateInterval: any
    templateOnlyList: any
    isUpdateInterval: any
}

function ShowCustomForm({
                            showModal,
                            onDismissModal,
                            onUpdateInterval,
                            isUpdateInterval,
                            update,
                            templateOnlyList,
                            selectionState,
                            documentId
                        }: Properties) {
    const {branding} = useBranding()
    const {t} = useTranslation(['renewalsalerts', 'common', 'workspace'])
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState("")
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const [isLoading, setIsLoading] = useState(false);
    const [previewTitle, setPreviewTitle] = useState("")
    const [showRenewalRenewal, setShowRenewalRenewal] = useState(false)

    const [currentTemplateId, setCurrentTemplateId] = useState(0)
    const [currentRecordId, setCurrentRecordId] = useState(0)

    const [showCustomForm, setShowCustomForm] = useState(false);
    const [previewPDF, setPeviewPDF] = useState(false);
    const [showPreview, setShowPreview] = useState(false)
    const [isRecordUpdate, setIsRecordUpdate] = useState(false)
    const [isRecordError, setIsRecordError] = useState(false);

    //region Form regin variables
    const [data, setData] = useState("");
    const [draftId, setDraftId] = useState<number>(-1);
    const [documentUpdate, setDocumentUpdate] = useState(false);
    const [iscoOwner, setiscoOwner] = useState(false);
    const [isOwner, setisOwner] = useState(false);
    const [isSubform, setIsSubform] = useState(false);
    const [supressPreview, setSupressPreview] = useState(false);
    const [culture, setCulture] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [ownerUser, setOwnerUser] = useState("");
    const [templateCategory, setTemplateCategory] = useState("");
    const [audienceMasterId, setAudienceMasterId] = useState("");
    const [templateVersion, setTemplateVersion] = useState("");
    const [audienceName, setAudienceName] = useState("");
    const [defaultDescription, setDefaultDescription] = useState("");
    const [documentDescription, setDocumentDescription] = useState("");
    const [emailMoniker, setEmailMoniker] = useState("");
    const [documentCreation] = useState(false);
    const [
        isprimaryDocumentIdForSecondary
    ] = useState(false);
    const [templateDescription, setTemplateDescription] = useState("");

    //region

    const [currentItem, setCurrentItem] = useState<ResponseGetContextRenewals>({
        AllowCheckInOut: false,
        AllowDocumentUpdate: false,
        AllowESign: false,
        AllowNotification: false,
        AudienceName: "",
        CoOwner: false,
        Executed: false,
        FullName: "",
        Owner: false,
        TemplatePropertyBag: "",
        TemplateVersion: "",
        TemplateId: 0,
        Description: "",
        DisplayName: "",
        LastNotificationCount: 0,
        Category: "",
        DocumentRecordId: 0,
        EmailBody: "",
        EmailSubject: "",
        OnBehalfUser: "",
        OnBehalfUserDisplayName: "",
        OnBehalfUserFullName: "",
        EmailTo: "",
        LastNotificationDate: "",
        NotifyTriggerDaysBeforeRenewal: 0,
        NotifyTriggerFrequency: "",
        RecordReference: "",
        RecordUpdated: "",
        RenewalDate: "",
        RenewalMonths: 0,
        Subject: "",
        TemplateDescription: "",
        TemplateName: "",
        UserName: "",
        Id: 0,
        IsPending: false,
        CheckedOut: false,
        Closed: false,
        Suspended: false
    });

    const QueueCustomModal = useModal()

    /**
     *
     */
    useEffect(() => {
        if (showModal) {

            onLoad()
        }
    }, [showModal])

    /**
     * On Before dismiss
     */
    const onBeforeDismissRenewal = () => {
        sessionStorage.removeItem("documentId")
        sessionStorage.removeItem("func")
        onDismissModal(false)
    }

    /**
     * getAudienceMasterId
     * @param templateId
     */
    const getAudienceMasterId = async (templateId: string) => {
        let templates = templateOnlyList.reduce(
            (acc: any, el: any) => acc.concat(el.links),
            []
        );
        let template = templates.find(
            (item: any) => item.key === templateId
        );
        if (template === undefined) {
            const templateResponse = await Helper.getDocumentOnlyTemplateId(templateId, true)
            return templateResponse?.DocumentOnlyAudienceId !== undefined ? templateResponse.DocumentOnlyAudienceId.toString() : ""

        }
        return template.DocumentOnlyAudienceId.toString();
    };

    /**
     * toggleCustomForm
     * @param open
     */
    const toggleCustomForm = (open: boolean) => {

        if (open) {
            onUpdateInterval(false);
            //onBeforeDismissRenewal()
            setShowCustomForm(open);
        } else {
            onUpdateInterval(true);
            setShowCustomForm(open);
            update()
        }
    };

    const toggleQueueModal = useCallback((open: boolean, isUpdate: boolean, isError: boolean) => {
        if (open) {
            QueueCustomModal.open(true)
            setIsRecordUpdate(isUpdate)
            setIsRecordError(isError)
        }
    }, [QueueCustomModal])

    useEffect(() => {
        if (!QueueCustomModal.isOpen && QueueCustomModal.data) {
            onUpdateInterval(true);
            update()
        }
    }, [QueueCustomModal.isOpen])


    /**
     * on Create Update
     */
    const onCreateUpdate = async () => {

        await loadRecord(currentRecordId).then(async (recordData) => {
            try {
                setPreviewTitle(recordData.Description)
                setCurrentItem({
                    ...currentItem,
                    DocumentRecordId: recordData.Id,
                    TemplateId: recordData.TemplateId,
                    Description: recordData.Description
                })
                setPeviewPDF(!supressPreview)

            } catch {

            }
        })

        RenewalsAndAlertsService.getContextRenewals({
            TemplateId: -1
        }).then(data => {

            try {
                const items: ResponseGetContextRenewals[] = JSON.parse(data.result)
                if (Array.isArray(items)) {
                    if (items.length > 0) {

                        const found = items.find((element => {
                            return element.DocumentRecordId === currentRecordId
                        }))

                        if (found !== undefined) {
                            setPeviewPDF(!supressPreview)
                            setShowRenewalRenewal(true)
                        } else {
                            if (!supressPreview) {
                                setShowPreview(true)
                            }
                        }
                    } else {
                        if (!supressPreview) {
                            setShowPreview(true)
                        }
                    }

                } else {
                    if (!supressPreview) {
                        setShowPreview(true)
                    }
                }

            } catch {

            }
        })

    }

    /**
     * on Before Dismiss Renewal
     * @param update
     * @param preview
     */
    const onBeforeDismissSetRenewal = (update: boolean = false, preview: boolean = false) => {
        setShowRenewalRenewal(false)
        if (preview) {
            setShowPreview(true)
        }
    }

    /**
     * Hide preview
     */
    const onHidePreview = () => {
        setShowPreview(false)
    }


    const allowUpdateOnLoad = (item: IDocument) => {
        if (item.Suspended || item.Closed || item.CheckedOut) {
            return false;
        }

        if (
            item.Owner ||
            item.CoOwner ||
            item.UserName === Helper.getUsername()
        ) {
            return true;
        }
        return true
    };

    /**
     * Or Record load
     */
    const onLoad = () => {

        setIsLoading(true)

        loadRecord(documentId).then(async (recordData) => {
            try {

                if (recordData.hasOwnProperty("Success")) {
                    const response: IHttpClientResponse = recordData as unknown as IHttpClientResponse
                    if (!response.Success) {
                        throw new Error(t('messages.recordNonexistent', {ns: 'renewalsalerts'}));
                    }
                }
                if (!allowUpdateOnLoad(recordData)) {
                    throw new Error(t('messages.recordUnAvaliable', {ns: 'renewalsalerts'}));

                } else {

                    await loadTemplate(recordData.TemplateId).then(async (templateData) => {

                        if (!recordData.AllowDocumentUpdate) {
                            onDismissModal(false)
                            setCurrentTemplateId(recordData.TemplateId)
                            setCurrentRecordId(recordData.Id)
                            setPreviewTitle(recordData.Description)
                            setShowPreview(true)

                        } else {

                            if (!templateData.ActiveSubscription) {
                                throw new Error(templateData.SubscriptionStatusMessage != null && templateData.SubscriptionStatusMessage != undefined ? templateData.SubscriptionStatusMessage : t('messages.noSuscription', {ns: 'renewalsalerts'}));
                            }

                            setTemplateVersion(recordData.TemplateVersion)
                            setAudienceName(templateData.AudienceName!)

                            setCurrentTemplateId(recordData.TemplateId)
                            setCurrentRecordId(recordData.Id)

                            setTemplateName(templateData.DisplayName!);
                            setTemplateDescription(recordData.Description);
                            setData(recordData.Data);

                            setIsSubform(false);

                            setisOwner(templateData.OwnerUserName!.toLowerCase().trim() === Helper.getUsername().toLowerCase().trim());
                            setiscoOwner(!!templateData.CoOwner);
                            setDocumentDescription(recordData.Description);

                            const dataPropertyBag: IPropertyBag = JSON.parse(templateData.PropertyBag!)
                            setCulture(dataPropertyBag.Culture);
                            setEmailMoniker(dataPropertyBag.emailMoniker);
                            setDocumentUpdate(dataPropertyBag.DocumentUpdate)
                            setSupressPreview(dataPropertyBag.SupressPreview)

                            setAudienceMasterId(await getAudienceMasterId(recordData.TemplateId.toString()))
                            setOwnerUser(recordData.UserName);
                            setTemplateCategory(recordData.Category);
                            onDismissModal(false)
                            toggleCustomForm(true);
                        }

                    })
                        .catch((error: any) => {
                            throw error
                        })
                        .finally(() => {

                        })

                }

            } catch (error: any) {
                setMessage(t('text.errorMessage1', {ns: 'common', Error: error.message ? error.message : error}))
                setShowMessage(true)
            } finally {
                sessionStorage.removeItem("documentId")
                sessionStorage.removeItem("func")
                setIsLoading(false)
            }
        })

    }

    return (

        <>

            {showCustomForm && (
                <CustomForm
                    allowDrafts={isSubform}
                    targetUser={Helper.getUsername()}
                    parentId={documentId}
                    draftId={draftId}
                    setDraftId={setDraftId}
                    showCustomForm={showCustomForm}
                    toggleCustomForm={toggleCustomForm}
                    siteSelected={Helper.getSiteSelected()}
                    accountId={Helper.getDefaultAccount()}
                    username={Helper.getUsername()}
                    sessionKey={Helper.getSessionKey()}
                    templateId={currentTemplateId.toString()}
                    templateName={templateName}
                    templateDescription={templateDescription}
                    data={data}
                    documentId={documentId.toString()}
                    update={onCreateUpdate}
                    culture={culture}
                    templateVersion={templateVersion.toString()}
                    isOwner={isOwner}
                    isCoOwner={iscoOwner}
                    ownerUser={ownerUser}
                    templateCategory={templateCategory}
                    audienceName={audienceName}
                    emailMoniker={emailMoniker}
                    documentCreation={documentCreation}
                    documentUpdate={documentUpdate}
                    isSubform={isSubform}
                    isprimaryDocumentIdForSecondary={
                        isprimaryDocumentIdForSecondary
                    }
                    onUpdateInterval={onUpdateInterval}
                    isUpdateInterval={isUpdateInterval}
                    defaultDescription={defaultDescription}
                    setDefaultDescription={setDefaultDescription}
                    documentDescription={documentDescription}
                    setDocumentDescription={setDocumentDescription}
                    audienceMasterId={audienceMasterId}
                    toggleQueueModal={toggleQueueModal}
                />
            )}

            <Modal
                isOpen={showModal}
                isBlocking={true}
                containerClassName={contentStyles(branding.theme).containerRecordPreview}
            >
                {/*-- START HEADER --*/}
                <Stack horizontal className={contentStyles(branding.theme).header}>
                    <StackItem grow={1}
                               styles={{root: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}}>
                        <Text variant={"xLarge"}
                              title={t('title.formLoad', {ns: 'workspace', Name: "name"})}>
                            {t('title.formLoad', {ns: 'workspace', Name: "name"})}
                        </Text>
                    </StackItem>
                    <Stack horizontal>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                            onClick={() => {
                                onBeforeDismissRenewal()
                            }}
                            title={t('Common.Button.Close', {ns: 'common'})}
                        />
                    </Stack>
                </Stack>
                <Separator/>

                {/*-- END HEADER --*/}

                {/*-- START BODY --*/}


                <Stack grow={1} tokens={{padding: 16}} style={{height: "30vh"}}>

                    {isLoading &&
                        <Stack grow={1}
                               style={{alignItems: "center", justifyContent: "center"}}>
                            <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})}
                                     ariaLive="assertive"/>
                        </Stack>
                    }

                    {showMessage &&
                        <Stack grow={1} tokens={{padding: 16}} style={{alignItems: "center", justifyContent: "center"}}>

                            <Text variant={"large"} styles={{root: {fontWeight: "500", marginBottom: 16}}}>
                                {t('title.recordUnAvaliable', {ns: 'renewalsalerts'})}
                            </Text>
                            <Text variant={"medium"}>
                                {message}
                            </Text>
                        </Stack>
                    }
                </Stack>

                {/*-- END BODY --*/}

                {/*-- START FOOTER --*/}
                <Separator/>


                <Stack horizontal styles={{root: {height: 48, marginBottom: 8, alignItems: "center"}}}>
                    <StackItem grow={1}>&nbsp;</StackItem>

                    <StackItem tokens={{padding: 8}}>
                        <DefaultButton
                            onClick={() => {
                                onBeforeDismissRenewal()
                            }}
                            styles={{root: {marginRight: 8}}}
                            text={t('Common.Button.Close', {ns: 'common'})}
                            title={t('Common.Button.Close', {ns: 'common'})}/>
                    </StackItem>
                </Stack>
                {/*-- START FOOTER --*/}


            </Modal>


            <SimplePreviewScreen
                showModal={showPreview}
                id={currentRecordId}
                templateId={currentTemplateId}
                title={previewTitle}
                context={"record"}
                hideModal={onHidePreview}
            />

            <QueueModal Modal={QueueCustomModal.Modal} close={QueueCustomModal.close} isUpdate={isRecordUpdate}
                        isLink={false} isError={isRecordError}/>

            <RenewalsAndAlertsRenewal
                showModal={showRenewalRenewal}
                onDismissModal={onBeforeDismissSetRenewal}
                item={{
                    Name: currentItem.Description,
                    Id: currentItem.DocumentRecordId,
                    TemplateId: currentItem.TemplateId,
                    RecordId: currentItem.DocumentRecordId
                }}
                previewPDF={previewPDF}
            />


        </>
    )
}

export default ShowCustomForm
