import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import '../../../Custom.css';
import RenewalsAndAlertsService, {
    loadPropertybag,
    loadRecord,
    loadTemplate,
    ResponseGetContextRenewals
} from "../RenewalsAndAlerts.service";
import {IColumn, ShimmeredDetailsList, Stack, StackItem} from "@fluentui/react";
import {Text} from "@fluentui/react/lib/Text";
import StatusMessage, {StatusEnum} from "../../../share/StatusMessage";
import {Separator} from "@fluentui/react/lib/Separator";
import {DetailsListLayoutMode, SelectionMode} from "office-ui-fabric-react/lib/DetailsList";
import {columnsRenewalsView} from "../RenewalsAndAlerts.data";
import SimplePreviewScreen from "../../Preview/SimplePreviewScreen";
import Helper from "../../../Helpers/Helper";
import {CustomForm} from "../../CustomTemplates/CustomForm/CustomForm";
import RenewalsAndAlertsEdit from "../RenewalsAndAlertsEdit";
import RenewalsAndAlertsRenewal from "../RenewalsAndAlertsRenewal";
import {IPropertyBag} from "../../DataPanel/DataPanel";
import { useModal } from '../../../hooks/useModal';
import QueueModal from '../../QueueModal/QueueModal';
import { useBranding } from '../../../hooks/useBranding';


type Properties = {
    templateId: number
    update: any
    selectionState: any
    onUpdateInterval: any
    templateOnlyList: any
    isUpdateInterval: any
}

function RenewalsDetailList({
                                templateId,
                                onUpdateInterval,
                                isUpdateInterval,
                                update,
                                templateOnlyList,
                                selectionState
                            }: Properties) {
    const{ branding } = useBranding()
    const {t} = useTranslation(['renewalsalerts', 'common'])

    const [statusMessageEnumPivot2, setStatusMessageEnumPivot2] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessagePivot2, setShowMessagePivot2] = useState(false)
    const [messagePivot2, setMessagePivot2] = useState("")
    const [contextRenewals, setContextRenewals] = useState<ResponseGetContextRenewals[]>([])
    const refContextRenewals = useRef(contextRenewals);
    const [isLoadingRenewals, setIsLoadingRenewals] = useState(false)
    const [renewalCount, setRenewalCount] = useState(0)
    const [showRenewalEdit, setShowRenewalEdit] = useState(false)
    const [showRenewalRenewal, setShowRenewalRenewal] = useState(false)
    const [showPreview, setShowPreview] = useState(false)

    const [previewTitle, setPreviewTitle] = useState("")
    const [currentRecordId, setCurrentRecordId] = useState(0)
    const [currentTemplateId, setCurrentTemplateId] = useState(0)
    const [showCustomForm, setShowCustomForm] = useState(false);
    const [previewPDF, setPeviewPDF] = useState(false);
    const [isRecordUpdate, setIsRecordUpdate] = useState(false);
    const [isRecordError, setIsRecordError] = useState(false);

    //region Form regin variables
    const [data, setData] = useState("");
    const [draftId, setDraftId] = useState<number>(-1);
    const [documentId, setDocumentId] = useState(-1);
    const [documentUpdate, setDocumentUpdate] = useState(false);
    const [iscoOwner, setiscoOwner] = useState(false);
    const [isOwner, setisOwner] = useState(false);
    const [isSubform, setIsSubform] = useState(false);
    const [culture, setCulture] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [ownerUser, setOwnerUser] = useState("");
    const [templateCategory, setTemplateCategory] = useState("");
    const [audienceMasterId, setAudienceMasterId] = useState("");
    const [templateVersion, setTemplateVersion] = useState("");
    const [audienceName, setAudienceName] = useState("");
    const [defaultDescription, setDefaultDescription] = useState("");
    const [documentDescription, setDocumentDescription] = useState("");
    const [emailMoniker, setEmailMoniker] = useState("");
    const [documentCreation] = useState(false);
    const [
        isprimaryDocumentIdForSecondary
    ] = useState(false);
    const [templateDescription, setTemplateDescription] = useState("");
    //region

    const [currentItem, setCurrentItem] = useState<ResponseGetContextRenewals>({
        AllowCheckInOut: false,
        AllowDocumentUpdate: false,
        AllowESign: false,
        AllowNotification: false,
        AudienceName: "",
        CoOwner: false,
        Executed: false,
        FullName: "",
        Owner: false,
        TemplatePropertyBag: "",
        TemplateVersion: "",
        TemplateId: 0,
        Description: "",
        DisplayName: "",
        LastNotificationCount: 0,
        Category: "",
        DocumentRecordId: 0,
        EmailBody: "",
        EmailSubject: "",
        EmailTo: "",
        LastNotificationDate: "",
        NotifyTriggerDaysBeforeRenewal: 0,
        NotifyTriggerFrequency: "",
        RecordReference: "",
        RecordUpdated: "",
        RenewalDate: "",
        RenewalMonths: 0,
        Subject: "",
        TemplateDescription: "",
        TemplateName: "",
        UserName: "",
        Id: 0,
        OnBehalfUser: "",
        OnBehalfUserDisplayName: "",
        OnBehalfUserFullName: "",
        IsPending: false,
        CheckedOut: false,
        Closed: false,
        Suspended: false
    });

    const [renewalsColumns, setRenewalsColumns] = useState<IColumn[]>([])
    const refRenewalsColumns = useRef(renewalsColumns);

    const QueueCustomModal = useModal()

    /**
     *
     */
    useEffect(() => {
        const columns = columnsRenewalsView(onEditViewRenewal, onPreview, onRecordForm, onSetNextRenewal, branding.theme)
        columns.forEach(c => c.onColumnClick = _onColumnClick);
        columns.forEach(c => c.key === "Actions" || c.key === "Preview" || c.key === "Form" ? c.onColumnClick = undefined : null);
        refRenewalsColumns.current = columns
        setRenewalsColumns(columns);
        loadRenewals(true).then()
    }, [])

    /**
     * _on Column Click
     * @param ev
     * @param column
     */
    const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {

        const newColumns: IColumn[] = refRenewalsColumns.current;
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        refRenewalsColumns.current = newColumns
        const newItems = _copyAndSort(refContextRenewals.current, currColumn.fieldName!, currColumn.isSortedDescending);
        setContextRenewals([...newItems])
        refContextRenewals.current = newItems
        setRenewalsColumns([...newColumns])
    }

    /**
     * Sort items in DetailList
     * @param items
     * @param columnKey
     * @param isSortedDescending
     */
    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;

        if (key === "LastNotificationDate" || key === "RenewalDate") {
            return items.slice(0).sort((a: T, b: T) => {
                const strA = a[key] as unknown as string;
                const strB = b[key] as unknown as string;
                const dateA = new Date(strA);
                const dateB = new Date(strB);

                dateA.setHours(0, 0, 0, 0)
                dateA.setHours(0, 0, 0, 0)
                dateB.setHours(0, 0, 0, 0)
                dateB.setHours(0, 0, 0, 0)

                return (isSortedDescending ? dateA < dateB : dateA > dateB) ? 1 : -1
            });
        } else {
            return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        }
    }

    /**
     * On View or Edit Renewal
     * @param recordRenewal
     */
    const onEditViewRenewal = (recordRenewal: ResponseGetContextRenewals) => {
        setCurrentItem(recordRenewal)
        setShowRenewalEdit(true)
    }

    /**
     * On Set Next Renewal
     * @param recordRenewal
     */
    const onSetNextRenewal = (recordRenewal: ResponseGetContextRenewals) => {
        setCurrentItem(recordRenewal)
        setPeviewPDF(false)

        setShowRenewalRenewal(true)
    }

    /**
     * On Preview
     * @param recordRenewal
     */
    const onPreview = (recordRenewal: ResponseGetContextRenewals) => {
        setPreviewTitle(recordRenewal.Description)
        setCurrentRecordId(recordRenewal.DocumentRecordId)
        setCurrentTemplateId(recordRenewal.TemplateId)
        setShowPreview(true)
    }


    /**
     * Status Message Pivot 2
     * @param message
     * @param status
     */
    const showStatusMessage2 = (message: string, status: StatusEnum) => {
        setMessagePivot2(message)
        setStatusMessageEnumPivot2(status)
        setShowMessagePivot2(true)
    }

    /**
     * Load renewals
     * @param clearmessages
     */
    const loadRenewals = async (clearmessages: boolean = false) => {

        setIsLoadingRenewals(true)
        setContextRenewals([])
        refContextRenewals.current = []
        if (clearmessages) {
            setMessagePivot2("")
            setShowMessagePivot2(false)
        }

        RenewalsAndAlertsService.getContextRenewals({
            TemplateId: templateId
        }).then(data => {

            try {
                const items: ResponseGetContextRenewals[] = JSON.parse(data.result)
                if (Array.isArray(items)) {
                    setRenewalCount(items.length)
                    const newItems = _copyAndSort(items, "LastNotificationDate", false);
                    setContextRenewals(newItems)
                    refContextRenewals.current = newItems
                } else {
                    showStatusMessage2(t('message.error', {
                        ns: 'common',
                        Error: data.result !== undefined ? data.result : data
                    }), StatusEnum.Error)
                }
            } catch (e) {
                showStatusMessage2(t('message.error', {
                    ns: 'common',
                    Error: e.toString()
                }), StatusEnum.Error)
            }

        }).catch(error => {
                showStatusMessage2(t('message.error', {ns: 'common', Error: error.toString()}), StatusEnum.Error)
            }
        ).finally(() => {
                setIsLoadingRenewals(false)
            }
        )
    }

    /**
     * on Before Dismiss
     * @param update
     */
    const onBeforeDismiss = (update: boolean = false) => {
        setShowRenewalEdit(false)
        if (update) loadRenewals().then()
    }

    /**
     * on Before Dismiss Renewal
     * @param update
     * @param preview
     */
    const onBeforeDismissRenewal = (update: boolean = false, preview: boolean = false) => {
        setShowRenewalRenewal(false)
        if (preview) onPreview(currentItem)
        if (update) loadRenewals().then()
    }


    /**
     * Hide preview
     */
    const onHidePreview = () => {
        setShowPreview(false)
    }

//region Form region
    /**
     * On Record form
     * @param recordRenewal
     */
    const onRecordForm = (recordRenewal: ResponseGetContextRenewals) => {
        setPreviewTitle(recordRenewal.Description)
        setCurrentRecordId(recordRenewal.DocumentRecordId)
        setCurrentTemplateId(recordRenewal.TemplateId)
        setCurrentItem(recordRenewal)
        setAudienceName(recordRenewal.AudienceName)

        loadRecord(recordRenewal.DocumentRecordId).then(async (recordData) => {
            try {
                setTemplateName(recordRenewal.DisplayName);
                setTemplateDescription(recordRenewal.Description);
                setTemplateVersion(recordRenewal.TemplateVersion.toString());
                setData(recordData.Data);
                setIsSubform(false);
                setDocumentId(recordRenewal.DocumentRecordId);
                setisOwner(recordRenewal.Owner);
                setiscoOwner(recordRenewal.CoOwner);
                setDocumentDescription(recordRenewal.Description);

                await loadPropertybag(recordRenewal.TemplatePropertyBag).then((dataPropertyBag) => {
                    setCulture(dataPropertyBag.Culture);
                    setEmailMoniker(dataPropertyBag.emailMoniker);
                    setDocumentUpdate(dataPropertyBag.DocumentUpdate)
                })

                setAudienceMasterId(await getAudienceMasterId(recordRenewal.TemplateId.toString()))
                setOwnerUser(recordRenewal.UserName!);
                setTemplateCategory(recordRenewal.Category!);
                toggleCustomForm(true);
            } catch {

            }
        })

    }

    /**
     * on Create Update
     */
    const onCreateUpdate = async () => {

        loadRenewals().then()
        loadTemplate(currentItem.TemplateId).then((templateData) => {
            try {
                if (typeof templateData.PropertyBag === "string") {
                    const jsonBag = JSON.parse(templateData.PropertyBag) as IPropertyBag;
                    loadRecord(currentItem.DocumentRecordId).then((recorData) => {
                        setCurrentItem({...currentItem, Description: recorData.Description})
                        setPeviewPDF(!jsonBag.SupressPreview)
                        setShowRenewalRenewal(true)
                    })

                }
            } catch {
            }
        })

        if (selectionState.getSelectedIndices().length >= 1)
            selectionState.setAllSelected(false);

        update();
    };

    /**
     * toggleCustomForm
     * @param open
     */
    const toggleCustomForm = (open: boolean) => {

        if (open) {
            onUpdateInterval(false);
            setShowCustomForm(open);
        } else {
            onUpdateInterval(true);
            setShowCustomForm(open);
        }
    };

    const toggleQueueModal = useCallback((open: boolean, isUpdate: boolean, isError:boolean)=> {
        if (open) {
            QueueCustomModal.open(true)
            setIsRecordUpdate(isUpdate)
            setIsRecordError(isError)
        }
    },[QueueCustomModal])

    useEffect(()=>{
        if (!QueueCustomModal.isOpen && QueueCustomModal.data) {
            loadRenewals()
        }
    },[QueueCustomModal.isOpen])

    /**
     * getAudienceMasterId
     * @param templateId
     */
    const getAudienceMasterId = async (templateId: string) => {
        let templates = templateOnlyList.reduce(
            (acc: any, el: any) => acc.concat(el.links),
            []
        );
        let template = templates.find(
            (item: any) => item.key === templateId
        );
        if (template === undefined) {
            const templateResponse = await Helper.getDocumentOnlyTemplateId(templateId, true)
            return templateResponse?.DocumentOnlyAudienceId !== undefined ? templateResponse.DocumentOnlyAudienceId.toString() : ""

        }
        return template.DocumentOnlyAudienceId.toString();
    };
//endregion

    return (

        <>

            <StackItem tokens={{padding: 8}}>

                <Text variant={'medium'}>
                    <p>{t('text.configureRenewal', {ns: 'renewalsalerts', AppName: branding.appName})}</p>
                </Text>
                {showMessagePivot2 &&
                    <>
                        <StatusMessage status={statusMessageEnumPivot2} hasTimer={false}
                                       truncated={true}
                                       isMultiline={true}
                                       dismiss={true}
                                       setShowMessage={showMessagePivot2}>
                            <div>{messagePivot2}</div>
                        </StatusMessage>
                        <Separator/>
                    </>
                }
            </StackItem>

            <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                   style={{
                       overflow: "hidden",
                       overflowY: "auto",
                       height: "50vh",
                       paddingRight: 24,
                       paddingBottom: 24
                   }}>


                <ShimmeredDetailsList
                    items={contextRenewals}
                    enableShimmer={isLoadingRenewals}
                    compact={false}
                    columns={renewalsColumns}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    isSelectedOnFocus={false}
                    setKey={"Id"}
                />

                {renewalCount === 0 &&
                    <p style={{textAlign: "center"}}>
                        <Text variant={'small'}>
                            <Trans>
                                {t('text.emptyRenewals', {ns: 'renewalsalerts'})}
                            </Trans>
                        </Text>
                    </p>
                }

            </Stack>

            <RenewalsAndAlertsEdit
                showModal={showRenewalEdit}
                onDismissModal={onBeforeDismiss}
                item={{
                    Name: currentItem.Description,
                    Id: 0,
                    TemplateId: currentItem.TemplateId,
                    RecordId: currentItem.DocumentRecordId
                }}
                lockAtStartup={true}
            />

            <RenewalsAndAlertsRenewal
                showModal={showRenewalRenewal}
                onDismissModal={onBeforeDismissRenewal}
                item={{
                    Name: currentItem.Description,
                    Id: 0,
                    TemplateId: currentItem.TemplateId,
                    RecordId: currentItem.DocumentRecordId
                }}
                previewPDF={previewPDF}
            />

            <SimplePreviewScreen
                showModal={showPreview}
                id={currentRecordId}
                templateId={currentTemplateId}
                title={previewTitle}
                context={"record"}
                hideModal={onHidePreview}
            />
            <QueueModal Modal={QueueCustomModal.Modal} close={QueueCustomModal.close} isUpdate={isRecordUpdate} isLink={false} isError={isRecordError}/>

            {showCustomForm && (
                <CustomForm
                    allowDrafts={isSubform}
                    targetUser={Helper.getUsername()}
                    parentId={documentId}
                    draftId={draftId}
                    setDraftId={setDraftId}
                    showCustomForm={showCustomForm}
                    toggleCustomForm={toggleCustomForm}
                    siteSelected={Helper.getSiteSelected()}
                    accountId={Helper.getDefaultAccount()}
                    username={Helper.getUsername()}
                    sessionKey={Helper.getSessionKey()}
                    templateId={currentTemplateId.toString()}
                    templateName={templateName}
                    templateDescription={templateDescription}
                    data={data}
                    documentId={documentId.toString()}
                    update={onCreateUpdate}
                    culture={culture}
                    templateVersion={templateVersion}
                    isOwner={isOwner}
                    isCoOwner={iscoOwner}
                    ownerUser={ownerUser}
                    templateCategory={templateCategory}
                    audienceName={audienceName}
                    emailMoniker={emailMoniker}
                    documentCreation={documentCreation}
                    documentUpdate={documentUpdate}
                    isSubform={isSubform}
                    isprimaryDocumentIdForSecondary={
                        isprimaryDocumentIdForSecondary
                    }
                    onUpdateInterval={onUpdateInterval}
                    isUpdateInterval={isUpdateInterval}
                    defaultDescription={defaultDescription}
                    setDefaultDescription={setDefaultDescription}
                    documentDescription={documentDescription}
                    setDocumentDescription={setDocumentDescription}
                    audienceMasterId={audienceMasterId}
                    toggleQueueModal={toggleQueueModal}
                />
            )}

        </>

    )
}


export default RenewalsDetailList
