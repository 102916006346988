import React from "react";
import HttpRequest from "../../services/HttpRequest";
import AuthService from "../../services/auth";

export interface IEmailServiceResponse {
    Owner: string
    ServiceEmail: string
    TemplateId: string
}

export interface IEmailServiceRequest {
    Owner: string
    TemplateId: string
}

export interface IAudienceMasterByTemplateIdRequest {
    TemplateId: string,
}

export interface IAudienceMasterByTemplateIdResponse {
    Id: number,
    Name: string
}

export interface IDocumentOnlyAudienceDetailByAudienceMasterIdRequest {
    AudienceMasterId: string,
    AvoidCache: boolean
}

export interface IDocumentOnlyAudienceDetailByAudienceMasterIdResponse {
    FullName?: string
    UserName?: string
}

export interface ISendTemplateLinkRequest {
    Personas: string
    TemplateId: string
    Subject: string
    Description: string
    Message: string
    DocumentOnlyPrimaryId: number
}

export interface ISendTemplateLinkResponse {
    success: boolean
    messageError: string
    result: string
    bag: string
}
export interface IgetDocumentOnlyTemplateIDBasicRequest {
    TemplateId: string,
    Autoprovision: boolean
    ignoreDocument: boolean
}




const FormTemplateLinkService = {
   
    /**
     * DocumentOnlyAudienceDetailByAudienceMasterId
     * @param request
     * @constructor
     */
    GetDocumentOnlyAudienceDetailByAudienceMasterId: async (request: IDocumentOnlyAudienceDetailByAudienceMasterIdRequest
    ): Promise<IDocumentOnlyAudienceDetailByAudienceMasterIdResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('DocumentOnlyAudienceDetailByAudienceMasterId', request)

        return new Promise<IDocumentOnlyAudienceDetailByAudienceMasterIdResponse[]>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },
    /**
     * SendTemplateLink
     * @param request
     * @constructor
     */
    PostSendTemplateLink: async (request: ISendTemplateLinkRequest
    ): Promise<ISendTemplateLinkResponse> => {
        let {data}: any = await HttpRequest.postWithSession('SendTemplateLink', request)

        return new Promise<ISendTemplateLinkResponse>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },

    /**
     * GetDocumentOnlyTemplateIDBasic
     * @param request
     * @constructor
     */
    GetDocumentOnlyTemplateIDBasic: async (request: IgetDocumentOnlyTemplateIDBasicRequest
    ): Promise<any> => {
        let {data}: any = await HttpRequest.getWithSession('getDocumentOnlyTemplateIDBasic', request)

        return new Promise<any>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    }

}

export default FormTemplateLinkService