import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useId, useConstCallback } from '@uifabric/react-hooks';
import { Stack } from '@fluentui/react/lib/Stack';
import { IconButton, PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Separator } from '@fluentui/react/lib/Separator';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { TextField } from '@fluentui/react/lib/TextField';
import '../../Custom.css'
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import Helper, {
    IAudienceIntersect,
    IDocumentOnlyAudienceDetail,
    IDocumentOnlyNoAttachmentRequest,
    ISendMailRequest,
    IServiceEmailRequest
} from '../../Helpers/Helper';
import { DetailsList, DetailsListLayoutMode, IBasePickerSuggestionsProps, IColumn, IPersonaProps, MarqueeSelection, NormalPeoplePicker, Panel, PanelType, SelectionMode, ValidationState } from '@fluentui/react';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Modal,  IModalStyles} from "@fluentui/react/lib/Modal"
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import {Checkbox} from "@fluentui/react/lib/Checkbox"
import { isMobile } from 'react-device-detect';
import { getRecordDescription } from '../../Helpers/functions';
import { MessageBar, MessageBarType} from '@fluentui/react';
import {useTranslation} from "react-i18next";
import StatusMessage from "../../share/StatusMessage";
import {useBranding} from "../../hooks/useBranding";
import ApplicationServiceEmailService, {
    IGetServiceEmailByTemplateIdAndOwnerRequest
} from "../../services/ApplicationServiceEmail";
import AttachmentService, {RequestAttachment} from "../../services/attachments";
import DOMPurify from "dompurify";


export interface IAttachmentResponse {
    Id: number;
    AccountId: number;
    DocumentId: number;
    MimeType: string;
    Name: string;
    Description: string;
    CreatedBy: string;
    UpdatedBy: string;
    DateTime: Date;
    FileExt: string;
    AllowEsignFromManualUpload: boolean;
}

const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const modalFormStyles: IModalStyles ={
    main:{
      width:'40vw',
      height:'90vh'
    },
    root:{
      
    },
    scrollableContent:{},
    layer:{},
    keyboardMoveIcon:{},
    keyboardMoveIconContainer:{}
  }


const cancelIcon: IIconProps = { iconName: 'Cancel' };
const stackTokens = { childrenGap: 50 };
const stackTokensEmail = { childrenGap: 0 };

function EmailWidget(props: any) {
    const { branding } = useBranding()
    const titleId = useId(props.modalId);
    const aList: IPersonaProps[] = []
    const [requiredSubject, setRequiredSubject] = useState(false)
    const [requiredMessage, setRequiredMessage] = useState(false)
    const [txtSubject, setTxtSubject] = useState("")
    const [txtMessage, setTxtMessage] = useState("")
    const [txtMessageError, setTxtMessageError] = useState("")

    const [docEmailList, setDocEmailList] = useState(aList)
    const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<IPersonaProps[]>(aList);
    const [toEmail, setToEmail] = useState("")
    const [messageUpdate, setMessageUpdate] = useState(0)
    const {t} = useTranslation(['common', 'recordsendmail'])
    const [attachments, setAttachments] = useState<any[]>([]);
    const [selectedAttachment, setSelectedAttachment] = useState<any[]>([]);
    const [selectionAttachment] = useState(new Selection({
        onSelectionChanged: () => {
            const selectionCount = selectionAttachment.getSelectedCount();
                if (selectionCount > 0) {
                    setSelectedAttachment(selectionAttachment.getSelection() as any)
                }
        }
    }))
    const [selectedEmails, setSelectedEmails] = useState<IPersonaProps[]>([])

    const [chkDocument, setChkDocument] = useState(false);
    const [docType, setDocType] = useState("PDF");
    const [fromApplicationServiceEmail, setFromApplicationServiceEmail] = useState("")
    const [fromApplicationOwner, setFromApplicationOwner] = useState("")
    const [profileServiceEmail, setProfileServiceEmail] = useState(false);
    const [applicationServiceEmail, setApplicationServiceEmail] = useState(false);

    const panelHeader: HTMLAttributes<HTMLDivElement> = {
        style: {
            fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
            fontWeight: "bolder",
            color: branding.theme.palette.themePrimary,
            fontSize: 20
        }
    }
    
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '59vw',
            height: '68vh',
            flex: '4 4 auto',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: t('label.colAttachmentName', { ns: 'recordsendmail'}),
            fieldName: 'Name',
            minWidth: 100,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.colSortedAsc', { ns: 'recordsendmail'}),
            sortDescendingAriaLabel: t('label.colSortedDesc', { ns: 'recordsendmail'}),
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <span title={item.Name.trim() + " " + item.FileExt.trim()}>{item.Name.trim() + " " + item.FileExt.trim()}</span>
            }
        },
        {
            key: 'column4',
            name: t('label.colDate', { ns: 'recordsendmail'}),
            fieldName: 'DateTime',
            minWidth: 100,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.colSortedAsc', { ns: 'recordsendmail'}),
            sortDescendingAriaLabel: t('label.colSortedDesc', { ns: 'recordsendmail'}),
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <span title={item.DateTime !== null ? Helper.getLocateDate(item.DateTime, 2)  : ""}>{item.DateTime !== null ? Helper.getLocateDate(item.DateTime, 2) : ""}</span>;
            }
        },
        {
            key: 'column3',
            name: t('label.colDescription', { ns: 'recordsendmail'}),
            fieldName: 'Description',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.colSortedAsc', { ns: 'recordsendmail'}),
            sortDescendingAriaLabel: t('label.colSortedDesc', { ns: 'recordsendmail'}),
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <span title={item.Description}>{item.Description}</span>
            }
        }
    ]
    const options: IChoiceGroupOption[] = [
        { key: 'PDF', text: 'PDF' },
        { key: 'DocX', text: 'DocX' },
        { key: 'DocXandPDF', text: 'Both' }
      ];

    useEffect(() => {
        if(props.showModal)
        {
            setMessageUpdate(0)
           

            if (!attachments.length) {
                loadAttachments();
            }
            getEmails()
            getApplicationServiceEmail()

        }
    }, [props.showModal]);

    const getApplicationServiceEmail = async()=>{

        const shareEmail = await Helper.getShareEmail()
        setFromApplicationServiceEmail(shareEmail)
        setFromApplicationOwner(shareEmail)

        let request: IGetServiceEmailByTemplateIdAndOwnerRequest = {
            TemplateId: "-1",
            Owner: Helper.getUsername()
        }

        await ApplicationServiceEmailService.GetServiceEmailByTemplateIdAndOwner(request)
            .then(async (data) => {
                if (data.length > 0) {
                    setFromApplicationOwner(data[0].Owner)
                    setFromApplicationServiceEmail(data[0].ServiceEmail)
                    setProfileServiceEmail(true)
                    setApplicationServiceEmail(false)
                } else {

                    request = {
                        TemplateId: props.templateId.toString(),
                        Owner: Helper.getUsername()
                    }
                    
                    await ApplicationServiceEmailService.GetServiceEmailByTemplateIdAndOwner(request)
                    .then((data2)=> {
                        if (data2.length > 0) {
                            setFromApplicationOwner(data2[0].Owner)
                            setFromApplicationServiceEmail(data2[0].ServiceEmail)
                            setProfileServiceEmail(false)
                            setApplicationServiceEmail(true)
                        } else {
                            setFromApplicationOwner(shareEmail)
                            setFromApplicationServiceEmail(shareEmail)
                            setProfileServiceEmail(false)
                            setApplicationServiceEmail(false)
                        }
                    })
                    .catch()
                    .finally()                   
                    
                }
            })
            .catch()
            .finally()

    }
    const loadAttachments = async () => {

        const request:RequestAttachment = {
            DocSource: "",
            EndUserName: "",
            TemplateId: 0, 
            Username: "",
            DocumentDraftId: -1,
            DocumentId: props.id
                }

        await AttachmentService.GetDocumentOnlyAttachmentsByDocumentId(request)
        .then((data)=>{
            const attachments = data.data.attachments as IAttachmentResponse[]
            setAttachments(attachments)
        })
        .catch()
        .finally()
    }

    const getKey = (item: IDocumentOnlyAudienceDetail, index?: number): string => {
        if (item !== undefined)
            return item.Id.toString()
        return ""
    }

    const getEmails = async () => {
        const req: IAudienceIntersect = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            DocumentId: props.id,
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
        }
        Helper.getAudienceIntersect(req).then((response: any[]) => {
            if (response !== undefined) {
                var emails = response.map<IPersonaProps>(item => {
                    return { text: item.UserName, }
                })
                setDocEmailList(emails)
                setMostRecentlyUsed(emails)
            }
        })
    }

    const sendMail = () => {
        setMessageUpdate(0)

        const send = async () => {
            props.setSpinnerText(t('text.spinnerSending', { ns: 'common'}))
            props.setLayoutLoaded(false)

            const username = Helper.getUsername()
            const accountId = Helper.getDefaultAccount()
            const sessionKey = Helper.getSessionKey()
            const id = props.id.toString()
           
            const subject = DOMPurify.sanitize(txtSubject, { USE_PROFILES: { html: false } });
            const message = DOMPurify.sanitize(txtMessage, { USE_PROFILES: { html: false } });
            
            const site = await Helper.getSite()!
            const templateID = profileServiceEmail? "-1" : applicationServiceEmail? props.templateId.toString() : "-101"


            const req: ISendMailRequest = {
                Id: id,
                DocumentId: id,
                FromEmail: fromApplicationServiceEmail,
                ToEmail: toEmail,
                EmailSubject: subject,
                EmailBody: message,
                AttachmentIds: selectedAttachment.map(item => item.Id).join(","),
                SendDocument:chkDocument,
                EmailMoniker: props.emailMoniker,
                FullMoniker: !profileServiceEmail && !applicationServiceEmail? "" : fromApplicationServiceEmail + ":" + props.emailMoniker ,
                TemplateId: templateID,                
                DocumentDelivery: docType
            }


            await Helper.SendMailHtml(req)
                .then(result => {

                    if (result.Success) {
                        setMessageUpdate(1)
                        setTimeout(function () {
                            closeModal();
                        }, 500);

                    } else {
                        setTxtMessageError(result.ExceptionMessage)
                        setMessageUpdate(-1)
                        // props.setLayoutLoaded(true)
                    }
                })
                .catch((error: any) => {
                    setTxtMessageError(error.toString())
                    setMessageUpdate(-1)
                })
                .finally(() => {
                    props.setSpinnerText("")
                })

            // var url = "SendMailHtml"
            // const raw = await fetch(url, {
            //     method: "POST",
            //     headers: await Helper.getHeaders({
            //         'Content-Type': 'application/json',
            //         'Accept-Language': !!sessionStorage.getItem("language") ? sessionStorage.getItem("language")!.toString() : 'en'
            //     }),  
            //     body: JSON.stringify(req)
            // })
            // try {
            //     const result = await raw.json()
            //     if (result.Success) {
            //         setMessageUpdate(1)
            //         setTimeout(function () {
            //             closeModal();
            //         }, 500);
            //        
            //     } else {
            //         setTxtMessageError(result.ExceptionMessage)
            //         setMessageUpdate(-1)
            //         // props.setLayoutLoaded(true)
            //     }
            // }
            // catch (error:any) {
            //     setTxtMessageError(error.toString())
            //     setMessageUpdate(-1)
            // }
            // finally {
            //     props.setSpinnerText("")
            // }
        }
        send();
        
    }

    const _onChangeSubjectTxt = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setTxtSubject(newValue!)

            if (newValue !== null && newValue?.trim().length !== 0)
                setRequiredSubject(true)
            else
                setRequiredSubject(false)
        },
    );

    const _onChangeMessageTxt = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setTxtMessage(newValue!)

            if (newValue !== null && newValue !== "")
                setRequiredMessage(true)
            else
                setRequiredMessage(false)
        },
    );

    const closeModal = () => {
        setSelectedEmails([])
        setAttachments([])
        setSelectedAttachment([])
        setRequiredMessage(false)
        setRequiredSubject(false)
        setToEmail("")
        setTxtSubject("")
        setTxtMessage("")
        setDocType("PDF")
        setChkDocument(false)
        setProfileServiceEmail(false)
        setApplicationServiceEmail(false)
        setFromApplicationOwner("")
        setFromApplicationServiceEmail("")
        selectionAttachment.setAllSelected(false);
        props.hideModal()
    }

    const onFilterChanged = (filterText: string, currentPersonas?: IPersonaProps[]): IPersonaProps[] => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);

            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = filteredPersonas.slice(0, 5);
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterPersonasByText = (filterText: string): IPersonaProps[] => {
        return docEmailList.filter(item => doesTextStartWith(item.text as string, filterText));
    };

    const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] => {
        return personasToReturn;
    };

    const onItemSelectedd = (item?: IPersonaProps): IPersonaProps | null => {
        if (item) {
            return item
        }
        else {
            return null
        }
    }

    const isEmail = (value: string) => {
        return value !== undefined;
    }

    const onRemoveSuggestion = (item: IPersonaProps): void => {
        const indexPeopleList: number = docEmailList.indexOf(item);
        const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople: IPersonaProps[] = docEmailList
                .slice(0, indexPeopleList)
                .concat(docEmailList.slice(indexPeopleList + 1));
            setDocEmailList(newPeople);
        }

        if (indexMostRecentlyUsed >= 0) {
            const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
                .slice(0, indexMostRecentlyUsed)
                .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
            setMostRecentlyUsed(newSuggestedPeople);
        }
    };

    const returnMostRecentlyUsed = (currentPersonas?: IPersonaProps[]): IPersonaProps[] => {
        return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
    };

    function doesTextStartWith(text: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    }

    function removeDuplicates(personas: IPersonaProps[], possibleDupes?: IPersonaProps[]) {
        return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
    }

    function listContainsPersona(persona: IPersonaProps, personas?: IPersonaProps[]) {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter(item => item.text === persona.text).length > 0;
    }

    function getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }

    function validateInput(input: string): ValidationState {
        var mailformat = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);

        if (mailformat.test(input.toLowerCase())) {
            return ValidationState.valid;
        }
        else {
            return ValidationState.invalid;
        }
    }

    function onInputChange(items?: any[], input?: string): string {
        if(items != undefined && (input == undefined || input == "")) {
            var emails = items?.map(item => item.text).filter(isEmail).concat(items?.map(item => item.key).filter(isEmail)).join(';')
            setToEmail(emails!)
            if(emails!.length>0) {
                setSelectedEmails(emails!.split(";").map( e => {return { text: e }}))
            }
            else {
                setSelectedEmails([])
            }
            return ""
        }
        if(input != undefined && input != "" && items == undefined) {

            if (selectedEmails.length>0 && input == "")
                return input

            var lastChar = input.substring(input.length-1, input.length)
            if(lastChar == ";") {
                input = input.substring(0, input.length-1)
            }

            var validInput= validateInput(input)
            if(validInput===ValidationState.valid) {
                if(lastChar ==";") {
                    selectedEmails?.push({ text: input})
                    setSelectedEmails(selectedEmails)
                    input="";
                }
            
                if(selectedEmails.length>0 && input.length>0)
                    setToEmail(selectedEmails!.map(item => item.text).join(";") + ";" + input)
                else if(selectedEmails.length == 0 && input.length > 0) {
                    setToEmail(input)
                } else {
                    setToEmail(selectedEmails!.map(item => item.text).join(";"))
                }
            }
            else{
                setToEmail("");
            }
            return input;
        }
        return ""
    }

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: t('text.suggestionsHeaderText', { ns: 'recordsendmail'}),
        mostRecentlyUsedHeaderText: t('text.mostRecentlyUsedHeaderText', { ns: 'recordsendmail'}),
        noResultsFoundText: t('text.noResultsFoundText', { ns: 'recordsendmail'}),
        loadingText: t('text.spinner', { ns: 'common'}),
        showRemoveButtons: false,
        suggestionsAvailableAlertText: t('text.suggestionsAvailableAlertText', { ns: 'recordsendmail'}),
        suggestionsContainerAriaLabel: t('text.suggestionsContainerAriaLabel', { ns: 'recordsendmail'})
    };
    function onClickCheckDoc(ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) {
      
        if(isChecked!=undefined)
          setChkDocument(isChecked)
      }
      function _onChangeType(ev?: React.FormEvent<HTMLElement| HTMLInputElement>, option?: IChoiceGroupOption): void {
        setDocType(option?.key!)
      }


    return !isMobile?(
        <Modal
            isOpen={props.showModal}
            isBlocking={true}
            containerClassName={contentStyles.container}
            styles={modalFormStyles}
            >
                <Stack tokens={stackTokensEmail}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.header}>
                                    <span id={titleId}>{getRecordDescription(t('title.modalEmail', { ns: 'recordsendmail'}), props.documentDescription)}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                        onClick={closeModal}
                                        title={t('Common.Button.Close', { ns: 'common'})}
                                    />
                                </div>
                                
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className=" ms-Grid-col ms-sm12 ms-md12 ms-lg12">


                            {messageUpdate === 0 &&
                                <StatusMessage status={"info"} hasTimer={false} truncated={true}
                                               isMultiline={true}
                                               dismiss={false}
                                               setShowMessage={undefined}>
                                    <div>{t('messages.infoEmail', {ns: 'recordsendmail'})}</div>
                                </StatusMessage>
                            }

                            {messageUpdate === 1 &&

                                <StatusMessage status={"success"} hasTimer={false} truncated={true}
                                               isMultiline={true}
                                               dismiss={true}
                                               setShowMessage={ () => { setMessageUpdate(0) }}>
                                    <div>{t('messages.successSent', {ns: 'recordsendmail'})}</div>
                                </StatusMessage>
                            }

                            {messageUpdate === -1 &&

                                <StatusMessage status={"error"} hasTimer={false} truncated={true}
                                               isMultiline={true}
                                               dismiss={true}
                                               setShowMessage={ () => { setMessageUpdate(0) }}>
                                    <div>{t('messages.errorSend', {
                                        ns: 'recordsendmail',
                                        Description: txtMessageError
                                    })}</div>
                                </StatusMessage>
                            }                               

                                
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                <ScrollablePane>
                                <div className="ms-Grid-col ms-sm12 ms-lg12" style={{paddingLeft:"20px", paddingRight:"20px"}}>
                                    <Pivot aria-label={t('ariaLabel.pivotEmail', { ns: 'recordsendmail'})}>
                                        <PivotItem headerText={t('label.pivotEmail', { ns: 'recordsendmail'})}>
                                            <br/>
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <TextField disabled label={t('label.emailFrom', { ns: 'recordsendmail'})} value={fromApplicationServiceEmail} />
                                                        
                                                        <div className="ms-TextField-wrapper">
                                                            <label><span style={{ color: "#323130" }}>{t('label.emailTo', { ns: 'recordsendmail'})} </span><span style={{ color: "#a4262c" }}>*</span></label>
                                                        </div>
                                                        <NormalPeoplePicker
                                                            onResolveSuggestions={onFilterChanged}
                                                            onEmptyInputFocus={returnMostRecentlyUsed}
                                                            getTextFromItem={getTextFromItem}
                                                            pickerSuggestionsProps={suggestionProps}
                                                            className={'ms-PeoplePicker'}
                                                            onChange={(items) => { return onInputChange(items, undefined) }}
                                                            key={'normal'}
                                                            onRemoveSuggestion={onRemoveSuggestion}
                                                            onValidateInput={validateInput}
                                                            removeButtonAriaLabel={t('ariaLabel.remove', { ns: 'recordsendmail'})}
                                                            inputProps={{
                                                                'aria-label': t('ariaLabel.peoplePicker', { ns: 'recordsendmail'}), id:'sendEmailTo', placeholder:t('placeholder.peoplePicker', { ns: 'recordsendmail'})
                                                            }}
                                                            onInputChange={(text) => { return onInputChange(undefined, text) }}
                                                            disabled={false}
                                                            selectedItems={selectedEmails}
                                                        />
                                                        
                                                        <TextField id="txtSubject" defaultValue={txtSubject} required label={t('label.emailSubject', { ns: 'recordsendmail'})} onChange={_onChangeSubjectTxt} title={t('title.emailSubject', { ns: 'recordsendmail'})} placeholder={t('placeholder.emailSubject', { ns: 'recordsendmail'})} maxLength={200}/>
                                                        <TextField id="txtMessage" defaultValue={txtMessage} label={t('label.emailMessage', { ns: 'recordsendmail'})} multiline rows={5} maxLength={5000} resizable={false} required onChange={_onChangeMessageTxt} placeholder={t('placeholder.emailMessage', { ns: 'recordsendmail'})}  title={t('title.emailMessage', { ns: 'recordsendmail'})}/>
                                                        <br/>
                                                        <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                                        <Checkbox checked={chkDocument} id="checkDocument" onChange={onClickCheckDoc} label={t('label.emailAttachment', { ns: 'recordsendmail'})}/>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
                                                        <ChoiceGroup 
                                                            style={{marginTop:"-5px"}}
                                                            hidden={(props.allowDocx && chkDocument)?false:true}
                                                            selectedKey={docType}
                                                            options={options} 
                                                            className="inlineGroup"
                                                            onChange={_onChangeType} 
                                                            />
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </PivotItem>
                                        <PivotItem headerText={t('ariaLabel.pivotAttachment', { ns: 'recordsendmail'})}>
                                        <MarqueeSelection selection={selectionAttachment}>
                                                    <DetailsList
                                                        items={attachments}
                                                        compact={false}
                                                        columns={columns}
                                                        getKey={getKey}
                                                        setKey="set"
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        isHeaderVisible={true}
                                                        selection={selectionAttachment}
                                                        selectionPreservedOnEmptyClick={true}
                                                        selectionMode={SelectionMode.multiple}
                                                />
                                            </MarqueeSelection>
                                        </PivotItem>
                                    </Pivot>
                                </div>
                                </ScrollablePane>
                            </div>
                        </div>
                    </div>
                    {props.layoutLoaded ? (
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton disabled={toEmail?.length == 0 || !requiredSubject || !requiredMessage } onClick={sendMail} text={t('Common.Button.Send', { ns: 'common'})} title={t('title.btnSend', { ns: 'recordsendmail'})} />
                                    <DefaultButton onClick={closeModal} text={t('Common.Button.Cancel', { ns: 'common'})} title={t('Common.Button.Cancel', { ns: 'common'})} />
                                </DialogFooter>
                            </div>
                        </div> 
                        ) :
                        <Stack tokens={stackTokens}>
                            <label />
                            <label />
                            <label />
                            <Spinner size={SpinnerSize.large} label={props.spinnerText} />
                        </Stack>
                    }
                </div>
            </Stack>
        </Modal>
    )
    :
    (
      <Panel
        headerText={getRecordDescription(t('title.modalEmail', { ns: 'recordsendmail'}), props.documentDescription)}
        headerTextProps={panelHeader}
        isBlocking={false}
        isOpen={props.showModal}
        onDismiss={closeModal}
        closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common'})}
        type={PanelType.smallFluid}
      >
        <Stack tokens={stackTokensEmail}>
                <div className="ms-Grid" dir="ltr">
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className=" ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {messageUpdate ? <MessageBar messageBarType={MessageBarType.success}>{t('messages.successSent', { ns: 'recordsendmail'})}</MessageBar> :
                                <MessageBar messageBarType={MessageBarType.info}>{t('messages.infoEmail', { ns: 'recordsendmail'})}</MessageBar>}
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                <ScrollablePane>
                                <div className="ms-Grid-col ms-sm12 ms-lg12" style={{paddingLeft:"20px", paddingRight:"20px"}}>
                                    <Pivot aria-label={t('ariaLabel.pivotEmail', { ns: 'recordsendmail'})}>
                                        <PivotItem headerText={t('label.pivotEmail', { ns: 'recordsendmail'})}>
                                            <br/>
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <TextField disabled label={t('label.emailFrom', { ns: 'recordsendmail'})} value={props.emailMoniker} />
                                                        
                                                        <div className="ms-TextField-wrapper">
                                                            <label><span style={{ color: "#323130" }}>{t('label.emailTo', { ns: 'recordsendmail'})} </span><span style={{ color: "#a4262c" }}>*</span></label>
                                                        </div>
                                                        <NormalPeoplePicker
                                                            onResolveSuggestions={onFilterChanged}
                                                            onEmptyInputFocus={returnMostRecentlyUsed}
                                                            getTextFromItem={getTextFromItem}
                                                            pickerSuggestionsProps={suggestionProps}
                                                            className={'ms-PeoplePicker'}
                                                            onChange={(items) => { return onInputChange(items, undefined) }}
                                                            key={'normal'}
                                                            onRemoveSuggestion={onRemoveSuggestion}
                                                            onValidateInput={validateInput}
                                                            removeButtonAriaLabel={t('ariaLabel.remove', { ns: 'recordsendmail'})}
                                                            inputProps={{
                                                                'aria-label': t('ariaLabel.peoplePicker', { ns: 'recordsendmail'}), placeholder:t('placeholder.peoplePicker', { ns: 'recordsendmail'})
                                                            }}
                                                            onInputChange={(text) => { return onInputChange(undefined, text) }}
                                                            disabled={false}
                                                            selectedItems={selectedEmails}
                                                        />
                                                        
                                                        <TextField id="txtSubject" defaultValue={txtSubject} required label={t('label.emailSubject', { ns: 'recordsendmail'})} onChange={_onChangeSubjectTxt} title={t('title.emailSubject', { ns: 'recordsendmail'})} placeholder={t('placeholder.emailSubject', { ns: 'recordsendmail'})} maxLength={200}/>
                                                        <TextField id="txtMessage" defaultValue={txtMessage} label={t('label.emailMessage', { ns: 'recordsendmail'})} multiline rows={5} maxLength={5000} resizable={false} required onChange={_onChangeMessageTxt} placeholder={t('placeholder.emailMessage', { ns: 'recordsendmail'})}  title={t('title.emailMessage', { ns: 'recordsendmail'})}/>
                                                        <br/>
                                                        <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                                        <Checkbox checked={chkDocument} id="checkDocument" onChange={onClickCheckDoc} label={t('label.emailAttachment', { ns: 'recordsendmail'})}/>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
                                                        <ChoiceGroup 
                                                            style={{marginTop:"-5px"}}
                                                            hidden={(props.allowDocx && chkDocument)?false:true}
                                                            selectedKey={docType}
                                                            options={options} 
                                                            className="inlineGroup"
                                                            onChange={_onChangeType} 
                                                            />
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </PivotItem>
                                        <PivotItem headerText={t('ariaLabel.pivotAttachment', { ns: 'recordsendmail'})}>
                                        <MarqueeSelection selection={selectionAttachment}>
                                                    <DetailsList
                                                        items={attachments}
                                                        compact={false}
                                                        columns={columns}
                                                        getKey={getKey}
                                                        setKey="set"
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        isHeaderVisible={true}
                                                        selection={selectionAttachment}
                                                        selectionPreservedOnEmptyClick={true}
                                                        selectionMode={SelectionMode.multiple}
                                                />
                                            </MarqueeSelection>
                                        </PivotItem>
                                    </Pivot>
                                </div>
                                </ScrollablePane>
                            </div>
                        </div>
                    </div>
                    {props.layoutLoaded ? (
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton disabled={toEmail?.length == 0 || !requiredSubject || !requiredMessage } onClick={sendMail} text={t('Common.Button.Send', { ns: 'common'})} title={t('title.btnSend', { ns: 'recordsendmail'})} />
                                    
                                </DialogFooter>
                            </div>
                        </div> 
                        ) :
                        <Stack tokens={stackTokens}>
                            <label />
                            <label />
                            <label />
                            <Spinner size={SpinnerSize.large} label={props.spinnerText} />
                        </Stack>
                    }
                </div>
            </Stack>
      </Panel>
    )
}

export default EmailWidget