import { InputType } from "../../Validations";
import { EnumDictionary } from "./EnumDictionatyType";

export enum ValidationType {
    Required = 0,
    MaxLength = 1,
    Mask = 2,
    Regex = 3,
    DateFormat = 4
}
export enum FunctionType {
    GetUserName=0,
    GetDisplayName=1,

}
export const FieldTypeValidations: EnumDictionary<InputType, ValidationType[]> = {
    [InputType.CheckBox]: [],
    [InputType.Currency]: [ValidationType.Required, ValidationType.MaxLength],
    [InputType.DatePicker]: [ValidationType.Required, ValidationType.DateFormat],
    [InputType.DropDownList]: [ValidationType.Required],
    [InputType.CheckBoxList]: [ValidationType.Required],
    [InputType.Number]: [ValidationType.Required],
    [InputType.RichText]: [ValidationType.Required, ValidationType.MaxLength],
    [InputType.TextArea]: [ValidationType.Required, ValidationType.MaxLength],
    [InputType.Textbox]: [ValidationType.Required, ValidationType.MaxLength, ValidationType.Mask, ValidationType.Regex],
    0: [],
    5: [],
    9: [],
    10: [],
    11: [],
    12: [],
    15: [],
    [InputType.Image]: [ValidationType.Required],
    17:[],
    18: []
}