import { ActionButton, Checkbox, IconButton, IDropdownOption, Label, PrimaryButton, Separator, Stack } from "@fluentui/react";
import React from "react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Helper from "../../../../Helpers/Helper";
import { EventModel } from "../../../OutboundIntegration/Models/EventModel";
import { OutboundIntegrationModel } from "../../../OutboundIntegration/Models/OutboundIntegrationModel";
import OutboundServices from "../../../OutboundIntegration/Services/OutboundServices";
import { RHFDropDownListSingleSelectControl } from "../../../RHFControls/RHFDropDownListSingleSelectControl";
import { ILayout } from "../../CustomTemplate";
import { InputType, TextBoxType } from "../../Validations";
import CommonProperties from "./CommonProperties";
import TextBoxProperties from "./TextBoxProperties";
import { DisplayType } from "../../../OutboundIntegration/Models/Enums";
import { RHFChoiceGroupControl } from "../../../RHFControls/RHFChoiceGroupControl";
import { RHFCheckBoxControl } from "../../../RHFControls/RHFChekBoxControl";
import { RHFTextBoxControl } from "../../../RHFControls/RHFTextBoxControl";

export interface IntegrationPropertiesProps {
    layout: ILayout;
    templateId: number;
    setItemProperties: React.Dispatch<React.SetStateAction<ILayout | undefined>>;
}

const IntegrationProperties = (props: IntegrationPropertiesProps) => {
    const prefix = "Integration";
    const [events, setEvents] = useState<EventModel[]>([]);
    const [selectedParameter, setSelectedParameter] = useState<IDropdownOption>();
    const [parameters, setParameters] = useState<ILayout[]>([]);
    const [defaultParameter, setDefaultParameter] = useState<IDropdownOption>();

    const integrationPropertiesForm = useForm<any, any>({
        defaultValues: {},
        reValidateMode: "onSubmit",
        mode: "all",
    });

    useEffect(() => {
        if (props.layout !== undefined) {
            loadOnClickIntegrations();
        }
        
    }, [props.layout]);

    const fillData = () => {
        const integrationId = integrationPropertiesForm.getValues(`${prefix}_Integrations`).key
        const event = events.find(x=>
            x.Integrations.find(y=>y.Id === integrationId)
        )?.Id

        var newLayout: ILayout = {
            Id: props.layout.Id,
            Label: integrationPropertiesForm.getValues(`${prefix}_FieldLabel`),
            Type: InputType.Integration,
            TextBoxType: TextBoxType.Normal,
            TabIndex: props.layout.TabIndex,
            ItemList: "",
            ListId: 0,
            Validations: {
                MaxLength: 50,
                Mask: "",
                Required: integrationPropertiesForm.getValues(`${prefix}_Required`),
                ReadOnly: integrationPropertiesForm.getValues(`${prefix}_ReadOnly`),
                Hide: integrationPropertiesForm.getValues(`${prefix}_Hide`),
                Regex: "",
                DefaultValue: "",
                ErrorMessage: "Please add an image",
            },
            Layout: {
                x: props.layout.Layout.x,
                y: props.layout.Layout.y,
                w: integrationPropertiesForm.getValues(`${prefix}_ColumnSpan`),
                h: 2,
                i: props.layout.Layout.i,
                static: integrationPropertiesForm.getValues(`${prefix}_Lock`),
            },
            Page: props.layout.Page,
            TextParagraph: "",
            Children: getChildren(),
            AdminOnly: integrationPropertiesForm.getValues(`${prefix}_AdminOnly`),
            Placeholder: integrationPropertiesForm.getValues(`${prefix}_Placeholder`),
            Tooltip: integrationPropertiesForm.getValues(`${prefix}_ToolTip`),
            TooltipUrl: integrationPropertiesForm.getValues(`${prefix}_TooltipUrl`),
            FontColor: "#000000",
            FontSize: 12,
            Align: "left",
            ShowScrollbar: false,
            IsBold: false,
            IsItalic: false,
            IsUnderlined: false,
            manualTag: props.layout.manualTag,
            Integration: {
                EventId: integrationPropertiesForm.getValues(`${prefix}_Integrations`) !== undefined ? events.find(x=>
                    x.Integrations.find(y=>y.Id === integrationPropertiesForm.getValues(`${prefix}_Integrations`).key)
                )?.Id ?? -1 : -1,
                Id: integrationPropertiesForm.getValues(`${prefix}_Integrations`) !== undefined ? Number(integrationPropertiesForm.getValues(`${prefix}_Integrations`).key) : -1,
                DisplayType: integrationPropertiesForm.getValues("DisplayType").key !== undefined ? integrationPropertiesForm.getValues("DisplayType").key : displayTypeDefault(),
            },
            IsPickingList: integrationPropertiesForm.getValues(`${prefix}_IsPickingList`),
            PickingList: {
                Integration: {
                    EventId: integrationPropertiesForm.getValues(`${prefix}_Integrations2`) !== undefined ? events.find(x=>
                        x.Integrations.find(y=>y.Id === integrationPropertiesForm.getValues(`${prefix}_Integrations2`).key)
                    )?.Id ?? -1 : -1,
                    Id: integrationPropertiesForm.getValues(`${prefix}_Integrations2`) !== undefined ? Number(integrationPropertiesForm.getValues(`${prefix}_Integrations2`).key) : -1,
                    DisplayType: displayTypeDefault()
                }
            },
            Version: 2
        };
        console.log(integrationPropertiesForm.getValues(`${prefix}_IsPickingList`))

        props.setItemProperties(newLayout);
    };

    const displayTypeDefault = (): DisplayType => {
        integrationPropertiesForm.setValue("DisplayType", {
            text: DisplayType.OnCreate,
            key: DisplayType.OnCreate,
        });
        return DisplayType.OnCreate;
    };

    const getChildren = (): ILayout[] => {
        parameters.forEach((fe) => {
            if (fe.Id === selectedParameter?.key) {
                fe.Id = integrationPropertiesForm.getValues("TextBox_FieldKey");
                fe.Label = integrationPropertiesForm.getValues("TextBox_FieldLabel");
                fe.Placeholder = integrationPropertiesForm.getValues("TextBox_Placeholder");
                fe.Tooltip = integrationPropertiesForm.getValues("TextBox_ToolTip");
                fe.TooltipUrl = integrationPropertiesForm.getValues("TextBox_TooltipUrl");
                fe.Validations.Required = integrationPropertiesForm.getValues("TextBox_Required");
                fe.Validations.MaxLength = integrationPropertiesForm.getValues("TextBox_MaxLength");
                fe.Validations.Hide = integrationPropertiesForm.getValues("TextBox_Hide");
            }
        });
        return parameters;
    };

    const onApply = () => {
        integrationPropertiesForm.handleSubmit(
            (data) => {
                fillData();
            },
            (error) => {}
        )();
    };

    const loadOnClickIntegrations = async () => {
        OutboundServices.GetOnClickEvents({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            TemplateId: props.templateId,
        }).then((res) => {
            integrationPropertiesForm.setValue("DisplayType", {
                text: props.layout.Integration?.DisplayType,
                key: props.layout.Integration?.DisplayType,
            });
            setParameters(props.layout.Children);
            const defaultParam = props.layout.Children.map<IDropdownOption>((m) => {
                return { key: m.Id, text: m.Label };
            })[0];
            setDefaultParameter(defaultParam);
            setSelectedParameter(defaultParam);
            integrationPropertiesForm.setValue(`${prefix}_IsPickingList`, props.layout.IsPickingList);
            integrationPropertiesForm.setValue(`${prefix}_Hide`, props.layout.Validations.Hide);
            if (props.layout.PickingList !== undefined && props.layout.PickingList !== null) {
                integrationPropertiesForm.setValue(`${prefix}_PickingListValue`, props.layout.IsPickingList );
                integrationPropertiesForm.setValue(`${prefix}_PickingListControl`, props.layout.IsPickingList );
            }
            if (res.length > 0) {
                setEvents(res);
                loadOutboundIntegrationValues(res,`${prefix}_Integrations`,props.layout.Integration?.Id);
                loadOutboundIntegrationValues(res,`${prefix}_Integrations2`,props.layout.PickingList?.Integration?.Id);
            }
        });
    };

    const loadOutboundIntegrationValues = (res: EventModel[], id:string, key:number | undefined) => {
        integrationPropertiesForm.setValue(id, {
            text: res
                .map((event: EventModel) => {
                    const arr: IDropdownOption[] = [];
                    event.Integrations.forEach((integration: OutboundIntegrationModel) => {
                        arr.push({
                            key: integration.Id,
                            text: integration.Description,
                        });
                    });
                    return arr;
                })[0]
                .find((f) => f.key === props.layout.Validations.DefaultValue)?.text,
            key,
        });
    };

    const getIntegrationList = (eventsList: EventModel[]): IDropdownOption[] => {
        return eventsList.reduce((acc: OutboundIntegrationModel[], value: EventModel) => {
            acc = acc.concat(value.Integrations)
            return acc;
        }, []).reduce((acc: IDropdownOption[], value: OutboundIntegrationModel) => {
            if (value.Active && value.ObjectApprovedStatus) {
                acc.push({ key: value.Id, text: value.Description })
            }
            return acc;
        }, [])
    };

    const addParameter = (currentValue: ILayout[]): ILayout[] => {
        const values = currentValue.concat({
            Id: `Param_${parameters.length === 0 ? 1 : parameters.length + 1}`,
            Label: `Param_${parameters.length === 0 ? 1 : parameters.length + 1}`,
            Type: InputType.Textbox,
            TextBoxType: TextBoxType.Normal,
            TabIndex: 1,
            ItemList: "",
            ListId: 0,
            Validations: {
                MaxLength: 150,
                Mask: "",
                Required: false,
                ReadOnly: false,
                Regex: "",
                DefaultValue: "",
                ErrorMessage: "",
                Hide: false
            },
            Layout: {
                x: props.layout.Layout.x,
                y: props.layout.Layout.y,
                w: 1,
                h: 2,
                i: `Param_${parameters.length === 0 ? 1 : parameters.length + 1}`,
                static: true,
            },
            Page: 0,
            TextParagraph: "",
            Children: [],
            AdminOnly: false,
            Placeholder: "",
            Tooltip: "",
            TooltipUrl: "",
            FontColor: "#000000",
            FontSize: 12,
            Align: "left",
            ShowScrollbar: false,
            IsBold: false,
            IsItalic: false,
            IsUnderlined: false,
            manualTag: false,
        });
        parametersDropdownCurrentValue(values);
        return values;
    };

    const deleteParameter = () => {
        setParameters((currentParams: ILayout[]) => {
            const newParameters = currentParams.filter((f) => f.Id !== selectedParameter!.key);
            parametersDropdownCurrentValue(newParameters);
            return newParameters;
        });
    };

    const parametersDropdownCurrentValue = (parametersList: ILayout[]) => {
        const selectedValue =
            parametersList.length > 0
                ? parametersList.map<IDropdownOption>((m) => {
                      return { key: m.Id, text: m.Label };
                  })[parametersList.length - 1]
                : undefined;
        integrationPropertiesForm.setValue(`${prefix}_Parameter`, selectedValue);
        setSelectedParameter(selectedValue);
    };

    return (
        <>
            <FormProvider {...integrationPropertiesForm}>
                <CommonProperties layout={props.layout} prefix={"Integration"} displayAdminOnly={true} displayColumnSpan={true} displayLock={true} displayReadOnly={false} displayRequired={false} disableFieldType={true} />
                <RHFCheckBoxControl
                    id={`${prefix}_Hide`}
                    title="Check the mark when input is hidden."
                    label="Hide"
                    control={integrationPropertiesForm.control}
                    setValue={integrationPropertiesForm.setValue}
                    getValues={integrationPropertiesForm.getValues}
                    trigger={integrationPropertiesForm.trigger}
                />
                
                <Separator />
                <Label>Display:</Label>
                <RHFChoiceGroupControl
                    id={"DisplayType"}
                    options={Object.entries(DisplayType).map((item) => {
                        return { text: item[0], key: item[1] };
                    })}
                    styles={{
                        root: { paddingLeft: "10px" },
                        flexContainer: { display: "flex", columnGap: "10px" },
                    }}
                    control={integrationPropertiesForm.control}
                    setValue={integrationPropertiesForm.setValue}
                    getValues={integrationPropertiesForm.getValues}
                    trigger={integrationPropertiesForm.trigger}
                    defaultValue={integrationPropertiesForm.getValues("DisplayType")}
                />
                <Separator />
                <RHFCheckBoxControl 
                    id={`${prefix}_IsPickingList`}
                    title="Select to setup integration as a picking list."
                    label="Picking List"
                    control={integrationPropertiesForm.control}
                    setValue={integrationPropertiesForm.setValue}
                    getValues={integrationPropertiesForm.getValues}
                    trigger={integrationPropertiesForm.trigger}
                />
                <RHFDropDownListSingleSelectControl
                    id={`${prefix}_Integrations`}
                    label={"Integrations"}
                    options={getIntegrationList(events)}
                    //disabled={props.disabled}
                    title={"Select Integration."}
                    control={integrationPropertiesForm.control}
                    setValue={integrationPropertiesForm.setValue}
                    getValues={integrationPropertiesForm.getValues}
                    trigger={integrationPropertiesForm.trigger}
                    //onCallback={props.onTypeDropdownChange}
                    // defaultValue={props.selectedType}
                />
                <ActionButton
                    iconProps={{ iconName: "Add" }}
                    disabled={parameters.length <= 2 ? false : true}
                    onClick={() => {
                        setParameters((currentValue) => addParameter(currentValue));
                    }}
                >
                    Add Integration Parameter
                </ActionButton>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg10" style={{ paddingLeft: "0px" }}>
                            <RHFDropDownListSingleSelectControl
                                id={`${prefix}_Parameter`}
                                label={"Parameters"}
                                options={parameters.map<IDropdownOption>((m) => {
                                    return { key: m.Id, text: m.Label };
                                })}
                                //disabled={[InputType.LineBreak, InputType.SectionHeader, InputType.GridList, InputType.Paragraph, InputType.Integration].includes(getValues(`${props.prefix}_FieldType`) !== undefined ? getValues(`${props.prefix}_FieldType`).key : props.layout.Type)}
                                title="Configure parameter."
                                control={integrationPropertiesForm.control}
                                setValue={integrationPropertiesForm.setValue}
                                getValues={integrationPropertiesForm.getValues}
                                trigger={integrationPropertiesForm.trigger}
                                onCallback={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, dropdownIndex?: number | undefined) => {
                                    setSelectedParameter(option);
                                }}
                                defaultValue={defaultParameter !== undefined ? defaultParameter.key : ""}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ paddingTop: "25px", paddingLeft: "0px" }}>
                            {selectedParameter !== undefined ? (
                                <IconButton
                                    onClick={() => {
                                        deleteParameter();
                                    }}
                                    iconProps={{ iconName: "Delete" }}
                                    title={"Remove selected parameter"}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>

                {selectedParameter !== undefined ? <TextBoxProperties prefix={"TextBox"} layout={parameters.find((f) => f.Id === selectedParameter.key)!} isIntegration={true}/> : null}

                <Separator />
                <RHFDropDownListSingleSelectControl
                    id={`${prefix}_Integrations2`}
                    label={"Integration after picking list"}
                    options={getIntegrationList(events)}
                    title={"Select Integration."}
                    control={integrationPropertiesForm.control}
                    setValue={integrationPropertiesForm.setValue}
                    getValues={integrationPropertiesForm.getValues}
                    trigger={integrationPropertiesForm.trigger}
                />
                <Separator />

                <PrimaryButton id={`${prefix}_Apply`} disabled={false} onClick={onApply} text="Apply" title="Apply all configuration for this control." />
            </FormProvider>
        </>
    );
};

export default IntegrationProperties;
