import React, { useState } from "react";
import { ActionButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import OutboundServices from "../../Services/OutboundServices";
import Helper from "../../../../Helpers/Helper";
import { useFormContext } from "react-hook-form";
import { MapInputHeader } from "../../Models/OutboundIntegrationModel";

export interface TestProps {
    templateId: number;
    isConfigurationTemplateReadMode?: boolean;
}

export const Test = (props: TestProps) => {
    const { getValues } = useFormContext();
    const [response, setResponse] = useState<string>("")
    const [isLoading, setIsLoading] = useState(false)

    const compile = async () => {
        setIsLoading(true)
        let path = await Helper.getSite()
        let mapInputVariableList: MapInputHeader[] = [];
        mapInputVariableList = getValues("mapInputVariableList");
        await OutboundServices.ScriptCompile({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: path,
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            BodyTargetNode: {
                Payload: getValues("MapInputPayload"),
                Script: getValues("MapInputScript"),
                ScriptVariables: mapInputVariableList.filter(item => item.Key.length > 0 && item.Value.length >= 0).map(item => {
                    return `${item.Key}="${item.Value}"`;
                }).join(";") + ";"
            },
            DocumentId: -1,
            TemplateId: props.templateId
        }).then(res => {
            setIsLoading(false)
            setResponse(JSON.stringify(res, null, 2))
        })
    }

    const execute = async () => {
        setIsLoading(true)
        let path = await Helper.getSite()
        let mapInputVariableList: MapInputHeader[] = [];
        mapInputVariableList = getValues("mapInputVariableList");
        await OutboundServices.ScriptExecute({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: path,
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            BodyTargetNode: {
                Payload: getValues("MapInputPayload"),
                Script: getValues("MapInputScript"),
                ScriptVariables: mapInputVariableList.filter(item => item.Key.length > 0 && item.Value.length >= 0).map(item => {
                    return `${item.Key}="${item.Value}"`;
                }).join(";") + ";"
            },
            DocumentId: -1,
            TemplateId: props.templateId
        }).then(res => {
            setIsLoading(false)
            setResponse(JSON.stringify(res, null, 2))
        })
    }

    return (
        <>
            <Stack tokens={{ childrenGap: 8 }} horizontal>
                <ActionButton disabled={props.isConfigurationTemplateReadMode} iconProps={{ iconName: 'Processing' }} onClick={compile}>
                    Compile
                </ActionButton>
                <ActionButton disabled={props.isConfigurationTemplateReadMode} iconProps={{ iconName: 'FlickLeft' }} onClick={execute}>
                    Execute
                </ActionButton>
            </Stack>
            {!isLoading ?
                <TextField disabled={props.isConfigurationTemplateReadMode} readOnly={true} label="Response" multiline rows={10} value={response} />
                :
                <Stack tokens={{ childrenGap: 8 }}>
                    <label />
                    <label />
                    <label />
                    <Spinner size={SpinnerSize.large} label="Loading..." />
                </Stack>
            }
        </>
    );
};