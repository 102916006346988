import React, { useState, useEffect, HTMLAttributes } from 'react';
import { Modal } from '@fluentui/react/lib/Modal';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { useId } from '@uifabric/react-hooks';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Separator } from '@fluentui/react/lib/Separator';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import '../../Custom.css';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import { IContextualMenuProps, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import Confirmation from '../Settings/Confirmation';
import PreviewScreen from '../Preview/PreviewScreen'
import Helper, { IDocumentVersionRequest } from '../../Helpers/Helper';
import { TextField } from '@fluentui/react/lib/TextField';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IconButton, IButtonStyles, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { isMobile } from 'react-device-detect';
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import ReactDOM from 'react-dom';
import { Panel, PanelType } from '@fluentui/react';
import { getRecordDescription } from '../../Helpers/functions';
import { formatFromUTC, setGlobalDateLocale } from '../../Helpers/DateUtils';
import {useTranslation} from "react-i18next";
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";

export interface IDocumentVersion {
    Id: number
    DocumentId: number
    Version: number
    AccountId: number
    TemplateId: number
    UserName: string
    Description: string
    Data: string
    DateCreated: string
    DateUpdated: string
    Name: string
    MimeType: string
    Culture: string
    IconName: string
    DATETIME: string
}

export class DocumentVersion implements IDocumentVersion {
    Id = -1
    DocumentId = -1
    Version = -1
    AccountId = -1
    TemplateId = -1
    UserName = ""
    Description = ""
    Data = ""
    DateCreated = ""
    DateUpdated = ""
    Name = ""
    MimeType = ""
    Culture = ""
    IconName = ""
    DATETIME = ""
}

const theme = getTheme();

const iconModalButtonStyles = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const iconButtonNoteModalStyles = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};



const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackTokens = { childrenGap: 50 };

function VersionScreen(props: any) {
    const { branding } = useBranding()
    const emptyDoc = new DocumentVersion()
    const titleId = useId(props.modalId);
    const [showConfirm, setShowConfirm] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [deleteMessage, setDeleteMessage] = useState("")
    const [docSelected, setDocumentSelected] = useState(emptyDoc)
    const [modalId, setModalId] = useState(-1)
    const [modalTitle, setModalTitle] = useState("")
    const [spinnerText, setSpinnerText] = useState("")
    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [previewURL, setPreviewURL] = useState("")
    const [previewMime, setPreviewMime] = useState("")
    const [id, setId] = useState(-1)
    const [showAddNote, setShowAddNote] = useState(false)
    const [note, setNote] = useState("")
    const [requiredNote, setRequiredNote] = useState(false)
    const [documentDescription, setDocumentDescription] = useState("")
    const [actionText, setActionText] = useState("")
    const [documentId, setDocumentId] = useState(-1)
    const [allowDocX, setAllowDocX] = useState(false)
    const [mQuery, setMQuery] = React.useState<any>({
        matches: window.innerWidth > 639 ? true : false,
    });
    const {t} = useTranslation(['common', 'versions']);

    const panelHeader: HTMLAttributes<HTMLDivElement> = {
        style: {
            fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
            fontWeight: "bolder",
            color: branding.theme.palette.themePrimary,
            fontSize: 20
        }
    }
    useEffect(() => {
        setGlobalDateLocale(navigator.language)
    }, [])

    const onSelectionChange = () => {
        const selectionCount = selection.getSelectedCount();
        console.log(selectionCount)

        switch (selectionCount) {
            case 0:
                setDocumentSelected(emptyDoc)
                break;
            case 1:
                console.log(selection.getSelection()[0])
                setDocumentSelected(selection.getSelection()[0] as IDocumentVersion)
                break;
            default:
                setDocumentSelected(emptyDoc)
                break;
        }
    }

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
            },
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        hideMenu: {
            display: 'none'
        },
        showMenu: {
            display: 'inline'
        },
    });


    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '60vw',
            height: '35vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    const selection = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    const onItemInvoked = (item: IDocumentVersion) => {
        setId(item.Id)
        setNote("")
        setShowAddNote(true)
    }

    const onItemPreview = (item: IDocumentVersion) => {
        const id = item.Id
        setModalId(item.Id)
        setLayoutLoaded(false)
        setShowPreview(true)
        setModalTitle(t('title.modal', { ns: 'preview'}))
        setDocumentDescription(props.documentDescription + "(v" + item.Version + ")")
        setSpinnerText(t('text.spinner', { ns: 'common'}))

        const preview = async () => {
            const req: IDocumentVersionRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id.toString(),
                IsPreview: true,
                IsDownload: false,
                IsDocx: false
            }
            var res = await Helper.getDocumentVersionPreview(req)

            if (res.Success) {
                setDocumentId(item.Id)
                setAllowDocX(props.allowDocX)
                setLayoutLoaded(true)
                setPreviewURL(res.UrlPath)
                setPreviewMime(res.mimeType)
            }
        }
        preview()
    }

    const hidePreview = () => {
        setShowPreview(false)
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalId(-1)
        setModalTitle("")
    }

    const onItemDelete = (item: IDocumentVersion) => {
        console.log(`Opening ${item.Description} Delete...`)
        setModalId(item.Id)
        setLayoutLoaded(false)
        setModalTitle("Delete Version")
        setSpinnerText("Loading content...")
        setDeleteId(item.Id.toString())
        setDeleteMessage("Are you sure you want to delete this version?")
        setLayoutLoaded(true)
        setSpinnerText("")
        setShowConfirm(true)
        setActionText("Delete")
    }

    const hideConfirm = () => {
        setShowConfirm(false)
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalId(-1)
        setModalTitle("")
        setDeleteMessage("")
        setLayoutLoaded(false)
    }

    const confirmDelete = (id: string) => {
        const deleteDoc = async () => {
            const req: IDocumentVersionRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id,
                IsDownload: false,
                IsPreview: false,
                IsDocx: false
            }
            var res = await Helper.deleteDocumentVersion(req)

            if (res.Success) {
                props.update()
                hideConfirm()
                props.hideModal()
            }
        }

        deleteDoc()
    }

    const getKey = (item: any, index?: number): string => {
        if (item !== undefined)
            return item.Id
        return ""
    }

    const getDateTimeDisplaySpan = (item: IDocumentVersion) => {
        var result
        isMobile ?
            result = <span title={item.DateCreated !== null ? formatFromUTC(item.DateCreated) : null}>{item.DateCreated !== null ? formatFromUTC(item.DATETIME, 'll') : null}</span>
            :
            result = <span title={item.DateCreated !== null ? formatFromUTC(item.DateCreated) : null}>{item.DateCreated !== null ? formatFromUTC(item.DateCreated, 'llll') : null}</span>
        return result
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'File Type',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: '',
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 15,
            maxWidth: 15,
            onRender: (item: IDocumentVersion) => {
                return item.Id !== -1 ?
                    <img style={{ cursor: 'pointer' }}
                        onClick={() => {
                            onItemPreview(item)
                        }} title={"Click to preview document in viewer."}
                        src={"https://static2.sharepointonline.com/files/fabric/assets/item-types/16/pdf.svg"} className={classNames.fileIconImg} alt={'Preview'} />
                    :
                    null;
            },
        },
        {
            key: 'column2',
            name: 'Created',
            fieldName: 'DateCreated',
            minWidth: isMobile ? 100 : 200,
            maxWidth: isMobile ? 100 : 200,
            isRowHeader: true,
            data: 'date',
            onRender: (item: IDocumentVersion) => {
                return getRecordCreatedHTMLTemplate(item);
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: ' Username',
            fieldName: 'DisplayName',
            minWidth: isMobile ? 0 : 125,
            maxWidth: isMobile ? 0 : 125,
            isRowHeader: true,
            isCollapsible: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column4',
            name: ' Version',
            fieldName: 'Version',
            minWidth: isMobile ? 40 : 50,
            maxWidth: isMobile ? 40 : 50,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item: IDocumentVersion) => {
                return <span style={{ display: 'block', textAlign: 'right' }}>{item.Version}</span>;
            },
        },
        {
            key: 'column6',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onRender: (item: IDocumentVersion) => {
                return item.Id === -1 ? "Current" :
                    <div style={{ display: 'block', textAlign: 'right' }}><IconButton
                        menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                        role="button"
                        aria-haspopup
                        title="Click to select from available actions."
                        aria-label="Show actions"
                        styles={iconListButtonStyles}
                        menuProps={menuProps}
                    /></div>
            },
            isPadded: true,
        },
    ];

    const getRecordCreatedHTMLTemplate = (item: any) => {
        if (isMobile) {
            return <div>
                <div className="ms-Grid-row">
                    <div title="Created On" className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">{getDateTimeDisplaySpan(item)}</div>
                </div>
                <div className="ms-Grid-row">
                    <div title="User name" className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">{item.DisplayName}</div>
                </div>
            </div>
        }
        else {
            return getDateTimeDisplaySpan(item);
        }
    }

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: 'Delete',
            onClick: () => {
                console.log('Delete clicked')
                onItemDelete(docSelected)
            },
            className: docSelected.Version === props.version ? classNames.hideMenu : classNames.showMenu
        },
        {
            key: 'rollback',
            text: 'Make Current Version',
            onClick: () => {
                onItemInvoked(docSelected)
            },
            className: docSelected.Version === props.version ? classNames.hideMenu : classNames.showMenu
        },
        {
            key: 'preview',
            text: 'Preview',
            onClick: () => {
                onItemPreview(docSelected)
            },
            className: docSelected.Version === props.version ? classNames.hideMenu : classNames.showMenu
        },
    ];

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    async function makeCurrentVersion() {
        const request = {
            DocumentId: id.toString(),
            Close: false,
            Suspend: false,
            Note: note
        };

        let {data}: any = await HttpRequest.postWithSession('MakeCurrentVersion', request)
        const response =data
        
        if (response != null) {
            props.update()
            hideAddNoteModal()
            props.hideModal()
        }
    }
    const hideAddNoteModal = () => {
        setShowAddNote(false)
    }
    const getErrorMessage = (value: string): string => {
        if (value.length > 0) {
            setRequiredNote(true)
        }
        else {
            setRequiredNote(false)
            return "Note must not be empty.";
        }
        return ""
    };

    return (
        <>
            <PreviewScreen
                showModal={showPreview}
                hideModal={hidePreview}
                modalTitle={modalTitle}
                allowDocX={allowDocX}
                documentId={documentId}
                documentDescription={documentDescription}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                URLPath={previewURL}
                mimeType={previewMime}
            />
            <Confirmation
                showModal={showConfirm}
                hideModal={hideConfirm}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                actionText={actionText}
                id={deleteId}
                message={deleteMessage}
                confirmDelete={confirmDelete}
            />
            {!isMobile ? <Modal
                isOpen={props.showModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={titleId}>{getRecordDescription(props.modalTitle, props.documentDescription)}</span>
                                    <IconButton
                                        styles={iconModalButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={props.hideModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                {props.layoutLoaded ?
                                    <ScrollablePane id="ScrollPaneUserlist" key="ScrollPaneUserlist">
                                        <DetailsList
                                            items={props.versionList || []}
                                            compact={false}
                                            columns={columns}
                                            getKey={getKey}
                                            setKey="none"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            selection={selection}
                                            selectionMode={SelectionMode.single}
                                            selectionPreservedOnEmptyClick={false}
                                            enterModalSelectionOnTouch={mQuery.matches}
                                            checkboxVisibility={CheckboxVisibility.hidden}
                                            onRowDidMount={() => {
                                                let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneUserlist")) as Element
                                                if (element !== null) { element.children[1].classList.add("scrollVisible") }
                                            }}
                                        />
                                    </ScrollablePane>

                                    :
                                    <Stack tokens={stackTokens}>
                                        <label />
                                        <label />
                                        <label />
                                        <Spinner size={SpinnerSize.large} label={props.spinnerText} />
                                    </Stack>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <DefaultButton onClick={props.hideModal} text="Close" title="Close." />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal> :

                <Panel
                    headerText={isMobile ? props.modalTitle : getRecordDescription(props.modalTitle, props.documentDescription)}
                    headerTextProps={panelHeader}
                    isBlocking={false}
                    isOpen={props.showModal}
                    onDismiss={props.hideModal}
                    closeButtonAriaLabel="Close."
                    type={PanelType.smallFluid}
                >
                    <Stack>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.body}>
                                    {props.layoutLoaded ?
                                        <ScrollablePane id="ScrollPaneUserlist" key="ScrollPaneUserlist">
                                            <DetailsList
                                                items={props.versionList || []}
                                                compact={false}
                                                columns={columns}
                                                getKey={getKey}
                                                setKey="none"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                isHeaderVisible={true}
                                                selection={selection}
                                                selectionMode={SelectionMode.single}
                                                selectionPreservedOnEmptyClick={false}
                                                enterModalSelectionOnTouch={mQuery.matches}
                                                checkboxVisibility={CheckboxVisibility.hidden}
                                                onRowDidMount={() => {
                                                    let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneUserlist")) as Element
                                                    if (element !== null) { element.children[1].classList.add("scrollVisible") }
                                                }}
                                            />
                                        </ScrollablePane>
                                        :
                                        <Stack tokens={stackTokens}>
                                            <label />
                                            <label />
                                            <label />
                                            <Spinner size={SpinnerSize.large} label={props.spinnerText} />
                                        </Stack>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <DefaultButton onClick={props.hideModal} text="Close" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                    </Stack>
                </Panel>
            }
            <Modal
                isOpen={showAddNote}
                isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="actionTitle">{"Make current version"}</span>
                                    <IconButton
                                        styles={iconButtonNoteModalStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideAddNoteModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.bodyNote}>
                                    <TextField label="Note" onGetErrorMessage={getErrorMessage} placeholder="Enter note." title="Enter note." required onChange={(_, v) => { setNote(v!); }} value={note} />
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={makeCurrentVersion} disabled={!requiredNote} text={"Submit"} title="Make current version." />
                                    <DefaultButton onClick={hideAddNoteModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                    </div>
                </Stack>
            </Modal>
        </>
    )
}

export default VersionScreen