import HttpRequest from "./HttpRequest";
import Helper, {IHttpClientResponseBag} from "../Helpers/Helper";


export interface IGetDocumentOnlyCustomUserSignatureByAudienceMasterRequest {
    DocumentOnlyAudienceMasterId: string
}

export interface IGetListSignatureByAudieceMasterIdRequest {
    DocumentOnlyAudienceMasterId: string
}

export interface GetDocumentOnlyCustomUserSignatureByIdRequest {
    Id: number
}

export interface IGetDocumentOnlyCustomUserSignatureByAudienceMasterResponse {
    Id: string
    AccountId: string
    DocumentOnlyAudienceMasterId: string
    Description: string
    FileExt: string
    MimeType: string
    Name: string
}

export interface IGetDocumentOnlyCustomUserSignatureByIdResponse {
    Id: string
    AccountId: string
    DocumentOnlyAudienceMasterId: string
    Description: string
    FileExt: string
    MimeType: string
    Name: string
    SignatureImage: string
}

export interface IGetListSignatureByAudieceMasterIdResponse {
    Id: string
    AccountId: string
    DocumentOnlyAudienceMasterId: string
    Description: string
    FileExt: string
    MimeType: string
    Name: string
    SignatureImage: string
}

export interface IUpdateDocumentOnlyCustomUserSignatureRequest {
    DocumentOnlyCustomUserSignatureId: number
    ImageDescription: string
    MimeType: string
    FileExt: string
    SignatureImage: string
}

export interface ICreateDocumentOnlyCustomUserSignatureRequest {
    DocumentOnlyAudienceMasterId: number
    ImageDescription: string
    ImageName: string
    MimeType: string
    FileExt: string
    SignatureImage: string
}

export interface IGetAllSignaturesOptionListResponse {
    key: string,
    text: string
}

export interface IDeleteDocumentOnlyCustomUserSignatureRequest {
    Id: number
}

const DocumentOnlyCustomUserSignatureService = {

    /**
     * GetDocumentOnlyCustomUserSignatureByAudienceMaster
     * @constructor
     */
    GetDocumentOnlyCustomUserSignatureByAudienceMaster: async (request: IGetDocumentOnlyCustomUserSignatureByAudienceMasterRequest): Promise<IGetDocumentOnlyCustomUserSignatureByAudienceMasterResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('GetDocumentOnlyCustomUserSignatureByAudienceMaster', request)

        return new Promise<IGetDocumentOnlyCustomUserSignatureByAudienceMasterResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * GetDocumentOnlyCustomUserSignatureById
     * @constructor
     */
    GetDocumentOnlyCustomUserSignatureById: async (request: GetDocumentOnlyCustomUserSignatureByIdRequest): Promise<IGetDocumentOnlyCustomUserSignatureByIdResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('GetDocumentOnlyCustomUserSignatureById', request)

        return new Promise<IGetDocumentOnlyCustomUserSignatureByIdResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * GetListSignatureByAudieceMasterId
     * @constructor
     */
    GetListSignatureByAudieceMasterId: async (request: IGetListSignatureByAudieceMasterIdRequest): Promise<IGetListSignatureByAudieceMasterIdResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('GetListSignatureByAudieceMasterId', request)

        return new Promise<IGetListSignatureByAudieceMasterIdResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * UpdateDocumentOnlyCustomUserSignature
     * @constructor
     */
    UpdateDocumentOnlyCustomUserSignature: async (request: IUpdateDocumentOnlyCustomUserSignatureRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('UpdateDocumentOnlyCustomUserSignature', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * DeleteDocumentOnlyCustomUserSignature
     * @constructor
     */
    DeleteDocumentOnlyCustomUserSignature: async (request: IDeleteDocumentOnlyCustomUserSignatureRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.getWithSession('DeleteDocumentOnlyCustomUserSignature', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * CreateDocumentOnlyCustomUserSignature
     * @constructor
     */
    CreateDocumentOnlyCustomUserSignature: async (request: ICreateDocumentOnlyCustomUserSignatureRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('CreateDocumentOnlyCustomUserSignature', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * GetAllSignaturesOptionList
     * @constructor
     */
    GetAllSignaturesOptionList: async (): Promise<IGetAllSignaturesOptionListResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('GetAllSignaturesOptionList', {})

        return new Promise<IGetAllSignaturesOptionListResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

}

export default DocumentOnlyCustomUserSignatureService