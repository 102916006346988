export enum AuthenticationType {
    OAuth20 = "OAuth20",
    Bearer = "Bearer",
    Basic = "Basic",
    Custom = "Custom"
    //certificate is missing
}

export enum OAuth20Type {
    RefreshTokenFlow = "Refresh-Token",
    ServerToServerFlowCC = "S-to-S-CC",
    ServerToServerFlowAssertion = "S-to-S-JWT",
    Custom = "Custom"
}

export enum ServiceProviderType {
    Salesforce = "Salesforce",
    MSGraph = "MSGraph",
    Google = "Google",
    SuccessFactorsSAP = "SuccessFactors-SAP"
}

export enum ParamType {
    URL = "URL",
    FORM = "FORM"
}

export enum ParamValueType {
    CC = "CC",
    Fixed = "Fixed",
    Document = "Document",
    Payload = "Payload",
    File = "File"
}

export enum MethodType {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE"
}

export enum TypeType {
    None = "None",
    Body = "Body",
    Form = "Form"
}

export enum SourceType {
    Fixed = "Fixed",
    DocumentData = "DocumentData"
}

export enum DocumentType {
    docx = "docx",
    PDF = "PDF",
    Both = "Both"
}

export enum DocumentTypeFormParamList {
    DOCX = "DOCX",
    PDF = "PDF"
}

export enum SendAsType {
    JSON = "application/json",
    XML = "application/xml",
    TextPlain = "text/plain"
}

export enum EncodeRequestType {
    SendAsSelected = "SendAsSelected",
    XML = "application/xml",
    Base64 = "text/plain"
}

export enum BodyType {
    KimBaseMessage = "KimBaseMessage",
    FormData = "FormData",
    Raw = "Raw",
    MapInput = "MapInput"
}

export enum MapOutputResponseType {
    Plain = "Plain",
    JSON = "JSON",
    XML = "XML",
    Auto = "Auto"
}

export enum MapOutputValueType {
    Node = "Node",
    Value = "Value"
}

export enum PivotOutbound {
    Security = "Security",
    RequestHeader = "RequestHeader",
    URLParams = "URLParams",
    Body = "Body"
}

export enum ApprovedStatus {
    Pending = "Pending",
    Approved = "Approved"
}

export enum MapOutputSurrogateField {
    surrogate1 = "surrogate1",
    surrogate2 = "surrogate2",
    surrogate3 = "surrogate3"
}

export enum DisplayType {
    OnCreate = "OnCreate",
    OnUpdate = "OnUpdate",
    Always = "Always"
}

export enum IdentifierKeyType {
    Identifier1 = "Identifier1",
    Identifier2 = "Identifier2",
    Identifier3 = "Identifier3"
}