import { Modal, Stack, FontWeights, getTheme, mergeStyleSets, IconButton, IIconProps, PrimaryButton, MessageBar } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Helper from '../../Helpers/Helper';
import { RHFDropDownListSingleSelectControl } from '../RHFControls/RHFDropDownListSingleSelectControl';
import { RHFTextBoxControl } from '../RHFControls/RHFTextBoxControl';
import { EventModel, EventState, EventType } from './Models/EventModel';
import { EventTypeValidation } from './Models/OutboundIntegrationModel';
import OutboundServices from './Services/OutboundServices';
import { ConfigurationTemplateModel } from './Models/ConfigurationTemplateModel';
import {useBranding} from "../../hooks/useBranding";

//https://codesandbox.io/s/nelec?file=/src/StyleList.tsx
export interface IntegrationEventProps {
    event: MutableRefObject<EventModel | undefined>,
    isEventOpen: boolean,
    templateId: number,
    hideEventModal: () => void,
    loadEvents: () => void,
    showSuccessMessage: (message: string) => void,

    configurationTemplate?: React.MutableRefObject<ConfigurationTemplateModel | undefined>
}

function IntegrationEvent(props: IntegrationEventProps) {
    const { branding } = useBranding()
    const integrationEventForm = useForm<any, any>({
        reValidateMode: "onSubmit",
        mode: "all"
    });
    const [eventTypeValidation, setEventTypeValidation] = useState<EventTypeValidation>()
    const [isErrorMessageEventVisible, { setTrue: showErrorEvent, setFalse: hideErrorEvent }] = useBoolean(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        loadEventValidations()
    }, [props.isEventOpen])

    useEffect(() => {
        if (props.event.current !== undefined) {
            integrationEventForm.setValue("Name", props.event.current!.Name)
            integrationEventForm.setValue("Description", props.event.current!.Description)
            integrationEventForm.setValue("Event", {
                text: getEventType(),
                key: getEventType()
            })
        } else {
            integrationEventForm.reset()
        }
    }, [props.event.current])

    const getEventType = () => {
        if ((props.event.current!.EventState !== null && props.event.current!.EventState !== undefined) && props.event.current!.EventState.trim() === EventState.Initial) {
            return EventType.OnManualInitial;
        } else if ((props.event.current!.EventState !== null && props.event.current!.EventState !== undefined) && props.event.current!.EventState.trim() === EventState.Subsequent) {
            return EventType.OnManualSubsequent;
        } else {
            return props.event.current!.EventType?.trim()
        }
    }

    const showErrorMessageEvent = (message: string) => {
        showErrorEvent()
        setMessage(message)
        setTimeout(function () {
            hideErrorEvent()
        }, 5000);
    }

    const titleId = useId('title');
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    const iconButtonStyles = {
        root: {
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
       
    };
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const create = () => {
        integrationEventForm.handleSubmit(
            async (data) => {
                let path = await Helper.getSite()
                OutboundServices.CreateEvent({
                    AccountId: Helper.getDefaultAccount(),
                    ApiKey: "-1",
                    Autoprovision: true,
                    Description: data.Description,
                    EventType: data.Event.key === EventType.OnManualInitial || data.Event.key === EventType.OnManualSubsequent ? "Manual" : data.Event.key,
                    EventState: data.Event.key === EventType.OnManualInitial ? "Initial" : data.Event.key === EventType.OnManualSubsequent ? "Subsequent" : "",
                    ForDisplay: false,
                    Name: data.Name,
                    Order: 1,
                    Path: path,
                    SK: Helper.getSessionKey(),
                    TemplateId: props.templateId,
                    UserName: Helper.getUsername()
                }).then(res => {
                    if (props.event !== undefined) {
                        props.event.current?.Integrations.forEach(integration => {
                            integration.Id = 0;
                            integration.TemplateId = props.templateId
                            OutboundServices.CreateIntegration({
                                AccountId: Helper.getDefaultAccount(),
                                ApiKey: "-1",
                                Autoprovision: true,
                                EventId: +res.Id,
                                Integration: integration,
                                Path: path,
                                SK: Helper.getSessionKey(),
                                UserName: Helper.getUsername()

                            }).then((res) => {
                                if (res.Success !== undefined && res.Success !== null && res.Success === true) {
                                    props.showSuccessMessage("Integration Event has been created successfully.")
                                    props.loadEvents()
                                    hideModal(true)
                                }
                                else {
                                    let errorMessage = (res.ExceptionMessage !== undefined && res.ExceptionMessage !== "") ? res.ExceptionMessage : "Something unexpected happened."
                                    showErrorMessageEvent("Error: " + errorMessage)
                                }
                            });
                        })

                    }

                    if (res.Success !== undefined && res.Success !== null && res.Success === true) {
                        props.showSuccessMessage("Integration Event has been created successfully.")
                        hideModal(true)
                    }
                    else {
                        let errorMessage = (res.ExceptionMessage !== undefined && res.ExceptionMessage !== "") ? res.ExceptionMessage : "Something unexpected happened."
                        showErrorMessageEvent("Error: " + errorMessage)
                    }
                })
            },
            (error) => {
                showErrorMessageEvent("Error: " + error)
            }
        )();
    }

    const updateEvent = () => {
        integrationEventForm.handleSubmit(
            async (data) => {
                let path = await Helper.getSite()
                OutboundServices.UpdateEvent({
                    AccountId: Helper.getDefaultAccount(),
                    ApiKey: "-1",
                    Autoprovision: true,
                    Description: data.Description,
                    ForDisplay: props.event.current!.ForDisplay,
                    Id: props.event.current!.Id,
                    Name: data.Name,
                    Order: props.event.current!.Order,
                    Path: path,
                    SK: Helper.getSessionKey(),
                    TemplateId: props.templateId,
                    UserName: Helper.getUsername(),
                }).then(res => {
                    if (res.Success !== undefined && res.Success !== null && res.Success === true) {
                        props.showSuccessMessage("Integration Event has been updated successfully.")
                        props.loadEvents()
                        hideModal(true)
                    }
                    else {
                        let errorMessage = (res.ExceptionMessage !== undefined && res.ExceptionMessage !== "") ? res.ExceptionMessage : "Something unexpected happened."
                        showErrorMessageEvent("Error: " + errorMessage)
                    }
                })
            },
            (error) => {
                showErrorMessageEvent("Error: " + error)
            }
        )();
    }

    const updateConfigurationTemplateEvent = async () => {
        let tempConfigurationTemplate = props.configurationTemplate!.current!;
        tempConfigurationTemplate.ObjectIntegrationConfig.Description = integrationEventForm.getValues("Description")
        tempConfigurationTemplate.ObjectIntegrationConfig.Name = integrationEventForm.getValues("Name")

        OutboundServices.UpdateConfigurationTemplate({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            ConfigurationTemplate: tempConfigurationTemplate!,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername()
        }).then(res => {
            if (res.Success !== undefined && res.Success !== null && res.Success === true) {
                props.showSuccessMessage("Configuration Template has been updated successfully.");
                hideModal(true);
            } else {
                let errorMessage = (res.ExceptionMessage !== undefined && res.ExceptionMessage !== "") ? res.ExceptionMessage : "Something unexpected happened."
                showErrorMessageEvent("Error: " + errorMessage)
            }
        })
    };

    const hideModal = (reloadEvents: boolean) => {
        integrationEventForm.reset()
        props.event.current = undefined

        if(props.configurationTemplate !== undefined) {
            props.configurationTemplate!.current = undefined;
        }

        props.hideEventModal()
        if(reloadEvents) {
            props.loadEvents()
        }
    }

    const loadEventValidations = async () => {
        let path = await Helper.getSite()
        OutboundServices.GetEvents({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: path,
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            TemplateId: props.templateId
        }).then(res => {
            if (res.length > 0) {
                setEventTypeValidation({
                    HasOnCloseEvent: res.filter(f => f.EventType.trim() === EventType.OnClose).length === 1,
                    HasOnCreateEvent: res.filter(f => f.EventType.trim() === EventType.OnCreate).length === 1,
                    HasOnUpdateEvent: res.filter(f => f.EventType.trim() === EventType.OnUpdate).length === 1
                });
            } else {
                setEventTypeValidation({
                    HasOnCloseEvent: false,
                    HasOnCreateEvent: false,
                    HasOnUpdateEvent: false
                })
            }
        })
    }

    return (
        <>
            <Modal
                titleAriaId={titleId}
                isOpen={props.isEventOpen}
                onDismiss={() => hideModal(false)}
                isBlocking={true}
                containerClassName={contentStyles.container}
                styles={{ main: { width: "700px" } }}
            >

                <FormProvider {...integrationEventForm}>
                    <div className={contentStyles.header}>
                        <span id={titleId}>Outbound Integration</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={() => hideModal(false)}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <Stack
                            horizontal={true}
                            wrap={true}
                            disableShrink={true}
                            horizontalAlign={"start"}
                            verticalAlign={"start"}
                            styles={{ root: { marginTop: 0 } }}
                            tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                        >
                            <Stack.Item grow>
                                {isErrorMessageEventVisible ? (
                                    <MessageBar styles={{ root: { backgroundColor: "#ffd5d5" } }}>
                                        {message}
                                    </MessageBar>
                                ) : null}
                            </Stack.Item>
                        </Stack>
                        <Stack
                            horizontal={true}
                            wrap={true}
                            disableShrink={true}
                            horizontalAlign={"start"}
                            verticalAlign={"start"}
                            styles={{ root: { marginTop: 0 } }}
                            tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                        >
                            <Stack.Item grow>
                                <span>
                                    <p>Select the event that is required for your needs.</p>
                                </span>
                                <ol>
                                    <li>OnCreate - executes when {branding.appName} creates a record. There can be 1 OnCreate event.</li>
                                    <li>OnUpdate- executes when {branding.appName} updates a record. There can be 1 OnUpdate event.</li>
                                    <li>OnClose - executes when close has been selected from Record -{'>'} Action -{'>'} Manage -{'>'} Close. There can be 1 OnClose event.</li>
                                    <li>OnClick - provides capability to build services that are linked to {branding.appName} Wizard for configuring service integration buttons to get data and map back to the {branding.appName} form and document. There can be many OnClick events.</li>
                                    <li>OnManual Initial - provides capability to build an integration menu under the Records -{'>'} Action -{'>'} Integration menu for members of the application audience to manually execute. There can be many OnManual events.  The OnManual Initial command will be available if there is no indication that this event as been previously executed.</li>
                                    <li>OnManual Subsequent - provides capability to build an integration menu under the Records -{'>'} Action -{'>'} Integration menu for members of the application audience to manually execute. There can be many OnManual events.  The OnManual Subsequent command will be available if there is an indication that an OnManual Initial event has been executed by referencing the SurrogateId.</li>
                                </ol>
                            </Stack.Item>
                        </Stack>
                        <Stack
                            horizontal={true}
                            wrap={true}
                            disableShrink={true}
                            horizontalAlign={"start"}
                            verticalAlign={"start"}
                            styles={{ root: { marginTop: 0 } }}
                            tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                        >
                            <Stack.Item grow>
                                <RHFTextBoxControl
                                    id={"Name"}
                                    label={"Name"}
                                    maxLength={100}
                                    disabled={(props.configurationTemplate === undefined && props.event.current === undefined) || /*(props.configurationTemplate === undefined && props.event.current !== undefined) ||*/ (props.configurationTemplate?.current !== undefined && props.event.current !== undefined) ? false : true}
                                    title="Enter integration event name."
                                    control={integrationEventForm.control}
                                    setValue={integrationEventForm.setValue}
                                    getValues={integrationEventForm.getValues}
                                    trigger={integrationEventForm.trigger}
                                    defaultValue={""}
                                    required={true}
                                    {...integrationEventForm.register("Name", { required: "Name is required." })}
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <RHFTextBoxControl
                                    id={"Description"}
                                    label={"Description"}
                                    maxLength={100}
                                    disabled={(props.configurationTemplate === undefined && props.event.current === undefined) || (props.configurationTemplate === undefined && props.event.current !== undefined) || (props.configurationTemplate?.current !== undefined && props.event.current !== undefined) ? false : true}
                                    title="Enter integration event description."
                                    control={integrationEventForm.control}
                                    setValue={integrationEventForm.setValue}
                                    getValues={integrationEventForm.getValues}
                                    trigger={integrationEventForm.trigger}
                                    defaultValue={""}
                                    required={true}
                                    {...integrationEventForm.register("Description", { required: "Description is required." })}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack
                            horizontal={true}
                            wrap={true}
                            disableShrink={true}
                            horizontalAlign={"start"}
                            verticalAlign={"start"}
                            styles={{ root: { marginTop: 0 } }}
                            tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                        >
                            <Stack.Item grow>
                                <RHFDropDownListSingleSelectControl
                                    id={"Event"}
                                    label="Event"
                                    options={Object.entries(EventType).filter(filter => filter[0] !== EventType.None).map(item => {
                                        return {
                                            text: item[0], key: item[1], disabled: (eventTypeValidation !== undefined && ((eventTypeValidation.HasOnCloseEvent && item[0] === EventType.OnClose) ||
                                                (eventTypeValidation.HasOnCreateEvent && item[0] === EventType.OnCreate) || (eventTypeValidation.HasOnUpdateEvent && item[0] === EventType.OnUpdate))) ? true : false
                                        };
                                    })}
                                    required={true}
                                    disabled={(props.event.current !== undefined && props.event.current!.Id !== undefined && props.event.current!.Id) || props.configurationTemplate !== undefined ? true : false}
                                    title="Select method type."
                                    control={integrationEventForm.control}
                                    setValue={integrationEventForm.setValue}
                                    getValues={integrationEventForm.getValues}
                                    trigger={integrationEventForm.trigger}
                                    {...integrationEventForm.register("Event", { required: "Event is required." })}
                                />
                            </Stack.Item>
                        </Stack>
                    </div>
                    <Stack styles={{ root: { marginTop: 0 } }} tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}>
                        <Stack.Item align="end">
                            {
                                props.configurationTemplate !== undefined && props.event.current!.Name !== "" && props.event.current!.Description !== "" ? <PrimaryButton disabled={props.configurationTemplate.current === undefined} id="Save" onClick={updateConfigurationTemplateEvent} text="Update" title="Update Configuration Event." /> :
                                    props.event.current !== undefined && props.event.current!.Id !== undefined && props.event.current!.Id > 0 ?
                                        <PrimaryButton id="Save" onClick={updateEvent} text="Update" title="Update Event." />
                                        :
                                        <PrimaryButton id="Create" onClick={create} text="Create" title="Create Event." />
                            }
                        </Stack.Item>
                    </Stack>
                </FormProvider>
            </Modal >
        </>
    )
}

export default IntegrationEvent