import { ILayout } from '../../CustomTemplate';
import { getNumberFromString, isNumericString } from "../../../../Helpers/Utils";
import {
    userRole,
    FormAssemblyOperator,
    IClausule,
    ICondition,
    IFormAssemblyRule,
    IRuleElement
} from "../../../../interfaces/IFormAssembly";
import { InputType } from '../../Validations';
import { parseISOToDate, getISODateString, diffDates, getDateFromFormat, getDateWithFormat } from '../../../../Helpers/DateUtils';
import { cleanHtml } from '../../CustomForm/RHFControls/utils/functions';
import { IDropdownOption } from '@fluentui/react';
import { evaluate } from 'mathjs'
import moment from 'moment';

export const getFormAssemblyCompareOptions = (type: InputType) => {

    let operators = []

    switch (type) {
        case InputType.CheckBox:
            operators = [['Equal', FormAssemblyOperator.Equal], ['NotEqual', FormAssemblyOperator.NotEqual]]
            break
        case InputType.Currency:
        case InputType.Number:
            operators = Object.entries(FormAssemblyOperator).filter(([key]) => key !== 'Include')
            break
        default:
            operators = Object.entries(FormAssemblyOperator)
            break
    }
    return operators.map(([key, value]) => {
        return {
            key: key,
            text: value
        }
    })
}


/**
 * Retrieves the key of a specific enum value from the given enum object.
 *
 * @param {Any} myEnum - The enum object to search in.
 * @param {string} enumValue - The enum value to look for.
 * @returns {string | null} - The key corresponding to the enum value found, or null if no match is found.
 */
export function getEnumKeyByEnumValue(myEnum: any, enumValue: string): string | null {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
}

export const getFormAssemblyActionOptions = (type: InputType): IDropdownOption[] => {
    switch (type) {
        case InputType.Integration:
        case InputType.LineBreak:
        case InputType.SectionHeader:
        case InputType.Paragraph:
        case InputType.Attachment:
        case InputType.GridList:
        case InputType.HelpLink:
        case InputType.Image:
            return [
                { key: 'lock', text: 'Lock' },
                { key: 'hide', text: 'Hide' },
                { key: 'unlock', text: 'Unlock' },
                { key: 'show', text: 'Show' },
            ]
        default:
            return [
                { key: 'lock', text: 'Lock' },
                { key: 'hide', text: 'Hide' },
                { key: 'unlock', text: 'Unlock' },
                { key: 'show', text: 'Show' },
                { key: 'value', text: 'Value' },
            ]
    }
}


export function evaluateConditionRules<T>(conditions: IRuleElement[], data: T, layouts: ILayout[], locale:string, AdminOnly: boolean): string {

    let rules: string[] = []
    for (const rule of conditions) {
        const ruleResult = evaluateRule(rule, data, layouts, locale, AdminOnly)
        const operatorGroups = { AND: '&', OR: '|' }
        const resultGroup = rule.operatorGroup ? operatorGroups[rule.operatorGroup] : ''
        rules.push(`${rule.parenOpen}${ruleResult}${rule.parenClose}${resultGroup}`)
    }
    return `(${rules.join('')})`
}

function applyOperator(operator: 'AND' | 'OR', leftOperand: boolean, rightOperand: boolean): boolean {
    //console.log(operator, 'left', leftOperand, 'right', rightOperand)

    if (operator === 'AND') {
        return leftOperand && rightOperand;
    } else {
        return leftOperand || rightOperand;
    }
}

function evaluateRule<T>(rule: IRuleElement, data: any, layouts: ILayout[], locale: string, AdminOnly: boolean): boolean {

    if (rule.field === userRole) {

        let roleOperator: FormAssemblyOperator | undefined;

        for (const [key, value] of Object.entries(FormAssemblyOperator)) {
            if (value === rule.operator) {
                roleOperator = value
                break
            }
        }

        switch (rule.value) {
            case 'Administrator':
                switch (roleOperator) {
                    case FormAssemblyOperator.Equal:
                        return AdminOnly
                    case FormAssemblyOperator.NotEqual:
                        return !AdminOnly
                    default:
                        return false
                }
            default:
                return false
        }

    } else {


        const fieldValue = data[rule.field] ?? ''

        const layoutItem = layouts.find(l => l.Id === rule.field)

        if (!layoutItem)
            return false
      
        let operator: FormAssemblyOperator | undefined;

        for (const [key, value] of Object.entries(FormAssemblyOperator)) {
            if (value === rule.operator) {
                operator = value
                break
            }
        }

        const {left, right}: any = getLeftAndRightData(layoutItem, operator)?.(fieldValue, `${rule.value}`)

        const callback = getCallback(layoutItem, locale)
        return evaluateConditionCallback(layoutItem, left, right, operator, callback, locale)
    }
}

/**
 * Returns a callback function based on the given layout type.
 *
 * @param {ILayout} layout - The layout object containing the type of input component.
 * @param locale
 * @returns {Function} - The callback function to be used for sorting or comparing values.
 */
const getCallback = (layout: ILayout, locale:string): Function => {
    switch (layout.Type) {
        case InputType.DatePicker: {
            return (a: any, b: any) => {
                return diffDates(a, b, 'days')
            }
        }
        case InputType.DropDownList: {
            return (a: any, b: string) => {
                if (isNaN(Number(a)) || isNaN(Number(b))) {
                    return a?.toString().localeCompare(b,locale)
                }
                else {
                    return Number(a) - Number(b)
                }
            }
        }
        case InputType.CheckBoxList: {
            return (a: any, b: string) => {
                if (isNaN(Number(a)) || isNaN(Number(b))) {
                    return a?.toString().localeCompare(b,locale)
                }
                else {
                    return Number(a) - Number(b)
                }
            }
        }
        case InputType.RichText: {
            return (a: any, b: string) => {
                if (isNaN(Number(a)) || isNaN(Number(b))) {
                    return a?.toString().localeCompare(b,locale)
                }
                else {
                    return Number(a) - Number(b)
                }
            }
        }
        case InputType.Currency:
        case InputType.Number: {
            return (a: any, b: string) => {
                return Number(a) - Number(b)
            }
        }
        case InputType.Textbox: {
            return (a: any, b: string) => {
                if (isNaN(Number(a)) || isNaN(Number(b))) {
                    return a?.toString().localeCompare(b,locale)
                }
                else {
                    return Number(a) - Number(b)
                }
            }
        }
        case InputType.TextArea: {
            return (a: any, b: string) => {
                if (isNaN(Number(a)) || isNaN(Number(b))) {
                    return a?.toString().localeCompare(b,locale)
                }
                else {
                    return Number(a) - Number(b)
                }
            }
        }
    }
    return (a: any, b: string) => a?.toString().localeCompare(b, locale)
}
const evaluateConditionCallback = (layoutItem: ILayout, leftValue: any, rightValue: string, operator: FormAssemblyOperator | undefined, callback: Function, locale:string) => {
 
    
    if(operator === undefined) {
        return false
    }
  

    if (leftValue === undefined && rightValue === undefined) {
        return false
    }
    const value = callback(leftValue, rightValue)
  
    switch (operator) {
        case FormAssemblyOperator.Equal:
            return value === 0
        case FormAssemblyOperator.NotEqual:
            return value !== 0
        case FormAssemblyOperator.GreaterThan:
            return value > 0
        case FormAssemblyOperator.GreaterThanOrEqual:
            return value >= 0
        case FormAssemblyOperator.LessThan:
            return value < 0
        case FormAssemblyOperator.LessThanOrEqual:
            return value <= 0
        case FormAssemblyOperator.Include: 
            switch (layoutItem.Type) {
                case InputType.DatePicker:

                    if (leftValue === undefined) {
                        leftValue = ''
                    }

                    if (typeof leftValue === 'string') {
                        return leftValue?.toString().toLowerCase().includes(rightValue?.toString().toLowerCase())
                    }
                    else if (leftValue instanceof Date) {
                        const dateLeftValue = moment(leftValue).locale(locale).format(layoutItem.Validations.Regex)
                        return leftValue?.toString().toLowerCase().includes(rightValue?.toString().toLowerCase()) || dateLeftValue.toString().toLowerCase().includes(rightValue?.toString().toLowerCase())
                    }
                    
                    return leftValue?.toString().toLowerCase().includes(rightValue?.toString().toLowerCase())
                default:
                    return leftValue?.toString().toLowerCase().includes(rightValue?.toString().toLowerCase())
            }
        case FormAssemblyOperator.NotInclude:
            switch (layoutItem.Type) {
                case InputType.DatePicker:
                    if (leftValue === undefined) {
                        leftValue = ''
                    }

                    if (typeof leftValue === 'string') {
                        return !leftValue?.toString().toLowerCase().includes(rightValue?.toString().toLowerCase())
                    }
                    else if (leftValue instanceof Date) {
                        const dateLeftValue = moment(leftValue).locale(locale).format(layoutItem.Validations.Regex)
                        return !leftValue?.toString().toLowerCase().includes(rightValue?.toString().toLowerCase()) && !dateLeftValue.toString().toLowerCase().includes(rightValue?.toString().toLowerCase())
                    }
                    return leftValue?.toString().toLowerCase().includes(rightValue?.toString().toLowerCase())
                default:
                    return !leftValue?.toString().toLowerCase().includes(rightValue?.toString().toLowerCase())
            }            
        default:
            return false;
    }
}

/**
 * Checks if a given string is null or empty.
 *
 * @param {string|null} str - The string to check.
 * @returns {boolean} - Returns true if the string is null or empty, false otherwise.
 */
export const isNullOrEmpty = (str: string | null): boolean => {
    return !str || str.trim() === '';
}


const getLeftAndRightData = (layout: ILayout, operator: FormAssemblyOperator | undefined) => {
    switch (layout.Type) {
        case InputType.DatePicker: {
            const callback = (a: any, b: string) => {

                let _a:any
                if(a === 'cleared' || a === '' || a === undefined) {
                    _a = undefined
                }
                else {
                    const _leftDate = new Date(a)
                    _a = parseISOToDate(getDateWithFormat(_leftDate, layout.Validations.Regex))
                }

                let _rightDate: Date | string | undefined = undefined
                switch (operator) {
                    case FormAssemblyOperator.NotInclude:
                        _rightDate = b
                        break
                    case FormAssemblyOperator.Include:
                        _rightDate = b
                        break
                    default:
                        _rightDate = parseISOToDate(getDateFromFormat(b, layout.Validations.Regex))
                        break
                }
                if (_rightDate === undefined) {
                    return { left: undefined, right: undefined }
                }
                return { left: _a, right: _rightDate }
            }
            return callback
        }
        case InputType.Currency:
        case InputType.Number: {

            return (a: any, b: string) => {
                const _a = getNumberFromString(a)
                const _b = getNumberFromString(b)
                return { left: _a, right: _b }
            }
        }
        case InputType.DropDownList: {
            const callback = (a: any, b: string) => {
                let _a = a ?? ''
                if (_a.hasOwnProperty('key')) {
                    _a = a.text
                }
                return { left: _a, right: b }
            }
            return callback
        }
        case InputType.CheckBoxList: {
            const callback = (a: any, b: string) => {
                let _a = a ?? ''
                let _b = b ?? ''
                if (Array.isArray(_a)) {
                    _a = _a.map(x => x.text.trim()).sort((a, b) => a.toString().localeCompare(b)).join(',')
                }
                _b = b.split(',').map(x => x.trim()).sort((a, b) => a.toString().localeCompare(b)).join(',')
                return { left: _a, right: _b }
            }
            return callback
        }
        case InputType.RichText: {
            const callback = (a: any, b: string) => {
                let _a = cleanHtml(a)
                return { left: _a, right: b }
            }
            return callback
        }
        case InputType.CheckBox:
            return (a: any, b: string) => {
                if(a === undefined || a.toString().trim().length === 0) {
                    return { left: 'false', right: b.trim().toLowerCase() }    
                }
                else {
                    return {left: a?.toString().trim().toLowerCase(), right: b.trim().toLowerCase()}
                }
            }
        default:
            return (a: any, b: string) => {
                return { left: a, right: b }
            }
    }
}
export const validateConditionRules = (conditions: IRuleElement[]) => {
    const stack: string[] = []
    for (let i = 0; i < conditions.length; i++) {
        const rule = conditions[i];
        if (rule.parenOpen === '(') {
            stack.push('(');
        }
        if (rule.parenClose === ')') {
            if (stack.pop() !== '(') {
                return false
            }
        }
    }

    return stack.length === 0
}
