import { IIconProps, IconButton, Modal, Panel, PanelType, getTheme, mergeStyleSets } from '@fluentui/react'
import React, { HTMLAttributes, ReactElement } from 'react'
import CustomModal, { Sizes } from './CustomModal'
import { isMobile } from "react-device-detect";
import { useBranding } from "../hooks/useBranding";
const theme = getTheme();

const cancelIcon: IIconProps = { iconName: 'Cancel' }
type ResponsiveModalProps = {
    className?: string | undefined
    children: ReactElement
    isOpen: boolean
    title: string
    titleId: string
    navigation: React.ReactNode
    handleClose: () => void
    closeButtonAriaLabel: string
    containerClassName?: string | undefined
    helpButton?: ReactElement
}

export default function ResponsiveModal({ className, containerClassName, children, isOpen, handleClose, title,
    titleId, helpButton, closeButtonAriaLabel, navigation }: ResponsiveModalProps) {
    const { branding } = useBranding()
    const _styles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '80vw',
            height: '90vh',
        },
    })

    const iconButtonStyles = {
        root: {
            color: branding.theme.palette.themePrimary,
            backgroundColor: isMobile ? 'transparent' : "lightgray",
            marginRight: isMobile ? '14px' : "0",
            selectors: {
                ":hover": {
                    cursor: "pointer",
                },
            },
        },
        rootHovered: {
            backgroundColor: isMobile ? 'transparent' : "lightgray",
        },
    };

    const styles = mergeStyleSets({
        contentPanelStyle: {

        },
        panelHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '18px',
            paddingLeft: '24px'
        },
        header: {
            fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
            fontWeight: "bolder",
            color: branding.theme.palette.themePrimary,
            fontSize: 20,
        }
    })

    return (
        <>
            {isMobile ?
                <Panel
                    onRenderNavigation={(props, defaultRender) => {
                        return (
                            <div className={styles.panelHeader}>
                                <div className={styles.header}>{title}</div>
                                <div>
                                    {helpButton}
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={handleClose}
                                    />
                                </div>
                            </div>)
                    }}
                    styles={{
                        content: {
                            padding: '0 14px'
                        }
                    }}
                    className={styles.contentPanelStyle}
                    isBlocking={false}
                    isOpen={isOpen}
                    onDismiss={handleClose}
                    closeButtonAriaLabel={closeButtonAriaLabel}
                    type={PanelType.smallFluid}
                >
                    {navigation}
                    {children}
                </Panel> :
                <Modal className={className} isOpen={isOpen} isBlocking={true} containerClassName={`${_styles.container} ${containerClassName}`}>
                    <div>
                        {navigation ? navigation :
                            <>
                                <span id={titleId}>{title}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={handleClose}
                                />
                            </>
                        }
                    </div>
                    <div>
                        {children}
                    </div>
                </Modal>
            }
        </>

    )
}


