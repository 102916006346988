import * as React from 'react';
import { DefaultButton, DialogFooter, mergeStyleSets, PrimaryButton, ProgressIndicator, Spinner, SpinnerSize, TextField } from '@fluentui/react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IDocumentLibrary } from '../../../interfaces/IDocumentAssembly'
import DocumentAssemblyService from '../../../services/assembly'
import { IStatusMessage } from '../../../interfaces/IApp';
import {useBranding} from "../../../hooks/useBranding";
type DocumentLibraryFormType = {
    type: 'create' | 'update'
    close: (loading: boolean, message?: IStatusMessage) => void
    templateId: number
    item?: IDocumentLibrary
}
type FormFields = {
    FileName: string,
    File: string
}
const defaultValues: FormFields = {
    FileName: '',
    File: ''
}
const DOC_MIMETYPE = "application/msword"
const DOCX_MIMETYPE = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
const DocumentLibraryForm = ({ close, templateId, item }: DocumentLibraryFormType) => {
    const { t } = useTranslation('assembly')
    const { branding } = useBranding()
    const formType: 'create' | 'update' = React.useMemo(() => {
        return !item ? 'create' : 'update'
    }, [item])
    const [isLoading, setIsLoading] = React.useState(false)
    const [uploading, setUploading] = React.useState(false)
    const { control, handleSubmit, formState: { errors }, setValue, setError, clearErrors } = useForm<FormFields>({
        defaultValues: defaultValues
    })

    const saveForm = (formData: FormFields) => {
        setIsLoading(true)
        if (formType === 'create') {
            DocumentAssemblyService.uploadDocumentLibrary(templateId, formData.File, formData.FileName).then(({ data }: any) => {
                if (data.Success === false) {
                    close(true, { Type: 'error', Message: <>Error on uploading document <strong>{formData.FileName}</strong>:<br /> {data.ExceptionMessage}</> })
                    return
                }

                close(true, { Type: 'success', Message: <>Document <strong>{formData.FileName}</strong> was upload successfully</> })
                setIsLoading(false)
            })
        } else {
            DocumentAssemblyService.updateDocumentLibrary(templateId, item?.TemplateId ?? -1, formData.File, formData.FileName).then(({ data }: any) => {
                if (data.Success === false) {
                    close(true, { Type: 'error', Message: <>Error on updating document <strong>{formData.FileName}</strong>:<br /> {data.ExceptionMessage}</> })
                    return
                }

                close(true, { Type: 'success', Message: <>Document <strong>{formData.FileName}</strong> was updated successfully</> })
                setIsLoading(false)
            })
        }


    }
    const modalClose = () => {
        close(false)
    }
    const convertFileToBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    }
    const onUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setUploading(true)

        const { files }: { files: FileList | null } = event.target
        if (!files || !files[0]) {
            setUploading(false)
            return
        }
        const file = files[0]
        const fileType = file.type.toLowerCase()
        const fileName = file.name.toLowerCase()
        const isWordFile = (fileType === DOC_MIMETYPE || fileType === DOCX_MIMETYPE) && (fileName.endsWith('.doc') || fileName.endsWith('.docx'))
        if (!isWordFile) {
            setError('File', { type: 'InvalidType', message: 'Invalid file type' })
            setUploading(false)
            return
        } else {
            clearErrors('File')
        }

        const base64 = await convertFileToBase64(file)
        setValue('FileName', file.name)
        setValue('File', base64.toString().split(',')[1])
        setUploading(false)
    }
    return (
        <>
            <form onSubmit={handleSubmit(saveForm)}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 block">
                            <div>
                                <div className="ms-Grid" dir="ltr">
                                    {!isLoading ?
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className="ms-Grid-row">
                                                    {formType === 'update' ?
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mb-4" >
                                                            <TextField required readOnly disabled={true} value={item?.FileName}
                                                                title={'Previous document'} label="Previous document" />
                                                        </div> : null}
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div>
                                                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ paddingRight: "0px" }}>
                                                            <label htmlFor="File"><div className="divHover divToolBoxItem" style={{color: branding.theme.palette.white, backgroundColor: branding.theme.palette.themePrimary}}>{t('Common.Input.File', { ns: 'common' })}</div></label>
                                                            <Controller name="File" control={control} rules={{ required: true }}
                                                                render={({ field }) =>
                                                                    <input className="fileButton" id="File" name="File" type="file" accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={onUploadFile} />
                                                                }
                                                            />
                                                            {uploading ?
                                                                <ProgressIndicator description={t('text.spinnerUpdating', { ns: 'common' })} />
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" >
                                                            <Controller name="FileName" control={control}
                                                                render={({ field }) => <TextField readOnly disabled={true} value={field.value}
                                                                    title={t('title.inputFile', { ns: 'attachmentsComponent' })} placeholder={t('placeholder.inputFile', { ns: 'attachmentsComponent' })} />}
                                                            />
                                                            <span className={contentStyles.error}>{errors.File && <p>{errors.File.message}</p>}</span>
                                                            <span className={contentStyles.error}>{errors.File?.type === 'required' && <p>This field is required</p>}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <DialogFooter>
                                                        <PrimaryButton id="btnCreateUpdate" type="submit" text={formType === 'create' ? 'Create' : 'Update'} disabled={false} title={formType === 'create' ? 'Create' : 'Update'} />
                                                        <DefaultButton id="btnCancel" onClick={modalClose} text={t('formSettings.btnCancel')} title={t('formSettings.btnCancel')} />
                                                    </DialogFooter>
                                                </div>
                                            </div>
                                        </div> :
                                        <Spinner size={SpinnerSize.medium} label={t('text.spinnerAdding', { ns: 'common' })} ariaLive="assertive" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>

    )
}
const contentStyles = mergeStyleSets({
    section: {
        padding: '0.5rem 0'
    },
    error: {
        color: '#a4262c'
    }
})
export default DocumentLibraryForm


