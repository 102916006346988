import {IFormAssemblyRule} from "../../../../interfaces/IFormAssembly"
import {ILayout} from "../../CustomTemplate"
import {InputType} from "../../Validations"

export enum AssemblyActions {
    VALIDATE_RULES = 'VALIDATE_RULES',
    VALIDATE_TAB_RULES = 'VALIDATE_TAB_RULES',
    RUN_ACTIONS = 'RUN_ACTIONS',
    RUN_TAB_ACTIONS = 'RUN_TAB_ACTIONS',
    RESTART_VALUE = 'RESTART_VALUE'
}
type AssemblyAction = {
    type: AssemblyActions.VALIDATE_RULES,
    payload: {
        isAdmin: boolean,
        values: any
    }
} | {

    type: AssemblyActions.VALIDATE_TAB_RULES,
    payload: {
        isAdmin: boolean,
        values: any
    }
} | {
    type: AssemblyActions.RUN_ACTIONS,
    payload: {
        type: InputType
        value: string
    }
} | {
    type: AssemblyActions.RUN_TAB_ACTIONS
} | {
    type: AssemblyActions.RESTART_VALUE
}
export type AssemblyState = {
    id: string
    formRules: IFormAssemblyRule[]
    layouts: ILayout[]
}

export const formAssemblyReducer = (state: AssemblyState, action: AssemblyAction): AssemblyState => {

    switch (action.type) {
        case AssemblyActions.VALIDATE_RULES:
           
            break
        case AssemblyActions.VALIDATE_TAB_RULES:
           
            break
        case AssemblyActions.RUN_ACTIONS: 
           
            break
        case AssemblyActions.RUN_TAB_ACTIONS: {
           
            break
        }
        case AssemblyActions.RESTART_VALUE:
           
            break
        default:
            return state

    }
    return state
}