import {FC, useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import * as React from "react";
import {ReactHookFormControlProps} from "../Models/ReactHookFormControlProps";
import {TextField, ITextFieldProps} from "@fluentui/react/lib/TextField";
import {SingleSelectStyles, textFieldStyle} from "./Styles";

export const RHFNumberControl: FC<ReactHookFormControlProps & ITextFieldProps> = (props) => {

    const [customError, setCustomError] = useState<string | undefined>(undefined)
    const [currentValue, setCurrentValue] = useState<string>(() => {
            const value = props.getValues(props.id)

            if (value === undefined || value === null || value === "") {
                props.setValue(props.id, "0")
                return "0"
            }

            if (Number.isNaN(Number(value))) {
                setCustomError(`Value is not valid: ${value}`)
                return "0"
            } else return value
        }
    )

    
    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                         field: {onChange, onBlur, name: fieldName, value},
                         fieldState: {error}
                     }) => (
                <>
                    <TextField
                        id={props.id}
                        name={fieldName}
                        label={props.label}
                        onRenderLabel={props.onRenderLabel}
                        title={props.title}
                        maxLength={props.maxLength}
                        placeholder={props.placeholder}
                        errorMessage={error && error.message}
                        required={props.required}
                        disabled={props.readOnly ? props.readOnly : false}
                        value={currentValue}
                        onChange={(e) => {
                            let value = (e?.target as HTMLInputElement).value;
                            onChange(value.substring(0, props.maxLength))
                            setCurrentValue(value.substring(0, props.maxLength))
                            props.setValue(props.id, value.substring(0, props.maxLength))
                        }}
                        onBlur={() => {
                            if(props.OnBlurOrOnChange !== undefined) props.OnBlurOrOnChange()
                            else onBlur()
                        }}
                        resizable={false}
                        defaultValue={undefined}
                        type={"number"}
                        styles={textFieldStyle}
                        borderless
                        tabIndex={props.tabIndex}
                    />
                    {customError &&
                        <div style={{fontWeight: "400", paddingTop: "2px"}} className={SingleSelectStyles.error}>
                            {customError}
                        </div>
                    }
                </>
            )}
        />
    );
};