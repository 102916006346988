import HttpRequest from "./HttpRequest"

const CampaignService = {
    getCampaignPrefillFile: async ({ CampaignMasterId, TemplateId }: { CampaignMasterId: string, TemplateId: string }) => {
        return await HttpRequest.getWithSession('GetCampaignPrefillFile', {
            CampaignMasterId,
            TemplateId
        })
    },

    getCampaignPrefillSpecificationFile: async ({ CampaignMasterId, TemplateId }: { CampaignMasterId: string, TemplateId: string }) => {
        return await HttpRequest.getWithSession('GetCampaignPrefillSpecificationFile', {
            CampaignMasterId,
            TemplateId
        })
    },

    uploadAndProcessCampaignPrefill: async ({ CampaignMasterId, Base64File, TemplateId }: { CampaignMasterId: string, Base64File: string, TemplateId: string }) => {
        return await HttpRequest.postWithSession('UploadAndProcessCampaignPrefill', {
            CampaignMasterId,
            Base64File,
            TemplateId,
        })
    },

}

export default CampaignService