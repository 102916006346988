import { getTheme, Icon, IDropdownOption } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RHFDropDownListSingleSelectControl } from '../../../RHFControls/RHFDropDownListSingleSelectControl';
import { RHFTextBoxControl } from '../../../RHFControls/RHFTextBoxControl';
import { RHFToggleControl } from '../../../RHFControls/RHFToggleControl';

const theme = getTheme();
const iconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    selectors: {
      ':hover': {
        cursor: 'pointer',
      },
      ':disabled': {
        backgroundColor: theme.palette.white
      },
    },
  },
  rootHovered: {
    color: theme.palette.black,
  },
};


function AttachmentProperties(props: any) {

  const { handleSubmit, control, setValue, getValues, reset, clearErrors, trigger, formState, resetField, register, unregister } = useForm<any, any>({
    defaultValues: {},
    reValidateMode: "onSubmit",
    mode: "all",
  });

  return (<>
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ paddingLeft: "0px" }}>
          <RHFToggleControl
            id="toggleLockLocation"
            checked={props.lockItem}
            onChange={props.onChangeBlock}
            title={!props.lockItem ? "Unlocked item location." : "Locked item location."}
            control={control}
            setValue={setValue}
            getValues={getValues}
            trigger={trigger}
          />
        </div>
        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ paddingLeft: "0px" }}>
          <Icon
            styles={iconButtonStyles}
            iconName={!props.lockItem ? "Unlock" : "Lock"}
            title="Specify whether this control's location is locked." />
        </div>
        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4" >

        </div>
        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ paddingRight: "0px" }}>
          <RHFToggleControl
            id="toggleAvailable"
            checked={props.adminOnly}
            disabled={props.isLineBreak}
            onChange={props.onChangeAdminOnly}
            title={!props.adminOnly ? "Available to all authorized users." : "Only available to administrators."}
            control={control}
            setValue={setValue}
            getValues={getValues}
            trigger={trigger}          />
        </div>
        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ paddingRight: "0px" }}>
          <Icon
            styles={iconButtonStyles}
            iconName={"PartyLeader"}
            title="Specify whether this control is only visible to administrators." />
        </div>
      </div>
    </div>
    <RHFTextBoxControl
      id="PropFieldLabel"
      maxLength={props.selectedSize * 100}
      onChange={props.onChangeLabel}
      disabled={props.disabled}
      value={props.selectedLabel}
      label="Field Label"
      title="Enter label that displays for this control on form wizard."
      control={control}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
    />
    <RHFDropDownListSingleSelectControl
      id="PropFieldType"
      label="Field Type"
      options={props.isGridList ? props.InputsList.filter((value: IDropdownOption) => value.text === 'Currency' || value.text === "Date" || value.text === "Drop Down List" || value.text === "Integer" || value.text === "Text Box") : props.InputsList!}
      onChange={props.onTypeDropdownChange}
      selectedKey={props.selectedType}
      disabled={props.disabled}
      title="Select control data input type."
      control={control}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
    />
  </>)
}

export default AttachmentProperties