import {ITemplateGeneral} from "../../Helpers/Helper";
import HttpRequest from "../../services/HttpRequest";
import {
    ICreateUpdateTemplateDefinitionRequest,
    IGetDocumentOnlyTemplateIDBasic,
    IGetTemplateDefinitionRequest, IGetTemplateStatusRequest,
    IHttpClientResponse
} from "./index";
import {IUpdateTemplateStatusRequest} from "./EmailTemplate.data";


/**
 * EmailTemplateNotificationsServices
 */
export const DocumentOnlyTemplateServices = {

    /**
     * GET getDocumentOnlyTemplateIDBasic
     * @param request
     */
    getDocumentOnlyTemplateIDBasic: async (request: IGetDocumentOnlyTemplateIDBasic): Promise<ITemplateGeneral> => {
        let {data}: any = await HttpRequest.getWithSession('getDocumentOnlyTemplateIDBasic', request)

        return new Promise<ITemplateGeneral>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    }
}

/**
 * EmailTemplateNotificationsServices
 */
export const EmailTemplateNotificationsServices = {

    /**
     * GET GetTemplateDefinition
     * @param request
     */
    GetTemplateDefinition: async (request: IGetTemplateDefinitionRequest): Promise<IHttpClientResponse> => {
        let {data}: any = await HttpRequest.getWithSession('EmailTemplateNotifications/GetTemplateDefinition', request)

        return new Promise<IHttpClientResponse>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },

    /**
     * CreateUpdateTemplateDefinition
     * @param request
     * @constructor
     */
    CreateUpdateTemplateDefinition: async (request: ICreateUpdateTemplateDefinitionRequest): Promise<IHttpClientResponse> => {
        let {data}: any = await HttpRequest.postWithSession('EmailTemplateNotifications/CreateUpdateTemplateDefinition', request)

        return new Promise<IHttpClientResponse>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },

    /**
     * DeleteTemplateDefinition
     * @param request
     * @constructor
     */
    DeleteTemplateDefinition: async (request: IGetTemplateDefinitionRequest): Promise<IHttpClientResponse> => {
        let {data}: any = await HttpRequest.postWithSession('EmailTemplateNotifications/DeleteTemplateDefinition', request)

        return new Promise<IHttpClientResponse>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },

    /**
     * UpdateTemplateStatus
     * @param request
     * @constructor
     */
    UpdateTemplateStatus: async (request: IUpdateTemplateStatusRequest): Promise<IHttpClientResponse> => {
        let {data}: any = await HttpRequest.postWithSession('EmailTemplateNotifications/UpdateTemplateStatus', request)

        return new Promise<IHttpClientResponse>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },

    /**
     * GetTemplateStatus
     * @param request
     * @constructor
     */
    GetTemplateStatus: async (request: IGetTemplateStatusRequest): Promise<IHttpClientResponse> => {
        let {data}: any = await HttpRequest.getWithSession('EmailTemplateNotifications/GetTemplateStatus', request)

        return new Promise<IHttpClientResponse>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    }

}

