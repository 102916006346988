import { FC, useState } from "react";
import { Controller } from "react-hook-form";
import { Dropdown, IDropdownProps, ResponsiveMode } from "office-ui-fabric-react";
import * as React from "react";
import { HookFormProps } from "./HookFormProps";

export const ControlledCheckListField: FC<HookFormProps & IDropdownProps> = (
  props
) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error }
      }) => (
        <Dropdown
          {...props}
          selectedKeys={selectedKeys}
          onChange={(_e, option) => {
            if (option) {
              setSelectedKeys(
                option.selected ? [...selectedKeys, option.key as string] : selectedKeys.filter(key => key !== option.key),
              );
              var arr: string[] = value
              if (option.selected) {
                arr = [...arr, option.text]
              }
              else {
                arr = arr.filter(text => text !== option.text)
              }
              onChange(arr)
            }
          }}
          multiSelect
          responsiveMode={ResponsiveMode.large}
          onBlur={onBlur}
          errorMessage={error && error.message}
          disabled={props.readOnly ? props.readOnly : false}
          defaultValue={undefined}
        />
      )}
    />
  );
};