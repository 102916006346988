import HttpRequest from "./HttpRequest";

const WizardService = {

    getStatemanagement: async ({ templateId, Path }: { templateId: string, Path: string }) => {
        return HttpRequest.getWithSession('statemanagement', { templateId, Path, User: '' })
    },
    getDocumentOnlyAudienceDetailByUserName: async ({ Path }: { Path: string }) => {
        return HttpRequest.getWithSession('DocumentOnlyAudienceDetailByUserName', { Path })
    },
    getCCGridList: async ({ TemplateId }: { TemplateId: number }) => {
        return HttpRequest.getWithSession('GetContentControls', { TemplateId })
    },
    getExpressionsByTemplate: async ({ TemplateId, Path }: { TemplateId: number, Path: string }) => {
        return HttpRequest.getWithSession('GetExpressionsByTemplate', { TemplateId, Path })
    },
    getExpressionList: async ({ TemplateId, Path }: { TemplateId: number, Path: string }) => {
        return HttpRequest.getWithSession('GetExpressionList', { TemplateId, Path })
    },
    getDateFormats: async ({ TemplateId, Path }: { TemplateId: number, Path: string }) => {
        return HttpRequest.getWithSession('GetDateFormats', { TemplateId, Path })
    },
    getDateUnits: async ({ TemplateId, Path }: { TemplateId: number, Path: string }) => {
        return HttpRequest.getWithSession('GetDateUnits', { TemplateId, Path })
    },
    addExpression: async (data: any) => {
        return HttpRequest.postWithSession('AddExpression', { ...data })
    },
    updateExpression: async (data: any) => {
        return HttpRequest.postWithSession('UpdateExpression', { ...data })
    },
    deleteExpresion: async ({ TemplateId, Path, Id }: { TemplateId: string, Path: string, Id: string }) => {
        return HttpRequest.postWithSession('DeleteExpresion', {
            TemplateId, Path, Id, Name: "", Description: "", Order: 0, Enabled: false, Expression: "",
        })
    },
    saveStatemanagement: async (data: any) => {
        return HttpRequest.postWithSession('statemanagement', { ...data })
    },
    saveContentControls: async ({ TemplateId, operations }: { TemplateId: number, operations: OperationsManualTagType[] }) => {
        const addTags: string[] = operations.reduce<string[]>((p, o) => { return o.operation === 'add' ? [...p, o.id] : p }, [])
        const delTags: string[] = operations.reduce<string[]>((p, o) => { return o.operation === 'delete' ? [...p, o.id] : p }, [])
        const errors: string[] = []
        if (delTags.length > 0) {
            const { data }: any = await WizardService.deleteContentControl({ TemplateId, TagNames: delTags })
            if (!data.Success) {
                errors.push(data.ExceptionMessage)
            }
        }
        if (addTags.length > 0 && errors.length === 0) {
            const { data }: any = await WizardService.addContentControl({ TemplateId, TagNames: addTags })
            if (!data.Success) {
                errors.push(data.ExceptionMessage)
            }

        }

        if (errors.length > 0) {
            return new Promise(solve => solve({ data: { Success: false, ExceptionMessage: errors.join(' & ') } }))
        } else {
            return new Promise(solve => solve({ data: { Success: true, ExceptionMessage: '' } }))
        }
    },
    addContentControl: async ({ TemplateId, TagNames }: { TemplateId: number, TagNames: string[] }) => {
        return HttpRequest.postWithSession('AddContentControl', { TemplateId, TagNames, operation: 'addcc' })
    },
    validateContentControl: async ({ TemplateId, TagNames }: { TemplateId: number, TagNames: string[] }) => {
        return HttpRequest.postWithSession('AddContentControl', { TemplateId, TagNames, operation: 'validatecc' })
    },
    deleteContentControl: async ({ TemplateId, TagNames }: { TemplateId: number, TagNames: string[] }) => {
        return HttpRequest.postWithSession('DeleteContentControl', { TemplateId, TagNames })
    }

}
export type OperationsManualTagType = {
    id: string
    operation: 'delete' | 'add'
}
export default WizardService