import React, {useContext, useEffect, useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import '../../Custom.css';
import {
    CommandBarButton,
    getTheme,
    IColumn,
    IComboBoxOption,
    Label,
    Pivot,
    PivotItem,
    Position,
    ShimmeredDetailsList,
    ShimmerElementType,
    SpinButton,
    Stack,
    StackItem
} from "@fluentui/react";
import {DefaultButton, IconButton, PrimaryButton} from "@fluentui/react/lib/Button";
import {Panel, PanelType} from "@fluentui/react/lib/Panel";
import {Nav} from "@fluentui/react/lib/Nav";
import {Text} from "@fluentui/react/lib/Text";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import {Separator} from "@fluentui/react/lib/Separator";
import {Modal} from "@fluentui/react/lib/Modal";
import {IIconProps} from "@fluentui/react/lib/Icon";
import {contentStyles, iconButtonStyles, itemsPaddingMediaQuery, navStyles, nonShrinkingStackItemStyles} from "./Style"
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import {Spinner, SpinnerSize} from "@fluentui/react/lib/Spinner";
import RenewalsAndAlertsService, {
    loadPropertybag,
    loadRecord,
    loadTemplate,
    ResponseGetContextRenewals,
    ResponseGetContextTemplate
} from "./RenewalsAndAlerts.service";
import {RHFTextBoxControl} from "../RHFControls/RHFTextBoxControl";

import {FormProvider, useForm} from "react-hook-form";
import RichTextEditor, {EditorValue} from "react-rte";
import {SingleRHFRichTextControl} from "../RHFControls/SingleRHFRichTextControl";
import {
    calculateYears,
    calculateYearsMonths,
    columnsRenewalsView,
    frequencyOptions,
    onRenderOption,
    removeHTMLTags,
    setDefaultOptions,
    toolbarConfigEmailBody,
    toolbarConfigEmailSubject
} from "./RenewalsAndAlerts.data";
import {RHFDropDownListSingleSelectControl} from "../RHFControls/RHFDropDownListSingleSelectControl";
import {ILayout} from "../CustomTemplates/CustomTemplate";
import {Shimmer} from "office-ui-fabric-react/lib/Shimmer";
import {IContextualMenuProps} from "office-ui-fabric-react";
import CustomDialog from "../../share/CustomDialog";
import {DetailsListLayoutMode, SelectionMode} from "office-ui-fabric-react/lib/DetailsList";
import {AppContext} from "../Provider";
import SimplePreviewScreen from "../Preview/SimplePreviewScreen";
import {IPropertyBag} from "../DataPanel/DataPanel";
import Helper from "../../Helpers/Helper";
import {CustomForm} from "../CustomTemplates/CustomForm/CustomForm";
import RenewalsAndAlertsEdit from "./RenewalsAndAlertsEdit";
import RenewalsAndAlertsRenewal from "./RenewalsAndAlertsRenewal";
import RenewalsEmailPreview from "./Hooks/RenewalsEmailPreview";
import { useBranding } from '../../hooks/useBranding';

type Properties = {
    titleId: string,
    cancel: any
    getLinkGroup: any
    option: string
    setIsOpenMenu: any
    showModal: any
    onLinkClick: any
    item?: any,
    isOpenMenu: any
}

function RenewalsAndAlerts({
                               showModal,
                               option,
                               item,
                               getLinkGroup,
                               titleId,
                               cancel,
                               isOpenMenu,
                               onLinkClick,
                               setIsOpenMenu
                           }: Properties) {

    const { branding } = useBranding()
    const {t} = useTranslation(['renewalsalerts', 'common'])
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const menuIcon: IIconProps = {iconName: 'GlobalNavButton'};
    const [isLoadingCC, setIsLoadingCC] = useState(true)
    const [isLoadingContext, setIsLoadingContext] = useState(true)
    const [isLoadingRenewals, setIsLoadingRenewals] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [id, setId] = useState(-1)
    const [renewalCount, setRenewalCount] = useState(0)
    const [showPreview, setShowPreview] = useState(false)
    const [showPreviewEmail, setShowPreviewEmail] = useState(false)
    const [previewTitle, setPreviewTitle] = useState("")
    const [previewId, setPreviewId] = useState(0)
    const [currentTemplateId, setCurrentTemplateId] = useState(0)
    const [isLoadedContext, setIsLoadedContext] = useState(false)
    const [showCustomForm, setShowCustomForm] = useState(false);

    // Pivot 1, Pivot 2, CC
    const [messagePivot1, setMessagePivot1] = useState("")
    const [messagePivot2, setMessagePivot2] = useState("")
    const [statusMessageEnumPivot1, setStatusMessageEnumPivot1] = useState<StatusEnum>(StatusEnum.Info)
    const [statusMessageEnumPivot2, setStatusMessageEnumPivot2] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessagePivot1, setShowMessagePivot1] = useState(false)
    const [showMessagePivot2, setShowMessagePivot2] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [renewalsColumns, setRenewalsColumns] = useState<IColumn[]>([])
    const refRenewalsColumns = useRef(renewalsColumns);

    const [rteValue, setRteValue] = useState(RichTextEditor.createEmptyValue())
    const [rteValueSubject, setRteValueSubject] = useState(RichTextEditor.createEmptyValue())
    const [activePivot, setActivePivot] = useState("pivot1")
    const [templateContentControls, setTemplateContentControls] = useState<IComboBoxOption[]>([])
    const [contextRenewals, setContextRenewals] = useState<ResponseGetContextRenewals[]>([])
    const refContextRenewals = useRef(contextRenewals);

    const formRenewals = useForm();
    const {context} = useContext(AppContext)
    const [showRenewals, setShowRenewals] = useState(false);

    //region Form regin variables
    const [previewPDF, setPeviewPDF] = useState(false);
    const [showRenewalRenewal, setShowRenewalRenewal] = useState(false)
    const [data, setData] = useState("");
    const [draftId, setDraftId] = useState<number>(-1);
    const [documentId, setDocumentId] = useState(-1);
    const [documentUpdate, setDocumentUpdate] = useState(false);
    const [iscoOwner, setiscoOwner] = useState(false);
    const [isOwner, setisOwner] = useState(false);
    const [isSubform, setIsSubform] = useState(false);
    const [culture, setCulture] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [ownerUser, setOwnerUser] = useState("");
    const [templateCategory, setTemplateCategory] = useState("");
    const [audienceMasterId, setAudienceMasterId] = useState("");
    const [templateVersion, setTemplateVersion] = useState("");
    const [audienceName, setAudienceName] = useState("");
    const [defaultDescription, setDefaultDescription] = useState("");
    const [documentDescription, setDocumentDescription] = useState("");
    const [emailMoniker, setEmailMoniker] = useState("");
    const [documentCreation] = useState(false);
    const [
        isprimaryDocumentIdForSecondary
    ] = useState(false);
    const [templateDescription, setTemplateDescription] = useState("");
    //region

    const [currentItem, setCurrentItem] = useState<ResponseGetContextRenewals>({
        AllowCheckInOut: false,
        AllowDocumentUpdate: false,
        AllowESign: false,
        AllowNotification: false,
        AudienceName: "",
        CoOwner: false,
        Executed: false,
        FullName: "",
        Owner: false,
        TemplatePropertyBag: "",
        TemplateVersion: "",
        TemplateId: 0,
        Description: "",
        DisplayName: "",
        LastNotificationCount: 0,
        Category: "",
        DocumentRecordId: 0,
        EmailBody: "",
        EmailSubject: "",
        EmailTo: "",
        LastNotificationDate: "",
        NotifyTriggerDaysBeforeRenewal: 0,
        NotifyTriggerFrequency: "",
        RecordReference: "",
        RecordUpdated: "",
        RenewalDate: "",
        RenewalMonths: 0,
        Subject: "",
        TemplateDescription: "",
        TemplateName: "",
        UserName: "",
        Id: 0,
        OnBehalfUser: "",
        OnBehalfUserDisplayName: "",
        OnBehalfUserFullName: "",
        IsPending: false,
        CheckedOut: false,
        Closed: false,
        Suspended: false        
    });

    /**
     * Load Content Controls
     */
    const loadContentControls = () => {
        // START Content Controls
        setTemplateContentControls([])
        setIsLoadingCC(true)
        RenewalsAndAlertsService.getStatemanagement({
            User: "",
            templateId: item?.key
        }).then(data => {
            try {
                const items = JSON.parse(data.Layout as string) as ILayout[];
                if (Array.isArray(items)) {
                    setTemplateContentControls(setDefaultOptions(templateContentControls, items))
                } else {
                    showStatusMessage(t('message.error', {ns: 'common', Error: items}), StatusEnum.Error)
                }
            } catch (e:any) {
                showStatusMessage(t('message.error', {
                    ns: 'common',
                    Error: e.toString()
                }), StatusEnum.Error)
            }

        }).catch(error => {
                showStatusMessage(t('message.error', {ns: 'common', Error: error.toString()}), StatusEnum.Error)
            }
        ).finally(() => {
                setIsLoadingCC(false)
            }
        )
        // END Content Controls
    }

    /**
     * Load Template Context
     * @param clearmessages
     */
    const load = (clearmessages: boolean = false) => {

        setIsLoadedContext(false)
        setIsLoadingContext(true)

        setRteValueSubject(EditorValue.createFromString("", "html"))
        setRteValue(EditorValue.createFromString("", "html"))

        if (clearmessages) {
            setMessagePivot1("")
            setShowMessagePivot1(false)
        }

        // START GET Context
        formRenewals.reset()
        formRenewals.setValue("notifyTriggerFrequency", {
            key: "daily",
            label: "Daily"
        })
        formRenewals.setValue("hiddenRenewalsMonths", 0)
        formRenewals.setValue("renewalsMonths", calculateYears(0))
        formRenewals.setValue("hiddenNotifytriggerDays", 0)
        formRenewals.setValue("notifytriggerDays", calculateYearsMonths(0))

        setIsNew(false)

        RenewalsAndAlertsService.getContextTemplate({
            Id: Number(item?.key)
        }).then(data => {

            try {
                const items: ResponseGetContextTemplate[] = JSON.parse(data.result)
                if (Array.isArray(items)) {
                    if (items.length > 0) {
                        setId(items[0].Id)

                        formRenewals.setValue("name", items[0].Name)
                        formRenewals.setValue("description", items[0].Description)

                        formRenewals.setValue("notifyTriggerFrequency", {
                            key: items[0].NotifyTriggerFrequency.toLowerCase(),
                            label: items[0].NotifyTriggerFrequency.toLowerCase()
                        })

                        formRenewals.setValue("hiddenRenewalsMonths", items[0].RenewalMonths)
                        formRenewals.setValue("renewalsMonths", calculateYears(items[0].RenewalMonths))

                        formRenewals.setValue("hiddenNotifytriggerDays", items[0].NotifyTriggerDaysBeforeRenewal)
                        formRenewals.setValue("notifytriggerDays", calculateYearsMonths(items[0].NotifyTriggerDaysBeforeRenewal))

                        setRteValue(EditorValue.createFromString(items[0].Body, 'html'))
                        setRteValueSubject(EditorValue.createFromString(items[0].Subject, 'html'))

                    } else {
                        setIsNew(true)
                    }
                    setIsLoadedContext(true)
                } else {
                    showStatusMessage(t('message.error', {
                        ns: 'common',
                        Error: data.result !== undefined ? data.result : data
                    }), StatusEnum.Error)
                }
            } catch (e:any) {
                showStatusMessage(t('message.error', {
                    ns: 'common',
                    Error: e.toString()
                }), StatusEnum.Error)
            }

        }).catch(error => {
                showStatusMessage(t('message.error', {ns: 'common', Error: error.toString()}), StatusEnum.Error)
            }
        ).finally(() => {
                setIsLoadingContext(false)
            }
        )
        // END GET Context

    }

    /**
     * on Dismiss Preview Email
     */
    const onDismissPreviewEmail = () => {
        setShowPreviewEmail(false)
    }

    /**
     * On Set Next Renewal
     * @param recordRenewal
     */
    const onSetNextRenewal = (recordRenewal: ResponseGetContextRenewals) => {
        setCurrentItem(recordRenewal)
        setPeviewPDF(false)
        setShowRenewalRenewal(true)
    }

    /**
     * on Before Dismiss Renewal
     * @param update
     * @param preview
     */
    const onBeforeDismissRenewal = (update: boolean = false, preview: boolean = false) => {
        setShowRenewalRenewal(false)
        if (preview) onPreview(currentItem)
        if (update) loadRenewals().then()

    }

    useEffect(() => {
        if (showModal) {

            const columns = columnsRenewalsView(onEditViewRenewal, onPreview, onRecordForm, onSetNextRenewal, branding.theme)
            columns.forEach(c => c.onColumnClick = _onColumnClick);
            columns.forEach(c => c.onColumnClick = _onColumnClick);
            columns.forEach(c => c.key === "Actions" || c.key === "Preview" || c.key === "Form" ? c.onColumnClick = undefined : null);

            refRenewalsColumns.current = columns
            setRenewalsColumns(columns);

            loadContentControls()
            loadRenewals(true).then()
            load(true)
        }
    }, [])

    /**
     * Load renewals
     * @param clearmessages
     */
    const loadRenewals = async (clearmessages: boolean = false) => {

        setIsLoadingRenewals(true)
        setContextRenewals([])
        refContextRenewals.current = []
        if (clearmessages) {
            setMessagePivot2("")
            setShowMessagePivot2(false)
        }

        RenewalsAndAlertsService.getContextRenewals({
            TemplateId: Number(item?.key)
        }).then(data => {

            try {
                const items: ResponseGetContextRenewals[] = JSON.parse(data.result)
                if (Array.isArray(items)) {
                    setRenewalCount(items.length)
                    const newItems = _copyAndSort(items, "LastNotificationDate", false);
                    setContextRenewals(newItems)
                    refContextRenewals.current = newItems
                } else {
                    showStatusMessage2(t('message.error', {
                        ns: 'common',
                        Error: data.result !== undefined ? data.result : data
                    }), StatusEnum.Error)
                }
            } catch (e:any) {
                showStatusMessage2(t('message.error', {
                    ns: 'common',
                    Error: e.toString()
                }), StatusEnum.Error)
            }

        }).catch(error => {
                showStatusMessage2(t('message.error', {ns: 'common', Error: error.toString()}), StatusEnum.Error)
            }
        ).finally(() => {
                setIsLoadingRenewals(false)
            }
        )
    }

    /**
     * Create Template Setting
     */
    const create = async () => {
        await formRenewals.handleSubmit(async () => {
                const subject = removeHTMLTags(rteValueSubject.toString("html"))
                const body = removeHTMLTags(rteValue.toString("html"))
                if (subject.trim().length === 0 || body.trim().length === 0) {
                    if (subject.trim().length === 0) formRenewals.setError("EmailSubject", {message: t('text.fieldRequired', {ns: 'common'})})
                    if (body.trim().length === 0) formRenewals.setError("EmailBody", {message: t('text.fieldRequired', {ns: 'common'})})
                } else {
                    setIsSaving(true)
                    await RenewalsAndAlertsService.postAplicationRenewal({
                        TemplateId: Number(item?.key),
                        Description: formRenewals.getValues("description"),
                        Name: formRenewals.getValues("name"),
                        Body: rteValue.toString("html"),
                        To: "none",
                        Subject: subject,
                        NotifyTriggerDaysBeforeRenewal: Number(formRenewals.getValues("hiddenNotifytriggerDays")),
                        RenewalMonths: Number(formRenewals.getValues("hiddenRenewalsMonths")),
                        NotifyTriggerFrequency: formRenewals.getValues("notifyTriggerFrequency").key
                    }).then(data => {

                        if (!data.success) {
                            showStatusMessage(data.messageError ? data.messageError : "", StatusEnum.Error)
                        } else {

                            showStatusMessage(t('message.successCreated', {ns: 'common'}), StatusEnum.Success)
                            load()
                            loadRenewals(true)
                        }
                    }).catch((reason) => {
                        showStatusMessage(`${reason.data.ExceptionMessage}: ${reason.data.AxiosError}`, StatusEnum.Error)
                    }).finally(() => {
                            setIsSaving(false)
                            context.setRefreshRenewals(true)
                        }
                    )
                }

            }, () => {

            }
        )();
    }

    /**
     * Delete
     */
    const deleteTemplateDefinition = async () => {
        setIsSaving(true)
        await RenewalsAndAlertsService.postDeleteTemplateDefinition({
            Id: id,
            TemplateId: Number(item?.key)
        }).then(data => {

            if (!data.success) {
                showStatusMessage(data.messageError ? data.messageError : "", StatusEnum.Error)
            } else {
                showStatusMessage(t('message.successRemoved', {ns: 'common'}), StatusEnum.Warning)
                load()
                loadRenewals(true)
            }
        }).catch((reason) => {
            showStatusMessage(`${reason.data.ExceptionMessage}: ${reason.data.AxiosError}`, StatusEnum.Error)
        }).finally(() => {
                setIsSaving(false)
                setShowDelete(false)
                context.setRefreshRenewals(true)
            }
        )
    }

    /**
     * Update
     */
    const update = async () => {
        await formRenewals.handleSubmit(async () => {
                const subject = removeHTMLTags(rteValueSubject.toString("html"))
                const body = removeHTMLTags(rteValue.toString("html"))
                if (subject.trim().length === 0 || body.trim().length === 0) {
                    if (subject.trim().length === 0) formRenewals.setError("EmailSubject", {message: t('text.fieldRequired', {ns: 'common'})})
                    if (body.trim().length === 0) formRenewals.setError("EmailBody", {message: t('text.fieldRequired', {ns: 'common'})})
                } else {

                    setIsSaving(true)
                    await RenewalsAndAlertsService.putAplicationRenewal({
                        Id: id,
                        TemplateId: Number(item?.key),
                        Description: formRenewals.getValues("description"),
                        Name: formRenewals.getValues("name"),
                        Body: rteValue.toString("html"),
                        To: "none",
                        Subject: removeHTMLTags(rteValueSubject.toString("html")),
                        NotifyTriggerDaysBeforeRenewal: Number(formRenewals.getValues("hiddenNotifytriggerDays")),
                        RenewalMonths: Number(formRenewals.getValues("hiddenRenewalsMonths")),
                        NotifyTriggerFrequency: formRenewals.getValues("notifyTriggerFrequency").key
                    }).then(data => {

                        if (!data.success) {
                            showStatusMessage(data.messageError ? data.messageError : "", StatusEnum.Error)
                        } else {
                            showStatusMessage(t('message.successUpdated', {ns: 'common'}), StatusEnum.Success)
                            load()
                        }
                    }).catch((reason) => {
                        showStatusMessage(`${reason.data.ExceptionMessage}: ${reason.data.AxiosError}`, StatusEnum.Error)
                    }).finally(() => {
                            setIsSaving(false)
                            context.setRefreshRenewals(true)
                        }
                    )
                }
            }, () => {

            }
        )();
    }

    /**
     * Shimmer
     */
    const shimmerWithElementFirstRow = [
        {type: ShimmerElementType.line, height: 16, width: '15%'},
        {type: ShimmerElementType.gap, width: '2%'},
        {type: ShimmerElementType.line, height: 16, width: '15%'},
        {type: ShimmerElementType.gap, width: '2%'},
        {type: ShimmerElementType.line, height: 16, width: '15%'},
    ];

    /**
     * show Status Message Pivot 1
     * @param message
     * @param status
     */
    const showStatusMessage = (message: string, status: StatusEnum) => {
        setMessagePivot1(message)
        setStatusMessageEnumPivot1(status)
        setShowMessagePivot1(true)
    }

    /**
     * Status Message Pivot 2
     * @param message
     * @param status
     */
    const showStatusMessage2 = (message: string, status: StatusEnum) => {
        setMessagePivot2(message)
        setStatusMessageEnumPivot2(status)
        setShowMessagePivot2(true)
    }

    /**
     * Months SpinButton increment
     */
    const onSpinButtonIncrement = () => {
        let totalMonths = Number(formRenewals.getValues("hiddenRenewalsMonths")) + 1
        if (totalMonths > 1200) totalMonths = 1200;
        formRenewals.setValue("hiddenRenewalsMonths", totalMonths)
        return calculateYears(totalMonths)
    }

    /**
     * Months SpinButton Decrement
     */
    const onSpinButtonDecrement = () => {
        let totalMonths = Number(formRenewals.getValues("hiddenRenewalsMonths")) - 1
        if (totalMonths < 0) totalMonths = 0;
        formRenewals.setValue("hiddenRenewalsMonths", totalMonths)
        return calculateYears(totalMonths)
    }

    /**
     * Months SpinButton Validate
     */
    const onSpinButtonValidate = (value: string) => {
        let totalMonths = Number(value)
        if (!isNaN(totalMonths)) {
            if (totalMonths < 0) totalMonths = 0;
            if (totalMonths > 1200) totalMonths = 1200;
        } else {
            totalMonths = Number(formRenewals.getValues("hiddenRenewalsMonths"))
        }
        formRenewals.setValue("hiddenRenewalsMonths", totalMonths)
        return calculateYears(totalMonths)
    }

    /**
     * Months SpinButton increment
     */
    const onSpinButtonIncrementDays = () => {
        let total = Number(formRenewals.getValues("hiddenNotifytriggerDays")) + 1
        if (total > 36500) total = 36500;
        formRenewals.setValue("hiddenNotifytriggerDays", total)
        return calculateYearsMonths(total)
    }

    /**
     * Months SpinButton Decrement
     */
    const onSpinButtonDecrementDays = () => {
        let total = Number(formRenewals.getValues("hiddenNotifytriggerDays")) - 1
        if (total < 0) total = 0;
        formRenewals.setValue("hiddenNotifytriggerDays", total)
        return calculateYearsMonths(total)
    }

    /**
     * Sort items in DetailList
     * @param items
     * @param columnKey
     * @param isSortedDescending
     */
    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {

        const key = columnKey as keyof T;

        if (key === "LastNotificationDate" || key === "RenewalDate") {
            return items.slice(0).sort((a: T, b: T) => {
                const strA = a[key] as unknown as string;
                const strB = b[key] as unknown as string;
                const dateA = new Date(strA);
                const dateB = new Date(strB);

                dateA.setHours(0, 0, 0, 0)
                dateA.setHours(0, 0, 0, 0)
                dateB.setHours(0, 0, 0, 0)
                dateB.setHours(0, 0, 0, 0)

                return (isSortedDescending ? dateA < dateB : dateA > dateB) ? 1 : -1
            });
        } else {
            return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        }
    }

    /**
     * _on Column Click
     * @param ev
     * @param column
     */
    const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {

        const newColumns: IColumn[] = refRenewalsColumns.current;
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        refRenewalsColumns.current = newColumns
        const newItems = _copyAndSort(refContextRenewals.current, currColumn.fieldName!, currColumn.isSortedDescending);
        setContextRenewals([...newItems])
        refContextRenewals.current = newItems
        setRenewalsColumns([...newColumns])
    }

    /**
     * Months SpinButton Validate
     */
    const onSpinButtonValidateDays = (value: string) => {
        let total = Number(value)
        if (!isNaN(total)) {
            if (total < 0) total = 0;
            if (total > 36500) total = 36500;
        } else {
            total = Number(formRenewals.getValues("hiddenNotifytriggerDays"))
        }
        formRenewals.setValue("hiddenNotifytriggerDays", total)
        return calculateYearsMonths(total)
    }

    /**
     * onShowPreviewEmail
     */
    const onShowPreviewEmail = () => {
        setShowPreviewEmail(true)
    }

    /**
     * Submenu
     */
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'deleteTemplateDefinition',
                text: t('Common.Button.Remove', {ns: 'common'}),
                iconProps: {iconName: 'Trash'},
                onClick: () => {
                    setShowDelete(true)
                }
            },
        ],
    };

    /**
     * Pivot OnClick
     * @param item
     */
    const onLinkPivotClick = (item: PivotItem | undefined) => {
        setActivePivot(item?.props.itemKey!)
    }

    /**
     * On View or Edit Renewal
     * @param recordRenewal
     */
    const onEditViewRenewal = (recordRenewal: ResponseGetContextRenewals) => {
        setCurrentItem(recordRenewal)
        setShowRenewals(true)
    }

    /**
     * Hide preview
     */
    const onHidePreview = () => {
        setShowPreview(false)
    }

    /**
     * On Preview
     * @param recordRenewal
     */
    const onPreview = (recordRenewal: ResponseGetContextRenewals) => {
        setPreviewTitle(recordRenewal.Description)
        setPreviewId(recordRenewal.DocumentRecordId)
        setCurrentTemplateId(recordRenewal.TemplateId)
        setShowPreview(true)
    }

    const onDismissRenewal = (update: boolean = false) => {
        setShowRenewals(false)
        if (update) loadRenewals().then()
    }

    //region Form region
    /**
     * On Record form
     * @param recordRenewal
     */
    const onRecordForm = (recordRenewal: ResponseGetContextRenewals) => {
        setPreviewTitle(recordRenewal.Description)
        setCurrentTemplateId(recordRenewal.TemplateId)
        setCurrentItem(recordRenewal)
        setAudienceName(recordRenewal.AudienceName)

        loadRecord(recordRenewal.DocumentRecordId).then(async (recordData) => {
            try {
                setTemplateName(recordRenewal.DisplayName);
                setTemplateDescription(recordRenewal.Description);
                setTemplateVersion(recordRenewal.TemplateVersion.toString());
                setData(recordData.Data);
                setIsSubform(false);
                setDocumentId(recordRenewal.DocumentRecordId);
                setisOwner(true);
                setiscoOwner(true);
                setDocumentDescription(recordRenewal.Description);

                await loadPropertybag(recordRenewal.TemplatePropertyBag).then((dataPropertyBag) => {
                    setCulture(dataPropertyBag.Culture);
                    setEmailMoniker(dataPropertyBag.emailMoniker);
                    setDocumentUpdate(dataPropertyBag.DocumentUpdate)
                })

                setAudienceMasterId(await getAudienceMasterId(recordRenewal.TemplateId.toString()))
                setOwnerUser(recordRenewal.UserName!);
                setTemplateCategory(recordRenewal.Category!);
                setShowCustomForm(true)
                // toggleCustomForm(true);
            } catch {

            }
        })

    }

    /**
     * on Create Update
     */
    const onCreateUpdate = async () => {

        loadRenewals().then()
        loadTemplate(currentItem.TemplateId).then((templateData) => {
            try {
                if (typeof templateData.PropertyBag === "string") {
                    const jsonBag = JSON.parse(templateData.PropertyBag) as IPropertyBag;

                    loadRecord(currentItem.DocumentRecordId).then((recorData) => {
                        setCurrentItem({...currentItem, Description: recorData.Description})
                        setPeviewPDF(!jsonBag.SupressPreview)
                        setShowRenewalRenewal(true)
                    })

                }
            } catch {
            }
        })

    };

    /**
     * toggleCustomForm
     * @param open
     */
    const toggleCustomForm = (open: boolean) => {

        setShowCustomForm(open);

    };

    /**
     * getAudienceMasterId
     * @param templateId
     */
    const getAudienceMasterId = async (templateId: string) => {
        const templateResponse = await Helper.getDocumentOnlyTemplateId(templateId, true)
        return templateResponse?.DocumentOnlyAudienceId !== undefined ? templateResponse.DocumentOnlyAudienceId.toString() : ""
    };
//endregion

    return (
        <>
            <Modal
                isOpen={showModal}
                isBlocking={true}
                containerClassName={contentStyles(branding.theme).container}
            >
                {/*-- START HEADER --*/}
                <Stack horizontal className={contentStyles(branding.theme).header}>
                    {/*-- START MENU BUTTON --*/}
                    <StackItem className="ms-hiddenXlUp">
                        <IconButton iconProps={menuIcon} style={{marginLeft: "-4px", paddingLeft: "0px"}}
                                    title={t('text.Menu', {ns: 'workspace'})} onClick={setIsOpenMenu}/>
                        <Panel
                            isBlocking={false}
                            isOpen={isOpenMenu}
                            onDismiss={setIsOpenMenu}
                            closeButtonAriaLabel={t('Common.Button.Close', {ns: 'common'})}
                            type={PanelType.smallFixedNear}
                        >
                            <Nav
                                groups={getLinkGroup}
                                onLinkClick={onLinkClick}
                                selectedKey={option}
                                styles={navStyles}
                            />
                        </Panel>
                    </StackItem>
                    {/*-- END MENU BUTTON --*/}

                    <StackItem grow={1}
                               styles={{root: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}}>
                        <Text variant={"xLarge"} id={titleId}
                              title={t('title.modal', {ns: 'renewalsalerts', Name: item!.name})}>
                            {t('title.modal', {ns: 'renewalsalerts', Name: item!.name})}
                        </Text>
                    </StackItem>
                    <Stack horizontal styles={{root: {alignItems: "center"}}}>
                        <HelpComponentRelative
                            headline={t('title.help', {ns: 'renewalsalerts'})}
                            text={t('text.help', {ns: 'renewalsalerts', AppName: branding.appName})}
                            location=""
                        />
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                            onClick={cancel}
                            title={t('Common.Button.Close', {ns: 'common'})}
                        />
                    </Stack>
                </Stack>
                {/*-- END HEADER --*/}

                {/*-- START BODY --*/}
                <Stack horizontal tokens={{padding: 8}} style={{overflow: "hidden"}}>

                    {/*-- START SIDEMENU --*/}
                    <StackItem tokens={{padding: 16}} className="scrollVisible ms-hiddenLgDown"
                               styles={{root: {minWidth: "240px", overflow: "hidden", overflowY: "auto"}}}>
                        <Nav
                            groups={getLinkGroup}
                            onLinkClick={onLinkClick}
                            selectedKey={option}
                            styles={navStyles}
                        />
                    </StackItem>
                    {/*-- END SIDEMENU --*/}

                    {/*-- START CONTENT --*/}
                    <Stack grow={1} tokens={{padding: 8}} style={{overflow: "hidden"}}>


                        {/*-- START PIVOT --*/}
                        <Pivot aria-label={t('label.menuProperty', {ns: 'renewalsalerts'})}
                               onLinkClick={(item) => {
                                   onLinkPivotClick(item)
                               }}>

                            {/*-- START PIVOT 1 --*/}
                            <PivotItem headerText={t('label.configureRenewal', {ns: 'renewalsalerts'})}
                                       itemIcon="RecurringEvent" itemKey="pivot1">
                                <StackItem>
                                    <Text variant={'medium'}>
                                        <p>{t('text.configureRenewal', {ns: 'renewalsalerts', AppName: branding.appName})}</p>
                                    </Text>
                                    {showMessagePivot1 &&
                                        <>
                                            <StatusMessage status={statusMessageEnumPivot1} hasTimer={false}
                                                           truncated={true}
                                                           isMultiline={true}
                                                           dismiss={true}
                                                           setShowMessage={setShowMessagePivot1}>
                                                <div>{messagePivot1}</div>
                                            </StatusMessage>
                                            <Separator/>
                                        </>
                                    }
                                </StackItem>

                                <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                                       style={{
                                           overflow: "hidden",
                                           overflowY: "auto",
                                           height: "50vh",
                                           paddingRight: 24,
                                           paddingBottom: 24
                                       }}>

                                    <FormProvider {...formRenewals}>
                                        <form>


                                            {isLoadingContext && <>
                                                <Shimmer styles={{root: {marginTop: 16}}}/>
                                                <Shimmer styles={{root: {marginTop: 16}}}/>
                                                <Shimmer styles={{root: {marginTop: 16}}}
                                                         shimmerElements={shimmerWithElementFirstRow}/>
                                                <Shimmer styles={{root: {marginTop: 32}}}/>
                                                <Shimmer styles={{root: {marginTop: 16}}}/>
                                                <Shimmer styles={{root: {marginTop: 16}}}/>
                                            </>}
                                            {isLoadedContext &&
                                                <>
                                                    <RHFTextBoxControl
                                                        id={"name"}
                                                        label={t('label.ctrlName', {ns: 'common'})}

                                                        rules={{
                                                            required: t('text.fieldRequired', {ns: 'common'}),
                                                            validate: {
                                                                required:
                                                                    (value) => {
                                                                        return !!value.trim() ||  t('text.fieldRequired', {ns: 'common'})
                                                                    }
                                                            },
                                                        }}

                                                        placeholder={t('label.ctrlName', {ns: 'common'})}
                                                        title={t('label.ctrlName', {ns: 'common'})}
                                                        ariaLabel={t('label.ctrlName', {ns: 'common'})}
                                                        maxLength={250}
                                                        control={formRenewals.control}
                                                        setValue={formRenewals.getValues}
                                                        getValues={formRenewals.getValues}
                                                        trigger={formRenewals.trigger}
                                                        required
                                                    >
                                                    </RHFTextBoxControl>

                                                    <RHFTextBoxControl
                                                        id={"description"}
                                                        label={t('label.ctrlDescription', {ns: 'common'})}
                                                        rules={{
                                                            required: t('text.fieldRequired', {ns: 'common'}),
                                                            validate: {
                                                                required:
                                                                    (value) => {
                                                                        return !!value.trim() ||  t('text.fieldRequired', {ns: 'common'})
                                                                    }
                                                            },
                                                        }}
                                                        placeholder={t('label.ctrlDescription', {ns: 'common'})}
                                                        title={t('label.ctrlDescription', {ns: 'common'})}
                                                        ariaLabel={t('label.ctrlDescription', {ns: 'common'})}
                                                        maxLength={1024}
                                                        control={formRenewals.control}
                                                        setValue={formRenewals.getValues}
                                                        getValues={formRenewals.getValues}
                                                        trigger={formRenewals.trigger}
                                                        required
                                                    >
                                                    </RHFTextBoxControl>

                                                    <Stack wrap={true} horizontal>


                                                        <input
                                                            type="hidden" {...formRenewals.register("hiddenRenewalsMonths")}
                                                            value="0"/>
                                                        <StackItem grow={1}>


                                                            <SpinButton
                                                                id={"renewalsMonths"}
                                                                label={t('label.ctrlMonths', {ns: 'renewalsalerts'})}
                                                                title={t('label.ctrlMonths', {ns: 'renewalsalerts'})}
                                                                ariaLabel={t('label.ctrlMonths', {ns: 'renewalsalerts'})}
                                                                labelPosition={Position.top}
                                                                min={0}
                                                                max={1200}
                                                                onIncrement={onSpinButtonIncrement}
                                                                onDecrement={onSpinButtonDecrement}
                                                                onValidate={onSpinButtonValidate}
                                                                value={calculateYears(formRenewals.getValues("hiddenRenewalsMonths"))}

                                                            />


                                                        </StackItem>
                                                        <StackItem grow={1} styles={itemsPaddingMediaQuery}>
                                                            <input
                                                                type="hidden" {...formRenewals.register("hiddenNotifytriggerDays")}
                                                                value="0"/>


                                                            <SpinButton
                                                                id={"notifytriggerDays"}
                                                                label={t('label.ctrlDays', {ns: 'renewalsalerts'})}
                                                                title={t('label.ctrlDays', {ns: 'renewalsalerts'})}
                                                                ariaLabel={t('label.ctrlDays', {ns: 'renewalsalerts'})}
                                                                labelPosition={Position.top}
                                                                min={0}
                                                                max={36500}
                                                                onValidate={onSpinButtonValidateDays}
                                                                onIncrement={onSpinButtonIncrementDays}
                                                                onDecrement={onSpinButtonDecrementDays}
                                                                value={calculateYearsMonths(formRenewals.getValues("hiddenNotifytriggerDays"))}

                                                            />


                                                        </StackItem>
                                                        <StackItem grow={1} styles={itemsPaddingMediaQuery}>

                                                            <RHFDropDownListSingleSelectControl
                                                                id={"notifyTriggerFrequency"}
                                                                label={t('label.ctrlFrecuency', {ns: 'renewalsalerts'})}
                                                                title={t('label.ctrlFrecuency', {ns: 'renewalsalerts'})}
                                                                options={frequencyOptions()}
                                                                control={formRenewals.control}
                                                                setValue={formRenewals.setValue}
                                                                getValues={formRenewals.getValues}
                                                                trigger={formRenewals.trigger}
                                                                defaultValue={formRenewals.getValues("notifyTriggerFrequency").key!}
                                                            />

                                                        </StackItem>
                                                    </Stack>

                                                    <Separator
                                                        styles={{root: {marginTop: 16}}}>{t('title.notificationEmail', {ns: 'common'})}
                                                    </Separator>


                                                    <Stack horizontal wrap={true}
                                                           styles={{root: {backgroundColor: getTheme().semanticColors.listItemBackgroundHovered}}}>
                                                        <StackItem grow={1}>


                                                            <StackItem tokens={{padding: 16}}>
                                                                <Label>{t('text.to', {ns: 'renewalsalerts'})}</Label>
                                                                <Separator/>
                                                                <CommandBarButton onClick={() => {
                                                                    onShowPreviewEmail()
                                                                }} styles={{
                                                                    root: {
                                                                        float: "right",
                                                                        height: 32,
                                                                        marginTop: "-8px"
                                                                    }
                                                                }} iconProps={{iconName: "MailCheck"}}
                                                                                  text={t('Common.Button.PreviewEmail', {ns: 'common'})}/>

                                                                <SingleRHFRichTextControl
                                                                    id={"EmailSubject"}
                                                                    label={t('label.ctrlEmailSubject', {ns: 'common'})}
                                                                    placeholder={t('placeholder.ctrlEmailSubject', {ns: 'renewalsalerts'})}
                                                                    setRteValue={setRteValueSubject}
                                                                    rteValue={rteValueSubject}
                                                                    control={formRenewals.control}
                                                                    setValue={formRenewals.setValue}
                                                                    getValues={formRenewals.getValues}
                                                                    trigger={formRenewals.trigger}
                                                                    rules={{required: t('text.fieldRequired', {ns: 'common'})}}
                                                                    onRenderOption={onRenderOption}
                                                                    options={templateContentControls}
                                                                    toolbarConfig={toolbarConfigEmailSubject}
                                                                    isTextArea={true}
                                                                />
                                                            </StackItem>

                                                            <StackItem tokens={{padding: 16}}>

                                                                <CommandBarButton onClick={() => {
                                                                    onShowPreviewEmail()
                                                                }} styles={{
                                                                    root: {
                                                                        float: "right",
                                                                        height: 32,
                                                                        marginTop: "-8px"
                                                                    }
                                                                }} iconProps={{iconName: "MailCheck"}}
                                                                                  text={t('Common.Button.PreviewEmail', {ns: 'common'})}/>
                                                                <SingleRHFRichTextControl
                                                                    id={"EmailBody"}
                                                                    label={t('label.ctrlEmailBody', {ns: 'common'})}
                                                                    placeholder={t('placeholder.ctrlEmailBody', {ns: 'renewalsalerts'})}
                                                                    setRteValue={setRteValue}
                                                                    rteValue={rteValue}
                                                                    control={formRenewals.control}
                                                                    setValue={formRenewals.setValue}
                                                                    getValues={formRenewals.getValues}
                                                                    trigger={formRenewals.trigger}
                                                                    onRenderOption={onRenderOption}
                                                                    options={templateContentControls}
                                                                    toolbarConfig={toolbarConfigEmailBody}
                                                                />
                                                            </StackItem>
                                                            <br/>
                                                        </StackItem>
                                                    </Stack>
                                                </>
                                            }
                                        </form>
                                    </FormProvider>

                                </Stack>
                            </PivotItem>
                            {/*-- END PIVOT 1 --*/}


                            {/*-- START PIVOT 2 --*/}

                            <PivotItem headerText={t('label.viewRenewal', {ns: 'renewalsalerts'})}
                                       itemCount={renewalCount}
                                       itemIcon="Ringer" itemKey="pivot2">

                                <StackItem>

                                    <Text variant={'medium'}>
                                        <p>{t('text.configureRenewal', {ns: 'renewalsalerts', AppName: branding.appName})}</p>
                                    </Text>
                                    {showMessagePivot2 &&
                                        <>
                                            <StatusMessage status={statusMessageEnumPivot2} hasTimer={false}
                                                           truncated={true}
                                                           isMultiline={true}
                                                           dismiss={true}
                                                           setShowMessage={showMessagePivot2}>
                                                <div>{messagePivot2}</div>
                                            </StatusMessage>
                                            <Separator/>
                                        </>
                                    }
                                </StackItem>

                                <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                                       style={{
                                           overflow: "hidden",
                                           overflowY: "auto",
                                           height: "50vh",
                                           paddingRight: 24,
                                           paddingBottom: 24
                                       }}>


                                    <ShimmeredDetailsList
                                        items={contextRenewals}
                                        enableShimmer={isLoadingRenewals}
                                        compact={false}
                                        columns={renewalsColumns}
                                        selectionMode={SelectionMode.none}
                                        layoutMode={DetailsListLayoutMode.justified}

                                        isHeaderVisible={true}
                                        isSelectedOnFocus={false}
                                        setKey={"Id"}
                                    />

                                    {renewalCount === 0 &&
                                        <p style={{textAlign: "center"}}>
                                            <Text variant={'small'}>
                                                <Trans>
                                                    {t('text.emptyRenewals', {ns: 'renewalsalerts'})}
                                                </Trans>
                                            </Text>
                                        </p>
                                    }


                                </Stack>

                            </PivotItem>
                            {/*-- END PIVOT 2 --*/}


                        </Pivot>
                        {/*-- END PIVOT --*/}


                        {/*-- START FOOTER --*/}
                        <Separator></Separator>
                        <Stack horizontal style={{overflow: "hidden"}} styles={nonShrinkingStackItemStyles}>
                            <StackItem grow={1}>&nbsp;</StackItem>
                            {(isLoadingCC || isLoadingContext) &&
                                <>
                                    <StackItem>
                                        <Spinner size={SpinnerSize.medium} labelPosition="left"
                                                 label={t('text.spinner', {ns: 'common'})}/>
                                    </StackItem>
                                </>
                            }
                            {isSaving &&
                                <>
                                    <StackItem>
                                        <Spinner size={SpinnerSize.medium} labelPosition="left"
                                                 label={t('text.spinnerSaving', {ns: 'common'})}/>
                                    </StackItem>
                                </>
                            }

                            <StackItem>

                                {!isNew && activePivot === "pivot1" && <>

                                    <PrimaryButton disabled={!isLoadedContext || isSaving}
                                                   style={{marginLeft: 8}}
                                                   onClick={update}
                                                   split
                                                   menuProps={menuProps}
                                                   text={t('Common.Button.Update', {ns: 'common'})}
                                                   title={t('Common.Button.Update', {ns: 'common'})}/>
                                </>
                                }
                                {isNew && activePivot === "pivot1" &&
                                    <PrimaryButton disabled={!isLoadedContext || isSaving}
                                                   style={{marginLeft: 8}}
                                                   onClick={create}
                                                   text={t('Common.Button.Create', {ns: 'common'})}
                                                   title={t('Common.Button.Create', {ns: 'common'})}/>
                                }
                                <DefaultButton onClick={cancel} style={{marginLeft: 8}}
                                               text={t('Common.Button.Close', {ns: 'common'})}
                                               title={t('Common.Button.Close', {ns: 'common'})}/>
                            </StackItem>
                        </Stack>
                        {/*-- START FOOTER --*/}

                    </Stack>
                    {/*-- END CONTENT --*/}

                </Stack>
                {/*-- END BODY --*/}

            </Modal>
            <CustomDialog title={t('Common.Button.Remove', {ns: 'common'})} titleId={`remove${item!.key}`}
                          actionText={t('Common.Button.Remove', {ns: 'common'})}
                          confirm={deleteTemplateDefinition}
                          close={() => {
                              setShowDelete(false)
                          }}
                          isOpen={showDelete}
            >
                {t('text.confirmRemove', {ns: 'renewalsalerts'})}
            </CustomDialog>

            <RenewalsAndAlertsEdit
                showModal={showRenewals}
                onDismissModal={onDismissRenewal}
                item={{
                    Name: currentItem.Description,
                    Id: currentItem.DocumentRecordId,
                    TemplateId: currentItem.TemplateId,
                    RecordId: currentItem.DocumentRecordId
                }}
                lockAtStartup={false}
            />

            <SimplePreviewScreen
                showModal={showPreview}
                id={previewId}
                templateId={currentTemplateId}
                title={previewTitle}
                context={"record"}
                hideModal={onHidePreview}
            />

            <RenewalsEmailPreview
                name={item!.name}
                onDismissModal={onDismissPreviewEmail}
                showModal={showPreviewEmail}
                subjectRTE={rteValueSubject}
                bodyRTE={rteValue}
            />

            <RenewalsAndAlertsRenewal
                showModal={showRenewalRenewal}
                onDismissModal={onBeforeDismissRenewal}
                item={{
                    Name: currentItem.Description,
                    Id: currentItem.DocumentRecordId,
                    TemplateId: currentItem.TemplateId,
                    RecordId: currentItem.DocumentRecordId
                }}
                previewPDF={previewPDF}
            />

            {showCustomForm && (
                <CustomForm
                    allowDrafts={isSubform}
                    targetUser={Helper.getUsername()}
                    parentId={documentId}
                    draftId={draftId}
                    setDraftId={setDraftId}
                    showCustomForm={showCustomForm}
                    toggleCustomForm={toggleCustomForm}
                    siteSelected={Helper.getSiteSelected()}
                    accountId={Helper.getDefaultAccount()}
                    username={Helper.getUsername()}
                    sessionKey={Helper.getSessionKey()}
                    templateId={currentTemplateId.toString()}
                    templateName={templateName}
                    templateDescription={templateDescription}
                    data={data}
                    documentId={documentId.toString()}
                    update={onCreateUpdate}
                    culture={culture}
                    templateVersion={templateVersion}
                    isOwner={isOwner}
                    isCoOwner={iscoOwner}
                    ownerUser={ownerUser}
                    templateCategory={templateCategory}
                    audienceName={audienceName}
                    emailMoniker={emailMoniker}
                    documentCreation={documentCreation}
                    documentUpdate={documentUpdate}
                    isSubform={isSubform}
                    isprimaryDocumentIdForSecondary={
                        isprimaryDocumentIdForSecondary
                    }
                    onUpdateInterval={undefined}
                    isUpdateInterval={undefined}
                    defaultDescription={defaultDescription}
                    setDefaultDescription={setDefaultDescription}
                    documentDescription={documentDescription}
                    setDocumentDescription={setDocumentDescription}
                    audienceMasterId={audienceMasterId}
                />
            )}

        </>
    )
}

export default RenewalsAndAlerts