import { IContentLibrary } from "../interfaces/IDocumentAssembly"
import HttpRequest from "./HttpRequest"

const DocumentAssemblyService = {
    getAssembly: async (TemplateId: number) => {
        return await HttpRequest.getWithSession('DocumentAssembly', { TemplateId })
    },
    getAssemblyByRuleId: async (TemplateId: number, RuleId: number) => {
        return await HttpRequest.getWithSession('DocumentAssembly/Rules', { TemplateId, RuleId })
    },
    createAssembly: async (TemplateId: number, Assembly: string) => {
        return await HttpRequest.postWithSession('DocumentAssembly', { TemplateId, Assembly })
    },
    updateAssembly: async (TemplateId: number, RuleId: number, Assembly: string) => {
        return await HttpRequest.postWithSession('UpdateDocumentAssembly', { TemplateId, RuleId, Assembly })
    },
    createAssemblyAction: async (TemplateId: number, RuleId: number, Assembly: string) => {
        return await HttpRequest.postWithSession('DocumentAssembly/Action', { TemplateId, RuleId, Assembly })
    },
    updateAssemblyAction: async (TemplateId: number, RuleId: number, ActionId: number, Assembly: string) => {
        return await HttpRequest.postWithSession('UpdateDocumentAssembly/Action', { TemplateId, RuleId, ActionId, Assembly })
    },
    orderAssemblyRule: async (TemplateId: number, RuleId: number, operation: 'orderup' | 'orderdown') => {
        return await HttpRequest.getWithSession('OrderDocumentAssembly/Rule', { TemplateId, RuleId, operation })
    },
    orderAssemblyAction: async (TemplateId: number, RuleId: number, ActionId: number, operation: 'orderup' | 'orderdown') => {
        return await HttpRequest.getWithSession('OrderDocumentAssembly/Action', { TemplateId, RuleId, ActionId, operation })
    },
    deleteRule: async (TemplateId: number, Ruleid: number) => {
        return await HttpRequest.getWithSession('DeleteDocumentAssemblyRule', { TemplateId, Ruleid })
    },
    deleteRuleAction: async (TemplateId: number, Ruleid: number, ActionId: number) => {
        return await HttpRequest.getWithSession('DeleteDocumentAssembly/Action', { TemplateId, Ruleid, ActionId })
    },
    getContentLibrary: async (TemplateId: number, Username: string = '') => {
        if (Username === '')
            return await HttpRequest.getWithSession('ContentLibrary', { TemplateId })
        return await HttpRequest.getWithSession('ContentLibrary', { TemplateId, Username })
    },
    createContentLibrary: async (TemplateId: number, TemplateKnowledgeControlId: number, ContentLibrary: string) => {
        return await HttpRequest.postWithSession('ContentLibrary', { TemplateId, TemplateKnowledgeControlId, ContentLibrary })
    },
    updateContentLibrary: async (TemplateId: number, TemplateKnowledgeControlId: number, KnowledgeLanguageId: number, ContentLibrary: string) => {
        return await HttpRequest.postWithSession('UpdateContentLibrary', { TemplateId, TemplateKnowledgeControlId, KnowledgeLanguageId, ContentLibrary })
    },
    deleteContentLibrary: async (TemplateId: number, TemplateKnowledgeControlId: number, KnowledgeLanguageId: number) => {
        return await HttpRequest.getWithSession('DeleteContentLibrary', { TemplateId, TemplateKnowledgeControlId, KnowledgeLanguageId })
    },
    // deleteContentLibraryOption: async (TemplateId: number, Ruleid: number) => {
    //     return await HttpRequest.getWithSession('DeleteContentLibraryOption', { TemplateId, Ruleid })
    // },
    getDocumentLibrary: async (TemplateId: number) => {
        return await HttpRequest.getWithSession('DocumentLibrary', { TemplateId })
    },
    uploadDocumentLibrary: async (TemplateId: number, File: string, FileName: string) => {
        return await HttpRequest.postWithSession('DocumentLibrary', { TemplateId, File, FileName })
    },
    dowloadTemplate: async (TemplateId: number, MergeTemplateId: number) => {
        return await HttpRequest.getWithSession('DownloadDocumentLibrary', { TemplateId, MergeTemplateId })
    },
    updateDocumentLibrary: async (TemplateId: number, MergeTemplateId: number, File: string, FileName: string) => {
        return await HttpRequest.postWithSession('UpdateDocumentLibrary', { TemplateId, MergeTemplateId, File, FileName })
    },
    deleteDocumentLibrary: async (TemplateId: number, MergeTemplateId: number) => {
        return await HttpRequest.getWithSession('DeleteDocumentLibrary', { TemplateId, MergeTemplateId })
    },
    getContentLibraryOptions: async (templateId: number, Username: string): Promise<IContentLibrary[]> => {
        return DocumentAssemblyService.getContentLibrary(templateId, Username).then(({ data }: any) => {
            if (data && data.length > 0) {
                const options = data.map((item: IContentLibrary) => {
                    const filtered = item.LanguageOptions.filter((option) => option.LanguageDescription !== null)
                    return { ...item, LanguageOptions: filtered ?? [] }
                })
                return new Promise((resolve, reject) => {
                    resolve(options)
                })
            }
            return new Promise((resolve, reject) => {
                resolve([])
            })
        })

    },
    getContentControls: async (templateId: number): Promise<any[]> => {
        return DocumentAssemblyService.getContentLibrary(templateId).then(({ data }: any) => {
            if (data && data.length > 0) {
                let controls = data.map((item: IContentLibrary) => {
                    return { key: item.ContentControl?.trim(), text: item.ContentControl?.trim() }
                })
                return new Promise((resolve, reject) => {
                    resolve(controls)
                })
            }
            return new Promise((resolve, reject) => {
                resolve([])
            })
        })
    }
}
export default DocumentAssemblyService