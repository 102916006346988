import HttpRequest from "./HttpRequest";
import Helper from "../Helpers/Helper";

export interface IConfigurationResponse {
    Settings: string
    Success: boolean
}

export interface IGetReleaseNotesResponse {
    Notes: string
}

export interface IConfigurationObject {
    Billing: boolean
    Path: string
    Default: boolean
    Site: string
    TeamsId: string
    DefaultPlan: string
    CurrencySymbol: string
    NotificationEmail: string
    ShareEmail: string
    SupportEmail: string
    AdminToEmail: string
}


const ConfigurationService = {

    /**
     * Configuration
     * @constructor
     */
    Configuration: async (): Promise<IConfigurationResponse> => {
        let {data}: any = await HttpRequest.getWithSession('Configuration', {})

        return new Promise<IConfigurationResponse>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * GetReleaseNotes
     * @constructor
     */
    GetReleaseNotes: async (): Promise<IGetReleaseNotesResponse> => {
        let {data}: any = await HttpRequest.getWithSession('GetReleaseNotes', {})

        return new Promise<IGetReleaseNotesResponse>((resolve, reject) => {
            if (!data.Notes) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * GetAllReleaseNotes
     * @constructor
     */
    GetAllReleaseNotes: async (): Promise<IGetReleaseNotesResponse> => {
        let {data}: any = await HttpRequest.getWithSession('GetAllReleaseNotes', {})

        return new Promise<IGetReleaseNotesResponse>((resolve, reject) => {
            if (!data.Notes) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },
}

export default ConfigurationService