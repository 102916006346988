import { Checkbox, DefaultButton, DialogFooter, Dropdown, IDropdownOption, Label, mergeStyleSets, PrimaryButton, Stack, TextField } from '@fluentui/react'
import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IDocumentAssembly } from '../../../interfaces/IDocumentAssembly'
import DocumentAssemblyService from '../../../services/assembly'
import { IStatusMessage } from '../../../interfaces/IApp'
type RulesFormType = {
    type: 'create' | 'update'
    close: (loading: boolean, message?: IStatusMessage, callback?: () => void) => void
    templateId: number
    item?: IDocumentAssembly
}
const typeOptions: IDropdownOption[] = [
    { key: 'INLINE', text: 'INLINE' },
    { key: 'MERGE', text: 'MERGE' },
]
const defaultValues: IDocumentAssembly = {
    RuleType: 'INLINE',
    RuleName: '',
    RuleOrder: 1,
    RuleDescription: '',
    Active: false,
    AssemblyExpressions: [],
    DocumentAssemblyActions: []
}
const RulesForm = ({ close, templateId, item }: RulesFormType) => {

    const { t } = useTranslation('assembly')
    const formType: 'create' | 'update' = useMemo(() => { return item && Object.keys(item).length === 0 ? 'create' : 'update' }, [item])
    const { control, handleSubmit, reset, formState: { errors }, setValue } = useForm<IDocumentAssembly>({
        defaultValues: useMemo(() => {
            if (formType === 'update') {
                return item
            }
            return defaultValues
        }, [formType, item])
    })

    const handleDropdown = (event: React.FormEvent, option?: IDropdownOption | undefined) => {
        if (option) {
            setValue('RuleType', option.key.toString())
        }
    }

    const saveForm = (formData: IDocumentAssembly) => {
        formData.RuleName = formData.RuleName?.trim()
        formData.RuleDescription = formData.RuleDescription?.trim()
        if (formType === 'create') {
            DocumentAssemblyService.createAssembly(templateId, JSON.stringify(formData)).then(({ data }: any) => {
                if (data.Success === false) {
                    close(true, { Message: <>Error adding <strong>{formData.RuleName}</strong>: {data.ExceptionMessage}</>, Type: 'error' })
                    return
                }
                reset(defaultValues)
                    close(true, { Message: <>Rule <strong>{formData.RuleName}</strong> added successfully</>, Type: 'success' })
            })
        } else if (item?.RuleId) {
            let assembly: IDocumentAssembly = { ...formData, AssemblyExpressions: item.AssemblyExpressions }
            delete assembly.DocumentAssemblyActions
            DocumentAssemblyService.updateAssembly(templateId, item.RuleId, JSON.stringify(assembly)).then(({ data }: any) => {
                if (data.Success === false) {
                    close(true, { Message: <>Error updating Rule <strong>{formData.RuleName}</strong>: {data.ExceptionMessage}</>, Type: 'error' })
                    return
                }
                reset(defaultValues)
                close(true, { Message: <>Rule <strong>{formData.RuleName}</strong> updated successfully</>, Type: 'success' })
            })
        }
    }
    const modalClose = () => {
        close(false)
    }
    return (
        <form onSubmit={handleSubmit(saveForm)}>
            <div>

                <div className="scrollVisible" style={{
                    overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh"
                }}>
                    <Stack styles={{ root: { paddingRight: "10px" } }}>
                        <div className={contentStyles.section}>
                            <Label required>{t('assembly.name')}</Label>
                            <Controller name="RuleName" control={control} rules={{ required: true, pattern: /\S/ }}
                                render={({ field }) => <TextField disabled={formType === 'update'} readOnly={formType === 'update'} onChange={field.onChange} value={field.value} />}
                            />
                            <span className={contentStyles.error}>{errors.RuleName && <p>This field is required</p>}</span>
                        </div>
                        <div className={contentStyles.section}>
                            <Label required>{t('assembly.supportedTypes')}</Label>
                            <Controller name="RuleType" control={control} rules={{ required: true }}
                                render={({ field }) => <Dropdown disabled={formType === 'update'} defaultSelectedKey={field.value}
                                    {...field} onChange={handleDropdown}
                                    options={typeOptions}
                                />}
                            />
                            <span className={contentStyles.error}>{errors.RuleType && <p>This field is required</p>}</span>
                        </div>
                        <div className={contentStyles.section}>
                            <Label required>{t('assembly.description')}</Label>
                            <Controller name="RuleDescription" control={control} rules={{ required: true, pattern: /\S/ }}
                                render={({ field }) => <TextField onChange={field.onChange} value={field.value} />}
                            />
                            <span className={contentStyles.error}>{errors.RuleDescription && <p>This field is required</p>}</span>
                        </div>
                        {
                            formType === 'update' ? <div className={contentStyles.section}>
                                <Controller name="Active" control={control}
                                    render={({ field }) => <Checkbox {...field} checked={Boolean(field.value)} title={t('assembly.enabled')} value={'enabled'} label={t('assembly.enabled')} />}
                                />
                            </div> : null
                        }

                    </Stack>
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <DialogFooter>
                        <PrimaryButton id="btnCreateUpdate" type="submit" text={t(formType === 'create' ? 'formSettings.btnCreate' : 'formSettings.btnUpdate')} disabled={false} title={t(formType === 'create' ? 'formSettings.btnCreate' : 'formSettings.btnUpdate')} />
                        <DefaultButton id="btnCancel" onClick={modalClose} text={t('formSettings.btnCancel')} title={t('formSettings.btnCancel')} />
                    </DialogFooter>
                </div>
            </div>
        </form>
    )
}
const contentStyles = mergeStyleSets({
    section: {
        padding: '0.5rem 0'
    },
    error: {
        color: '#a4262c'
    }
})
// const typeOptionsDetail: IDropdownOption[] = [
//     { key: 'INLINE_insert', text: 'Insert Content' },
//     { key: 'INLINE_append', text: 'Append Content' },
//     { key: 'INLINE_replace', text: 'Replace Content' },
//     { key: 'INLINE_delete', text: 'Delete Content' },
//     { key: 'MERGE_prepend', text: 'Prepend Document' },
//     { key: 'MERGE_append', text: 'Append Document' },
// ]
export default RulesForm
