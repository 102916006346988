//import * as locales from "./locales"
import * as L from './locales'
export type DateLocalizationType = {
    days: string[];
    daysShort: string[];
    daysMin: string[];
    months: string[];
    monthsShort: string[];
    today: string;
    weekStart?: number;
    clear?: string;
    monthsTitle?: string;
}
const localization: { [key: string]: DateLocalizationType } = {
    'af': L.Dates_af,
    'ar-TN': L.Dates_arTN,
    'ar': L.Dates_ar,
    'az': L.Dates_az,
    'bg': L.Dates_bg,
    'bm': L.Dates_bm,
    'bn': L.Dates_bn,
    'br': L.Dates_br,
    'bs': L.Dates_bs,
    'ca': L.Dates_ca,
    'cs': L.Dates_cs,
    'cy': L.Dates_cy,
    'da': L.Dates_da,
    'de': L.Dates_de,
    'el': L.Dates_el,
    'en-AU': L.Dates_enAU,
    'en-GB': L.Dates_enGB,
    'en-IE': L.Dates_enIE,
    'en-NZ': L.Dates_enNZ,
    'en-ZA': L.Dates_enZA,
    'en': L.Dates_enCA,
    'eo': L.Dates_eo,
    'es': L.Dates_es,
    'et': L.Dates_et,
    'eu': L.Dates_eu,
    'fa': L.Dates_fa,
    'fi': L.Dates_fi,
    'fo': L.Dates_fo,
    'fr-CH': L.Dates_frCH,
    'fr': L.Dates_fr,
    'gl': L.Dates_gl,
    'he': L.Dates_he,
    'hi': L.Dates_hi,
    'hr': L.Dates_hr,
    'hu': L.Dates_hu,
    'hy': L.Dates_hy,
    'id': L.Dates_id,
    'is': L.Dates_is,
    'it-CH': L.Dates_itCH,
    'it': L.Dates_it,
    'ja': L.Dates_ja,
    'ka': L.Dates_ka,
    'kh': L.Dates_kh,
    'kk': L.Dates_kk,
    'km': L.Dates_km,
    'ko': L.Dates_ko,
    'kr': L.Dates_kr,
    'lt': L.Dates_lt,
    'lv': L.Dates_lv,
    'me': L.Dates_me,
    'mk': L.Dates_mk,
    'mn': L.Dates_mn,
    'ms': L.Dates_ms,
    'nl-BE': L.Dates_nlBE,
    'nl': L.Dates_nl,
    'no': L.Dates_no,
    'oc': L.Dates_oc,
    'pl': L.Dates_pl,
    'pt-BR': L.Dates_ptBR,
    'pt': L.Dates_pt,
    'ro': L.Dates_ro,
    'rs-LATIN': L.Dates_rsLATIN,
    'rs': L.Dates_rs,
    'ru': L.Dates_ru,
    'si': L.Dates_si,
    'sk': L.Dates_sk,
    'sl': L.Dates_sl,
    'sq': L.Dates_sq,
    'sr-latin': L.Dates_srLATIN,
    'sr': L.Dates_sr,
    'sv': L.Dates_sv,
    'sw': L.Dates_sw,
    'ta': L.Dates_ta,
    'tg': L.Dates_tg,
    'th': L.Dates_th,
    'tk': L.Dates_tk,
    'tr': L.Dates_tr,
    'uk': L.Dates_uk,
    'uz': L.Dates_uzCYRL,
    'uz-CYRL': L.Dates_uzCYRL,
    'uz-LATN': L.Dates_uzLATN,
    'vi': L.Dates_vi,
    'zh': L.Dates_zhCN,
    'zh-CN': L.Dates_zhCN,
    'zh-TW': L.Dates_zhTW,
}
export default localization