import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { IMaskedTextFieldProps, MaskedTextField } from "@fluentui/react";
import { ReactHookFormControlProps } from "../Models/ReactHookFormControlProps";
import { textFieldStyle } from "./Styles";

export interface IRHFMaskControlProps {
    getErrorMessage: (value: string) => any
}
export const RHFMaskControl: FC<ReactHookFormControlProps & IMaskedTextFieldProps & IRHFMaskControlProps> = (
    props
) => {
    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error }
            }) => (
                <MaskedTextField
                    id={props.id}
                    name={fieldName}
                    label={props.label}
                    onRenderLabel={props.onRenderLabel}
                    title={props.title}
                    errorMessage={error && error.message}
                    required={props.required}
                    disabled={props.readOnly || false}
                    value={value}
                    onGetErrorMessage={props.getErrorMessage}
                    onChange={(event, newValue) => {
                        props.setValue(props.id, newValue)
                        props.trigger(props.id)
                    }}
                    onBlur={() => {
                        if(props.OnBlurOrOnChange !== undefined) props.OnBlurOrOnChange()
                        else onBlur()
                    }}
                    resizable={false}
                    defaultValue={undefined}
                    mask={props.mask}
                    maskFormat={{ 'L': /[a-zA-Z]/, '9': /[0-9]/, 's': /[!@#$%^&*()]/ }}
                    styles={textFieldStyle}
                    borderless
                    tabIndex={props.tabIndex}
                />
            )}
        />
    );
};
