import * as React from "react";
import {Dispatch, FC, SetStateAction, useState} from "react";

import {Controller} from "react-hook-form";
import {ReactHookFormControlProps} from "../CustomTemplates/CustomForm/Models/ReactHookFormControlProps";
import {
    assign,
    IBasePickerSuggestionsProps,
    IPersonaProps,
    IPickerItemProps,
    Label,
    NormalPeoplePicker,
    PeoplePickerItem,
    Text,
    ValidationState
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import {theme} from "../AdminLogs/AdminLogs.data";

export interface IChildProps {
    peopleList: IPersonaProps[]
    selectedPeopleList: IPersonaProps[]
    setSelectedPeopleList: Dispatch<SetStateAction<IPersonaProps[]>>;
    pickerSuggestionsProps: IBasePickerSuggestionsProps
    label?: string
}

export const RHFPeoplePickerControl: FC<ReactHookFormControlProps & IChildProps> = (props) => {

    const {t} = useTranslation(['formtemplatelink', 'common'])

    const [peopleList, setPeopleList] = useState<IPersonaProps[]>(props.peopleList)
    const [mostRecentlyUsed, setMostRecentlyUsed] = useState<IPersonaProps[]>(props.peopleList)
    let text = ""

    /**
     * Render SecondaryText, tertiary ...
     * @param props
     */
    const renderItemWithSecondaryText = (props: IPickerItemProps<IPersonaProps>) => {
        const newProps = {
            ...props,
            item: {
                ...props.item,
                ValidationState: ValidationState.valid,
                showSecondaryText: true,
            },
        };
        return <PeoplePickerItem {...newProps} />;
    };

    /**
     * suggestionProps
     */
        //todo add as default, but required parameter 
    const suggestionProps: IBasePickerSuggestionsProps = {
            suggestionsHeaderText: t('text.suggestionsHeaderText', {ns: 'recordsendmail'}),
            noResultsFoundText: t('text.noResultsFoundText', {ns: 'recordsendmail'}),
            loadingText: t('text.spinner', {ns: 'common'}),
            showRemoveButtons: false,
            suggestionsAvailableAlertText: t('text.suggestionsAvailableAlertText', {ns: 'recordsendmail'}),
            mostRecentlyUsedHeaderText: t('text.mostRecentlyUsedHeaderText', {ns: 'recordsendmail'}),
            suggestionsContainerAriaLabel: t('text.suggestionsContainerAriaLabel', {ns: 'recordsendmail'}),
        };

    const limitedSearchSuggestionProps: IBasePickerSuggestionsProps = assign(suggestionProps, {...props.pickerSuggestionsProps});

    const onFilterChanged = (
        filterText: string,
        currentPersonas?: IPersonaProps[]
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);

            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas!);
            return filteredPersonas;
        } else {
            return [];
        }
    };

    const filterPersonasByText = (filterText: string): IPersonaProps[] => {
        return peopleList.filter(item => doesTextStartWith(item.text as string, item.secondaryText as string, filterText));
    };

    function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
        return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
    }

    function doesTextStartWith(text: string, secondaryText: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) > -1 || secondaryText.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
    }

    function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter(item => item.secondaryText === persona.secondaryText).length > 0;
    }

    const returnMostRecentlyUsedWithLimit = (
        selectedItems?: IPersonaProps[],
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        return removeDuplicates(mostRecentlyUsed, selectedItems!);
    };

    function getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }

    const onRemoveSuggestion = (item: IPersonaProps): void => {
        const indexPeopleList: number = peopleList.indexOf(item);
        const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople: IPersonaProps[] = peopleList
                .slice(0, indexPeopleList)
                .concat(peopleList.slice(indexPeopleList + 1));
            setPeopleList(newPeople);
        }

        if (indexMostRecentlyUsed >= 0) {
            const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
                .slice(0, indexMostRecentlyUsed)
                .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
            setMostRecentlyUsed(newSuggestedPeople);
        }
    };

    function validateInput(input: string): ValidationState {
        const mailformat = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/);

        if (mailformat.test(input.toLowerCase().trim())) {
            return ValidationState.valid;
        } else {
            return ValidationState.invalid;
        }
    }

    function onItemSelected(item?: IPersonaProps): Promise<IPersonaProps> {
        let processedItem: IPersonaProps = {}
        if (item?.primaryText) {
            // noinspection JSDeprecatedSymbols
            processedItem.text = `${item!.primaryText}`;
            // noinspection JSDeprecatedSymbols
            processedItem.secondaryText = `${item!.primaryText}`
        } else {
            processedItem.text = item?.text
            processedItem.secondaryText = item?.secondaryText
        }
        return new Promise<IPersonaProps>((resolve) => setTimeout(() => resolve(processedItem), 0));
    }

    function OnInputBlur(input: string, onChange: any) {
        if (input.trim().length > 0) {

            const validate: ValidationState = validateInput(input.trim())
            if (validate === ValidationState.valid) {

                const newArray: IPersonaProps[] = props.selectedPeopleList!.concat({
                    text: input.trim(),
                    secondaryText: input.trim()
                })
                props.setSelectedPeopleList(newArray)
                onChange(newArray)
            }
        }
    }

    function OnInputChange(input: string, onChange: any): string {

        if (input.trim().length > 0) {

            const arrayItems = input.split(";")
            if (arrayItems.length > 1) {
                let peopleFromInput: IPersonaProps[] = []
                arrayItems.forEach((item) => {
                    const validate: ValidationState = validateInput(item.trim())
                    if (item.trim().length > 0 && validate === ValidationState.valid) {
                        peopleFromInput.push({
                            text: item.trim(),
                            secondaryText: item.trim()
                        })
                    }
                })

                const newArray: IPersonaProps[] = props.selectedPeopleList!.concat(peopleFromInput)
                props.setSelectedPeopleList(newArray)
                onChange(newArray)
            }
        }

        return input

    }

    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            shouldUnregister={props.shouldUnregister !== undefined && props.shouldUnregister}
            render={({
                         field: {onChange, onBlur},
                         fieldState: {invalid, error}
                     }) => (

                <>
                    {props.label !== undefined &&
                        <Label required={true}>{props.label}</Label>
                    }
                    
                    <NormalPeoplePicker
                        disabled={props.readOnly}

                        onResolveSuggestions={onFilterChanged}
                        onEmptyResolveSuggestions={returnMostRecentlyUsedWithLimit}
                        getTextFromItem={getTextFromItem}
                        onGetMoreResults={onFilterChanged}
                        pickerSuggestionsProps={limitedSearchSuggestionProps}
                        onRemoveSuggestion={onRemoveSuggestion}
                        onRenderItem={renderItemWithSecondaryText}
                        onValidateInput={validateInput}
                        onItemSelected={onItemSelected}

                        selectedItems={props.selectedPeopleList}
                        onInputChange={(input: string) => {
                            text = input
                            return OnInputChange(input, onChange)

                        }}

                        key={'normal'}
                        onChange={(items) => {
                            props.setSelectedPeopleList(items!)
                            onChange(items)
                        }}
                        onBlur={() => {
                            OnInputBlur(text, onChange)
                            onBlur()
                        }}

                        inputProps={{
                            'aria-label': t('ariaLabel.peoplePicker', {ns: 'recordsendmail'}),
                            id: 'sendEmailTo',
                            placeholder: t('placeholder.peoplePicker', {ns: 'recordsendmail'})
                        }}

                        styles={{
                            text: {
                                selectors: {
                                    ':hover': {
                                        borderColor: invalid ? theme.semanticColors.errorText : theme.semanticColors.inputBorder
                                    },
                                    ':after': {
                                        borderColor: invalid ? theme.semanticColors.errorText : theme.semanticColors.inputBorder
                                    },
                                },
                                borderColor: invalid ? theme.semanticColors.errorText : theme.semanticColors.inputBorder
                            }
                        }}

                    />


                    {invalid &&
                        <>
                            <Text variant={"small"}
                                  styles={{root: {color: theme.semanticColors.errorText}}}>{error?.message} </Text>
                        </>
                    }

                </>
            )}
        />
    );
};