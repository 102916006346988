import HttpRequest from "./HttpRequest";
import Helper, {IHttpClientResponseBag} from "../Helpers/Helper";

export interface IGetAllManageClientPlansRequest {
    ownerName: string
}

export interface ICreateUpdateManageClientPlansRequest {
    Id: number,
    BillingPlanId: number,
    OwnerName: string,
    OrganizationName: string,
    OrganizationAddress: string,
    OrganizationCity: string,
    OrganizationState: string,
    OrganizationPostalCode: string,
    OrganizationCountry: string,
    ContactFirstName: string,
    ContactLastName: string,
    ContactEmailAddress: string,
    ContactPhoneNumber: string,
    SubscriptionId: string,
    Updated?: string
    Created?: string
    Active: boolean,
    Comment: string,
    Operation: string
}

export interface ICreateUpdateManageClientPlansResponse {
    ResponseType: number
    ExceptionMessage: string
    Id: string
    Result: string
    CaseNumber: string
    Success: boolean
}

export interface IDeleteRequest {
    Id: number,
    Operation: string
}

export interface IGetAllManageClientPlansResponse {
    Active: boolean
    BillingPlanId: number
    Comment: string
    ContactEmailAddress: string
    ContactFirstName: string
    ContactLastName: string
    ContactPhoneNumber: string
    Created: string
    Id: number
    NextDueDate: string
    OrganizationAddress: string
    OrganizationCity: string
    OrganizationCountry: string
    OrganizationName: string
    OrganizationPostalCode: string
    OrganizationState: string
    OwnerName: string
    PlanName: string
    SubscriptionId: string
    Updated: string
}


const ManageClientPlansService = {

    /**
     * ManageClientPlans/GetAllManageClientPlans
     * @param request
     * @constructor
     */
    GetAllManageClientPlans: async (request: IGetAllManageClientPlansRequest): Promise<IGetAllManageClientPlansResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('ManageClientPlans/GetAllManageClientPlans', request)

        return new Promise<IGetAllManageClientPlansResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * ManageClientPlans/CreateUpdateManageClientPlans
     * @param request
     * @constructor
     */
    CreateUpdateManageClientPlans: async (request: ICreateUpdateManageClientPlansRequest): Promise<ICreateUpdateManageClientPlansResponse> => {
        let {data}: any = await HttpRequest.postWithSession('ManageClientPlans/CreateUpdateManageClientPlans', request)

        return new Promise<ICreateUpdateManageClientPlansResponse>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })

    },

    /**
     * ManageClientPlans/Delete
     * @param request
     * @constructor
     */
    Delete: async (request: IDeleteRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.getWithSession('ManageClientPlans/Delete', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })

    },

    /**
     * ManageClientPlans/CreateUpdateManageClientPlansOwnerName
     * @param request
     * @constructor
     */
    CreateUpdateManageClientPlansOwnerName: async (request: ICreateUpdateManageClientPlansRequest): Promise<ICreateUpdateManageClientPlansResponse> => {
        let {data}: any = await HttpRequest.postWithSession('ManageClientPlans/CreateUpdateManageClientPlansOwnerName', request)

        return new Promise<ICreateUpdateManageClientPlansResponse>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })

    },


}

export default ManageClientPlansService