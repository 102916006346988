import React, { useState, useEffect } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import Helper, { IDocumentOnlyAudienceDetail, IDocumentOnlyAudienceRequest, IDocumentOnlyAudienceDetailRequest } from '../../Helpers/Helper';
import {
    DetailsListLayoutMode,
    IContextualMenuItem,
    IContextualMenuProps,
    SelectionMode,
    MessageBar,
    MessageBarType,
    Checkbox,
    Label,
    ScrollablePane,
    IScrollablePaneStyles,
    IMessageBarStyles,
    IDropdownOption,
    StackItem
} from '@fluentui/react';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles, ActionButton } from '@fluentui/react/lib/Button';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField } from '@fluentui/react/lib/TextField';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import UserLists from './UserList';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import NewAudience from './NewAudience';
import ReactDOM from 'react-dom';
import ReassignRecords from './ReassignRecords';
import ChangeOwner from './ChangeOwner';
import { formatFromUTC, setGlobalDateLocale } from '../../Helpers/DateUtils';
import {useBranding} from "../../hooks/useBranding";
import {useTranslation} from "react-i18next";
import AudiencesAddUser from "../Audiences";
import HttpRequest from "../../services/HttpRequest";

const stackTokens = { childrenGap: 10 };
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
function Audiences(props: any) {
    const { branding } = useBranding()
    const {t} = useTranslation(['common'])
    const [audienceList, setAudienceList] = useState<IDocumentOnlyAudienceDetail[]>([]);
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [requiredName, setrequiredName] = useState(false)
    const [requiredDesc, setrequiredDesc] = useState(false)
    const [audienceName, setAudienceName] = useState("")
    const [audienceDesc, setAudienceDesc] = useState("")
    const [message, setMessage] = useState("");
    const [showMessageError, setshowMessageError] = useState(false)
    const [modalId, setModalId] = useState(-1)
    const [selectedAudience, setSelectedAudience] = useState<IDocumentOnlyAudienceDetail>();
    const [modalTitle, setModalTitle] = useState("")
    const [audienceUsers, setAudienceUsers] = useState<IDocumentOnlyAudienceDetail[]>([]);
    const [selectedAudienceUser, setSelectedAudienceUser] = useState<IDocumentOnlyAudienceDetail>();
    const [isUserModalVisible, setIsUserModalVisible] = useState(false)
    const [userEmail, setUserEmail] = useState("")
    const [roleDocumentcreator, setRoleDocumentcreator] = useState(false)
    const [roleCoOwner, setRoleCoOwner] = useState(false)
    const [validForm, setValidForm] = useState(false)
    const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(false)
    const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false)
    const [isSavingEditUSer, setIsSavingEditUSer] = useState(false)
    const [showUploadUserList, setShowUploadUserList] = useState(false)
    const [isEditName, setIseditName] = useState(false)
    const [applying, setApplying] = useState(false);
    const [showModalUsers, setShowModalUsers] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [Usermessage, setUserMessage] = useState("");
    const [showUserMessageError, setUsershowMessageError] = useState(false)
    const [showUserMessageSuccess, setShowUserMessageSuccess] = useState(false)
    const [selectionAudienceUser] = useState(new Selection({
        onSelectionChanged: () => {
            const selectionCount = selectionAudienceUser.getSelectedCount();
            switch (selectionCount) {
                case 1: {
                    setSelectedAudienceUser(selectionAudienceUser.getSelection()[0] as IDocumentOnlyAudienceDetail)
                }
            }
        }
    }))
    const [audienceMasterIdUser, setaudienceMasterIdUser] = useState(0)
    const [enabledUploaduserList, setenabledUploaduserList] = useState(false)
    const [showNewAudience, setShowNewAudience] = useState(false)
    const [showMessageUserAudienceError, setShowMessageUserAudienceError] = useState(false)
    const [messageUser, setmessageUser] = useState("");
    const [DocumentOnlyAudienceMasterId, setDocumentOnlyAudienceMasterId] = useState(0)

    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [isReassignRecordsVisible, setIsReassignRecordsVisible] = useState(false)
    const [isChangeAllAudiences, setIsChangeAllAudiences] = useState(false)
    const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false)

    const [audienceOwnerUsername, setAudienceOwnerUsername] = useState("")
    const [audienceLabel, setAudienceLabel] = useState("")
    const getAudienceDefaultOwners = (item: IDocumentOnlyAudienceDetail): string => {
        var result = ""
        if (item.Name.trim().toLowerCase() === "default") {

            if (item.Owner) {

                result = item.Name + " (" + item.UserName + "(" + item.FullName + ")" + ")"
            }
            else {
                result = item.Name + (item.OwnerFullName !== null ? " (" + item.OwnerUser + "(" + item.OwnerFullName + ")" + ")" : "")
            }
        }
        else {
            result = item.Name + " (" + item.Description + ")"
        }
        return result;
    }


    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
                class: 'ms-Icon ms-Icon--Mail'
            }
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        bodyDelete: {
            width: '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        body: {
            width: '25vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyEdit: {
            width: '50vw',
            height: '60vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyVWOverride: {
            width: '90%',

            flex: '4 4 auto',
            padding: '0 0px 0px 18px',
        },
        bodyEditVWOverride: {
            width: '47vw',

            flex: '4 4 auto',
            padding: '0 18px 0px 18px',
        },
        User: {
            width: '35vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        pane: {
            maxWidth: "100%",
            borderr: '0 10px  0 0 solid ' + theme.palette.neutralLight,
        },
        contentAudienceUser: {
            height: '40vh',
            position: 'relative',
            backgroundColor: 'white',
        },
        contentAudienceList: {
            height: '40vh',
            position: 'relative',
            backgroundColor: 'white',
        },
    });
    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };
    
    let tittle = ""
    let audienceMasterId = 0
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Audience',
            fieldName: 'Name',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return <span title={getAudienceDefaultOwners(item)}>{getAudienceDefaultOwners(item)}</span>
            },
        },
        {
            key: 'column3',
            name: 'Owner',
            fieldName: 'Owner',
            minWidth: 40,
            maxWidth: 40,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return item.Owner ? <Checkbox disabled={true} checked title="Member is audience owner." /> : <Checkbox disabled={true} checked={false} title="Member is not the audience owner and cannot be granted this right." />;
            },
        },
        {
            key: 'column4',
            name: 'Co-owner',
            fieldName: 'CoOwner',
            minWidth: 40,
            maxWidth: 40,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return item.CoOwner ? <Checkbox disabled={true} checked title="Member has application management rights granted." /> : <Checkbox disabled={true} checked={false} title="Member does not have application management right granted." />;
            },
        },
        {
            key: 'column5',
            name: 'Create Document',
            fieldName: 'CreateDocument',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return item.CreateDocument ? <Checkbox disabled={true} checked title="Member has create document right granted." /> : <Checkbox disabled={true} checked={false} title="Member does not have create document right granted." />;
            },
        },
        {
            key: 'column6',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                if (!item.CoOwner && !item.Owner)
                    return null;
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    title={"Click to select from available actions."}
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={item.Name.toLocaleLowerCase() === "default" ? menuFilterProps : menuProps}
                /></div>
            },
            isPadded: true
        },
    ]


    /**
     * on Dismiss Add user modal
     * @param update
     */
    const onDismissAddUser = async (update: boolean = false) => {
        if (update) {
            loadUsers(selectedAudience!.DocumentOnlyAudienceMasterId.toString())
        }
    }
    
    /**
     * Let the parent to manage the IsUserModalVisible state
     * @param value
     */
    const onHideAddUserModal = (value:boolean) => {
        setIsUserModalVisible(value)
    }
    
    const menuItems: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete audience',
            onClick: () => {
                deleteAudience();
            },
        },
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit audience.',
            onClick: () => {
                setModalTitle("Edit")
                tittle = "Edit"
                audienceMasterId = selectedAudience?.DocumentOnlyAudienceMasterId!
                setaudienceMasterIdUser(selectedAudience?.DocumentOnlyAudienceMasterId!)
                setShowNewAudience(true)
                //editAudience();
            },
        },
        {
            key: 'uploaduserlist',
            text: 'Upload user list',
            title: 'Create user list by uploading from CSV text file.',
            onClick: () => {
                setAudienceOwnerUsername(selectedAudience?.OwnerUser ? selectedAudience?.OwnerUser : "")
                ToggleUploadUserList();
            },
        },
        {
            key: 'uploaduserlist',
            text: 'Users',
            title: 'Create or Update user list .',
            onClick: () => {
                audienceMasterId = selectedAudience?.DocumentOnlyAudienceMasterId!
                setaudienceMasterIdUser(selectedAudience?.DocumentOnlyAudienceMasterId!)
                setAudienceOwnerUsername(selectedAudience?.OwnerUser ? selectedAudience?.OwnerUser : "")
                setAudienceLabel(selectedAudience?.Name ? selectedAudience?.Name : "")
                showUsers();
            },
        },
        {
            key: 'changeOwner',
            text: 'Change Owner',
            title: 'Change Audience Owner.',
            onClick: () => {
                setaudienceMasterIdUser(selectedAudience?.DocumentOnlyAudienceMasterId!)
                changeAllAudiences()
            },
        },

    ];

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    const menuFilterProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems.filter(f => f.key != "delete")
    };

    const
        emptyL: IDocumentOnlyAudienceDetail = {
            Id: 0,
            Name: "",
            Description: "",
            DocumentOnlyAudienceMasterId: 0,
            CoOwner: false,
            Owner: false,
            CreateDocument: false,
            UserName: "",
            key: "",
            text: "",
            FullName: "",
            DateCreated: "",
            DateMasterCreated: "",

        };

    const columnsUser: IColumn[] = [

        {
            key: 'column2',
            name: 'Username',
            fieldName: 'UserName',
            minWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return <span title={item.FullName.toString() + " (" + item.UserName.toString() + ")"}>{item.FullName.trim().length == 0 ? item.UserName : item.FullName}</span>;
            }
        },
        {
            key: 'column7',
            name: 'Date',
            fieldName: 'DateCreated',
            minWidth: 100,
            maxWidth: 145,
            isResizable: true,
            data: 'number',
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return <span title={getRecordDateTitle(item)}>{item.DateCreated !== null ? formatFromUTC(item.DateCreated) : null}</span>;
            },
            isPadded: true,

        },
        {
            key: 'column3',
            name: 'Owner',
            fieldName: 'Owner',
            minWidth: 30,
            maxWidth: 40,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return item.Owner ? <Checkbox checked disabled={true} title="Member is audience owner." /> : <Checkbox disabled={true} checked={false} title="Member is not the audience owner and cannot be granted this right." />;
            },
        },
        {
            key: 'column4',
            name: 'Co-owner',
            fieldName: 'CoOwner',
            minWidth: 30,
            maxWidth: 40,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return item.CoOwner ? <Checkbox disabled={true} checked title="Member has application management rights granted." /> : <Checkbox disabled={true} checked={false} title="Member does not have application management right granted." />;
            },
        },
        {
            key: 'column5',
            name: 'Create Document',
            fieldName: 'CreateDocument',
            minWidth: 30,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return item.CreateDocument ? <Checkbox disabled={true} checked title="Member has create document right granted." /> : <Checkbox disabled={true} checked={false} title="Member does not have create document right granted." />;
            },
        },
        {
            key: 'column6',
            name: 'Action',
            fieldName: 'Actions',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                //var menu= item.Owner ?menuPropsUserFilter.items:menuPropsUser
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={(item.Name.toLocaleLowerCase() === "default" && item.Owner == true) ?
                        menuPropsUserFilter : menuPropsUser}
                /></div>
            },
            isPadded: true
        },
    ]
    const menuItemsUser: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: 'Delete',
            title: "Delete audience member.",
            onClick: () => {
                //console.log(selectedAudienceUser)
                setDocumentOnlyAudienceMasterId(selectedAudienceUser?.DocumentOnlyAudienceMasterId!)
                setIsDeleteUserModalVisible(true);
            },
        },
        {
            key: 'edit',
            text: 'Edit',
            title: "Edit audience member.",
            onClick: () => {
                setIsSavingEditUSer(false)
                setRoleDocumentcreator(false)
                setRoleCoOwner(false)
                setIsEditUserModalVisible(true);
                setDocumentOnlyAudienceMasterId(selectedAudienceUser?.DocumentOnlyAudienceMasterId!)
                if (selectedAudienceUser?.CoOwner)
                    setRoleCoOwner(true)
                if (selectedAudienceUser?.CreateDocument)
                    setRoleDocumentcreator(true)

                setValidForm(true)
                if(selectedAudienceUser?.CoOwner || selectedAudienceUser?.CreateDocument) setValidForm(false)
                
            },
        },
        {
            key: 'reassignRecords',
            text: 'Reassign Records',
            title: "Reassign Records.",
            onClick: () => {
                setIsReassignRecordsVisible(true);
            },
        },
    ];
    const menuPropsUser: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItemsUser,
    };
    const menuPropsUserFilter: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItemsUser.filter(f => f.key != "delete"),
    };

    const audienceMenuProps: IContextualMenuProps = {
        items: [
            {
                key: 'addAudience',
                text: 'Add Audience',
                iconProps: { iconName: 'Add' },
                onClick: () => {
                    addAudience()
                },
            },
            {
                key: 'changeAllAudiencesWhereIAmOwner',
                text: 'Change all audiences where I am owner',
                iconProps: { iconName: 'ChangeEntitlements' },
                onClick: () => {
                    setaudienceMasterIdUser(0)
                    changeAllAudiences()
                },
            },
        ],
        directionalHintFixed: true,
    };

    const changeAllAudiences = () => {
        setIsChangeAllAudiences(true)
        setShowChangeOwnerModal(true)
    }

    useEffect(() => {
        setGlobalDateLocale(navigator.language)

        if (audienceList.length == 0) {
            setApplying(true)
            setAudienceList([])
            loadAudience();
        }
    }, [])

    const getRecordDateTitle = (item: IDocumentOnlyAudienceDetail) => {
        var result;
        item.DateCreated !== null ? result = "Created-" + formatFromUTC(item.DateCreated) : result = "Created-not available";
        return result;
    }

    const onSelectionChange = () => {
        const selectionCount = selectionAudience.getSelectedCount();
        switch (selectionCount) {
            case 0:
                setSelectedAudience(emptyL)
                break;
            case 1:
                setSelectedAudience(selectionAudience.getSelection()[0] as IDocumentOnlyAudienceDetail)
                break;
            default:
                setSelectedAudience(emptyL)
                break;
        }
    }

    const selectionAudience = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    const loadAudience = async () => {

        const req: IDocumentOnlyAudienceRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceMasterId: "-1",
            FromRegistration: false
        }
        await Helper.GetDocumentOnlyAudienceDetailByUserName(req).then(data => {
            setAudienceList(data)
            setApplying(false)
            let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneAudiencelist")) as Element
            if (element !== null) { element.children[1].classList.add("scrollVisible") }
        })
    }

    const getKey = (item: IDocumentOnlyAudienceDetail, index?: number): string => {
        if (item !== undefined)
            return item.Id.toString()
        return ""
    }


    const onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined
        , option?: IChoiceGroupOption | undefined) => {
        props.updateSiteSelected(option!.key)
    }

    const addAudience = () => {
        selectionAudience.setAllSelected(false)
        setShowNewAudience(true)
        setModalTitle("Add")
        tittle = "Add"
    }

    const closeNewAudience = async () => {
        setModalTitle("")
        setApplying(true)
        setShowNewAudience(false)
        await loadAudience()
    }

    const deleteUser = async () => {
        const req: IDocumentOnlyAudienceDetailRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceDetailId: selectedAudienceUser?.Id.toString(),
            AudienceMasterId: selectedAudienceUser?.DocumentOnlyAudienceMasterId.toString(),
            Coowner: selectedAudienceUser?.CoOwner,
            TargetUserName: selectedAudienceUser?.UserName,
            CreateDocument: selectedAudienceUser?.CreateDocument,
            TemplateId: "-101"
        }
        await Helper.DeleteDocumentOnlyAudienceDetail(req).then(data => {
            if(data !== null) {
            if (data.Success) {
                loadUsers(selectedAudience!.DocumentOnlyAudienceMasterId.toString())

                hideDeleteUserModal()
            }
            else {
                hideDeleteUserModal()

                setShowErrorMessage(true)
                setMessage(data.ExceptionMessage)

                setTimeout(function () {
                    setShowErrorMessage(false)
                    setMessage("")
                    loadUsers(selectedAudience!.DocumentOnlyAudienceMasterId.toString())

                }, 5000);


            }
            }

        });
    }
    const deleteAudience = () => {
        setShowDeleteModal(true)
        setModalId(2)
        selectionAudience.setAllSelected(false)
    }
    const editAudience = async () => {

        setModalTitle("Edit")
        setaudienceMasterIdUser(selectedAudience?.DocumentOnlyAudienceMasterId!)
        setShowNewAudience(true)
    }

    const hideModal = () => {
        setShowModal(false)
        setAudienceDesc("")
        setAudienceName("")
        setIseditName(false)
        setenabledUploaduserList(false)
    }

    const hideDeleteModal = () => {
        setShowDeleteModal(false)
    }

    const getErrorMessage = (value: string): string => {
        if (value.length > 0) {
            setrequiredName(true)
        }
        else {
            setrequiredName(false)
            return "Name must not be empty.";
        }
        return ""
    };
    const getErrorMessageDesc = (value: string): string => {
        if (value.length > 0) {
            setrequiredDesc(true)
        }
        else {
            setrequiredDesc(false)
            return "Description must not be  empty.";
        }

        return ""
    };
    const sendAudience = () => {
        switch (modalId) {
            case 2: {
                deleteExistingAudience()
                setShowDeleteModal(false)
            }
                break;
        }
        hideModal()
    }
    
    async function deleteExistingAudience() {

        const request = {
            AudienceMasterId: selectedAudience?.DocumentOnlyAudienceMasterId,
            Name: undefined,
            Description: undefined,
        }

        let {data}: any = await HttpRequest.getWithSession('DeleteAudienceMaster', request)
        const response = data
        
        if (response !== undefined) {
            if (response.Success) {
                setApplying(true)
                setAudienceList([])
                loadAudience()
            }
            else {
                setshowMessageError(true)
                setMessage("An error occurred: " + response.ExceptionMessage)
                setTimeout(function () {
                    setshowMessageError(false)
                }, 5000);
            }
        }
    }
    
    const showUserModal = () => {
        setIsUserModalVisible(true)
    }
      
    const loadUsers = async (id: string) => {
        const req: IDocumentOnlyAudienceRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceMasterId: id || "-1",
            FromRegistration: false,
            AvoidCache: true
        }
        await Helper.GetDocumentOnlyAudienceDetailByAudienceMasterId(req).then(data => {
            setAudienceUsers(data)

            let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneAudienceUserlist")) as Element
            if (element !== null) { element.children[1].classList.add("scrollVisible") }
        })
    }

    const hideDeleteUserModal = () => {
        selectionAudienceUser.setAllSelected(false)
        setIsDeleteUserModalVisible(false)
    }

    const editUser = async () => {
        setIsSavingEditUSer(true)
        
        const req: IDocumentOnlyAudienceDetailRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceDetailId: selectedAudienceUser?.Id.toString(),
            AudienceMasterId: selectedAudienceUser?.DocumentOnlyAudienceMasterId.toString(),
            Coowner: roleCoOwner,
            TargetUserName: selectedAudienceUser?.UserName,
            CreateDocument: roleDocumentcreator,
            AudienceOwnerName: audienceOwnerUsername,
            TemplateId: "-101"

        }
        await Helper.UpdateDocumentOnlyAudienceDetail(req).then(data => {
            if (data.Success) {
                // setAudienceList([])
                loadUsers(DocumentOnlyAudienceMasterId.toString())
                hideEditUserModal()
            }
            else {
                setShowMessageUserAudienceError(true)
                setmessageUser(data.ExceptionMessage)
                setTimeout(function () {
                    setShowMessageUserAudienceError(false)
                    setmessageUser("")
                }, 5000);
            }
        }).catch(response => {
            console.log(response)
        }).finally(()=> {
            setIsSavingEditUSer(false)
        });
    }
    const hideEditUserModal = () => {
        setUserEmail("")
        setIsEditUserModalVisible(false)
        selectionAudienceUser.setAllSelected(false)
        setValidForm(false)
    }
    const hideUploadUserList = (open: boolean) => {
        setShowUploadUserList(open)
    }
    const uploadList = () => {
        setShowUploadUserList(true)
    }
    const ToggleUploadUserList = () => {
        setaudienceMasterIdUser(selectedAudience?.DocumentOnlyAudienceMasterId!)
        let isUploapList = selectedAudience?.Owner || selectedAudience?.CoOwner ? true : false
        setShowUploadUserList(true)
    }
    const showUsers = () => {
        setaudienceMasterIdUser(selectedAudience?.DocumentOnlyAudienceMasterId!)
        let isUploapList = selectedAudience?.Owner || selectedAudience?.CoOwner ? true : false
        setenabledUploaduserList(isUploapList)
        setIseditName(selectedAudience?.Name.toLocaleLowerCase() === "default" ? true : false)
        setShowModalUsers(true)
        setModalTitle("Users")
        loadUsers(selectedAudience?.DocumentOnlyAudienceMasterId.toString()!)
        setModalId(4)
    }
    const hideModalUsers = async () => {
        setShowModalUsers(false)
        setIseditName(false)
        setenabledUploaduserList(false)
        setModalTitle("")
        setShowMessageUserAudienceError(false)
        setmessageUser("")
        setAudienceList([])
        await loadAudience()
    }
    const closeChangeOwnerModal = () => {
        setShowChangeOwnerModal(false)
    }

    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <label className="ms-fontSize-18">My Audiences</label><br />
                        <div>Audiences are distribution groups for documents you create. Create a New Audience or Edit an existing Audience.</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <label className="ms-fontSize-18">My Audiences</label>
                    </div>
                </div>
                : null
            }

            <Stack tokens={stackTokens}>
                {showMessageError ? <MessageBar messageBarType={MessageBarType.error}>{message} </MessageBar> : null}
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <DefaultButton text="Options" menuProps={audienceMenuProps} />
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                    {!applying ?
                        // <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", paddingRight: "10px", maxWidth: "100%", height: "40vh" }}>
                        <div className={classNames.contentAudienceList}>
                            <ScrollablePane styles={scrollablePaneStyles} id="ScrollPaneAudiencelist" key="ScrollPaneAudiencelist" >
                                <DetailsList
                                    items={audienceList}
                                    compact={false}
                                    columns={columns}
                                    getKey={getKey}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    selection={selectionAudience}
                                    selectionMode={SelectionMode.single}
                                    selectionPreservedOnEmptyClick={false}
                                    enterModalSelectionOnTouch={true}
                                    checkboxVisibility={CheckboxVisibility.hidden}

                                />
                            </ScrollablePane>
                        </div>
                        : <Spinner size={SpinnerSize.medium} label="Loading data, please wait..." ariaLive="assertive" />
                    }
                </div>
            </Stack>
            <Modal
                isOpen={showModal}
                isBlocking={true}
            >
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Create">{modalTitle + " Audience"}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={modalTitle == "Edit" ? classNames.bodyEditVWOverride : classNames.bodyVWOverride}>
                                    <TextField label="Name" onGetErrorMessage={getErrorMessage} disabled={isEditName} required onChange={(_, v) => { setAudienceName(v!); }} value={audienceName} maxLength={75} title="Audience name." />
                                    <TextField label="Description" maxLength={1000} onGetErrorMessage={getErrorMessageDesc} required onChange={(_, v) => { setAudienceDesc(v!); }} value={audienceDesc} title="Audience description." />
                                    {modalTitle == "Edit" ?
                                        <Stack>
                                            {/* <div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
                                                        <ActionButton title="Add user." text="Add User" disabled={selectedAudience?.key == ""} onClick={showUserModal} iconProps={{ iconName: 'AddFriend' }} />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7">
                                                        <ActionButton hidden={!enabledUploaduserList} title="Upload user list." text="Upload User List" iconProps={{ iconName: 'AddToShoppingList' }} onClick={uploadList} />
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "25vh" }}>
                                                <MarqueeSelection selection={selectionAudienceUser}>
                                                    <DetailsList
                                                        items={audienceUsers}
                                                        compact={false}
                                                        columns={columnsUser}
                                                        getKey={getKey}
                                                        setKey="set"
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        isHeaderVisible={true}
                                                        selection={selectionAudienceUser}
                                                        selectionMode={SelectionMode.single}
                                                        selectionPreservedOnEmptyClick={false}
                                                        enterModalSelectionOnTouch={true}
                                                        checkboxVisibility={CheckboxVisibility.hidden}
                                                    />
                                                </MarqueeSelection>
                                            </div> */}
                                        </Stack>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={sendAudience} disabled={!requiredName || !requiredDesc || isEditName} text={modalTitle == "Edit" ? "Update" : "Add"} title={modalTitle == "Edit" ? "Update audience." : "Create audience."} />
                                    <DefaultButton onClick={hideModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>

            <Modal
                isOpen={showDeleteModal}
                isBlocking={true}
            >
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Create">Delete Audience</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeleteModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div>
                                    Are you sure you want to delete '{selectedAudience?.Name}' audience?
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={sendAudience} text="Delete" title="Delete audience." />
                                    <DefaultButton onClick={hideDeleteModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>

            <AudiencesAddUser
                showModal={isUserModalVisible}
                setShowModal={onHideAddUserModal}
                audienceMasterId={ audienceUsers.find(item => item.DocumentOnlyAudienceMasterId == selectedAudience?.DocumentOnlyAudienceMasterId)?.DocumentOnlyAudienceMasterId.toString()}
                templateId={"-101"}
                onDismissModal={onDismissAddUser}
            />
                                   

            <Modal
                isOpen={isDeleteUserModalVisible}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={classNames.header}>
                                    <span id="deleteModal">Delete User</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeleteUserModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.bodyDelete}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className="ms-Grid-row">
                                                    <Label>Are you sure you want to delete the User {selectedAudienceUser?.UserName}?</Label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <DialogFooter>
                                                    <PrimaryButton onClick={deleteUser} text="Delete" title="Delete audience member." />
                                                    <DefaultButton onClick={hideDeleteUserModal} text="Cancel" title="Close." />
                                                </DialogFooter>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isEditUserModalVisible}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={classNames.header}>
                                    <span id="updateModal" title={"Owner: " + audienceOwnerUsername}>{audienceLabel} - Edit User</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideEditUserModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.User}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className="ms-Grid-row"> {showMessageUserAudienceError ? <MessageBar messageBarType={MessageBarType.error}>{messageUser} </MessageBar> : null}</div>
                                                <div className="ms-Grid-row">
                                                    <TextField label="Email" defaultValue={selectedAudienceUser?.UserName + " (" + selectedAudienceUser?.FullName + ")"} disabled />
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <br />
                                                    <Checkbox id="coowner" checked={roleCoOwner} label="Co-owner"
                                                              onChange={(ev, checked) => {
                                                                  setRoleCoOwner(checked!)
                                                                  if (checked || roleDocumentcreator) setValidForm(false)
                                                                  else {
                                                                      setValidForm(true)
                                                                  }
                                                              }}
                                                              title="Grants member manage application capability." />
                                                    <br />
                                                    <Checkbox id="documentcreator" checked={roleDocumentcreator} label="Create Document"
                                                              onChange={(ev, checked) => {
                                                                  setRoleDocumentcreator(checked!)
                                                                  if (checked || roleCoOwner) setValidForm(false)
                                                                  else {
                                                                      setValidForm(true)
                                                                  }
                                                              }}    
                                                              title="Grants member create document capability when selecting application." />
                                                </div>
                                            </div>
                                        </div>
                                        {isSavingEditUSer &&
                                            <>
                                                <StackItem>
                                                    <Spinner size={SpinnerSize.medium} labelPosition="left"
                                                             label={t('text.spinnerUpdating', {ns: 'common'})}/>
                                                </StackItem>
                                            </>
                                        }
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <DialogFooter>
                                                    <PrimaryButton onClick={editUser} disabled={validForm} text="Update" title="Update audience member." />
                                                    <DefaultButton onClick={hideEditUserModal} text="Cancel" title="Close." />
                                                </DialogFooter>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <UserLists
                showUploadUserList={showUploadUserList}
                hideUploadUserList={hideUploadUserList}
                audienceMasterId={audienceMasterIdUser}
                AudienceOwnerName={audienceOwnerUsername}
                loadUsers={loadUsers}
            />
            <NewAudience
                modalTitle={modalTitle}
                showNewAudience={showNewAudience}
                closeNewAudience={closeNewAudience}
                audienceMasterIdUser={audienceMasterIdUser}
            />
            <Modal
                isOpen={showModalUsers}
                isBlocking={true}
            >
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Users" title={"Owner: " + audienceOwnerUsername}>{audienceLabel} - {modalTitle}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideModalUsers}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.bodyEditVWOverride}>

                                    <Stack>
                                        <div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
                                                    <ActionButton title="Add user." text="Add User" disabled={selectedAudience?.key == ""} onClick={showUserModal} iconProps={{ iconName: 'AddFriend' }} />
                                                </div>
                                                <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7">
                                                    <ActionButton hidden={!enabledUploaduserList} title="Upload user list." text="Upload User List" iconProps={{ iconName: 'AddToShoppingList' }} onClick={uploadList} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "25vh" }}> */}
                                        {/* <MarqueeSelection selection={selectionAudienceUser}> */}
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                                                <div className={classNames.contentAudienceUser}>
                                                    <ScrollablePane styles={scrollablePaneStyles} id="ScrollPaneAudienceUserlist" key="ScrollPaneAudienceUserlist" >
                                                        <DetailsList
                                                            items={audienceUsers}
                                                            compact={false}
                                                            columns={columnsUser}
                                                            getKey={getKey}
                                                            setKey="set"
                                                            layoutMode={DetailsListLayoutMode.justified}
                                                            isHeaderVisible={true}
                                                            selection={selectionAudienceUser}
                                                            selectionMode={SelectionMode.single}
                                                            selectionPreservedOnEmptyClick={false}
                                                            enterModalSelectionOnTouch={true}
                                                            checkboxVisibility={CheckboxVisibility.hidden}
                                                        />

                                                    </ScrollablePane>
                                                </div>
                                            </div></div>
                                        {/* </MarqueeSelection> */}
                                        {/* </div> */}
                                    </Stack>

                                </div>
                            </div>
                        </div>

                        <br />
                    </div>
                </Stack>
            </Modal>

            <ReassignRecords
                audiences={audienceUsers}
                selectedAudienceUser={selectedAudienceUser?.UserName}
                setIsReassignRecordsVisible={setIsReassignRecordsVisible}
                isReassignRecordsVisible={isReassignRecordsVisible}
                audienceId={selectedAudienceUser?.DocumentOnlyAudienceMasterId}
                audienceOptions={audienceUsers.map<IDropdownOption>(item => {
                    var textAudience = item.Name + " - " + item.UserName
                    return { key: item.UserName, text: textAudience }
                }).filter(f => f.key != selectedAudienceUser?.UserName)}
            />

            <ChangeOwner
                isAdministration={false}
                showChangeOwnerModal={showChangeOwnerModal}
                closeChangeOwnerModal={closeChangeOwnerModal}
                isChangeAllAudiences={isChangeAllAudiences}
                audienceMasterId={audienceMasterIdUser}
            />
        </>
    );
}

export default Audiences