import { Checkbox, DefaultButton, DialogFooter, Dropdown, mergeStyleSets, PrimaryButton, Stack } from '@fluentui/react';
import React, { useEffect } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FILE_ICONS, orderIcons } from '../../../Helpers/Utils';
import { AttachmentRule, IPropertyBagSettings } from '../../../interfaces/IPropertyBagSettings';
import { CustomModalProps, Sizes } from '../../../share/CustomModal';
import { RHFTextBoxControl } from '../../RHFControls/RHFTextBoxControl';
import {useDialog} from "../../../hooks/useDialog";

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '65vw',
    },
    dialogMessage: {
        padding: '0px 24px 12px 24px',
    },
    section: {
        padding: '4px 0'
    },
});
type AttachmentFormProps = {
    Modal: React.FC<CustomModalProps>
    settings?: IPropertyBagSettings
    setSettings?: any
    close: any
    attachment?: AttachmentRule
    attachments?: AttachmentRule[]
    getAttachments: any
}
const AttachmentsForm = ({ Modal, close, getAttachments, attachment, attachments }: AttachmentFormProps) => {
    const continueDialog = useDialog()

    const myForm = useForm({
        defaultValues: {
            id: '',
            count: '',
            maxSize: '',
            name: '',
            description: '',
            supportedTypes: '',
            required: ''
        }
    })
 
    const { t } = useTranslation('attachmentsComponent')

    const type = !attachment?.id || attachment?.id === -1 ? 'create' : 'update'

    const Save = (data: any) => {
        let lastId = 1
        if(attachments !== undefined) {
            const last = attachments.slice().sort(function (a, b) {return a.id - b.id}).at(-1)
            lastId = last ? last.id + 1 : 1
        }

        let _attachment: AttachmentRule = {
            ...data,
            id: attachment?.id ?? lastId,
            name: data.name,
            count: data.count != '' ? Number(data.count) : 0,
            maxSize: data.maxSize != '' ? Number(data.maxSize) : 0,
            required: !!data.required,
            supportedTypes: data.supportedTypes
        }
        getAttachments(_attachment)
        myForm.reset({maxSize: '', count: '', name: '', description: '', supportedTypes: '', required: ''})
        continueDialog.close()
        close()
    }

    const saveAttachment = async () => {
        await myForm.handleSubmit(async (data) => {
            const _id = attachment?.id ?? -1
            const _prevAttachment = attachments?.filter((item) => item.id !== _id && item.count === Number(data.count))
           
            if (_prevAttachment === undefined || _prevAttachment?.length === 0) {
                Save(data)
            } else {
                continueDialog.open({
                    confirm: () => {
                        return () => { Save(data) }
                       
                    }
                })
            }
        }, () => {

        })()
    }

    useEffect(() => {
        if (attachment) {
            const supportedTypes = attachment.supportedTypes ? attachment?.supportedTypes.split(',') : []
            myForm.reset({
                id: attachment.id ? `${attachment.id}` : '',
                count: attachment.count ? `${attachment.count}` : '',
                maxSize: attachment.maxSize ? `${attachment.maxSize}` : '',
                name: attachment.name ?? '',
                description: attachment.description ?? '',
                required: attachment.required ? `${attachment.required}` : '',
                supportedTypes: supportedTypes.filter((v) => FILE_ICONS.find(f => f.key === v)).toString()
            })
        }
    }, [attachment, myForm.reset])

    


    return (
        <>
            <Modal size={Sizes.xxs} titleId={'attachSettingsModal'}
                   title={t(type === 'create' ? 'formSettings.titleCreate' : 'formSettings.titleUpdate')}>

                <FormProvider {...myForm}>
                    <form>

                        {/*<form onSubmit={handleSubmit(saveAttachment)} noValidate>*/}
                        <div>

                            <div className="scrollVisible" style={{
                                overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh"
                            }}>
                                <Stack styles={{root: {paddingRight: "10px"}}}>
                                    <div className={contentStyles.section}>
                                        <RHFTextBoxControl
                                            id={"count"}
                                            label={t('attachment.count')}
                                            rules={{
                                                required: t('text.fieldRequired', {ns: 'common'}),
                                            }}
                                            placeholder={t('attachment.count')}
                                            title={t('attachment.count')}
                                            ariaLabel={t('attachment.count')}
                                            maxLength={250}
                                            control={myForm.control}
                                            setValue={myForm.getValues}
                                            getValues={myForm.getValues}
                                            trigger={myForm.trigger}
                                            min={0} type={'number'}
                                            required
                                        >
                                        </RHFTextBoxControl>
                                    </div>
                                    <div className={contentStyles.section}>
                                        <RHFTextBoxControl
                                            id={"name"}
                                            label={t('attachment.name')}
                                            rules={{
                                                required: t('text.fieldRequired', {ns: 'common'}),
                                                validate: {
                                                    required:
                                                        (value) => {
                                                            return !!value.trim() || t('text.fieldRequired', {ns: 'common'})
                                                        }
                                                },
                                            }}
                                            placeholder={t('attachment.name')}
                                            title={t('attachment.name')}
                                            ariaLabel={t('attachment.name')}
                                            maxLength={250}
                                            control={myForm.control}
                                            setValue={myForm.getValues}
                                            getValues={myForm.getValues}
                                            trigger={myForm.trigger}
                                            required
                                        >
                                        </RHFTextBoxControl>
                                    </div>
                                    <div className={contentStyles.section}>
                                        <RHFTextBoxControl
                                            id={"description"}
                                            label={t('attachment.description')}
                                            placeholder={t('attachment.description')}
                                            title={t('attachment.description')}
                                            ariaLabel={t('attachment.description')}
                                            maxLength={250}
                                            control={myForm.control}
                                            setValue={myForm.getValues}
                                            getValues={myForm.getValues}
                                            trigger={myForm.trigger}
                                        >
                                        </RHFTextBoxControl>
                                    </div>
                                    <div className={contentStyles.section}>
                                        <Controller name="supportedTypes" control={myForm.control}
                                                    rules={{required: t('text.fieldRequired', {ns: 'common'})}}
                                                    render={({field, fieldState: {error}}) => <Dropdown

                                                        {...field}
                                                        selectedKeys={field.value ? field.value.split(",") : undefined}
                                                        onChange={(_, option) => {
                                                            if (option) {
                                                                const _value = field.value ? field.value.split(",") : []
                                                                const _newValue = option.selected ? [..._value, option.key as string] : _value.filter(key => key !== option.key)
                                                                myForm.setValue("supportedTypes", _newValue.toString())
                                                            }
                                                        }}
                                                        errorMessage={error && error.message}
                                                        required
                                                        label={t('attachment.supportedTypes')}
                                                        multiSelect
                                                        options={orderIcons()}
                                                    />}
                                        />
                                    </div>
                                    <div className={contentStyles.section}>
                                        <Controller name="required" control={myForm.control}
                                                    render={({field}) => <Checkbox {...field}
                                                                                   checked={Boolean(field.value)}
                                                                                   title={t('attachment.required')}
                                                                                   value={'required'}
                                                                                   label={t('attachment.required')}/>}
                                        />
                                    </div>
                                </Stack>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton id="btnCreateUpdate"
                                                   text={t(type === 'create' ? 'formSettings.btnCreate' : 'formSettings.btnUpdate')}
                                                   disabled={false} onClick={saveAttachment}
                                                   title={t(type === 'create' ? 'formSettings.btnCreate' : 'formSettings.btnUpdate')}/>
                                    <DefaultButton id="btnCancel" onClick={close} text={t('formSettings.btnCancel')}
                                                   title={t('formSettings.btnCancel')}/>
                                </DialogFooter>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </Modal>
            <continueDialog.Dialog title={t('title.duplicatedOrder', {ns: 'attachmentsComponent'})} titleId='confirmModal'
                                 actionText={t('Common.Button.Continue', {ns: 'common'})}>
                <div className={contentStyles.dialogMessage}>
                    {t('text.confirmAdding', {ns: 'attachmentsComponent'})}
                </div>
            </continueDialog.Dialog>
        </>
    )
}
export default AttachmentsForm