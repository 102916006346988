import { FontWeights, getTheme, IChoiceGroupOption, IColumn, IconButton, IDatePickerStyleProps, IDatePickerStyles, IDropdownOption, IDropdownStyleProps, IDropdownStyles, IIconProps, ILabelStyleProps, ILabelStyles, IScrollablePaneStyles, IStyleFunctionOrObject, ITextFieldStyles, mergeStyleSets, Modal, ScrollablePane, Separator, TextField } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import { useForm } from 'react-hook-form';
import { ControlledCheckListField } from '../../Fluent-RHF/ControllerCheckListField';
import { ControlledChoiceGroupField } from '../../Fluent-RHF/ControllerChoiceGroupField';
import { ControlledDropDownField } from '../../Fluent-RHF/ControllerDropdownField';
import { ControlledMaskField } from '../../Fluent-RHF/ControllerMaskField';
import { ControlledTextField } from '../../Fluent-RHF/ControllerTextField';
import { ControlledDatePickerField } from '../../Fluent-RHF/ControllesDatePickerField';
import { ILayout } from './CustomTemplate';
import { InputType } from './Validations';
import '../../Custom.css';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { formatFromUTC } from '../../Helpers/DateUtils';
import Helper from '../../Helpers/Helper';
import {useBranding} from "../../hooks/useBranding";
import ListService, {IGetListsDetailsRequest} from "../../services/List";
import HttpRequest from "../../services/HttpRequest";


const ResponsiveReactGridLayout = WidthProvider(Responsive);
const ReactGridLayout = WidthProvider(RGL);

const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
            ':disabled': {
                backgroundColor: theme.palette.white
            },
        },
    },
    
};
const classNamex = mergeStyleSets({
    pane: {
        maxWidth: '100%',
    },
    toolbox: {

        maxWidth: '100%',
        marginTop: '45px',
        marginRight: '5px'
    }
});
const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNamex.pane };
const textFieldStyle: ITextFieldStyles = {
    root: {

    },
    revealButton: "",
    revealSpan: "",
    revealIcon: "",
    prefix: "",
    suffix: "",
    description: "",
    icon: "",
    field: "",
    errorMessage: "",
    fieldGroup: "",
    subComponentStyles: {
        label: getLabelStyles
    },
    wrapper: ""
};
function getLabelStyles(props: ILabelStyleProps): ILabelStyles {
    return {
        root: {
            //color: props.theme.palette.themePrimary,"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif
            fontSize: 14,
            fontFamily: "Segoe UI Web (West European)",
            fontWeight: "normal"
        },
    };
}
const datePickerStyles: IStyleFunctionOrObject<IDatePickerStyleProps, IDatePickerStyles> = {
    root: {
        selectors: { 'label': { fontWeight: "normal" } }
    }

};
const dropdownStyles: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles> = {
    title: {
        border: "none"
    }

};


const cancelIcon: IIconProps = { iconName: 'Cancel' };


function PreviewLayout(props: any) {
    const { branding } = useBranding()
    const [previewItems, setPreviewItems] = useState<ILayout[]>([]);
    const [contentPreview, setContentPreview] = useState<JSX.Element[]>();
    const [layoutItems, setLayoutItems] = useState<ILayout[]>([]);
    const [pageSelected, setPageSelected] = useState(1);
    const [nSeparator, setNSeparator] = useState(1);
    const [nHeader, setNHeader] = useState(1);
    const [nParagraph, setNParagraph] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        bodyPreview: {
            width: '80vw',
            height: '100vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },

    });


    useEffect(() => {
        if (props.isOpen) {
            if (props.data !== null && props.data !== undefined && props.data !== "") {
                setIsLoading(true)
                setPageSelected(1)
                var json = JSON.parse(props.data)
                sessionStorage.setItem("layout", json.Layout)
                setPreviewItems(JSON.parse(sessionStorage.getItem("layout")!))


                //console.log(props.data)
                setTimeout(function () {
                    generatePreview();
                }, 500);

            }
            else
                if (props.templateId !== undefined && props.templateId !== "") {
                    setIsLoading(true)
                    loadTemplate().then(() => {
                        if (sessionStorage.getItem("layout") != null && sessionStorage.getItem("layout") != "")
                            setPreviewItems(JSON.parse(sessionStorage.getItem("layout")!))

                        generatePreview();

                    });
                }
        }

    }, [props.isOpen])

    const { handleSubmit, control, setValue } = useForm<any, any>({
        defaultValues: {
        },
        reValidateMode: "onSubmit",
        mode: "all"
    });

    async function loadTemplate() {

        const request = {
            TemplateId: props.templateId.toString(),
            User: undefined
        }

        let {data}: any = await HttpRequest.getWithSession('statemanagement', request)
        const response = data        
                
        sessionStorage.setItem("layout", response.Layout)
        if (response.Layout != "") {
            var loadedLayout = JSON.parse(response.Layout) as ILayout[]
            var prevItems = loadedLayout.filter(x => x.Page == 1)

            setLayoutItems(prevItems)

            var secHeaders = loadedLayout.filter(x => x.Id.includes("SectionHeader"))
            if (secHeaders != null && secHeaders.length > 0) {
                var maxN = 0
                secHeaders.forEach(sh => {
                    var indx = sh.Id.substring(13, sh.Id.length)
                    if (parseInt(indx) > maxN)
                        maxN = parseInt(indx)
                });
                setNHeader(maxN + 1)
            }

            var lineBreaks = loadedLayout.filter(x => x.Id.includes("LineBreak"))
            if (lineBreaks != null && lineBreaks.length > 0) {
                var maxN = 0
                lineBreaks.forEach(ln => {
                    var indx = ln.Id.substring(9, ln.Id.length)
                    if (parseInt(indx) > maxN)
                        maxN = parseInt(indx)
                });
                setNSeparator(maxN + 1)
            }

            var parag = loadedLayout.filter(x => x.Id.includes("Paragraph"))
            if (parag != null && parag.length > 0) {
                var maxN = 0
                parag.forEach(pa => {
                    var indx = pa.Id.substring(9, pa.Id.length)
                    if (parseInt(indx) > maxN)
                        maxN = parseInt(indx)
                });
                setNParagraph(maxN + 1)
            }

        }
        else
            setLayoutItems([])

        setPageCount(parseInt(response.Pages))
    }

    function generatePreview() {
        var col: IColumn[] = []
        var auxColumns = "";
        var pagePrevItems = JSON.parse(sessionStorage.getItem("layout")!) as ILayout[];
        var prevItems = pagePrevItems.filter(x => x.Page == pageSelected)
        var list = prevItems!.map(function (layout) {
            if (layout.Children != null && layout.Children.length > 0) {
                var nCol = layout.Children.length;
                var maxW = 500 / nCol;

                layout.Children.forEach(c => {
                    col.push({
                        key: c.Id,
                        name: c.Label,
                        ariaLabel: '',
                        fieldName: c.Id,
                        minWidth: maxW,
                        maxWidth: maxW,

                    });
                    auxColumns = auxColumns.concat(c.Id + ",")
                });
            }
            return (
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" key={layout.Id}>
                    {layout.Type == InputType.Textbox && layout.Validations.Mask.length == 0 ?
                        <ControlledTextField
                            styles={textFieldStyle}
                            borderless
                            className={"w3-inputCC"}
                            required={layout.Validations.Required}
                            label={layout.Label}
                            maxLength={layout.Validations.MaxLength}
                            control={control}
                            tabIndex={layout.TabIndex}
                            title={layout.Tooltip}
                            name={layout.Id}
                            pattern={layout.Validations.Regex.length > 0 ? layout.Validations.Regex : undefined}
                            defaultValue={layout.Validations.DefaultValue}
                            rules={layout.Validations.Required ? { required: "This field is required" } : {}} /> :

                        layout.Type == InputType.TextArea ?
                            <ControlledTextField
                                styles={textFieldStyle}
                                borderless
                                className={"w3-inputCC"}
                                required={layout.Validations.Required}
                                maxLength={layout.Validations.MaxLength}
                                label={layout.Label}
                                control={control}
                                tabIndex={layout.TabIndex}
                                title={layout.Tooltip}
                                name={layout.Id}
                                defaultValue={layout.Validations.DefaultValue}
                                rules={layout.Validations.Required ? { required: "This field is required" } : {}}
                                multiline
                                rows={6} /> :

                            layout.Type == InputType.DropDownList ?
                                <ControlledDropDownField
                                    className={"w3-select"}
                                    required={layout.Validations.Required}
                                    options={layout.ListId != undefined && layout.ListId != null && layout.ListId == 0 ? layout.ItemList.split(',').map<IDropdownOption>(item => {
                                        return {
                                            key: item,
                                            text: item
                                        }
                                    })
                                        : layout.ListId != undefined && layout.ListId != null ? loadEachList(layout.ListId.toString()) : []
                                    }
                                    label={layout.Label}
                                    control={control}
                                    tabIndex={layout.TabIndex}
                                    title={layout.Tooltip}
                                    name={layout.Id}
                                    onRenderLabel={onRenderLabel}
                                    styles={dropdownStyles}
                                    placeholder="Select a value"
                                    rules={layout.Validations.Required ? { required: "Please select a value" } : {}} /> :

                                layout.Type == InputType.CheckBoxList ?
                                    <ControlledCheckListField
                                        className={"w3-select"}
                                        required={layout.Validations.Required}
                                        options={layout.ListId != undefined && layout.ListId != null && layout.ListId == 0 ? layout.ItemList.split(',').map<IDropdownOption>(item => {
                                            return {
                                                key: item,
                                                text: item
                                            }
                                        })
                                            : layout.ListId != undefined && layout.ListId != null ? loadEachList(layout.ListId.toString()) : []
                                        }
                                        label={layout.Label}
                                        control={control}
                                        tabIndex={layout.TabIndex}
                                        title={layout.Tooltip}
                                        name={layout.Id}
                                        id={layout.Id}
                                        onRenderLabel={onRenderLabel}
                                        styles={dropdownStyles}
                                        placeholder="Select an option"
                                        rules={layout.Validations.Required ? { required: "Please select an option" } : {}}
                                    /> :

                                    layout.Type == InputType.RadioButtonList ?
                                        <ControlledChoiceGroupField
                                            required={layout.Validations.Required}
                                            defaultSelectedKey={layout.Validations.Required ? layout.ItemList.split(',')[0] : ""}
                                            options={layout.ItemList.split(',').map<IChoiceGroupOption>(item => {
                                                return {
                                                    key: item,
                                                    text: item
                                                }
                                            })}
                                            label={layout.Label}
                                            control={control}
                                            tabIndex={layout.TabIndex}
                                            title={layout.Tooltip}
                                            name={layout.Id}
                                            placeholder="Select a value"
                                            rules={layout.Validations.Required ? { required: "Please select a value" } : {}} /> :

                                        layout.Type == InputType.DatePicker ?
                                            <ControlledDatePickerField
                                                tooltipUrl={layout.TooltipUrl}
                                                borderless
                                                isMonthPickerVisible={true}
                                                formatDate={onFormatDate}
                                                className={"w3-inputCC"}
                                                isRequired={layout.Validations.Required}
                                                label={layout.Label}
                                                control={control}
                                                tabIndex={layout.TabIndex}
                                                title={layout.Tooltip}
                                                name={layout.Id}
                                                styles={datePickerStyles}
                                                rules={layout.Validations.Required ? { required: "Date is required" } : {}} /> :

                                            layout.Type == InputType.Number ?
                                                <ControlledTextField
                                                    styles={textFieldStyle}
                                                    borderless
                                                    className={"w3-inputCC"}
                                                    label={layout.Label}
                                                    control={control}
                                                    tabIndex={layout.TabIndex}
                                                    title={layout.Tooltip}
                                                    name={layout.Id}
                                                    type={"number"}
                                                    rules={layout.Validations.Required ? {
                                                        required: "This field is required"
                                                    } : {}}
                                                /> :
                                                layout.Type == InputType.Currency ?
                                                    <ControlledMaskField
                                                        styles={textFieldStyle}
                                                        borderless
                                                        className={"w3-inputCC"}
                                                        required={layout.Validations.Required}
                                                        label={layout.Label}
                                                        control={control}
                                                        tabIndex={layout.TabIndex}
                                                        title={layout.Tooltip}
                                                        name={layout.Id}
                                                        prefix="$"
                                                        mask="999,999,999.99"
                                                        rules={layout.Validations.Required ? { required: "This field is required" } : {}} /> :

                                                    layout.Type == InputType.Textbox && layout.Validations.Mask.length > 0 ?
                                                        <ControlledMaskField
                                                            styles={textFieldStyle}
                                                            borderless
                                                            className={"w3-inputCC"}
                                                            required={layout.Validations.Required}
                                                            label={layout.Label}
                                                            control={control}
                                                            tabIndex={layout.TabIndex}
                                                            title={layout.Tooltip}
                                                            name={layout.Id}
                                                            onChange={(evt) => {
                                                                console.log(evt)
                                                            }}
                                                            mask={layout.Validations.Mask}
                                                            //mask="Lssss-999"
                                                            maskFormat={{ 'L': /[a-zA-Z]/, '9': /[0-9]/, 's': /[\!\@\#\$\%\^\&\*\(\)]/ }}
                                                            rules={layout.Validations.Required ? { required: "This field is required" } : {}} /> :

                                                        layout.Type == InputType.LineBreak ?
                                                            <Separator /> :

                                                            layout.Type == InputType.SectionHeader ?
                                                                <div style={{ display: "flex", justifyContent: "left", alignItems: "center", fontSize: "18px", position: "absolute", bottom: "0px" }}>{layout.Label}</div> :

                                                                layout.Type == InputType.Paragraph ?
                                                                    <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "100%" }}>
                                                                        <TextField tabIndex={layout.TabIndex}
                                                                            value={layout.TextParagraph}
                                                                            multiline
                                                                            rows={5}
                                                                            title={layout.Tooltip}
                                                                            borderless
                                                                            resizable={false}
                                                                            readOnly={false} /></div> :

                                                                    layout.Type == InputType.GridList ?
                                                                        <>
                                                                            <ScrollablePane styles={scrollablePaneStyles} >
                                                                                <table className="styled-table" title={layout.Tooltip} id={layout.Id}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ display: "none" }}></th>
                                                                                            {col.map(x => {
                                                                                                return (
                                                                                                    <th>{x.name}</th>
                                                                                                )
                                                                                            })}
                                                                                            <th></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            {col.map(x => {
                                                                                                return (
                                                                                                    <td>{"Example for " + x.name}</td>
                                                                                                )
                                                                                            })}
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>

                                                                            </ScrollablePane>



                                                                        </> : <div>{layout.Label}</div>}
                </div>
            );
        });
        setContentPreview(list);
        setIsLoading(false)
    }

    async function loadList(listId: string) {

        let list: IDropdownOption[] = []

        const request: IGetListsDetailsRequest = {
            DocumentOnlyListMasterId: listId
        }

        await ListService.GetListsDetails(request)
            .then((data) => {
                data.forEach((e: any) => {
                    list.push({
                        key: e.Key,
                        text: e.Value
                    })
                })
            })
            .catch((error) => {
            })
            .finally(() => {
            })
        return list;
    }

    function loadEachList(listId: string) {

        var l: IDropdownOption[] = []
        var list = loadList(listId).then(x => {
            x.forEach(e => {
                l.push(e)
            });
        }
        );

        return l;

    }

    const onRenderLabel = (props: any) => {
        return (
            <div>
                <label style={{ fontSize: 14, fontFamily: "Segoe UI Web (West European)", fontWeight: "normal" }}>{props.label}</label>
                {props.required ? <span style={{ color: "#973434" }}>*</span> : null}
            </div>
        );
    };

    const onFormatDate = (date?: Date): string => {
        return date !== null ? formatFromUTC(date, 'LL', "en-US") : null;
    }

    const onClose = () => {

        setPreviewItems([]);
        //setContentPreview()
        setLayoutItems([]);
        setPageSelected(1);
        setNSeparator(1);
        setNHeader(1);
        setNParagraph(1);
        setPageCount(1);
        props.Close(false)
    }

    return (
        <>
            <Modal
                key={"PreviewLayout"}
                isOpen={props.isOpen}
                isBlocking={true}>
                <div className={contentStyles.header}>
                    <span id={props.titleId}>{"Preview (" + props.templateName + ")"}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={() => {
                            onClose()
                        }}
                        title="Close."
                    />
                </div>

                <div className={contentStyles.bodyPreview}>

                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                {!isLoading ?
                                    <ReactGridLayout
                                        layout={previewItems.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), [])}
                                        cols={4}
                                        rowHeight={35}
                                        autoSize={false}
                                        useCSSTransforms={false}
                                        isResizable={false}
                                        isDraggable={false}
                                        verticalCompact={false}
                                    >
                                        {contentPreview}
                                    </ReactGridLayout>
                                    :
                                    <Spinner size={SpinnerSize.medium} label="Loading..." ariaLive="assertive" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )

}

export default PreviewLayout