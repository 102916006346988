import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Sizes} from "../../share/CustomModal";
import {useModal} from "../../hooks/useModal";
import {_styles, Content, nonShrinkingStackItemStyles} from "./index";
import {DefaultButton, Separator, Spinner, SpinnerSize, Stack, StackItem} from "@fluentui/react";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import {useBranding} from "../../hooks/useBranding";

type Properties = {
    showModal: boolean
    setShowModal: (status: boolean) => void
}
export const MyProfile = ({setShowModal, showModal}: Properties) => {

    const {t} = useTranslation(['common', 'profile'])
    const {branding} = useBranding()
    const myModal = useModal()
    const [onCloseModal, setOnCloseModal] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [triggerOnUpdate, setTriggerOnUpdate] = useState(false)

    useEffect(() => {
        if (showModal) {
            setOnCloseModal(false)
            setIsLoaded(false)
            setIsSaving(false)
            setTriggerOnUpdate(false)
            myModal.open()
        }
    }, [showModal])

    /**
     * Close modal
     */
    const closeModal = (cancel: boolean = false) => {

        if (!cancel) {

            myModal.close()
            setShowModal(false)
        }

        setOnCloseModal(false)
    }

    const onBeforeCloseModal = () => {
        setOnCloseModal(true)
    }

    return (
        <>
            {/*region MODAL TEMPLATE*/}
            <myModal.Modal
                title={t('text.title', {'ns': 'profile'})} titleId="MyProfile" size={Sizes.sm}
                beforeClose={onBeforeCloseModal}
                bodyClassName={_styles(branding.theme).body}
                additionalHeader={<>
                    <HelpComponentRelative
                        headline={t('text.helpTitle', {'ns': 'profile'})}
                        text={t('text.helpSubtitle', {'ns': 'profile'})}
                        location="settings"
                    />
                </>}
            >
                <>
                    {/*region CONTENT*/}
                    <Stack grow={1}
                           style={{
                               overflow: "hidden",
                               height: "60vh",
                           }}>

                        <Content
                            fromModal={true}
                            triggerOnCloseParentModal={onCloseModal}
                            onCloseParentModal={closeModal}
                            setIsLoadedParent={setIsLoaded}
                            setIsSavingParent={setIsSaving}
                            triggerOnUpdate={triggerOnUpdate}
                            onUpdateParentModal={setTriggerOnUpdate}
                        />
                    </Stack>
                    {/*endregion*/}

                    {/*region FOOTER*/}
                    <Separator styles={{root: {marginBottom: -8}}}></Separator>
                    <Stack horizontal style={{overflow: "hidden"}} disableShrink tokens={{childrenGap: 8}}
                           styles={nonShrinkingStackItemStyles}>
                        <StackItem grow={1}>&nbsp;</StackItem>
                        {isSaving &&
                            <>
                                <StackItem>
                                    <Spinner
                                        size={SpinnerSize.medium} labelPosition="left"
                                        label={t('text.spinnerSaving', {ns: 'common'})}/>
                                </StackItem>
                            </>
                        }
                        <Stack horizontal wrap tokens={{childrenGap: 8}}>
                            <Stack>
                                <PrimaryButton
                                    onClick={() => {
                                        setTriggerOnUpdate(true)
                                    }}
                                    text={t('Common.Button.Update', {'ns': 'common'})}
                                    disabled={isSaving || !isLoaded}
                                    title={t('title.update', {'ns': 'profile'})}/>
                            </Stack>
                            <Stack>
                                <DefaultButton
                                    onClick={onBeforeCloseModal}
                                    text={t('Common.Button.Close', {ns: 'common'})}
                                    title={t('Common.Button.Close', {ns: 'common'})}/>
                            </Stack>
                        </Stack>
                    </Stack>
                    {/*endregion*/}


                </>
            </myModal.Modal>
            {/*endregion*/}
        </>
    )
}

