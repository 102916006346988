import { Auth0Provider } from '@auth0/auth0-react'
import React, { createContext, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react'
import configJson from "../authConfig.json"

type TeamsOptions = {
    theme?: string,
    isLoaded: boolean
}
type SSOOptions = {
    enabled?: boolean,
    isLoaded?: boolean,
    isValid?: boolean
    user?: string
    orgId?: string
}
type AuthContextType = {
    user: any,
    authenticateUser: (user: any) => void,
    isLoggedIn: boolean,
    isAuthenticated: boolean,
    isAuthLoaded: boolean,
    setIsAuthLoaded: (isAuthLoaded: boolean) => void,
    setIsAuthenticated: (isAuthenticated: boolean) => void,
    source: {
        teams: TeamsOptions;
        sso: SSOOptions;
    }
    setSSO: React.Dispatch<React.SetStateAction<SSOOptions>>
    setSource: (type: 'sso' | 'teams', value: SSOOptions | TeamsOptions) => void
}
export const appSourceContext = createContext({} as AuthContextType)


export const AuthContextProvider = ({ children }: { children: ReactElement }) => {
    const [user, setUser] = useState({})
    const [isAuthLoaded, setIsAuthLoaded] = useState<boolean>(false)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [sso, setSSO] = useState<SSOOptions>({
        enabled: false,
        isLoaded: false,
        isValid: false
    })
    const [teams, setTeams] = useState<TeamsOptions>({
        theme: undefined,
        isLoaded: false
    })

    const authenticateUser = useCallback((user: any) => {
        // if (!user?.email)
        //    return
        setUser(user)
        //setIsAuthenticated(true)
    }, [])

    const source = useMemo(() => {
        return {
            teams,
            sso,
        }
    }, [sso, teams])
    const setSource = (type: 'sso' | 'teams', value: SSOOptions | TeamsOptions) => {
        switch (type) {
            case 'sso':
                setSSO(() => { return { ...value } as SSOOptions })
                break;
            case 'teams':
                setTeams(value as TeamsOptions)
                break;
        }
    }
    const isLoggedIn = user !== undefined


    return (
        <Auth0Provider
            domain={configJson.domain}
            clientId={configJson.clientId}>
            <appSourceContext.Provider value={{
                user, authenticateUser, isLoggedIn, isAuthLoaded,
                setIsAuthLoaded,
                isAuthenticated, setIsAuthenticated, source, setSource, setSSO
            }}>
                {children}
            </appSourceContext.Provider >
        </Auth0Provider>

    )

}