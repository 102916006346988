import React, { useState, useEffect } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import Helper, { IBaseRequest, IUserProfile } from '../../Helpers/Helper';
import { DetailsListLayoutMode, IContextualMenuItem, IContextualMenuProps, SelectionMode, MarqueeSelection, IMessageBarStyles, MessageBar, MessageBarType } from '@fluentui/react';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField } from '@fluentui/react/lib/TextField';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useConstCallback } from '@uifabric/react-hooks';
import { setGlobalDateLocale } from '../../Helpers/DateUtils';
import { useBranding } from "../../hooks/useBranding";
import UserService from '../../services/User';
import AuthService from '../../services/auth';

const stackTokens = { childrenGap: 10 };
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },

};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

enum ModalType {
    None = 0,
    Add = 1,
    Edit = 2,
    Delete = 3,
}

const emptyProfile: IUserProfile = {
    Email: "",
    Title: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    TimeZone: "",
    Success: false,
    AdminBilling: false,
    Billing: false
};
function DeleteUserAccount(props: any) {
    const { branding } = useBranding()

    const [profileList, setprofileList] = useState<any[]>([]);
    const [selectedProfile, setSelectedProfile] = useState<IUserProfile>(emptyProfile);
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [modalType, setModalType] = useState<ModalType>(ModalType.None)
    const [showMessageError, setshowMessageError] = useState(false)
    const [message, setMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false)
    const [accountSelected, setAccountSelected] = useState(undefined)
    const [filterOwnerName, setFilterOwnerName] = useState("");

    const [applying, setApplying] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false)
    const [requiredFilterEmail, setrequiredFilterEmail] = useState(false)
    const messageBarSuccessStyles: IMessageBarStyles = {
        root: {
            backgroundColor: "#acffac"
        }
    };

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
                class: 'ms-Icon ms-Icon--Mail'
            }
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },

        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],

        body: {
            width: '27vw',
            height: '7vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },

    });


    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Email',
            fieldName: 'UserName',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true
        },

        {
            key: 'column3',
            name: 'FirstName',
            fieldName: 'FirstName',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,

        },
        {
            key: 'column4',
            name: 'MiddleName ',
            fieldName: 'MiddleName',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,

        },
        {
            key: 'column10',
            name: 'LastName',
            fieldName: 'LastName',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true
        },

        {
            key: 'column9',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onRender: (item: IUserProfile) => {

                return <MenuItems item={item} menuItems={menuItems} />
            },
            isPadded: true
        },
    ]

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete Manage Client Plan.',
            onClick: () => {
                setShowModalDelete(true)
                setModalType(ModalType.Delete)

            },
        },
    ];

    useEffect(() => {
        setGlobalDateLocale(navigator.language)
    }, [])

    const onSelectionChange = () => {
        const selectionCount = selectionProfile.getSelectedCount();

        switch (selectionCount) {
            case 0:
                setSelectedProfile(emptyProfile)
                break;
            case 1:
                var obj = selectionProfile.getSelection()[0]
                var objManage = obj as IUserProfile
                setSelectedProfile(objManage)
                break;
            default:
                setSelectedProfile(emptyProfile)
                break;
        }
    }

    const selectionProfile = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    const getKey = (item: any, index?: number): string => {
        if (item !== undefined)
            return item.UserName!.toString()
        return ""
    }

    const hideDeleteAccountModal = () => {
        setShowModalDelete(false)
        resetForm()
        setshowMessageError(false)
        setShowSuccess(false)
        setMessage("")
    }

    const resetForm = () => {
        setFilterOwnerName("")
    }

    const getErrorMessageEmailFilter = async (value: string): Promise<string> => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length > 0) {
            if (!value.match(mailformat)) {
                setrequiredFilterEmail(false)
                return "Email must be valid.";
            }
            else {
                setrequiredFilterEmail(true)
            }
        }
        return ""
    };

    const getUserProfile = async (profile: string) => {

        Helper.getRegistration({
            email: profile,
            activationGuid: "",
            culture: "",
            firstName: "",
            lastName: "",
            middleName: "",
            timeZone: "",
            title: ""
        }).then((res: any) => {
            var List: any[] = []
            if (res.IsActive) {
                List.push(res)
            }
            setprofileList(List)
            setApplying(false)
        })
    }

    const _onChangeFilter = useConstCallback(
        async (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let value = newValue!
            if (value.length > 0) {
                if (value.match(mailformat)) {
                    if (requiredFilterEmail === false) {

                        setApplying(true)
                        setFilterOwnerName(newValue!)
                        await getUserProfile(newValue!)


                    }
                }
            }
        }
    );

    const deleteUserByAdmin = () => {
        setIsDeleting(true)
        if (!selectedProfile.UserName) {
            setshowMessageError(true)
            setMessage("An error occurred: please select a valid account")
            return
        }
        UserService.DeleteUserbyAdminRequest({ onBehalfUser: selectedProfile.UserName }).then(async (res: any) => {
            if (res.Success) {
                setApplying(false)
                console.log(res)
                setshowMessageError(false)
                setShowSuccess(true)
                setMessage("User " + filterOwnerName + " has been deleted.")
                setFilterOwnerName("")
                setprofileList([])
                setTimeout(function () {
                    hideDeleteAccountModal()
                    setIsDeleting(false)
                }, 3000);
            } else {
                setApplying(false)
                setShowSuccess(false)
                setshowMessageError(true)
                setMessage("An error occurred. Please try again later.")
                setTimeout(function () {
                    hideDeleteAccountModal()
                    setIsDeleting(false)
                }, 3000);
            }
        })
    }

    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <label className="ms-fontSize-18">Delete user account</label><br />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <label className="ms-fontSize-18">Delete user account</label>
                    </div>
                    <br />
                </div>
                : null
            }

            <Stack tokens={stackTokens}>

                <div className="ms-Grid-row" >
                    <br />
                    <div key="col" className="ms-Grid-col ms-sm4">
                        <TextField placeholder="User account" onGetErrorMessage={getErrorMessageEmailFilter} onChange={_onChangeFilter} maxLength={100} title="User account to delete." style={{ paddingTop: "4%" }} />
                    </div>

                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                    {!applying ?
                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", paddingRight: "10px", maxWidth: "100%", height: "40vh" }}>
                            <MarqueeSelection selection={selectionProfile}>
                                <DetailsList
                                    items={profileList}
                                    compact={false}
                                    columns={columns}
                                    getKey={getKey}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    selection={selectionProfile}
                                    selectionMode={SelectionMode.single}
                                    selectionPreservedOnEmptyClick={false}
                                    enterModalSelectionOnTouch={true}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                />
                            </MarqueeSelection>
                        </div>
                        : <Spinner size={SpinnerSize.medium} label="Loading data, please wait..." ariaLive="assertive" />
                    }
                </div>
            </Stack>

            <Modal isOpen={showModalDelete} isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Delete">Delete User Account</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeleteAccountModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div key="row" className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        {showMessageError ? <MessageBar messageBarType={MessageBarType.error}>{message} </MessageBar> : null}
                                        {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                    </div>
                                </div>
                                <div className={classNames.body}>
                                    Are you sure you want to delete  acount {selectedProfile.Email} ?
                                </div>

                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            {!applying ?
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <PrimaryButton disabled={isDeleting} onClick={deleteUserByAdmin} text="Delete" title="Delete Manage Client Plan.">
                                            {isDeleting ? <Spinner size={SpinnerSize.medium} /> : null}
                                        </PrimaryButton>
                                        <DefaultButton disabled={isDeleting} onClick={hideDeleteAccountModal} text="Cancel" title="Close." />
                                    </DialogFooter>
                                </div>
                                : <Spinner size={SpinnerSize.medium} label="Loading data, please wait..." ariaLive="assertive" />}
                        </div>
                        <br />

                    </div>
                </Stack>
            </Modal>

        </>
    );
}
type MenuItemsProps = {
    menuItems: any
    item: IUserProfile
}
const MenuItems = ({ menuItems, item }: MenuItemsProps) => {

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
        menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
        role="button"
        title={"Click to select from available actions."}
        aria-haspopup
        aria-label="Show actions"
        styles={iconListButtonStyles}
        menuProps={menuProps}
    /></div>
}


export default DeleteUserAccount