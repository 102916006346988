import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Sizes} from "../../share/CustomModal";
import {useModal} from "../../hooks/useModal";
import {_styles, detailInfoStyles, detailInfoStylesTitle, nonShrinkingStackItemStyles, Payment} from "./index";
import {DefaultButton, Separator, Spinner, SpinnerSize, Stack, StackItem, Text} from "@fluentui/react";
import PlanService, {IGetPlanByIdRequest, IPlanResponse} from "../../services/Plan";
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import {IconButton} from "@fluentui/react/lib/Button";
import {Carousel} from "react-responsive-carousel";
import ManageClientPlansService, {
    IGetAllManageClientPlansRequest,
    IGetAllManageClientPlansResponse
} from "../../services/ManageClientPlans";
import Helper from "../../Helpers/Helper";
import {useBranding} from "../../hooks/useBranding";
import {FontWeights} from "@fluentui/react/lib/Styling";

type Properties = {
    showModal: boolean
    setShowModal: (status: boolean) => void
}
export const CarruselBillingPlan = ({setShowModal, showModal}: Properties) => {

    const {t} = useTranslation(['common', 'mybillingplan'])
    const {branding} = useBranding()
    const myModal = useModal()
    const [isLoading, setIsLoading] = useState(true)
    const [isLoaded, setIsLoaded] = useState(false)
    const [planList, setPlanList] = useState<IPlanResponse[]>([]);
    const [message, setMessage] = useState("")
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)
    const [currentBillingPlan, setCurrentBillingPlan] = useState<IGetAllManageClientPlansResponse | null>(null)
    const [currentPlan, setCurrentPlan] = useState<IPlanResponse | null>(null)

    useEffect(() => {
        if (showModal) {
            myModal.open()
        }
    }, [showModal])

    /**
     * Close modal
     */
    const closeModal = (reload: boolean = false) => {
        myModal.close()
        setShowModal(reload)
    }

    useEffect(() => {
        if (showModal) {
            init().then()
        }
    }, [showModal])


    async function init() {
        setIsLoading(true)
        setPlanList([])
        setIsLoaded(false)
        setCurrentPlan(null)
        setCurrentBillingPlan(null)
        let promises: any[] = [];

        const request: IGetAllManageClientPlansRequest = {
            ownerName: Helper.getUsername()
        }

        await ManageClientPlansService.GetAllManageClientPlans(request)
            .then((result1) => {
                if (result1.length === 0) {
                    throw new Error(t('message.notFound', {ns: 'common'}))
                } else {
                    setCurrentBillingPlan(result1[0])

                    // plan
                    const request2: IGetPlanByIdRequest = {
                        Type: "",
                        UseCache: true,
                        Id: result1[0].BillingPlanId.toString()
                    }
                    promises.push(
                        PlanService.GetPlanById(request2)
                            .then(async (result) => {
                                if (result.length > 0) {
                                    // Plan Info
                                    setCurrentPlan(result[0])
                                } else {
                                    throw new Error(t('message.notFound', {ns: 'common'}))
                                }
                            })
                            .catch((error: any) => {
                                throw error
                            })
                    )

                    //all plans
                    promises.push(
                        PlanService.GetAllPlan()
                            .then((result2) => {
                                const filtered = result2.filter(v => v.Type?.includes("Normal"))
                                const sorted = filtered.sort((a, b) => a.TransactionAmount < b.TransactionAmount ? -1 : a.TransactionAmount > b.TransactionAmount ? 1 : 0)
                                setPlanList(sorted)
                            })
                            .catch((error: any) => {
                                throw error
                            })
                    )

                    Promise.all(promises)
                        .then(() => {
                            setIsLoaded(true)
                        })
                        .catch((error: any) => {
                            throw error
                        })

                }
            })
            .catch((error: any) => {
                setMessage(error.toString())
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
                setIsLoaded(false)
            })
            .finally(() => {
                setIsLoading(false)
            })


    }

    const getPlanPriceHtml = (item: any) => {
        if (item.Name === "Free") {
            return t("for", {
                ns: "mybillingplan",
                Description: `${item.TransactionLength} ${item.TransactionUnit.trim()}${item.TransactionLength > 1 ? "(s)" : ""}`
            })
        } else if (item.TransactionLength === 1) {
            return t("per", {ns: "mybillingplan", Description: item.TransactionUnit.trim()})
        } else {
            return t("every", {
                ns: "mybillingplan",
                Description: `${item.TransactionLength > 1 ? item.TransactionLength + " " : ""}${item.TransactionUnit.trim()}${item.TransactionLength > 1 ? "(s)" : ""}`
            })
        }
    }

    const OnRenderCell = (item: any, _: number) => {
        return (<>
            <Stack styles={{root: {height: "auto"}}}>

                <Stack styles={{root: {margin: "0px 32px"}}}>
                    <Stack styles={{root: {height: 16}}}>
                        {currentBillingPlan!.BillingPlanId === item.Id &&
                            <>
                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    background: "#f80",
                                    color: "#fff",
                                    textTransform: "uppercase",
                                    zIndex: 99,
                                    right: 40,
                                    padding: 2,
                                    width: "140px"

                                }}>
                                    <Text variant={"small"}
                                          title={t("currentPlan", {ns: "mybillingplan"})}>{t("currentPlan", {ns: "mybillingplan"})}
                                    </Text>
                                </div>
                            </>
                        }
                    </Stack>

                    <Stack tokens={{padding: 4}} styles={{root: {background: "#20bada"}}}>
                        <Text variant={"large"}
                              styles={{root: {color: "#FFFFFF", fontWeight: FontWeights.bold}}}
                              title={item.Name}>
                            {item.Name.toUpperCase()}
                        </Text>
                    </Stack>

                    <Stack tokens={{padding: 4}} styles={{root: {background: "#53cfe9", height: 80}}}>

                        <div
                            style={{
                                position: "absolute",
                                background: "#20bada",
                                top: 64,
                                height: 120,
                                width: 120,
                                left: "calc(50% - 60px)",
                                overflow: "hidden",
                                borderRadius: 60,
                                border: "4px solid #fff",
                                color: "#FFFFFF",
                                paddingTop: 32
                            }}>
                            <Text variant={"mediumPlus"} block styles={{root: {fontWeight: FontWeights.bold}}}>
                                {`${Helper.getCurrencySymbol()} ${item.TransactionAmount >= 1 ? item.TransactionAmount : 0}`}
                            </Text>
                            <Text variant={"medium"} block styles={{root: {padding: "0px 4px"}}}>
                                {getPlanPriceHtml(item)}
                            </Text>
                        </div>
                    </Stack>
                    <Stack styles={{root: {paddingTop: 68}}}>
                        <Text variant={"medium"} block styles={detailInfoStylesTitle(branding.theme)}>
                            {item.Description}
                        </Text>
                        <Text variant={"medium"} block styles={detailInfoStyles(branding.theme)}>
                            {
                                item.MaxApplicationAllowed > -1 ?
                                    t("maxApplicationsAllowed", {
                                        ns: "mybillingplan",
                                        Value: Helper.FormatNumber(item.MaxApplicationAllowed)
                                    })
                                    :
                                    t("maxUnlimitedApplicationsAllowed", {
                                        ns: "mybillingplan"
                                    })
                            }
                        </Text>
                        <Text variant={"medium"} block styles={detailInfoStyles(branding.theme)}>

                            {t("superUsers", {ns: "mybillingplan", Users: Helper.FormatNumber(item.UserLimit)})}
                        </Text>
                        <Text variant={"medium"} block styles={detailInfoStyles(branding.theme)}>
                            {t("workspaceUsers", {
                                ns: "mybillingplan",
                                Users: Helper.FormatNumber(item.WorkspaceOnlyUsers)
                            })}
                        </Text>
                        <Text variant={"medium"} block styles={detailInfoStyles(branding.theme)}>
                            {t("gbStorage", {
                                ns: "mybillingplan",
                                Value: Helper.FormatNumber(item.StorageLimit / 1000000000)
                            })}
                        </Text>
                    </Stack>
                    <Stack styles={{root: {padding: 16}}}>
                        {currentBillingPlan!.BillingPlanId !== item.Id &&
                            <>
                                <Text variant={"medium"} styles={{root: {fontStyle: "italic"}}}>
                                    {t("redirectMessage", {ns: "mybillingplan"})}
                                </Text>
                            </>
                        }
                    </Stack>
                    {currentBillingPlan!.BillingPlanId !== item.Id && item.TransactionAmount > 0 &&
                        <>
                            <Payment
                                setShowModal={closeModal}
                                newPlan={item}
                                currentBillingPlan={currentBillingPlan!}
                                currentPlan={currentPlan!}
                                isDowngrade={item.TransactionAmount >= 1 && item.TransactionAmount < currentPlan!.TransactionAmount}
                            />
                        </>
                    }

                </Stack>

            </Stack>
        </>)
    }

    const SlidesPlans = () => {
        return planList.sort((a, b) => a.TransactionAmount < b.TransactionAmount ? -1 : a.TransactionAmount > b.TransactionAmount ? 1 : 0).map(OnRenderCell)
    }

    return (
        <>
            {/*region MODAL TEMPLATE*/}
            <myModal.Modal
                title={t('billingPlans', {ns: 'mybillingplan'})} titleId="BillingPlans" size={Sizes.absolute480}
                afterClose={() => {
                    closeModal()
                }}
                bodyClassName={_styles.body}
                additionalHeader={undefined}
            >
                <>

                    {/*region MESSAGES*/}
                    <StackItem>
                        {showMessage &&
                            <>
                                <StatusMessage status={statusMessageEnum} hasTimer={false}
                                               truncated={true}
                                               isMultiline={true}
                                               dismiss={true}
                                               setShowMessage={setShowMessage}>
                                    <div>{message}</div>
                                </StatusMessage>
                            </>
                        }
                    </StackItem>
                    {/*endregion*/}

                    {/*region CONTENT*/}
                    <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                           style={{
                               overflow: "hidden",
                               overflowY: "auto",
                               height: "60vh",
                               padding: "16px 16px 24px 16px"
                           }}>


                        {isLoading &&
                            <Stack grow={1} styles={{root: {justifyContent: "center"}}}>
                                <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})}
                                         ariaLive="assertive"/>
                            </Stack>
                        }

                        {!isLoading && isLoaded &&
                            <>

                                <Carousel
                                    preventMovementUntilSwipeScrollTolerance={true}
                                    swipeScrollTolerance={50}
                                    showIndicators={false}
                                    infiniteLoop={true}
                                    selectedItem={planList.sort((a, b) => a.TransactionAmount < b.TransactionAmount ? -1 : a.TransactionAmount > b.TransactionAmount ? 1 : 0).findIndex((element) => element.Id == currentBillingPlan!.BillingPlanId)}
                                    showArrows={true}
                                    showStatus={false}
                                    useKeyboardArrows={true}
                                    autoFocus={true}
                                    renderArrowPrev={(onClickHandler, hasPrev, _) =>
                                        hasPrev && (
                                            <>
                                                <IconButton onClick={onClickHandler}
                                                            iconProps={{iconName: 'ChevronLeft'}}
                                                            allowDisabledFocus
                                                            styles={{
                                                                root: {
                                                                    position: "absolute",
                                                                    top: "calc(50% - 16px)",
                                                                    left: "0",
                                                                    width: "32px",
                                                                    height: "32px",
                                                                    zIndex: 99
                                                                }
                                                            }}
                                                            style={{fontSize: "32px"}}> </IconButton>
                                            </>
                                        )
                                    }
                                    renderArrowNext={(onClickHandler, hasNext, _) =>
                                        hasNext && (
                                            <>
                                                <IconButton onClick={onClickHandler}
                                                            iconProps={{iconName: 'ChevronRight'}}
                                                            allowDisabledFocus
                                                            styles={{
                                                                root: {
                                                                    position: "absolute",
                                                                    top: "calc(50% - 16px)",
                                                                    right: "0",
                                                                    width: "32px",
                                                                    height: "32px",
                                                                    zIndex: 99
                                                                }
                                                            }}
                                                            style={{fontSize: "32px"}}> </IconButton>
                                            </>
                                        )
                                    }
                                >
                                    {SlidesPlans()}
                                </Carousel>

                            </>
                        }

                    </Stack>
                    {/*endregion*/}

                    {/*region FOOTER*/}
                    <Separator styles={{root: {marginBottom: -8}}}></Separator>
                    <Stack horizontal style={{overflow: "hidden"}} disableShrink
                           styles={nonShrinkingStackItemStyles}>
                        <StackItem grow={1}>&nbsp;</StackItem>
                        <StackItem>
                            <DefaultButton onClick={() => {
                                closeModal()
                            }} style={{marginLeft: 8}}
                                           text={t('Common.Button.Close', {ns: 'common'})}
                                           title={t('Common.Button.Close', {ns: 'common'})}/>
                        </StackItem>
                    </Stack>
                    {/*endregion*/}


                </>
            </myModal.Modal>
            {/*endregion*/}
        </>
    )
}

