import React, { useEffect, useState } from 'react'
import { IDocumentLibrary } from '../../../interfaces/IDocumentAssembly'
import { CheckboxVisibility, DefaultButton, DetailsList, DetailsListLayoutMode, IButtonStyles, IColumn, IContextualMenuItem, IContextualMenuProps, IconButton, Spinner, SpinnerSize, Stack, mergeStyleSets } from '@fluentui/react'
import { useModal } from '../../../hooks/useModal'
import { useDialog } from '../../../hooks/useDialog'
import { useTranslation } from 'react-i18next'
import { Sizes } from '../../../share/CustomModal'
import DocumentAssemblyService from '../../../services/assembly'
import DocumentLibraryForm from './DocumentLibraryForm'
import { IStatusMessage } from '../../../interfaces/IApp'
import StatusMessage from '../../../share/StatusMessage'
import { useDownload } from '../../../hooks/useDownload'
import { getMimeTypeFromExt } from '../../../Helpers/Utils'

type DocumentLibraryProps = {
    templateId: number
}
type OptionKeys = 'download' | 'edit' | 'enable' | 'up' | 'down' | 'delete'
const DocumentLibrary = ({ templateId }: DocumentLibraryProps) => {

    const [documentLibrary, setDocumentLibrary] = useState<IDocumentLibrary[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState<IStatusMessage | undefined>(undefined)

    const deleteDialog = useDialog()
    const downloadDialog = useDialog()
    const documentForm = useModal()
    const { downloadFile } = useDownload()

    const { t } = useTranslation('assembly')
    useEffect(() => {
        let isMounted = true
        if (isLoading) {
            DocumentAssemblyService.getDocumentLibrary(templateId).then(({ data }: any) => {
                if (isMounted) {
                    setDocumentLibrary(data)
                    setIsLoading(false)
                }
            })
        }
        return () => {
            isMounted = false
        }
    }, [isLoading, templateId])

    const handleDelete = (item?: IDocumentLibrary) => {
        if (item) {
            DocumentAssemblyService.deleteDocumentLibrary(templateId, item.TemplateId).then(({ data }: any) => {
                const callback = () => {
                    setIsLoading(true)
                    if (data.Success === false) {
                        setMessage({ Type: 'error', HasTimeOut: false, Message: <>Error when delete file <strong>{item?.FileName}</strong>: {data.ExceptionMessage}</> })
                        return
                    }
                    setMessage({ Type: 'success', Message: <>Document <strong>{item?.FileName}</strong> deleted successfully</> })
                }
                deleteDialog.close(callback)
            })
        }
    }
    const handleDownload = (item?: IDocumentLibrary) => {
        DocumentAssemblyService.dowloadTemplate(templateId, item?.TemplateId ?? -1).then(({ data }: any) => {
            const callback = () => {
                if (data.Success === false) {
                    setMessage({ Type: 'error', Message: <>Error when dowloading file <strong>{item?.FileName}</strong>: {data.ExceptionMessage}</> })
                    return
                }

                setMessage({ Type: 'success', Message: <>Document <strong>{item?.FileName}</strong> downloaded successfully</> })
                downloadFile(data.TemplateContent, data.FileName, getMimeTypeFromExt('docx'))
                setIsLoading(true)
            }
            downloadDialog.close(callback)
        })
    }
    const handleMenu = (option: OptionKeys, item?: IDocumentLibrary) => {
        const confirm = () => () => handleDelete(item)
        const downloadConfirm = () => () => handleDownload(item)
        switch (option) {
            case 'edit':
                documentForm.open(item)
                break
            case 'download':
                downloadDialog.open({ confirm: downloadConfirm })
                break
            case 'delete':
                if (item)
                    deleteDialog.open({ confirm })
                break
        }
    }

    const wrappedColumns = () => {
        return getColumns(handleMenu)
    }
    const handleClose = (callback: () => void, loading: boolean, message?: IStatusMessage) => {
        callback?.()
        if (loading) {
            setIsLoading(true)
            setMessage(message)
        }
    }
    const handleShowMessage = () => {
        setMessage(undefined)
    }
    return (
        <>
            <Stack>
                <label className="ms-fontSize-18">Document Library</label>
                <div>
                    <p>This functionality provides a collection of documents that can be appended or prepended based on rules defined within the Document Assembly feature.
                        With the ability to add and update tags within the library, users can easily insert content into documents based on rules.</p>
                </div>
            </Stack>
            {message ? <StatusMessage dismiss={message.HasTimeOut === false ? true : false} truncated setShowMessage={handleShowMessage} status={message.Type} hasTimer={message.HasTimeOut}>
                <>{message.Message}</>
            </StatusMessage> : null}
            {isLoading ? <Spinner size={SpinnerSize.medium} label="Loading..." ariaLive="assertive" /> :
                <Stack>
                    <div className={contentStyles.section}>
                        <h3 className={contentStyles.sectionHeader}>
                            <span>Document List</span>
                            <DefaultButton key="openForm"
                                onClick={() => { documentForm.open() }}
                                title='Add'
                                text={t('settings.addBtn')} />
                        </h3>
                        <div className="scrollVisible" style={{
                            overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "47vh"
                        }}>
                            <DetailsList items={documentLibrary} columns={wrappedColumns()} layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible
                                selectionPreservedOnEmptyClick={false}
                                enterModalSelectionOnTouch
                                checkboxVisibility={CheckboxVisibility.hidden} />
                        </div>
                    </div>
                </Stack>
            }

            <documentForm.Modal title='Upload Document' titleId='' size={Sizes.xs}>
                <DocumentLibraryForm type={'create'}
                    close={(loading, message) => { handleClose(documentForm.close, loading, message) }}
                    templateId={templateId} item={documentForm.data} />
            </documentForm.Modal>
            <deleteDialog.Dialog title={t('title.modalDelete', { ns: 'attachmentsComponent' })} titleId='deleteModal' actionText={t('Common.Button.Delete', { ns: 'common' })}>
                <>Are you sure you want to delete the <strong>Document</strong>?</>
            </deleteDialog.Dialog>
            <downloadDialog.Dialog title="Download Document" titleId='deleteModal' actionText="Download">
                <>Are you sure you want to download the <strong>Document</strong>?</>
            </downloadDialog.Dialog>
        </>
    )
}
const getColumns = (handleMenu: (option: OptionKeys, item?: IDocumentLibrary) => void): IColumn[] => {
    return [
        {
            key: 'FileName',
            name: 'File Name',
            ariaLabel: 'File Name',
            fieldName: 'FileName',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'OwnerUserName',
            name: 'Owner',
            ariaLabel: 'Owner',
            fieldName: 'OwnerUserName',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'action',
            name: 'Actions',
            minWidth: 55,
            maxWidth: 55,
            onRender: (item) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}>
                    <MenuItems item={item} handleMenu={handleMenu} />
                </div>
            }
        },
    ]
}
const iconButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } }
type MenuProps = { item: IDocumentLibrary, handleMenu: (option: OptionKeys, item?: IDocumentLibrary) => void }
const MenuItems = ({ item, handleMenu }: MenuProps) => {

    const items: IContextualMenuItem[] = [
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit attachment.',
            onClick: () => {
                handleMenu('edit', item)
            },
        },
        {
            key: 'download',
            text: 'Download',
            title: 'Download document.',
            onClick: () => {
                handleMenu('download', item)
            },
        },
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete attachment.',
            onClick: () => {
                handleMenu('delete', item)
            },
        },
    ]
    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: items
    };
    return (
        <>
            <IconButton
                menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424', textAlign: 'right' } }}
                role="button"
                aria-haspopup
                aria-label="Show actions"
                styles={iconButtonStyles}
                menuProps={menuProps}
                title={"Click to select from available actions."}
            />
        </>

    )
}
const contentStyles = mergeStyleSets({
    sectionHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    section: {
        padding: '1rem 0'
    }
})

export default DocumentLibrary