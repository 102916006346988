import {DropdownMenuItemType, IChoiceGroupOption, IDropdownOption} from "@fluentui/react";
import React from "react";
import {ToolbarConfig} from "react-rte";
import {ILayout} from "../CustomTemplates/CustomTemplate";
import i18n from "../../i18n/config"
import {InputType} from "../CustomTemplates/Validations";

// region INTERFACES
export enum EmailType {
    DOCUMENTCREATION = 0,
    DOCUMENTUPDATE = 1,
    DOCUMENTDELETE = 2,
    DOCUMENTSHARE = 3,
    APPLICATIONEDIT = 4,
    APPLICATIONDELETE = 5,
    APPLICATIONREMOVE = 6,
    APPLICATIONSHARE = 7,
    WIZARDSUBMISSIONCREATE = 8,
    WIZARDSUBMISSIONUPDATE = 9
}

export enum ToType {
    DEFAULT = 0,
    CREATOR = 1,
    CUSTOM = 2
}

export const optionsToType = (): IChoiceGroupOption[] => {
    const {t} = i18n
    return [
        {key: ToType.DEFAULT.toString(), text: t("optionsRecipientDefault", {ns: "emailtemplate"})},
        {key: ToType.CREATOR.toString(), text: t("optionsRecipientCreator", {ns: "emailtemplate"})},
        {key: ToType.CUSTOM.toString(), text: t("optionsRecipientCustom", {ns: "emailtemplate"})}
    ]
}

export interface IEmailTemplates {
    DocumentCreation: boolean | undefined
    DocumentUpdate: boolean | undefined
    DocumentDelete: boolean | undefined
    DocumentShare: boolean | undefined
    ApplicationEdit: boolean | undefined
    ApplicationDelete: boolean | undefined
    ApplicationRemove: boolean | undefined
    ApplicationShare: boolean | undefined
    WizardSubmissionCreate: boolean | undefined
    WizardSubmissionUpdate: boolean | undefined
}

export interface IGetTemplateDefinitionRequest {
    TemplateId: string
    EmailType: EmailType
}

export interface IHttpClientResponse {
    messageError: string
    result: string
    bag: string
    success: boolean
}

export interface ICreateUpdateTemplateDefinitionRequest extends ICreateUpdateTemplateDefinitionResponse {
    TemplateId: string
    Status: boolean
}

export interface ICreateUpdateTemplateDefinitionResponse {
    Description: string
    Name: string
    Subject: string
    Body: string
    ToType: ToType
    To?: string
    EmailType: EmailType
}

export interface IDeleteTemplateDefinitionRequest {
    TemplateId: string
    EmailType: EmailType
}

export interface IGetDocumentOnlyTemplateIDBasic {
    TemplateId: string,
    IgnoreDocument: boolean
}

export interface IUpdateTemplateStatusRequest {
    TemplateId: string
    EmailType: EmailType
    Status: boolean
}

export interface IGetTemplateStatusRequest {
    TemplateId: string
}

interface ITemplate {
    EmailType: EmailType
    Status: boolean
}

export interface IGetTemplateStatusResponse {
    Templates: ITemplate[]
}


// endregion

/**
 * Function onRenderOption for DropdownList of customControls
 * @param option
 */
export const onRenderOption = (option?: IDropdownOption): JSX.Element => {
    return (
        <span>{option!.text}</span>
    )
}

/**
 * Email Body RTE Toolbar config
 */
export const toolbarConfigEmailBody: ToolbarConfig = {
    display: ["INLINE_STYLE_BUTTONS", 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'},
        {label: 'Strikethrough', style: 'STRIKETHROUGH'},
        {label: 'blockquote', style: 'blockquote'},
    ],
    BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
    ],
    BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
    ],
}


/**
 * Email Body RTE Toolbar config
 */
export const toolbarConfigEmailSubject: ToolbarConfig = {
    BLOCK_TYPE_BUTTONS: [], BLOCK_TYPE_DROPDOWN: [], INLINE_STYLE_BUTTONS: [],
    display: []
}

/**
 * Set default options for the two Content Control lists
 * @param layout
 * @param _emailType
 */
export const setDefaultOptions = (layout: ILayout[], _emailType: EmailType): IDropdownOption[] => {
    const {t} = i18n

    const contentControls: IDropdownOption[] = []

    contentControls.push({
        key: `headerEmailTemplate`,
        text: t('optionsTemplateInformation', {ns: 'emailtemplate'}),
        itemType: DropdownMenuItemType.Header
    })

    // contentControls.push({
    //     key: `[#EmailTemplateName]`,
    //     text: t('text.optionsName', {ns: 'common', Name: "[#EmailTemplateName]"}),
    // })
    // contentControls.push({
    //     key: `[#EmailTemplateDescription]`,
    //     text: t('text.optionsDescription', {ns: 'common', Name: "[#EmailTemplateDescription]"}),
    // })

    contentControls.push({
        key: `[#Datetime]`,
        text: t('text.optionsDateTime', {ns: 'common', Name: "[#Datetime]"}),
    })


    if (_emailType === EmailType.DOCUMENTUPDATE || _emailType === EmailType.DOCUMENTSHARE || _emailType === EmailType.DOCUMENTCREATION || _emailType === EmailType.WIZARDSUBMISSIONUPDATE || _emailType === EmailType.WIZARDSUBMISSIONCREATE) {

        contentControls.push({
            key: `headerUser`,
            text: t('text.optionsUserInformation', {ns: 'common'}),
            itemType: DropdownMenuItemType.Header
        })

        //if(_emailType !== EmailType.WIZARDSUBMISSIONUPDATE && _emailType !== EmailType.WIZARDSUBMISSIONCREATE) {
            contentControls.push({
                key: `[#RecordLink]`,
                text: t('text.optionsRecordLink', {ns: 'common', Name: "[#RecordLink]"})
            })
        //}

        contentControls.push({
            key: `[#Description]`,
            text: t('text.optionsRecordDescription', {ns: 'common', Name: "[#Description]"})
        })
        contentControls.push({
            key: `[#Versions]`,
            text: t('text.optionsRecordVersion', {ns: 'common', Name: "[#Versions]"})
        })
        contentControls.push({
            key: `[#FullName]`,
            text: t('text.optionsRecordCreatorFullname', {ns: 'common', Name: "[#FullName]"})
        })
        contentControls.push({
            key: `[#UserName]`,
            text: t('text.optionsRecordCreatorUsername', {ns: 'common', Name: "[#UserName]"})
        })
        contentControls.push({
            key: `[#ApplicationName]`,
            text: t('text.optionsApplicationName', {ns: 'common', Name: "[#ApplicationName]"})
        })
        contentControls.push({
            key: `[#ApplicationCategory]`,
            text: t('text.optionsApplicationCategory', {ns: 'common', Name: "[#ApplicationCategory]"})
        })
        contentControls.push({
            key: `[#AudienceName]`,
            text: t('text.optionsApplicationAudiencename', {ns: 'common', Name: "[#AudienceName]"})
        })

        if (layout.length !== 0) {
            layout.sort((a, b) => a.Label.localeCompare(b.Label))

            contentControls.push({
                key: `headerCC`,
                text: t('text.optionsTemplateContentControls', {ns: 'common'}),
                itemType: DropdownMenuItemType.Header
            })

            layout.map((item) => {
                if (item.Type !== InputType.GridList && item.Type !== InputType.Paragraph && item.Type !== InputType.SectionHeader && item.Type !== InputType.LineBreak && item.Type !== InputType.HelpLink) {
                    contentControls.push({text: `${item.Label} - [$${item.Id}]`, key: `[$${item.Id}]`});
                }
            })
        }
    } else {

        contentControls.push({
            key: `ApplicationInformation`,
            text: t('text.optionsApplicationInformation', {ns: 'common'}),
            itemType: DropdownMenuItemType.Header
        })
        contentControls.push({
            key: `[#ApplicationDisplayName]`,
            text: t('text.optionsApplicationDisplayName', {ns: 'common', Name: "[#ApplicationName]"})
        })

        contentControls.push({
            key: `[#ApplicationCategoryName]`,
            text: t('text.optionsApplicationCategoryName', {ns: 'common', Name: "[#ApplicationCategory]"})
        })
        contentControls.push({
            key: `[#ApplicationAudience]`,
            text: t('text.optionsApplicationAudience', {ns: 'common', Name: "[#AudienceName]"})
        })
    }
    return contentControls
}

export {}