import { IScrollablePaneStyles, ScrollablePane, TextField } from '@fluentui/react';
import { useConstCallback } from '@uifabric/react-hooks';
import { DefaultButton, IButtonStyles, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { CheckboxVisibility, DetailsList, IColumn } from '@fluentui/react/lib/DetailsList';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { Stack } from '@fluentui/react/lib/Stack';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, { useEffect, useState } from 'react';
import Helper, { DocumentOnlyCategoryRequest, ICategoriesTemplate, IRequestGeneral } from '../../Helpers/Helper';
import ReactDOM from 'react-dom';
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";

const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const stackTokens = { childrenGap: 10 };
const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

function EditCategories(props: any) {
    const { branding } = useBranding()
    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
                class: 'ms-Icon ms-Icon--Mail'
            }
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '25vw',

            flex: '4 4 auto',
            padding: '0 24px 15px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyEdit: {
            width: '50vw',
            height: '60vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        pane: {
            maxWidth: "100%",
            borderr: '0 10px  0 0 solid ' + theme.palette.neutralLight,
        },
        contentCategories: {
            height: '55vh',
            position: 'relative',
            backgroundColor: 'white',
        },
    });
    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };
    
    const getCategories = async () => {

        const req: DocumentOnlyCategoryRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Site: await Helper.getSite(),
            OwnerOnlyList: true
        }

        await Helper.getDocumentOnlyCategory(req).then(data => {
            setCategories(data);
            let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneCategorieslist")) as Element
            if (element !== null) { element.children[1].classList.add("scrollVisible") }
        })
    }


    useEffect(() => {

        if (props.fetchCategories) {
            getCategories().catch(e => { console.log(e) })        }

    }, [props.fetchCategories])

    const [categories, setCategories] = useState<string[]>([]);
    const [openRename, setOpenRename] = useState(false);
    const [renameCategory, setRenameCategory] = useState("");
    const [categoryName, setCategoryName] = useState("")
    const [disableRename, setDisableRename] = useState(true)
    const menuItems = (item: string): IContextualMenuItem[] => {
       return [
            {
                key: 'edit',
                text: 'Edit',
                title: "Edit category.",
                onClick: () => {
                    setOpenRename(true)
                    setRenameCategory(item);
                },
            },
        ]
    }
    const menuProps = (item: string): IContextualMenuProps => {
        return {
            shouldFocusOnMount: true,
            items: menuItems(item),
        }
    };
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Name',
            onRender: (item: string) => {
                return <span>{item}</span>;
            },
            minWidth: 200,
            isRowHeader: true,
            isResizable: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column2',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onRender: (item: string) => {
                return <div style={{ width:'40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color:'#242424' } }}
                    role="button"
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={menuProps(item)}
                    title="Click to select from available actions."
                /></div>
            },
            isPadded: true
        },
    ]

    const onRenameCategory = async () => {

        setDisableRename(true)

        const valueFrom = renameCategory
        const valueTo = categoryName
        let site=await Helper.getSite()!
        const data = {
            ApiKey: "-1",
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            UserName: Helper.getUsername(),
            Path: Helper.getSiteSelected(),
            Autoprovision: true,
            CategoryValueFrom: valueFrom,
            CategoryValueTo: valueTo
        };

       const result = await HttpRequest.postWithSession('RenameCategory', data).then((response:any) => {
           if (response.data.Success) {
               setOpenRename(false)
               getCategories().catch(e => { console.log(e) })
               setCategoryName("")
               props.reloadApp(site)
           }
           else {
               console.log(response.data.ExceptionMessage)
           }
       })
       
    }

    const checkRequiredfields = () => {
        if (categoryName !== "") {
            setDisableRename(false)
        } else {
            setDisableRename(true)
        }
    }

    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <label className="ms-fontSize-18"  >My Categories</label><br />
                        <div>Use this view to change your Category [section] names.</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <label className="ms-fontSize-18">My Categories</label>
                    </div>
                </div>
                : null
            }
            {/* <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", paddingRight: "10px", maxWidth: "100%", height: "40vh" }}> */}
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">                  
                    <div className={classNames.contentCategories}>
                        <ScrollablePane styles={scrollablePaneStyles} id="ScrollPaneCategorieslist" key="ScrollPaneCategorieslist" >
                        <DetailsList 
                            items={categories}
                            columns={columns}
                            checkboxVisibility={CheckboxVisibility.hidden}

                        />
                        </ScrollablePane>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={openRename}
                isBlocking={true}
                allowTouchBodyScroll={false}
            >
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Rename">Rename '{renameCategory}' Category</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={() => setOpenRename(false)}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.body}>
                                    <label>All applications linked to your audience membership with the category name will be moved to the new category name.</label><br />
                                    <TextField label="Category Name"
                                        required
                                        onChange={(_, v) => { setCategoryName(v!); checkRequiredfields() }}
                                        onBlur={() => {checkRequiredfields()}}
                                        maxLength={30}
                                        title="Enter new category name."
                                        value={categoryName} />
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row" >
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={onRenameCategory} text="Rename" disabled={disableRename} title="Rename application category."/>
                                    <DefaultButton onClick={() => setOpenRename(false)} text="Cancel" title="Close." />
                                </DialogFooter>
                                <br/>
                            </div>
                        </div>
                    </div>
                </Stack>
            </Modal>
        </>
    )
}

export default EditCategories