import { ConstrainMode, DetailsList, DetailsListLayoutMode, IconButton, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDragDropEvents, IDropdownOption, IRenderFunction, ScrollablePane, ScrollbarVisibility, SelectionMode, Sticky, StickyPositionType, TooltipHost } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { RHFDropDownListSingleSelectControl } from '../../RHFControls/RHFDropDownListSingleSelectControl';
import { RHFTextBoxControl } from '../../RHFControls/RHFTextBoxControl';
import { Const_UrlParam } from '../Models/Consts';
import { DocumentTypeFormParamList, ParamValueType } from '../Models/Enums';

export interface UrlParamsListProps {
    isConfigurationTemplateReadMode?: boolean;
}

function UrlParamsList(props: UrlParamsListProps) {
    const { control, setValue, getValues, trigger } = useFormContext();
    const [updateParamValueSourceColumn, setUpdateParamValueSourceColumn] = useState<boolean>(false)
    const urlParamsList = useFieldArray(
        {
            control,
            name: "urlParamsList"
        }
    );

    useEffect(() => {

    }, [urlParamsList.fields, updateParamValueSourceColumn])

    const getDragDropEvents = () => {
        return {
            canDrop: (dropContext: any, dragContext?: any) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                if (draggedItem) {
                    insertBeforeItem(item);
                }
            },
            onDragStart: (
                item?: any,
                itemIndex?: number,
                selectedItems?: any[],
                event?: MouseEvent
            ) => {
                if (typeof item.startIndex === "undefined") {
                    // skip group item
                    draggedItem = item;
                    draggedIndex = itemIndex!;
                }
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                draggedItem = undefined;
                draggedIndex = -1;
            }
        };
    };

    const dragDropEvents: IDragDropEvents = getDragDropEvents();
    let draggedItem: any | undefined;
    let draggedIndex: number = -1;

    const insertBeforeItem = (item: any): void => {
        urlParamsList.move(draggedIndex, urlParamsList.fields.indexOf(item))
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
            <TooltipHost {...tooltipHostProps} />
        );
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    const resetParamValueSource = (id: any) => {
        setValue(id, "")
    }

    return (
        <div style={{
            height: '300px',
            position: 'relative',
            backgroundColor: 'white',
        }} >
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.unconstrained}
                    compact={true}
                    items={urlParamsList.fields.map(item => item)}
                    columns={[
                        {
                            key: 'paramName',
                            name: 'Key',
                            fieldName: 'ParamName',
                            minWidth: 100,
                            maxWidth: 200,
                            isResizable: false,
                            onRender: (item, index, column) => {
                                return <RHFTextBoxControl
                                    key={item.id}
                                    id={`urlParamsList.${index!}.ParamName`}
                                    disabled={props.isConfigurationTemplateReadMode ? true : false}
                                    title="Enter form param ParamName."
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                    defaultValue={""}
                                    onCallback={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                        if (index === urlParamsList.fields.length - 1 && newValue.length > 0 && getValues("urlParamsList")[index].ParamValueType.key !== undefined && getValues("urlParamsList")[index].ParamValueSource.length >= 0) {
                                            urlParamsList.append(Const_UrlParam)
                                        }
                                    }}
                                />
                            }
                        },
                        {
                            key: 'paramValueType',
                            name: 'Value Type',
                            fieldName: 'ParamValueType',
                            minWidth: 100,
                            maxWidth: 200,
                            isResizable: false,
                            onRender: (item, index, column) => {
                                return <RHFDropDownListSingleSelectControl
                                    id={`urlParamsList.${index}.ParamValueType`}
                                    options={Object.entries(ParamValueType).filter(item => item[0] !== ParamValueType.Document && item[0] !== ParamValueType.Payload && item[0] !== ParamValueType.File).map(item => {
                                        return { text: item[0], key: item[1] }
                                    })}
                                    disabled={props.isConfigurationTemplateReadMode ? true : false}
                                    title="Select param type."
                                    control={control}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                    onCallback={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, dropdownIndex?: number | undefined) => {
                                        if (index === urlParamsList.fields.length - 1 && getValues("urlParamsList")[index].ParamName.length > 0 && option !== undefined && getValues("urlParamsList")[index].ParamValueSource.length >= 0) {
                                            urlParamsList.append(Const_UrlParam)
                                        }
                                        resetParamValueSource(`urlParamsList.${index}.ParamValueSource`)
                                        setUpdateParamValueSourceColumn(!updateParamValueSourceColumn)
                                    }}
                                />
                            }
                        },
                        {
                            key: 'paramValueSource',
                            name: 'Value',
                            fieldName: 'ParamValueSource',
                            minWidth: 100,
                            maxWidth: 200,
                            isResizable: false,
                            onRender: (item, index, column) => {
                                const id: any = `urlParamsList.${index}.ParamValueType`
                                if (getValues(id) !== "" && (getValues(id).key === ParamValueType.CC || getValues(id).key === ParamValueType.Fixed)) {
                                    return <RHFTextBoxControl
                                        key={item.id}
                                        id={`urlParamsList.${index!}.ParamValueSource`}
                                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                                        title="Enter form param value."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                        defaultValue={""}
                                        onCallback={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => {
                                            if (index === urlParamsList.fields.length - 1 && getValues("urlParamsList")[index].ParamName.length > 0 && getValues("urlParamsList")[index].ParamValueType.key !== undefined && newValue.length >= 0) {
                                                urlParamsList.append(Const_UrlParam)
                                            }
                                        }}
                                    />
                                } else if (getValues(id) !== "" && getValues(id).key === ParamValueType.Document) {
                                    return <RHFDropDownListSingleSelectControl
                                        id={`urlParamsList.${index}.ParamValueSource`}
                                        options={Object.entries(DocumentTypeFormParamList).filter(item => item[0]).map(item => {
                                            return { text: item[0], key: item[1] }
                                        })}
                                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                                        title="Select param value."
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        trigger={trigger}
                                    />
                                } else {
                                    return null
                                }
                            }
                        },
                        {
                            key: 'actions',
                            name: 'Actions',
                            onRender: (item?: any, index?: number) => {
                                return <IconButton disabled={props.isConfigurationTemplateReadMode} onClick={() => {
                                    urlParamsList.remove(index!)
                                    if ((index === 0 && urlParamsList.fields.length - 1 === 0) || index === urlParamsList.fields.length - 1) {
                                        urlParamsList.append(Const_UrlParam)
                                    }
                                }} iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" />
                            },
                            minWidth: 100,
                            maxWidth: 100,
                            isResizable: false
                        }
                    ]}
                    selectionMode={SelectionMode.none}
                    dragDropEvents={dragDropEvents}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                />
            </ScrollablePane>
        </div>
    )
}

export default UrlParamsList