import React, { useCallback, useEffect, useState } from 'react';
import { CommandButton, DefaultButton, FontWeights, getTheme, IChoiceGroupOption, Icon, IconButton, IContextualMenuItem, IIconProps, IIconStyles, mergeStyleSets, Modal, PrimaryButton, Separator, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useConstCallback, useBoolean, useId } from '@uifabric/react-hooks';
import Helper, {
    IAudienceRequest,
    IBaseRequest,
    IDocumentOnlyAudience,
    IDocumentOnlyTemplate,
    IDocumentRequest,
    RegistrationStatus
} from '../../Helpers/Helper';
import '../../web.css';
import { CustomForm } from '../CustomTemplates/CustomForm/CustomForm';
import PreviewScreen from '../Preview/PreviewScreen';
import { IPropertyBag } from '../DataPanel/DataPanel';
import { ITemplates } from '../CustomTemplates/UploadTemplate';
import { isMobile } from 'react-device-detect';
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog"
import { useTranslation } from "react-i18next";
import useAuth from '../../hooks/useAuth';
import { getTokenClaims } from '../../Helpers/Utils';
import { useBranding } from '../../hooks/useBranding';
import { useModal } from '../../hooks/useModal';
import QueueModal from '../QueueModal/QueueModal';
import { useSignalR } from '../../hooks/useSignalR';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useDocument } from '../../hooks/useDocument';
import AuthService from '../../services/auth';
import useAppIdle from '../../hooks/useAppIdle';
import useIdle from '../../hooks/useIdle';
import UserService, { IGetuserprofileRequest } from "../../services/User";
import HttpRequest from "../../services/HttpRequest";
import { getIsApplication } from './FormDocumentLink.data';
import { useSSO } from '../../hooks/useSSO';
import KeepAliveService from '../../services/KeepAlive';
const contactIcon: IIconProps = {
    iconName: 'Contact',
    style: { color: "white" },
};
const chevronIcon: IIconProps = { style: { color: "white" } };

const theme = getTheme();
export interface IDataLink {
    User: string
    DocumentId: string
    TargetUser: string
    TemplateId: string
    allowDocx: boolean
    ownerUser: string
    TemplateVersion: string
    PrimaryId: string
    IsSubform: boolean
    ARules: string
}
var datajson: IDataLink = {
    User: "",
    DocumentId: "",
    TargetUser: "",
    TemplateId: "",
    allowDocx: false,
    ownerUser: "",
    TemplateVersion: "",
    PrimaryId: "-1",
    IsSubform: false,
    ARules: ''
}
var path: string = "";

var jsonBag: IPropertyBag = {
    Clon: "",
    Culture: "",
    CultureName: "",
    Released: false,
    emailMoniker: "",
    AllowDocX: false,
    AllowSendMail: false,
    DocumentCreation: false,
    DocumentUpdate: false,
    DocumentDelete: false,
    DocumentShare: false,
    TemplateEdit: false,
    TemplateShare: false,
    TemplateDelete: false,
    DefaultDescription: "",
    attachment: { enable: false, maxSize: 1, attachments: [] },
    SupressPreview: false,
    DocumentDelivery: "None",
    RedirectURL: ""
}
let templateLink = false
const sites: IChoiceGroupOption[] = []

const companyIconStyle: IIconStyles = {
    root: {
        fontWeight: "100",
        fontSize: '55px',
        height: '50px',
        width: '50px',
        color: 'white',
        paddingTop: "6px",
    },
};

const iconButtonStyles = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },

};

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const dialogStyles = { main: { maxWidth: 450 } };

function DocumentLink(props: any) {
    const { authCode } = props
    const { isAuthenticated, user, isAuthLoaded, loginWithRedirect, logoutKimDocument, forceAuthenticated } = useAuth();
    const { isIdle } = useIdle({ onIdle: logoutKimDocument, idleTime: 30 })
    const { getBrandingInformation, getAppSettings, branding } = useBranding()
    const [isAnonymous, setIsAnonymous] = useState(false)
    const [isApply, setisApply] = React.useState(true);
    const [showCustomForm, setShowCustomForm] = useState(true)
    const [siteSelected, setSiteSelected] = useState("")
    const [dataJsonLink, setData] = useState<IDataLink>()
    const [siteOptions, setSiteOptions] = useState(sites)
    const [templateName, setTemplateName] = useState("")
    const [description, setDescription] = useState("")
    const [modalId, setModalId] = useState(-1)
    const [modalTitle, setModalTitle] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [closeMessage, setCloseMessage] = useState("")

    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const [spinnerText, setSpinnerText] = useState("")
    const [showPreview, setShowPreview] = useState(false)
    const [previewURL, setPreviewURL] = useState("")
    const [previewMime, setPreviewMime] = useState("")
    const [culture, setCulture] = useState("")

    const [sessionKey, setSessionKey] = useState("")
    const [accountId, setAccountId] = useState("")
    const [dataCustom, setdataCustom] = useState("")
    const [propertyBag, setPropertyBag] = useState<IPropertyBag>()
    const [documentdescription, setDocumentDescription] = useState("")
    const [id, setId] = useState("")
    const [isCheckedOut, setIsCheckedOut] = useState(false)
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const [isprimaryDocumentIdForSecondary, setIsprimaryDocumentIdForSecondary] = useState(false)
    const [displayName, setDisplayName] = useState("")
    const [hideSubscriptionDialog, setHideSubscriptionDialog] = useState(true);
    const [subscriptionMessage, setSubscriptionMessage] = useState("")
    const [isDraft, setIsDraft] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [dataAudienceTargetUser, seDataAudienceTargetUser] = useState<IDocumentOnlyAudience>()
    const [onBehalf, setOnBehalf] = useState("")
    const [draftId, setDraftId] = useState<number>(-1)
    const [audienceName, setAudienceName] = useState("")
    const [templateCategory, setTemplateCategory] = useState("")
    const [audienceMasterId, setAudienceMasterId] = useState<number>(0)
    const [isAppLinkDraft, setIsAppLinkDraft] = useState(false)
    const [allowDrafts, setAllowDrafts] = useState(true)
    const [endUserName, setEndUserName] = useState<string | null>("")
    const [isRecordUpdate, setIsRecordUpdate] = useState(false);
    const [isRecordError, setIsRecordError] = useState(false);
    const [isAccessCodeValid, setIsAccessCodeValid] = useState(props.isAccessCodeValid)
    const [profileUserName, setProfileUserName] = useState("")
    const [waitToLoadProfile, setWaitToLoadProfile] = useState(false);
    const { connection, setConnection } = useSignalR()
    const QueueCustomModal = useModal()
    const { profile, setProfile } = useDocument()
    const { isValid: isSSOValid, } = useSSO()
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme?.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 500,
        },
        containerSaveModal: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: isMobile ? null : '30vw',
            height: '20vh',
        },
        body: {
            width: '80vw',
            height: '45vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        headerLogo: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
    });

    const dialogSubscriptionContentProps = {
        type: DialogType.largeHeader,
        title: sessionStorage.getItem("entityId") !== 'Not In Teams' ? "Information" : 'Subscription',
        subText: subscriptionMessage,
    };

    const { t, i18n } = useTranslation(['common', 'recordFormLink']);

    const dialogContentProps = {
        type: DialogType.normal,
        title: t('title.documentCheckout', { ns: 'recordFormLink' }),
        closeButtonAriaLabel: t('Common.Button.Close', { ns: 'common' }),
        subText: t('text.documentCheckout', { ns: 'recordFormLink', Name: documentdescription }),
    };
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
            className: "DialogBackgoundColor"
        }),
        [labelId, subTextId],
    );
    let dataTemplate: ITemplates = {
        AccountId: 0,
        AllowCheckInOut: false,
        AllowEsign: false,
        Category: "",
        Description: "",
        DisplayName: "",
        DisplayNameToolTip: "",
        DocumentOnlyAudienceId: "",
        FileName: "",
        Id: 0,
        PropertyBag: propertyBag!,
        TemplateContent: "",
        TemplateId: "",
        eSignIntegrationKey: "",
        eSignPassword: "",
        eSignProvider: "",
        eSignURL: "",
        eSignUserId: "",
        culture: "",
        released: false,
        emailMoniker: "",
        AllowDocumentUpdate: false,
        AllowNotification: false,
        AllowDocX: false,
        cultureName: "",
        AllowSendEmail: false,
        DocumentCreation: false,
        DocumentUpdate: false,
        DocumentDelete: false,
        DocumentShare: false,
        TemplateEdit: false,
        TemplateShare: false,
        TemplateDelete: false,
        DefaultDescription: "",
        UseServiceAccount: false,
        ServiceAccountEmail: "",
        UniqueReference: "",
        SupressPreview: false,
        DocumentDelivery: "None",
        RedirectURL: "",
        ImageInfo: "",
        ExternalPublish: false,
        ExternalPublishContext: "",
        AccessCodeInstructions: "",
        EnabledCaptcha: false,
        EnabledSSO: false
    };
    const saveHeaderStyles = mergeStyleSets({
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                // borderTop: `4px solid ${branding.backgroundColor}`,
                borderTop: `4px solid ${branding.theme?.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ]
    })

    const getTokenParams = () => {
        const url_string = window.location.href
        const url = new URL(url_string);
        const token = url.searchParams.get("token")
        return getTokenClaims(token!)
    }

    async function loadInfo() {
        //sessionStorage.removeItem("campaignTargetUser");

        var params = getTokenParams()
        let url = new URL(window.location.href)
        templateLink = url.pathname.toString().indexOf("templatelink") > 0 || url.pathname.toString().indexOf("application") > 0
        if (params !== undefined) {

            try {
                if (templateLink && params.RequireAuth0 === "false") {
                    setIsAppLinkDraft(true)
                    if (profile?.Email) {
                        setEndUserName(profile?.Email)
                    } else {
                        setEndUserName(user)
                    }
                }
                datajson = params
                datajson.allowDocx = params.allowDox === "True" ? true : false;
                datajson.IsSubform = params.IsSubform === "True" ? true : false;
                setData(datajson)

                if (datajson.IsSubform) {
                    setIsprimaryDocumentIdForSecondary(true)
                }
                const currentUsername = Helper.getUsername() === undefined || Helper.getUsername() === null ? user.email : Helper.getUsername()
                setProfileUserName(currentUsername)
                if (currentUsername !== datajson.User) {
                    setOnBehalf(currentUsername)
                }

                const newConnection = new HubConnectionBuilder()
                    .withUrl(`${window.location.origin}/records?username=${datajson.User}`, {
                        headers: await Helper.getHeaders()
                    })
                    .withAutomaticReconnect()
                    .build();

                setConnection(newConnection);

            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        const init = async () => {
            await Helper.loadSites()

            await loadInfo()

            if (templateLink) {
                loadTemplate(datajson.TemplateId, datajson.User)
            }
            else {
                await getData()
            }
        }
        if (isAuthLoaded) {
            const url_string = window.location.href
            const url = new URL(url_string);
            const token = url.searchParams.get("token")

            var params = getTokenClaims(token!)

            //when user is authenticated
            if (isAuthenticated && params.RequireAuth0 === "true") {
                init().catch(e => { console.log(e) })
                //when user is registered but not authenticated
            } else if (isAuthenticated === false && params.RequireAuth0 === "true") {
                loginWithRedirect(window.location.href)
                //when user is not registered
            } else if (params.RequireAuth0 === "false" && props !== null && props.location !== null && props.location.pathname === "/link/application") {
                init().catch(e => { console.log(e) })
            } else {
                if (props !== null && props.location !== null) {
                    setIsAnonymous(true)
                }
            }
        }
    }, [isAuthLoaded])

    useEffect(() => {
        if (isAnonymous) {
            const init = async () => {

                await Helper.loadSites()

                await loadInfo()

                await getData2()
                // await getData()
            }
            init().catch(e => { console.log(e) })
        }
    }, [isAnonymous])

    useEffect(() => {
        const reload = async () => {
            if (props.isAccessCodeValid) {

                if (templateLink) {
                    setWaitToLoadProfile(true)
                } else {
                    await getDocument()

                }
                await setIsAccessCodeValid(true)

            }
        }
        reload()
    }, [props.isAccessCodeValid])

    useEffect(() => {
        console.log("Clear Session Storage")
        sessionStorage.clear()
        const interval = setInterval(async () => {
            console.log("Keep Alive")
            if (await AuthService.isAuthenticateExists()) {
                let userMessage = "";
                var onBehalfUser = sessionStorage.getItem("OnBehalfUser")!
                if (onBehalfUser !== undefined && onBehalfUser != null && onBehalfUser !== "") {
                    userMessage = `for user ${onBehalfUser}`
                } else {
                    userMessage = `- No user logged`
                }

                let message = `KeepAlive for TemplateID: ${datajson.TemplateId} ${userMessage}`
                await KeepAliveService.ping({
                    Message: message,
                    TemplateId: parseInt(datajson.TemplateId)
                })
                .catch(() => {
                    forceAuthenticated()
                })

                /*let url = new URL(window.location.href)
                let isApplication = url.pathname.toString().indexOf("templatelink") > 0
                    || url.pathname.toString().indexOf("application") > 0

                const request: IGetuserprofileRequest = {
                    UserName: isApplication ? datajson.TargetUser : datajson.ownerUser,
                    Profile: isApplication ? datajson.TargetUser : datajson.ownerUser,
                }
                await UserService.GetUserProfile(request)
                    .then()
                    .catch(() => {
                        forceAuthenticated()
                    })*/

            } else {
                console.log("No Auth")
            }
        }, 300000);

        return () => {
            clearInterval(interval);
        };

    }, [])

    useEffect(() => {
        if (isSSOValid) {
            setIsAppLinkDraft(true)
            setAllowDrafts(true)
        }
    }, [isSSOValid])

    const wrapperGetBranding = (DocumentOnlyAudienceId: string) => {
        const url_string = window.location.href
        const url = new URL(url_string);
        const token = url.searchParams.get("token")
        getAppSettings()
        var params = getTokenClaims(token!)
        return getBrandingInformation(params.User! === user.email ? params.User! : user.email, DocumentOnlyAudienceId)
    }

    const getData2 = async () => {

        Helper.getDocumentOnlyTemplateId(datajson.TemplateId, true, datajson.User === user.email ? datajson.User : user.email).then(response => {
            wrapperGetBranding((response as ITemplates).DocumentOnlyAudienceId).then(data => {
                if (response !== undefined) {
                    if (response.Billing && !response.ActiveSubscription) {
                        setisApply(false)
                        setHideSubscriptionDialog(false)
                        setSubscriptionMessage(response.SubscriptionStatusMessage ?? t('text.audienceDisabled', { ns: 'recordFormLink' }))
                    }
                    else {
                        // setTemplateName(res.Name)
                        // setDocumentDescription(res.Description)
                        // setdataCustom(res.Data)
                        // setIsCheckedOut(res.CheckedOut)
                        // setCulture(res.culture)
                        setisApply(false)
                        toggleCustomForm(true)
                    }

                }
            })
            if (response.PropertyBag) {
                try {
                    jsonBag = JSON.parse(response.PropertyBag) as IPropertyBag
                    setAudienceMasterId(response.DocumentOnlyAudienceId)
                    setAudienceName(response.AudienceName)
                    setTemplateCategory(response.Category)
                    dataTemplate = response as ITemplates
                } catch { }
            }
        })


    }

    const getDocument = async () => {

        const request = {
            UserName: datajson?.User!,
            DocumentId: -1,
            Id: datajson?.DocumentId!,
            IsPreview: false,
            IsDownload: true,
            IsDocx: true,
        }

        let { data }: any = await HttpRequest.getWithSession('GetDocument', request)
        const res = data

        if (res.Success === undefined || !res.Success) {
            setTemplateName(res.Name)
            setDocumentDescription(res.Description)
            setdataCustom(res.Data)
            setIsCheckedOut(res.CheckedOut)
            setCulture(res.culture)
        }
    }

    const getData = async () => {

        const request = {
            UserName: datajson?.User!,
            DocumentId: -1,
            Id: datajson?.DocumentId!,
            IsPreview: false,
            IsDownload: true,
            IsDocx: true,
        }

        let { data }: any = await HttpRequest.getWithSession('GetDocument', request)
        const res = data

        if (res.Success === undefined || !res.Success) {

            // todo check the username param
            Helper.getDocumentOnlyTemplateId(res.TemplateId, true, res.UserName === user.email ? res.UserName : user.email).then(response => {
                wrapperGetBranding((response as ITemplates).DocumentOnlyAudienceId).then(data => {
                    if (response !== undefined) {
                        if (response.Billing && !response.ActiveSubscription) {
                            setisApply(false)
                            setHideSubscriptionDialog(false)
                            setSubscriptionMessage(response.SubscriptionStatusMessage ?? t('text.audienceDisabled', { ns: 'recordFormLink' }))
                        }
                        else {
                            setTemplateName(res.Name)
                            setDocumentDescription(res.Description)
                            setdataCustom(res.Data)
                            var culture = ""
                            setIsCheckedOut(res.CheckedOut)

                            setCulture(res.culture)
                            setisApply(false)
                            toggleCustomForm(true)
                        }

                    }
                })
                if (response.PropertyBag) {
                    try {
                        jsonBag = JSON.parse(response.PropertyBag) as IPropertyBag
                        setAudienceMasterId(response.DocumentOnlyAudienceId)
                        setAudienceName(response.AudienceName)
                        setTemplateCategory(response.Category)
                        dataTemplate = response as ITemplates
                    } catch { }
                }
            })
        }
    }

    const toggleCustomForm = useCallback((open: boolean) => {
        const site = Helper.getSiteSelected()
        setSiteSelected(site)
        setShowCustomForm(open)
    }, [])

    const toggleClose = useCallback((open: boolean) => {
        const site = Helper.getSiteSelected()
        setSiteSelected(site)
        setShowCustomForm(open)
        if (!open) {
            const url = jsonBag.RedirectURL !== undefined && jsonBag.RedirectURL !== null && jsonBag.RedirectURL.trim() !== "" ? (jsonBag.RedirectURL) : (window.location.origin + "/app")
            window.location.assign(url);
        }
    }, [])

    const toggleQueueModal = useCallback((open: boolean, isUpdate: boolean, isError: boolean) => {
        if (open) {
            QueueCustomModal.open(true)
            setIsRecordUpdate(isUpdate)
            setIsRecordError(isError)
        }
    }, [QueueCustomModal])

    useEffect(() => {
        if (!QueueCustomModal.isOpen && QueueCustomModal.data) {
            toggleClose(false)
        }
    }, [QueueCustomModal.isOpen])

    const onCreateUpdate = async (id: string) => {
        setModalId(parseInt(id))
        setLayoutLoaded(false)
        setModalTitle(t('title.modal', { ns: 'preview' }))
        setSpinnerText(t('text.spinner', { ns: 'common' }))

        const preview = async () => {
            const req: IDocumentRequest = {
                UserName: datajson.User,
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id,
                IsPreview: true,
                IsDownload: false,
                IsDocx: false,
                context: 'webform',
                code: authCode,
                templateId: datajson.TemplateId
            }

            await Helper.getDocumentOnlyTemplateId(datajson.TemplateId, true, datajson.User).then(data => {
                try {

                    setId(id)

                    jsonBag = JSON.parse(data.PropertyBag) as IPropertyBag

                    jsonBag.AllowDocX = jsonBag.AllowDocX

                    setShowPreview(!jsonBag.SupressPreview)

                    if (!jsonBag.SupressPreview) {

                        Helper.getDocumentPreview(req).then(res => {
                            console.log(res)
                            if (res.Success) {
                                setDocumentDescription(res.Description ?? '')
                                setLayoutLoaded(true)
                                setPreviewURL(res.UrlPath)
                                setPreviewMime(res.mimeType)
                            }
                        })
                    } else {
                        hidePreview()
                    }

                }
                catch (reason) {
                    console.log(reason)
                }


            }).catch((reason) => {

                console.log(reason)
            }).finally(() => {

            })

        }
        await preview()

    }

    const hidePreview = () => {
        setShowModal(true)
        templateLink ? setCloseMessage("added") : setCloseMessage("updated")
        setShowPreview(false)
        setLayoutLoaded(false)
        setModalId(-1)
        setModalTitle("")
        setShowModal(true)
        hideModal()
    }

    const hideModal = () => {
        setSpinnerText(t('text.spinner', { ns: 'common' }))
        setisApply(true)
        const url = jsonBag.RedirectURL !== undefined && jsonBag.RedirectURL !== null && jsonBag.RedirectURL.trim() !== "" ? (jsonBag.RedirectURL) : (window.location.origin + "/app")
        window.location.assign(url);
        setShowModal(false)
    }


    const loadTemplate = async (templateId: string, user?: string) => {
        Helper.getDocumentOnlyTemplateId(templateId, true, user, "link").then(response => {

            wrapperGetBranding((response as ITemplates).DocumentOnlyAudienceId).then(async data => {
                if (response !== undefined) {
                    if (response.Billing && !response.ActiveSubscription) {
                        setisApply(false)
                        setHideSubscriptionDialog(false)
                        setSubscriptionMessage(response.SubscriptionStatusMessage ?? t('text.audienceDisabled', { ns: 'recordFormLink' }))
                    }
                    else {
                        try {
                            var template = response as IDocumentOnlyTemplate;
                            setDisplayName(template.DisplayName.toString())
                            setDescription(template.Description.toString())
                            dataTemplate = response as ITemplates
                            setTemplateCategory(template.Category.toString())
                            setTemplateName(template.DisplayName.toString())
                            setAudienceName(response?.AudienceName)
                            setAudienceMasterId(template.DocumentOnlyAudienceId)
                            jsonBag = JSON.parse(dataTemplate.PropertyBag) as IPropertyBag
                            if (jsonBag.EnabledCaptcha && getIsApplication()) {
                                setIsAppLinkDraft(false)
                                setAllowDrafts(false)
                            }

                            setisApply(false)
                            toggleCustomForm(true)
                            await GetAudiencebyOwner(dataTemplate.DocumentOnlyAudienceId)
                        }
                        catch (e) {
                            console.log(e)
                            return null
                        }
                    }
                }
            })

        })
    }

    const hideModalSubscription = useConstCallback(() => {
        setHideSubscriptionDialog(true)
        window.location.assign(window.location.origin + "/app");
    })

    const toggleCustomFormDraft = useConstCallback(() => {
        setShowModal(true)
        setShowCustomForm(false)
        setIsDraft(true)
    })

    const GetAudiencebyOwner = async (audienceMasterId: string) => {
        var params = getTokenParams()
        const req: IAudienceRequest = {
            UserName: params.TargetUser,
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            SK: Helper.getSessionKey(),
            Path: await Helper.getSite()!,
            AudienceMasterId: audienceMasterId,
            TargetUser: Helper.getUsername() === undefined || Helper.getUsername() === null ? user.email : Helper.getUsername(),
            Autoprovision: true

        }
        var response = await Helper.GetAudiencebyOwner(req)
        let audienceDetail = response as IDocumentOnlyAudience
        response != undefined && seDataAudienceTargetUser(audienceDetail)
        let isUserAdmin = audienceDetail != undefined ? audienceDetail.CoOwner || audienceDetail.Owner ? true : false : false
        setIsAdmin(isUserAdmin)
    }
    const getMenu = () => {
        const menuOptions: IContextualMenuItem[] = []
        return {
            items: menuOptions,
            directionalHintFixed: true,
        }
    }
    return (
        <>
            {!isApply ?
                <div style={{ width: "100%" }}>
                    <div style={{ backgroundColor: branding.backgroundColor, color: branding.fontColor, float: "left", width: "100%", height: "60px" }}>
                        {/*logo === undefined || logo === null || logo.length === 0 ? */}
                        <Icon iconName="GripperDotsVertical" styles={companyIconStyle} />{/* :*/}
                        {/*<img id={"logo"} src={`data:image/png;base64,${logo}`} alt="Audience Logo" width="50px" height="50px" />}*/}
                        <label style={{ paddingTop: "12px", paddingLeft: "10px", position: "absolute" }}>{branding.companyName}</label>
                        <label style={{ paddingTop: "35px", paddingLeft: "10px", position: "absolute" }}>{templateName}</label>
                    </div>
                    <>{isAccessCodeValid || !props.isAccessCodeValid ?
                        <div style={{ float: "left", width: "100%", backgroundColor: "#F0F0F0", height: "100%" }}>
                            <div style={{ margin: "auto", padding: "10px" }}>
                                {templateLink ? <CustomForm
                                    waitToLoadProfile={waitToLoadProfile}
                                    documentDescription={documentdescription}
                                    isAccessCodeValid={props.isAccessCodeValid}
                                    setIsAccessCodeValid={setisApply}
                                    showCustomForm={showCustomForm}
                                    toggleCustomForm={toggleCustomForm}
                                    siteOptions={siteOptions}
                                    siteSelected={siteSelected}
                                    accountId={Helper.getDefaultAccount()}
                                    //targetUser={datajson.TargetUser}
                                    targetUser={Helper.getUsername() === undefined || Helper.getUsername() === null ? user.email : Helper.getUsername()}
                                    username={datajson.User}
                                    sessionKey={Helper.getSessionKey()}
                                    templateId={datajson.TemplateId}
                                    templateName={templateName}
                                    update={onCreateUpdate}
                                    isCreator={false}
                                    isOwner={true}
                                    isCoOwner={false}
                                    culture={culture}
                                    ownerName={datajson.User}
                                    ownerUser={datajson.ownerUser}
                                    templateCategory={templateName}
                                    audienceName={audienceName}
                                    emailMoniker={jsonBag !== null ? jsonBag.emailMoniker : ""}
                                    documentCreation={jsonBag !== null ? jsonBag.DocumentCreation : false}
                                    documentUpdate={false}
                                    templateEdit={dataTemplate.TemplateEdit}
                                    toggleClose={toggleClose}
                                    sendlink={templateLink}
                                    defaultDescription={jsonBag != null ? jsonBag.DefaultDescription : ""}
                                    onBehalf={profile?.Email ?? user?.email!}
                                    isSubform={datajson.IsSubform}
                                    documentId={datajson.PrimaryId}
                                    isprimaryDocumentIdForSecondary={isprimaryDocumentIdForSecondary}
                                    allowDrafts={allowDrafts}
                                    draftId={draftId}
                                    setDraftId={setDraftId}
                                    createUpdateDraft={toggleCustomFormDraft}
                                    isAdmin={isAdmin}
                                    isAppLinkDraft={isAppLinkDraft}
                                    toggleQueueModal={toggleQueueModal}
                                    notifyClient={true}
                                >
                                    {props.children}
                                </CustomForm>
                                    : isCheckedOut ?
                                        <Dialog
                                            hidden={hideDialog}
                                            onDismiss={toggleHideDialog}
                                            dialogContentProps={dialogContentProps}
                                            modalProps={modalProps}
                                        >
                                            <DialogFooter>
                                                <PrimaryButton onClick={toggleHideDialog} text={t('Common.Button.Accept', { ns: 'common' })} />
                                            </DialogFooter>
                                        </Dialog> : showCustomForm && <CustomForm
                                            documentDescription={documentdescription}
                                            isAccessCodeValid={props.isAccessCodeValid}
                                            showCustomForm={showCustomForm}
                                            toggleCustomForm={toggleCustomForm}
                                            siteOptions={siteOptions}
                                            siteSelected={siteSelected}
                                            accountId={Helper.getDefaultAccount()}
                                            //targetUser={datajson.TargetUser}
                                            targetUser={Helper.getUsername() === undefined || Helper.getUsername() === null ? user.email : Helper.getUsername()}
                                            username={datajson.User === user.email ? datajson.User : user.email}
                                            sessionKey={Helper.getSessionKey()}
                                            templateId={datajson.TemplateId}
                                            templateName={templateName}
                                            templateDescription={documentdescription}
                                            data={dataCustom}
                                            documentId={datajson?.DocumentId}
                                            update={onCreateUpdate}
                                            culture={culture}
                                            sendlink={true}
                                            toggleClose={toggleClose}
                                            templateVersion={datajson?.TemplateVersion}
                                            isAdmin={isAdmin}
                                            onBehalf={profile?.Email ?? user?.email!}
                                            // onBehalf={onBehalf !== null && onBehalf !== undefined ? onBehalf : profile?.Email}
                                            ownerName={datajson.User}
                                            ownerUser={datajson.ownerUser}
                                            templateCategory={templateCategory}
                                            audienceMasterId={audienceMasterId.toString()}
                                            audienceName={audienceName}
                                            emailMoniker={jsonBag !== null ? jsonBag.emailMoniker : ""}
                                            documentCreation={jsonBag !== null ? jsonBag.DocumentCreation : false}
                                            documentUpdate={jsonBag !== null ? jsonBag.DocumentUpdate : false}
                                            templateEdit={dataTemplate.TemplateEdit}
                                            defaultDescription={jsonBag != null ? jsonBag.DefaultDescription : ""}
                                            isSubform={datajson.IsSubform}
                                            toggleQueueModal={toggleQueueModal}
                                            notifyClient={true}
                                        >
                                            {props.children}
                                        </CustomForm>
                                }
                                <PreviewScreen
                                    showModal={showPreview}
                                    hideModal={hidePreview}
                                    modalTitle={templateName}
                                    documentDescription={documentdescription}
                                    documentId={datajson?.DocumentId}
                                    allowDocX={jsonBag.AllowDocX}
                                    spinnerText={spinnerText}
                                    layoutLoaded={layoutLoaded}
                                    URLPath={previewURL}
                                    mimeType={previewMime}
                                    onClose={false}
                                    authUser={user?.email!}
                                    username={datajson.User}
                                    Id={id}
                                />
                                <QueueModal Modal={QueueCustomModal.Modal} close={QueueCustomModal.close} isUpdate={isRecordUpdate} isLink={true} isError={isRecordError} />
                                <Modal
                                    className="DialogBackgoundColor"
                                    isOpen={showModal}
                                    isBlocking={true}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Stack>
                                                    <div className={saveHeaderStyles.header}>
                                                        <IconButton
                                                            styles={iconButtonStyles}
                                                            iconProps={cancelIcon}
                                                            ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                                            onClick={hideModal}
                                                            title={t('Common.Button.Close', { ns: 'common' })}
                                                        />
                                                    </div>
                                                    <Separator />
                                                </Stack>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className={contentStyles.body}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                            <br />
                                                            <div style={{ textAlign: "center" }}>
                                                                {isDraft ?
                                                                    <>
                                                                        <p>{t('text.saveDraft', { ns: 'recordFormLink' })}</p>
                                                                        {/*<p>When you want to update your draft please return to the email you were sent and click on the link that provided you with initial access.</p>
                                                                <p>You will then be sent a new one-time access code that will allow you to re-open your draft and complete before submitting.</p>*/}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p>{t('text.saveNoDraft', { ns: 'recordFormLink', Name: templateLink ? displayName : documentdescription + "  " + templateCategory })}</p>
                                                                    </>
                                                                }

                                                                <br /><br />
                                                                <div style={{ paddingBottom: "10px" }}>
                                                                    <DefaultButton onClick={hideModal} text={t('text.btnClose', { ns: 'recordFormLink' })} title={t('title.btnClose', { ns: 'recordFormLink' })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div> : null}
                    </>

                    <Dialog
                        hidden={hideSubscriptionDialog}
                        dialogContentProps={dialogSubscriptionContentProps}
                        modalProps={modalProps}
                    >
                        <DialogFooter>
                            <PrimaryButton onClick={hideModalSubscription} text={t('Common.Button.Accept', { ns: 'common' })} />
                        </DialogFooter>
                    </Dialog>
                </div> :
                <>
                    <Stack grow={1} styles={{ root: { height: "100%", alignItems: "center", justifyContent: "center" } }}>
                        <Spinner size={SpinnerSize.large} label={t('text.spinner', { ns: 'common' })} ariaLive="assertive" />
                    </Stack>
                </>
            }

        </>
    );
}

export default DocumentLink