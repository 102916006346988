import HttpRequest from "./HttpRequest";
import {IHttpClientResponseBag} from "../Helpers/Helper";

export interface IContactSupportRequest {
    From: string
    To: string
    Message: string
    Subject: string

}

export interface IContactSupportEmail {
    ContactSupport: string

}

const EmailService = {

    /**
     * ContactSupport
     * @param request
     * @constructor
     */
    ContactSupport: async (request: IContactSupportRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.post('ContactSupport', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject({err: 'Server error'})
            else resolve(data)
        })
    },



}

export default EmailService