import { IDropdownOption } from "@fluentui/react";
import React from "react"
import { ActionType } from "../../interfaces/IFormAssembly";

export enum InputType {
    None = 0,
    Textbox = 1,
    TextArea = 2,
    DropDownList = 3,
    CheckBoxList = 4,
    RadioButtonList = 5,
    DatePicker = 6,
    Number = 7,
    Currency = 8,
    LineBreak = 9,
    SectionHeader = 10,
    GridList = 11,
    Paragraph = 12,
    RichText = 13,
    CheckBox = 14,
    Attachment = 15,
    Image = 16,
    HelpLink = 17,
    Integration = 18
}
export const FORM_ASSEMBLY_EXCLUDED_TYPES = [
    InputType.LineBreak,
    InputType.SectionHeader,
    InputType.GridList,
    InputType.Attachment,
    InputType.Image,
    InputType.HelpLink,
    InputType.Integration,
]

export const getResetValueByType = (prevValue: string, type: InputType | undefined, actionType: ActionType) => {
    if(type === undefined) return ' '
    if (actionType !== ActionType.Hide) {
        return prevValue === '' || prevValue === undefined ? getResetValue(type) : prevValue
    }
    return getResetValue(type)
}

export const getResetValue = (type: InputType) => {
    switch (type) {
        case InputType.None: return ' '
        case InputType.Textbox: return ' '
        case InputType.TextArea: return ' '
        case InputType.DropDownList: return ' '
        case InputType.CheckBoxList: return ' '
        case InputType.RadioButtonList: return ' '
        case InputType.DatePicker: return ' '
        case InputType.Number: return '0'
        case InputType.Currency: return '0'
        case InputType.LineBreak: return ' '
        case InputType.SectionHeader: return ' '
        case InputType.GridList: return ' '
        case InputType.Paragraph: return ' '
        case InputType.RichText: return ' '
        case InputType.CheckBox: return ' '
        case InputType.Attachment: return ' '
        case InputType.Image: return ' '
        case InputType.HelpLink: return ' '
        case InputType.Integration: return ' '
    }
}
export enum TextBoxType {
    None = 0,
    Normal = 1,
    Date = 2,
    Integer = 3,
    Currency = 4
}

export enum CheckoutOptionsVisibilityType {
    Checkin = 0,
    Checkout = 1,
    Download = 2,
    UndoCheckout = 3,
    UpdateWebForm = 4,
    WebFormIcon = 5,
    Delete = 6,
    Manage = 7,
    WebformLink = 8
}
export function getTypeName(type: InputType) {
    var name = ""

    switch (type) {
        case InputType.Textbox:
            name = "Text Box"
            break;
        case InputType.TextArea:
            name = "Text Area"
            break;
        case InputType.DropDownList:
            name = "Drop Down List"
            break;
        case InputType.CheckBoxList:
            name = "Drop Down (Multiple selection)"
            break;
        case InputType.DatePicker:
            name = "Date"
            break;
        case InputType.Number:
            name = "Integer"
            break;
        case InputType.Currency:
            name = "Currency"
            break;
        case InputType.GridList:
            name = "Grid List"
            break;
        case InputType.Paragraph:
            name = "Paragraph"
            break;
        case InputType.RichText:
            name = "Rich Text Editor"
            break;
        case InputType.CheckBox:
            name = "Check Box"
            break;
        case InputType.Image:
            name = "Image"
            break;
        case InputType.Integration:
            name = "Integration"
            break;
    }
    return name
}


const requiredValidation = (fieldName: string, fieldValue: any) => {
    if (fieldValue.trim().length > 0) {
        return `${fieldName} is required`;
    }

    return null;
}

const validations = {
    required: (fieldName: string, fieldValue: any) => requiredValidation(fieldName, fieldValue),
};

export enum RuleActionType {
    REPLACE,
    DELETE
}



