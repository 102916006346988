import {IStackStyles} from "@fluentui/react";
import {Theme} from "@fluentui/react-theme-provider";

export const notificationStackItemStyles = (theme: Theme): IStackStyles => {
    return {
        root: {
            borderBottom: `solid 1px ${theme.semanticColors.listItemBackgroundHovered}`,
            minHeight: 40,
            margin: 0,
            padding: 0,
            selectors: {
                ":hover": {
                    backgroundColor: theme.semanticColors.listItemBackgroundHovered
                }
            }
        },
        inner: {
            padding: 4,
        }
    }
}


