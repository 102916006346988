import React, { useState, useEffect } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import Helper, { IDocumentOnlyAudienceDetail, IRequest, IPlan, IManageClientPlans, IManageClientPlansRequest, ICUManageClientPlansRequest, BillingStatebyOwnerNameRequest, IDManageClientPlanRequest, ISubscriptionUpdateRequest } from '../../Helpers/Helper';
import { DetailsListLayoutMode, IContextualMenuItem, IContextualMenuProps, SelectionMode, IDropdownOption, Toggle, MessageBar, MessageBarType, MarqueeSelection, Dropdown, IMessageBarStyles, DatePicker } from '@fluentui/react';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField } from '@fluentui/react/lib/TextField';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useConstCallback } from '@uifabric/react-hooks';
import { isMobile } from 'react-device-detect';
import { formatFromUTC, formatSimpleDate, setGlobalDateLocale } from '../../Helpers/DateUtils';
import {useBranding} from "../../hooks/useBranding";
import { useTranslation } from 'react-i18next';
import {FormProvider, useForm } from 'react-hook-form';
import { RHFTextBoxControl } from '../RHFControls/RHFTextBoxControl';
import {removeHTMLTags} from "../RenewalsAndAlerts/RenewalsAndAlerts.data";
import {StatusEnum} from "../../share/StatusMessage";
import ManageClientPlansService, {
    ICreateUpdateManageClientPlansRequest, IDeleteRequest,
    IGetAllManageClientPlansRequest
} from "../../services/ManageClientPlans";
import BillingStateService, {
    ICreateUpdateOwnerNameRequest,
    IGetAllBillingStateOwnerHistoryRequest,
    IGetAllBillingStateOwnerHistoryResponse
} from "../../services/BillingState";

const stackTokens = { childrenGap: 10 };
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

enum ModalType {
    None = 0,
    Add = 1,
    Edit = 2,
    Delete = 3,
    NextDueDate = 4
}
function ManageClientPlans(props: any) {
    const { branding } = useBranding()
    const {t} = useTranslation(['common'])

    const emptyManageClientPlans: IManageClientPlans = {
        Id: 0,
        BillingPlanId: 0,
        OwnerName: "",
        OrganizationName: "",
        OrganizationAddress: "",
        OrganizationCity: "",
        OrganizationState: "",
        OrganizationPostalCode: "",
        OrganizationCountry: "",
        ContactFirstName: "",
        ContactLastName: "",
        ContactEmailAddress: "",
        ContactPhoneNumber: "",
        SubscriptionId: "",
        Updated: new Date().toString(),
        Created: new Date().toString(),
        Active: false,
        Comment: ""
    };

    const [manageClientPlansList, setManageClientPlansList] = useState<IManageClientPlans[]>([]);
    const [selectedManageClientPlan, setSelectedManageClientPlan] = useState<IManageClientPlans>(emptyManageClientPlans);
    const [showAddEditManageClientPlanModal, setShowAddEditManageClientPlanModal] = useState<boolean>(false)
    const [showDeleteManageClienPlanModal, setShowDeleteManageClientPlanModal] = useState(false)
    const [modalType, setModalType] = useState<ModalType>(ModalType.None)
    const [Id, setId] = useState(0);
    const [BillingPlanId, setBillingPlanId] = useState(0);
    const [OwnerName, setOwnerName] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationAddress, setOrganizationAddress] = useState("");
    const [organizationCity, setOrganizationCity] = useState("");
    const [organizationState, setOrganizationState] = useState("");
    const [organizationPostalCode, setOrganizationPostalCode] = useState("");
    const [organizationCountry, setOrganizationCountry] = useState("");
    const [contactFirstName, setContactFirstName] = useState("");
    const [contactLastName, setContactLastName] = useState("");
    const [contactEmailAddress, setContactEmailAddress] = useState("");
    const [contactPhoneNumber, setContactPhoneNumber] = useState("");
    const [SubscriptionId, setSubscriptionId] = useState("");
    const [active, setActive] = useState(false);
    const [comment, setComment] = useState("");
    const [showMessageError, setshowMessageError] = useState(false)
    const [message, setMessage] = useState("");
    const [filterOwnerName, setFilterOwnerName] = useState("");
    const [planList, setPlanList] = useState<IPlan[]>([]);
    const [applying, setApplying] = useState(false);
    const [planOptions, setPlanOptions] = useState<IDropdownOption[]>([]);
    const [selectedBillingPlan, setSelectedBillingPlan] = useState<IDropdownOption>();
    const [requiredEmail, setrequiredEmail] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showModalNextDueDate, setShowModalNextDueDate] = useState(false)
    const myForm = useForm();

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
                class: 'ms-Icon ms-Icon--Mail'
            }
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        bodyDelete: {
            width: '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        body: {
            width: '45vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyEdit: {
            width: '50vw',
            height: '60vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyVWOverride: {
            width: '90%',

            flex: '4 4 auto',
            padding: '0 0px 0px 18px',
        },
        bodyEditVWOverride: {
            width: '70vw',

            flex: '4 4 auto',
            padding: '0 18px 0px 18px',
        },
        User: {
            width: '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const emptyBillingState: IGetAllBillingStateOwnerHistoryResponse = {
        Active: false,
        BillingClientId: 0,
        BillingMerchantId: 0,
        BillingPlanId: 0,
        Id: 0,
        MaxApplicationAllowed: 0,
        NextDueDate: undefined,
        StorageLimit: 0,
        SubmissionLimit: 0,
        TransactionAmount: "",
        TransactionCode: "",
        TransactionDate: "",
        TransactionDescription: "",
        TransactionError: "",
        TransactionId: "",
        TransactionName: "",
        UserLimit: 0,
        WorkspaceOnlyUsers: 0

    };
    const [billingState, setbillingState] = useState<IGetAllBillingStateOwnerHistoryResponse>(emptyBillingState);
    const [nextDueDate, setNextDueDate] = useState<Date | undefined>();
    const [requiredFilterEmail, setrequiredFilterEmail] = useState(false)
    const messageBarSuccessStyles: IMessageBarStyles = {
        root: {
            backgroundColor: "#acffac"
        }
    };
    const getDateTimeDisplaySpan = (item: any) => {

        var result

        isMobile ?
            result = <span title={item.NextDueDate !== null ? formatSimpleDate(item.NextDueDate) : null}>{item.NextDueDate !== null ? formatSimpleDate(item.NextDueDate, 'll') : null}</span>
            :
            result = <span title={item.NextDueDate !== null ? formatSimpleDate(item.NextDueDate) : null}>{item.NextDueDate !== null ? formatSimpleDate(item.NextDueDate, 'llll') : null}</span>

        return result
    }
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Organization Name',
            fieldName: 'OrganizationName',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true
        },
        {
            key: 'column2',
            name: 'Contact Phone',
            fieldName: 'ContactPhoneNumber',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
        },
        {
            key: 'column3',
            name: 'Contact Email',
            fieldName: 'ContactEmailAddress',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IManageClientPlans) => {
                return item.ContactEmailAddress ?
                    <a style={{ cursor: 'pointer' }}
                        title={item.ContactEmailAddress} >
                        <div style={{ width: "100%", height: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {item.ContactEmailAddress}</div></a>
                    : null;
            }
        },
        {
            key: 'column4',
            name: 'Contact ',
            fieldName: 'ContactFirstName',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IManageClientPlans) => {
                return item.ContactFirstName ?
                    <a style={{ cursor: 'pointer' }}
                        title={item.ContactFirstName + " " + item.ContactLastName} >
                        <div style={{ width: "100%", height: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {item.ContactFirstName + " " + item.ContactLastName}</div></a>
                    : null;
            },
        },
        {
            key: 'column10',
            name: 'Plan Name',
            fieldName: 'PlanName',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true
        },
        {
            key: 'column11',
            name: 'Next Due Date',
            fieldName: 'NextDueDate',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'date',
            isPadded: true,
            onRender: (item: any) => {
                return getDateTimeDisplaySpan(item);
            },
        },
        {
            key: 'column9',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    title={"Click to select from available actions."}
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={menuProps}
                /></div>
            },
            isPadded: true
        },
    ]

    const menuItems: IContextualMenuItem[] = [
       /* {
             key: 'delete',
             text: 'Delete',
             title: 'Delete Manage Client Plan.',
             onClick: () => {
                 setShowDeleteManageClientPlanModal(true)
                 setModalType(ModalType.Delete)
                 selectionManageClientPlant.setAllSelected(false)
             },
         },*/
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit Manage Client Plan.',
            onClick: () => {
                loadBillingState(selectedManageClientPlan.OwnerName)
                setApplying(true)
                setShowAddEditManageClientPlanModal(true)
                setId(selectedManageClientPlan.Id)
                var dataPlan = planList.find(p => p.Id == selectedManageClientPlan.BillingPlanId)!
                if (dataPlan !== undefined) { setSelectedBillingPlan({ key: dataPlan.Id, text: dataPlan.Name }) }
                setOwnerName(selectedManageClientPlan.OwnerName)
                setBillingPlanId(selectedManageClientPlan.BillingPlanId)
                setActive(selectedManageClientPlan.Active);
                setComment(selectedManageClientPlan.Comment);
                setContactEmailAddress(selectedManageClientPlan.ContactEmailAddress);
                setContactFirstName(selectedManageClientPlan.ContactFirstName);
                setContactLastName(selectedManageClientPlan.ContactLastName);
                setContactPhoneNumber(selectedManageClientPlan.ContactPhoneNumber);
                setOrganizationAddress(selectedManageClientPlan.OrganizationAddress);
                setOrganizationCity(selectedManageClientPlan.OrganizationCity);
                setOrganizationCountry(selectedManageClientPlan.OrganizationCountry);
                setOrganizationName(selectedManageClientPlan.OrganizationName);
                setOrganizationPostalCode(selectedManageClientPlan.OrganizationPostalCode);
                setOrganizationState(selectedManageClientPlan.OrganizationState);
                setSubscriptionId(selectedManageClientPlan.SubscriptionId)
                setModalType(ModalType.Edit)
                setApplying(false)
            },
        },
        {
            key: 'NextDueDate',
            text: 'Next Due Date',
            title: 'Next Due Date.',
            onClick: async () => {
                setApplying(true)

                await loadBillingState(selectedManageClientPlan.OwnerName)

                setShowModalNextDueDate(true)

                setId(selectedManageClientPlan.Id)
                setId(selectedManageClientPlan.Id)
                var dataPlan = planList.find(p => p.Id == selectedManageClientPlan.BillingPlanId)!
                if (dataPlan !== undefined) { setSelectedBillingPlan({ key: dataPlan.Id, text: dataPlan.Name }) }
                setOwnerName(selectedManageClientPlan.OwnerName)
                setBillingPlanId(selectedManageClientPlan.BillingPlanId)
                setActive(selectedManageClientPlan.Active);
                setSubscriptionId(selectedManageClientPlan.SubscriptionId)
                setModalType(ModalType.NextDueDate)

                //selectionManageClientPlant.setAllSelected(false)
            },
        },
    ];

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    useEffect(() => {
        myForm.clearErrors()
        myForm.reset()
        
        setGlobalDateLocale(navigator.language)
        //setNextDueDate(new Date(Date.now() + 5 * 24 * 60 * 60 * 1000))
        if (planList.length == 0) {
            setApplying(true)
            setPlanList([])
            loadPlan();
        }
    }, [])

    const onSelectionChange = () => {
        const selectionCount = selectionManageClientPlant.getSelectedCount();

        switch (selectionCount) {
            case 0:
                setSelectedManageClientPlan(emptyManageClientPlans)
                break;
            case 1:
                var obj = selectionManageClientPlant.getSelection()[0]
                var objManage = obj as IManageClientPlans
                setSelectedManageClientPlan(objManage)
                break;
            default:
                setSelectedManageClientPlan(emptyManageClientPlans)
                break;
        }
    }

    const selectionManageClientPlant = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    const loadManageClientPlans = async (filtergridOwnerName: string) => {
        setApplying(true)

        const request: IGetAllManageClientPlansRequest = {
            ownerName: filtergridOwnerName
        }

        await ManageClientPlansService.GetAllManageClientPlans(request)
            .then((data) => {
                setManageClientPlansList(data)
            })
            .catch(() => {
                setManageClientPlansList([])
            })
            .finally(() => {
                setApplying(false)
            })
    }

    const getKey = (item: IManageClientPlans, index?: number): string => {
        if (item !== undefined)
            return item.Id!.toString()
        return ""
    }

    const addManageClientPlant = () => {
        setShowAddEditManageClientPlanModal(true)
        setModalType(ModalType.Add)
        selectionManageClientPlant.setAllSelected(false)
        setId(-1)
    }

    const deleteManageClientPlan = async () => {

        const request: IDeleteRequest = {
            Id: selectedManageClientPlan.Id,
            Operation: "delete"
        }
        await ManageClientPlansService.Delete(request)
            .then(() => {
                setManageClientPlansList([])
                loadManageClientPlans(filterOwnerName)
                hideDeleteManageClientPlanModal()
            })
            .catch()
            .finally()
    }

    const hideAddEditManageClientPlanModal = async () => {
        setApplying(true)
        setManageClientPlansList([])
        await loadManageClientPlans(filterOwnerName)
        setShowAddEditManageClientPlanModal(false)
        resetForm()
        selectionManageClientPlant.setAllSelected(false)
    }

    const hideDeleteManageClientPlanModal = () => {
        setShowDeleteManageClientPlanModal(false)
        resetForm()
    }

    const resetForm = () => {
        setSelectedManageClientPlan(emptyManageClientPlans)
        setModalType(ModalType.None)
        setId(0)
        setSelectedBillingPlan({ key: 0, text: "" })
        setOwnerName("")
        setBillingPlanId(0)
        setActive(false);
        setComment("");
        setContactEmailAddress("");
        setContactFirstName("");
        setContactLastName("");
        setContactPhoneNumber("");
        setOrganizationAddress("");
        setOrganizationCity("");
        setOrganizationCountry("");
        setOrganizationName("");
        setOrganizationPostalCode("");
        setOrganizationState("");
        setSubscriptionId("")
        setMessage("")
        setShowSuccess(false)
        setshowMessageError(false)
        setApplying(false);
    }
    const getErrorMessage = (value: string, message: string): string => {
        if (value.length > 0) {
            return ""
        }
        else {
            return message;
        }
    };
    const getErrorMessageEmail = async (value: string): Promise<string> => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length > 0) {
            if (!value.match(mailformat)) {
                setrequiredEmail(false)
                return "Email must be valid.";
            }
            setrequiredEmail(true)
        }
        return ""
    };
    const getErrorMessageEmailFilter = async (value: string): Promise<string> => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length > 0) {
            if (!value.match(mailformat)) {
                setrequiredFilterEmail(false)
                return "Email must be valid.";
            }
            else {
                setrequiredFilterEmail(true)
            }
        }
        return ""
    };
    const getErrorMessagePhone = (value: string): string => {

        if (value.length > 0) {
            return ""
        }
        else {
            return message;
        }
    };

    async function addEditManageClientPlan() {
        setApplying(true)
                
        const request:ICreateUpdateManageClientPlansRequest = {
            Id: modalType == ModalType.Add ? -1 : selectedManageClientPlan.Id,
            OwnerName: OwnerName,
            Active: active,
            Comment: comment,
            ContactEmailAddress: contactEmailAddress,
            ContactFirstName: contactFirstName,
            ContactLastName: contactLastName,
            ContactPhoneNumber: contactPhoneNumber,
            OrganizationAddress: organizationAddress,
            OrganizationCity: organizationCity,
            OrganizationCountry: organizationCountry,
            OrganizationName: organizationName,
            OrganizationPostalCode: organizationPostalCode,
            SubscriptionId: SubscriptionId,
            BillingPlanId: BillingPlanId,
            OrganizationState: organizationState,
            Operation: modalType == ModalType.Add ? "create" : "update",
            Updated: modalType == ModalType.Add ? "" : Date.now().toString(),
            Created: modalType == ModalType.Add ? Date.now().toString() : "",
        }
        await ManageClientPlansService.CreateUpdateManageClientPlans(request)
            .then(() => {
                setShowSuccess(true)
                setMessage("Added  item successfully")
                setManageClientPlansList([])
                loadManageClientPlans(filterOwnerName).then(data => {
                    createUpdateBillingState("Admin Update" + "(" + selectedBillingPlan?.text + ")", Number(selectedBillingPlan?.key),)
                })
                setTimeout(function () {
                    hideAddEditManageClientPlanModal()
                }, 3000);
            })
            .catch((error)=> {
                setshowMessageError(true)
                setMessage("An error occurred: " + Helper.GetErrorMessage(error))
                setTimeout(function () {
                    setshowMessageError(false)
                }, 5000);
            })
            .finally(()=>{
                setApplying(false)
            })
      
    }

    const validForm = () => {
        if (modalType === ModalType.Add) {
            if (comment.length > 0 &&
                contactEmailAddress.length > 0 &&
                BillingPlanId > 0 &&
                contactFirstName.length > 0 &&
                contactLastName.length > 0 &&
                contactPhoneNumber.length > 0 &&
                SubscriptionId.length > 0 &&// SubscriptionId!=="-1" &&
                organizationAddress.length > 0 &&
                organizationCity.length > 0 &&
                organizationCountry.length > 0 &&
                organizationName.length > 0 &&
                organizationPostalCode.length > 0 &&
                organizationState.length > 0) {
                return true;
            }
        }
        else if (modalType === ModalType.Edit) {
            if (Id > 0 &&
                comment.length > 0 &&
                contactEmailAddress.length > 0 &&
                BillingPlanId > 0 &&
                contactFirstName.length > 0 &&
                contactLastName.length > 0 &&
                contactPhoneNumber.length > 0 &&
                SubscriptionId.length > 0 && //SubscriptionId!=="-1" &&
                organizationAddress.length > 0 &&
                organizationCity.length > 0 &&
                organizationCountry.length > 0 &&
                organizationName.length > 0 &&
                organizationPostalCode.length > 0 &&
                organizationState.length > 0) {
                return true;
            }
        }
        else {
            return false
        }
    }

    /**
     * Search the email owner 
     */
    const onSearch = async () => {
        await myForm.handleSubmit(async () => {
                myForm.clearErrors()
                setFilterOwnerName(myForm.getValues("emailOwner"))
                await loadManageClientPlans(myForm.getValues("emailOwner"));
            }, () => {

            }
        )();
    }
    const loadPlan = async () => {
        const req: IRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
        }
        const sortStringKeys = (a: IDropdownOption, b: IDropdownOption) => String(a.text).localeCompare(b.text)

        await Helper.getAllPlan(req).then(data => {
            setPlanList(data)
            setPlanOptions((data.map<IDropdownOption>((item: IPlan) => {
                return { key: item.Id, text: `${item.Name} (${item.Type?.trim()})` }
            })).sort(sortStringKeys))
            setApplying(false)
        })
    }

    const onPlanChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setSelectedBillingPlan(option);
        setBillingPlanId(Number(option?.key!))
    };

    const loadBillingState = async (_ownerName: string) => {

        const request: IGetAllBillingStateOwnerHistoryRequest = {
            ownerName: _ownerName
        }

        await BillingStateService.GetAllBillingStateOwnerHistory(request)
            .then((data) => {
                if (data.length > 0) {
                    var sortedItems = data.sort(function (a, b) {
                        return (b.Id - a.Id);
                    }).filter(f => f.TransactionName != "Admin Update")
                    let state = sortedItems[0]!  // Take the last register               
                    setbillingState(state)
                    setNextDueDate(state.NextDueDate == null ? undefined : new Date(state.NextDueDate))
                }
            })
            .catch()
            .finally(() => {
                setApplying(false)
            })
    }

    const getDatePickerDate = (inputDate?: Date) => {
        return inputDate !== undefined ? new Date(formatSimpleDate(inputDate, 'LL')) : undefined;
    };

    const onFormatDate = (date?: Date): string => {
        return date !== undefined ? formatFromUTC(date, 'LL') : undefined;
    };

    async function createUpdateBillingState(transaction: string, planId: number) {

        if (planId > 0) {
            setApplying(true)

            const request: ICreateUpdateOwnerNameRequest = {
                Active: billingState.Active,
                BillingClientId: billingState.BillingClientId,
                BillingMerchantId: billingState.BillingMerchantId,
                BillingPlanId: planId,
                TransactionCode: "NA",
                TransactionDescription: transaction,
                TransactionError: "NA",
                TransactionId: "NA",
                TransactionName: transaction,
                Operation: "update",
                NextDueDate: nextDueDate!,
                OwnerName: filterOwnerName
            }

            await BillingStateService.CreateUpdateOwnerName(request)
                .then(async (data) => {
                    if (modalType == ModalType.NextDueDate) {
                        await loadManageClientPlans(filterOwnerName).then(data => hideModalNextDueDate());
                        setApplying(false)
                    } else if (SubscriptionId.length > 0 && SubscriptionId !== "-1" && SubscriptionId.toLowerCase() !== "na") {
                        await updateSubscription()
                    }
                    selectionManageClientPlant.setAllSelected(false)
                })
                .catch((error) => {
                })
                .finally(() => {
                })
        }
    }

    const hideModalNextDueDate = async () => {

        setNextDueDate(undefined)
        setShowModalNextDueDate(false)
        selectionManageClientPlant.setAllSelected(false)

    }
    async function updateSubscription() {
        var Newplan = planList.filter(p => p.Id == selectedBillingPlan?.key)[0]
        let _plan = await Helper.getDefaultPlan()        
        if (_plan !== Newplan.Name.toLowerCase() && SubscriptionId !== "-1") {
            const req: ISubscriptionUpdateRequest = {
                AccountId: Helper.getDefaultAccount(),
                ApiKey: "-1",
                Autoprovision: true,
                Path: await Helper.getSite(),
                PriceId: Newplan.PaymentProviderProductId,
                SK: Helper.getSessionKey(),
                UserName: Helper.getUsername(),
                subscriptionId: SubscriptionId
            }
            await Helper.updateSubscription(req)
            await loadManageClientPlans(filterOwnerName).then(data => hideModalNextDueDate());
            setApplying(false)
        }




    }
    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <label className="ms-fontSize-18">Manage Client Plans</label><br />
                        <div>Form, document and records management all-in-one.</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <label className="ms-fontSize-18">Manage Client Plans</label>
                    </div>
                    <br />
                </div>
                : null
            }

            <Stack tokens={stackTokens}>

                <FormProvider {...myForm}>
                    <form onSubmit={(e) => {e.preventDefault(); onSearch()}}>
                        <Stack horizontal wrap tokens={{childrenGap: 8}} grow={1}
                               styles={{root: {padding: 8, maxWidth: 630}}}>

                            <Stack grow={1}>
                                
                                <RHFTextBoxControl
                                    id="emailOwner"
                                    control={myForm.control}
                                    setValue={myForm.setValue}
                                    getValues={myForm.getValues}
                                    trigger={myForm.trigger}
                                    rules={{
                                        required: t('text.fieldRequired', {ns: 'common'}),
                                        validate: {
                                            required:
                                                (value) => {
                                                    return Helper.validateEmail(value) ? true : t('text.emailNoValid', {ns: 'common'})
                                                }
                                        },
                                        maxLength: 320
                                    }}
                                    placeholder="Owner Name"
                                    maxLength={320}
                                    title="Filter by Owner"
                                    disabled={applying}
                                />
                            </Stack>
                            <Stack>
                                <PrimaryButton
                                    onClick={() => {
                                        onSearch().then()
                                    }}
                                    text={t('Common.Button.Search', {ns: 'common'})}
                                    title={t('Common.Button.Search', {ns: 'common'})}
                                    disabled={applying}
                                />
                            </Stack>
                        </Stack>
                    </form>
                </FormProvider>

               
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                    {!applying ?
                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", paddingRight: "10px", maxWidth: "100%", height: "40vh" }}>
                            <MarqueeSelection selection={selectionManageClientPlant}>
                                <DetailsList
                                    items={manageClientPlansList}
                                    compact={false}
                                    columns={columns}
                                    getKey={getKey}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    selection={selectionManageClientPlant}
                                    selectionMode={SelectionMode.single}
                                    selectionPreservedOnEmptyClick={false}
                                    enterModalSelectionOnTouch={true}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                />
                            </MarqueeSelection>
                        </div>
                        : <Spinner size={SpinnerSize.medium} label="Loading data, please wait..." ariaLive="assertive" />
                    }
                </div>
            </Stack>
            <Modal isOpen={showAddEditManageClientPlanModal} isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Create">{modalType == ModalType.Add ? "New Manage Client Plans" : "Edit Manage Client Plans"}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideAddEditManageClientPlanModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.body}>
                                    <div key="row" className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            {showMessageError ? <MessageBar messageBarType={MessageBarType.error}>{message} </MessageBar> : null}
                                            {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                        </div>
                                    </div>
                                    {!applying ?
                                        <div key="grid" className="ms-Grid" dir="ltr">
                                            <div key="row1" className="ms-Grid-row">
                                                <div key="col1" className="ms-Grid-col ms-sm4">
                                                    <Toggle label="Active" checked={active} onText="Active" offText="Inactive" onChange={(_, v) => { setActive(v!); }} title="Manage Client Plan is active" />
                                                </div>
                                                <div key="col2" className="ms-Grid-col ms-sm4">
                                                    <Dropdown
                                                        label="Billing Plan"
                                                        options={planOptions}
                                                        selectedKey={selectedBillingPlan?.key}
                                                        errorMessage={selectedBillingPlan?.key == "" ? "This field is required" : ""}
                                                        onChange={onPlanChange}
                                                        title="Select billing plan  to manage client plans." />
                                                    {/* <TextField label="BillingPlanId" type="number" onGetErrorMessage={(val) => getErrorMessage(val, "Billing Plan Length must not be 0.")} required onChange={(_, v) => { setBillingPlanId(Number(v!)); }} value={BillingPlanId.toString()} title="BillingPlanId organization name." /> */}
                                                </div>
                                                <div key="col3" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Comment" maxLength={100} onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setComment(v!); }} value={comment} title="Manage Client Plan comment." />
                                                </div>
                                            </div>
                                            <div key="row2" className="ms-Grid-row">
                                                <div key="col4" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Contact First Name" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setContactFirstName(v!); }} value={contactFirstName} title="Manage Client Plan contact first name." />
                                                </div>
                                                <div key="col5" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Contact Last Name" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setContactLastName(v!); }} value={contactLastName} title="Manage Client Plan contact last name." />
                                                </div>

                                            </div>
                                            <div key="row3" className="ms-Grid-row">
                                                <div key="col6" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Contact Email Address" maxLength={100} onGetErrorMessage={getErrorMessageEmail} required onChange={(_, v) => { setContactEmailAddress(v!); }} value={contactEmailAddress} title="Manage Client Plan contact email address." />
                                                </div>
                                                <div key="col7" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Contact Phone Number" type="" onGetErrorMessage={getErrorMessagePhone} required onChange={(_, v) => { setContactPhoneNumber(v!); }} value={contactPhoneNumber} title="Manage Client Plan contact phone number." />
                                                </div>
                                            </div>
                                            <div key="row4" className="ms-Grid-row">
                                                <div key="col8" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization Name" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationName(v!); }} value={organizationName} title="Manage Client Plan organization address." />
                                                </div>
                                                <div key="col9" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization Address" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationAddress(v!); }} value={organizationAddress} title="Manage Client Plan organization address." />
                                                </div>
                                                <div key="col10" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization Postal Code" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationPostalCode(v!); }} value={organizationPostalCode} title="Manage Client Plan organization postal code." />
                                                </div>
                                            </div>
                                            <div key="row5" className="ms-Grid-row">
                                                <div key="col11" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization Country" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationCountry(v!); }} value={organizationCountry} title="Manage Client Plan organization country." />
                                                </div>
                                                <div key="col12" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization State" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationState(v!); }} value={organizationState} title="Manage Client Plan organization state." />
                                                </div>
                                                <div key="col13" className="ms-Grid-col ms-sm4">
                                                    <TextField label="Organization City" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setOrganizationCity(v!); }} value={organizationCity} title="Manage Client Plan organization city." />
                                                </div>
                                            </div>
                                            <div key="row6" className="ms-Grid-row">
                                                <div key="col14" className="ms-Grid-col ms-sm4">
                                                    <TextField disabled={modalType === ModalType.Edit} label="Owner Name" maxLength={100} onGetErrorMessage={getErrorMessageEmail} required onChange={(_, v) => { setOwnerName(v!); }} value={OwnerName} title="Owner for  filter manage client plants." />
                                                </div>
                                                <div key="col15" className="ms-Grid-col ms-sm4">
                                                    <TextField disabled={modalType === ModalType.Edit} label="Subscription" type="text" onGetErrorMessage={(val) => getErrorMessage(val, "This must not be empty.")} required onChange={(_, v) => { setSubscriptionId(v!); }} value={SubscriptionId} title="number  of SubscriptionId." />
                                                </div>
                                            </div>
                                        </div>
                                        : <Spinner size={SpinnerSize.large} label="Loadding data..." ariaLive="assertive" />}
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                <DialogFooter>
                                    <PrimaryButton onClick={addEditManageClientPlan} disabled={!validForm()} text={modalType == ModalType.Add ? "Add" : "Update"} title={modalType == ModalType.Add ? "Create Manage Client Plan." : "Update Manage Client Plan."} />
                                    <DefaultButton onClick={hideAddEditManageClientPlanModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>
            <Modal isOpen={showDeleteManageClienPlanModal} isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Delete">Delete Manage Client Plan</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeleteManageClientPlanModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div>
                                    Are you sure you want to delete  Manage Client Plans of  owner ?{selectedManageClientPlan.OwnerName}
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={deleteManageClientPlan} text="Delete" title="Delete Manage Client Plan." />
                                    <DefaultButton onClick={hideDeleteManageClientPlanModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>
            <Modal isOpen={showModalNextDueDate} isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Delete">Next Due Date</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideModalNextDueDate}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div key="col" className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                {!applying ?
                                    <DatePicker title="Client plan next due date." isMonthPickerVisible={true} id="nextDueDate" placeholder="Select a date..." ariaLabel="Select a date" label="Next Due Date" formatDate={onFormatDate} value={nextDueDate!} onSelectDate={setNextDueDate as (date: Date | null | undefined) => void} isRequired={true} />
                                    : <Spinner size={SpinnerSize.large} label="Updating data..." ariaLive="assertive" />}
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={() => { createUpdateBillingState("Admin Update Next Due Date", selectedManageClientPlan.BillingPlanId) }} disabled={applying} text="Update " title="Update Next Due Date." />
                                    <DefaultButton onClick={hideModalNextDueDate} disabled={applying} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>
        </>
    );
}

export default ManageClientPlans