import React, { useEffect, useState, useRef } from 'react';
import { Modal } from '@fluentui/react/lib/Modal';
import { Stack } from '@fluentui/react/lib/Stack';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles, ActionButton } from '@fluentui/react/lib/Button';
import { Separator } from '@fluentui/react/lib/Separator';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { IIconProps, Icon, IIconStyles } from '@fluentui/react/lib/Icon';
import { TextField } from '@fluentui/react/lib/TextField';
import { Label } from '@fluentui/react/lib/Label';
import '../../Custom.css'
import { IDropdownOption, Dropdown } from '@fluentui/react/lib/Dropdown';
import Helper, { IDocumentRequest, IDownloadDocumentOnlyTemplateRequest, IFormControl, IListItem } from '../../Helpers/Helper';
import { CheckboxVisibility, DetailsList, IColumn, IDetailsListStyles } from 'office-ui-fabric-react/lib/DetailsList';
import { DetailsListLayoutMode, ConstrainMode, IContextualMenuItem, IContextualMenuProps, IMessageBarStyles, IScrollablePaneStyles, MessageBar, MessageBarType, ScrollablePane, SelectionMode, Spinner, SpinnerSize, Text } from '@fluentui/react';
import { MarqueeSelection, Selection, IObjectWithKey } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { store } from 'react-notifications-component';
import { Callout } from "@fluentui/react/lib/Callout"
import { useBoolean, useConstCallback } from '@uifabric/react-hooks';
import HelpComponent from '../Help/HelpComponent'
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { isElement, truncate } from 'lodash';
import UploadTemplate from '../CustomTemplates/UploadTemplate';
import { decode } from 'base64-arraybuffer'
import { isMobile } from 'react-device-detect';
import { convertToObject } from 'typescript';
import ReactDOM from 'react-dom';
import {useBranding} from "../../hooks/useBranding";
import ListService, {
    IAddListCSVRequest,
    ICreateListDetailsRequest,
    ICreateListMasterRequest, IDeleteListDetailsRequest, IDeleteListMasterRequest,
    IGetListMasterByUserRequest,
    IGetListMasterByUserResponse,
    IGetListsDetailsRequest, IGetListsDetailsResponse, IUpdateListDetailsRequest, IUpdateListMasterRequest
} from "../../services/List";
import HttpRequest from "../../services/HttpRequest";
import {AddItem} from "../List";



const FileDownload = require("js-file-download");
const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};


const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
            class: 'ms-Icon ms-Icon--Mail'
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '100%',
    },
    wrapper: {
        height: '30vh',
        position: 'relative',
        backgroundColor: 'white',
    },
    pane: {
        maxWidth: "30vw",
        borderr: '0 10px  0 0 solid ' + theme.palette.neutralLight,
    },
});
const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
var classmodal = 'bodyModalMax';
function showNotification(Nmessage: string, Ntype: string) {

    switch (Ntype) {
        case "success":
            store.addNotification({
                title: "Success",
                message: Nmessage,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            break;
        case "warning":
            store.addNotification({
                title: "Warning",
                message: Nmessage,
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            break;
        case "error":
            store.addNotification({
                title: "Error",
                message: Nmessage,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            break;
        case "info":
            store.addNotification({
                title: "Information",
                message: Nmessage,
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            break;

        default:
            break;
    }


}
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const CSVlIcon: IIconProps = { iconName: 'ExcelDocument' };


const CSVlIconStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.black,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',

    },
    
    icon: {
        color: theme.palette.greenDark,
        fontSize: '20px'
    },
    iconHovered: {
        color: theme.palette.greenDark
    },
};


export interface ITemplateAudience {
    templateId: string,
    Owners?: string[],
    CoOwner?: string[],
    groupName: string,
    audience: string
    documentCreator: string,
}

const TemplateAudience: ITemplateAudience[] = [];

export interface ITemplates {
    templateId: number,
    userName: string
    name: string,
    expandAriaLabel: string,
    collapseAriaLabel: string,
    links: ITemplatesCustom[]
}
export interface ITemplatesCustom {
    isCustom: boolean,
    key: string
    name: string,
    expandAriaLabel: string,
    url: string
}
export interface IUsers {
    index?: number
    templateId: number,
    name: string,
    role: string
}
export interface ISMTemplate {
    TemplateId: number,
    CoAuthor: string[],
    Users: string[],
    Layout: any,
    Pages: any,
    Owner: string
}

export class Users implements IUsers {
    templateId = -1
    name = "";
    role = "";
    index?= -1
}
export class SMTemplate implements ISMTemplate {
    TemplateId = -1;
    CoAuthor = [""];
    Users = [""];
    Layout = null;
    Pages = null;
    Owner = ""
}

export interface IAllowedUser {
    Id: number
    AccountId: number
    TemplateId: number
    DocumentOnlyId: number
    UserName: string
}

export interface IAudience {
    Id: number
    DocumentOnlyAudienceMasterId: number
    Owner: boolean
    CoOwner: boolean
    CreateDocument: boolean
    Name: string
    Description: string
}



var optionsuser: IUsers[] = []

var SMtemplatecustom = new SMTemplate()
var usersCoAuthor: string[] = []
var userspublic: string[] = []


let optionTemplateAudience: IDropdownOption[] = []
let oldRecords = 0
let addedRecords = 0

const stackTokens = { childrenGap: 15 };

function Lists(props: any) {
    const { branding } = useBranding()
    //const titleId = useId(props.modalId);
    const [requiredName, setrequiredName] = useState(false)
    const [requiredDesc, setrequiredDesc] = useState(false)
    const [requiredAudience, setrequiredAudience] = useState(false)
    const [TemplateId, setTemplateId] = useState("")
    const [disableUpdate, setDisableUpdate] = useState(true)
    const [DisableUpdateUser, setDisableUpdateUser] = useState(true)
    const [file, setFile] = useState<File>();
    const emptyDropdown: IDropdownOption[] = []
    const usertemplate: IUsers[] = []
    const itemList: IGetListsDetailsResponse[] = []
    const emptyList: IGetListMasterByUserResponse[] = []
    const ref = useRef<any>()

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '50vw',
            height: '50vh',
            flex: '4 4 auto',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: isMobile ? null : '15vw',
            height: '5vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        listDialog: {
            width: isMobile ? null : '30vw',
            height: '25vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        addItemList: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        buttonArea: {
        },
        callout: {
            maxWidth: 300,
        },
        inner: {
            height: '100%',
            padding: '0 24px 20px',
        }
    });

    const emptyL: IGetListMasterByUserResponse = {
        Id: 0,
        DocumentOnlyAudienceMaster: 0,
        Name: "",
        Description: ""
    };
    const emptyUser: IAllowedUser = {
        AccountId: 0,
        DocumentOnlyId: 0,
        Id: 0,
        TemplateId: 0,
        UserName: ""
    };
    const emptyItem: IGetListsDetailsResponse = {
        Id: 0,
        DocumentOnlyListMasterId: 0,
        Key: "",
        Value: ""
    };

    const [modalId, setModalId] = useState(-1)
    const [modalTitle, setModalTitle] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [layout, setLayout] = useState("")
    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const [spinnerText, setSpinnerText] = useState("")
    const [listItems, setlistItems] = useState(itemList)
    const [listLists, setListLists] = useState(emptyList)
    const [userSelected, setuserSelected] = useState(emptyUser)
    const [audiences, setAudiences] = useState(emptyDropdown)
    const [myLists, setMyList] = useState(emptyDropdown)
    //const [selectedAudience, setSelectedAudience] = useState<IDropdownOption>();
    const [selectedAudience, setSelectedAudience] = useState("");
    const [showAddItem, setShowAddItem] = useState(false);
    const [selectedAudienceAdd, setSelectedAudienceAdd] = useState<IDropdownOption | undefined>(undefined);
    const [selectedList, setSelectedList] = useState<IGetListMasterByUserResponse>();

    
    const [isLoadingList, setIsLoadingList] = useState(false)
    const [isAddItem, setIsAddItem] = useState(true)

    const [ListName, setListName] = useState("")
    const [ListDesc, setListDesc] = useState("")
    const [showDeleteModal, setshowDeleteModal] = useState(false)
    const [showEditModal, setshowEditModal] = useState(false)
    const [message, setMessage] = useState("");
    const [showMessage, setShowMessage] = useState(false)
    const [showMessageError, setshowMessageError] = useState(false)
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [keyText, setKeyText] = useState("")
    const [valueText, setValueText] = useState("")
    const [showDeleteItemModal, setshowDeleteItemModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(emptyItem)
    const [showMessageItemError, setshowMessageItemError] = useState(false)
    const [messageItem, setMessageItem] = useState("");
    const [modalTitleItem, setModalTitleItem] = useState("")
    const [showAddItemmModal, setshowAddItemmModal] = useState(false)
    const [newList, setnewList] = useState<IGetListMasterByUserResponse>();
    const [applying, setApplying] = useState(false);
    const [requiredUploadFile, setRequiredUploadFile] = useState(false)
    const [uploadingFile, setUploadingFile] = useState(false);
    const [fileName, setFileName] = useState("");
    const [showSuccess, setShowSuccess] = useState(false)
    const [base64File, setBase64File] = useState<string>("");
    const [showUploadList, setshowUploadList] = useState(false)
    const [selectedAudienceListAdd, setSelectedAudienceListAdd] = useState<IDropdownOption>();
    const [DocumentOnlyListMasterId, setDocumentOnlyListMasterId] = useState("")
    const [messageErrorListCSV, setMessageErrorListCSV] = useState("")
    const [showMessageListCSV, setshowMessageListCSV] = useState(false)

    const [showItemFailedMessage, setShowItemFailedMessage] = useState(false)
    const [showItemSuccessMessage, setShowItemSuccessMessage] = useState(false)
    const [itemMessageFail, setItemMessageFail] = useState("")
    const [itemMessageSuccess, setItemMessageSuccess] = useState("")


    useEffect(() => {
        getMyList()

        getAudiences();       

    }, [props.showShare])




    const getErrorMessage = (value: string): string => {
        if (value.length > 0) {
            setrequiredName(true)
        }
        else {
            setrequiredName(false)
            return "Name must not be empty.";
        }

        return ""
    };
    const getErrorMessageDesc = (value: string): string => {
        if (value.length > 0) {
            setrequiredDesc(true)
        }
        else {
            setrequiredDesc(false)
            return "Description must not be  empty.";
        }

        return ""
    };

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Key',
            fieldName: 'Key',
            minWidth: 100,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IGetListsDetailsResponse) => {
                return <>
                    <Text variant={"medium"} title={item.Key} aria-label={item.Key}>{item.Key}</Text>
                </>
            }
        },
        {
            key: 'column2',
            name: 'Value',
            fieldName: 'Value',
            minWidth: 50,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IGetListsDetailsResponse) => {
                return <>
                <Text variant={"medium"} title={item.Value} aria-label={item.Value}>{item.Value}</Text>
                </>               
            }
        },
        {
            key: 'column3',
            name: 'Action',
            fieldName: '',
            minWidth: 20,
            maxWidth: 20,
            isResizable: false,
            isPadded: true,
            onRender: (item: IGetListsDetailsResponse) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={menuPropsValues(item)}
                    title="Click to select from available actions."
                /></div>
            },
        }
    ]
    const columnsList: IColumn[] = [
        {
            key: 'column2',
            name: 'List',
            fieldName: 'Description',
            minWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                const audience = audiences.find(aud => aud.key == item.DocumentOnlyAudienceMaster)
                return <span title={item.Name.trim() + " (" + item.Description.trim() + ") " + audience?.text}>{item.Name.trim() + " (" + item.Description.trim() + ") " + audience?.text}</span>
            },
        },
        {
            key: 'column3',
            name: 'Action',
            fieldName: '',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            isPadded: true,
            headerClassName: "columnAlignedRight",
            onRender: (item: IGetListMasterByUserResponse) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={menuProps(item)}
                    title="Click to select from available actions."
                /></div>
            },
        }
    ]

    const getKey = (item: any, index?: number): string => {
        if (item !== undefined)
            return item.DocumentOnlyAudienceMaster + "_" + item.Id
        //return item.toString()

        return ""
    }
  
    const menuItems = (item: IGetListMasterByUserResponse): IContextualMenuItem[] => {
        return [
            {
                key: 'addItem',
                text: 'Upload List',
                title: 'Create list by uploading from CSV text file.',
                onClick: () => {
                    setDocumentOnlyListMasterId(item.Id.toString())
                    uploadItemList()

                },
            },
            {
                key: 'delete',
                text: 'Delete',
                title: 'Delete list.',
                onClick: () => {
                    setSelectedList(item)
                    DeleteUser(userSelected)
                },
            },
            {
                key: 'edit',
                text: 'Edit',
                title: 'Edit list.',
                onClick: () => {
                    setSelectedList(item)
                    setDocumentOnlyListMasterId(item.Id.toString())
                    editList(item)
                },
            },
        ]
    }

    const menuValues = (_item: IGetListsDetailsResponse): IContextualMenuItem[] => {
        return [
            {
                key: 'edit',
                text: 'Edit',
                title: 'Edit list item.',
                onClick: (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) => {
                    setIsAddItem(false);
                    setSelectedItem(_item)
                    setShowAddItem(true)
                    // EditItem(_item)
                },
            }, {
                key: 'delete',
                text: 'Delete',
                title: 'Delete list item.',
                onClick: () => {
                    DeleteItem(_item)
                },
            },
        ]
    }

    const menuProps = (item: IGetListMasterByUserResponse): IContextualMenuProps => {
        return {
            shouldFocusOnMount: true,
            items: menuItems(item),
        }
    }
    const menuPropsValues = (item: IGetListsDetailsResponse): IContextualMenuProps => {
        return {
            shouldFocusOnMount: true,
            items: menuValues(item),
        }
    }

    async function getAudiences() {

        setSpinnerText("Loading content...")
       
        let {data}: any = await HttpRequest.getWithSession('DocumentOnlyAudienceDetailByUserName', {})
        const response = data

        if (response !== undefined) {
            try {
                var auxAudiences: IDropdownOption[] = []
                response.map((item: any) => {
                    var itemText = item.Name
                    if (item.Name.trim().toLowerCase() === "default") {

                        if (item.Owner) {

                            itemText = itemText + " (" + item.UserName + "(" + item.FullName + ")" + ")"
                        }
                        else {
                            itemText = itemText + (item.OwnerFullName !== null ? " (" + item.OwnerUser + "(" + item.OwnerFullName + ")" + ")" : "")
                        }

                    }
                    else {
                        itemText = itemText + " (" + item.Description + " )"
                    }
                    auxAudiences.push({
                        key: item.DocumentOnlyAudienceMasterId,
                        text: itemText
                    })
                })

                setAudiences(auxAudiences)

            }
            catch (error) {
                console.log(error)
                showNotification("An error occurred: " + error, "error")
            }



        }
        setSpinnerText("")
    }

    async function getMyList() {

        setSpinnerText("Loading content...")

        const request: IGetListMasterByUserRequest = {
            OwnerCoOwnerUser: props.username
        }

        await ListService.getListMasterByUser(request)
            .then((data) => {
                setListLists(data)
            })
            .catch((error) => {
            })
            .finally(() => {
                setSpinnerText("")
            })

    }

    /**
     * Callback function called after close a child modal
     * @param {string} listId
     */
    const onAfterClose = (listId: string) => {
        LoadList(listId).then( )
    }

    async function LoadList(listId: string) {

        setSpinnerText("Loading content...")

        const request: IGetListsDetailsRequest = {
            DocumentOnlyListMasterId: listId
        }

        await ListService.GetListsDetails(request)
            .then((data) => {

                setlistItems(data)

                if (data.length > 0) {
                    addedRecords = data.length - oldRecords
                }
                let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneUserlist")) as Element
                if (element !== null) {
                    element.children[1].classList.add("scrollVisible")
                }

            })
            .catch((error) => {
            })
            .finally(() => {
                setSpinnerText("")
            })

    }



    const hideModal = () => {
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setLayout("")
        setModalTitle("")
        setShowModal(false)
        setshowDeleteModal(false)
        setModalId(-1)
        optionsuser = []
        usersCoAuthor = []
        userspublic = []
        setListName("")
        setListDesc("")
        setrequiredName(false)
        setrequiredDesc(false)
        setnewList(emptyL)
        setlistItems(itemList)
        oldRecords = 0
        addedRecords = 0
        setIsLoadingList(false)
        setIsAddItem(true)
        setShowItemFailedMessage(false)
        setShowItemSuccessMessage(false)
        setItemMessageFail("")
        setItemMessageSuccess("")
        setSelectedAudienceAdd(undefined)
    }

    const addUser = () => {
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalTitle("New List")
        setShowModal(true)
        setModalId(1)
        getAudiences()
    }
    const editList = (item: IGetListMasterByUserResponse) => {
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalTitle("Edit List")
        setshowEditModal(true)
        setModalId(3)
        setSelectedAudience("7")
        setListDesc(item.Description)
        setListName(item.Name)
        setDocumentOnlyListMasterId(item.Id?.toString()!)

        if (item != undefined) {
            LoadList(item.Id?.toString()!)
        }
    }
    const addItem = () => {
        var item = newList!

        if (item !== undefined) {
            editList(item)
        }
    }

    const sendUser = () => {
        switch (modalId) {
            case 1: {
                addList()
            }
                break;
            case 2: {
                deleteList()
            }
                break;
            case 3: {
                editCurrentList()
            }
                break;
        }
        hideModal()
    }

    const DeleteUser = (item: IAllowedUser) => {
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setshowDeleteModal(true)
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalTitle("Delete List")
        setModalId(2)
    }

    const EditItem = (item: IGetListsDetailsResponse) => {
        setIsAddItem(false);
        toggleIsCalloutVisible()
        setSelectedItem(item)
    }

    const DeleteItem = (item: IGetListsDetailsResponse) => {
        setSelectedItem(item)
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setshowDeleteItemModal(true)
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalTitleItem("Delete Item")
    }
    const hideDeleteModal = () => {
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setLayout("")
        setModalTitle("")
        setshowDeleteModal(false)
        setTemplateId("")
        setModalId(-1)
    }
    const hideDeleteItemModal = () => {
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setLayout("")
        setshowDeleteItemModal(false)
    }
    const hideEditModal = () => {
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setLayout("")
        setModalTitle("")
        setshowEditModal(false)
        setTemplateId("")
        setModalId(-1)
        hideAddItemListModal()
        setDocumentOnlyListMasterId("")

    }
    const hideAddItemListModal = () => {
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setLayout("")
        setshowAddItemmModal(false)
        hideModal()
    }
    const onAudienceDropdownChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        getMyList()
    };
    const onAudienceAddDropdownChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedAudienceAdd(item)
    };
    const onListDropdownChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        LoadList(item?.key.toString()!)
    };

    const onChangeValue = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValueText(newValue!)
        },
    );
    
    async function deleteRow() {

        setSpinnerText("Loading content...")

        const request: IDeleteListDetailsRequest = {
            DocumentOnlyListMasterId: selectedList !== undefined ? selectedList?.Id.toString() : "",
            Key: encodeURIComponent(selectedItem.Key)
        }

        await ListService.DeleteListDetails(request)
            .then((data) => {
                LoadList(selectedList?.Id.toString()!)
            })
            .catch((error) => {
                setshowMessageItemError(true)
                setMessageItem("An error occurred: " + Helper.GetErrorMessage(error))
                setTimeout(function () {
                    setshowMessageItemError(false)
                }, 5000);
            })
            .finally(() => {
                hideDeleteItemModal()
                setSpinnerText("")
            })

    }

    async function addList() {

        const request: ICreateListMasterRequest = {
            Description: ListDesc,
            DocumentOnlyAudienceMaster: selectedAudienceAdd !== undefined? selectedAudienceAdd?.key.toString() : "",
            Name: ListName,
            UniqueId: undefined
        }

        await ListService.CreateListMaster(request)
            .then((data) => {
                getMyList()
            })
            .catch((error) => {
                setshowMessageError(true);
                setMessage(Helper.GetErrorMessage(error))

                setTimeout(function () {
                    setshowMessageError(false)
                    setMessage("")
                }, 5000);
            })
            .finally(() => {
            })

    }

    async function deleteList() {

        setSpinnerText("Loading content...")

        const request: IDeleteListMasterRequest = {
            DocumentOnlyAudienceMaster: selectedList !== undefined ? String(selectedList.DocumentOnlyAudienceMaster) : "",
            DocumentOnlyListMasterId: selectedList !== undefined ? String(selectedList.Id) : "",
            Name: selectedList !== undefined ? selectedList.Name : ""
        }

        await ListService.DeleteListMaster(request)
            .then((data) => {
                getMyList()
                hideDeleteModal()

            })
            .catch((error) => {
                setshowMessageError(true)
                setMessage("An error occurred: " + Helper.GetErrorMessage(error))
                setTimeout(function () {
                    setshowMessageError(false)
                }, 5000);
            })
            .finally(() => {
                setSpinnerText("")
            })
    }

    async function editCurrentList() {

        const request: IUpdateListMasterRequest = {
            Name: ListName,
            Description: ListDesc,
            DocumentOnlyAudienceMaster: selectedList !== undefined ? selectedList.DocumentOnlyAudienceMaster.toString() : "",
            DocumentOnlyListMasterId: selectedList !== undefined ? selectedList.Id.toString() : ""
        }

        await ListService.UpdateListMaster(request)
            .then((data) => {
                getMyList()
                hideEditModal()
            })
            .catch()
            .finally(() => {
            })
    }
    
    const getBase64 = (file: File) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64File(reader.result!.toString().split(',')[1]);
        };
        reader.onloadend = () => {
            setRequiredUploadFile(true)
        }
    }

    const uploadFile = useConstCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        setUploadingFile(true)

        if (ev.target.files != null && ev.target.files.length > 0) {
            setMessageErrorListCSV("")
            setshowMessageListCSV(false)
            var mimeType = ev.target.files![0].type;

            if (mimeType.indexOf("vnd.ms-excel") >= 0 || mimeType.indexOf(".txt") > 0 || mimeType.indexOf("text/csv") >= 0) {
                var name = ev.target.files![0].name;
                setRequiredUploadFile(true)
                setFileName(name);
                setFile(ev.target.files![0])
                getBase64(ev.target.files![0]);
            }
            else {
                setshowMessageListCSV(true)
                setMessageErrorListCSV("This type of file is not valid. Must be a CSV with a .csv extenstion.")
                setRequiredUploadFile(false)
            }
        }
        else {
            setShowMessage(true)
            setMessage("File not found")
            setRequiredUploadFile(false)
        }
        setUploadingFile(false)
    });
    async function goToBuilder() {
        
        addedRecords = 0
        if (listItems.length != undefined) {
            oldRecords = listItems.length
        }
        setRequiredUploadFile(true)
        setApplying(true)

        const request: IAddListCSVRequest = {
            base64File: base64File,
            DocumentOnlyListMasterId: DocumentOnlyListMasterId
        }

        await ListService.AddListCSV(request)
            .then(async (data) => {
                await LoadList(DocumentOnlyListMasterId).then(x => {
                    setMessageErrorListCSV("")
                    setshowMessageListCSV(false)
                    setShowSuccess(true)
                    if (addedRecords > 0) {
                        setMessage("Added " + addedRecords + " items successfully")
                    } else {
                        setMessage("The items were updated successfully")
                    }

                    setTimeout(function () {
                        setShowSuccess(false)
                        addedRecords = 0
                        getMyList()
                        hideUploadList()
                    }, 2000);
                })
            })
            .catch((error) => {
                setShowSuccess(false)
                setMessage("")
                setMessageErrorListCSV(Helper.GetErrorMessage(error))
                setshowMessageListCSV(true)
                setRequiredUploadFile(false)
                addedRecords = 0
            })
            .finally(() => {
                setApplying(false)
            })
    }
    const hideUploadList = () => {
        setshowMessageListCSV(false)
        setMessage("")
        setShowMessage(false)
        setShowSuccess(false)
        setFileName("")
        setshowMessageListCSV(false)
        setApplying(false)
        setshowUploadList(false)
        addedRecords = 0
        oldRecords = 0
        if (modalTitle !== "Edit List") {
            setDocumentOnlyListMasterId("")
        }
    }

    const onAudienceListAddDropdownChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedAudienceListAdd(item)
    };

    const uploadItemList = () => {
        setshowUploadList(true)
        oldRecords = 0
        addedRecords = 0
    }

    const DownloadExample = () => {

        const download = async () => {
            const name = "Example.csv"
            var Base64 = { blob: "77u/S2V5LFZhbHVlDQoxLHRlc3QwMQ0KMix0ZXN0MDINCg==" }
            const decoded = decode(Base64.blob)
            try {
                const file = new Blob(
                    [decoded], { type: "application/vnd.ms-excel" });
                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(file);
                a.download = name;
                a.click();
                setShowSuccess(true)
                setMessage("Example successfully downloaded. Please check Downloads folder!")
                setTimeout(function () {
                    setShowSuccess(false);
                    setMessage("")
                }, 3000);
            } catch (error) {
                console.log(error)
            }
        }
        download()
    }

    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <label className="ms-fontSize-18"  >{props.modalTitle}</label><br />
                        <div>Add a New List or Edit an existing List.</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <label className="ms-fontSize-18">{props.modalTitle}</label>
                    </div>
                </div>
                : null
            }
            <Stack tokens={stackTokens}>
                {showMessageError ? <MessageBar messageBarType={MessageBarType.error}>{message} </MessageBar> : null}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ActionButton title="Add List" text="Add List"
                            iconProps={{ iconName: 'AddToShoppingList' }} onClick={addUser} />
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                    <div data-is-scrollable className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "40vh" }}>

                        <DetailsList
                            items={listLists || []}
                            compact={false}
                            columns={columnsList}
                            getKey={getKey}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            isHeaderVisible={true}
                            checkboxVisibility={CheckboxVisibility.hidden}
                        />
                    </div>
                </div>
            </Stack>
            <Modal
                isOpen={showModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={modalTitle}>{modalTitle}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                    {showMessageItemError ? <MessageBar messageBarType={MessageBarType.error}>{messageItem} </MessageBar> : null}
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.listDialog}>
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="ms-Grid-row">
                                                <Dropdown required
                                                    placeholder=""
                                                    label="Audience:"
                                                    options={audiences}
                                                    onChange={onAudienceAddDropdownChange}
                                                    title="Select audience that will administrate list."
                                                />
                                                <TextField label="Name:" onGetErrorMessage={getErrorMessage} maxLength={50} required onChange={(_, v) => { setListName(v!); }} value={ListName} title="Enter list name." />
                                                <TextField label="Description:" onGetErrorMessage={getErrorMessageDesc} maxLength={1000} required onChange={(_, v) => { setListDesc(v!); }} value={ListDesc} title="Enter list description." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={sendUser} disabled={!requiredName || !requiredDesc || selectedAudienceAdd === undefined } text="Add" title="Add list." />
                                <DefaultButton onClick={hideModal} text="Cancel" title="Close." />
                            </DialogFooter>
                        </div>
                    </div>
                    <br />
                </div>
            </Modal>
            <Modal
                isOpen={showDeleteModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={modalTitle}>{modalTitle}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeleteModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.User}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className="ms-Grid-row">
                                            Are you sure you want to delete list {selectedList?.Name}?
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <DialogFooter>
                            <PrimaryButton onClick={sendUser} text="Delete" title="Delete list." />
                            <DefaultButton onClick={hideDeleteModal} text="Cancel" title="Close." />
                        </DialogFooter>
                    </div>
                </div>
                <br />
            </Modal>
            <Modal
                isOpen={showEditModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={modalTitle}>{modalTitle}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideEditModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.listDialog}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className="ms-Grid-row">
                                            <TextField label="Description:" onGetErrorMessage={getErrorMessageDesc} maxLength={1000} required onChange={(_, v) => { setListDesc(v!); }} value={ListDesc} title="Enter description." />
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className={contentStyles.buttonArea + " ms-Grid-row"} >
                                        <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
                                            <ActionButton title="Add item" text="Add Item" disabled={selectedAudience != undefined ? false : true}
                                                iconProps={{ iconName: 'Add' }} onClick={() => {
                                                setSelectedItem({
                                                    Id: 0,
                                                    Key: "",
                                                    Value: "",
                                                    DocumentOnlyListMasterId: Number(DocumentOnlyListMasterId)
                                                })
                                                setIsAddItem(true);
                                                setShowAddItem(true)
                                                }} />
                                        </div>
                                        <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7">
                                            <ActionButton title="Upload List" text="Upload Excel list." iconProps={{ iconName: 'AddToShoppingList' }} onClick={uploadItemList} />
                                        </div>


                                    </div>
                                </div>

                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className={classNames.wrapper}>
                                            {!isLoadingList ?

                                            <ScrollablePane styles={scrollablePaneStyles} id="ScrollPaneUserlist" key="ScrollPaneUserlist" >
                                            {/* <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "30vh" }} >*/}



                                                <DetailsList
                                                    items={listItems || []}
                                                    compact={false}
                                                    columns={columns}
                                                    getKey={getKey}
                                                    setKey="set"
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    isHeaderVisible={true}
                                                    selectionMode={SelectionMode.none}
                                                    checkboxVisibility={CheckboxVisibility.hidden}
                                                />

                                            {/*</div>*/}
                                                </ScrollablePane>
                                                :
                                            <Stack>
                                                <Label />
                                                <Label />
                                                <Label />
                                                <Spinner size={SpinnerSize.medium} label="Loading List..." ariaLive="assertive" />
                                            </Stack>
                                            }
                                        </div>
                                    </div></div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <DialogFooter>
                            <PrimaryButton onClick={sendUser} disabled={!requiredDesc} text="Update" title="Update list." />
                            <DefaultButton onClick={hideEditModal} text="Close" title="Close." />
                        </DialogFooter>
                    </div>
                </div>
                <br />

            </Modal>
            <Modal
                isOpen={showDeleteItemModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={modalTitleItem}>{modalTitleItem}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeleteItemModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.User}>
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="ms-Grid-row">
                                                Are you sure you want to delete {selectedItem?.Value}?
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={deleteRow} text="Delete" title="Delete row." />
                                <DefaultButton onClick={hideDeleteItemModal} text="Cancel" title="Close." />
                            </DialogFooter>
                        </div>
                    </div>
                    <br />
                </div>
            </Modal>
            <Modal
                isOpen={showAddItemmModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={modalTitleItem}>{"Add Item List"}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideAddItemListModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.User}>
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="ms-Grid-row">
                                                Do you want to add {newList?.Name}?
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={addItem} text="Yes" title="Add list" />
                                <DefaultButton onClick={hideAddItemListModal} text="No" title="Do not add list." />
                            </DialogFooter>
                        </div>
                    </div>
                    <br />
                </div>
            </Modal>
            <Modal
                isOpen={showUploadList}
                isBlocking={true}>
                <div className={contentStyles.header}>
                    <span id="CloseUploadList" key="CloseUploadList">{"Upload Items"}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup "
                        onClick={() => {
                            hideUploadList()
                        }}
                        title="Close."
                    />
                </div>
                <div className={contentStyles.addItemList}>
                    <div className="ms-Grid" dir="ltr">
                        {!applying ?
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {showMessageListCSV ? <MessageBar styles={messageBarFailedStyles}>{messageErrorListCSV}</MessageBar> : null}
                                    {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                    <div className="ms-Grid-row">
                                        <br />
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{ textAlign: "right" }}></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{ textAlign: "right" }}>
                                            <ActionButton title="Download the example list to use as a reference." text="Example list" styles={CSVlIconStyles} onClick={DownloadExample} iconProps={CSVlIcon} />
                                        </div>
                                        <br />
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <span>
                                                <ul>
                                                    <li>Lists are limited to 2000 rows.</li>
                                                    <li>Lists must be a CSV text file.</li>
                                                    <li>Lists must be in our specified format. Click link for example.</li>
                                                </ul>
                                            </span>
                                        </div>
                                        <br />
                                    </div>
                                    <br />
                                    <br />


                                    <div className="ms-Grid-row">

                                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                            <label htmlFor="file"><div className="divHover divToolBoxItem" style={{color: branding.theme.palette.white, backgroundColor: branding.theme.palette.themePrimary}}>Choose File</div></label>
                                            <input accept=".csv" className="fileButton" id="file" type="file" name="file" onChange={uploadFile} disabled={DocumentOnlyListMasterId === ""} placeholder="Select file to upload." />
                                        </div>
                                        <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ paddingRight: "0px" }}>
                                            <TextField key="tesxtFilename" disabled value={fileName} title="File name." />
                                        </div>
                                    </div>
                                    {uploadingFile ?
                                        <ProgressIndicator description={"Uploading... "} />
                                        : null
                                    }
                                    <div className="ms-Grid-row" >
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <DialogFooter>
                                                <PrimaryButton disabled={!requiredUploadFile} onClick={goToBuilder} text="Upload" title="Upload selected list." />
                                            </DialogFooter>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            : <Spinner size={SpinnerSize.medium} label="Applying changes..." ariaLive="assertive" />
                        }
                    </div>
                </div>
            </Modal>

            {showAddItem &&
                <>
                    <AddItem item={selectedItem} isAddItem={isAddItem} setShowModal={setShowAddItem} onAfterClose={onAfterClose}
                             showModal={showAddItem}/>
                </>
            }
           
            
            
        </>
    )
}

export default Lists
