import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DOMPurify from 'dompurify';




import '../../Custom.css';
import {
    IBasePickerSuggestionsProps,
    IPersonaProps,
    Label,
    Panel,
    PanelType,
    Shimmer,
    Stack,
    StackItem
} from "@fluentui/react";
import {DefaultButton, IconButton, PrimaryButton} from "@fluentui/react/lib/Button";
import {Text} from "@fluentui/react/lib/Text";
import {TextField} from '@fluentui/react/lib/TextField';
import HelpComponentRelative from "../Help/HelpComponentRelative";
import {Separator} from "@fluentui/react/lib/Separator";
import {Modal} from "@fluentui/react/lib/Modal";
import {IIconProps} from "@fluentui/react/lib/Icon";
import {contentStyles, iconButtonStyles, nonShrinkingStackItemStyles} from "./Style"
import StatusMessage from "../../share/StatusMessage";
import {Spinner, SpinnerSize} from "@fluentui/react/lib/Spinner";

import {FormProvider, useForm} from "react-hook-form";
import {panelHeader} from "../Preview/Style";
import {isMobile} from "react-device-detect";
import Helper from "../../Helpers/Helper";
import FormTemplateLinkService, {
    IEmailServiceRequest,
    IgetDocumentOnlyTemplateIDBasicRequest,
    ISendTemplateLinkRequest
} from "./FormTemplateLink.service";
import {RHFPeoplePickerControl} from "../RHFControls/RHFPeoplePickerControl";
import {RHFTextBoxControl} from "../RHFControls/RHFTextBoxControl";
import {IPanelProps} from "@fluentui/react/lib/Panel";
import {useBranding} from "../../hooks/useBranding";
import ApplicationServiceEmailService, {IGetServiceEmailByOwnerRequest} from "../../services/ApplicationServiceEmail";

type Properties = {
    showModal: boolean
    onDismissModal: () => void;
    templateId: string
    ownerUser: string
    name: string
    documentOnlyPrimaryId: number
}

function FormTemplateLink({
                              showModal,
                              templateId,
                              name,
                              onDismissModal,
                              documentOnlyPrimaryId,
                              ownerUser
                          }: Properties) {

    const { branding } = useBranding()
    const {t} = useTranslation(['formtemplatelink', 'common'])
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const [fromApplicationServiceEmail, setFromApplicationServiceEmail] = useState("")
    const formPublishWebform = useForm();
    const [isSent, setIsSent] = useState(false)
    const [isLoadingEmailService, setIsLoadingEmailService] = useState(false)
    const [isLoadingPeople, setIsLoadingPeople] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [messageSent, setMessageSent] = useState(0)
    const [txtMessageError, setTxtMessageError] = useState("")
    const [peopleList, setPeopleList] = useState<IPersonaProps[]>([])
    const [selectedPeopleList, setSelectedPeopleList] = useState<IPersonaProps[]>([])

    const suggestionProps: IBasePickerSuggestionsProps = {
        mostRecentlyUsedHeaderText: t('text.mostRecentlyUsedHeaderText', {ns: 'formtemplatelink'}),
        suggestionsContainerAriaLabel: t('text.suggestionsContainerAriaLabel', {ns: 'formtemplatelink'}),
        suggestionsHeaderText: t('text.mostRecentlyUsedHeaderText', {ns: 'formtemplatelink'}),
    };

    useEffect(() => {

        if (showModal) {
            initFields().then()
        }

    }, [showModal])

    /**
     * initFields
     */
    const initFields = async () => {
        formPublishWebform.clearErrors()
        formPublishWebform.reset()

        setIsLoadingPeople(true)
        setIsLoadingEmailService(true)
        setMessageSent(0)
        setIsSent(false)
        setIsSaving(false)
        getApplicationServiceEmail().then()
        getApplicationAudience().then()
        setSelectedPeopleList([])
    }

    /**
     * onBeforeDismiss
     */
    const onBeforeDismiss = () => {
        onDismissModal()
    }

    /**
     * getApplicationAudience
     */
    const getApplicationAudience = async () => {
        setPeopleList([])

        const request: IgetDocumentOnlyTemplateIDBasicRequest = {
            TemplateId: templateId,
            Autoprovision: true,
            ignoreDocument: true
        }

        FormTemplateLinkService.GetDocumentOnlyTemplateIDBasic(request)
            .then(async (data) => {
                if (data.DocumentOnlyAudienceId !== undefined && data.DocumentOnlyAudienceId !== null) {
                    await FormTemplateLinkService.GetDocumentOnlyAudienceDetailByAudienceMasterId({
                        AvoidCache: true,
                        AudienceMasterId: data.DocumentOnlyAudienceId
                    })
                        .then(async (data) => {
                            if (data.length > 0) {

                                const people: IPersonaProps[] = data.map<IPersonaProps>(item => {
                                    return {text:  item.FullName?.trim().length === 0? item.UserName : item.FullName, secondaryText: item.UserName}
                                })
                                setPeopleList(people)
                            }
                        })
                }
            })
            .catch()
            .finally(() => {
                setIsLoadingPeople(false)
            })

    }

    /**
     * getApplicationServiceEmail
     */
    const getApplicationServiceEmail = async () => {
        setFromApplicationServiceEmail(await Helper.getShareEmail())
        const request: IGetServiceEmailByOwnerRequest = {
            TemplateId: templateId,
            Owner: ownerUser
        }

        ApplicationServiceEmailService.GetServiceEmailByOwner(request)
            .then(async (data) => {

                if (data.length > 0) {
                    setFromApplicationServiceEmail(data[0].ServiceEmail)

                } else {
                    setFromApplicationServiceEmail(await Helper.getShareEmail())
                }

            })
            .catch(() => {

            })
            .finally(() => {
                setIsLoadingEmailService(false)
            })
    }

    /**
     * Send emails
     */
    const sendEmail = async () => {

        await formPublishWebform.handleSubmit(async () => {

            setIsSaving(true)
            
            const subject = DOMPurify.sanitize(formPublishWebform.getValues("txtSubject").trim(), { USE_PROFILES: { html: false } });
            const message = DOMPurify.sanitize(formPublishWebform.getValues("txtMessage").trim(), { USE_PROFILES: { html: false } });

            const request: ISendTemplateLinkRequest = {

                    Personas: selectedPeopleList.map(item => item.secondaryText).join(","),
                    DocumentOnlyPrimaryId: documentOnlyPrimaryId,
                    TemplateId: templateId,
                    Subject: subject,
                    Message: message,
                    Description: name
                }

                FormTemplateLinkService.PostSendTemplateLink(request)
                    .then(async (data) => {
                        if (data.success) {
                            setMessageSent(1)
                            setIsSent(true)
                            setTimeout(function () {
                                onBeforeDismiss();
                            }, 500);

                        } else {
                            setMessageSent(-1)
                            setTxtMessageError(t('text.errorMessage1', {ns: 'common', Error: data.messageError}))
                        }

                    })
                    .catch(() => {

                    })
                    .finally(() => {
                        setIsSaving(false)
                    })

            }, () => {

            }
        )();

    }

    /**
     * FormPublishLink
     * @constructor
     */
    const FormPublishLink = () => {
        return (<>

                <StackItem>

                    {messageSent === 0 &&
                        <StatusMessage status={"info"} hasTimer={false} truncated={true}
                                       isMultiline={true}
                                       dismiss={false}
                                       setShowMessage={undefined}>
                            <Text variant={"medium"}>{t('messages.infoEmail', {ns: 'formtemplatelink'})}</Text>
                        </StatusMessage>
                    }

                    {messageSent === 1 &&

                        <StatusMessage status={"success"} hasTimer={false} truncated={true}
                                       isMultiline={true}
                                       dismiss={true}
                                       setShowMessage={() => {
                                           setMessageSent(0)
                                       }}>
                            <Text variant={"medium"}>{t('messages.successSent', {ns: 'formtemplatelink'})}</Text>
                        </StatusMessage>
                    }

                    {messageSent === -1 &&

                        <StatusMessage status={"error"} hasTimer={false} truncated={true}
                                       isMultiline={true}
                                       dismiss={true}
                                       setShowMessage={() => {
                                           setMessageSent(0)
                                       }}>
                            <Text variant={"medium"}>{t('messages.errorSend', {
                                ns: 'formtemplatelink',
                                Description: txtMessageError
                            })}</Text>
                        </StatusMessage>
                    }
                    <Separator/>
                </StackItem>

                <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                       style={{
                           overflow: "hidden",
                           overflowY: "auto",
                           height: "50vh",
                       }}>


                    <FormProvider {...formPublishWebform}>
                        <form>

                            {isLoadingEmailService || isLoadingPeople ?
                                <>
                                    <Shimmer styles={{root: {marginTop: 16}}}/>
                                    <Shimmer styles={{root: {marginTop: 16}}}/>
                                    <Separator/>
                                    <Shimmer styles={{root: {marginTop: 16}}}/>
                                    <Shimmer styles={{root: {marginTop: 16}}}/>

                                </>
                                :
                                <>
                                    <TextField readOnly={true} disabled
                                               label={t('label.emailFrom', {ns: 'formtemplatelink'})}
                                               value={fromApplicationServiceEmail}/>
                                    <RHFPeoplePickerControl
                                        readOnly={isSaving}
                                        label={t('label.emailTo', {ns: 'formtemplatelink'})}
                                        pickerSuggestionsProps={suggestionProps}
                                        control={formPublishWebform.control}
                                        setValue={formPublishWebform.setValue}
                                        getValues={formPublishWebform.getValues}
                                        trigger={formPublishWebform.trigger}
                                        id={"to"}
                                        rules={{
                                            required: t('text.fieldRequired', {ns: 'common'})

                                        }}
                                        onCallback={(personas: IPersonaProps[]) => {
                                            setSelectedPeopleList(personas)
                                        }}
                                        peopleList={peopleList}
                                        selectedPeopleList={selectedPeopleList}
                                        setSelectedPeopleList={setSelectedPeopleList}
                                    
                                    />


                                    <RHFTextBoxControl id="txtSubject"
                                                       disabled={isSaving}
                                                       readOnly={isSaving}
                                                       control={formPublishWebform.control}
                                                       setValue={formPublishWebform.setValue}
                                                       getValues={formPublishWebform.getValues}
                                                       trigger={formPublishWebform.trigger}
                                                       label={t('label.emailSubject', {ns: 'formtemplatelink'})}
                                                       title={t('title.emailSubject', {ns: 'formtemplatelink'})}
                                                       placeholder={t('placeholder.emailSubject', {ns: 'formtemplatelink'})}
                                                       rules={{
                                                           required: t('text.fieldRequired', {ns: 'common'}),
                                                           validate: {
                                                               required:
                                                                   (value) => {
                                                                       return !!value.trim() || t('text.fieldRequired', {ns: 'common'})
                                                                   }
                                                           },
                                                           maxLength: 200
                                                       }}

                                    />
                                    <RHFTextBoxControl id="txtMessage"
                                                       disabled={isSaving}
                                                       readOnly={isSaving}
                                                       control={formPublishWebform.control}
                                                       setValue={formPublishWebform.setValue}
                                                       getValues={formPublishWebform.getValues}
                                                       trigger={formPublishWebform.trigger}
                                                       label={t('label.emailMessage', {ns: 'formtemplatelink'})}
                                                       multiline
                                                       resizable={false}
                                                       rows={6} maxLength={5000}
                                                       rules={{
                                                           required: t('text.fieldRequired', {ns: 'common'}),
                                                           validate: {
                                                               required:
                                                                   (value) => {
                                                                       return !!value.trim() || t('text.fieldRequired', {ns: 'common'})
                                                                   }
                                                           },
                                                           maxLength: 5000
                                                       }}
                                                       placeholder={t('placeholder.emailMessage', {ns: 'formtemplatelink'})}
                                                       title={t('title.emailMessage', {ns: 'formtemplatelink'})}/>

                                    <Label>{documentOnlyPrimaryId>-1? t('text.noteDocument', {ns: 'formtemplatelink'}):t('text.note', {ns: 'formtemplatelink'})}</Label>
                                </>
                            }


                        </form>
                    </FormProvider>


                </Stack>

                {/*-- START FOOTER --*/}
                <Separator></Separator>
                <Stack horizontal style={{overflow: "hidden"}} styles={nonShrinkingStackItemStyles}>
                    <StackItem grow={1}>&nbsp;</StackItem>

                    {isSaving &&
                        <>
                            <StackItem>
                                <Spinner size={SpinnerSize.medium} labelPosition="left"
                                         label={t('text.spinnerSending', {ns: 'common'})}/>
                            </StackItem>
                        </>
                    }

                    <StackItem>

                        <PrimaryButton disabled={isSaving || isSent || isLoadingEmailService || isLoadingPeople}
                        
                                       style={{marginLeft: 8}}
                                       onClick={sendEmail}
                                       text={t('Common.Button.Send', {ns: 'common'})}
                                       title={t('title.btnSend', {ns: 'formtemplatelink'})}/>


                        <DefaultButton
                            onClick={() => {
                                onBeforeDismiss()
                            }}
                            style={{marginLeft: 8}}
                            text={t('Common.Button.Close', {ns: 'common'})}
                            title={t('Common.Button.Close', {ns: 'common'})}/>
                    </StackItem>
                </Stack>
                {/*-- END FOOTER --*/}

            </>
        )
    }

    return (
        <>
            {!isMobile ?

                <Modal
                    isOpen={showModal}
                    isBlocking={true}
                    containerClassName={contentStyles(branding.theme).containerRecord}
                >
                    {/*-- START HEADER --*/}
                    <Stack horizontal className={contentStyles(branding.theme).header}>


                        <StackItem grow={1}
                                   styles={{
                                       root: {
                                           textOverflow: "ellipsis",
                                           whiteSpace: "nowrap",
                                           overflow: "hidden"
                                       }
                                   }}>
                            <Text variant={"xLarge"} id={templateId}
                                title={ documentOnlyPrimaryId> -1 ? t('title.publishLinkDocument', {ns: 'formtemplatelink', name: name, interpolation: { 'escapeValue': false } }) : t('title.publishLink', {ns: 'formtemplatelink', name: name, interpolation: { 'escapeValue': false } })}>
                                {documentOnlyPrimaryId > -1 ? t('title.publishLinkDocument', {ns: 'formtemplatelink', name: name, interpolation: { 'escapeValue': false } }) : t('title.publishLink', {ns: 'formtemplatelink', name: name, interpolation: { 'escapeValue': false } })}
                            </Text>
                        </StackItem>
                        <Stack horizontal styles={{root: {alignItems: "center"}}}>
                            <HelpComponentRelative
                                headline={ documentOnlyPrimaryId>-1? t('title.publishLinkDocumentHelp', {ns: 'formtemplatelink'}): t('title.publishLinkHelp', {ns: 'formtemplatelink'})}
                                text={t('text.help', {ns: 'formtemplatelink'})}
                                location=""
                            />
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                                onClick={() => {
                                    onBeforeDismiss()
                                }}
                                title={t('Common.Button.Close', {ns: 'common'})}
                            />
                        </Stack>
                    </Stack>
                    <Separator/>

                    {/*-- END HEADER --*/}

                    {/*-- START BODY --*/}
                    <Stack horizontal tokens={{padding: 8}} style={{overflow: "hidden"}}>

                        {/*-- START CONTENT --*/}
                        <Stack grow={1} style={{overflow: "hidden", padding: "0px 8px 8px 8px"}}>
                            <FormPublishLink/>
                        </Stack>
                        {/*-- END CONTENT --*/}

                    </Stack>
                    {/*-- END BODY --*/}

                </Modal>

                :
                <>
                    {/*-- START PANEL --*/}
                    <Panel

                        headerText={documentOnlyPrimaryId>-1? t('title.publishLinkDocument', {ns: 'formtemplatelink', name: name}): t('title.publishLink', {ns: 'formtemplatelink', name: name})}
                        headerTextProps={panelHeader}
                        isBlocking={false}
                        isOpen={showModal}
                        onDismiss={onBeforeDismiss}
                        onRenderHeader={(props?: IPanelProps): JSX.Element | null => {
                            return (<>
                                <Stack horizontal styles={{root: {width: "100%"}}}>
                                    <StackItem grow={1} id={props?.id}
                                               styles={{root: {paddingLeft: 24, paddingRight: 24}}}>
                                        <Text variant={"xLarge"}
                                              styles={{root: {fontWeight: 500, color: branding.theme.palette.themePrimary}}}>
                                            {props?.headerText}
                                        </Text>

                                    </StackItem>
                                    <StackItem>
                                        <HelpComponentRelative
                                            headline={documentOnlyPrimaryId>-1? t('title.publishLinkDocumentHelp', {ns: 'formtemplatelink'}): t('title.publishLinkHelp', {ns: 'formtemplatelink'})}
                                            text={t('text.help', {ns: 'formtemplatelink'})}
                                            location=""
                                        />
                                    </StackItem>

                                </Stack>


                            </>)
                        }}
                        closeButtonAriaLabel={t('Common.Button.Close', {ns: 'common'})}
                        type={PanelType.smallFluid}
                        styles={{
                            content: {
                                display: "flex",
                                flexGrow: 1
                            },
                            scrollableContent: {
                                display: "flex",
                                flexGrow: 1
                            }

                        }}
                    >
                        <Stack grow={1}>
                            <Stack grow={0}>
                                <Separator/>
                            </Stack>
                            <FormPublishLink/>
                        </Stack>
                    </Panel>
                    {/*-- END PANEL --*/}
                </>

            }


        </>
    )
}

export default FormTemplateLink 
