import { IFormControl } from './../Helpers/Helper';
import HttpRequest from "./HttpRequest"

const DataPanelService = {
    cloneDocument: async (request: CloneDocumentRequest) => {
        return HttpRequest.postWithSession('CloneDocument', {
            ...request, Lists: '', TemplateXML: "",
            onBehalf: "",
            DocumentonlyPrimaryId: "-1"
        })
    },
}
interface CloneDocumentRequest {

    TemplateId: string
    TargetCulture: string
    Content: IFormControl[]
    DocumentId: string
    TemplateVersion: string
}
export default DataPanelService