import { DatePicker, IconButton, PrimaryButton, TextField } from "@fluentui/react";
import React from "react";
import { cancelIcon } from "../../AdminLogs.data";
import useFilterLogs from "../hooks/useFilterLogs";
type FilterLogsProps = {
    handleSearch: any
}

const FiltersLog = (props: FilterLogsProps) => {
    const { filter, handleDateChange, onFormatDate, onTexfieldChange } = useFilterLogs()
    return (
        <>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm3">
                    <DatePicker maxDate={filter.DateTo} title="Select Date From." isMonthPickerVisible={true} id="logDateFrom" placeholder="Select Date" ariaLabel="Select a date" label="Date From" formatDate={onFormatDate} value={filter.DateFrom} onSelectDate={newDate => { handleDateChange(newDate, "DateFrom") }} />
                </div>
                <div className="ms-Grid-col ms-sm1">
                    <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel="Clean" onClick={e => { handleDateChange(undefined, "DateFrom") }} title="Clean" id='CleanFromDateLog' />
                </div>
                <div className="ms-Grid-col ms-sm3">
                    <DatePicker minDate={filter.DateFrom} title="Select Date To." isMonthPickerVisible={true} id="logDateTo" placeholder="Select Date" ariaLabel="Select a date" label="Date To" formatDate={onFormatDate} value={filter.DateTo} onSelectDate={newDate => { handleDateChange(newDate, "DateTo") }} />
                </div>
                <div className="ms-Grid-col ms-sm1">
                    <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel="Clean" onClick={e => { handleDateChange(undefined, "DateTo") }} title="Clean" id='CleanToDateLog' />
                </div>
                <div className="ms-Grid-col ms-sm4" >
                    <TextField name='TargetUserName' acceptCharset='' label="User Email" maxLength={50} onChange={onTexfieldChange} value={filter.TargetUserName} placeholder="Enter User Email" title="Enter User Email." />
                </div>

            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm4" >
                    <TextField name='ApiEvent' acceptCharset='' label="API Event" maxLength={50} onChange={onTexfieldChange} value={filter.ApiEvent} placeholder="Enter API Event" title="Enter API Event." />
                </div>
                <div className="ms-Grid-col ms-sm2" style={{ paddingTop: '28px' }}>
                    <PrimaryButton key="LogSearch" onClick={props.handleSearch} allowDisabledFocus title="Search." text="Search" />
                </div>
            </div>

        </>
    )
}

export default FiltersLog