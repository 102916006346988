import {IDropdownOption} from "@fluentui/react/lib/Dropdown";
import {IDocumentOnlyStatisticsResponse} from "../../services/document";

export interface IDefaultValues {

    Email: string
    FirstName: string
    MiddleName: string
    LastName: string
    Title: string
    TimeZone: any
    Language: any
}

export const defaultValues: IDefaultValues = {
    Email: "",
    FirstName: "",
    Language: {
        label: 'en',
        key: 'en'
    },
    LastName: "",
    MiddleName: "",
    TimeZone: {
        label: 'Eastern Standard Time',
        key: 'Eastern Standard Time'
    },
    Title: ""
}

export const defaultStatistics: IDocumentOnlyStatisticsResponse = {
    Audience_Closed_Documents: 0,
    Audience_Open_Documents: 0,
    Creator_Closed_Documents: 0,
    Creator_Open_Documents: 0,
    CurrentDateTime: "",
    DisplayName: "",
    FullName: "",
    Share_Closed_Documents: 0,
    Share_Open_Documents: 0,
    Template_Co_Owner: 0,
    Template_Member: 0,
    Template_Owner: 0,
    UserName: 0
}

export const languages: IDropdownOption[] = [{key: 'en', text: 'English'}, {key: 'es', text: 'Español'}]

export const timeZones: IDropdownOption[] = [
    {key: 'Dateline Standard Time', text: '(UTC-12:00) International Date Line West'},
    {key: 'UTC-11', text: '(UTC-11:00) Coordinated Universal Time-11'},
    {key: 'Aleutian Standard Time', text: '(UTC-10:00) Aleutian Islands'},
    {key: 'Hawaiian Standard Time', text: '(UTC-10:00) Hawaii'},
    {key: 'Marquesas Standard Time', text: '(UTC-09:30) Marquesas Islands'},
    {key: 'Alaskan Standard Time', text: '(UTC-09:00) Alaska'},
    {key: 'UTC-09', text: '(UTC-09:00) Coordinated Universal Time-09'},
    {key: 'Pacific Standard Time (Mexico)', text: '(UTC-08:00) Baja California'},
    {key: 'UTC-08', text: '(UTC-08:00) Coordinated Universal Time-08'},
    {key: 'Pacific Standard Time', text: '(UTC-08:00) Pacific Time (US & Canada)'},
    {key: 'US Mountain Standard Time', text: '(UTC-07:00) Arizona'},
    {key: 'Mountain Standard Time (Mexico)', text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan'},
    {key: 'Mountain Standard Time', text: '(UTC-07:00) Mountain Time (US & Canada)'},
    {key: 'Central America Standard Time', text: '(UTC-06:00) Central America'},
    {key: 'Central Standard Time', text: '(UTC-06:00) Central Time (US & Canada)'},
    {key: 'Easter Island Standard Time', text: '(UTC-06:00) Easter Island'},
    {key: 'Central Standard Time (Mexico)', text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey'},
    {key: 'Canada Central Standard Time', text: '(UTC-06:00) Saskatchewan'},
    {key: 'SA Pacific Standard Time', text: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco'},
    {key: 'Eastern Standard Time (Mexico)', text: '(UTC-05:00) Chetumal'},
    {key: 'Eastern Standard Time', text: '(UTC-05:00) Eastern Time (US & Canada)'},
    {key: 'Haiti Standard Time', text: '(UTC-05:00) Haiti'},
    {key: 'Cuba Standard Time', text: '(UTC-05:00) Havana'},
    {key: 'US Eastern Standard Time', text: '(UTC-05:00) Indiana (East)'},
    {key: 'Turks and Caicos Standard Time', text: '(UTC-05:00) Turks and Caicos'},
    {key: 'Paraguay Standard Time', text: '(UTC-04:00) Asuncion'},
    {key: 'Atlantic Standard Time', text: '(UTC-04:00) Atlantic Time (Canada)'},
    {key: 'Venezuela Standard Time', text: '(UTC-04:00) Caracas'},
    {key: 'Central Brazilian Standard Time', text: '(UTC-04:00) Cuiaba'},
    {key: 'SA Western Standard Time', text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan'},
    {key: 'Pacific SA Standard Time', text: '(UTC-04:00) Santiago'},
    {key: 'Newfoundland Standard Time', text: '(UTC-03:30) Newfoundland'},
    {key: 'Tocantins Standard Time', text: '(UTC-03:00) Araguaina'},
    {key: 'E. South America Standard Time', text: '(UTC-03:00) Brasilia'},
    {key: 'SA Eastern Standard Time', text: '(UTC-03:00) Cayenne, Fortaleza'},
    {key: 'Argentina Standard Time', text: '(UTC-03:00) City of Buenos Aires'},
    {key: 'Greenland Standard Time', text: '(UTC-03:00) Greenland'},
    {key: 'Montevideo Standard Time', text: '(UTC-03:00) Montevideo'},
    {key: 'Magallanes Standard Time', text: '(UTC-03:00) Punta Arenas'},
    {key: 'Saint Pierre Standard Time', text: '(UTC-03:00) Saint Pierre and Miquelon'},
    {key: 'Bahia Standard Time', text: '(UTC-03:00) Salvador'},
    {key: 'UTC-02', text: '(UTC-02:00) Coordinated Universal Time-02'},
    {key: 'Mid-Atlantic Standard Time', text: '(UTC-02:00) Mid-Atlantic - Old'},
    {key: 'Azores Standard Time', text: '(UTC-01:00) Azores'},
    {key: 'Cabo Verde Standard Time', text: '(UTC-01:00) Cabo Verde Is.'},
    {key: 'Coordinated Universal Time', text: '(UTC) Coordinated Universal Time'},
    {key: 'GMT Standard Time', text: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London'},
    {key: 'Greenwich Standard Time', text: '(UTC+00:00) Monrovia, Reykjavik'},
    {key: 'Sao Tome Standard Time', text: '(UTC+00:00) Sao Tome'},
    {key: 'Morocco Standard Time', text: '(UTC+01:00) Casablanca'},
    {key: 'W. Europe Standard Time', text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'},
    {key: 'Central Europe Standard Time', text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'},
    {key: 'Romance Standard Time', text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris'},
    {key: 'Central European Standard Time', text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb'},
    {key: 'W. Central Africa Standard Time', text: '(UTC+01:00) West Central Africa'},
    {key: 'Jordan Standard Time', text: '(UTC+02:00) Amman'},
    {key: 'GTB Standard Time', text: '(UTC+02:00) Athens, Bucharest'},
    {key: 'Middle East Standard Time', text: '(UTC+02:00) Beirut'},
    {key: 'Egypt Standard Time', text: '(UTC+02:00) Cairo'},
    {key: 'E. Europe Standard Time', text: '(UTC+02:00) Chisinau'},
    {key: 'Syria Standard Time', text: '(UTC+02:00) Damascus'},
    {key: 'West Bank Gaza Standard Time', text: '(UTC+02:00) Gaza, Hebron'},
    {key: 'South Africa Standard Time', text: '(UTC+02:00) Harare, Pretoria'},
    {key: 'FLE Standard Time', text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'},
    {key: 'Jerusalem Standard Time', text: '(UTC+02:00) Jerusalem'},
    {key: 'Russia TZ 1 Standard Time', text: '(UTC+02:00) Kaliningrad'},
    {key: 'Sudan Standard Time', text: '(UTC+02:00) Khartoum'},
    {key: 'Libya Standard Time', text: '(UTC+02:00) Tripoli'},
    {key: 'Namibia Standard Time', text: '(UTC+02:00) Windhoek'},
    {key: 'Arabic Standard Time', text: '(UTC+03:00) Baghdad'},
    {key: 'Turkey Standard Time', text: '(UTC+03:00) Istanbul'},
    {key: 'Arab Standard Time', text: '(UTC+03:00) Kuwait, Riyadh'},
    {key: 'Belarus Standard Time', text: '(UTC+03:00) Minsk'},
    {key: 'Russia TZ 2 Standard Time', text: '(UTC+03:00) Moscow, St. Petersburg'},
    {key: 'E. Africa Standard Time', text: '(UTC+03:00) Nairobi'},
    {key: 'Iran Standard Time', text: '(UTC+03:30) Tehran'},
    {key: 'Arabian Standard Time', text: '(UTC+04:00) Abu Dhabi, Muscat'},
    {key: 'Astrakhan Standard Time', text: '(UTC+04:00) Astrakhan, Ulyanovsk'},
    {key: 'Azerbaijan Standard Time', text: '(UTC+04:00) Baku'},
    {key: 'Russia TZ 3 Standard Time', text: '(UTC+04:00) Izhevsk, Samara'},
    {key: 'Mauritius Standard Time', text: '(UTC+04:00) Port Louis'},
    {key: 'Saratov Standard Time', text: '(UTC+04:00) Saratov'},
    {key: 'Georgian Standard Time', text: '(UTC+04:00) Tbilisi'},
    {key: 'Volgograd Standard Time', text: '(UTC+04:00) Volgograd'},
    {key: 'Caucasus Standard Time', text: '(UTC+04:00) Yerevan'},
    {key: 'Afghanistan Standard Time', text: '(UTC+04:30) Kabul'},
    {key: 'West Asia Standard Time', text: '(UTC+05:00) Ashgabat, Tashkent'},
    {key: 'Russia TZ 4 Standard Time', text: '(UTC+05:00) Ekaterinburg'},
    {key: 'Pakistan Standard Time', text: '(UTC+05:00) Islamabad, Karachi'},
    {key: 'Qyzylorda Standard Time', text: '(UTC+05:00) Qyzylorda'},
    {key: 'India Standard Time', text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi'},
    {key: 'Sri Lanka Standard Time', text: '(UTC+05:30) Sri Jayawardenepura'},
    {key: 'Nepal Standard Time', text: '(UTC+05:45) Kathmandu'},
    {key: 'Central Asia Standard Time', text: '(UTC+06:00) Astana'},
    {key: 'Bangladesh Standard Time', text: '(UTC+06:00) Dhaka'},
    {key: 'Omsk Standard Time', text: '(UTC+06:00) Omsk'},
    {key: 'Myanmar Standard Time', text: '(UTC+06:30) Yangon (Rangoon)'},
    {key: 'SE Asia Standard Time', text: '(UTC+07:00) Bangkok, Hanoi, Jakarta'},
    {key: 'Altai Standard Time', text: '(UTC+07:00) Barnaul, Gorno-Altaysk'},
    {key: 'W. Mongolia Standard Time', text: '(UTC+07:00) Hovd'},
    {key: 'Russia TZ 6 Standard Time', text: '(UTC+07:00) Krasnoyarsk'},
    {key: 'Novosibirsk Standard Time', text: '(UTC+07:00) Novosibirsk'},
    {key: 'Tomsk Standard Time', text: '(UTC+07:00) Tomsk'},
    {key: 'China Standard Time', text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi'},
    {key: 'Russia TZ 7 Standard Time', text: '(UTC+08:00) Irkutsk'},
    {key: 'Malay Peninsula Standard Time', text: '(UTC+08:00) Kuala Lumpur, Singapore'},
    {key: 'W. Australia Standard Time', text: '(UTC+08:00) Perth'},
    {key: 'Taipei Standard Time', text: '(UTC+08:00) Taipei'},
    {key: 'Ulaanbaatar Standard Time', text: '(UTC+08:00) Ulaanbaatar'},
    {key: 'Aus Central W. Standard Time', text: '(UTC+08:45) Eucla'},
    {key: 'Transbaikal Standard Time', text: '(UTC+09:00) Chita'},
    {key: 'Tokyo Standard Time', text: '(UTC+09:00) Osaka, Sapporo, Tokyo'},
    {key: 'North Korea Standard Time', text: '(UTC+09:00) Pyongyang'},
    {key: 'Korea Standard Time', text: '(UTC+09:00) Seoul'},
    {key: 'Russia TZ 8 Standard Time', text: '(UTC+09:00) Yakutsk'},
    {key: 'Cen. Australia Standard Time', text: '(UTC+09:30) Adelaide'},
    {key: 'AUS Central Standard Time', text: '(UTC+09:30) Darwin'},
    {key: 'E. Australia Standard Time', text: '(UTC+10:00) Brisbane'},
    {key: 'AUS Eastern Standard Time', text: '(UTC+10:00) Canberra, Melbourne, Sydney'},
    {key: 'West Pacific Standard Time', text: '(UTC+10:00) Guam, Port Moresby'},
    {key: 'Tasmania Standard Time', text: '(UTC+10:00) Hobart'},
    {key: 'Russia TZ 9 Standard Time', text: '(UTC+10:00) Vladivostok'},
    {key: 'Lord Howe Standard Time', text: '(UTC+10:30) Lord Howe Island'},
    {key: 'Bougainville Standard Time', text: '(UTC+11:00) Bougainville Island'},
    {key: 'Russia TZ 10 Standard Time', text: '(UTC+11:00) Chokurdakh'},
    {key: 'Magadan Standard Time', text: '(UTC+11:00) Magadan'},
    {key: 'Norfolk Standard Time', text: '(UTC+11:00) Norfolk Island'},
    {key: 'Sakhalin Standard Time', text: '(UTC+11:00) Sakhalin'},
    {key: 'Central Pacific Standard Time', text: '(UTC+11:00) Solomon Is., New Caledonia'},
    {key: 'Russia TZ 11 Standard Time', text: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky'},
    {key: 'New Zealand Standard Time', text: '(UTC+12:00) Auckland, Wellington'},
    {key: 'UTC+12', text: '(UTC+12:00) Coordinated Universal Time+12'},
    {key: 'Fiji Standard Time', text: '(UTC+12:00) Fiji'},
    {key: 'Kamchatka Standard Time', text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old'},
    {key: 'Chatham Islands Standard Time', text: '(UTC+12:45) Chatham Islands'},
    {key: 'UTC+13', text: '(UTC+13:00) Coordinated Universal Time+13'},
    {key: 'Tonga Standard Time', text: '(UTC+13:00) Nuku\'alofa'},
    {key: 'Samoa Standard Time', text: '(UTC+13:00) Samoa'},
    {key: 'Line Islands Standard Time', text: '(UTC+14:00) Kiritimati Island'}
]
