import React, { HTMLAttributes, useEffect, useState } from 'react';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { getTheme, FontWeights, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IconButton, PrimaryButton, DefaultButton, ActionButton } from '@fluentui/react/lib/Button';
import { useId } from '@uifabric/react-hooks';
import { Stack } from '@fluentui/react/lib/Stack';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { Shimmer, IShimmerStyleProps, IShimmerStyles } from 'office-ui-fabric-react/lib/Shimmer';
import Helper, { ICreateDocumentRequest, IFormControl } from '../../Helpers/Helper';
import '../../Custom.css';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import { Separator } from '@fluentui/react/lib/Separator';
import {IModalProps, Modal, IDragOptions, IModalStyles} from "@fluentui/react/lib/Modal"
import { List, Panel, PanelType } from 'office-ui-fabric-react';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';
import {useBranding} from "../../hooks/useBranding";
import ConfigurationService from "../../services/Configuration";




const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackTokens = { childrenGap: 50 };
const modalStyles: IModalStyles ={
    main:{
      width:'30vw',
      height:'60vh'
    },
    root:{
      
    },
    scrollableContent:{},
    layer:{},
    keyboardMoveIcon:{},
    keyboardMoveIconContainer:{}
  }


function ReleaseNotes(props: any) {
    const { branding } = useBranding()
    const [loadingReleaseNotes, setLoadingReleaseNotes] = useState(false)
    const [releaseNotes, setReleaseNotes] = useState("")
    const [isPanelOpen, setIsPanelOpen] = useState(true);

    const panelHeader: HTMLAttributes<HTMLDivElement> = {
        style: {
            fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
            fontWeight: "bolder",
            color: branding.theme.palette.themePrimary,
            fontSize: 20
        }
    }
    
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '35vw',
            height: '50vh',
            flex: '4 4 auto',
            padding: '0 24px 0px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyPanel: {
            width: '35vw',
            height: '70vh',
            flex: '4 4 auto',
            padding: '0 24px 0px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    useEffect(()  => {
        if(props.showModal)
        {
            setLoadingReleaseNotes(true)
            getReleaseNotes()
        }

    }, [props.showModal])

    const onClose = () => {
        setLoadingReleaseNotes(false)
        props.hideModal()
    }
    const getReleaseNotes = async() => {
        
        await ConfigurationService.GetReleaseNotes()
            .then((data)=>{
                setReleaseNotes(data.Notes)
            })
            .catch()
            .finally(()=> {
                setLoadingReleaseNotes(false)
            })
     

        let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneUserlist")) as Element
        if (element !== null) { element.children[1].classList.add("scrollVisible") }
    }
    const getAllReleaseNotes = async() => {
        
        await ConfigurationService.GetAllReleaseNotes()
            .then((data)=>{
                setReleaseNotes(data.Notes)
            })
            .catch((error)=> {})
            .finally(()=> {
                setLoadingReleaseNotes(false)
            })
    }

    //Mobile
    const dismissPanel = () => {
        props.hideModal()
      }


    return !isMobile?(
        <Modal
            isOpen={props.showModal}
            isBlocking={true}
            containerClassName={contentStyles.container}
            
            >
           
             <Stack>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id="releaseN">{"Recent News And Updates"}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                        onClick={onClose}
                                        title="Close."
                                />
                            </div>
                        </div>
                    </div>
                    <Separator/>
                    
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                            {!loadingReleaseNotes ?
                                <ScrollablePane>
                                    <div className="scrollVisible" style={{overflow:"auto", overflowX:"hidden" , marginRight:"5px", height:"51vh", paddingLeft:"10px"}}>
                                        <div dangerouslySetInnerHTML={{ __html: releaseNotes }} />
                                    </div>
                                </ScrollablePane>
                                :
                            <Stack tokens={stackTokens}>
                                <label />
                                <label />
                                <label />
                                <Spinner size={SpinnerSize.large} label="Loading release notes..." />
                            </Stack>
                            }
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{marginRight:"35%", position:"relative"}}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <ActionButton onClick={getAllReleaseNotes} text="View All Updates" />
                            </DialogFooter>
                            </div>
                    </div>
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <List/>
                            </div>
                    </div>
                        
                </div>
            </Stack>
        
        </Modal>
    ):
    (
        <Panel
        headerText={"Recent News And Updates"}
        headerTextProps={panelHeader}
        isBlocking={false}
        isOpen={props.showModal}
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close."
        type={PanelType.smallFluid}
      >
        <Stack>
                <div className="ms-Grid" dir="ltr">
                    {/* <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id="releaseN">{"Recent News And Updates"}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                        onClick={onClose}
                                        title="Close."
                                />
                            </div>
                        </div>
                    </div> */}
                    <Separator/>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.bodyPanel}>
                            {!loadingReleaseNotes ?
                               <ScrollablePane  id="ScrollPaneUserlist" key="ScrollPaneUserlist">
                                    
                                        <div dangerouslySetInnerHTML={{ __html: releaseNotes }} />
                                   
                                </ScrollablePane>
                                :
                            <Stack tokens={stackTokens}>
                                <label />
                                <label />
                                <label />
                                <Spinner size={SpinnerSize.large} label="Loading release notes..." />
                            </Stack>
                            }
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{marginRight:"35%", position:"relative"}}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <ActionButton onClick={getAllReleaseNotes} text="View All Updates" />
                            </DialogFooter>
                            </div>
                    </div>
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <List/>
                            </div>
                    </div>
                        
                </div>
            </Stack>
      </Panel>
    )
}

export default ReleaseNotes


