import React, {useEffect, useState} from "react"
import {
    Checkbox,
    IContextualMenuItem,
    IContextualMenuProps,
    Link,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem,
    Text
} from "@fluentui/react";
import StatusMessage, {StatusEnum} from "../../../share/StatusMessage";
import {useBranding} from "../../../hooks/useBranding";
import {Trans, useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import Helper from "../../../Helpers/Helper";
import {RHFTextBoxControl} from "../../RHFControls/RHFTextBoxControl";
import {RHFDropDownListSingleSelectControl} from "../../RHFControls/RHFDropDownListSingleSelectControl";
import {defaultValues, DeleteAccount, deleteButtonStyles, IDefaultValues, languages, timeZones} from "..";
import {DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";
import UserService, {IGetuserprofileRequest, IUpdateuserProfileRequest} from "../../../services/User";
import CustomDialog from "../../../share/CustomDialog";
import i18n from "i18next";
import AccountService from "../../../services/Account";
import ApplicationServiceEmailService, {
    IGetServiceEmailByTemplateIdAndOwnerRequest
} from "../../../services/ApplicationServiceEmail";
import GraphApiService, {IDeleteRequest, IResendRequest, ITestRequest} from "../../../services/GraphApi";


interface IProps {
    triggerOnCloseParentModal: boolean
    onUpdateParentModal?: (status: boolean) => void
    triggerOnUpdate?: boolean
    onCloseParentModal: (cancel: boolean) => void
    fromModal: boolean
    setIsSavingParent?: (status: boolean) => void
    setIsLoadedParent?: (status: boolean) => void
}

export const Content = ({
                            setIsSavingParent,
                            setIsLoadedParent,
                            fromModal,
                            triggerOnCloseParentModal,
                            onUpdateParentModal,
                            onCloseParentModal,
                            triggerOnUpdate
                        }: IProps) => {

    const {branding} = useBranding()
    const {t} = useTranslation(['common', 'profile'])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isSavingEmailService, setIsSavingEmailService] = useState(false)
    const [isSavingResetPassword, setISavingResetPassword] = useState(false)
    const [message, setMessage] = useState("")
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)
    const [defaultValue, setDefaulValue] = useState<IDefaultValues>(defaultValues)
    const [showQuitWithoutSaving, setShowQuitWithoutSaving] = useState(false)
    const [showResetPassword, setShowResetPassword] = useState(false)
    const [showDeleteAccount, setShowDeleteAccount] = useState(false)
    const [disableDeleteAccountButton, setDisableDeleteAccountButton] = useState(true)
    const [fromApplicationServiceEmail, setFromApplicationServiceEmail] = useState("")
    const [showRemoveServiceEmail, setShowRemoveServiceEmail] = useState(false)
    const [showActivateServiceEmail, setShowActivateServiceEmail] = useState(false)

    const myForm = useForm<IDefaultValues>()

    useEffect(() => {
        if (triggerOnCloseParentModal) {
            OnClosePanel()
        }

    }, [triggerOnCloseParentModal])


    useEffect(() => {
        if (triggerOnUpdate) {
            UpdateProfile().then()
        }
    }, [triggerOnUpdate])

    // region init
    useEffect(() => {
        init().then()
    }, [])

    /**
     * On close this panel
     * @constructor
     */
    const OnClosePanel = () => {

        if (myForm.getValues("FirstName") !== defaultValue.FirstName
            || myForm.getValues("LastName") !== defaultValue.LastName
            || myForm.getValues("MiddleName") !== defaultValue.MiddleName
            || myForm.getValues("Language").key !== defaultValue.Language.key
            || myForm.getValues("TimeZone").key !== defaultValue.TimeZone.key
        ) {
            onCloseParentModal(true)
            setShowQuitWithoutSaving(true)
        } else {
            onCloseParentModal(false)
        }
    }

    async function init() {

        setIsLoading(true)
        setIsLoaded(false)
        setIsSaving(false)
        setShowQuitWithoutSaving(false)
        setShowResetPassword(false)
        setISavingResetPassword(false)
        setDisableDeleteAccountButton(true)
        setShowDeleteAccount(false)
        setFromApplicationServiceEmail("")
        setIsSavingEmailService(false)
        setShowRemoveServiceEmail(false)
        setShowActivateServiceEmail(false)
        
        myForm.reset()
        myForm.clearErrors()
        myForm.setValue("TimeZone", {
            label: 'Eastern Standard Time',
            key: 'Eastern Standard Time'
        })

        myForm.setValue("Language", {
            label: 'en',
            key: 'en'
        })

        let promises = [];

        // GetUserProfile
        const request: IGetuserprofileRequest = {
            Profile: Helper.getUsername()
        }

        promises.push(
            UserService.GetUserProfile(request)
                .then((data) => {

                    const _defaultValues: IDefaultValues = {
                        Email: data.Email,
                        FirstName: data.FirstName,
                        Language: {
                            label: '',
                            key: data.Language
                        },
                        LastName: data.LastName,
                        MiddleName: data.MiddleName,
                        TimeZone: {
                            label: '',
                            key: data.TimeZone
                        },
                        Title: data.Title
                    }

                    setDefaulValue(_defaultValues)

                    myForm.setValue("Email", _defaultValues.Email)
                    myForm.setValue("FirstName", _defaultValues.FirstName)
                    myForm.setValue("MiddleName", _defaultValues.MiddleName)
                    myForm.setValue("LastName", _defaultValues.LastName)

                    myForm.setValue("TimeZone", {
                        label: '',
                        key: _defaultValues.TimeZone.key
                    })

                    myForm.setValue("Language", {
                        label: '',
                        key: _defaultValues.Language.key
                    })
                })
                .catch((error) => {
                    throw error
                })
        )

        // Get Service email
        const request2: IGetServiceEmailByTemplateIdAndOwnerRequest = {
            Owner: Helper.getUsername(),
            TemplateId: '-1'

        }
        promises.push(
            ApplicationServiceEmailService.GetServiceEmailByTemplateIdAndOwner(request2)
                .then((data) => {
                    if (data.length > 0) {
                        setFromApplicationServiceEmail(data[0].Owner)
                    }
                })
                .catch((error) => {
                    throw error
                })
        )

        // call all promises
        await Promise.all(promises)
            .then(() => {
                setIsLoaded(true)
                setIsLoadedParent?.(true)
            })
            .catch((error: any) => {
                setMessage(Helper.GetErrorMessage(error))
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
                setIsLoaded(false)
                setIsLoadedParent?.(false)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    // endregion


    /**
     * Update tthe user profile
     * @constructor
     */
    const UpdateProfile = async () => {
        setStatusMessageEnum(StatusEnum.Info)
        setShowMessage(false)
        await myForm.handleSubmit(async () => {
            myForm.clearErrors()
            setIsSaving(true)
            setIsSavingParent?.(true)

            const request: IUpdateuserProfileRequest = {
                Profile: [{
                    "UserName": myForm.getValues("Email"),
                    "ProfileInformation": [
                        {
                            "Key": "title",
                            "Value": myForm.getValues("Title"),
                        },
                        {
                            "Key": "givenName",
                            "Value": myForm.getValues("FirstName"),
                        },
                        {
                            "Key": "middleName",
                            "Value": myForm.getValues("MiddleName"),
                        },
                        {
                            "Key": "sn",
                            "Value": myForm.getValues("LastName"),
                        },
                        {
                            "Key": "TimeZonePreference",
                            "Value": myForm.getValues("TimeZone").key
                        },
                        {
                            "Key": "SelectedCulture",
                            "Value": myForm.getValues("Language").key
                        }
                    ]
                }]
            }

            await UserService.UpdateUserProfile(request)
                .then(async () => {

                    const _defaultValues: IDefaultValues = {
                        Email: myForm.getValues("Email"),
                        FirstName: myForm.getValues("FirstName"),
                        Language: {
                            label: '',
                            key: myForm.getValues("Language").key
                        },
                        LastName: myForm.getValues("LastName"),
                        MiddleName: myForm.getValues("MiddleName"),
                        TimeZone: {
                            label: '',
                            key: myForm.getValues("TimeZone").key
                        },
                        Title: myForm.getValues("Title")
                    }

                    setDefaulValue(_defaultValues)

                    setStatusMessageEnum(StatusEnum.Success)
                    setMessage(t('text.profileSaved', {ns: 'profile'}))
                    setShowMessage(true)

                    if (defaultValue.Language.key !== _defaultValues.Language.key) {
                        sessionStorage.setItem("language", _defaultValues.Language.key)
                        await i18n.changeLanguage(_defaultValues.Language.key)
                    }

                    setTimeout(function () {
                        onCloseParentModal(false)
                    }, 1000);


                })
                .catch((error) => {
                    setMessage(Helper.GetErrorMessage(error))
                    setStatusMessageEnum(StatusEnum.Error)
                    setShowMessage(true)
                })
                .finally(() => {
                    setIsSaving(false)
                    setIsSavingParent?.(false)
                    onUpdateParentModal?.(false)
                })


        }, () => {
            setStatusMessageEnum(StatusEnum.Error)
            setMessage(t('message.fieldsRequired', {ns: 'common'}))
            setShowMessage(true)
            onUpdateParentModal?.(false)
        })()
    }

    /**
     * Reset password
     * @constructor
     */
    const ResetPassword = async () => {
        setISavingResetPassword(true)

        await AccountService.ResetPassword()
            .then(() => {
                setStatusMessageEnum(StatusEnum.Success)
                setMessage(t('text.messageSuccessResetPassword', {ns: 'profile'}))
                setShowMessage(true)
            })
            .catch((error: any) => {
                setMessage(Helper.GetErrorMessage(error))
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
            })
            .finally(() => {
                setShowResetPassword(false)
                setISavingResetPassword(false)
            })
    }

    /**
     * Test Email Connection
     * @constructor
     */
    const TestEmailConnection = async () => {
        setIsSavingEmailService(true)
        const request: ITestRequest = {
            TemplateId: "-1"
        }
        await GraphApiService.Test(request)
            .then(() => {
                setStatusMessageEnum(StatusEnum.Success)
                setMessage(t('text.messageSuccessTest', {ns: 'profile'}))
                setShowMessage(true)
            })
            .catch((error) => {
                setMessage(Helper.GetErrorMessage(error))
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
            })
            .finally(() => {
                setIsSavingEmailService(false)
            })
    }


    /**
     * Resend email Consent
     */
    const ResendEmailConsent = async () => {
        setIsSavingEmailService(true)
        const request: IResendRequest = {
            Approver: Helper.getUsername(),
            AudienceId: "-1",
            ClientID: "",
            ClientSecret: "",
            Owner: Helper.getUsername(),
            RedirectURL: "",
            ServiceEmail: Helper.getUsername(),
            TemplateId: "-1"

        }
        await GraphApiService.Resend(request)
            .then(() => {
                setStatusMessageEnum(StatusEnum.Success)
                setMessage(t('text.messageSuccessReconsent', {ns: 'profile'}))
                setShowMessage(true)
            })
            .catch((error) => {
                setMessage(Helper.GetErrorMessage(error))
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
            })
            .finally(() => {
                setIsSavingEmailService(false)
            })
    }

    /**
     * Remove Service email
     * @constructor
     */
    const RemoveOauthAuthorization = async () => {
        setIsSavingEmailService(true)

        const request: IDeleteRequest = {
            AudienceId: "-1",
            Id: "",
            Owner: Helper.getUsername(),
            TemplateId: "-1"
        }

        await GraphApiService.Delete(request)
            .then(() => {
                setFromApplicationServiceEmail("")
                setStatusMessageEnum(StatusEnum.Success)
                setMessage(t('text.messageSuccessRemoveServiceEmail', {ns: 'profile'}))
                setShowMessage(true)
            })
            .catch((error) => {
                setMessage(Helper.GetErrorMessage(error))
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
            })
            .finally(() => {
                setIsSavingEmailService(false)
                setShowRemoveServiceEmail(false)
            })
    }

    const ActivateServiceEmail = async () => {
        setIsSavingEmailService(true)

        const request: IResendRequest = {
            Approver: Helper.getUsername(),
            AudienceId: "-1",
            ClientID: "",
            ClientSecret: "",
            Owner: Helper.getUsername(),
            RedirectURL: "",
            ServiceEmail: Helper.getUsername(),
            TemplateId: "-1"
        }

        await GraphApiService.GraphApi(request)
            .then(() => {
                setStatusMessageEnum(StatusEnum.Success)
                setMessage(t('text.messageSuccessReconsent', {ns: 'profile'}))
                setShowMessage(true)
            })
            .catch((error) => {
                setMessage(Helper.GetErrorMessage(error))
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
            })
            .finally(() => {
                setIsSavingEmailService(false)
                setShowActivateServiceEmail(false)
            })
    }


    const menuItems: IContextualMenuItem[] = [
        {
            key: 'activate',
            text: t('text.activate', {'ns': 'profile'}),
            iconProps: {iconName: 'PublicEmail'},
            onClick: () => {
                setShowActivateServiceEmail(true)
            }
        },
        {
            key: 'resendConsent',
            text: t('text.resendConsent', {'ns': 'profile'}),
            iconProps: {iconName: 'SyncOccurence'},
            onClick: () => {
                ResendEmailConsent().then()
            }
        },
        {
            key: 'removeOauthAuthorization',
            text: t('text.removeOauthAuthorization', {'ns': 'profile'}),
            iconProps: {iconName: 'Cancel'},
            onClick: () => {
                setShowRemoveServiceEmail(true)
            }
        },
        {
            key: 'testEmailConnection',
            text: t('text.testEmailConnection', {'ns': 'profile'}),
            iconProps: {iconName: 'PlugDisconnected'},
            onClick: () => {
                TestEmailConnection().then()
            }
        }
    ]

    const EmailProps = (): IContextualMenuProps => {
        return {
            items: fromApplicationServiceEmail === "" ? menuItems.filter(f => f.key == "activate" || f.key == "resendConsent") : menuItems.filter(f => f.key != "activate")
        }
    }


    return (
        <>
            <Stack grow={1} styles={{root: {height: "100%"}}}>
                <Stack>
                    {!fromModal &&
                        <Stack styles={{root: {paddingBottom: 16}}}>
                            <Text variant={"xLarge"}>{t('text.title', {ns: 'profile'})}</Text>
                        </Stack>
                    }

                    {/*region MESSAGES*/}
                    {showMessage &&
                        <StackItem styles={{root: {paddingBottom: 16}}}>
                            <>
                                <StatusMessage status={statusMessageEnum} hasTimer={false}
                                               truncated={true}
                                               isMultiline={false}
                                               dismiss={true}
                                               setShowMessage={setShowMessage}>
                                    <div>
                                        <Trans>
                                            {message}
                                        </Trans>
                                    </div>
                                </StatusMessage>
                            </>
                        </StackItem>
                    }
                    {/*endregion*/}
                </Stack>
                <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                       style={{
                           overflow: "hidden",
                           overflowY: "auto",
                           height: "100%",
                           padding: "8px 16px 24px 16px"
                       }}>

                    {/*region Content*/}
                    {isLoading &&
                        <Stack grow={1} styles={{root: {justifyContent: "center"}}}>
                            <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})}
                                     ariaLive="assertive"/>
                        </Stack>
                    }

                    {!isLoading && isLoaded &&
                        <>

                            {/*region General information*/}
                            <Text variant={"large"} title={t("title.generalInformation", {ns: "profile"})}>
                                {t("title.generalInformation", {ns: "profile"})}
                            </Text>

                            <Stack styles={{root: {padding: "8px 0px 16px 0px"}}}>
                                <FormProvider {...myForm}>
                                    <form>
                                        <>

                                            <RHFTextBoxControl
                                                id={"Email"}

                                                label={t('label.email', {ns: 'profile'})}
                                                ariaLabel={t('title.email', {ns: 'profile'})}
                                                title={t('title.email', {ns: 'profile'})}
                                                placeholder={t('title.email', {ns: 'profile'})}

                                                control={myForm.control}
                                                getValues={myForm.getValues}
                                                setValue={myForm.getValues}
                                                trigger={myForm.trigger}

                                                maxLength={320}
                                                rules={{
                                                    required: t('text.fieldRequired', {ns: 'common'}),
                                                    validate: {
                                                        required:
                                                            (value) => {
                                                                return Helper.validateEmail(value) ? true : t('text.emailNoValid', {ns: 'common'})
                                                            }
                                                    },
                                                    maxLength: 320
                                                }}

                                                required
                                                disabled
                                            />

                                            <Stack horizontal wrap tokens={{childrenGap: 8}}>
                                                <Stack grow={1}>
                                                    <RHFTextBoxControl
                                                        id={"FirstName"}

                                                        label={t('label.firstName', {ns: 'profile'})}
                                                        ariaLabel={t('title.firstName', {ns: 'profile'})}
                                                        placeholder={t('title.firstName', {ns: 'profile'})}
                                                        title={t('title.firstName', {ns: 'profile'})}

                                                        control={myForm.control}
                                                        setValue={myForm.getValues}
                                                        trigger={myForm.trigger}
                                                        getValues={myForm.getValues}

                                                        maxLength={75}
                                                        rules={{
                                                            required: t('text.fieldRequired', {ns: 'common'}),
                                                            maxLength: 75
                                                        }}

                                                        required
                                                        disabled={isSaving}
                                                        autoFocus={true}
                                                    />
                                                </Stack>
                                                <Stack grow={1}>
                                                    <RHFTextBoxControl
                                                        id={"MiddleName"}

                                                        label={t('label.middleName', {ns: 'profile'})}
                                                        ariaLabel={t('title.middleName', {ns: 'profile'})}
                                                        placeholder={t('title.middleName', {ns: 'profile'})}
                                                        title={t('title.middleName', {ns: 'profile'})}

                                                        control={myForm.control}
                                                        setValue={myForm.getValues}
                                                        trigger={myForm.trigger}
                                                        getValues={myForm.getValues}

                                                        maxLength={50}
                                                        disabled={isSaving}
                                                    />
                                                </Stack>

                                            </Stack>


                                            <RHFTextBoxControl
                                                id={"LastName"}

                                                label={t('label.lastName', {ns: 'profile'})}
                                                placeholder={t('title.lastName', {ns: 'profile'})}
                                                ariaLabel={t('title.lastName', {ns: 'profile'})}
                                                title={t('title.lastName', {ns: 'profile'})}

                                                control={myForm.control}
                                                setValue={myForm.getValues}
                                                trigger={myForm.trigger}
                                                getValues={myForm.getValues}

                                                maxLength={50}
                                                rules={{
                                                    required: t('text.fieldRequired', {ns: 'common'}),
                                                    maxLength: 50
                                                }}
                                                disabled={isSaving}
                                                required
                                            />


                                            <RHFDropDownListSingleSelectControl
                                                id={"TimeZone"}
                                                label={t('label.timeZone', {ns: 'profile'})}
                                                title={t('title.timeZone', {ns: 'profile'})}

                                                options={timeZones}
                                                defaultValue={myForm.getValues("TimeZone").key}

                                                control={myForm.control}
                                                setValue={myForm.setValue}
                                                getValues={myForm.getValues}
                                                trigger={myForm.trigger}

                                                disabled={isSaving}
                                                required
                                            />

                                            <RHFDropDownListSingleSelectControl
                                                id={"Language"}
                                                label={t('label.language', {ns: 'profile'})}
                                                title={t('title.language', {ns: 'profile'})}

                                                options={languages}
                                                defaultValue={myForm.getValues("Language").key}

                                                control={myForm.control}
                                                setValue={myForm.setValue}
                                                getValues={myForm.getValues}
                                                trigger={myForm.trigger}

                                                disabled={isSaving}
                                                required
                                            />
                                        </>
                                    </form>
                                </FormProvider>


                                <Stack horizontal tokens={{childrenGap: 8}} styles={{root: {paddingTop: 16}}}>
                                    <Stack>
                                        <Link
                                            href=""
                                            onClick={() => {
                                                setShowResetPassword(true)
                                            }} disabled={isSaving || isSavingResetPassword}>
                                            {t('label.resetPassword', {ns: 'profile'})}
                                        </Link>
                                    </Stack>
                                    <Stack grow={1}>
                                        {" "}
                                    </Stack>
                                    {!fromModal && <>
                                        <Stack>
                                            <PrimaryButton onClick={UpdateProfile}
                                                           text={t('Common.Button.Update', {'ns': 'common'})}
                                                           disabled={isSaving}
                                                           title={t('title.update', {'ns': 'profile'})}/>

                                        </Stack>
                                        <Stack>
                                            <DefaultButton onClick={OnClosePanel}
                                                           text={t('Common.Button.Cancel', {'ns': 'common'})}
                                                           title={t('Common.Button.Cancel', {'ns': 'common'})}/>

                                        </Stack>
                                    </>
                                    }
                                </Stack>
                            </Stack>
                            {/*endregion*/}

                            {/*region Email integration*/}
                            <Separator/>
                            <Text variant={"large"} title={t("title.emailIntegration", {ns: "profile"})}>
                                {t("title.emailIntegration", {ns: "profile"})}
                            </Text>
                            <Stack styles={{root: {padding: "16px 0px 16px 0px"}}}>

                                <Stack horizontal wrap tokens={{childrenGap: 8}}>
                                    <Stack grow={1} styles={{root: {justifyContent: "center"}}}>
                                        <Text variant={"medium"} block>
                                            <Trans>
                                                {t('label.emailInformation', {
                                                    ns: 'profile',
                                                    AppName: branding.appName,
                                                    escapeInterpolation: false
                                                })}
                                            </Trans>
                                        </Text>

                                        <Link
                                            styles={{root: {paddingTop: 8, width: "auto"}}}
                                            href="/Kim-Document-Email-Integration.pdf" target="_blank"
                                            title="Kim-Document-Email-Integration.pdf">
                                            {t('link.emailConfiguration', {
                                                ns: 'profile',
                                                AppName: branding.appName
                                            })}
                                        </Link>


                                    </Stack>
                                    {isSavingEmailService &&
                                        <Stack styles={{root: {justifyContent: "center"}}}>
                                            <Spinner size={SpinnerSize.medium}
                                                     ariaLive="assertive"/>
                                        </Stack>
                                    }
                                    <Stack styles={{root: {justifyContent: "center"}}}>
                                        <DefaultButton
                                            text={t('text.options', {'ns': 'profile'})}
                                            split
                                            splitButtonAriaLabel={t('text.options', {'ns': 'profile'})}
                                            aria-roledescription={t('text.options', {'ns': 'profile'})}
                                            menuProps={EmailProps()}
                                            disabled={isSaving || isSavingEmailService}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                            {/*endregion*/}

                            {/*region Delete account*/}
                            <Separator/>
                            <Text variant={"large"} title={t("label.deleteAccount", {ns: "profile"})}>
                                {t("label.deleteAccount", {ns: "profile"})}
                            </Text>

                            <Stack horizontal wrap tokens={{childrenGap: 8}} styles={{root: {paddingTop: 16}}}>
                                <Stack grow={1} styles={{root: {justifyContent: "center"}}}>
                                    <Checkbox
                                        id="checkDeleteAccount"
                                        disabled={isLoading}
                                        label={t('label.confirmRemove', {'ns': 'profile'})}
                                        defaultChecked={false}
                                        onChange={(_, checked) => {
                                            if (checked !== undefined) setDisableDeleteAccountButton(!checked)
                                        }}
                                    />
                                </Stack>
                                <Stack styles={{root: {justifyContent: "center"}}}>
                                    <DefaultButton
                                        styles={deleteButtonStyles(branding.theme)}
                                        text={t('label.deleteAccount', {'ns': 'profile'})}
                                        title={t('title.deleteAccount', {'ns': 'profile'})}
                                        disabled={disableDeleteAccountButton}
                                        onClick={() => {
                                            setShowDeleteAccount(true)
                                        }}
                                    />
                                </Stack>

                            </Stack>
                            {/*endregion*/}
                        </>
                    }

                </Stack>

            </Stack>


            {/*region Confirm without saving*/}
            {showQuitWithoutSaving &&
                <CustomDialog title={t("title.unsavedChanges", {"ns": "profile"})} titleId={`ClosingMyProfile`}
                              actionText={t('Common.Button.Yes', {ns: 'common'})}
                              confirm={onCloseParentModal}
                              close={() => {
                                  setShowQuitWithoutSaving(false)
                              }}
                              isDisabled={false}
                              reversePrimary={true}
                              isOpen={showQuitWithoutSaving}
                >
                    <>
                        {t("text.unsavedChanges", {ns: "profile"})}
                    </>
                </CustomDialog>
            }
            {/*endregion*/}

            {/*region Reset password*/}
            {showResetPassword &&
                <CustomDialog title={t("text.resetpasswordTitle", {"ns": "profile"})} titleId={`ResetPassword`}
                              actionText={t('Common.Button.Yes', {ns: 'common'})}
                              confirm={ResetPassword}
                              close={() => {
                                  setShowResetPassword(false)
                              }}
                              isDisabled={isSavingResetPassword}
                              reversePrimary={true}
                              isOpen={showResetPassword}
                >
                    <>
                        {isSavingResetPassword ?
                            <>
                                <Spinner size={SpinnerSize.medium} label={t('text.spinnerSending', {ns: 'common'})}
                                         ariaLive="assertive"/>
                            </>
                            :
                            <Trans>
                                {t("text.textConfirmResetPassword", {ns: "profile"})}
                            </Trans>
                        }
                    </>
                </CustomDialog>
            }
            {/*endregion*/}


            {/*region Remove Service Email*/}
            {showRemoveServiceEmail &&
                <CustomDialog title={t("title.confirmation", {"ns": "profile"})} titleId={`ServiceEmailRemove`}
                              actionText={t('Common.Button.Yes', {ns: 'common'})}
                              confirm={RemoveOauthAuthorization}
                              close={() => {
                                  setShowRemoveServiceEmail(false)
                              }}
                              isDisabled={isSavingEmailService}
                              reversePrimary={true}
                              isOpen={showRemoveServiceEmail}
                >
                    <>
                        {isSavingEmailService ?
                            <>
                                <Spinner size={SpinnerSize.medium} label={t('text.spinnerDeleting', {ns: 'common'})}
                                         ariaLive="assertive"/>
                            </>
                            :
                            <Trans>
                                {t('text.confirmRemove', {ns: "profile", AppName: branding.appName})}
                            </Trans>
                        }
                    </>
                </CustomDialog>
            }
            {/*endregion*/}


            {/*region Activate Service Email*/}
            {showActivateServiceEmail &&
                <CustomDialog title={t("title.confirmation", {"ns": "profile"})} titleId={`ServiceEmailRemove`}
                              actionText={t('Common.Button.Yes', {ns: 'common'})}
                              confirm={ActivateServiceEmail}
                              close={() => {
                                  setShowActivateServiceEmail(false)
                              }}
                              isDisabled={isSavingEmailService}
                              isOpen={showActivateServiceEmail}
                >
                    <>
                        {isSavingEmailService ?
                            <>
                                <Spinner size={SpinnerSize.medium} label={t('text.spinnerSending', {ns: 'common'})}
                                         ariaLive="assertive"/>
                            </>
                            :
                            <Trans>
                                {t('text.confirmActivate', { 'ns': 'profile' })}
                            </Trans>
                        }
                    </>
                </CustomDialog>
            }
            {/*endregion*/}



            {/*region Delete*/}
            {showDeleteAccount &&
                <DeleteAccount showModal={showDeleteAccount} setShowModal={setShowDeleteAccount}/>
            }
            {/*endregion*/}
        </>
    )
}