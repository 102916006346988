import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

if(sessionStorage.getItem("language") === null) {
    sessionStorage.setItem("language", navigator.language.split("-")[0])
}

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        // lng: Helper.GetDisclaimerLeng(),
        lng: sessionStorage.getItem("language") === null ? 'en' : sessionStorage.getItem("language"),
        resources: {
            en: {
                renewalsalerts:require('./locales/en/renewalsalerts.json'),
                automaticallyCreateSubrecords:require('./locales/en/automaticallyCreateSubrecords.json'),
                landingpage:require('./locales/en/landingpage.json'),
                registration:require('./locales/en/registration.json'),
                richtexteditor:require('./locales/en/richtexteditor.json'),
                recordsign:require('./locales/en/recordsign.json'),
                checkinout:require('./locales/en/check-in-out.json'),
                recordmanage:require('./locales/en/recordmanage.json'),
                remove:require('./locales/en/remove.json'),
                recordFormLink: require('./locales/en/recordFormLink.json'),
                recordShareEmail: require('./locales/en/recordShareEmail.json'),
                recordsendmail: require('./locales/en/recordsendmail.json'),
                recordremove: require('./locales/en/recordremove.json'),
                recordshare:require('./locales/en/recordshare.json'),
                recordnotes:require('./locales/en/recordnotes.json'),
                common: require('./locales/en/common.json'),
                exportComponent: require('./locales/en/exportcomponent.json'),
                importComponent: require('./locales/en/importcomponent.json'),
                profile: require('./locales/en/profile.json'),
                importExternalComponent: require('./locales/en/importexternalcomponent.json'),
                attachmentsComponent: require('./locales/en/attachments.json'),
                workspace: require('./locales/en/workspace.json'),
                searchComponent: require('./locales/en/search.json'),
                settings: require('./locales/en/settings.json'),
                draft: require('./locales/en/draft.json'),
                bulkdownload: require('./locales/en/bulkdownload.json'),
                properties: require('./locales/en/properties.json'),
                // Check audiencecomponent
                audiencecomponent: require('./locales/en/audiencecomponent.json'),
                support: require('./locales/en/support.json'),
                wizard:require('./locales/en/wizard.json'),
                versions:require('./locales/en/versions.json'),
                formtemplatelink:require('./locales/en/formtemplatelink.json'),             
                deleteTemplate:require('./locales/en/deletetemplate.json'),
                download:require('./locales/en/download.json'),
                clone:require('./locales/en/clone.json'),
                recordactivity:require('./locales/en/recordactivity.json'),
                recordcopy:require('./locales/en/recordcopy.json'),
                preview:require('./locales/en/preview.json'),
                recorddelete:require('./locales/en/recorddelete.json'),
                coachmarks:require('./locales/en/coachmarks.json'),
                uploadtemplate:require('./locales/en/uploadtemplate.json'),
                assembly:require('./locales/en/assembly.json'),
                queueModal:require('./locales/en/queueModal.json'),
                restrictsubforms:require('./locales/en/restrictsubforms.json'),
                managesubforms:require('./locales/en/managesubforms.json'),
                emailtemplate:require('./locales/en/emailtemplate.json'),
                mybillingplan:require('./locales/en/mybillingplan.json')
            },
            es: {
                renewalsalerts:require('./locales/es/renewalsalerts.json'),
                automaticallyCreateSubrecords:require('./locales/es/automaticallyCreateSubrecords.json'),
                landingpage:require('./locales/es/landingpage.json'),
                registration:require('./locales/es/registration.json'),
                richtexteditor:require('./locales/es/richtexteditor.json'),
                recordsign:require('./locales/es/recordsign.json'),
                checkinout:require('./locales/es/check-in-out.json'),
                recordmanage:require('./locales/es/recordmanage.json'),
                remove:require('./locales/es/remove.json'),
                recordFormLink: require('./locales/es/recordFormLink.json'),
                recordShareEmail: require('./locales/es/recordShareEmail.json'),
                recordsendmail: require('./locales/es/recordsendmail.json'),
                recordremove: require('./locales/es/recordremove.json'),
                recordshare:require('./locales/es/recordshare.json'),
                recordnotes:require('./locales/es/recordnotes.json'),
                common: require('./locales/es/common.json'),
                exportComponent: require('./locales/es/exportcomponent.json'),
                importComponent: require('./locales/es/importcomponent.json'),
                profile: require('./locales/es/profile.json'),
                attachmentsComponent:require('./locales/es/attachments.json'),
                importExternalComponent: require('./locales/es/importexternalcomponent.json'),
                workspace: require('./locales/es/workspace.json'),
                searchComponent: require('./locales/es/search.json'),
                settings: require('./locales/es/settings.json'),
                draft: require('./locales/es/draft.json'),
                bulkdownload: require('./locales/es/bulkdownload.json'),
                properties: require('./locales/es/properties.json'),
                // Check audiencecomponent
                audiencecomponent: require('./locales/es/audiencecomponent.json'),
                support: require('./locales/es/support.json'),
                wizard:require('./locales/es/wizard.json'),
                versions:require('./locales/es/versions.json'),
                formtemplatelink:require('./locales/es/formtemplatelink.json'),
                deleteTemplate:require('./locales/es/deletetemplate.json'),
                download:require('./locales/es/download.json'),
                clone:require('./locales/es/clone.json'),
                recordactivity:require('./locales/es/recordactivity.json'),
                recordcopy:require('./locales/es/recordcopy.json'),
                preview:require('./locales/es/preview.json'),
                recorddelete:require('./locales/es/recorddelete.json'),
                coachmarks:require('./locales/es/coachmarks.json'),
                uploadtemplate:require('./locales/es/uploadtemplate.json'),
                assembly:require('./locales/es/assembly.json'),
                queueModal:require('./locales/es/queueModal.json'),
                restrictsubforms:require('./locales/es/restrictsubforms.json'),
                managesubforms:require('./locales/es/managesubforms.json'),
                emailtemplate:require('./locales/es/emailtemplate.json'),
                mybillingplan:require('./locales/es/mybillingplan.json')
            }
        },
        ns: [
            'renewalsalerts',
            'automaticallyCreateSubrecords',
            'registration',
            'richtexteditor',
            'recordsign',
            'checkinout',
            'recordmanage',
            'remove',
            'recordFormLink',
            'recordShareEmail',
            'recordsendmail',
            'recordremove',
            'preview',
            'recordshare',
            'recorddelete',
            'recordnotes',
            'recordcopy',
            'recordactivity',
            'attachmentsComponent',
            'coachmarks',
            'common',
            'clone',
            'download',
            'deleteTemplate',
            'support',
            'properties',
            'bulkdownload',
            'profile',
            'exportComponent',
            'importComponent',
            'importExternalComponent',
            'workspace',
            'searchComponent',
            'fullsettingcomponent',
            'audiencecomponent',
            'draft',
            'wizard',
            'settings',
            'uploadtemplate',
            'formtemplatelink',
            'versions',
            'assembly',
            'landingpage',
            'queueModal',
            'emailtemplate',
            'mybillingplan'
        ],
        defaultNS: 'common'         
    }).then();

i18n.languages = ['en', 'es'];

export default i18n;
