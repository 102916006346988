import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Sizes} from "../../share/CustomModal";
import {useModal} from "../../hooks/useModal";
import {_styles, nonShrinkingStackItemStyles} from "./index";
import {DefaultButton, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, StackItem} from "@fluentui/react";
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import ListService, {
    ICreateListDetailsRequest,
    IGetListsDetailsResponse,
    IUpdateListDetailsRequest
} from "../../services/List";
import {FormProvider, useForm} from 'react-hook-form';
import {RHFTextBoxControl} from "../RHFControls/RHFTextBoxControl";
import Helper from "../../Helpers/Helper";

type Properties = {
    isAddItem: boolean
    item: IGetListsDetailsResponse
    showModal: boolean
    setShowModal: (status: boolean) => void
    onAfterClose:  (listId: string) => void 
}
export const AddItem = ({setShowModal, showModal, item, isAddItem, onAfterClose}: Properties) => {

    const {t} = useTranslation(['common', 'profile'])
    const myModal = useModal()
    const [message, setMessage] = useState("")
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isSaved, setIsSaved] = useState(false)
    const myForm = useForm()


    useEffect(() => {
        if (showModal) {
            myModal.open()
            Init().then()
        }
    }, [showModal])

    /**
     * Close modal
     */
    const CloseModal = () => {
        myModal.close()
        setShowModal(false)
    }

    /**
     * Init
     */
    async function Init() {
        setIsSaved(false)
        setIsSaving(false)
        setMessage("")
        setShowMessage(false)
        setStatusMessageEnum(StatusEnum.Info)
        myForm.reset()
        myForm.clearErrors()
        if (!isAddItem) {
            myForm.setValue("key", item.Key)
            myForm.setValue("value", item.Value)
        }
    }

    /**
     * Updating item
     * @returns {Promise<void>}
     * @constructor
     */
    async function Update() {
        setStatusMessageEnum(StatusEnum.Info)
        setShowMessage(false)
        await myForm.handleSubmit(async () => {
                myForm.clearErrors()
                setIsSaving(true)

                const request: IUpdateListDetailsRequest = {
                    DocumentOnlyListDetailId: item.Id.toString(),
                    DocumentOnlyListMasterId: item.DocumentOnlyListMasterId.toString(),
                    Key: myForm.getValues("key"),
                    Value: myForm.getValues("value"),
                }

                await ListService.UpdateListDetails(request)
                    .then(() => {
                        setIsSaved(true)
                        setStatusMessageEnum(StatusEnum.Success)
                        setMessage(t("message.successUpdated", {ns: "common"}))
                        setShowMessage(true)
                        const interval = setInterval(() => {
                            clearInterval(interval)
                            onAfterClose(item.DocumentOnlyListMasterId.toString())
                            CloseModal()                            
                        }, 1200)                    
                        
                    })
                    .catch((error) => {
                        setStatusMessageEnum(StatusEnum.Error)
                        setMessage("An error occurred: " + Helper.GetErrorMessage(error))
                        setShowMessage(true)
                    })
                    .finally(() => {
                        setIsSaving(false)
                    })
            },
            () => {
                setStatusMessageEnum(StatusEnum.Error)
                setMessage(t('message.fieldsRequired', {ns: 'common'}))
                setShowMessage(true)
            }
        )()
    }

    /**
     * Add new item
     * @returns {Promise<void>}
     * @constructor
     */
    async function Save() {
        setStatusMessageEnum(StatusEnum.Info)
        setShowMessage(false)
        await myForm.handleSubmit(async () => {
                myForm.clearErrors()
                setIsSaving(true)

                const request: ICreateListDetailsRequest = {
                    DocumentOnlyListDetailId: "",
                    DocumentOnlyListMasterId: item.DocumentOnlyListMasterId.toString(),
                    Key: myForm.getValues("key"),
                    Value: myForm.getValues("value")
                }

                await ListService.CreateListDetails(request)
                    .then(() => {
                        setIsSaved(true)
                        setStatusMessageEnum(StatusEnum.Success)
                        setMessage(t("message.successCreated", {ns: "common"}))
                        setShowMessage(true)
                        const interval = setInterval(() => {
                            clearInterval(interval)
                            onAfterClose(item.DocumentOnlyListMasterId.toString())
                            CloseModal()
                        }, 1200)
                    })
                    .catch((error) => {
                        setStatusMessageEnum(StatusEnum.Error)
                        setMessage("An error occurred: " + Helper.GetErrorMessage(error))
                        setShowMessage(true)
                    })
                    .finally(() => {
                        setIsSaving(false)
                    })
            },
            () => {
                setStatusMessageEnum(StatusEnum.Error)
                setMessage(t('message.fieldsRequired', {ns: 'common'}))
                setShowMessage(true)
            }
        )()
    }

    return (
        <>
            {/*region MODAL TEMPLATE*/}
            <myModal.Modal
                title={isAddItem ? "New item" : "Edit item"}
                titleId="EditItemList" size={Sizes.xs}
                bodyClassName={_styles().body}
                afterClose={CloseModal}
            >
                <>
                    {/*region CONTENT*/}
                    <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                           style={{
                               overflow: "hidden",
                               overflowY: "auto",
                               height: "200px",
                               padding: "8px 16px 24px 16px"
                           }}>
                        <>

                            {/*region MESSAGES*/}
                            {showMessage &&
                                <StackItem styles={{root: {paddingBottom: 16}}}>
                                    <>
                                        <StatusMessage status={statusMessageEnum} hasTimer={false}
                                                       truncated={true}
                                                       isMultiline={true}
                                                       dismiss={true}
                                                       setShowMessage={setShowMessage}>
                                            <div>{message}</div>
                                        </StatusMessage>
                                    </>
                                </StackItem>
                            }
                            {/*endregion*/}

                            {/*region FORM*/}
                            <FormProvider {...myForm}>
                                <form>
                                    <>

                                        <RHFTextBoxControl
                                            id={"key"}

                                            label={'Key'}
                                            ariaLabel={'item key'}
                                            title={'item key'}
                                            placeholder={'Key'}

                                            control={myForm.control}
                                            getValues={myForm.getValues}
                                            setValue={myForm.getValues}
                                            trigger={myForm.trigger}

                                            maxLength={250}
                                            rules={{
                                                required: t('text.fieldRequired', {ns: 'common'}),
                                                maxLength: 250
                                            }}
                                            required
                                            disabled={true}

                                        />

                                        <RHFTextBoxControl
                                            id={"value"}

                                            label={'Value'}
                                            ariaLabel={'item value'}
                                            title={'item value'}
                                            placeholder={'Value'}

                                            control={myForm.control}
                                            getValues={myForm.getValues}
                                            setValue={myForm.getValues}
                                            trigger={myForm.trigger}

                                            maxLength={250}
                                            rules={{
                                                required: t('text.fieldRequired', {ns: 'common'}),
                                                maxLength: 250
                                            }}
                                            required
                                            disabled={isSaving || isSaved}

                                            onCallback={(event: React.FormEvent<HTMLInputElement>, newValue: string | undefined) => {
                                                if(newValue !== undefined && isAddItem )  myForm.setValue('key', newValue)
                                            }}
                                        />

                                    </>
                                </form>
                            </FormProvider>
                            {/*endregion*/}
                        </>

                    </Stack>
                    {/*endregion*/}

                    {/*region FOOTER*/}
                    <Separator styles={{root: {marginBottom: -8}}}></Separator>
                    <Stack horizontal style={{overflow: "hidden"}} disableShrink tokens={{childrenGap: 8}}
                           styles={nonShrinkingStackItemStyles}>
                        <StackItem grow={1}>&nbsp;</StackItem>
                        {isSaving &&
                            <>
                                <StackItem>
                                    <Spinner
                                        size={SpinnerSize.medium} labelPosition="left"
                                        label={t(isAddItem ? 'text.spinnerSaving' : 'text.spinnerUpdating', {ns: 'common'})}/>
                                </StackItem>
                            </>
                        }
                        <Stack horizontal wrap tokens={{childrenGap: 8}}>
                            <Stack>
                                <PrimaryButton
                                    onClick={isAddItem ? Save : Update}
                                    text={t(isAddItem ? 'Common.Button.Add' : 'Common.Button.Update', {'ns': 'common'})}
                                    disabled={isSaving || isSaved}
                                    title={t(isAddItem ? 'Common.Button.Add' : 'Common.Button.Update', {'ns': 'common'})}/>
                            </Stack>
                            <Stack>
                                <DefaultButton
                                    onClick={CloseModal}
                                    disabled={isSaving || isSaved}
                                    text={t('Common.Button.Cancel', {ns: 'common'})}
                                    title={t('Common.Button.Cancel', {ns: 'common'})}/>
                            </Stack>
                        </Stack>
                    </Stack>
                    {/*endregion*/}


                </>
            </myModal.Modal>
            {/*endregion*/}
        </>
    )
}

