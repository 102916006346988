import { useState } from "react"
import { APIEventLogStatus } from "../../../../interfaces/IAdminLog"
import AdminLogsService from "../../../../services/adminlogs"

const useToggleLogs = () => {
    const [toggleValue, setToggleValue] = useState<boolean | null>(null)

    const handleToggle = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        AdminLogsService.changeApiEventLogsStatus(checked!).then((data) => {
            setToggleValue(() => checked!)
        })
    }
    const toggleStatus = () => {
        AdminLogsService.getApiEventLogsStatus().then((data) => {
            try {
                const status = data as APIEventLogStatus[]
                if (status.length > 0) {
                    setToggleValue(() => status[0].logFlag)
                } else {
                    console.log(data)
                }
            } catch (error) {
                console.log(error)
            }
        })
    }

    return { handleToggle, toggleStatus, toggleValue }
}

export default useToggleLogs