import React, { useEffect, useState } from 'react';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import '../Custom.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { isMobile } from 'react-device-detect';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IconButton, DefaultButton, PrimaryButton, IButtonProps } from '@fluentui/react/lib/Button';
import {useTranslation} from "react-i18next";
import {useBranding} from "../hooks/useBranding";


const theme = getTheme();

const iconButtonStylesPrompt = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
            ':disabled': {
                backgroundColor: theme.palette.white
            },
        },
    },
    
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

function DownloadConfirmation(props: any) {
    const { branding } = useBranding()
    const {t} = useTranslation(['common', 'bulkdownload', 'workspace']);

    useEffect(() => {
        
     }, [props.showDownloadConfirmationModal])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        props.setShowDownloadConfirmationModal(false)
    }

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 500,
        },
        containerSaveModal: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: isMobile ? null : '30vw',
            height: '20vh',
        },
        body: {
            width: '80vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    return (
        <>
            <Modal
                isOpen={props.showDownloadConfirmationModal}
                isBlocking={true}
                containerClassName={contentStyles.containerSaveModal}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id="Confirmation">{t('title.download', { ns: 'bulkdownload'})}</span>
                                <IconButton
                                    styles={iconButtonStylesPrompt}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                    onClick={hideConfirmationModal}
                                    title={t('Common.Button.Close', { ns: 'common'})}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg12">
                            <div id="messageConfirmationDownload" className={contentStyles.bodyMail}>
                                {t('text.downloadConfirmation', { ns: 'bulkdownload'})}
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={() => {props.callbackDownloadConfirmation(props.downloadDocSelected, props.downloadType)}} text={t('Common.Button.Yes', { ns: 'common'})} id='downloadDocumentDataPoint' title={t('title.downloadConfirmation', { ns: 'bulkdownload'})} />
                                <DefaultButton onClick={hideConfirmationModal} text={t('Common.Button.No', { ns: 'common'})} title={t('Common.Button.No', { ns: 'common'})} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default DownloadConfirmation