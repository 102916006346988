import { StyleConfig, ToolbarConfig } from "react-rte";

import i18n from "../i18n/config"
import HttpRequest from "../services/HttpRequest";
const { t } = i18n
export const DefaultExtensions: string[] = ["zip", "tiff", "tif", "jpg", "xls", "xlsx", "rtf", "png", "jpeg", "gif", "doc", "docx", "ppt", "pptx", "pdf", "csv", "txt"]
export const DefaultFullExtensions: string[] = ["zip", "tiff", "tif", "jpg", "xls", "xlsx", "rtf", "png", "jpeg", "gif", "svg", "doc", "docx", "ppt", "pptx", "pdf", "csv", "txt"]
export const DefaultAcceptList: string[] = ["text/csv", "image/gif",
    "image/jpeg", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/pdf", "image/png", "application/rtf", "text/plain", "image/tiff", "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/zip"
]
export const FILE_ICONS: { icon: string, text: string, key: string, ext?: string[], mimetype: string[] }[] = [
    { key: 'csv', icon: 'csv', text: 'Comma-separated values (.csv)', mimetype: ['text/csv'], },
    { key: 'gif', icon: 'gif', text: 'GIF Image (.gif)', mimetype: ['image/gif'] },
    { key: 'jpg', icon: 'jpg', text: 'JPEG Image (.jpg,.jpeg)', mimetype: ['image/jpeg'], ext: ['jpg', 'jpeg'] },
    { key: 'ppt', icon: 'ppt', text: 'Microsoft PowerPoint (.ppt)', mimetype: ['application/vnd.ms-powerpoint'] },
    { key: 'pptx', icon: 'pptx', text: 'Microsoft PowerPoint (.pptx)', mimetype: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'] },
    { key: 'xls', icon: 'xls', text: 'Microsoft Excel (.xls)', mimetype: ['application/vnd.ms-excel'] },
    { key: 'xlsx', icon: 'xlsx', text: 'Microsoft Excel (.xlsx)', mimetype: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] },
    { key: 'pdf', icon: 'pdf', text: 'PDF Document (.pdf)', mimetype: ['application/pdf'] },
    { key: 'png', icon: 'png', text: 'PNG Image (.png)', mimetype: ['image/png'] },
    { key: 'rtf', icon: 'rtf', text: 'Rich Text Format (.rtf)', mimetype: ['application/rtf', 'application/msword'] },
    { key: 'txt', icon: 'txt', text: 'Text File (.txt)', mimetype: ['text/plain'] },
    { key: 'tiff', icon: 'tiff', text: 'TIFF Image (.tif, .tiff)', mimetype: ['image/tiff'], ext: ['tif', 'tiff'] },
    { key: 'doc', icon: 'doc', text: 'Microsoft Word (.doc)', mimetype: ['application/msword'] },
    { key: 'docx', icon: 'docx', text: 'Microsoft Word (.docx)', mimetype: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] },
    { key: 'zip', icon: 'zip', text: 'Zip File (.zip)', mimetype: ['application/zip', 'application/x-zip-compressed'] },
]
export const getMimeTypeFromExt = (ext: string): string => {
    const fileType = FILE_ICONS.find(file => file.key === ext)
    return fileType?.mimetype[0] ?? ''
}
export const orderIcons = () => {
    return FILE_ICONS.sort((a, b) => { return a.text.localeCompare(b.text) })
}
export const getGroupExtensions = (keys: string[]) => {
    return keys.reduce((p: { mimetype: string[], ext: string[] }, c: string) => {
        const group = FILE_ICONS.find(g => g.key === c)
        if (group) {
            const _ext: string[] = group.ext && group.ext.length > 0 ? group.ext : [group.key]
            return group ? { mimetype: [...p.mimetype, ...group.mimetype], ext: [...p.ext, ..._ext] } : p
        }
        return p
    }, { mimetype: [], ext: [] })
}

export const move = (arr: any[], old_index: number, new_index: number): any[] => {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

export const isInTeams = (): boolean => {
    return sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== "Not In Teams"
}

interface IRichTextEditor extends ToolbarConfig {
    HISTORY_BUTTONS: {
        undo: StyleConfig,
        redo: StyleConfig
    }
    LINK_BUTTONS: {
        link: StyleConfig,
        removeLink: StyleConfig
    }
}

export const DEFAULT_RICHTOOLBAR: IRichTextEditor = {
    display: ["INLINE_STYLE_BUTTONS", 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
        { label: t('label.bold', { ns: 'richtexteditor' }), style: 'BOLD', className: 'custom-css-class' },
        { label: t('label.italic', { ns: 'richtexteditor' }), style: 'ITALIC' },
        { label: t('label.underline', { ns: 'richtexteditor' }), style: 'UNDERLINE' },
        { label: t('label.strikethrough', { ns: 'richtexteditor' }), style: 'STRIKETHROUGH' },
        { label: t('label.blockquote', { ns: 'richtexteditor' }), style: 'blockquote' },
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: t('label.ul', { ns: 'richtexteditor' }), style: 'unordered-list-item' },
        { label: t('label.ol', { ns: 'richtexteditor' }), style: 'ordered-list-item' }
    ],
    BLOCK_TYPE_DROPDOWN: [],
    HISTORY_BUTTONS: {
        undo: { label: t('label.undo', { ns: 'richtexteditor' }), style: 'unstyled' },
        redo: { label: t('label.redo', { ns: 'richtexteditor' }), style: 'unstyled' },
    },
    LINK_BUTTONS: {
        link: { label: t('label.link', { ns: 'richtexteditor' }), style: 'unstyled' },
        removeLink: { label: t('label.removeLink', { ns: 'richtexteditor' }), style: 'unstyled' },
    }
}

export const getTokenClaims = (token: string): any | undefined => {
    try {
        if (token === null || token === '') {
            return undefined
        }
        const parts = token.split('.');
        if (parts.length !== 3) {
            return undefined;
        }

        const decoded = Buffer.from(parts[1], 'base64').toString()
        if (!decoded) {
            return undefined;
        }
        return JSON.parse(decoded);
    } catch {
        return undefined;
    }
}
export const getTokenHeaders = async (href: string) => {
    try {
        const url = new URL(href)
        const token = url.searchParams.get("token")
        const params = getTokenClaims(token!)
        const headers = token === null ? await HttpRequest.getAxiosHeaders() : await HttpRequest.getAxiosHeaders({ "Authorization": `Bearer ${token}` })
        return { params, headers }
    } catch (error) {
        console.log(error)
        return {}
    }

}
export const getTokenParams = (url_string: string) => {
    const url = new URL(url_string);
    const token = url.searchParams.get("token")
    return getTokenClaims(token!)
}
export const isNumericString = (input: any) => {
    return typeof input === 'string' && !Number.isNaN(input)
}
export const getNumberFromString = (currency: string) => {
    if (!currency) {
        return 0
    }
    return Number(currency.replace(/[^0-9.-]+/g, ""))
}
export const isEmail = (emailAddress: string) => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/;
    return mailformat.test(emailAddress);
}
export const removeParametersFromUrl = (url: string, paramList: string[]) => {
    let _url = new URL(url)
    let params = _url.searchParams
    paramList.forEach(param => {
        params.delete(param)
    })
    _url.search = params.toString()
    return _url.toString()
}
export const addParametersToUrl = (url: string, paramList: ({ [key: string]: string })) => {
    let _url = new URL(url)
    let params = _url.searchParams
    Object.entries(paramList).forEach(([key, value]) => {
        params.append(key, value)
    })
    _url.search = params.toString()
    return _url.toString()
}
export const getLinkType = () => {
    const urls: { [s: string]: string; } = {
        link: '/link/application',
        update: '/link/document',
        campaign: '/campaign'
    }
    const urlKey = Object.keys(urls).find((key) => window.location.pathname.includes(urls[key]))
    return urlKey ?? ''
}
