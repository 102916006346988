import HttpRequest from "../../../../services/HttpRequest";

const RestrictSubFormsService = {
    CreateSubFormRestriction: async (request: SubFormRestrictionRequest): Promise<any> => {
        let {data}: any = await HttpRequest.postWithSession('SubformRestriction', request)

        return new Promise<any>((resolve, reject) => {
            resolve(data)
        })
    },
    GetSubFormRestriction: async (request: SubFormRestrictionRequest): Promise<SubFormRestrictionResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('SubformRestriction', { ...request })

        return new Promise<SubFormRestrictionResponse[]>((resolve, reject) => {
            resolve(data)
        })
    },
    GetSubFormRestrictionFullInfo: async (request: SubFormRestrictionRequest): Promise<ISubFormsResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('SubformRestrictionInfo', { ...request })

        return new Promise<ISubFormsResponse[]>((resolve, reject) => {
            resolve(data)
        })
    },
}

export interface ISubFormsResponse {
    AccountId: number
    ActiveSubscription: boolean
    AllowCheckInOut: boolean
    AllowDocumentUpdate: boolean
    AllowEsign: boolean
    AllowNotification: boolean
    AudienceName: string
    Category: string
    CoOwner: boolean
    CreateDocument: boolean
    Description: string
    DisplayName: string
    DisplayNameToolTip: string
    DocumentOnlyAudienceId: number
    FileName: string
    Id: number
    IsSubForm: boolean
    OwnerDisplayName: string
    OwnerFullName: string
    OwnerUserName: string
    PropertyBag: string
    SubscriptionStatusMessage: string
    TemplateId: number
}

export interface Template {
    TemplateId: number
    TemplateName: string
}

interface SubFormRestrictionRequest {
    TemplateId: number,
    Templates?: Template[]
}

export interface SubFormRestrictionResponse {
    AccountId: number
    TemplateId: number
    SubformTemplateId: number
    SubformDisplayName: string
}

export default RestrictSubFormsService