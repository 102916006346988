import HttpRequest from "./HttpRequest";
import Helper from "../Helpers/Helper";


export interface IContentControlTemplateResponse {
    success: string
    result: string
    description: string
    id?: string
    exceptionMessage: string
    responseType: number
}

export interface IContentControls {
    alias: string;
    tag: string;
    children: IContentControls[];
}

export interface IContentControlResponse {
    error: string
    template: IContentControlTemplateResponse
    contentControls: IContentControls[]
}

export interface IContentControlRequest {
    AccessCodeInstructions: string
    AllowDocx: boolean
    AllowSendMail: boolean
    Allownotification: boolean
    Allowupdate: boolean
    Audience: string
    Category: string
    ChekInCheckOut: boolean
    Culture: string
    CultureName: string
    DefaultDescription: string
    Description: string
    DisplayName: string
    DocumentCreation: boolean
    DocumentDelete: boolean
    DocumentDelivery: string
    DocumentShare: boolean
    DocumentUpdate: boolean
    EmailMoniker: string
    EsignAccountId: string
    ExternalPublish: boolean
    ExternalPublishContext: string
    File: string
    Filename: string
    ImageInfo: string
    IntegrationKey: string
    MimeType: string
    PrivateKey: string
    Provider: string
    RedirectURL: string
    Released: boolean
    SignatureChecked: boolean
    Subform: boolean
    SupressPreview: boolean
    TemplateDelete: boolean
    TemplateEdit: boolean
    TemplateName: string
    TemplateRemove: boolean
    TemplateShare: boolean
    Tooltip: string
    UniqueReference: string
    Url: string
    UserId: string
    EnabledCaptcha: boolean
    EnabledSSO: boolean
}

const ContentControlService = {

    /**
     * ContentControl
     * @param request
     * @constructor
     */
    ContentControl: async (request: IContentControlRequest): Promise<IContentControlResponse> => {
        let { data }: any = await HttpRequest.postWithSession('ContentControl', request)

        return new Promise<IContentControlResponse>((resolve, reject) => {
            if (data.error) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    }
}

export default ContentControlService