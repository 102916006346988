import React, { useEffect, useState } from 'react'
import {
    Checkbox,
    CheckboxVisibility, DefaultButton, DetailsList, DetailsListLayoutMode, FontWeights, IButtonStyles,
    IColumn, IconButton, IContextualMenuItem, IContextualMenuProps, mergeStyleSets, Spinner, SpinnerSize, Stack
} from '@fluentui/react'
import { useModal } from '../../../hooks/useModal'
import { Sizes } from '../../../share/CustomModal'
import RulesForm from './RulesForm'
import DocumentAssemblyService from '../../../services/assembly'
import { useTranslation } from 'react-i18next'
import { useDialog } from '../../../hooks/useDialog'
import { IDocumentAssembly } from '../../../interfaces/IDocumentAssembly'
import RulesExpressionsForm from './RulesExpressionsForm'
import StatusMessage from '../../../share/StatusMessage'
import RulesActions from './RulesActions'
import { theme } from '../../AdminLogs/AdminLogs.data'
import { IStatusMessage } from '../../../interfaces/IApp'
import { groupByProperty } from '../../../Helpers/functions'
import {useBranding} from "../../../hooks/useBranding";


type AssemblyProps = {
    templateId: number
}
type OptionKeys = 'expressions' | 'edit' | 'enable' | 'up' | 'down' | 'delete' | 'actions'
const AssemblyRules = ({ templateId }: AssemblyProps) => {
    const { branding } = useBranding()
    const [assemblyRules, setAssemblyRules] = useState<IDocumentAssembly[]>([])
    const [message, setMessage] = useState<IStatusMessage | undefined>(undefined)
    const rulesForm = useModal()
    const deleteDialog = useDialog()
    const expressionsForm = useModal()
    const actionsForm = useModal()
    const [isLoading, setIsLoading] = useState(true)

    const { t } = useTranslation('assembly')

    const contentStyles = mergeStyleSets({
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        sectionHeader: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        section: {
            padding: '1rem 0'
        }
    })


    const handleDelete = (item?: IDocumentAssembly) => {
        if (item) {
            DocumentAssemblyService.deleteRule(templateId, Number(item.RuleId)).then(({ data }: any) => {
                const callback = () => {
                    if (data.Success === false) {
                        return setMessage({ Type: 'error', Message: <>Error deleting <strong>{item?.RuleName}</strong>: {data.ExceptionMessage}</> })
                    }
                    setIsLoading(true)
                    setMessage({ Type: 'success', Message: <>Rule <strong>{item?.RuleName}</strong> deleted successfully</> })
                }
                deleteDialog.close(callback)
            })
        }
    }

    const handleMenu = (option: OptionKeys, item?: IDocumentAssembly) => {
        const confirm = () => () => handleDelete(item)
        switch (option) {
            case 'edit':
                rulesForm.open(item)
                break
            case 'delete':
                deleteDialog.open({ confirm })

                break
            case 'expressions':
                expressionsForm.open(item)
                break
            case 'actions':
                actionsForm.open(item)
                break
            case 'up':
                if (item?.RuleId)
                    DocumentAssemblyService.orderAssemblyRule(templateId, item?.RuleId, 'orderup').then(() => {
                        setIsLoading(true)
                    })
                break
            case 'down':
                if (item?.RuleId)
                    DocumentAssemblyService.orderAssemblyRule(templateId, item?.RuleId, 'orderdown').then(() => {
                        setIsLoading(true)
                    })
                break
            case 'enable':
                if (item?.RuleId)
                    DocumentAssemblyService.updateAssembly(templateId, item?.RuleId, JSON.stringify(item)).then(() => {
                        setIsLoading(true)
                    })
                break
        }
    }

    const handleClose = (callback: () => void, loading: boolean, message?: IStatusMessage) => {
        callback?.()
        if (loading) {
            setIsLoading(true)
            setMessage(message)
        }
    }
    const wrappedColumns = (items: IDocumentAssembly[]) => {
        return getColumns(items, handleMenu)
    }

    const handleShowMessage = (status: boolean) => {
        setMessage(undefined)
    }
    const closeActions = () => {
        setIsLoading(true)
    }
    useEffect(() => {
        let isMounted = true
        if (isLoading) {
            DocumentAssemblyService.getAssembly(templateId).then(({ data }: any) => {
                if (isMounted) {
                    if (data.Success === false) {
                        setMessage({ Type: 'error', HasTimeOut: false, Message: <>{data.ExceptionMessage}</> })
                        setIsLoading(false)
                        setAssemblyRules([])
                        return
                    }
                    const rules: IDocumentAssembly[] = data as IDocumentAssembly[]
                    setAssemblyRules(() => {
                        return rules.sort((a, b) => a.RuleType.localeCompare(b.RuleType) || a.RuleOrder - b.RuleOrder);
                    })
                    setIsLoading(false)
                }
            })
        }
        return () => {
            isMounted = false
        }
    }, [isLoading, templateId])

    return (

        <>
            <Stack>
                <label className="ms-fontSize-18">Assembly Rules</label>
                <div>
                    <p> Document Assembly feature allows users to define rules to assemble documents and modify their content.
                        Users can create rules by defining input values and specifying the resulting action, such as adding or removing content from the document</p>
                    <p>Note: Rule changes will not be available for document assembly or preview or record until 5 minutes after saving rules.</p>
                </div>
            </Stack>
            {message ? <StatusMessage setShowMessage={handleShowMessage} status={message.Type} hasTimer={message.HasTimeOut}>
                <>{message.Message}</>
            </StatusMessage> : null}
            <Stack>
                <div className={contentStyles.section}>
                    <h3 className={contentStyles.sectionHeader}>
                        <span>{t('settings.tableList')}</span>
                        <DefaultButton key="openForm"
                            onClick={() => { rulesForm.open({}) }}
                            title='Add'
                            text={t('settings.addBtn')} />
                    </h3>
                    {isLoading ? <Spinner size={SpinnerSize.medium} label="Loading..." ariaLive="assertive" /> :
                        <DetailsList columns={wrappedColumns(assemblyRules)} items={assemblyRules} layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selectionPreservedOnEmptyClick={false}
                            enterModalSelectionOnTouch={true}
                            checkboxVisibility={CheckboxVisibility.hidden} />}
                </div>
            </Stack>
            <rulesForm.Modal title="Rule" titleId="" size={Sizes.xxs}>
                <RulesForm close={(loading, message) => { handleClose(rulesForm.close, loading, message) }} item={rulesForm.data} type='create' templateId={templateId} />
            </rulesForm.Modal>
            <expressionsForm.Modal title="Rules Expressions" titleId="expressions" size={Sizes.settings}>
                <RulesExpressionsForm type={'create'} close={(loading, message) => { handleClose(expressionsForm.close, loading, message) }} templateId={templateId} item={expressionsForm.data} />
            </expressionsForm.Modal>
            <actionsForm.Modal title="Rules Actions" titleId="actions" size={Sizes.settings} afterClose={closeActions} >
                <RulesActions templateId={templateId} close={closeActions} ruleItem={actionsForm.data} />
            </actionsForm.Modal>
            <deleteDialog.Dialog title={'Delete'} titleId="deleteModal" actionText={t('Common.Button.Delete', { ns: 'common' })}>
                <>Are you sure you want to delete the <strong>Assembly Rule</strong>?</>
            </deleteDialog.Dialog>
        </>
    )
}
const iconButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
type MenuProps = { item: IDocumentAssembly, items: IDocumentAssembly[], handleMenu: (option: OptionKeys, item?: IDocumentAssembly) => void }
const MenuItems = ({ item, items, handleMenu }: MenuProps) => {

    const itemsMenu: IContextualMenuItem[] = [
        {
            key: 'expressions',
            text: 'Expressions',
            title: 'Expressions.',
            onClick: () => {
                handleMenu('expressions', item)
            },
        },
        {
            key: 'actions',
            text: 'Actions',
            title: 'Actions.',
            onClick: () => {
                handleMenu('actions', item)
            },
        },
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit attachment.',
            onClick: () => {
                handleMenu('edit', item)
            },
        },

        {
            key: 'enable',
            text: item.Active ? 'Disable' : 'Enable',
            title: item.Active ? 'Disable.' : 'Enable.',
            onClick: () => {
                handleMenu('enable', { ...item, Active: !item.Active })
            },
        },
        {
            key: 'up',
            text: 'Up',
            title: 'Up.',
            disabled: item.RuleOrder === 1,
            onClick: () => {
                handleMenu('up', item)
            },
        },
        {
            key: 'down',
            text: 'Down',
            title: 'Down.',
            disabled: isLastRule(item, items),
            onClick: () => {
                handleMenu('down', item)
            },
        },
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete attachment.',
            onClick: () => {
                handleMenu('delete', item)
            },
        },
    ]
    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: itemsMenu
    };
    return (
        <>
            <IconButton
                menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424', textAlign: 'right' } }}
                role="button"
                aria-haspopup
                aria-label="Show actions"
                styles={iconButtonStyles}
                menuProps={menuProps}
                title={"Click to select from available actions."}
            />
        </>

    )
}
const getColumns = (items: IDocumentAssembly[], handleMenu: (option: OptionKeys, item?: IDocumentAssembly) => void): IColumn[] => {
    return [
        {
            key: 'type',
            name: 'Type',
            ariaLabel: 'Type',
            fieldName: 'RuleType',
            minWidth: 80,
            maxWidth: 80,
        },
        {
            key: 'name',
            name: 'Name',
            ariaLabel: 'Name',
            fieldName: 'RuleName',
            minWidth: 100,
        },
        {
            key: 'description',
            name: 'Description',
            ariaLabel: 'Description',
            fieldName: 'RuleDescription',
            minWidth: 0,
            isCollapsible: true,
            isResizable: true,
        },
        {
            key: 'Order',
            name: 'Order',
            ariaLabel: 'Order',
            fieldName: 'RuleOrder',
            minWidth: 50,
            maxWidth: 50,
        },
        {
            key: 'Enabled',
            name: 'Enabled',
            ariaLabel: 'Enabled',
            fieldName: 'Active',
            minWidth: 60,
            maxWidth: 60,
            onRender: (item: IDocumentAssembly) => {
                return <Checkbox disabled={true} checked={item.Active} />
            }
        },
        {
            key: 'action',
            name: 'Actions',
            minWidth: 55,
            maxWidth: 55,
            onRender: (item, index) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}>
                    <MenuItems item={item} items={items} handleMenu={handleMenu} />
                </div>
            }
        },

    ]
}
const isLastRule = (item: IDocumentAssembly, items: IDocumentAssembly[]) => {
    const groupedItems: { [key: string]: IDocumentAssembly[] } = groupByProperty<IDocumentAssembly>(items, 'RuleType')
    const lastIndex = groupedItems[item.RuleType].length - 1
    return item.RuleId === groupedItems[item.RuleType][lastIndex].RuleId
}
export default AssemblyRules