import { useContext } from "react"
import { customAppContext, defaultTheme } from "../context/AppContext"
import AppService from "./../services/app"
import { createTheme, IPalette } from "@fluentui/react";
import { Theme } from "@fluentui/react-theme-provider";
import useSEO from "./useSEO"
import ApplicationContextService from "../services/ApplicationContext" 


export const useBranding = () => {

    const { branding, setBranding } = useContext(customAppContext)

    useSEO({ title: branding?.appName, description: branding?.appTagline, favicon: branding?.appiconLogo })

    const getBrandingInformation = (User: string, documentOnlyAudienceId: string) => {
        return AppService.getBrandingInformation({ UserName: User, AudienceMasterId: documentOnlyAudienceId }).then(({ data }: any) => {
            if (data.length > 0) {
                const [companyData] = data
                setBranding((prev) => {
                    const newData = {
                        backgroundColor: companyData.BackgroundColor,
                        fontColor: companyData.CompanyForeColor,
                        companyName: companyData.CompanyName,
                        logo: companyData.Logo,
                        icon: "string",
                        imagotype: "string",
                        appTagline: "string",

                        createExampleApplication: true,
                        createExampleRecord: true,
                        exampleUniqueReferenceToImport: "",
                        showGetStarted: true,
                        showDefaultExamplesSection: true,
                        showCustomExamplesLink: false,
                        customExamplesLink: "#",
                        customExamplesLinkTarget: "#",
                        showWebformUseCasesLink: false,
                        webformUseCasesLink: "#",
                        webformUseCasesLinkTarget: "#",
                        showFAQLink: false,
                        faqLink: "#",
                        faqLinkTarget: "#",
                    }
                    return { ...prev, ...newData }
                })
            }

        })
    }

    /**
     * Return bool if the string is a positive value
     * @param value
     */
    const booleanify = (value: string): boolean => {
        const truthy: string[] = [
            'true',
            'True',
            '1'
        ]

        return truthy.includes(value)
    }

    const getAppSettings = () => {

        ApplicationContextService.ApplicationContext()
            .then((data) => {

                let myTheme: Theme = defaultTheme
                if (data.AppTheme !== null && data.AppTheme != undefined) {
                    try {
                        const jsonTheme: Partial<IPalette> = JSON.parse(data.AppTheme)
                        myTheme = createTheme({
                            palette: {
                                ...jsonTheme
                            }
                        });
                    } catch {

                    }
                }

                setBranding(prev => {
                    const newData = prev;
                    newData.companyName = data.AppName
                    newData.appName = data.AppName
                    newData.appTagline = data.AppTagline
                    newData.appLogo = data.AppLogo
                    newData.appiconLogo = data.AppiconLogo
                    newData.theme = myTheme
                    newData.helpPath = data.HelpPath

                    newData.createExampleApplication = booleanify(data.CreateExampleApplication)
                    newData.createExampleRecord = booleanify(data.CreateExampleRecord)
                    newData.exampleUniqueReferenceToImport = data.ExampleUniqueReferenceToImport
                    newData.showGetStarted = booleanify(data.ShowGetStarted)
                    newData.showDefaultExamplesSection = booleanify(data.ShowDefaultExamplesSection)
                    newData.showCustomExamplesLink = booleanify(data.ShowCustomExamplesLink)
                    newData.customExamplesLink = data.CustomExamplesLink
                    newData.customExamplesLinkTarget = data.CustomExamplesLinkTarget
                    newData.showWebformUseCasesLink = booleanify(data.ShowWebformUseCasesLink)
                    newData.webformUseCasesLink = data.WebformUseCasesLink
                    newData.webformUseCasesLinkTarget = data.WebformUseCasesLinkTarget
                    newData.showFAQLink = booleanify(data.ShowFAQLink)
                    newData.faqLink = data.FAQLink
                    newData.faqLinkTarget = data.FAQLinkTarget
                    newData.supportEmail = data.SupportEmail
                    newData.shareEmail = data.ShareEmail
                    newData.ShowPrintRules = data.ShowPrintRules
                    return {...prev, ...newData}
                })

            })
            .catch()
            .finally()

    }

    return {
        branding,
        getBrandingInformation,
        getAppSettings
    }
}
