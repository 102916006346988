import HttpRequest from "./HttpRequest";
import Helper from "../Helpers/Helper";

export interface IGetPlanByIdRequest {
    Type: string
    UseCache: boolean
    Id: string
}

export interface IPlanResponse {
    Description: string
    Id: number
    MaxApplicationAllowed: number
    Name: string
    PaymentProviderProductId: string
    StorageLimit: number
    SubmissionLimit: number
    TransactionAmount: number
    TransactionLength: number
    TransactionUnit: string
    Type: string
    UserLimit: number
    WorkspaceOnlyUsers: number
}

export interface IEvaluateRequest {
    Operation: string
    Id: number
    OwnerName: string
}

export interface IEvaluateResponse {
    CurrentStorageUsedGB: number
    CurrentUsers: number
    DowngradeAllowed: boolean
    Response: string
    TargetStorageLimitGB: number
    TargetUserLimit: number
}


const PlanService = {

    /**
     * GetPlanById
     * @param request
     * @constructor
     */
    GetPlanById: async (request: IGetPlanByIdRequest): Promise<IPlanResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('Plan/GetPlanById', request)

        return new Promise<IPlanResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject({err: 'Server error'})
            else resolve(data)
        })
    },

    /**
     * GetAllPlan
     * @param request
     * @constructor
     */
    GetAllPlan: async (): Promise<IPlanResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('Plan/GetAllPlan', {})

        return new Promise<IPlanResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject({err: 'Server error'})
            else resolve(data)
        })
    },

    /**
     * Evaluate
     * @param request
     * @constructor
     */
    Evaluate: async (request:IEvaluateRequest): Promise<IEvaluateResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('Plan/Evaluate', request)

        return new Promise<IEvaluateResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject({err: 'Server error'})
            else resolve(data)
        })
    },
    
}

export default PlanService