import HttpRequest from "./HttpRequest";

export interface IGetPlanUsageByYearMonthRequest {
    BillingClientId: string
    Year?: string
    Month?: string
}

export interface IGetPlanUsageByYearMonthResponse {
    BillingClientId: number
    CampaignCount: number
    Id: number
    Month: number
    StorageUsed: number
    SubmissionCount: number
    UserCount: number
    Year: number
}

const PlanUsageService = {

    /**
     * GetPlanUsageByYearMonth
     * @param request
     * @constructor
     */
    GetPlanUsageByYearMonth: async (request: IGetPlanUsageByYearMonthRequest): Promise<IGetPlanUsageByYearMonthResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('GetPlanUsageByYearMonth', request)

        return new Promise<IGetPlanUsageByYearMonthResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject({err: 'Server error'})
            else resolve(data)
        })
    }
}

export default PlanUsageService