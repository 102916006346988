import { Stack } from '@fluentui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

const SubFormsProperties = () => {
  const { t } = useTranslation('managesubforms')
  
  return (
    <>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">

                <div className="scrollVisible" style={{
                    overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh"
                }}>
                    <label className="ms-fontSize-18" >{t("title.titleSection", { ns: "managesubforms" })}</label>
                    <label />
                    <div style={{paddingTop:"14px"}}>
                        <p>
                          {t("title.help1", { ns: "managesubforms" })}
                        </p>
                        <p>
                          {t("title.help2", { ns: "managesubforms" })}
                        </p>
                    </div>
                    <Stack styles={{ root: { paddingRight: "10px" } }}>

                        <p></p>
                        <label />
                    </Stack>
                </div>
            </div >
        </>
  )
}

export default SubFormsProperties