import { ILayout } from "../Components/CustomTemplates/CustomTemplate"

export type FormRuleEditMode = {
    type: 'RULES_CONDITION' | 'RULES_ACTIONS' | 'RULE_PREVIEW' | undefined
    rule: IFormAssemblyRule | undefined
    index: number | undefined
    rules: IFormAssemblyRule[] | undefined
    showBar: boolean
    contentControls: ILayoutAssemblyRule[]
}
export const DEFAULT_EDIT_MODE: FormRuleEditMode = { type: undefined, contentControls: [], rule: undefined, index: undefined, rules: undefined, showBar: false }
export type FormAssemblyActions = 'CREATE' | 'EDIT' | 'DELETE' | 'SAVE_CONDITIONS' | 'SAVE_ACTIONS'


export const conditionColor = '#5168E0'
export const trueClauseColor = '#82DB39'
export const falseClauseColor = '#E08769'
export const defaultCCColor = '#e2e3e5'

export enum FormAssemblyOperator {
    Equal = 'Equal',
    NotEqual = 'Not Equal',
    GreaterThan = 'Greater than',
    GreaterThanOrEqual = 'Greater than or equal',
    LessThan = 'Lower than',
    LessThanOrEqual = 'Lower than or equal',
    Include = 'Contains',
    NotInclude = 'Does not contain'
}

export enum ActionType {
    Lock = 'Lock',
    Hide = 'Hide',
    Unlock = 'Unlock',
    Show = 'Show',
    Value = 'Value',
    Checked = 'Checked',
    Unchecked = 'Unchecked'
}
export enum TabActionType {
    Hide = 'hide',
    Show = 'show'
}
export type IFormAssemblyRule = {
    ruleName: string;
    description?: string;
    conditions: IRuleElement[]
    actions: IClausule[]
    adminOnly?: boolean,
    executeOnManualOnCreate?: boolean,
    executeOnInitOnCreate?: boolean,    
    disabled?: boolean
    version?: number
}

export const userRole = '_*userRole*_'

export interface IFormAssemblyExtraData {
    ConditionIndex?: number
}

export type ILayoutAssemblyRule = IFormAssemblyExtraData & ILayout;

export interface ICondition {
    type: 'and' | 'or';

    rules: (IRuleElement | ICondition)[];
}

export interface IRuleElement {
    field: string;
    operatorGroup: 'AND' | 'OR' | ''
    parenOpen: '(' | ''
    parenClose: ')' | ''
    operator: FormAssemblyOperator | ''
    value: string | number;
}


export type IClausule = {
    type: 'field' | 'tab'
    value?: string
    hide?:boolean
    readonly?:boolean
    setValue?:boolean
    fieldValue?: string
}