import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Sizes} from "../../share/CustomModal";
import {useModal} from "../../hooks/useModal";
import {_styles, Content, nonShrinkingStackItemStyles} from "./index";
import {DefaultButton, Separator, Stack, StackItem} from "@fluentui/react";
import {useBranding} from "../../hooks/useBranding";

type Properties = {
    showModal: boolean
    setShowModal: (status: boolean) => void
}
export const MyActivity = ({setShowModal, showModal}: Properties) => {

    const {t} = useTranslation(['common'])
    const {branding} = useBranding()
    const myModal = useModal()

    useEffect(() => {
        if (showModal) {
            myModal.open()
        }
    }, [showModal])

    /**
     * Close modal
     */
    const closeModal = () => {
        myModal.close()
        setShowModal(false)
    }

    return (
        <>
            {/*region MODAL TEMPLATE*/}
            <myModal.Modal
                title={t('text.activity', {ns: 'workspace'})} titleId="MyActivity" size={Sizes.md}
                afterClose={() => {
                    closeModal()
                }}
                bodyClassName={_styles(branding.theme).body}
                additionalHeader={undefined}
            >
                <>
                    {/*region CONTENT*/}
                    <Stack grow={1}
                           style={{
                               overflow: "hidden",
                               height: "60vh",
                           }}>
                        <Content isLoggedIn={true}/>
                        {/*endregion*/}

                        {/*region FOOTER*/}
                        <Separator styles={{root: {marginBottom: -8}}}></Separator>
                        <Stack horizontal style={{overflow: "hidden"}} disableShrink
                               styles={nonShrinkingStackItemStyles}>
                            <StackItem grow={1}>&nbsp;</StackItem>
                            <StackItem>
                                <DefaultButton onClick={() => {
                                    closeModal()
                                }} style={{marginLeft: 8}}
                                               text={t('Common.Button.Close', {ns: 'common'})}
                                               title={t('Common.Button.Close', {ns: 'common'})}/>
                            </StackItem>
                        </Stack>
                        {/*endregion*/}
                    </Stack>
                </>
            </myModal.Modal>
            {/*endregion*/}
        </>
    )
}

