import { Modal, IconButton, Separator, DialogFooter, PrimaryButton, DefaultButton, mergeStyleSets, getTheme, FontWeights, Text } from '@fluentui/react'
import React from 'react'
import { cancelIcon } from '../AdminLogs/AdminLogs.data'
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import {useBranding} from "../../hooks/useBranding";

const theme = getTheme();

const iconButtonStylesClose = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
}

type LogoutProps = {
    showConfirmationModal: boolean
    setShowConfirmationModal: any
}

const Logout = ({ showConfirmationModal, setShowConfirmationModal }: LogoutProps) => {
    const { logout } = useAuth()
    const { branding } = useBranding()
    const hideConfirmationModal = () => {
        setShowConfirmationModal(false)
    }
    const { t } = useTranslation(['common', 'profile', 'workspace']);

    const contentStyles = mergeStyleSets({
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            width: '50vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        containerSaveModal: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: isMobile ? null : '30vw',
            height: '20vh',
            backgroundColor: branding.theme.palette.white
        },
        bodyList: {
            width: '70vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        }
    })


    return (
        <Modal
            isOpen={showConfirmationModal}
            isBlocking={true}
            containerClassName={contentStyles.containerSaveModal}
        >
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.header}>
                            <Text variant={"xLarge"} id="Confirmation" styles={{root: {color: branding.theme.palette.black}}}>{t('text.logOut', { ns: 'workspace' })}</Text>
                            <IconButton
                                styles={iconButtonStylesClose}
                                iconProps={cancelIcon}
                                ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                onClick={hideConfirmationModal}
                                title={t('Common.Button.Close', { ns: 'common' })}
                            />
                        </div>
                    </div>
                </div>
                <Separator />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                        <div className={contentStyles.body}>
                            <Text variant={"medium"} styles={{root: {color: branding.theme.palette.black}}}>{t('text.logOutConfirmation', { ns: 'workspace' })}</Text>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <DialogFooter>
                            <PrimaryButton id='LogOutConfirmation' onClick={logout} text={t('Common.Button.Yes', { ns: 'common' })} title={t('title.logOutConfirmation', { ns: 'workspace' })} />
                            <DefaultButton onClick={hideConfirmationModal} text={t('Common.Button.No', { ns: 'common' })} title={t('Common.Button.Cancel', { ns: 'common' })} />
                        </DialogFooter>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default Logout