import { Text, Modal, Stack, FontWeights, getTheme, mergeStyleSets, IconButton, SelectionMode, IContextualMenuProps, IContextualMenuItem, TextField, DetailsList, DetailsListLayoutMode, ConstrainMode, IRenderFunction, IDetailsGroupDividerProps, IGroupDividerProps, MessageBar, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { IGroup } from 'office-ui-fabric-react/lib/GroupedList';
import { DetailsRow } from 'office-ui-fabric-react/lib/DetailsList';
import Helper from "../../Helpers/Helper";
import OutboundServices from "./Services/OutboundServices";
import { ConfigurationTemplateModel } from "./Models/ConfigurationTemplateModel";
import { OutboundIntegrationModel } from "./Models/OutboundIntegrationModel";
import { EventModel, EventType } from "./Models/EventModel";
import { useBoolean } from "@fluentui/react-hooks";
import ConfigurationTemplate from "./ConfigurationTemplate";
import IntegrationEvent from "./IntegrationEvent";
import OutboundIntegration from "./OutboundIntegration";
import InfiniteScroll from "react-infinite-scroll-component";
import {useBranding} from "../../hooks/useBranding";
//https://codesandbox.io/s/nelec?file=/src/StyleList.tsx

interface ConfigurationTemplateProps {
    clientEvents: IGroup[];
    isConfigurationTemplateListModaOpen: boolean;
    isAdminMenu: boolean;
    hideConfigurationTemplateListModal: () => void;
    loadEvents: () => Promise<void>;
    templateId: number;
}

const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    
};

function ConfigurationTemplateList(props: ConfigurationTemplateProps) {
    const { branding } = useBranding()
    const [configurationTemplateList, setConfigurationTemplateList] = useState<IGroup[]>([])
    const [filteredConfigurationTemplateList, setFilteredConfigurationTemplateList] = useState<IGroup[]>([])
    const [outboundIntegrations, setOutboundIntegrations] = useState<OutboundIntegrationModel[]>([]);

    const [isConfigurationTemplateModalOpen, { setTrue: showConfigurationTemplateModal, setFalse: hideConfigurationTemplateModal }] = useBoolean(false);
    const [isEventOpen, { setTrue: showEventModal, setFalse: hideEventModal }] = useBoolean(false);
    const event = useRef<EventModel>();
    const configurationTemplate = useRef<ConfigurationTemplateModel>();
    const [isErrorMessageCTLVisible, { setTrue: showErrorCTL, setFalse: hideErrorCTL }] = useBoolean(false);
    const [isSuccessMessageCTLVisible, { setTrue: showSuccessCTL, setFalse: hideSuccessCTL }] = useBoolean(false);
    const [message, setMessage] = useState("");

    const [isOutboundIntegrationOpen, { setTrue: showOutboundIntegrationModal, setFalse: hideOutboundIntegrationModal }] = useBoolean(false);
    const outboundIntegration = useRef<OutboundIntegrationModel>();
    const [isConfigurationTemplateReadMode, { setTrue: setConfigurationTemplateReadMode, setFalse: setConfigurationTemplateEditMode }] = useBoolean(false);
    const [isLoadingVisible, { setTrue: showLoading, setFalse: hideLoading }] = useBoolean(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    const [pageCount, setPageCount] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isScrolling, setIsScrolling] = useState(false);
    const searchContent = useRef("")
    let startIndexIntegration = useRef(0);
    const recordsPerPage = 10;

    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
            },
        ],
        body: {
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            overflowY: "hidden",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
    });


    useEffect(() => {
        getConfigurationTemplates()
    }, []);

    const hideModal = () => {
        props.hideConfigurationTemplateListModal()
    }

    const showErrorMessageCTL = (message: string) => {
        showErrorCTL()
        setMessage(message)
        setTimeout(function () {
            hideErrorCTL()
        }, 5000);
    }

    const showSuccessMessageCTL = (message: string) => {
        showSuccessCTL()
        setMessage(message)
        setTimeout(function () {
            hideSuccessCTL()
        }, 5000);
    }

    const showLoadingMessage = (message: string) => {
        setLoadingMessage(message)
        showLoading()
    }

    const hideEventModalCustom = () => {
        setPageCount(1);
        setHasMore(true);
        setIsScrolling(false);
        searchContent.current = ""
        startIndexIntegration.current = 0
        hideEventModal()
    }

    const getAdminMenu = (groupProps: IDetailsGroupDividerProps | undefined): IContextualMenuItem[] => {
        if (groupProps!.group!.key.indexOf("ConfigurationTemplate_") >= 0) {
            return [
                {
                    key: "editConfigurationTemplate",
                    text: "Edit Information",
                    title: "Edit Information.",
                    onClick: () => {
                        configurationTemplate!.current = groupProps!.group?.data as ConfigurationTemplateModel
                        showConfigurationTemplateModal()
                    }
                },
                {
                    key: "delete",
                    text: "Delete",
                    title: "Delete Configuration Template.",
                    onClick: async () => {
                        showLoadingMessage("Deleting Configuration Template")
                        OutboundServices.GetConfigurationTemplateById({
                            AccountId: Helper.getDefaultAccount(),
                            ApiKey: "-1",
                            Autoprovision: true,
                            Id: groupProps!.group?.data.Id,
                            Path: await Helper.getSite(),
                            SK: Helper.getSessionKey(),
                            UserName: Helper.getUsername()
                        }).then(async (res: ConfigurationTemplateModel[]) => {
                            OutboundServices.DeleteConfigurationTemplate({
                                AccountId: Helper.getDefaultAccount(),
                                ApiKey: "-1",
                                Autoprovision: true,
                                ConfigurationTemplate: res[0],
                                Path: await Helper.getSite(),
                                SK: Helper.getSessionKey(),
                                UserName: Helper.getUsername(),
                                SessionKey: Helper.getSessionKey()
                            }).then(res => {
                                if (res.Success) {
                                    showSuccessMessageCTL(res.Description)
                                } else {
                                    showErrorMessageCTL(`${res.Description} | ${res.ExceptionMessage}`)
                                }
                                getConfigurationTemplates()
                                hideLoading()
                            })
                        })
                    }
                },
                {
                    key: "activate",
                    text: groupProps!.group?.data.Active ? "Deactivate" : "Activate",
                    title: groupProps!.group?.data.Active ? "Deactivate Configuration Template." : "Activate Configuration Template.",
                    onClick: async () => {
                        showLoadingMessage(`${groupProps!.group?.data.Active ? "Deactivating" : "Activating"} Configuration Template`)
                        OutboundServices.GetConfigurationTemplateById({
                            AccountId: Helper.getDefaultAccount(),
                            ApiKey: "-1",
                            Autoprovision: true,
                            Id: groupProps!.group?.data.Id,
                            Path: await Helper.getSite(),
                            SK: Helper.getSessionKey(),
                            UserName: Helper.getUsername()
                        }).then(async (res: ConfigurationTemplateModel[]) => {
                            let tempItem = res[0];
                            tempItem.Active = !tempItem.Active
                            OutboundServices.UpdateConfigurationTemplate({
                                AccountId: Helper.getDefaultAccount(),
                                ApiKey: "-1",
                                Autoprovision: true,
                                ConfigurationTemplate: tempItem,
                                Path: await Helper.getSite(),
                                SK: Helper.getSessionKey(),
                                UserName: Helper.getUsername(),
                                SessionKey: Helper.getSessionKey()
                            }).then(res => {
                                if (res.Success) {
                                    showSuccessMessageCTL(res.Description)
                                } else {
                                    showErrorMessageCTL(`${res.Description} | ${res.ExceptionMessage}`)
                                }
                                getConfigurationTemplates()
                                hideLoading()
                            })
                        })
                    }
                }
            ]
        }

        if (groupProps!.group!.key.indexOf("Event_") >= 0) {
            return [
                {
                    key: "viewConfigurationTemplateEvent",
                    text: "View Event Read Mode",
                    title: "View Event Read Mode.",
                    onClick: (ev?: | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
                        configurationTemplate.current = undefined
                        event.current = groupProps!.group!.data as EventModel;
                        showEventModal();
                    }
                },
                {
                    key: "edit",
                    text: "Edit Event",
                    title: "Edit Event.",
                    onClick: (ev?: | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
                        event.current = groupProps!.group!.data as EventModel;
                        configurationTemplate!.current = configurationTemplateList.find(confTemplate => (confTemplate.data as ConfigurationTemplateModel).ObjectIntegrationConfig.Id === (groupProps!.group!.data as EventModel).Id)?.data as ConfigurationTemplateModel
                        showEventModal();
                    }
                }
            ]
        }
        return []
    }

    const loadUseTemplateEvent = async (currentConfigurationTemplate: ConfigurationTemplateModel) => {
        currentConfigurationTemplate.ObjectIntegrationConfig.Name = "";
        currentConfigurationTemplate.ObjectIntegrationConfig.Description = "";
        currentConfigurationTemplate.ObjectIntegrationConfig.Id = 0;
        currentConfigurationTemplate.ObjectIntegrationConfig.TemplateId = props.templateId
        currentConfigurationTemplate.ObjectIntegrationConfig.Integrations.forEach(integration => {
            integration.ObjectMapInput.MapInput.Params.ObjectSurrogateFilter.forEach(surrogate => {
                surrogate.TemplateId = props.templateId
            })
        })
        event.current = currentConfigurationTemplate.ObjectIntegrationConfig
        showEventModal()
    }

    const getClientMenu = (groupProps: IDetailsGroupDividerProps | undefined): IContextualMenuItem[] => {
        if (groupProps!.group!.key.indexOf("ConfigurationTemplate_") >= 0) {
            return [
                {
                    key: "useConfigurationTemplate",
                    text: "Use Configuration Template",
                    title: props.clientEvents.filter(clientEvent => {
                        const event = clientEvent.data as EventModel;
                        const configurationTemplateEvent = groupProps!.group?.data as ConfigurationTemplateModel;
                        return event.EventType.trim() === configurationTemplateEvent.ObjectIntegrationConfig.EventType.trim()
                    }).length > 0 && (
                            (groupProps!.group?.data as ConfigurationTemplateModel).ObjectIntegrationConfig.EventType.trim() === EventType.OnCreate ||
                            (groupProps!.group?.data as ConfigurationTemplateModel).ObjectIntegrationConfig.EventType.trim() === EventType.OnClose ||
                            (groupProps!.group?.data as ConfigurationTemplateModel).ObjectIntegrationConfig.EventType.trim() === EventType.OnUpdate) ? "You already have one event of this type" : "Use Configuration Template",
                    disabled: props.clientEvents.filter(clientEvent => {
                        const event = clientEvent.data as EventModel;
                        const configurationTemplateEvent = groupProps!.group?.data as ConfigurationTemplateModel;
                        return event.EventType.trim() === configurationTemplateEvent.ObjectIntegrationConfig.EventType.trim()
                    }).length > 0 && (
                            (groupProps!.group?.data as ConfigurationTemplateModel).ObjectIntegrationConfig.EventType.trim() === EventType.OnCreate ||
                            (groupProps!.group?.data as ConfigurationTemplateModel).ObjectIntegrationConfig.EventType.trim() === EventType.OnClose ||
                            (groupProps!.group?.data as ConfigurationTemplateModel).ObjectIntegrationConfig.EventType.trim() === EventType.OnUpdate),
                    onClick: () => {
                        configurationTemplate!.current = groupProps!.group?.data;
                        loadUseTemplateEvent(groupProps!.group?.data)
                    }
                }
            ]
        }
        if (groupProps!.group!.key.indexOf("Event_") >= 0) {
            return [
                {
                    key: "viewConfigurationTemplateEvent",
                    text: "View Event Read Mode",
                    title: "View Event Read Mode.",
                    onClick: (ev?: | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
                        configurationTemplate.current = undefined
                        event.current = groupProps!.group!.data as EventModel;
                        showEventModal();
                    }
                }
            ]
        }
        return []
    }

    const getConfigurationTemplateMenu = (groupProps: IDetailsGroupDividerProps | undefined): IContextualMenuProps => {
        return {
            shouldFocusOnMount: true,
            items: props.isAdminMenu ? getAdminMenu(groupProps) : getClientMenu(groupProps)
        };
    }

    const getIntegrationEventsMenu = (item: OutboundIntegrationModel): IContextualMenuProps => {
        let adminMenu = [
            {
                key: "edit",
                text: "View Service Read Mode",
                title: "View Service Read Mode.",
                onClick: () => {
                    configurationTemplate!.current = configurationTemplateList.find(confTemplate => (confTemplate.data as ConfigurationTemplateModel).ObjectIntegrationConfig.Integrations.find(integration => integration.Id === item.Id) !== undefined)?.data
                    event.current = configurationTemplate!.current?.ObjectIntegrationConfig
                    outboundIntegration.current = item;
                    showOutboundIntegrationModal();
                    setConfigurationTemplateReadMode();
                }
            },
            {
                key: "editConfigurationTemplateIntegration",
                text: "Edit Service",
                title: "Edit Service.",
                onClick: () => {
                    configurationTemplate!.current = configurationTemplateList.find(confTemplate => (confTemplate.data as ConfigurationTemplateModel).ObjectIntegrationConfig.Integrations.find(integration => integration.Id === item.Id) !== undefined)?.data
                    event.current = configurationTemplate!.current?.ObjectIntegrationConfig
                    outboundIntegration.current = item;
                    showOutboundIntegrationModal();
                    setConfigurationTemplateEditMode();
                }
            },
        ]

        let clientMenu = [
            {
                key: "edit",
                text: "View Service Read Mode",
                title: "View Service Read Mode.",
                onClick: () => {
                    configurationTemplate!.current = configurationTemplateList.find(confTemplate => (confTemplate.data as ConfigurationTemplateModel).ObjectIntegrationConfig.Integrations.find(integration => integration.Id === item.Id) !== undefined)?.data
                    event.current = configurationTemplate!.current?.ObjectIntegrationConfig
                    outboundIntegration.current = item;
                    showOutboundIntegrationModal();
                    setConfigurationTemplateReadMode();
                }
            }
        ]

        return {
            shouldFocusOnMount: true,
            items: props.isAdminMenu ? adminMenu : clientMenu
        };
    };

    const getConfigurationTemplates = async () => {
        showLoadingMessage("Loading Configuration Templates")
        OutboundServices.GetConfigurationTemplateList({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Content: searchContent.current,
            PageNo: 1,
            Path: await Helper.getSite(),
            RecordsPerPage: recordsPerPage,
            SessionKey: Helper.getSessionKey(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
        }).then(res => {

            let tempConfigurationTemplates: IGroup[] = [];
            let tempOutboundIntegrations: OutboundIntegrationModel[] = [];
            let _startIndexConfigurationTemplate: number = 0;
            res.forEach(item => {
                tempConfigurationTemplates.push({
                    key: "ConfigurationTemplate_" + item.Id.toString(),
                    name: item.Name,
                    startIndex: _startIndexConfigurationTemplate,
                    count: 1,
                    level: 0,
                    data: item,
                    isDropEnabled: false,
                    isCollapsed: true,
                    isShowingAll: false,
                    hasMoreData: false,
                    children: [{
                        key: "Event_" + item.ObjectIntegrationConfig.Id.toString(),
                        name: item.ObjectIntegrationConfig.Name,
                        startIndex: startIndexIntegration.current,
                        count: item.ObjectIntegrationConfig.Integrations.length,
                        level: 1,
                        data: item.ObjectIntegrationConfig,
                        isDropEnabled: false,
                        isCollapsed: true,
                        isShowingAll: false,
                        hasMoreData: false,
                        children: []
                    }]
                })
                tempOutboundIntegrations.push(...item.ObjectIntegrationConfig.Integrations)
                startIndexIntegration.current += item.ObjectIntegrationConfig.Integrations.length;
            })

            setOutboundIntegrations(tempOutboundIntegrations);

            if (props.isAdminMenu) {
                setConfigurationTemplateList(tempConfigurationTemplates)
                setFilteredConfigurationTemplateList(tempConfigurationTemplates)
            } else {
                setConfigurationTemplateList(tempConfigurationTemplates.filter(f => f.data.Active))
                setFilteredConfigurationTemplateList(tempConfigurationTemplates.filter(f => f.data.Active))
            }
            hideLoading()
        })
    }

    const onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string): void => {
        setPageCount(1)
        searchContent.current = text !== undefined ? text : ""
        if (text !== undefined && text.length === 0) {
            setIsScrolling(false)
            setHasMore(true)
        }
        setTimeout(function () {
            getConfigurationTemplates()
        }, 1000);
    }

    const onRenderGroupHeader: IRenderFunction<IDetailsGroupDividerProps> = (groupProps) => {
        if (groupProps) {
            return (
                <DetailsRow
                    columns={[
                        {
                            key: "collapse",
                            name: "collapse",
                            minWidth: 36,
                            maxWidth: 36,
                            onRender: () => {
                                return (
                                    <IconButton
                                        menuIconProps={{ iconName: groupProps.group!.isCollapsed ? "ChevronRight" : "ChevronDown" }}
                                        role="button"
                                        aria-haspopup
                                        aria-label="Show actions"
                                        styles={{ root: { height: "inherit" } }}
                                        title={"Click to select from available actions"}
                                        id="actionsCampaignListButton"
                                        onClick={() => {
                                            onToggleCollapse(groupProps);
                                        }}
                                    />
                                );
                            },
                            isResizable: false,
                            styles: {
                                root: { paddingLeft: 2, paddingRight: 0 },
                            },
                        },
                        {
                            key: "name",
                            name: "Name ",
                            fieldName: "Name",
                            minWidth: 300,
                            maxWidth: 300,
                            onRender: (item) => {
                                return (
                                    <div style={{ width: 296 + "px" }}>
                                        {groupProps.group!.data.Name}
                                    </div>
                                );
                            },
                            isResizable: false,
                            styles: {
                                root: { paddingLeft: 0, paddingRight: 0 },
                            }
                        },
                        {
                            key: "description",
                            name: "Description",
                            fieldName: "Description",
                            minWidth: 450,
                            maxWidth: 450,
                            onRender: (item) => {
                                return (
                                    <div style={{ width: 446 + "px" }}>
                                        {groupProps.group!.data.Description}
                                    </div>
                                );
                            },
                            isResizable: true
                        },
                        {
                            key: "eventType",
                            name: "Event Type",
                            fieldName: "EventType",
                            minWidth: 120,
                            maxWidth: 120,
                            onRender: (item) => {
                                return (

                                    <div style={{ width: 116 + "px" }}>
                                        {
                                            groupProps!.group!.key.indexOf("Event_") >= 0 ?
                                                groupProps.group!.data.EventType : ""}
                                    </div>
                                );
                            },
                            isResizable: true
                        },
                        {
                            key: "method",
                            name: "Method",
                            fieldName: "Method",
                            minWidth: 60,
                            maxWidth: 60,
                            onRender: (item) => {
                                return <div style={{ width: 56 + "px" }}></div>;
                            },
                            isResizable: true
                        },
                        {
                            key: "authoirzation",
                            name: "Authorization",
                            minWidth: 100,
                            maxWidth: 100,
                            onRender: (item) => {
                                return <div style={{ width: 96 + "px" }}></div>;
                            },
                            isResizable: true
                        },
                        {
                            key: "actions",
                            name: "Actions",
                            onRender: (item: any) => {

                                return (
                                    <div style={{ width: groupProps!.group!.key.indexOf("Event_") >= 0 ? "25px" : "40px", display: "block", textAlign: "right" }}>
                                        <IconButton
                                            menuIconProps={{
                                                iconName: "MoreVertical",
                                                style: {
                                                    fontSize: 20,
                                                    color: "#242424",
                                                    textAlign: "right"
                                                }
                                            }}
                                            role="button"
                                            aria-haspopup
                                            aria-label="Show actions"
                                            styles={{ root: { float: "right", height: "inherit" } }}
                                            menuProps={getConfigurationTemplateMenu(groupProps)}
                                            title={"Click to select from available actions."}
                                            id="actionsEventListButton"
                                        />
                                    </div>
                                );
                            },
                            minWidth: 100,
                            maxWidth: 100,
                            isResizable: true
                        },
                    ]}
                    selectionMode={SelectionMode.none}
                    item={groupProps.group!.data}
                    itemIndex={0}
                    cellStyleProps={{
                        cellExtraRightPadding: 0,
                        cellLeftPadding: 2,
                        cellRightPadding: 2
                    }}
                />
            );
        }
        return null;
    }

    const onToggleCollapse = (props: IGroupDividerProps): void => {
        props.onToggleCollapse!(props.group!);
    }

    const fetchMoreData = () => {
        setIsScrolling(true)
        const page = pageCount + 1

        OutboundServices.GetConfigurationTemplateList({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Content: searchContent.current,
            PageNo: page,
            Path: window.sessionStorage.getItem("site")!,
            RecordsPerPage: recordsPerPage,
            SessionKey: Helper.getSessionKey(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
        }).then(res => {
            let tempConfigurationTemplates: IGroup[] = [];
            let tempOutboundIntegrations: OutboundIntegrationModel[] = [];
            let _startIndexConfigurationTemplate: number = 0;
            res.forEach(item => {
                tempConfigurationTemplates.push({
                    key: "ConfigurationTemplate_" + item.Id.toString(),
                    name: item.Name,
                    startIndex: _startIndexConfigurationTemplate,
                    count: 1,
                    level: 0,
                    data: item,
                    isDropEnabled: false,
                    isCollapsed: true,
                    isShowingAll: false,
                    hasMoreData: false,
                    children: [{
                        key: "Event_" + item.ObjectIntegrationConfig.Id.toString(),
                        name: item.ObjectIntegrationConfig.Name,
                        startIndex: startIndexIntegration.current,
                        count: item.ObjectIntegrationConfig.Integrations.length,
                        level: 1,
                        data: item.ObjectIntegrationConfig,
                        isDropEnabled: false,
                        isCollapsed: true,
                        isShowingAll: false,
                        hasMoreData: false,
                        children: []

                    }]
                })
                tempOutboundIntegrations.push(...item.ObjectIntegrationConfig.Integrations)
                startIndexIntegration.current += item.ObjectIntegrationConfig.Integrations.length;
            })
            setOutboundIntegrations((prev) => [...prev, ...tempOutboundIntegrations]);
            setPageCount(prev => prev + 1 )
            if (tempConfigurationTemplates.length < recordsPerPage) {
                setHasMore(false)
            }

            if (props.isAdminMenu) {
                setConfigurationTemplateList((prev) => [...prev, ...tempConfigurationTemplates])
                setFilteredConfigurationTemplateList((prev) => [...prev, ...tempConfigurationTemplates])
            } else {
                setConfigurationTemplateList((prev) => [...prev, ...tempConfigurationTemplates.filter(f => f.data.Active)])
                setFilteredConfigurationTemplateList((prev) => [...prev, ...tempConfigurationTemplates.filter(f => f.data.Active)])
            }
            setIsScrolling(false)

        })
    }

    const customHideOutboundIntegrationModal = () => {
        setPageCount(1);
        setHasMore(true);
        setIsScrolling(false);
        searchContent.current = ""
        startIndexIntegration.current = 0
        hideOutboundIntegrationModal()
    }

    return (
        <>
            <Modal
                isOpen={props.isConfigurationTemplateListModaOpen}
                onDismiss={hideModal}
                isBlocking={true}
                containerClassName={contentStyles.container}
                styles={{ main: { width: "65%", height: props.isAdminMenu ? "550px" : "650px" } }}
            >
                <div className={contentStyles.header}>
                    <div>Configuration Template</div>
                    <IconButton styles={iconButtonStyles} iconProps={{ iconName: "Cancel" }} ariaLabel="Close popup modal" onClick={hideModal} />
                </div>

                <div className={contentStyles.body}>
                    {props.isAdminMenu ? null : <Stack
                        horizontal={true}
                        wrap={true}
                        disableShrink={true}
                        horizontalAlign={"start"}
                        verticalAlign={"start"}
                        styles={{ root: { marginTop: 0 } }}
                        tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                    >
                        <Stack.Item grow={2}>
                            <Text>
                                Choose any of the preconfigured outbound integrations from the list below by selecting “Use Configuration Template” from the first-level context menu.  If you’d like to know more about service before making a selection, expand the first-level menu and then select “View Event Read Mode” from the context menu and explore the properties.
                            </Text>
                        </Stack.Item>
                    </Stack>}
                    <Stack
                        horizontal={true}
                        wrap={true}
                        disableShrink={true}
                        horizontalAlign={"start"}
                        verticalAlign={"start"}
                        styles={{ root: { marginTop: 0 } }}
                        tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                    >
                        <Stack.Item grow={2}>
                            {isErrorMessageCTLVisible ? (
                                <MessageBar styles={{ root: { backgroundColor: "#ffd5d5" } }}>
                                    {message}
                                </MessageBar>
                            ) : null}
                            {isSuccessMessageCTLVisible ? (
                                <MessageBar styles={{ root: { backgroundColor: "#acffac" } }}>
                                    {message}
                                </MessageBar>
                            ) : null}
                        </Stack.Item>
                    </Stack>
                    <Stack
                        horizontal={true}
                        wrap={true}
                        disableShrink={true}
                        horizontalAlign={"start"}
                        verticalAlign={"start"}
                        styles={{ root: { marginTop: 0 } }}
                        tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                    >
                        <Stack.Item grow={2}>
                            <TextField label="Filter:" onChange={onFilter} styles={{ root: { maxWidth: '300px' } }} />
                        </Stack.Item>
                        <Stack.Item grow={3}>
                        </Stack.Item>
                        <Stack.Item grow={2}>
                        </Stack.Item>
                    </Stack>
                    <Stack
                        horizontal={true}
                        wrap={true}
                        disableShrink={true}
                        horizontalAlign={"start"}
                        verticalAlign={"start"}
                        styles={{ root: { marginTop: 0 } }}
                        tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                    >
                        <Stack.Item grow={2}>
                            {isLoadingVisible ?
                                <Spinner styles={{ root: { zIndex: "1" } }} size={SpinnerSize.large} label={loadingMessage} /> :
                                <div id="scrollableDiv" style={{ height: 300, overflow: "auto" }}>
                                    <InfiniteScroll
                                        dataLength={configurationTemplateList.length}
                                        next={fetchMoreData}
                                        hasMore={hasMore}
                                        loader={isScrolling && hasMore ? <Spinner size={SpinnerSize.large} label="Loading" ariaLive="assertive" /> : null}
                                        scrollableTarget="scrollableDiv"
                                        scrollThreshold={0.9}

                                    >
                                        <DetailsList
                                            className="ConfigurationTemplateList"
                                            isHeaderVisible={true}
                                            items={outboundIntegrations}
                                            columns={[
                                                {
                                                    key: "name",
                                                    name: "Name",
                                                    fieldName: "Name",
                                                    minWidth: 300,
                                                    maxWidth: 300,
                                                    isResizable: true
                                                },
                                                {
                                                    key: "description",
                                                    name: "Description",
                                                    fieldName: "Description",
                                                    minWidth: 450,
                                                    maxWidth: 450,
                                                    isResizable: true,
                                                },
                                                {
                                                    key: "eventType",
                                                    name: "Event Type",
                                                    minWidth: 120,
                                                    maxWidth: 120,
                                                    isResizable: false
                                                },
                                                {
                                                    key: "method",
                                                    name: "Method",
                                                    minWidth: 60,
                                                    maxWidth: 60,
                                                    isResizable: false,
                                                    onRender: (item: OutboundIntegrationModel) => {
                                                        return item.ObjectMapInput.MapInput.Params.Method;
                                                    },
                                                },
                                                {
                                                    key: "authorization",
                                                    name: "Authorization",
                                                    minWidth: 100,
                                                    maxWidth: 100,
                                                    isResizable: false,
                                                    onRender: (item: OutboundIntegrationModel) => {
                                                        return item.AuthenticationType;
                                                    },
                                                },
                                                {
                                                    key: "actions",
                                                    name: "Actions",
                                                    onRender: (item: OutboundIntegrationModel) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    width: "25px",
                                                                    display: "block",
                                                                    textAlign: "right",
                                                                }}
                                                            >
                                                                <IconButton
                                                                    menuIconProps={{
                                                                        iconName: "MoreVertical",
                                                                        style: {
                                                                            fontSize: 20,
                                                                            color: "#242424",
                                                                            textAlign: "right",
                                                                        },
                                                                    }}
                                                                    role="button"
                                                                    aria-haspopup
                                                                    aria-label="Show actions"
                                                                    styles={{
                                                                        root: { float: "right", height: "inherit" },
                                                                    }}
                                                                    menuProps={getIntegrationEventsMenu(item)}
                                                                    title={"Click to select from available actions."}
                                                                    id="actionsCampaignListButton"
                                                                />
                                                            </div>
                                                        );
                                                    },
                                                    minWidth: 100,
                                                    maxWidth: 100,
                                                    isResizable: true,
                                                }
                                            ]}
                                            cellStyleProps={{ cellExtraRightPadding: 0, cellLeftPadding: 0, cellRightPadding: 0 }}
                                            selectionMode={SelectionMode.none}
                                            groups={filteredConfigurationTemplateList}
                                            compact={true}
                                            indentWidth={18}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            selectionPreservedOnEmptyClick={true}
                                            constrainMode={ConstrainMode.unconstrained}

                                            ariaLabelForSelectionColumn="Toggle selection"
                                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                            checkButtonAriaLabel="select row"
                                            groupProps={{
                                                onRenderHeader: onRenderGroupHeader,
                                                showEmptyGroups: true,
                                                isAllGroupsCollapsed: true
                                            }}
                                        />
                                    </InfiniteScroll>
                                </div>
                            }
                        </Stack.Item>
                    </Stack>
                </div>


            </Modal>
            {isConfigurationTemplateModalOpen ?
                <ConfigurationTemplate
                    event={event}
                    hideConfigurationTemplateModal={hideConfigurationTemplateModal}
                    showSuccessMessage={showSuccessMessageCTL}
                    showErrorMessage={showErrorMessageCTL}
                    configurationTemplateToUpdate={configurationTemplate}
                    reloadConfigurationTemplates={getConfigurationTemplates} />
                : null}

            {isEventOpen ? (
                <IntegrationEvent
                    event={event!}
                    templateId={Number(event.current?.TemplateId)}
                    loadEvents={getConfigurationTemplates}
                    isEventOpen={isEventOpen}
                    hideEventModal={hideEventModalCustom}
                    showSuccessMessage={showSuccessMessageCTL}
                    configurationTemplate={configurationTemplate}
                />) : null}

            {isOutboundIntegrationOpen ? (
                <OutboundIntegration
                    templateId={configurationTemplate.current!.ObjectIntegrationConfig.TemplateId}
                    event={event}
                    outboundIntegration={outboundIntegration.current!}
                    outboundIntegrations={event.current?.Integrations!}
                    hideOutboundIntegrationModal={customHideOutboundIntegrationModal}
                    isOutboundIntegrationOpen={isOutboundIntegrationOpen}
                    loadEvents={getConfigurationTemplates}
                    showSuccessMessage={showSuccessMessageCTL}
                    configurationTemplate={configurationTemplate}
                    isConfigurationTemplateReadMode={isConfigurationTemplateReadMode}
                    setConfigurationTemplateEditMode={setConfigurationTemplateEditMode}
                />
            ) : null}


        </>
    );
}

export default ConfigurationTemplateList;