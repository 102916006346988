import {IStackStyles, mergeStyleSets, IButtonStyles} from "@fluentui/react";
import {Theme} from "@fluentui/react-theme-provider";

export const deleteButtonStyles = (theme:Theme):IButtonStyles => {
    return {
        root: {
            borderColor: theme.palette.red,
            color: theme.palette.red
        },
        rootChecked: {
            color: theme.palette.white,
            backgroundColor: "none"
        },
        rootPressed: {
            color: theme.palette.redDark,
            backgroundColor: "none"
        },
        rootHovered: {
            color: theme.palette.redDark,
            backgroundColor: "none"
        },
        labelHovered: {
            color: theme.palette.redDark
        },
        labelChecked: {
            color: theme.palette.redDark
        },
    }    
} 

export const _styles = (theme: Theme) => {

    return mergeStyleSets({
        body: {
            flex: '4 4 auto',
            padding: 0,
        }        
    })
}

export const nonShrinkingStackItemStyles: IStackStyles = {
    root: {
        padding: 12,
        overflow: 'hidden',
        alignItems: "center",
    }
}
