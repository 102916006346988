import {
    CheckboxVisibility,
    ConstrainMode,
    DetailsListLayoutMode,
    Dropdown,
    FontWeights,
    IDropdownOption,
    Label,
    Link,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    SelectionMode,
    Separator,
    ShimmeredDetailsList,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem,
    Text
} from "@fluentui/react";
import Helper from "../../Helpers/Helper";
import React, {useEffect, useState} from "react";
import GaugeChart from "react-gauge-chart";
import {useBranding} from "../../hooks/useBranding";
import {CarruselBillingPlan, columns, months} from "./index";
import convertSize from "convert-size";
import {useTranslation} from "react-i18next";
import ManageClientPlansService, {
    IGetAllManageClientPlansRequest,
    IGetAllManageClientPlansResponse
} from "../../services/ManageClientPlans";
import PlanUsageService, {
    IGetPlanUsageByYearMonthRequest,
    IGetPlanUsageByYearMonthResponse
} from "../../services/PlanUsage";
import PlanService, {IGetPlanByIdRequest, IPlanResponse} from "../../services/Plan";
import {formatSimpleDate} from "../../Helpers/DateUtils";
import BillingStateService, {IGetAllBillingStateOwnerHistoryRequest} from "../../services/BillingState";
import CustomDialog from "../../share/CustomDialog";
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import PaymentService, {ICancelSubscriptionRequest} from "../../services/Payment";


type IProperties = {
    showContent: boolean
}
export function Content({showContent}:IProperties) {

    const {branding} = useBranding()
    const {t} = useTranslation(['common', 'mybillingplan'])

    const [isLoading, setIsLoading] = useState(true)
    const [isLoaded, setIsLoaded] = useState(false)

    const [message, setMessage] = useState("")
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)

    const [currentBillingPlan, setCurrentBillingPlan] = useState<IGetAllManageClientPlansResponse | null>(null)
    const [currentPlan, setCurrentPlan] = useState<IPlanResponse | null>(null)
    const [planUsage, setPlanUsage] = useState<IGetPlanUsageByYearMonthResponse | null>(null)
    const [userType, setUserType] = useState("");
    const [isLoadingHistory, setIsLoadingHistory] = useState(false)
    const [currentDate] = useState(new Date())
    const [selectedMonth, setSelectedMonth] = useState(-1);
    const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear())
    const [listItems, setlistItems] = useState<IGetPlanUsageByYearMonthResponse[]>([])
    const [allowCancelSubscription, setAllowCancelSubscription] = useState(false)
    const [allowCancelOnUpdateSubscription, setAllowCancelOnUpdateSubscription] = useState(true)
    const [showCancelSubscription, setShowCancelSubscription] = useState(false)
    const [isCancelingSubscription, setIsCancelingSubscription] = useState(false)
    const [showPlans, setShowPlans] = useState(false)


    useEffect(() => {
        if(showContent) {
            init().then()
        }
    },[])

    /**
     * Close modal
     */
    const closeModal = (reload: boolean = false) => {
        setShowPlans(false)
        if (reload) {
            setTimeout(function () {
                init(true).then()
            }, 2000)
        }
    }

    /**
     * Load information
     */
    async function init(cleanFlag: boolean = true) {

        setIsLoading(true)
        setIsLoaded(false)
        setCurrentBillingPlan(null)
        setCurrentPlan(null)
        setPlanUsage(null)
        setlistItems([])
        setUserType("")
        setAllowCancelSubscription(false)
        setShowCancelSubscription(false)
        setIsCancelingSubscription(false)

        if (cleanFlag) {
            setShowMessage(false)
            setStatusMessageEnum(StatusEnum.Info)
            setMessage("")
            setAllowCancelOnUpdateSubscription(true)
        }
        setShowPlans(false)
        const request: IGetAllManageClientPlansRequest = {
            ownerName: Helper.getUsername()
        }
        await ManageClientPlansService.GetAllManageClientPlans(request)
            .then(async (result) => {
                if (result.length === 0) {
                    throw new Error(t('message.notFound', {ns: 'common'}))
                } else {

                    // Client Plans
                    setCurrentBillingPlan(result[0])

                    let promises = [];

                    const request2: IGetPlanByIdRequest = {
                        Type: "",
                        UseCache: true,
                        Id: result[0].BillingPlanId.toString()
                    }
                    promises.push(
                        PlanService.GetPlanById(request2)
                            .then(async (result2) => {
                                if (result2.length > 0) {
                                    // Plan Info
                                    setCurrentPlan(result2[0])
                                } else {
                                    throw new Error(t('message.notFound', {ns: 'common'}))
                                }
                            })
                            .catch((error: any) => {
                                throw error
                            })
                    )

                    const currentDate = new Date()
                    const request3: IGetPlanUsageByYearMonthRequest = {
                        BillingClientId: result[0].Id.toString(),
                        Year: currentDate.getFullYear().toString()
                    }

                    promises.push(
                        PlanUsageService.GetPlanUsageByYearMonth(request3)
                            .then(async (result3) => {
                                setUserType("OWNER")
                                if (result3.length > 0) {
                                    // Client Plan Usage
                                    setPlanUsage(result3[0])
                                } else {
                                    setPlanUsage({
                                        BillingClientId: 0,
                                        Id: 0,
                                        Month: 0,
                                        Year: 0,
                                        CampaignCount: 0, UserCount: 0, SubmissionCount: 0, StorageUsed: 0
                                    })
                                }
                            })
                            .catch((error: any) => {
                                throw error
                            })
                    )


                    const request4: IGetAllBillingStateOwnerHistoryRequest = {
                        ownerName: Helper.getUsername()
                    }

                    promises.push(
                        BillingStateService.GetAllBillingStateOwnerHistory(request4)
                            .then(async (result4) => {
                                if (result4.length > 0) {
                                    // Transaction Plan
                                    setAllowCancelSubscription(result4[0].TransactionDescription.toLowerCase().includes("cancel"))
                                } else {
                                    throw new Error(t('message.notFound', {ns: 'common'}))
                                }
                            })
                            .catch((error: any) => {
                                throw error
                            })
                    )

                    await Promise.all(promises)
                        .then(() => {
                            setIsLoaded(true)
                        })
                        .catch((error: any) => {
                            throw error
                        })

                }
            })
            .catch((error) => {
                setMessage(Helper.GetErrorMessage(error))
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
                setIsLoaded(false)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    async function loadPlanUsageHistoryByFilter(year: string, month: number) {

        if (month === -1) {
            setlistItems([])
        } else {
            setIsLoadingHistory(true)

            let request: IGetPlanUsageByYearMonthRequest = {
                BillingClientId: currentBillingPlan!.Id.toString(),
                Year: year,
                Month: month.toString()
            }


            if (month === 0) {
                let allMonthsItems: IGetPlanUsageByYearMonthResponse[] = [];
                for (const fe of months().filter(f => f.key > 0)) {

                    request.Month = fe!.key.toString()
                    await PlanUsageService.GetPlanUsageByYearMonth(request)
                        .then((data) => {
                            allMonthsItems = [...allMonthsItems, ...data];
                            setlistItems(allMonthsItems)
                        })
                }
                setIsLoadingHistory(false)
            } else {
                await PlanUsageService.GetPlanUsageByYearMonth(request)
                    .then((data) => {
                        setlistItems(data)
                    })
                    .finally(() => {
                        setIsLoadingHistory(false)
                    })
            }
        }
    }

    const years: IDropdownOption[] = []


    const chartStyle = {
        height: "80%",
    }

    const onMonthChange = async (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setSelectedMonth(option?.key as number)
        loadPlanUsageHistoryByFilter(selectedYear.toString(), Number(option!.key)).then()
    }

    const onYearChange = async (_?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setSelectedYear(option?.key as number)
        loadPlanUsageHistoryByFilter(option?.text!, selectedMonth).then()
    }

    /**
     * Populate years dropdownlist
     */
    const getYears = () => {
        for (let i = 2021; i <= (new Date()).getFullYear(); i++) {
            let year: IDropdownOption = {
                key: i,
                text: i.toString()
            }
            years.push(year)
        }
        return years;
    }

    /**
     * total Submissions percentage
     */
    function TotalSubmissions() {
        return ((planUsage!.SubmissionCount * 100) / currentPlan!.SubmissionLimit) / 100
    }

    /**
     * total Users percentage
     */
    function TotalUsers() {
        const total = planUsage!.UserCount > 0 ? planUsage!.UserCount : 1
        return ((total * 100) / (currentPlan!.UserLimit + currentPlan!.WorkspaceOnlyUsers )) / 100
    }

    /**
     * total Plan Size percentage
     */
    function TotalPlanSize() {
        const totalSize = planUsage!.StorageUsed
        const percentLimit = Number(convertSize(currentPlan!.StorageLimit, "GB")).toFixed(2);
        return (totalSize / Number(percentLimit))
    }

    /**
     * Total Storage used
     * @constructor
     */
    function TotalStorageUsed() {
        return (Number(convertSize(planUsage!.StorageUsed * 1000000000, "MB")).toString() + " MB")
    }

    /**
     * Periodicity
     * @constructor
     */
    function Periodicity() {
        const unit = currentPlan!.TransactionUnit.toLowerCase().trim()

        switch (unit) {
            case "week":
                return t("text.frequencyWeekly", {ns: "common"})
            case "month":
                return t("text.frequencyMonthly", {ns: "common"})
            case "year":
                return t("text.frequencyAnnual", {ns: "common"})
            default:
                return t("text.frequencyCustom", {ns: "common"})
        }
    }

    /**
     * Cancel Subscription
     * @constructor
     */
    async function CancelSubscription() {
        setIsCancelingSubscription(true)
        const request: ICancelSubscriptionRequest = {
            PlanName: currentPlan!.Name,
            SubscriptionId: currentBillingPlan!.SubscriptionId.toString(),
            OwnerName: currentBillingPlan!.OwnerName,
            BillingPlanId: currentPlan!.Id
        }
        await PaymentService.cancelSubscription(request)
            .then(() => {
                setAllowCancelSubscription(false)
                setAllowCancelOnUpdateSubscription(false)
                setMessage(t('confirmedCancelation', {ns: 'mybillingplan'}))
                setStatusMessageEnum(StatusEnum.Success)
                setShowMessage(true)
                init(false).then()
            })
            .finally(() => {
                setShowCancelSubscription(false)
                setIsCancelingSubscription(false)
            })
            .catch((error: any) => {
                setMessage(t('message.error', {ns: 'common', Error: Helper.GetErrorMessage(error)}))
                setStatusMessageEnum(StatusEnum.Error)
                setShowMessage(true)
            })
    }

    return (
        <>

            {/*region MESSAGES*/}
            <StackItem>
                {showMessage &&
                    <>
                        <StatusMessage status={statusMessageEnum} hasTimer={false}
                                       truncated={true}
                                       isMultiline={true}
                                       dismiss={true}
                                       setShowMessage={setShowMessage}>
                            <div>{message}</div>
                        </StatusMessage>
                    </>
                }
            </StackItem>
            {/*endregion*/}

            {/*region Content*/}
            {isLoading &&
                <Stack grow={1} styles={{root: {justifyContent: "center"}}}>
                    <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})} ariaLive="assertive"/>
                </Stack>
            }

            {!isLoading && isLoaded &&
                <>

                    <Stack horizontal wrap tokens={{childrenGap: 4}} styles={{inner: {alignItems: "end"}}}>
                        <Stack grow={1}>

                            <Dropdown
                                id="PropFieldType"
                                label={t("client", {ns: "mybillingplan"})}
                                options={[{
                                    key: currentBillingPlan!.Id,
                                    text: `${currentBillingPlan!.OrganizationName} (${currentBillingPlan!.ContactEmailAddress})`
                                }]}
                                onChange={undefined}
                                selectedKey={currentBillingPlan!.Id}
                                title={t("client", {ns: "mybillingplan"})}
                                styles={{root: {width: "100%"}}}
                            />
                        </Stack>
                        <Stack styles={{root: {flexBasis: "content"}}}>
                            <>
                                {!currentBillingPlan!.Active &&
                                    <>
                                        {t("planNotActivated", {ns: "mybillingplan"})}
                                        <br/>
                                    </>
                                }

                                <PrimaryButton
                                    disabled={!currentBillingPlan!.Active}
                                    onClick={() => {
                                        setShowPlans(true)
                                    }}
                                    text={t("plans", {ns: "mybillingplan"})}
                                    title={t("billingPlans", {ns: "mybillingplan"})}
                                    styles={{root: {width: "100%"}}}
                                />
                            </>
                        </Stack>
                    </Stack>


                    <Separator/>
                    <Stack tokens={{childrenGap: 4}} styles={{root: {paddingTop: 24}}}>

                        {(currentDate >= new Date(currentBillingPlan!.NextDueDate) || !allowCancelSubscription) && (currentBillingPlan!.SubscriptionId !== "-1" && currentBillingPlan!.SubscriptionId.toLowerCase() !== "na") && allowCancelOnUpdateSubscription ?
                            <>
                                <Text variant={"mediumPlus"}
                                      styles={{root: {textAlign: "center", fontWeight: FontWeights.bold}}} block>
                                    {t("currentPlanDueDate", {
                                        ns: "mybillingplan",
                                        Name: currentPlan?.Name,
                                        Period: formatSimpleDate(currentBillingPlan!.NextDueDate, 'll')
                                    })}
                                </Text>

                                <Link style={{textAlign: "center", fontSize: "lager"}}
                                      onClick={() => {
                                          setShowCancelSubscription(true);
                                      }}>
                                    {t("confirmCancelationTitle", {ns: "mybillingplan"})}
                                </Link>

                            </>
                            :
                            <>
                                <Text variant={"mediumPlus"}
                                      styles={{root: {textAlign: "center", fontWeight: FontWeights.bold}}} block>
                                    {t("currentPlanPeriod", {
                                        ns: "mybillingplan",
                                        Name: currentPlan?.Name,
                                        Period: Periodicity()
                                    })}
                                </Text>
                                <Text variant={"medium"} styles={{root: {textAlign: "center"}}} block>
                                    {t("membershipPeriod", {
                                        ns: "mybillingplan",
                                        Date: formatSimpleDate(currentBillingPlan!.NextDueDate, 'll')
                                    })}
                                </Text>
                            </>
                        }

                        <Text variant={"smallPlus"}
                              styles={{root: {textAlign: "center", fontWeight: FontWeights.bold}}} block
                              title={t("userTypeViewer", {ns: "mybillingplan", NameType: userType})}>
                            {t("userTypeViewer", {ns: "mybillingplan", NameType: userType})}
                        </Text>
                    </Stack>


                    <Stack styles={{root: {paddingTop: 16, paddingBottom: 16}}}>
                        {currentPlan!.SubmissionLimit < planUsage!.SubmissionCount &&
                            <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={true}
                            >
                                {t("documentsExceeded", {ns: "mybillingplan"})}
                            </MessageBar>
                        }
                    </Stack>

                    <Stack wrap horizontal tokens={{childrenGap: 8}}
                           styles={{
                               inner: {justifyContent: "center"},
                               root: {paddingTop: "16px", paddingBottom: "16px"}
                           }}>

                        <Stack styles={{root: {width: 240, alignItems: "center"}}}>
                            <GaugeChart id="gauge-chart1"
                                        style={chartStyle}
                                        nrOfLevels={10}
                                        arcPadding={0.1}
                                        cornerRadius={3}
                                        percent={TotalSubmissions() > 1 ? 1 : TotalSubmissions()}
                                        textColor={branding.theme.palette.themePrimary}
                            />
                            <Label

                                title={t("documentspermonth", {
                                    ns: "mybillingplan",
                                    Documents: Helper.FormatNumber(currentPlan!.SubmissionLimit)
                                })}
                                style={{textAlign: "center"}}>
                                {t("records", {
                                    ns: "mybillingplan",
                                    'interpolation': {'escapeValue': false},
                                    Records: `${Helper.FormatNumber(planUsage!.SubmissionCount)} / ${Helper.FormatNumber(currentPlan!.SubmissionLimit)}`
                                })}
                            </Label>
                        </Stack>

                        <Stack styles={{root: {width: 240, alignItems: "center"}}}>
                            <GaugeChart id="gauge-chart2"
                                        style={chartStyle}
                                        nrOfLevels={10}
                                        arcPadding={0.1}
                                        cornerRadius={3}
                                        percent={TotalUsers() > 1 ? 1 : TotalUsers()}
                                        textColor={branding.theme.palette.themePrimary}
                            />
                            <Text variant={"mediumPlus"} block style={{
                                fontWeight: "500",
                                textAlign: "center"
                            }}>
                                {t("users", {
                                    ns: "mybillingplan",
                                    'interpolation': {'escapeValue': false},
                                    Users: `${Helper.FormatNumber(planUsage!.UserCount > 0 ? planUsage!.UserCount : 1)} / ${Helper.FormatNumber(currentPlan!.WorkspaceOnlyUsers ? currentPlan!.UserLimit + currentPlan!.WorkspaceOnlyUsers : currentPlan!.UserLimit)}`
                                })}
                            </Text>
                            <Text variant={"medium"} block
                                  style={{textAlign: "center"}}>
                                {t("superUsers", {
                                    ns: "mybillingplan",
                                    'interpolation': {'escapeValue': false},
                                    Users: `${Helper.FormatNumber(currentPlan!.UserLimit)}`
                                })}
                            </Text>
                            <Text variant={"medium"} block
                                  style={{textAlign: "center"}}>
                                {t("workspaceUsers", {
                                    ns: "mybillingplan",
                                    'interpolation': {'escapeValue': false},
                                    Users: `${Helper.FormatNumber(currentPlan!.WorkspaceOnlyUsers)}`
                                })}
                            </Text>
                        </Stack>

                        <Stack styles={{root: {width: 240, alignItems: "center"}}}>
                            <GaugeChart id="gauge-chart3"
                                        style={chartStyle}
                                        nrOfLevels={10}
                                        arcPadding={0.1}
                                        cornerRadius={3}
                                        percent={TotalPlanSize() > 1 ? 1 : TotalPlanSize()}
                                        textColor={branding.theme.palette.themePrimary}
                            />
                            <Text variant={"mediumPlus"} block style={{
                                fontWeight: "500",
                                textAlign: "center"
                            }}>
                                {t("sizeStored", {
                                    ns: "mybillingplan",
                                    'interpolation': {'escapeValue': false},
                                    Size: `${TotalStorageUsed()}/${convertSize(currentPlan!.StorageLimit)}`
                                })}
                            </Text>
                        </Stack>

                        <Stack styles={{root: {width: 240, justifyContent: "center", alignItems: "center"}}}>
                            <Stack>
                                <Text variant={"xxLargePlus"} block title={t("campaigns", {ns: "mybillingplan"})}
                                      style={{
                                          fontWeight: "500", textAlign: "center"
                                      }}> {planUsage!.CampaignCount}</Text>

                                <Text variant={"mediumPlus"} block style={{
                                    fontWeight: "500", textAlign: "center"
                                }}>
                                    {t("campaigns", {ns: "mybillingplan"})}
                                </Text>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Separator/>
                    <Stack styles={{root: {paddingTop: 16, paddingBottom: 16}}}>
                        <Label>{t("planUsageHistory", {ns: "mybillingplan"})}</Label>
                        <Stack horizontal tokens={{childrenGap: 8}}>
                            <Stack grow={1}>
                                <Dropdown
                                    id="yearDropDown"
                                    label={t("label.year", {ns: "common"})}
                                    options={getYears()}
                                    onChange={onYearChange}
                                    selectedKey={selectedYear}
                                    title={t("label.year", {ns: "common"})}
                                />
                            </Stack>
                            <Stack grow={1}>
                                <Dropdown
                                    id="monthDropDown"
                                    label={t("label.month", {ns: "common"})}
                                    options={months()}
                                    onChange={onMonthChange}
                                    selectedKey={selectedMonth}
                                    title={t("label.month", {ns: "common"})}
                                />
                            </Stack>
                        </Stack>
                    </Stack>


                    <Stack grow={1} styles={{root: {height: 150}}}>
                        <div style={{overflow: "auto", display: "block", scrollbarWidth: "thin"}}
                             data-is-scrollable="true">
                            <ShimmeredDetailsList
                                enableShimmer={isLoadingHistory}
                                items={listItems}
                                compact={true}
                                columns={columns()}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                constrainMode={ConstrainMode.unconstrained}
                                isHeaderVisible={true}
                                selectionMode={SelectionMode.none}
                                checkboxVisibility={CheckboxVisibility.hidden}
                            />
                        </div>
                    </Stack>

                </>
            }
            {/*endregion*/}

            {/*region Cancel Dialog*/}
            <CustomDialog title={t('confirmCancelationTitle', {ns: 'mybillingplan'})} titleId={`CancelMembership`}
                          actionText={t('Common.Button.Continue', {ns: 'common'})}
                          confirm={CancelSubscription}
                          close={() => {
                              setShowCancelSubscription(false)
                          }}
                          isDisabled={isCancelingSubscription}
                          isOpen={showCancelSubscription}
            >
                <>
                    {isCancelingSubscription ?
                        <>
                            <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})}
                                     ariaLive="assertive"/>
                        </>
                        :
                        <>
                            {t('confirmCancelation', {ns: 'mybillingplan'})}
                        </>
                    }

                </>
            </CustomDialog>
            {/*endregion*/}


            <CarruselBillingPlan
                setShowModal={closeModal}
                showModal={showPlans}
            />

        </>
    )
}


