import HttpRequest from "./HttpRequest";
import Helper, {IHttpClientResponseBag} from "../Helpers/Helper";

export interface IResendRequest {
    AudienceId: string
    Owner: string
    TemplateId: string
    Approver: string
    ServiceEmail: string
    ClientID: string
    ClientSecret: string
    RedirectURL: string
}

export interface ITestRequest {
    TemplateId: string
}

export interface IDeleteRequest {
    AudienceId: string
    Owner: string
    Id?: string
    TemplateId: string
}

export interface IConsentUrlRequest {
    ClientId: string
    RedirectUrl: string
    Scope: string
    State: string
}

export interface IConsentUrlResponse {
    Url: string
}

export interface IConsentRequest {
    State: string
    Code: string
}

const GraphApiService = {

    /**
     * Resend
     * @param request
     * @constructor
     */
    Resend: async (request: IResendRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('GraphApi/Resend', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * Test
     * @param request
     * @constructor
     */
    Test: async (request: ITestRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('GraphApi/Test', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * Delete
     * @param request
     * @constructor
     */
    Delete: async (request: IDeleteRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('GraphApi/Delete', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * GraphApi
     * @param request
     * @constructor
     */
    GraphApi: async (request: IResendRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('GraphApi', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * ConsentUrl
     * @param request
     * @constructor
     */
    ConsentUrl: async (request: IConsentUrlRequest): Promise<IConsentUrlResponse> => {
        let {data}: any = await HttpRequest.post('GraphApi/ConsentUrl', request)

        return new Promise<IConsentUrlResponse>((resolve, reject) => {
            if (!data.Url) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * Consent
     * @param request
     * @constructor
     */

    Consent: async (request: IConsentRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.post('GraphApi/Consent', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    }
}

export default GraphApiService