import {
    Checkbox,
    ChoiceGroup,
    IChoiceGroupOption,
    IMessageBarStyles,
    IScrollablePaneStyles,
    Label,
    Link,
    MessageBar,
    ScrollablePane,
    SearchBox,
    Spinner,
    SpinnerSize,
    Text
} from '@fluentui/react';
import {ActionButton, DefaultButton, IButtonStyles, IconButton} from '@fluentui/react/lib/Button';
import {IContextualMenuItem, IContextualMenuProps} from '@fluentui/react/lib/ContextualMenu';
import {
    CheckboxVisibility,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode
} from '@fluentui/react/lib/DetailsList';
import {DialogFooter} from '@fluentui/react/lib/Dialog';
import {IIconProps} from '@fluentui/react/lib/Icon';
import {Modal} from '@fluentui/react/lib/Modal';
import {INavLink, INavLinkGroup, Nav} from '@fluentui/react/lib/Nav';
import {Separator} from '@fluentui/react/lib/Separator';
import {Stack} from '@fluentui/react/lib/Stack';
import {FontWeights, getTheme, mergeStyleSets} from '@fluentui/react/lib/Styling';
import {MarqueeSelection, Selection} from 'office-ui-fabric-react/lib/MarqueeSelection';
import CampaignUser from './CampaignUser';
import CampaignUserLists from './CampaignUserList';
import React, { useEffect, useState, useRef } from 'react';
import Helper from '../../Helpers/Helper';
import '../../Custom.css';
import RichTextEditor, {EditorValue} from 'react-rte';
import {DatePicker} from '@fluentui/react/lib/DatePicker';
import ReactPaginate from 'react-paginate';
import {isMobile} from 'react-device-detect';
import {useConstCallback} from '@uifabric/react-hooks';
import ConfirmationModal from '../../Helpers/Modals/ConfirmationModal';
import HelpComponent from '../Help/HelpComponent'
import ReactDOM from 'react-dom';
import {formatFromUTC} from '../../Helpers/DateUtils';
import WebFormCampaignService from '../../services/webFormCampaign';
import {FormProvider, useForm} from "react-hook-form";
import {RHFTextBoxControl} from "../RHFControls/RHFTextBoxControl";
import {useTranslation} from "react-i18next";
import {RHFCheckBoxControl} from "../RHFControls/RHFChekBoxControl";
import { useBranding } from "../../hooks/useBranding";
import { decode } from "base64-arraybuffer";
import CampaignService from "../../services/campaign";
import HttpRequest from "../../services/HttpRequest";

export interface IUserCampaign {
    Id: number
    AccountId: number
    CampaignMasterId: number
    Username: string
    FirstName: string
    LastName: string
    PhoneNumber: string
    DateCompleted: string
    DateReminded: string
    DateCreated: string
    Records: number
}

const emptyRespondent: IUserCampaign = {
    Id: -1,
    AccountId: -1,
    CampaignMasterId: -1,
    Username: "",
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    DateCompleted: "",
    DateReminded: "",
    DateCreated: "",
    Records: -1
}

interface SpecificationFile {
    FileName: string;
    FileBytes: string;
}

const theme = getTheme();


const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};

const cancelIcon: IIconProps = {iconName: 'Cancel'};
const stackTokens = {childrenGap: 50};
const stackTokensUsers = {childrenGap: 15};
const iconButtonStyles: Partial<IButtonStyles> = {root: {float: 'right', height: 'inherit'}};

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}


function Campaign(props: any) {
    const { branding } = useBranding()
    const {t} = useTranslation(['common'])

    const [selectedKey, setSelectedKey] = useState("1")
    const [isNewCampaign, setIsNewCampaign] = useState(true)
    const [showUploadUsers, setShowUploadUsers] = useState(false)
    const [showAddEditUser, setShowAddEditUser] = useState(false)
    const itemList: IUserCampaign[] = []
    const [userItems, setUserItems] = useState(itemList)

    const [campaignName, setCampaignName] = useState("")
    const [campaignDescription, setCampaignDescription] = useState("")
    const [isCampaignActive, setIsCampaignActive] = useState(false);
    const [returnURL, setReturnURL] = useState("")
    const [emailOwner, setEmailOwner] = useState("")
    const [emailSubject, setEmailSubject] = useState("")
    const [emailReminderSubject, setEmailReminderSubject] = useState("")
    const [campaignURI, setCampaignURI] = useState("")
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [rteValue, setRteValue] = useState(EditorValue.createEmpty())
    const [rteValue2, setRteValue2] = useState(EditorValue.createEmpty())
    const [campaignStartDate, setCampaignStartDate] = useState<Date | undefined>();
    const [campaignEndDate, setCampaignEndDate] = useState<Date | undefined>();
    const [pageSelected, setPageSelected] = useState(1);
    const [isLoadingUsers, setIsLoadingUsers] = useState(false)
    const [pageCount, setPageCount] = useState(1);
    const [rteValueDefault, setRteValueDefault] = useState(EditorValue.createEmpty())
    const [rteValue2Default, setRteValue2Default] = useState(EditorValue.createEmpty())

    const [showMessage, setShowMessage] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [message, setMessage] = useState("");
    const [showConfirm, setShowConfirm] = useState(false)
    const [confirmMessage, setConfirmMessage] = useState("")
    const [actionText, setActionText] = useState("")
    const [spinnerText, setSpinnerText] = useState("")
    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [filter, setFilter] = useState("0")
    const [searchText, setSearchText] = useState("")
    const [showDeleteRespondentModal, setShowDeleteRespondentModal] = useState(false)
    const [respondent, setRespondent] = useState(emptyRespondent)
    const [editRespondent, setEditRespondent] = useState(false)
    const [operation, setOperation] = useState(0);
    const [showResendAccessCodeModal, setShowResendAccessCodeModal] = useState(false)
    const campaigneForm = useForm();
    const [busySpinning, setbusySpinning] = useState(false);

    const iconModalButtonStyles = {
        root: {
            color: branding.theme.palette.themePrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },

    };
    
    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
            },
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        bodyNote: {
            width: '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: {margin: '14px 0'},
                'p:first-child': {marginTop: 0},
                'p:last-child': {marginBottom: 0},
            },
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        hideMenu: {
            display: 'none'
        },
        showMenu: {
            display: 'inline'
        },
        contentCampaignList: {
            height: '50vh',
            position: 'relative',
            backgroundColor: 'white',
        },
        pane: {
            maxWidth: "100%",
            borderr: '0 10px  0 0 solid ' + theme.palette.neutralLight,
        },
    });
    const contentStyles = mergeStyleSets({
        pagination: {
            padding: 0,
            selectors: {
                "ul": {
                    display: "inline-block"
                },
                "li": {
                    fontSize: "12pt",
                    color: branding.theme.palette.themeDarker,
                    display: "inline-block",
                    border: `solid 1px ${branding.theme.palette.themeDarker}`,
                    cursor: "pointer",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    borderCollapse: "collapse",
                    fontWeight: "500"
                }
            }
        },
        active: {
            backgroundColor: branding.theme.palette.themeDarker,
            selectors: {
                "a": {
                    color: branding.theme.palette.white
                }
            }
        },
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '60vw',
            height: '60vh',
            flex: '4 4 auto',
            selectors: {
                p: {margin: '14px 0'},
                'p:first-child': {marginTop: 0},
                'p:last-child': {marginBottom: 0},
            },
        },
    });
    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = {root: classNames.pane};

    const _deselect = () => {
        if (selection.getSelectedIndices().length >= 1)
            selection.toggleIndexSelected(selection.getSelectedIndices()[0]);
        selection.setAllSelected(false);
    }

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const campaignLinks: INavLinkGroup[] = [
        {
            name: '',
            links: [
                {name: 'General', url: '', key: '1', icon: 'ContactList', title: 'Manage campaign.'},
                {
                    name: 'Respondents/Status',
                    url: '',
                    key: '2',
                    icon: 'AllApps',
                    title: 'Manage respondents for this campaign.'
                },
                {
                    name: 'Prefill Campaign',
                    url: '',
                    key: '3',
                    icon: 'AutoFillTemplate',
                    title: 'Manage Prefill file upload for this campaign.'
                },
            ],
        }
    ];

    const campaignLinksNew: INavLinkGroup[] = [
        {
            name: '',
            links: [
                {name: 'General', url: '', key: '1', icon: 'ContactList', title: 'Manage campaign.'},
            ],
        }
    ];

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete Respondent.',
            onClick: () => {
                setShowDeleteRespondentModal(true)
            },
        },
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit Respondent.',
            onClick: () => {
                setShowAddEditUser(true)
                setEditRespondent(true)
            },
        },
        {
            key: 'resend',
            text: 'Resend',
            title: 'Resend campaign to only respondent.',
            onClick: () => {
                setShowResendAccessCodeModal(true)
            },
        },
    ]

    const userFullName = (item: IUserCampaign) => {
        return <div title={`${item.FirstName} ${item.LastName}`}>
            {item.FirstName} {item.LastName}</div>
    }

    const menuProps: IContextualMenuProps = {
        onMenuOpened: () => {
        },
        shouldFocusOnMount: true,
        items: menuItems
    };

    const columnsWidth = () => {
        if (windowSize.innerWidth >= 2048) {
            return 140
        }
        if (windowSize.innerWidth >= 1920) {
            return 130
        }
        if (windowSize.innerWidth >= 1536) {
            return 100
        }
        if (windowSize.innerWidth >= 1440) {
            return 90
        }
        if (windowSize.innerWidth >= 1366) {
            return 80
        }
        if (windowSize.innerWidth >= 1280) {
            return 70
        }
        if (windowSize.innerWidth >= 1024) {
            return 60
        }
        return 50
    }

    const columns: IColumn[] = [
        {
            key: 'column0',
            name: ' Username',
            fieldName: 'Username',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'string',
            isPadded: false,
            onRender: (item: IUserCampaign) => {
                return <div title={item.Username}>{item.Username}</div>
            },
        },
        {
            key: 'column1',
            name: ' Name',
            fieldName: 'FirstName',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'string',
            isPadded: false,
            onRender: (item: IUserCampaign) => {
                return userFullName(item);
            },
        },
        {
            key: 'column2',
            name: ' Phone(Optional)',
            fieldName: 'PhoneNumber',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'string',
            isPadded: false,
            onRender: (item: IUserCampaign) => {
                return <div title={item.PhoneNumber}>{item.PhoneNumber}</div>
            },
        },
        {
            key: 'column3',
            name: 'Date Completed',
            fieldName: 'DateCompleted',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'date',
            isPadded: false,
            onRender: (item: any) => {
                return <div title={item.DateCompleted !== null ? formatFromUTC(item.DateCompleted) : ""}>
                    {item.DateCompleted !== null ? formatFromUTC(item.DateCompleted) : ""}</div>
            },
        },
        {
            key: 'column4',
            name: 'Date Sent',
            fieldName: 'DateCreated',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'date',
            isPadded: false,
            onRender: (item: any) => {
                return <div title={item.DateCreated !== null ? formatFromUTC(item.DateCreated) : ""}>
                    {item.DateCreated !== null ? formatFromUTC(item.DateCreated) : ""}</div>
            },
        },
        {
            key: 'column5',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 35,
            maxWidth: 35,
            isResizable: true,
            onRender: (item: any) => {
                return <div style={{width: '40px', display: 'block', textAlign: 'right'}}>
                    <IconButton
                        menuIconProps={{
                            iconName: 'MoreVertical',
                            style: {fontSize: 20, color: '#242424', textAlign: 'right'}
                        }}
                        role="button"
                        aria-haspopup
                        aria-label="Show actions"
                        styles={iconButtonStyles}
                        menuProps={menuProps}
                        title={"Click to select from available actions."}
                    />
                </div>
            },
            isPadded: true,
        },

    ]

    const onSelectionChange = () => {
        const selectionCount = selection.getSelectedCount();
        switch (selectionCount) {
            case 0:
                setRespondent(emptyRespondent)
                break;
            case 1:
                setRespondent(selection.getSelection()[0] as IUserCampaign)
                break;
            default:
                setRespondent(emptyRespondent)
                break;
        }
    }

    const selection = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        if (item?.key != undefined) {
            setSelectedKey(item?.key)
        }
    }

    async function loadCampaignsUsers(page: number, option: string, text: string, campaignId: any) {
        setIsLoadingUsers(true)
        var inc = option == '0' ? false : option == '1' ? true : false
        var com = option == '0' ? false : option == '2' ? true : false
        let pageNumber = (page !== NaN || page !== undefined || page !== null) && page > 0 ? page : 1
        sessionStorage.setItem("pageU", pageNumber.toString())

        const request = {
            CampaignMasterId: campaignId,
            PageNumber: pageNumber.toString(),
            RecordsPerPage: 12,
            CampaignUsername: text,
            Incomplete: inc,
            Complete: com
        }

        let {data}: any = await HttpRequest.getWithSession('GetCampaignDetailByPage', request)
        const response = data
        
        if (response !== undefined) {
            setIsLoadingUsers(false)
            setUserItems(response)
            let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneCampaingList")) as Element

            if (element !== null) {
                element.children[1].classList.add("scrollVisible")
            }
            if (response.length > 0) {
                var nPages = Math.ceil(response[0].Records / 12)
                setPageCount(nPages)
            } else
                setPageCount(0)
        }
    }

    async function loadDefaults() {
        var request = {
            ApiKey: "-1",
            AccountId: Helper.getDefaultAccount(),
            SessionKey: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            Id: "-1",
            Operation: "emailBodyDefault",
            TemplateId: props.templateId
        }

        campaigneForm.reset()
        setEmailOwner(Helper.getUsername())
        campaigneForm.setValue("txtCampaignEmailFrom", Helper.getUsername())


        var body = await Helper.getCampaignDefaults(request)
        request.Operation = "emailBodyReminderDefault"
        var reminder = await Helper.getCampaignDefaults(request)

        setRteValue(EditorValue.createFromString(body.Result, 'html'))
        setRteValueDefault(EditorValue.createFromString(body.Result, 'html'))
        setRteValue2(EditorValue.createFromString(reminder.Result, 'html'))
        setRteValue2Default(EditorValue.createFromString(reminder.Result, 'html'))

    }

    const onFormatDate = (date?: Date): string => {
        return date !== undefined ? formatFromUTC(date, 'LL') : undefined;
    };


    async function loadData() {

        var res = await WebFormCampaignService.getCampaign({
            AccountId: Helper.getDefaultAccount(),
            Id: props.campaignId,
            SessionKey: Helper.getSessionKey(),
            UserName: Helper.getUsername()
        })
        var campaign = res[0]

        campaigneForm.reset()
        setCampaignName(campaign.Name)
        setCampaignDescription(campaign.Description)
        setCampaignStartDate(campaign.CampaignStartDate == null ? undefined : new Date(campaign.CampaignStartDate))
        setCampaignEndDate(campaign.CampaignEndDate == null ? undefined : new Date(campaign.CampaignEndDate))
        setEmailOwner(campaign.EmailOwner)
        setEmailSubject(campaign.EmailSubject)
        setRteValue(EditorValue.createFromString(campaign.EmailBody, 'html'))
        setEmailReminderSubject(campaign.ReminderEmailSubject)
        setRteValue2(EditorValue.createFromString(campaign.ReminderEmailBody, 'html'))
        setAcceptTerms(campaign.AcceptOrganizationalTerms)
        setIsCampaignActive(campaign.Active)
        setCampaignURI(window.location.origin)

        var s = campaign.ReturnURL
        if (s != null) {
            s = s.replace("https://", "")
            setReturnURL(s)
        }

        campaigneForm.setValue("txtCampaignName", campaign.Name)
        campaigneForm.setValue("txtCampaignDescription", campaign.Description)
        campaigneForm.setValue("txtCampaignEmailFrom", campaign.EmailOwner)
        campaigneForm.setValue("txtCampaignStartDate", campaign.CampaignStartDate == null ? undefined : new Date(campaign.CampaignStartDate))
        campaigneForm.setValue("txtCampaignEndDate", campaign.CampaignEndDate == null ? undefined : new Date(campaign.CampaignEndDate))
        campaigneForm.setValue("txtReturnUrl", s)
        campaigneForm.setValue("txtCampaignEmailSubject", campaign.EmailSubject)
        campaigneForm.setValue("txtCampaignReminderEmailSubject", campaign.ReminderEmailSubject)
        campaigneForm.setValue("checkRequireCampaign", campaign.AcceptOrganizationalTerms)

    }

    useEffect(() => {
        if (props.showCampaign) {

            campaigneForm.reset()
            setIsNewCampaign(props.isNew)
            sessionStorage.setItem("pageU", "1")

            if (props.isNew) {
                loadDefaults()
            } else {
                loadData()
                loadCampaignsUsers(1, '0', "", props.campaignId)
            }
        }

    }, [props.showCampaign, props.isNew])
    const deleteRespondent = useConstCallback(async (args: any[]) => {
        var request = {
            ApiKey: "-1",
            AccountId: Helper.getDefaultAccount(),
            SessionKey: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            Id: args[0],
            Operation: "delete"
        }
        var res = await Helper.deleteRespondent(request)
        setShowDeleteRespondentModal(false)
        var auxPage = parseInt(sessionStorage.getItem("pageU")!)
        loadCampaignsUsers(auxPage, '0', "", args[1])
        setRespondent(emptyRespondent);
        _deselect()
    })
    const pageChange = (value: any) => {
        setIsLoadingUsers(true)
        let selected = value.selected + 1;
        setPageSelected(selected);
        sessionStorage.setItem("pageU", selected)
        loadCampaignsUsers(selected, filter, searchText, props.campaignId)
    }
    const onSearch = async (value: any) => {
        setSearchText(value)
        setPageSelected(1)
        loadCampaignsUsers(1, filter, value, props.campaignId)
    };
    const onSearchClear = async (value: any) => {
        setSearchText("")
        setPageSelected(1)
        loadCampaignsUsers(1, filter, "", props.campaignId)
    }

    const CleanFilds = () => {
        campaigneForm.reset()
        campaigneForm.clearErrors()
        setCampaignName("")
        setCampaignDescription("")
        setEmailOwner("")
        setEmailSubject("")
        setIsCampaignActive(false)
        setCampaignStartDate(undefined)
        setCampaignEndDate(undefined)
        setRteValue(EditorValue.createEmpty())
        setRteValue2(EditorValue.createEmpty())
        setEmailReminderSubject("")
        setAcceptTerms(false)
        setReturnURL("")
        setSelectedKey("1")
        setPageSelected(1);
        setMessage("")
        setShowMessage(false)
        sessionStorage.setItem("pageU", "1")
    };

    async function loadDefaultRteValue() {
        var request = {
            ApiKey: "-1",
            AccountId: Helper.getDefaultAccount(),
            SessionKey: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            Id: "-1",
            Operation: "emailBodyDefault",
            TemplateId: props.templateId
        }
        var body = await Helper.getCampaignDefaults(request)
        request.Operation = "emailBodyDefault"
        setRteValue(EditorValue.createFromString(body.Result, 'html'))
    }

    async function loadDefaultRteValue2() {
        var request = {
            ApiKey: "-1",
            AccountId: Helper.getDefaultAccount(),
            SessionKey: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            Id: "-1",
            Operation: "emailBodyReminderDefault",
            TemplateId: props.templateId
        }

        request.Operation = "emailBodyReminderDefault"
        var reminder = await Helper.getCampaignDefaults(request)
        setRteValue2(EditorValue.createFromString(reminder.Result, 'html'))
    }

    async function sendReminder() {

        const request = {
            Id: props.campaignId,
            Uri: Helper.getDefaultlocation()
        }

        let {data}: any = await HttpRequest.getWithSession('SendReminders', request)
        const response = data

        if (response !== undefined) {
            if (response.Success) {
                setShowSuccess(true)
                setMessage("Reminders sent successfully!")

                setTimeout(function () {
                    setShowSuccess(false)
                }, 5000);
            } else {
                setShowMessage(true)
                setMessage("An error occurred: " + response.ExceptionMessage)

                setTimeout(function () {
                    setShowMessage(false)
                }, 5000);
            }
        }
        hideConfirm()
    }

    function onOptionChange(ev?: React.FormEvent<HTMLElement>, option?: IChoiceGroupOption): void {
        setFilter(option?.key!)
        setPageSelected(1)
        loadCampaignsUsers(1, option?.key!, searchText, props.campaignId)
    };

    const CloseModal = () => {

        CleanFilds()
        props.hideCampaign(props.templateId)
    };

    const resendAccessCode = async () => {
        var response = await WebFormCampaignService.campaignAccessCode({
            AccessCode: "000000",
            Guid: "",
            Id: respondent.Id,
            Path: await Helper.getSite(),
            ResetCode: true
        })

        if (response === "A new link has been sent to recipient email address.") {
            setShowSuccess(true)
            setMessage(response)
            setTimeout(function () {
                setShowSuccess(false)
            }, 5000);
        } else {
            setShowMessage(true)
            setMessage("Something went wrong.")
            setTimeout(function () {
                setShowMessage(false)
            }, 5000);
        }
        setShowResendAccessCodeModal(false)
        _deselect()
    }

    function onChangeActivateCampaign(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        setIsCampaignActive(isChecked!)
    }

    const getPanel = () => {

        switch (selectedKey) {
            case "1":
                return (
                    <>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {showMessage ?
                                        <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                    {showSuccess ?
                                        <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                </div>
                            </div>


                            <div className="ms-Grid-row">
                                <div className="scrollVisible"
                                     style={{overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "70vh"}}>
                                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                                        <div className='ActivateCampaign'>

                                            {isNewCampaign ? null :
                                                <><br/>
                                                    <Checkbox color="red" id="cbxActivateCampaign"
                                                              label="Please activate this campaign by (i) ticking the box and (ii) selecting ‘Update Campaign’ at the bottom"
                                                              checked={isCampaignActive}
                                                              onChange={onChangeActivateCampaign}
                                                              title="Activate campaign when available for respondents to access."/>
                                                    <br/></>
                                            }
                                        </div>

                                        <FormProvider {...campaigneForm}>
                                            <form>



                                                <RHFTextBoxControl
                                                    id={"txtCampaignName"}
                                                    autoFocus
                                                    label={"Campaign Name"}

                                                    rules={{
                                                        required: t('text.fieldRequired', {ns: 'common'}),
                                                        maxLength: 300

                                                    }}
                                                    maxLength={300}
                                                    title={"Enter name that best identifies this campaign."}
                                                    ariaLabel={"Campaign Name"}
                                                    control={campaigneForm.control}
                                                    setValue={campaigneForm.getValues}
                                                    getValues={campaigneForm.getValues}
                                                    trigger={campaigneForm.trigger}
                                                    value={campaignName}
                                                    onCallback={(_: any, v: any) => {
                                                        setCampaignName(v!)
                                                    }}
                                                    required
                                                >
                                                </RHFTextBoxControl>



                                                <RHFTextBoxControl
                                                    id={"txtCampaignDescription"}
                                                    label={"Campaign Description"}
                                                    rules={{
                                                        required: t('text.fieldRequired', {ns: 'common'}),
                                                        maxLength: 2048
                                                    }}
                                                    maxLength={2048}
                                                    title={"Enter a descritpion that best explains this campaign."}
                                                    ariaLabel={"Campaign Description"}
                                                    control={campaigneForm.control}
                                                    setValue={campaigneForm.getValues}
                                                    getValues={campaigneForm.getValues}
                                                    trigger={campaigneForm.trigger}
                                                    value={campaignDescription}
                                                    onCallback={(_: any, v: any) => {
                                                        setCampaignDescription(v!)
                                                    }}
                                                    required
                                                >
                                                </RHFTextBoxControl>


                                                <RHFTextBoxControl
                                                    id={"txtCampaignEmailFrom"}
                                                    disabled={true}
                                                    label={"Campaign Email Owner"}

                                                    rules={{
                                                        required: t('text.fieldRequired', {ns: 'common'}),
                                                        maxLength: 1000
                                                    }}
                                                    maxLength={1000}
                                                    title={"Email address that hosts this campaign."}
                                                    ariaLabel={"Campaign Email Owner"}
                                                    control={campaigneForm.control}
                                                    setValue={campaigneForm.getValues}
                                                    getValues={campaigneForm.getValues}
                                                    trigger={campaigneForm.trigger}
                                                    value={emailOwner}
                                                    onCallback={(_: any, v: any) => {
                                                        setEmailOwner(v!)
                                                    }}
                                                    required
                                                >
                                                </RHFTextBoxControl>

                                                <DatePicker
                                                    id="txtCampaignStartDate"
                                                    strings={Helper.getLocateCalendar()}
                                                    title={"Reference date when campaign formally begins."}
                                                    isMonthPickerVisible={true}
                                                    placeholder={"Select a date..."}
                                                    ariaLabel={"Select a date"}
                                                    label={"Campaign Start Date (Optional)"}
                                                    formatDate={onFormatDate}
                                                    value={campaignStartDate}
                                                    onSelectDate={setCampaignStartDate as (date: Date | null | undefined) => void}
                                                />

                                                <DatePicker
                                                    title={"Enter end date when respondents are no longer allowed to access campaign."}
                                                    minDate={campaignStartDate!}
                                                    isMonthPickerVisible={true}
                                                    id="txtCampaignEndDate"
                                                    placeholder="Select a date..."
                                                    ariaLabel="Select a date"
                                                    label="Campaign End Date (Optional)"
                                                    formatDate={onFormatDate}
                                                    value={campaignEndDate}
                                                    onSelectDate={setCampaignEndDate as (date: Date | null | undefined) => void}
                                                    strings={Helper.getLocateCalendar()}
                                                />

                                                <RHFTextBoxControl
                                                    id={"txtReturnUrl"}

                                                    prefix="https://"
                                                    title={`Return URL is the location where respondents are redirected upon campaign form submission. If Return URL is not provided, respondents are redirected to ${branding.appName} registration page upon campaign form submission.`}
                                                    label={"Return URL (Optional - see tooltip for more information)"}
                                                    ariaLabel={"Return URL (Optional - see tooltip for more information)"}
                                                    rules={{
                                                        maxLength: 2048

                                                    }}
                                                    maxLength={2048}
                                                    value={returnURL}
                                                    control={campaigneForm.control}
                                                    setValue={campaigneForm.getValues}
                                                    getValues={campaigneForm.getValues}
                                                    trigger={campaigneForm.trigger}
                                                    onCallback={(_: any, v: any) => {
                                                        setReturnURL(v!)
                                                    }}
                                                >
                                                </RHFTextBoxControl>

                                                <RHFTextBoxControl
                                                    id="txtCampaignEmailSubject"
                                                    required
                                                    maxLength={512}
                                                    rules={{
                                                        required: t('text.fieldRequired', {ns: 'common'}),
                                                        maxLength: 512
                                                    }}
                                                    label={"Initial Email Subject"}
                                                    value={emailSubject}
                                                    onCallback={(_: any, v: any) => {
                                                        setEmailSubject(v!)
                                                    }}
                                                    title="Enter subject when initial emails are sent."
                                                    ariaLabel={"Initial Email Subject"}
                                                    control={campaigneForm.control}
                                                    setValue={campaigneForm.getValues}
                                                    getValues={campaigneForm.getValues}
                                                    trigger={campaigneForm.trigger}
                                                >
                                                </RHFTextBoxControl>

                                                <Label></Label>
                                                <br/>
                                                <div style={{height: "200px", paddingBottom: "10px"}}>
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                                                         style={{paddingLeft: 0}}>
                                                        <label
                                                            title={"Do not remove any of the tags in the email templates (e.g. [$link], campaignAccessCode ]). These tags enable the Campaign process. If you want to change your email or accidentally delete a tag select Default Email’ to reset the template."}
                                                            style={{
                                                                fontSize: 14,
                                                                fontFamily: "Segoe UI Web (West European)",
                                                                color: "#323130",
                                                                fontWeight: 600
                                                            }}>Initial Email Body (Important - Do not remove
                                                            [$tags])</label>
                                                        <span style={{color: "#973434"}}>*</span>
                                                        <div style={{float: 'right'}}><Link
                                                            title="Reset to default email template." allowDisabledFocus
                                                            onClick={() => {
                                                                loadDefaultRteValue()
                                                            }}>Default Email</Link></div>
                                                    </div>

                                                    <RichTextEditor
                                                        toolbarConfig={{
                                                            display: ["INLINE_STYLE_BUTTONS", 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', "HISTORY_BUTTONS"],
                                                            INLINE_STYLE_BUTTONS: [
                                                                {
                                                                    label: 'Bold',
                                                                    style: 'BOLD',
                                                                    className: 'custom-css-class'
                                                                },
                                                                {label: 'Italic', style: 'ITALIC'},
                                                                {label: 'Underline', style: 'UNDERLINE'},
                                                                {label: 'Strikethrough', style: 'STRIKETHROUGH'},
                                                                {label: 'blockquote', style: 'blockquote'},
                                                            ],
                                                            BLOCK_TYPE_BUTTONS: [
                                                                {label: 'UL', style: 'unordered-list-item'},
                                                                {label: 'OL', style: 'ordered-list-item'}
                                                            ],
                                                            BLOCK_TYPE_DROPDOWN: [
                                                                {label: 'Normal', style: 'unstyled'},
                                                                {label: 'Heading Large', style: 'header-one'},
                                                                {label: 'Heading Medium', style: 'header-two'},
                                                                {label: 'Heading Small', style: 'header-three'}
                                                            ],
                                                        }}
                                                        editorClassName={"richText"}
                                                        placeholder={"Enter a text"}
                                                        className={"richText"}
                                                        value={rteValue}
                                                        onChange={(e) => {
                                                            setRteValue(e)
                                                        }}
                                                    />
                                                </div>
                                                <br/>

                                                <RHFTextBoxControl
                                                    id="txtCampaignReminderEmailSubject"
                                                    required
                                                    maxLength={512}
                                                    label={"Reminder Email Subject"}
                                                    ariaLabel={"Reminder Email Subject"}
                                                    value={emailReminderSubject}
                                                    onCallback={(_: any, v: any) => {
                                                        setEmailReminderSubject(v!)
                                                    }}
                                                    title="Enter subject when reminder emails are sent."
                                                    rules={{
                                                        required: t('text.fieldRequired', {ns: 'common'}),
                                                        maxLength: 512

                                                    }}
                                                    control={campaigneForm.control}
                                                    setValue={campaigneForm.getValues}
                                                    getValues={campaigneForm.getValues}
                                                    trigger={campaigneForm.trigger}
                                                >
                                                </RHFTextBoxControl>


                                                <Label></Label>
                                                <br/>
                                                <div style={{height: "200px"}}>
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                                                         style={{paddingLeft: 0}}>
                                                        <label
                                                            title={"Do not remove any of the tags in the email templates (e.g. [$link], campaignAccessCode ]). These tags enable the Campaign process. If you want to change your email or accidentally delete a tag select Default Email’ to reset the template."}
                                                            style={{
                                                                fontSize: 14,
                                                                fontFamily: "Segoe UI Web (West European)",
                                                                color: "#323130",
                                                                fontWeight: 600
                                                            }}>Reminder Email Body (Important - Do not remove
                                                            [$tags])</label>
                                                        <span style={{color: "#973434"}}>*</span>
                                                        <div style={{float: 'right'}}><Link
                                                            title="Reset to default reminder email template."
                                                            allowDisabledFocus onClick={() => {
                                                            loadDefaultRteValue2()
                                                        }}>Default Email</Link></div>
                                                    </div>
                                                    <RichTextEditor
                                                        toolbarConfig={{
                                                            display: ["INLINE_STYLE_BUTTONS", 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', "HISTORY_BUTTONS"],
                                                            INLINE_STYLE_BUTTONS: [
                                                                {
                                                                    label: 'Bold',
                                                                    style: 'BOLD',
                                                                    className: 'custom-css-class'
                                                                },
                                                                {label: 'Italic', style: 'ITALIC'},
                                                                {label: 'Underline', style: 'UNDERLINE'},
                                                                {label: 'Strikethrough', style: 'STRIKETHROUGH'},
                                                                {label: 'blockquote', style: 'blockquote'},
                                                            ],
                                                            BLOCK_TYPE_BUTTONS: [
                                                                {label: 'UL', style: 'unordered-list-item'},
                                                                {label: 'OL', style: 'ordered-list-item'}
                                                            ],
                                                            BLOCK_TYPE_DROPDOWN: [
                                                                {label: 'Normal', style: 'unstyled'},
                                                                {label: 'Heading Large', style: 'header-one'},
                                                                {label: 'Heading Medium', style: 'header-two'},
                                                                {label: 'Heading Small', style: 'header-three'}
                                                            ],
                                                        }}
                                                        placeholder={"Enter a text"}
                                                        className={"richText"}
                                                        value={rteValue2}
                                                        onChange={(e) => {
                                                            setRteValue2(e)
                                                        }}
                                                    />
                                                </div>
                                                <br/>

                                                <RHFCheckBoxControl
                                                    id='checkRequireCampaign'
                                                    title="Select to accept Terms and Conditions."
                                                    label="(Required *) Please confirm you are authorised to send Campaign emails to recipients."
                                                    checked={acceptTerms}
                                                    onCallback={(_: any, v: any) => {
                                                        setAcceptTerms(v!)
                                                    }}
                                                    control={campaigneForm.control}
                                                    setValue={campaigneForm.setValue}
                                                    getValues={campaigneForm.getValues}
                                                    trigger={campaigneForm.trigger}
                                                />


                                                <br/>
                                            </form>
                                        </FormProvider>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            {isNewCampaign ?
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <DialogFooter>
                                            <DefaultButton primary onClick={createCampaign} id="createCampaignButton"
                                                           text="Create Campaign" title="" disabled={!acceptTerms}/>
                                            <DefaultButton onClick={CloseModal} text="Close" title="Close."/>
                                        </DialogFooter>
                                        <br/>
                                    </div>
                                </div> : null}
                            {!isNewCampaign ?
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <DialogFooter>
                                            <DefaultButton primary onClick={updateCampaign} id="updateCampaignButton"
                                                           text="Update Campaign" title="" disabled={!acceptTerms}/>
                                            <DefaultButton disabled={!isCampaignValid() || !isCampaignActive} onClick={onItemSendReminder} id="sendRemindersButton"
                                                           text="Send Reminders" title=""/>
                                            <DefaultButton disabled={!isCampaignValid() || !isCampaignActive} onClick={onItemRestart} id="restartCampaignButton"
                                                           text="Restart Campaign" title=""/>
                                            <DefaultButton onClick={CloseModal} id="closeButton" text="Close"
                                                           title="Close."/>
                                        </DialogFooter>
                                        <br/>
                                    </div>
                                </div> : null}
                        </div>
                    </>
                )
            case "2":
                return (
                    <>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {showMessage ?
                                        <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                    {showSuccess ?
                                        <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <ActionButton id="uploadUserListButton" title="" text="Upload User List"
                                                  iconProps={{iconName: 'Add'}} onClick={() => {
                                        setShowUploadUsers(true)
                                    }} disabled={!isCampaignActive}/>
                                    <ActionButton id="addUserButton" title="" text="Add User"
                                                  iconProps={{iconName: 'Add'}} onClick={() => {
                                        setShowAddEditUser(true)
                                    }} disabled={!isCampaignActive}/>
                                    <ActionButton id="deleteRespondentsButton" title="" text="Delete Respondents"
                                                  iconProps={{iconName: 'Delete'}} onClick={onItemDelete}/>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                                    <Stack tokens={stackTokensUsers}>
                                        <label className="ms-fontWeight-bold">Respondents</label>
                                        <SearchBox
                                            placeholder="Search"
                                            title="Enter a username to search on the list."
                                            underlined={true}
                                            onSearch={newValue => onSearch(newValue)}
                                            onClear={onSearchClear}
                                            maxLength={500}
                                        />
                                        <ChoiceGroup
                                            options={[
                                                {key: "0", text: "All"},
                                                {key: "1", text: "Incomplete"},
                                                {key: "2", text: "Complete"}
                                            ]}
                                            onChange={onOptionChange}
                                            className="inlineGroup"
                                            defaultSelectedKey={"0"}
                                        />
                                        {!isLoadingUsers ?
                                            <div className={classNames.contentCampaignList}>
                                                <ScrollablePane styles={scrollablePaneStyles}
                                                                id="ScrollPaneCampaingList"
                                                                key="ScrollPaneCampaingList">
                                                    <MarqueeSelection selection={selection}>
                                                        <div className='DetailsListGrid'>
                                                            <DetailsList
                                                                items={userItems || []}
                                                                columns={columns}
                                                                compact={false}
                                                                setKey="none"
                                                                layoutMode={DetailsListLayoutMode.justified}
                                                                isHeaderVisible={true}
                                                                selection={selection}
                                                                selectionMode={SelectionMode.single}
                                                                selectionPreservedOnEmptyClick={false}
                                                                enterModalSelectionOnTouch={true}
                                                                checkboxVisibility={CheckboxVisibility.hidden}
                                                            />
                                                        </div>
                                                    </MarqueeSelection>
                                                </ScrollablePane>

                                            </div>
                                            :
                                            <Stack tokens={stackTokens}>
                                                <label/>
                                                <label/>
                                                <label/>
                                                <Spinner size={SpinnerSize.large} label={"Loading users..."}/>
                                            </Stack>
                                        }
                                    </Stack>

                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {pageCount > 1 ?
                                        <div className="ms-Grid-row">
                                            <Stack horizontal>
                                                <ReactPaginate
                                                    previousLabel={!isMobile ? 'previous' : null}
                                                    nextLabel={!isMobile ? 'next' : null}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={pageChange}
                                                    containerClassName={contentStyles.pagination}
                                                    activeClassName={contentStyles.active}
                                                    pageLinkClassName={"indexTab"}
                                                    previousLinkClassName={"indexTab"}
                                                    nextLinkClassName={"indexTab"}
                                                />
                                            </Stack>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <ConfirmationModal
                            showConfirmationModal={showDeleteRespondentModal}
                            setShowConfirmationModal={setShowDeleteRespondentModal}
                            callbackConfirmationModal={deleteRespondent}
                            args={[respondent.Id, props.campaignId]}
                            title="Delete Respondent"
                            message={`Are you sure you want to delete the Respondent ( ${respondent.FirstName} ${respondent.LastName} )?`}
                            titleYesButton="Confirm that you want to delete the respondent."
                        />
                        <CampaignUser
                            respondent={respondent}
                            editRespondent={editRespondent}
                            showAddEditUser={showAddEditUser}
                            hideAddEditUser={hideAddEditUser}
                            setEditRespondent={setEditRespondent}
                            CampaignMasterId={props.campaignId}
                        />
                        <ConfirmationModal
                            showConfirmationModal={showResendAccessCodeModal}
                            setShowConfirmationModal={setShowResendAccessCodeModal}
                            callbackConfirmationModal={resendAccessCode}
                            title="Resend Access Code"
                            message="Are you sure you want to resend the access code?"
                            titleButtonYes="Confirm that you want to resend the access code."
                        />
                    </>
                )
            case "3":
                // #region Capmpaign Prefill
                return (
                    <>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Stack tokens={stackTokensUsers}> { }
                                        <label className="ms-fontWeight-bold">Prefill Campaign</label>
                                        {/*<Separator>*/}
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <DefaultButton style={{ float: 'right' }} primary onClick={downloadSpecificationExcel} id="btnGetSpecification"
                                                    text="Get Specification" title="" />
                                            </div>
                                        </div>
                                        {/*</Separator>*/}
                                        <br />
                                        <div style={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: "15px", paddingRight: "70px"}}>
                                            <Text>
                                                <p><b>This functionality allows you to use data from other systems (e.g. Salesforce, Workday, SAP,
                                                    ServiceNow …) to prefill the campaign forms that you send out to individuals so that they can
                                                    confirm that the data is accurate and/or so that they can update it and/or add new information.
                                                    Simply:</b></p>
                                                <b><u>Get Specification xls</u></b>
                                                <ol>
                                                    <li>Use the <b>‘Get Specification’</b> button to download the data points (in xls format) for the
                                                        form that you have set-up in Campaigns.</li>
                                                    <li>Complete the xls with the data that you want an individual recipient to confirm,
                                                        update and/or, add to (<b>NOTE</b> Column B (Description) is optional and you may not
                                                        have all the data for an individual recipient and if not, there will be data gaps in
                                                        this xls which is not an issue).</li>
                                                    <li>Use the <b>‘Upload’</b> button upload the completed xls.</li>
                                                </ol>

                                                <b><u>+ Upload User xls</u></b>
                                                <ol start={4}>
                                                    <li>Select <b>‘Respondents/Status’</b>’ from the left-hand menu.</li>
                                                    <li>Select <b>‘+ Upload User List’</b>’ and download the ‘Example List’ xls.</li>
                                                    <li>Populate the <b>‘Example List’</b> xls with the email addresses and first and last names
                                                        of recipients that were contained in the <b>‘Get Specification’</b> xls in step 2 above -
                                                        ‘Phone Number’ is optional (<b>NOTE</b> you may want to provide this xls to any
                                                        colleague that is filling in the ‘Get Specification’ xls so they can complete both).</li>
                                                    <li>Upload the xls via the ‘Choose File’ (<span style={{ color: 'red' }}><b>NOTE</b> when you upload this xls the Campaign will
                                                        be sent out so only complete this step when all other elements of the campaign are set up</span>).</li>
                                                </ol>
                                            </Text>
                                        </div>
                                        <br />
                                        <div className="ms-Grid-row" style={{ minHeight: '50px' }}>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                {showMessage ?
                                                    <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null }
                                                {showSuccess ?
                                                    <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                                                <label />
                                                {!busySpinning ? <label />:
                                                    <Spinner size={SpinnerSize.large}/>
                                                }
                                            </div>
                                        </div>
                                        <br />
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <DialogFooter>
                                                    <Text style={{ paddingRight: "40px" }} >Create /Update File</Text>
                                                    <input type="file" accept=".xlsx" ref={fileInputCampaignRefillRef} onChange={uploadCampaignRefillFile} style={{ display: 'none' }}/>
                                                    <DefaultButton primary onClick={handleCampaignRefillClick} id="btnUpload"
                                                        text="Upload" title="Upload" />
                                                    <DefaultButton primary disabled={!acceptTerms} onClick={downloadPrefilExcel} id="btnDownload"
                                                        text="Download" title="" />
                                                </DialogFooter>
                                            </div>
                                        </div>
                                        <br />
                                    </Stack>

                                </div>
                            </div>
                        </div>
                       
                    </>
                )
                // #endregion Capmpaign Prefill
            default:
                return (<></>)

        }
    }


    const AreValidCampaignDates = (): boolean => {
        let returnValue:boolean = true;
        if (campaignStartDate !== null && campaignStartDate !== undefined &&
            campaignEndDate !== null && campaignEndDate !== undefined) {

            campaignStartDate.setHours(0, 0, 0, 0);
            campaignStartDate.setHours(0, 0, 0, 0);
            campaignEndDate.setHours(0, 0, 0, 0);
            campaignEndDate.setHours(0, 0, 0, 0);

            if (campaignEndDate < campaignStartDate) {
                returnValue = false
            } 
        }

        return returnValue;
    }


    async function createCampaign() {

        setMessage("")
        setShowMessage(false)
        campaigneForm.clearErrors()
        await campaigneForm.handleSubmit(async () => {

                if (AreValidCampaignDates()) {

                    const request = {
                        TemplateId: props.templateId,
                        Operation: "create",
                        Active: isCampaignActive,
                        ReturnURL: returnURL === "" || returnURL === null || returnURL === undefined ? "" : "https://" + returnURL,
                        CampaignStartDate: campaignStartDate,
                        CampaignEndDate: campaignEndDate,
                        Name: campaignName,
                        Description: campaignDescription,
                        EmailOwner: emailOwner,
                        EmailBody: rteValue.toString('html'),
                        EmailSubject: emailSubject,
                        EmailReminderBody: rteValue2.toString('html'),
                        EmailReminderSubject: emailReminderSubject,
                        AcceptTerms: acceptTerms,
                        URI: window.location.origin
                    }

                    var res = await Helper.CreateCampaign(request)
                    if (res !== null) {
                        if (res.Success) {
                            CleanFilds()
                            props.hideCampaign(props.templateId)
                        } else {
                            setMessage(res.ExceptionMessage)
                            setShowMessage(true)
                        }
                    } else {
                        setMessage(t('message.blockError', {ns: 'common'}))
                        setShowMessage(true)
                    }
                } else {
                    setMessage("The 'Campaign End Date' value cannot be less than the 'Campaign Start Date'. Please, select a valid 'Campaign End Date' to proceed.")
                    setShowMessage(true)
                    campaigneForm.setError("txtCampaignEndDate", {type: 'custom', message: 'Error fecha menor'});
                }
            }, () => {
                setMessage(t('message.fieldsRequired', {ns: 'common'}))
                setShowMessage(true)
            }
        )();
    }

    const isCampaignValid = (): boolean => {
        if (isCampaignActive) {
            if(props.GRAPHAPIServiceEmail.ServiceEmail.length > 0 && props.GRAPHAPIServiceEmail.AccessToken === "" && props.SMTPServiceEmail.ServiceEmail === "") {
                setMessage(`To activate the campaign, you will need to consent to the use of the specified email by ${branding.appName}. *Please, check your email or resend a new Consent Email.*`)
                setShowMessage(true)
                return false;
            } else if(props.GRAPHAPIServiceEmail.ServiceEmail.length === 0 && props.GRAPHAPIServiceEmail.AccessToken === "" && props.SMTPServiceEmail.ServiceEmail === "") {
                setMessage("To activate the campaign, please configure the Service Email Account to send emails from your own Microsoft Office 365 Account or configure a custom SMTP")
                setShowMessage(true)
                return false;
            }
        }

        if(!AreValidCampaignDates()) {
            setMessage("The 'Campaign End Date' value cannot be less than the 'Campaign Start Date'. Please, select a valid 'Campaign End Date' to proceed.")
            setShowMessage(true)
            campaigneForm.setError("txtCampaignEndDate", {type: 'custom', message: 'Error fecha menor'});
            return false;
        }
        return true
    }


    async function updateCampaign() {
        setMessage("")
        setShowMessage(false)
        campaigneForm.clearErrors()
        await campaigneForm.handleSubmit(async () => {


                if (isCampaignValid()) {

                    const request = {
                        TemplateId: props.templateId,
                        Operation: "create",
                        Active: isCampaignActive,
                        ReturnURL: returnURL === "" || returnURL === null || returnURL === undefined ? "" : "https://" + returnURL,
                        CampaignStartDate: campaignStartDate,
                        CampaignEndDate: campaignEndDate,
                        Name: campaignName,
                        Description: campaignDescription,
                        EmailOwner: emailOwner,
                        EmailBody: rteValue.toString('html'),
                        EmailSubject: emailSubject,
                        EmailReminderBody: rteValue2.toString('html'),
                        EmailReminderSubject: emailReminderSubject,
                        AcceptTerms: acceptTerms,
                        Id: props.campaignId.toString(),
                        URI: window.location.origin
                    }
                    var res = await Helper.UpdateCampaign(request)

                    if (res !== null) {
                        if (res.Success) {
                            props.hideCampaign(props.templateId)
                        } else {
                            setShowMessage(true)
                            setMessage("An error occurred: " + res.ExceptionMessage)

                            setTimeout(function () {
                                setShowMessage(false)
                            }, 7000);
                        }
                    } else {
                        setMessage(t('message.blockError', {ns: 'common'}))
                        setShowMessage(true)
                    }
                }
            }, () => {
                setMessage(t('message.fieldsRequired', {ns: 'common'}))
                setShowMessage(true)
            }
        )();
    }

    const hideUserList = (toggle: boolean) => {
        setShowUploadUsers(toggle)
        var auxPage = 1
        sessionStorage.setItem("pageU", auxPage.toString())
        loadCampaignsUsers(auxPage, filter, searchText, props.campaignId)
    }

    const hideAddEditUser = (toggle: boolean, modified: boolean) => {
        setShowAddEditUser(toggle)
        setEditRespondent(false)
        setIsLoadingUsers(true)
        setUserItems([])
        var auxPage = parseInt(sessionStorage.getItem("pageU")!)
        loadCampaignsUsers(auxPage, filter, searchText, props.campaignId)
        _deselect()
    }
    const hideConfirm = () => {
        setShowConfirm(false)
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setConfirmMessage("")
        setActionText("")
        setLayoutLoaded(false)
    }
    const confirmReminder = () => {
        sendReminder()
    }
    const onItemSendReminder = () => {
        setShowConfirm(true)
        setConfirmMessage("Are you sure you want to send a reminder to pending respondents?")
        setActionText("Send")
        setLayoutLoaded(true)
        setSpinnerText("Sending reminder...")
        setModalTitle("Confirm Reminders")
        setOperation(0)
    }
    const confirmRestart = () => {
        restartCampaign()
    }
    const onItemRestart = () => {
        setShowConfirm(true)
        setConfirmMessage("Are you sure you want to restart the campaign (All previous access codes will no longer work)?")
        setActionText("Restart")
        setLayoutLoaded(true)
        setSpinnerText("Restarting campaign...")
        setModalTitle("Confirm Restart")
        setOperation(1)
    }

    async function restartCampaign() {
        var site = await Helper.getSite()


        const request = {
            TemplateId: "",
            Operation: "restart",
            Active: true,
            CampaignStartDate: campaignStartDate,
            CampaignEndDate: campaignEndDate,
            Name: "",
            Description: "",
            EmailOwner: "",
            EmailBody: "",
            EmailSubject: "",
            EmailReminderBody: "",
            EmailReminderSubject: "",
            AcceptTerms: acceptTerms,
            Uri: Helper.getDefaultlocation(),
            Id: props.campaignId.toString()
        }

        let {data}: any = await HttpRequest.postWithSession('RestartCampaign', request)
        const response = data
        
        if (response !== undefined) {
            if (response.Success) {
                setShowSuccess(true)
                setMessage("Campaign restarted successfully!")

                setTimeout(function () {
                    setShowSuccess(false)
                }, 5000);
            } else {
                setShowMessage(true)
                setMessage("An error occurred: " + response.ExceptionMessage)

                setTimeout(function () {
                    setShowMessage(false)
                }, 5000);
            }
        }
        hideConfirm()
    }

    const onItemDelete = () => {
        setShowConfirm(true)
        setConfirmMessage("WARNING! This action will delete all respondents from campaign. Are you sure you want to continue?")
        setActionText("Delete")
        setLayoutLoaded(true)
        setSpinnerText("Deleting respondents...")
        setModalTitle("Confirm Delete")
        setOperation(2)
    }
    const confirmDelete = () => {
        deleteRespondets()
    }

    async function deleteRespondets() {

        const request = {
            TemplateId: "",
            Operation: "deleteRespondents",
            Active: true,
            CampaignStartDate: campaignStartDate,
            CampaignEndDate: campaignEndDate,
            Name: "",
            Description: "",
            EmailOwner: "",
            EmailBody: "",
            EmailSubject: "",
            EmailReminderBody: "",
            EmailReminderSubject: "",
            AcceptTerms: acceptTerms,
            Uri: Helper.getDefaultlocation(),
            Id: props.campaignId.toString()
        }

        let {data}: any = await HttpRequest.postWithSession('DeleteRespondents', request)
        const response = data
        
        //
        // var request = {
        //     Autoprovision: true,
        //     ApiKey: "-1",
        //     Path: site,
        //     UserName: Helper.getUsername(),
        //     SK: Helper.getSessionKey(),
        //     AccountId: Helper.getDefaultAccount(),
        //     TemplateId: "",
        //     Operation: "deleteRespondents",
        //     Active: true,
        //     CampaignStartDate: campaignStartDate,
        //     CampaignEndDate: campaignEndDate,
        //     Name: "",
        //     Description: "",
        //     EmailOwner: "",
        //     EmailBody: "",
        //     EmailSubject: "",
        //     EmailReminderBody: "",
        //     EmailReminderSubject: "",
        //     AcceptTerms: acceptTerms,
        //     Uri: Helper.getDefaultlocation(),
        //     Id: props.campaignId.toString()
        // }
        //
        //
        // var url = "DeleteRespondents"
        // const data = await fetch(url, {
        //     method: "POST",
        //     headers: await Helper.getHeaders({'Content-Type': 'application/json'}),
        //     body: JSON.stringify(request)
        // })
        // const response = await data.json()

        if (response !== undefined) {
            if (response.Success) {
                setShowSuccess(true)
                setMessage("Respondents deleted successfully!")
                loadCampaignsUsers(1, filter, searchText, props.campaignId)

                setTimeout(function () {
                    setShowSuccess(false)
                }, 5000);
            } else {
                setShowMessage(true)
                setMessage("An error occurred: " + response.ExceptionMessage)

                setTimeout(function () {
                    setShowMessage(false)
                }, 5000);
            }
        }
        hideConfirm()
    }

    // #region Capmpaign Prefill

    const fileInputCampaignRefillRef = useRef<HTMLInputElement>(null);
    const handleCampaignRefillClick = () => {
        if (fileInputCampaignRefillRef.current !== null) {
            fileInputCampaignRefillRef.current.click();
        }
    };

    const uploadCampaignRefillFile = useConstCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.files != null && ev.target.files.length > 0) {
            var sizevalid = 1024
            var mimeType = ev.target.files![0].type;
            var fileSize = ev.target.files![0].size
            var Sizekb = fileSize / 1024
            let IsValidSize = Sizekb <= sizevalid
            if ((mimeType.indexOf("vnd.ms-excel") >= 0 || mimeType.indexOf("vnd.openxmlformats-officedocument.spreadsheetml.sheet") >= 0) && IsValidSize) {
                uploadCampaignPrefill(ev.target.files![0]);
            }
            else {
                if (!(mimeType.indexOf("vnd.ms-excel") >= 0 || mimeType.indexOf("vnd.openxmlformats-officedocument.spreadsheetml.sheet") >= 0)) {
                    setShowMessage(true);
                    setMessage("Sorry the file is invalid because it is not in XLSX format. Please save as XLSX and try again.");
                }
                if (!IsValidSize) {
                    setShowMessage(true);
                    setMessage("Sorry we cannot process this file. Prefill Campaign data is limited to 1MB.");
                }
            }

        }
        else {
            setShowMessage(true);
            setMessage("File not found");
        }

        setTimeout(function () {
            setShowMessage(false);
            setShowSuccess(false);
            setMessage("");
        }, 5000);

    });

    async function uploadCampaignPrefill(file: File) { 
        if (props.campaignId > 0) {
            setbusySpinning(true) // to set loading gif

            const _fileBase64String = await fileToBase64(file);

            CampaignService.uploadAndProcessCampaignPrefill({ CampaignMasterId: props.campaignId.toString(), Base64File: _fileBase64String, TemplateId: props.templateId.toString() }).then(({ data }: any) => {
                try {
                    if (fileInputCampaignRefillRef.current) {
                        fileInputCampaignRefillRef.current.value = '';
                    }

                    if (data.Message !== undefined && data.Status === "Success") {
                        setShowSuccess(true);
                        setShowMessage(false);
                        setMessage("Campaign Prefill saved successfully!");
                    }
                    else if (data.ExceptionMessage !== undefined) {
                        setShowSuccess(false);
                        setShowMessage(true);
                        setMessage(data.ExceptionMessage);
                    }
                    else {
                        setShowSuccess(false);
                        setShowMessage(true);
                        setMessage("Internal Server error");
                    }
                } catch (e) {
                    setShowSuccess(false);
                    setMessage("Internal Server error");
                }
                setTimeout(function () {
                    setShowMessage(false);
                    setShowSuccess(false);
                    setMessage("");
                }, 5000);

            })
            setbusySpinning(false);
        }
    }

    const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();

            reader.onload = function () {
                resolve(reader.result!.toString().split(',')[1]);
            };

            reader.onerror = function (error) {
                console.log('Error reading file:', error);
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    }

    const downloadPrefilExcel = async () => {
        setbusySpinning(true);
        try {
            CampaignService.getCampaignPrefillFile({ CampaignMasterId: props.campaignId.toString(), TemplateId: props.templateId.toString() }).then(({ data }: any) => {
           
                if (data !== undefined && data.State !== undefined) {
                    try {

                        const decoded = decode(data.State);
                        const fileName = `CampaignPrefill.xlsx`;
                        const mimeType = "application/vnd.ms-excel";

                            const file = new Blob(
                                [decoded], { type: mimeType });
                            var a = document.createElement("a");
                            a.href = window.URL.createObjectURL(file);
                            a.download = fileName;
                            a.click();
                    } catch (error) {

                        if (error instanceof Error)
                        {
                            setShowMessage(true);
                            setMessage(`Error: ${error.message}`);
                            console.log(`Error: ${error.message}`);
                        } else {
                            console.log("An unknown error occurred.");
                        }
                        return;
                    }
                    if (data.ExceptionMessage === undefined || data.ExceptionMessage === "") {
                        setShowMessage(false);
                        setShowSuccess(true);
                        setMessage("Campaign Prefill downloaded successfully!");
                    }
                    else if (data.ExceptionMessage !== undefined) {
                        setShowSuccess(false);
                        setShowMessage(true);
                        setMessage(data.ExceptionMessage);
                    }
                    else {
                        setShowSuccess(false);
                        setShowMessage(true);
                        setMessage("Internal Server error");
                    }
                }
            
        })
        } catch (e)
        {
                setShowSuccess(false);
                setShowMessage(true);
                setMessage("Internal Server error");
        }
        setTimeout(function () {
            setShowMessage(false);
            setShowSuccess(false);
            setMessage("");
        }, 5000);
        setbusySpinning(false);
    }

    const downloadSpecificationExcel = async () => {
        setbusySpinning(true);

        CampaignService.getCampaignPrefillSpecificationFile({ CampaignMasterId: props.campaignId.toString(), TemplateId: props.templateId.toString() }).then(({ data }: any) => {

            try {
                if (data !== undefined) {
                    try {
                        const response: SpecificationFile = data;

                        const decoded = decode(response.FileBytes)
                        const mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

                        const file = new Blob(
                            [decoded], { type: mimeType });
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(file);
                        a.download = response.FileName;
                        a.click();

                    } catch (error) {
                        if (error instanceof Error) {
                            setShowMessage(true);
                            setMessage(`Error: ${error.message}`);
                            console.log(`Error: ${error.message}`);
                        } else {
                            console.log("An unknown error occurred.");
                        }
                    }
                }
                else {
                    setShowSuccess(false)
                    setMessage("Internal Server error")
                    setbusySpinning(false);
                    return;
                }

            } catch (e) {
                setShowSuccess(false)
                setMessage("Internal Server error")
            }

            if (data.ExceptionMessage === undefined || data.ExceptionMessage === "") {
                setShowMessage(false);
                setShowSuccess(true);
                setMessage("Campaign Specification file downloaded successfully!");
            }
            else if (data.ExceptionMessage !== undefined) {
                setShowSuccess(false);
                setShowMessage(true);
                setMessage(data.ExceptionMessage);
            }
            else {
                setShowSuccess(false);
                setShowMessage(true);
                setMessage("Internal Server error");
            }

        })
        setTimeout(function () {
            setShowMessage(false);
            setShowSuccess(false);
            setMessage("");
        }, 5000);
        setbusySpinning(false);
    }
    

    // #endregion Capmpaign Prefill
    return (
        <>
            <Modal
                isOpen={props.showCampaign}
                isBlocking={true}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{padding: "0px"}} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id="CampaignList">{props.isNew ? "New Campaign" : "Edit Campaign"}</span>
                                    <IconButton
                                        styles={iconModalButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={CloseModal}
                                        title="Close."
                                    />
                                    <HelpComponent
                                        headline="Campaign and Respondents"
                                        text={"Do not remove any of the tags in the email templates (e.g. [$link], campaignAccessCode ]). These tags enable the Campaign process. If you want to change your email or accidentally delete a tag select Default Email’ to reset the template. Select ‘Respondents’ to upload user lists, add users manually or delete users. When you edit campaign settings it can take up to 3 minutes to take affect. Most campaign properties are straight forward with the exception of the Initial and Reminder email. These are templates that are automatically sent when creating users, restarting campaigns or sending reminders."}
                                        right="50px"
                                        top="15px"
                                        location="campaigns"
                                    />
                                </div>
                                <Separator/>
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                <div className="ms-Grid-col ms-sm12 ms-lg12">
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                                <div className="ms-hiddenMdDown">
                                                    <Nav
                                                        groups={isNewCampaign ? campaignLinksNew : campaignLinks}
                                                        onLinkClick={_onLinkClick}
                                                        selectedKey={selectedKey}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                                {
                                                    getPanel()
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <CampaignUserLists
                showUploadUserList={showUploadUsers}
                hideUploadUserList={hideUserList}
                CampaignMasterId={props.campaignId}
            />
            <ConfirmationModal
                showConfirmationModal={showConfirm}
                setShowConfirmationModal={setShowConfirm}
                callbackConfirmationModal={operation == 0 ? confirmReminder : operation == 1 ? confirmRestart : confirmDelete}
                title={modalTitle}
                message={confirmMessage}
                titleYesButton={actionText}
            />
        </>
    )
}

export default Campaign