import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { HookFormProps } from "./HookFormProps";
import { ChoiceGroup, IChoiceGroupProps } from "@fluentui/react";

export const ControlledChoiceGroupField: FC<HookFormProps & IChoiceGroupProps> = (
  props
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => (
        <ChoiceGroup 
        {...props}
        onChange={(_e, option) => {
            onChange(option?.key);
          }}
        onBlur={onBlur}
        defaultValue={undefined}/>
      )}
    />
  );
};
