import {mergeStyleSets} from "@fluentui/react/lib/Styling";
import {IStackStyles} from "office-ui-fabric-react";

export const _styles = mergeStyleSets({
    body: {
        flex: '4 4 auto',
        padding: 0,
    },
    callout: {
        maxWidth: 300,
    },
})

export const footer: IStackStyles = {
    root: {
        display: 'flex',
        height: 44,
        overflow: 'hidden',
    },
}