import React from "react";
import {
    IAudienceIntersectRequest,
    IAudienceIntersectResponse,
    IEmailServiceRequest,
    IEmailServiceResponse,
    IGetDocumentOnlyAttachmentByDocumentIdRequest,
    IGetDocumentOnlyAttachmentByDocumentIdResponse,
    IgetDocumentOnlyTemplateIDBasicRequest,
    ISendLinkDocumentRequest,
    ISendLinkDocumentResponse,
} from ".";
import HttpRequest from "../../services/HttpRequest";
import {IAddAllowedUserRequest} from "./DocumentShareEmail.data";

export const DocumentShareService = {
    
    /**
     * POST AddAllowedUser
     * @param request
     * @constructor
     */
    AddAllowedUser: async (request: IAddAllowedUserRequest
    ): Promise<any> => {
        let {data}: any = await HttpRequest.postWithSession('AddAllowedUser', request)

        return new Promise<any>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },
  


}

