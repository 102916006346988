import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { Checkbox, ICheckboxProps, ICheckboxStyleProps, ICheckboxStyles, IStyleFunctionOrObject } from "@fluentui/react";
import { ReactHookFormControlProps } from "../CustomTemplates/CustomForm/Models/ReactHookFormControlProps";

export interface CheckboxReactHookFormControlProps {
    styles?: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> | undefined
}

export const RHFCheckBoxControl: FC<ReactHookFormControlProps & ICheckboxProps & CheckboxReactHookFormControlProps> = (props) => {
    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
            }) => (
                <Checkbox
                    id={props.id}
                    styles={props.styles}
                    name={fieldName}
                    label={props.label}
                    onRenderLabel={props.onRenderLabel}
                    title={props.title}
                    value={value}
                    onChange={(e, checked) => {
                        props.setValue(
                            props.id,
                            checked || !checked ? checked : undefined
                        );
                        props.trigger(props.id);
                        if (
                            props.onCallback !== null &&
                            props.onCallback !== undefined
                        ) {
                            props.onCallback(e, checked);
                        }
                    }}
                    onBlur={onBlur}
                    defaultChecked={undefined}
                    checked={props.getValues(props.id)}
                    tabIndex={props.tabIndex}
                    disabled={props.disabled ?? false}
                />
            )}
        />
    );
};
