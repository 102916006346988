import {ColumnActionsMode, IColumn,} from "@fluentui/react";
import Helper from "../../Helpers/Helper";
import React from "react";
import {Text} from "@fluentui/react/lib/Text";
import {IChoiceGroupOption} from "@fluentui/react/lib/ChoiceGroup";

export interface IEmailServiceResponse {
    Owner: string
    ServiceEmail: string
    TemplateId: number
}

export interface IEmailServiceRequest {
    Owner: string
    TemplateId: string | null
}

export interface IgetDocumentOnlyTemplateIDBasicRequest {
    TemplateId: string,
    Autoprovision: boolean
    ignoreDocument: boolean
}

export interface IAudienceIntersectRequest {
    DocumentId: string,
}

export interface IGetDocumentOnlyAttachmentByDocumentIdRequest {
    DocumentId: string,
}

export interface IAudienceIntersectResponse {
    FullName?: string
    UserName?: string
}

export interface IGetDocumentOnlyAttachmentByDocumentIdResponse {
    Id: number;
    AccountId: number;
    DocumentId: number;
    MimeType: string;
    Name: string;
    Description: string;
    CreatedBy: string;
    UpdatedBy: string;
    DateTime: Date;
    FileExt: string;
    AllowEsignFromManualUpload: boolean;
}

export interface IPeople {
    Text: string
    SecondaryText: string
    IsShared: boolean
}


export interface IAddAllowedUserRequest {
    TargetUserName: string
    ApplicationServiceEmailTemplateId: string
    FromEmail: string
    TemplateId: string
    OwnerUser: string
    DocumentId: string
    DocumentDescription: string
    Body: string
    Subject: string
    Autoprovision: boolean
}


export interface ISendLinkDocumentRequest {
    DocumentId: string
    TemplateId: string
    allowDocx: boolean
    TemplateVersion: string
    Attachments: string
    Subject: string
    DocumentDelivery: string
    Body: string
    Peoples: IPeople[]
    OwnerName: string
    OwnerUser: string
    DocumentDescription: string
    ApplicationServiceEmailTemplateId: string
    ServiceEmailTemplateId: number
    IsSubform: boolean
}

export interface ISendLinkDocumentResponse {
    Success: boolean,
    ExceptionMessage: string,
    Result: string
}

export interface IgetDocumentOnlyTemplateIDBasicRequest {
    TemplateId: string,
    Autoprovision: boolean
    ignoreDocument: boolean
}

export const GetAttachmentsColumns = (t: any): IColumn[] => {

    return [

        {
            key: 'column1',
            name: t('label.colAttachmentName', {ns: 'recordShareEmail'}),
            fieldName: 'Name',
            minWidth: 100,
            maxWidth: 200,
            columnActionsMode: ColumnActionsMode.disabled,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.colSortedAsc', {ns: 'recordShareEmail'}),
            sortDescendingAriaLabel: t('label.colSortedDesc', {ns: 'recordShareEmail'}),
            data: 'string',
            isPadded: true,
            onRender: (item: IGetDocumentOnlyAttachmentByDocumentIdResponse) => {
                return (
                    <Text variant={"medium"} title={`${item.Name.trim()}.${item.FileExt.trim()}`}>
                        {`${item.Name.trim()}.${item.FileExt.trim()}`}
                    </Text>
                )

            }
        },
        {
            key: 'column4',
            name: t('label.colDate', {ns: 'recordShareEmail'}),
            fieldName: 'DateTime',
            minWidth: 100,
            maxWidth: 200,
            columnActionsMode: ColumnActionsMode.disabled,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.colSortedAsc', {ns: 'recordShareEmail'}),
            sortDescendingAriaLabel: t('label.colSortedDesc', {ns: 'recordShareEmail'}),
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return (
                    <Text variant={"medium"}
                          title={item.DateTime !== null ? Helper.getLocateDate(item.DateTime, 2) : ""}>
                        {item.DateTime !== null ? Helper.getLocateDate(item.DateTime, 2) : ""}
                    </Text>
                )
            }
        },
        {
            key: 'column3',
            name: t('label.colDescription', {ns: 'recordShareEmail'}),
            fieldName: 'Description',
            minWidth: 150,
            maxWidth: 300,
            columnActionsMode: ColumnActionsMode.disabled,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.colSortedAsc', {ns: 'recordShareEmail'}),
            sortDescendingAriaLabel: t('label.colSortedDesc', {ns: 'recordShareEmail'}),
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return (
                    <Text variant={"medium"}
                          title={item.Description}>
                        {item.Description}
                    </Text>
                )
            }
        }
    ]
}

export const optionsAttachment: IChoiceGroupOption[] = [
    {key: 'PDF', text: 'PDF'},
    {key: 'DocX', text: 'DocX'},
    {key: 'DocXandPDF', text: 'Both'}
];


