import React from 'react'
import {
    IconButton, IIconProps, INavStyles
} from '@fluentui/react'
import { Panel, PanelType } from '@fluentui/react/lib/Panel'
import { Nav } from '@fluentui/react/lib/Nav'
import CustomModal, { Sizes } from './CustomModal'
type SidebarModalProps = {
    showModal: any
    titleId: any
    title: string
    settingLinks: any
    onLinkClick: any
    setIsOpenMenu: any
    isOpenMenu: any
    closeModal: any
    children: any
    helpButton: any
    selectedKey: any
}
const SidebarModal = (props: SidebarModalProps) => {
    const { showModal, titleId, title, settingLinks, selectedKey, onLinkClick, setIsOpenMenu, isOpenMenu, helpButton, closeModal, children } = props
    return (
        <>
            <CustomModal isOpen={showModal} title={title} titleId={titleId} size={Sizes.settings} additionalHeader={helpButton} close={closeModal}  >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                            <div className="ms-hiddenMdDown">
                                <Nav groups={settingLinks} onLinkClick={onLinkClick} styles={navStyles} selectedKey={selectedKey} />
                            </div>
                            <div className="ms-hiddenLgUp">
                                <IconButton iconProps={menuIcon} style={{
                                    marginLeft: "-4px",
                                    paddingLeft: "0px"
                                }} title="Menu" onClick={setIsOpenMenu} />
                                <Panel isBlocking={false} isOpen={isOpenMenu} onDismiss={setIsOpenMenu} closeButtonAriaLabel="Close" type={PanelType.smallFixedNear}>
                                    <Nav groups={settingLinks} onLinkClick={onLinkClick} styles={navStyles} selectedKey={selectedKey} />
                                </Panel>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg9">
                            {children}
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    )
}
const menuIcon: IIconProps = { iconName: 'GlobalNavButton' }
const navStyles: Partial<INavStyles> = {
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
    },
    chevronIcon: {
        display: 'none'
    },
    chevronButton: {
        display: 'none'
    },
};
export default SidebarModal