import { Text, ConstrainMode, DetailsList, DetailsListLayoutMode, IconButton, IContextualMenuProps, IDetailsListStyles, IGroup, SelectionMode, Separator, IDragDropEvents, IDetailsGroupDividerProps, IRenderFunction, IGroupDividerProps, DetailsRow, IColumn, IContextualMenuItem, PrimaryButton, Link, Stack, GroupHeader, ScrollablePane, ScrollbarVisibility } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { DragAndDropModel, OutboundIntegrationModel, UpdateOutboundIntegration } from "./Models/OutboundIntegrationModel";
import OutboundIntegration from "./OutboundIntegration";
import { useBoolean } from "@fluentui/react-hooks";
import Helper, { IBaseRequest } from "../../Helpers/Helper";
import OutboundServices from "./Services/OutboundServices";
import Confirmation from "../Settings/Confirmation";
import { isMobile } from "react-device-detect";
import IntegrationEvent from "./IntegrationEvent";
import { EventModel, EventType } from "./Models/EventModel";
import { move } from "../../Helpers/Utils";
import ConfirmationModal from "../../Helpers/Modals/ConfirmationModal";
import ConfigurationTemplateList from "./ConfigurationTemplateList";
import ConfigurationTemplate from "./ConfigurationTemplate";
import {useBranding} from "../../hooks/useBranding";
import UserService, {IGetuserprofileRequest} from "../../services/User";
//https://codesandbox.io/s/nelec?file=/src/StyleList.tsx

export interface OutboundIntegrationListProps {
    templateId: string;
    showSuccessMessage: (message: string) => void;
    showErrorMessage: (message: string) => void;
}

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

function OutboundIntegrationList(props: OutboundIntegrationListProps) {
    const { branding } = useBranding()
    const [events, setEvents] = useState<IGroup[]>([]);

    const [outboundIntegrations, setOutboundIntegrations] = useState<OutboundIntegrationModel[]>([]);
    const outboundIntegration = useRef<OutboundIntegrationModel>();
    const event = useRef<EventModel>();

    const [isOutboundIntegrationOpen, { setTrue: showOutboundIntegrationModal, setFalse: hideOutboundIntegrationModal }] = useBoolean(false);
    const [isEventOpen, { setTrue: showEventModal, setFalse: hideEventModal }] = useBoolean(false);

    const [confirmationModalTitle, setConfirmationModalTitle] = useState("");
    const [isDeleteModalOpen, { setTrue: showDeleteModal, setFalse: hideDeleteModal }] = useBoolean(false);
    const [layoutLoaded, setLayoutLoaded] = useState(false);
    const [actionText, setActionText] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [spinnerText, setSpinnerText] = useState("");
    const isIntegration = useRef(false);
    const [showDeactivatedIntegrationModal, setShowDeactivatedIntegrationModal] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const [isConfigurationTemplateListModaOpen, { setTrue: showConfigurationTemplateListModal, setFalse: hideConfigurationTemplateListModal }] = useBoolean(false);
    const [isConfigurationTemplateAdminMenu, { setTrue: setConfigurationTemplateAdminMenu, setFalse: setConfigurationTemplateClientMenu }] = useBoolean(false);
    const [isConfigurationTemplateModalOpen, { setTrue: showConfigurationTemplateModal, setFalse: hideConfigurationTemplateModal }] = useBoolean(false);
    const [profile, setProfile] = useState<any>()

    let dragAndDrop: DragAndDropModel = {
        SourceGroupIndex: -1,
        SourceItemIndex: -1,
        SourceGroup: undefined,
        SourceItem: undefined,
    };

    const getDragDropEvents = () => {
        return {
            canDrop: (dropContext: any, dragContext?: any) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDrop: (target?: any, event?: DragEvent) => {
                if (dragAndDrop) {
                    insertBeforeItem(target!);
                }
            },
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                if (item.data) {
                    dragAndDrop.SourceGroupIndex = itemIndex!;
                    dragAndDrop.SourceGroup = item!;
                } else {
                    dragAndDrop.SourceItemIndex = itemIndex!;
                    dragAndDrop.SourceItem = item!;
                }
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                dragAndDrop.SourceGroupIndex = -1;
                dragAndDrop.SourceItemIndex = -1;
                dragAndDrop.SourceGroup = undefined;
                dragAndDrop.SourceItem = undefined;
            },
            canDragGroups: false,
        };
    };

    const dragDropEvents: IDragDropEvents = getDragDropEvents();
    const insertBeforeItem = (target: any): void => {
        if (
            dragAndDrop.SourceGroup !== undefined ||
            dragAndDrop.SourceItem !== undefined) {
            if (target.Id &&
                dragAndDrop.SourceGroup.data.Integrations.find((currentItem: any) => currentItem.Id === target.Id) !== undefined
            ) {
                const orderedIntegrations = _.sortBy(
                    dragAndDrop.SourceGroup.data.Integrations,
                    [
                        (item: EventModel) => {
                            return item.Order;
                        },
                    ]
                );
                const newIndex = orderedIntegrations.indexOf(target);
                const oldIndex = orderedIntegrations.indexOf(dragAndDrop.SourceItem);
                const newEventIntegrations = move(orderedIntegrations, oldIndex, newIndex);
                const newOutboundIntegrations = move(outboundIntegrations, outboundIntegrations.indexOf(dragAndDrop.SourceItem), outboundIntegrations.indexOf(target));

                newEventIntegrations.forEach(
                    async (item: OutboundIntegrationModel, index: number, array: OutboundIntegrationModel[]) => {
                        let tempOutboundIntegration: UpdateOutboundIntegration =
                            {
                                AccountId: Helper.getDefaultAccount(),
                                ApiKey: "-1",
                                Autoprovision: true,
                                Integration: item,
                                Path: await Helper.getSite(),
                                SK: Helper.getSessionKey(),
                                UserName: Helper.getUsername(),
                            };
                        tempOutboundIntegration.Integration.Order = index + 1;
                        OutboundServices.UpdateIntegration(tempOutboundIntegration).then((res) => {
                            if (res.Success !== undefined &&
                                res.Success !== null &&
                                res.Success === true) {
                                props.showSuccessMessage("Integration has been updated successfully.");
                            } else {
                                let errorMessage =
                                    res.ExceptionMessage !== undefined &&
                                    res.ExceptionMessage !== "" ? res.ExceptionMessage : "Something unexpected happened.";
                                props.showErrorMessage("Error: " + errorMessage);
                            }
                        });
                        newOutboundIntegrations.forEach((fe) => {
                            if (fe.Id === item.Id) {
                                fe.Order = tempOutboundIntegration.Integration.Order;
                            }
                        });
                    }
                );
                setOutboundIntegrations([...newOutboundIntegrations]);
            }
        }
    };

    useEffect(() => {
        loadEvents();
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const loadEvents = async () => {

        let path = await Helper.getSite();
        
        const request: IGetuserprofileRequest = {
            UserName: Helper.getUsername(),
            Profile: Helper.getUsername()
        }
        UserService.GetUserProfile(request)
            .then((data) => {
                setProfile(data);
                OutboundServices.GetEvents({
                    AccountId: Helper.getDefaultAccount(),
                    ApiKey: "-1",
                    Autoprovision: true,
                    Path: path,
                    SK: Helper.getSessionKey(),
                    UserName: Helper.getUsername(),
                    TemplateId: +props.templateId,
                }).then((res) => {
                    if (res.length > 0) {
                        let tempEvents: IGroup[] = [];
                        let tempOutboundIntegrations: OutboundIntegrationModel[] = [];
                        let _startIndex: number = 0;
                        _.sortBy(res, [
                            (item: EventModel) => {
                                return item.Order;
                            },
                        ]).forEach((item: EventModel, index: number) => {
                            tempEvents.push({
                                key: item.Id.toString(),
                                name: item.Name,
                                startIndex: _startIndex,
                                count: item.Integrations.length,
                                level: 0,
                                data: item,
                                isDropEnabled: true,
                            });
                            tempOutboundIntegrations.push(
                                ..._.sortBy(item.Integrations, [
                                    (item: OutboundIntegrationModel) => {
                                        return item.Order;
                                    },
                                ])
                            );
                            _startIndex += item.Integrations.length;
                        });
                        setOutboundIntegrations(tempOutboundIntegrations);
                        setEvents(tempEvents);
                    } else {
                        setOutboundIntegrations([]);
                        setEvents([]);
                    }
                });
            })
            .catch()
            .finally()
      
    };

    const getIntegrationEventsMenu = (item: OutboundIntegrationModel): IContextualMenuProps => {
        return {
            shouldFocusOnMount: true,
            items: [
                {
                    key: "edit",
                    text: "Edit",
                    title: "Edit Outbound Integration.",
                    onClick: () => {
                        outboundIntegration.current = item;
                        event.current = events.find(eventGroup => eventGroup.data.Integrations.find((integration: OutboundIntegrationModel) => integration.Id === item.Id) !== undefined)!.data;
                        showOutboundIntegrationModal();
                    }
                },
                {
                    key: "delete",
                    text: "Delete",
                    title: "Delete Outbound Integration.",
                    onClick: () => {
                        onIntegrationDelete(item);
                    }
                },
                {
                    key: "activate",
                    text: item.Active ? "Deactivate" : "Activate",
                    title: item.Active ? "Deactivate Outbound Integration." : "Activate Outbound Integration.",
                    onClick: async () => {
                        outboundIntegration.current = item;
                        if (events.find((event) => event.data.Integrations.some((integration: any) => integration.Id === item.Id))!.data.Integrations.filter((f: any) => f.Active).length === 3 &&
                            item.Active === false) {
                            setShowDeactivatedIntegrationModal(true);
                        } else {
                            callbackDeactivatedIntegrationConfirmation();
                        }
                    }
                }
            ],
        };
    };

    const callbackDeactivatedIntegrationConfirmation = async () => {
        const tempEvent = events.find((find1: any) =>
            find1.data.Integrations.find(
                (find2: any) => find2.Id === outboundIntegration.current!.Id
            ) !== undefined
        );
        const activeIntegrations = tempEvent!.data.Integrations.filter((f: any) => f.Active).length;

        let tempOutboundIntegration = outboundIntegration.current!;
        if (activeIntegrations <= 3 && outboundIntegration.current!.Active) {
            tempOutboundIntegration.Active = false;
        } else if (activeIntegrations <= 2 && !outboundIntegration.current!.Active) {
            tempOutboundIntegration.Active = true;
        } else if (activeIntegrations === 3 && !outboundIntegration.current!.Active) {
            setShowDeactivatedIntegrationModal(false);
            outboundIntegration.current = undefined;
            return;
        }

        OutboundServices.UpdateIntegration({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Integration: tempOutboundIntegration,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
        }).then((res) => {
            if (res.Success !== undefined &&
                res.Success !== null &&
                res.Success === true) {
                props.showSuccessMessage("Integration has been updated successfully.");
                loadEvents();
            } else {
                let errorMessage = res.ExceptionMessage !== undefined && res.ExceptionMessage !== "" ? res.ExceptionMessage : "Something unexpected happened.";
                props.showErrorMessage("Error: " + errorMessage);
            }
            outboundIntegration.current = undefined;
        });
    };

    const customHideOutboundIntegrationModal = () => {
        event.current = undefined;
        outboundIntegration.current = undefined;
        hideOutboundIntegrationModal();

    };

    const outboundIntegrationColumns: IColumn[] = [
        {
            key: "event",
            name: "Event",
            fieldName: "EventType",
            minWidth: 80,
            maxWidth: 80,
            isResizable: false,
        },
        {
            key: "name",
            name: "Name",
            fieldName: "Name",
            minWidth: 150,
            isResizable: true,
        },
        {
            key: "description",
            name: "Description",
            fieldName: "Description",
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
            isCollapsable: true
        },
        {
            key: "method",
            name: "Method",
            minWidth: 60,
            maxWidth: 60,
            isResizable: false,
            isCollapsable: true,
            onRender: (item: OutboundIntegrationModel) => {
                return item.ObjectMapInput.MapInput.Params.Method;
            },
        },
        {
            key: "authorization",
            name: "Authorization",
            minWidth: 100,
            maxWidth: 100,
            isResizable: false,
            isCollapsable: true,
            onRender: (item: OutboundIntegrationModel) => {
                return item.AuthenticationType;
            },
        },
        {
            key: "order",
            name: "Order",
            fieldName: "Order",
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            isCollapsable: true,
        },
        {
            key: "actions",
            name: "Actions",
            minWidth: 55,
            maxWidth: 55,
            isResizable: false,
            onRender: (item: OutboundIntegrationModel) => {
                return (
                    <>
                        <IconButton
                            menuIconProps={{
                                iconName: "MoreVertical",
                                style: {
                                    fontSize: 20,
                                    color: "#242424",
                                    textAlign: "right",
                                },
                            }}
                            role="button"
                            aria-haspopup
                            aria-label="Show actions"

                            menuProps={getIntegrationEventsMenu(item)}
                            title={"Click to select from available actions."}
                            id="actionsCampaignListButton"
                        />
                    </>
                );
            },
        },
    ];

    const eventMenuCallback = (currentEvent: EventModel): IContextualMenuProps => {
        let tempMenu: IContextualMenuItem[] = [
            {
                key: "edit",
                text: "Edit",
                title: "Edit Event.",
                onClick: (ev?: | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
                    event.current = item!.data as EventModel;
                    showEventModal();
                },
                data: currentEvent
            },
            {
                key: "delete",
                text: "Delete",
                title: "Delete Event.",
                onClick: (ev?: | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
                    onEventDelete(item!.data);
                },
                data: currentEvent
            },
            {
                key: "addService",
                text: "Add Service",
                title: "Add Service.",
                onClick: (ev?: | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
                    event.current = item!.data as EventModel;
                    showOutboundIntegrationModal();
                },
                disabled: currentEvent.EventType.trim() === EventType.OnClick && currentEvent.Integrations.length === 1,
                data: currentEvent
            }
        ]
        if (profile.AdminBilling) {
            tempMenu = tempMenu.concat([{
                key: "saveConfigurationTemplate",
                text: "Save Configuration as Template",
                title: "Save Configuration as Template",
                onClick: (ev?: | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
                    event.current = item!.data as EventModel;
                    showConfigurationTemplateModal()
                },
                data: currentEvent
            }])
        }

        const eventMenu: IContextualMenuProps = {
            shouldFocusOnMount: true,
            items: tempMenu
        };
        return eventMenu;
    };

    const onToggleCollapse = (props: IGroupDividerProps): void => {
        props.onToggleCollapse!(props.group!);
    };

    const onRenderGroupHeader: IRenderFunction<IDetailsGroupDividerProps> = (props) => {

        return <GroupHeader  {...props} styles={{title: {paddingLeft: 0}}} onRenderTitle={() => (

            <div style={{display: "flex"}}>
                {props!.columns!.map(column => (

                    <>
                        <div style={{width: column.calculatedWidth}}>
                            {column.key === 'event' &&
                                <>
                                    {props!.group!.data.EventType}
                                </>
                            }

                            {column.key === 'name' &&
                                <>
                                    {props!.group!.data.Name}
                                </>
                            }

                            {column.key === 'description' &&
                                <>
                                    {props!.group!.data.Description}
                                </>
                            }

                            {column.key === 'description' &&
                                <>
                                    {props!.group!.data.Description}
                                </>
                            }

                            {column.key === 'actions' &&
                                <>

                                    <IconButton
                                        menuIconProps={{
                                            iconName: "MoreVertical",
                                            style: {
                                                fontSize: 20,
                                                color: "#242424",
                                                textAlign: "right",
                                            }
                                        }}
                                        role="button"
                                        aria-haspopup
                                        aria-label="Show actions"
                                        menuProps={eventMenuCallback(props!.group!.data)}
                                        title={"Click to select from available actions."}
                                        id="actionsEventListButton"
                                    />
                                </>
                            }



                        </div>
                    </>
                ))}
            </div>
        )}/>
    }

    const getModalDescription = (title: string, description: string) => {
        var value;
        isMobile ? (value = title) : (value = title + " (" + description + ")");
        return value;
    };

    const onIntegrationDelete = (item: OutboundIntegrationModel) => {
        isIntegration.current = true;
        setLayoutLoaded(false);
        setConfirmationModalTitle(
            getModalDescription("Delete Integration", item.Name!)
        );
        setDeleteId(item.Id!.toString());
        setDeleteMessage("Are you sure you want to delete this Integration?");
        setActionText("Delete");
        setLayoutLoaded(true);
        showDeleteModal();
    };

    const onEventDelete = (item: EventModel) => {
        setLayoutLoaded(false);
        setConfirmationModalTitle(getModalDescription("Delete Event", item.Name!));
        setDeleteId(item.Id!.toString());
        setDeleteMessage("Are you sure you want to delete this Event?");
        setActionText("Delete");
        setLayoutLoaded(true);
        showDeleteModal();
    };

    const hideConfirm = () => {
        hideDeleteModal();
        setLayoutLoaded(false);
        setSpinnerText("Loading content...");
        setConfirmationModalTitle("");
        setDeleteMessage("");
        setLayoutLoaded(false);
    };

    const confirmOutboundIntegrationDelete = (id: string) => {
        const deleteIntegration = async () => {
            let path = await Helper.getSite();
            OutboundServices.DeleteIntegration({
                AccountId: Helper.getDefaultAccount(),
                ApiKey: "-1",
                Autoprovision: true,
                Id: Number(id),
                Path: path,
                SK: Helper.getSessionKey(),
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
            }).then((res) => {
                if (res.Success !== undefined &&
                    res.Success !== null &&
                    res.Success === true) {
                    props.showSuccessMessage("Integration has been deleted.");
                } else {
                    let errorMessage =
                        res.ExceptionMessage !== undefined &&
                        res.ExceptionMessage !== ""
                            ? res.ExceptionMessage
                            : "Something unexpected happened.";
                    props.showErrorMessage("Error: " + errorMessage);
                }
                loadEvents();
                hideConfirm();
            });
        };
        deleteIntegration();
    };

    const confirmEventDelete = (id: string) => {
        const deleteEvent = async () => {
            let path = await Helper.getSite();
            OutboundServices.DeleteEvent({
                AccountId: Helper.getDefaultAccount(),
                ApiKey: "-1",
                Autoprovision: true,
                Id: Number(id),
                Path: path,
                SK: Helper.getSessionKey(),
                TemplateId: Number(props.templateId),
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
            }).then((res) => {
                if (res.Success !== undefined &&
                    res.Success !== null &&
                    res.Success === true) {
                    props.showSuccessMessage(
                        "Integration Event has been deleted."
                    );
                } else {
                    let errorMessage =
                        res.ExceptionMessage !== undefined &&
                        res.ExceptionMessage !== ""
                            ? res.ExceptionMessage
                            : "Something unexpected happened.";
                    props.showErrorMessage("Error: " + errorMessage);
                }
                loadEvents();
                hideConfirm();
            });
        };
        deleteEvent();
    };

    return (
        <>
            {isEventOpen ? (
                <IntegrationEvent
                    event={event!}
                    templateId={Number(props.templateId)}
                    loadEvents={loadEvents}
                    isEventOpen={isEventOpen}
                    hideEventModal={hideEventModal}
                    showSuccessMessage={props.showSuccessMessage}
                />) : null}
            {isOutboundIntegrationOpen ? (
                <OutboundIntegration
                    templateId={+props.templateId}
                    event={event}
                    outboundIntegration={outboundIntegration.current!}
                    outboundIntegrations={
                        event.current !== undefined
                            ? events.find((f) => f.data.Id === event.current!.Id)
                                ?.data.Integrations
                            : []
                    }
                    hideOutboundIntegrationModal={customHideOutboundIntegrationModal}
                    isOutboundIntegrationOpen={isOutboundIntegrationOpen}
                    loadEvents={loadEvents}
                    showSuccessMessage={props.showSuccessMessage}
                />
            ) : null}
            <Stack
                horizontal={true}
                wrap={true}
                disableShrink={true}
                horizontalAlign={"start"}
                verticalAlign={"start"}
                styles={{ root: { marginTop: 0 } }}
                tokens={{ childrenGap: "5 5" }}
            >
                <Stack.Item grow={2}>
                    <label style={{ float: "left" }} className="ms-fontSize-18">Outbound Integration</label>
                </Stack.Item>
                <Stack.Item grow={3}>
                    {profile !== undefined && profile.AdminBilling ?
                        <Link style={{ float: "right" }} underline id={'administrateConfigurationTemplate'} onClick={() => { setConfigurationTemplateAdminMenu(); showConfigurationTemplateListModal(); }}>Administrate Configuration Templates</Link>
                        : null}
                </Stack.Item>
            </Stack>
            <Stack
                horizontal={true}
                wrap={true}
                disableShrink={true}
                horizontalAlign={"start"}
                verticalAlign={"start"}
                styles={{ root: { marginTop: 0 } }}
                tokens={{ childrenGap: "5 5" }}
            >
                <Stack.Item grow={2}>
                </Stack.Item>
            </Stack>
            <br />
            <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <Text>
                    Kim Outbound Integration allows administrators to configure
                    direct REST API calls from {branding.appName} to GET, POST, PUT
                    and DELETE to a specified target URL. Security
                    authentication type must be one of OAuth20, Bearer, Basic,
                    or Custom for the specified target URL. Request Headers and
                    URL Parameters may be specified for the target. The POST,
                    PUT or DELETE may specify mapping and transformation for the
                    target URL. Use to connect to Salesforce, SAP Success
                    Factors, Kim Platform, MS DevOps and other platforms capable
                    of being targeted with {branding.appName} Outbound Integration
                    configurations.
                </Text>
            </div>
            <Separator>
                <PrimaryButton style={{ marginRight: "10px" }} text="Create Outbound" allowDisabledFocus onClick={showEventModal} />
                <PrimaryButton text="Choose Configuration Template" allowDisabledFocus onClick={() => { setConfigurationTemplateClientMenu(); showConfigurationTemplateListModal(); }} />
            </Separator>

            <Stack styles={{root: {width: "95%"}}}>

                <DetailsList
                    isHeaderVisible={true}
                    items={outboundIntegrations}
                    columns={outboundIntegrationColumns}

                    cellStyleProps={{
                        cellExtraRightPadding: 0,
                        cellLeftPadding: 0,
                        cellRightPadding: 0,
                    }}
                    selectionMode={SelectionMode.none}
                    groups={events}
                    compact={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    constrainMode={ConstrainMode.unconstrained}
                    dragDropEvents={dragDropEvents}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                    groupProps={{
                        onRenderHeader: onRenderGroupHeader,
                        showEmptyGroups: true,
                    }}
                />
            </Stack>

            <Confirmation
                showModal={isDeleteModalOpen}
                hideModal={hideConfirm}
                modalTitle={confirmationModalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                id={deleteId}
                message={deleteMessage}
                confirmDelete={
                    isIntegration.current
                        ? confirmOutboundIntegrationDelete
                        : confirmEventDelete
                }
                actionText={actionText}
            />
            <ConfirmationModal
                showConfirmationModal={showDeactivatedIntegrationModal}
                setShowConfirmationModal={setShowDeactivatedIntegrationModal}
                callbackConfirmationModal={callbackDeactivatedIntegrationConfirmation}
                title={"Active/Deactivated Integration"}
                textYesButton={"Ok"}
                message={"We only support 3 active integrations, to perform this action pleas deactivate another integration."}
                titleYesButton={"Ok"}
                HideNoButton={false}
            />

            {isConfigurationTemplateListModaOpen ?
                <ConfigurationTemplateList
                    clientEvents={events}
                    isConfigurationTemplateListModaOpen={isConfigurationTemplateListModaOpen}
                    isAdminMenu={isConfigurationTemplateAdminMenu}
                    hideConfigurationTemplateListModal={hideConfigurationTemplateListModal}
                    loadEvents={loadEvents}
                    templateId={Number(props.templateId)}
                /> : null}

            {isConfigurationTemplateModalOpen ?
                <ConfigurationTemplate
                    event={event}
                    hideConfigurationTemplateModal={hideConfigurationTemplateModal}
                    showSuccessMessage={props.showSuccessMessage}
                    showErrorMessage={props.showErrorMessage}
                />
                : null}
        </>
    );
}

export default OutboundIntegrationList;