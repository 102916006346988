import React, { useCallback, useEffect, useState } from 'react';
import {
    Checkbox, DefaultButton, DialogFooter, FontWeights, getTheme, IMessageBarStyles, mergeStyleSets, MessageBar,
    PrimaryButton, ProgressIndicator, Spinner, SpinnerSize, TextField
} from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import '../../Custom.css';
import { DefaultAcceptList, DefaultExtensions, getGroupExtensions } from '../../Helpers/Utils';
import AttachmentService from '../../services/attachments';
import { CustomModalProps, Sizes } from '../../share/CustomModal';
import { useAttachment } from './Context/useAttachment';
import {useBranding} from "../../hooks/useBranding";

const theme = getTheme();

type AttachmentFormProps = {
    close: any
    isAddAttachmentModalVisible: boolean
    setIsAddAttachmentModalVisible: any
    setIsLoading: any
    Modal: React.FC<CustomModalProps>
    item?: any
    createDraftAndAttachment?: Function | undefined
    setSpinnerText: Function
}
const AttachmentForm = ({ Modal, item, close, isAddAttachmentModalVisible, setIsAddAttachmentModalVisible,
    setIsLoading, setSpinnerText, createDraftAndAttachment }: AttachmentFormProps) => {
    const { branding } = useBranding()
    const { t } = useTranslation('attachmentsComponent')

    const [isDescriptionRequired, setIsDescriptionRequired] = useState(false)
    const [isFileRequired, setIsFileRequired] = useState(false)
    const [chkPrivate, setChkPrivate] = useState(false);
    const [isAdding, setIsAdding] = useState(false)
    const [isUploadingFile, setIsUploadingFile] = useState(false)
    const [fileExt, setFileExt] = useState("")
    const [mimeType, setMimeType] = useState("")
    const [validForm, setValidForm] = useState(false)
    const [fileName, setFileName] = useState("")

    const [description, setDescription] = useState("")
    const [base64File, setBase64File] = useState<string>("");
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [message, setMessage] = useState("");

    const [extensions, setExtensions] = useState<string[]>([])
    const [acceptList, setAcceptList] = useState<string[]>([])
    const [maxSize, setMaxSize] = useState(1)
    const { documentId, templateId, endUserName, username, docSource, documentDraftId, update } = useAttachment()

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: isMobile ? null : '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        callout: {
            width: 222,
            padding: '10px 10px',
        },
    });


    const hideAddAttachmentModal = () => {
        setIsAddAttachmentModalVisible(false)
        setChkPrivate(false)
        setValidForm(false)
        setMimeType("")
        setBase64File("")
        setFileName("")
        setDescription("")
        setIsFileRequired(false)
        setIsDescriptionRequired(false)
        setShowErrorMessage(false)
        setShowSuccessMessage(false)
    }
    const handleClose = () => {
        hideAddAttachmentModal()
        close()
    }
    const onUploadFile = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {

        setIsUploadingFile(true)
        setBase64File("")
        //Default extensions: ["csv", "bmp", "tiff", "jpg", "vnd.openxmlformats-officedocument.spreadsheetml.sheet", "png", "jpeg", "gif", "svg+xml", "msword", "vnd.openxmlformats-officedocument.presentationml.presentation", "vnd.ms-powerpoint", "vnd.ms-excel", "plain", "vnd.openxmlformats-officedocument.wordprocessingml.document", "pdf"]
        const fileData = ev.target.files
        if (fileData != null && fileData.length > 0) {
            const { type, name, size } = fileData![0]
            const sizevalid = 2048

            const typefile = type === 'application/x-zip-compressed' ? 'application/zip' : type
            setMimeType(typefile)
            const isAccepted = acceptList.includes(typefile)

            const Sizekb = size / 1024
            const IsValidSize = (Sizekb <= sizevalid && size > 0)
            const ext: string = name.split(".").pop() ?? ''
            const validExt = extensions.includes(ext.toLowerCase())
            const filevalid = isAccepted && validExt
            if (filevalid && IsValidSize) {
                setFileExt(ext)
                setMessage("")
                setShowErrorMessage(false)
                let tempFileName = name.split(".")
                setFileName(tempFileName.slice(0, tempFileName.length - 1).join("."));
                getBase64(fileData![0]);
                if (description.length > 0 && tempFileName.slice(0, tempFileName.length - 1).join(".").length > 0 && base64File.length > 0)
                    setValidForm(true);
            } else {
                setValidForm(false);
                setIsFileRequired(false)
                setShowErrorMessage(true)
                setFileName("")
                setBase64File("")
                setMessage("")
                if (!IsValidSize) {
                    if (Sizekb === 0)
                        setMessage(t('message.errorType', { ns: 'attachmentsComponent' }))
                    else
                        setMessage(t('message.errorSize', { ns: 'attachmentsComponent' }))
                }
                else if (validExt && !isAccepted)
                    setMessage(t('message.errorFormat', { ns: 'attachmentsComponent' }))
                else
                    setMessage(t('message.errorType', { ns: 'attachmentsComponent' }))

            }
        } else {
            setValidForm(false);
            setIsFileRequired(false)
            setFileName("")
            setBase64File("")
            setTimeout(function () {
                setShowErrorMessage(false)
                setMessage("")
            }, 5000);
        }
        setIsUploadingFile(false)
    }, [extensions])

    useEffect(() => {
        if (!item || item?.rule?.id === -1) {
            setExtensions(DefaultExtensions)
            setAcceptList(DefaultAcceptList)
        } else {
            const group = getGroupExtensions(item?.rule?.supportedTypes.split(','))
            setExtensions(group.ext)
            setAcceptList(group.mimetype)
        }
    }, [item])

    const getBase64 = (file: File) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64File(reader.result!.toString().split(',')[1]);
        };
        reader.onloadend = () => {
            setIsFileRequired(true)
        }
    }
    const isFormValid = () => {
        if (description.length > 0 && fileName.length > 0 && base64File.length > 0)
            setValidForm(true);
        else
            setValidForm(false);
    }

    function onAddAttachment() {
        setValidForm(false)
        setIsAdding(true)
        setShowErrorMessage(false)
        setShowSuccessMessage(false)
        setSpinnerText(t('text.spinnerSaving', { ns: 'common' }))
        if ((!documentId || Number(documentId) === -1) && documentDraftId === -1) {
            let callback = (Id: string, targetUser: string, callback?: Function) => {
                console.log('callback...')
                AttachmentService.uploadFile({
                    EndUserName: endUserName,
                    DocumentId: documentId, TemplateId: templateId, DocumentDraftId: Number(Id),
                    DocSource: docSource, Username: username
                }, {
                    AttachmentName: item?.rule?.id !== -1 ? item.rule.name : fileName,
                    AttachmentDescription: description,
                    MimeType: mimeType,
                    FileExt: fileExt,
                    Private: chkPrivate,
                    File: base64File,
                    RuleId: item?.rule?.id,
                    EndUserName: endUserName
                }).then(({ data }: any) => {
                    console.log('adding...')
                    if (data.Success) {
                        setShowSuccessMessage(true)
                        setMessage(t('message.successAdded', { ns: 'attachmentsComponent' }))
                        callback?.()
                        setTimeout(function () {
                            setIsLoading(true)
                            update(0)
                            hideAddAttachmentModal()
                            setIsAdding(false)
                            close()
                        }, 2000);
                    } else {

                        if (data.ExceptionMessage !== undefined && data.ExceptionMessage !== "") {
                            setMessage(data.ExceptionMessage)
                        } else {
                            setMessage(t('message.errorInternal', { ns: 'attachmentsComponent' }))
                        }
                        setShowErrorMessage(true)
                        setShowSuccessMessage(false)
                        setIsAdding(false)
                    }
                }).catch(err => {
                    setMessage(err)
                    setShowErrorMessage(true)
                    setShowSuccessMessage(false)
                    setIsAdding(false)
                })
            }
            createDraftAndAttachment?.(callback)
        } else {
            AttachmentService.uploadFile({
                DocumentId: documentId, TemplateId: templateId,
                DocumentDraftId: documentDraftId, DocSource: docSource, EndUserName: endUserName, Username: username
            }, {
                AttachmentName: item?.rule?.id !== -1 ? item.rule.name : fileName,
                AttachmentDescription: description,
                MimeType: mimeType,
                FileExt: fileExt,
                Private: chkPrivate,
                File: base64File,
                RuleId: item?.rule?.id,
                EndUserName: endUserName
            }).then(({ data }: any) => {
                if (data.Success) {
                    setShowSuccessMessage(true)
                    setMessage(t('message.successAdded', { ns: 'attachmentsComponent' }))
                    setTimeout(function () {
                        setIsLoading(true)
                        update(0)
                        hideAddAttachmentModal()
                        setIsAdding(false)
                        close()
                    }, 2000);
                } else {
                    setMessage(data.ExceptionMessage)
                    setShowErrorMessage(true)
                    setShowSuccessMessage(false)
                    setIsAdding(false)
                }
            }).catch((err) => {
                setMessage(err.message)
                setShowErrorMessage(true)
                setShowSuccessMessage(false)
                setIsAdding(false)
            })

        }

    }
    function onClickCheckPrivate(ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) {
        if (isChecked != undefined)
            setChkPrivate(isChecked)
    }
    const descriptionValidation = (value: string): string => {
        isFormValid()
        if (value.trim().length > 0) {
            return "";
        }
        else {
            return t('text.invalidDescription', { ns: 'attachmentsComponent' });
        }
    }
    const onDescriptionChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        setDescription(value || '');
        if (value!.length > 0)
            setIsDescriptionRequired(true)
    }

    return (
        <Modal afterClose={hideAddAttachmentModal}
            isOpen={isAddAttachmentModalVisible} title={t('title.modalAdd', { ns: 'attachmentsComponent' })} titleId={'attachmentModal'} size={Sizes.xs}>
            <div className={contentStyles.body}>
                <div className='mb-4'>
                    {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                    {showSuccessMessage ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                </div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 block">
                            <div>
                                <div className="ms-Grid" dir="ltr">
                                    {!isAdding ?
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 block">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                                        <label htmlFor="file"><div className="divHover divToolBoxItem" style={{color: branding.theme.palette.white, backgroundColor: branding.theme.palette.themePrimary}}>{t('Common.Input.File', { ns: 'common' })}</div></label>
                                                        <input accept={acceptList.join(',')} className="fileButton" id="file" type="file" name="file" onChange={onUploadFile} />
                                                        {!(base64File.length >= 0) && isUploadingFile ?
                                                            <ProgressIndicator description={t('text.spinnerUpdating', { ns: 'common' })} />
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" >
                                                        <TextField required value={fileName} readOnly disabled={true} title={t('title.inputFile', { ns: 'attachmentsComponent' })} placeholder={t('placeholder.inputFile', { ns: 'attachmentsComponent' })} />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <TextField value={description} label={t('label.description', { ns: 'attachmentsComponent' })} onGetErrorMessage={descriptionValidation} maxLength={50} onChange={onDescriptionChange} placeholder={t('placeholder.inputDescription', { ns: 'attachmentsComponent' })} id='attachmentDescription' required title={t('title.inputDescription', { ns: 'attachmentsComponent' })} />
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <br />
                                                    <Checkbox boxSide="end" checked={chkPrivate} id="checkDocument" onChange={onClickCheckPrivate} label={t('label.private', { ns: 'attachmentsComponent' })} title={t('title.privateLabel', { ns: 'attachmentsComponent' })} />
                                                    <label>{t('text.privateLabel', { ns: 'attachmentsComponent' })}</label>
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <DialogFooter>
                                                        <PrimaryButton disabled={!isDescriptionRequired || !isFileRequired} onClick={onAddAttachment} id="addAttachmentButton" text={t('Common.Button.Add', { ns: 'common' })} title={t('title.btnAdd', { ns: 'attachmentsComponent' })} />
                                                        <DefaultButton onClick={handleClose} text={t('Common.Button.Close', { ns: 'common' })} title={t('Common.Button.Close', { ns: 'common' })} />
                                                    </DialogFooter>
                                                </div>
                                            </div>
                                        </div> :
                                        <Spinner size={SpinnerSize.medium} label={t('text.spinnerAdding', { ns: 'common' })} ariaLive="assertive" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
export default AttachmentForm
