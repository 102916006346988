import React from "react";
import { useFormContext } from "react-hook-form";
import { Pivot, PivotItem } from "@fluentui/react";
import { RHFTextBoxAreaControl } from "../../../RHFControls/RHFTextBoxAreaControl";
import { RHFChoiceGroupControl } from "../../../RHFControls/RHFChoiceGroupControl";
import { MapOutputResponseType } from "../../Models/Enums";
import { MapOutputMapPropsList } from "./MapOutputMapPropsList";

export interface MapOutputProps {
    templateId: number;
    eventType: string;
    isConfigurationTemplateReadMode?: boolean;
}

export const MapOutput = (props: MapOutputProps) => {
    const { getValues, control, setValue, trigger } = useFormContext();

    return (
        <>
            <RHFChoiceGroupControl
                id="MapOutputResponseType"
                styles={{ root: { paddingLeft: "10px" }, flexContainer: { display: "flex" } }}
                options={Object.entries(MapOutputResponseType).map(item => {
                    return { text: item[0], key: item[1], styles: { field: { marginRight: "10px" } } }
                })}
                control={control}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
            />
            <Pivot styles={{ root: { width: "10%", float: "left", paddingTop: "15px" }, link: { display: "block" } }}>
                <PivotItem style={{ width: "90%", float: "right" }} headerText="Properties" headerButtonProps={{ 'data-order': 0 }} >
                    <MapOutputMapPropsList isConfigurationTemplateReadMode={props.isConfigurationTemplateReadMode} eventType={props.eventType} templateId={props.templateId} />
                </PivotItem>
                <PivotItem style={{ width: "90%", float: "right", paddingTop: "30px" }} headerText="Script">
                    <RHFTextBoxAreaControl
                        id={"MapOutputScript"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter script."}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={""}
                        rows={10}
                    />
                </PivotItem>
            </Pivot>

        </>
    );
};