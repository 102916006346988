import {IStackStyles, mergeStyleSets} from "@fluentui/react";
import {Theme} from "@fluentui/react-theme-provider";


export const _styles = () => {

    return mergeStyleSets({
        body: {
            flex: '4 4 auto',
            padding: 0,
        }
    })
}

export const nonShrinkingStackItemStyles: IStackStyles = {
    root: {
        padding: 12,
        overflow: 'hidden',
        alignItems: "center",
    }
}
