import { FC, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import * as React from "react";
import { EditorState, Modifier } from 'draft-js';
import RichTextEditor, { EditorValue, StyleConfig, ToolbarConfig } from 'react-rte';
import "../../../../Custom.css"
import { ReactHookFormControlProps } from "../Models/ReactHookFormControlProps";
import { Validation } from "../../CustomTemplate";
import { useTranslation } from "react-i18next";
import { IContentLibrary, IContentLibraryLanguage } from "../../../../interfaces/IDocumentAssembly";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";

export interface ITooltipUrl {
    tooltipUrl: string;
}

type IRichTextEditorProps = {
    setRteValue: React.Dispatch<React.SetStateAction<{ [key: string]: EditorValue }>> | undefined
    rteValue: { [key: string]: EditorValue } | undefined
    onRenderLabel: () => JSX.Element
    validations: Validation
    label: string
    placeholder: string
    tabIndex: number
    contentLibrary: IContentLibrary[]
    richValue: any
}

export const RHFRichTextControl: FC<ReactHookFormControlProps & IRichTextEditorProps> = (
    props
) => {
    const { getValues: _getValues, id, setValue } = props
    //const formValue: string = useMemo(() => _getValues(id), [richValue, id])
    const [richState, setRichState] = useState<EditorValue>(RichTextEditor.createEmptyValue())

    const { t } = useTranslation(['common', 'wizard', 'richtexteditor']);


    const contentLibraryOptions = useMemo(() => {
        const options = props.contentLibrary.find((item) => item.ContentControl?.trim() === id)
        return options?.LanguageOptions
    }, [id, props.contentLibrary])

    const handleBeforeInput = (text: string, state: EditorState) => {
        const totalLength = state.getCurrentContent().getPlainText().length + text.length;
        if (totalLength > props.validations.MaxLength) {
            return 'handled'
        }
        return 'not-handled';
    }

    const handlePastedText = (text: string, _: string, state: EditorState) => {
        const overflowChars = text.length + state.getCurrentContent().getPlainText().length - props.validations.MaxLength;

        if (overflowChars > 0) {
            if (text.length - overflowChars > 0) {
                const newContent = Modifier.insertText(
                    state.getCurrentContent(),
                    state.getSelection(),
                    text.substring(0, text.length - overflowChars)
                )
                setRichState(EditorValue.createFromState(EditorState.push(state, newContent, 'insert-characters')))
            }
            return 'handled';
        } else {
            return 'not-handled';
        }
    }
    const setInitialValue = useCallback((initialValue) => {
        setRichState(() => {
            const editor = EditorValue.createFromString(initialValue ?? props.defaultValue, "html")
            setValue(id, editor.toString("html"))
            return editor
        })
    }, [id, props.defaultValue, setValue])

    const getLength = (): number => {
        if (richState && richState?.getEditorState()) {
            const length = richState.getEditorState().getCurrentContent()?.getPlainText().length
            return length
        }
        return 0
    }
    const onChange = useCallback((newValue: EditorValue) => {
        setValue(id, newValue.toString("html"))
        setRichState(newValue)
    }, [id, setValue])
    
    
    const onBlur = () => {
        if(props.OnBlurOrOnChange !== undefined) props.OnBlurOrOnChange()
    }

    useEffect(() => {
        if (props.richValue !== undefined) {
            setInitialValue(props.richValue)
        }else{
            setInitialValue(_getValues(id))
        }
    }, [setInitialValue, props.richValue])


    interface IRichTextEditor extends ToolbarConfig {
        HISTORY_BUTTONS: {
            undo: StyleConfig,
            redo: StyleConfig
        }
        LINK_BUTTONS: {
            link: StyleConfig,
            removeLink: StyleConfig
        }
    }

    const propsRichText: IRichTextEditor = {
        display: ["INLINE_STYLE_BUTTONS", 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', "HISTORY_BUTTONS"],
        INLINE_STYLE_BUTTONS: [
            { label: t('label.bold', { ns: 'richtexteditor' }), style: 'BOLD', className: 'custom-css-class' },
            { label: t('label.italic', { ns: 'richtexteditor' }), style: 'ITALIC' },
            { label: t('label.underline', { ns: 'richtexteditor' }), style: 'UNDERLINE' },
            { label: t('label.strikethrough', { ns: 'richtexteditor' }), style: 'STRIKETHROUGH' },
            { label: t('label.blockquote', { ns: 'richtexteditor' }), style: 'blockquote' },
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: t('label.ul', { ns: 'richtexteditor' }), style: 'unordered-list-item' },
            { label: t('label.ol', { ns: 'richtexteditor' }), style: 'ordered-list-item' },
        ],
        BLOCK_TYPE_DROPDOWN: [
        ],
        HISTORY_BUTTONS: {
            undo: { label: t('label.undo', { ns: 'richtexteditor' }), style: 'unstyled' },
            redo: { label: t('label.redo', { ns: 'richtexteditor' }), style: 'unstyled' },
        },
        LINK_BUTTONS: {
            link: { label: t('label.link', { ns: 'richtexteditor' }), style: 'unstyled' },
            removeLink: { label: t('label.removeLink', { ns: 'richtexteditor' }), style: 'unstyled' },
        }
    }

    const RTEProps = {
        placeholder: props.placeholder,
        toolbarConfig: propsRichText,
        editorClassName: "richText",
        readOnly: props.readOnly || false,
        className: `richText ${props.readOnly ? 'disabled' : ''}`,
        //value: richState ?? RichTextEditor.createEmptyValue(),
        tabIndex: props.tabIndex,
        onBlur,
        onChange,
        handlePastedText,
        handleBeforeInput

    }
    const setContentLibraryValue = (content: string) => {
        setInitialValue(content)
    }

    return (
        <Controller
            control={props.control}
            name={props.id}
            shouldUnregister={props.shouldUnregister || false}
            render={() => (
                <div id={props.id} style={{ height: "200px", paddingBottom: "10px" }}>
                    {props.onRenderLabel()}
                    <RichTextEditor toolbarClassName="richtext_toolbar"
                        customControls={[<ContentLibraryOptionDropdown key="ccntentLibraryOptionDropdown" options={contentLibraryOptions ?? []} handleSelectValue={setContentLibraryValue} />]}
                        {...RTEProps} value={richState} />
                    <span style={{ fontSize: "14px", color: "#973434", fontWeight: 600 }} hidden={getLength() <= props.validations.MaxLength}>*{t('messages.errorImageMaxlenght', { ns: 'wizard', CurrentLenght: props.id, MaximumLenght: props.validations.MaxLength, interpolation: {escapeValue: false} })} </span>
                    {props.validations.Required && getLength() === 0 ?
                        <span style={{ fontSize: "14px", color: "#973434", fontWeight: 600 }}>*{t('messages.errorRequired', { ns: 'wizard', Field: props.label, interpolation: {escapeValue: false} })}</span>
                        : null
                    }
                </div>
            )}
        />
    );
};
type ContentLibraryOptionDropdownType = {
    options: IContentLibraryLanguage[]
    handleSelectValue: (content: string) => void
}
const ContentLibraryOptionDropdown = (({ options, handleSelectValue }: ContentLibraryOptionDropdownType) => {

    //const [selectedValue, setSelectedValue] = useState<string | number | undefined>('')
    const { t } = useTranslation(['richtexteditor']);

    const formatedOptions = useMemo(() => {
        return options?.map(option => {
            return {
                key: option.Id,
                text: option.LanguageDescription
            }
        })
    }, [options])

    if (!options || options.length === 0) {
        return null
    }
    const handleChange = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
        const selected = options.find(o => o.Id === option?.key)
        if (selected) {
            const html = new TextDecoder().decode(Uint8Array.from(atob(selected?.Language), c => c.charCodeAt(0)));
            handleSelectValue(html);
            //setSelectedValue('')
        }
    }

    return (
        <>
            {options.length > 0 ? <Dropdown placeholder={t('label.contentLibrary', { ns: 'richtexteditor' })}
                className="richtext_dropdown" options={formatedOptions} onChange={handleChange} styles={dropdownStyle} /> : null
            }
        </>
    )
})
const dropdownStyle: Partial<IDropdownStyles> = {
    root: { flex: '1' },
    dropdown: { width: 150, },
}
