import HttpRequest from "./HttpRequest"
import AuthService from "./auth"
import { IHttpClientResponseBag } from "../Helpers/Helper";


export interface IGetuserprofileRequest {
    UserName?: string
    Profile: string
}

export interface IGetuserprofileResponse {
    AdminBilling: boolean
    Billing: boolean
    Email: string
    FirstName: string
    MiddleName: string
    Language: string
    LastName: string
    Success: boolean
    TimeZone: string
    Title: string
}

export interface IProfile {
    UserName: string
    ProfileInformation: Record<string, string>[]
}

export interface IUpdateuserProfileRequest {
    Profile: IProfile[]
}
export interface IUpdateLinkUserProfileRequest extends IUpdateuserProfileRequest {
    LoggedUser: string
}
export interface IDeleteRegistrationRequest {
    onBehalfUser: string
}


const UserService = {
    /**
     * deleteregistration
     * @constructor
     * @param request
     */
    DeleteRegistration: async (request: IDeleteRegistrationRequest): Promise<IHttpClientResponseBag> => {
        const { data }: any = await HttpRequest.getWithSession("deleteregistration", request)
        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject({ err: 'Server error' })
            else resolve(data)
        })
    },

    DeleteUserbyAdminRequest: async ({ onBehalfUser }: { onBehalfUser: string }): Promise<any> => {
        const { data }: any = await HttpRequest.getWithSession("DeleteUserbyAdminRequest", { onBehalfUser })
        return new Promise((resolve) => {
            resolve(data)
        })
    },

    /**
     * Get user profile
     * @param request IGetuserprofileRequest
     * @constructor
     */
    GetUserProfile: async (request: IGetuserprofileRequest): Promise<IGetuserprofileResponse> => {

        const { data }: any = await HttpRequest.getWithSession("getuserprofile", request)
        return new Promise<IGetuserprofileResponse>((resolve, reject) => {
            if (!data.Success) reject({ err: 'Server error' })
            else resolve(data)
        })
    },

    /**
     * Update user profile
     * @param request
     * @constructor
     */
    UpdateUserProfile: async (request: IUpdateuserProfileRequest): Promise<IHttpClientResponseBag> => {

        const { data }: any = await HttpRequest.postWithSession("updateuserprofile", request)
        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject({ err: 'Server error' })
            else resolve(data)
        })
    },
    UpdateLinkUserProfile: async (request: IUpdateLinkUserProfileRequest): Promise<IHttpClientResponseBag> => {

        const { data }: any = await HttpRequest.postWithSession("updateuserprofile", { ...request, Username: request.LoggedUser })
        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject({ err: 'Server error' })
            else resolve(data)
        })
    },
    Logout: async (LoggedUser: string): Promise<IHttpClientResponseBag> => {

        const { data }: any = await HttpRequest.getWithSession("logoutSSO", { TargetUser: LoggedUser })
        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject({ err: 'Server error' })
            else resolve(data)
        })
    },
}

export default UserService
