import React, {useEffect, useState} from 'react';
import {IconButton} from '@fluentui/react/lib/Button';
import {Stack} from '@fluentui/react/lib/Stack';
import '../../Custom.css';
import {Separator} from '@fluentui/react/lib/Separator';
import {Modal} from "@fluentui/react/lib/Modal"
import {useTranslation} from 'react-i18next';
import {Spinner, SpinnerSize, StackItem} from "@fluentui/react";
import {Text} from "@fluentui/react/lib/Text";
import Vimeo from '@u-wave/react-vimeo';
import {cancelIcon, contentStyles, iconButtonStyles} from "./Style";
import {useBranding} from "../../hooks/useBranding";

type Properties = {
    showModal: boolean
    onDismissModal: () => void
    modalTitle: string
    videoId: string
}

export function MultimediaVideo({
                                    videoId,
                                    modalTitle,
                                    onDismissModal,
                                    showModal
                                }: Properties) {
    const { branding } = useBranding()
    const {t} = useTranslation(['common', 'workspace']);
    const [isLoading, setIsloading] = useState(false)

    useEffect(() => {
        if (showModal) {
            setIsloading(true)
        }
    }, [showModal])

    const onClose = () => {
        onDismissModal()
    }

    return (
        <>
            <Modal
                isOpen={showModal}
                isBlocking={true}
                containerClassName={contentStyles(branding.theme).container}
            >
                {/*-- START HEADER --*/}
                <Stack horizontal className={contentStyles(branding.theme).header}>

                    <StackItem grow={1}
                               styles={{root: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}}>
                        <Text variant={"xLarge"} id={"Video"}
                              title={modalTitle}>
                            {modalTitle}
                        </Text>
                    </StackItem>

                    <Stack horizontal styles={{root: {alignItems: "center"}}}>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                            onClick={() => {
                                onClose()
                            }}
                            title={t('Common.Button.Close', {ns: 'common'})}
                        />
                    </Stack>
                </Stack>
                <Separator/>

                {/*-- END HEADER --*/}

                {/*-- START BODY --*/}
                <Stack

                    style={{
                        overflow: "hidden",
                        width: "100%",
                        height: undefined,
                        aspectRatio: "16/9",
                        padding: 8,
                        paddingTop: 0
                    }}>

                    <Vimeo id="video" video={videoId} autoplay dnt={true} controls={true} responsive={true}
                           color={"3e7bbb"}
                           onReady={() => {

                               setIsloading(false)
                           }}
                    />

                    {isLoading &&
                        <Stack styles={{root: {height: "100%"}}}>
                            <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})}
                                     styles={{root: {marginTop: "auto", marginBottom: "auto"}}}
                                     ariaLive="assertive"/>
                        </Stack>
                    }
                </Stack>
            </Modal>
        </>
    );
}

