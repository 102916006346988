import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { ReactHookFormControlProps } from "../../Models/ReactHookFormControlProps";
import { DatePicker, IconButton, IDatePickerProps, IDatePickerStyleProps, IDatePickerStyles, IIconProps, IRenderFunction, IStyleFunctionOrObject, ITextFieldProps, TextField } from "@fluentui/react";
import { getDateWithFormat, getISODateString, parseISOToDate } from "../../../../../Helpers/DateUtils";
import {SingleSelectStyles, textFieldStyle} from "../Styles";
import { getDateStrings } from "../../../../../Helpers/DateStrings.locale";


const datePickerStyles: IStyleFunctionOrObject<IDatePickerStyleProps, IDatePickerStyles> = {
  root: {
    selectors: { 'label': { fontWeight: "normal" } }
  }
};
const cancelIcon: IIconProps = { iconName: 'Cancel' }
const calendarIcon = { iconName: 'Calendar' }
type DatePickerProps = {
  formatValue: string;
  locale?: string;
  isLabelVisible: boolean
} & ReactHookFormControlProps & IDatePickerProps
export const RHFDatePickerControl: FC<DatePickerProps> = (props) => {

  const { getValues: _getValues, defaultValue, id, setValue, trigger, formatValue, locale = 'en-US' } = props

  const formValue: string = useMemo(() => _getValues(id), [_getValues, id])
  const [customError, setCustomError] = useState<string | undefined>(undefined)
  const [dateValue, setDateValue] = useState<Date | undefined>(undefined)
  const cleanDatePickerValue = useCallback((): void => {
    setValue(id, "cleared")
    trigger(id)
    setDateValue(undefined)
    if(props.OnBlurOrOnChange !== undefined) props.OnBlurOrOnChange()
  }, [id, setValue, trigger])


  const getDateValue = useCallback(() => {
    if (formValue !== undefined && formValue !== "cleared" && formValue !== "")
      return formValue
    return formValue === "cleared" ? undefined : (defaultValue === '' ? undefined : defaultValue)
  }, [defaultValue, formValue])

  const handleChangeDate = (date: Date | null | undefined) => {
    let iso = date !== undefined ? getISODateString(date) : undefined
    setValue(id, iso)
    trigger(id)
    setDateValue(date === null ? undefined : date)
  }
  useEffect(() => {
    setDateValue(() => {
      const val = getDateValue()
      const _value =  val ? parseISOToDate(val) : undefined
      if(_value === undefined) {
        setCustomError(defaultValue)
      }
      return _value
    })
  }, [getDateValue, id])


  return (
    <Controller
      name={props.id}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      shouldUnregister={props.shouldUnregister || false}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => (
        <>

          {
            props.readOnly ?
              <RHFDisabledDate onRenderLabel={props.onRenderLabel} locale={locale}
                name={fieldName} dateValue={dateValue} formatValue={formatValue} id={props.id}
                label={props.label} title={props.title} placeholder={props.placeholder}
                tabIndex={props.tabIndex} /> :

              <div style={{ display: 'flex' }}>
                <DatePicker
                  id={props.id}
                  label={props.label}
                  textField={
                    {
                      name: fieldName,
                      value: value === undefined ? undefined : value,
                      onRenderLabel: props.onRenderLabel
                    }
                  }
                  title={props.title}
                  isRequired={props.isRequired}
                  disabled={props.readOnly ? props.readOnly : false}
                  onSelectDate={(date) => {
                    handleChangeDate(date)
                    onChange(date)
                    if(props.OnBlurOrOnChange !== undefined) props.OnBlurOrOnChange()
                  }}
                  value={dateValue}
                  onBlur={onBlur}
                  formatDate={(date) => { return getDateWithFormat(date, formatValue, locale) }}
                  placeholder={props.placeholder}
                  className={"w3-inputCC-datepicker"}
                  today={new Date()}
                  tabIndex={props.tabIndex}
                  styles={datePickerStyles}
                  defaultValue={undefined}
                  strings={getDateStrings(locale)}
                  isMonthPickerVisible={true}
                  borderless
                />
                {props.isRequired || props.readOnly ? null : <IconButton styles={{ root: { right: "15px", top: props.isLabelVisible ? "20px": "2px", color: "#666666" } }} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={cleanDatePickerValue} title="Clear Date" />}
              </div>
          }

          {(error && error.message) &&
            <span>
              <div role="alert"><p className="ms-TextField-errorMessage errorMessage-Wizard" ><span >{error.message}</span></p> </div> </span>
          }
          {/* TODO remove this part to see the convertion error */}
          {/*{customError &&*/}
          {/*    <div style={{fontWeight: "400", paddingTop: "2px"}} className={SingleSelectStyles.error}>*/}
          {/*      {customError}*/}
          {/*    </div>*/}
          {/*}*/}
        </>
      )}
    />
  )
}
type TextDatePickerProps = {
  name: string
  dateValue: Date | undefined
  formatValue: string
  id: string
  label?: string
  onRenderLabel?: IRenderFunction<ITextFieldProps>
  title?: string
  placeholder?: string
  locale: string
  tabIndex?: number
}
const RHFDisabledDate: FC<TextDatePickerProps> = (props) => {
  const selectedDateStr = props.dateValue ? getDateWithFormat(props.dateValue, props.formatValue, props.locale) : '';

  return (
    <div>
      <TextField
        id={props.id}
        name={props.name}
        label={props.label}
        onRenderLabel={props.onRenderLabel}
        title={props.title}
        disabled={true}
        value={selectedDateStr}
        placeholder={props.placeholder}
        resizable={false}
        borderless
        styles={textFieldStyle}
        tabIndex={props.tabIndex}
        iconProps={calendarIcon}
      />
    </div>
  );
}