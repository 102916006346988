import {
    IDatePickerStyleProps,
    IDatePickerStyles,
    IDropdownStyleProps,
    IDropdownStyles,
    ILabelStyleProps,
    ILabelStyles,
    IStyleFunctionOrObject,
    ITextFieldStyleProps,
    ITextFieldStyles,
    mergeStyleSets,
} from "@fluentui/react";
import { GroupBase, StylesConfig } from "react-select";

const getLabelStylesCC = (props: ILabelStyleProps): ILabelStyles => {
    return {
        root: {
            fontSize: 14,
            fontFamily: "Segoe UI Web (West European)",
            fontWeight: "normal",
        },
    };
};

export const textFieldStyle: ITextFieldStyles = {
    root: {},
    revealButton: "",
    revealSpan: "",
    revealIcon: "",
    prefix: "",
    suffix: "",
    description: "",
    icon: "",
    field: {
        borderBottom: "1px solid #ccc;",
        selectors: {
            "::placeholder ": {
                fontStyle: "italic",
            },
        },
    },
    errorMessage: "",
    fieldGroup: "",
    subComponentStyles: {
        label: getLabelStylesCC,
    },
    wrapper: "",
};

export const textAreaStyle: ITextFieldStyles = {
    root: {},
    revealButton: "",
    revealSpan: "",
    revealIcon: "",
    prefix: "",
    suffix: "",
    description: "",
    icon: "",
    field: {
        borderBottom: "1px solid #ccc;",
        backgroundColor: "rgba(0, 0, 0, .020);",
        selectors: {
            "::placeholder ": {
                fontStyle: "italic",
            },
        },
    },
    errorMessage: "",
    fieldGroup: "",
    subComponentStyles: {
        label: getLabelStylesCC,
    },
    wrapper: "",
};

export const dropdownStyles: IStyleFunctionOrObject<
    IDropdownStyleProps,
    IDropdownStyles
> = {
    title: {
        border: "none",
        borderBottom: "solid",
        borderBottomWidth: "thin",
        height: "30px",
        borderBottomColor: "#ccc",
    },
    dropdown: {},
};
export const datePickerControlStyle: IStyleFunctionOrObject<
    IDatePickerStyleProps,
    IDatePickerStyles
> = {
    wrapper: {
        height: "27px",
    },
    root: {},
    readOnlyTextField: {
        border: "none !important",
        borderColor: "#ffffff ",
        selectors: {
            ":focus ": {
                pointerEvents: "none",
                position: "absolute",
                inset: "-1px",
                border: "none !important",
                borderColor: "#fff !important",
            },
            ":after ": {
                pointerEvents: "none",
                position: "absolute",
                inset: "-1px",
                borderColor: "#fff !important",
                border: "none !important",
            },
            ":before ": {
                border: "none",
                borderColor: "#fff",
            },
        },
    },
};
export const textFielDP:
    | IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>
    | undefined = {
    root: {
        readOnlyTextField: {
            border: "none",
            selectors: {
                "::focus ": {
                    pointerEvents: "none",
                    position: "absolute",
                    inset: "-1px",
                    border: "none",
                },
                "::after ": {
                    pointerEvents: "none",
                    position: "absolute",
                    inset: "-1px",
                    border: "none",
                },
                "::before ": {
                    border: "none",
                },
            },
        },
    },
    fieldGroup: {
        border: "none",
        borderBottom: "1px solid #ccc;",
        height: "29px",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        selectors: {
            "::after ": {
                border: "none",
            },
            "::before ": {
                border: "none",
            },
            "::focus ": {
                pointerEvents: "none",
                position: "absolute",
                inset: "-1px",
                border: "none",
            },
        },
    },

    field: {
        color: "rgb(50 49 48)",
        border: "none",
        borderBottom: "1px solid #ccc;",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,

        selectors: {
            "::not:focus ": {
                border: "none",
                borderBottom: "1px solid #ccc;",
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            },
            "::focus ": {
                border: "none",
                borderBottom: "1px solid #ccc;",
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            },
            "::after ": {
                border: "none",
            },
            "::before ": {
                border: "none",
            },
        },
    },
    wrapper: {
        readOnlyTextField: {
            border: "none",
            selectors: {
                "::focus ": {
                    pointerEvents: "none",
                    position: "absolute",
                    inset: "-1px",
                    border: "none",
                },
                "::after ": {
                    pointerEvents: "none",
                    position: "absolute",
                    inset: "-1px",
                    border: "none",
                },
                "::before ": {
                    border: "none",
                },
            },
        },
    },
    icon: {},
};

export const customSelectDropdownStyles: StylesConfig<
    any,
    false,
    GroupBase<any>
> = {
    control: (styles: any, state: any) => ({
        ...styles,
        borderRadius: 1,
        borderColor: state.isFocused ? "rgb(0, 120, 212)" : "rgb(96, 94, 92)",
        minHeight: 32,
        borderBottom: "1px solid #ccc",
        borderWidth: "0px",
        minWidth: "100px"
        
    }),
    valueContainer: (styles: any) => ({
        ...styles,
        paddingTop: 0,
        fontWeight: "normal"
    }),
    menu: (styles: any) => ({
        ...styles,
        marginTop: 0,
    }),
    menuList: (styles: any) => ({
        ...styles,
        padding: 0,
        whiteSpace: "normal"
    }),
    clearIndicator: (styles: any) => ({
        ...styles,
        padding: "0px 5px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            color: isDisabled ? "#ccc" : isSelected ? "#000000" : "#000000",
            fontWeight: "normal",
        };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            fontStyle: "italic",
            whiteSpace:'nowrap',
            overflow:'hidden',
            textOverflow:'ellipsis',
        };
    },
};

export const customSelectDropdownMultiStyles: StylesConfig<
    any,
    true,
    GroupBase<any>
> = {
    control: (styles: any, state: any) => ({
        ...styles,
        borderRadius: 1,
        borderColor: state.isFocused ? "rgb(0, 120, 212)" : "rgb(96, 94, 92)",
        minHeight: 32,
        borderBottom: "1px solid #ccc",
        borderWidth: "0px",
    }),
    valueContainer: (styles: any) => ({
        ...styles,
        paddingTop: 0,
        //textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "inline-block",
    }),
    menu: (styles: any) => ({
        ...styles,
        marginTop: 0,
    }),
    menuList: (styles: any) => ({
        ...styles,
        padding: 0,
    }),
    clearIndicator: (styles: any) => ({
        ...styles,
        padding: "0px 5px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            color: isDisabled ? "#ccc" : isSelected ? "#000000" : "#000000",
            fontWeight: "normal",
        };
    },
    menuPortal: (base) => ({ ...base, zIndex: 1000001 }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            fontStyle: "italic",
            whiteSpace:'nowrap',
            overflow:'hidden',
            textOverflow:'ellipsis',
            position: "absolute"
        };
    },
};

export const SingleSelectStyles = mergeStyleSets({
    option: {
        display: "flex",
        flexDirection: "row",
        selectors: {
            ":hover": { backgroundColor: "rgb(243, 242, 241)!important" },
        },
        minHeight: "47px",
        alignItems: "center"
    },
    error: {
        color: "#a4262c",
        fontSize: "12px",
        paddingTop: "5px",
    },
    paragraph :{
        paddingLeft: "10px",
        margin:0, color:"black",
        fontWeight:"normal",
    }
});

export const MultiSelectStyles = mergeStyleSets({
    option: {
        display: "flex",
        flexDirection: "row",
        selectors: {
            ":hover": { backgroundColor: "rgb(243, 242, 241)!important" },
        },
        minHeight: "47px",
        alignItems: "center"
    },
    checkbox: {
        alignItems: "center",
        padding: "0px 5px",
    },
    error: {
        color: "#a4262c",
        fontSize: "12px",
        paddingTop: "5px",
    },
    label: {
        padding: "0px",
        fontWeight: "normal",
        selectors: {
            "::after": { float: "right" },
        },
    },
});

export const customLabelStyle: IStyleFunctionOrObject<
    ILabelStyleProps,
    ILabelStyles
> = {
    root: { padding: "0px", fontWeight: "normal" },
};

export const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};

export const groupBadgeStyles: React.CSSProperties = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
};