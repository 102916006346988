import HttpRequest from "./HttpRequest";

export interface IGetactivityRequest {
    PageNumber: number
    RecordsPerPage: number
    DateFrom?: string
    DateTo?: string
}

export interface IGetactivityResponse {
    AccountId: number
    Action: string
    Area: string
    Date: string
    DisplayName: string
    Exception: string
    Identity: string
    Message: string
    Section: string
    id: number
    resultNbr: number
}

const ActivityService = {

    /**
     * getactivity
     * @param request
     * @constructor
     */
    getactivity: async (request: IGetactivityRequest): Promise<IGetactivityResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('getactivity', request)

        return new Promise<IGetactivityResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject({err: 'Server error'})
            else resolve(data)
        })
    },


}

export default ActivityService