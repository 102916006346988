import HttpRequest from "./HttpRequest";
import Helper, {IRequest} from "../Helpers/Helper";

export interface IGetServiceEmailByTemplateIdAndOwnerRequest {
    Owner: string
    TemplateId: string
}

export interface IGetServiceEmailByTemplateIdAndOwnerResponse {
    AccessToken: string
    Approver: string
    AudienceId: number
    ClientId: string
    ClientSecret: string
    DateTimeApproved: string
    DateTimeCreated: string
    Id: number
    Owner: string
    RedirectURL: string
    RefreshToken: string
    ServiceEmail: string
    TemplateId: number
    TenantGUID?: string
}


export interface IUpdateServiceEmailRequest {
    Id: number,
    Context: string,
    Value: string
}


export interface IUpdateServiceEmailResponse {
    AccessToken: string
    Approver: string
    AudienceId: number
    ClientId: string
    ClientSecret: string
    DateTimeApproved: string
    DateTimeCreated: string
    Id: number
    Owner: string
    RedirectURL: string
    RefreshToken: string
    ServiceEmail: string
    TemplateId: number
    TenantGUID?: string
}

export interface IGetServiceEmailByOwnerRequest {
    TemplateId: string | null
    Owner?: string    
}

export interface IGetServiceEmailByOwnerResponse {
    Owner: string
    ServiceEmail: string
    TemplateId: number
}

export interface IGetServiceEmailResponse {
    AccessToken: string
    Approver: string
    AudienceId: number
    ClientId: string
    ClientSecret: string
    DateTimeApproved: string
    DateTimeCreated: string
    Id: number
    Owner: string
    RedirectURL: string
    RefreshToken: string
    ServiceEmail: string
    TemplateId: number
    TenantGUID?: string
}

export interface IGetServiceEmailRequest {
    TemplateId: string | null
}

const ApplicationServiceEmailService = {

    /**
     * GetServiceEmailByTemplateIdAndOwner
     * @param request
     * @constructor
     */
    GetServiceEmailByTemplateIdAndOwner: async (request: IGetServiceEmailByTemplateIdAndOwnerRequest): Promise<IGetServiceEmailByTemplateIdAndOwnerResponse[]> => {
        let {data}: any = await HttpRequest.postWithSession('GetServiceEmailByTemplateIdAndOwner', request)

        return new Promise<IGetServiceEmailByTemplateIdAndOwnerResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * UpdateServiceEmail
     * @param request
     * @constructor
     */
    UpdateServiceEmail: async (request: IUpdateServiceEmailRequest): Promise<IUpdateServiceEmailResponse[]> => {
        let {data}: any = await HttpRequest.postWithSession('UpdateServiceEmail', request)

        return new Promise<IUpdateServiceEmailResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * GetServiceEmailByOwner
     * @param request
     * @constructor
     */
    GetServiceEmailByOwner: async (request: IGetServiceEmailByOwnerRequest): Promise<IGetServiceEmailByOwnerResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('GetServiceEmailByOwner', request)

        return new Promise<IGetServiceEmailByOwnerResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    },

    /**
     * GetServiceEmail
     * @param request
     * @constructor
     */
    GetServiceEmail: async (request: IGetServiceEmailRequest): Promise<IGetServiceEmailResponse[]> => {
        let {data}: any = await HttpRequest.postWithSession('GetServiceEmail', request)

        return new Promise<IGetServiceEmailResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)

        })
    }
}

export default ApplicationServiceEmailService