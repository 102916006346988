import {IColumn, IDropdownOption} from "@fluentui/react";
import React from "react";
import i18n from "../../i18n/config";

export const months = (): IDropdownOption[] => {
    const {t} = i18n

    return [
        {key: -1, text: t("text.selectOption", {ns: "common"})},
        {key: 0, text: t("text.All", {ns: "common"})},
        {key: 1, text: t("text.January", {ns: "common"})},
        {key: 2, text: t("text.February", {ns: "common"})},
        {key: 3, text: t("text.March", {ns: "common"})},
        {key: 4, text: t("text.April", {ns: "common"})},
        {key: 5, text: t("text.May", {ns: "common"})},
        {key: 6, text: t("text.June", {ns: "common"})},
        {key: 7, text: t("text.July", {ns: "common"})},
        {key: 8, text: t("text.August", {ns: "common"})},
        {key: 9, text: t("text.September", {ns: "common"})},
        {key: 10, text: t("text.October", {ns: "common"})},
        {key: 11, text: t("text.November", {ns: "common"})},
        {key: 12, text: t("text.December", {ns: "common"})}
    ]
}

export const columns = (): IColumn[] => {
    const {t} = i18n
    return [
        {
            key: 'Year',
            name: t("label.year", {ns: "common"}),
            ariaLabel: t("label.year", {ns: "common"}),
            fieldName: 'Year',
            minWidth: 50,
            maxWidth: 50,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            isCollapsible: true
        },
        {
            key: 'Month',
            name: t("label.month", {ns: "common"}),
            ariaLabel: t("label.month", {ns: "common"}),
            fieldName: 'Month',
            minWidth: 50,
            maxWidth: 50,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <div
                    title={months().filter(f => f.key == item.Month)[0].text}>{months().filter(f => f.key == item.Month)[0].text}</div>
            },
        },
        {
            key: 'SubmissionCount',
            name: t("text.documents", {ns: "common"}),
            ariaLabel: t("text.documents", {ns: "common"}),
            fieldName: 'SubmissionCount',
            minWidth: 70,
            maxWidth: 70,
            isRowHeader: true,
            isResizable: false,
            isMultiline: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'UserCount',
            name: t("text.users", {ns: "common"}),
            ariaLabel: t("text.users", {ns: "common"}),
            fieldName: 'UserCount',
            minWidth: 45,
            maxWidth: 45,
            isRowHeader: true,
            isResizable: false,
            isMultiline: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'StorageUsed',
            name: t("text.storageUsedGB", {ns: "common"}),
            ariaLabel: t("text.storageUsedGB", {ns: "common"}),
            fieldName: 'StorageUsed',
            minWidth: 120,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: false,
            isMultiline: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'CampaignCount',
            name: t("text.campaigns", {ns: "common"}),
            ariaLabel: t("text.campaigns", {ns: "common"}),
            fieldName: 'CampaignCount',
            minWidth: 70,
            maxWidth: 70,
            isRowHeader: true,
            isResizable: false,
            isMultiline: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        }
    ]
}
