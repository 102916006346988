import { DialogFooter, IIconProps, IMessageBarStyles, Label, MessageBar, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Modal } from '@fluentui/react/lib/Modal';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { TextField } from '@fluentui/react/lib/TextField';
import { useBoolean, useConstCallback } from '@uifabric/react-hooks';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { isMobile } from "react-device-detect";
import {Color, CompactPicker, TwitterPicker} from 'react-color';
import Helper from '../../Helpers/Helper';
import { useTranslation } from 'react-i18next';
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";
import {RHFTextBoxAreaControl} from "../RHFControls/RHFTextBoxAreaControl";
import {RHFUploadControl} from "../RHFControls/RHFUploadControl";
import {FormProvider, useForm} from "react-hook-form";



const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };


function NewAudience(props: any) {
    const { branding } = useBranding()
    const [audienceName, setAudienceName] = useState("")
    const [audienceDescription, setAudienceDescription] = useState("")
    const [audienceCompanyName, setAudienceCompanyName] = useState("")
    const [audienceCompanyGeography, setAudienceCompanyGeography] = useState("")
    const [audienceCompanyDepartment, setAudienceCompanyDepartment] = useState("")
    const [audienceBackgroundColor, setAudienceBackgroundColor] = useState("#FFFFFF")
    const [audienceFontColor, setAudienceFontColor] = useState("#000000")
    const [isDisabled, setIsDisabled] = useState(true)
    const [applying, setApplying] = useState(false)


    //Messages
    const [errorMessage, setErrorMessage] = useState("")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [message, setMessage] = useState("")

    //Branding
    const emptyColor: Color = "#FFFF"
    const emptyFontColor: Color = "#0000"
    const [color, setColor] = useState(emptyColor)
    const [fontColor, setFontColor] = useState(emptyFontColor)
    const myForm = useForm();
    
    const [trimmedDataURL, setTrimmedDataURL] = useState("")
    const [loadingPreview, setloadingPreview] = useState(false)
    const {t} = useTranslation(['common','audiencecomponent']);
    
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '50vw',
            height: '50vh',
            flex: '4 4 auto',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        listDialog: {
            width: isMobile ? null : '30vw',
            height: '50vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        addItemList: {
            width: isMobile ? null : '35vw',
            height: '60vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        buttonArea: {
        },
        callout: {
            maxWidth: 300,
        },
        inner: {
            height: '100%',
            padding: '0 24px 20px',
        }
    });

    const checkRequiredfields = () => {
        var check = audienceName === "" || audienceDescription === ""
        setIsDisabled(check)
    }

    async function addNewAudience() {

        await myForm.handleSubmit(
            async (data) => {

            }
        )();
        
        const request = {
            AudienceMasterId: "-1",
            Name: audienceName,
            Description: audienceDescription,
            CompanyName: audienceCompanyName,
            CompanyGeography: audienceCompanyGeography,
            CompanyDepartment: audienceCompanyDepartment,
            BackgroundColor: audienceBackgroundColor,
            Logo: myForm.getValues("ImageDataAudience"),
            FontColor: audienceFontColor
        }

        let {data}: any = await HttpRequest.postWithSession('CreateAudienceMaster', request)
        const response = data

        if (response !== undefined) {
            if (response.Success) {
                //setApplying(true)
                //setAudienceList([])
                //loadAudience()
                props.closeNewAudience()
            }
            else {

                setShowErrorMessage(true)
                setErrorMessage("An error occurred: " + response.ExceptionMessage)
                setTimeout(function () {
                    setShowErrorMessage(false)
                }, 5000);
            }
        }

    }
    async function getBrandingInformation(documentAudienceId: string) {
        //dataTemplate.DocumentOnlyAudienceId

        const request = {
            AudienceMasterId: documentAudienceId
        }

        let {data}: any = await HttpRequest.getWithSession('GetAudienceMasterById', request)
        const response = data

        if (response !== undefined) {
            let audience = response.filter((x: any) => x.Id === props.audienceMasterIdUser)
            if (audience !== undefined && audience.length > 0) {
                setAudienceName(audience[0].Name.toString())
                setAudienceDescription(audience[0].Description!)
                setAudienceCompanyName(audience[0].CompanyName!)
                setAudienceCompanyGeography(audience[0].CompanyGeography!)
                setAudienceCompanyDepartment(audience[0].CompanyDepartment!)
                
                audience[0].BackgroundColor !== null && audience[0].BackgroundColor !== undefined ? setAudienceBackgroundColor(audience[0].BackgroundColor) : setAudienceBackgroundColor("#FFFFFF")
                audience[0].CompanyForeColor !== null && audience[0].CompanyForeColor !== undefined ? setAudienceFontColor(audience[0].CompanyForeColor) : setAudienceFontColor("#000000")
                
                if(audience[0].BackgroundColor !== null && audience[0].BackgroundColor !== undefined) {
                    setColor(audience[0].BackgroundColor)
                    document.getElementById("exampleBackground")!.style.backgroundColor = audience[0].BackgroundColor.toUpperCase()
                }
                else {
                    setColor("#FFFFFF")
                    document.getElementById("exampleBackground")!.style.backgroundColor = "#FFFFFF"

                }
                if(audience[0].CompanyForeColor !== null && audience[0].CompanyForeColor !== undefined) {
                    setFontColor(audience[0].CompanyForeColor)
                    document.getElementById("exampleFont")!.style.color = audience[0].CompanyForeColor.toUpperCase()
                }
                else {
                    setFontColor("#000000")
                    document.getElementById("exampleFont")!.style.color = "#000000"
                } 
    
                if (audience[0].Logo !== null && audience[0].Logo !== undefined && audience[0].Logo !== "") {
                    myForm.setValue("ImageDataAudience", audience[0].Logo)
                    setTrimmedDataURL(audience[0].Logo)
                }
                else {
                    myForm.setValue("ImageDataAudience", "")
                    setTrimmedDataURL("")
                }
                setIsDisabled(audience[0].Name.toString() === "" || audience[0].Description! === "")



            }


        }
    }
    useEffect(() => {

        if (props.modalTitle === "Edit" && props.audienceMasterIdUser > 0) {
            getBrandingInformation(props.audienceMasterIdUser)
        }
    }, [props.showNewAudience == true])
    const sendAudience = () => {

        switch (props.modalTitle) {
            case "Add": {
                addNewAudience()

            }
                break;

            case "Edit": {
                editExistingAudience()

            }
                break;

        }
    }

    async function editExistingAudience() {

        await myForm.handleSubmit(
            async (data) => {

            }
        )();
        
        const request = {
            AudienceMasterId: props.audienceMasterIdUser.toString(),
            Name: audienceName,
            Description: audienceDescription,
            CompanyName: audienceCompanyName,
            CompanyGeography: audienceCompanyGeography,
            CompanyDepartment: audienceCompanyDepartment,
            BackgroundColor: audienceBackgroundColor,
            Logo: myForm.getValues("ImageDataAudience"),
            FontColor: audienceFontColor
        }

        let {data}: any = await HttpRequest.postWithSession('UpdateAudienceMaster', request)
        const response = data
        
        if (response !== undefined) {

            if (response.Success) {
                setApplying(true)
                setShowSuccess(true)
                setMessage("Audience " + audienceName + " updated successfully")

            }
            else {

                setShowErrorMessage(true)
                setErrorMessage("An error occurred: " + response.ExceptionMessage)
                setTimeout(function () {
                    setShowErrorMessage(false)
                }, 5000);
            }
        }

    }
    const CleanModalAudience = useConstCallback(async () => {
        setAudienceName("")
        setIsDisabled(true)
        setAudienceDescription("")
        setTrimmedDataURL("")
        setAudienceCompanyName("")
        setAudienceCompanyGeography("")
        setAudienceCompanyDepartment("")
        setAudienceBackgroundColor("#FFFFFF")
        setAudienceFontColor("#000000")        
        setColor(audienceBackgroundColor)
        setFontColor(audienceFontColor)
        document.getElementById("exampleBackground")!.style.backgroundColor = audienceBackgroundColor.toUpperCase()      
        document.getElementById("exampleFont")!.style.color = audienceFontColor.toUpperCase()
        myForm.reset()
        myForm.setValue("ImageDataAudience", "")
        setShowSuccess(false)
        setMessage("")
        setShowErrorMessage(false)
        setErrorMessage("")

    })

    const handleChange = (color: any) => {
        setColor(color);
        setAudienceBackgroundColor(color.hex.toUpperCase());
        document.getElementById("exampleBackground")!.style.backgroundColor = color.hex.toUpperCase()
    }
    const handleFontChange = (color: any) => {
        setFontColor(color);
        setAudienceFontColor(color.hex.toUpperCase())
        document.getElementById("exampleFont")!.style.color = color.hex.toUpperCase()
    }


    return (
        <>
            <Modal
                isOpen={props.showNewAudience}
                isBlocking={true}>
                <div className={contentStyles.header}>
                    <Text variant={"xLarge"} id="CloseModalAudience" key="CloseModalAudience">{props.modalTitle == "Edit" ? "Update Audience" : "New Audience"} </Text>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel={t("Common.Button.CloseTitle", {ns: 'common'})}
                        onClick={async () => {
                            await CleanModalAudience();

                            props.closeNewAudience()

                        }}
                        title={t("Common.Button.Close", {ns: 'common'})}
                    />
                </div>
                <div className={contentStyles.addItemList}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{errorMessage}</MessageBar> : null}
                        {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                    </div>
                    <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", paddingRight: "10px", maxWidth: "100%", height: "60vh" }}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {/* {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{errorMessage}</MessageBar> : null}
                                    {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null} */}
                                    <div className="ms-Grid-row">
                                        <TextField
                                            label={t("Audience.TextFields.Name", {ns: 'audiencecomponent'})}
                                            onGetErrorMessage={() => { return "" }}
                                            autoFocus
                                            required
                                            onChange={(_, v) => { setAudienceName(v!); checkRequiredfields(); }}
                                            onBlur={() => { checkRequiredfields() }} value={audienceName}
                                            maxLength={75} title={t("Audience.TextFields.NameTitle", {ns: 'audiencecomponent'})}
                                            disabled={audienceName === "Default"}
                                        />
                                        <TextField label={t("Audience.TextFields.Description", {ns: 'audiencecomponent'})} onGetErrorMessage={() => { return "" }} required onChange={(_, v) => { setAudienceDescription(v!); checkRequiredfields(); }} value={audienceDescription} onBlur={() => { checkRequiredfields() }} maxLength={75} title="Audience name." />
                                        <TextField label={t("Audience.TextFields.CompanyName", {ns: 'audiencecomponent'})}onGetErrorMessage={() => { return "" }} onChange={(_, v) => { setAudienceCompanyName(v!); }} value={audienceCompanyName} maxLength={75} title={t("Audience.TextFields.CompanyNameTitle", {ns: 'audiencecomponent'})} />
                                        <TextField label={t("Audience.TextFields.CompanyGeography", {ns: 'audiencecomponent'})} onGetErrorMessage={() => { return "" }} onChange={(_, v) => { setAudienceCompanyGeography(v!); }} value={audienceCompanyGeography} maxLength={75} title={t("Audience.TextFields.CompanyGeography", {ns: 'audiencecomponent'})} />
                                        <TextField label={t("Audience.TextFields.CompanyDepartment", {ns: 'audiencecomponent'})} onGetErrorMessage={() => { return "" }} onChange={(_, v) => { setAudienceCompanyDepartment(v!); }} value={audienceCompanyDepartment} maxLength={75} title={t("Audience.TextFields.CompanyDepartment", {ns: 'audiencecomponent'})} />
                                        <h4>{t("Audience.Branding", {ns: 'audiencecomponent'})}</h4>
                                        <label>{t("Audience.BrandingDescription", {ns: 'audiencecomponent'})}</label>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                <TextField label={t("Audience.TextFields.BackgroundColor", {ns: 'audiencecomponent'})} onGetErrorMessage={() => { return "" }} onChange={(_, v) => { setAudienceBackgroundColor(v!); }} value={audienceBackgroundColor} maxLength={75} title={t("Audience.TextFields.BackgroundColor", {ns: 'audiencecomponent'})}/>
                                                <CompactPicker color={color} onChangeComplete={handleChange} colors={['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']} />
                                                <TextField label={t("Audience.TextFields.FontColor", {ns: 'audiencecomponent'})} onGetErrorMessage={() => { return "" }} onChange={(_, v) => { setAudienceFontColor(v!); }} value={audienceFontColor} maxLength={75} title={t("Audience.TextFields.FontColor", {ns: 'audiencecomponent'})} />
                                                <CompactPicker color={fontColor} onChangeComplete={handleFontChange} colors={['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']} />
                                                <br />
                                            </div>
                                            {!loadingPreview ?
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{ paddingTop: "80px", paddingLeft: "100px" }}>
                                                    <div id="exampleBackground" style={{ width: "150px", height: "150px" }}>
                                                        <div id="exampleFont" style={{ paddingTop: "40%", paddingLeft: "28%", fontSize: "large", fontWeight: "bold" }}>{t("Audience.TextFields.Example", {ns: 'audiencecomponent'})}</div>
                                                    </div>
                                                </div>
                                                : <Spinner size={SpinnerSize.medium} label="Loading preview..." ariaLive="assertive" />
                                            }
                                        </div>

                                        <Stack styles={{root: {marginTop: 8}}}>

                                            <FormProvider {...myForm}>
                                                <form>
                                                    <Label
                                                        title={t("Audience.Logo", {ns: 'audiencecomponent'})}>{t("Audience.Logo", {ns: 'audiencecomponent'})}</Label>
                                                    <RHFUploadControl
                                                        id={`ImageDataAudience`}
                                                        title="Select the default image for the content control"
                                                        control={myForm.control}
                                                        setValue={myForm.setValue}
                                                        getValues={myForm.getValues}
                                                        trigger={myForm.trigger}
                                                        rules={{

                                                            validate: async (data: any) => {
                                                                
                                                                if (data === undefined) return true
                                                                if (typeof data === "string") {
                                                                    return true
                                                                }

                                                                if (data === null && data.size <= 0) {
                                                                    return "The Min file size is 1 KB and the max file size is 100 KB"
                                                                }

                                                                var filevalid = false
                                                                var sizevalid = 100
                                                                var extensions = ["bmp", "tiff", "jpg", "png", "jpeg", "gif", "svg+xml"]
                                                                var typefile = data.type
                                                                var type = typefile.split("/")[1]
                                                                filevalid = extensions.includes(type)
                                                                if (!filevalid) {
                                                                    return "The supported types are: .jpg, .png .jpeg";
                                                                }

                                                                var fileSize = data.size
                                                                var Sizekb = fileSize / 1024
                                                              
                                                                let IsValidSize = (Sizekb <= sizevalid && fileSize > 0)
                                                                if (!IsValidSize) {
                                                                    return "The Max file size is 100 KB"
                                                                }

                                                                if (filevalid && IsValidSize) {
                                                                    return true
                                                                } else {
                                                                    return false
                                                                }
                                                            }
                                                        }}
                                                        placeholder={t("Audience.TextFields.FileImageEditPlaceholder", {ns: 'audiencecomponent'})}
                                                        defaultValue={trimmedDataURL}
                                                    />
                                                </form>
                                            </FormProvider>
                                        </Stack>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <DialogFooter>
                                            <PrimaryButton disabled={isDisabled} onClick={sendAudience} text={props.modalTitle == "Edit" ? "Update" : "Add"} title={props.modalTitle == "Edit" ? "Update Audience." : "Create Audience."} />
                                        </DialogFooter>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NewAudience