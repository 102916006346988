import { Checkbox, DefaultButton, DialogFooter, Dropdown, IconButton, IDropdownOption, IDropdownStyles, IIconProps, mergeStyleSets, PrimaryButton, TextField } from '@fluentui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IAssemblyExpression, IDocumentAssembly } from '../../../interfaces/IDocumentAssembly'
import DocumentAssemblyService from '../../../services/assembly'
import { IStatusMessage } from '../../../interfaces/IApp'

type ExpressionsFormType = {
    type: 'create' | 'update'
    close: (loading: boolean, message?: IStatusMessage, callback?: () => void) => void
    templateId: number
    item?: IDocumentAssembly
}
const conditionOptions: IDropdownOption[] = [
    { key: '', text: '' },
    { key: 'and', text: 'AND' },
    { key: 'or', text: 'OR' },
]
const pharenInnerOptions: IDropdownOption[] = [
    { key: '', text: '' },
    { key: '(', text: '(' },
]
const pharenOutherOptions: IDropdownOption[] = [
    { key: '', text: '' },
    { key: ')', text: ')' },
]
const compareOptions: IDropdownOption[] = [
    { key: '=', text: '=' },
    { key: '<', text: '<' },
    { key: '<=', text: '<=' },
    { key: '>', text: '>' },
    { key: '>=', text: '>=' },
    { key: '<>', text: '<>' },
    { key: 'Contains', text: 'Contains' },
    { key: 'Does Not Contains', text: 'Not Contains' },
]
type ExpressionsFormValueType = {
    expressions: IAssemblyExpression[]
}
const defaultValues: IAssemblyExpression[] = [
    {
        Id: Date.now(),
        RowNumber: 0,
        FieldName: '',
        defaultSet: false,
        defaultValue: false,
        runAlways: false,
        Compare: '',
        CompareValue: '',
        Condition: '', ParenOpen: '', ParenOuterClose: ''
    }
]
type DropdownFieldsType = 'ParenOpen' | 'Compare' | 'ParenOuterClose' | 'Condition' | 'FieldName'
const ExpressionsForm = ({ type, close, templateId, item }: ExpressionsFormType) => {

    const [contentControls, setContentControls] = useState<any[]>([])
    const { t } = useTranslation('assembly')
    const { control, handleSubmit, reset, setValue, trigger } = useForm<ExpressionsFormValueType>({
        defaultValues: {
            expressions: useMemo(() => {
                const hasExpressions = item && item.AssemblyExpressions.length > 0
                const mapped = item?.AssemblyExpressions.map(expression => {
                    return {
                        ...expression,
                        runAlways: expression.defaultSet && expression.defaultValue,
                    }
                })
                const expressions = hasExpressions ? mapped : defaultValues
                return expressions
            }, [item])
        }
    })

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "expressions",
    })
    const handleDropdown = (fieldName: DropdownFieldsType, index: number, event: React.FormEvent, option?: IDropdownOption | undefined) => {
        const dropDownsfields: { [key: string]: IDropdownOption[] } = {
            ParenOpen: pharenInnerOptions,
            Compare: compareOptions,
            ParenOuterClose: pharenOutherOptions,
            Condition: conditionOptions,
            FieldName: contentControls,
            FieldValue: contentControls
        }
        const items: IDropdownOption[] = dropDownsfields[fieldName]
        const item = items.find(item => item.key === option?.key)
        if (item) {
            //@ts-ignore
            setValue(`expressions.${index}.${fieldName}` as const, `${option?.key}`)
        }
        if (fieldName === 'Condition' && option?.key !== '') {
            if (fields[index + 1] === undefined)
                append(defaultValues[0])
        }
    }

    const saveForm = (data: ExpressionsFormValueType) => {
        if (item && item.RuleId) {
            const expressions = data.expressions.map(expression => {
                return { ...expression, defaultSet: expression.runAlways, defaultValue: expression.runAlways }
            })
            let assembly: IDocumentAssembly = { ...item, AssemblyExpressions: expressions }

            delete assembly.DocumentAssemblyActions

            DocumentAssemblyService.updateAssembly(templateId, item.RuleId, JSON.stringify(assembly)).then(({ data }: any) => {
                if (data.Success === false) {
                    close(true, { Type: 'error', Message: <>Error saving Expressions: {data.ExceptionMessage}</> })
                    return
                }

                close(true, { Type: 'success', Message: <>Changes on Rules Expressiosn saved</> })
            })
        }

    }
    const handleDeleteRow = (index: number) => {
        if (index > 0) {
            const isLastIndex = fields.length === index + 1
            if (isLastIndex) {
                //@ts-ignore
                setValue(`expressions.${index - 1}.Condition` as const, '')
                trigger('expressions')
            }
            remove(index)
        }
    }
    const modalClose = () => {
        close(false)
    }
    useEffect(() => {
        DocumentAssemblyService.getContentControls(templateId).then((controls: any) => {
            setContentControls(controls)
        })
    }, [templateId])

    return (
        <form onSubmit={handleSubmit(saveForm)}>
            <div>

                <div className="scrollVisible" style={{
                    overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh"
                }}>
                    <table className={contentStyles.table}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Run Always</th>
                                <th>{'('}</th>
                                <th>Content Control</th>
                                <th>Compare</th>
                                <th>Compare Value</th>
                                <th>{')'}</th>
                                <th>Condition</th>
                            </tr>
                        </thead>
                        <tbody>

                            {fields.map((item, index) => {
                                return (
                                    <tr key={item.id}>
                                        <td>
                                            <IconButton iconProps={deleteIcon} disabled={index === 0} title="Remove" ariaLabel="Remove" onClick={() => { handleDeleteRow(index) }} />
                                        </td>
                                        <td>
                                            <Controller name={`expressions.${index}.runAlways` as const} control={control}
                                                render={({ field }) => <Checkbox  {...field} className={contentStyles.checkBox} checked={Boolean(field.value)}
                                                    title={t('assembly.runAlways')} value={'runAlways'} />}
                                            />
                                        </td>
                                        <td>
                                            <Controller name={`expressions.${index}.ParenOpen` as const} control={control}
                                                render={({ field }) => <Dropdown selectedKey={field.value}
                                                    {...field} onChange={(event, option) => handleDropdown('ParenOpen', index, event, option)}
                                                    options={pharenInnerOptions}
                                                />}
                                            />
                                        </td>
                                        <td>
                                            <Controller name={`expressions.${index}.FieldName` as const} control={control}
                                                render={({ field }) => <Dropdown selectedKey={field.value} styles={fieldNameDropdown}
                                                    {...field} onChange={(event, option) => handleDropdown('FieldName', index, event, option)}
                                                    options={contentControls}
                                                />}
                                            />
                                        </td>
                                        <td>
                                            <Controller name={`expressions.${index}.Compare` as const} control={control}
                                                render={({ field }) => <Dropdown styles={compareDropdown} selectedKey={field.value}
                                                    {...field} onChange={(event, option) => handleDropdown('Compare', index, event, option)}
                                                    options={compareOptions}
                                                />}
                                            />
                                        </td>
                                        <td>
                                            <Controller name={`expressions.${index}.CompareValue` as const} control={control}
                                                render={({ field }) => <TextField {...field} />}
                                            />
                                        </td>
                                        <td>
                                            <Controller name={`expressions.${index}.ParenOuterClose` as const} control={control}
                                                render={({ field }) => <Dropdown selectedKey={field.value}
                                                    {...field} onChange={(event, option) => handleDropdown('ParenOuterClose', index, event, option)}
                                                    options={pharenOutherOptions}
                                                />} />
                                        </td>
                                        <td>
                                            <Controller name={`expressions.${index}.Condition` as const} control={control}
                                                render={({ field }) => <Dropdown styles={conditionDropdown}
                                                    {...field} selectedKey={field.value} onChange={(event, option) => handleDropdown('Condition', index, event, option)}
                                                    options={conditionOptions}
                                                />}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <DialogFooter>
                        <PrimaryButton id="btnCreateUpdate" type="submit" text="Save Expressions" disabled={false} title="Save Expressions" />
                        <DefaultButton id="btnCancel" onClick={modalClose} text={t('formSettings.btnCancel')} title={t('formSettings.btnCancel')} />
                    </DialogFooter>
                </div>
            </div>
        </form>
    )
}
const plusIcon: IIconProps = { iconName: 'Add' }
const deleteIcon: IIconProps = { iconName: 'Delete' }
const contentStyles = mergeStyleSets({
    section: {
        padding: '0.5rem 0'
    },
    table: {
        width: '100%'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    checkBox: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '30px',
    },

})
const fieldNameDropdown: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 150 },
}
const compareDropdown: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 150 },
}
const conditionDropdown: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 100 },
}
export default ExpressionsForm
