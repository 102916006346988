import {INavStyles} from "@fluentui/react/lib/Nav";
import {FontWeights, getTheme, mergeStyleSets} from "@fluentui/react/lib/Styling";
import {IStackStyles} from "office-ui-fabric-react";
import {Theme} from "@fluentui/react-theme-provider";

const theme = getTheme();

export const nonShrinkingStackItemStyles: IStackStyles = {
    root: {
        display: 'flex',
        height: 40,
        overflow: 'hidden',
        alignItems: "center"
    },
};



export const navStyles: Partial<INavStyles> = {
    root: {},
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
    },
    chevronIcon: {
        display: 'none'
    },
    chevronButton: {
        display: 'none'
    },
};

export const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

export interface IContentStyles {
    container: string
    containers: string
    fileIconImg: string
    header: string
    body: string
    bodyGeneral: string
    bodyList: string
    bodyExample: string
    bodyExamples: string
    containerMail: string
    User: string
    SppinnerActive: string
}

export const contentStyles = (theme: Theme):IContentStyles => { 
    return mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '65vw',
    },
    containers: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: window.innerWidth > 1800 ? '45vw' : '65vw',
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
        color: 'rgb(96, 94, 92)'
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.black,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        height: window.innerHeight > 660 ? '63vh' : '58vh',
        flex: '4 4 auto',
        padding: '0 15px 24px 15px',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
    },
    bodyGeneral: {
        height: window.innerHeight > 660 ? '63vh' : '58vh',
        flex: '4 4 auto',
        padding: '0 15px 24px 15px',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
    },
    bodyList: {
        width: '70vw',
        height: '75vh',
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
    },
    bodyExample: {
        height: window.innerHeight > 660 ? '63vh' : '58vh',
        flex: '4 4 auto',
        padding: '0 15px 24px 15px',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
    },
    bodyExamples: {
        height: window.innerHeight > 660 ? '50vh' : '50vh',
        flex: '4 4 auto',
        padding: '0 15px 24px 15px',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
    },
    containerMail: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '30vw',
        height: '20vh',
    },
    User: {
        width: '25vw',
        height: '20vh',
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
    },
    SppinnerActive: {
        pointerevents: 'none!important'
    }
})}