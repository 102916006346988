import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Nav, INavLink, INavLinkGroup, INavStyles, IRenderGroupHeaderProps} from '@fluentui/react/lib/Nav';
import { mergeStyleSets, getTheme, FontWeights } from '@fluentui/react/lib/Styling';
import {IconButton, DefaultButton, PrimaryButton, ActionButton} from '@fluentui/react/lib/Button';
import Helper, { IDocumentRequest, IDownloadDocumentOnlyTemplateRequest, IDocumentOnlyAudienceRequest, IDocumentOnlyAudienceDetailRequest } from '../../Helpers/Helper';
import { useConstCallback } from '@uifabric/react-hooks';
import CustomTemplate from '../CustomTemplates/CustomTemplate';
import '../../Custom.css'
import PreviewScreen from '../Preview/PreviewScreen'
import {
    IContextualMenuItem,
    IContextualMenuProps,
    IStackTokens,
    Stack,
    CommandButton,
    Modal,
    IIconProps,
    GroupHeader, INavStyleProps, classNamesFunction
} from '@fluentui/react';
import ShareTemplate from '../CustomTemplates/ShareTemplate';
import { Separator } from '@fluentui/react/lib/Separator';
import { CustomForm } from '../CustomTemplates/CustomForm/CustomForm';
import FormDeleteTemplate from '../Form/FormDeleteTemplate';
import { decode } from 'base64-arraybuffer';
import { ReactNotificationOptions, store } from 'react-notifications-component';
import EditCategoriesModal from '../Settings/EditCategoriesModal';
import UploadTemplate from '../CustomTemplates/UploadTemplate';
import CloneTemplate from '../CustomTemplates/CloneTemplate';
import ExportTemplate from '../ExportTemplate/ExportTemplate';
import Confirmation from '../Settings/Confirmation';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import FormTemplateLink from '../TemplateLink/FormTemplateLink';
import { isMobile } from 'react-device-detect';
import VersionTemplate from '../Version/VersionTemplate';
import CampaignList from '../Campaign/CampaignList';
import {useTranslation} from "react-i18next";
import { formatFromUTC } from '../../Helpers/DateUtils';
import {IPropertyBag} from "../DataPanel/DataPanel";
import {AttachmentSettings} from "../../interfaces/IPropertyBagSettings";
import QueueModal from '../QueueModal/QueueModal';
import { useModal } from '../../hooks/useModal';
import {useBranding} from "../../hooks/useBranding";
import {Icon} from "office-ui-fabric-react";
import {customAppContext} from "../../context/AppContext";
const theme = getTheme();
const FileDownload = require("js-file-download");

const navStyles: Partial<INavStyles> = {
    chevronButton: {
        fontSize: '16px',
    }    
};

export class TemplateListRequest {
    UserName: string = ""
    SessionKey: string = ""
    AccountId: string = ""
    AccountIds: string = ""
    Site: String = ""
}

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',

    },


};
const iconButtonStylesPrompt = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
            ':disabled': {
                backgroundColor: theme.palette.white
            },
        },
    },
    
};
const iconButtonStylesHide = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        display: 'none'
    },
    
};

const stackTokens: IStackTokens = { childrenGap: 0 };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
export interface IContentControls {

    alias: string;
    tag: string;
    children: IContentControls[];
}

let isOwnerTemplate = false
function TemplateList(props: any) {
    const { branding } = useBranding()
    const navItemLinks: INavLinkGroup[] = [];
    const [modalTitle, setModalTitle] = useState("")
    const [documentId, setModalId] = useState("")
    const [showShare, setShowShare] = useState(false)
    const [templateId, setTemplateId] = useState("")
    const [spinnerText, setSpinnerText] = useState("")
    const [showFormBuilder, setShowFormBuilder] = useState(false)
    const [siteSelected, setSiteSelected] = useState("")
    const [contentControls, setContentControls] = useState<IContentControls[]>()
    const [templateName, setTemplateName] = useState("")
    const [isUpdate, setIsUpdate] = useState(false)
    const [isRecordUpdate, setIsRecordUpdate] = useState(false);
    const [isRecordError, setIsRecordError] = useState(false);
    const [showCustomForm, setShowCustomForm] = useState(false)
    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [previewURL, setPreviewURL] = useState("")
    const [previewMime, setPreviewMime] = useState("")
    const [showText, setshowText] = useState("")
    const [showDeleteModal, setshowDeleteModal] = useState(false)
    const [documentOnlyAudienceId, setDocumentOnlyAudienceId] = useState("")
    const [showUploadTemplate, setShowUploadTemplate] = useState(false)
    const [showCloneTemplate, setShowCloneTemplate] = useState(false)
    const [allowDocx, setAllowDocx] = useState(false)
    const [showExportTemplate, setShowExportTemplate] = useState(false)
    const [showEditCategories, setShowEditCategories] = useState(false)
    const [showRemove, setShowRemove] = useState(false)
    const [removeeMessage, setremoveMessage] = useState("")
    const [audienceDetail, setAudienceDetail] = useState(-1)
    const [iscoOwner, setiscoOwner] = useState(false)
    const [isCreator, setisCreator] = useState(false)
    const [isOwner, setisOwner] = useState(false)
    const [itemId, setItemId] = useState("")
    const [culture, setCulture] = useState("")

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [docSelected, setDocSelected] = useState("")
    const [showFormTemplateLink, setshowFormTemplateLink] = useState(false);

    const [ownerName, setOwnerName] = useState("")
    const [ownerUser, setOwnerUser] = useState("")
    const [templateCategory, setTemplateCategory] = useState("")
    const [audienceName, setAudienceName] = useState("")
    const [emailMoniker, setEmailMoniker] = useState("")
    const [documentCreation, setDocumentCreation] = useState(false)
    const [documentUpdate, setDocumentUpdate] = useState(false)
    const [templateEdit, setTemplateEdit] = useState(false)
    const [templateDelete, setTemplateDelete] = useState(false)
    const [templateShare, setTemplateShare] = useState(false)
    const [showVersionTemplate, setShowVersionTemplate] = useState(false)
    const [defaultDescription, setDefaultDescription] = useState("")

    const [showCampaignList, SetShowCampaignList] = useState(false)
    const [subscriptionMessage, setSubscriptionMessage] = useState("")
    const [hideSubscriptionDialog, setHideSubscriptionDialog] = useState(true);
    const [templateRemove, setTemplateRemove] = useState(false)

    const {t} = useTranslation(['remove','common','workspace', 'deleteTemplate', 'download']);
    const { setCategoryFocus, categoryFocus } = useContext(customAppContext)



    useEffect(() => {
        if(categoryFocus !== undefined) {

            // const element: HTMLElement = document.querySelector(`[aria-label="Expand ${categoryFocus}"], [aria-label="Collapse ${categoryFocus}"]`) as HTMLElement;
            //
            // if (element) {
            //     element.scrollIntoView({behavior: 'auto', block: 'center'})
            //     element.click()
            // }
         
        }
        return () => {
            setCategoryFocus(undefined)
        }
      
    }, [categoryFocus]);

    const [draftId, setDraftId] = useState<number>(-1)

    const QueueCustomModal = useModal()
    
    const dialogSubscriptionContentProps = {
        type: DialogType.largeHeader,
        title: sessionStorage.getItem("entityId") !== 'Not In Teams' ? "Information" : 'Subscription',
        subText: subscriptionMessage,
    };
    const modalProps = {
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
    };

    const classNames = mergeStyleSets({
        containerSaveModal: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '30vw',
            height: '20vh',
        },
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 500,

        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        wrapper: {
            height: '88vh',
            position: 'relative',
            backgroundColor: 'white',
        },
        pane: {
            maxWidth: '100%',
        },
        settings: {
            backgroundColor: 'white',
        },
        version: {
            float: 'right',
            marginTop: '5px',
            marginRight: '15px',
        },
    });


    const showNotification = (Nmessage: string, Ntype: string) => {

        var auxType: ReactNotificationOptions;
        switch (Ntype) {
            case "success":
                store.addNotification({
                    title: t('title.success', { ns: 'common'}),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "warning":
                store.addNotification({
                    title: t('title.warning', { ns: 'common'}),
                    message: Nmessage,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "error":
                store.addNotification({
                    title: t('title.error', { ns: 'common'}),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "info":
                store.addNotification({
                    title: t('title.information', { ns: 'common'}),
                    message: Nmessage,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "successLeft":
                store.addNotification({
                    title: t('title.success', { ns: 'common'}),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "errorLeft":
                store.addNotification({
                    title: t('title.error', { ns: 'common'}),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            default:
                break;
        }
    }

    const onRenderLink = (item?: INavLink) => {

        var itemSubscriptionStatusMessage = item!.SubscriptionStatusMessage != null && item!.SubscriptionStatusMessage != undefined && item!.SubscriptionStatusMessage != "" ? item!.SubscriptionStatusMessage : "Expired subscription"
        if (item!.Owner) {
            return (

                <div style={{
                    width: "100%",
                    height: "100%",
                }}>
                    <div 
                        title={(!item!.activeSubscription && sessionStorage.getItem("billingActive") === 'true') ? itemSubscriptionStatusMessage : item!.title} className="ms-Grid-col ms-sm11 ms-md11 ms-lg11" style={{
                        height: "100%",
                        textAlign: "left",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: (!item!.activeSubscription && sessionStorage.getItem("billingActive") === 'true') ? "gray" : item!.released ? "black" : "red"
                    }}>
                        {!isMobile &&
                            <div style={{width: '40px', display: 'block', textAlign: 'right'}}><IconButton
                                id={"btnTemplate" + item?.key}
                                menuIconProps={{iconName: 'Copy', style: {fontSize: 15, color: '#242424'}}}
                                role="button"
                                aria-haspopup
                                aria-label="Show actions"
                                styles={item!.isClon ? iconButtonStyles : iconButtonStylesHide}
                                menuProps={menuProps(item)}
                                title={"Click to select from available actions."}
                            /></div>
                        }
                        {item!.name}
                    </div>
                    {!isMobile &&
                        <div style={{width: '40px', display: 'block', textAlign: 'right'}}><IconButton
                            id={"btnTemplateActions" + item?.key}
                            menuIconProps={{iconName: 'MoreVertical', style: {fontSize: 15, color: '#242424'}}}
                            role="button"
                            aria-haspopup
                            aria-label="Show actions"
                            styles={iconButtonStyles}
                            menuProps={menuProps(item)}
                            title={"Click to select from available actions."}
                        /></div>
                    }

                </div>

            )
        }
        else if (item!.CoOwner) {
            return (

                <div style={{
                    width: "100%",
                    height: "100%",
                }}>
                    <div 
                        title={(!item!.activeSubscription && sessionStorage.getItem("billingActive") === 'true') ? itemSubscriptionStatusMessage : item!.title}
                        className="ms-Grid-col ms-sm11 ms-md11 ms-lg11" style={{
                        height: "100%",
                        textAlign: "left",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: (!item!.activeSubscription && sessionStorage.getItem("billingActive") === 'true') ? "gray" : item!.released ? "black" : "red"
                    }}>
                        {item!.name}
                    </div>
                    {!isMobile &&
                        <div style={{width: '40px', display: 'block', textAlign: 'right'}}><IconButton
                            id={"btnTemplateActions" + item?.key}
                            menuIconProps={{iconName: 'MoreVertical', style: {fontSize: 15, color: '#242424'}}}
                            role="button"
                            aria-haspopup
                            aria-label="Show actions"
                            styles={iconButtonStyles}
                            menuProps={menuPropsCoOwner(item)}
                            title={"Click to select from available actions."}
                        /></div>
                    }
                </div>
            )
        }
        else if (item!.CreateDocument) {
            return (

                <div style={{
                    width: "100%",
                    height: "100%",
                }}>
                    <div
                        title={(!item!.activeSubscription && sessionStorage.getItem("billingActive") === 'true') ? itemSubscriptionStatusMessage : item!.title}
                        className="ms-Grid-col ms-sm11 ms-md11 ms-lg11" style={{
                            height: "100%",
                            textAlign: "left",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            color: (!item!.activeSubscription && sessionStorage.getItem("billingActive") === 'true') ? "gray" : item!.released ? "black" : "red"
                        }}>
                        {item!.name}
                    </div>
                    {!isMobile &&
                        <div style={{width: '40px', display: 'block', textAlign: 'right'}}><IconButton
                            id={"btnTemplateActions" + item?.key}
                            menuIconProps={{iconName: 'MoreVertical', style: {fontSize: 15, color: '#242424'}}}
                            role="button"
                            aria-haspopup
                            aria-label="Show actions"
                            styles={iconButtonStyles}
                            menuProps={menuPropsCreateDocument(item)}
                            title={"Click to select from available actions."}
                        /></div>
                    }

                </div>

            )

        }
        else {
            return (

                <div style={{
                    width: "100%",
                    height: "100%",
                }}>
                    <div
                        title={(!item!.activeSubscription && sessionStorage.getItem("billingActive") === 'true') ? itemSubscriptionStatusMessage : item!.title}
                        className="ms-Grid-col ms-sm11 ms-md11 ms-lg11" style={{
                            height: "100%",
                            textAlign: "left",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            color: (!item!.activeSubscription && sessionStorage.getItem("billingActive") === 'true') ? "gray" : item!.released ? "black" : "red"
                        }}>
                        {item!.name}
                    </div>
                </div>
            )
        }
    }
    const toggleQueueModal = useCallback((open: boolean, isUpdate: boolean, isError:boolean)=> {
        if (open) {
            QueueCustomModal.open()
            setIsRecordUpdate(isUpdate)
            setIsRecordError(isError)
        }
    },[QueueCustomModal])

    const toggleCampaignList = (item?: INavLink) => {
        SetShowCampaignList(true)
        setTemplateId(item!.key!)
    }

    const hideCampaignList = useConstCallback(() => {
        SetShowCampaignList(false)
    })

    const templateMenu = (item?: INavLink): IContextualMenuItem[] => {
        return [
            {
                id: "btnTemplateActionsTemplateAudience" + item?.key,
                key: 'templateAudiences',
                text: t('text.linkAudience', { ns: 'workspace'}),
                title: t('text.linkAudienceTitle', { ns: 'workspace'}),
                onClick: () => {
                    setisOwner(item!.Owner)
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setModalTitle("Application's Audience (" + item!.name + ")")
                    onItemShare(item!)
                },
            },
            {
                id: "btnTemplateActionsCampaign" + item?.key,
                key: 'campaign',
                text: t('text.linkCampaign', { ns: 'workspace'}),
                title: t('text.linkCampaignTitle', { ns: 'workspace'}),
                onClick: () => {
                    setOwnerUser(item!.ownerUsername!)
                    setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())
                    toggleCampaignList(item)
                },
            },
            {
                id: "btnTemplateActionsClone" + item?.key,
                key: 'CloneTemplate' + item?.key,
                text: t('text.linkClone', { ns: 'workspace'}),
                title: t('text.linkCloneTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())
                    setIsUpdate(true)
                    setShowCloneTemplate(true)
                },
            },
            {
                id: "btnTemplateActionsDelete" + item?.key,
                key: 'delete',
                text: t('text.linkDelete', { ns: 'workspace'}),
                title: t('text.linkDeleteTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setOwnerName(item!.ownerFullName!)
                    setOwnerUser(item!.ownerUsername!)
                    setTemplateCategory(item!.category!)
                    setAudienceName(item!.audienceName!)
                    setEmailMoniker(item!.emailMoniker)
                    setTemplateDelete(item!.templateDelete)
                    setModalTitle(t('title.modal', { ns: 'deleteTemplate', Name: item!.name}))
                    setshowDeleteModal(true)
                    setCulture(item!.culture)
                    setshowText(t('message.confirmDelete', { ns: 'deleteTemplate'}))
                },
            },
            {
                id: "btnTemplateActionsDownload" + item?.key,
                key: 'download',
                text: t('text.linkDownload', { ns: 'workspace'}),
                title: t('text.linkDownloadTitle', { ns: 'workspace'}),
                onClick: () => {
                    const id = item!.key
                    setDocSelected(id!)
                    setShowConfirmationModal(true)
                },
            },
            {
                id: "btnTemplateActionsExport" + item?.key,
                key: 'ExportTemplate' + item?.key,
                text: t('text.linkExport', { ns: 'workspace'}),
                title: t('text.linkExportTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setIsUpdate(true)
                    setShowExportTemplate(true)
                },
            },
            {
                id: "btnTemplateActionsProperties" + item?.key,
                key: 'properties',
                text: t('text.linkProperties', { ns: 'workspace'}),
                title: t('text.linkPropertiesTitle', { ns: 'workspace'}),
                onClick: () => {
                    props.openMyTemplate(item!)
                },
            },
            {
                id: "btnTemplateActionsTemplateLink" + item?.key,
                key: 'sendTemplatelink',
                text: t('text.linkWebform', { ns: 'workspace'}),
                title: t('text.linkWebformTitle', { ns: 'workspace'}),
                onClick: () => {

                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setModalTitle("Send Application (" + item!.name + ")")
                    sendTemplateLink(item!)
                }
            },            
            {
                id: "btnTemplateActionsUpdateTemplate" + item?.key,
                key: 'Updatetemplate',
                text: t('text.linkUpdate', { ns: 'workspace'}),
                title: t('text.linkUpdateTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setModalTitle(t('title.update', { ns: 'uploadtemplate'}) + " (" + item!.name + ")")
                    setTemplateName(item!.name)
                    onItemUploadTemplate(item!)
                    setIsUpdate(true)
                },
            },
            {
                id: "btnTemplateActionsVersionTemplate" + item?.key,
                key: 'VersionTemplate',
                text: t('text.linkVersion', { ns: 'workspace'}),
                title: t('text.linkVersionTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setTemplateName(item!.name)
                    onItemVersionTemplate(item!)
                    setIsUpdate(true)
                },
            },
            {
                id: "btnTemplateActionsOrder" + item?.key,
                key: 'order',
                text: t('text.linkWizard', { ns: 'workspace'}),
                title: isMobile ? t('text.linkWizardMobileTitle', { ns: 'workspace'}) : t('text.linkWizardTitle', { ns: 'workspace'}),
                disabled: isMobile,
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setCulture(item!.culture)
                    setOwnerName(item!.ownerFullName!)
                    setOwnerUser(item!.ownerUsername!)
                    setTemplateCategory(item!.category!)
                    setAudienceName(item!.audienceName!)
                    setEmailMoniker(item!.emailMoniker)
                    setTemplateEdit(item!.templateEdit)
                    setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())
                    setIsUpdate(true)
                    toggleFormBuilder(true)
                },
            },
        ];
    }
    const templateMenuMobile = (item?: INavLink): IContextualMenuItem[] => {
        return [
            {
                id: "btnTemplateActionsTemplateAudience" + item?.key,
                key: 'templateAudiences',
                text: t('text.linkAudience', { ns: 'workspace'}),
                title: t('text.linkAudienceTitle', { ns: 'workspace'}),
                onClick: () => {
                    setisOwner(true)
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setModalTitle("Application's Audience (" + item!.name + ")")
                    onItemShare(item!)
                },
            },
            {
                key: 'campaign',
                text: t('text.linkCampaign', { ns: 'workspace'}),
                title: t('text.linkCampaignTitle', { ns: 'workspace'}),
                onClick: () => {
                    setOwnerUser(item!.ownerUsername!)
                    setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())
                    toggleCampaignList(item)
                },
            },
            {
                id: "btnTemplateActionsClone" + item?.key,
                key: 'CloneTemplate' + item?.key,
                text: t('text.linkClone', { ns: 'workspace'}),
                title: t('text.linkCloneTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())
                    setIsUpdate(true)
                    setShowCloneTemplate(true)
                },
            },
            {
                id: "btnTemplateActionsDelete" + item?.key,
                key: 'delete',
                text: t('text.linkDelete', { ns: 'workspace'}),
                title: t('text.linkDeleteTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setOwnerName(item!.ownerFullName!)
                    setOwnerUser(item!.ownerUsername!)
                    setTemplateCategory(item!.category!)
                    setAudienceName(item!.audienceName!)
                    setEmailMoniker(item!.emailMoniker)
                    setTemplateDelete(item!.templateDelete)
                    setModalTitle("Application Statistics (" + item!.name + ")")
                    setshowDeleteModal(true)
                    setCulture(item!.culture)
                    setshowText("Are you sure you want to delete this application?")
                },
            },
            {
                id: "btnTemplateActionsExport" + item?.key,
                key: 'ExportTemplate' + item?.key,
                text: t('text.linkExport', { ns: 'workspace'}),
                title: t('text.linkExportTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setIsUpdate(true)
                    setShowExportTemplate(true)
                },
            },
            {
                id: "btnTemplateActionsProperties" + item?.key,
                key: 'properties',
                text: t('text.linkProperties', { ns: 'workspace'}),
                title: t('text.linkPropertiesTitle', { ns: 'workspace'}),
                onClick: () => {
                    props.openMyTemplate(item!)
                },
            },
            {
                id: "btnTemplateActionsTemplateLink" + item?.key,
                key: 'sendTemplatelink',
                text: t('text.linkWebform', { ns: 'workspace'}),
                title: t('text.linkWebformTitle', { ns: 'workspace'}),
                onClick: () => {

                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setModalTitle("Send Application (" + item!.name + ")")
                    sendTemplateLink(item!)
                }
            },
            {
                id: "btnTemplateActionsUpdateTemplate" + item?.key,
                key: 'Updatetemplate',
                text: t('text.linkUpdate', { ns: 'workspace'}),
                title: t('text.linkUpdateTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setModalTitle(t('title.update', { ns: 'uploadtemplate'}) + " (" + item!.name + ")")
                    setTemplateName(item!.name)
                    onItemUploadTemplate(item!)
                    setIsUpdate(true)
                },
            },
            {
                id: "btnTemplateActionsVersionTemplate" + item?.key,
                key: 'VersionTemplate',
                text: t('text.linkVersion', { ns: 'workspace'}),
                title: t('text.linkVersionTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setTemplateName(item!.name)
                    onItemVersionTemplate(item!)
                    setIsUpdate(true)
                },
            },
            {
                id: "btnTemplateActionsOrder" + item?.key,
                key: 'order',
                text: t('text.linkWizard', { ns: 'workspace'}),
                title: isMobile ? t('text.linkWizardMobileTitle', { ns: 'workspace'}) : t('text.linkWizardTitle', { ns: 'workspace'}),
                disabled: isMobile,
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setCulture(item!.culture)
                    setOwnerName(item!.ownerFullName!)
                    setOwnerUser(item!.ownerUsername!)
                    setTemplateCategory(item!.category!)
                    setAudienceName(item!.audienceName!)
                    setEmailMoniker(item!.emailMoniker)
                    setTemplateEdit(item!.templateEdit)
                    setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())
                    setIsUpdate(true)
                    toggleFormBuilder(true)
                },
            },
        ];
    }
    const templateMenuCoOwnerMobile = (item?: INavLink): IContextualMenuItem[] => {
        return [
            {
                id: "btnTemplateActionsTemplateAudiences" + item?.key,
                key: 'templateAudiences',
                text: t('text.linkAudience', { ns: 'workspace'}),
                title: t('text.linkAudienceTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setiscoOwner(true)
                    setTemplateId(item!.key!)
                    setModalTitle("Application's Audience (" + item!.name + ")")
                    onItemShare(item!)
                },
            },
            {
                key: 'campaign',
                text: t('text.linkCampaign', { ns: 'workspace'}),
                title: t('text.linkCampaignTitle', { ns: 'workspace'}),
                onClick: () => {
                    toggleCampaignList(item)
                },
            },
            {
                id: "btnTemplateActionsCloneTemplate" + item?.key,
                key: 'CloneTemplate' + item?.key,
                text: t('text.linkClone', { ns: 'workspace'}),
                title: t('text.linkCloneTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())
                    setIsUpdate(true)
                    setShowCloneTemplate(true)
                },
            },
            {
                id: "btnTemplateActionsProperties" + item?.key,
                key: 'properties',
                text: t('text.linkProperties', { ns: 'workspace'}),
                title: t('text.linkPropertiesTitle', { ns: 'workspace'}),
                onClick: () => {
                    props.openMyTemplate(item!)
                },
            },
            {
                id: "btnTemplateActionsTemplateLink" + item?.key,
                key: 'sendTemplatelink',
                text: t('text.linkWebform', { ns: 'workspace'}),
                title: t('text.linkWebformTitle', { ns: 'workspace'}),
                onClick: () => {

                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setModalTitle("Send Application (" + item!.name + ")")
                    sendTemplateLink(item!)
                }
            },
            {
                id: "btnTemplateActionsTemplateUpdate" + item?.key,
                key: 'Updatetemplate',
                text: t('text.linkUpdate', { ns: 'workspace'}),
                title: t('text.linkUpdateTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setModalTitle(t('title.update', { ns: 'uploadtemplate'}) + " (" + item!.name + ")")
                    onItemUploadTemplate(item!)
                },
            },
            {
                id: "btnTemplateActionsVersionTemplate" + item?.key,
                key: 'VersionTemplate',
                text: t('text.linkVersion', { ns: 'workspace'}),
                title: t('text.linkVersionTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setTemplateName(item!.name)
                    onItemVersionTemplate(item!)
                    setIsUpdate(true)
                },
            },
            {
                id: "btnTemplateActionsOrder" + item?.key,
                key: 'order',
                text: t('text.linkWizard', { ns: 'workspace'}),
                title: isMobile ? t('text.linkWizardMobileTitle', { ns: 'workspace'}) : t('text.linkWizardTitle', { ns: 'workspace'}),
                disabled: isMobile,
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setCulture(item!.culture)
                    setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())
                    setOwnerName(item!.ownerFullName!)
                    setOwnerUser(item!.ownerUsername!)
                    setTemplateCategory(item!.category!)
                    setAudienceName(item!.audienceName!)
                    setEmailMoniker(item!.emailMoniker)
                    setTemplateEdit(item!.templateEdit)
                    setIsUpdate(true)
                    toggleFormBuilder(true)
                },
            },
            {
                id: "btnTemplateActionsRemove" + item?.key,
                key: 'Remove',
                text: t('text.linkRemove', { ns: 'workspace'}),
                title: t('text.linkRemoveTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setLayoutLoaded(false)
                    setModalTitle(t('title.modal', { ns: 'remove', Name: item!.name }))
                    setTemplateId(item!.key!)
                    setItemId(item!.key!)
                    setLayoutLoaded(true)
                    setSpinnerText(t('text.spinner', { ns: 'common'}))
                    onItemRemove(item!)

                },
            },

        ];
    }
    const templateMenuCoOwner = (item?: INavLink): IContextualMenuItem[] => {
        return [
            {
                id: "btnTemplateActionsTemplateAudiences" + item?.key,
                key: 'templateAudiences',
                text: t('text.linkAudience', { ns: 'workspace'}),
                title: t('text.linkAudienceTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setiscoOwner(true)
                    setTemplateId(item!.key!)
                    setModalTitle("Application's Audience (" + item!.name + ")")
                    onItemShare(item!)
                },
            },
            {
                key: 'campaign',
                text: t('text.linkCampaign', { ns: 'workspace'}),
                title: t('text.linkCampaignTitle', { ns: 'workspace'}),
                onClick: () => {
                    toggleCampaignList(item)
                },
            },
            {
                id: "btnTemplateActionsCloneTemplate" + item?.key,
                key: 'CloneTemplate' + item?.key,
                text: t('text.linkClone', { ns: 'workspace'}),
                title: t('text.linkCloneTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())
                    setIsUpdate(true)
                    setShowCloneTemplate(true)
                },
            },
            {
                id: "btnTemplateActionsDownload" + item?.key,
                key: 'download',
                text: t('text.linkDownload', { ns: 'workspace'}),
                title: t('text.linkDownloadTitle', { ns: 'workspace'}),
                onClick: () => {
                    const id = item!.key
                    setDocSelected(id!)
                    setShowConfirmationModal(true)
                },
            },
            {
                id: "btnTemplateActionsProperties" + item?.key,
                key: 'properties',
                text: t('text.linkProperties', { ns: 'workspace'}),
                title: t('text.linkPropertiesTitle', { ns: 'workspace'}),
                onClick: () => {
                    props.openMyTemplate(item!)
                },
            },
            {
                id: "btnTemplateActionsTemplateLink" + item?.key,
                key: 'sendTemplatelink',
                text: t('text.linkWebform', { ns: 'workspace'}),
                title: t('text.linkWebformTitle', { ns: 'workspace'}),
                onClick: () => {

                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setModalTitle("Send Application (" + item!.name + ")")
                    sendTemplateLink(item!)
                }
            },
            {
                id: "btnTemplateActionsTemplateUpdate" + item?.key,
                key: 'Updatetemplate',
                text: t('text.linkUpdate', { ns: 'workspace'}),
                title: t('text.linkUpdateTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setModalTitle(t('title.update', { ns: 'uploadtemplate'}) + " (" + item!.name + ")")
                    onItemUploadTemplate(item!)
                    setIsUpdate(true)
                },
            },
            {
                id: "btnTemplateActionsVersionTemplate" + item?.key,
                key: 'VersionTemplate',
                text: t('text.linkVersion', { ns: 'workspace'}),
                title: t('text.linkVersionTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setTemplateName(item!.name)
                    onItemVersionTemplate(item!)
                    setIsUpdate(true)
                },
            },
            {
                id: "btnTemplateActionsOrder" + item?.key,
                key: 'order',
                text: t('text.linkWizard', { ns: 'workspace'}),
                title: isMobile ? t('text.linkWizardMobileTitle', { ns: 'workspace'}) : t('text.linkWizardTitle', { ns: 'workspace'}),
                disabled: isMobile,
                onClick: () => {
                    setTemplateName(item!.name)
                    setTemplateId(item!.key!)
                    setCulture(item!.culture)
                    setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())
                    setOwnerName(item!.ownerFullName!)
                    setOwnerUser(item!.ownerUsername!)
                    setTemplateCategory(item!.category!)
                    setAudienceName(item!.audienceName!)
                    setEmailMoniker(item!.emailMoniker)
                    setTemplateEdit(item!.templateEdit)
                    setIsUpdate(true)
                    toggleFormBuilder(true)
                },
            },
            {
                id: "btnTemplateActionsRemove" + item?.key,
                key: 'Remove',
                text: t('text.linkRemove', { ns: 'workspace'}),
                title: t('text.linkRemoveTitle', { ns: 'workspace'}),
                onClick: () => {
                    setTemplateName(item!.name)
                    setModalTitle(t('title.modal', { ns: 'remove', Name: item!.name }))
                    setTemplateId(item!.key!)
                    setItemId(item!.key!)
                    setLayoutLoaded(false)
                    setSpinnerText(t('text.spinner', { ns: 'common'}))
                    onItemRemove(item!)
                },
            },

        ];
    }
    const templateMenuCreateDocument = (item?: INavLink): IContextualMenuItem[] => {
        return [
            {
                id: "btnTemplateActionsRemove" + item?.key,
                key: 'Remove',
                text: t('text.linkRemove', { ns: 'workspace'}),
                title: t('text.linkRemoveTitle', { ns: 'workspace'}),
                onClick: () => {
                    setLayoutLoaded(false)
                    setSpinnerText(t('text.spinner', { ns: 'common'}))
                    setTemplateName(item!.name)
                    setModalTitle(t('title.modal', { ns: 'remove', Name: item!.name }))
                    setTemplateId(item!.key!)
                    setItemId(item!.key!)
                    onItemRemove(item!)
                },
            }
        ];
    }

    const menuProps = (item?: INavLink): IContextualMenuProps => {
        return {
            shouldFocusOnMount: true,
            items: isMobile && sessionStorage.getItem("entityId") !== "Not In Teams" ? templateMenuMobile(item) : templateMenu(item)
        }
    };
    const menuPropsCoOwner = (item?: INavLink): IContextualMenuProps => {
        return {
            shouldFocusOnMount: true,
            items: isMobile && sessionStorage.getItem("entityId") !== "Not In Teams" ? templateMenuCoOwnerMobile(item) : templateMenuCoOwner(item)
        }
    };
    const menuPropsCreateDocument = (item?: INavLink): IContextualMenuProps => {
        return {
            shouldFocusOnMount: true,
            items: templateMenuCreateDocument(item)
        }
    };
    const onItemShare = (item: INavLink) => {
        const user = Helper.getUsername()

        setSpinnerText("")
        setShowShare(true)
        setDocumentOnlyAudienceId(item.DocumentOnlyAudienceId.toString())
        setTemplateName(item!.name)
        setTemplateId(item!.key!)
        setCulture(item!.culture)
        setOwnerName(item!.ownerFullName!)
        setOwnerUser(item!.ownerUsername!)
        setTemplateCategory(item!.category!)
        setAudienceName(item!.audienceName!)
        setEmailMoniker(item!.emailMoniker)
        setTemplateShare(item!.templateShare)
    }

    const onItemUploadTemplate = (item: INavLink) => {
        const user = Helper.getUsername()

        setSpinnerText("")
        setShowUploadTemplate(true)
        setDocumentOnlyAudienceId(item.DocumentOnlyAudienceId.toString())
        setTemplateName(item!.name)
        setTemplateId(item!.key!)
        setCulture(item!.culture)
        setOwnerName(item!.ownerFullName!)
        setOwnerUser(item!.ownerUsername!)
        setTemplateCategory(item!.category!)
        setAudienceName(item!.audienceName!)
        setEmailMoniker(item!.emailMoniker)
        setTemplateEdit(item!.templateEdit)
    }
    const onItemVersionTemplate = (item: INavLink) => {
        const user = Helper.getUsername()
        setModalTitle(item!.name)
        setSpinnerText("")
        setShowVersionTemplate(true)
        setDocumentOnlyAudienceId(item.DocumentOnlyAudienceId.toString())
        setTemplateName(item!.name)
        setTemplateId(item!.key!)
        setOwnerName(item!.ownerFullName!)
        setOwnerUser(item!.ownerUsername!)
        setTemplateCategory(item!.category!)
        setAudienceName(item!.audienceName!)
        setEmailMoniker(item!.emailMoniker)
        setTemplateEdit(item!.templateEdit)
    }
    const hideVersionTemplate = () => {

        setModalId("-1")
        setModalTitle("")
        setShowVersionTemplate(false)
    }
    const hideShare = () => {

        setSpinnerText("Loading content...")
        setModalId("-1")
        setModalTitle("")
        setShowShare(false)
        setCulture("")
    }
    const hideUploadTemplate = () => {

        setModalId("-1")
        setModalTitle("")
        setShowUploadTemplate(false)
        setCulture("")
    }

    const toggleFormBuilder = useConstCallback((open: boolean) => {
        const site = Helper.getSiteSelected()
        setSiteSelected(site)
        setShowFormBuilder(open)
    })

    const getContentControls = useConstCallback((cc: IContentControls[]) => {
        setContentControls(cc)

    })
    const getTemplateId = useConstCallback((templateId: string) => {
        setTemplateId(templateId)
    })
    const toggleCustomForm = useConstCallback((open: boolean) => {
        const site = Helper.getSiteSelected()
        setSiteSelected(site)
        if (open === true) {
            props.onUpdateInterval(false)
            setShowCustomForm(open)
        }
        else {
            props.onUpdateInterval(true)
            setShowCustomForm(open)
        }


    })
    const onLinkClick = async (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        setisOwner(item!.Owner)
        if (item!.activeSubscription || sessionStorage.getItem("billingActive") !== 'true') {

            setTemplateName(item!.name)
            setTemplateId(item!.key!)
            setOwnerName(item!.ownerFullName!)
            setOwnerUser(item!.ownerUsername!)
            setTemplateCategory(item!.category!)
            setAudienceName(item!.audienceName!)
            setiscoOwner(item!.CoOwner)
            setisOwner(item!.Owner)
            setisCreator(item!.CreateDocument)
            setCulture(item!.culture)
            setEmailMoniker(item!.emailMoniker)
            setDocumentCreation(item!.documentCreation)
            setDocumentUpdate(item!.documentUpdate)
            setDefaultDescription(item!.defaultDescription)
            toggleCustomForm(true)
        }

        var BillingActive = await getBillingActive()
        isOwnerTemplate = item!.Owner;
        setDraftId(-1)
        if (BillingActive) {
            if (item?.activeSubscription && sessionStorage.getItem("billingActive") === 'true') {

                setHideSubscriptionDialog(true)
            } else {
                var statusMessage = item?.SubscriptionStatusMessage != null && item?.SubscriptionStatusMessage != undefined ? item.SubscriptionStatusMessage : "Your subscription has expired"
                setSubscriptionMessage(statusMessage)
                setHideSubscriptionDialog(false)
            }
        }

    }
    const hideModal = () => {
        setshowDeleteModal(false)
        setCulture("")
        setTemplateId("")
        setModalTitle("")
        setshowText("")
    }
    const loadAudienceMasterId = async (DocumentOnlyAudienceMasterId: string) => {
        const req: IDocumentOnlyAudienceRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceMasterId: DocumentOnlyAudienceMasterId,
            FromRegistration: false
        }

        var data = await Helper.GetDocumentOnlyAudienceDetailByUserName(req)
        if (data !== undefined && data !== null) {
            var selected = data.filter(x => x.DocumentOnlyAudienceMasterId == Number(DocumentOnlyAudienceMasterId))
            console.log(selected)
            console.log(selected[0].Id)
            setAudienceDetail(selected[0].Id)
            setiscoOwner(selected[0].Owner)
            setisCreator(selected[0].CreateDocument)
            setLayoutLoaded(true)
            setSpinnerText("")
        }
    }

    const onCreateUpdate = (id: string) => {
        setModalId(id)
        setLayoutLoaded(false)
        setModalTitle(t('title.modal', { ns: 'preview'}))
        setSpinnerText(t('text.spinner', { ns: 'common'}))
        
        let jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            CultureName: "",
            Released: false,
            emailMoniker: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate:false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            attachment: {} as AttachmentSettings,
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        }

        const preview = async () => {

            setAllowDocx(false)
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id,
                IsPreview: true,
                IsDownload: false,
                IsDocx: false
            }
            
           await Helper.getDocumentOnlyTemplateId(templateId, true).then(data => {
               try {

                   jsonBag = JSON.parse(data.PropertyBag) as IPropertyBag
                   setShowPreview(!jsonBag.SupressPreview)
                   setAllowDocx(jsonBag.AllowDocX)
                   if (!jsonBag.SupressPreview) {
                       Helper.getDocumentPreview(req).then(res => {
                           if (res.Success) {
                               setLayoutLoaded(true)
                               setPreviewURL(res.UrlPath)
                               setPreviewMime(res.mimeType)
                           }
                       })
                    }
               }
               catch (reason) {
                   console.log(reason)
               }
            }).catch((reason)=> {
               console.log(reason)
           }).finally(()=> {
           })
        }

        props.update()
        preview()
        if (props.selectionState.getSelectedIndices().length >= 1)
            props.selectionState.setAllSelected(false);
    }

    const toggleEditCategories = (show: boolean) => {
        setShowEditCategories(show)
    }
    const hidePreview = () => {
        setShowPreview(false)
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalId("")
        setModalTitle("")
    }

    const hideCloneTemplate = () => {
        setShowCloneTemplate(false)
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalId("")
        setModalTitle("")
    }

    const hideExportTemplate = () => {
        setShowExportTemplate(false)
        setLayoutLoaded(false)
        setSpinnerText(t('text.spinner', { ns: 'common'}))
        setModalId("")
        setModalTitle("")
    }

    const hideRemove = () => {
        setShowRemove(false)
        setLayoutLoaded(false)
        setSpinnerText(t('text.spinner', { ns: 'common'}))
        setItemId("")
        setModalTitle("")
        setremoveMessage("")
        setCulture("")
        setLayoutLoaded(false)
    }

    const onItemRemove = async (item: INavLink) => {

        setDocumentOnlyAudienceId(item!.DocumentOnlyAudienceId.toString())

        setremoveMessage(t('messages.warning', { ns: 'remove'}))


        setShowRemove(true)
        setisOwner(item.Owner)
        setCulture(item!.culture)
        setiscoOwner(item.CoOwner)
        setisCreator(item.CreateDocument)
        setOwnerUser(item.ownerUsername)
        setTemplateRemove(item.templateRemove)
        setAudienceName(item.audienceName)
        setTemplateName(item.name)
        setTemplateCategory(item.category)
        setEmailMoniker(item.emailMoniker)
        console.log(item)

        await loadAudienceMasterId(item!.DocumentOnlyAudienceId.toString())
    }

    const confirmRemove = (id: string) => {
        const RemoveTemplate = async () => {
            let site = await Helper.getSite()
            const req: IDocumentOnlyAudienceDetailRequest = {
                UserName: Helper.getUsername(),
                SK: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                ApiKey: "-1",
                Autoprovision: true,
                Path: site,
                AudienceDetailId: audienceDetail.toString(),
                AudienceMasterId: documentOnlyAudienceId,
                Coowner: iscoOwner,
                TargetUserName: Helper.getUsername(),
                CreateDocument: isCreator,
                AudienceOwnerName: ownerUser,
                TemplateId: "-101"
            }

            await Helper.DeleteDocumentOnlyAudienceDetail(req).then(data => {
                if (data !== undefined && data !== null) {

                    if (templateRemove) {
                        Helper.SendNotification({
                            ActionByEmailAddress: Helper.getUsername(),
                            ActionDate: formatFromUTC(Date.now(), "ll" ,  'en' ),
                            AudienceName: audienceName,
                            AudienceOwnerEmailAddress: ownerUser,
                            RecordDescription: templateName,
                            TargetOfActionEmailAddress: "",
                            TemplateCategory: templateCategory,
                            TemplateName: templateName,
                            Culture: culture,
                            Type: "8",
                            UserName: Helper.getUsername(),
                            SK: Helper.getSessionKey(),
                            AccountId: Helper.getDefaultAccount(),
                            Path: site,
                            Autoprovision: true,
                            ApiKey: "-1",
                            EmailMoniker: emailMoniker,
                            TemplateId: templateId
                        })
                    }
                    props.reloadApp(site)
                    hideRemove()
                }
            });

        }
        RemoveTemplate()
    }
    function DownloadDoc() {
        setShowConfirmationModal(false)
        const download = async () => {
            const req: IDownloadDocumentOnlyTemplateRequest = {
                ApiKey: "-1",
                Autoprovision: true,
                TemplateId: parseInt(docSelected),
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                Path: await Helper.getSite(),
            }
            var res = await Helper.downloadDocumentOnlyTemplate(req).then(data => {
                const decoded = decode(data.TemplateContent)
                try {
                    const file = new Blob([decoded],)
                    FileDownload(file, data.FileName);
                    showNotification(t('message.success', { ns: 'download'}), "success")
                } catch (error) {
                    showNotification(t('message.error', { ns: 'download', Error: data.ExceptionMessage }) , "error")
                }
            })

        }
        download()
    }
    const hideConfirmationModal = () => {

        setShowConfirmationModal(false)

    }


    const sendTemplateLink = (item: INavLink) => {
        setshowFormTemplateLink(true)
        setEmailMoniker(item!.emailMoniker)
        setDocumentOnlyAudienceId(item.DocumentOnlyAudienceId.toString())
        setOwnerName(item!.ownerFullName!)
        setOwnerUser(item!.ownerUsername!)
        setTemplateCategory(item!.category!)
        setAudienceName(item!.audienceName!)
        setEmailMoniker(item!.emailMoniker)
        setTemplateEdit(item!.templateEdit)
    }
    const hideFormTemplateLink = () => {
        setshowFormTemplateLink(false)       
        setTemplateName("")
        setTemplateId("")
    }
    const getBillingActive = async (): Promise<boolean> => {
        var site = await Helper.loadSites()
        return site !== null? site.Billing : false
    }
    const hideModalSubscription = useConstCallback(() => {

        setHideSubscriptionDialog(true)
        var isWeb = (!isMobile && sessionStorage.getItem("entityId") === "Not In Teams")
        if (isWeb) {
            props.onIsOwner(isOwnerTemplate)
            props.onSubscriptionSettings(isOwnerTemplate)
        }
    })
    return (
        <>
            <div className="ms-Grid-row">
                <Stack id="categoryList" title={t('title.categoryList', {ns: 'workspace'})} tokens={stackTokens}>
                    <Nav
                        onLinkClick={onLinkClick}
                        ariaLabel={t('label.myTemplates', {ns: 'workspace'})}
                        groups={props.templateOnlyList}
                        onRenderLink={onRenderLink}
                        styles={navStyles}
                    />
                </Stack>
            </div>

            {showCampaignList && 
            <CampaignList
                showCampaignList={showCampaignList}
                hideCampaignList={hideCampaignList}
                templateId={templateId}
                DocumentOnlyAudienceId={documentOnlyAudienceId}
                OwnerUser={ownerUser}
            />
            }

            <EditCategoriesModal
                showEditCategories={showEditCategories}
                toggleEditCategories={toggleEditCategories}
                reloadApp={props.reloadApp}
            />

            <ShareTemplate
                showModal={showShare}
                showShare={showShare}
                culture={culture}
                hideModal={hideShare}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                TemplateId={templateId}
                DocumentOnlyAudienceId={documentOnlyAudienceId}
                templateName={templateName}
                ownerName={ownerName}
                ownerUser={ownerUser}
                isOwner={isOwner}
                isCoOwner={iscoOwner}
                templateCategory={templateCategory}
                audienceName={audienceName}
                emailMoniker={emailMoniker}
                templateShare={templateShare}
            />

            {showCustomForm && <CustomForm
                allowDrafts={true}
                draftId={draftId}
                setDraftId={setDraftId}
                showCustomForm={showCustomForm}
                toggleCustomForm={toggleCustomForm}
                siteOptions={props.siteOptions}
                siteSelected={siteSelected}
                accountId={Helper.getDefaultAccount()}
                targetUser={Helper.getUsername()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                templateId={templateId}
                templateName={templateName}
                update={onCreateUpdate}
                isCreator={isCreator}
                isOwner={isOwner}
                isCoOwner={iscoOwner}
                culture={culture}
                ownerName={ownerName}
                ownerUser={ownerUser}
                templateCategory={templateCategory}
                audienceName={audienceName}
                emailMoniker={emailMoniker}
                documentCreation={documentCreation}
                documentUpdate={documentUpdate}
                templateEdit={templateEdit}
                defaultDescription={defaultDescription}
                toggleQueueModal={toggleQueueModal}
                updateDocumentList={props.update}
            />}
            <CustomTemplate showFormBuilder={showFormBuilder}
                toggleFormBuilder={toggleFormBuilder}
                siteOptions={props.siteOptions}
                siteSelected={siteSelected}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                reloadApp={props.reloadApp}
                contentControls={props.contentControls}
                templateId={templateId}
                templateName={templateName}
                isUpdate={isUpdate}
                DocumentOnlyAudienceId={documentOnlyAudienceId}
                culture={culture}
                ownerName={ownerName}
                ownerUser={ownerUser}
                templateCategory={templateCategory}
                audienceName={audienceName}
                emailMoniker={emailMoniker}
                templateEdit={templateEdit}
            />
            <FormDeleteTemplate
                modalTitle={modalTitle}
                templateId={templateId}
                modalTitleId={templateId}
                showText={showText}
                showDeleteModal={showDeleteModal}
                hideModal={hideModal}
                reloadApp={props.reloadApp}
                ownerName={ownerName}
                ownerUser={ownerUser}
                culture={culture}
                templateCategory={templateCategory}
                audienceName={audienceName}
                emailMoniker={emailMoniker}
                templateDelete={templateDelete}
                templateName={templateName}

            />
            <PreviewScreen
                showModal={showPreview}
                hideModal={hidePreview}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                URLPath={previewURL}
                mimeType={previewMime}
                documentId={documentId}
                allowDocX={allowDocx}
            />
            <QueueModal Modal={QueueCustomModal.Modal} close={QueueCustomModal.close} isUpdate={isRecordUpdate} isLink={false} isError={isRecordError}/>
            <UploadTemplate
                showUploadTemplate={showUploadTemplate}
                siteOptions={props.siteOptions}
                hideUploadTemplate={hideUploadTemplate}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                TemplateId={templateId}
                DocumentOnlyAudienceId={documentOnlyAudienceId}
                reloadApp={props.reloadApp}
                getContentControls={getContentControls}
                getTemplateId={getTemplateId}
                toggleFormBuilder={toggleFormBuilder}
                templateName={templateName}
                setTemplateName={setTemplateName}
                siteSelected={props.siteSelected}
                IsUpdate={setIsUpdate}
                ownerName={ownerName}
                ownerUser={ownerUser}
                templateCategory={templateCategory}
                audienceName={audienceName}
                emailMoniker={emailMoniker}
                templateEdit={templateEdit}
                culture={culture}
            />
            <CloneTemplate
                showCloneTemplate={showCloneTemplate}
                siteOptions={props.siteOptions}
                siteSelected={props.siteSelected}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                templateId={templateId}
                templateName={templateName}
                hideCloneTemplate={hideCloneTemplate}
                reloadApp={props.reloadApp}
            />
            <ExportTemplate
                showExportTemplate={showExportTemplate}
                siteOptions={props.siteOptions}
                siteSelected={props.siteSelected}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                templateId={templateId}
                templateName={templateName}
                hideExportTemplate={hideExportTemplate}
                notificartions={showNotification}
                reloadApp={props.reloadApp}
            />
            <Confirmation
                showModal={showRemove}
                hideModal={hideRemove}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                id={itemId}
                message={removeeMessage}
                confirmDelete={confirmRemove}
                actionText={t('Common.Button.Remove', { ns: 'common'})}
            />

            <FormTemplateLink 
                showModal={showFormTemplateLink}
                onDismissModal={hideFormTemplateLink} 
                templateId={templateId} 
                name={templateName}
                documentOnlyPrimaryId={-1}
                ownerUser={ownerUser}
            />

            <VersionTemplate
                showVersionTemplate={showVersionTemplate}
                siteOptions={props.siteOptions}
                hideVersionTemplate={hideVersionTemplate}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                TemplateId={templateId}
                DocumentOnlyAudienceId={documentOnlyAudienceId}
                reloadApp={props.reloadApp}
                getContentControls={getContentControls}
                getTemplateId={getTemplateId}
                toggleFormBuilder={toggleFormBuilder}
                templateName={templateName}
                setTemplateName={setTemplateName}
                siteSelected={props.siteSelected}
                IsUpdate={setIsUpdate}
                ownerName={ownerName}
                ownerUser={ownerUser}
                templateCategory={templateCategory}
                audienceName={audienceName}
                emailMoniker={emailMoniker}
                templateEdit={templateEdit}
            />
            <Modal
                isOpen={showConfirmationModal}
                isBlocking={true}
                containerClassName={classNames.containerSaveModal}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={classNames.header}>
                                <span id="Confirmation">{t('title.modal', { ns: 'download'})}</span>
                                <IconButton
                                    styles={iconButtonStylesPrompt}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                    onClick={hideConfirmationModal}
                                    title={t('Common.Button.Close', { ns: 'common'})}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg12">
                            <div className={classNames.bodyMail}>
                                {t('text.confirmation', { ns: 'download'})}
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={DownloadDoc} text={t('Common.Button.Yes', { ns: 'common'})} title={t('Common.Button.Yes', { ns: 'common'})} />
                                <DefaultButton onClick={hideConfirmationModal} text={t('Common.Button.No', { ns: 'common'})} title={t('Common.Button.No', { ns: 'common'})} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
            <Dialog
                hidden={hideSubscriptionDialog}
                dialogContentProps={dialogSubscriptionContentProps}
                modalProps={modalProps}
            >
                {(dialogSubscriptionContentProps.subText.indexOf('past due') >= 0 && isOwnerTemplate) ? "You will be redirected to Billing Plan where a plan can be purchased." : ""}
                {(dialogSubscriptionContentProps.subText.indexOf('storage') >= 0 && isOwnerTemplate) ? "You will be redirected to Billing Plan where you can upgrade your plan." : ""}
                {(dialogSubscriptionContentProps.subText.indexOf('submission limit has been met') >= 0 && isOwnerTemplate) ? "You will be redirected to Billing Plan where you can upgrade your plan." : ""}
                <DialogFooter>
                    <PrimaryButton onClick={hideModalSubscription} text="Ok" />
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default TemplateList