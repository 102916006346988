import React from "react";
import {
    IAudienceIntersectRequest,
    IAudienceIntersectResponse,
    IEmailServiceRequest,
    IEmailServiceResponse,
    IGetDocumentOnlyAttachmentByDocumentIdRequest,
    IGetDocumentOnlyAttachmentByDocumentIdResponse,
    IgetDocumentOnlyTemplateIDBasicRequest,
    ISendLinkDocumentRequest,
    ISendLinkDocumentResponse,
} from ".";
import HttpRequest from "../../services/HttpRequest";

export const FormDocumentLinkService = {
    
    /**
     * GET AudienceIntersect
     * @param request
     * @constructor
     */
    GetAudienceIntersect: async (request: IAudienceIntersectRequest
    ): Promise<IAudienceIntersectResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('AudienceIntersect', request)

        return new Promise<IAudienceIntersectResponse[]>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },
    /**
     * GET GetDocumentOnlyAttachmentByDocumentId
     * @param request
     * @constructor
     */
    GetDocumentOnlyAttachmentByDocumentId: async (request: IGetDocumentOnlyAttachmentByDocumentIdRequest
    ): Promise<IGetDocumentOnlyAttachmentByDocumentIdResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('GetDocumentOnlyAttachmentByDocumentId', request)

        return new Promise<IGetDocumentOnlyAttachmentByDocumentIdResponse[]>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },
    /**
     * POST SendLinkDocument
     * @param request
     * @constructor
     */
    PostSendLinkDocument: async (request: ISendLinkDocumentRequest
    ): Promise<ISendLinkDocumentResponse[]> => {
        let {data}: any = await HttpRequest.postWithSession('SendLinkDocument', request)

        return new Promise<ISendLinkDocumentResponse[]>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },
    /**a
     * GET GetDocumentOnlyTemplateIDBasic
     * @param request
     * @constructor
     */
    GetDocumentOnlyTemplateIDBasic: async (request: IgetDocumentOnlyTemplateIDBasicRequest
    ): Promise<any> => {
        let {data}: any = await HttpRequest.getWithSession('getDocumentOnlyTemplateIDBasic', request)

        return new Promise<any>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    }



    
    

}

