import React, { FC, useCallback, useMemo } from "react";
import { useState } from "react";
import CustomDialog, { CustomDialogProps } from "../share/CustomDialog";

export const useDialog = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<(() => void) | undefined>(undefined)

    const open = ({ confirm }: { confirm?: (() => void) | undefined }) => {
        setIsOpen(true);
        setConfirm(confirm)

    }
    const close = (afterClose?: Function) => {
        if (typeof afterClose === 'function') {
            afterClose()
        }
        setIsOpen(false)
    }

    const Dialog: FC<CustomDialogProps> = useCallback(({ title, titleId, children, actionText, showAction,closeText }: CustomDialogProps) =>
    (<CustomDialog title={title} titleId={titleId}
        close={() => { close(); }} isOpen={isOpen} closeText={closeText}
        children={children} actionText={actionText} showAction={showAction} confirm={confirm} />), [confirm, isOpen])

    return useMemo(() => ({ isOpen, Dialog, open, close }), [Dialog, isOpen])



}