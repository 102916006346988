import { ProfileType } from "../context/AppContext"
import Secure from "../Helpers/Crypto"
import Helper, { IAuthenticateRequest, Registration, RegistrationStatus } from "../Helpers/Helper"
import { Auth0Claims } from "../interfaces/IApp"

import HttpRequest from "./HttpRequest"
type ValidateIssuerRequest = {
    orgId?: string,
    templateId?: string,
    owner?: string,
    onBehalf?: string
}
const AuthService = {

    isAuthenticateExists: async () => {
        const session = sessionStorage.getItem("x-identity")
        return session !== null && session.trim().length > 0;
    },

    getAuthInitialData: async () => {
        const site = await Helper.getSite()!
        const user = {
            ApiKey: "-1",
            Username: Helper.getUsername(),
            User: Helper.getUsername(),
            SK: Helper.getSessionKey(),

            AccountId: Helper.getDefaultAccount(),
            Token: window.sessionStorage.getItem("authToken"),
            Site: site,
            Path: site,
            Autoprovision: true

        }
        return user
    },
    authenticate: async (username: string, site: string) => {
        try {
            const x_Identity: Record<string, string> = { "x-identity": sessionStorage.getItem("x-identity")!.toString() }

            var urlVerify = `Verify?path=${site}`
            const codeRaw = await fetch(urlVerify, {
                headers: {
                    ...x_Identity,
                }
            })
            const verify = await codeRaw.text()

            var data = Secure.encrypt(username, verify)
            var username64 = btoa(username);
            var identity = btoa(`${username64}.${data}.${verify}`)

            const request: IAuthenticateRequest = {
                Identity: identity,
                Path: site,
                Autoprovision: true
            }

            var url = `Authenticate`
            const raw = await fetch(url, {
                method: "POST",
                headers: {
                    ...x_Identity,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            })
            const result = await raw.json()
            if (result.hasOwnProperty('SessionKey')) {
                const accoutnId = Helper.getDefaultAccount()
                const sessionKey = AuthService.getSessionKey()
                return { Status: RegistrationStatus.Registered, SessionKey: sessionKey, AccountId: accoutnId, Message: "" }
            } else if (result.hasOwnProperty('ErrorCode')) {
                switch (result.ErrorCode) {
                    case "404":
                        return { Status: RegistrationStatus.NotRegistered, SessionKey: "", AccountId: "", Message: result.ExceptionMessage }
                    case "405":
                        return { Status: RegistrationStatus.ValidationNeeded, SessionKey: "", AccountId: "", Message: result.ExceptionMessage }
                    case "415":
                        return { Status: RegistrationStatus.AuthCode, SessionKey: "", AccountId: "", Message: result.ExceptionMessage }
                    case "406":
                    case "407":
                    case "410":
                    case "411":
                    case "412":
                    case "420":
                    case "421":
                    case "422":
                    case "423":
                    case "425":
                    case "500":
                        return { Status: RegistrationStatus.Error, SessionKey: "", AccountId: "", Message: result.ExceptionMessage }
                    default:
                        throw new Error('No Error Code Found');
                }
            }
        } catch (e: any) {
            throw new Error(e)
        }
    },
    getUsername: () => {
        return sessionStorage.getItem("username")!;
    },

    getSessionKey: () => {
        return "-1";
    },
    getCaptcha: async (TargetUser: string) => {
        return await HttpRequest.getWithSession('Captcha', { TargetUser })
    },
    validateCaptcha: async (request: CaptchaRequest) => {
        return await HttpRequest.getWithSession('Captcha/Verify', request)
    },
    validateIssuer: async (request: ValidateIssuerRequest) => {
        return await HttpRequest.getWithSession('validateIssuer', request)
    },
    validateSSO: async ({ TemplateId, UserName, Guid }: { TemplateId: string, UserName: string, Guid?: string }) => {
        const { data }: any = await HttpRequest.getWithSession('Validate/SSO', { TargetUser: UserName, TemplateId, UserName, guid: Guid })
        return new Promise<ProfileType>((resolve, reject) => {
            if (data.success) {
                return resolve(JSON.parse(data.result) as ProfileType)
            }
            return reject(data)
        })
    },
    validateUser: async ({ TemplateId, UserName, TargetUser }: { TemplateId: string, TargetUser: string, UserName: string }) => {
        return await HttpRequest.getWithSession('ValidateUser', { TargetUser, TemplateId, UserName })
    },
    SAMLProvider: async ({ Provider }: { Provider?: string }) => {
        return await HttpRequest.getWithSession('Saml/Provider', {Provider})
    },
    SAMLValidate: async ({ }) => {
        return await HttpRequest.getWithSession('Saml/Validate', {})
    },
    SAMLCreate: async ({ Provider }: { Provider?: string }) => {
        let request = {
            Provider: Provider
        }
        return await HttpRequest.postWithSession('Saml/Create', request)
    }
}
export interface CaptchaRequest {
    OnBehalfUser: string
    LoggedUser: string
    TemplateId: string
    UserFirstName?: string
    UserLastName?: string
    CaptchaGuid?: string
    CaptchaValue?: string
}
export default AuthService