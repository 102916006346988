import HttpRequest from "./HttpRequest";
import {IHttpClientResponseBag} from "../Helpers/Helper";

export interface ICancelSubscriptionRequest {
    SubscriptionId: string
    OwnerName: string
    PlanName: string
    BillingPlanId: number
}

export interface IUpdateSubscriptionRequest {
    subscriptionId: string
    PriceId: string
}

const PaymentService = {

    /**
     * cancelSubscription
     * @param request
     * @constructor
     */
    cancelSubscription: async (request: ICancelSubscriptionRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('payment/cancelSubscription', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject({err: `Server error: ${data.ExceptionMessage !== undefined ? data.ExceptionMessage : ""}` })
            else resolve(data)
        })
    },

    /**
     * updateSubscription
     * @param request
     * @constructor
     */
    updateSubscription: async (request: IUpdateSubscriptionRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('payment/updateSubscription', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject({err: 'Server error'})
            else resolve(data)
        })
    },


}

export default PaymentService