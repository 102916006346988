import { set } from 'lodash';
import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IMyListOption } from "../../../../../Helpers/Helper";
import { ActionMeta, SingleValue } from "react-select";
import { useFormContext, UseFormSetValue, useWatch } from "react-hook-form";

type Props = {
    id: string
    options: IDropdownOption[]
    defaultValue: any
}
const mapDropdownOptions = (options: IDropdownOption[]): IMyListOption[] => {
    return options
        ? options.map((option) => {
            return { value: option.key?.toString(), label: option?.text };
        })
        : [];
};
const mapDropdownValues = (newValue: SingleValue<IMyListOption>): IDropdownOption => {
    return { key: newValue?.value!, text: newValue?.label! };
};
const mapDefaultValue = (option: IDropdownOption | string, options: IMyListOption[]): SingleValue<IMyListOption> | undefined => {
    if (!option) {
        return undefined
    }
    
    if (typeof option === "string" && option.trim().length === 0) {
        return undefined
    }

    if (typeof option === "string") {
        const _option = options.find(o => o.label.trim() === option.trim())
        return _option ?? { value: option, label: option }
    }
    if (option.key === undefined && option.text !== undefined) {
        return { value: option.text, label: option.text }
    }
    return { value: option.key?.toString(), label: option?.text };
}
export const useSelectSingleControl = ({ id, options: _options, defaultValue }: Props) => {

    const { getValues, setValue } = useFormContext<any>()
    const value = useWatch<any>({ name: id })
    //const value: any = useMemo(() => { getValues(id) }, [id])
    const options = mapDropdownOptions(_options)
    const [selectedOption, setSelectedOption] = useState<SingleValue<IMyListOption> | undefined>(() => {
        return value
            ? mapDefaultValue(value, options)
            : defaultValue
    })

    

    const onChangeDropdown = useCallback((
        newValue: SingleValue<IMyListOption>) => {
        const value = newValue ? mapDropdownValues(newValue) : undefined
        setSelectedOption(newValue)
        setValue(id, value)

    }, [id, setValue])

    useEffect(() => {
        setSelectedOption(mapDefaultValue(value, options))
    }, [value, id])

    return {
        options,
        selectedOption,
        mapDropdownOptions,
        mapDropdownValues,
        mapDefaultValue,
        onChangeDropdown,
    }
}
