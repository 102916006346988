import { Modal, Stack, FontWeights, getTheme, mergeStyleSets, PrimaryButton, IconButton, Label, SpinnerSize, Spinner } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import Helper from "../../Helpers/Helper";
import { RHFTextBoxControl } from "../RHFControls/RHFTextBoxControl";
import OutboundServices from "./Services/OutboundServices";
import { ConfigurationTemplateModel, CreateUpdateConfigurationTemplate } from "./Models/ConfigurationTemplateModel";
import RichTextEditor, { EditorValue } from "react-rte";
import { DEFAULT_RICHTOOLBAR } from "../../Helpers/Utils";
import { EventModel } from "./Models/EventModel";
import { useBoolean } from "@fluentui/react-hooks";
import {useBranding} from "../../hooks/useBranding";
//https://codesandbox.io/s/nelec?file=/src/StyleList.tsx

interface ConfigurationTemplateProps {
    event: React.MutableRefObject<EventModel | undefined>
    hideConfigurationTemplateModal: () => void;
    showSuccessMessage: (message: string) => void;
    showErrorMessage: (message: string) => void;
    configurationTemplateToUpdate?: React.MutableRefObject<ConfigurationTemplateModel | undefined> | undefined;
    reloadConfigurationTemplates?: () => Promise<void>
}

const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    
};

function ConfigurationTemplate(props: ConfigurationTemplateProps) {
    const { branding } = useBranding()
    const [editorState, setEditorState] = useState<EditorValue>(EditorValue.createEmpty())
    const [isLoadingVisible, { setTrue: showLoading, setFalse: hideLoading }] = useBoolean(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
            },
        ],
        body: {
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            overflowY: "hidden",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
    });

    const configurationTemplateForm = useForm<any, any>({
        reValidateMode: "onSubmit",
        mode: "all",
        defaultValues: {},
        shouldUnregister: false,
    });

    useEffect(() => {
        configurationTemplateForm.register("Name", { required: "Name is required." });
        configurationTemplateForm.register("Description", { required: "Description is required." });
        configurationTemplateForm.register("Category", { required: "Category is required." });
        configurationTemplateForm.register("Instructions", { required: "Instructions are required." });
    }, []);

    useEffect(() => {
        if (props.configurationTemplateToUpdate !== undefined && props.configurationTemplateToUpdate!.current !== undefined) {
            loadConfigurationTempalteValues();
        }
    }, [props.configurationTemplateToUpdate]);

    const showLoadingMessage = (message: string) => {
        setLoadingMessage(message)
        showLoading()
    }

    const loadConfigurationTempalteValues = () => {
        configurationTemplateForm.setValue("Name", props.configurationTemplateToUpdate!.current!.Name);
        configurationTemplateForm.setValue("Description", props.configurationTemplateToUpdate!.current!.Description);
        configurationTemplateForm.setValue("Category", props.configurationTemplateToUpdate!.current!.Category);

        setEditorState(() => {
            return EditorValue.createFromString(props.configurationTemplateToUpdate!.current!.Instructions, 'html')
        })
        configurationTemplateForm.setValue('Instructions', props.configurationTemplateToUpdate!.current!.Instructions)
    };

    const hideConfigurationTemplateModal = () => {
        configurationTemplateForm.reset()
        props.hideConfigurationTemplateModal()
        if (props.configurationTemplateToUpdate !== undefined && props.configurationTemplateToUpdate!.current !== undefined) {
            props.configurationTemplateToUpdate!.current = undefined
        }

        props.event!.current = undefined
    };

    const CreateConfigurationTemplate = () => {
        showLoadingMessage("Creating Configuration Template")
        configurationTemplateForm.handleSubmit(
            async (data) => {
                let currentEvent = props.event!.current;
                currentEvent!.TemplateId = 0;
                currentEvent?.Integrations.forEach(item => {
                    item.TemplateId = 0;
                    item.ObjectAuthenticationProperties.ObjectBasicToken.Password = "";
                    item.ObjectAuthenticationProperties.ObjectBasicToken.Username = "";
                    item.ObjectAuthenticationProperties.BearerToken = "";
                    item.ObjectAuthenticationProperties.OAuth20Prop.AccessToken = "";
                    item.ObjectAuthenticationProperties.OAuth20Prop.ClientId = "";
                    item.ObjectAuthenticationProperties.OAuth20Prop.ClientSecret = "";
                    item.ObjectAuthenticationProperties.OAuth20Prop.RefreshToken = "";
                    item.ObjectAuthenticationProperties.OAuth20Prop.AssertionCertPassword = "";
                    item.ObjectAuthenticationProperties.OAuth20Prop.AssertionFileName = "";
                    item.ObjectAuthenticationProperties.OAuth20Prop.new_token = "";
                    item.ObjectAuthenticationProperties.OAuth20Prop.Password = "";
                    item.ObjectAuthenticationProperties.OAuth20Prop.Username = "";
                    item.ObjectAuthenticationProperties.BasicToken = "";
                    item.ObjectMapInput.MapInput.Params.ObjectSurrogateFilter.forEach(surrogate => {
                        surrogate.IntegrationId = 0;
                        surrogate.TemplateId = 0;
                    })
                })

                const configurationTemplate: CreateUpdateConfigurationTemplate = {
                    AccountId: Helper.getDefaultAccount(),
                    ApiKey: "-1",
                    Autoprovision: true,
                    ConfigurationTemplate: {
                        Active: true,
                        Category: configurationTemplateForm.getValues("Category"),
                        Description: configurationTemplateForm.getValues("Description"),
                        Id: 1,
                        Instructions: configurationTemplateForm.getValues("Instructions"),
                        IntegrationConfig: "",
                        ObjectIntegrationConfig: currentEvent!,
                        Name: configurationTemplateForm.getValues("Name")
                    },
                    Path: await Helper.getSite(),
                    SK: Helper.getSessionKey(),
                    UserName: Helper.getUsername()
                }
                OutboundServices.CreateConfigurationTemplate(configurationTemplate).then((res) => {
                    if (res.Success !== undefined && res.Success !== null && res.Success === true) {
                        props.showSuccessMessage("Configuration Template has been saved successfully.");
                        hideConfigurationTemplateModal();
                    } else {
                        let errorMessage = res.ExceptionMessage !== undefined && res.ExceptionMessage !== "" ? res.ExceptionMessage : "Something unexpected happened.";
                        props.showErrorMessage("Error: " + errorMessage);
                    }
                    hideLoading()
                })
            },
            (errors) => {
                props.showErrorMessage(`Error: ${Object.values(errors).map((item: any) => item.message).join("|")}`);
                hideLoading()
            }
        )();
    };

    const UpdateConfigurationTemplate = () => {
        showLoadingMessage("Updating Configuration Template")
        configurationTemplateForm.handleSubmit(
            async (data) => {
                OutboundServices.GetConfigurationTemplateById({
                    AccountId: Helper.getDefaultAccount(),
                    ApiKey: "-1",
                    Autoprovision: true,
                    Id: props.configurationTemplateToUpdate!.current!.Id,
                    Path: await Helper.getSite(),
                    SK: Helper.getSessionKey(),
                    UserName: Helper.getUsername()
                }).then(async (res) => {
                    const configurationTemplate: CreateUpdateConfigurationTemplate = {
                        AccountId: Helper.getDefaultAccount(),
                        ApiKey: "-1",
                        Autoprovision: true,
                        ConfigurationTemplate: {
                            Active: true,
                            Category: configurationTemplateForm.getValues("Category"),
                            Description: configurationTemplateForm.getValues("Description"),
                            Id: props.configurationTemplateToUpdate!.current!.Id,
                            Instructions: configurationTemplateForm.getValues("Instructions"),
                            IntegrationConfig: "",
                            ObjectIntegrationConfig: res[0].ObjectIntegrationConfig,
                            Name: configurationTemplateForm.getValues("Name")
                        },
                        Path: await Helper.getSite(),
                        SK: Helper.getSessionKey(),
                        UserName: Helper.getUsername()
                    }
                    OutboundServices.UpdateConfigurationTemplate(configurationTemplate).then((res) => {
                        if (res.Success !== undefined && res.Success !== null && res.Success === true) {
                            props.showSuccessMessage("Configuration Template has been updated successfully.");
                            hideConfigurationTemplateModal();
                            if (props.reloadConfigurationTemplates !== undefined)
                                props.reloadConfigurationTemplates();

                        } else {
                            let errorMessage = res.ExceptionMessage !== undefined && res.ExceptionMessage !== "" ? res.ExceptionMessage : "Something unexpected happened.";
                            props.showErrorMessage("Error: " + errorMessage);
                        }
                        hideLoading()
                    })
                })
            },
            (errors) => {
                props.showErrorMessage(`Error: ${Object.values(errors).map((item: any) => item.message).join("|")}`);
                hideLoading()
            }
        )();
    };

    const handleRichTextChange = (value: EditorValue) => {
        configurationTemplateForm.setValue('Instructions', value.toString('html'))
        setEditorState(value)
    }

    return (
        <>
            <Modal
                isOpen={true}
                onDismiss={hideConfigurationTemplateModal}
                isBlocking={true}
                containerClassName={contentStyles.container}
                styles={{ main: { width: "50%", height: "400px" } }}
            >
                <FormProvider {...configurationTemplateForm}>
                    <div className={contentStyles.header}>
                        <div>Save Configuration as Template</div>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: "Cancel" }}
                            ariaLabel="Close popup modal"
                            onClick={hideConfigurationTemplateModal}
                        />
                    </div>
                    {isLoadingVisible ?
                        <Spinner styles={{ root: { zIndex: "1" } }} size={SpinnerSize.large} label={loadingMessage} /> :
                        <div className={contentStyles.body}>
                            <Stack
                                horizontal={true}
                                wrap={true}
                                disableShrink={true}
                                horizontalAlign={"start"}
                                verticalAlign={"start"}
                                styles={{ root: { marginTop: 0 } }}
                                tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                            >
                                <Stack.Item grow={2}>
                                    <RHFTextBoxControl
                                        id={"Name"}
                                        label={"Name"}
                                        maxLength={100}
                                        disabled={props.configurationTemplateToUpdate?.current !== undefined ? true : false}
                                        title="Enter configuration template name."
                                        control={configurationTemplateForm.control}
                                        setValue={configurationTemplateForm.setValue}
                                        getValues={configurationTemplateForm.getValues}
                                        trigger={configurationTemplateForm.trigger}
                                        defaultValue={""}
                                        required={true}
                                    />
                                </Stack.Item>
                                <Stack.Item grow={3}>
                                    <RHFTextBoxControl
                                        id={"Description"}
                                        label={"Description"}
                                        maxLength={100}
                                        disabled={false}
                                        title="Enter configuration template name."
                                        control={configurationTemplateForm.control}
                                        setValue={configurationTemplateForm.setValue}
                                        getValues={configurationTemplateForm.getValues}
                                        trigger={configurationTemplateForm.trigger}
                                        defaultValue={""}
                                        required={true}
                                    />
                                </Stack.Item>
                                <Stack.Item grow={2}>
                                    <RHFTextBoxControl
                                        id={"Category"}
                                        label={"Category"}
                                        maxLength={100}
                                        disabled={props.configurationTemplateToUpdate?.current !== undefined ? true : false}
                                        title="Enter configuration template category."
                                        control={configurationTemplateForm.control}
                                        setValue={configurationTemplateForm.setValue}
                                        getValues={configurationTemplateForm.getValues}
                                        trigger={configurationTemplateForm.trigger}
                                        defaultValue={""}
                                        required={true}
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack
                                horizontal={true}
                                wrap={true}
                                disableShrink={true}
                                horizontalAlign={"start"}
                                verticalAlign={"start"}
                                styles={{ root: { marginTop: 0 } }}
                                tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}
                            >
                                <Stack.Item grow={5}>
                                    <Label required={true}>Instructions</Label>
                                    <Controller name={"Instructions"} control={configurationTemplateForm.control}
                                        render={({ field }) => {
                                            return (<RichTextEditor editorClassName="richText" className="richText" toolbarConfig={DEFAULT_RICHTOOLBAR} value={editorState} onChange={handleRichTextChange} />)
                                        }} />
                                    {configurationTemplateForm.formState.errors !== undefined && configurationTemplateForm.formState.errors["Instructions"] !== undefined && configurationTemplateForm.formState.errors["Instructions"]!.message!.toString().length > 0 ?
                                        <span style={{ fontSize: "14px", color: "#973434", fontWeight: 600 }}>{configurationTemplateForm.formState.errors["Instructions"]!.message}</span>
                                        : null
                                    }
                                </Stack.Item>
                            </Stack>
                            <Stack styles={{ root: { marginTop: 0, float: "right" } }} tokens={{ childrenGap: "5 5", padding: "5px 5px 5px 5px" }}>
                                <Stack horizontal disableShrink>
                                    <Stack.Item align="start">
                                        {
                                            props.configurationTemplateToUpdate?.current !== undefined ?
                                                <PrimaryButton id="Update" onClick={UpdateConfigurationTemplate} text="Update" title="Update Configuration Template." /> :
                                                <PrimaryButton id="Save" onClick={CreateConfigurationTemplate} text="Save" title="Save Integration Template." />
                                        }
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </div>
                    }
                </FormProvider>
            </Modal>
        </>
    );
}

export default ConfigurationTemplate;