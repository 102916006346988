import Helper from "../../../Helpers/Helper";
import {
    ICertificateModel,
    ICreateCertificate,
    IGetCertificateRequest,
    IUpdateDeleteCertificate
} from "../Models/CertificateModels";
import HttpRequest from "../../../services/HttpRequest";

const InboundServices = {
    createCertificate: async (request: ICreateCertificate) => {
        const {data}: any = await HttpRequest.postWithSession('ApiIntegration/CreateCertificate', request)
        return data
    },
    updateCertificate: async (request: IUpdateDeleteCertificate) => {
        const {data}: any = await HttpRequest.postWithSession('ApiIntegration/UpdateCertificate', request)
        return data
    },
    deleteCertificate: async (request: IUpdateDeleteCertificate) => {
        const {data}: any = await HttpRequest.postWithSession('ApiIntegration/DeleteCertificate', request)
        return data
    },
    getCertificates: async (request: IGetCertificateRequest): Promise<ICertificateModel[]> => {
        const {data}: any = await HttpRequest.getWithSession('ApiIntegration/Certificates', request)

        return new Promise<ICertificateModel[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    }
}

export default InboundServices