import React, { FC, ReactElement } from 'react';
import '../Custom.css'
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { Modal } from '@fluentui/react/lib/Modal';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { DialogFooter, IDialogFooterStyles } from '@fluentui/react/lib/Dialog';
import { useTranslation } from "react-i18next";
import {useBranding} from "../hooks/useBranding";



const theme = getTheme();

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const dialogStyles: IDialogFooterStyles = {
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        bottom: 0,
        margin: 0
    },
    actionsRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        margin: '10px 8px 8px 8px',
    },
    action: {
        margin: '2px 4px'
    }
}
const cancelIcon: IIconProps = { iconName: 'Cancel' };
export type CustomDialogProps = {
    title: string
    titleId: string
    children: ReactElement | string,
    close?: () => void
    isOpen?: boolean
    actionText: string
    closeText?: string
    confirm?: any
    isDisabled?: boolean
    reversePrimary?: boolean
    showAction?: boolean
}

function CustomDialog({ title, titleId, children, close, isOpen, actionText, closeText, confirm, isDisabled = false, showAction = true, reversePrimary = false }: CustomDialogProps): JSX.Element {
    const { branding } = useBranding()
    const { t } = useTranslation(['common']);

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '30vw',
            maxWidth: '30vw',
            backgroundColor: branding.theme.palette.white
        },
        header: [
            theme.fonts.large,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0px 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        }
    });

    const _closeText = closeText ?? t('Common.Button.Cancel', { ns: 'common' })
    return (
        <Modal isOpen={isOpen} isBlocking={true} containerClassName={contentStyles.container}>
            <div className={contentStyles.header}>
                <span id={titleId}>{title}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                    onClick={close}
                />

            </div>

            <div className={contentStyles.body}>

                <div>
                    {children}

                </div>

            </div>
            <DialogFooter styles={dialogStyles}>
                {showAction &&
                    <>
                        {reversePrimary ?
                            <DefaultButton onClick={() => {
                                confirm?.()
                            }} disabled={isDisabled} text={actionText} title={actionText}/>
                            :
                            <PrimaryButton onClick={() => {
                                confirm?.()
                            }} disabled={isDisabled} text={actionText} title={actionText}/>
                        }
                    </>
                }
                {reversePrimary ?
                    <PrimaryButton onClick={close} text={_closeText} title={_closeText}/>
                    :
                    <DefaultButton onClick={close} text={_closeText} title={_closeText}/>
                }
            </DialogFooter>
        </Modal>
    )

}

export default CustomDialog