import React, {useEffect, useState} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {useBranding} from "../../hooks/useBranding";
import {Sizes} from "../../share/CustomModal";
import {useModal} from "../../hooks/useModal";
import {
    _styles,
    DocumentOnlyTemplateServices,
    EmailTemplateNotificationsServices,
    EmailType,
    ICreateUpdateTemplateDefinitionRequest,
    IDeleteTemplateDefinitionRequest,
    nonShrinkingStackItemStyles,
    onRenderOption,
    optionsToType,
    setDefaultOptions,
    toolbarConfigEmailBody,
    toolbarConfigEmailSubject,
    ToType
} from "./index";
import {RHFTextBoxControl} from "../RHFControls/RHFTextBoxControl";
import {SingleRHFRichTextControl} from "../RHFControls/SingleRHFRichTextControl";
import {
    ChoiceGroup,
    CommandBarButton,
    DefaultButton,
    IBasePickerSuggestionsProps,
    IChoiceGroupOption,
    IComboBoxOption,
    IContextualMenuProps,
    IPersonaProps,
    Label,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Separator,
    Shimmer,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem,
    Text,
    Toggle
} from "@fluentui/react";
import {FormProvider, useForm} from "react-hook-form";
import RichTextEditor, {EditorValue} from 'react-rte';
import RenewalsAndAlertsService from "../RenewalsAndAlerts/RenewalsAndAlerts.service";
import {ILayout} from "../CustomTemplates/CustomTemplate";
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import {removeHTMLTags} from "../RenewalsAndAlerts/RenewalsAndAlerts.data";
import CustomDialog from "../../share/CustomDialog";
import {RHFPeoplePickerControl} from "../RHFControls/RHFPeoplePickerControl";
import FormTemplateLinkService from "../TemplateLink/FormTemplateLink.service";

type Properties = {
    showModal: boolean
    setShowModal: (_emailType?: EmailType, onBeforeClose?: boolean, templateStatus?: boolean) => void | undefined
    templateId: string | undefined
    emailType: EmailType
}
const EmailTemplate = ({templateId, setShowModal, showModal, emailType}: Properties) => {

    const {t} = useTranslation(['common', 'emailtemplate'])
    const {branding} = useBranding()
    const myModal = useModal()
    const myModalPreview = useModal()
    const myForm = useForm();
    const [isLoadingData, setIsLoadingData] = useState(true)
    const [isLoadedData, setIsLoadedData] = useState(false)
    const [isErrorOnLoad, setIsErrorOnLoad] = useState(false)
    const [rteValue, setRteValue] = useState(RichTextEditor.createEmptyValue())
    const [rteValueSubject, setRteValueSubject] = useState(RichTextEditor.createEmptyValue())
    const [templateContentControls, setTemplateContentControls] = useState<IComboBoxOption[]>([])
    const [isSaving, setIsSaving] = useState(false)
    const [isNew, setIsNew] = useState(true)
    const [isActive, setIsActive] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [message, setMessage] = useState("")
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)
    const [applicationDisplayName, setApplicationDisplayName] = useState("")
    const [applicationCategory, setApplicationCategory] = useState("")
    const [toType, setToType] = useState<ToType>(ToType.DEFAULT)
    const [selectedPeopleList, setSelectedPeopleList] = useState<IPersonaProps[]>([])
    const [peopleList, setPeopleList] = useState<IPersonaProps[]>([])

    const [updateParent, setUpdateParent] = useState(false)
    const [statusParent, setStatusParent] = useState(false)
    const suggestionProps: IBasePickerSuggestionsProps = {
        mostRecentlyUsedHeaderText: t('mostRecentlyUsedHeaderText', {ns: 'emailtemplate'}),
        suggestionsContainerAriaLabel: t('suggestionsContainerAriaLabel', {ns: 'emailtemplate'}),
        suggestionsHeaderText: t('mostRecentlyUsedHeaderText', {ns: 'emailtemplate'}),
    };
    const setCustomError = (error: any, showTryAgain: boolean = false) => {
        showStatusMessage(t('message.error', {
            ns: 'common',
            Error: error?.error ? error.error.toString() : error.toString()
        }), StatusEnum.Error)
        if (showTryAgain) setIsErrorOnLoad(true)
    }
    const throwError = (mesage: string) => {
        throw new Error(mesage);
    };

    useEffect(() => {
        if (showModal) {
            myModal.open()
            onLoad().then()
        }
    }, [showModal])

    /**
     * Close modal
     * @param _updateParent
     * @param _statusParent
     */

    const closeModal = (_updateParent: boolean, _statusParent: boolean | undefined) => {
        myModal.close()
        setShowModal(emailType, _updateParent, _statusParent)
    }

    const onLoad = async () => {
        await initLoad().then()
    }

    /**
     * Load init
     */
    const initLoad = async () => {
        setMessage("")
        setShowMessage(false)
        setIsErrorOnLoad(false)
        setIsLoadingData(true)
        setIsLoadedData(false)
        setUpdateParent(false)
        setStatusParent(false)
        setIsSaving(false)
        setShowDelete(false)
        setPeopleList([])
        setToType(ToType.DEFAULT)
        setSelectedPeopleList([])
        setRteValueSubject(EditorValue.createFromString("", "html"))
        setRteValue(EditorValue.createFromString("", "html"))

        myForm.clearErrors()
        myForm.reset()
        await DocumentOnlyTemplateServices.getDocumentOnlyTemplateIDBasic({
            TemplateId: templateId!,
            IgnoreDocument: true
        })
            .then(async (result) => {

                if (emailType === EmailType.DOCUMENTUPDATE || emailType === EmailType.DOCUMENTDELETE || emailType === EmailType.DOCUMENTCREATION || emailType === EmailType.DOCUMENTSHARE) {
                    await FormTemplateLinkService.GetDocumentOnlyAudienceDetailByAudienceMasterId({
                        AvoidCache: true,
                        AudienceMasterId: result.DocumentOnlyAudienceId!.toString()
                    })
                        .then(async (data) => {
                            if (data.length > 0) {

                                const people: IPersonaProps[] = data.map<IPersonaProps>(item => {
                                    return {
                                        text: item.FullName?.trim().length === 0 ? item.UserName : item.FullName,
                                        secondaryText: item.UserName
                                    }
                                })
                                setPeopleList(people)
                            }
                        })
                }

                await loadContentControls().then(async () => {
                    await loadEmailTemplate().then(() => {
                        setApplicationDisplayName(result.DisplayName !== undefined ? result.DisplayName : "")
                        setApplicationCategory(result.Category !== undefined ? result.Category.toString() : "")

                        setIsLoadedData(true)

                    }).catch((error: any) => {
                        setCustomError(error, true)

                    }).finally(() => {
                        setIsLoadingData(false)
                    })
                }).catch((error: any) => {
                    setCustomError(error, true)
                    setIsLoadingData(false)
                })

            })
            .catch((error: any) => {
                setCustomError(error, true)
                setIsLoadingData(false)
            })

    }

    /**
     * Load Content Controls
     */
    const loadContentControls = async () => {
        setTemplateContentControls([])
        await RenewalsAndAlertsService.getStatemanagement({
            User: "",
            templateId: parseInt(templateId!)
        }).then(data => {
            try {
                const items = JSON.parse(data.Layout as string) as ILayout[];
                if (Array.isArray(items)) {
                    setTemplateContentControls(setDefaultOptions(items, emailType))
                } else {
                    throwError(items);
                }
            } catch (error: any) {
                setCustomError(error, true)
            }

        }).catch(error => {
                setCustomError(error, true)
            }
        )
    }

    /**
     * loadEmailTemplate
     */
    const loadEmailTemplate = async () => {
        await EmailTemplateNotificationsServices.GetTemplateDefinition({
            EmailType: emailType,
            TemplateId: templateId!
        }).then(data => {
            try {
                if (data.success) {
                    if (data.result === null) {
                        setIsActive(true)
                        setIsNew(true)
                        setToType(ToType.DEFAULT)
                    } else {

                        try {
                            const template = JSON.parse(data.result) as ICreateUpdateTemplateDefinitionRequest

                            myForm.setValue("name", template.Name)
                            myForm.setValue("description", template.Description)
                            setRteValue(EditorValue.createFromString(template.Body, 'html'))
                            setRteValueSubject(EditorValue.createFromString(template.Subject, 'html'))

                            if (template.ToType !== null) {
                                setToType(template.ToType)
                                if (template.ToType === ToType.CUSTOM) {
                                    const peopleList: string[] = template.To === undefined ? [] : template.To.split(",")
                                    const newPeopleList: IPersonaProps[] = peopleList.map(item => ({
                                        secondaryText: item,
                                        primaryText: item
                                    }))
                                    setSelectedPeopleList(newPeopleList)
                                    myForm.setValue("to", newPeopleList)
                                } else setSelectedPeopleList([])
                            }

                            setIsActive(template.Status)
                            setIsNew(false)

                        } catch (e: any) {
                            throwError(e.toString());
                        }
                    }


                } else {
                    throwError(data.messageError);
                }
            } catch (error: any) {
                setCustomError(error, true)
            }

        }).catch(error => {
                setCustomError(error, true)
            }
        )
    }

    const onCLosePreview = () => {
        myModalPreview.close()
    }

    /**
     * show Status Message Pivot 1
     * @param message
     * @param status
     */
    const showStatusMessage = (message: string, status: StatusEnum) => {
        setMessage(message)
        setStatusMessageEnum(status)
        setShowMessage(true)
    }

    /**
     * Return Preview title
     */
    function modalPreviewTitle(): string {
        let name = ""
        if (applicationCategory !== undefined && applicationDisplayName !== undefined && applicationCategory.trim().length >= 0 && applicationDisplayName.trim().length > 0)
            name = `(${applicationCategory} - ${applicationDisplayName})`
        return t('title.modalPreviewEmail', {ns: 'common', Name: name})
    }


    /**
     * Return title according to emailType
     */
    function modalTitle(): string {
        let name = ""
        if (applicationCategory !== undefined && applicationDisplayName !== undefined && applicationCategory.trim().length >= 0 && applicationDisplayName.trim().length > 0)
            name = `(${applicationCategory} - ${applicationDisplayName})`

        switch (emailType) {
            case EmailType.DOCUMENTCREATION:
                return t("DocumentCreationTitle", {
                    ns: "emailtemplate",
                    Application: name
                })
            case EmailType.DOCUMENTUPDATE:
                return t("DocumentUpdateTitle", {
                    ns: "emailtemplate",
                    Application: name
                })
            case EmailType.DOCUMENTDELETE:
                return t("DocumentDeleteTitle", {
                    ns: "emailtemplate",
                    Application: name
                })
            case EmailType.DOCUMENTSHARE:
                return t("DocumentShareTitle", {
                    ns: "emailtemplate",
                    Application: name
                })
            case EmailType.APPLICATIONEDIT:
                return t("ApplicationEditTitle", {
                    ns: "emailtemplate",
                    Application: name
                })
            case EmailType.APPLICATIONDELETE:
                return t("ApplicationDeleteTitle", {
                    ns: "emailtemplate",
                    Application: name
                })
            case EmailType.APPLICATIONREMOVE:
                return t("ApplicationRemoveTitle", {
                    ns: "emailtemplate",
                    Application: name
                })
            case EmailType.APPLICATIONSHARE:
                return t("ApplicationShareTitle", {
                    ns: "emailtemplate",
                    Application: name
                })
            case EmailType.WIZARDSUBMISSIONCREATE:
                return t("WizardSubmissionCreate", {
                    ns: "emailtemplate",
                    Application: name
                })
            case EmailType.WIZARDSUBMISSIONUPDATE:
                return t("WizardSubmissionUpdate", {
                    ns: "emailtemplate",
                    Application: name
                })
            default:
                return t("EmailTemplateTitle", {
                    ns: "emailtemplate",
                    Application: name
                })
        }
    }

    /**
     * Submenu
     */
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'saveTemplateDefinition',
                text: t('Common.Button.Save', {ns: 'common'}),
                iconProps: {iconName: 'Save'},
                onClick: () => {
                    create(false).then()
                }
            },
            {
                key: 'deleteTemplateDefinition',
                text: t('Common.Button.Remove', {ns: 'common'}),
                iconProps: {iconName: 'Trash'},
                onClick: () => {
                    setShowDelete(true)
                }
            },
        ],
    }


    /**
     * Submenu
     */
    const menuPropsSave: IContextualMenuProps = {
        items: [
            {
                key: 'saveTemplateDefinition',
                text: t('Common.Button.Save', {ns: 'common'}),
                iconProps: {iconName: 'Save'},
                onClick: () => {
                    create(false).then()
                }
            },
        ],
    }

    const statusToogle = () => {
        return (
            <>
                <Toggle styles={{root: {margin: 0}, label: {wordBreak: "normal"}}}
                        checked={isActive}
                        onChange={(e, checked) => {
                            setIsActive(checked!)
                        }}
                        label={t("label.status", {ns: "common"})}
                        inlineLabel onText={t("label.active", {ns: "common"})}
                        offText={t("label.inactive", {ns: "common"})}
                        disabled={!isLoadedData || isSaving || isLoadingData}/>
            </>
        )
    }

    /**
     * Delete template definition
     */
    const deleteTemplate = async () => {
        setIsSaving(true)
        const request: IDeleteTemplateDefinitionRequest = {
            TemplateId: templateId!,
            EmailType: emailType
        }

        await EmailTemplateNotificationsServices.DeleteTemplateDefinition(request)
            .then((data) => {
                if (data.success) {

                    setUpdateParent(true)
                    setStatusParent(false)

                    closeModal(true, undefined)
                } else {
                    setCustomError(data.messageError, false)
                    setIsSaving(false)
                }
            })
            .catch((error) => {
                setCustomError(error, false)
                setIsSaving(false)
            })
            .finally(() => {
                setShowDelete(false)
            })

    }


    /**
     * Create email Template
     * @param closeAfterSaving
     */
    const create = async (closeAfterSaving: boolean = false) => {
        setShowMessage(false)
        await myForm.handleSubmit(async () => {
                const subject = removeHTMLTags(rteValueSubject.toString("html"))
                const body = removeHTMLTags(rteValue.toString("html"))
                if (subject.trim().length === 0 || body.trim().length === 0) {
                    if (subject.trim().length === 0) myForm.setError("EmailSubject", {message: t('text.fieldRequired', {ns: 'common'})})
                    if (body.trim().length === 0) myForm.setError("EmailBody", {message: t('text.fieldRequired', {ns: 'common'})})
                    showStatusMessage(t("message.fieldsRequired", {ns: "common"}), StatusEnum.Error)
                } else {
                    setIsSaving(true)

                    //todo add here recipients scnerarios
                    let _to: string | undefined = selectedPeopleList.map(item => item.secondaryText).join(",")
                    let _toType: ToType = toType
                    if (emailType === EmailType.WIZARDSUBMISSIONCREATE || emailType === EmailType.WIZARDSUBMISSIONUPDATE || emailType === EmailType.DOCUMENTSHARE) {
                        _to = undefined
                        _toType = ToType.DEFAULT
                    }
                    if (_toType === ToType.CREATOR || _toType === ToType.DEFAULT) {
                        _to = undefined
                    }

                    const request: ICreateUpdateTemplateDefinitionRequest = {
                        Body: rteValue.toString("html"),
                        Description: myForm.getValues("description"),
                        EmailType: emailType,
                        Name: myForm.getValues("name"),
                        Status: isActive,
                        Subject: subject,
                        TemplateId: templateId!,
                        To: _to,
                        ToType: _toType
                    }

                    await EmailTemplateNotificationsServices.CreateUpdateTemplateDefinition(request)
                        .then((data) => {
                            if (data.success) {

                                setUpdateParent(true)
                                setStatusParent(isActive)

                                if (closeAfterSaving) closeModal(true, isActive)
                                else {
                                    showStatusMessage(t('message.successCreated', {ns: 'common'}), StatusEnum.Success)
                                    setIsSaving(false)
                                    setIsNew(false)
                                }
                            } else {
                                setCustomError(data.messageError, false)
                                setIsSaving(false)
                            }
                        })
                        .catch((error) => {
                            setCustomError(error, false)
                            setIsSaving(false)
                        })

                }

            }, () => {
                showStatusMessage(t("message.fieldsRequired", {ns: "common"}), StatusEnum.Error)
            }
        )()
    }

    return (
        <>
            {/*region MODAL TEMPLATE*/}
            <myModal.Modal
                title={modalTitle()} titleId="EmailTemplate" size={Sizes.sm}
                afterClose={() => {
                    closeModal(updateParent, statusParent)
                }}
                bodyClassName={_styles.body}
                additionalHeader={statusToogle()}
            >
                <>
                    {/*region MESSAGES*/}
                    <StackItem>
                        {showMessage &&
                            <>
                                <StatusMessage status={statusMessageEnum} hasTimer={false}
                                               truncated={true}
                                               isMultiline={true}
                                               dismiss={true}
                                               setShowMessage={setShowMessage}>
                                    <div>{message}</div>
                                </StatusMessage>
                            </>
                        }
                    </StackItem>
                    {/*endregion*/}


                    {/*region CONTENT*/}
                    <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                           style={{
                               overflow: "hidden",
                               overflowY: "auto",
                               height: "50vh",
                               padding: "16px 16px 24px 16px"
                           }}>

                        {/*region TRY AGAIN*/}
                        {isErrorOnLoad &&
                            <>
                                <Stack grow={1} styles={{
                                    root: {
                                        height: "100%",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }
                                }}>
                                    <StackItem>
                                        <DefaultButton onClick={() => {
                                            onLoad().then()
                                        }} style={{marginLeft: 8}}
                                                       text={t('Common.Button.Try', {ns: 'common'})}
                                                       title={t('Common.Button.Try', {ns: 'common'})}/>
                                    </StackItem>
                                    <StackItem tokens={{padding: 8}}>
                                        <Text variant={"medium"}>{t("text.errorAndTry", {ns: "common"})}</Text>
                                    </StackItem>
                                </Stack>
                            </>
                        }
                        {/*endregion*/}

                        {/*region CONTENT FORM*/}
                        {!isErrorOnLoad &&

                            <FormProvider {...myForm}>
                                <form>
                                    {isLoadingData && <>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Shimmer styles={{root: {marginTop: 32}}}/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                    </>}
                                    {isLoadedData &&
                                        <>
                                            <Text variant={"medium"} block styles={{root: {paddingBottom: 4}}}>
                                                <Trans>
                                                    {t("instructions", {ns: "emailtemplate"})}
                                                </Trans>
                                            </Text>

                                            <RHFTextBoxControl
                                                id={"name"}
                                                label={t('label.ctrlName', {ns: 'common'})}

                                                rules={{
                                                    required: t('text.fieldRequired', {ns: 'common'}),
                                                    validate: {
                                                        required:
                                                            (value) => {
                                                                return !!value.trim() || t('text.fieldRequired', {ns: 'common'})
                                                            }
                                                    },
                                                }}

                                                placeholder={t('label.ctrlName', {ns: 'common'})}
                                                title={t('label.ctrlName', {ns: 'common'})}
                                                ariaLabel={t('label.ctrlName', {ns: 'common'})}
                                                maxLength={250}
                                                control={myForm.control}
                                                setValue={myForm.getValues}
                                                getValues={myForm.getValues}
                                                trigger={myForm.trigger}
                                                required
                                                disabled={isSaving}
                                            >
                                            </RHFTextBoxControl>

                                            <RHFTextBoxControl
                                                id={"description"}
                                                label={t('label.ctrlDescription', {ns: 'common'})}
                                                rules={{
                                                    required: t('text.fieldRequired', {ns: 'common'}),
                                                    validate: {
                                                        required:
                                                            (value) => {
                                                                return !!value.trim() || t('text.fieldRequired', {ns: 'common'})
                                                            }
                                                    },
                                                }}
                                                placeholder={t('label.ctrlDescription', {ns: 'common'})}
                                                title={t('label.ctrlDescription', {ns: 'common'})}
                                                ariaLabel={t('label.ctrlDescription', {ns: 'common'})}
                                                maxLength={1024}
                                                control={myForm.control}
                                                setValue={myForm.getValues}
                                                getValues={myForm.getValues}
                                                trigger={myForm.trigger}
                                                required
                                                disabled={isSaving}
                                            >
                                            </RHFTextBoxControl>

                                            <Separator
                                                styles={{root: {marginTop: 16}}}>{t('title.notificationEmail', {ns: 'common'})}
                                            </Separator>


                                            {(emailType === EmailType.WIZARDSUBMISSIONUPDATE || emailType === EmailType.WIZARDSUBMISSIONCREATE) &&
                                                <>
                                                    <Label
                                                        title={t('label.ctrlEmailTo', {ns: 'common'})}>{t('label.ctrlEmailTo', {ns: 'common'})}</Label>
                                                    <MessageBar
                                                        styles={{root: {marginBottom: 16}}}
                                                        messageBarType={MessageBarType.info}
                                                        isMultiline={true}
                                                        truncated={true}
                                                    >
                                                        {t("text.toUserWhoSubmits", {ns: "common"})}
                                                    </MessageBar>
                                                </>
                                            }


                                            {(emailType === EmailType.DOCUMENTSHARE) &&
                                                <>
                                                    <Label
                                                        title={t('label.ctrlEmailTo', {ns: 'common'})}>{t('label.ctrlEmailTo', {ns: 'common'})}</Label>
                                                    <MessageBar
                                                        styles={{root: {marginBottom: 16}}}
                                                        messageBarType={MessageBarType.info}
                                                        isMultiline={true}
                                                        truncated={true}
                                                    >
                                                        {t("text.toUserShared", {ns: "common"})}
                                                    </MessageBar>
                                                </>
                                            }


                                            {(emailType === EmailType.DOCUMENTCREATION || emailType === EmailType.DOCUMENTDELETE || emailType === EmailType.DOCUMENTUPDATE) &&
                                                <>
                                                    <Stack styles={{root: {marginBottom: 16}}}>
                                                        <ChoiceGroup
                                                            disabled={isSaving}
                                                            options={optionsToType()}
                                                            selectedKey={toType.toString()}
                                                            styles={{root: {marginBottom: 8}}}
                                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                                                                if (option !== undefined) setToType(Number(option.key))
                                                            }}
                                                            title={t('label.ctrlEmailTo', {ns: 'common'})}
                                                            label={t('label.ctrlEmailTo', {ns: 'common'})}
                                                            required={true}
                                                        />

                                                        {toType === ToType.CUSTOM &&
                                                            <>
                                                                <RHFPeoplePickerControl
                                                                    readOnly={isSaving}
                                                                    label={undefined}
                                                                    pickerSuggestionsProps={suggestionProps}
                                                                    control={myForm.control}
                                                                    setValue={myForm.setValue}
                                                                    getValues={myForm.getValues}
                                                                    trigger={myForm.trigger}
                                                                    id={"to"}
                                                                    rules={{
                                                                        required: t('text.fieldRequired', {ns: 'common'})
                                                                    }}
                                                                    onCallback={(personas: IPersonaProps[]) => {
                                                                        setSelectedPeopleList(personas)
                                                                    }}
                                                                    peopleList={peopleList}
                                                                    selectedPeopleList={selectedPeopleList}
                                                                    setSelectedPeopleList={setSelectedPeopleList}
                                                                />
                                                            </>
                                                        }
                                                    </Stack>
                                                </>
                                            }

                                            <Stack horizontal wrap={true}
                                                   styles={{root: {backgroundColor: branding.theme.semanticColors.listItemBackgroundHovered}}}>
                                                <StackItem grow={1}>

                                                    <StackItem tokens={{padding: 16}}>

                                                        <CommandBarButton onClick={() => {
                                                            myModalPreview.open()
                                                        }} styles={{
                                                            root: {
                                                                float: "right",
                                                                height: 32,
                                                                marginTop: "-8px"
                                                            }
                                                        }} iconProps={{iconName: "MailCheck"}}
                                                                          text={t('Common.Button.PreviewEmail', {ns: 'common'})}
                                                                          disabled={isSaving}
                                                        />

                                                        <SingleRHFRichTextControl
                                                            id={"EmailSubject"}
                                                            label={t('label.ctrlEmailSubject', {ns: 'common'})}
                                                            placeholder={t('label.ctrlEmailSubject', {ns: 'common'})}
                                                            setRteValue={setRteValueSubject}
                                                            rteValue={rteValueSubject}
                                                            control={myForm.control}
                                                            setValue={myForm.setValue}
                                                            getValues={myForm.getValues}
                                                            trigger={myForm.trigger}
                                                            rules={{required: t('text.fieldRequired', {ns: 'common'})}}
                                                            onRenderOption={onRenderOption}
                                                            options={templateContentControls}
                                                            toolbarConfig={toolbarConfigEmailSubject}
                                                            disabled={isSaving}
                                                            isTextArea={true}
                                                        />
                                                    </StackItem>
                                                    <Separator/>
                                                    <StackItem tokens={{padding: 16}}>

                                                        <CommandBarButton onClick={() => {
                                                            myModalPreview.open()
                                                        }} styles={{
                                                            root: {
                                                                float: "right",
                                                                height: 32,
                                                                marginTop: "-8px"
                                                            }
                                                        }} iconProps={{iconName: "MailCheck"}}
                                                                          text={t('Common.Button.PreviewEmail', {ns: 'common'})}
                                                                          disabled={isSaving}/>
                                                        <SingleRHFRichTextControl
                                                            id={"EmailBody"}
                                                            label={t('label.ctrlEmailBody', {ns: 'common'})}
                                                            placeholder={t('label.ctrlEmailBody', {ns: 'common'})}
                                                            setRteValue={setRteValue}
                                                            rteValue={rteValue}
                                                            control={myForm.control}
                                                            setValue={myForm.setValue}
                                                            getValues={myForm.getValues}
                                                            trigger={myForm.trigger}
                                                            onRenderOption={onRenderOption}
                                                            options={templateContentControls}
                                                            toolbarConfig={toolbarConfigEmailBody}
                                                            disabled={isSaving}
                                                        />
                                                    </StackItem>
                                                    <br/>
                                                </StackItem>
                                            </Stack>
                                        </>
                                    }
                                </form>
                            </FormProvider>
                        }
                        {/*endregion*/}
                    </Stack>
                    {/*endregion*/}

                    {/*region FOOTER*/}
                    <Separator styles={{root: {marginBottom: -8}}}></Separator>
                    <Stack horizontal style={{overflow: "hidden"}} disableShrink styles={nonShrinkingStackItemStyles}>
                        <StackItem grow={1}>&nbsp;</StackItem>
                        {isSaving &&
                            <>
                                <StackItem>
                                    <Spinner size={SpinnerSize.medium} labelPosition="left"
                                             label={t('text.spinnerSaving', {ns: 'common'})}/>
                                </StackItem>
                            </>
                        }

                        <StackItem>

                            {!isNew && <>

                                <PrimaryButton disabled={!isLoadedData || isSaving || isLoadingData}
                                               style={{marginLeft: 8}}
                                               onClick={() => {
                                                   create(true).then()
                                               }}
                                               split
                                               menuProps={menuProps}
                                               text={t('Common.Button.SaveAndClose', {ns: 'common'})}
                                               title={t('Common.Button.SaveAndClose', {ns: 'common'})}/>
                            </>
                            }
                            {isNew &&
                                <PrimaryButton disabled={!isLoadedData || isSaving || isLoadingData}
                                               style={{marginLeft: 8}}
                                               onClick={() => {
                                                   create(true).then()
                                               }}
                                               split
                                               menuProps={menuPropsSave}
                                               text={t('Common.Button.SaveAndClose', {ns: 'common'})}
                                               title={t('Common.Button.SaveAndClose', {ns: 'common'})}/>
                            }

                            <DefaultButton onClick={() => {
                                closeModal(updateParent, statusParent)
                            }} style={{marginLeft: 8}}
                                           text={t('Common.Button.Close', {ns: 'common'})}
                                           title={t('Common.Button.Close', {ns: 'common'})}/>
                        </StackItem>
                    </Stack>
                    {/*endregion*/}


                </>
            </myModal.Modal>
            {/*endregion*/}


            {/*region MODAL PREVIEW*/}
            <myModalPreview.Modal
                title={modalPreviewTitle()} titleId="EmailTemplatePreview" size={Sizes.xsPlus}
                afterClose={onCLosePreview}
                bodyClassName={_styles.body}
                additionalHeader={undefined}
            >
                <>

                    <Stack grow={1} tokens={{padding: 16}} style={{height: "50vh"}}
                    >
                        <StackItem styles={{root: {marginBottom: 16}}}>

                            <StatusMessage status={'info'} hasTimer={false}
                                           truncated={false}
                                           isMultiline={true}
                                           dismiss={false}
                                           setShowMessage={true}>
                                <div>{t('previewEmailTags', {ns: 'emailtemplate'})}</div>
                            </StatusMessage>

                        </StackItem>

                        <StackItem>
                            <Text block
                                  variant={"large"}>{t('label.ctrlEmailSubject', {ns: 'common'})}: {removeHTMLTags(rteValueSubject.toString("html"))}</Text>


                            {(emailType === EmailType.WIZARDSUBMISSIONUPDATE || emailType === EmailType.WIZARDSUBMISSIONCREATE) &&
                                <>
                                    <Text block variant={"medium"}
                                          styles={{root: {fontWeight: "300"}}}>{`${t("label.ctrlEmailTo", {ns: "common"})}: ${t("text.toUserWhoSubmits", {ns: "common"})}`}</Text>
                                </>
                            }

                            {(emailType === EmailType.DOCUMENTSHARE) &&
                                <>
                                    <Text block variant={"medium"}
                                          styles={{root: {fontWeight: "300"}}}>{`${t("label.ctrlEmailTo", {ns: "common"})}: ${t("text.toUserShared", {ns: "common"})}`}</Text>
                                </>
                            }


                            {(emailType === EmailType.DOCUMENTUPDATE || emailType === EmailType.DOCUMENTDELETE || emailType === EmailType.DOCUMENTCREATION) &&
                                <>
                                    {(toType === ToType.DEFAULT || toType === ToType.CREATOR) &&
                                        <Text block variant={"medium"}
                                              styles={{root: {fontWeight: "300"}}}>{`${t("label.ctrlEmailTo", {ns: "common"})}: ${optionsToType()[toType].text}`}</Text>
                                    }

                                    {toType === ToType.CUSTOM &&
                                        <>
                                            <Text block variant={"medium"}
                                                  styles={{root: {fontWeight: "300"}}}>{`${t("label.ctrlEmailTo", {ns: "common"})}: ${selectedPeopleList.map(item => item.secondaryText).join(",")}`}</Text>
                                        </>
                                    }

                                </>
                            }

                        </StackItem>
                        <Separator title={t('label.ctrlEmailBody', {ns: 'common'})}/>
                        <StackItem grow={1} className="scrollVisible" data-is-scrollable="true">
                            <div dangerouslySetInnerHTML={{__html: rteValue.toString("html")}}/>
                            <Text styles={{root: {marginTop: "16"}}} block variant={"smallPlus"}>
                                <p>_____</p>
                                <p>{t('message.disclaimeCconfidentialData', {ns: 'common'})}</p>
                            </Text>
                        </StackItem>
                    </Stack>


                </>

            </myModalPreview.Modal>
            {/*endregion*/}

            {/*region Delete Dialog*/}
            <CustomDialog title={t('Common.Button.Remove', {ns: 'common'})} titleId={`remove${templateId}`}
                          actionText={t('Common.Button.Remove', {ns: 'common'})}
                          confirm={deleteTemplate}
                          close={() => {
                              setShowDelete(false)
                          }}
                          isDisabled={isSaving}
                          isOpen={showDelete}
            >
                {t('confirmRemove', {ns: 'emailtemplate'})}
            </CustomDialog>
            {/*endregion*/}

        </>
    )
}

export default EmailTemplate