import {FontWeights, Spinner, SpinnerSize, Stack, Text} from "@fluentui/react";
import Helper from "../../Helpers/Helper";
import React, {useEffect, useState} from "react";
import {useBranding} from "../../hooks/useBranding";
import {_styles} from "./index";
import {useTranslation} from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import ActivityService, {IGetactivityRequest, IGetactivityResponse} from "../../services/Activity";
import {Icon} from "office-ui-fabric-react";

interface IProps {
    isLoggedIn: boolean
}

export function Content({isLoggedIn}: IProps) {

    const recordsPerPage = 20
    const [page, setPage] = useState(1)
    const {t} = useTranslation(['common'])
    const {branding} = useBranding()
    const [isLoading, setIsLoading] = useState(true)
    const [isLoaded, setIsLoaded] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [activityItems, setActivityItems] = useState<IGetactivityResponse[]>([])

    useEffect(() => {
        if(isLoggedIn) {
            init().then()
        }
    }, [])

    async function init() {
        setActivityItems([])
        setIsLoaded(false)
        getActivity().then()
    }

    const getActivity = async () => {
        if (page === 1) {
            setIsLoading(true)
        }

        const request: IGetactivityRequest = {
            DateFrom: "",
            DateTo: "",
            PageNumber: page === 1 ? page : page + 1,
            RecordsPerPage: recordsPerPage,

        }

        await ActivityService.getactivity(request)
            .then((data) => {
                if (page === 1) {
                    setPage(page + 1)
                    setIsLoaded(true)
                }
                if (data.length < recordsPerPage) {
                    setHasMore(false)
                }
                setActivityItems(activityItems.concat(data))

            })
            .catch()
            .finally(() => {
                if (page === 1) {
                    setIsLoading(false)
                }
            })
    }

    /**
     * On render Icon
     * @param action
     */
    const onRenderIcon = (action: string) => {
        switch (action) {
            case "Update":
                return (
                    <Icon iconName={'PostUpdate'} className={_styles(branding.theme).icon}/>
                )
            case "Create":
                return (
                    <Icon iconName={'EditCreate'} className={_styles(branding.theme).icon}/>
                )
            case "Delete":
                return (
                    <Icon iconName={'Delete'} className={_styles(branding.theme).icon}/>
                )
            default:
                return (
                    <Icon iconName={'AccountActivity'} className={_styles(branding.theme).icon}/>
                )
        }
    }


    return (
        <>
            <Stack id="scrollableDiv" className="scrollVisible" data-is-scrollable="true" style={{
                overflow: "auto",
                height: "100%",
                padding: "16px 16px 24px 16px"
            }}>

                {isLoading &&
                    <Stack grow={1} styles={{root: {justifyContent: "center"}}}>
                        <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})}
                                 ariaLive="assertive"/>
                    </Stack>
                }

                {!isLoading && isLoaded &&
                    <>
                        <InfiniteScroll
                            dataLength={activityItems.length}
                            next={getActivity}
                            hasMore={hasMore}
                            loader={
                                <>
                                    <Stack tokens={{padding: 32}}>
                                        <Spinner size={SpinnerSize.large}
                                                 label={t('text.spinner', {ns: 'common'})}
                                                 ariaLive="assertive"/>
                                    </Stack>
                                </>
                            }
                            scrollableTarget="scrollableDiv"
                        >
                            {activityItems.map(item => (
                                <>
                                    <Stack horizontal tokens={{childrenGap: 8, padding: 8}}
                                           className={_styles(branding.theme).activity}>
                                        <Stack tokens={{padding: 8}}>
                                            {onRenderIcon(item.Action)}
                                        </Stack>
                                        <Stack grow={1}>
                                            <Text variant={"mediumPlus"}
                                                  styles={{root: {fontWeight: FontWeights.semibold}}}
                                                  block>{item.DisplayName}</Text>
                                            <Text variant={"medium"}
                                                  block>{`${item.Section} - ${item.Area} - ${item.Action}`}</Text>
                                            <Text variant={"medium"} block>{item.Message}</Text>
                                            <Text variant={"small"}
                                                  block>{Helper.getLocateDate(item.Date, 1)}</Text>
                                        </Stack>
                                    </Stack>
                                </>
                            ))}

                        </InfiniteScroll>
                    </>
                }

            </Stack>

        </>
    )
}


