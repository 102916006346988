import {IHttpClientResponse, IHttpClientResponseBag} from "../Helpers/Helper";
import HttpRequest from "./HttpRequest";


const AccountService = {
    /**
     * Reset password
     * @constructor
     */
    ResetPassword: async () => {

        const { data }: any = await HttpRequest.postWithSession("Account/ResetPassword", {})
        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            console.log(data)
            if (!data.success) reject({err: 'Server error'})
            else resolve(data)
        })
    }
}

export default AccountService