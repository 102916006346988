import React from "react";
import { getTheme, IButtonStyles, IColumn, IIconProps, ISeparatorStyles } from "@fluentui/react"
import Helper, { IMyListOption } from "../../Helpers/Helper"
import { APIEventLog, ApplicationLog } from "../../interfaces/IAdminLog"
import {Theme} from "@fluentui/react-theme-provider";
import {IContentStyles} from "../CustomTemplates/subforms/RestrictSubForms/Style";

export const stackTokens = { childrenGap: 10 };
export const theme = getTheme();


export const iconButtonMobileStyles = (theme: Theme): IButtonStyles => {

    return {
        root: {
            color: theme.palette.themePrimary,
            selectors: {
                ':hover': {
                    cursor: 'pointer',
                },
            },
        },

    }
}
export const separatorStyles: ISeparatorStyles = {
    root: {},
    content: {
        padding: "0px 12px 0px 0px"
    },
};
export const cancelIcon: IIconProps = { iconName: 'Cancel' };
export const customDropdownStyles = {
    control: (styles: any, state: any) => ({
        ...styles,
        borderRadius: 1,
        borderColor: state.isFocused ? "rgb(0, 120, 212)" : "rgb(96, 94, 92)",
        minHeight: 32,
    }),
    valueContainer: (styles: any) => ({
        ...styles,
        paddingTop: 0,
    }),
    menu: (styles: any) => ({
        ...styles,
        marginTop: 0,
    }),
    menuList: (styles: any) => ({
        ...styles,
        padding: 0,
    }),
};
export const ErrorLevel: IMyListOption[] = [
    {
        value: 'ERROR',
        label: 'ERROR'
    },
    {
        value: 'INFO',
        label: 'INFO'
    },
    {
        value: 'WARN',
        label: 'WARNING'
    }
]

export const ApplicationLogsColumns = () =>{
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Date',
            fieldName: 'Date',
            minWidth: 220,
            maxWidth: 220,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={Helper.getLocateDate(item.Date, 2)}>{Helper.getLocateDate(item.Date, 2)}</span>
            }
        },
        {
            key: 'column0',
            name: 'Application',
            fieldName: 'Application',
            minWidth: 200,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.TemplateDescription}>{item.TemplateDescription}</span>
            }
        },
        {
            key: 'column2',
            name: 'Section',
            fieldName: 'Section',
            minWidth: 80,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.Section}>{item.Section}</span>
            }
        },
        {
            key: 'column3',
            name: 'Area',
            fieldName: 'Area',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.Area}>{item.Area}</span>
            }
        },
        {
            key: 'column4',
            name: 'Action',
            fieldName: 'Action',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.Action}>{item.Action}</span>
            }
        },
        {
            key: 'column5',
            name: 'Type',
            fieldName: 'Type',
            minWidth: 70,
            maxWidth: 70,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.Level}>{item.Level}</span>
            }
        },
        {
            key: 'column6',
            name: 'Message',
            fieldName: 'Message',
            minWidth: 300,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.Message}>{item.Message}</span>
            }
        },
        {
            key: 'column7',
            name: 'Exception',
            fieldName: 'Exception',
            minWidth: 300,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.Exception}>{item.Exception}</span>
            }
        },
        {
            key: 'column8',
            name: 'User Email',
            fieldName: 'User Email',
            minWidth: 250,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.Identity}>{item.Identity}</span>
            }
        },
        {
            key: 'column9',
            name: 'IPAddress',
            fieldName: 'IPAddress',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.IPAddress}>{item.IPAddress}</span>
            }
        },
        {
            key: 'column10',
            name: 'ActionStatus',
            fieldName: 'ActionStatus',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ApplicationLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.ActionStatus}>{item.ActionStatus}</span>
            }
        }
    ]
    return columns
}

export const APIEventLogsColumns = () =>{
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Date',
            fieldName: 'Date',
            minWidth: 220,
            maxWidth: 220,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: APIEventLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={Helper.getLocateDate(item.ApiEventDateTime, 2)}>{Helper.getLocateDate(item.ApiEventDateTime, 2)}</span>
            }
        },
        {
            key: 'column2',
            name: 'AddinKey',
            fieldName: 'AddinKey',
            minWidth: 150,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: APIEventLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.AddinKey}>{item.AddinKey}</span>
            }
        },
        {
            key: 'column3',
            name: 'User Email',
            fieldName: 'User Email',
            minWidth: 250,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: APIEventLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.UserName}>{item.UserName}</span>
            }
        },
        {
            key: 'column4',
            name: 'ApiEvent',
            fieldName: 'ApiEvent',
            minWidth: 150,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: APIEventLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.ApiEvent}>{item.ApiEvent}</span>
            }
        },
        {
            key: 'column5',
            name: 'ApiCallMethod',
            fieldName: 'ApiCallMethod',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: APIEventLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.ApiCallMethod}>{item.ApiCallMethod}</span>
            }
        },
        {
            key: 'column6',
            name: 'IPAddress',
            fieldName: 'IPAddress',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: APIEventLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.IPAddress}>{item.IPAddress}</span>
            }
        },
        {
            key: 'column7',
            name: 'URLParams',
            fieldName: 'URLParams',
            minWidth: 300,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: APIEventLog) => {
                return <span style={{ "whiteSpace": "break-spaces" }} title={item.URLParams}>{item.URLParams}</span>
            }
        }
    ]
    return columns
}

export type APILogsForm = {
    DateFrom: Date | undefined,
    DateTo: Date | undefined,
    TargetUserName: string,
    ApiEvent: string
}

export type FormReducerAction = {
    type: "CHANGE_INPUT",
    payload: {
        name: string,
        value: string
    }
} | {
    type: "CHANGE_DATE",
    payload: {
        name: string,
        value: Date | undefined
    }
}
