import { ActionButton, CheckboxVisibility, DefaultButton, DetailsList, DetailsListLayoutMode, DialogFooter, IButtonStyles, IColumn, IconButton, IContextualMenuItem, IContextualMenuProps, IIconProps, MessageBar, MessageBarType, Modal, PrimaryButton, SelectionMode, Separator, Stack, TextField } from "@fluentui/react"
import React, { useEffect, useState } from 'react';
import Helper, { ICreateApplicationServiceEmail, IDeleteApplicationServiceEmail, IServiceEmailRequest, IUpdateApplicationServiceEmail } from "../../Helpers/Helper";
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { isMobile } from "react-device-detect";
import Secure from "../../Helpers/Crypto";
import {useBranding} from "../../hooks/useBranding";
import GraphApiService, {IDeleteRequest, IResendRequest, ITestRequest} from "../../services/GraphApi";
import ApplicationServiceEmailService, {
    IGetServiceEmailRequest, IGetServiceEmailResponse,
    IUpdateServiceEmailRequest
} from "../../services/ApplicationServiceEmail";

const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const stackTokens = { childrenGap: 15 };
const emptyList: IGetServiceEmailResponse[] = []
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };

function ServiceEmail(props: any) {
    const { branding } = useBranding()
    const [showMessageError, setShowMessageError] = useState(false)
    const [showMessageSuccess, setShowMessageSuccess] = useState(false)
    const [message, setMessage] = useState("");
    const [layout, setLayout] = useState("")
    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const [spinnerText, setSpinnerText] = useState("")
    const [modalId, setModalId] = useState(-1)
    const [modalTitle, setModalTitle] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [serviceEmailList, setServiceEmailList] = useState(emptyList)
    const [selectedList, setSelectedList] = useState<IGetServiceEmailResponse>();
    const [showDeleteModal, setshowDeleteModal] = useState(false)
    const [showMessageItemError, setshowMessageItemError] = useState(false)
    const [messageItem, setMessageItem] = useState("");
    const [EmailServiceOwner, setEmailServiceOwner] = useState("")
    const [EmailServiceApproverService, setEmailServiceApproverService] = useState("")
    const [EmailServiceApprover, setEmailServiceApprover] = useState("")
    const [EmailServiceServiceEmail, setEmailServiceServiceEmail] = useState("")
    const [EmailServiceClientId, setEmailServiceClientId] = useState("")
    const [EmailServiceClientSecret, setEmailServiceClientSecret] = useState("")
    const [EmailServiceRedirect, setEmailServiceRedirect] = useState("")
    const [EmailServiceAudienceId, setEmailServiceAudienceId] = useState("")
    const [EmailServiceTenantGUID, setEmailServiceTenantGUID] = useState("")
    const [EmailServiceTemplateId, setEmailServiceTemplateId] = useState("")
    const [EmailServiceAccessToken, setEmailServiceAccessToken] = useState("")
    const [EmailServiceRefreshToken, setEmailServiceRefreshToken] = useState("")
    const [requiredEmailServiceOwner, setRequiredEmailServiceOwner] = useState(false)
    const [requiredEmailServiceApprover, setRequiredEmailServiceApprover] = useState(false)
    const [requiredEmailServiceServiceEmail, setRequiredEmailServiceServiceEmail] = useState(false)
    const [requiredEmailServiceApproverService, setRequiredEmailServiceApproverService] = useState(false)
    const [requiredEmailServiceClientId, setRequiredEmailServiceClientId] = useState(false)
    const [requiredEmailServiceClientSecret, setRequiredEmailServiceClientSecret] = useState(false)
    const [requiredEmailServiceRedirect, setRequiredEmailServiceRedirect] = useState(false)
    const [requiredEmailServiceAudienceId, setRequiredEmailServiceAudienceId] = useState(false)
    const [requiredEmailServiceTenantGUID, setRequiredEmailServiceTenantGUID] = useState(false)
    const [requiredEmailServiceTemplateId, setRequiredEmailServiceTemplateId] = useState(false)
    const [requiredEmailServiceAccessToken, setRequiredEmailServiceAccessToken] = useState(false)
    const [requiredEmailServiceRefreshToken, setRequiredEmailServiceRefreshToken] = useState(false)
    const [showViewModal, setShowViewModal] = useState(false)

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '50vw',
            height: '50vh',
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: isMobile ? null : '15vw',
            height: '5vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        listDialog: {
            width: isMobile ? null : '30vw',
            height: '25vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        addItemList: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        buttonArea: {
        },
        callout: {
            maxWidth: 300,
        },
        inner: {
            height: '100%',
            padding: '0 24px 20px',
        }
    });

    useEffect(() => {
        getServiceEmailList()
    }, [props.showShare])

    const emptyL: IGetServiceEmailResponse = {
        Id: 0,
        DateTimeCreated: "",
        Owner: "",
        AudienceId: 0,
        TenantGUID: "",
        TemplateId: 0,
        DateTimeApproved: "",
        Approver: "",
        ServiceEmail: "",
        AccessToken: "",
        RefreshToken: "",
        ClientId: "",
        ClientSecret: "",
        RedirectURL: ""
    };

    const columnsList: IColumn[] = [
        {
            key: 'column2',
            name: 'Service Email',
            fieldName: 'Description',
            minWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IGetServiceEmailResponse) => {
                return <span title={item.ServiceEmail.trim()}>{item.ServiceEmail.trim()}</span>
            },
        },
        {
            key: 'column3',
            name: 'Action',
            fieldName: '',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            isPadded: true,
            headerClassName: "columnAlignedRight",
            onRender: (item: IGetServiceEmailResponse) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={menuProps}
                    title="Click to select from available actions."
                /></div>
            },
        }
    ]

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'view',
            text: 'View',
            title: 'View Service.',
            onClick: () => {
                viewServiceEmail(selectedList!)
            },
        },
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete Service.',
            onClick: () => {
                DeleteUser(selectedList!)
            },
        },
        {
            key: 'resendConsentEmail',
            text: 'Resend Consent Email',
            title: 'Resend Consent Email.',
            onClick: () => {
                integrateGraphApi()
            },
        },
        {
            key: 'testEmail',
            text: 'Test Email',
            title: 'Test Email.',
            onClick: () => {
                testEmailConnection()
            },
        }
    ];

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    const getErrorMessageOwner = (value: string): string => {
        if (value.length > 0) {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!value.match(mailformat))
            {
                return "Owner must be valid.";
            }
            setRequiredEmailServiceOwner(true)
        }
        else {
            setRequiredEmailServiceOwner(false)
            return "Owner must not be empty.";
        }

        return ""
    };

    const getErrorMessageApproverService = (value: string): string => {
        if (value.length > 0) {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!value.match(mailformat))
            {
                return "Approver and Service must be valid.";
            }
            setRequiredEmailServiceApproverService(true)
        }
        else {
            setRequiredEmailServiceApproverService(false)
            return "Approver and Service must not be empty.";
        }

        return ""
    };

    const getErrorMessageApprover = (value: string): string => {
        if (value.length > 0) {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!value.match(mailformat))
            {
                return "Approver must be valid.";
            }
            setRequiredEmailServiceApprover(true)
        }
        else {
            setRequiredEmailServiceApprover(false)
            return "Approver must not be empty.";
        }

        return ""
    };

    const getErrorMessageServiceEmail = (value: string): string => {
        if (value.length > 0) {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!value.match(mailformat))
            {
                return "Approver and Service must be valid.";
            }
            setRequiredEmailServiceServiceEmail(true)
        }
        else {
            setRequiredEmailServiceServiceEmail(false)
            return "Approver and Service must not be empty.";
        }

        return ""
    };

    const getErrorMessageClientId = (value: string): string => {
        if (value.length > 0) {
            setRequiredEmailServiceClientId(true)
        }
        else {
            setRequiredEmailServiceClientId(false)
            return "Client ID must not be empty.";
        }

        return ""
    };

    const getErrorMessageClientSecret = (value: string): string => {
        if (value.length > 0) {
            setRequiredEmailServiceClientSecret(true)
        }
        else {
            setRequiredEmailServiceClientSecret(false)
            return "Client Secret must not be empty.";
        }

        return ""
    };

    const getErrorMessageRedirect = (value: string): string => {
        if (value.length > 0) {
            setRequiredEmailServiceRedirect(true)
        }
        else {
            setRequiredEmailServiceRedirect(false)
            return "Redirect URL must not be empty.";
        }

        return ""
    };

    const getErrorMessageAudienceId = (value: string): string => {
        if (value.length > 0) {
            setRequiredEmailServiceAudienceId(true)
        }
        else {
            setRequiredEmailServiceAudienceId(false)
            return "AudienceId must not be empty.";
        }

        return ""
    };

    const getErrorMessageTenantGUID = (value: string): string => {
        if (value.length > 0) {
            setRequiredEmailServiceTenantGUID(true)
        }
        else {
            setRequiredEmailServiceTenantGUID(false)
            return "TenantGUID must not be empty.";
        }

        return ""
    };

    const getErrorMessageTemplateId = (value: string): string => {
        if (value.length > 0) {
            setRequiredEmailServiceTemplateId(true)
        }
        else {
            setRequiredEmailServiceTemplateId(false)
            return "TemplateId must not be empty.";
        }

        return ""
    };

    const getErrorMessageAccessToken = (value: string): string => {
        if (value.length > 0) {
            setRequiredEmailServiceAccessToken(true)
        }
        else {
            setRequiredEmailServiceAccessToken(false)
            return "AccessToken must not be empty.";
        }

        return ""
    };

    const getErrorMessageRefreshToken = (value: string): string => {
        if (value.length > 0) {
            setRequiredEmailServiceRefreshToken(true)
        }
        else {
            setRequiredEmailServiceRefreshToken(false)
            return "RefreshToken must not be empty.";
        }

        return ""
    };

    const getKey = (item: string, index?: number): string => {
        if (item !== undefined)
            return item.toString()

        return ""
    }

    const onSelectionChange = () => {
        const selectionCount = selectionList.getSelectedCount();
        switch (selectionCount) {
            case 0:
                setSelectedList(emptyL)
                break;
            case 1:
                setSelectedList(selectionList.getSelection()[0] as IGetServiceEmailResponse)

                break;
            default:
                setSelectedList(emptyL)
                break;
        }
    }

    const selectionList = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    async function getServiceEmailList() {

        setSpinnerText("Loading content...")

        const request: IGetServiceEmailRequest = {
            TemplateId: "-101",
        }

        await ApplicationServiceEmailService.GetServiceEmail(request)
            .then((data) => {
                setServiceEmailList(data)
            })
            .catch()
            .finally(()=> {
                setSpinnerText("")
            })
        
    }

    async function addServiceEmail() {
        const site = await Helper.getSite()!
        //var clientID64Secure = EmailServiceClientId;
        //var clientSecret64Secure = EmailServiceClientSecret;

        /*if (EmailServiceClientId !== "" && EmailServiceClientSecret !== "") {
            var urlVerify = `Verify?path=${site}`
            const codeRaw = await fetch(urlVerify,{
                method: 'GET',
                headers: await Helper.getHeaders()
            })
            const verify = await codeRaw.text()

            var clientID = Secure.encrypt(EmailServiceClientId, verify)
            var clientID64 = btoa(EmailServiceClientId);
            clientID64Secure = btoa(`${clientID64}.${clientID}.${verify}`)

            var clientSecret = Secure.encrypt(EmailServiceClientSecret, verify)
            var clientSecret64 = btoa(EmailServiceClientSecret);
            clientSecret64Secure = btoa(`${clientSecret64}.${clientSecret}.${verify}`)
        }*/

        const request: IResendRequest = {
            Approver: EmailServiceApproverService,
            Owner: EmailServiceOwner,
            AudienceId: "-101",
            ServiceEmail: EmailServiceApproverService,
            TemplateId: "-101",
            ClientID: "",//clientID64Secure,
            ClientSecret: "",//clientSecret64Secure,
            RedirectURL: EmailServiceRedirect
        }

        await GraphApiService.GraphApi(request)
            .then((response) => {
                getServiceEmailList()
                setShowMessageSuccess(true);
                setMessage(response.Result)

                setTimeout(function () {
                    setShowMessageSuccess(false)
                    setMessage("")
                }, 5000);
            })
            .catch((error) => {
                console.log(Helper.GetErrorMessage(error))
                setShowMessageError(true);
                setMessage(Helper.GetErrorMessage(error))

                setTimeout(function () {
                    setShowMessageError(false)
                    setMessage("")
                }, 5000);
            })
            .finally()
        
    }

    const sendServiceEmail = () => {
        switch (modalId) {
            case 1: {
                addServiceEmail()
            }
                break;
            case 2: {
                deleteServiceEmail()
            }
                break;
            case 3: {
                editServiceEmail()
            }
                break;
        }
        hideModal()
    }

    const hideModal = () => {
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setLayout("")
        setModalTitle("")
        setShowModal(false)
        setshowDeleteModal(false)
        setModalId(-1)
        setEmailServiceOwner("")
        setRequiredEmailServiceOwner(false)
        setEmailServiceApproverService("")
        setRequiredEmailServiceApproverService(false)
        setEmailServiceClientId("")
        setRequiredEmailServiceClientId(false)
        setEmailServiceClientSecret("")
        setRequiredEmailServiceClientSecret(false)
        setEmailServiceRedirect("")
        setRequiredEmailServiceRedirect(false)
    }

    const hideViewModal = () => {
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setLayout("")
        setModalTitle("")
        setShowViewModal(false)
        setModalId(-1)
        setEmailServiceOwner("")
        setRequiredEmailServiceOwner(false)
        setEmailServiceApproverService("")
        setRequiredEmailServiceApproverService(false)
        setEmailServiceApprover("")
        setRequiredEmailServiceApprover(false)
        setEmailServiceServiceEmail("")
        setRequiredEmailServiceServiceEmail(false)
        setEmailServiceClientId("")
        setRequiredEmailServiceClientId(false)
        setEmailServiceClientSecret("")
        setRequiredEmailServiceClientSecret(false)
        setEmailServiceRedirect("")
        setRequiredEmailServiceRedirect(false)
        setEmailServiceAudienceId("")
        setRequiredEmailServiceAudienceId(false)
        setEmailServiceTenantGUID("")
        setRequiredEmailServiceTenantGUID(false)
        setEmailServiceTemplateId("")
        setRequiredEmailServiceTemplateId(false)
        setEmailServiceAccessToken("")
        setRequiredEmailServiceAccessToken(false)
        setEmailServiceRefreshToken("")
        setRequiredEmailServiceRefreshToken(false)
    }

    const addEmail = () => {
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalTitle("New Service Email")
        setShowModal(true)
        setModalId(1)
        setEmailServiceRedirect(`${window.location.origin}/app/permission`)
        setEmailServiceOwner(Helper.getUsername())

    }

    const viewServiceEmail = (item: IGetServiceEmailResponse) => {

        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalTitle("Service Email")
        setShowViewModal(true)
        setModalId(3)
        setEmailServiceOwner(item.Owner)
        setEmailServiceApprover(item.Approver)
        setEmailServiceServiceEmail(item.Approver)
        setEmailServiceClientId(item.ClientId)
        setEmailServiceClientSecret(item.ClientSecret)
        setEmailServiceRedirect(item.RedirectURL)
        setEmailServiceAudienceId(item.AudienceId.toString())
        setEmailServiceTenantGUID(item.TenantGUID? item.TenantGUID : "")
        setEmailServiceTemplateId(item.TemplateId.toString())
        setEmailServiceAccessToken(item.AccessToken)
        setEmailServiceRefreshToken(item.RefreshToken)
    }

    const DeleteUser = (item: IGetServiceEmailResponse) => {
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setshowDeleteModal(true)
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setModalTitle("Delete Service Email")
        setModalId(2)
    }

    const hideDeleteModal = () => {
        setLayoutLoaded(false)
        setSpinnerText("Loading content...")
        setLayout("")
        setModalTitle("")
        setshowDeleteModal(false)
        setModalId(-1)
    }

    const integrateGraphApi = async () => {

        var audienceId = ""
        var templateId = ""
        var approver = ""
        var owner = ""
        var serviceEmail = ""
        var clientId = ""
        var clientSecret = ""
        var redirectURL = ""

        if (selectedList !== undefined) {
            audienceId = selectedList?.AudienceId.toString()
            templateId = selectedList?.TemplateId.toString()
            approver = selectedList?.Approver
            owner = selectedList?.Owner
            serviceEmail = selectedList?.ServiceEmail
            clientId = selectedList?.ClientId
            clientSecret = selectedList?.ClientSecret
            redirectURL = selectedList?.RedirectURL
        }



        const request:IResendRequest = {
            Approver: approver,
            Owner: owner,
            AudienceId: audienceId,
            ServiceEmail: serviceEmail,
            TemplateId: templateId,
            ClientID: clientId,
            ClientSecret: clientSecret,
            RedirectURL: redirectURL

        }
        await GraphApiService.Resend(request)
            .then(()=> {
                setShowMessageSuccess(true)
                setMessage("Email Sent Correctly. If you do not see the email in a few minutes, check your \"junk mail\" folder or \"spam folder\"")
                setTimeout(function () {
                    setShowMessageSuccess(false)
                }, 5000);
            })
            .catch((error)=> {
                setShowMessageError(true)
                setMessage("An error occurred: " + Helper.GetErrorMessage(error))
                setTimeout(function () {
                    setShowMessageError(false)
                }, 5000);
            })
            .finally()
        
	}

    const testEmailConnection = async () => {
        var templateId = 0
   
        if (selectedList !== undefined) {
            templateId = selectedList.TemplateId
        }

        const request: ITestRequest = {
            TemplateId: templateId.toString(),
        }
        await GraphApiService.Test(request)
            .then((data) => {
                setShowMessageSuccess(true)
                setMessage("Email Sent Correctly. If you do not see the email in a few minutes, check your \"junk mail\" folder or \"spam folder\"")
                setTimeout(function () {
                    setShowMessageSuccess(false)
                }, 5000);
            })
            .catch((error) => {
                setShowMessageError(true)
                setMessage("An error occurred: " + Helper.GetErrorMessage(error))
                setTimeout(function () {
                    setShowMessageError(false)
                }, 5000);
            })
            .finally()
    }

    async function deleteServiceEmail() {

        setSpinnerText("Loading content...")

        var audienceId = ""
        var audienceOwner = ""
        var templateId = ""
        if (selectedList !== undefined) {
            audienceId = selectedList.AudienceId.toString()
            audienceOwner = selectedList.Owner!
            templateId = selectedList.TemplateId.toString()
        }

        const request: IDeleteRequest = {
            Owner: audienceOwner,
            AudienceId: audienceId,
            TemplateId: templateId,
            Id: ""
        }

        await GraphApiService.Delete(request)
            .then(() => {
                getServiceEmailList()
                hideDeleteModal()
            })
            .catch()
            .finally(() => {
                setSpinnerText("")
            })

    }

    async function editServiceEmail() {

        setSpinnerText("Loading content...")

        const req: IUpdateServiceEmailRequest = {
            Id: 0,
            Context: "",
            Value: ""
        }

        if (selectedList !== undefined) {
            if (selectedList.Owner !== EmailServiceOwner) {
                req.Id = selectedList.Id
                req.Context = "Owner"
                req.Value = EmailServiceOwner
                await ApplicationServiceEmailService.UpdateServiceEmail(req)
            }
            if (selectedList.Approver !== EmailServiceApprover) {
                req.Id = selectedList.Id
                req.Context = "Approver"
                req.Value = EmailServiceApprover
                await ApplicationServiceEmailService.UpdateServiceEmail(req)
            }
            if (selectedList.ServiceEmail !== EmailServiceServiceEmail) {
                req.Id = selectedList.Id
                req.Context = "ServiceEmail"
                req.Value = EmailServiceServiceEmail
                await ApplicationServiceEmailService.UpdateServiceEmail(req)
            }
            if (selectedList.ClientId !== EmailServiceClientId) {
                req.Id = selectedList.Id
                req.Context = "ClientId"
                req.Value = EmailServiceClientId
                await ApplicationServiceEmailService.UpdateServiceEmail(req)
            }
            if (selectedList.ClientSecret !== EmailServiceClientSecret) {
                req.Id = selectedList.Id
                req.Context = "ClientSecret"
                req.Value = EmailServiceClientSecret
                await ApplicationServiceEmailService.UpdateServiceEmail(req)
            }
            if (selectedList.AudienceId.toString() !== EmailServiceAudienceId) {
                req.Id = selectedList.Id
                req.Context = "AudienceId"
                req.Value = EmailServiceAudienceId
                await ApplicationServiceEmailService.UpdateServiceEmail(req)
            }
            if (selectedList.TenantGUID !== EmailServiceTenantGUID) {
                req.Id = selectedList.Id
                req.Context = "TenantGUID"
                req.Value = EmailServiceTenantGUID
                await ApplicationServiceEmailService.UpdateServiceEmail(req)
            }
            if (selectedList.TemplateId.toString() !== EmailServiceTemplateId) {
                req.Id = selectedList.Id
                req.Context = "TemplateId"
                req.Value = EmailServiceTemplateId
                await ApplicationServiceEmailService.UpdateServiceEmail(req)
            }
            if (selectedList.AccessToken !== EmailServiceAccessToken) {
                req.Id = selectedList.Id
                req.Context = "AccessToken"
                req.Value = EmailServiceAccessToken
                await ApplicationServiceEmailService.UpdateServiceEmail(req)
            }
            if (selectedList.RefreshToken !== EmailServiceRefreshToken) {
                req.Id = selectedList.Id
                req.Context = "RefreshToken"
                req.Value = EmailServiceRefreshToken
                await ApplicationServiceEmailService.UpdateServiceEmail(req)
            }
        }

        
        getServiceEmailList()
        hideViewModal()
        setShowMessageSuccess(true)
            setMessage("Email Service Updated Correctly")
            setTimeout(function () {
                setShowMessageSuccess(false)
            }, 5000);
        
        setSpinnerText("")
    }

    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <label className="ms-fontSize-18"  >{props.modalTitle}</label><br />
                        <div>Add a New Email Service or Edit an existing Service.</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <label className="ms-fontSize-18">{props.modalTitle}</label>
                    </div>
                </div>
                : null
            }
            <Stack tokens={stackTokens}>
                {showMessageError ? <MessageBar messageBarType={MessageBarType.error}>{message} </MessageBar> : null}
                {showMessageSuccess ? <MessageBar messageBarType={MessageBarType.success}>{message} </MessageBar> : null}

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ActionButton title="Add Email" text="Add Email"
                            iconProps={{ iconName: 'NewMail' }} onClick={addEmail} />
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                    <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "40vh" }}>
                        <DetailsList
                            items={serviceEmailList || []}
                            compact={false}
                            columns={columnsList}
                            getKey={getKey}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selection={selectionList}
                            selectionMode={SelectionMode.single}
                            selectionPreservedOnEmptyClick={false}
                            enterModalSelectionOnTouch={true}
                            checkboxVisibility={CheckboxVisibility.hidden}
                        />
                    </div>
                </div>
            </Stack>
            <Modal
                isOpen={showModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={modalTitle}>{modalTitle}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                    {showMessageItemError ? <MessageBar messageBarType={MessageBarType.error}>{messageItem} </MessageBar> : null}
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.listDialog}>
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="ms-Grid-row">
                                                {/*<TextField disabled={true} label="Emails Owner:" onGetErrorMessage={getErrorMessageOwner} maxLength={50} required onChange={(_, v) => { setEmailServiceOwner(v!); }} value={EmailServiceOwner} title="Enter Owner email." />*/}
                                                <TextField label="Emails Approver and Service:" onGetErrorMessage={getErrorMessageApproverService} maxLength={50} required onChange={(_, v) => { setEmailServiceOwner(v!); setEmailServiceApproverService(v!); }} value={EmailServiceApproverService} title="Enter Approver and Service email." />
                                                {/*<TextField disabled={true} label="Client ID:" onGetErrorMessage={getErrorMessageClientId} maxLength={1000} onChange={(_, v) => { setEmailServiceClientId(v!); }} value={EmailServiceClientId} title="Enter Client ID." />
                                                <TextField disabled={true} label="Client Secret:" onGetErrorMessage={getErrorMessageClientSecret} maxLength={1000} onChange={(_, v) => { setEmailServiceClientSecret(v!); }} value={EmailServiceClientSecret} title="Enter Client Secret." />*/}
                                                <TextField disabled={true} label="Redirect URL:" onGetErrorMessage={getErrorMessageRedirect} maxLength={1000} onChange={(_, v) => { setEmailServiceRedirect(v!); }} value={EmailServiceRedirect} title="Enter Redirect URL." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={sendServiceEmail} disabled={!requiredEmailServiceApproverService} text="Add" title="Add Service Email." />
                                <DefaultButton onClick={hideModal} text="Cancel" title="Close." />
                            </DialogFooter>
                        </div>
                    </div>
                    <br />
                </div>
            </Modal>
            <Modal
                isOpen={showViewModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={modalTitle}>{modalTitle}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideViewModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.listDialog}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className="ms-Grid-row">
                                            <TextField disabled={true} label="Owner:" onGetErrorMessage={getErrorMessageOwner} maxLength={50} required onChange={(_, v) => { setEmailServiceOwner(v!); }} value={EmailServiceOwner} title="Enter Owner." />
                                            <TextField disabled={true} label="Approver:" onGetErrorMessage={getErrorMessageApprover} maxLength={50} required onChange={(_, v) => { setEmailServiceApprover(v!); }} value={EmailServiceApprover} title="Enter Approver." />
                                            <TextField disabled={true} label="ServiceEmail:" onGetErrorMessage={getErrorMessageServiceEmail} maxLength={50} required onChange={(_, v) => { setEmailServiceServiceEmail(v!); }} value={EmailServiceServiceEmail} title="Enter ServiceEmail." />
                                            <TextField disabled={true} label="Client ID:" onGetErrorMessage={getErrorMessageClientId} maxLength={1000} required onChange={(_, v) => { setEmailServiceClientId(v!); }} value={EmailServiceClientId} title="Enter Client ID." />
                                            <TextField disabled={true} label="Client Secret:" onGetErrorMessage={getErrorMessageClientSecret} maxLength={1000} required onChange={(_, v) => { setEmailServiceClientSecret(v!); }} value={EmailServiceClientSecret} title="Enter Client Secret." />
                                            <TextField disabled={true} label="Redirect URL:" onGetErrorMessage={getErrorMessageRedirect} maxLength={1000} required onChange={(_, v) => { setEmailServiceRedirect(v!); }} value={EmailServiceRedirect} title="Enter Redirect URL." />
                                            <TextField disabled={true} label="AudienceId:" onGetErrorMessage={getErrorMessageAudienceId} maxLength={50} required onChange={(_, v) => { setEmailServiceAudienceId(v!); }} value={EmailServiceAudienceId} title="Enter AudienceId." />
                                            <TextField disabled={true} label="TenantGUID:" maxLength={1000} onChange={(_, v) => { setEmailServiceTenantGUID(v!); }} value={EmailServiceTenantGUID} title="Enter TenantGUID." />
                                            <TextField disabled={true} label="TemplateId:" onGetErrorMessage={getErrorMessageTemplateId} maxLength={1000} required onChange={(_, v) => { setEmailServiceTemplateId(v!); }} value={EmailServiceTemplateId} title="Enter TemplateId." />
                                            <TextField disabled={true} label="Access Token:" onGetErrorMessage={getErrorMessageAccessToken} maxLength={1000} required onChange={(_, v) => { setEmailServiceAccessToken(v!); }} value={EmailServiceAccessToken} title="Enter Access Token." />
                                            <TextField disabled={true} label="Refresh Token:" onGetErrorMessage={getErrorMessageRefreshToken} maxLength={1000} required onChange={(_, v) => { setEmailServiceRefreshToken(v!); }} value={EmailServiceRefreshToken} title="Enter Refresh Token." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <DialogFooter>
                            {/*<PrimaryButton onClick={sendUser} disabled={!requiredEmailServiceOwner || !requiredEmailServiceApprover || !requiredEmailServiceServiceEmail || !requiredEmailServiceClientId || !requiredEmailServiceClientSecret || !requiredEmailServiceRedirect || !requiredEmailServiceAudienceId || !requiredEmailServiceTemplateId || !requiredEmailServiceAccessToken || !requiredEmailServiceRefreshToken} text="Update" title="Update list." />*/}
                            <DefaultButton onClick={hideViewModal} text="Close" title="Close." />
                        </DialogFooter>
                    </div>
                </div>
                <br />

            </Modal>
            <Modal
                isOpen={showDeleteModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={modalTitle}>{modalTitle}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeleteModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.User}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className="ms-Grid-row">
                                            Are you sure you want to delete mail {selectedList?.ServiceEmail}?
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <DialogFooter>
                            <PrimaryButton onClick={sendServiceEmail} text="Delete" title="Delete list." />
                            <DefaultButton onClick={hideDeleteModal} text="Cancel" title="Close." />
                        </DialogFooter>
                    </div>
                </div>
                <br />
            </Modal>
        </>
    )
}

export default ServiceEmail
