import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { Dropdown, IDropdownOption, IDropdownProps } from "@fluentui/react";
import { ReactHookFormControlProps } from "../CustomTemplates/CustomForm/Models/ReactHookFormControlProps";

export const RHFDropDownListSingleSelectControl: FC<ReactHookFormControlProps & IDropdownProps> = (props) => {
  return (
    <Controller
      name={props.id}
      control={props.control}
      rules={props.rules}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => (
        <Dropdown
          id={props.id}
          label={props.label}
          onRenderLabel={props.onRenderLabel}
          title={props.title}
          options={props.options}
          errorMessage={error && error.message}
          required={props.required}
          onChange={(event, option, index) => {
            props.setValue(props.id, Object.assign({}, props.getValues(props.id), option))
            props.trigger(props.id)
            if (props.onCallback !== null && props.onCallback !== undefined) {
              props.onCallback(event, option, index)
            }
          }}
          selectedKey={props.getValues(props.id) as IDropdownOption ? props.getValues(props.id).key : props.defaultValue}
          multiSelect={false}
          tabIndex={props.tabIndex}
          disabled={props.disabled !== undefined ? props.disabled : false}
        />
      )}
    />
  );
};