import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react"
import { useCallback } from "react"
import { addParametersToUrl, removeParametersFromUrl } from "../Helpers/Utils"
import AuthService from "../services/auth"
import { useAppSourceContext } from "./useAppSourceContext"
export const getFullPathUrl = (url: string, pathname?: string) => {
    if (!url)
        return ''
    const _url = new URL(url)
    return _url.origin + (pathname ?? _url.pathname) + _url.search;
}
const loginWithRedirect = (connectionUrl: string, urlToRedirect: string) => {
    if (!urlToRedirect)
        return
    const relayState = Buffer.from(urlToRedirect!).toString('base64')
    window.location.assign(`${connectionUrl}&RelayState=${relayState}`)
}
export const getLinkUrl = (document: string, params: { [key: string]: string }) => {
    let pathname = ''
    if (document == 'form') {
        pathname = '/link/application'
    } else if (document === 'update') {
        pathname = '/link/document'
    } else if (document === 'campaign') {
        pathname = '/campaign'
    }
    const url = addParametersToUrl(window.location.href, params)
    return getFullPathUrl(url, pathname)
}
const getRedirectOptions = (redirectUri: string, options?: any) => {
    const _redirectUri = removeParametersFromUrl(redirectUri, ['error', 'code', 'state', 'error_description'])
    let loginOptions: RedirectLoginOptions = {
        redirectUri: _redirectUri,
        scope: 'openid profile email',
        appState: {
            returnTo: _redirectUri,
        },
        prompt: options.prompt ?? 'login',
        ...options
        //connection: 'AzureSAML',
    }
    return loginOptions
}
type ValidateIssuerRequest = {
    orgId?: string
    templateId: string
    owner: string
    onBehalf: string
}
type SAMLProviderRequest = {
    Provider?: string
}

export const useSSO = () => {
    const { isAuthenticated: auth0Authenticated, user, loginWithRedirect: loginWithRedirectAuth0 } = useAuth0();

    const { source, setSSO } = useAppSourceContext()

    const _setSSO = useCallback((options?: typeof source.sso) => {
        setSSO(prev => ({ ...prev, ...options }))
    }, [setSSO, source])


    const validateUser = useCallback(async (request: ValidateIssuerRequest) => {
        const url = new URL(window.location.href)
        const urlParams = url.searchParams
        const errorResponse = urlParams.get('error')
        if (errorResponse === 'consent_required') {
            let loginOptions = getRedirectOptions(window.location.href, {
                connection: request.orgId
            })
            await loginWithRedirectAuth0(loginOptions)
            return
        }
        _setSSO({ orgId: request.orgId })

        AuthService.validateUser({ TemplateId: request.templateId, UserName: request.owner, TargetUser: request.onBehalf }).then(async ({ data }: any) => {
            if (!data.success) {
                _setSSO({
                    isLoaded: true,
                    isValid: false,
                })
                return
            }
            loginWithRedirect(data.result, window.location.href)
        }).catch(async (error) => {
            _setSSO({
                isLoaded: true,
                isValid: false,
            })
        })

    }, [_setSSO, loginWithRedirectAuth0])

    const samlProvider = useCallback(async (request: SAMLProviderRequest) => {
        _setSSO({ orgId: request.Provider })

        AuthService.SAMLProvider({ Provider: request.Provider }).then(async ({ data }: any) => {
            if (!data.success) {
                _setSSO({
                    isLoaded: true,
                    isValid: false,
                })
                return
            }
            loginWithRedirect(data.result, window.location.href)
        }).catch(async (error) => {
            _setSSO({
                isLoaded: true,
                isValid: false,
            })
        })

    }, [_setSSO])

    const getImanageContext = useCallback(async (request: SAMLProviderRequest): Promise<Boolean> => {
        let iManageUser: any = sessionStorage.getItem("iManageContextUser")

        if (iManageUser == undefined || iManageUser === null || iManageUser === "") {
            return new Promise<Boolean>((resolve, reject) => {
                reject(false)
            } )
        }

        return new Promise<Boolean>((resolve, reject) => {
            _setSSO({ orgId: request.Provider })
            let userContext = JSON.parse(iManageUser)
            AuthService.SAMLCreate({ Provider: userContext.email }).then(async (data: any) => {
                if (data.data.success) {
                    _setSSO({
                        isValid: true,
                        isLoaded:true
                    })
                    resolve(true)
                } else {
                    reject(false)
                }
            })
            
        })
    }, [_setSSO])

    const validateSSO = (TemplateId: string, UserName: string) => {
        AuthService.validateSSO({ TemplateId, UserName }).then((claims) => {
            _setSSO({
                isLoaded: true,
                isValid: true,
            })
        }).catch(() => {
            _setSSO({
                isLoaded: true,
                isValid: false,
            })
            return
        })
    }

    const executeLogin = useCallback(async () => {
        if (!source.sso.orgId) {
            return
        }
        await loginWithRedirect(source.sso.orgId, window.location.href)
    }, [source.sso.orgId])

    const redirectToLink = () => {
        const url = new URL(window.location.href);
        const urlParams = url.searchParams
        const document = urlParams.get('document') ?? ''
        const link = getLinkUrl(document, {})

        loginWithRedirect('', link)
    }


    return {
        ...source.sso, validateUser, executeLogin, setSSO: _setSSO, user, redirectToLink, validateSSO, samlProvider, getImanageContext
    }
}