import React from 'react'
import useAppIdle from '../hooks/useAppIdle'
import { Main } from './Main'
type AppMainProps = {
    isLoggedIn: any
    isCookieSetUp: any
}
const AppMain = ({ isLoggedIn, isCookieSetUp }: AppMainProps) => {
    const appIdle = useAppIdle()
    return (
        <Main isLoggedIn={isLoggedIn} isCookieSetUp={isCookieSetUp} />
    )
}
export default AppMain