import { FC } from "react";
import { Controller } from "react-hook-form";
import { ITextFieldProps, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { HookFormProps } from "./HookFormProps";
import { MaskedTextField } from "@fluentui/react";

export const ControlledMaskField: FC<HookFormProps & ITextFieldProps> = (
    props
) => {
    return (
        <Controller
            control={props.control}
            name={props.name}
            rules={props.rules}
            defaultValue={props.defaultValue || ""}
            shouldUnregister={props.shouldUnregister || false}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error }
            }) => (
                <MaskedTextField 
                    {...props}
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    name={fieldName}
                    id={fieldName}
                    errorMessage={error && error.message}
                    disabled={props.readOnly || false}
                    defaultValue={undefined}>
                </MaskedTextField>
                
            )}
        />
    );
};
