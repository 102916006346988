import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { ActionButton, DefaultButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { INavLink, INavLinkGroup, INavStyles, Nav } from '@fluentui/react/lib/Nav';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { useBoolean, useConstCallback, useId } from '@uifabric/react-hooks';
import TemplateGeneral from './TemplateGeneral';
import TemplateFeatures from './TemplateFeatures/TemplateFeatures';
import Helper, {
    IAudienceWithOwnerResponse,
    IBaseRequest,
    ICreateApplicationServiceEmail,
    IDeleteApplicationServiceEmail,
    IDocumentOnlyTemplateResponsebyId,
    IDocumentOnlyTemplateUpdateRequest,
    IServiceEmailRequest,
    ITemplateGeneral
} from '../../Helpers/Helper';
import {
    DialogFooter, getBoundsFromTargetWindow, getMeasurementCache,
    IChoiceGroupOption,
    IDropdownOption,
    IMessageBarStyles,
    MessageBar,
    Stack,
    StackItem
} from '@fluentui/react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import TemplateSystem from './TemplateSystem';
import HelpComponent from '../Help/HelpComponent';
import { ITag } from '@fluentui/react/lib/Pickers';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import Secure from '../../Helpers/Crypto';
import ConfirmationModal from '../../Helpers/Modals/ConfirmationModal';
import ReferenceNumber from './ReferenceNumber';
import OutboundIntegrationList from '../OutboundIntegration/OutboundIntegrationList';
import InboundIntegration from '../InboundIntegration/InboundIntegration';
import Attachments from './attachments/Attachments';
import { useTranslation } from "react-i18next";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import { Text } from '@fluentui/react/lib/Text';
import ApplicationLogs from '../AdminLogs/ApplicationLogs';
import AssemblyRules from './assembly/Rules';
import SidebarModal from '../../share/SidebarModal';
import Assembly from './assembly';
import ContentLibrary from './assembly/ContentLibrary';
import DocumentLibrary from './assembly/DocumentLibrary';
import AutomaticallySubrecords from "./subforms/AutomaticallyGenerateSubrecords/AutomaticallySubrecords";
import { IDocuSignSigners, IDocusignTagsRoles } from "../DataPanel/Signers";
import { useForm } from "react-hook-form";
import RenewalsAndAlerts from "../RenewalsAndAlerts/RenewalsAndAlerts";
import SubFormsProperties from './subforms/SubFormsProperties';
import RestrictSubForms from './subforms/RestrictSubForms/RestrictSubForms';
import { useBranding } from "../../hooks/useBranding";
import EmailTemplate, { IEmailTemplates, EmailType } from "../EmailTemplate";
import { stateEmailTemplatesDefault } from './TemplateFeatures';
import UserService, { IGetuserprofileRequest } from "../../services/User";
import GraphApiService, { IDeleteRequest, IResendRequest } from "../../services/GraphApi";
import ApplicationServiceEmailService, { IGetServiceEmailRequest } from "../../services/ApplicationServiceEmail";
import ContentControlService, { IContentControlRequest } from "../../services/ContentControl";
import { createNotification } from '../../Helpers/toast';
import HttpRequest from "../../services/HttpRequest";


const theme = getTheme();
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
const stackTokens = { childrenGap: 10 };
const menuIcon: IIconProps = { iconName: 'GlobalNavButton' };
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },

};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

export class CTemplateGeneral implements ITemplateGeneral {
    templateName = "";
    Description = "";
    DisplayName = "";
    Tooltip = "";
    Category = "";
    Audience = "";
    fileName = "";
    mimeType = ""
    file = undefined;
    allowesign = false;
    allowcheckinout = false;
    eSignAccountId = "";
    eSignIntegrationKey = "";
    eSignPrivateKey = "";
    eSignUrl = "";
    eSignUserId = "";
    eSignProvider = "";
    PropertyBag = "";
    emailMoniker = "";
    allowNotification = false;
    allowUpdate = false;
    allowSendMail = false;
    defaultDescription = "";
    IsSubForm = false;
    SupressPreview = false;
    DocumentDelivery = "None";
    RedirectURL = "";
    DocumentOnlyAudienceId = undefined;
}
export interface ISignature {
    ClixSignatureTag: string
    DocumentOnlyCustomUserSignatureId: string
    Id: string
    GetBlobData: boolean
}
const ASSEMBLY_MENU_OPTIONS: string[] = ['9', '10', '11', '12']
const SUBFORMS_MENU_OPTIONS: string[] = ['20', '21', '22']

function MyTemplate(props: any) {
    const { branding } = useBranding()
    const [option, setOption] = useState("1")
    const [disableCreate, setDisableCreate] = useState(false)
    const [isRequired, setRequired] = useState(true)
    const [showMessage, setShowMessage] = useState(false)
    const [signatureChecked, setSignatureChecked] = useState(false)
    const [showTemplateGeneral, setTemplateGeneral] = useState(false)
    const [ChekInCheckOut, setChekInCheckOut] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState<IDropdownOption>();
    const [txttemplateName, setTemplateName] = useState("");
    const [selectedAudience, setSelectedAudience] = useState<IAudienceWithOwnerResponse>();
    const [txtDescription, setDescription] = useState("");
    const [txtDisplayName, setDisplayName] = useState("");
    const [txtUniqueReference, setUniqueReference] = useState("");
    const [txtTooltip, setTooltip] = useState("");
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState<string>("");
    const [mimeType, setMimeType] = useState("");
    const titleId = useId("MyTemplate");
    const emptyParams = new CTemplateGeneral();
    const [gettempTemplate, settempTemplate] = useState<ITemplateGeneral>();
    const [selectedKey, setSelectedKey] = useState('');
    const [AudienceSelectedKey, setAudienceSelectedKey] = useState('');
    const [message, setMessage] = useState("The field's are  required!");
    const [isCreating, setIsCreating] = useState(false)
    const [isEdit, setisEdit] = useState(false)
    const [templateId, setTemplateId] = useState("");
    const [showSuccess, setShowSuccess] = useState(false)
    const [showMessageSuccess, setShowMessageSuccess] = useState("false")
    const [showReferenceNumber, setShowReferenceNumber] = useState(false)

    let tempSigners: IDocuSignSigners[] = [];

    const [eSignUrl, setESignUrl] = useState("");
    const [integrationKey, setIntegrationKey] = useState("");
    const [eSignAccountId, setEsignAccountId] = useState("");
    const [userId, setUserId] = useState("");
    const [privateKey, setPrivateKey] = useState("");
    const [selectedProvider, setSelectedProvider] = useState<IDropdownOption>();
    const [providerSelectedKey, setProviderSelectedKey] = useState('');
    const [docuSignSigners, setdocuSignSigners] = useState<IDocuSignSigners[]>();
    const [docusignTagsRoles, setDocusignTagsRoles] = useState<IDocusignTagsRoles[]>([]);
    const [isLoading, setIsLoading] = useState(false)

    const [signatures, setSignatures] = useState<ISignature[]>([]);
    const [signaturesOg, setSignaturesOg] = useState<ISignature[]>([]);
    const [selectedSignature, setSelectedSignature] = useState("0");
    const [showTemplateSystem, setshowTemplateSystem] = useState(false)

    const [showSaveModal, setShowSaveModal] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [selectedCulture, setSelectedCulture] = useState<ITag[]>([{ key: "en-US", name: "English (United States) - [en-US]" }]);
    const [cultureSelectedKey, setCultureSelectedKey] = useState("en-US");
    const [released, setReleased] = useState(false)
    const [txtEmailMoniker, setEmailMoniker] = useState("");
    const [documentNotification, setDocumentNotification] = useState(false)
    const [documentUpdate, setDocumentUpdate] = useState(false)
    const [allowDocx, setAllowDocx] = useState(false)
    const [isOpenMenu, { toggle: setIsOpenMenu }] = useBoolean(false);
    const [allowSendMail, setAllowSendMail] = useState(false)
    const [documentCreation, setDocumentCreation] = useState(false);
    const [documentUpdateNotification, setDocumentUpdateNotification] = useState(false);
    const [documentDelete, setDocumentDelete] = useState(false);
    const [documentShare, setDocumentShare] = useState(false);
    const [templateEdit, setTemplateEdit] = useState(false);
    const [templateShare, setTemplateShare] = useState(false);
    const [templateDelete, setTemplateDelete] = useState(false);
    const [txtDefaultDescription, setDefaultDescription] = useState("");
    const [subform, setsubform] = useState(false);
    const [isDocusign, setisDocusign] = useState(false)
    const [isClixsignature, setisClixsignature] = useState(false)
    const [templateRemove, setTemplateRemove] = useState(false);
    const [useServiceAccount, setUseServiceAccount] = useState(false)
    const [serviceAccountEmail, setServiceAccountEmail] = useState("")
    const [serviceAccountEmailDisabled, setServiceAccountEmailDisabled] = useState(false)
    const [serviceAccountClientID, setServiceAccountClientID] = useState("")
    const [serviceAccountClientSecret, setServiceAccountClientSecret] = useState("")
    const [serviceAccountRedirectURL, setServiceAccountRedirectURL] = useState("")
    const [serviceEmailAccountConfirmationMessage, setServiceEmailAccountConfirmationMessage] = useState("")
    const [showServiceEmailAccountConfirmation, setShowServiceEmailAccountConfirmation] = useState(false)
    const [serviceAccountEmailToggleState, setServiceAccountEmailToggleState] = useState(false);
    const [TotalAutoSign, setTotalAutoSign] = useState(0)
    const [DocumentOnlyTemplateResponsebyId, setDocumentOnlyTemplateResponsebyId] = useState<IDocumentOnlyTemplateResponsebyId>();
    const [isUpdatedAutosign, setisUpdatedAutosign] = useState(false)
    const [attachment, setAttachment] = useState('')
    const [isdeletedClixSignature, setisDeletedClixSignature] = useState(true)
    const [supressPreview, setSupressPreview] = useState(false)
    const [documentDelivery, setDocumentDelivery] = useState("None")
    const [redirectURL, setRedirectURL] = useState("")
    const [imageInfo, setImageInfo] = useState("")
    const [externalPublish, setExternalPublish] = useState(false)
    const [showEmailTemplate, setShowEmailTemplate] = useState(false)
    const [emailType, setEmailType] = useState<EmailType>(0)

    const [ssoProperties, setSSOProperties] = useState({
        enabledCaptcha: false,
        enabledSSO: false
    })
    const [stateEmailTemplates, setStateEmailTemplates] = useReducer(
        (state: IEmailTemplates, newState: Partial<IEmailTemplates>) => ({
            ...state,
            ...newState,
        }),
        stateEmailTemplatesDefault
    );

    const [externalPublishContext, setExternalPublishContext] = useState("")
    const [accessCodeInstructions, setAccessCodeInstructions] = useState("")
    const [profile, setProfile] = useState<any>()
    const methods = useForm();

    const { t } = useTranslation(['common', 'workspace', 'properties', 'automaticallyCreateSubrecords', 'renewalsalerts', 'restrictsubforms', 'managesubforms']);

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '65vw',
        },
        containers: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: window.innerWidth > 1800 ? '45vw' : '65vw',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            height: window.innerHeight > 660 ? '63vh' : '58vh',
            flex: '4 4 auto',
            padding: '0 15px 24px 15px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyGeneral: {
            height: window.innerHeight > 660 ? '63vh' : '58vh',
            flex: '4 4 auto',
            padding: '0 15px 24px 15px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyList: {
            width: '70vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyExample: {
            height: window.innerHeight > 660 ? '63vh' : '58vh',
            flex: '4 4 auto',
            padding: '0 15px 24px 15px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyExamples: {
            height: window.innerHeight > 660 ? '50vh' : '50vh',
            flex: '4 4 auto',
            padding: '0 15px 24px 15px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        containerMail: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '30vw',
            height: '20vh',
        },
        User: {
            width: '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        SppinnerActive: {
            pointerevents: 'none!important'
        }
    });


    const cleanState = () => {
        sessionStorage.setItem("temTemplate", "")
        setOption("1")
        setRequired(true)
        setShowMessage(false)
        setSignatureChecked(false)
        setTemplateGeneral(false)
        setChekInCheckOut(false)
        setSelectedCategory(undefined)
        setTemplateName("")
        setSelectedAudience(undefined)
        setAudienceSelectedKey("")
        setDescription("")
        setDisplayName("")
        setUniqueReference("")
        setTooltip("")
        setFileName("")
        setMimeType("")
        setESignUrl("")
        setEsignAccountId("")
        setUserId("")
        setPrivateKey("")
        setIntegrationKey("")
        setSelectedProvider(undefined)
        settempTemplate(emptyParams)
        setSelectedKey('')
        setisEdit(false)
        setMessage("")
        setShowSuccess(false)
        setShowSaveModal(false)
        setDisableCreate(false)
        setSelectedCulture([{ key: "en-US", name: "English (United States) - [en-US]" }])
        setCultureSelectedKey("en-US")
        setEmailMoniker("")
        setDocumentNotification(false)
        setDocumentUpdate(false)
        setAllowDocx(false)
        setSSOProperties({
            enabledCaptcha: false,
            enabledSSO: false
        })
        setAllowSendMail(false)
        setDocumentCreation(false);
        setDocumentUpdateNotification(false)
        setDocumentDelete(false);
        setDocumentShare(false);
        setTemplateEdit(false);
        setTemplateShare(false);
        setTemplateDelete(false);
        setDefaultDescription("")
        setSignatures([])
        setSignaturesOg([])
        //setSelectedSignature({ key: "0", text: "0" })
        setsubform(false)
        setisDocusign(false)
        setisClixsignature(false)
        setTemplateRemove(false)
        setUseServiceAccount(false)
        setServiceAccountEmailToggleState(false)
        setServiceAccountEmail("")
        setServiceAccountClientID("")
        setServiceAccountClientSecret("")
        setServiceAccountRedirectURL("")
        setServiceAccountEmailDisabled(false)
        setisDeletedClixSignature(false)
        setSupressPreview(false)
        setDocumentDelivery("None")
        setRedirectURL("")
        setImageInfo("")
        setExternalPublish(false)
        setExternalPublishContext("")
        setAccessCodeInstructions("")
        setShowEmailTemplate(false)
        setStateEmailTemplates(stateEmailTemplatesDefault)
    }


    const navStyles: Partial<INavStyles> = {
        root: {

        },
        groupContent: {
            marginBottom: "0px",
        },
        link: {
            whiteSpace: 'normal',
            lineHeight: 'inherit',
        },
        chevronIcon: {
            display: 'none'
        },
        chevronButton: {
            display: 'none'
        },
    };

    /**
     * callback EmailTemplate
     * @param status
     * @param _emailType
     */
    const callbackSetEmailTemplate = (status: boolean, _emailType: EmailType = -1) => {

        setEmailType(_emailType)
        setShowEmailTemplate(status)
    }

    /**
     * callbackCloseEmailTemplate
     * @param _emailType
     * @param onBeforeClose
     * @param templateStatus
     */
    const callbackCloseEmailTemplate = (_emailType: EmailType = -1, onBeforeClose: boolean = false, templateStatus: boolean | undefined = undefined) => {

        if (onBeforeClose) {

            switch (_emailType) {
                case EmailType.DOCUMENTDELETE:
                    setStateEmailTemplates({ ...stateEmailTemplates, DocumentDelete: templateStatus })
                    break;
                case EmailType.DOCUMENTCREATION:
                    setStateEmailTemplates({ ...stateEmailTemplates, DocumentCreation: templateStatus })
                    break;
                case EmailType.DOCUMENTSHARE:
                    setStateEmailTemplates({ ...stateEmailTemplates, DocumentShare: templateStatus })
                    break;
                case EmailType.DOCUMENTUPDATE:
                    setStateEmailTemplates({ ...stateEmailTemplates, DocumentUpdate: templateStatus })
                    break;
                case EmailType.WIZARDSUBMISSIONCREATE:
                    setStateEmailTemplates({ ...stateEmailTemplates, WizardSubmissionCreate: templateStatus })
                    break;
                case EmailType.WIZARDSUBMISSIONUPDATE:
                    setStateEmailTemplates({ ...stateEmailTemplates, WizardSubmissionUpdate: templateStatus })
                    break;
            }
        }
        callbackSetEmailTemplate(false, -1)
    }


    const isAssemblyMenuExpanded = useMemo(() => ASSEMBLY_MENU_OPTIONS.includes(option), [option])
    const isSubformMenuExpanded = useMemo(() => SUBFORMS_MENU_OPTIONS.includes(option), [option])

    const optionsUpdateTemplate: INavLinkGroup[] = (props.item !== undefined && (props.item.Owner || props.item.CoOwner)) ? [
        {
            name: '',
            links: [
                { name: 'Properties', url: '', key: '1', icon: 'DocumentManagement', title: t('title.properties', { ns: 'workspace' }) },
                { name: 'Settings', url: '', key: '2', icon: 'DocumentApproval' },
                { name: 'API Inbound Integration', url: '', key: '99', icon: 'WebAppBuilderFragment' },
                { name: 'API Outbound Integration', url: '', key: '100', icon: 'WebAppBuilderFragment' },
                { name: 'Attachments', url: '', key: '14', icon: 'Attach' },
                {
                    name: t('label.menuProperty', { ns: 'managesubforms' }), url: '', key: '20', icon: 'FormLibrary', isExpanded: isSubformMenuExpanded, onRenderLink: () => {
                    }, links: [
                        { name: t('label.menuProperty', { ns: 'automaticallyCreateSubrecords' }), url: '', key: '21', icon: 'StackIndicator' },
                        { name: t('label.menuProperty', { ns: 'restrictsubforms' }), url: '', key: '22', icon: 'RemoveFromShoppingList' }
                    ]
                },
                {
                    name: 'Document Assembly', url: '', key: '9', icon: 'ChangeEntitlements', isExpanded: isAssemblyMenuExpanded, onRenderLink: () => {
                    }, links: [
                        { name: 'Rules', url: '', key: '10', icon: 'QuestionnaireMirrored' },
                        { name: 'Content Library', url: '', key: '11', icon: 'InsertTextBox' },
                        { name: 'Document Library', url: '', key: '12', icon: 'FabricDocLibrary' },
                    ]
                },
                { name: 'Logs', url: '', key: '15', icon: 'BacklogList' },
                { name: 'Reference Number', url: '', key: '16', icon: 'TaskManager' },
                { name: t('label.menuProperty', { ns: 'renewalsalerts' }), url: '', key: '18', icon: 'WorkItemAlert' }

            ]
        }
    ]
        :
        [
            {
                name: '',
                links: [
                    { name: 'Properties', url: '', key: '1', icon: 'DocumentManagement', title: t('title.properties', { ns: 'workspace' }) },
                    { name: 'Settings', url: '', key: '2', icon: 'DocumentApproval' }
                ]
            }
        ]

    const optionsnewTemplate: INavLinkGroup[] = [
        {
            name: '',
            links: [
                { name: t('label.properties', { ns: 'workspace' }), url: '', key: '1', icon: 'DocumentManagement', title: t('title.properties', { ns: 'workspace' }) },
                { name: t('label.examples', { ns: 'workspace' }), url: '', key: '3', icon: 'Globe2', title: t('title.examples', { ns: 'workspace' }) },
                {
                    name: t('label.guides', { ns: 'workspace' }), url: `${branding.helpPath}#howdoi`, key: '5', target: '_blank', isExpanded: true, links: [
                        { name: t('label.gettingStarted', { ns: 'workspace' }), url: '/1.%20Getting%20Started%20-%20Login%20and%20First%20Time%20User%20Experience%20June%202022.pdf', key: '11', icon: 'lightbulb', target: '_blank' },
                        { name: t('label.tag', { ns: 'workspace' }), url: '/2.%20Tag%20It%20-%20How%20to%20Tag%20a%20Document.pdf', key: '6', icon: 'lightbulb', target: '_blank' },
                        { name: t('label.upload', { ns: 'workspace' }), url: '/3.%20Upload%20It%20-%20How%20to%20Upload%20a%20Document.pdf', key: '7', icon: 'lightbulb', target: '_blank' },
                        { name: t('label.automate', { ns: 'workspace' }), url: '/4.%20Automate%20It%20-%20How%20to%20Automate%20a%20Document.pdf', key: '8', icon: 'lightbulb', target: '_blank' },
                        { name: t('label.workspace', { ns: 'workspace' }), url: '/5.%20Kim%20Document%20Workspace%20June%202022.pdf', key: '12', icon: 'lightbulb', target: '_blank' },
                        { name: t('label.collaboration', { ns: 'workspace' }), url: '/6.%20Collaboration%20in%20Kim%20Document%20May%202022.pdf', key: '9', icon: 'lightbulb', target: '_blank' },
                        { name: t('label.compliance', { ns: 'workspace' }), url: '/7.%20Compliance%20Campaigns%20in%20Minutes%20with%20Kim%20Document%20May%202022.pdf', key: '10', icon: 'lightbulb', target: '_blank' },
                        { name: t('label.webApps', { ns: 'workspace' }), url: '/8.%20Web%20Applications%20-%20From%20Your%20Docs%20to%20Web%20Applications%20in%20Minutes.pdf', key: '13', icon: 'lightbulb', target: '_blank' },
                        { name: t('label.aPI', { ns: 'workspace' }), url: '/9.%20APIs%20June%202022.pdf', key: '14', icon: 'lightbulb', target: '_blank' },
                    ]
                },
                { name: t('label.automateDocument', { ns: 'workspace' }), url: `${branding.helpPath}#automation`, key: '4', icon: 'WorkFlow', target: '_blank' }

            ]
        }
    ]

    const getOptionsNewTemplate = (): INavLinkGroup[] => {
        if (!branding.showDefaultExamplesSection) {
            let hola = optionsnewTemplate.map((item: INavLinkGroup) => {
                item.links = item.links.filter((item: INavLink) => item.key !== "3" && item.key !== "5" && item.key !== "4")
                return item
            })
            return hola
        } else {
            return optionsnewTemplate;
        }

    }

    const getLinkGroup = () => {
        let linkGroup = props.newTemplate ? getOptionsNewTemplate() : optionsUpdateTemplate;
        return linkGroup
    }

    const onLinkClick = (ev?: React.MouseEvent<HTMLElement, MouseEvent>, item?: INavLink) => {
        setOption(item!.key!)

        if ((item!.key!) === '2') {
        }
        else if ((item!.key!) === '3') {
            setshowTemplateSystem(true)
        }
        else if ((item!.key!) === '16') {
            setShowReferenceNumber(true)
        }
    }

    useEffect(() => {
        if (props.item !== undefined) {
            console.log(`Go Get The Template`)
            setTemplateId(props.item.key)
            getTemplateId(props.item.key)
        }
        setTemplateGeneral(true)
        if (props.optionKey !== undefined) {
            setOption(props.optionKey)
        }
        getProfile().then()
    }, [props.item, props.optionKey])

    const getProfile = async () => {

        const request: IGetuserprofileRequest = {
            UserName: Helper.getUsername(),
            Profile: Helper.getUsername()
        }
        return await UserService.GetUserProfile(request)
            .then((data) => {
                setProfile(data)
            })
            .catch()
            .finally()
    }

    function _onChangesignatureChecked(isChecked: boolean) {
        setSignatureChecked(isChecked);
    }

    function _onChangeChekInCheckOut(isChecked: boolean) {
        setChekInCheckOut(isChecked)
    }

    function _onChangeAllowNotification(isChecked: boolean) {
        setDocumentNotification(isChecked)
    }

    function _onChangeAllowUpdate(isChecked: boolean) {
        setDocumentUpdate(isChecked)
    }

    function _onChangeAllowDocx(isChecked: boolean) {
        setAllowDocx(isChecked)
    }

    function _onChangeAllowSendMail(isChecked: boolean) {
        setAllowSendMail(isChecked)
    }
    function _onChangeDocumentCreation(isChecked: boolean) {
        setDocumentCreation(isChecked);
    }
    function _onChangeDocumentUpdateNotification(isChecked: boolean) {
        setDocumentUpdateNotification(isChecked);
    }
    function _onChangeDocumentDelete(isChecked: boolean) {
        setDocumentDelete(isChecked);
    }
    function _onChangeDocumentShare(isChecked: boolean) {
        setDocumentShare(isChecked);
    }
    function _onChangeTemplateEdit(isChecked: boolean) {
        setTemplateEdit(isChecked);
    }
    function _onChangeTemplateShare(isChecked: boolean) {
        setTemplateShare(isChecked);
    }
    function _onChangeTemplateDelete(isChecked: boolean) {
        setTemplateDelete(isChecked);
    }
    function _onChangeTemplateRemove(isChecked: boolean) {
        setTemplateRemove(isChecked);
    }

    function onChangeSupressPreview(isChecked: boolean) {
        setSupressPreview(isChecked);
    }

    function _onChangeDocumentDelivery(option?: IChoiceGroupOption | undefined) {
        setDocumentDelivery(option === undefined ? "None" : option.key)
    }

    function _onChangeRedirectURL(value: string) {
        setRedirectURL(value);
    }

    function onChangeExternalPublish(isChecked: boolean) {
        setExternalPublish(isChecked);
    }

    function _onChangeExternalPublishContext(value: string) {
        setExternalPublishContext(value);
    }

    function _onChangeAccessCodeInstructions(value: string) {
        setAccessCodeInstructions(value);
    }

    const cancel = () => {
        if (isModified) {
            setShowSaveModal(true)
            setShowReferenceNumber(false)
        }
        else {
            props.resetExamples()
            cleanState()
            props.toggleUploadModal(false)
            props.togglenewtemplate(false)
            setShowReferenceNumber(false)
            setOption("1")
        }
    }

    const cancelSaveModal = (option: number) => {
        switch (option) {
            case 1:
                setShowSaveModal(false)
                ValidTemplateParams("2")
                break;

            case 2:
                setShowSaveModal(false)
                break;

            case 3:
                setShowSaveModal(false)
                setIsModified(false)
                cleanState()
                props.toggleUploadModal(false)
                props.togglenewtemplate(false)
                setOption("1")
                break;
            case 4:
                props.resetExamples()
                cleanState()
                props.toggleUploadModal(false)
                props.togglenewtemplate(false)
                setShowReferenceNumber(false)
                setOption("1")
                break
        }
    }

    const isValidURL = (url: string) => {
        try {
            return Helper.validateURL(url.trim())


        } catch (err) {
            return false;
        }
    }

    const ValidTemplateParams = async (step: string) => {
        setRequired(false)
        setShowMessage(false)
        setMessage("");
        let signatureMessage = await ValidSignatures()
        let isDeletedAutosign = signatureMessage == "clixsignature deleted";

        if (txtDisplayName === "") {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.nameRequired', { ns: 'properties' }));
        }
        else if (txtTooltip === "") {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.tooltipRequired', { ns: 'properties' }));
        }
        else if (txtDescription === "") {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.descriptionRequired', { ns: 'properties' }));
        }
        else if (txtEmailMoniker === "") {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.emailRequired', { ns: 'properties' }));
        }
        else if (selectedKey === "") {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.categoryRequired', { ns: 'properties' }));
        }

        else if (cultureSelectedKey === "") {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.cultureRequired', { ns: 'properties' }));
        }
        else if (txttemplateName === "") {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.fileChoseRequired', { ns: 'properties' }));
        }
        else if (selectedKey.length > 30) {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.categoryLength', { ns: 'properties' }));
        }
        else if (!isEdit && (fileName === "" || mimeType === "")) {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.fileRequired', { ns: 'properties' }));
        }

        else if (useServiceAccount && serviceAccountEmail === "") {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.emailServiceRequired', { ns: 'properties' }));
        }
        else if (useServiceAccount && !Helper.isEmailValid(serviceAccountEmail)) {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.emailServiceNotValid', { ns: 'properties' }));
        }
        else if (!isEdit) {
            setRequired(false)
            setShowMessage(false)
            setMessage("")
            goToBuilder(step)
        }
        else if (signatureMessage != "" && !isDeletedAutosign) {
            setRequired(true)
            setShowMessage(true)
            setMessage(signatureMessage);
        }
        else if (redirectURL.trim() !== "" && !isValidURL(redirectURL)) {
            setRequired(true)
            setShowMessage(true)
            setMessage(t('text.redirectURLRequired', { ns: 'properties' }));
        }
        else {
            Updatetemplate(isDeletedAutosign)
        }
    }

    const ValidSignatures = async () => {
        let result = ""
        if (signatureChecked) {
            let isRequerid = signatureChecked && (DocumentOnlyTemplateResponsebyId!.IsDocusign && DocumentOnlyTemplateResponsebyId!.isClixsignature)
            let isAutosignRequired = signatureChecked && DocumentOnlyTemplateResponsebyId!.isClixsignature && !DocumentOnlyTemplateResponsebyId!.IsDocusign
            let isProviderIsRequired = signatureChecked && DocumentOnlyTemplateResponsebyId!.IsDocusign && !DocumentOnlyTemplateResponsebyId!.isClixsignature
            if (isRequerid) {
                if ((selectedProvider?.key == "" || selectedProvider?.key === undefined || selectedProvider?.key == "None") && (selectedSignature == "0") || Number(selectedSignature) > 0 && selectedProvider?.key != "None") {
                    result = "Please select one signature type."
                }
            }
            else if (isAutosignRequired) {
                if (selectedSignature == "0") {
                    result = "The auto-signers specified are required.";
                }
            }
            else if (isProviderIsRequired) {
                if (selectedProvider?.key == "" || selectedProvider?.key === undefined || selectedProvider?.key == "None") {
                    result = "Provider is required for eSign process.";
                }
                else if (isUpdatedAutosign && selectedProvider?.key !== "None") {
                    await deleteClixSig(templateId)
                    setisDeletedClixSignature(true)
                    result = "clixsignature deleted";
                }
            }
        }
        return result;
    }

    /**
     * callback for the MaxApplicationAllowed
     */
    const callbackMaxApplicationAllowed = (message: string, status: boolean) => {
        setMessage(message)
        setShowMessage(status)
        setDisableCreate(status)
    }

    const callbackLoading = (status: boolean) => {
        setIsLoading(status)
    }

    const _successMessageProps = (message: string) => {
        setShowSuccess(true)
        setMessage(message)
        setTimeout(function () {
            setShowSuccess(false)
        }, 2500);
    }

    const _errorMessageProps = (message: string) => {
        setShowMessage(true)
        setMessage(message)
        setTimeout(function () {
            setShowMessage(false)
        }, 5000);
    }

    const showNotification = useConstCallback((message: string, success: boolean) => {
        if (success) {
            _successMessageProps(message)
        } else {
            _errorMessageProps(message)
        }
    })

    function _onChangesubFormChecked(isChecked: boolean) {
        setsubform(isChecked);
    }

    async function goToBuilder(step: string) {
        setIsCreating(true)
        let finaly = false
        let accountId = Helper.getDefaultAccount()
        let username = Helper.getUsername()
        let sessionKey = Helper.getSessionKey()
        let site = await Helper.getSite()
        let audience = ""
        let cat = ""
        let provider = ""
        let valBase64 = ""
        if (selectedKey !== undefined) {
            cat = selectedKey.toString()
        }
        if (selectedAudience !== undefined) {
            audience = selectedAudience.Id.toString()
        }
        if (selectedProvider !== undefined) {
            provider = selectedProvider!.key.toString()
        }
        if (privateKey != null && privateKey != "") {
            valBase64 = Buffer.from(privateKey!).toString('base64')
        }


        const request: IContentControlRequest = {
            AccessCodeInstructions: accessCodeInstructions,
            EnabledCaptcha: ssoProperties.enabledCaptcha,
            EnabledSSO: ssoProperties.enabledSSO,
            AllowDocx: allowDocx,
            AllowSendMail: allowSendMail,
            Allownotification: documentNotification,
            Allowupdate: documentUpdate,
            Audience: audience,
            Category: cat,
            ChekInCheckOut: ChekInCheckOut,
            Culture: cultureSelectedKey,
            CultureName: selectedCulture[0].name,
            DefaultDescription: txtDefaultDescription,
            Description: txtDescription,
            DisplayName: txtDisplayName,
            DocumentCreation: documentCreation,
            DocumentDelete: documentDelete,
            DocumentDelivery: documentDelivery,
            DocumentShare: documentShare,
            DocumentUpdate: documentUpdateNotification,
            EmailMoniker: txtEmailMoniker,
            EsignAccountId: eSignAccountId,
            ExternalPublish: externalPublish,
            ExternalPublishContext: externalPublishContext,
            File: file!,
            Filename: fileName,
            ImageInfo: imageInfo,
            IntegrationKey: integrationKey,
            MimeType: mimeType,
            PrivateKey: valBase64,
            Provider: provider,
            RedirectURL: redirectURL,
            Released: false,
            SignatureChecked: signatureChecked,
            Subform: subform,
            SupressPreview: supressPreview,
            TemplateDelete: templateDelete,
            TemplateEdit: templateEdit,
            TemplateName: txttemplateName,
            TemplateRemove: templateRemove,
            TemplateShare: templateShare,
            Tooltip: txtTooltip,
            UniqueReference: txtUniqueReference,
            Url: eSignUrl,
            UserId: userId
        }
        await ContentControlService.ContentControl(request)
            .then((response) => {
                if (response.contentControls !== null && response.contentControls.length > 0) {
                    props.getContentControls(response.contentControls);
                    props.getTemplateId(response.template.result);
                    setFileName(fileName);

                    props.reloadApp(site)
                    setOption("1")

                    if (isUpdatedAutosign) {
                        addClixSig(response.template.result)
                    }
                    finaly = true
                }
                else if (response.template !== undefined && response.template !== null) {
                    props.getContentControls([]);
                    props.getTemplateId(response.template.result);
                    setFileName(fileName);
                    props.reloadApp(site)
                    setShowSuccess(true)
                    setMessage("Template saved successfully!")
                    finaly = true
                    setOption("1")
                }
                props.templateName(txtDisplayName)
                props.toggleFormBuilder(false)
            })
            .catch((error: any) => {
                props.getContentControls([]);
                props.templateName(txtDisplayName)
                setIsCreating(false)
                props.toggleFormBuilder(false)
                setMessage(Helper.GetErrorMessage(error))
                setShowMessage(true)
                setOption("1")
            })

        setTimeout(function () {

            if (finaly) {
                props.templateName(txtDisplayName)
                props.toggleFormBuilder(true)
                cancelSaveModal(3)
                setIsCreating(false)
            }
        }, 6000);
    }

    const getTemplateId = async (templateId: string) => {
        setIsCreating(true)
        setDocusignTagsRoles([])
        await Helper.getDocumentOnlyTemplateId(templateId, false).then(data => {

            Init(data, parseInt(templateId))
        })
    }

    const Init = useConstCallback((data: any, tempId: number) => {
        if (data !== undefined && data !== null) {

            setDocumentOnlyTemplateResponsebyId(data as IDocumentOnlyTemplateResponsebyId)

            setTemplateName(data.Description)
            setSignatureChecked(data.AllowEsign)
            setChekInCheckOut(data.AllowCheckInOut)
            setDescription(data.Description)
            setDisplayName(data.DisplayName)
            setTooltip(data.DisplayNameToolTip)
            setFileName(data.FileName)
            setSelectedKey(data.Category)
            setAudienceSelectedKey(data.DocumentOnlyAudienceId)
            setESignUrl(data.eSignURL)
            setIntegrationKey(data.eSignIntegrationKey)
            setEsignAccountId(data.eSignPassword)
            setUserId(data.eSignUserId)
            setSelectedProvider({ key: data.eSignProvider, text: data.eSignProvider })
            setPrivateKey(data.PropertyBag)
            setProviderSelectedKey(data.eSignProvider)
            setdocuSignSigners(data.DocusignTags)
            setDocumentNotification(data.AllowNotification)
            setDocumentUpdate(data.AllowDocumentUpdate)
            var _isDocu = data.IsDocusign != undefined && data.IsDocusign != null ? data.IsDocusign : false;
            setisDocusign(_isDocu)
            var _isClixsig = data.isClixsignature != undefined && data.isClixsignature != null ? data.isClixsignature : false
            setisClixsignature(_isClixsig)
            setTotalAutoSign(data.TotalAutosign != undefined ? data.TotalAutosign : 0)
            setImageInfo(data.ImageInfo !== null ? data.ImageInfo : "")
            setExternalPublish(data.ExternalPublish !== null ? data.ExternalPublish : false)
            setExternalPublishContext(data.ExternalPublishContext !== null ? data.ExternalPublishContext.trim() : "")
            setAccessCodeInstructions(data.PropertyBag ? JSON.parse(data.PropertyBag)?.accessCodeInstructions?.trim() : "")

            setSSOProperties({
                enabledCaptcha: data.PropertyBag ? JSON.parse(data.PropertyBag)?.EnabledCaptcha : false,
                enabledSSO: data.PropertyBag ? JSON.parse(data.PropertyBag)?.EnabledSSO : false,
            })
            if (data.emailMoniker !== "") {
                setEmailMoniker(data.emailMoniker)
            }

            if (data.UniqueReference !== "") {
                setUniqueReference(data.UniqueReference)
            }

            if (data.PropertyBag != null && data.PropertyBag.length > 0) {
                try {
                    var jsonBag = JSON.parse(data.PropertyBag)
                    if (jsonBag != null) {

                        setDocusignTagsRoles(jsonBag.DocusignTagsRoles)
                        setCultureSelectedKey(jsonBag.Culture)
                        setReleased(jsonBag.Released)
                        setEmailMoniker(jsonBag.emailMoniker)
                        setUniqueReference(jsonBag.UniqueReference)
                        setAllowDocx(jsonBag.AllowDocX)
                        setAllowSendMail(jsonBag.AllowSendMail)
                        setDocumentCreation(jsonBag.DocumentCreation);
                        setDocumentUpdateNotification(jsonBag.DocumentUpdate);
                        setDocumentDelete(jsonBag.DocumentDelete);
                        setDocumentShare(jsonBag.DocumentShare);
                        setTemplateEdit(jsonBag.TemplateEdit);
                        setTemplateShare(jsonBag.TemplateShare);
                        setTemplateDelete(jsonBag.TemplateDelete);
                        setDefaultDescription(jsonBag.DefaultDescription)
                        setAttachment(JSON.stringify(jsonBag.attachment))
                        setSupressPreview(jsonBag.SupressPreview)
                        setDocumentDelivery(jsonBag.DocumentDelivery)
                        setRedirectURL(jsonBag.RedirectURL ?? "")
                        var _temRemove = jsonBag.TemplateRemove != undefined && jsonBag.TemplateRemove != null ? jsonBag.TemplateRemove : false
                        setTemplateRemove(_temRemove)
                        var tag: ITag = {
                            key: jsonBag.Culture,
                            name: jsonBag.CultureName
                        }
                        setSelectedCulture([tag])
                        setIsCreating(false)

                        if (jsonBag.UseServiceAccount != undefined && jsonBag.UseServiceAccount != null) {

                            const getService = async (templateId: string) => {

                                const request: IGetServiceEmailRequest = {
                                    TemplateId: templateId
                                }

                                await ApplicationServiceEmailService.GetServiceEmail(request)
                                    .then((data) => {
                                        if (data.length > 0) {
                                            setUseServiceAccount(data[0].ServiceEmail !== '')
                                            setServiceAccountEmail(data[0].ServiceEmail)
                                            setServiceAccountEmailDisabled(true)
                                            setServiceAccountClientID(data[0].ClientId)
                                            setServiceAccountClientSecret(data[0].ClientSecret)
                                            setServiceAccountRedirectURL(data[0].RedirectURL)
                                            if (data[0].ClientId != undefined && data[0].ClientId != "") {
                                                setServiceAccountEmailToggleState(true)
                                            }
                                        } else {
                                            setUseServiceAccount(false)
                                        }
                                    })
                                    .catch()
                                    .finally()
                            }

                            getService(tempId.toString())
                        } else {
                            setUseServiceAccount(false)
                        }
                    }
                } catch (error) {
                }
            }
            setisEdit(true)
            setOption("1")
            setsubform(data.IsSubForm)
            sessionStorage.setItem("TemplateContent", data.TemplateContent)
        }
    })

    async function Updatetemplate(isDeletedAutosign: boolean) {
        setIsCreating(true)


        // START DocuSign section form
        tempSigners = docuSignSigners !== undefined ? docuSignSigners : []
        let docuSignTagsRoles: IDocusignTagsRoles[] = tempSigners.map(signer => {
            return {
                RecipientId: signer.recipientId,
                RoleDescription: ""
            };
        });

        if (providerSelectedKey === "Docusign") {

            await methods.handleSubmit((data) => {
                for (const [key, value] of Object.entries(data)) {

                    const inputArray = key.split("_");
                    const signer = docuSignTagsRoles.filter(x => x.RecipientId == inputArray[1]);
                    if (inputArray[0] === "txtSignerRoleDescription") {
                        if (docuSignTagsRoles[docuSignTagsRoles.indexOf(signer[0])] !== undefined) {
                            docuSignTagsRoles[docuSignTagsRoles.indexOf(signer[0])].RoleDescription = value !== undefined ? value : ""
                        }
                    }
                }

            }, () => {

            }
            )();
        }
        // END DocuSign section form

        // tempSigners


        var templateContent = "";
        templateContent = sessionStorage.getItem("TemplateContent")!;
        let cat = ""
        let valBase64 = ""
        if (selectedKey !== undefined) {
            cat = selectedKey.toString()
        }
        let provider = ""

        if (providerSelectedKey !== "" && providerSelectedKey !== "None") {
            provider = providerSelectedKey
        }
        if (privateKey != null && privateKey != "") {
            valBase64 = Buffer.from(privateKey!).toString('base64')
        }

        const site = await Helper.getSite()!
        const sessionKey = Helper.getSessionKey()
        const accountId = Helper.getDefaultAccount()
        const userName = Helper.getUsername()
        var request: IDocumentOnlyTemplateUpdateRequest =
        {
            ApiKey: "-1",
            SK: sessionKey,
            UserName: userName,
            AccountIds: accountId,
            Path: site,
            AccountId: accountId,
            Autoprovision: true,
            Base64HTMLString: templateContent,
            TemplateId: templateId,
            audience: AudienceSelectedKey.toString(),
            allowesign: signatureChecked,
            allowcheckinout: ChekInCheckOut,
            category: cat,
            description: txtDescription,
            buttontext: txtDisplayName,
            tooltip: txtTooltip,
            filename: fileName,
            displayName: txtDisplayName,
            esignurl: eSignUrl,
            esignintegrationkey: integrationKey,
            esignuserid: userId,
            esignpassword: eSignAccountId,
            propertybag: valBase64,
            esignprovider: provider,
            culture: cultureSelectedKey,
            cultureName: selectedCulture[0]?.name!,
            released: released,
            emailMoniker: txtEmailMoniker,
            documentNotification: documentNotification,
            documentUpdate: documentUpdate,
            allowDocx: allowDocx,
            allowSendMail: allowSendMail,
            documentCreation: documentCreation,
            documentUpdateNotification: documentUpdateNotification,
            documentDelete: documentDelete,
            documentShare: documentShare,
            templateEdit: templateEdit,
            templateShare: templateShare,
            templateDelete: templateDelete,
            defaultDescription: txtDefaultDescription,
            subform: subform,
            templateRemove: templateRemove,
            useServiceAccount: serviceAccountEmailToggleState,// useServiceAccount,
            serviceAccountEmail: serviceAccountEmail,
            uniqueReference: txtUniqueReference,
            attachment: attachment,
            docusignTagsRoles: docuSignTagsRoles,
            supressPreview: supressPreview,
            documentDelivery: documentDelivery,
            redirectURL: redirectURL.trim(),
            imageInfo: imageInfo,
            externalPublish: externalPublish,
            externalPublishContext: externalPublishContext,
            accessCodeInstructions: accessCodeInstructions,
            EnabledCaptcha: ssoProperties.enabledCaptcha,
            EnabledSSO: ssoProperties.enabledSSO
        }


        const callbackService = await updateServiceAccount()

        await Helper.UpdateTemplate(request).then(async data => {
            if (data !== undefined) {
                try {
                    if (data.Success) {
                        // reset form
                        methods.reset()
                        if (isUpdatedAutosign) {
                            if (!isDeletedAutosign) {
                                await deleteClixSig(templateId)
                                await addClixSig(templateId)
                            }

                        }
                        props.reloadApp(site)
                        setShowSuccess(true)
                        setShowMessageSuccess("Template Updated Successfully!")
                        setSignatures([])
                        setSignaturesOg([])
                        // setSelectedSignature({ key: "0", text: "0" })
                    }
                    else {
                        setShowMessage(false)
                        setMessage("An error occurred: " + data.ExceptionMessage)
                    }
                }
                catch (error) {
                    console.log(error)
                    setShowMessage(false)
                    setMessage("An error occurred: " + error)
                }

                props.reloadNav(true)
                props.update(callbackService)
                if (props.selectionState.getSelectedIndices().length >= 1)
                    props.selectionState.setAllSelected(false)

                cancelSaveModal(3)
                setIsCreating(false)
            }
        })
    }

    async function updateServiceAccount() {
        const site = await Helper.getSite()!

        if (useServiceAccount && serviceAccountEmailToggleState && serviceAccountEmail !== "") {
            let audienceId = ""
            let audienceOwner = ""
            if (selectedAudience !== undefined) {
                audienceId = selectedAudience.Id.toString()
                audienceOwner = selectedAudience.OwnerUserName!
            }

            let clientID64Secure = serviceAccountClientID;
            let clientSecret64Secure = serviceAccountClientSecret;

            if (serviceAccountClientID !== "" && serviceAccountClientSecret !== "") {
                const urlVerify = `Verify?path=${site}`
                const codeRaw = await fetch(urlVerify, {
                    method: 'GET',
                    headers: await Helper.getHeaders()
                })
                const verify = await codeRaw.text()

                const clientID = Secure.encrypt(serviceAccountClientID, verify)
                const clientID64 = btoa(serviceAccountClientID);
                clientID64Secure = btoa(`${clientID64}.${clientID}.${verify}`)

                const clientSecret = Secure.encrypt(serviceAccountClientSecret, verify)
                const clientSecret64 = btoa(serviceAccountClientSecret);
                clientSecret64Secure = btoa(`${clientSecret64}.${clientSecret}.${verify}`)
            }

            return GraphApiService.GraphApi({
                Approver: serviceAccountEmail,
                Owner: audienceOwner,
                AudienceId: audienceId,
                ServiceEmail: serviceAccountEmail,
                TemplateId: templateId.toString(),
                ClientID: clientID64Secure,
                ClientSecret: clientSecret64Secure,
                RedirectURL: serviceAccountRedirectURL
            }).then((data) => {
                return () => createNotification(t("title.success", { ns: "common" }), t("message.successUpdated", { ns: "common" }))
            }).catch((error) => {
                return () => createNotification(t("title.error", { ns: "common" }), Helper.GetErrorMessage(error), 'danger')
            })


        } else if ((!useServiceAccount || !serviceAccountEmailToggleState) && serviceAccountEmail !== "") {

            let audienceId = ""
            let audienceOwner = ""
            if (selectedAudience !== undefined) {
                audienceId = selectedAudience.Id.toString()
                audienceOwner = selectedAudience.OwnerUserName!
            }

            return GraphApiService.Delete({
                Owner: audienceOwner,
                AudienceId: audienceId,
                TemplateId: templateId.toString(),
            }).then((data) => {
                return () => createNotification(t("title.success", { ns: "common" }), data.Result)
            }).catch((error) => {
                return () => createNotification(t("title.error", { ns: "common" }), error.message ?? 'Unexpected error', 'danger')
            })
        }
    }

    async function deleteClixSig(id: string) {

        const request = {
            Signatures: signaturesOg.map(item => {
                return {
                    DocumentOnlyCustomUserSignatureId: item.DocumentOnlyCustomUserSignatureId.toString(),
                    ClixSignatureTag: item.ClixSignatureTag,
                    Id: item.Id.toString(),
                    GetBlobData: false
                }
            }),
            TemplateId: id,
            Indicator: "",
        }

        await HttpRequest.postWithSession('DeleteTemplateSignature', request)
            .then((data: any) => {
                if (data.Success) {
                    // setMessage("")
                    // setShowMessage(false)
                }
                else {
                    setMessage(data.Message)
                    setShowMessage(true)
                }
            })
            .catch((error) => {
                setMessage(Helper.GetErrorMessage(error))
                setShowMessage(true)
            })
            .finally(() => { })
    }
    async function addClixSig(id: string) {

        const request = {
            Signatures: signatures,
            TemplateId: id,
            Indicator: "",
        }

        await HttpRequest.postWithSession('CreateTemplateSignature', request)
            .then((data: any) => {
                if (data.Success) {

                }
                else {
                    console.log(data.Message)
                }
            })
            .catch((error) => {
                console.log(Helper.GetErrorMessage(error))
            })
            .finally(() => {
            })
    }
    const toggleTemplateSystem = useConstCallback((open: boolean) => {
        setshowTemplateSystem(open)
    })

    const callbackServiceEmailAccountConfirmation = () => {
        if (serviceAccountEmailToggleState) {
            setUseServiceAccount(true)
        } else {
            setUseServiceAccount(false)
        }
        ValidTemplateParams("2")
        setShowServiceEmailAccountConfirmation(false)
    }
    function SaveChangesAlertModal() {
        return (
            <Modal
                isOpen={showSaveModal}
                isBlocking={true}
                containerClassName={contentStyles.containerMail}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id={titleId}>{"Unsaved Changes"}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={() => { cancelSaveModal(2) }}
                                    title="Close."
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.User}>
                                <div className="ms-Grid-col ms-sm12 ms-lg12">
                                    Changes detected. Are you sure you want to exit?
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={() => { cancelSaveModal(3) }} text="Yes" title="Close." />
                                <DefaultButton onClick={() => { cancelSaveModal(2) }} text="No" title="Cancel exit." />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
    const toggleMessage = useConstCallback((value: string, open: boolean) => {
        setMessage(value)
        setShowMessage(open)
    })
    const UpdatedAutosign = async (value: boolean) => {
        setisUpdatedAutosign(value)
    }
    const MapingSignatures = async (data: ISignature[]) => {
        setSignatures(data)
        var selectedSignature: IDropdownOption = { key: data.length.toString(), text: data.length.toString() }
        // setSelectedSignature(selectedSignature)
    }
    const MapingSignaturesog = async (data: ISignature[]) => {

        setSignaturesOg(data);
    }
    const GetSelectedSignature = async (data: string) => {
        setSelectedSignature(data)

    }


    switch (option) {
        case "4": {
            setOption("1");
        }
        case "5": {
            setOption("1");
        }
        case "6": {
            setOption("1");
        }
        case "7": {
            setOption("1");
        }
        case "8": {
            setOption("1");
        }
        case "9": {
            return <SidebarModal showModal={props.showModal} titleId={titleId} title={'Assembly'}
                settingLinks={getLinkGroup()} onLinkClick={onLinkClick} setIsOpenMenu={setIsOpenMenu}
                isOpenMenu={isOpenMenu} closeModal={() => { cancelSaveModal(4) }} helpButton={undefined} selectedKey={'9'}>
                <Assembly templateId={templateId} cancel={cancel} />
            </SidebarModal>
        }
        case "10": {
            return <SidebarModal showModal={props.showModal} titleId={titleId} title={'Assembly Rules'}
                settingLinks={getLinkGroup()} onLinkClick={onLinkClick} setIsOpenMenu={setIsOpenMenu}
                isOpenMenu={isOpenMenu} closeModal={() => { cancelSaveModal(4) }} helpButton={undefined} selectedKey={'10'}>
                <AssemblyRules templateId={Number(templateId)} />
            </SidebarModal>
        }
        case "11": {
            return <SidebarModal showModal={props.showModal} titleId={titleId} title={'Content Library'}
                settingLinks={getLinkGroup()} onLinkClick={onLinkClick} setIsOpenMenu={setIsOpenMenu}
                isOpenMenu={isOpenMenu} closeModal={() => { cancelSaveModal(4) }} helpButton={undefined} selectedKey={'11'}>
                <ContentLibrary templateId={Number(templateId)} />
            </SidebarModal>
        }
        case "12": {
            return <SidebarModal showModal={props.showModal} titleId={titleId} title={'Document Library'}
                settingLinks={getLinkGroup()} onLinkClick={onLinkClick} setIsOpenMenu={setIsOpenMenu}
                isOpenMenu={isOpenMenu} closeModal={() => { cancelSaveModal(4) }} helpButton={undefined} selectedKey={'12'}>
                <DocumentLibrary templateId={Number(templateId)} />
            </SidebarModal>
        }
        case "13": {
            setOption("1");

        }
        case "14": {
            return (
                <Attachments templateId={templateId} closeModal={() => { cancelSaveModal(4) }}
                    showModal={props.showModal} setIsOpenMenu={setIsOpenMenu}
                    isOpenMenu={isOpenMenu} onLinkClick={onLinkClick} titleId={titleId}
                    settingLinks={getLinkGroup()} cancel={cancel} />
            )
        }
        case "20": {
            return <SidebarModal showModal={props.showModal} titleId={titleId} title={'Manage Subforms and Subrecords'}
                settingLinks={getLinkGroup()} onLinkClick={onLinkClick} setIsOpenMenu={setIsOpenMenu}
                isOpenMenu={isOpenMenu} closeModal={() => { cancelSaveModal(4) }} helpButton={undefined} selectedKey={'20'}>
                <SubFormsProperties />
            </SidebarModal>
        }
        case "21": {
            return <SidebarModal showModal={props.showModal} titleId={titleId} title={t('label.menuProperty', { ns: 'automaticallyCreateSubrecords' })}
                settingLinks={getLinkGroup()} onLinkClick={onLinkClick} setIsOpenMenu={setIsOpenMenu}
                isOpenMenu={isOpenMenu} closeModal={() => { cancelSaveModal(4) }} helpButton={undefined} selectedKey={'21'}>
                <AutomaticallySubrecords
                    titleId={titleId}
                    cancel={cancel}
                    getLinkGroup={getLinkGroup()}
                    option={option}
                    setIsOpenMenu={setIsOpenMenu}
                    showModal={props.showModal}
                    isOpenMenu={isOpenMenu}
                    onLinkClick={onLinkClick}
                    item={props.item}
                />
            </SidebarModal>
        }
        case "22": {
            return <SidebarModal showModal={props.showModal} titleId={titleId} title={t('label.menuProperty', { ns: 'restrictsubforms' })}
                settingLinks={getLinkGroup()} onLinkClick={onLinkClick} setIsOpenMenu={setIsOpenMenu}
                isOpenMenu={isOpenMenu} closeModal={() => { cancelSaveModal(4) }} helpButton={undefined} selectedKey={'22'}>
                <RestrictSubForms
                    titleId={titleId}
                    cancel={cancel}
                    getLinkGroup={getLinkGroup()}
                    option={option}
                    setIsOpenMenu={setIsOpenMenu}
                    showModal={props.showModal}
                    isOpenMenu={isOpenMenu}
                    onLinkClick={onLinkClick}
                    item={props.item}
                />
            </SidebarModal>
        }
        case "1": {
            return (
                <>
                    <SaveChangesAlertModal />
                    <Modal
                        isOpen={props.showModal}
                        isBlocking={true}
                        containerClassName={contentStyles.container}
                    >
                        <div className="ms-Grid" dir="ltr">
                            <div style={isCreating ? { pointerEvents: "none" } : {}}>
                                <div className="ms-Grid-row">

                                    <Stack horizontal className={contentStyles.header} wrap={false} >
                                        <StackItem grow={1} >
                                            <Text id={titleId} variant={'xLarge'} >
                                                {props.item !== undefined ? `${t('title.applicationProperties', { ns: 'properties' })} (${props.item!.name})` : t('title.applicationCreate', { ns: 'properties' })}
                                            </Text>
                                        </StackItem>

                                        <Stack horizontal wrap={false}>
                                            <StackItem>
                                                <HelpComponentRelative
                                                    headline={t('title.applicationProperties', { ns: 'properties' })}
                                                    text={t('text.applicationHelp', { ns: 'properties' })}
                                                    right="50px"
                                                    top="15px"
                                                    location={props.item !== undefined ? "applicationproperties" : "addnew"}
                                                />
                                            </StackItem>
                                            <StackItem>
                                                <IconButton
                                                    styles={iconButtonStyles}
                                                    iconProps={cancelIcon}
                                                    ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                                    onClick={cancel}
                                                    title={t('Common.Button.Close', { ns: 'common' })}
                                                />
                                            </StackItem>
                                        </Stack>


                                    </Stack>




                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className={contentStyles.body}>
                                            <div className="ms-Grid" dir="ltr">

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3" >
                                                        <div className="ms-hiddenMdDown">
                                                            <Nav
                                                                groups={getLinkGroup()}
                                                                onLinkClick={onLinkClick}
                                                                selectedKey={option}
                                                                styles={navStyles}
                                                            />

                                                            {props.newTemplate && branding.showCustomExamplesLink &&
                                                                <ActionButton href={`${branding.customExamplesLink}`}
                                                                    target={`${branding.customExamplesLinkTarget}`}
                                                                    iconProps={{ iconName: "AddTo" }}
                                                                    title={t('title.examples', { ns: 'workspace' })}
                                                                    styles={iconButtonStyles}
                                                                >
                                                                    {t('text.examples', { ns: 'workspace' })}
                                                                </ActionButton>
                                                            }
                                                        </div>
                                                        <div className="ms-hiddenLgUp">
                                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                                            <Panel
                                                                isBlocking={false}
                                                                isOpen={isOpenMenu}
                                                                onDismiss={setIsOpenMenu}
                                                                closeButtonAriaLabel="Close"
                                                                type={PanelType.smallFixedNear}
                                                            >
                                                                <Nav
                                                                    groups={getLinkGroup()}
                                                                    onLinkClick={onLinkClick}
                                                                    selectedKey={option}
                                                                    styles={navStyles}
                                                                />

                                                            </Panel>
                                                        </div>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                                                {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                                            </div>
                                                        </div>
                                                        {!isCreating ?
                                                            <div className="ms-Grid" dir="ltr">
                                                                <div className="ms-Grid-row">
                                                                    <div className={contentStyles.bodyGeneral}>
                                                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh" }}>
                                                                            <TemplateGeneral
                                                                                emptyParams={emptyParams}
                                                                                setSelectedAudience={setSelectedAudience}
                                                                                setTemplateName={setTemplateName}
                                                                                setDescription={setDescription}
                                                                                setDisplayName={setDisplayName}
                                                                                setTooltip={setTooltip}
                                                                                setFileName={setFileName}
                                                                                fileName={fileName}
                                                                                setFile={setFile}
                                                                                selectedAudience={selectedAudience}
                                                                                setMimeType={setMimeType}
                                                                                showTemplateGeneral={true}
                                                                                cancelStep={() => { props.toggleUploadModal(false) }}
                                                                                settempTemplate={settempTemplate}
                                                                                txttemplateName={txttemplateName}
                                                                                gettemTemplate={gettempTemplate}
                                                                                txtDescription={txtDescription}
                                                                                setSelectedKey={setSelectedKey}
                                                                                selectedKey={selectedKey}
                                                                                txtDisplayName={txtDisplayName}
                                                                                txtUniqueReference={txtUniqueReference}
                                                                                txtTooltip={txtTooltip}
                                                                                isEdit={isEdit}
                                                                                setAudienceSelectedKey={setAudienceSelectedKey}
                                                                                AudienceSelectedKey={AudienceSelectedKey}
                                                                                setSelectedCategory={setSelectedCategory}
                                                                                newTemplate={props.newTemplate}
                                                                                setIsModified={setIsModified}
                                                                                cultureSelectedKey={cultureSelectedKey}
                                                                                setCultureSelectedKey={setCultureSelectedKey}
                                                                                setSelectedCulture={setSelectedCulture}
                                                                                txtEmailMoniker={txtEmailMoniker}
                                                                                setEmailMoniker={setEmailMoniker}
                                                                                selectedCulture={selectedCulture}
                                                                                toggleMessage={toggleMessage}
                                                                                txtDefaultDescription={txtDefaultDescription}
                                                                                setDefaultDescription={setDefaultDescription}
                                                                                siteSelected={props.siteSelected}
                                                                                templateId={templateId}
                                                                                useServiceAccount={useServiceAccount}
                                                                                setUseServiceAccount={setUseServiceAccount}
                                                                                serviceAccountEmail={serviceAccountEmail}
                                                                                serviceAccountEmailDisabled={serviceAccountEmailDisabled}
                                                                                setServiceAccountEmail={setServiceAccountEmail}
                                                                                serviceAccountClientID={serviceAccountClientID}
                                                                                setServiceAccountClientID={setServiceAccountClientID}
                                                                                serviceAccountClientSecret={serviceAccountClientSecret}
                                                                                setServiceAccountClientSecret={setServiceAccountClientSecret}
                                                                                serviceAccountRedirectURL={serviceAccountRedirectURL}
                                                                                setServiceAccountRedirectURL={setServiceAccountRedirectURL}
                                                                                showNotification={showNotification}
                                                                                showServiceEmailAccountConfirmation={showServiceEmailAccountConfirmation}
                                                                                setShowServiceEmailAccountConfirmation={setShowServiceEmailAccountConfirmation}
                                                                                setServiceEmailAccountConfirmationMessage={setServiceEmailAccountConfirmationMessage}
                                                                                setServiceAccountEmailToggleState={setServiceAccountEmailToggleState}
                                                                                setImageInfo={setImageInfo}
                                                                                imageInfo={imageInfo}
                                                                                profile={profile}
                                                                                callbackMaxApplicationAllowed={callbackMaxApplicationAllowed}
                                                                                callbackLoading={callbackLoading}
                                                                            />
                                                                            <div>
                                                                                <pre>
                                                                                    {emptyParams.templateName}
                                                                                </pre>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-Grid-row">
                                                                    {isLoading && props.item === undefined &&
                                                                        <Spinner size={SpinnerSize.medium} label={t('text.spinnerVerifying', { ns: 'common' })} ariaLive="assertive" />
                                                                    }
                                                                </div>

                                                            </div>

                                                            :
                                                            <div style={{ maxWidth: "100%", minWidth: "35vw", height: "70vh" }}>
                                                                <>
                                                                    <Stack tokens={stackTokens} styles={{ root: { paddingRight: "10px" } }}>
                                                                        <label />


                                                                        <Spinner size={SpinnerSize.medium} label={props.item === undefined ? t('text.spinnerCreating', { ns: 'common' }) : t('text.spinnerUpdating', { ns: 'common' })} ariaLive="assertive" />
                                                                    </Stack>
                                                                </>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-row" style={{ bottom: "0px", right: "12px", position: "relative" }}>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <DialogFooter>
                                            <PrimaryButton id="btnCreateUpdate" onClick={() => { ValidTemplateParams("2") }} text={props.item === undefined ? t('Common.Button.Create', { ns: 'common' }) : t('Common.Button.Update', { ns: 'common' })} disabled={disableCreate} title={props.item === undefined ? t('label.createButton', { ns: 'workspace' }) : t('label.updateButton', { ns: 'workspace' })} />
                                            <DefaultButton id="btnCancel" onClick={cancel} text={t('Common.Button.Cancel', { ns: 'common' })} title={t('Common.Button.Cancel', { ns: 'common' })} />
                                        </DialogFooter>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <ConfirmationModal
                        showConfirmationModal={showServiceEmailAccountConfirmation}
                        setShowConfirmationModal={setShowServiceEmailAccountConfirmation}
                        callbackConfirmationModal={callbackServiceEmailAccountConfirmation}
                        //args={[allowDowngradeUpgrade]}
                        title={t('text.emailRemove', { ns: 'properties' })}
                        textYesButton={t('Common.Button.Yes', { ns: 'common' })}
                        message={serviceEmailAccountConfirmationMessage}
                        titleYesButton={t('Common.Button.Yes', { ns: 'common' })}
                        HideNoButton={false}
                    />
                </>
            );
        }
        case "2": {
            return (
                <>
                    <SaveChangesAlertModal />
                    <Modal
                        isOpen={props.showModal}
                        isBlocking={true}
                        containerClassName={contentStyles.container}
                    >
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm8 ms-md10 ms-lg10">
                                    <div className={contentStyles.header}>
                                        <span id={titleId}>{props.item !== undefined ? `Application Settings (${props.item!.name})` : "Create New Application"}</span>
                                    </div>
                                </div>
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm4 ms-md2 ms-lg2">
                                    <div className={contentStyles.header}>
                                        <HelpComponent
                                            headline="Settings"
                                            text="Manage document edit, update, notification and electronic signature capability."
                                            right="50px"
                                            top="15px"
                                            location="properties"
                                        />
                                        <IconButton
                                            styles={iconButtonStyles}
                                            iconProps={cancelIcon}
                                            ariaLabel="Close popup modal"
                                            onClick={cancel}
                                            title="Close."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={contentStyles.bodyExample}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey={option}
                                                styles={navStyles}
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey={option}
                                                    styles={navStyles}
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                        {showMessage ? <MessageBar
                                            styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                        {showSuccess ? <MessageBar
                                            styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                        <div className="scrollVisible" style={{
                                            overflow: "auto",
                                            overflowX: "hidden",
                                            maxWidth: "100%",
                                            height: "60vh"
                                        }}>
                                            <TemplateFeatures
                                                isCreating={isCreating}
                                                cancelStep={cancel}
                                                signatureChecked={signatureChecked}
                                                checkinCheckOut={_onChangeChekInCheckOut}
                                                selectChekInCheckOut={ChekInCheckOut}
                                                onChangeSignature={_onChangesignatureChecked}
                                                setChekInCheckOut={setChekInCheckOut}
                                                setSignatureChecked={setSignatureChecked}
                                                docusignTagsRoles={docusignTagsRoles}
                                                file={file}
                                                setUrl={setESignUrl}
                                                setAccountId={setEsignAccountId}
                                                ssoProperties={ssoProperties}
                                                setSSOProperties={setSSOProperties}
                                                setUserId={setUserId}
                                                setIntegrationKey={setIntegrationKey}
                                                setPrivateKey={setPrivateKey}
                                                setSelectedProvider={setSelectedProvider}
                                                url={eSignUrl}
                                                accountId={eSignAccountId}
                                                userId={userId}
                                                integrationKey={integrationKey}
                                                privateKey={privateKey}
                                                setProviderSelectedKey={setProviderSelectedKey}
                                                providerSelectedKey={providerSelectedKey}
                                                selectedProvider={selectedProvider}
                                                setIsModified={setIsModified}
                                                onChangeAllowUpdate={_onChangeAllowUpdate}
                                                allowUpdate={documentUpdate}
                                                onChangeAllowNotification={_onChangeAllowNotification}
                                                allowNotification={documentNotification}
                                                allowDocx={allowDocx}
                                                onChangeAllowDocx={_onChangeAllowDocx}
                                                allowSendMail={allowSendMail}
                                                onChangeAllowSendMail={_onChangeAllowSendMail}
                                                documentCreation={documentCreation}
                                                documentUpdateNotification={documentUpdateNotification}
                                                onChangeDocumentCreation={_onChangeDocumentCreation}
                                                onChangeDocumentUpdateNotification={_onChangeDocumentUpdateNotification}
                                                documentDelete={documentDelete}
                                                onChangeDocumentDelete={_onChangeDocumentDelete}
                                                documentShare={documentShare}
                                                onChangeDocumentShare={_onChangeDocumentShare}
                                                templateEdit={templateEdit}
                                                onChangeTemplateEdit={_onChangeTemplateEdit}
                                                templateShare={templateShare}
                                                onChangeTemplateShare={_onChangeTemplateShare}
                                                templateDelete={templateDelete}
                                                onChangeTemplateDelete={_onChangeTemplateDelete}
                                                _onChangesubFormChecked={_onChangesubFormChecked}
                                                subform={subform}
                                                isDocusign={isDocusign}
                                                docuSignSigners={docuSignSigners}
                                                isClixsignature={isClixsignature}
                                                deleteClixSig={deleteClixSig}
                                                templateId={templateId}
                                                templateRemove={templateRemove}
                                                onChangeTemplateRemove={_onChangeTemplateRemove}
                                                selectedAudience={selectedAudience}
                                                ServiceAccountEmailToggleState={serviceAccountEmailToggleState}
                                                TotalAutoSign={TotalAutoSign}
                                                MapingSignatures={MapingSignatures}
                                                MapingSignaturesog={MapingSignaturesog}
                                                UpdatedAutosign={UpdatedAutosign}
                                                DocumentOnlyTemplateResponsebyId={DocumentOnlyTemplateResponsebyId}
                                                siteSelected={props.siteSelected}
                                                GetSelectedSignature={GetSelectedSignature}
                                                selectedSignature={selectedSignature}
                                                methods={methods}
                                                supressPreview={supressPreview}
                                                onChangeSupressPreview={onChangeSupressPreview}
                                                documentDelivery={documentDelivery}
                                                onChangeDocumentDelivery={_onChangeDocumentDelivery}
                                                redirectURL={redirectURL}
                                                onChangeRedirectURL={_onChangeRedirectURL}
                                                externalPublish={externalPublish}
                                                onChangeExternalPublish={onChangeExternalPublish}
                                                externalPublishContext={externalPublishContext}
                                                onChangeExternalPublishContext={_onChangeExternalPublishContext}
                                                accessCodeInstructions={accessCodeInstructions}
                                                onChangeAccessCodeInstructions={_onChangeAccessCodeInstructions}
                                                profile={profile}
                                                callbackSetEmailTemplate={callbackSetEmailTemplate}
                                                stateEmailTemplates={stateEmailTemplates}
                                                setStateEmailTemplates={setStateEmailTemplates}
                                            />
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <Stack horizontal styles={{ root: { paddingTop: 8, alignItems: "center" } }}>

                                                <StackItem grow={1}>{" "}</StackItem>
                                                {isCreating &&
                                                    <StackItem>
                                                        <Spinner styles={{ root: { marginRight: 8 } }}
                                                            size={SpinnerSize.medium} labelPosition={"left"}
                                                            label={t('text.spinnerUpdating', { ns: 'common' })}
                                                            ariaLive="assertive" />
                                                    </StackItem>
                                                }

                                                <StackItem>
                                                    <PrimaryButton styles={{ root: { marginRight: 8 } }}
                                                        disabled={isCreating}
                                                        onClick={() => {
                                                            ValidTemplateParams("3")
                                                        }} text="Update"
                                                        title="Update application settings." />
                                                    <DefaultButton onClick={cancel} text="Cancel"
                                                        title="Close without saving" />
                                                </StackItem>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>


                    <EmailTemplate
                        templateId={templateId}
                        showModal={showEmailTemplate}
                        setShowModal={callbackCloseEmailTemplate}
                        emailType={emailType}
                    />

                </>
            );
        }
        case "3": {
            return (
                <>
                    <SaveChangesAlertModal />
                    <Modal
                        isOpen={props.showModal}
                        isBlocking={true}
                        containerClassName={contentStyles.containers}
                    >
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm8 ms-md10 ms-lg10">
                                    <div className={contentStyles.header}>
                                        <span id={titleId}>{t('title.examplesTitle', { ns: 'properties' })}</span>
                                    </div>
                                </div>
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm4 ms-md2 ms-lg2">
                                    <div className={contentStyles.header}>
                                        <HelpComponentRelative
                                            headline={t('title.examplesTitle', { ns: 'properties' })}
                                            text={t('text.examplesHelp', { ns: 'properties' })}
                                            right="50px"
                                            top="15px"
                                            location="addnew"
                                        />
                                        <IconButton
                                            styles={iconButtonStyles}
                                            iconProps={cancelIcon}
                                            ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                            onClick={cancel}
                                            title={t('Common.Button.Close', { ns: 'common' })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={contentStyles.bodyExamples}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey={option}
                                                styles={navStyles}
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey={option}
                                                    styles={navStyles}
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                        <TemplateSystem
                                            showTemplateSystem={showTemplateSystem}
                                            templateList={props.templateList}
                                            toggleTemplateSystem={toggleTemplateSystem}
                                            cancelStep={cancel}
                                            reloadApp={props.reloadApp}
                                        />
                                        <br />
                                    </div>

                                    <div className="ms-Grid-row" style={{
                                        bottom: "10px", right: "12px",
                                    }}>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <DialogFooter>
                                                <DefaultButton onClick={cancel} text={t('Common.Button.Close', { ns: 'common' })} title={t('Common.Button.Close', { ns: 'common' })} />
                                            </DialogFooter>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        }
        case "99": {
            return (
                <>
                    <SaveChangesAlertModal />
                    <Modal
                        isOpen={props.showModal}
                        isBlocking={true}
                        containerClassName={contentStyles.container}
                    >
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm8 ms-md10 ms-lg10">
                                    <div className={contentStyles.header}>
                                        <span id={titleId}>{`API Inbound Integration (${props.item!.name})`}</span>
                                    </div>
                                </div>
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm4 ms-md2 ms-lg2">
                                    <div className={contentStyles.header}>
                                        <HelpComponent
                                            headline="API Inbound Integration"
                                            text="Manage Api Integration Token services."
                                            right="50px"
                                            top="15px"
                                            location="properties"
                                        />
                                        <IconButton
                                            styles={iconButtonStyles}
                                            iconProps={cancelIcon}
                                            ariaLabel="Close popup modal"
                                            onClick={cancel}
                                            title="Close."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={contentStyles.bodyExample}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey={option}
                                                styles={navStyles}
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey={option}
                                                    styles={navStyles}
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                        {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                        {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh" }}>
                                            <InboundIntegration
                                                templateId={templateId}
                                                showSuccessMessage={_successMessageProps}
                                                showErrorMessage={_errorMessageProps} />
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <DialogFooter>
                                            </DialogFooter>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        }
        case "16": {
            return (
                <>
                    <SaveChangesAlertModal />
                    <Modal
                        isOpen={props.showModal}
                        isBlocking={true}
                        containerClassName={contentStyles.container}
                    >
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm8 ms-md10 ms-lg10">
                                    <div className={contentStyles.header}>
                                        <span id={titleId}>{`Manage Reference Number (${props.item!.name})`}</span>
                                    </div>
                                </div>
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm4 ms-md2 ms-lg2">
                                    <div className={contentStyles.header}>
                                        <HelpComponent
                                            headline="Reference Number "
                                            text={`${branding.appName} creates a reference number placeholder for every new application that is created. Reference numbers generate independently of tags however for special tagging of reference numbers within webforms, please see the link to the Webform Wizard How To Guide.`}
                                            right="50px"
                                            top="15px"
                                            location="referencenumber"
                                        />
                                        <IconButton
                                            styles={iconButtonStyles}
                                            iconProps={cancelIcon}
                                            ariaLabel="Close popup modal"
                                            onClick={cancel}
                                            title="Close."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={contentStyles.bodyExample}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey={option}
                                                styles={navStyles}
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey={option}
                                                    styles={navStyles}
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                        {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                        {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh" }}>
                                            <ReferenceNumber
                                                templateId={templateId}
                                                showSuccessMessage={_successMessageProps}
                                                showErrorMessage={_errorMessageProps}
                                                site={Helper.getSiteSelected()}
                                                showReferenceNumber={showReferenceNumber} />

                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <DialogFooter>
                                            </DialogFooter>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        }
        case "17": {
            return (
                <>
                    <AutomaticallySubrecords
                        titleId={titleId}
                        cancel={cancel}
                        getLinkGroup={getLinkGroup()}
                        option={option}
                        setIsOpenMenu={setIsOpenMenu}
                        showModal={props.showModal}
                        isOpenMenu={isOpenMenu}
                        onLinkClick={onLinkClick}
                        item={props.item}
                    />
                </>
            )
        }
        case "18": {
            return (
                <>
                    <RenewalsAndAlerts
                        titleId={titleId}
                        cancel={cancel}
                        getLinkGroup={getLinkGroup()}
                        option={option}
                        setIsOpenMenu={setIsOpenMenu}
                        showModal={props.showModal}
                        isOpenMenu={isOpenMenu}
                        onLinkClick={onLinkClick}
                        item={props.item}
                    />
                </>
            )


        }
        case "100": {
            return (
                <>
                    <SaveChangesAlertModal />
                    <Modal
                        isOpen={props.showModal}
                        isBlocking={true}
                        containerClassName={contentStyles.container}
                    >
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm8 ms-md10 ms-lg10">
                                    <div className={contentStyles.header}>
                                        <span id={titleId}>{`API Outbound Integration (${props.item!.name})`}</span>
                                    </div>
                                </div>
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm4 ms-md2 ms-lg2">
                                    <div className={contentStyles.header}>
                                        <HelpComponent
                                            headline="API Outbound Integration"
                                            text="Manage Api Integration Token services."
                                            right="50px"
                                            top="15px"
                                            location="properties"
                                        />
                                        <IconButton
                                            styles={iconButtonStyles}
                                            iconProps={cancelIcon}
                                            ariaLabel="Close popup modal"
                                            onClick={cancel}
                                            title="Close."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={contentStyles.bodyExample}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey={option}
                                                styles={navStyles}
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey={option}
                                                    styles={navStyles}
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                        {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                        {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh" }}>
                                            <OutboundIntegrationList
                                                templateId={templateId}
                                                showSuccessMessage={_successMessageProps}
                                                showErrorMessage={_errorMessageProps} />
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <DialogFooter>
                                            </DialogFooter>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        }
        case "15": {
            return (
                <>
                    <SaveChangesAlertModal />
                    <Modal
                        isOpen={props.showModal}
                        isBlocking={true}
                        containerClassName={contentStyles.container}
                    >
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm8 ms-md10 ms-lg10">
                                    <div className={contentStyles.header}>
                                        <span id={titleId}>{`Logs (${props.item!.name})`}</span>
                                    </div>
                                </div>
                                <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm4 ms-md2 ms-lg2">
                                    <div className={contentStyles.header}>
                                        <HelpComponent
                                            headline="Logs"
                                            text="Manage Application logs."
                                            right="50px"
                                            top="15px"
                                            location="properties"
                                        />
                                        <IconButton
                                            styles={iconButtonStyles}
                                            iconProps={cancelIcon}
                                            ariaLabel="Close popup modal"
                                            onClick={cancel}
                                            title="Close."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={contentStyles.bodyExample}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                        <div className="ms-hiddenMdDown">
                                            <Nav
                                                groups={getLinkGroup()}
                                                onLinkClick={onLinkClick}
                                                selectedKey={option}
                                                styles={navStyles}
                                            />
                                        </div>
                                        <div className="ms-hiddenLgUp">
                                            <IconButton iconProps={menuIcon} style={{ marginLeft: "-4px", paddingLeft: "0px" }} title="Menu" onClick={setIsOpenMenu} />
                                            <Panel
                                                isBlocking={false}
                                                isOpen={isOpenMenu}
                                                onDismiss={setIsOpenMenu}
                                                closeButtonAriaLabel="Close"
                                                type={PanelType.smallFixedNear}
                                            >
                                                <Nav
                                                    groups={getLinkGroup()}
                                                    onLinkClick={onLinkClick}
                                                    selectedKey={option}
                                                    styles={navStyles}
                                                />
                                            </Panel>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                        {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                        {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh" }}>
                                            <ApplicationLogs TemplateId={Number(templateId)} />
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <DialogFooter>
                                            </DialogFooter>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            );
        }
        default: {
            return (
                <></>
            );
        }
    }
}

export default MyTemplate