import HttpRequest from "../../../../services/HttpRequest";

const AutomaticallySubrecordsService = {

    getSubforms: async (request: RequestSettings): Promise<IClientResponse> => {
        let {data}: any = await HttpRequest.getWithSession('AutomaticallySubFormTemplates', request)

        return new Promise<IClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({err: 'Server error'})
            resolve(data)
        })
    },

    postSubforms: async (request: SavingSettings): Promise<IClientResponse> => {
        let {data}: any = await HttpRequest.postWithSession('AutomaticallySubFormTemplates', request)

        return new Promise<IClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({err: 'Server error'})
            resolve(data)
        })
    }
}

export interface IDocumentResponse {

    SubscriptionStatusMessage: string
    ActiveSubscription: boolean
    OwnerFullName: string
    OwnerDisplayName: string
    OwnerUserName: string
    Id: string
    AccountId: string
    TemplateId: string
    DocumentOnlyAudienceId: string
    FileName: string
    Category: string
    Description: string
    DisplayName: string
    DisplayNameToolTip: string
    AllowDocumentUpdate: boolean
    AllowNotification: boolean
    CoOwner: boolean
    CreateDocument: boolean
    AudienceName: string
    CheckedTemplate: boolean

}

interface IClientResponse {
    success: boolean
    bag?: string
    result?: string
    messageError?: string
}

interface RequestSettings {
    TemplateId: number
    DocumentOnlyAudienceId: number
}

export interface Template {
    TemplateId: number
    TemplateName: string
}

interface SavingSettings {
    TemplateId: number
    DocumentOnlyAudienceId: number
    Templates: Template[]
}

export default AutomaticallySubrecordsService