import React, { useEffect } from 'react';
import { Panel, PanelType, } from '@fluentui/react/lib/Panel';
import { useConstCallback } from '@uifabric/react-hooks';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { useBoolean } from '@uifabric/react-hooks';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles, ActionButton, IButtonProps } from '@fluentui/react/lib/Button';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { IIconProps, Icon } from '@fluentui/react/lib/Icon';
import { useTranslation } from "react-i18next";
import { useBranding } from '../../hooks/useBranding';

const theme = getTheme();
const classNames = mergeStyleSets({

});

function HelpComponent(props: any) {
    const { branding } = useBranding()
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
    const { t } = useTranslation(['common']);
    const PrimaryButtonProps: IButtonProps = React.useMemo(
        () => ({
            children: 'More info',
            onClick: goToHelp,
        }),
        [props.location],
    );
    const iconButtonStyles = {
        root: {
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
       
    };
    const helpIcon: IIconProps = { iconName: 'Help' };

    function showHelp(ev: React.MouseEvent<HTMLElement>) {
        toggleTeachingBubbleVisible()
    }
    function goToHelp() {
        if (props.location === undefined
            || props.location === null
            || props.location === "") {
            window.open(`${branding.helpPath}`, '_blank');
        } else {
            window.open(`${branding.helpPath}#${props.location}`, '_blank');
        }

    }
    useEffect(() => {
        setTimeout(function () {
            var e = document.getElementsByClassName("indexTabHelp") as HTMLCollectionOf<HTMLElement>
            for (let i = 0; i < e.length; i++) {
                e[i].tabIndex = 1;
            }
        }, 500);
    }, [])

    return (
        <>
            <div style={{ position: props.position ?? "absolute", right: props.right, top: props.top }}>
                <IconButton
                    className="indexTabHelp"
                    id={props.target ?? "help"}
                    styles={iconButtonStyles}
                    iconProps={helpIcon}
                    ariaLabel="Help"
                    onClick={showHelp}
                    title="Help"
                />
            </div>
            {teachingBubbleVisible && (
                <TeachingBubble
                    target={"#" + (props.target ?? "help")}
                    headline={props.headline}
                    hasCloseButton
                    closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common' })}
                    primaryButtonProps={sessionStorage.getItem("entityId") === "Not In Teams" ? PrimaryButtonProps : undefined}
                    onDismiss={toggleTeachingBubbleVisible}
                >
                    {props.text}
                </TeachingBubble>
            )}
        </>
    );
}

export default HelpComponent

