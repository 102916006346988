import React, { createContext, useEffect, useState } from 'react';
import AudienceService from "../services/audience";
import AuthService from '../services/auth';
import useAuth from '../hooks/useAuth';


/**
 * IContextSession Interface
 */
export interface IContextSession {
    context: {
        refreshRenewals: boolean
        setRefreshRenewals: (value: boolean) => void
        countRefreshRenewals: number,
        setCountRefreshRenewals: (value: number) => void
        showSettings: number | undefined,
        setShowSettings: (value: number | undefined) => void,
        ownAudiences: number
    }
}


const initContext: IContextSession = {
    context: {
        refreshRenewals: false,
        setRefreshRenewals: () => {
        },
        countRefreshRenewals: 0,
        setCountRefreshRenewals: () => {
        },
        showSettings: undefined,
        setShowSettings: () => {
        },
        ownAudiences: 0
    }
}

/**
 * Children properties
 */
interface PropsChildren {
    children?: React.ReactNode
}


export const AppContext = createContext<IContextSession>(initContext)


const Provider: React.FC<PropsChildren> = ({ children }: PropsChildren) => {
    const [refreshRenewals, setRefreshRenewals] = useState(false)
    const [countRefreshRenewals, setCountRefreshRenewals] = useState(0)

    /**
     * Show the settings window according a specified Id menu
     */
    const [showSettings, setShowSettings] = useState<number | undefined>(undefined)

    /**
     * Audiences that the user owns
     */
    const [ownAudiences, setOwnAudiences] = useState(0)
    const { isAuthenticated } = useAuth()


    useEffect(() => {
        GetOwnAudiences().then()
    }, [isAuthenticated])

    const GetOwnAudiences = async () => {
        setOwnAudiences(0)
        if (await AuthService.isAuthenticateExists()) {
            AudienceService.GetBillingClientAudiences()
                .then((data) => {
                    setOwnAudiences(data.filter((item: any) => item.Owner === true).length)
                })
                .catch()
                .finally()
        }
    }

const value: IContextSession = {
    context: {
        refreshRenewals,
        setRefreshRenewals,
        countRefreshRenewals,
        setCountRefreshRenewals,
        setShowSettings,
        showSettings,
        ownAudiences
    }
}

return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default Provider