import { IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFTextBoxControl } from '../../../RHFControls/RHFTextBoxControl';

export interface SecurityProps {
    templateId: number;
    showSuccessMessage: (message: string) => void;
    showErrorMessage: (message: string) => void;
    isConfigurationTemplateReadMode?: boolean;
}

const verticalStyle = mergeStyles({ height: '200px' });

const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};

const Bearer = (props: SecurityProps) => {
    const { control, setValue, getValues, trigger } = useFormContext();

    const getDefaultValue = (id: any): any => {
        let currentValue: any = getValues(id)
        let tempCurrentValue: any = currentValue !== undefined ? currentValue : "";
        setValue(id, tempCurrentValue)
        return tempCurrentValue
    }

    return (
        <Stack horizontal tokens={stackTokens}>
            <Stack.Item className={verticalStyle}>
                <RHFTextBoxControl
                    id={"BearerToken"}
                    label={"Bearer Token:"}
                    disabled={props.isConfigurationTemplateReadMode ? true : false}
                    title={"Enter Bearer Token"}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    defaultValue={getDefaultValue("BearerToken")}
                />
            </Stack.Item>
        </Stack>
    )
}

export default Bearer