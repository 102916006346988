import { ActionButton, DefaultButton, IButtonStyles, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, { useEffect, useState } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import HelpComponent from '../Help/HelpComponent'
import Helper, { IServiceEmailRequest, ICreateApplicationServiceEmail, IDeleteSMTPEmail, ISMTPEmailRequest, IApplicationSMTPServiceEmails, ISMTPEmailbyTemplateId, IApplicationGRAPHAPIServiceEmails, ISendSMTPEmailRequest } from '../../Helpers/Helper';
import { IContextualMenuProps, IMessageBarStyles, IContextualMenuItem, MessageBar } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { isMobile } from 'react-device-detect';
import { useConstCallback } from '@uifabric/react-hooks';
import {useBranding} from "../../hooks/useBranding";
import GraphApiService, {IResendRequest, ITestRequest} from "../../services/GraphApi";
import ApplicationServiceEmailService, {IGetServiceEmailRequest} from "../../services/ApplicationServiceEmail";

const theme = getTheme();


const cancelIcon: IIconProps = { iconName: 'Cancel' };
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};

const emptySMTPMailService: IApplicationSMTPServiceEmails = {
    Id: 0,
    TemplateId: 0,
    ServiceEmail: "",
    Host: "",
    Port: 0,
    Username: "",
    Pwd: ""
}

const emptyGraphMailService: IApplicationGRAPHAPIServiceEmails = {
    ServiceEmail: "",
    ClientId: "",
    ClientSecret: "",
    RedirectURL: "",
    AccessToken: "",
    RefreshToken: ""
}

function CampaignDelivery(props: any) {
    const { branding } = useBranding()
    const [showSMTPInputs, setshowSMTPInputs] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [message, setMessage] = useState("")
    const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'left', height: 'inherit' } };
    const [InputValid, setInputValid] = useState(false)
    const [ServiceEmail, setServiceEmail] = useState("")
    const [Host, setHost] = useState("")
    const [Port, setPort] = useState("")
    const [Username, setUsername] = useState("")
    const [Pwd, setPwd] = useState("")
    const [disableFields, setDisableFields] = useState(false)

    const iconModalButtonStyles = {
        root: {
            color: branding.theme.palette.themePrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },

    };
    
    const menuItems1: IContextualMenuItem[] = [

        {
            key: 'resendConsentEmail',
            text: 'Resend Consent Email',
            title: 'Resend Consent Email.',
            onClick: () => {
                integrateGraphApi();
            },
        }       
    ];

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '60vw',
            maxWidth: '500px'
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
        }
    });

    const menuItems2: IContextualMenuItem[] = [

        {
            key: 'testEmailConnection',
            text: 'Test Email Connection',
            title: 'Test Email Connection',
            onClick: () => {
                testEmailConnection()
            },
        },
    ];

    const menuProps1: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems1,
    };

    const menuProps2: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems2,
    };

    useEffect(() => {
        if (props.showCampaignDelivery && props.SMTPServiceEmail.ServiceEmail != "") {
            setDisableFields(true)
            setServiceEmail(props.SMTPServiceEmail.ServiceEmail)
            setHost(props.SMTPServiceEmail.Host)
            setPort(props.SMTPServiceEmail.Port)
            setUsername(props.SMTPServiceEmail.Username)
            setPwd(props.SMTPServiceEmail.Pwd)
            setshowSMTPInputs(true)
        }
    }, [props.showCampaignDelivery])

    useEffect(() => {
        var result = true;
        if (ServiceEmail.trim().length === 0 || !Helper.isEmailValid(ServiceEmail)) result = false;
        if (Host.trim().length === 0) result = false;
        if (Port.toString().trim().length === 0) result = false;
        if (Username.trim().length === 0 || !Helper.isEmailValid(ServiceEmail)) result = false;
        if (Pwd.trim().length === 0) result = false;
        setInputValid(result);
    }, [ServiceEmail, Host, Port, Pwd, Username])

    useEffect(() => {
        if(props.TemplateId){
            getServiceGraph(props.TemplateId.toString()).then()
            getServiceSMTP(props.TemplateId.toString()).then()
        }
    },[props.TemplateId])


    const testSMTP = async () => {
        const req: ISendSMTPEmailRequest = {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            AttachmentIds: "",
            Autoprovision: true,
            DocumentId: "-1",
            DocumentOnly: false,
            EmailBody: "Test SMTP Compliance Email Service",
            EmailMoniker: "",
            EmailSubject: "Test SMTP",
            FilingId: "-1",
            FromEmail: props.SMTPServiceEmail.ServiceEmail,
            IsHTML: true,
            Path: await Helper.getSite(),
            SendCaseDocument: false,
            SendPDF: false,
            SK: Helper.getSessionKey(),
            TemplateId: props.SMTPServiceEmail.TemplateId.toString(),
            ToEmail: Helper.getUsername(),
            UserName: Helper.getUsername()
        }
        let response = await Helper.SendSMTPEmail(req)
        if (response === "") {
            setShowSuccessMessage(true)
            setMessage("SMTP test email has been send.")
        } else {
            setShowErrorMessage(true)
            setMessage(response.toString())
        }
        setTimeout(function () {
            setShowSuccessMessage(false)
            setShowErrorMessage(false)
            setMessage("")
        }, 5000);
    }

    const addSMTPConfiguration = async () => {
        setshowSMTPInputs(true);
    }

    const getServiceGraph = async (templateId: string) => {

        const request: IGetServiceEmailRequest = {
            TemplateId: templateId
        }

        await ApplicationServiceEmailService.GetServiceEmail(request)
            .then(async (data) => {
                if (data.length > 0) {
                    const serviceModel: IApplicationGRAPHAPIServiceEmails = {
                        ServiceEmail: data[0].ServiceEmail,
                        ClientId: data[0].ClientId,
                        ClientSecret: data[0].ClientSecret,
                        RedirectURL: data[0].RedirectURL,
                        AccessToken: data[0].AccessToken,
                        RefreshToken: data[0].RefreshToken
                    }
                    props.setGRAPHAPIServiceEmail(serviceModel)
                } else {
                    await props.setGRAPHAPIServiceEmail(emptyGraphMailService)
                }
            })
            .catch()
            .finally()

    }

    const getServiceSMTP = async (templateId: string) => {
        const rqst: ISMTPEmailbyTemplateId = {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            TemplateId: templateId,
            UserName: Helper.getUsername(),
            Autoprovision: true,
        }
        var data = await Helper.GetSMTPEmailTemplateId(rqst)
        if (data.length > 0) {
            const serviceModel: IApplicationSMTPServiceEmails = {
                Id: data[0].Id,
                TemplateId: data[0].TemplateId,
                ServiceEmail: data[0].ServiceEmail,
                Host: data[0].Host,
                Port: data[0].Port,
                Username: data[0].Username,
                Pwd: data[0].Pwd,
            }
            props.setSMTPServiceEmail(serviceModel)
        }
        else {
            await props.setSMTPServiceEmail(() => ({...emptySMTPMailService}))
        }
    }

    const saveSMTPConfiguration = async () => {
        setIsLoading(true)
        const req: ISMTPEmailRequest = {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Host: Host,
            operation: "Create",
            Path: await Helper.getSite(),
            Port: Port,
            Pwd: Pwd,
            ServiceEmail: ServiceEmail,
            SK: Helper.getSessionKey(),
            TemplateId: Number(props.TemplateId),
            UserName: Helper.getUsername(),
            UsernameSMTP: Username,
            //TODO check WithEncrypt
            WithEncrypt: false
        }
        await Helper.CreateUpdateSMTPEmail(req).then(response => {
            setIsLoading(false)
            if (response.Success) {
                setShowSuccessMessage(true)
                setMessage(response.Result)
                getServiceGraph(props.TemplateId.toString())
                getServiceSMTP(props.TemplateId.toString())
                _onCloseModal()

            } else {
                setShowErrorMessage(true)
                setMessage(response.Result)
            }
            setTimeout(function () {
                setShowSuccessMessage(false)
                setShowErrorMessage(false)
                setMessage("")
            }, 5000);
        })
    }

    const deleteSMTP = async () => {
        setIsLoading(true)
        const req: IDeleteSMTPEmail = {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Id: props.SMTPServiceEmail.Id,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            TemplateId: props.SMTPServiceEmail.TemplateId,
            UserName: Helper.getUsername()
        }
        let response = await Helper.DeleteSMTPEmail(req)

        if (response.Success) {
            setIsLoading(false)
            setShowSuccessMessage(true)
            setMessage(response.Result)
            
            _onCloseModal()
        } else {
            setIsLoading(false)
            setShowErrorMessage(true)
            setMessage(response.Result)
        }
    }

    const integrateGraphApi = async () => {
        if (props.GRAPHAPIServiceEmail.ServiceEmail !== "" && Helper.isEmailValid(props.GRAPHAPIServiceEmail.ServiceEmail)) {

            const request:IResendRequest = {
                Approver: props.GRAPHAPIServiceEmail.ServiceEmail,
                Owner: props.OwnerUser,
                AudienceId: props.DocumentOnlyAudienceId.toString(),
                ServiceEmail: props.GRAPHAPIServiceEmail.ServiceEmail,
                TemplateId: props.TemplateId.toString(),
                ClientID: props.GRAPHAPIServiceEmail.ClientID,
                ClientSecret: props.GRAPHAPIServiceEmail.ClientSecret,
                RedirectURL: props.GRAPHAPIServiceEmail.RedirectURL
            }
            await GraphApiService.Resend(request)
                .then(()=> {
                    setShowSuccessMessage(true)
                    setMessage("Email Sent Correctly. If you do not see the email in a few minutes, check your \"junk mail\" folder or \"spam folder\"")
                })
                .catch((error)=> {
                    setShowErrorMessage(true)
                    setMessage(Helper.GetErrorMessage(error))
                })
                .finally()

            setTimeout(function () {
                setShowSuccessMessage(false)
                setShowErrorMessage(false)
                setMessage("")
            }, 5000);

        }
    }

    const testEmailConnection = async () => {
        if (props.GRAPHAPIServiceEmail.ServiceEmail !== "" && Helper.isEmailValid(props.GRAPHAPIServiceEmail.ServiceEmail)) {

            const request: ITestRequest = {
                TemplateId: props.TemplateId.toString(),
            }
            await GraphApiService.Test(request)
                .then((data) => {
                    setShowSuccessMessage(true)
                    setMessage("Email Sent Correctly. If you do not see the email in a few minutes, check your \"junk mail\" folder or \"spam folder\"")

                    setTimeout(function () {
                        setShowSuccessMessage(false)
                        setShowErrorMessage(false)
                        setMessage("")
                    }, 5000);
                })
                .catch((error) => {
                    setShowErrorMessage(true)
                    setMessage(`Email Delivery Failure: ${Helper.GetErrorMessage(error)}`)

                })
                .finally()

        }
    }

    const _onChangeServiceEmail = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setServiceEmail(newValue!);
        },
    );

    const _onChangeHost = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setHost(newValue!);
        },
    );

    const _onChangePort = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setPort(newValue!);
        },
    );

    const _onChangeUsername = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setUsername(newValue!);
        },
    );

    const _onChangePwd = useConstCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setPwd(newValue!);
        },
    );

    const _onCloseModal = () => {
        setDisableFields(false)
        setshowSMTPInputs(false);
        setServiceEmail("");
        setHost("");
        setPort("");
        setUsername("");
        setPwd("");
        props.setShowCampaignDelivery(false);
    };

    const getDisplayLabel = (title: string, description: string): string => {
        var label = title + " " + description;
        if (isMobile) {
            label = title;
        }
        return label;
    };

    return (
        <>
            <Modal
                isOpen={props.showCampaignDelivery}
                isBlocking={true}
                containerClassName={contentStyles.container}
            >
                <div className="ms-Grid" dir="ltr" >
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id="CampaignList">Delivery Campaign</span>
                                <IconButton
                                    styles={iconModalButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={_onCloseModal}
                                    title="Close."
                                />
                                <HelpComponent
                                    headline="Delivery Campaign configuration"
                                    text="Configure a Service Email Account or SMTP server to Activate the Campaigns."
                                    right="50px"
                                    top="15px"
                                    location="campaigns"
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                            {showSuccessMessage ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                        </div>
                    </div>
                    <div className="ms-Grid-row" >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.bodyMail}>
                                <label className="ms-fontSize-18" style={{ marginBottom: "16px", display: "block" }}>Service Email Account</label>
                                {isLoading ?
                                    <Spinner size={SpinnerSize.medium} label={props.spinnerText} />
                                    :
                                    <>
                                        {props.GRAPHAPIServiceEmail && props.GRAPHAPIServiceEmail.ServiceEmail.trim().length === 0 ?
                                            <>
                                                <div>
                                                    Use the Service Email Account to send emails from your own Microsoft Office 365 Account. You will need to consent the use of the specified email by {branding.appName}. <strong>Please configure the Account in the Properties option.</strong>
                                                </div>
                                            </> :
                                            <>
                                                <div key="grid" className="ms-Grid" dir="ltr">
                                                    <div key="row" className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm10">
                                                            <TextField id="txtEmailMoniker" label="" value={props.GRAPHAPIServiceEmail.ServiceEmail} disabled={true} />

                                                            {props.GRAPHAPIServiceEmail.AccessToken.trim().length === 0 &&
                                                                <p>
                                                                    You will need to consent to the use of the specified email by {branding.appName}. *Please, check your email or resend a new Consent Email.*
                                                                </p>
                                                            }

                                                        </div>
                                                        <div className="ms-Grid-col ms-sm2" style={{ top: '8px' }}>
                                                            <IconButton
                                                                menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                                                                role="button"
                                                                title={"Click to select from available actions."}
                                                                aria-haspopup
                                                                aria-label="Show actions"
                                                                styles={iconListButtonStyles}
                                                                menuProps={props.GRAPHAPIServiceEmail.AccessToken.trim().length === 0 ? menuProps1 : menuProps2}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }


                                        {props.SMTPServiceEmail?.ServiceEmail.trim().length === 0 ?
                                            <>
                                                <div key="grid" className="ms-Grid" dir="ltr" style={{ marginTop: "16px", display: "block" }}>
                                                    <div key="row" className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm6">
                                                            <label className="ms-fontSize-18" style={{ marginBottom: "16px", display: "block" }}>or Use a custom SMTP</label>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6">
                                                            {!showSMTPInputs &&
                                                                <ActionButton title="Add a SMTP Configuration" text="+ Add SMTP Configuration" onClick={addSMTPConfiguration} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :

                                            <div key="grid" className="ms-Grid" dir="ltr" style={{ marginTop: "16px", display: "block" }}>
                                                <div key="row" className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <label className="ms-fontSize-18" style={{ marginBottom: "16px", display: "block" }}>or Use a custom SMTP</label>
                                                    </div>

                                                </div>
                                            </div>

                                        }

                                        {showSMTPInputs ?

                                            <>
                                                <div key="grid" className="ms-Grid" dir="ltr">

                                                    <div key="row" className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12">
                                                            <TextField disabled={disableFields} id="txtServiceEmail" onGetErrorMessage={(v) => { return Helper.isEmailValid(v) ? "" : "Please enter a valid email address" }} placeholder="Enter email address." autoFocus label={getDisplayLabel("Service Account Email", "(Use the Service Email Account to send Campaigns from your own SMTP Server)")} maxLength={360} required value={ServiceEmail} onChange={_onChangeServiceEmail} title="Service Account Email" />
                                                            <TextField disabled={disableFields} id="txtHost" placeholder="Enter Host name." label={getDisplayLabel("Host", "")} maxLength={360} required value={Host} onChange={_onChangeHost} title="Host" />
                                                            <TextField disabled={disableFields} id="txtPort" onGetErrorMessage={(v) => { return Helper.isPortValid(v) ? "" : "Please enter a valid Port number" }} placeholder="Enter Port number." label={getDisplayLabel("Port", "")} maxLength={5} required value={Port} onChange={_onChangePort} title="Port" />
                                                            <TextField disabled={disableFields} id="txtUsername" placeholder="Enter Username" label={getDisplayLabel("Username", "(Your smtp username)")} maxLength={360} required value={Username} onChange={_onChangeUsername} title="Username" />
                                                            <TextField disabled={disableFields} id="txtPwd" type="password" label={getDisplayLabel("Password", "")} maxLength={360} required value={Pwd} onChange={_onChangePwd} title="Password" />
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12">
                                                            {props.SMTPServiceEmail?.ServiceEmail.trim().length > 0 ?
                                                                <>
                                                                    <ActionButton title="Test SMTP" text="Test SMTP" onClick={testSMTP} />
                                                                    <ActionButton title="Remove SMTP" text="Remove SMTP" onClick={deleteSMTP} />
                                                                </>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            null
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton disabled={!InputValid || props.SMTPServiceEmail.ServiceEmail.trim().length > 0} text="Save" title="Save." onClick={saveSMTPConfiguration} />
                                <DefaultButton onClick={_onCloseModal} text="Close" title="Close" />
                            </DialogFooter>
                        </div>
                    </div>
                    <br />
                </div>
            </Modal>
        </>
    )
}

export default CampaignDelivery