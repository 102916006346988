import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import '../../Custom.css';
import {
    DefaultButton,
    IComboBoxOption,
    Label,
    Panel,
    PanelType,
    PrimaryButton,
    Selection,
    Shimmer,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem
} from "@fluentui/react";
import {IconButton} from "@fluentui/react/lib/Button";
import {Text} from "@fluentui/react/lib/Text";
import {TextField} from '@fluentui/react/lib/TextField';
import HelpComponentRelative from "../Help/HelpComponentRelative";
import {Separator} from "@fluentui/react/lib/Separator";
import {Modal} from "@fluentui/react/lib/Modal";
import {IIconProps} from "@fluentui/react/lib/Icon";
import {contentStyles, iconButtonStyles, nonShrinkingStackItemStyles} from "./Style"
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";

import {FormProvider, useForm} from "react-hook-form";
import {panelHeader} from "../Preview/Style";
import {isMobile} from "react-device-detect";
import {RHFTextBoxControl} from "../RHFControls/RHFTextBoxControl";
import {IPanelProps} from "@fluentui/react/lib/Panel";
import Helper from "../../Helpers/Helper";
import {DocumentShareService, IAddAllowedUserRequest, IEmailServiceRequest, IEmailServiceResponse} from "./index";
import {number} from "mathjs";
import FormTemplateLinkService, {
    IgetDocumentOnlyTemplateIDBasicRequest
} from "../TemplateLink/FormTemplateLink.service";
import {useBranding} from "../../hooks/useBranding";
import {
    EmailTemplateNotificationsServices,
    EmailType,
    ICreateUpdateTemplateDefinitionRequest,
    onRenderOption,
    setDefaultOptions,
    toolbarConfigEmailBody,
    toolbarConfigEmailSubject
} from "../EmailTemplate";
import {SingleRHFRichTextControl} from "../RHFControls/SingleRHFRichTextControl";
import RichTextEditor, {EditorValue} from 'react-rte';
import {removeHTMLTags} from "../RenewalsAndAlerts/RenewalsAndAlerts.data";
import RenewalsAndAlertsService from "../RenewalsAndAlerts/RenewalsAndAlerts.service";
import {ILayout} from "../CustomTemplates/CustomTemplate";
import ApplicationServiceEmailService, {IGetServiceEmailByOwnerRequest} from "../../services/ApplicationServiceEmail";
import DOMPurify from "dompurify";

type Properties = {
    showModal: boolean
    onDismissModal: (reload: boolean, userAdded: string) => void;
    documentId: string
    modalTitle: string
    templateId: string
    ownerUser: string
    documentDescription: string
    documentShare: boolean
}

function DocumentShareEmail({
                                showModal,
                                documentId,
                                modalTitle,
                                onDismissModal,
                                templateId,
                                documentDescription,
                                ownerUser,
                                documentShare
                            }: Properties) {
    const {branding} = useBranding()
    const {t} = useTranslation(['recordShareEmail', 'common'])
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const [fromApplicationServiceEmail, setFromApplicationServiceEmail] = useState("")
    const [ServiceEmailTemplateId, setServiceEmailTemplateId] = useState(0)
    const formPublishWebform = useForm();
    const [isSent, setIsSent] = useState(false)
    const [isLoadingEmailService, setIsLoadingEmailService] = useState(false)
    const [isLoadingEmailTemplate, setIsLoadingEmailTemplate] = useState(false)
    const [isLoadingContentControls, setIsLoadingContentControls] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [messageSent, setMessageSent] = useState(0)
    const [txtMessageError, setTxtMessageError] = useState("")
    const [rteValue, setRteValue] = useState(RichTextEditor.createEmptyValue())
    const [rteValueSubject, setRteValueSubject] = useState(RichTextEditor.createEmptyValue())
    const [message, setMessage] = useState("")
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)
    const [selectionAttachment] = useState(new Selection<any>())
    const [templateContentControls, setTemplateContentControls] = useState<IComboBoxOption[]>([])

    useEffect(() => {
        if (showModal) {
            initFields().then()
        }
    }, [showModal])

    /**
     * initFields
     */
    const initFields = async () => {
        formPublishWebform.clearErrors()
        formPublishWebform.reset()

        selectionAttachment.setAllSelected(false)
        setMessage("")
        setShowMessage(false)
        setStatusMessageEnum(StatusEnum.Info)
        setIsLoadingEmailTemplate(false)
        setIsLoadingContentControls(false)
        setRteValueSubject(EditorValue.createFromString("", "html"))
        setRteValue(EditorValue.createFromString("", "html"))
        setIsLoadingEmailService(true)
        setServiceEmailTemplateId(0)
        setMessageSent(0)
        setIsSent(false)
        setIsSaving(false)
        getApplicationServiceEmail().then()
        init().then()
    }

    /**
     * onBeforeDismiss
     */
    const onBeforeDismiss = () => {
        onDismissModal(false, "")
    }

    const init = async () => {
        await loadContentControls()
            .then(async () => {
                if (documentShare) {
                    await loadEmailTemplate()
                        .then()
                        .catch()
                        .finally()
                }
            })
            .catch()
            .finally()
    }


    /**
     * Load Content Controls
     */
    const loadContentControls = async () => {
        setIsLoadingContentControls(true)
        setTemplateContentControls([])
        await RenewalsAndAlertsService.getStatemanagement({
            User: "",
            templateId: parseInt(templateId!)
        })
            .then(data => {
                try {
                    const items = JSON.parse(data.Layout as string) as ILayout[];
                    if (Array.isArray(items)) {
                        setTemplateContentControls(setDefaultOptions(items, EmailType.DOCUMENTSHARE))
                    }
                } catch {

                }

            })
            .catch()
            .finally(() => {
                setIsLoadingContentControls(false)
            })
    }


    /**
     * loadEmailTemplate
     */
    const loadEmailTemplate = async () => {
        setIsLoadingEmailTemplate(true)
        setRteValueSubject(EditorValue.createFromString("", "html"))
        setRteValue(EditorValue.createFromString("", "html"))

        await EmailTemplateNotificationsServices.GetTemplateDefinition({
            EmailType: EmailType.DOCUMENTSHARE,
            TemplateId: templateId!
        }).then(data => {
            try {
                if (data.success) {
                    if (data.result !== null) {

                        try {
                            const template = JSON.parse(data.result) as ICreateUpdateTemplateDefinitionRequest
                            if (template.Status) {
                                formPublishWebform.setValue("name", template.Name)
                                formPublishWebform.setValue("description", template.Description)
                                setRteValue(EditorValue.createFromString(template.Body, 'html'))
                                setRteValueSubject(EditorValue.createFromString(template.Subject, 'html'))
                            }
                        } catch (e: any) {

                        }
                    }
                }
            } catch {
            }

        })
            .catch()
            .finally(() => {
                setIsLoadingEmailTemplate(false)
            })
    }

    /**
     * getApplicationServiceEmail
     */
    const getApplicationServiceEmail = async () => {
        setFromApplicationServiceEmail(await Helper.getShareEmail())
        setServiceEmailTemplateId(-101)

        let request: IGetServiceEmailByOwnerRequest = {
            TemplateId: null,
            Owner: Helper.getUsername()
        }

        ApplicationServiceEmailService.GetServiceEmailByOwner(request)
            .then(async (data: IEmailServiceResponse[]) => {

                try {
                    let found = data.find(item => item.TemplateId === -1);
                    if (found != undefined) {
                        setFromApplicationServiceEmail(found.ServiceEmail)
                        setServiceEmailTemplateId(-1)
                    } else {

                        const requestTemplate: IgetDocumentOnlyTemplateIDBasicRequest = {
                            TemplateId: templateId,
                            Autoprovision: true,
                            ignoreDocument: true
                        }

                        await FormTemplateLinkService.GetDocumentOnlyTemplateIDBasic(requestTemplate)
                            .then(async (data) => {

                                request = {
                                    TemplateId: templateId,
                                    Owner: data.OwnerUserName
                                }

                                await ApplicationServiceEmailService.GetServiceEmailByOwner(request)
                                    .then(async (data: any) => {
                                        if (data.length > 0) {
                                            setFromApplicationServiceEmail(data[0].ServiceEmail)
                                            setServiceEmailTemplateId(number(templateId))
                                        }
                                    })

                            })

                    }
                } catch {

                }
            })
            .catch((error) => {
                setMessageSent(-1)
                setTxtMessageError(t('text.errorMessage1', {ns: 'common', Error: error.toString()}))

            })
            .finally(() => {
                setIsLoadingEmailService(false)
            })

    }

    /**
     * SendEmail
     * @constructor
     */
    const SendEmail = async () => {
        setIsSaving(true)
        setMessageSent(0)
        setShowMessage(false)

        const subjectHTML = rteValueSubject.toString("html")
        const subject = DOMPurify.sanitize(subjectHTML, { USE_PROFILES: { html: false } });
        const body = rteValue.toString("html")

        const addAllowedUserRequest: IAddAllowedUserRequest = {
            ApplicationServiceEmailTemplateId: ServiceEmailTemplateId.toString(),
            Autoprovision: true,
            Body: body,
            DocumentDescription: documentDescription,
            DocumentId: documentId,
            FromEmail: fromApplicationServiceEmail,
            OwnerUser: ownerUser,
            Subject: subject,
            TargetUserName: formPublishWebform.getValues("to"),
            TemplateId: templateId,
        }

        await DocumentShareService.AddAllowedUser(addAllowedUserRequest)
            .then((response) => {

                if (response.Success) {

                    setMessageSent(1)
                    setIsSent(true)
                    setTimeout(function () {
                        onDismissModal(true, formPublishWebform.getValues("to"))
                    }, 500);
                } else {
                    setMessageSent(-1)
                    setTxtMessageError(t('text.errorMessage1', {ns: 'common', Error: response.ExceptionMessage}))
                }

            })
            .catch((error) => {
                setMessageSent(-1)
                setTxtMessageError(t('text.errorMessage1', {ns: 'common', Error: error}))
            })
            .finally(() => {
                setIsSaving(false)
            })
    }

    /**
     * show Status Message Pivot 1
     * @param message
     * @param status
     */
    const showStatusMessage = (message: string, status: StatusEnum) => {
        setMessage(message)
        setStatusMessageEnum(status)
        setShowMessage(true)
    }

    /**
     * ValidateEmails
     */
    const ValidateEmails = async () => {

        await formPublishWebform.handleSubmit(async () => {
                const subject = removeHTMLTags(rteValueSubject.toString("html"))
                const body = removeHTMLTags(rteValue.toString("html"))
                if (subject.trim().length === 0 || body.trim().length === 0) {
                    if (subject.trim().length === 0) formPublishWebform.setError("EmailSubject", {message: t('text.fieldRequired', {ns: 'common'})})
                    if (body.trim().length === 0) formPublishWebform.setError("EmailBody", {message: t('text.fieldRequired', {ns: 'common'})})
                    showStatusMessage(t("message.fieldsRequired", {ns: "common"}), StatusEnum.Error)
                } else {


                    SendEmail().then()
                }
            }, (errors) => {
                console.log(errors)
                showStatusMessage(t("message.fieldsRequired", {ns: "common"}), StatusEnum.Error)

            }
        )();
    }

    /**
     * Messages
     * @constructor
     */
    const Messages = () => {
        return (
            <StackItem>
                {messageSent === 0 && !showMessage &&
                    <StatusMessage status={"info"} hasTimer={false} truncated={true}
                                   isMultiline={true}
                                   dismiss={false}
                                   setShowMessage={undefined}>
                        <Text variant={"medium"}>{t('messages.infoEmail', {ns: 'recordShareEmail'})}</Text>
                    </StatusMessage>
                }
                {messageSent === 1 && !showMessage &&

                    <StatusMessage status={"success"} hasTimer={false} truncated={true}
                                   isMultiline={true}
                                   dismiss={true}
                                   setShowMessage={() => {
                                       setMessageSent(0)
                                   }}>
                        <Text variant={"medium"}>{t('messages.successSent', {ns: 'recordShareEmail'})}</Text>
                    </StatusMessage>
                }
                {messageSent === -1 && !showMessage &&

                    <StatusMessage status={"error"} hasTimer={false} truncated={true}
                                   isMultiline={true}
                                   dismiss={true}
                                   setShowMessage={() => {
                                       setMessageSent(0)
                                   }}>
                        <Text variant={"medium"}>{t('messages.errorSend', {
                            ns: 'recordShareEmail',
                            Description: txtMessageError
                        })}</Text>
                    </StatusMessage>
                }

                {showMessage &&
                    <>
                        <StatusMessage status={statusMessageEnum} hasTimer={false}
                                       truncated={true}
                                       isMultiline={true}
                                       dismiss={true}
                                       setShowMessage={setShowMessage}>
                            <div>{message}</div>
                        </StatusMessage>
                    </>
                }


                <Separator/>
            </StackItem>
        )
    }

    /**
     * Footer
     * @constructor
     */
    const Footer = () => {
        return (
            <>
                {/*-- START FOOTER --*/}
                <Separator></Separator>
                <Stack horizontal style={{overflow: "hidden"}} styles={nonShrinkingStackItemStyles}>
                    <StackItem grow={1}>&nbsp;</StackItem>

                    {isSaving &&
                        <>
                            <StackItem>
                                <Spinner size={SpinnerSize.medium} labelPosition="left"
                                         label={t('text.spinnerSending', {ns: 'common'})}/>
                            </StackItem>
                        </>
                    }

                    <StackItem>

                        <PrimaryButton
                            disabled={isSaving || isSent || isLoadingEmailService || isLoadingEmailTemplate || isLoadingContentControls}
                            style={{marginLeft: 8}}
                            onClick={ValidateEmails}
                            text={t('Common.Button.Send', {ns: 'common'})}
                            title={t('title.btnSend', {ns: 'recordShareEmail'})}/>


                        <DefaultButton
                            onClick={() => {
                                onBeforeDismiss()
                            }}
                            style={{marginLeft: 8}}
                            text={t('Common.Button.Close', {ns: 'common'})}
                            title={t('Common.Button.Close', {ns: 'common'})}/>
                    </StackItem>
                </Stack>
                {/*-- END FOOTER --*/}
            </>
        )
    }

    /**
     * FormPublishLink
     * @constructor
     */
    const FormPublishLink = () => {
        return (<>


                {isLoadingEmailService || isLoadingEmailTemplate || isLoadingContentControls ?
                    <>
                        <Shimmer styles={{root: {display: "block", paddingTop: 8}}}/>
                        <Separator/>
                        <Shimmer styles={{root: {display: "block", paddingTop: 8}}}/>
                        <Shimmer styles={{root: {display: "block", paddingTop: 8}}}/>
                        <Shimmer styles={{root: {display: "block", paddingTop: 8}}}/>
                        <Separator/>
                        <Shimmer height={16} styles={{root: {display: "block", paddingTop: 8}}}/>
                    </>
                    :
                    <>
                        <FormProvider {...formPublishWebform}>
                            <form>

                                <TextField readOnly={true} disabled
                                           label={t('label.emailFrom', {ns: 'recordShareEmail'})}
                                           value={fromApplicationServiceEmail}/>


                                <RHFTextBoxControl id="to"
                                                   disabled={isSaving}
                                                   readOnly={isSaving}
                                                   control={formPublishWebform.control}
                                                   setValue={formPublishWebform.setValue}
                                                   getValues={formPublishWebform.getValues}
                                                   trigger={formPublishWebform.trigger}
                                                   label={t('label.ctrlEmailTo', {ns: 'common'})}
                                                   title={t('label.ctrlEmailTo', {ns: 'common'})}
                                                   rules={{
                                                       required: t('text.fieldRequired', {ns: 'common'}),
                                                       validate: {


                                                           required:
                                                               (value) => {
                                                                   return Helper.validateEmail(value) ? true : t('text.emailNoValid', {ns: 'common'})
                                                               }
                                                       },
                                                       maxLength: 320
                                                   }}
                                />

                                <Stack styles={{root: {margin: "16px 0px"}}}>
                                    <SingleRHFRichTextControl
                                        id={"EmailSubject"}
                                        label={t('label.ctrlEmailSubject', {ns: 'common'})}
                                        placeholder={t('label.ctrlEmailSubject', {ns: 'common'})}
                                        setRteValue={setRteValueSubject}
                                        rteValue={rteValueSubject}
                                        control={formPublishWebform.control}
                                        setValue={formPublishWebform.setValue}
                                        getValues={formPublishWebform.getValues}
                                        trigger={formPublishWebform.trigger}
                                        rules={{required: t('text.fieldRequired', {ns: 'common'})}}
                                        onRenderOption={onRenderOption}
                                        options={templateContentControls}
                                        toolbarConfig={toolbarConfigEmailSubject}
                                        disabled={isSaving}
                                        isTextArea={true}
                                    />
                                </Stack>

                                <Stack styles={{root: {margin: "16px 0px"}}}>
                                    <SingleRHFRichTextControl
                                        id={"EmailBody"}
                                        label={t('label.ctrlEmailBody', {ns: 'common'})}
                                        placeholder={t('label.ctrlEmailBody', {ns: 'common'})}
                                        setRteValue={setRteValue}
                                        rteValue={rteValue}
                                        control={formPublishWebform.control}
                                        setValue={formPublishWebform.setValue}
                                        getValues={formPublishWebform.getValues}
                                        trigger={formPublishWebform.trigger}
                                        rules={{required: t('text.fieldRequired', {ns: 'common'})}}
                                        onRenderOption={onRenderOption}
                                        options={templateContentControls}
                                        toolbarConfig={toolbarConfigEmailBody}
                                        disabled={isSaving}
                                    />
                                </Stack>
                                <Stack styles={{root: {padding: "16px 0px"}}}>
                                    <Label>{t('text.note', {ns: 'recordShareEmail'})}</Label>
                                </Stack>

                            </form>
                        </FormProvider>
                    </>
                }
            </>
        )
    }

    return (
        <>

            {!isMobile ?

                <Modal
                    isOpen={showModal}
                    isBlocking={true}
                    containerClassName={contentStyles(branding.theme).containerRecord}
                >
                    {/*-- START HEADER --*/}
                    <Stack horizontal className={contentStyles(branding.theme).header}>


                        <StackItem grow={1}
                                   styles={{
                                       root: {
                                           textOverflow: "ellipsis",
                                           whiteSpace: "nowrap",
                                           overflow: "hidden"
                                       }
                                   }}>
                            <Text variant={"xLarge"} id={documentId}
                                  title={modalTitle}>
                                {modalTitle}
                            </Text>
                        </StackItem>
                        <Stack horizontal styles={{root: {alignItems: "center"}}}>
                            <HelpComponentRelative
                                target={"documentShareHelp"}
                                headline={t('title.publishLinkHelp', {ns: 'recordShareEmail'})}
                                text={t('text.help', {ns: 'recordShareEmail'})}
                                location={undefined}
                            />
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                                onClick={() => {
                                    onBeforeDismiss()
                                }}
                                title={t('Common.Button.Close', {ns: 'common'})}
                            />
                        </Stack>
                    </Stack>
                    <Separator/>

                    {/*-- END HEADER --*/}

                    {/*-- START BODY --*/}
                    <Stack horizontal tokens={{padding: 8}} style={{overflow: "hidden"}}>

                        {/*-- START CONTENT --*/}
                        <Stack grow={1} style={{overflow: "hidden", padding: "0px 8px 8px 8px"}}>

                            <Messages/>
                            <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                                   style={{
                                       overflow: "hidden",
                                       overflowY: "auto",
                                       height: "50vh",
                                   }}>
                                <FormPublishLink/>
                            </Stack>
                            <Footer/>
                        </Stack>
                        {/*-- END CONTENT --*/}

                    </Stack>
                    {/*-- END BODY --*/}

                </Modal>

                :
                <>
                    {/*-- START PANEL --*/}
                    <Panel

                        headerText={modalTitle}
                        headerTextProps={panelHeader}
                        isBlocking={false}
                        isOpen={showModal}
                        onDismiss={onBeforeDismiss}
                        onRenderHeader={(props?: IPanelProps): JSX.Element | null => {
                            return (<>
                                <Stack horizontal styles={{root: {width: "100%"}}}>
                                    <StackItem grow={1} id={props?.id}
                                               styles={{root: {paddingLeft: 24, paddingRight: 24}}}>
                                        <Text variant={"xLarge"}
                                              styles={{
                                                  root: {
                                                      fontWeight: 500,
                                                      color: branding.theme.palette.themePrimary
                                                  }
                                              }}>
                                            {props?.headerText}
                                        </Text>

                                    </StackItem>
                                    <StackItem>
                                        <HelpComponentRelative
                                            headline={t('title.publishLinkHelp', {ns: 'recordShareEmail'})}
                                            text={t('text.help', {ns: 'recordShareEmail'})}
                                            location=""
                                        />
                                    </StackItem>

                                </Stack>


                            </>)
                        }}
                        closeButtonAriaLabel={t('Common.Button.Close', {ns: 'common'})}
                        type={PanelType.smallFluid}
                        styles={{
                            content: {
                                display: "flex",
                                flexGrow: 1
                            },
                            scrollableContent: {
                                display: "flex",
                                flexGrow: 1
                            }

                        }}
                    >
                        <Stack grow={1}>
                            <Stack grow={0}>
                                <Separator/>
                            </Stack>
                            <Messages/>
                            <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                                   style={{
                                       overflow: "hidden",
                                       overflowY: "auto",
                                       height: "50vh",
                                   }}>
                                <FormPublishLink/>
                            </Stack>
                            <Footer/>
                        </Stack>
                    </Panel>
                    {/*-- END PANEL --*/}
                </>

            }


        </>
    )
}

export default DocumentShareEmail
