import React, { useState, useEffect, HTMLAttributes } from 'react';
import { mergeStyleSets, getTheme, FontWeights } from '@fluentui/react/lib/Styling';
import { IColumn, SelectionMode, DetailsListLayoutMode, DetailsList } from '@fluentui/react/lib/DetailsList';
import { Stack } from '@fluentui/react/lib/Stack';
import Helper, {  } from '../../Helpers/Helper';
import { IconButton, DefaultButton} from '@fluentui/react/lib/Button';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import '../../Custom.css';
import { ReactNotificationOptions, store } from 'react-notifications-component';
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import { CheckboxVisibility, DialogFooter, IIconProps, Modal, Panel, PanelType, Separator } from '@fluentui/react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Label } from '@fluentui/react/lib/Label';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';
import {useTranslation} from "react-i18next";
import { getRecordDescription } from '../../Helpers/functions';
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";

const theme = getTheme();

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
export interface IStatus {
  AccountId: number
  TemplateId: number
  DocumentOnlyId:number
  Activity:string
  CreatedByUserName:string
  DATETIME:string
  FullName:string
 
}
const emptyStatus: IStatus={
  AccountId:0,
  CreatedByUserName:"",
  DATETIME:"",
  DocumentOnlyId:0,
  TemplateId:0,
  Activity:"",
  FullName:""

}



function ActivityStatus(props: any) {
    const { branding } = useBranding()
  const itemList: IStatus[] = []
  const [listItems, setlistItems] = useState(itemList)
  const [noteSelected, setNoteSelected] = useState(emptyStatus)
  const [isLoading, setIsLoading] = useState(false)
  const {t} = useTranslation(['common', 'recordactivity']);
  const getKey = (item: string, index?: number): string => {
    if(item !== undefined) 
        return item.toString()

    return ""
}

    const panelHeader: HTMLAttributes<HTMLDivElement> = {
        style: {
            fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
            fontWeight: "bolder",
            color: branding.theme.palette.themePrimary,
            fontSize: 20
        }
    }

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '60vw',
            height: '40vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
        },
        bodyPanel: {
            width: isMobile ? null : '60vw',
            height: '70vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
        },
    });


    function showNotification(Nmessage:string, Ntype:string)
    {
        var auxType:ReactNotificationOptions;
        switch (Ntype) {
            case "success":
                store.addNotification({
                    title: t('title.success', { ns: 'common'}),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;
            case "warning":
                store.addNotification({
                    title: t('title.warning', { ns: 'common'}),
                    message: Nmessage,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;
            case "error":
                store.addNotification({
                    title: t('title.error', { ns: 'common'}),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;
            case "info":
                store.addNotification({
                    title: t('title.information', { ns: 'common'}),
                    message: Nmessage,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;

            default:
                break;
        }


    }


    async function GetActivity() {
        setIsLoading(true)

        const request = {
            documentId: props.documentId
        }

        let {data}: any = await HttpRequest.getWithSession('DocumentOnlyStatus', request)
        const response = data
       
        if (response !== undefined) {
            try {
                if (response !== undefined) {
                    setlistItems(response)
                    setIsLoading(false)

                    let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneUserlist")) as Element
                    if (element !== null) {
                        element.children[1].classList.add("scrollVisible")
                    }

                }
            } catch (error) {
                showNotification(t('message.error', {ns: 'common', Error: error}), "error")
                setIsLoading(false)
            }
        }
    }

  const onSelectionItemChange = () => {
    const selectionCount = selectionItem.getSelectedCount();
        switch (selectionCount) {
        case 0:
           setNoteSelected(emptyStatus)
        break;
       case 1:
        setNoteSelected(selectionItem.getSelection()[0] as IStatus)
            break;
       default:
        setNoteSelected(emptyStatus)
            break;
     }
    }

    const getDateTimeDisplaySpan = (item: IStatus) => {

        var result

        isMobile ?
            result = <span title={item.DATETIME !== null ? Helper.getLocateDate(item.DATETIME, 2 ) : ""}>{item.DATETIME !== null ? Helper.getLocateDate(item.DATETIME, 3 ): ""}</span>
            :
            result = <span title={item.DATETIME !== null ? Helper.getLocateDate(item.DATETIME, 2 ) : ""}>{item.DATETIME !== null ? Helper.getLocateDate(item.DATETIME, 4 ): ""}</span>
        return result
    }

    const selectionItem = new Selection({
      onSelectionChanged: onSelectionItemChange,
  });

  const columns: IColumn[] = [
  {   
    key: 'column3',
    name: t('label.created', { ns: 'recordactivity'}),
    fieldName: 'DATETIME',
    minWidth: 100,
    maxWidth: isMobile ? 100 : 200,
    isRowHeader: true,
    isResizable: false,
    isSorted: false,
    isSortedDescending: false,
    sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordactivity'}),
    sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordactivity'}),
    data: 'string',
    isPadded: true,
    onRender: (item: IStatus) => {
        return getDateTimeDisplaySpan(item);
    }
  },
      {
          key: 'column2',
          name: t('label.username', { ns: 'recordactivity'}),
          fieldName: 'CreatedByUserName',
          minWidth: 125,
          maxWidth: 150,
          isRowHeader: true,
          isResizable: false,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordactivity'}),
          sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordactivity'}),
          data: 'string',
          isPadded: true,
          onRender: (item: IStatus) => {
              return <span title={item.CreatedByUserName.toString()}>{item.FullName}</span>;
          }
      },
    {
      key: 'column4',
      name: t('label.activity', { ns: 'recordactivity'}),
      fieldName: 'Activity',
      minWidth: 280,
      maxWidth: isMobile? 280: 400,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      isMultiline: true,
      isSortedDescending: false, 
      sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'recordactivity'}), 
      sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'recordactivity'}),  
      data: 'string',
      isPadded: true,
      onRender: (item: IStatus) => {
        return <span title={item.Activity}>{item.Activity}</span>;
      }
    },
]
    useEffect(() => {
      if(props.showActivity)
        GetActivity()


    },[props.showActivity])

    const dismissPanel = () => {
      props.hideModalActivity()
    }


    return !isMobile?(
        <Modal
            isOpen={props.showActivity}
            containerClassName={contentStyles.container}
            isBlocking={true}>
            <Stack >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }}  className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id={props.modalTitleId}>{getRecordDescription(props.modalTitle,props.documentDescription)}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                    onClick={props.hideModalActivity}
                                    title={t('Common.Button.Close', { ns: 'common'})}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                {!isLoading ?
                                    <ScrollablePane id="ScrollPaneUserlist" key="ScrollPaneUserlist">
                                      {/* <div className="scrollVisible" style={{overflow:"auto", overflowX:"hidden" , maxWidth:"100%", height:"50vh"}}> */}
                                        <DetailsList
                                            items={listItems || []}
                                            compact={false}
                                            columns={isMobile?columns.filter(value=> value.name==="Activity"):columns }
                                            getKey={getKey}
                                            setKey="set"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            selection={selectionItem}
                                            selectionMode={SelectionMode.single}
                                            selectionPreservedOnEmptyClick={false}
                                            checkboxVisibility={CheckboxVisibility.hidden}
                                        />
                                        {/* </div> */}
                                    </ScrollablePane> 
                                    :
                                    <Stack>
                                        <Label/>
                                        <Label/>
                                        <Label/>
                                        <Spinner size={SpinnerSize.medium} label={t('text.spinner', { ns: 'common'})} ariaLive="assertive" />
                                    </Stack>
                                    }
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <DefaultButton onClick={props.hideModalActivity} text={t('Common.Button.Close', { ns: 'common'})} title={t('Common.Button.Close', { ns: 'common'})} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Stack>
      </Modal>
    ):
    (
      <Panel
        headerText={getRecordDescription(props.modalTitle,props.documentDescription)}
        headerTextProps={panelHeader}
        isBlocking={false}
        isOpen={props.showActivity}
        onDismiss={dismissPanel}
        closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common'})}
        type={PanelType.smallFluid}
      >
        <Stack >
                <div className="ms-Grid" dir="ltr">
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.bodyPanel}>
                                {!isLoading ?
                                    <ScrollablePane id="ScrollPaneUserlist" key="ScrollPaneUserlist">
                                      {/* <div className="scrollVisible" style={{overflow:"auto", overflowX:"hidden" , maxWidth:"100%", height:"50vh"}}> */}
                                        <DetailsList
                                            items={listItems || []}
                                            compact={false}
                                            columns={isMobile?columns.filter(value=> value.name==="Activity"):columns}
                                            getKey={getKey}
                                            setKey="set"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            selection={selectionItem}
                                            selectionMode={SelectionMode.single}
                                            selectionPreservedOnEmptyClick={false}
                                            checkboxVisibility={CheckboxVisibility.hidden}
                                        />
                                        {/* </div> */}
                                    </ScrollablePane> 
                                    :
                                    <Stack>
                                        <Label/>
                                        <Label/>
                                        <Label/>
                                        <Spinner size={SpinnerSize.medium} label={t('text.spinner', { ns: 'common'})} ariaLive="assertive" />
                                    </Stack>
                                    }
                            </div>
                        </div>
                    </div>
                    
                </div>
            </Stack>
      </Panel>
    )
}

export default ActivityStatus

