
import { useContext } from 'react'
import AttachmentContext from './AttachmentContext'

export const useAttachment = () => {

    const { billingActiveStr, documentId, documentDraftId, templateId,
        update, docSource, showSuccessMessage, setShowSuccessMessage, setMessage,
        showErrorMessage, setShowErrorMessage, message,endUserName,username } = useContext(AttachmentContext)

    return {
        billingActiveStr, documentId, documentDraftId, templateId,
        update, docSource, showSuccessMessage, setShowSuccessMessage, setMessage,
        showErrorMessage, setShowErrorMessage, message,endUserName,username
    }
}
