import React, {useEffect, useState} from 'react'
import {
    Checkbox,
    CheckboxVisibility,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    DialogFooter,
    IconButton,
    IIconProps,
    INavStyles,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack
} from '@fluentui/react'
import HelpComponent from '../../Help/HelpComponent';
import {FontWeights, getTheme} from '@fluentui/react/lib/Styling';
import {Panel, PanelType} from '@fluentui/react/lib/Panel';
import {Nav} from '@fluentui/react/lib/Nav';
import {renderColumns} from './Attachment.data';
import {useTranslation} from 'react-i18next';
import AttachmentService from '../../../services/attachments';
import {AttachmentRule, IPropertyBagSettings} from '../../../interfaces/IPropertyBagSettings';
import {Controller, useForm} from "react-hook-form";
import {useModal} from '../../../hooks/useModal';
import AttachmentsForm from './AttachmentsForm';
import StatusMessage, {StatusEnum} from '../../../share/StatusMessage';
import Helper from '../../../Helpers/Helper';
import {useBranding} from "../../../hooks/useBranding";

const MAX_ALLOWED_RULES = 10
const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const menuIcon: IIconProps = { iconName: 'GlobalNavButton' };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const navStyles: Partial<INavStyles> = {
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
    },
    chevronIcon: {
        display: 'none'
    },
    chevronButton: {
        display: 'none'
    },
};

type AttachmentsProps = {
    showModal: any
    titleId: any
    settingLinks: any
    onLinkClick: any
    setIsOpenMenu: any
    isOpenMenu: any
    cancel: any
    templateId: any
    closeModal: any
}
const Attachments = ({ showModal, closeModal, titleId, cancel, templateId, settingLinks, isOpenMenu, onLinkClick, setIsOpenMenu }: AttachmentsProps) => {
    const { branding } = useBranding()
    const [isLoading, setIsLoading] = useState(true)
    const [isClosing, setIsClosing] = useState(false)
    const [spinerLabel, setSpinerLabel] = useState('Loading rules...')
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState('')

    const [settings, setSettings] = useState<IPropertyBagSettings>({} as IPropertyBagSettings)
    const { control, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            enable: false,
            maxSize: '1',
        }
    })

    const { t } = useTranslation('attachmentsComponent')
    const attachmentForm = useModal()

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '65vw',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        section: {
            padding: '1rem 0'
        },
        sectionHeader: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        bodyExample: {
            height: window.innerHeight > 660 ? '63vh' : '58vh',
            flex: '4 4 auto',
            padding: '0 15px 24px 15px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    useEffect(() => {
        let isMounted = true
        if (isLoading) {
            AttachmentService.getSettings({
                TemplateId: templateId,
                DocSource: '',
                EndUserName: Helper.getUsername(),
                Username: Helper.getUsername(),
                IgnoreDocument: true,
                Cache: false
            }).then(data => {
                if (isMounted) {
                    setIsLoading(false)
                    setSettings(data)
                    reset({
                        enable: data.attachment?.enable,
                        maxSize: `${data.attachment?.maxSize}`
                    })
                }
            })
        }
        return () => { isMounted = false }
    }, [isLoading, reset, setValue, templateId])

    const saveSettings = (data: any) => {

        let _settings = { ...settings }
        _settings.attachment.enable = data.enable
        _settings.attachment.maxSize = data.maxSize
        setSpinerLabel('Saving attachment rules...')
        setIsClosing(true)
        AttachmentService.updateSettings(templateId, JSON.stringify(_settings)).then(({ data }: any) => {
            if (data.success) {
                setMessage('Attachment settings saved successfully')
                setShowMessage(true)
                setIsClosing(false)
                closeModal()
            }
        })
    }
    const getAttachments = ((attachment: AttachmentRule) => {
        
        setSettings((settings) => {
            
            let _settings = { ...settings }
            let _attachments = [..._settings?.attachment?.attachments]

            const result = _attachments.find((a) => 
                 a.id === attachment.id )

            if (result) {
                _attachments = _attachments.map((a) => {
                    return a.id === attachment.id ? attachment : a
                })
            } else {
                _attachments = [..._attachments, { ...attachment }]
            }
            _settings.attachment.attachments = [..._attachments]
            return _settings
        })
    })
    const deleteAttachment = (id: number) => {
        
        setSettings((settings: IPropertyBagSettings) => {

            settings.attachment.attachments = settings?.attachment?.attachments.filter(a => a.id !== id)

            return { ...settings }
        })
    }

    /**
     * Sort the DetailList Items
     * @param {AttachmentRule[]} attachments
     * @returns {AttachmentRule[]}
     * @constructor
     */
    function SortItems(attachments: AttachmentRule[]): AttachmentRule[] {
        return attachments.sort((a, b) => {
            return a.count - b.count || a.name.localeCompare(b.name)
        })
    }
    
    return (
        <>
            <Modal
                isOpen={showModal}
                isBlocking={true}
                containerClassName={contentStyles.container}
            >
                <form onSubmit={handleSubmit(saveSettings)}>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{
                                padding: "0px"
                            }} className="ms-Grid-col ms-sm8 ms-md10 ms-lg10">
                                <div className={contentStyles.header}>
                                    <span id={titleId}>{t('settingsHeader')}</span>
                                </div>
                            </div>
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm4 ms-md2 ms-lg2">
                                <div className={contentStyles.header}>
                                    <HelpComponent headline="Attachment " text={t('settingsHeader')} right="50px" top="15px" location="properties" />
                                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={cancel} title="Close." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={contentStyles.bodyExample}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg3">
                                    <div className="ms-hiddenMdDown">
                                        <Nav groups={settingLinks} onLinkClick={onLinkClick} selectedKey={'14'} styles={navStyles}/>
                                    </div>
                                    <div className="ms-hiddenLgUp">
                                        <IconButton iconProps={menuIcon} style={{
                                            marginLeft: "-4px",
                                            paddingLeft: "0px"
                                        }} title="Menu" onClick={setIsOpenMenu} />
                                        <Panel isBlocking={false} isOpen={isOpenMenu} onDismiss={setIsOpenMenu} closeButtonAriaLabel="Close" type={PanelType.smallFixedNear}>
                                            <Nav groups={settingLinks} onLinkClick={onLinkClick} selectedKey={'14'} styles={navStyles}/>
                                        </Panel>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                    {showMessage && <StatusMessage status={StatusEnum.Success} hasTimer={true} setShowMessage={setShowMessage}>
                                        <div>{message}</div>
                                    </StatusMessage>}
                                    <div className="scrollVisible" style={{
                                        overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh"
                                    }}>
                                        <label className="ms-fontSize-18">{t('settingsHeader')}</label>
                                        <div>
                                            <p>{t('settingsDescription')}</p>

                                        </div>
                                        <Stack styles={{ root: { paddingRight: "10px" } }}>

                                            {(!isLoading && !isClosing) ?
                                                <>
                                                    <div className={contentStyles.section}>
                                                        <label className="ms-fontSize-16">{t('settings.enable')}</label>
                                                        <Controller
                                                            name="enable"
                                                            control={control}
                                                            render={({ field }) => <Checkbox {...field} checked={field.value} value={'enable'} title={t('settings.enable')} label={t('settings.enableDescription')} />}
                                                        />

                                                    </div>
                                                    {/* <div className={contentStyles.section}>
                                                        <label className="ms-fontSize-16">{t('settings.maxSize')}</label>
                                                        <Controller
                                                            name="maxSize"
                                                            control={control}
                                                            render={({ field }) => <TextField {...field}  suffix="MB" />}
                                                        />

                                                    </div> */}
                                                    <div className={contentStyles.section}>
                                                        <h3 className={contentStyles.sectionHeader}>
                                                            <span>{t('settings.tableList')}</span>
                                                            <DefaultButton key="openForm"
                                                                disabled={settings?.attachment?.attachments.length >= MAX_ALLOWED_RULES}
                                                                onClick={() => { attachmentForm.open({}) }}
                                                                title={settings?.attachment?.attachments.length >= MAX_ALLOWED_RULES ? `Limit of rules reached` : 'Add'}
                                                                text={t('settings.addBtn')} />
                                                        </h3>
                                                        {
                                                            settings?.attachment?.attachments ?
                                                                <DetailsList columns={
                                                                    renderColumns({ deleteAttachment, openForm: attachmentForm.open })
                                                                }
                                                                    layoutMode={DetailsListLayoutMode.justified}
                                                                    isHeaderVisible={true}
                                                                    selectionMode={SelectionMode.single}
                                                                    selectionPreservedOnEmptyClick={false}
                                                                    checkboxVisibility={CheckboxVisibility.hidden}
                                                                             
                                                                    items={SortItems(settings?.attachment?.attachments)} /> : <></>
                                                        }

                                                    </div>
                                                </>
                                                :
                                                <Spinner size={SpinnerSize.medium} label={spinerLabel} ariaLive="assertive" />
                                            }
                                            <label />

                                        </Stack>

                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <DialogFooter>
                                            <PrimaryButton id="btnCreateUpdate" type="submit" text={'Update'} disabled={false} title={"Update attachment rules"} />
                                            <DefaultButton id="btnCancel" onClick={cancel} text="Cancel" title="Close." />
                                        </DialogFooter>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
            <AttachmentsForm Modal={attachmentForm.Modal} attachment={attachmentForm.data} attachments={settings?.attachment?.attachments} getAttachments={getAttachments} close={attachmentForm.close} />
        </>
    )
}
export default Attachments