import {IEmailTemplates} from "../../EmailTemplate";

export const stateEmailTemplatesDefault:IEmailTemplates = {
    ApplicationDelete: undefined,
    ApplicationEdit: undefined,
    ApplicationRemove: undefined,
    ApplicationShare: undefined,
    DocumentCreation: undefined,
    DocumentDelete: undefined,
    DocumentShare: undefined,
    DocumentUpdate: undefined,
    WizardSubmissionCreate: undefined,
    WizardSubmissionUpdate: undefined
}