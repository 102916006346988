import React, { useState, useEffect } from 'react';
import {Stack, StackItem, Text} from '@fluentui/react';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import Helper, { IDocumentOnlyAudienceDetail, IRequest, IPlan, IDPlanRequest, ICUPlanRequest } from '../../Helpers/Helper';
import { DetailsListLayoutMode, IContextualMenuItem, IContextualMenuProps, SelectionMode, IDropdownOption, Dropdown, MessageBar, IMessageBarStyles, Label } from '@fluentui/react';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles, ActionButton, IButtonProps } from '@fluentui/react/lib/Button';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField } from '@fluentui/react/lib/TextField';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useConstCallback } from '@uifabric/react-hooks';
import HelpComponent from '../Help/HelpComponent';
import { setGlobalDateLocale } from '../../Helpers/DateUtils';
import {useBranding} from "../../hooks/useBranding";
const stackTokens = { childrenGap: 10 };
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

enum ModalType {
    None = 0,
    Add = 1,
    Edit = 2,
    Delete = 3
}
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
var UnitActive=""
function Plan(props: any) {
    const { branding } = useBranding()
    const emptyPlan: IPlan = {
        Id: 0,
        Name: "",
        Description: "",
        StorageLimit: 0,
        SubmissionLimit: 0,
        TransactionAmount: 0,
        TransactionLength: 0,
        TransactionUnit: "",
        Type: "",
        UserLimit: 0,
        PaymentProviderProductId: ""
    };

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
                class: 'ms-Icon ms-Icon--Mail'
            }
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        bodyDelete: {
            width: '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        body: {
            width: '25vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyEdit: {
            width: '50vw',
            height: '60vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyVWOverride: {
            width: '90%',

            flex: '4 4 auto',
            padding: '0 0px 0px 18px',
        },
        bodyEditVWOverride: {
            width: '45vw',

            flex: '4 4 auto',
            padding: '0 18px 0px 18px',
        },
        bodyPlans: {
            width: '98%',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const optionsTransactionUnit: IDropdownOption[] = [
       
        { key: 'Week', text: 'Week' },
        { key: 'Month', text: 'Month' },
        { key: 'Year', text: 'Year' },
        
    ];
    const optionsType: IDropdownOption[] = [
        { key: 'Normal', text: 'Normal' },
        { key: 'Custom', text: 'Custom' }
    ];


    const [planList, setPlanList] = useState<IPlan[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<IPlan>(emptyPlan);
    const [showAddEditPlanModal, setShowAddEditPlanModal] = useState<boolean>(false)
    const [showDeletePlanModal, setShowDeletePlanModal] = useState(false)
    const [modalType, setModalType] = useState<ModalType>(ModalType.None)
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [storageLimit, setStorageLimit] = useState(0);
    const [submissionLimit, setSubmissionLimit] = useState(0);
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [transactionLength, setTransationLength] = useState(0);
    const [transactionUnit, setTransactionUnit] = useState<IDropdownOption>();
    const [type, setType] = useState<IDropdownOption>();
    const [superuserLimit, setSuperuserLimit] = useState(0);
    const [maxApplicationAllowed, setMaxApplicationAllowed] = useState<number | undefined>(0);
    const [workspaceuserLimit, setWorkspaceuserLimit] =  useState<number | undefined>(0);
    const [paymentProviderProductId, setPaymentProviderProductId] = useState<string|null>("")
    const [applying, setApplying] = useState(false);
    const [message, setMessage] = useState("");   
    const [showSuccess, setShowSuccess] = useState(false)
    const [showMessage, setShowMessage] = useState(false);
    const [isUnitValid, setUnitValid] = useState(true)
    const [isTransactionLengthValid, setIsTransactionLengthValid] = useState(true)
    const [unitTransc, setUnitTransc] = useState("")
    
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Plan',
            fieldName: 'Name',
            minWidth: 80,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IPlan) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "start",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text variant={"medium"}
                              title={item.Name}>
                            {item.Name}
                        </Text>
                    </StackItem>
                )
            },
        },
        {
            key: 'column7',
            name: 'Type',
            fieldName: 'Type',
            minWidth: 40,
            maxWidth: 40,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IPlan) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "start",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text variant={"medium"}
                              title={item.Type}>
                            {item.Type}
                        </Text>
                    </StackItem>
                )
            },
        },
        {
            key: 'column2',
            name: 'Storage',
            fieldName: 'StorageLimit',
            minWidth: 50,
            maxWidth: 50,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IPlan) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "end",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text variant={"medium"}
                              title={(Helper.FormatNumber(item.StorageLimit / 1000000000)) + " GB over the Plan's lifetime."}>
                            {Helper.FormatNumber(item.StorageLimit / 1000000000) + " GB"}
                        </Text>
                    </StackItem>
                )
            },
        },
        {
            key: 'column3',
            name: 'Billing Period',
            fieldName: 'TransactionAmount',
            minWidth: 120,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IPlan) => {
                return (

                    <StackItem styles={{
                        root: {
                            justifyContent: "start",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>

                        {String(item.TransactionUnit).toLowerCase().trim() === "eternity" ?
                            <Text block title="Eternity">Eternity</Text> :
                            <Text block title={Helper.getCurrencySymbol() + " " + Helper.FormatNumber(item.TransactionAmount) + " every (" + item.TransactionLength.toString() + ")" + item.TransactionUnit}>{Helper.getCurrencySymbol() + " " + Helper.FormatNumber(item.TransactionAmount) + " every (" + item.TransactionLength.toString() + ")" + item.TransactionUnit}</Text>
                        }

                    </StackItem>
                )
            },
        },
        {
            key: 'column4',
            name: 'Maximum Applications',
            fieldName: 'MaxApplicationAllowed',
            minWidth: 120,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,

            onRender: (item: IPlan) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "end",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text variant={"medium"}
                              title={item.MaxApplicationAllowed! === -1 ? "Unlimited Applications" : Helper.FormatNumber(item.MaxApplicationAllowed) + " maximum Applications."}>
                            {item.MaxApplicationAllowed! === -1 ? "Unlimited" : Helper.FormatNumber(item.MaxApplicationAllowed)}
                        </Text>
                    </StackItem>
                )
            },
        },
        {
            key: 'column5',
            name: 'Submissions',
            fieldName: 'SubmissionLimit',
            minWidth: 70,
            maxWidth: 70,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,

            onRender: (item: IPlan) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "end",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text variant={"medium"}
                              title={Helper.FormatNumber(item.SubmissionLimit) + " submissions allowance per Billing Period. A submission is a new record created in the workspace from the form wizard."}>
                            {Helper.FormatNumber(item.SubmissionLimit)}
                        </Text>
                    </StackItem>
                )
            },
        },
        {
            key: 'column8',
            name: 'Users',
            fieldName: 'UserLimit',
            minWidth: 80,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IPlan) => {
                return (
                    <Stack>
                        <Text variant={"medium"} block
                              title={`${Helper.FormatNumber(item.UserLimit)} Super Users allowed per Plan. Application audience members are managed by selecting <<Share> from Application's menu or <<Settings>> from bottom page left.`}>
                            {`${Helper.FormatNumber(item.UserLimit)} Super Users`}
                        </Text>
                        <Text variant={"medium"} block
                              title={`${Helper.FormatNumber(item.WorkspaceOnlyUsers)} Workspace Users allowed per Plan. Application audience members are managed by selecting <<Share> from Application's menu or <<Settings>> from bottom page left.`}>
                            {`${Helper.FormatNumber(item.WorkspaceOnlyUsers)} Workspace Users`}
                        </Text>
                    </Stack>
                )
            },
        },
        /*
        {
            key: 'column5',
            name: 'Unit Length',
            fieldName: 'TransactionLength',
            minWidth: 60,
            maxWidth: 70,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true
        },
        {
            key: 'column6',
            name: 'Unit',
            fieldName: 'TransactionUnit',
            minWidth: 50,
            maxWidth: 50,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true
        },
        */
        {
            key: 'column9',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "end",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <IconButton
                            menuIconProps={{iconName: 'MoreVertical', style: {fontSize: 20, color: '#242424'}}}
                            role="button"
                            title={"Click to select from available actions."}
                            aria-haspopup
                            aria-label="Show actions"
                            styles={iconListButtonStyles}
                            menuProps={menuProps}
                        />
                    </StackItem>
                )
            },
            isPadded: true
        },
    ]

    const menuItems: IContextualMenuItem[] = [
        // {
        //     key: 'delete',
        //     text: 'Delete',
        //     title: 'Delete plan.',
        //     onClick: () => {
        //         setShowDeletePlanModal(true)
        //         setModalType(ModalType.Delete)
        //         selectionPlan.setAllSelected(false)
        //     },
        // },
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit plan.',
            onClick: () => {
                setShowAddEditPlanModal(true)
                setName(selectedPlan.Name)
                setDescription(selectedPlan.Description)
                setStorageLimit(selectedPlan.StorageLimit / 1000000000)
                setSubmissionLimit(selectedPlan.SubmissionLimit)
                setTransactionAmount(selectedPlan.TransactionAmount)
                setTransationLength(selectedPlan.TransactionLength)
                setTransactionUnit(optionsTransactionUnit.find(item => item.key == selectedPlan.TransactionUnit?.trim()))
                setType(optionsType.find(item => item.key == selectedPlan.Type?.trim()))
                setSuperuserLimit(selectedPlan.UserLimit)
                setWorkspaceuserLimit(selectedPlan.WorkspaceOnlyUsers)
                setMaxApplicationAllowed(selectedPlan.MaxApplicationAllowed)
                setPaymentProviderProductId(selectedPlan.PaymentProviderProductId)
                setModalType(ModalType.Edit)
            },
        }
    ];

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    const PrimaryButtonProps: IButtonProps = React.useMemo(
        () => ({
            children: 'More info',
            onClick: goToHelp,
        }),
        [],
    );

    useEffect(() => {
        setGlobalDateLocale(navigator.language)

        if (planList.length == 0) {
            setApplying(true)
            setPlanList([])
            loadPlan();
        }
    }, [])

    const onSelectionChange = () => {
        const selectionCount = selectionPlan.getSelectedCount();
        switch (selectionCount) {
            case 0:
                setSelectedPlan(emptyPlan)
                break;
            case 1:
                setSelectedPlan(selectionPlan.getSelection()[0] as IPlan)
                break;
            default:
                setSelectedPlan(emptyPlan)
                break;
        }
    }

    const [selectionPlan] = useState( new Selection({
        onSelectionChanged: onSelectionChange,
    }));

    const loadPlan = async () => {
        const req: IRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
        }

        await Helper.getAllPlan(req).then(data => {
            setPlanList(data)
            setApplying(false)
        })
    }

    const getKey = (item: IPlan, index?: number): string => {
        if (item !== undefined)
            return item.Id!.toString()
        return ""
    }

    const addPlan = () => {
        setShowAddEditPlanModal(true)
        setModalType(ModalType.Add)
        selectionPlan.setAllSelected(false)
    }

    const deletePlan = async () => {
        const req: IDPlanRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            Id: selectedPlan.Id,
            Operation: "delete"
        }
        await Helper.deletePlan(req).then(data => {
            loadPlan()
            hideDeletePlanModal()
        });
    }

    const hideAddEditPlanModal = () => {
        setShowAddEditPlanModal(false)
        resetForm()
    }

    const hideDeletePlanModal = () => {
        setShowDeletePlanModal(false)
        resetForm()
    }

    const resetForm = () => {
        selectionPlan.setAllSelected(false)
        setSelectedPlan(emptyPlan)
        setModalType(ModalType.None)
        setName("");
        setDescription("");
        setStorageLimit(0);
        setSubmissionLimit(0);
        setTransactionAmount(0);
        setTransationLength(0);
        setTransactionUnit(undefined);
        setType(undefined);
        setSuperuserLimit(0);
        setWorkspaceuserLimit(0);
        setMaxApplicationAllowed(-1)
        setPaymentProviderProductId("")
        setApplying(false);
    }
    const getErrorMessage = (value: string, message: string): string => {
        if (value.length > 0 && value != "0") {
            return ""
        }
        else {
            return message;
        }
    };

    async function addEditPlan() {
        const req: ICUPlanRequest = {
            Description: description,
            Id: modalType == ModalType.Add ? 0 : selectedPlan.Id,
            Name: name,
            Operation: modalType == ModalType.Add ? "create" : "update",
            StorageLimit: storageLimit * 1000000000,
            SubmissionLimit: submissionLimit,
            TransactionAmount: transactionAmount,
            TransactionLength: transactionLength,
            TransactionUnit: transactionUnit?.text,
            Type: type?.text,
            UserLimit: superuserLimit,
            WorkspaceOnlyUsers: workspaceuserLimit !== undefined && workspaceuserLimit !== null? workspaceuserLimit : 0,
            MaxApplicationAllowed: maxApplicationAllowed !== undefined && maxApplicationAllowed !== null? maxApplicationAllowed : 0,
            PaymentProviderProductId: paymentProviderProductId!,
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
           
        }

        await Helper.createUpdatePlan(req).then(data => {
            
      
            if(data.Success){
              setMessage("Plan added")
              setShowSuccess(true)
            }
            else{
              setMessage(data.ExceptionMessage)
              setShowMessage(true)
              setMessage("")
              setShowSuccess(false)
            }
            setTimeout(function () {
                loadPlan()
                setShowMessage(false)
                setMessage("")
                setShowMessage(false)
                setShowSuccess(false)
                hideAddEditPlanModal()


            }, 2000);
           
        })
    }

    function goToHelp() {
        window.open(`${branding.helpPath}`, '_blank');
    }

    const validForm = () => {
        if (name.length > 0 &&
            description.length > 0 &&
            storageLimit > 0 &&
            submissionLimit > 0 &&
            transactionAmount > 0 &&
            transactionLength > 0 &&
            transactionUnit != undefined && transactionUnit.text.length > 0 &&
            type != undefined && type.text.length > 0 &&
            superuserLimit > 0 
            // && paymentProviderProductId != null && paymentProviderProductId.length > 0
             ) {
            return true;
        }
        else {
            return false
        }
    }
    const clear = () => {

     

    }
    const onUnitChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
       var  unit=item?.key.toString().toLowerCase()!
        
         
       
        switch (unit) {
            case "week":
                if(transactionLength <=52){
                    setUnitValid(true)
                    setTransactionUnit(item)
                    unit=item?.key.toString().toLowerCase()!
                    UnitActive=unit
                    
                }
                else{
                    setUnitValid(false)
                    setTransactionUnit({key:"",text:""})
                   
                    //return "Billing period must be between 1 day and 1 year"
                }
                break;
                case "month":
                    if(transactionLength <=12){
                        setTransactionUnit(item)
                        unit=item?.key.toString().toLowerCase()!
                        setUnitValid(true)
                        UnitActive=unit
                       
                
                    }
                    else{
                        setTransactionUnit(item)
                        setUnitValid(false)
                        setTransactionUnit({key:"",text:""})
                       
                    }
                    break;
                case "year":
                    if(transactionLength <=1){
                        setTransactionUnit(item)
                        unit=item?.key.toString().toLowerCase()!
                        setUnitValid(true)
                        setUnitTransc(unit)
                        UnitActive=unit
                    }
                    else{
                        setUnitValid(false)
                        setTransactionUnit({key:"",text:""})
                        unit=""
                    }
                    break;
            
                    default:  setUnitValid(false)
                        break;
        }
        
    }


  
    const _onChangeTransactionLength = useConstCallback(
        async (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            var TotalDays=+Number(newValue)
            var result=""
           var messageErrorTl="Billing period must be between 1 Week and 1 year"
         
            if(UnitActive==="week"){
                result= TotalDays >52? messageErrorTl:""
            }
            else if(UnitActive==="month"){
                result= TotalDays >12?messageErrorTl:""
            }
            else if(UnitActive==="year"){
                result= TotalDays >1?messageErrorTl:""
            }
            else{
                result=""
            }
             if(result!==""){
            
                setTransationLength(0)
                setIsTransactionLengthValid(false)
             }
             else{
                setTransationLength(TotalDays)
                setIsTransactionLengthValid(true)
             }
          
        
        }
    );
    const getErrorTL = (value: string): string => {
        var tl=Number(value)!
        var result=""
        if (isTransactionLengthValid==false){
            result= "Billing period must be between 1 Week and 1 year";
        }
        else if(isTransactionLengthValid && (tl==undefined  ||tl==0)){
            result= "Billing Period Length must not be 0"
        }
        return result;
    };
    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <label className="ms-fontSize-18">Plans</label><br />
                        <div>Form, document and records management all-in-one. <br/>(Storage is total limit allowed over plan's life - delete documents and attachments to manage storage. Users are total, Administrative, application audience users per Plan. Submissions are total limits allowed per billing period.)</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <label className="ms-fontSize-18">Plans</label>
                    </div>
                </div>
                : null
            }

            <Stack tokens={stackTokens}>
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ActionButton title="Add new plan." text="Add Plan"
                            iconProps={{ iconName: 'Add' }} onClick={addPlan} />
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                    {!applying ?
                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "auto", paddingRight: "10px", maxWidth: "100%", height: "40vh" }}>
                            <DetailsList
                                items={planList}
                                compact={false}
                                columns={columns}
                                getKey={getKey}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                selection={selectionPlan}
                                selectionMode={SelectionMode.single}
                                selectionPreservedOnEmptyClick={false}
                                enterModalSelectionOnTouch={true}
                                checkboxVisibility={CheckboxVisibility.hidden}
                            />
                        </div>
                        : <Spinner size={SpinnerSize.medium} label="Loading data, please wait..." ariaLive="assertive" />
                    }
                </div>
            </Stack>
            <Modal isOpen={showAddEditPlanModal} isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Create">{modalType == ModalType.Add ? "New Plan" : "Edit Plan"}</span>
                                    {!props.fromModal ?
                                        <HelpComponent
                                            headline="Plans"
                                            text="Plan properties. Name determinates the plan's name and how it will be displayed in my billing plan. Description describes in more detail the plan characteristics. Storage limit is the amount of storage allowed for the user, defined by the number of documents and attachments uploaded. Document Submission Limit determinates the amount of submission allowed. Billing period type determinates de type of frequency for the plan (week, month, year). Billing period length determines how long the billing period type will last before its renewal (example: 2 weeks, 3 months, 1 year, etc.). If Billing period type value selected is Year, Transaction Length can only be 1; Month can only be from 1 to 12; Week can only be from 1 to 52."
                                            right="50px"
                                            top="15px"
                                            target="plansettings"
                                        />
                                        : null
                                    }
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideAddEditPlanModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className={classNames.bodyPlans}>
                              <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                    {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                <div className={modalType == ModalType.Edit ? classNames.bodyEditVWOverride : classNames.bodyVWOverride}>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <Label required title="Plan name.">Name</Label>
                                <TextField maxLength={100} onGetErrorMessage={(val) => getErrorMessage(val, "Name must not be empty.")} required onChange={(_, v) => { setName(v!); }} value={name} title="Plan name." />
                                <Label required title="Plan storage limit (in Gigabytes).">Storage Limit in GB</Label>
                                <TextField type="number" onGetErrorMessage={(val) => getErrorMessage(val, "Storage Limit must not be 0.")} required onChange={(_, v) => { setStorageLimit(+v!); }} value={storageLimit.toString()} title="Plan storage limit (in Gigabytes)." />
                                <Label required title="Type of periodicity for the plan.">Billing Period Type.</Label>
                                <Dropdown placeholder="Select an option" selectedKey={transactionUnit?.text}  options={optionsTransactionUnit} required errorMessage={ transactionUnit == undefined || transactionUnit?.key == "" ? isUnitValid? "Billing Period Type must not be empty.":"Billing period must be between 1 Week and 1 year" : ""} onChange={onUnitChange} />
                                <Label required title="Plan transaction amount.">Price</Label>
                                <TextField disabled={modalType == ModalType.Add ? false : true} type="number" min="1" pattern="^[0-9]" onGetErrorMessage={(val) => getErrorMessage(val, "Price must not be 0.")} required onChange={(_, v) => { setTransactionAmount(+v!); }} value={transactionAmount.toString()} title="Plan trasaction amount." />

                                    <Label required title="Super Users Plan limit.">Super Users Plan limit.</Label>
                                    <TextField type="number"
                                               min={0}
                                               onGetErrorMessage={(val) => getErrorMessage(val, "Super Users Plan limit must not be 0.")}
                                               required onChange={(_, v) => {
                                        setSuperuserLimit(+v!);
                                    }} value={superuserLimit.toString()} title="Super Users Plan limit."/>

                                    <Label required title="Workspace Users Plan limit.">Workspace Users Plan limit.</Label>
                                    <TextField type="number"
                                               min={0}
                                               required onChange={(_, v) => {
                                        setWorkspaceuserLimit(+v!);
                                    }} value={workspaceuserLimit !== undefined && workspaceuserLimit !== null ? workspaceuserLimit.toString() : "0"} title="Workspace Users Plan limit."/>

                                    <Label required title="Maximum Applications">Maximum Applications (set -1 value to unlimited).</Label>
                                    <TextField type="number"
                                               min={-1}
                                               required onChange={(_, v) => {
                                                setMaxApplicationAllowed(  isNaN(Number(v))? -1 : Number(v));
                                    }} value={maxApplicationAllowed !== undefined && maxApplicationAllowed !== null ? maxApplicationAllowed.toString() : "0"} title="Maximum Applications"/>

                                    
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <Label required title="Plan description.">Description</Label>
                                <TextField maxLength={100} onGetErrorMessage={(val) => getErrorMessage(val, "Description must not be empty.")} required onChange={(_, v) => { setDescription(v!); }} value={description} title="Plan description." />
                                <Label required title="Plan submission limit.">Document Submission Limit</Label>
                                <TextField type="number" onGetErrorMessage={(val) => getErrorMessage(val, "Submission Limit must not be 0.")} required onChange={(_, v) => { setSubmissionLimit(+v!); }} value={submissionLimit.toString()} title="Plan submission limit." />
                                <Label required title="Determines how long the billing period type will last before its renewal.">Billing Period Length</Label>
                                <TextField type="number" min="1" pattern="^[0-9]" onGetErrorMessage={(val) => getErrorTL(val)}  required onChange={_onChangeTransactionLength} value={transactionLength.toString()} title="Determines how long the billing period type will last before its renewal." />
                                <Label required title="Plan Type. Normal plans are displayed to all the clients. Customs are personalized plans.">Type</Label>
                                <Dropdown placeholder="Select an option" selectedKey={type?.text} options={optionsType} required errorMessage={type == undefined || type?.key == "" ? "Type must not be empty." : ""} onChange={(_, v) => { setType(v); }} />
                                <div  hidden={modalType == ModalType.Add ? true : false}>
                                <TextField  label="Payment Provider Product Id"  disabled={modalType == ModalType.Add ? false : true} maxLength={100} onGetErrorMessage={(val) => getErrorMessage(val, "Payment Provider Product Id must not be empty.")}  onChange={(_, v) => { setPaymentProviderProductId(v!); }} value={paymentProviderProductId!} title="Payment Provider Product Id." />

                                </div>
                                
                                </div>

                                  
                                    
                                   
                                   
                                </div>
                            </div>
                        </div>
                        </div>
                      
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={addEditPlan} disabled={!validForm()} text={modalType == ModalType.Add ? "Add" : "Update"} title={modalType == ModalType.Add ? "Create plan." : "Update plan."} />
                                    <DefaultButton onClick={hideAddEditPlanModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>

            <Modal isOpen={showDeletePlanModal} isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Delete">Delete Plan</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeletePlanModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div>
                                    Are you sure you want to delete '{selectedPlan.Name}' plan?
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={deletePlan} text="Delete" title="Delete Plan." />
                                    <DefaultButton onClick={hideDeletePlanModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>
        </>
    );
}

export default Plan

