import React, {Dispatch, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Sizes} from "../../share/CustomModal";
import {useModal} from "../../hooks/useModal";
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import {Separator, Spinner, SpinnerSize, Stack, StackItem, Text, TextField} from "@fluentui/react";
import Helper, {IDocumentOnlyAudienceDetailRequest} from "../../Helpers/Helper";
import {ActionButton, DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";
import {_styles, AudiencesService, footer, IAvailableRolesResponse} from "./index"
import {FormProvider, useForm} from "react-hook-form";
import {Checkbox} from "@fluentui/react/lib/Checkbox";
import {Callout} from "@fluentui/react/lib/Callout";

type Properties = {
    showModal: boolean
    setShowModal: Dispatch<boolean>
    templateId: string | undefined
    audienceMasterId: string | undefined
    onDismissModal: (update?: boolean) => void
}

const AudiencesAddUser = ({showModal, onDismissModal, setShowModal, audienceMasterId, templateId}: Properties) => {

    const {t} = useTranslation(['common'])
    const myModal = useModal()
    const myForm = useForm({mode: "onSubmit"});

    const [message, setMessage] = useState("");
    const [userEmail, setUserEmail] = useState<string | undefined>("");

    const [statusMessage, setStatusMessage] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isSaved, setIsSaved] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isCalloutVisible, setIsCalloutVisible] = useState(false)
    const [invalidForm, setInvalidForm] = useState(true)
    const [roleDocumentcreator, setRoleDocumentcreator] = useState(false)
    const [roleCoOwner, setRoleCoOwner] = useState(false)
    const [enabledRoleDocumentcreator, setEnabledRoleDocumentcreator] = useState(false)
    const [enabledRoleCoOwner, setEnabledRoleCoOwner] = useState(false)

    const [planName, setPlanName] = useState("")
    const [planDescription, setPlanDescription] = useState("")
    const [superUserLimit, setSuperUserLimit] = useState(0)
    const [workspaceUserLimit, setWorkspaceUserLimit] = useState(0)

    useEffect(() => {
        if (showModal) {
            clearForm()
            myModal.open()
        }
    })

    /**
     * Clear the form values
     */
    const clearForm = () => {
        setUserEmail("")
        myForm.reset()
        clearFields()
    }

    /**
     * Clear form fields
     */
    const clearFields = () => {

        setMessage("")
        setShowMessage(false)
        setIsValidEmail(false)
        setIsSaving(false)
        setIsSaved(false)
        setInvalidForm(true)
        setEnabledRoleDocumentcreator(false)
        setEnabledRoleCoOwner(false)
        setRoleDocumentcreator(false)
        setRoleCoOwner(false)
        setIsCalloutVisible(false)
        setStatusMessage(StatusEnum.Info)
        setPlanName("")
        setPlanDescription("")
        setSuperUserLimit(0)
        setWorkspaceUserLimit(0)
    }

    /**
     * Adding the user
     */
    const saving = async () => {

        setIsSaving(true)
        const request: IDocumentOnlyAudienceDetailRequest = {

            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite().then(),
            AudienceDetailId: "-1",
            AudienceMasterId: audienceMasterId,
            TargetUserName: userEmail,
            Coowner: roleCoOwner,
            CreateDocument: roleDocumentcreator,
            TargetSite: window.location.origin,
            TemplateId: templateId!
        }

        AudiencesService.PostCreateDocumentOnlyAudienceDetail(request).then(
            async data => {
                if (!data.Success) {
                    setStatusMessage(StatusEnum.Error)
                    setMessage(data.ExceptionMessage)
                    setShowMessage(true)
                    setIsSaving(false)
                } else {
                    setStatusMessage(StatusEnum.Success)
                    setMessage("The user has been successfully added to the Audience.")
                    setShowMessage(true)
                    setIsSaved(true)
                    setTimeout(function () {
                        onDismissModal(true)
                        myModal.close()
                    }, 2000);
                }

            }
        ).catch((error) => {
                setStatusMessage(StatusEnum.Error)
                setMessage(error)
                setShowMessage(true)
                setIsSaving(false)
            }
        ).finally(() => {
            }
        )

    }

    /**
     * View available roles based on the specified username
     */
    const checkAvailability = async () => {
        clearFields()
        setIsProcessing(true)
        await myForm.handleSubmit(async () => {
            myForm.clearErrors()

            const request: IDocumentOnlyAudienceDetailRequest = {
                UserName: Helper.getUsername(),
                SK: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                ApiKey: "-1",
                Autoprovision: true,
                Path: await Helper.getSite().then(),
                AudienceDetailId: "-1",
                AudienceMasterId: audienceMasterId,
                TargetUserName: userEmail,
                TargetSite: window.location.origin,
                TemplateId: templateId!
            }

            AudiencesService.GetAudienceRolesAvailabilityByNewUser(request).then(
                async data => {
                    if (!data.Success) {
                        setStatusMessage(StatusEnum.Error)
                        setMessage(data.ExceptionMessage)
                        setShowMessage(true)
                    } else {
                        let response: IAvailableRolesResponse
                        try {
                            response = JSON.parse(data.Result)
                            setEnabledRoleDocumentcreator(response.CreateDocument)
                            setEnabledRoleCoOwner(response.CoOwner)
                            setPlanName(response.PlanName)
                            setPlanDescription(response.PlanDescription)
                            setSuperUserLimit(response.UserLimit)
                            setWorkspaceUserLimit(response.WorkspaceOnlyUsers)
                            setIsValidEmail(true)
                        } catch (error:any) {
                            setStatusMessage(StatusEnum.Error)
                            setMessage(error)
                            setShowMessage(true)
                        }

                    }
                }
            ).catch((error) => {
                    setStatusMessage(StatusEnum.Error)
                    setMessage(error)
                    setShowMessage(true)
                }
            ).finally(() => {
                    setIsProcessing(false)
                }
            )

        }, () => {
            setIsProcessing(false)
            setIsValidEmail(false)

        })()
    }

    return (
        <>
            <myModal.Modal
                title="Add User" titleId="formAsemblyRuleActionsModal" size={Sizes.xs}
                afterClose={setShowModal(false)}
                bodyClassName={_styles.body}
            >
                <>
                    <Stack styles={{root: {padding: "4px 16px"}}}>
                        {showMessage &&
                            <>
                                <StatusMessage status={statusMessage} hasTimer={false}
                                               truncated={true}
                                               isMultiline={true}
                                               dismiss={true}
                                               setShowMessage={setShowMessage}>
                                    <div>{message}</div>
                                </StatusMessage>
                                <br/>
                            </>
                        }

                        <FormProvider {...myForm}>
                            <form>
                                <Stack horizontal wrap styles={{root: {alignItems: "center"}}}>

                                    <StackItem grow={1}>

                                        <TextField
                                            {...myForm.register("userEmail", {

                                                required: "Email address required",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}
                                            label="Email"
                                            type=""
                                            disabled={isValidEmail || isSaving}
                                            required
                                            value={userEmail!.trim()}
                                            onGetErrorMessage={() => Helper.isEmailValid(userEmail!.trim()) ? "" : "Invalid email address"}
                                            placeholder={"Enter email address"}
                                            onChange={(_, newValue) => setUserEmail(newValue)}
                                            maxLength={320} title="Enter email address for username."/>
                                    </StackItem>
                                    <StackItem styles={{
                                        root: {
                                            '@media(min-width: 1199.98px)': {
                                                padding: '28px 0px 0px 8px',
                                            },
                                            '@media(max-width: 1199.88px)': {
                                                padding: '8px 0px',
                                                width: "100%"
                                            }
                                        }
                                    }}>
                                        {isValidEmail ?

                                            <PrimaryButton
                                                onClick={clearFields}
                                                disabled={isSaving}
                                                text={t('Common.Button.Clear', {ns: 'common'})}
                                                title={t('Common.Button.Clear', {ns: 'common'})}/>

                                            :
                                            <PrimaryButton
                                                styles={{root: {width: "100%"}}}
                                                disabled={isProcessing || isSaving}
                                                onClick={checkAvailability}
                                                text={t('Common.Button.CheckAvailability', {ns: 'common'})}
                                                title={t('Common.Button.CheckAvailability', {ns: 'common'})}/>
                                        }

                                    </StackItem>
                                </Stack>
                            </form>
                        </FormProvider>
                        <StackItem styles={{root: {paddingTop: 16}}}>
                            <Separator>Available roles:</Separator>
                        </StackItem>

                        <Stack tokens={{padding: 16, childrenGap: 8}}>
                            <StackItem>
                                <Checkbox id="coowner" label="Co-owner (Super User)"
                                          checked={roleCoOwner}
                                          onChange={(ev, checked) => {
                                              setRoleCoOwner(checked!)
                                              if(!checked) {
                                               
                                               
                                                  if(!enabledRoleDocumentcreator ) {
                                               
                                                      setRoleDocumentcreator(false)
                                                  }
                                              }
                                            
                                              if (checked || roleDocumentcreator) setInvalidForm(false)
                                              else {
                                                  setInvalidForm(true)
                                              }
                                          }}
                                          disabled={!enabledRoleCoOwner || isSaving}
                                          title="Grants Co-Owner role to manage the Application."/>
                            </StackItem>
                            <StackItem>
                                <Checkbox id="documentcreator" label="Document Creator (Workspace User)"
                                          checked={roleDocumentcreator}
                                          disabled={(!enabledRoleDocumentcreator && !roleCoOwner ) || isSaving}
                                          onChange={(ev, checked) => {
                                              setRoleDocumentcreator(checked!)
                                              if (checked || roleCoOwner) setInvalidForm(false)
                                              else {
                                                  setInvalidForm(true)
                                              }

                                          }}
                                          title="Grants Document Creator role to manage the Application."/>
                            </StackItem>
                            <StackItem>
                                <ActionButton className={"rolesCallout"} iconProps={{iconName: "UserGauge"}}
                                              onClick={() => setIsCalloutVisible(true)}
                                              disabled={!isValidEmail || isSaving}>
                                    View Owner's Plan details
                                </ActionButton>


                                {isCalloutVisible &&
                                    <Callout
                                        className={_styles.callout}
                                        role="alertdialog"
                                        gapSpace={0}
                                        target={".rolesCallout"}
                                        onDismiss={() => setIsCalloutVisible(false)}
                                        setInitialFocus
                                    >
                                        <Stack tokens={{padding: 16}}>
                                            <Text variant={"mediumPlus"} styles={{root: {fontWeight: "500"}}}
                                                  block>{`Plan: ${planName}`}</Text>
                                            <Text variant={"small"} block>{`Description: ${planDescription}`}</Text>
                                            <Separator/>
                                            <Text variant={"medium"}
                                                  block>{`Super Users Plan limit: ${Helper.FormatNumber(superUserLimit)}`}</Text>
                                            <Text variant={"medium"}
                                                  block>{`Workspace Users Plan limit: ${Helper.FormatNumber(workspaceUserLimit)}`}</Text>

                                        </Stack>

                                    </Callout>
                                }

                            </StackItem>
                        </Stack>


                    </Stack>

                    {/*START Footer*/}
                    <Separator/>
                    <Stack horizontal style={{overflow: "hidden"}} styles={footer}>
                        <StackItem grow={1}>&nbsp;</StackItem>
                        {(isProcessing) &&
                            <>
                                <StackItem>
                                    <Spinner size={SpinnerSize.medium} labelPosition="left"
                                             label={t('text.spinnerVerifying', {ns: 'common'})}/>
                                </StackItem>
                            </>
                        }
                        {isSaving && !isSaved &&
                            <>
                                <StackItem>
                                    <Spinner size={SpinnerSize.medium} labelPosition="left"
                                             label={t('text.spinnerSaving', {ns: 'common'})}/>
                                </StackItem>
                            </>
                        }

                        <StackItem styles={{root: {marginRight: 16}}}>
                            <PrimaryButton disabled={invalidForm || isSaving}
                                           style={{marginLeft: 8}}
                                           onClick={saving}
                                           text={t('Common.Button.Add', {ns: 'common'})}
                                           title={t('Common.Button.Add', {ns: 'common'})}/>

                            <DefaultButton
                                disabled={isSaving}
                                onClick={() => {
                                    myModal.close()
                                }} style={{marginLeft: 8}}
                                text={t('Common.Button.Close', {ns: 'common'})}
                                title={t('Common.Button.Close', {ns: 'common'})}/>
                        </StackItem>
                    </Stack>
                    {/*END Footer*/}

                </>

            </myModal.Modal>
        </>
    )
}

export default AudiencesAddUser