import React, {useContext, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import '../../Custom.css';
import {
    DatePicker,
    getTheme,
    MessageBarButton,
    MessageBarType,
    Position,
    SpinButton,
    Stack,
    StackItem
} from "@fluentui/react";
import {DefaultButton, IconButton, PrimaryButton} from "@fluentui/react/lib/Button";
import {Text} from "@fluentui/react/lib/Text";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import {Separator} from "@fluentui/react/lib/Separator";
import {Modal} from "@fluentui/react/lib/Modal";
import {IIconProps} from "@fluentui/react/lib/Icon";
import {contentStyles, iconButtonStyles, nonShrinkingStackItemStyles} from "./Style"
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";
import {Spinner, SpinnerSize} from "@fluentui/react/lib/Spinner";
import RenewalsAndAlertsService, {
    IRecordRenewal,
    ResponseGetContextRecord,
    ResponseGetContextTemplate
} from "./RenewalsAndAlerts.service";

import {FormProvider, useForm} from "react-hook-form";
import {calculateYears, calculateYearsMonths, frequencyOptions} from "./RenewalsAndAlerts.data";
import {RHFDropDownListSingleSelectControl} from "../RHFControls/RHFDropDownListSingleSelectControl";
import {Shimmer} from "office-ui-fabric-react/lib/Shimmer";
import moment from "moment-with-locales-es6";
import Helper from "../../Helpers/Helper";
import {AppContext} from "../Provider";
import {IContextualMenuProps, MessageBar, TextField} from "office-ui-fabric-react";
import {RHFCheckBoxControl} from "../RHFControls/RHFChekBoxControl";
import CustomDialog from "../../share/CustomDialog";
import {formatFromUTC, formatSimpleDate} from "../../Helpers/DateUtils";
import { useBranding } from '../../hooks/useBranding';

type Properties = {
    showModal: boolean
    lockAtStartup: boolean
    onDismissModal: (update?: boolean) => void;
    item: IRecordRenewal

}

function RenewalsAndAlertsEdit({
                                   showModal,
                                   item,
                                   onDismissModal,
                                   lockAtStartup
                               }: Properties) {
    const { branding } = useBranding()
    const {t} = useTranslation(['renewalsalerts', 'common'])
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const [isLoadingRecordContext, setIsLoadingRecordContext] = useState(true)
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [dateCreatedFrom, setCreatedFrom] = useState<Date>(new Date());
    const [isActive, setIsActive] = useState(true)

    const [isLoadedContext, setIsLoadedContext] = useState(false)

    const [id, setId] = useState(0)
    const [messagePivot1, setMessagePivot1] = useState("")
    const [messagePivot2, setMessagePivot2] = useState("")
    const [statusMessageEnumPivot2, setStatusMessageEnumPivot2] = useState<StatusEnum>(StatusEnum.Info)
    const [statusMessageEnumPivot1, setStatusMessageEnumPivot1] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessagePivot2, setShowMessagePivot2] = useState(false)
    const [showMessagePivot1, setShowMessagePivot1] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const formRenewals = useForm();
    const {context} = useContext(AppContext)
    const [updated, setUpdated] = useState(false)

    const loadTemplateRenewalSettings = (preloadDefaults: boolean = true) => {

        setMessagePivot2("")
        setShowMessagePivot2(false)

        RenewalsAndAlertsService.getContextTemplate({
            Id: Number(item.TemplateId)
        }).then(data => {

            try {
                const items: ResponseGetContextTemplate[] = JSON.parse(data.result)
                if (Array.isArray(items)) {
                    if (items.length == 0) {
                        showStatusMessage2(t('messages.renewalTemplateNoSetting', {ns: 'renewalsalerts'}), StatusEnum.Error)
                    } else {
                        if (preloadDefaults) {
                            formRenewals.setValue("hiddenRenewalsMonths", items[0].RenewalMonths)
                            formRenewals.setValue("renewalsMonths", calculateYears(items[0].RenewalMonths))
                            formRenewals.setValue("hiddenNotifytriggerDays", items[0].NotifyTriggerDaysBeforeRenewal)
                            formRenewals.setValue("notifytriggerDays", calculateYearsMonths(items[0].NotifyTriggerDaysBeforeRenewal))

                            formRenewals.setValue("notifyTriggerFrequency", {
                                key: items[0].NotifyTriggerFrequency.toLowerCase(),
                                label: items[0].NotifyTriggerFrequency
                            })

                            const date = new Date()
                            date.setMonth(date.getMonth() + items[0].RenewalMonths)
                            setCreatedFrom(date)

                            showStatusMessage(t('messages.defaultData', {ns: 'renewalsalerts'}), StatusEnum.Info)
                        }

                    }
                }
            } catch {

            }

        }).finally(() => {

            }
        )
    }

    const load = async (clearmessages: boolean = false) => {

        setId(0)
        setIsLoadedContext(false)
        setIsLoadingRecordContext(true)

        if (clearmessages) {
            setMessagePivot1("")
            setShowMessagePivot1(false)
        }

        // START GET Context
        formRenewals.reset()
        formRenewals.setValue("notifyTriggerFrequency", {
            key: "daily",
            label: "Daily"
        })
        formRenewals.setValue("active", true)
        formRenewals.setValue("hiddenRenewalsMonths", 0)
        formRenewals.setValue("LastNotificationDate", "")
        formRenewals.setValue("Category", "")
        formRenewals.setValue("TemplateName", "")
        formRenewals.setValue("LastNotificationCount", 0)
        formRenewals.setValue("renewalsMonths", calculateYears(0))
        formRenewals.setValue("hiddenNotifytriggerDays", 0)
        formRenewals.setValue("notifytriggerDays", calculateYearsMonths(0))

        setCreatedFrom(new Date())
        setIsNew(false)

        RenewalsAndAlertsService.getContextRecord({
            Id: item.RecordId
        }).then(data => {

            try {
                const items: ResponseGetContextRecord[] = JSON.parse(data.result)
                if (Array.isArray(items)) {
                    if (items.length > 0) {
                        setCreatedFrom(new Date(items[0].RenewalDate))

                        setId(items[0].Id)

                        formRenewals.setValue("notifyTriggerFrequency", {
                            key: items[0].NotifyTriggerFrequency.toLowerCase(),
                            label: items[0].NotifyTriggerFrequency
                        })

                        formRenewals.setValue("Category", items[0].Category)
                        formRenewals.setValue("TemplateName", items[0].DisplayName)
                        formRenewals.setValue("LastNotificationDate", items[0].LastNotificationDate ? onFormatDate(new Date(items[0].LastNotificationDate), true) : t('text.noNotifications', {ns: 'renewalsalerts'}))
                        formRenewals.setValue("LastNotificationCount", String(Intl.NumberFormat('en-US', {maximumSignificantDigits: 3}).format(items[0].LastNotificationCount ? items[0].LastNotificationCount : 0)))

                        formRenewals.setValue("hiddenRenewalsMonths", items[0].RenewalMonths)
                        formRenewals.setValue("renewalsMonths", calculateYears(items[0].RenewalMonths))

                        formRenewals.setValue("hiddenNotifytriggerDays", items[0].NotifyTriggerDaysBeforeRenewal)
                        formRenewals.setValue("notifytriggerDays", calculateYearsMonths(items[0].NotifyTriggerDaysBeforeRenewal))

                        formRenewals.setValue("active", items[0].Active)
                        loadTemplateRenewalSettings(false)
                    } else {
                        setIsNew(true)
                        loadTemplateRenewalSettings(true)
                        setIsReadOnly(false)
                    }
                    setIsLoadedContext(true)
                } else {
                    showStatusMessage(t('message.error', {
                        ns: 'common',
                        Error: data.result !== undefined ? data.result : data
                    }), StatusEnum.Error)
                }
            } catch (e:any) {
                showStatusMessage(t('message.error', {
                    ns: 'common',
                    Error: e.toString()
                }), StatusEnum.Error)
            }

        }).catch(error => {
                showStatusMessage(t('message.error', {ns: 'common', Error: error.toString()}), StatusEnum.Error)
            }
        ).finally(() => {
                setIsLoadingRecordContext(false)
            }
        )
        // END GET Context

    }
    useEffect(() => {
        if (showModal) {
            setIsReadOnly(lockAtStartup)
            load(true).then()
        }
    }, [showModal])

    /**
     * Notify message if date is less than today's date
     * @param date
     */
    const onSelectDate = (date: Date | null | undefined) => {
        if (date !== null && date !== undefined) {

            const now = new Date();

            now.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);

            if (date < now) {
                showStatusMessage(t('messages.renewalDateLesstoToday', {ns: 'renewalsalerts'}), StatusEnum.Warning)
            } else {
                setShowMessagePivot1(false)
            }
            setCreatedFrom(date)
        }
    }

    /**
     * Delete
     */
    const deleteRecordDefinition = async () => {
        setIsSaving(true)
        await RenewalsAndAlertsService.postDeleteRecordDefinition({
            Id: id,
            TemplateId: Number(item?.TemplateId),
            RecordId: Number(item?.RecordId)
        }).then(data => {

            if (!data.success) {
                showStatusMessage(data.messageError ? data.messageError : "", StatusEnum.Error)
            } else {
                showStatusMessage(t('message.successRemoved', {ns: 'common'}), StatusEnum.Warning)
                load(false)
                setUpdated(true)

            }
        }).catch((reason) => {
            showStatusMessage(`${reason.data.ExceptionMessage}: ${reason.data.AxiosError}`, StatusEnum.Error)
        }).finally(() => {
                setIsSaving(false)
                setShowDelete(false)
                context.setRefreshRenewals(true)
            }
        )
    }

    /**
     * Update
     */
    const update = async () => {
        await formRenewals.handleSubmit(async () => {
                setIsSaving(true)
                console.log(dateCreatedFrom)
                await RenewalsAndAlertsService.postUpdateRecordDefinition({

                    TemplateId: Number(item.TemplateId),
                    RecordId: Number(item.RecordId),
                    NotifyTriggerDaysBeforeRenewal: Number(formRenewals.getValues("hiddenNotifytriggerDays")),
                    RenewalMonths: Number(formRenewals.getValues("hiddenRenewalsMonths")),
                    NotifyTriggerFrequency: formRenewals.getValues("notifyTriggerFrequency").key,
                    RenewalDate: moment(dateCreatedFrom).locale('en-US').format('MM/DD/YYYY'),
                    Active: Boolean(formRenewals.getValues("active"))

                }).then(data => {

                    if (!data.success) {
                        showStatusMessage(data.messageError ? data.messageError : "", StatusEnum.Error)
                    } else {
                        showStatusMessage(t('message.successUpdated', {ns: 'common'}), StatusEnum.Success)
                        setUpdated(true)
                        load()
                    }
                }).catch((reason) => {
                    showStatusMessage(`${reason.data.ExceptionMessage}: ${reason.data.AxiosError}`, StatusEnum.Error)
                }).finally(() => {
                        setIsSaving(false)
                        context.setRefreshRenewals(true)
                    }
                )

            }, () => {

            }
        )();
    }

    const showStatusMessage = (message: string, status: StatusEnum) => {
        setMessagePivot1(message)
        setStatusMessageEnumPivot1(status)
        setShowMessagePivot1(true)
    }

    const showStatusMessage2 = (message: string, status: StatusEnum) => {
        setMessagePivot2(message)
        setStatusMessageEnumPivot2(status)
        setShowMessagePivot2(true)
    }

    /**
     * Months SpinButton increment
     */
    const onSpinButtonIncrement = () => {
        let totalMonths = Number(formRenewals.getValues("hiddenRenewalsMonths")) + 1
        if (totalMonths > 1200) totalMonths = 1200;
        formRenewals.setValue("hiddenRenewalsMonths", totalMonths)
        return calculateYears(totalMonths)
    }

    /**
     * Months SpinButton Decrement
     */
    const onSpinButtonDecrement = () => {
        let totalMonths = Number(formRenewals.getValues("hiddenRenewalsMonths")) - 1
        if (totalMonths < 0) totalMonths = 0;
        formRenewals.setValue("hiddenRenewalsMonths", totalMonths)
        return calculateYears(totalMonths)
    }

    /**
     * Months SpinButton Validate
     */
    const onSpinButtonValidate = (value: string) => {
        let totalMonths = Number(value)
        if (!isNaN(totalMonths)) {
            if (totalMonths < 0) totalMonths = 0;
            if (totalMonths > 1200) totalMonths = 1200;
        } else {
            totalMonths = Number(formRenewals.getValues("hiddenRenewalsMonths"))
        }
        formRenewals.setValue("hiddenRenewalsMonths", totalMonths)
        return calculateYears(totalMonths)
    }

    /**
     * Months SpinButton increment
     */
    const onSpinButtonIncrementDays = () => {
        let total = Number(formRenewals.getValues("hiddenNotifytriggerDays")) + 1
        if (total > 36500) total = 36500;
        formRenewals.setValue("hiddenNotifytriggerDays", total)
        return calculateYearsMonths(total)
    }

    /**
     * Months SpinButton Decrement
     */
    const onSpinButtonDecrementDays = () => {
        let total = Number(formRenewals.getValues("hiddenNotifytriggerDays")) - 1
        if (total < 0) total = 0;
        formRenewals.setValue("hiddenNotifytriggerDays", total)
        return calculateYearsMonths(total)
    }

    /**
     * Create
     */
    const create = async () => {
        await formRenewals.handleSubmit(async () => {

                setIsSaving(true)
                await RenewalsAndAlertsService.postCreateRecordDefinition({
                    TemplateId: Number(item.TemplateId),
                    RecordId: Number(item.RecordId),
                    NotifyTriggerDaysBeforeRenewal: Number(formRenewals.getValues("hiddenNotifytriggerDays")),
                    RenewalMonths: Number(formRenewals.getValues("hiddenRenewalsMonths")),
                    NotifyTriggerFrequency: formRenewals.getValues("notifyTriggerFrequency").key,
                    RenewalDate: moment(dateCreatedFrom).locale('en-US').format('MM/DD/YYYY'),
                }).then(data => {
                    if (!data.success) {
                        showStatusMessage(data.messageError ? data.messageError : "", StatusEnum.Error)
                    } else {

                        showStatusMessage(t('message.successCreated', {ns: 'common'}), StatusEnum.Success)
                        load()
                        setUpdated(true)
                    }
                }).catch((reason) => {
                    showStatusMessage(`${reason.data.ExceptionMessage}: ${reason.data.AxiosError}`, StatusEnum.Error)
                }).finally(() => {
                        setIsSaving(false)
                        context.setRefreshRenewals(true)
                    }
                )
            }, () => {

            }
        )();
    }

    /**
     * Submenu
     */
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'deleteTemplateDefinition',
                text: t('Common.Button.Remove', {ns: 'common'}),
                iconProps: {iconName: 'Trash'},
                onClick: () => {
                    setShowDelete(true)
                }
            },
        ],
    };

    /**
     * Months SpinButton Validate
     */
    const onSpinButtonValidateDays = (value: string) => {
        let total = Number(value)
        if (!isNaN(total)) {
            if (total < 0) total = 0;
            if (total > 36500) total = 36500;
        } else {
            total = Number(formRenewals.getValues("hiddenNotifytriggerDays"))
        }
        formRenewals.setValue("hiddenNotifytriggerDays", total)
        return calculateYearsMonths(total)
    }

    /**
     * Format Date using moment
     * @param date
     * @param utc
     */
    const onFormatDate = (date?: Date, utc: boolean = false): string => {

        const language = !!sessionStorage.getItem("language")
            ? sessionStorage.getItem("language")!.toString()
            : "en";

        if (utc) {
            const now_utc = Date.UTC(date!.getFullYear(), date!.getMonth(),
                date!.getDate(), date!.getHours(),
                date!.getMinutes(), date!.getSeconds());

            return date !== null ? formatFromUTC(now_utc, "LL", language) : null
        } else return date !== null ? formatSimpleDate(date, "LL", language) : null
    }

    const onBeforeDismiss = () => {
        // setIsReadOnly(true)
        setUpdated(false)
    }

    return (
        <>
            <Modal
                isOpen={showModal}
                isBlocking={true}
                containerClassName={contentStyles(branding.theme).containerRecord}
            >
                {/*-- START HEADER --*/}
                <Stack horizontal className={contentStyles(branding.theme).header}>


                    <StackItem grow={1}
                               styles={{root: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}}>
                        <Text variant={"xLarge"} id={item.RecordId.toString()}
                              title={t('title.modal', {ns: 'renewalsalerts', Name: item.Name})}>
                            {t('title.modal', {ns: 'renewalsalerts', Name: item.Name})}
                        </Text>
                    </StackItem>
                    <Stack horizontal styles={{root: {alignItems: "center"}}}>
                        <HelpComponentRelative
                            headline={t('title.help', {ns: 'renewalsalerts'})}
                            text={t('text.help', {ns: 'renewalsalerts', AppName: branding.appName})}
                            location=""
                        />
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                            onClick={() => {
                                onBeforeDismiss()
                                onDismissModal(updated)
                            }}
                            title={t('Common.Button.Close', {ns: 'common'})}
                        />
                    </Stack>
                </Stack>
                <Separator/>

                {/*-- END HEADER --*/}

                {/*-- START BODY --*/}
                <Stack horizontal tokens={{padding: 8}} style={{overflow: "hidden"}}>

                    {/*-- START CONTENT --*/}
                    <Stack grow={1} style={{overflow: "hidden", padding: "0px 8px 8px 8px"}}>
                        <StackItem>

                            {isLoadedContext && isReadOnly &&
                                <Stack>
                                    <MessageBar
                                        styles={{root: {marginBottom: 16}}}
                                        messageBarType={MessageBarType.warning}
                                        isMultiline={false}
                                        actions={
                                            <div>
                                                <MessageBarButton onClick={() => {
                                                    setIsReadOnly(false)
                                                }}>{t('Common.Button.Enable', {ns: 'common'})}</MessageBarButton>
                                            </div>
                                        }
                                    >
                                        {t('text.enableEdit', {ns: 'renewalsalerts'})}

                                    </MessageBar>
                                </Stack>
                            }

                            {showMessagePivot2 &&
                                <StackItem styles={{root: {marginBottom: 16}}}>
                                    <StatusMessage status={statusMessageEnumPivot2} hasTimer={false}
                                                   truncated={true}
                                                   isMultiline={true}
                                                   dismiss={false}
                                                   setShowMessage={setShowMessagePivot2}>
                                        <div>{messagePivot2}</div>
                                    </StatusMessage>
                                </StackItem>
                            }

                            {showMessagePivot1 &&
                                <>
                                    <StackItem styles={{root: {marginBottom: 16}}}>

                                        <StatusMessage status={statusMessageEnumPivot1} hasTimer={false}
                                                       truncated={true}
                                                       isMultiline={true}
                                                       dismiss={true}
                                                       setShowMessage={setShowMessagePivot1}>
                                            <div>{messagePivot1}</div>
                                        </StatusMessage>
                                    </StackItem>

                                </>
                            }
                        </StackItem>

                        <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                               style={{
                                   overflow: "hidden",
                                   overflowY: "auto",
                                   height: "40vh",
                                   paddingRight: 16
                               }}>


                            <FormProvider {...formRenewals}>
                                <form>


                                    {!isLoadingRecordContext && !isLoadedContext &&
                                        <>
                                            {t('text.errorAndTry', {ns: 'common'})}
                                            <DefaultButton onClick={() => {
                                                load(true).then()
                                            }} style={{marginLeft: 8}}
                                                           text={t('Common.Button.Try', {ns: 'common'})}
                                                           title={t('Common.Button.Try', {ns: 'common'})}/>
                                        </>
                                    }
                                    {isLoadingRecordContext && <>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Separator/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                        <Shimmer styles={{root: {marginTop: 16}}}/>
                                    </>}
                                    {isLoadedContext &&
                                        <>
                                            <Stack>
                                                <StackItem grow={1}>

                                                    {!isNew && <>
                                                        <TextField
                                                            id={"Category"}
                                                            label={t('label.ctrCategory', {ns: 'renewalsalerts'})}
                                                            placeholder={t('label.ctrCategory', {ns: 'renewalsalerts'})}
                                                            title={t('label.ctrCategory', {ns: 'renewalsalerts'})}
                                                            ariaLabel={t('label.ctrCategory', {ns: 'renewalsalerts'})}
                                                            disabled={true}
                                                            value={formRenewals.getValues("Category")}
                                                        />

                                                        <TextField
                                                            id={"TemplateName"}
                                                            label={t('label.ctrTemplateName', {ns: 'renewalsalerts'})}
                                                            placeholder={t('label.ctrTemplateName', {ns: 'renewalsalerts'})}
                                                            title={t('label.ctrTemplateName', {ns: 'renewalsalerts'})}
                                                            ariaLabel={t('label.ctrTemplateName', {ns: 'renewalsalerts'})}
                                                            disabled={true}
                                                            value={formRenewals.getValues("TemplateName")}
                                                        />


                                                        <TextField
                                                            id={"LastNotificationDate"}
                                                            label={t('label.ctrLastRenewalNotification', {ns: 'renewalsalerts'})}
                                                            placeholder={t('label.ctrLastRenewalNotification', {ns: 'renewalsalerts'})}
                                                            title={t('label.ctrLastRenewalNotification', {ns: 'renewalsalerts'})}
                                                            ariaLabel={t('label.ctrLastRenewalNotification', {ns: 'renewalsalerts'})}
                                                            disabled={true}
                                                            value={formRenewals.getValues("LastNotificationDate")}
                                                        />

                                                        <TextField
                                                            id={"LastNotificationCount"}
                                                            label={t('label.colLastRenewalNotificationCount', {ns: 'renewalsalerts'})}
                                                            placeholder={t('label.colLastRenewalNotificationCount', {ns: 'renewalsalerts'})}
                                                            title={t('label.colLastRenewalNotificationCount', {ns: 'renewalsalerts'})}
                                                            ariaLabel={t('label.colLastRenewalNotificationCount', {ns: 'renewalsalerts'})}
                                                            disabled={true}
                                                            value={formRenewals.getValues("LastNotificationCount")}
                                                        />
                                                    </>
                                                    }
                                                </StackItem>

                                            </Stack>

                                            <input
                                                type="hidden" {...formRenewals.register("hiddenRenewalsMonths")}
                                                value="0"/>
                                            <StackItem>
                                                <SpinButton
                                                    id={"renewalsMonths"}
                                                    label={t('label.ctrlMonths', {ns: 'renewalsalerts'})}
                                                    title={t('label.ctrlMonths', {ns: 'renewalsalerts'})}
                                                    ariaLabel={t('label.ctrlMonths', {ns: 'renewalsalerts'})}
                                                    labelPosition={Position.top}
                                                    min={0}
                                                    max={1200}
                                                    disabled={isReadOnly || !isActive}
                                                    onIncrement={onSpinButtonIncrement}
                                                    onDecrement={onSpinButtonDecrement}
                                                    onValidate={onSpinButtonValidate}
                                                    value={calculateYears(formRenewals.getValues("hiddenRenewalsMonths"))}
                                                />

                                            </StackItem>
                                            <StackItem>
                                                <input
                                                    type="hidden" {...formRenewals.register("hiddenNotifytriggerDays")}
                                                    value="0"/>

                                                <SpinButton
                                                    id={"notifytriggerDays"}
                                                    label={t('label.ctrlDays', {ns: 'renewalsalerts'})}
                                                    title={t('label.ctrlDays', {ns: 'renewalsalerts'})}
                                                    ariaLabel={t('label.ctrlDays', {ns: 'renewalsalerts'})}
                                                    labelPosition={Position.top}
                                                    min={0}
                                                    max={36500}
                                                    disabled={isReadOnly || !isActive}
                                                    onValidate={onSpinButtonValidateDays}
                                                    onIncrement={onSpinButtonIncrementDays}
                                                    onDecrement={onSpinButtonDecrementDays}
                                                    value={calculateYearsMonths(formRenewals.getValues("hiddenNotifytriggerDays"))}

                                                />
                                            </StackItem>

                                            <StackItem>
                                                <RHFDropDownListSingleSelectControl
                                                    id={"notifyTriggerFrequency"}
                                                    label={t('label.ctrlFrecuency', {ns: 'renewalsalerts'})}
                                                    title={t('label.ctrlFrecuency', {ns: 'renewalsalerts'})}
                                                    options={frequencyOptions()}
                                                    control={formRenewals.control}
                                                    setValue={formRenewals.setValue}
                                                    getValues={formRenewals.getValues}
                                                    disabled={isReadOnly || !isActive}
                                                    trigger={formRenewals.trigger}
                                                    defaultValue={formRenewals.getValues("notifyTriggerFrequency").key!}
                                                />

                                            </StackItem>


                                            <Stack tokens={{padding: 16}}
                                                   styles={{
                                                       root: {
                                                           marginTop: 16,
                                                           backgroundColor: getTheme().semanticColors.listItemBackgroundHovered
                                                       }
                                                   }}>

                                                <DatePicker
                                                    strings={Helper.getLocateCalendar()}
                                                    disabled={isReadOnly || !isActive}
                                                    today={new Date()}
                                                    title={t('title.ctrlRenewalDate', {ns: 'renewalsalerts'})}
                                                    isMonthPickerVisible={true} id="renewalDate"
                                                    placeholder={t('placeholder.ctrlRenewalDate', {ns: 'renewalsalerts'})}
                                                    ariaLabel={t('ariaLabel.ctrlRenewalDate', {ns: 'renewalsalerts'})}
                                                    label={t('label.ctrlRenewalDate', {ns: 'renewalsalerts'})}
                                                    formatDate={onFormatDate}
                                                    value={dateCreatedFrom!}
                                                    onSelectDate={onSelectDate}/>


                                                {!isNew &&
                                                    <StackItem styles={{root: {paddingTop: 16}}}>
                                                        <RHFCheckBoxControl
                                                            id={"active"}
                                                            control={formRenewals.control}
                                                            setValue={formRenewals.setValue}
                                                            getValues={formRenewals.getValues}
                                                            trigger={formRenewals.trigger}
                                                            checked={formRenewals.getValues("active")}
                                                            onCallback={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean | undefined) => {
                                                                setIsActive(checked ? checked : false)
                                                            }}
                                                            label={t('label.ctrlActive', {ns: 'renewalsalerts'})}
                                                            title={t('title.ctrlActive', {ns: 'renewalsalerts'})}
                                                            ariaLabel={t('ariaLabel.ctrlActive', {ns: 'renewalsalerts'})}
                                                            disabled={isReadOnly}
                                                        />
                                                    </StackItem>
                                                }


                                            </Stack>
                                        </>
                                    }
                                </form>
                            </FormProvider>

                        </Stack>


                        {/*-- START FOOTER --*/}
                        <Separator></Separator>
                        <Stack horizontal style={{overflow: "hidden"}} styles={nonShrinkingStackItemStyles}>
                            <StackItem grow={1}>&nbsp;</StackItem>
                            {isLoadingRecordContext &&
                                <>
                                    <StackItem>
                                        <Spinner size={SpinnerSize.medium} labelPosition="left"
                                                 label={t('text.spinner', {ns: 'common'})}/>
                                    </StackItem>
                                </>
                            }
                            {isSaving &&
                                <>
                                    <StackItem>
                                        <Spinner size={SpinnerSize.medium} labelPosition="left"
                                                 label={t('text.spinnerSaving', {ns: 'common'})}/>
                                    </StackItem>
                                </>
                            }

                            <StackItem>


                                {!isNew && !isReadOnly &&
                                    <>
                                        <PrimaryButton disabled={!isLoadedContext || isSaving}
                                                       style={{marginLeft: 8}}
                                                       onClick={update}
                                                       split
                                                       menuProps={menuProps}
                                                       text={t('Common.Button.Update', {ns: 'common'})}
                                                       title={t('Common.Button.Update', {ns: 'common'})}/>
                                    </>
                                }
                                {isNew &&
                                    <PrimaryButton disabled={!isLoadedContext || isSaving}
                                                   style={{marginLeft: 8}}
                                                   onClick={create}
                                                   text={t('Common.Button.Override', {ns: 'common'})}
                                                   title={t('Common.Button.Override', {ns: 'common'})}/>
                                }


                                <DefaultButton
                                    onClick={() => {
                                        onBeforeDismiss()
                                        onDismissModal(updated)
                                    }}
                                    style={{marginLeft: 8}}
                                    text={t('Common.Button.Close', {ns: 'common'})}
                                    title={t('Common.Button.Close', {ns: 'common'})}/>
                            </StackItem>
                        </Stack>
                        {/*-- START FOOTER --*/}

                    </Stack>
                    {/*-- END CONTENT --*/}

                </Stack>
                {/*-- END BODY --*/}

            </Modal>
            <CustomDialog title={t('Common.Button.Remove', {ns: 'common'})} titleId={`deleteRecordDefinition`}
                          actionText={t('Common.Button.Remove', {ns: 'common'})}
                          confirm={deleteRecordDefinition}
                          close={() => {
                              setShowDelete(false)
                          }}
                          isOpen={showDelete}
            >
                <Trans>
                    {t('text.confirmRemove', {ns: 'renewalsalerts'})}
                </Trans>
            </CustomDialog>


        </>
    )
}

export default RenewalsAndAlertsEdit
