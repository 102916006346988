import React, { useEffect } from "react";
import { Label, Stack } from "@fluentui/react";
import { RHFCheckBoxControl } from "../../../RHFControls/RHFChekBoxControl";
import { useFormContext } from "react-hook-form";
import { RHFChoiceGroupControl } from "../../../RHFControls/RHFChoiceGroupControl";
import { useBoolean } from "@fluentui/react-hooks";
import { DocumentType } from '../../Models/Enums';

export interface KimBaseMessageProps {
    isConfigurationTemplateReadMode?: boolean;
}

export const KimBaseMessage = (props: KimBaseMessageProps) => {
    const { control, setValue, getValues, trigger, unregister, register, resetField, formState: { errors } } = useFormContext();
    const [isDocumentTypeHidden, { setTrue: hideDocumentType, setFalse: showDocumentType }] = useBoolean(true);

    const onIncludeCaseDocumentChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined): void => {
        if (checked) {
            resetField("DocumentType")
            register("DocumentType", { required: "DocumentType is required." })
            showDocumentType()
        } else {
            resetField("DocumentType")
            unregister("DocumentType")
            hideDocumentType()
        }
    };

    useEffect(() => {
        //register("SendAs", { required: "SendAs is required." });
        //register("EncodeRequest", { required: "EncodeRequest is required." });
    }, [])

    useEffect(() => {
        if (getValues("IncludeCaseDocument") !== undefined) {
            getValues("IncludeCaseDocument") ? showDocumentType() : hideDocumentType()
        }
    }, [isDocumentTypeHidden])

    return (
        <Stack
            horizontal={true}
            wrap={true}
            disableShrink={true}
            horizontalAlign={"start"}
            verticalAlign={"start"}
            styles={{ root: { marginTop: 0, paddingTop: "2px" } }}
            tokens={{ childrenGap: "5 5", padding: "10px" }}
        >
            <Stack.Item grow>
                <Label>Include:</Label>
                <div style={{ paddingTop: "8px" }}>
                    <RHFCheckBoxControl
                        id={"IncludeXMLPayload"}
                        label={"XML Payload"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        disabled={props.isConfigurationTemplateReadMode}
                    />
                </div>
                <div style={{ paddingTop: "8px" }}>
                    <RHFCheckBoxControl
                        id={"IncludeAttachments"}
                        label={"Attachments"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        disabled={props.isConfigurationTemplateReadMode}
                    />
                </div>
                <div style={{ paddingTop: "8px" }}>
                    <RHFCheckBoxControl
                        id={"IncludeCaseDocument"}
                        label={"Case Document"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        onCallback={onIncludeCaseDocumentChange}
                        disabled={props.isConfigurationTemplateReadMode}
                    />
                </div>
                <div style={{ paddingTop: "15px" }}>
                    <RHFChoiceGroupControl
                        id={"DocumentType"}
                        options={Object.entries(DocumentType).map(item => {
                            return { text: item[0], key: item[1] }
                        })}
                        hidden={isDocumentTypeHidden}
                        label={"Output Document Options:"}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        required={true}
                        errors={errors}
                    />
                </div>
            </Stack.Item>
            <Stack.Item grow>
                {/*<RHFChoiceGroupControl
                    id={"SendAs"}
                    options={Object.entries(SendAsType).map(item => {
                        return { text: item[0], key: item[1] }
                    })}
                    label={"Send As:"}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    trigger={trigger}
                    required={true}
                    errors={errors}
                />
                <RHFChoiceGroupControl
                    id={"EncodeRequest"}
                    label={"Encode Request:"}
                    options={Object.entries(EncodeRequestType).map(item => {
                        return { text: item[0], key: item[1] }
                    })}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    trigger={trigger}
                    required={true}
                    errors={errors}
                />*/}
            </Stack.Item>
        </Stack>
    );
};