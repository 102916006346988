import React, {HTMLAttributes, useEffect, useState} from 'react';
import {Modal} from '@fluentui/react/lib/Modal';
import {Stack} from '@fluentui/react/lib/Stack';
import {IconButton, PrimaryButton, DefaultButton, IButtonStyles, ActionButton} from '@fluentui/react/lib/Button';
import {Separator} from '@fluentui/react/lib/Separator';
import {DialogFooter} from '@fluentui/react/lib/Dialog';
import {getTheme, mergeStyleSets, FontWeights} from '@fluentui/react/lib/Styling';
import {IIconProps} from '@fluentui/react/lib/Icon';
import {TextField} from '@fluentui/react/lib/TextField';
import {Label} from '@fluentui/react/lib/Label';
import '../../Custom.css'
import {IDropdownOption} from '@fluentui/react/lib/Dropdown';
import Helper, {IServiceEmailRequest} from '../../Helpers/Helper';
import {CheckboxVisibility, DetailsList, IColumn} from 'office-ui-fabric-react/lib/DetailsList';
import {Text} from '@fluentui/react/lib/Text';
import {
    DetailsListLayoutMode,
    IContextualMenuItem,
    IContextualMenuProps,
    IMessageBarStyles,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    SelectionMode,
    StackItem
} from '@fluentui/react';
import {Selection} from 'office-ui-fabric-react/lib/MarqueeSelection';
import {store} from 'react-notifications-component';
import {Spinner, SpinnerSize} from '@fluentui/react/lib/Spinner';
import {isMobile} from 'react-device-detect';
import {useTranslation} from "react-i18next";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import {getRecordDescription} from '../../Helpers/functions';
import {formatFromUTC, setGlobalDateLocale} from '../../Helpers/DateUtils';
import {useBranding} from "../../hooks/useBranding";
import DocumentShareEmail from "../DocumentShare";
import FormDocumentLink from "../DocumentLink/FormDocumentLink";
import ApplicationServiceEmailService, {
    IGetServiceEmailByTemplateIdAndOwnerRequest
} from "../../services/ApplicationServiceEmail";
import HttpRequest from "../../services/HttpRequest";


const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },

};

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
            class: 'ms-Icon ms-Icon--Mail'
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});


const cancelIcon: IIconProps = {iconName: 'Cancel'};
const iconListButtonStyles: Partial<IButtonStyles> = {root: {float: 'right', height: 'inherit'}};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};


export interface ITemplateAudience {
    templateId: string,
    Owners?: string[],
    CoOwner?: string[],
    groupName: string,
    audience: string
    documentCreator: string,
}

const TemplateAudience: ITemplateAudience[] = [];

export interface ITemplates {
    templateId: number,
    userName: string
    name: string,
    expandAriaLabel: string,
    collapseAriaLabel: string,
    links: ITemplatesCustom[]
}

export interface ITemplatesCustom {
    isCustom: boolean,
    key: string
    name: string,
    expandAriaLabel: string,
    url: string
}

export interface IUsers {
    index?: number
    templateId: number,
    name: string,
    role: string
}

export interface ISMTemplate {
    TemplateId: number,
    CoAuthor: string[],
    Users: string[],
    Layout: any,
    Pages: any,
    Owner: string
}

export class Users implements IUsers {
    templateId = -1
    name = "";
    role = "";
    index? = -1;
    FullName = "";
}

export class SMTemplate implements ISMTemplate {
    TemplateId = -1;
    CoAuthor = [""];
    Users = [""];
    Layout = null;
    Pages = null;
    Owner = ""
}

export interface IAllowedUser {
    Id: number
    AccountId: number
    TemplateId: number
    DocumentOnlyId: number
    UserName: string
    FullName: string
    DateCreated: string
    DateMasterCreated: string
}


var optionsuser: IUsers[] = []

var SMtemplatecustom = new SMTemplate()
var usersCoAuthor: string[] = []
var userspublic: string[] = []


let optionTemplateAudience: IDropdownOption[] = []


const stackTokens = {childrenGap: 50};

function ShareDocument(props: any) {
    const {branding} = useBranding()
    //const titleId = useId(props.modalId);
    const [requiredEmail, setrequiredEmail] = useState(false)
    const [TemplateId, setTemplateId] = useState("")
    const [DisableUpdateUser, setDisableUpdateUser] = useState(true)
    const [showSuccess, setShowSuccess] = useState(false)
    const [applying, setApplying] = useState(false);
    const templatesOptions: IDropdownOption[] = []
    const usertemplate: IUsers[] = []
    const emailList: IAllowedUser[] = []
    const emptyUser: IAllowedUser = {
        AccountId: 0,
        DocumentOnlyId: 0,
        Id: 0,
        TemplateId: 0,
        UserName: "",
        FullName: "",
        DateCreated: "",
        DateMasterCreated: ""
    };
    const {t} = useTranslation(['common', 'recordshare']);
    const [modalId, setModalId] = useState(-1)
    const [modalTitle, setModalTitle] = useState("")
    const [layout, setLayout] = useState("")
    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const [spinnerText, setSpinnerText] = useState("")
    const [listUserEmail, setlistUserEmail] = useState(emailList)
    const [userSelected, setuserSelected] = useState(emptyUser)
    const [role, setRole] = useState("")

    //const [templateId,settemplateId]= useState(-1)
    const [Useremail, setUseremail] = useState("")
    const [showDeleteModal, setshowDeleteModal] = useState(false)
    const [message, setMessage] = useState("");
    const [showMessage, setShowMessage] = useState(false)
    const [showMessageError, setshowMessageError] = useState(false)
    const [Successmessage, setSuccessmessage] = useState("");
    const [fromApplicationServiceEmail, setFromApplicationServiceEmail] = useState("")
    const [showDocumentShareEmail, setshowDocumentShareEmail] = useState(false);


    const panelHeader: HTMLAttributes<HTMLDivElement> = {
        style: {
            fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
            fontWeight: "bolder",
            color: branding.theme.palette.themePrimary,
            fontSize: 20
        }
    }

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '50vw',
            height: '35vh',
            flex: '4 4 auto',
            //overflowY: 'hidden',
            selectors: {
                p: {margin: '14px 0'},
                'p:first-child': {marginTop: 0},
                'p:last-child': {marginBottom: 0},
            },
        },
        buttonArea: {},
        bodyDelete: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: {margin: '14px 0'},
                'p:first-child': {marginTop: 0},
                'p:last-child': {marginBottom: 0},
            },
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: {margin: '14px 0'},
                'p:first-child': {marginTop: 0},
                'p:last-child': {marginBottom: 0},
            },
        },
        User: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: {margin: '14px 0'},
                'p:first-child': {marginTop: 0},
                'p:last-child': {marginBottom: 0},
            },
        },
    });


    const hideDocumentShareEmail = (reload:boolean = false, userAdded:string = "") => {
        
        setshowDocumentShareEmail(false);
        if(reload) {
            getAllowedUsers()
            if (props.documentShare) {
                Helper.SendNotification({
                    ActionByEmailAddress: props.username,
                    ActionDate: formatFromUTC(Date.now(), "ll", 'en'),
                    AudienceName: props.audienceName,
                    AudienceOwnerEmailAddress: props.audienceOwnerUserName,
                    RecordDescription: props.documentDescription.trim(),
                    DocumentId: props.documentId.toString(),
                    TargetOfActionEmailAddress: userAdded,
                    TemplateCategory: props.templateCategory,
                    TemplateName: props.templateName,
                    Type: "3",
                    UserName: props.username,
                    SK: props.sessionKey,
                    AccountId: props.accountId,
                    Path: props.siteSelected,
                    Autoprovision: true,
                    ApiKey: "-1",
                    EmailMoniker: props.emailMoniker,
                    TemplateId: props.TemplateId.toString()
                })
            }
            
        }

    };
    
    function showNotification(Nmessage: string, Ntype: string) {

        switch (Ntype) {
            case "success":
                store.addNotification({
                    title: t('title.success', {ns: 'common'}),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;
            case "warning":
                store.addNotification({
                    title: t('title.warning', {ns: 'common'}),
                    message: Nmessage,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;
            case "error":
                store.addNotification({
                    title: t('title.error', {ns: 'common'}),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;
            case "info":
                store.addNotification({
                    title: t('title.information', {ns: 'common'}),
                    message: Nmessage,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                break;

            default:
                break;
        }


    }

    useEffect(() => {
        setGlobalDateLocale(navigator.language)

        if (props.showShare == true) {
            getAllowedUsers();
            if (props.TemplateId !== undefined && props.TemplateId !== "") {
                if (optionTemplateAudience.length == 0)
                    getTemplateAudience(props.TemplateId)
            }
            getApplicationServiceEmail()
        }

    }, [props.showShare])

    const getApplicationServiceEmail = async () => {

        const request: IGetServiceEmailByTemplateIdAndOwnerRequest = {
            TemplateId: '-1',
            Owner: Helper.getUsername()
        }

        await ApplicationServiceEmailService.GetServiceEmailByTemplateIdAndOwner(request)
            .then((data) => {
                if (data.length > 0) {
                    setFromApplicationServiceEmail(data[0].Owner)
                }
            })
            .catch()
            .finally()
       
    }

    const getRecordDateTitle = (item: IAllowedUser) => {

        var result;

        item.DateCreated !== null ? result = t('text.created', {ns: 'recordshare'}) + " - " + Helper.getLocateDate(item.DateCreated, 2) : result = t('text.notAvailable', {ns: 'recordshare'});

        return result;
    }

    async function getTemplateAudience(templateId: string) {
        setSpinnerText(t('text.spinner', {ns: 'common'}))
        const response = TemplateAudience

        try {
            TemplateAudience.map(a => (optionTemplateAudience.push({key: a.audience, text: a.audience})));
        } catch (error) {
            console.log(error)
            showNotification(t('message.error', {ns: 'common', Error: error}), "error")
        }
        setSpinnerText("")
    }


    const getErrorMessage = (value: string): string => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length > 0) {
            if (!value.match(mailformat)) {
                setrequiredEmail(false)
                return t('text.emailValid', {ns: 'common'});
            }

            setrequiredEmail(true)
        }

        return ""
    };
    const closeModal = () => {
        setrequiredEmail(false)
        props.hideModal()
        setTemplateId("")
        optionsuser = []
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: t('label.username', {ns: 'recordshare'}),
            fieldName: 'UserName',
            minWidth: 300,
            maxWidth: 400,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.sortedAsc', {ns: 'recordshare'}),
            sortDescendingAriaLabel: t('label.sortedDesc', {ns: 'recordshare'}),
            data: 'string',
            isPadded: true,
            onRender: (item: IAllowedUser) => {
                return <span
                    title={item.FullName.trim().length === 0 ? item.UserName : item.UserName + " (" + item.FullName + ")"}>{item.FullName.trim().length === 0 ? item.UserName : item.UserName + " (" + item.FullName + ")"}</span>;
            },
        },
        {
            key: 'column7',
            name: t('label.created', {ns: 'recordshare'}),
            fieldName: 'DateCreated',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            data: 'number',
            onRender: (item: IAllowedUser) => {
                return <span
                    title={getRecordDateTitle(item)}>{item.DateCreated !== null ? Helper.getLocateDate(item.DateCreated, 1) : ""}</span>;
            },
            isPadded: true,

        },
        {
            key: 'column2',
            name: t('label.action', {ns: 'recordshare'}),
            fieldName: '',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            isPadded: true,
            onRender: (item: IUsers) => {
                return <div style={{width: '40px', display: 'block', textAlign: 'right'}}><IconButton
                    menuIconProps={{iconName: 'MoreVertical', style: {fontSize: 20, color: '#242424'}}}
                    role="button"
                    aria-haspopup
                    aria-label={t('label.showAction', {ns: 'recordshare'})}
                    styles={iconListButtonStyles}
                    menuProps={menuProps}
                    title={t('label.showAction', {ns: 'recordshare'})}
                /></div>
            },
        }
    ]

    const getKey = (item: string, index?: number): string => {
        if (item !== undefined)
            return item.toString()

        return ""
    }
    const onSelectionChange = () => {
        const selectionCount = selectionUser.getSelectedCount();
        switch (selectionCount) {
            case 0:
                setuserSelected(emptyUser)
                break;
            case 1:
                setuserSelected(selectionUser.getSelection()[0] as IAllowedUser)
                break;
            default:
                setuserSelected(emptyUser)
                break;
        }
    }
    const selectionUser = new Selection({
        onSelectionChanged: onSelectionChange,
    });
    const menuItems: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: t('label.delete', {ns: 'recordshare'}),
            title: t('title.delete', {ns: 'recordshare'}),
            onClick: () => {
                DeleteUser(userSelected)
            },
        },
    ];
    const _deselect = () => {

        //selectionUserAudience.setAllSelected(false);
        //setuserSelected(emptyUser);
    }
    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    async function getAllowedUsers() {
        setApplying(true)
        setSpinnerText(t('text.spinner', {ns: 'common'}))

        const request = {
            DocumentId: props.documentId
        }

        let {data}: any = await HttpRequest.getWithSession('AudienceIntersect', request)
        const response = data

        if (response !== undefined) {
            try {
                setlistUserEmail(response)
            } catch (error) {
                console.log(error)
                showNotification(t('message.error', {ns: 'common', Error: error}), "error")
            }
        }

        setSpinnerText("")
        setApplying(false)
    }

    async function DeleteAllowedUsers(user: string) {

        setSpinnerText(t('text.spinner', {ns: 'common'}))

        const request = {
            DocumentId: props.documentId,
            TargetUserName: user
        }

        let {data}: any = await HttpRequest.getWithSession('DeleteAllowedUser', request)
        const response = data
      
        if (response !== undefined) {
            if (response.Success) {
                var aux: IAllowedUser[] = []
                aux = listUserEmail
                var indx = aux.findIndex(x => x.UserName == user)
                aux.splice(indx, 1);
                setlistUserEmail(aux)
            } else {

                setshowMessageError(true)
                setMessage(t('message.error', {ns: 'common', Error: response.ExceptionMessage}))
                setTimeout(function () {
                    setshowMessageError(false)
                }, 5000);
            }
        }

        setSpinnerText("")


    }

    async function getStateManagement(templateId: number) {

        const sessionKey = Helper.getSessionKey()
        const accountId = Helper.getDefaultAccount()
        const userName = Helper.getUsername()
        const site = await Helper.getSite()!
        optionsuser = []

        setSpinnerText("Loading content...")


        const request = {
            TemplateId: templateId.toString()
        }

        let {data}: any = await HttpRequest.getWithSession('statemanagement', request)
        const response = data
                
        if (response.TemplateId !== undefined) {
            try {
                SMtemplatecustom.Layout = response.Layout
                SMtemplatecustom.Owner = response.Owner
                SMtemplatecustom.Pages = response.Pages
                SMtemplatecustom.TemplateId = response.TemplateId
                if (response.CoAuthor !== null && response.CoAuthor.length > 0) {
                    response.CoAuthor.map(
                        (i: any, index: number) => InitCoAuthor(i, Number(response.TemplateId), index)
                    )
                } else {
                    SMtemplatecustom.CoAuthor = [""]
                }
                if (response.Users !== null && response.Users.length > 0) {
                    response.Users.map((i: any, index: number) => InitUser(i, Number(response.TemplateId), index))
                } else {
                    SMtemplatecustom.Users = [""]
                }


            } catch (error) {
                console.log(error)
                showNotification("An error occurred: " + error, "error")
            }
        }
        setSpinnerText("")
    }

    const InitCoAuthor = (user: string, templateId: number, index: any) => {

        optionsuser.push({
            templateId: templateId!,
            name: user,
            role: "CoAuthor",
            index: Number(index)
        });
        usersCoAuthor.push(user)
    }

    const InitUser = (user: string, templateId: number, index: any) => {
        optionsuser.push({
            templateId: templateId!,
            name: user,
            role: "Users",
            index: Number(index)
        });
        userspublic.push(user)
    }


    const hideModal = () => {
        setLayoutLoaded(false)
        setSpinnerText(t('text.spinner', {ns: 'common'}))
        setLayout("")
        setModalTitle("")
        setshowDeleteModal(false)
        setModalId(-1)
        optionsuser = []
        usersCoAuthor = []
        userspublic = []
        setUseremail("")
        setrequiredEmail(false)
        setShowSuccess(false)
        setSuccessmessage("")
        //setTemplateId("")


    }

    const addUser = () => {
        // setLayout("")
        // setLayoutLoaded(false)
        // setSpinnerText(t('text.spinner', {ns: 'common'}))
        // setModalTitle(t('title.modalNewUser', {ns: 'recordshare'}))
        // setShowModal(true)
        // setModalId(1)
        setshowDocumentShareEmail(true)
    }

    const sendUser = () => {

        DeleteAllowedUsers(userSelected.UserName)
        hideModal()
    }


    const DeleteUser = (item: IAllowedUser) => {
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText(t('text.spinner', {ns: 'common'}))
        setshowDeleteModal(true)
        setLayout("")
        setLayoutLoaded(false)
        setSpinnerText(t('text.spinner', {ns: 'common'}))
        setModalTitle(t('title.modalDelUser', {ns: 'recordshare'}))
        setModalId(2)
    }
    const hideDeleteModal = () => {
        setLayoutLoaded(false)
        setSpinnerText(t('text.spinner', {ns: 'common'}))
        setLayout("")
        setModalTitle("")
        setshowDeleteModal(false)
        setTemplateId("")
        setModalId(-1)
    }

    return (
        <>
            {!isMobile ?
                <Modal
                    isOpen={props.showModal}
                    isBlocking={true}>
                    <div className="ms-Grid" dir="ltr">
                        <Stack horizontal className={contentStyles.header}>
                            <StackItem grow={1}>
                                <Text variant={"xLarge"}>
                                    {getRecordDescription(props.modalTitle, props.documentDescription)}
                                </Text>

                            </StackItem>
                            <Stack horizontal>

                                <HelpComponentRelative
                                    headline={t('title.help', {ns: 'recordshare'})}
                                    text={t('text.help', {ns: 'recordshare'})}
                                    right="50px"
                                    top="15px"
                                    location="records"
                                />
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                                    onClick={closeModal}
                                    title={t('Common.Button.Close', {ns: 'common'})}
                                />
                            </Stack>
                        </Stack>

                        <Separator/>
                        <div className="ms-Grid-row">
                            <div className={contentStyles.buttonArea + " ms-Grid-col ms-sm12 ms-md12 ms-lg12"}
                                 style={{width: 200}}>
                                {showMessageError ?
                                    <MessageBar messageBarType={MessageBarType.error}>{message} </MessageBar> : null}
                                <ActionButton title={t('title.adduser', {ns: 'recordshare'})}
                                              text={t('text.adduser', {ns: 'recordshare'})} onClick={addUser}
                                              iconProps={{iconName: 'Add'}}/>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.body}>
                                    {!applying ?
                                        <div className="scrollVisible" style={{
                                            overflow: "auto",
                                            overflowX: "hidden",
                                            maxWidth: "100%",
                                            height: "35vh"
                                        }}>
                                            <DetailsList
                                                items={listUserEmail || []}
                                                compact={false}
                                                columns={columns}
                                                getKey={getKey}
                                                setKey="set"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                isHeaderVisible={true}
                                                selection={selectionUser}
                                                selectionMode={SelectionMode.single}
                                                selectionPreservedOnEmptyClick={false}
                                                enterModalSelectionOnTouch={true}
                                                checkboxVisibility={CheckboxVisibility.hidden}
                                            />
                                        </div>
                                        :
                                        <Stack>
                                            <Label/>
                                            <Label/>
                                            <Label/>
                                            <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})}
                                                     ariaLive="assertive"/>
                                        </Stack>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{bottom: "5px"}}>
                                <DialogFooter>
                                    <DefaultButton onClick={closeModal} text={t('Common.Button.Close', {ns: 'common'})}
                                                   title={t('Common.Button.Close', {ns: 'common'})}/>
                                </DialogFooter>
                            </div>
                        </div>
                    </div>
                </Modal>
                :
                <Panel
                    headerText={getRecordDescription(props.modalTitle, props.documentDescription)}
                    headerTextProps={panelHeader}
                    isBlocking={false}
                    isOpen={props.showModal}
                    onDismiss={closeModal}
                    closeButtonAriaLabel={t('Common.Button.Close', {ns: 'common'})}
                    type={PanelType.smallFluid}
                >
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{padding: "0px", position: "absolute", top: "-2px", right: "5px"}}
                                 className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <HelpComponentRelative
                                    headline={t('title.help', {ns: 'recordshare'})}
                                    text={t('text.help', {ns: 'recordshare'})}
                                    right="50px"
                                    top="15px"
                                    location="records"
                                />
                            </div>
                        </div>
                        <Separator/>
                        <div className="ms-Grid-row">
                            <div className={contentStyles.buttonArea + " ms-Grid-col ms-sm12 ms-md12 ms-lg12"}
                                 style={{width: 200}}>
                                {showMessageError ?
                                    <MessageBar messageBarType={MessageBarType.error}>{message} </MessageBar> : null}
                                <ActionButton title={t('title.adduser', {ns: 'recordshare'})}
                                              text={t('text.adduser', {ns: 'recordshare'})} onClick={addUser}
                                              iconProps={{iconName: 'Add'}}/>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.body}>
                                    {!applying ?
                                        <div className="scrollVisible" style={{
                                            overflow: "auto",
                                            overflowX: "hidden",
                                            maxWidth: "100%",
                                            height: "35vh"
                                        }}>
                                            <DetailsList
                                                items={listUserEmail || []}
                                                compact={false}
                                                columns={columns}
                                                getKey={getKey}
                                                setKey="set"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                isHeaderVisible={true}
                                                selection={selectionUser}
                                                selectionMode={SelectionMode.single}
                                                selectionPreservedOnEmptyClick={false}
                                                enterModalSelectionOnTouch={true}
                                                checkboxVisibility={CheckboxVisibility.hidden}
                                            />
                                        </div>
                                        :
                                        <Stack>
                                            <Label/>
                                            <Label/>
                                            <Label/>
                                            <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})}
                                                     ariaLive="assertive"/>
                                        </Stack>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
            }

            {/*todo update subform */}
            
            <DocumentShareEmail
                showModal={showDocumentShareEmail}
                modalTitle={getRecordDescription(props.modalTitle, props.documentDescription)}
                documentId={props.documentId.toString()}
                onDismissModal={hideDocumentShareEmail}
                templateId={props.TemplateId.toString()}
                ownerUser={props.ownerUser}
                documentDescription={props.documentDescription}
                documentShare={props.documentShare}
            />

            <Modal
                isOpen={showDeleteModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{padding: "0px"}} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={modalTitle}>{modalTitle}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                                        onClick={hideDeleteModal}
                                        title={t('Common.Button.Close', {ns: 'common'})}
                                    />
                                </div>
                                <Separator/>
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.bodyDelete}>
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="ms-Grid-row">
                                                {t('text.removeConfirm', {
                                                    ns: 'recordshare',
                                                    User: userSelected.FullName.trim().length > 0 ? userSelected.UserName + " (" + userSelected.FullName + ")" : userSelected.UserName
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{bottom: "10px", right: "12px", position: "absolute"}}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={sendUser} text={t('Common.Button.Delete', {ns: 'common'})}
                                               title={t('title.delete', {ns: 'recordshare'})}/>
                                <DefaultButton onClick={hideDeleteModal}
                                               text={t('Common.Button.Cancel', {ns: 'common'})}
                                               title={t('Common.Button.Cancel', {ns: 'common'})}/>
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ShareDocument


