import React, { useEffect, useState } from 'react';
import { mergeStyleSets, getTheme, FontWeights } from '@fluentui/react/lib/Styling';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { IFormControl } from '../../Helpers/Helper';
import { IconButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import '../../Custom.css';
import { IIconProps, Modal, Separator } from '@fluentui/react';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { TextField } from '@fluentui/react/lib/TextField';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { isMobile } from 'react-device-detect';
import '../../Custom.css';
import { useTranslation } from 'react-i18next';
import { formatString, getRecordDescription } from '../../Helpers/functions';
import DataPanelService from '../../services/dataPanel';
import {useBranding} from "../../hooks/useBranding";


const theme = getTheme();
const iconButtonNoteModalStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const stackTokens: Partial<IStackTokens> = { childrenGap: 0 };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
type CopyProps = {
    showModalClone: boolean
    hideModalClone: () => void
    onCreateUpdate: (id: string) => void
    templateId: string
    documentId: string
    templateVersion: string
    description: string
}
const Copy = ({ templateId, documentId, templateVersion, description, showModalClone, hideModalClone, onCreateUpdate }: CopyProps) => {
    const { branding } = useBranding()
    const { t } = useTranslation(['common', 'workspace', 'coachmarks', 'recordnotes', 'recordactivity', 'recordcopy', 'recorddelete', 'preview']);

    const [requiredShortcut, setRequiredShortcut] = useState(true)
    const [cloneShortcut, setCloneShortcut] = useState("")
    const [clonning, setClonning] = useState(false)

    const contentStyles = mergeStyleSets({
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    useEffect(() => {
        setCloneShortcut('')
        return ()=>{
            setCloneShortcut('')
        }
    }, [])
    async function cloneDocument() {

        setRequiredShortcut(false)
        setClonning(true)

        var formData: IFormControl[] = [];

        formData.push({
            Key: "_shortcutReference",
            Value: cloneShortcut.trim()
        })

        const { data }: any = await DataPanelService.cloneDocument({
            TemplateId: templateId.toString(),
            TargetCulture: "en-US",
            Content: formData,
            DocumentId: documentId.toString(),
            TemplateVersion: templateVersion,
        })
        if (data.Success) {

            onCreateUpdate(data.Id)
            setClonning(false)
            handleClose()
        }
    }
    const getCloneErrorMessage = (value: string): string => {
        if(requiredShortcut){
            return t('text.required', { ns: 'recordcopy' });
        }
        return ""
    };
    const handleClose = ()=>{
        setCloneShortcut('')
        setRequiredShortcut(true)
        hideModalClone()
    }
    const handleInput = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const value = formatString(newValue ?? '')
        setCloneShortcut(value)
        setRequiredShortcut(value.trim().length === 0)
    }
    return (
        <Modal
            isOpen={showModalClone}
            isBlocking={true}>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.header}>
                            <span id={"confirmClone"}>{getRecordDescription(t('text.copy', { ns: 'recordcopy' }), description)}</span>
                            <IconButton
                                styles={iconButtonNoteModalStyles}
                                iconProps={cancelIcon}
                                ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                onClick={handleClose}
                                title={t('Common.Button.Close', { ns: 'common' })}
                            />
                        </div>
                    </div>
                </div>
                <Separator />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.body}>
                            {!clonning ?
                                <div className="ms-Grid-col ms-sm12 ms-lg12">
                                    <TextField
                                        label={t('label.descriptionInput', { ns: 'recordcopy' })}
                                        autoFocus
                                        required
                                        value={cloneShortcut}
                                        maxLength={40}
                                        onGetErrorMessage={getCloneErrorMessage}
                                        title={t('title.descriptionInput', { ns: 'recordcopy' })}
                                        onChange={handleInput} id="descriptionClone" placeholder={t('placeholder.descriptionInput', { ns: 'recordcopy' })} />
                                </div>
                                :
                                <Stack tokens={stackTokens}>
                                    <label />
                                    <label />
                                    <label />
                                    <Spinner size={SpinnerSize.large} label={t('text.spinnerCopying', { ns: 'common' })} />
                                </Stack>
                            }
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div style={{ paddingBottom: "10px", paddingRight: "12px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <DialogFooter>
                            <PrimaryButton id='confirmButton' onClick={cloneDocument} text={t('Common.Button.Confirm', { ns: 'common' })} disabled={requiredShortcut} title={t('title.confirmButton', { ns: 'recordcopy' })} />
                            <DefaultButton onClick={handleClose} text={t('Common.Button.Cancel', { ns: 'common' })} title={t('Common.Button.Cancel', { ns: 'common' })} />
                        </DialogFooter>
                    </div>
                </div>

            </div>
        </Modal>
    )
}
export default Copy