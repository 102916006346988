import { IStackTokens, Label, mergeStyles, PrimaryButton, Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import Helper from "../../../../Helpers/Helper";
import { RHFCheckBoxControl } from "../../../RHFControls/RHFChekBoxControl";
import { RHFTextBoxControl } from "../../../RHFControls/RHFTextBoxControl";
import { OAuth20Type } from "../../Models/Enums";
import { TestOAuth20Request } from "../../Models/OutboundIntegrationModel";
import OutboundServices from "../../Services/OutboundServices";
import TestIntegrationModal from "../../TestIntegrationModal";

export interface SecurityProps {
    templateId: number;
    showSuccessMessage: (message: string) => void;
    showErrorMessage: (message: string) => void;
    isConfigurationTemplateReadMode?: boolean;
}

const verticalStyle = mergeStyles({
    height: "200px",
});

const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};

const ServerToServerFlowCC = (props: SecurityProps) => {
    const { control, setValue, getValues, trigger } = useFormContext();
    const [isIntegrationTestModalOpen, { setTrue: showIntegrationTestModal, setFalse: hideIntegrationTestModal }] = useBoolean(false);
    const [modalContent, setModalContent] = useState<any>(null);

    const getDefaultValue = (id: any): any => {
        let currentValue: any = getValues(id);
        let tempCurrentValue: any =
            currentValue !== undefined ? currentValue : "";
        setValue(id, tempCurrentValue);
        return tempCurrentValue;
    };

    const testStoSCCFlow = async () => {
        OutboundServices.TestOAuth20(await getTestOAuth20Request()).then((res) => {
            showIntegrationTestModal();
            setModalContent(res);
        }
        );
    };

    const getTestOAuth20Request = async (): Promise<TestOAuth20Request> => {
        return {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            TemplateId: props.templateId,
            OAuth20Prop: {
                AccessToken: "",
                RefreshToken: "",
                AuthUrl: "",
                ResponseType: "",
                TokenUrl: getValues("TokenUrl"),
                GrantType: getValues("GrantType"),
                ClientId: getValues("ClientId"),
                ClientSecret: getValues("ClientSecret"),
                RedirectURI: "",
                Scope: getValues("Scope"),
                Username: getValues("SSFCCUsername"),
                Password: getValues("SSFCCPassword"),
                code: "",
                client_assertion_type: getValues("ClientAssertionType"),
                Assertion: "",
                AssertionCertPassword: "",
                company_id: "",
                code_verifier: "",
                assertion_keyname: "",
                AssertionFileName: "",
                new_token: "",
                oAuth20Type: OAuth20Type.ServerToServerFlowCC,
                State: "",
                ScopeRequired: getValues("ScopeRequired"),
                ServiceProvider: "",
                AuthorizationHeader: getValues("AuthorizationHeader"),
            },
        };
    };

    return (
        <>
            <Stack horizontal tokens={stackTokens}>
                <Stack.Item className={verticalStyle}>
                    <RHFTextBoxControl
                        id={"TokenUrl"}
                        label={"Token URL:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Token URL"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("TokenUrl")}
                    />
                    <div style={{ paddingTop: "31px", paddingBottom: "10px" }}>
                        <RHFCheckBoxControl
                            id={"ScopeRequired"}
                            label={"Scope Required:"}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            trigger={trigger}
                        />
                    </div>
                    <RHFTextBoxControl
                        id={"ClientId"}
                        label={"Client Id:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Client Id"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("ClientId")}
                    />
                    <RHFTextBoxControl
                        id={"SSFCCUsername"}
                        label={"User:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter User"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("Username")}
                    />
                    <RHFTextBoxControl
                        id={"AuthorizationHeader"}
                        label={"Authorization Header:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Authorization Header"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("AuthorizationHeader")}
                    />
                </Stack.Item>
                <Stack.Item className={verticalStyle}>
                    <RHFTextBoxControl
                        id={"GrantType"}
                        label={"Grant Type:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Grant Type"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("GrantType")}
                    />
                    <RHFTextBoxControl
                        id={"Scope"}
                        label={"Scope:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Scope"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("Scope")}
                    />
                    <RHFTextBoxControl
                        id={"ClientSecret"}
                        label={"Client Secret:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Client Secret"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("ClientSecret")}
                    />
                    <RHFTextBoxControl
                        id={"SSFCCPassword"}
                        label={"Password:"}
                        disabled={props.isConfigurationTemplateReadMode ? true : false}
                        title={"Enter Password"}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        defaultValue={getDefaultValue("Password")}
                    />
                </Stack.Item>
                <Stack.Item className={verticalStyle}>
                    <Label />
                    <Label />
                    <Label />
                    <PrimaryButton
                        disabled={props.isConfigurationTemplateReadMode}
                        text={"Test S to S CC Flow"}
                        onClick={testStoSCCFlow}
                    />
                </Stack.Item>
            </Stack>
            <TestIntegrationModal
                showModal={isIntegrationTestModalOpen}
                hideModal={hideIntegrationTestModal}
                content={modalContent}
                setModalContent={setModalContent}
            />
        </>
    );
};

export default ServerToServerFlowCC;
