import Helper from "../../../Helpers/Helper";
import { ConfigurationTemplateListRequest, ConfigurationTemplateModel, ConfigurationTemplateRequest, CreateUpdateConfigurationTemplate } from "../Models/ConfigurationTemplateModel";
import {
    CreateEventRequest, DeleteEventRequest, EventModel, EventRequest,
    ManualEventRequest, OnClickEventRequest, UpdateEventRequest
} from "../Models/EventModel";
import {
    DeleteOutboundIntegrationRequest, ApproveOutboundIntegrationRequest, CompileExecuteOutboundIntegrationScriptRequest,
    OutboundIntegrationRequest, OutboundIntegrationModel, ExecuteIntegrationRequest, ExecuteEventRequest,
    CreateOutboundIntegration, UpdateOutboundIntegration, TestOAuth20Request, OAuthTokenRequest
} from "../Models/OutboundIntegrationModel";
import HttpRequest from "../../../services/HttpRequest";

const OutboundServices = {
    GetIntegrationsByTemplateId: async (request: OutboundIntegrationRequest): Promise<OutboundIntegrationModel[]> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/GetIntegrationsByTemplateId', request)
        return data
    },
    CreateIntegration: async (request: CreateOutboundIntegration): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('OutboundIntegration/Integration', request)
        return data
    },
    UpdateIntegration: async (request: UpdateOutboundIntegration): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('OutboundIntegration/Integration/Update', request)
        return data
    },
    DeleteIntegration: async (request: DeleteOutboundIntegrationRequest): Promise<any> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/Integration/Delete', request)
        return data
    },
    ExecuteIntegration: async (request: ExecuteIntegrationRequest): Promise<any> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/Integration/Execute', request)
        return data
    },
    ExecuteEvent: async (request: ExecuteEventRequest): Promise<any> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/Event/Execute', request)
        return data
    },
    ScriptCompile: async (request: CompileExecuteOutboundIntegrationScriptRequest): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('OutboundIntegration/Script/Compile', request)
        return data
    },
    ScriptExecute: async (request: CompileExecuteOutboundIntegrationScriptRequest): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('OutboundIntegration/Script/Execute', request)
        return data
    },
    ApprovedStatus: async (request: ApproveOutboundIntegrationRequest): Promise<any> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/Integration/Approve', request)
        return data
    },
    TestOAuth20: async (request: TestOAuth20Request): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('OutboundIntegration/Integration/TestOAuth20', request)
        return data
    },
    GetOAuth20Token: async (request: OAuthTokenRequest): Promise<any> => {
        var url = `OutboundIntegration/OAuth/Token`;
        const raw = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("authToken")!,
            },
            body: JSON.stringify(request),
        });
        const result = await raw.json();
        return result;
    },
    GetEvents: async (request: EventRequest): Promise<EventModel[]> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/GetEventsByTemplateId', request)
        return data
    },
    CreateEvent: async (request: CreateEventRequest): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('OutboundIntegration/Event', request)
        return data
    },
    UpdateEvent: async (request: UpdateEventRequest): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('OutboundIntegration/Event/Update', request)
        return data
    },
    DeleteEvent: async (request: DeleteEventRequest): Promise<any> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/Event/Delete', request)
        return data
    },
    GetManualEvents: async (request: ManualEventRequest): Promise<any> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/Event/Manual', request)
        return data
    },
    GetOnClickEvents: async (request: OnClickEventRequest): Promise<any> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/Event/OnClick', request)
        return data
    },
    GetOnLoadEvents: async (request: OnClickEventRequest): Promise<any> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/Event/OnLoad', request)
        return data
    },
    CreateConfigurationTemplate: async (request: CreateUpdateConfigurationTemplate): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('OutboundIntegration/ConfigurationTemplate', request)
        return data
    },
    UpdateConfigurationTemplate: async (request: CreateUpdateConfigurationTemplate): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('OutboundIntegration/ConfigurationTemplate/Update', request)
        return data
    },
    DeleteConfigurationTemplate: async (request: CreateUpdateConfigurationTemplate): Promise<any> => {
        let { data }: any = await HttpRequest.postWithSession('OutboundIntegration/ConfigurationTemplate/Delete', request)
        return data
    },
    GetConfigurationTemplateById: async (request: ConfigurationTemplateRequest): Promise<any[]> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/ConfigurationTemplate', request)
        return data
    },
    GetConfigurationTemplateList: async (request: ConfigurationTemplateListRequest): Promise<ConfigurationTemplateModel[]> => {
        let { data }: any = await HttpRequest.getWithSession('OutboundIntegration/ConfigurationTemplateAll', request)
        return data
    }
};

export default OutboundServices;