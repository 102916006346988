import React, { useState, useEffect, useRef } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import Helper, { IDocumentOnlyAudienceDetail, IDocumentOnlyAudienceRequest, IDocumentOnlyCustomUserSignature } from '../../Helpers/Helper';
import { DetailsListLayoutMode, IContextualMenuItem, IContextualMenuProps, SelectionMode, MessageBar, Checkbox, Dropdown, IDropdownOption, IMessageBarStyles, ITooltipHostStyles, Image, ImageFit, Spinner, SpinnerSize, ScrollablePane, IScrollablePaneStyles } from '@fluentui/react';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles, ActionButton } from '@fluentui/react/lib/Button';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField } from '@fluentui/react/lib/TextField';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Label } from '@fluentui/react/lib/Label';
import Audiences from './Audiences';
import { useConstCallback, useBoolean } from '@uifabric/react-hooks';
import SignatureCanvas from 'react-signature-canvas'
import _ from 'lodash';
import ReactDOM from 'react-dom';
import {useBranding} from "../../hooks/useBranding";
import DocumentOnlyCustomUserSignatureService, {
    GetDocumentOnlyCustomUserSignatureByIdRequest,
    ICreateDocumentOnlyCustomUserSignatureRequest,
    IDeleteDocumentOnlyCustomUserSignatureRequest,
    IGetDocumentOnlyCustomUserSignatureByAudienceMasterRequest,
    IGetListSignatureByAudieceMasterIdRequest,
    IGetListSignatureByAudieceMasterIdResponse,
    IUpdateDocumentOnlyCustomUserSignatureRequest
} from "../../services/DocumentOnlyCustomUserSignature";

const stackTokens = { childrenGap: 10 };
const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const stylescallout = mergeStyleSets({
    button: {
        width: 130,
    },
    callout: {
        width: 320,
        padding: '20px 24px',
    },
});

const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
const signature = mergeStyleSets({
    container: {
        width: '100%',
        height: '100%',
        top: '10%',
        left: '10%'
    },
    sigContainer: {
        width: '80%',
        height: '80%',
        margin: '0 auto',
        backgroundColor: '#fff'
    },

    sigPad: {
        width: ' 100%',
        height: '100%'
    },
    buttons: {
        width: '100%',
        height: '30px'
    },
    sigImage: {
        backgroundsize: '200px 50px',
        width: '200px',
        height: '50px',
        backgroundcolor: 'white'
    }
});

const styles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
const calloutProps = { gapSpace: 0 };
var fileimage: string | Blob

function Signatures(props: any) {
    const { branding } = useBranding()
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [requiredName, setrequiredName] = useState(false)
    const [requiredDesc, setrequiredDesc] = useState(false)
    const [requiredSignature, setrequiredSignature] = useState(true)
    const [requiredAudience, setRequiredAudience] = useState(true)
    const [signatureName, setsignatureName] = useState("")
    const [signatureDesc, setsignatureDesc] = useState("")
    const [message, setMessage] = useState("");
    const [modalId, setModalId] = useState(-1)
    const [audiences, setAudiences] = useState<IDocumentOnlyAudienceDetail[]>([]);
    const [modalTitle, setModalTitle] = useState("")
    const [signatures, setSignatures] = useState<IGetListSignatureByAudieceMasterIdResponse[]>([]);
    const [Selectedsignature, setSelectedsignature] = useState<IGetListSignatureByAudieceMasterIdResponse>();
    const [uploadingFile, setUploadingFile] = useState(false);
    const [url, setUrl] = useState("");
    const [selectedAudience, setSelectedAudience] = useState<IDropdownOption>();
    const [addSignatureDisable, setAddSignatureDisable] = useState(true);
    const [audienceOptions, setAudienceOptions] = useState<IDropdownOption[]>([]);
    const [isChanging, setIsChanging] = useState(false)
    const [modalAudienceOpen, setModalAudienceOpen] = useState(false)
    const [TypeSignature, setTypeSignature] = useState("");
    const [_isCheckDigital, setIsisCheckDigital] = useState(false)
    const [_isCheckImage, setIsisCheckImage] = useState(false)
    const [trimmedDataURL, setTrimmedDataURL] = useState("")
    const [mimeType, setMimeType] = useState("");
    const [extType, setextType] = useState("");
    const [_isCurrentImage, setisCurrentImage] = useState(false)
    const [CurrentImageurl, setCurrentImageUrl] = useState("");
    const signatureRef = useRef<any>()
    const [showMessage, setShowMessage] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [SignatureId, setSignatureId] = useState("0")
    const [base64File, setBase64File] = useState<string>("");
    const [isUpdate, setIsUpdate] = useState(false)
    const [applying, setApplying] = useState(false);

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '65vw',
            height: '60vh',
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },

        User: {
            width: '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 500,

        },
        containerMail: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '50vw'
        },
    });

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
                class: 'ms-Icon ms-Icon--Mail'
            }
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },

        ],
        body: {
            width: '43vw',
            height: '55vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: '28vw',
            height: '16vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        User: {
            width: '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        pane: {
            maxWidth: "100%",
            borderr: '0 10px  0 0 solid ' + theme.palette.neutralLight,
        },
        contentSignatures: {
            height: '30vh',
            position: 'relative',
            backgroundColor: 'white',
        },
    });
    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };

    const emptySignature: IGetListSignatureByAudieceMasterIdResponse = {
        Id: "0",
        AccountId: "",
        DocumentOnlyAudienceMasterId: "0",
        Description: "",
        FileExt: "",
        MimeType: "",
        Name: "",
        SignatureImage: ""
    };

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Name',
            fieldName: 'Name',
            minWidth: 300,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IGetListSignatureByAudieceMasterIdResponse) => {
                return <span title={item.Name + " (" + item.Description + ")"}>{item.Name + " (" + item.Description + ")"}</span>;
            }
        },
        {
            key: 'column3',
            name: 'Signature',
            fieldName: 'Signature',
            minWidth: 110,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IGetListSignatureByAudieceMasterIdResponse) => {
                var result = onItemInvoked(item)
                return <Image src={result} width={100} height={60} imageFit={ImageFit.cover} />;
            },
        },
        {
            key: 'column4',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 20,
            maxWidth: 20,
            isResizable: false,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    aria-haspopup
                    aria-label="Show actions"
                    title={"Click to select from available actions."}
                    styles={iconListButtonStyles}
                    menuProps={menuProps}
                /></div>
            },
            isPadded: true
        },
    ]
    const menuItems: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete signature.',
            onClick: () => {
                deleteItem();
            },
        },
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit signature.',
            onClick: () => {
                goEdit()

            },
        },
    ];
    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    const menuItemsUser: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete signature.',
        },
        {
            key: 'edit',
            text: 'Edit',
            title: 'Edit signature.',
        },
    ];

    const onItemInvoked = (item: IGetListSignatureByAudieceMasterIdResponse) => {

        var userSignature = item.SignatureImage
        var mime = item.MimeType
        var base64Response = fetch(`data:${mime};base64,${userSignature}`)
        var byteCharacters = atob(item.SignatureImage);
        let n = byteCharacters.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = byteCharacters.charCodeAt(n);
        }
        var fileblob = new Blob([u8arr], { type: mime })
        let objectURL = URL.createObjectURL(fileblob)
        return objectURL

    }
    useEffect(() => {
        if (audienceOptions.length == 0) {
            loadAudience();
        }

    }, [props.showSignature])

    const onSelectionChange = () => {
        const selectionCount = selectionitem.getSelectedCount();

        switch (selectionCount) {
            case 0:
                setSelectedsignature(emptySignature)
                break;
            case 1:

                setSelectedsignature(selectionitem.getSelection()[0] as IGetListSignatureByAudieceMasterIdResponse)
                break;
            default:
                setSelectedsignature(emptySignature)
                break;
        }
    }
    const selectionitem = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    const addSignature = () => {

        setModalTitle("New")
        setShowModal(true)
        setModalId(1)

        selectionitem.setAllSelected(false)

    }
    const deleteItem = async () => {

        setShowDeleteModal(true)
        setModalId(3)
        setModalTitle("Delete")
        setSignatureId(Selectedsignature?.Id!)
        selectionitem.setAllSelected(false)
    }

    const hideModal = () => {
        setShowModal(false)
        setsignatureDesc("")
        setsignatureName("")
        setTypeSignature("")
        setUrl("")
        setIsisCheckDigital(false)
        setIsisCheckImage(false)
        setMessage("")
        setShowMessage(false)
        setShowSuccess(false)
        setTrimmedDataURL("")
        setrequiredSignature(true)
        setextType("")
        setMimeType("")
        setrequiredName(false)
        setrequiredDesc(false)
        setCurrentImageUrl("")
        setisCurrentImage(false)
        setIsUpdate(false)

    }

    const getErrorMessage = (value: string): string => {
        if (value.length > 0) {
            setrequiredName(true)
        }
        else {
            setrequiredName(false)
            return "Name must not be empty.";
        }
        return ""
    };
    const getErrorMessageDesc = (value: string): string => {
        if (value.length > 0) {
            setrequiredDesc(true)
        }
        else {
            setrequiredDesc(false)
            return "Description must not be  empty.";
        }
        return ""
    };

    const getBase64 = (file: File) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64File(reader.result!.toString().split(',')[1]);
        };
        reader.onloadend = () => {
            setrequiredSignature(false)
        }
    }

    const getBase64Digital = (file: File) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            //setBase64File(reader.result!.toString().split(',')[1]);
        };
        reader.onloadend = () => {
            setrequiredSignature(false)
            sendSignatureDigital(reader.result!.toString().split(',')[1])
        }

    }

    async function checkDigital() {
        let arr = trimmedDataURL.split(',');
        let mime = "image/png";
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        var fileblob = new Blob([u8arr], { type: mime })
        let objectURL = URL.createObjectURL(fileblob)
        let file = new File([fileblob], "signature", { type: "image/png" })
        fileimage = file

        setMimeType("image/png")
        setextType("png")
        getBase64Digital(file)
    }

    async function sendSignature() {
        let accountId = Helper.getDefaultAccount()
        let username = Helper.getUsername()
        let sessionKey = Helper.getSessionKey()
        let site = await Helper.getSite()

        const formData = new FormData();
        formData.append('ApiKey', '-1');
        formData.append('SK', sessionKey);
        formData.append('AccountId', accountId);
        formData.append('UserName', username);
        formData.append('Path', site);
        formData.append('DocumentOnlyAudienceMasterId', selectedAudience?.key.toString()!);
        formData.append('ImageName', signatureName);
        formData.append('ImageDescription', signatureDesc);
        formData.append('MimeType', mimeType);
        formData.append('FileExt', extType);
        formData.append('file', base64File);
        
        if (modalTitle === "Edit") {
            formData.append('DocumentOnlyCustomUserSignatureId', SignatureId);

            const request:IUpdateDocumentOnlyCustomUserSignatureRequest = {
                DocumentOnlyCustomUserSignatureId: Number(SignatureId),
                FileExt: extType,
                ImageDescription: signatureDesc,
                MimeType: mimeType,
                SignatureImage: base64File

            }
            await DocumentOnlyCustomUserSignatureService.UpdateDocumentOnlyCustomUserSignature(request)
                .then(()=> {
                    setMessage("signature update successfully!")
                    setShowSuccess(true)
                    setTimeout(function () {
                        setShowSuccess(false)
                        setApplying(true)
                        getSignatures(selectedAudience?.key.toString()!)
                        hideModal()
                    }, 1000);
                })
                .catch((error)=> {
                    setMessage("Something went wrong!")
                    setShowMessage(true)
                    setTimeout(function () {
                        setShowMessage(false)
                        hideModal()
                    }, 1000);
                })
                .finally()
            
        }
        else if (modalTitle === "New") {

            const request: ICreateDocumentOnlyCustomUserSignatureRequest = {
                DocumentOnlyAudienceMasterId: Number(selectedAudience?.key.toString()!),
                FileExt: extType,
                ImageDescription: signatureDesc,
                MimeType: mimeType,
                SignatureImage: base64File,
                ImageName: signatureName
            }

            await DocumentOnlyCustomUserSignatureService.CreateDocumentOnlyCustomUserSignature(request)
                .then(() => {
                    setMessage("signature sent successfully!")
                    setShowSuccess(true)
                    setTimeout(async function () {
                        setShowSuccess(false)
                        setApplying(true)
                        await getSignatures(selectedAudience?.key.toString()!)
                        hideModal()
                    }, 3000);
                })
                .catch((error) => {
                    setMessage(Helper.GetErrorMessage(error))
                    setShowMessage(true)
                    setTimeout(function () {
                        setShowMessage(false)
                        hideModal()
                    }, 3000);
                })
                .finally()            
        }
    }
    async function sendSignatureDigital(f: string) {
       
        let accountId = Helper.getDefaultAccount()
        let username = Helper.getUsername()
        let sessionKey = Helper.getSessionKey()
        let site = await Helper.getSite()

        const formData = new FormData();
        formData.append('ApiKey', '-1');
        formData.append('SK', sessionKey);
        formData.append('AccountId', accountId);
        formData.append('UserName', username);
        formData.append('Path', site);
        formData.append('DocumentOnlyAudienceMasterId', selectedAudience?.key.toString()!);
        formData.append('ImageName', signatureName);
        formData.append('ImageDescription', signatureDesc);
        formData.append('MimeType', mimeType);
        formData.append('FileExt', extType);
        formData.append('file', f);
        if (modalTitle === "Edit") {
            formData.append('DocumentOnlyCustomUserSignatureId', SignatureId);

            const request:IUpdateDocumentOnlyCustomUserSignatureRequest = {
                DocumentOnlyCustomUserSignatureId: Number(SignatureId),
                FileExt: extType,
                ImageDescription: signatureDesc,
                MimeType: mimeType,
                SignatureImage: f
            }
            
            await DocumentOnlyCustomUserSignatureService.UpdateDocumentOnlyCustomUserSignature(request)
                .then(()=> {
                    setMessage("signature update successfully!")
                    setShowSuccess(true)
                    setTimeout(function () {
                        setShowSuccess(false)
                        setApplying(true)
                        getSignatures(selectedAudience?.key.toString()!)

                        hideModal()
                    }, 1000);
                })
                .catch((error) => {
                    setMessage(Helper.GetErrorMessage(error))
                    setShowMessage(true)
                    setTimeout(function () {
                        setShowMessage(false)
                        hideModal()
                    }, 3000);
                })
                .finally()

        } else if (modalTitle === "New") {


            const request: ICreateDocumentOnlyCustomUserSignatureRequest = {
                DocumentOnlyAudienceMasterId: Number(selectedAudience?.key.toString()!),
                FileExt: extType,
                ImageDescription: signatureDesc,
                MimeType: mimeType,
                SignatureImage: f,
                ImageName: signatureName
            }

            await DocumentOnlyCustomUserSignatureService.CreateDocumentOnlyCustomUserSignature(request)
                .then(() => {
                    setMessage("signature sent successfully!")
                    setShowSuccess(true)
                    setTimeout(async function () {
                        setShowSuccess(false)
                        setApplying(true)
                        await getSignatures(selectedAudience?.key.toString()!)

                        hideModal()
                    }, 3000);
                })
                .catch((error) => {
                    setMessage(Helper.GetErrorMessage(error))
                    setShowMessage(true)
                    setTimeout(function () {
                        setShowMessage(false)
                        hideModal()
                    }, 3000);
                })
                .finally()

        }
    }

    async function uploadSignature(ev: React.ChangeEvent<HTMLInputElement>) {
        setUploadingFile(true)
        if(ev.target.files!=null && ev.target.files.length>0)
        {
          var mimeType =ev.target.files![0].type;
          var name =ev.target.files![0].name;
          var file = ev.target.files![0];
          var sizevalid = 1000
          var fileSize = ev.target.files![0].size
          var Sizekb = fileSize / 1024
          let IsValidSize = Sizekb <= sizevalid
          if(mimeType.includes("image/"))
          {
              if(IsValidSize){
                setShowMessage(false)
                fileimage=file
                var imageurl=URL.createObjectURL(file)
                setUrl(imageurl!);
             
                if(mimeType!){
                  var Ext=  mimeType.split("/")[1]
                  setextType(Ext)
                  setMimeType(mimeType)
                }
                setrequiredSignature(false)
                getBase64(file);
              }
              else{

                setShowMessage(true)
                setMessage("The image cannot be greater than 100KB.")
              }
          }
          else{
            setShowMessage(true)
            setMessage("This type of file is not valid. Must be a file with a any image extenstion.")
           }
        }
        setUploadingFile(false)
    }


    const loadAudience = async () => {
        const req: IDocumentOnlyAudienceRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceMasterId: "-1",
            FromRegistration: false
        }
        await Helper.GetDocumentOnlyAudienceDetailByUserName(req).then(data => {
            setAudiences(data)
            if (data.length !== undefined) {
                setAudienceOptions(data.map<IDropdownOption>(item => {
                    var itemText = item.Name
                    if (item.Name.trim().toLowerCase() === "default") {

                        if (item.Owner) {

                            itemText = itemText + " (" + item.UserName + "(" + item.FullName + ")" + ")"
                        }
                        else {
                            itemText = itemText + (item.OwnerFullName !== null ? " (" + item.OwnerUser + "(" + item.OwnerFullName + ")" + ")" : "")
                        }
                    }
                    else {
                        itemText = itemText + " (" + item.Description + " )"
                    }
                    return { key: item.DocumentOnlyAudienceMasterId, text: itemText }
                }))
            }
        })

    }

    const getKey = (item: IDocumentOnlyAudienceDetail, index?: number): string => {
        if (item !== undefined)
            return item.Id.toString()
        return ""
    }

    const onAudienceChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setSelectedAudience(option);
        setAddSignatureDisable(false)
        if (option !== undefined) {
            setApplying(true)
            await getSignatures(option.key.toString())

        }
        setIsChanging(true)
    };

    const toggleAudienceModal = useConstCallback((open: boolean) => {
        setModalAudienceOpen(open)
    })
    function goToAudiences() {
        toggleAudienceModal(true)
    }
    const onCheckboxChangeDigital = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void => {
        if (isChecked) {
            setIsisCheckDigital(isChecked!)
            setIsisCheckImage(false)
            setTypeSignature(ev?.currentTarget.id as string)
        }
    };
    const onCheckboxChangeImage = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void => {
        if (isChecked) {
            setIsisCheckImage(isChecked!)
            setIsisCheckDigital(false)
            setTypeSignature(ev?.currentTarget.id as string)
        }
    };

    const clear = () => {
        if (signatureRef !== undefined) {
            signatureRef.current.clear();
            setTrimmedDataURL("")
            setrequiredSignature(true)
            setextType("")
            setMimeType("")
        }
    }
    const CancelEdit = () => {
        if (_isCheckDigital) {
            clear()
        }
        else if (_isCheckImage) {
            setTypeSignature("")
            setUrl("")
        }
        setisCurrentImage(true)
        setrequiredSignature(true)
        setIsisCheckDigital(false)
        setIsisCheckImage(false)
        setextType("")
        setMimeType("")
        setIsUpdate(false)
    }
    const trim = () => {
        if (signatureRef !== undefined) {
            var dataUrl = signatureRef.current.getTrimmedCanvas().toDataURL("image/png")
            setTrimmedDataURL(dataUrl)
            setextType("png")
            setMimeType("image/png")
            setrequiredSignature(false)
        }
    }
    const getSignatures = async (AudienceMasterId: string) => {
                
        setSignatures([])

        const request: IGetListSignatureByAudieceMasterIdRequest = {
            DocumentOnlyAudienceMasterId: AudienceMasterId
        }
        
        await DocumentOnlyCustomUserSignatureService.GetListSignatureByAudieceMasterId(request)
            .then(data => {
                setSignatures(data)
            })
            .catch()
            .finally(() => {
                setRequiredAudience(false)
            })
        
        setApplying(false)
        let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneSignatureslist")) as Element
        
        if (element !== null) { element.children[1].classList.add("scrollVisible") }  
           
    }
    const goEdit = async () => {

        
        const request:GetDocumentOnlyCustomUserSignatureByIdRequest = {
            Id: Number(Selectedsignature?.Id!)
        }
        
        await DocumentOnlyCustomUserSignatureService.GetDocumentOnlyCustomUserSignatureById(request).then(async data => {
            var result = data
                var userSignature = result.filter((x: IDocumentOnlyCustomUserSignature) => x.Id == Selectedsignature?.Id!)[0]
                setModalTitle("Edit")
                setrequiredName(true)
                setsignatureDesc(userSignature.Description)
                setsignatureName(userSignature.Name)
                setSignatureId(userSignature.Id!)
                setisCurrentImage(true)

                var mime = userSignature.MimeType
                setMimeType(mime)
                var Ext= mime.split("/")[1]
                setextType(Ext)
                var base64Response = await fetch(`data:${mime};base64,${userSignature.SignatureImage}`, {
                    headers: {
                        "x-identity": sessionStorage.getItem("x-identity")!.toString()                    }
                });
               
                var blob = await base64Response.blob();
                let objectURL = URL.createObjectURL(blob)
                
                let file = new File([blob], "signature", { type: mime })
                fileimage = file
               
                getBase64(file);

                setCurrentImageUrl(objectURL)
                setShowModal(true)
                setModalId(1)
        })

        selectionitem.setAllSelected(false)
    }

    const hideDeleteModal = () => {
        setShowDeleteModal(false)
        setIsUpdate(false)
    }

    async function deleteSignature() {

        if (Number(SignatureId) > 0) {

            const request: IDeleteDocumentOnlyCustomUserSignatureRequest = {
                Id: Number(SignatureId)
            }

            await DocumentOnlyCustomUserSignatureService.DeleteDocumentOnlyCustomUserSignature(request)
                .then((data) => {
                    setMessage("signature deleted successfully!")
                    setShowSuccess(true)
                    setTimeout(async function () {
                        setShowSuccess(false)
                        setApplying(true)
                        await getSignatures(selectedAudience?.key.toString()!)
                    }, 1000);
                    hideDeleteModal()
                })
                .catch(() => {
                    setMessage("Something went wrong!")
                    setShowMessage(true)
                    setTimeout(function () {
                        setShowMessage(false)
                        hideDeleteModal()
                    }, 1000);
                })
                .finally()

        }
    }

    return (
        <>
            {!props.fromModal ? <>
                <label className="ms-fontSize-18">My Signatures</label><br />
                <div>Manage and list the signatures for selected audiences.</div>
            </>
                : null
            }
            <Stack tokens={stackTokens}>
                <label />
                <label />
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <Dropdown
                                label="Audience"
                                options={audienceOptions}
                                selectedKey={selectedAudience?.key}
                                errorMessage={selectedAudience?.key == "" ? "This field is required" : ""}
                                onChange={onAudienceChange}
                                title="Select audience to manage signatures." />

                        </div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1" style={{ paddingTop: "20px", right: "5px", top: "5px" }}>
                            <ActionButton title="Add Audience" iconProps={{ iconName: 'Add' }} onClick={goToAudiences} />
                        </div>
                    </div>
                </div> <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ActionButton title="Add signature to audience." text="Add Signature" 
                            iconProps={{ iconName: 'Add' }} onClick={addSignature} disabled={addSignatureDisable || applying} />
                    </div>
                </div>
               
                
                {/* <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "25vh" }}> */}
                {!applying ?
                  
                 <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                        <div className={classNames.contentSignatures}>
                        <ScrollablePane styles={scrollablePaneStyles} id="ScrollPaneSignatureslist" key="ScrollPaneSignatureslist" >
                        <DetailsList
                            items={signatures}
                            compact={false}
                            columns={columns}
                            //getKey={getKey}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selection={selectionitem}
                            selectionMode={SelectionMode.single}
                            selectionPreservedOnEmptyClick={false}
                            enterModalSelectionOnTouch={true}
                            checkboxVisibility={CheckboxVisibility.hidden}
                        />
                         </ScrollablePane>
                        </div>
                    </div>
                     </div>
                      
                    : <Spinner size={SpinnerSize.medium} label="Loading data..." ariaLive="assertive" />}
                {/* </div> */}
               
            </Stack>

            <Modal
                isOpen={showModal}
                isBlocking={true}
            >
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Create">{modalTitle}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.body}>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                        {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <TextField label="Name:" onGetErrorMessage={getErrorMessage} maxLength={75} required onChange={(_, v) => { setsignatureName(v!); }} value={signatureName} disabled={modalTitle === "Edit"} title="Enter name for this signature." />
                                            <TextField label="Description" onGetErrorMessage={getErrorMessageDesc} maxLength={1000} required onChange={(_, v) => { setsignatureDesc(v!); setIsUpdate(true)}} value={signatureDesc} title="Enter description for this signature." />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="ms-Grid-row" hidden={!_isCurrentImage} >
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                                            <Label>Signature</Label>
                                        </div>

                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="ms-Grid-col ms-sm2 ms-md3 ms-lg4" style={{ paddingLeft: "0px", paddingRight: "0px" }} >
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                                                    <ActionButton title="Save signature" text="Edit signature" iconProps={{ iconName: 'Edit' }} onClick={() => { setisCurrentImage(false); setIsUpdate(true);}} />
                                                </div>

                                            </div>
                                            <img src={CurrentImageurl} style={{ width: "16vw", height: "10vh", border: "1px solid rgb(0 120 212)" }} />
                                        </div>

                                    </div>
                                    <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "30vh" }} hidden={_isCurrentImage}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <Label>Type signature*</Label>
                                            </div>
                                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ top: "10px" }}>
                                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" >
                                                    <Checkbox id="ImageSignature" checked={_isCheckImage} label="Image" onChange={onCheckboxChangeImage} title="Select an image that you want to use for your signature." />

                                                </div>
                                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" >
                                                    <Checkbox id="DigitalSignature" label="Digital" checked={_isCheckDigital} onChange={onCheckboxChangeDigital} title="Freely scribble an entry to use for your signature." />
                                                </div>
                                            </div>
                                        </div>

                                        <br />

                                        <div className="ms-Grid-row" hidden={!_isCheckImage} >
                                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4" style={{ paddingLeft: "0px", paddingRight: "0px" }} >
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                                                    <label htmlFor="file"><div className="divHover divToolBoxItem" style={{color: branding.theme.palette.white, backgroundColor: branding.theme.palette.themePrimary}}>Choose Image</div></label>
                                                    <input accept="image/*" className="fileButton" id="file" type="file" name="file" onChange={uploadSignature} title="Select file that represents this signature." />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" hidden={url == ""}>
                                                    <ActionButton title="Close." text="Cancel edit" iconProps={{ iconName: 'Cancel' }} onClick={CancelEdit} />
                                                </div>

                                            </div>
                                            <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8" hidden={url == ""}>
                                                <img src={url} style={{ width: "16vw", height: "10vh", border: "1px solid rgb(0 120 212)" }} />
                                            </div>

                                        </div>

                                        <div className="ms-Grid-row" hidden={!_isCheckDigital}>
                                            <div className="ms-Grid-col ms-sm2 ms-md3 ms-lg4" style={{ paddingLeft: "0px", paddingRight: "0px" }} >
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                                                    <ActionButton title="Save signature." text="Save signature" iconProps={{ iconName: 'Save' }} onClick={trim} />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                                                    <ActionButton title="Clear signature." text="Clear signature" iconProps={{ iconName: 'Cancel' }} onClick={clear} />
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                                                    <ActionButton title="Close." text="Cancel edit" iconProps={{ iconName: 'Cancel' }} onClick={CancelEdit} />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-col ms-sm8 ms-md8  ms-lg8" hidden={trimmedDataURL !== ""}>
                                                <SignatureCanvas ref={signatureRef} canvasProps={{ width: 300, height: 150, style: { "border": "1px solid rgb(0 120 212)" } }}
                                                    minWidth={2}
                                                    maxWidth={3}
                                                    penColor="black"
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8" hidden={trimmedDataURL == ""}>
                                                <img src={trimmedDataURL} style={{ width: "16vw", border: "1px solid rgb(0 120 212)" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={_isCheckDigital ? checkDigital : sendSignature}disabled={ modalTitle == "Edit" ? !isUpdate:(!requiredName || !requiredDesc || requiredSignature)}  text={modalTitle == "Edit" ? "Update" : "Add"} title={modalTitle == "Edit" ? "Update signer." : "Add signer."} />
                                    <DefaultButton onClick={hideModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                    </div>
                </Stack>
            </Modal>


            <Modal
                isOpen={modalAudienceOpen}
                isBlocking={true}
                containerClassName={contentStyles.containerMail}>
                <div className={contentStyles.header}>
                    <span id="myAudience">My Audiences</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        title="Close."
                        ariaLabel="Close popup modal"
                        onClick={() => {
                            toggleAudienceModal(false)
                        }}
                    />
                </div>

                <div className={contentStyles.body}>
                    <div className="ms-Grid" >
                        <Audiences
                            toggleSettings={props.toggleSettings}
                            siteOptions={props.siteOptions}
                            updateSiteSelected={props.updateSiteSelected}
                            siteSelected={Helper.getSiteSelected()}
                            accountId={Helper.getDefaultAccount()}
                            username={Helper.getUsername()}
                            sessionKey={Helper.getSessionKey()}
                            fromModal={true}
                        />
                    </div>
                </div>


            </Modal>
            <Modal
                isOpen={showDeleteModal}
                isBlocking={true}
            >
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.header}>
                                    <span id="Delete">Delete Signature</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeleteModal}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={classNames.bodyDelete}>
                                    {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                    {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                    Are you sure you want to delete '{Selectedsignature?.Name}' signature?
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton onClick={deleteSignature} text="Delete" title="Delete signer." />
                                    <DefaultButton onClick={hideDeleteModal} text="Cancel" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                    </div>
                </Stack>
            </Modal>
        </>
    );
}

export default Signatures