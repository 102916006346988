import { Checkbox, IIconProps, IconButton, TextField, mergeStyleSets } from '@fluentui/react'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import CustomModal, { Sizes } from '../../../../share/CustomModal'
import { MultiSelectStyles, SingleSelectStyles } from './Styles'
import { MultiValue, SingleValue } from 'react-select'
import { IMyListOption } from '../../../../Helpers/Helper'
import useDebounce from '../../../../hooks/useDebounce'

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const ChevronDown: IIconProps = { iconName: 'ChevronDown' };
type MultiSelect = MultiValue<IMyListOption>
type SingleSelect = SingleValue<IMyListOption>
type SelectValue = SingleSelect | MultiSelect | undefined

type MobileSelectProps = {
    options: IMyListOption[]
    name: string
    defaultValue: SelectValue
    onChange: (value: SelectValue | undefined) => void
    required: boolean
    readOnly: boolean
    isMultiple?: boolean
    tabIndex: number | undefined
    placeholder?: string
    label?: string
}

export default function MobileSelect({ defaultValue, name, options: initialOptions, onChange, isMultiple, tabIndex, readOnly, placeholder, label }: MobileSelectProps) {

    const allOptions = useRef(initialOptions)
    const [options, setOptions] = useState(initialOptions)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState<SelectValue>(defaultValue)
    const [search, setSearch] = useState('')
    const debounceSearch = useDebounce(search, 500)

    const handleChange = (newValue: IMyListOption) => {
        if (isMultiple) {
            const selectedOptions = selectedOption as MultiSelect
            const index = selectedOptions.findIndex(item => item.value === newValue?.value)
            let _selectedOptions = [...selectedOptions]
            if (index >= 0) {
                _selectedOptions = [...selectedOptions.slice(0, index), ...selectedOptions.slice(index + 1)]
            } else {
                _selectedOptions = [...selectedOptions, newValue]
            }
            setSelectedOption(_selectedOptions)
            onChange(_selectedOptions)
            return
        }
        setSelectedOption(newValue)
        onChange(newValue)
        setIsOpen(false)
    }
    const handleClick = () => {
        if (readOnly) return
        setIsOpen(true)
    }

    const dataSelected = useMemo<IMyListOption>(() => {
        if (isMultiple) {
            const selectedOptions = selectedOption as MultiSelect
            if (selectedOptions?.length === 0) {
                return { label: '', value: '' }
            }
            const labels = selectedOptions?.map(item => item.label).join(', ') ?? ''
            const values = selectedOptions?.map(item => item.value).join(',') ?? ''
            return { label: labels, value: values }
        }
        return { label: (selectedOption as SingleSelect)?.label ?? '', value: (selectedOption as SingleSelect)?.value ?? '' }

    }, [isMultiple, selectedOption])

    useEffect(() => {
        if (debounceSearch) {
            const filter = allOptions.current.filter(item => item.label.toLowerCase().includes(debounceSearch.toLowerCase()))
            return setOptions(filter)
        }
        setOptions(allOptions.current)
    }, [debounceSearch])

    const handleSearch = (event: any, newValue?: string | undefined) => {
        setSearch(newValue ?? '')
    }
    const clearValue = () => {
        if (isMultiple){
            setSelectedOption([])
            onChange([])
            return
        }
        setSelectedOption({ label: '', value: '' })
        onChange(undefined)
    }
    return (
        <>
            <div className={`select-input ${readOnly ? 'readonly' : ''}`}>
                <span className="select-input-value" onClick={handleClick}>{
                    (dataSelected)?.label ?
                        <span>{dataSelected?.label}</span> : <em className="select-input-placeholder">{placeholder}</em>
                }
                </span>
                <span className="select-input-right">
                    <div className='select-input-buttons'>
                        {dataSelected?.label ? <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel={'Clear'}
                            onClick={clearValue}
                            title={'Clear'}
                        /> : null}
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={ChevronDown}
                            ariaLabel={'Select'}
                            title={'Select'}
                            onClick={handleClick}
                        />
                    </div>
                    {
                        !readOnly ? <input key={`input_${name}`} type='text' id={name}
                            style={{ opacity: 0, position: 'absolute', height: 0, width: '0' }}
                            tabIndex={tabIndex} /> : null
                    }
                </span>
            </div>
            {!readOnly ?
                <CustomModal title={label!== undefined? label: name} titleId={`modal-${name}`} size={Sizes.lg} close={() => setIsOpen(false)} isOpen={isOpen}>
                    <div>
                        <div className="select-input-search">
                            <TextField key={`search_${name}`} placeholder='Search...' value={search} onChange={handleSearch} />
                        </div>
                        <div className={OptionStyles.list}>
                            {options.map((option) => (
                                <Fragment key={option.value}>
                                    {isMultiple ?
                                        <MultipleOption onChange={handleChange} selectedOptions={selectedOption as MultiSelect}
                                            option={option} />
                                        :
                                        <SingleOption onChange={handleChange} option={option}
                                            isSelected={(dataSelected)?.value === option.value} />
                                    }
                                </Fragment>

                            ))}
                        </div>
                    </div>

                </CustomModal> : null}
        </>

    )
}
type SingleOptionProps = {
    isSelected: boolean
    option: IMyListOption
    onChange: (value: IMyListOption) => void
}
const SingleOption = ({ option, isSelected, onChange }: SingleOptionProps) => {


    return (
        <div onClick={() => { onChange(option) }}
            className={SingleSelectStyles.option}
            style={{
                backgroundColor: isSelected ? "rgb(237, 235, 233)" : "#ffffff"
            }}
        >
            <span className={SingleSelectStyles.paragraph}>{option.label}</span>
        </div>
    )
}
type MultipleOptionProps = {
    selectedOptions: MultiSelect
    option: IMyListOption
    onChange: (value: IMyListOption) => void
}
const MultipleOption = ({ option, selectedOptions, onChange }: MultipleOptionProps) => {

    const isSelected = useMemo<boolean>(() => {
        if (selectedOptions?.length === 0)
            return false
        return !!selectedOptions.find(item => item.value === option.value)
    }, [option.value, selectedOptions])

    return (
        <div
            className={MultiSelectStyles.option}
            onClick={() => { onChange(option) }}
            style={{
                backgroundColor: isSelected ? "rgb(237, 235, 233)" : "#ffffff",
            }}
        >
            {isSelected ? (
                <>
                    <Checkbox
                        className={`${MultiSelectStyles.checkbox}`}
                        checked={true}
                        disabled
                        styles={{
                            checkbox: {
                                backgroundColor: "rgb(0, 120, 212)",
                                border: "0px",
                            },
                        }}
                    />
                    <span className={OptionStyles.label}>{option.label}</span>
                </>
            ) : (
                <>
                    <Checkbox
                        className={MultiSelectStyles.checkbox}
                        checked={false}
                        disabled
                    />
                    <span className={OptionStyles.label}>{option.label}</span>
                </>
            )}
        </div>
    );
}
const iconButtonStyles = {
    root: {
        color: '#ddd',
        backgroundColor: 'transparent',
        width: 'auto',
        fontWeight: 'bold'
    },
    rootHovered: {
        color: '#000',
        backgroundColor: 'transparent',
    },
    rootPressed: {
        backgroundColor: 'transparent',
    },
    icon: {
        fontSize: '12px'
    }

};
const OptionStyles = mergeStyleSets({
    list: {
        marginTop: '10px',
        height: '60vh'
    },
    label: {
        alignItems: "center",
        margin: 0
    }
})