import { UpdateOutboundIntegration } from "../Components/OutboundIntegration/Models/OutboundIntegrationModel";
import { IFormControl } from "../Helpers/Helper";
import { ExecuteEventRequestPost, IExecuteEventResponse, IExecuteIntegrationPost, IOutputMap, IOutputPayloadResponse, } from "../interfaces/IOutbound";
import HttpRequest from "./HttpRequest";

const OutboundService = {
    ExecuteIntegrationPost: async (request: IExecuteIntegrationPost): Promise<IOutputPayloadResponse> => {
        const { data }: any = await HttpRequest.postWithSession("OutboundIntegration/Integration/Execute", { ...request, DocumentId: -1 });
        let OutputPayload: IFormControl[] = [];
        let OutputMap: IOutputMap[] = [];
        let ErrorMessage: string | null = null;
        if (data.Success !== false) {
            
            console.log("==============================================")
            console.log(data)
            console.log("==============================================")
            const { Data } = data;
            // try {
            //     Output = JSON.parse(Data.OutputMap) as IMapOutput[]
            // } catch (error) {
            //     ErrorMessage = Data
            // }
            try {
                OutputMap = JSON.parse(Data.OutputMap) as IOutputMap[]
                OutputPayload = JSON.parse(Data.OutputPayload) as IFormControl[];
            } catch (error) {
                ErrorMessage = Data;
            }
        } else {
            ErrorMessage = data.ExceptionMessage;
        }

        return new Promise((resolve) => {
            resolve({ ErrorMessage, OutputPayload, OutputMap });
        });
    },
    ExecuteIntegration: async (request: IExecuteIntegrationPost): Promise<any> => {
        return await HttpRequest.postWithSession("OutboundIntegration/Integration/Execute", { ...request, DocumentId: -1 });
    },
    UpdateIntegration: async (request: UpdateOutboundIntegration): Promise<any> => {
        return await HttpRequest.postWithSession("OutboundIntegration/Integration/Update", { ...request });
    },
    GetOnClickEvents: async ({ TemplateId }: { TemplateId: number }) => {
        return await HttpRequest.getWithSession("OutboundIntegration/Event/OnClick", { TemplateId });
    },
    ExecuteEventPost: async (request: ExecuteEventRequestPost): Promise<IExecuteEventResponse> => {
        const { data }: any = await HttpRequest.postWithSession("OutboundIntegration/Event/Execute", { ...request, DocumentId: -1 });
        let OutputPayload: IFormControl[] = [];
        let OutputMap: IOutputMap[] = [];
        let ErrorMessage: string | null = null;

        if (Array.isArray(data) && data.length > 0 &&  data[0].Success !== false) {
            const { Data } = data[0];
            try {
                OutputPayload = JSON.parse(Data.OutputPayload) as IFormControl[];
                OutputMap = JSON.parse(Data.OutputMap) as IOutputMap[];
            } catch (error) {
                ErrorMessage = Data;
            }
        } else {
            ErrorMessage = data.ExceptionMessage;
        }

        return new Promise((resolve) => {
            resolve({ ErrorMessage, OutputPayload, OutputMap });
        });
    },
};

export default OutboundService;
