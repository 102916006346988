import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import '../../../../Custom.css';
import {ShimmeredDetailsList, Stack, StackItem} from "@fluentui/react";
import {DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";
import {Text} from "@fluentui/react/lib/Text";
import {Separator} from "@fluentui/react/lib/Separator";
import {IIconProps} from "@fluentui/react/lib/Icon";
import {nonShrinkingStackItemStyles} from "./Style"
import {
    CheckboxVisibility,
    DetailsListLayoutMode,
    Selection,
    SelectionMode
} from "office-ui-fabric-react/lib/DetailsList";
import {getColumns} from "./AutomaticallySubrecords.data";
import StatusMessage, {StatusEnum} from "../../../../share/StatusMessage";
import {Spinner, SpinnerSize} from "@fluentui/react/lib/Spinner";
import AutomaticallySubrecordsService, {IDocumentResponse, Template} from "./AutomaticallySubrecords.service";

type Properties = {
    titleId: string,
    cancel: any
    getLinkGroup: any
    option: string
    setIsOpenMenu: any
    showModal: any
    onLinkClick: any
    item?: any,
    isOpenMenu: any
}

function AutomaticallySubrecords({
                                     showModal,
                                     option,
                                     item,
                                     getLinkGroup,
                                     titleId,
                                     cancel,
                                     isOpenMenu,
                                     onLinkClick,
                                     setIsOpenMenu
                                 }: Properties) {

    const {t} = useTranslation(['attachmentsComponent', 'common'])
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const menuIcon: IIconProps = {iconName: 'GlobalNavButton'};
    const [isLoading, setIsLoading] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [showEmptySubforms, setShowEmptySubforms] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [hasTimer, setHasTimer] = useState(false)
    const [truncated, setTruncated] = useState(true)
    const [isMultiline, setIsMultiline] = useState(false)
    const [dismiss, setDismiss] = useState(true)
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [message, setMessage] = useState("")
    const [items, setItems] = useState<IDocumentResponse[]>([])

    let [selection] = useState(
        new Selection({selectionMode: SelectionMode.multiple})
    )

    const showStatusMessage = (message: string, status: StatusEnum, hasTimer = false, truncated = true, isMultiline = true, dismiss = true) => {
        setMessage(message)
        setHasTimer(hasTimer)
        setTruncated(truncated)
        setIsMultiline(isMultiline)
        setDismiss(dismiss)
        setStatusMessageEnum(status)
        setShowMessage(true)
    }

    const save = async () => {
        setIsSaving(true)

        let templates: Template[] = []
        selection.getSelection().map((item: any) => {
                templates.push({TemplateId: item.TemplateId, TemplateName: item.DisplayName})
            }
        )

        await AutomaticallySubrecordsService.postSubforms({
            TemplateId: Number(item?.key),
            DocumentOnlyAudienceId: item?.DocumentOnlyAudienceId,
            Templates: templates
        }).then(data => {
            if (!data.success) {
                showStatusMessage(data.messageError ? data.messageError : "", StatusEnum.Error)
            } else {
                showStatusMessage(t('messages.success', {ns: 'automaticallyCreateSubrecords'}), StatusEnum.Success)
            }
        }).catch(() => {
            showStatusMessage("Request error", StatusEnum.Error)
        }).finally(() => {
                setIsSaving(false)
            }
        )
    }

    useEffect(() => {
        let isMounted = true
        if (isLoading) {
            AutomaticallySubrecordsService.getSubforms({
                TemplateId: item?.key,
                DocumentOnlyAudienceId: item?.DocumentOnlyAudienceId
            }).then(data => {
                if (isMounted) {

                    if (!data.success) {
                        showStatusMessage(data.messageError ? data.messageError : "", StatusEnum.Error)
                    } else {
                        try {
                            const items = JSON.parse(data.result as string)
                            if (Array.isArray(items)) {
                                setIsLoaded(true)

                                if (items.length === 0) {
                                    setItems([])
                                    setShowEmptySubforms(true)
                                } else {

                                    setItems(items)
                                    selection.setItems(items, true)
                                    items.map((selectedItem: IDocumentResponse, index) => {
                                        if (selectedItem.CheckedTemplate) {
                                            selection.setIndexSelected(index, true, false)

                                        }
                                    });
                                }

                            } else {
                                showStatusMessage(t('message.error', {ns: 'common', Error: items}), StatusEnum.Error)
                            }

                        } catch (e) {
                            showStatusMessage(t('message.error', {ns: 'common', Error: e?.toString()}), StatusEnum.Error)
                        }
                    }
                }
            }).catch(error => {
                    showStatusMessage(error, StatusEnum.Error)
                }
            )
                .finally(() => {
                        setIsLoading(false)
                    }
                )
        }
        return () => {
            isMounted = false
        }
    }, [isLoading])

    return (
        <>
                    {/*-- START CONTENT --*/}
                    <Stack grow={1} tokens={{padding: 8}} style={{overflow: "hidden"}}>
                        <StackItem>
                            <Text id={titleId} variant={'large'}>
                                {t('title.titleSection', {ns: 'automaticallyCreateSubrecords'})}
                            </Text>
                            <Text variant={'medium'}>
                                <p style={{padding:"14px 0px"}}>{t('text.help', {ns: 'automaticallyCreateSubrecords'})}</p>
                            </Text>
                            {showMessage &&
                                <>
                                    <StatusMessage status={statusMessageEnum} hasTimer={hasTimer} truncated={truncated}
                                                   isMultiline={isMultiline}
                                                   dismiss={dismiss}
                                                   setShowMessage={setShowMessage}>
                                        <div>{message}</div>
                                    </StatusMessage>
                                    <Separator/>
                                </>
                            }
                        </StackItem>
                        <StackItem grow={1} className="scrollVisible" data-is-scrollable="true"
                                   style={{overflow: "hidden", overflowY: "auto", height: "40vh"}}>

                            <ShimmeredDetailsList
                                items={items}
                                enableShimmer={!isLoaded}
                                compact={false}
                                columns={getColumns()}
                                selectionMode={SelectionMode.multiple}
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                isSelectedOnFocus={false}
                                setKey={"TemplateId"}
                                selection={selection}
                                selectionPreservedOnEmptyClick={true}
                                enterModalSelectionOnTouch={true}
                                ariaLabelForSelectionColumn={t('ariaLabel.selectionColumn', {ns: 'automaticallyCreateSubrecords'})}
                                ariaLabelForSelectAllCheckbox={t('ariaLabel.selectAllCheckbox', {ns: 'automaticallyCreateSubrecords'})}
                                checkButtonAriaLabel={t('ariaLabel.checkButton', {ns: 'automaticallyCreateSubrecords'})}
                                checkboxVisibility={CheckboxVisibility.always}
                            />
                            {showEmptySubforms &&
                                <p style={{textAlign: "center"}}>
                                    <Text variant={'small'}>
                                        <Trans>
                                            {t('text.emptySubforms', {ns: 'automaticallyCreateSubrecords'})}
                                        </Trans>
                                    </Text>
                                </p>
                            }

                        </StackItem>

                        {/*-- START FOOTER --*/}
                        <Separator></Separator>
                        <Stack horizontal style={{overflow: "hidden"}} styles={nonShrinkingStackItemStyles}>
                            <StackItem grow={1}>&nbsp;</StackItem>
                            {isLoading &&
                                <>
                                    <StackItem>
                                        <Spinner size={SpinnerSize.medium} labelPosition="left"
                                                 label={t('text.spinner', {ns: 'common'})}/>
                                    </StackItem>
                                </>
                            }
                            {isSaving &&
                                <>
                                    <StackItem>
                                        <Spinner size={SpinnerSize.medium} labelPosition="left"
                                                 label={t('text.spinnerSaving', {ns: 'common'})}/>
                                    </StackItem>
                                </>
                            }

                            <StackItem>
                                <PrimaryButton disabled={!isLoaded || items.length === 0} style={{marginLeft: 8}}
                                               onClick={save}
                                               text={t('Common.Button.Update', {ns: 'common'})}
                                               title={t('Common.Button.Update', {ns: 'common'})}/>
                                <DefaultButton onClick={cancel} style={{marginLeft: 8}}
                                               text={t('Common.Button.Close', {ns: 'common'})}
                                               title={t('Common.Button.Close', {ns: 'common'})}/>
                            </StackItem>
                        </Stack>
                        {/*-- START FOOTER --*/}

                    </Stack>
                    {/*-- END CONTENT --*/}
        </>

    )
}

export default AutomaticallySubrecords