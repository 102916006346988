import { useContext, useEffect, useMemo } from "react";
import { customAppContext } from "../context/AppContext";

export const useSignalR = () => {
    const { connection, setConnection, setKimDocumentCreateSuccessMessage, setKimDocumentCreateFailedMessage, setKimDocumentUpdateSuccessMessage, setKimDocumentUpdateFailedMessage, kimDocumentCreateSuccessMessage, kimDocumentCreateFailedMessage, kimDocumentUpdateSuccessMessage, kimDocumentUpdateFailedMessage } = useContext(customAppContext);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    connection.on("KimDocumentCreateSuccess", (message: string) => {
                        setKimDocumentCreateSuccessMessage(message);
                    });

                    connection.on("KimDocumentCreateFailed", (message: string) => {
                        setKimDocumentCreateFailedMessage(message);
                    });

                    connection.on("KimDocumentUpdateSuccess", (message: string) => {
                        setKimDocumentUpdateSuccessMessage(message);
                    });

                    connection.on("KimDocumentUpdateFailed", (message: string) => {
                        setKimDocumentUpdateFailedMessage(message);
                    });
                })
                .catch((e: any) => console.log("Connection failed: ", e));
        }
    }, [connection]);

    return useMemo(
        () => ({
            connection,
            setConnection,
            CreateSuccessMessage: kimDocumentCreateSuccessMessage,
            CreateFailedMessage: kimDocumentCreateFailedMessage,
            UpdateSuccessMessage: kimDocumentUpdateSuccessMessage,
            UpdateFailedMessage: kimDocumentUpdateFailedMessage,
        }),
        [connection, setConnection, kimDocumentCreateFailedMessage, kimDocumentCreateSuccessMessage, kimDocumentUpdateFailedMessage, kimDocumentUpdateSuccessMessage],
    );
};
