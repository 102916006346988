import React, { useState } from 'react';
import { useId } from '@uifabric/react-hooks';
import { IconButton } from '@fluentui/react/lib/Button';
import { Separator } from '@fluentui/react/lib/Separator';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { IIconProps } from '@fluentui/react/lib/Icon';
import '../../Custom.css'
import { Modal } from "@fluentui/react/lib/Modal"
import {IDropdownStyles, Spinner, SpinnerSize, Stack, StackItem} from '@fluentui/react';
import BulkDownload from './BulkDownload';
import {useTranslation} from "react-i18next";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import { Text } from "@fluentui/react/lib/Text"
import {useBranding} from "../../hooks/useBranding";


export interface IAttachmentResponse {
    Id: number;
    AccountId: number;
    DocumentId: number;
    MimeType: string;
    Name: string;
    Description: string;
    CreatedBy: string;
    UpdatedBy: string;
    DateTime: Date;
    FileExt: string;
    AllowEsignFromManualUpload: boolean;
}

const theme = getTheme();

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
    dropdownItemsWrapper: { maxHeight: "300px", overflowY: "auto" }
};

function BulkDownloadModal(props: any) {
    const { branding } = useBranding()
    const titleId = useId(props.modalId);
    const {t} = useTranslation(['common', 'bulkdownload']);

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
                class: 'ms-Icon ms-Icon--Mail'
            }
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        header: [
            theme.fonts.xLarge,
            {
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        bodyDelete: {
            width: '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        body: {
            width: '40vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyEdit: {
            width: '50vw',
            height: '60vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyVWOverride: {
            width: '90%',

            flex: '4 4 auto',
            padding: '0 0px 0px 18px',
        },
        bodyEditVWOverride: {
            width: '40vw',

            flex: '4 4 auto',
            padding: '0 18px 0px 18px',
        },
        User: {
            width: '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const stackTokens = { childrenGap: 10 };

    return (
        <>
            <Modal isOpen={props.showModal} isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={classNames.header}>
                                <Stack horizontal styles={{root: {alignItems: "center"}}}>
                                    <StackItem grow={1}>
                                        <Text variant={"xLarge"} id={titleId}>{t('title.title', { ns: 'bulkdownload'})}</Text>
                                    </StackItem>
                                    <StackItem>
                                <HelpComponentRelative
                                    headline={t('title.title', { ns: 'bulkdownload'})}
                                    text={t('text.help', { ns: 'bulkdownload'})}
                                    right="50px"
                                    location="bulkdownload"
                                />
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                    onClick={props.hideModal}
                                    title={t('Common.Button.Close', { ns: 'common'})}
                                />
                                    </StackItem>
                                </Stack>
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <BulkDownload
                        fetchTemplates={true}
                        fromModal={true}
                        hideModal={props.hideModal}
                        update={props.update}

                    />
                    <br />
                    <br />
                </div>
            </Modal>
        </>
    )
}

export default BulkDownloadModal