import {
    ActionButton,
    Callout,
    Checkbox,
    ChoiceGroup,
    DatePicker,
    DirectionalHint,
    Dropdown,
    FontIcon,
    FontWeights,
    getTheme,
    IChoiceGroupOption,
    Icon,
    IconButton,
    IDropdownOption,
    Image,
    ImageFit,
    ImageLoadState,
    IStackStyles,
    ITextStyles,
    Label,
    MaskedTextField,
    mergeStyleSets,
    PrimaryButton,
    Separator,
    Slider,
    Spinner,
    Stack,
    StackItem,
    Text,
    TextField,
    Toggle,
    TooltipHost
} from '@fluentui/react';
import React, {FormEvent, useCallback, useEffect, useState} from 'react'
import {Color, CompactPicker} from 'react-color';
import Select, {ActionMeta, SingleValue} from 'react-select';
import {
    datePickerFormats,
    DEFAULT_FORM_FORMAT,
    formatSimpleDate,
    getDateFromFormat,
    getISODateString
} from '../../../../Helpers/DateUtils';
import {cancelIcon, customDropdownStyles, stackTokens} from '../../../AdminLogs/AdminLogs.data';
import HelpComponentRelative from '../../../Help/HelpComponentRelative';
import {InputType, TextBoxType} from '../../Validations';
import AttachmentProperties from './AttachmentProperties';
import HelpLinkProperties from './HelpLinkProperties';
import IntegrationProperties from './IntegrationProperties';
import {IMyListOption} from '../../../../Helpers/Helper';
import {useBoolean, useConstCallback, useId} from '@fluentui/react-hooks';
import {EditorValue} from 'react-rte';
import {DisplayType} from '../../../OutboundIntegration/Models/Enums';
import {EventModel} from '../../../OutboundIntegration/Models/EventModel';
import {OutboundIntegrationModel} from '../../../OutboundIntegration/Models/OutboundIntegrationModel';
import CurrencyInput from "react-currency-input-field";
import {emptyBackgroundColor, emptyColor, errorMessage, ILayout, maxLength} from '../../CustomTemplate';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {currencyOptions} from '../../CustomTemplate.data';
import {useBranding} from '../../../../hooks/useBranding';
import PropertiesMenu from '../../PropertiesMenu';
import ListService, {IGetListsDetailsRequest} from "../../../../services/List";
import {RHFTextBoxAreaControl} from "../../../RHFControls/RHFTextBoxAreaControl";
import {RHFUploadControl} from "../../../RHFControls/RHFUploadControl";
import {SpinnerSize} from "@fluentui/react/lib/Spinner";

const theme = getTheme();
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        selectors: {
            ":hover": {
                cursor: "pointer",
            },
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const contentStyles = mergeStyleSets({
    header: [
        theme.fonts.xLarge,
        {
            flex: "1 1 auto",
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
        },
    ],
    bodyMail: {
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        overflowY: "hidden",
        minHeight: 500,
    },
    containerSaveModal: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        width: "30vw",
        height: "20vh",
    },

    callout: {
        width: 320,
        maxWidth: "90%",
        padding: "16px 8px",
    },
    labelHelp: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    msLabel: {
        fontWeight: "600",
    },
});

const iconProps = { iconName: "Info" };
const labelCalloutStackStyles: Partial<IStackStyles> = {
    root: { padding: 20 },
};
const options: IChoiceGroupOption[] = [
    { key: "0", text: "None" },
    { key: "1", text: "Mask" },
    { key: "2", text: "Pattern" },
];

const optionsAlign: IChoiceGroupOption[] = [
    { key: "0", text: "Left" },
    { key: "1", text: "Center" },
    { key: "2", text: "Right" },
];
const optionsFont: IChoiceGroupOption[] = [
    { key: "0", text: "Small" },
    { key: "1", text: "Medium" },
    { key: "2", text: "Large" },
];
const InputsList: IDropdownOption[] = [
    {
        key: 0,
        text: "",
    },
    {
        key: 14,
        text: "Check Box",
    },
    {
        key: 8,
        text: "Currency",
    },
    {
        key: 6,
        text: "Date",
    },
    {
        key: 3,
        text: "Drop Down List",
    },
    {
        key: 4,
        text: "Drop Down (Multiple selection)",
    },
    {
        key: 16,
        text: "Image",
    },
    {
        key: 7,
        text: "Integer",
    },
    {
        key: 13,
        text: "Rich Text Editor",
    },
    {
        key: 2,
        text: "Text Area",
    },
    {
        key: 1,
        text: "Text Box",
    },
    {
        key: 9,
        text: "Line Break",
        disabled: true,
        hidden: true,
    },
    {
        key: 10,
        text: "Section Header",
        disabled: true,
        hidden: true,
    },
    {
        key: 11,
        text: "Grid List",
        disabled: true,
        hidden: true,
    },
    {
        key: 12,
        text: "Paragraph",
        disabled: true,
        hidden: true,
    },
    {
        key: 15,
        text: "Attachment",
        disabled: true,
        hidden: true,
    },
    {
        key: 17,
        text: "HelpLink",
        disabled: true,
        hidden: true,
    },
    {
        key: 18,
        text: "Integration",
        disabled: true,
        hidden: true,
    },
];
type PropertyFormType = {
    rteValue: any
    templateId: any,
    siteSelected: any,
    setSelectedList: any,
    setSelectedIntegrationId: any,
    setSelectedIntegration: any,
    setSelectedItemGridList: any,
    setCCId: any,
    setSelectedCC: any,
    selectedList: any,
    goToList: any,
    setIsOpen: any,
    setLockItem: any,
    setSelectedDropDownGridList: any,
    selectedCC: any,
    auxItems: any,
    setApplying: any,
    setRteValue: any,
    setUnexpectedError: any,
    setIsModified: any,
    saveLayout: any,
    setLayoutItems: any,
    selectedItemGridList: any,
    generateDOM: any,
    preloadLists: any,
    ccId: any,
    selectedType: any,
    setSelectedType: any,
    setIsModifiedP: any,
    myLists: any,
    events: EventModel[],
    selectedDropDownGridList: any,
    setSelectedListId: any,
    selectedIntegration: IMyListOption | null,
    lockItem: any,
    setItemProperties: any,
    layoutItems: ILayout[],
    setShowSavePModal: any
    showSavePModal: any
    titleId: any
    cancelSaveModal: any
    isOpen: string | null
    dismissPanel:any
}
//React.Dispatch<React.SetStateAction<string>>
const PropertyForm = ({ templateId, siteSelected, setSelectedList, setSelectedIntegrationId, setShowSavePModal,
    setSelectedIntegration, setSelectedItemGridList, setCCId, setSelectedCC, selectedList, goToList,
    setIsOpen, isOpen, setLockItem, setSelectedDropDownGridList, selectedCC, auxItems, setApplying, setRteValue,
    setUnexpectedError, setIsModified, saveLayout, setLayoutItems, selectedItemGridList, rteValue,
    generateDOM, preloadLists, ccId, selectedType, setSelectedType, setIsModifiedP, myLists, events,
    selectedDropDownGridList, setSelectedListId, selectedIntegration, lockItem, dismissPanel,
    setItemProperties, layoutItems }: PropertyFormType) => {
        
    const { branding } = useBranding()
    const myForm = useForm();

    const labelId: string = useId("label");
    let item = layoutItems!.filter((x) => x.Id === ccId)[0];
    const [validPattern, setValidPattern] = useState(true);
    const [isLineBreak, setIsLineBreak] = useState(false);
    const [isGridList, setIsGridList] = useState(false);
    const [columns, setColumns] = useState<IDropdownOption[]>();
    const [selectedColumnRequired, setSelectedColumnRequired] = useState(false);
    const [selectedColumnReadOnly, setSelectedColumnReadOnly] = useState(false);
    const [showStyles, setShowStyles] = useState(false);
    const [showDelayStyles, setDelayShowStyles] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderlined, setIsUnderlined] = useState(false);
    const [showScrollbar, setShowScrollbar] = useState(true);
    const [selectedLabel, setSelectedLabel] = useState("");
    const [selectedRequired, setSelectedRequired] = useState(false);
    const [selectedReadOnly, setSelectedReadOnly] = useState(false);
    const [selectedHide, setSelectedHide] = useState(false);
    const [selectedSize, setSelectedSize] = useState(1);
    const [selectedItemList, setSelectedItemList] = useState("");
    const [selectedMaxLength, setSelectedMaxLength] = useState(maxLength);
    const [selectedNumber, setSelectedNumber] = useState(0);
    const [pattern, setPattern] = useState("0");
    const [adminOnly, setAdminOnly] = useState(false);
    const [selectedErrorMessage, setSelectedErrorMessage] =
        useState(errorMessage);
    const [selectedParagraphText, setSelectedParagraphText] = useState("");

    const [selectedDefaultValue, setSelectedDefaultValue] = useState("");
    const [selectedFormula, setSelectedFormula] = useState("");
    // Currency default values
    const defaultCurrencyValue = 0.00
    const defaultCurrency = "USD"
    const defaultCulture = "en-US"
    const defaultCurrencySubstrate = "100,000.99"
    const [currency, setCurrency] = useState(defaultCurrency);
    const [currencyValue, setCurrencyValue] = useState<string | number | undefined>(defaultCurrencyValue);
    const [cultures, setCultures] = useState<IDropdownOption[]>([])
    const [currencySubstrate, setCurrencySubstrate] = useState(defaultCurrencySubstrate)
    const [decimalSeparator, setDecimalSeparator] = useState<string | undefined>(undefined)
    const [groupSeparator, setGroupSeparator] = useState<string | undefined>(undefined)
    // const [cultures, setCultures] = useState<IAsyncSelect[]>([])
    //const [cultures, setCultures] = useState<IComboBoxOption[]>([])
    const [culture, setCulture] = useState<string | undefined>(defaultCulture)
    const [selectedColumn, setSelectedColumn] = useState("");
    const [selectedColumnType, setSelectedColumnType] = useState<InputType>();
    const [selectedColumnTextType, setSelectedColumnTextType] =
        useState<TextBoxType>();
    const [selectedColumnLabel, setSelectedColumnLabel] = useState("");
    const [selectedPlaceholder, setSelectedPlaceholder] = useState("");
    const [selectedTooltip, setSelectedTooltip] = useState("");
    const [tooltipUrlError, setTooltipUrlError] = useState("");
    const [selectedTooltipUrl, setSelectedTooltipUrl] = useState("");
    const [defaultDate, setDefaultDate] = useState<Date>();
    const [selectedStaticList, setSelectedStaticList] = useState(true);
    const [selectedStaticGridList, setSelectedStaticGridList] = useState(true);
    const [selectedMask, setSelectedMask] = useState("");
    const [color, setColor] = useState(emptyColor);
    const [colorBackground, setColorBackground] = useState(emptyBackgroundColor);
    const [fontColor, setFontColor] = useState("#000000");
    const [backGroundColor, setBackGroundColor] = useState("#FFFFFF");
    const [align, setAlign] = useState("0");
    const [fontSize, setFontSize] = useState("0");
    const [requiredMask, setRequiredMask] = useState(true);
    const [selectedTextboxType, setSelectedTextBoxType] =
        useState<TextBoxType>();
    const [selectedTodaysDate, setSelectedTodaysDate] = useState(false);

    const [selectedRegex, setSelectedRegex] = useState("");
    const { t } = useTranslation("wizard");
    const { control, setValue, register, getValues, trigger } = useForm<
        any,
        any
    >({
        defaultValues: {},
        reValidateMode: "onSubmit",
        mode: "all",
    });
    const [multiline, { toggle: toggleMultiline }] = useBoolean(false);

    const getProperties = useCallback((targetId: string) => {
        // const t = ev?.currentTarget;
        // let id = targetId?.id;
        if (targetId.length === 0) {
            return
        }
        let id = targetId
        let ccId = id.slice(1);
        setCCId(ccId);

        // let previousItem = document.getElementById(
        //     "I" + sessionStorage.getItem("selected")
        // );
        // if (previousItem != null) previousItem!.className = "divGridItem";

        //let itemSelected = document.getElementById("I" + ccId);
        //itemSelected!.className = "divGridItemSelected";

        let item = layoutItems!.filter((x) => x.Id === ccId);
        let aux = item[0];

        if (
            aux.Type === InputType.LineBreak ||
            aux.Type === InputType.SectionHeader ||
            aux.Type === InputType.GridList ||
            aux.Type === InputType.Paragraph
        )
            setIsLineBreak(true);
        else setIsLineBreak(false);

        if (aux.Type === InputType.GridList) {
            setSelectedColumn("");
            setSelectedLabel("");
            setSelectedType(undefined);
            setSelectedStaticList(true);
            setSelectedItemList("");
            setSelectedList(null);
            setSelectedColumnType(undefined);
            setSelectedColumnLabel("");
            setSelectedColumnReadOnly(false);
            setSelectedColumnRequired(false);

            setIsGridList(true);
            let ddo: IDropdownOption[] = [];
            aux.Children.forEach((el) => {
                ddo.push({
                    key: el.Id,
                    text: el.Id,
                });
            });
            setColumns(ddo);
        } else {
            setIsGridList(false);
            setColumns([]);
        }

        setSelectedLabel(aux.Label);
        setSelectedMaxLength(aux.Validations.MaxLength);
        
        setSelectedSize(item[0].Layout.w);
        setSelectedItemList(aux.ItemList);
        setShowScrollbar(
            aux.ShowScrollbar === null || aux.ShowScrollbar === undefined
                ? true
                : aux.ShowScrollbar
        );

        setFontColor(
            aux.FontColor === null || aux.FontColor === ""
                ? "#000"
                : aux.FontColor
        );
        let auxColor: Color =
            aux.FontColor === null || aux.FontColor === ""
                ? "#000"
                : aux.FontColor;
        setColor(auxColor);

        if (aux.Validations.Mask.length > 0) {
            setSelectedMask(aux.Validations.Mask);
            setPattern("1");
        } else if (aux.Validations.Regex.length > 0) {
            setSelectedRegex(aux.Validations.Regex);
            setSelectedErrorMessage(aux.Validations.ErrorMessage);
            setPattern("2");
        } else {
            setPattern("0");
        }

        if (aux.Align === "left") setAlign("0");
        else if (aux.Align === "center") setAlign("1");
        else setAlign("2");
        
        switch (aux.FontSize) {
            case 28:
                setFontSize("2")
                break
            case 23:
                setFontSize("1")
                break
            default:
                setFontSize("0")
                break
        }

        if (aux.IsBold == null || aux.IsBold === undefined) {
            setIsBold(false);
        } else {
            setIsBold(aux.IsBold);
        }

        if (aux.IsItalic == null || aux.IsItalic === undefined) {
            setIsItalic(false);
        } else {
            setIsItalic(aux.IsItalic);
        }

        if (aux.IsUnderlined == null || aux.IsUnderlined === undefined) {
            setIsUnderlined(false);
        } else {
            setIsUnderlined(aux.IsUnderlined);
        }
        
        // Region Font Content Controls
        if ([
            InputType.Textbox,
            InputType.TextArea,
            InputType.RichText,
            InputType.DropDownList,
            InputType.CheckBox,
            InputType.CheckBoxList,
            InputType.Number,
            InputType.Currency,
            InputType.DatePicker,
            InputType.Image,
            InputType.Paragraph
        ].includes(aux.Type)) {
            switch (aux.FontSize) {
                case 22:
                    setFontSize("2")
                    break
                case 18:
                    setFontSize("1")
                    break
                default:
                    setFontSize("0")
                    break
            }
        }
        // end region        

        // region Background get properties
        setShowStyles(false)
        setDelayShowStyles(false)
        if ([
            InputType.Textbox,
            InputType.TextArea,
            InputType.RichText,
            InputType.DropDownList,
            InputType.CheckBox,
            InputType.CheckBoxList,            
            InputType.Number,
            InputType.Currency,
            InputType.Paragraph,
            InputType.DatePicker,
            InputType.Image,
            InputType.SectionHeader
        ].includes(aux.Type)) {
            if (aux.BackgroundColor !== undefined && aux.BackgroundColor !== null && aux.BackgroundColor.trim().length > 0) {
                setBackGroundColor(aux.BackgroundColor)

                let auxColor: Color = aux.BackgroundColor
                setColorBackground(auxColor)
            } else {
                setBackGroundColor(emptyBackgroundColor.toString())
                setColorBackground(emptyBackgroundColor)
            }
        }
        else {
            setBackGroundColor(emptyBackgroundColor.toString())
            setColorBackground(emptyBackgroundColor)
        }
        // endregion


        setSelectedRequired(aux.Validations.Required);
        setSelectedReadOnly(
            aux.Validations.ReadOnly ? aux.Validations.ReadOnly : false
        );

        setSelectedHide(
            aux.Validations.Hide !== undefined && aux.Validations.Hide === true
        )
        
        setSelectedParagraphText(aux.TextParagraph);
        setLockItem(item[0].Layout.static!);

        if (aux.Type === InputType.DatePicker) {
            setSelectedTodaysDate(
                aux.Validations.TodaysDate ? aux.Validations.TodaysDate : false
            );
            if (
                aux.Validations.Regex.length > 0 &&
                aux.Validations.Regex !== "LL"
            ) {
                setSelectedRegex(aux.Validations.Regex);
            } else {
                setSelectedRegex(DEFAULT_FORM_FORMAT);
            }

            if (
                aux.Validations.DefaultValue !== null &&
                aux.Validations.DefaultValue !== undefined &&
                aux.Validations.DefaultValue !== "" &&
                !aux.Validations.TodaysDate
            ) {
                const tempDate = getDateFromFormat(
                    aux.Validations.DefaultValue,
                    aux.Validations.Regex
                );
                if (tempDate !== "") {
                    let d = new Date(tempDate);
                    setDefaultDate(d);
                } else {
                    setDefaultDate(undefined);
                }
            } else {
                setDefaultDate(undefined);
            }

            // if (aux.Validations.DefaultValue !== null && aux.Validations.DefaultValue !== undefined && aux.Validations.DefaultValue !== "" && !aux.Validations.TodaysDate) {
            //     var d = new Date(_moment(aux.Validations.DefaultValue, aux.Validations.Regex).local().locale("en-US").toString())
            //     setDefaultDate(d)
            // }
            // else {
            //     setDefaultDate(undefined)
            // }
        } else {
            setSelectedDefaultValue(aux.Validations.DefaultValue);
            setDefaultDate(undefined);
        }

        if (aux.Type === InputType.Number) {
            let parsedValue = parseInt(aux.Validations.DefaultValue!);
            if (isNaN(parsedValue)) {
                setSelectedNumber(0);
                setSelectedDefaultValue("0");
            } else {
                setSelectedNumber(parsedValue);
                setSelectedDefaultValue(aux.Validations.DefaultValue!);
            }
        }
       
        /**
         * GetProperties currency field
         * Culture
         * Currency
         * Length
         * Default value
         */
        // START Section                
        if (aux.Type === InputType.Currency) {

            setCulture(defaultCulture)
            setCurrency(defaultCurrency)
            setCurrencyValue(defaultCurrencyValue)
            setCurrencySubstrate(defaultCurrencySubstrate)
            setDecimalSeparator(undefined)
            setGroupSeparator(undefined)

            if(aux.Validations.Culture !== null
                && aux.Validations.Culture !== undefined &&
                aux.Validations.Culture.length > 0 ) {
                // setCulture(aux.Validations.Culture)
            }

            if(aux.Validations.Currency !== null
                && aux.Validations.Currency !== undefined
                && aux.Validations.Currency.length > 0 ) {
                setCurrency(aux.Validations.Currency)

                const result = currencyOptions().filter((item) => item.key === aux.Validations.Currency);
                if(Array.isArray(result)) {
                    setCurrencySubstrate(result[0].data.CurrencySubstrate)
                    setDecimalSeparator(result[0].data.decimalSeparator)
                    setGroupSeparator(result[0].data.groupSeparator)
                }
                else {
                    setCurrencySubstrate(defaultCurrencySubstrate)
                }

            }

            if (!isNaN(parseInt(aux.Validations.DefaultValue!))) {
                setCurrencyValue(aux.Validations.DefaultValue!)
            }
        }
        // END Section


        setAdminOnly(aux.AdminOnly);
        setSelectedPlaceholder(aux.Placeholder);
        setSelectedTooltip(aux.Tooltip);
        setSelectedTooltipUrl(aux.TooltipUrl);
        if (aux.ListId !== 0) {
            setSelectedStaticList(false);
            setSelectedStaticGridList(false);
        } else {
            setSelectedStaticList(true);
            setSelectedStaticGridList(true);
        }
        let selectedType = InputsList!.filter((x) => x.key === aux.Type);
        if (selectedType[0].key === 0) setSelectedType(InputType.Textbox);
        else setSelectedType(selectedType[0].key as InputType);

        if (aux.ListId !== undefined && aux.ListId !== 0) {
            setSelectedListId(aux.ListId.toString());
        } else {
            setSelectedListId("");
            setSelectedList(null);
        }

        // Integration
        if (
            aux.Integration !== undefined &&
            aux.Integration !== null &&
            aux.Integration.Id !== 0
        ) {
            events.forEach((event) => {
                const _integration = event.Integrations.find(integration=>integration.Id === aux.Integration?.Id)
                if(_integration !== undefined) {
                    setSelectedIntegrationId(_integration.Id.toString())
                    setSelectedIntegration({value: _integration.Id.toString(), label: _integration.Name  } as IMyListOption)
                    return
                }
            })            
        } else {
            setSelectedIntegrationId("");
            setSelectedIntegration(null);
        }
        
        
        // Image
        if (aux.Type === InputType.Image) {
            myForm.clearErrors()
            myForm.reset()
            myForm.setValue('ImageDescription', aux.TextParagraph)
            
            if(aux.Validations.DefaultValue === undefined || aux.Validations.DefaultValue === null || aux.Validations.DefaultValue.trim().length === 0) {
               setSelectedDefaultValue("")
            }
            else {
                setSelectedDefaultValue(aux.Validations.DefaultValue)
            }
        }
        //

        setSelectedCC(ccId);
        sessionStorage.setItem("selected", ccId);
        //setIsOpen(true);
    }, [isOpen])

    useEffect(() => {

        if (isOpen !== null)
            getProperties(isOpen)
    }, [isOpen])

    const onTypeDropdownChange = (
        event: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption,
        index?: number
    ): void => {
        setSelectedType(item?.key as InputType);
        setIsModifiedP(true);
        setSelectedMaxLength(150);
        setSelectedMask("");
        setSelectedNumber(0);
        setSelectedRequired(false);
        setSelectedReadOnly(false)
        setSelectedHide(false)
        setSelectedDefaultValue("");
        setSelectedFormula("");
        setSelectedErrorMessage("");
        /**
         * onTypeDropdownChange
         * Currency field
         * Culture
         * Currency
         * Length
         * Default value
         */
        // START Section
        setCulture(defaultCulture)
        setCurrency(defaultCurrency)
        setCurrencyValue(defaultCurrencyValue)
        setCurrencySubstrate(defaultCurrencySubstrate)
        // END Section
        if ((item?.key as InputType) === InputType.DatePicker) {
            setSelectedRegex(DEFAULT_FORM_FORMAT);
            setSelectedTodaysDate(false);
            setDefaultDate(undefined);
        } else {
            setSelectedRegex("");
            setSelectedTodaysDate(false);
            setDefaultDate(undefined);
        }

        if ((item?.key as InputType) === InputType.Image) {
            // setImagePreview(undefined)
            // setLoadingImagePreview(false)
        }

        };
    const onRenderLabelHelp = (props: any) => (
        <CustomLabel id={labelId} {...props} />
    );

    const onChangeIsBold = (
        ev?: FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void => {
        setIsBold(checked!);
    };
    const onChangeIsItalic = (
        ev?: FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void => {
        setIsItalic(checked!);
    };
    async function loadList(listId: string) {

        let list: IDropdownOption[] = [];

        const request: IGetListsDetailsRequest = {
            DocumentOnlyListMasterId: listId
        }

        await ListService.GetListsDetails(request)
            .then((data) => {
                data.forEach((e: any) => {
                    list.push({
                        key: e.Key,
                        text: e.Value,
                    })
                })               
            })
            .catch((error) => {
            })
            .finally(() => {

            })
        return list
    }

    function loadEachList(listId: string) {
        let l: IDropdownOption[] = [];
        loadList(listId).then((x) => {
            x.forEach((e) => {
                l.push(e);
            });
        });
        return l;
    }
    function onChangeBlock(
        ev: React.MouseEvent<HTMLElement>,
        checked?: boolean
    ) {
        setLockItem(checked!);
    }
    function onChangeAdminOnly(
        ev: React.MouseEvent<HTMLElement>,
        checked?: boolean
    ) {
        setAdminOnly(checked!);
    }
    const onChangeIsUnderlined = (
        ev?: FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void => {
        setIsUnderlined(checked!);
    };
    const onIntegrationDropdownChange = (
        newValue: SingleValue<IMyListOption>,
        actionMeta: ActionMeta<IMyListOption>
    ): void => {
        setSelectedIntegration(newValue);
        setSelectedIntegrationId(newValue !== null ? newValue.value : "");
        setIsModifiedP(true);
    };
    const sliderOnChange = (value: number) => {
        setSelectedSize(value);
        setIsModifiedP(true);
    };


    const onColumnTypeDropdownChange = (
        event: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption
    ): void => {
        setSelectedColumnType(item?.key as InputType);
        setIsModifiedP(true);
        setSelectedStaticGridList(true);
        setSelectedItemGridList("");
        setSelectedDropDownGridList(null);
        setSelectedFormula("");
        setSelectedListId("");
        setSelectedIntegrationId("");
        if ((item?.key as InputType) === InputType.DatePicker) {
            setSelectedRegex(DEFAULT_FORM_FORMAT);
            setSelectedTodaysDate(false);
            setDefaultDate(undefined);
        } else {
            setSelectedRegex("");
            setSelectedTodaysDate(false);
            setDefaultDate(undefined);
        }
    };
    const onColumnDropdownChange = (
        event: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption
    ): void => {
        setSelectedColumn(item!.key.toString());
        setIsModifiedP(true);

        let childList = layoutItems!.filter((x) => x.Id === selectedCC);

        if (childList != null && childList.length > 0) {
            if (childList[0].Children.length > 0) {
                let auxChild = childList[0].Children!.filter(
                    (x) => x.Id === item?.key
                );

                setSelectedColumnLabel(auxChild[0].Label);
                setSelectedRequired(
                    auxChild[0].Validations.Required
                        ? auxChild[0].Validations.Required
                        : false
                );
                setSelectedReadOnly(
                    auxChild[0].Validations.ReadOnly
                        ? auxChild[0].Validations.ReadOnly
                        : false
                )

                setSelectedHide(
                    auxChild[0].Validations.Hide !== undefined && auxChild[0].Validations.Hide
                )
                
                
                setSelectedRegex("");
                setDefaultDate(undefined);
                if (auxChild[0].Type === InputType.DatePicker) {
                    auxChild[0].Validations.Regex =
                        datePickerFormats.find(
                            (f) => f.key === auxChild[0].Validations.Regex
                        ) !== undefined
                            ? auxChild[0].Validations.Regex
                            : DEFAULT_FORM_FORMAT;
                    setSelectedRegex(auxChild[0].Validations.Regex);
                    setSelectedTodaysDate(
                        auxChild[0].Validations.TodaysDate !== undefined
                            ? auxChild[0].Validations.TodaysDate
                            : false
                    );

                    if (
                        auxChild[0].Validations.DefaultValue !== null &&
                        auxChild[0].Validations.DefaultValue !== undefined &&
                        auxChild[0].Validations.DefaultValue !== "" &&
                        !auxChild[0].Validations.TodaysDate
                    ) {
                        let tempDate = getDateFromFormat(
                            auxChild[0].Validations.DefaultValue,
                            auxChild[0].Validations.Regex
                        );
                        if (tempDate !== "") {
                            let d = new Date(tempDate);
                            setDefaultDate(d);
                        } else {
                            setDefaultDate(undefined);
                        }
                    } else {
                        setDefaultDate(undefined);
                    }
                }

                if (auxChild[0].Type === InputType.Textbox) {
                    if (auxChild[0].Validations.Mask.length > 0) {
                        setSelectedMask(auxChild[0].Validations.Mask);
                        setPattern("1");
                    } else if (auxChild[0].Validations.Regex.length > 0) {
                        setSelectedRegex(auxChild[0].Validations.Regex);
                        setSelectedErrorMessage(
                            auxChild[0].Validations.ErrorMessage
                        );
                        setPattern("2");
                    } else {
                        setPattern("0");
                    }
                }

                if (auxChild[0].Type === InputType.DropDownList) {
                    setSelectedDefaultValue(
                        auxChild[0].Validations.DefaultValue!
                    );
                }

                if (auxChild[0].Type === InputType.Number) {
                    setSelectedFormula(auxChild[0].Validations.Formula!);
                    let parsedValue = parseInt(
                        auxChild[0].Validations.DefaultValue!
                    );
                    if (isNaN(parsedValue)) {
                        setSelectedNumber(0);
                        setSelectedDefaultValue("0");
                    } else {
                        setSelectedNumber(parsedValue);
                        setSelectedDefaultValue(
                            auxChild[0].Validations.DefaultValue!
                        );
                    }
                }

                /**
                 * GetProperties currency field / grid
                 * Culture
                 * Currency
                 * Length
                 * Default value
                 */
                // START Section
                if (auxChild[0].Type === InputType.Currency) {

                    setCulture(defaultCulture)
                    setCurrency(defaultCurrency)
                    setCurrencyValue(defaultCurrencyValue)
                    setSelectedFormula("")
                    setSelectedDefaultValue("0");
                    setCurrencySubstrate(defaultCurrencySubstrate)

                    if(auxChild[0].Validations.Culture !== null
                        && auxChild[0].Validations.Culture !== undefined &&
                        auxChild[0].Validations.Culture.length > 0 ) {
                        // setCulture(auxChild[0].Validations.Culture)
                    }

                    if(auxChild[0].Validations.Currency !== null
                        && auxChild[0].Validations.Currency !== undefined
                        && auxChild[0].Validations.Currency.length > 0 ) {
                        setCurrency(auxChild[0].Validations.Currency)

                        if(auxChild[0].Validations.CurrencySubstrate !== null
                            && auxChild[0].Validations.CurrencySubstrate !== undefined &&
                            auxChild[0].Validations.CurrencySubstrate.length > 0 ) {
                            setCurrencySubstrate(auxChild[0].Validations.CurrencySubstrate)
                        }
                    }

                    if(auxChild[0].Validations.Formula !== null
                        && auxChild[0].Validations.Formula !== undefined
                        && auxChild[0].Validations.Formula.length > 0 ) {
                        setSelectedFormula(auxChild[0].Validations.Formula)
                    }

                    let parsedValue = parseInt(
                        auxChild[0].Validations.DefaultValue!
                    );
                    if (!isNaN(parsedValue)) {
                        setSelectedDefaultValue(auxChild[0].Validations.DefaultValue!)
                        setCurrencyValue(auxChild[0].Validations.DefaultValue!)
                    }
                }
                // END Section

                if (auxChild[0].Type === InputType.Textbox) {
                    setSelectedDefaultValue(
                        auxChild[0].Validations.DefaultValue!
                    );
                }

                let selectedType = InputsList!.filter(
                    (x) => x.key === auxChild[0].Type
                );
                setSelectedColumnType(selectedType[0].key as InputType);

                if (auxChild[0].Type === InputType.DropDownList) {
                    if (auxChild[0].ListId > 0) {
                        setSelectedStaticGridList(false);
                        setSelectedItemGridList("");
                        setSelectedListId(auxChild[0].ListId.toString());
                    } else {
                        setSelectedStaticGridList(true);
                        setSelectedItemGridList(auxChild[0].ItemList);
                        setSelectedDropDownGridList(null);
                        setSelectedListId("");
                    }
                }
            }
        }
    };

    const onChangeColumnLabel = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedColumnLabel(newValue!);
            setIsModifiedP(true);
        }
    );

    const onChangeLabel = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedLabel(newValue!);
            setIsModifiedP(true);
        }
    );
    const onChangeDateRegex = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedRegex(newValue!);
            setIsModifiedP(true);
        }
    );
    const onChangeMask = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedMask(newValue!);
            setIsModifiedP(true);
            if (newValue!.length > 0) {
                if (
                    !newValue!.includes("L") &&
                    !newValue!.includes("s") &&
                    !newValue!.includes("9")
                ) {
                    setRequiredMask(false);
                } else setRequiredMask(true);
            } else setRequiredMask(true);
        }
    );
    const onChangeDefaultValue = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedDefaultValue(newValue!);
            setIsModifiedP(true);
        }
    );

    const onChangeFormula = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            if (newValue) {
                const result = newValue!.replace(
                    /[^a-z0-9\[\]\s\(\)\+\_\-\*\/\$\.\%\,]/gi,
                    ""
                );
                setSelectedFormula(result);
            } else {
                setSelectedFormula("");
            }
            setIsModifiedP(true);
        }
    );

    const onChangeRegex = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedRegex(newValue!);
            setIsModifiedP(true);
        }
    );

    const onChangeDateFormat = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ) => {
        setSelectedRegex(option!.key.toString());
        setIsModifiedP(true);
    };

    const onChangeErrorMessage = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedErrorMessage(newValue!);
            setIsModifiedP(true);
        }
    );
    const onChangeItemList = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedItemList(newValue!);
            setIsModifiedP(true);
        }
    );

    const onChangeNumber = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            if (newValue !== "") {
                let parsedValue = parseInt(newValue!);
                if (isNaN(parsedValue)) {
                    setSelectedNumber(0);
                    setSelectedDefaultValue("0");
                } else {
                    setSelectedNumber(parsedValue);
                    setSelectedDefaultValue(newValue!);
                }
            } else {
                setSelectedNumber(0);
                setSelectedDefaultValue("0");
            }
            setIsModifiedP(true);
        }
    );

    const onChangeMaxLenght = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            if (newValue !== "") {
                let parsedValue = parseInt(newValue!);
                if (isNaN(parsedValue)) setSelectedMaxLength(0);
                else setSelectedMaxLength(parsedValue);
            } else {
                setSelectedMaxLength(0);
            }
            setIsModifiedP(true);
        }
    );

    const onChangeTextParagraph = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedParagraphText(newValue!);
            setIsModifiedP(true);
        }
    );
    function onChangeRequired(
        ev?: React.FormEvent<HTMLElement>,
        isChecked?: boolean
    ) {
        if (isChecked) setSelectedReadOnly(false);
        setSelectedRequired(isChecked!);
        setIsModifiedP(true);
    }

    function onChangeReadOnly(
        ev?: React.FormEvent<HTMLElement>,
        isChecked?: boolean
    ) {
        if (isChecked) setSelectedRequired(false);
        setSelectedReadOnly(isChecked!);
        setIsModifiedP(true);
    }

    function onChangeShow(
        ev?: React.FormEvent<HTMLElement>,
        isChecked?: boolean
    ) {
        if (isChecked) setSelectedHide(true)
        setSelectedHide(isChecked!)
        setIsModifiedP(true)
    }

    function onChangeTodaysDate(
        ev?: React.FormEvent<HTMLElement>,
        isChecked?: boolean
    ) {
        setSelectedTodaysDate(isChecked!);
        setDefaultDate(undefined);
        setIsModifiedP(true);
    }

    function onChangeStaticList(
        ev?: React.FormEvent<HTMLElement>,
        isChecked?: boolean
    ) {
        setSelectedStaticList(isChecked!);
        setSelectedList(null);
        setSelectedItemList("");
        setIsModifiedP(true);
    }
    const onMapDropDownGridList = (): void => {
        let id = selectedCC;
        auxItems = layoutItems!;
        let item = layoutItems!.filter((x) => x.Id === id);
    };


    const getIntegrationList = (eventsList: EventModel[]): IMyListOption[] => {
        return eventsList
            .reduce((acc: OutboundIntegrationModel[], value: EventModel) => {
                acc = acc.concat(value.Integrations);
                return acc;
            }, [])
            .reduce((acc: IMyListOption[], value: OutboundIntegrationModel) => {
                if (value.Active && value.ObjectApprovedStatus) {
                    acc.push({
                        value: value.Id.toString(),
                        label: value.Description,
                    });
                }
                return acc;
            }, []);
    };

    function onChangeStaticGridList(
        ev?: React.FormEvent<HTMLElement>,
        isChecked?: boolean
    ) {
        setSelectedStaticGridList(isChecked!);
        setSelectedItemGridList("");
        setSelectedDropDownGridList(null);
        setIsModifiedP(true);
    }
    const onListDropdownChange = (
        newValue: SingleValue<IMyListOption>,
        actionMeta: ActionMeta<IMyListOption>
    ): void => {
        setSelectedList(newValue);
        setSelectedListId(newValue !== null ? newValue.value : "");
        setIsModifiedP(true);
    };
    const handleChange = (color: any) => {
        setColor(color);
        setFontColor(color.hex.toUpperCase());
    };

    const handleBackgroundChange = (color: any) => {
        setColorBackground(color);
        setBackGroundColor(color.hex.toUpperCase());
    };
    
    function _onChangeAlign(
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ): void {
        setAlign(option?.key!);
    }
    function _onChangeFontSize(
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ): void {
        setFontSize(option?.key!);
    }

    const onListDropdownChangeGridList = (
        newValue: SingleValue<IMyListOption>,
        actionMeta: ActionMeta<IMyListOption>
    ): void => {
        setSelectedDropDownGridList(newValue);
        setSelectedListId(newValue !== null ? newValue.value : "");
        setIsModifiedP(true);
    };

    const onChangeItemGridList = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedItemGridList(newValue!);
            setIsModifiedP(true);
            onMapDropDownGridList();
        }
    );


    const onChangeTooltip = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedTooltip(newValue!);
            setIsModifiedP(true);
        }
    );
    const onChangePlaceholder = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            setSelectedPlaceholder(newValue!);
            setIsModifiedP(true);
        }
    );
    const onFormatDate = (date?: Date) => {
        if (selectedTodaysDate) {
            return undefined;
        } else {
            let format =
                selectedRegex !== undefined && selectedRegex !== ""
                    ? selectedRegex
                    : DEFAULT_FORM_FORMAT;
            return date !== null
                ? formatSimpleDate(date, format, "en-US")
                : null;
        }
    };
    const onChangeTooltipUrl = useConstCallback(
        (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
        ) => {
            let url;
            try {
                url = new URL(newValue!);
                let isUrlValid =
                    url?.protocol === "http:" || url?.protocol === "https:";
                if (isUrlValid) {
                    setSelectedTooltipUrl(newValue!);
                    setIsModifiedP(true);
                    setTooltipUrlError("");
                } else {
                    newValue !== ""
                        ? setTooltipUrlError(
                            "Invalid Url. E.g.(https://kimdocument.com/)"
                        )
                        : setTooltipUrlError("");
                    setSelectedTooltipUrl(newValue!);
                }
            } catch (_) {
                newValue !== ""
                    ? setTooltipUrlError(
                        "Invalid Url. E.g.(https://kimdocument.com/)"
                    )
                    : setTooltipUrlError("");
                setSelectedTooltipUrl(newValue!);
            }
        }
    );
    function _onChangePattern(
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ): void {
        setPattern(option?.key!);
        if (option?.key! === "0" || option?.key! === "2") {
            setRequiredMask(true);
        } else {
            if (
                !selectedMask.includes("L") &&
                !selectedMask.includes("s") &&
                !selectedMask.includes("9")
            ) {
                setRequiredMask(false);
            } else setRequiredMask(true);
        }
    }

    const getErrorMessage = (value: string): string => {
        if (value.length > 0) {
            if (
                !value.includes("L") &&
                !value.includes("s") &&
                !value.includes("9")
            ) {
                setRequiredMask(false);
                return "Mask must include a valid character. (L-alphabetical, 9-digit, s-special characters) E.g. s(99.99)";
            }
        } else setRequiredMask(true);
        return "";
    };

    async function applyChanges() {
        setApplying(true);
        setUnexpectedError(false);
        setIsModified(true);
        setIsModifiedP(false);

        let id = selectedCC;
        auxItems = layoutItems!;

        let item = layoutItems!.filter((x) => x.Id === id);
        let index = layoutItems!.indexOf(item[0]);

        /////////////////////////Common Properties
        item[0].Layout.h = 2;
        item[0].Layout.w = selectedSize;
        item[0].Layout.static = lockItem;

        ////////////////////////Only for Text Area
        if (selectedType === InputType.TextArea) {
            item[0].Layout.h = 4;
        }
        if (selectedType === InputType.GridList) {
            item[0].Layout.h = 6;
        }
        if (
            selectedType === InputType.SectionHeader ||
            selectedType === InputType.LineBreak
        ) {
            item[0].Layout.h = 1;
        }

        ////////////////////////Only for Rich Text
        if (selectedType === InputType.RichText) {
            item[0].Layout.h = 6;
            let tempRichText = rteValue;
            tempRichText[item[0].Id] =
                selectedDefaultValue !== undefined &&
                    selectedDefaultValue !== null &&
                    selectedDefaultValue !== ""
                    ? EditorValue.createFromString(selectedDefaultValue, "html")
                    : EditorValue.createEmpty();
            setRteValue(tempRichText);
        }

        let aux = item[0];
        aux.Type = selectedType as InputType;
        aux.Label = selectedLabel;

        if (selectedType !== InputType.GridList) {
            aux.Validations.MaxLength = selectedMaxLength;
            aux.Validations.Required = selectedRequired;
            aux.Validations.ReadOnly = selectedReadOnly;
            aux.Validations.Hide = selectedHide;
            aux.Validations.Formula = "";

            if (pattern === "0") {
                aux.Validations.Mask = "";
                aux.Validations.Regex = "";
                aux.Validations.ErrorMessage = "";
            } else if (pattern === "1") {
                aux.Validations.Mask = selectedMask;
                aux.Validations.Regex = "";
                aux.Validations.ErrorMessage = "";
            } else if (pattern === "2") {
                aux.Validations.Regex = selectedRegex;
                aux.Validations.Mask = "";
                aux.Validations.ErrorMessage = selectedErrorMessage;
            }
        } else {
            aux.Validations.Required = false;
            aux.Validations.ReadOnly = false;
            aux.Validations.Hide = selectedHide;
        }

        ////////////////////////Only for DatePicker
        if (aux.Type === InputType.DatePicker) {
            aux.Validations.TodaysDate = selectedTodaysDate;

            aux.Validations.Regex =
                datePickerFormats.find((f) => f.key === selectedRegex) !==
                    undefined
                    ? selectedRegex
                    : DEFAULT_FORM_FORMAT;
            if (defaultDate !== undefined) {
                aux.Validations.DefaultValue = getISODateString(defaultDate);
            } else {
                aux.Validations.DefaultValue = "";
            }
        } else {
            aux.Validations.DefaultValue = selectedDefaultValue;
        }

        if (
            selectedType === InputType.GridList &&
            selectedColumnType === InputType.DropDownList
        ) {
            let itemList = aux.Children.filter((i) => i.Id === selectedColumn);

            if (itemList !== undefined && itemList.length > 0) {
                if (selectedStaticGridList) {
                    if (selectedItemGridList && selectedItemGridList !== "") {
                        itemList[0].ItemList = selectedItemGridList;
                        itemList[0].ListId = 0;
                    }
                } else {
                    if (
                        selectedDropDownGridList?.value !== undefined &&
                        selectedDropDownGridList.value !== null &&
                        selectedDropDownGridList.value !== "" &&
                        selectedDropDownGridList.value !== "0"
                    ) {
                        itemList[0].ListId = parseInt(
                            selectedDropDownGridList.value
                        );
                        itemList[0].ItemList = "";
                    } else {
                        itemList[0].ListId = 0;
                    }
                }
            }
        }

        aux.ItemList = selectedItemList;
        aux.TextBoxType = selectedTextboxType as TextBoxType;
        aux.TextParagraph = selectedParagraphText;
        aux.AdminOnly = adminOnly;
        aux.Placeholder = selectedPlaceholder;
        aux.Tooltip = selectedTooltip;
        aux.TooltipUrl = selectedTooltipUrl;
        aux.FontColor = fontColor;
        aux.ShowScrollbar = showScrollbar;
        aux.IsBold = isBold;
        aux.IsItalic = isItalic;
        aux.IsUnderlined = isUnderlined;

        if (align === "0") aux.Align = "left";
        else if (align === "1") aux.Align = "center";
        else aux.Align = "right";

        switch (fontSize) {
            case "0":
                aux.FontSize = 18
                break
            case "1":
                aux.FontSize = 23
                break
            default:
                aux.FontSize = 28
                break
        }
        // region Font - Apply changes

        if ([
            InputType.Textbox,
            InputType.TextArea,
            InputType.RichText,
            InputType.DropDownList,
            InputType.CheckBox,
            InputType.CheckBoxList,
            InputType.Number,
            InputType.Currency,
            InputType.DatePicker,
            InputType.Image,
            InputType.Paragraph
        ].includes(aux.Type)) {
            switch (fontSize) {
                case "0":
                    aux.FontSize = 14
                    break
                case "1":
                    aux.FontSize = 18
                    break
                default:
                    aux.FontSize = 22
                    break
            }
        }
        // endregion
        
        
        // region BackGround Color - Apply changes
        if ([
            InputType.Textbox,
            InputType.TextArea,
            InputType.RichText,
            InputType.DropDownList,
            InputType.CheckBox,
            InputType.CheckBoxList,
            InputType.Number,
            InputType.Currency,
            InputType.DatePicker,
            InputType.Paragraph,
            InputType.Image,
            InputType.SectionHeader
        ].includes(selectedType as InputType)) {
            aux.BackgroundColor = backGroundColor;
        }
        // endregion        
       
        ////////////////////////Lists
        if (!selectedStaticList) {
            if (
                selectedList?.value !== undefined &&
                selectedList != null &&
                selectedList.value !== "" &&
                selectedList.value !== "0"
            )
                aux.ListId = parseInt(selectedList.value);
            else aux.ListId = 0;
        } else aux.ListId = 0;

        /////////////////////////Integrations
        if (
            selectedIntegration?.value !== undefined &&
            selectedIntegration != null &&
            selectedIntegration.value !== "" &&
            selectedIntegration.value !== "0"
        )
            aux.Integration = {
                EventId: events.find(event=>event.Integrations.find(integration=>integration.Id === parseInt(selectedIntegration.value)))?.Id ?? -1,
                Id: parseInt(selectedIntegration.value),
                DisplayType: DisplayType.Always,
            };
        else aux.Integration = undefined;
        
        /**
         * ApplyChanges
         * Currency field
         * Culture
         * Currency
         * Length
         * Default value
         */
        // START Section
        if (selectedType === InputType.Currency) {
            aux.Validations.Culture = culture
            aux.Validations.Currency = currency
            aux.Validations.DefaultValue = currencyValue!.toString()
            aux.Validations.CurrencySubstrate = currencySubstrate!.toString()
        }
        // End Section


        // region Apply image and inline help text
        if (selectedType === InputType.Image) {
            await myForm.handleSubmit(
                async (data) => {

                    item[0].Layout.h = 6;
                    const imageData = data[`ImageData_${id}`]
                    setSelectedParagraphText(data.ImageDescription)
                    aux.TextParagraph = data.ImageDescription

                    setSelectedDefaultValue(imageData)
                    aux.Validations.DefaultValue = imageData
                  
                    setItemProperties(aux)
                    return

                }
            )();
        }

        /////////////////////////Grid List Properties
        let child = aux.Children!.filter((x) => x.Id === selectedColumn);
        if (child != null && child.length > 0) {
            let indexChild = aux.Children!.indexOf(child[0]);
            child[0].Type = selectedColumnType as InputType;
            child[0].Label = selectedColumnLabel;
            child[0].Validations.Required = selectedRequired;
            child[0].Validations.ReadOnly = selectedReadOnly;
            child[0].Validations.Hide = selectedHide;
            child[0].Validations.Formula = selectedFormula;

            if (pattern === "0") {
                child[0].Validations.Mask = "";
                child[0].Validations.Regex = "";
                child[0].Validations.ErrorMessage = "";
            } else if (pattern === "1") {
                child[0].Validations.Mask = selectedMask;
                child[0].Validations.Regex = "";
                child[0].Validations.ErrorMessage = "";
            } else if (pattern === "2") {
                child[0].Validations.Regex = selectedRegex;
                child[0].Validations.Mask = "";
                child[0].Validations.ErrorMessage = selectedErrorMessage;
            }

            if (child[0].Type === InputType.DatePicker) {
                child[0].Validations.TodaysDate = selectedTodaysDate;

                child[0].Validations.Regex =
                    datePickerFormats.find((f) => f.key === selectedRegex) !==
                        undefined
                        ? selectedRegex
                        : DEFAULT_FORM_FORMAT;
                if (defaultDate !== undefined) {
                    let tempDateString = formatSimpleDate(
                        defaultDate,
                        child[0].Validations.Regex,
                        "en-US"
                    );
                    child[0].Validations.DefaultValue =
                        tempDateString.toString();
                } else {
                    child[0].Validations.DefaultValue = "";
                }
            } else {
                child[0].Validations.DefaultValue = selectedDefaultValue;
            }

            /**
             * ApplyChanges / Grid
             * Currency field
             * Culture
             * Currency
             * Length
             * Default value
             */
            if (child[0].Type === InputType.Currency) {
                child[0].Validations.Culture = culture
                child[0].Validations.Currency = currency
                child[0].Validations.DefaultValue = currencyValue!.toString()
                child[0].Validations.CurrencySubstrate = currencySubstrate!.toString()
            }

            child[0].TextBoxType = selectedColumnTextType as TextBoxType;
            aux.Children![indexChild] = child[0];
        }
        // Todo Version 3 = Format styles to Content Controls. Specifically, Font size 
        aux.Version = 3
        item[0] = aux;

        layoutItems![index] = item[0];
        saveLayout(layoutItems);
        setLayoutItems(layoutItems);

        setTimeout(function () {
            generateDOM(layoutItems);
        }, 500);
        preloadLists();
    }

    const cancelSavePModal = (option: number) => {
        switch (option) {
            case 1:
                setIsModifiedP(false);
                setShowSavePModal(false);
                applyChanges();
                break;
            case 2:
                setShowSavePModal(false);
                break;
            case 3:
                setShowSavePModal(false);
                setIsModifiedP(false);
                setIsOpen(null);
                setSelectedCC("");
                setLockItem(false);
                setSelectedList(null);
                setSelectedIntegration(null);
                setSelectedItemGridList("");
                setSelectedDropDownGridList(null);
                // let previousItem = document.getElementById(
                //     "I" + sessionStorage.getItem("selected")
                // );
                //if (previousItem != null)
                    //previousItem!.className = "divGridItem";
                break;
        }
    };
    /**
         * Rendering footer
         */
    const onRenderFooterContent = (selectedType: InputType | undefined) => {
        if (selectedType !== InputType.Attachment
            && selectedType !== InputType.HelpLink
            && selectedType !== InputType.Integration) {

            return (<PrimaryButton
                id="PropApply"
                disabled={
                    !validPattern ||
                    !requiredMask ||
                    tooltipUrlError.length > 0
                }
                styles={{ root: { width: "100%" } }}
                onClick={applyChanges}
                text="Apply"
                title="Apply all configuration for this control."
            />

            )
        } else {
            return null
        }
    }

    const toNormalForm = (str: string) => {
        return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    /**
     * Return General text styles
     * @returns {JSX.Element}
     */
    const textStyles = (selected: InputType): JSX.Element => {
        return (
            <Stack>
                {/* Text Align */}
                {[
                    InputType.Textbox,
                    InputType.TextArea,
                    InputType.RichText,
                    InputType.DropDownList,
                    InputType.CheckBox,
                    InputType.CheckBoxList,
                    InputType.Number,
                    InputType.Currency,
                    InputType.DatePicker,
                    InputType.Image,
                    InputType.SectionHeader
                ].includes(selectedType as InputType) && <>
                    <Label>Align</Label>
                    <ChoiceGroup
                        id="PropInlineGroupAlign"
                        style={{marginLeft: "-10px"}}
                        selectedKey={align}
                        options={optionsAlign}
                        className="inlineGroup"
                        onChange={_onChangeAlign}
                    />
                </>
                }
                {/* Font Size */}
                {[
                    InputType.Textbox,
                    InputType.TextArea,
                    InputType.RichText,
                    InputType.DropDownList,
                    InputType.CheckBox,
                    InputType.CheckBoxList,
                    InputType.Number,
                    InputType.Currency,
                    InputType.DatePicker,
                    InputType.Image,
                    InputType.SectionHeader,
                    InputType.Paragraph
                ].includes(selectedType as InputType) && <>
                    <Label></Label>
                    <Label>Font Size</Label>
                    <ChoiceGroup
                        id="PropInlineGroupFont"
                        style={{marginLeft: "-10px"}}
                        selectedKey={fontSize}
                        options={optionsFont}
                        className="inlineGroup"
                        onChange={_onChangeFontSize}
                    />
                </>
                }
                {/* Font Style */}
                {[
                    InputType.Textbox,
                    InputType.TextArea,
                    InputType.RichText,
                    InputType.DropDownList,
                    InputType.CheckBox,
                    InputType.CheckBoxList,
                    InputType.Number,
                    InputType.Currency,
                    InputType.DatePicker,
                    InputType.Image,
                    InputType.LineBreak,
                    InputType.SectionHeader,
                    InputType.Paragraph
                ].includes(selectedType as InputType) && <>
                    <Label></Label>
                    <Label>Font Style</Label>
                    <Stack wrap horizontal tokens={stackTokens}>
                        <Checkbox
                            label="Bold"
                            checked={isBold}
                            onChange={onChangeIsBold}
                        />
                        <Checkbox
                            label="Italic"
                            checked={isItalic}
                            onChange={onChangeIsItalic}
                        />
                        <Checkbox
                            label="Underlined"
                            checked={isUnderlined}
                            onChange={onChangeIsUnderlined}
                        />
                    </Stack>
                </>
                }
                {/* Preview format */}
                {[
                    InputType.Textbox,
                    InputType.TextArea,
                    InputType.RichText,
                    InputType.DropDownList,
                    InputType.CheckBox,
                    InputType.CheckBoxList,
                    InputType.Number,
                    InputType.Currency,
                    InputType.DatePicker,
                    InputType.Image,
                    InputType.LineBreak,
                    InputType.SectionHeader,
                    InputType.Paragraph
                ].includes(selectedType as InputType) && <>
                    <Label></Label>
                    <Stack styles={{root: {padding: '4px 0px'}}}>
                        <Label>Preview format</Label>
                        <Stack tokens={{padding: 4}}
                               styles={{root: {border: `solid 1px ${branding.theme.semanticColors.listItemBackgroundHovered}`}}}
                        >
                            {selectedType === InputType.LineBreak ?
                                <>
                                    <Separator styles={{
                                        content: {
                                            color: fontColor,
                                            fontWeight: isBold ? FontWeights.bold : FontWeights.regular,
                                            fontStyle: isItalic ? 'italic' : 'normal',
                                            textDecoration: isUnderlined ? 'underline' : 'none',
                                        },
                                        root: [
                                            {
                                                selectors: {
                                                    "::before": {
                                                        background: `${fontColor} !important`,
                                                    },
                                                },
                                            },
                                        ],
                                    }}>{"Example text"}
                                    </Separator>
                                </>
                                :
                                <>
                                    <Text block styles={previewContentControlStyles(selected)}>
                                        {"Example text"}
                                    </Text>
                                </>
                            }
                            
                        </Stack>
                    </Stack>
                </>
                }
                {/* Font color */}
                {[
                    InputType.Textbox,
                    InputType.TextArea,
                    InputType.RichText,
                    InputType.DropDownList,
                    InputType.CheckBox,
                    InputType.CheckBoxList,
                    InputType.Number,
                    InputType.Currency,
                    InputType.DatePicker,
                    InputType.Image,
                    InputType.LineBreak,
                    InputType.SectionHeader,
                    InputType.Paragraph
                ].includes(selectedType as InputType) && <>
                    <Label></Label>
                    <Label>Font Color</Label>
                    <CompactPicker
                        color={color}
                        onChangeComplete={handleChange}
                        colors={[
                            "#4D4D4D",
                            "#999999",
                            "#FFFFFF",
                            "#F44E3B",
                            "#FE9200",
                            "#FCDC00",
                            "#DBDF00",
                            "#A4DD00",
                            "#68CCCA",
                            "#73D8FF",
                            "#AEA1FF",
                            "#FDA1FF",
                            "#333333",
                            "#808080",
                            "#cccccc",
                            "#D33115",
                            "#E27300",
                            "#FCC400",
                            "#B0BC00",
                            "#68BC00",
                            "#16A5A5",
                            "#009CE0",
                            "#7B64FF",
                            "#FA28FF",
                            "#000000",
                            "#666666",
                            "#B3B3B3",
                            "#9F0500",
                            "#C45100",
                            "#FB9E00",
                            "#808900",
                            "#194D33",
                            "#0C797D",
                            "#0062B1",
                            "#653294",
                            "#AB149E",
                        ]}
                    />
                </>
                }
                {/* Background color */}
                {[
                    InputType.Textbox,
                    InputType.TextArea,
                    InputType.RichText,
                    InputType.DropDownList,
                    InputType.CheckBox,
                    InputType.CheckBoxList,
                    InputType.Number,
                    InputType.Currency,
                    InputType.DatePicker,
                    InputType.Paragraph,
                    InputType.Image,
                    InputType.SectionHeader
                ].includes(selectedType as InputType) && <>
                    <Label></Label>
                    <Label>Background Color</Label>
                    <CompactPicker
                        color={colorBackground}
                        onChangeComplete={handleBackgroundChange}
                        colors={[
                            "#4D4D4D",
                            "#999999",
                            "#FFFFFF",
                            "#F44E3B",
                            "#FE9200",
                            "#FCDC00",
                            "#DBDF00",
                            "#A4DD00",
                            "#68CCCA",
                            "#73D8FF",
                            "#AEA1FF",
                            "#FDA1FF",
                            "#333333",
                            "#808080",
                            "#cccccc",
                            "#D33115",
                            "#E27300",
                            "#FCC400",
                            "#B0BC00",
                            "#68BC00",
                            "#16A5A5",
                            "#009CE0",
                            "#7B64FF",
                            "#FA28FF",
                            "#000000",
                            "#666666",
                            "#B3B3B3",
                            "#9F0500",
                            "#C45100",
                            "#FB9E00",
                            "#808900",
                            "#194D33",
                            "#0C797D",
                            "#0062B1",
                            "#653294",
                            "#AB149E",
                        ]}
                    />
                </>
                }
            </Stack>
        )
    }

    const previewContentControlStyles = (selected:InputType): ITextStyles => {

        let _fontSize = '18px'
        switch (fontSize) {
            case '0':
                _fontSize = '18px'
                break;
            case '1':
                _fontSize = '23px'
                break;
            case '2':
                _fontSize = '28px'
                break;
        }

        if ([
            InputType.Textbox,
            InputType.TextArea,
            InputType.RichText,
            InputType.DropDownList,
            InputType.CheckBoxList,
            InputType.CheckBox,
            InputType.Number,
            InputType.Currency,
            InputType.DatePicker,
            InputType.Image,
            InputType.Paragraph
        ].includes(selected)) {
            switch (fontSize) {
                case '0':
                    _fontSize = '14px'
                    break
                case '1':
                    _fontSize = '18px'
                    break
                case '2':
                    _fontSize = '22px'
                    break
                default:
                    _fontSize = '14px'
                    break
            }
        }

        let textAlign = 'left'
        switch (align) {
            case '0':
                textAlign = 'left'
                break;
            case '1':
                textAlign = 'center'
                break;
            case '2':
                textAlign = 'right'
                break;
        }

        return {
            
            root: {
                fontSize: `${_fontSize}`,
                color: `${fontColor}`,
                backgroundColor: `${backGroundColor}`,
                fontWeight: isBold ? FontWeights.bold : FontWeights.regular,
                fontStyle: isItalic ? 'italic' : 'normal',
                textDecoration: isUnderlined ? 'underline' : 'none',
                textAlign: `${textAlign}`,
                margin: 8,
                paddingLeft: 4,
                paddingRight: 4
            }
        }
    }

// const filterCultures = (inputValue: string) => {
//     console.log(inputValue)
//     return cultures.filter((i) => {
//             console.log(i);
//             toNormalForm(i.label.toString()).includes(toNormalForm(inputValue))
//         }
//     );
// };
//
// const loadOptions = (inputValue: string) =>
//     new Promise<IAsyncSelect[]>((resolve) => {
//         setTimeout(() => {
//             resolve(filterCultures(inputValue));
//         }, 500);
//     });
    
    return (
        <PropertiesMenu onRenderFooterContent={onRenderFooterContent} selectedType={selectedType} dismissPanel={dismissPanel} isOpen={isOpen} ccId={ccId}>
            {selectedType === InputType.Attachment ? (
                <Stack tokens={{ padding: 16, childrenGap: 8 }}>
                    <AttachmentProperties
                        item={item}
                        InputsList={InputsList}
                        selectedType={selectedType}
                        onTypeDropdownChange={onTypeDropdownChange}
                    />
                </Stack>
            )
                : selectedType === InputType.HelpLink ? (
                        <Stack tokens={{ padding: 16, childrenGap: 8 }}>
                            <HelpLinkProperties
                                item={item}
                                InputsList={InputsList}
                                selectedType={selectedType}
                                onTypeDropdownChange={onTypeDropdownChange}
                            />
                        </Stack>
                    )
                        : selectedType === InputType.Integration ? (
                            <Stack tokens={{ padding: 16, childrenGap: 8 }}>
                                <IntegrationProperties
                                    layout={item}
                                    templateId={Number(templateId)}
                                    setItemProperties={setItemProperties}
                                />
                            </Stack>
                        )
                            :
                            <>
                                <Stack tokens={{ padding: 16, childrenGap: 8 }}>
                                    <Stack horizontal>
                                        <StackItem>
                                            <Toggle
                                                id="toggleLockLocation"
                                                checked={lockItem}
                                                onChange={onChangeBlock}
                                                title={
                                                    !lockItem
                                                        ? "Unlocked item location."
                                                        : "Locked item location."
                                                }
                                            />

                                        </StackItem>
                                        <StackItem styles={{ root: { paddingLeft: 8 } }}>
                                            <Icon
                                                styles={iconButtonStyles}
                                                iconName={!lockItem ? "Unlock" : "Lock"}
                                                title="Specify whether this control's location is locked."
                                            />
                                        </StackItem>
                                        <StackItem grow={1}>&nbsp;</StackItem>
                                        <StackItem disableShrink>
                                            <Toggle
                                                id="toggleAvailable"
                                                checked={adminOnly}
                                                onChange={onChangeAdminOnly}
                                                title={
                                                    !adminOnly
                                                        ? "Available to all authorized users."
                                                        : "Only available to administrators."
                                                }
                                            />
                                        </StackItem>
                                        <StackItem styles={{ root: { paddingLeft: 8 } }}>
                                            <Icon
                                                styles={iconButtonStyles}
                                                iconName={"PartyLeader"}
                                                title="Specify whether this control is only visible to administrators."
                                            />
                                        </StackItem>
                                    </Stack>


                                    <Stack horizontal styles={{root: {alignItems: "end"}}} tokens={{childrenGap: 8}}>
                                        <Stack grow={1}>
                                            <TextField
                                                id="PropFieldLabel"
                                                maxLength={selectedSize * 100}
                                                onChange={onChangeLabel}
                                                disabled={isGridList}
                                                value={selectedLabel}
                                                label="Field Label"
                                                title="Enter label that displays for this control on form wizard."
                                            />
                                        </Stack>
                                        {
                                            [
                                                InputType.Textbox,
                                                InputType.TextArea,
                                                InputType.RichText,
                                                InputType.DropDownList,
                                                InputType.CheckBoxList,
                                                InputType.CheckBox,
                                                InputType.Number,
                                                InputType.Currency,
                                                InputType.DatePicker,
                                                InputType.Image,
                                                InputType.LineBreak,
                                                InputType.SectionHeader,
                                                InputType.Paragraph
                                            ].includes(selectedType as InputType) &&
                                            <>
                                                <Stack>
                                                    <IconButton
                                                        iconProps={{iconName: 'Color'}}
                                                        title={showStyles ? "Hide format" : "Show format"}
                                                        ariaLabel={showStyles ? "Hide format" : "Show format"}
                                                        styles={{
                                                            icon: {color: branding.theme.palette.black},
                                                            root: {border: `solid 1px ${branding.theme.palette.black}`}
                                                        }}
                                                        checked={showStyles}
                                                        onClick={() => {
                                                            let _time = 0
                                                            if (showStyles) _time = 200

                                                            setDelayShowStyles((prevState) => !prevState)
                                                            setTimeout(function () {
                                                                setShowStyles((prevState) => !prevState)
                                                            }, _time)
                                                        }}
                                                    />
                                                </Stack>
                                            </>
                                        }
                                    </Stack>

                                    {/* region Content Control Label styles */}
                                    {
                                        [
                                            InputType.Textbox,
                                            InputType.TextArea,
                                            InputType.RichText,
                                            InputType.DropDownList,
                                            InputType.CheckBoxList,
                                            InputType.CheckBox,
                                            InputType.Number,
                                            InputType.Currency,
                                            InputType.DatePicker,
                                            InputType.Image,
                                            InputType.LineBreak,
                                            InputType.SectionHeader,
                                            InputType.Paragraph
                                        ].includes(selectedType as InputType) && showStyles &&
                                        <>
                                            <Stack
                                                className={showDelayStyles ? "ms-motion-slideDownIn" : "ms-motion-slideUpOut"}>
                                                <Separator>{"Format"}</Separator>
                                                {textStyles(selectedType)}
                                                <Separator/>
                                            </Stack>
                                        </>
                                    }
                                    {/* endregion */}
                                    
                                    
                                    
                                    <Dropdown
                                        id="PropFieldType"
                                        label="Field Type"
                                        options={
                                            isGridList
                                                ? InputsList.filter(
                                                    (value: IDropdownOption, index) =>
                                                        value.text === "Currency" ||
                                                        value.text === "Date" ||
                                                        value.text === "Drop Down List" ||
                                                        value.text === "Integer" ||
                                                        value.text === "Text Box"
                                                )
                                                : InputsList!
                                        }
                                        onChange={onTypeDropdownChange}
                                        selectedKey={selectedType}
                                        disabled={isLineBreak}
                                        title="Select control data input type."
                                    />
                                    {[
                                        InputType.Textbox,
                                        InputType.TextArea,
                                        InputType.RichText,
                                        InputType.DropDownList,
                                        InputType.CheckBoxList,
                                        InputType.Number,
                                        InputType.Currency,
                                        InputType.DatePicker,
                                    ].includes(selectedType as InputType) ? (
                                            <>
                                                <TextField
                                                    id="PropPlaceholder"
                                                    onChange={onChangePlaceholder}
                                                    value={selectedPlaceholder}
                                                    label="Placeholder Text"
                                                    title="Enter placeholder text for this control on form wizard."
                                                />
                                            </>
                                        ) : null}

                                    {selectedType === InputType.GridList ||
                                        selectedType === InputType.Paragraph ||
                                        selectedType === InputType.SectionHeader ||
                                        selectedType === InputType.LineBreak ? null : (
                                            <>

                                                <TextField
                                                    id="PropToolTip"
                                                    onChange={onChangeTooltip}
                                                    value={selectedTooltip}
                                                    label="Tooltip Text"
                                                    title="Enter tooltip text for this control on form wizard."
                                                />
                                                <TextField
                                                    errorMessage={tooltipUrlError}
                                                    id="PropTooltipUrl"
                                                    onChange={onChangeTooltipUrl}
                                                    value={selectedTooltipUrl}
                                                    label="Tooltip Url"
                                                    title="Enter the url to be redirected."
                                                />

                                            </>
                                        )}

                                    {selectedType !== InputType.Image &&
                                        <Slider
                                            disabled={isGridList}
                                            label="Control Column Span"
                                            min={1}
                                            max={4}
                                            step={1}
                                            value={selectedSize}
                                            showValue
                                            snapToStep
                                            onChange={sliderOnChange}
                                        />
                                    }

                                    <Stack horizontal>
                                        {selectedType !== InputType.CheckBox ? (
                                            !isLineBreak && !isGridList ? (
                                                <>
                                                    <Stack.Item grow={1}>
                                                        <Checkbox
                                                            id="PropRequired"
                                                            checked={selectedRequired}
                                                            onChange={onChangeRequired}
                                                            label="Required"
                                                            title="Check mark when input is required for this control."
                                                        />
                                                    </Stack.Item>
                                                    <Stack.Item grow={1}>
                                                        <Checkbox
                                                            id="PropReadOnly"
                                                            checked={selectedReadOnly}
                                                            onChange={onChangeReadOnly}
                                                            label={selectedType !== InputType.Image ? "Read-Only" : "Read-Only (static image)"}
                                                            title="Check mark when input is Read Only for this control."
                                                        />
                                                    </Stack.Item>
                                                </>
                                            ) : null
                                        ) : null}
                                        <Stack.Item grow={1}>
                                            <Checkbox
                                                id="PropHide"
                                                checked={selectedHide}
                                                onChange={onChangeShow}
                                                label={"Hide"}
                                                title="Check the mark when input is hidden."
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    
                                    
                                    

                                    {selectedType === InputType.Image &&
                                        <>
                                            {item !== null && item !== undefined && item.Type !== InputType.Image && (
                                                item.Validations.DefaultValue = ""
                                            )}
                                            <FormProvider {...myForm}>
                                                <form>
                                                    <RHFTextBoxAreaControl
                                                        id="ImageDescription"
                                                        label="Inline Help Text"
                                                        title="Enter a small text to show next to the upload control"
                                                        control={myForm.control}
                                                        setValue={myForm.setValue}
                                                        getValues={myForm.getValues}
                                                        trigger={myForm.trigger}
                                                        defaultValue={item?.TextParagraph === "" ? "Please upload your image up to 2 inches" : item?.TextParagraph}
                                                        maxLength={40}
                                                    />

                                                    <RHFUploadControl
                                                        id={`ImageData_${item?.Id}`}
                                                        label="Default Image"
                                                        title="Select the default image for the content control"
                                                        control={myForm.control}
                                                        setValue={myForm.setValue}
                                                        getValues={myForm.getValues}
                                                        trigger={myForm.trigger}
                                                        rules={{
                                                            
                                                            validate: async (data: any) => {
                                                                
                                                                if(data === undefined) return true
                                                                if (typeof data === "string") {
                                                                    return true
                                                                }

                                                                if (data === null && data.size <= 0) {
                                                                    // myForm.setValue("ImageData", undefined)
                                                                    return "The Min file size is 1 kb and the max file size is 300 kb"
                                                                }

                                                                var filevalid = false
                                                                var sizevalid = 300
                                                                var extensions = ["bmp", "tiff", "jpg", "png", "jpeg", "gif", "svg+xml"]
                                                                var typefile = data.type
                                                                var type = typefile.split("/")[1]
                                                                filevalid = extensions.includes(type)
                                                                if (!filevalid) {
                                                                    // myForm.setValue("ImageData", undefined)
                                                                    return "The supported types are: .jpg, .png .jpeg";
                                                                }

                                                                var fileSize = data.size
                                                                var Sizekb = fileSize / 1024
                                                                let IsValidSize = (Sizekb <= sizevalid && fileSize > 0)
                                                                if (!IsValidSize) {
                                                                    // myForm.setValue("ImageData", undefined)
                                                                    return "The Max file size is 300 kb"
                                                                }
                                                             
                                                                if (filevalid && IsValidSize) {
                                                                    return true
                                                                }
                                                                else {
                                                                    // myForm.setValue("ImageData", undefined)
                                                                    return false                                                                    
                                                                }
                                                            }
                                                        }}
                                                        defaultValue={item?.Validations.DefaultValue}
                                                    />
                                                </form>
                                            </FormProvider>
                                        </>
                                    }
                                    

                                    {(selectedType === InputType.DropDownList ||
                                        selectedType === InputType.CheckBoxList ||
                                        selectedType === InputType.RadioButtonList) &&
                                        <>
                                            <Stack>
                                                <Checkbox
                                                    id="PropStaticList"
                                                    checked={selectedStaticList}
                                                    onChange={onChangeStaticList}
                                                    label="Static List"
                                                    title="Select this option to manually enter list as selection data source."
                                                />
                                                <TextField
                                                    id="PropItemList"
                                                    disabled={!selectedStaticList}
                                                    value={selectedItemList}
                                                    onChange={onChangeItemList}
                                                    multiline
                                                    rows={4}
                                                    readOnly={false}
                                                    resizable={false}
                                                    label="Item list(separate each item with a comma):"
                                                />
                                                <div className="ms-Grid" dir="ltr">
                                                    <div className="ms-Grid-row">
                                                        <div
                                                            className="ms-Grid-col ms-sm11 ms-md11 ms-lg11"
                                                            style={{
                                                                paddingTop: "20px",
                                                                top: "6px",
                                                            }}
                                                        >
                                                            {/* <Dropdown
                                    id="PropMyLists"
                                    label="My Lists"
                                    options={myLists!}
                                    onChange={onListDropdownChange}
                                    selectedKey={selectedList?.key}
                                    disabled={selectedStaticList}
                                    title="Select a prefined list as selection data source."
                                /> */}

                                                            <TooltipHost
                                                                content={
                                                                    "Select a prefined list as selection data source."
                                                                }
                                                                directionalHint={
                                                                    DirectionalHint.bottomCenter
                                                                }
                                                            >
                                                                <Select
                                                                    className="basic-single"
                                                                    id="PropMyLists"
                                                                    aria-label="My Lists"
                                                                    options={myLists}
                                                                    onChange={
                                                                        onListDropdownChange
                                                                    }
                                                                    defaultValue={selectedList}
                                                                    value={selectedList}
                                                                    isDisabled={
                                                                        selectedStaticList
                                                                    }
                                                                    placeholder="Type to search..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    menuPlacement="top"
                                                                    styles={
                                                                        customDropdownStyles
                                                                    }
                                                                    components={{
                                                                        IndicatorSeparator:
                                                                            () => null,
                                                                        DropdownIndicator:
                                                                            () => (
                                                                                <FontIcon
                                                                                    iconName="ChevronDown"
                                                                                    style={{
                                                                                        paddingRight:
                                                                                            "7px",
                                                                                        fontSize:
                                                                                            "12px",
                                                                                    }}
                                                                                />
                                                                            ),
                                                                    }}
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25:
                                                                                "#EDEBE9",
                                                                        },
                                                                    })}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                        <div
                                                            className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"
                                                            style={{
                                                                paddingTop: "20px",
                                                                right: "5px",
                                                                top: "5px",
                                                            }}
                                                        >
                                                            <ActionButton
                                                                id="PropAddList"
                                                                title="Add a predefined list for use across controls."
                                                                iconProps={{
                                                                    iconName:
                                                                        "AddToShoppingList",
                                                                }}
                                                                onClick={goToList}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {selectedType === InputType.DropDownList && (
                                                    <>
                                                        <Separator />
                                                        <p>
                                                            Only if the list contains the value,
                                            the <em>Default Value</em> is
                                            applied.
                                        </p>
                                                        <TextField
                                                            id="PropDefaultValue"
                                                            multiline={multiline}
                                                            onChange={onChangeDefaultValue}
                                                            value={selectedDefaultValue}
                                                            maxLength={250}
                                                            label="Default Value"
                                                            title="Enter value control assumes when form wizard activates."
                                                        />
                                                    </>
                                                )}
                                                {selectedType === InputType.DropDownList ||
                                                    selectedType === InputType.CheckBoxList ? (
                                                        <>
                                                            <Separator />
                                                            <TooltipHost
                                                                content={"Select an Integration."}
                                                                directionalHint={
                                                                    DirectionalHint.bottomCenter
                                                                }
                                                            >
                                                                <Label>{"Integrations"}</Label>
                                                                <Select
                                                                    className="basic-single"
                                                                    id="PropIntegrations"
                                                                    aria-label="Integrations"
                                                                    options={getIntegrationList(
                                                                        events
                                                                    )}
                                                                    onChange={
                                                                        onIntegrationDropdownChange
                                                                    }
                                                                    defaultValue={
                                                                        selectedIntegration
                                                                    }
                                                                    value={selectedIntegration}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    menuPlacement="top"
                                                                    styles={customDropdownStyles}
                                                                    components={{
                                                                        IndicatorSeparator: () =>
                                                                            null,
                                                                        DropdownIndicator: () => (
                                                                            <FontIcon
                                                                                iconName="ChevronDown"
                                                                                style={{
                                                                                    paddingRight:
                                                                                        "7px",
                                                                                    fontSize:
                                                                                        "12px",
                                                                                }}
                                                                            />
                                                                        ),
                                                                    }}
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25: "#EDEBE9",
                                                                        },
                                                                    })}
                                                                />
                                                            </TooltipHost>
                                                        </>
                                                    ) : null}
                                            </Stack>
                                        </>
                                    }
                                    {selectedType === InputType.Number &&
                                        <Stack>
                                            <TextField
                                                id="maxL"
                                                value={selectedMaxLength.toString()}
                                                maxLength={4}
                                                onChange={onChangeMaxLenght}
                                                inputMode="numeric"
                                                label="Max Length"
                                                title="Enter maximum number of characters allowed."
                                            />

                                            <TextField
                                                id="PropDefaultValue"
                                                onChange={onChangeNumber}
                                                value={selectedNumber.toString()}
                                                maxLength={selectedMaxLength}
                                                label="Default Value"
                                                title="Enter value control assumes when form wizard activates."
                                            />
                                        </Stack>
                                    }
                                </Stack>

                                {/* Render InputType.Currency*/}
                                {selectedType === InputType.Currency &&
                                    <>
                                        <Separator>Currency options</Separator>

                                        <Stack tokens={{ padding: 16, childrenGap: 8 }}
                                            styles={{ root: { backgroundColor: getTheme().semanticColors.listItemBackgroundHovered } }}>

                                            {/*<VirtualizedComboBox*/}
                                            {/*    defaultSelectedKey={culture}*/}
                                            {/*    label="Scaled/virtualized example with 1000 items"*/}
                                            {/*    allowFreeform={false}*/}
                                            {/*    autoComplete="on"*/}
                                            {/*    options={cultures}*/}
                                            {/*    useComboBoxAsMenuWidth*/}
                                            {/*    */}
                                            {/*    */}
                                            {/*/>*/}

                                            {/*<TooltipHost content={"Select an Culture"}>*/}
                                            {/*    <Select */}
                                            {/*        className="basic-single"*/}
                                            {/*        id="culture"*/}
                                            {/*        aria-label={"Culture"}*/}
                                            {/*        */}
                                            {/*        value={{}}*/}
                                            {/*        //loadOptions={loadOptions}*/}
                                            {/*        options={cultures}*/}
                                            {/*        onChange={newValue => {*/}
                                            {/*            setCulture(newValue!)*/}
                                            {/*            setCurrencyValue(currencyValue)*/}
                                            {/*        }}*/}
                                            {/*        placeholder=""*/}
                                            {/*        isClearable={false}*/}
                                            {/*        isSearchable={true}*/}
                                            {/*        styles={customDropdownStyles}*/}
                                            {/*        components={{*/}
                                            {/*            Option: ({ children, ...props }) => {*/}
                                            {/*                const { onMouseMove, onMouseOver, ...rest } = props.innerProps;*/}
                                            {/*                const newProps = Object.assign(props, { innerProps: rest });*/}
                                            {/*                return (*/}
                                            {/*                    <components.Option*/}
                                            {/*                        {...newProps}*/}
                                            {/*                    >*/}
                                            {/*                        {children}*/}
                                            {/*                    </components.Option>*/}
                                            {/*                )*/}
                                            {/*            }, */}
                                            {/*            IndicatorSeparator: () => null,*/}
                                            {/*            DropdownIndicator: () => (*/}
                                            {/*                <FontIcon*/}
                                            {/*                    iconName="ChevronDown"*/}
                                            {/*                    style={{ paddingRight: "7px", fontSize: "12px" }}*/}
                                            {/*                />*/}
                                            {/*            ),*/}
                                            {/*        }}*/}
                                            {/*        theme={(theme) => ({*/}
                                            {/*            ...theme,*/}
                                            {/*            colors: {*/}
                                            {/*                ...theme.colors,*/}
                                            {/*                primary25: "#EDEBE9",*/}
                                            {/*            },*/}
                                            {/*        })}*/}
                                            {/*    />*/}
                                            {/*</TooltipHost>*/}

                                            {/*  BCP 47 language tag */}
                                            {/*<Dropdown*/}
                                            {/*    id="culture"*/}
                                            {/*    placeholder="Select an option"*/}
                                            {/*    label="Culture"*/}
                                            {/*    options={*/}
                                            {/*        cultures*/}
                                            {/*    }*/}
                                            {/*    */}
                                            {/*    selectedKey={culture}*/}
                                            {/*    onChange={(event, option) => {*/}
                                            {/*        setCulture(option?.key.toString())*/}
                                            {/*        setCurrencyValue(currencyValue)*/}
                                            {/*    }}*/}
                                            {/*/>*/}

                                            {/* ISO 4217  */}
                                            <Dropdown
                                                id="currency"
                                                placeholder="Select an option"
                                                label="Currency"
                                                options={
                                                    currencyOptions()
                                                }
                                                defaultSelectedKey={currency}
                                                onChange={(event, option) => {
                                                    setCurrency(option ? option.key.toString() : "")
                                                    setCurrencyValue(currencyValue)
                                                    setCurrencySubstrate(option ? option.data.CurrencySubstrate : defaultCurrencySubstrate)
                                                }}
                                            />

                                            <TextField
                                                id="maxL"
                                                value={selectedMaxLength.toString()}
                                                maxLength={4}
                                                onChange={(event, newValue) => {

                                                    onChangeMaxLenght(event, newValue)
                                                    const cutValue = currencyValue!.toString().substring(0, Number(newValue!))
                                                    setCurrencyValue(Number(cutValue))
                                                }}
                                                inputMode="numeric"
                                                label="Max Length (consider decimal symbol)"
                                                title="Enter maximum number of characters allowed."
                                            />
                                            <Label title={"Enter value control assumes when form wizard activates."}>Default
                                value</Label>

                                            <CurrencyInput intlConfig={{locale: culture!, currency: currency}}
                                                           style={{height: 30, padding: "0px 8px", margin: 0}}
                                                           className={"ms-Fabric input"}
                                                           id="PropDefaultValue"
                                                           name="PropDefaultValue"

                                                           step={0}
                                                           disableAbbreviations={true}

                                                           decimalSeparator={decimalSeparator}
                                                           groupSeparator={groupSeparator}

                                                           value={currencyValue}
                                                           maxLength={Number(selectedMaxLength)}
                                                           onValueChange={(value, name, values) => {
                                                               if(values !== null && values !== undefined && values.float !== null && values.float !== NaN) {
                                                                   setCurrencyValue(values.value)
                                                                   setSelectedDefaultValue(String(values.float))

                                                                   if(currency === "ALL" ||
                                                                       currency === "JPY" ||
                                                                       currency === "CLP" ||
                                                                       currency === "ISK" ||
                                                                       currency === "IRR" ||
                                                                       currency === "IQD" ||
                                                                       currency === "KRW" ||
                                                                       currency === "LBP" ||
                                                                       currency === "PYG" ||
                                                                       currency === "RSD" ||
                                                                       currency === "SYP" ||
                                                                       currency === "VND" ||
                                                                       currency === "YER"  ) {
                                                                       setSelectedDefaultValue(String(values.float).replace("0.", ""))

                                                                   }

                                                               }
                                                               else {
                                                                   setCurrencyValue(0);
                                                                   setSelectedDefaultValue("0");
                                                               }
                                                               setIsModifiedP(true);
                                                           }}
                                            />

                                        </Stack>
                                        <Separator />

                                    </>
                                }

                                <Stack tokens={{ padding: 16, childrenGap: 8 }}>
                                    {selectedType === InputType.None ||
                                        selectedType ===
                                        InputType.CheckBox ? null : selectedType ===
                                            InputType.SectionHeader || selectedType === InputType.DropDownList ? (
                                                <>
                                                   
                                                </>                                                
                                            ) : selectedType === InputType.RichText ? (
                                                <Stack>
                                                    <TextField
                                                        id="maxL"
                                                        value={selectedMaxLength.toString()}
                                                        maxLength={4}
                                                        onChange={onChangeMaxLenght}
                                                        inputMode="numeric"
                                                        label="Max Length"
                                                        title="Enter maximum number of characters allowed."
                                                    />
                                                    <TextField
                                                        id="PropDefaultValue"
                                                        onChange={onChangeDefaultValue}
                                                        value={selectedDefaultValue}
                                                        maxLength={selectedMaxLength}
                                                        label="Default Value"
                                                        title="Enter value control assumes when form wizard activates."
                                                    />
                                                </Stack>
                                            ) : selectedType === InputType.GridList ? (
                                                <Stack>
                                                    <Label></Label>
                                                    <Dropdown
                                                        id="PropColumns"
                                                        label="Columns"
                                                        options={columns!}
                                                        onChange={onColumnDropdownChange}
                                                        selectedKey={selectedColumn}
                                                        title="Enter number of columns required."
                                                    />
                                                    <TextField
                                                        onChange={onChangeColumnLabel}
                                                        maxLength={500}
                                                        value={selectedColumnLabel}
                                                        label="Column Label"
                                                        title="Enter label that displays for this control on form wizard."
                                                    />
                                                    <Dropdown
                                                        id="PropColumnType"
                                                        label="Column Type"
                                                        options={
                                                            isGridList
                                                                ? InputsList.filter(
                                                                    (
                                                                        value: IDropdownOption,
                                                                        index
                                                                    ) =>
                                                                        value.text ===
                                                                        "Currency" ||
                                                                        value.text === "Date" ||
                                                                        value.text ===
                                                                        "Integer" ||
                                                                        value.text ===
                                                                        "Text Box" ||
                                                                        value.text ===
                                                                        "Drop Down List"
                                                                )
                                                                : InputsList!
                                                        }
                                                        onChange={onColumnTypeDropdownChange}
                                                        selectedKey={selectedColumnType}
                                                        title="Select control data input type."
                                                    />

                                                    <br />
                                                    <Stack>
                                                        <div
                                                            hidden={
                                                                selectedColumnType !==
                                                                InputType.DropDownList
                                                            }
                                                        >
                                                            <Checkbox
                                                                id="PropStaticList"
                                                                checked={selectedStaticGridList}
                                                                onChange={onChangeStaticGridList}
                                                                label="Static List"
                                                                title="Select this option to manually enter list as selection data source."
                                                            />
                                                            <TextField
                                                                id="PropItemList"
                                                                disabled={!selectedStaticGridList}
                                                                value={selectedItemGridList}
                                                                onChange={onChangeItemGridList}
                                                                multiline
                                                                rows={4}
                                                                readOnly={false}
                                                                resizable={false}
                                                                label="Item list(separate each item with a comma):"
                                                            />
                                                            <div className="ms-Grid" dir="ltr">
                                                                <div className="ms-Grid-row">
                                                                    {/*<div*/}
                                                                    {/*    className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">{selectedColumn}</div>*/}
                                                                    <div
                                                                        className="ms-Grid-col ms-sm11 ms-md11 ms-lg11"
                                                                        style={{
                                                                            paddingTop: "20px",
                                                                            top: "6px",
                                                                        }}
                                                                    >
                                                                        <TooltipHost
                                                                            content={
                                                                                "Select a prefined list as selection data source."
                                                                            }
                                                                            directionalHint={
                                                                                DirectionalHint.bottomCenter
                                                                            }
                                                                        >
                                                                            <Select
                                                                                className="basic-single"
                                                                                id="PropMyLists"
                                                                                aria-label="My Lists"
                                                                                options={myLists}
                                                                                onChange={
                                                                                    onListDropdownChangeGridList
                                                                                }
                                                                                defaultValue={
                                                                                    selectedDropDownGridList
                                                                                }
                                                                                value={
                                                                                    selectedDropDownGridList
                                                                                }
                                                                                isDisabled={
                                                                                    selectedStaticGridList
                                                                                }
                                                                                placeholder="Type to search..."
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                menuPlacement="top"
                                                                                styles={
                                                                                    customDropdownStyles
                                                                                }
                                                                                components={{
                                                                                    IndicatorSeparator:
                                                                                        () => null,
                                                                                    DropdownIndicator:
                                                                                        () => (
                                                                                            <FontIcon
                                                                                                iconName="ChevronDown"
                                                                                                style={{
                                                                                                    paddingRight:
                                                                                                        "7px",
                                                                                                    fontSize:
                                                                                                        "12px",
                                                                                                }}
                                                                                            />
                                                                                        ),
                                                                                }}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        primary25:
                                                                                            "#EDEBE9",
                                                                                    },
                                                                                })}
                                                                            />
                                                                        </TooltipHost>
                                                                    </div>
                                                                    <div
                                                                        className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"
                                                                        style={{
                                                                            paddingTop: "20px",
                                                                            right: "5px",
                                                                            top: "5px",
                                                                        }}
                                                                    >
                                                                        <ActionButton
                                                                            id="PropAddList"
                                                                            title="Add a predefined list for use across controls."
                                                                            iconProps={{
                                                                                iconName:
                                                                                    "AddToShoppingList",
                                                                            }}
                                                                            onClick={goToList}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Stack>

                                                    {selectedColumnLabel && (
                                                        <>
                                                            <br />
                                                            <Stack horizontal>
                                                                <Stack.Item grow={1}>
                                                                    <Checkbox
                                                                        id="PropRequired"
                                                                        checked={selectedRequired}
                                                                        onChange={onChangeRequired}
                                                                        label="Required"
                                                                        title="Check mark when input is required for this control."
                                                                    />
                                                                </Stack.Item>
                                                                <Stack.Item grow={1}>
                                                                    <Checkbox
                                                                        id="PropReadOnly"
                                                                        checked={selectedReadOnly}
                                                                        onChange={onChangeReadOnly}
                                                                        label="Read-Only"
                                                                        title="Check mark when input is Read Only for this control."
                                                                    />
                                                                </Stack.Item>
                                                            </Stack>
                                                            <br />


                                                            {selectedColumnType ===
                                                                InputType.DropDownList && (
                                                                    <>
                                                                        <Separator></Separator>
                                                                        <p>
                                                                            Only if the list contains
                                                    the value, the{" "}
                                                                            <em>Default Value</em> is
                                                    applied.
                                                </p>
                                                                        <TextField
                                                                            id="PropDefaultValue"
                                                                            onChange={
                                                                                onChangeDefaultValue
                                                                            }
                                                                            value={selectedDefaultValue}
                                                                            maxLength={
                                                                                selectedMaxLength
                                                                            }
                                                                            label="Default Value"
                                                                            title="Enter value control assumes when form wizard activates."
                                                                        />
                                                                        {/* <Separator />
                                        <TooltipHost
                                            content={
                                                "Select a Integration."
                                            }
                                            directionalHint={
                                                DirectionalHint.bottomCenter
                                            }
                                        >
                                            <Label>
                                                {"Integrations"}
                                            </Label>
                                            <Select
                                                className="basic-single"
                                                id="PropIntegrations"
                                                aria-label="Integrations"
                                                options={getIntegrationList(
                                                    events
                                                )}
                                                onChange={
                                                    onIntegrationDropdownChange
                                                }
                                                defaultValue={
                                                    selectedIntegration
                                                }
                                                value={
                                                    selectedIntegration
                                                }
                                                isClearable={true}
                                                isSearchable={true}
                                                menuPlacement="top"
                                                styles={
                                                    customDropdownStyles
                                                }
                                                components={{
                                                    IndicatorSeparator:
                                                        () => null,
                                                    DropdownIndicator:
                                                        () => (
                                                            <FontIcon
                                                                iconName="ChevronDown"
                                                                style={{
                                                                    paddingRight:
                                                                        "7px",
                                                                    fontSize:
                                                                        "12px",
                                                                }}
                                                            />
                                                        ),
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25:
                                                            "#EDEBE9",
                                                    },
                                                })}
                                            />
                                        </TooltipHost> */}
                                                                    </>
                                                                )}

                                                            {selectedColumnType ===
                                                                InputType.Textbox && (
                                                                    <>
                                                                        <Separator></Separator>
                                                                        <TextField
                                                                            id="PropDefaultValue"
                                                                            onChange={
                                                                                onChangeDefaultValue
                                                                            }
                                                                            value={selectedDefaultValue}
                                                                            maxLength={
                                                                                selectedMaxLength
                                                                            }
                                                                            label="Default Value"
                                                                            title="Enter value control assumes when form wizard activates."
                                                                        />
                                                                        <ChoiceGroup
                                                                            id="PropInlineGroup"
                                                                            style={{
                                                                                marginLeft: "-10px",
                                                                            }}
                                                                            selectedKey={pattern}
                                                                            options={options}
                                                                            className="inlineGroup"
                                                                            onChange={_onChangePattern}
                                                                        />
                                                                        {pattern === "0" && null}
                                                                        {pattern === "1" && (
                                                                            <>
                                                                                <div>
                                                                                    <div
                                                                                        className={
                                                                                            contentStyles.labelHelp
                                                                                        }
                                                                                    >
                                                                                        <label
                                                                                            htmlFor="PropMask"
                                                                                            id="labelTextMaskGridTextbox"
                                                                                            className={
                                                                                                contentStyles.msLabel
                                                                                            }
                                                                                        >
                                                                                            Mask
                                                                </label>
                                                                                        <HelpComponentRelative
                                                                                            headline={t(
                                                                                                "properties.helpMaskTitle"
                                                                                            )}
                                                                                            text={t(
                                                                                                "properties.helpMask"
                                                                                            )}
                                                                                            target="labelTextMaskGridTextbox"
                                                                                            location="webformwwizard"
                                                                                        />
                                                                                    </div>
                                                                                    <TextField
                                                                                        id="PropMask"
                                                                                        onGetErrorMessage={
                                                                                            getErrorMessage
                                                                                        }
                                                                                        validateOnFocusOut
                                                                                        onChange={
                                                                                            onChangeMask
                                                                                        }
                                                                                        maxLength={
                                                                                            selectedMaxLength
                                                                                        }
                                                                                        value={
                                                                                            selectedMask
                                                                                        }
                                                                                        title="Enter input mask for controlled input formatting. Type: 'L' for characters, '9' for numbers and 's' for special characters"
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {pattern === "2" && (
                                                                            <>
                                                                                <div>
                                                                                    <div
                                                                                        className={
                                                                                            contentStyles.labelHelp
                                                                                        }
                                                                                    >
                                                                                        <label
                                                                                            htmlFor="PropPattern"
                                                                                            id="labelTextPatternGridTextbox"
                                                                                            className={
                                                                                                contentStyles.msLabel
                                                                                            }
                                                                                        >
                                                                                            Pattern
                                                                </label>
                                                                                        <HelpComponentRelative
                                                                                            headline={t(
                                                                                                "properties.helpPatternTitle"
                                                                                            )}
                                                                                            text={t(
                                                                                                "properties.helpPattern", {
                                                                                                ns: "wizard",
                                                                                                AppName: branding.appName
                                                                                            }
                                                                                            )}
                                                                                            target="labelTextPatternGridTextbox"
                                                                                            location="webformwwizard"
                                                                                        />
                                                                                    </div>
                                                                                    <TextField
                                                                                        id="PropPattern"
                                                                                        onGetErrorMessage={(
                                                                                            value: string
                                                                                        ) => {
                                                                                            try {
                                                                                                new RegExp(
                                                                                                    value
                                                                                                );
                                                                                                setValidPattern(
                                                                                                    true
                                                                                                );
                                                                                                return "";
                                                                                            } catch (e) {
                                                                                                setValidPattern(
                                                                                                    false
                                                                                                );
                                                                                                return "Invalid pattern";
                                                                                            }
                                                                                        }}
                                                                                        onChange={
                                                                                            onChangeRegex
                                                                                        }
                                                                                        maxLength={500}
                                                                                        value={
                                                                                            selectedRegex
                                                                                        }
                                                                                        title="Enter regular expression to validate input. E.g. [A-Za-z]{3}"
                                                                                    />
                                                                                    <TextField
                                                                                        id="PropPatternError"
                                                                                        onChange={
                                                                                            onChangeErrorMessage
                                                                                        }
                                                                                        maxLength={1000}
                                                                                        value={
                                                                                            selectedErrorMessage
                                                                                        }
                                                                                        label="Pattern Error Message"
                                                                                        title="Enter text to display the user a message if pattern do not match."
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}

                                                            {selectedColumnType ===
                                                                InputType.DatePicker && (
                                                                    <>
                                                                        <Separator></Separator>
                                                                        <Dropdown
                                                                            id="dateRegex"
                                                                            label={"Date Format:"}
                                                                            options={datePickerFormats}
                                                                            onChange={
                                                                                onChangeDateFormat
                                                                            }
                                                                            selectedKey={selectedRegex}
                                                                            title="Select the date format."
                                                                        />
                                                                        <div
                                                                            style={{ display: "flex" }}
                                                                        >
                                                                            <DatePicker
                                                                                textField={{
                                                                                    name: "DateDefaultValue",
                                                                                    onRenderLabel:
                                                                                        () => {
                                                                                            return (
                                                                                                <div
                                                                                                    style={{
                                                                                                        paddingTop:
                                                                                                            "10px",
                                                                                                        paddingBottom:
                                                                                                            "10px",
                                                                                                        display:
                                                                                                            "flow-root",
                                                                                                    }}
                                                                                                >
                                                                                                    <label
                                                                                                        htmlFor={
                                                                                                            "DateDefaultValue"
                                                                                                        }
                                                                                                        style={{
                                                                                                            float: "left",
                                                                                                            fontSize: 14,
                                                                                                            paddingBottom:
                                                                                                                "5px",
                                                                                                            fontFamily:
                                                                                                                "Segoe UI Web (West European)",
                                                                                                            fontWeight:
                                                                                                                "600",
                                                                                                        }}
                                                                                                    >
                                                                                                        Default
                                                                                                        Value
                                                                            </label>
                                                                                                    <Checkbox
                                                                                                        styles={{
                                                                                                            root: {
                                                                                                                float: "right",
                                                                                                            },
                                                                                                        }}
                                                                                                        id="PropTodaysDate"
                                                                                                        checked={
                                                                                                            selectedTodaysDate
                                                                                                        }
                                                                                                        onChange={
                                                                                                            onChangeTodaysDate
                                                                                                        }
                                                                                                        label="Today's date."
                                                                                                        title="Check mark when input will get Today's Date for this control."
                                                                                                    />
                                                                                                </div>
                                                                                            );
                                                                                        },
                                                                                }}
                                                                                disabled={
                                                                                    selectedTodaysDate
                                                                                }
                                                                                styles={{
                                                                                    root: {
                                                                                        width: "100%",
                                                                                    },
                                                                                }}
                                                                                isMonthPickerVisible={
                                                                                    true
                                                                                }
                                                                                id="DateDefaultValue"
                                                                                placeholder={
                                                                                    selectedTodaysDate
                                                                                        ? "Todays's date"
                                                                                        : "Select a date..."
                                                                                }
                                                                                ariaLabel="Select a date"
                                                                                label="Default Value"
                                                                                formatDate={
                                                                                    onFormatDate
                                                                                }
                                                                                today={defaultDate}
                                                                                value={defaultDate!}
                                                                                onSelectDate={
                                                                                    setDefaultDate as (
                                                                                        date:
                                                                                            | Date
                                                                                            | null
                                                                                            | undefined
                                                                                    ) => void
                                                                                }
                                                                            />
                                                                            {selectedRequired ||
                                                                                selectedTodaysDate ? null : (
                                                                                    <IconButton
                                                                                        styles={{
                                                                                            root: {
                                                                                                right: "5px",
                                                                                                top: "35px",
                                                                                            },
                                                                                        }}
                                                                                        iconProps={
                                                                                            cancelIcon
                                                                                        }
                                                                                        onClick={() => {
                                                                                            setDefaultDate(
                                                                                                undefined
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                        </div>
                                                                    </>
                                                                )}

                                                            {selectedColumnType ===
                                                                InputType.Number && (
                                                                    <>
                                                                        <Separator></Separator>
                                                                        <TextField
                                                                            id="PropDefaultValue"
                                                                            onChange={onChangeNumber}
                                                                            value={selectedNumber.toString()}
                                                                            maxLength={
                                                                                selectedMaxLength
                                                                            }
                                                                            label="Default Value"
                                                                            title="Enter value control assumes when form wizard activates."
                                                                        />
                                                                        <Separator>or</Separator>
                                                                        <TextField
                                                                            disabled={!selectedReadOnly}
                                                                            id="PropFormula"
                                                                            onChange={onChangeFormula}
                                                                            value={selectedFormula}
                                                                            title="Entered value control assumes when the form wizard activates."
                                                                            maxLength={256}
                                                                            label="Formula (only when field is Read-only and formula is not empty)"
                                                                            onRenderLabel={
                                                                                onRenderLabelHelp
                                                                            }
                                                                            prefix="="
                                                                        />
                                                                    </>
                                                                )}

                                                            {/* Render InputType.Currency / Grid*/}
                                                            {selectedColumnType ===
                                                                InputType.Currency && (

                                                                    <>
                                                                        <Separator>Currency options</Separator>

                                                                        <Stack tokens={{ padding: 16, childrenGap: 8 }}
                                                                            styles={{ root: { backgroundColor: getTheme().semanticColors.listItemBackgroundHovered } }}>


                                                                            {/*  BCP 47 language tag */}
                                                                            {/*<Dropdown*/}
                                                                            {/*    id="culture"*/}
                                                                            {/*    placeholder="Select an option"*/}
                                                                            {/*    label="Culture"*/}
                                                                            {/*    options={*/}
                                                                            {/*        cultures*/}
                                                                            {/*    }*/}
                                                                            {/*    selectedKey={culture}*/}
                                                                            {/*    onChange={(event, option) => {*/}
                                                                            {/*        setCulture(option?.key.toString())*/}
                                                                            {/*        setCurrencyValue(currencyValue)*/}
                                                                            {/*    }}*/}
                                                                            {/*/>*/}

                                                                            {/* ISO 4217  */}
                                                                            <Dropdown
                                                                                id="currency"
                                                                                placeholder="Select an option"
                                                                                label="Currency"
                                                                                options={
                                                                                    currencyOptions()
                                                                                }
                                                                                defaultSelectedKey={currency}
                                                                                onChange={(event, option) => {
                                                                                    setCurrency(option ? option.key.toString() : "")
                                                                                    setCurrencyValue(currencyValue)
                                                                                    setCurrencySubstrate(option ? option.data.CurrencySubstrate : defaultCurrencySubstrate)
                                                                                }}
                                                                            />

                                                                            <Label title={"Enter value control assumes when form wizard activates."}>Default
                                                        value</Label>

                                                                            <CurrencyInput intlConfig={{locale: culture!, currency: currency}}
                                                                                           style={{height: 30, padding: "0px 8px", margin: 0}}
                                                                                           className={"ms-Fabric input"}
                                                                                           id="PropDefaultValue"
                                                                                           name="PropDefaultValue"

                                                                                           decimalSeparator={decimalSeparator}
                                                                                           groupSeparator={groupSeparator}
                                                                                           step={0}
                                                                                           disableAbbreviations={true}

                                                                                           value={currencyValue}
                                                                                           onValueChange={(value, name, values) => {
                                                                                               if(values !== null && values !== undefined && values.float !== null && values.float !== NaN) {
                                                                                                   setCurrencyValue(values.value)
                                                                                                   setSelectedDefaultValue(String(values.float))

                                                                                                   if(currency === "ALL" ||
                                                                                                       currency === "JPY" ||
                                                                                                       currency === "CLP" ||
                                                                                                       currency === "ISK" ||
                                                                                                       currency === "IRR" ||
                                                                                                       currency === "IQD" ||
                                                                                                       currency === "KRW" ||
                                                                                                       currency === "LBP" ||
                                                                                                       currency === "PYG" ||
                                                                                                       currency === "RSD" ||
                                                                                                       currency === "SYP" ||
                                                                                                       currency === "VND" ||
                                                                                                       currency === "YER"  ) {
                                                                                                       setSelectedDefaultValue(String(values.float).replace("0.", ""))

                                                                                                   }

                                                                                               }
                                                                                               else {
                                                                                                   setCurrencyValue(0);
                                                                                                   setSelectedDefaultValue("0");
                                                                                               }
                                                                                               setIsModifiedP(true);
                                                                                           }}
                                                                            />
                                                                        </Stack>
                                                                        <Separator />
                                                                        <TextField
                                                                            disabled={!selectedReadOnly}
                                                                            id="PropFormula"
                                                                            onChange={onChangeFormula}
                                                                            value={selectedFormula}
                                                                            title="Entered value control assumes when the form wizard activates."
                                                                            maxLength={256}
                                                                            label="Formula (only when field is Read-only and formula is not empty)"
                                                                            onRenderLabel={
                                                                                onRenderLabelHelp
                                                                            }
                                                                            prefix="="
                                                                        />


                                                                        <Separator />

                                                                    </>

                                                                )}
                                                        </>
                                                    )}
                                                </Stack>
                                            ) : selectedType === InputType.TextArea ? (
                                                <Stack>
                                                    <TextField
                                                        id="maxL"
                                                        value={selectedMaxLength.toString()}
                                                        maxLength={4}
                                                        onChange={onChangeMaxLenght}
                                                        inputMode="numeric"
                                                        label="Max Length"
                                                        title="Enter maximum number of characters allowed."
                                                    />
                                                    <TextField
                                                        id="PropDefaultValue"
                                                        onChange={onChangeDefaultValue}
                                                        value={selectedDefaultValue}
                                                        maxLength={selectedMaxLength}
                                                        label="Default Value"
                                                        title="Enter value control assumes when form wizard activates."
                                                    />
                                                </Stack>
                                            ) : selectedType === InputType.Paragraph ? (
                                                <Stack>
                                                    <div
                                                        className="scrollVisible"
                                                        style={{
                                                            overflow: "auto",
                                                            overflowX: "hidden",
                                                            maxWidth: "100%",
                                                            height: "100%",
                                                        }}
                                                    >
                                                        <TextField
                                                            id="paragraphText"
                                                            style={{ width: "100" }}
                                                            value={selectedParagraphText}
                                                            maxLength={selectedSize * 170}
                                                            multiline
                                                            rows={4}
                                                            resizable={false}
                                                            readOnly={false}
                                                            onChange={onChangeTextParagraph}
                                                            label="Paragraph Text"
                                                            title="Enter static text to provide instructions."
                                                        />
                                                    </div>
                                                    
                                                </Stack>
                                            ) : selectedType === InputType.DatePicker ? (
                                                <>
                                                    <Dropdown
                                                        id="dateRegex"
                                                        label={"Date Format:"}
                                                        options={datePickerFormats}
                                                        onChange={onChangeDateFormat}
                                                        selectedKey={selectedRegex}
                                                        title="Select the date format for the expression."
                                                    />

                                                    <div style={{ display: "flex" }}>
                                                        <DatePicker
                                                            textField={{
                                                                name: "DateDefaultValue",
                                                                onRenderLabel: () => {
                                                                    return (
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flow-root",
                                                                            }}
                                                                        >
                                                                            <label
                                                                                htmlFor={
                                                                                    "DateDefaultValue"
                                                                                }
                                                                                style={{
                                                                                    float: "left",
                                                                                    fontSize: 14,
                                                                                    paddingBottom:
                                                                                        "5px",
                                                                                    fontFamily:
                                                                                        "Segoe UI Web (West European)",
                                                                                    fontWeight:
                                                                                        "600",
                                                                                }}
                                                                            >
                                                                                Default Value
                                                    </label>
                                                                            <Checkbox
                                                                                styles={{
                                                                                    root: {
                                                                                        float: "right",
                                                                                    },
                                                                                }}
                                                                                id="PropTodaysDate"
                                                                                checked={
                                                                                    selectedTodaysDate
                                                                                }
                                                                                onChange={
                                                                                    onChangeTodaysDate
                                                                                }
                                                                                label="Today's date."
                                                                                title="Check mark when input will get Today's Date for this control."
                                                                            />
                                                                        </div>
                                                                    );
                                                                },
                                                            }}
                                                            disabled={selectedTodaysDate}
                                                            styles={{ root: { width: "100%" } }}
                                                            isMonthPickerVisible={true}
                                                            id="DateDefaultValue"
                                                            placeholder={
                                                                selectedTodaysDate
                                                                    ? "Todays's date"
                                                                    : "Select a date..."
                                                            }
                                                            ariaLabel="Select a date"
                                                            label="Default Value"
                                                            formatDate={onFormatDate}
                                                            today={defaultDate}
                                                            value={defaultDate!}
                                                            onSelectDate={
                                                                setDefaultDate as (
                                                                    date: Date | null | undefined
                                                                ) => void
                                                            }
                                                        />
                                                        {selectedRequired ||
                                                            selectedTodaysDate ? null : (
                                                                <IconButton
                                                                    styles={{
                                                                        root: {
                                                                            right: "0px",
                                                                            top: "29px",
                                                                        },
                                                                    }}
                                                                    iconProps={cancelIcon}
                                                                    onClick={() => {
                                                                        setDefaultDate(undefined);
                                                                    }}
                                                                />
                                                            )}
                                                    </div>
                                                </>
                                            ) : selectedType === InputType.LineBreak ? (
                                                <Stack>
                                                    <Label></Label>
                                                    <TextField
                                                        id="PropDefaultValue"
                                                        onChange={onChangeDefaultValue}
                                                        value={selectedDefaultValue}
                                                        maxLength={selectedMaxLength}
                                                        label="Default Value"
                                                        title="Enter value control assumes when form wizard activates."
                                                    />
                                                </Stack>
                                            ) :
                                                                    (selectedType as InputType === InputType.RichText
                                                                        || selectedType as InputType === InputType.Textbox
                                                                        || selectedType as InputType === InputType.TextArea) &&
                                                                    <Stack>
                                                                        <Label></Label>
                                                                        <MaskedTextField
                                                                            id="maxL"
                                                                            value={selectedMaxLength.toString()}
                                                                            maxLength={selectedType as InputType === InputType.Textbox ? 3 : 4}
                                                                            onChange={onChangeMaxLenght}
                                                                            mask="9999"
                                                                            maskChar=""
                                                                            label="Max Length"
                                                                            title="Enter maximum number of characters allowed."
                                                                        />
                                                                        <TextField
                                                                            id="PropDefaultValue"
                                                                            onChange={onChangeDefaultValue}
                                                                            value={selectedDefaultValue}
                                                                            maxLength={selectedMaxLength}
                                                                            label="Default Value"
                                                                            title="Enter value control assumes when form wizard activates."
                                                                        />
                                                                        <ChoiceGroup
                                                                            id="PropInlineGroup"
                                                                            style={{ marginLeft: "-10px" }}
                                                                            selectedKey={pattern}
                                                                            options={options}
                                                                            className="inlineGroup"
                                                                            onChange={_onChangePattern}
                                                                        />
                                                                        {pattern === "0" ? null : pattern === "1" ? (
                                                                            <div>
                                                                                <div
                                                                                    className={contentStyles.labelHelp}
                                                                                >
                                                                                    <label
                                                                                        htmlFor="PropMask"
                                                                                        id="labelTextMaskTextbox"
                                                                                        className={
                                                                                            contentStyles.msLabel
                                                                                        }
                                                                                    >
                                                                                        Mask
                                        </label>
                                                                                    <HelpComponentRelative
                                                                                        headline={t(
                                                                                            "properties.helpMaskTitle"
                                                                                        )}
                                                                                        text={t("properties.helpMask")}
                                                                                        target="labelTextMaskTextbox"
                                                                                        location="webformwwizard"
                                                                                    />
                                                                                </div>
                                                                                <TextField
                                                                                    id="PropMask"
                                                                                    onGetErrorMessage={getErrorMessage}
                                                                                    validateOnFocusOut
                                                                                    onChange={onChangeMask}
                                                                                    maxLength={selectedMaxLength}
                                                                                    value={selectedMask}
                                                                                    title="Enter input mask for controlled input formatting. Type: 'L' for characters, '9' for numbers and 's' for special characters"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                                <div>
                                                                                    <div
                                                                                        className={contentStyles.labelHelp}
                                                                                    >
                                                                                        <label
                                                                                            htmlFor="PropPattern"
                                                                                            id="labelTextPatternTextbox"
                                                                                            className={
                                                                                                contentStyles.msLabel
                                                                                            }
                                                                                        >
                                                                                            Pattern
                                        </label>
                                                                                        <HelpComponentRelative
                                                                                            headline={t("properties.helpPatternTitle")}
                                                                                            text={t("properties.helpPattern", {
                                                                                                ns: "wizard",
                                                                                                AppName: branding.appName
                                                                                            })}
                                                                                            target="labelTextPatternTextbox"
                                                                                            location="webformwwizard"
                                                                                        />
                                                                                    </div>
                                                                                    <TextField
                                                                                        id="PropPattern"
                                                                                        onGetErrorMessage={(
                                                                                            value: string
                                                                                        ) => {
                                                                                            try {
                                                                                                new RegExp(value);
                                                                                                setValidPattern(true);
                                                                                                return "";
                                                                                            } catch (e) {
                                                                                                setValidPattern(false);
                                                                                                return "Invalid pattern";
                                                                                            }
                                                                                        }}
                                                                                        onChange={onChangeRegex}
                                                                                        maxLength={500}
                                                                                        value={selectedRegex}
                                                                                        title="Enter regular expression to validate input. E.g. [A-Za-z]{3}"
                                                                                    />
                                                                                    <TextField
                                                                                        id="PropPatternError"
                                                                                        onChange={onChangeErrorMessage}
                                                                                        maxLength={1000}
                                                                                        value={selectedErrorMessage}
                                                                                        label="Pattern Error Message"
                                                                                        title="Enter text to display the user a message if pattern do not match."
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </Stack>
                                    }
                                </Stack>
                            </>
            }
        </PropertiesMenu>
    )

}

const CustomLabel = (props: any) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
        useBoolean(false);
    const descriptionId: string = useId("description");
    const iconButtonId: string = useId("iconButton");

    return (
        <>
            <Stack
                horizontal
                verticalAlign="center"
                tokens={stackTokens}
                style={{ marginBottom: 8 }}
            >
                <span id={props.id} style={{ fontWeight: "500" }}>
                    {props.label}
                </span>
                <IconButton
                    id={iconButtonId}
                    iconProps={iconProps}
                    title="Info"
                    ariaLabel="Info"
                    onClick={toggleIsCalloutVisible}
                    styles={iconButtonStyles}
                />
            </Stack>
            {isCalloutVisible && (
                <Callout
                    target={"#" + iconButtonId}
                    setInitialFocus
                    onDismiss={toggleIsCalloutVisible}
                    ariaDescribedBy={descriptionId}
                    role="alertdialog"
                    className={contentStyles.callout}
                >
                    <Stack
                        tokens={stackTokens}
                        horizontalAlign="start"
                        styles={labelCalloutStackStyles}
                    >
                        <span id={descriptionId}>
                            <p>
                                You can create a simple formula to add,
                                subtract, multiply, or divide values in this
                                column. Simple formulas include{" "}
                                <strong>column fields</strong> (always
                                enclosed in brackets <strong>[ ]</strong>,
                                starts with the '$' symbol) and{" "}
                                <strong>constants</strong> that are numeric
                                values and calculation operators such as
                                plus (<strong>+</strong>), minus (
                                <strong>-</strong>), asterisk(
                                <strong>*</strong>), or forward slash (
                                <strong>/</strong>) signs.
                            </p>
                            <p>
                                For example, when you enter the formula{" "}
                                <strong>
                                    <em>[$col1] + 2 * 3</em>
                                </strong>
                                , the last two numbers are multiplied and
                                added to the first number to get the result.
                                Following the standard order of mathematical
                                operations, multiplication is performed
                                before addition.
                            </p>
                            <p>
                                <strong>Note:</strong> <em>The calculation between different currencies and cultures does not evaluate the conversion.</em>
                            </p>
                        </span>
                    </Stack>
                </Callout>
            )}
        </>
    );
};
export default PropertyForm