import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IContentLibraryLanguage } from '../../../interfaces/IDocumentAssembly'
import { Stack, TextField, DialogFooter, PrimaryButton, DefaultButton, mergeStyleSets, ITag, Label, TagPicker } from '@fluentui/react'
import { t } from 'i18next'
import { useForm, Controller } from 'react-hook-form'
import DocumentAssemblyService from '../../../services/assembly'
import RichTextEditor, { EditorValue } from 'react-rte'
import { SingleValue } from 'react-select'
import { SelectOptions } from '../../../Helpers/functions'
import { IStatusMessage } from '../../../interfaces/IApp'
import { useTranslation } from 'react-i18next'
import AppService from '../../../services/app'
import { DEFAULT_RICHTOOLBAR } from '../../../Helpers/Utils'
type SingleValueType = SingleValue<SelectOptions | null>
type RulesFormProps = {
    type: 'create' | 'update'
    contentControl: SingleValueType
    close: (loading: boolean, message?: IStatusMessage) => void
    templateId: number
    item: any
}
const defaultValues: IContentLibraryLanguage = {
    Id: 0,
    TemplateKnowledgeControlId: 0,
    Culture: '',
    CultureSelect: [{ key: 'All', name: 'All' }],
    Language: '',
    LanguageWeighting: 0,
    Order: 0,
    Overridden: false,
    AccountKnowledgeLanguageId: 0,
    LanguageDescription: '',
    IsLanguageOverridden: false,
    IsLanguageWeightingOverridden: false,
    IsLanguageDescriptionOverridden: false,
    IsLanguageOrderOverridden: false,
    AccountKnowledgeKey: null,
    AccountKnowledgeLanguageKey: null
}
const ContentLibraryForm = ({ type, contentControl, close, templateId, item }: RulesFormProps) => {

    const [cultures, setCultures] = useState<ITag[]>([])
    const [culture, setCulture] = useState<ITag[]>([])
    const typeForm: string = useMemo(() => { return item.LanguageOptions !== undefined ? 'create' : 'update' }, [item.LanguageOptions])
    const { control, handleSubmit, formState: { errors }, setValue } = useForm<IContentLibraryLanguage>({
        defaultValues: useMemo(() => {
            if (item.LanguageOptions !== undefined) {
                return defaultValues
            }
            return item

        }, [item])
    })
    const { t } = useTranslation('assembly')
    const saveForm = (formData: IContentLibraryLanguage) => {
        const TemplateKnowledgeControlId = item.TemplateKnowledgeControlId ?? item.Id
        if (TemplateKnowledgeControlId) {
            formData.LanguageDescription = formData.LanguageDescription?.trim()
            formData.TemplateKnowledgeControlId = TemplateKnowledgeControlId
            formData.Culture = formData.Culture === '' ? 'All' : formData.Culture
            if (typeForm === 'create') {
                DocumentAssemblyService.createContentLibrary(templateId, TemplateKnowledgeControlId, JSON.stringify([formData])).then(({ data }: any) => {
                    if (data.Success === false) {
                        close(true, { Type: 'error', Message: <>Error creating language for <strong>{contentControl?.label}</strong>: {data.ExceptionMessage}</> })
                        return
                    }
                    close(true, {
                        Type: 'success', Message: <>Content Library for <strong>{contentControl?.label}</strong> was successfully added.</>
                    })
                })
            } else {
                DocumentAssemblyService.updateContentLibrary(templateId, TemplateKnowledgeControlId, formData.Id, JSON.stringify([formData])).then(({ data }: any) => {
                    if (data.Success === false) {
                        close(true, { Type: 'error', Message: <>Error updating language for <strong>{contentControl?.label}</strong>: {data.ExceptionMessage}</> })
                        return
                    }
                    close(true, {
                        Type: 'success', Message: <>Content Library for <strong>{contentControl?.label}</strong> was successfully updated.</>
                    })
                })
            }

        }

    }
    const [editorState, setEditorState] = useState<EditorValue>(EditorValue.createEmpty())
    const handleRichTextChange = (value: EditorValue) => {
        setValue('Language', value.toString('html'))
        setEditorState(value)
    }
    useEffect(() => {
        AppService.getCultures().then(({ data }: any) => {
            setCultures(() => {
                return data.map((culture: any) => {
                    return { key: culture.Key, name: culture.Value }
                })
            })
        })
        if (item?.Language) {
            const decodedString = new TextDecoder().decode(Uint8Array.from(atob(item.Language), c => c.charCodeAt(0)));
            setEditorState(() => {

                return EditorValue.createFromString(decodedString, 'html')
            })
            setValue('Language', decodedString)
        }
    }, [item, setValue])
    const getTagFromCultureString = useCallback((str: string): ITag[] => {
        const _filterText = str.trim() === 'All' ? '' : str.trim()
        const item = cultures.find(culture => culture.key === _filterText)
        return item ? [item] : []
    }, [cultures])

    useEffect(() => {

        setCulture(getTagFromCultureString(item?.Culture ?? ''))

    }, [getTagFromCultureString, item, item.Culture])

    const handleCultureChange = (items?: ITag[] | undefined) => {
        if (items) {
            setCulture(items)
            setValue('Culture', `${items[0]?.key}`)
        }
    }
    const filterSuggestedTags = useCallback((filterText: string, tagList: ITag[] | undefined): ITag[] => {
        return filterText ? cultures.filter(
            culture => culture.name?.toLowerCase().includes(filterText?.toLowerCase())
        ) : [];
    }, [cultures])


    const getTextFromItem = (item: ITag) => `${item.key}`;
    const modalClose = () => {
        close(false)
    }
    return (
        <form onSubmit={handleSubmit(saveForm)}>
            <div>

                <div className="scrollVisible" style={{
                    overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh"
                }}>
                    <Stack styles={{ root: { paddingRight: "10px" } }}>
                        <div className={contentStyles.section}>

                            <TagPicker
                                removeButtonAriaLabel="Remove"
                                itemLimit={1}
                                selectedItems={culture}
                                onChange={handleCultureChange}
                                onResolveSuggestions={filterSuggestedTags}
                                getTextFromItem={getTextFromItem}
                                pickerSuggestionsProps={{
                                    suggestionsHeaderText: 'Suggested cultures',
                                    noResultsFoundText: 'No cultures found',
                                }}
                                inputProps={{
                                    id: 'picker1',
                                }}
                            />
                        </div>
                        <div className={contentStyles.section}>
                            <Label required>Description</Label>
                            <Controller name="LanguageDescription" control={control} rules={{ required: true, pattern: /\S/ }}
                                render={({ field }) => <TextField {...field} />}
                            />
                            <span className={contentStyles.error}>{errors.LanguageDescription && <p>This field is required</p>}</span>
                        </div>
                        <div className={contentStyles.section}>
                            <Label>Content</Label>
                            <Controller name="Language" control={control}
                                render={({ field }) =>
                                    <div id={field.name} style={{ height: "300px", paddingBottom: "10px" }}>
                                        <RichTextEditor editorClassName="richText" className="richText"
                                         toolbarConfig={DEFAULT_RICHTOOLBAR}
                                          value={editorState} onChange={handleRichTextChange} />
                                    </div>
                                }
                            />
                        </div>
                    </Stack>
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <DialogFooter>
                        <PrimaryButton id="btnCreateUpdate" type="submit" text={typeForm === 'create' ? 'Create Language' : 'Update Language'} disabled={false} title={typeForm === 'create' ? 'Create Language' : 'Update Language'} />
                        <DefaultButton id="btnCancel" onClick={modalClose} text={t('formSettings.btnCancel')} title={t('formSettings.btnCancel')} />
                    </DialogFooter>
                </div>
            </div>
        </form>
    )
}

const contentStyles = mergeStyleSets({
    section: {
        padding: '0.5rem 0'
    },
    error: {
        color: '#a4262c'
    }
})
export default ContentLibraryForm