import HttpRequest from "./../../services/HttpRequest";
import {IDocument} from "../../App";
import Helper, {IDocumentRequest, ITemplateGeneral} from "../../Helpers/Helper";
import {IPropertyBag} from "../DataPanel/DataPanel";
import {AttachmentSettings} from "../../interfaces/IPropertyBagSettings";
import {Buffer} from "buffer";




// TODO separate in new component
/**
 * Load Record data
 * @param id
 * @param IsDocx
 */
export const loadRecord = async (id: number, IsDocx:boolean = true): Promise<IDocument> => {
    let result: IDocument = {
        AccountId: 0,
        ActiveSubscription: false,
        AllowCheckInOut: false,
        AllowDocumentUpdate: false,
        AllowESign: false,
        AllowNotification: false,
        Attachments: 0,
        AudienceName: "",
        CampaignId: 0,
        Category: "",
        CheckedOut: false,
        CheckedOutDateTime: "",
        CheckedOutUser: "",
        Closed: false,
        CoOwner: false,
        Culture: "",
        Data: "",
        DateCreated: "",
        DateUpdated: "",
        Description: "",
        Executed: false,
        FullName: "",
        HasSubRecord: false,
        IconName: "",
        Id: 0,
        IsCustom: false,
        LastActivity: "",
        LastNote: "",
        MimeType: "",
        Name: "",
        OnBehalfUser: "",
        OnBehalfUserFullName: "",
        Owner: false,
        PrimaryId: 0,
        SubRecords: [],
        SubscriptionStatusMessage: "",
        Suspended: false,
        TemplateId: 0,
        TemplatePropertyBag: "",
        TemplateVersion: "",
        Tickler: "",
        UserName: "",
        Versions: 0
    }
    const req: IDocumentRequest = {
        UserName: Helper.getUsername(),
        SessionKey: Helper.getSessionKey(),
        AccountId: Helper.getDefaultAccount(),
        DocumentId: "-1",
        Id: id.toString(),
        IsPreview: false,
        IsDownload: true,
        IsDocx: IsDocx
    }

    await Helper.getDocumentDownload(req).then((data) => {
        try {
            result = data
        } catch {
            throw new Error()
        }
    })
    return result
}

// TODO separate in new component
/**
 * load Template data
 * @param id
 */
export const loadTemplate = async (id: number): Promise<ITemplateGeneral> => {
    let result: ITemplateGeneral = {
        Audience: undefined,
        Category: undefined,
        Description: undefined,
        DisplayName: undefined,
        IsSubForm: undefined,
        PropertyBag: undefined,
        Tooltip: undefined,
        allowNotification: undefined,
        allowSendMail: undefined,
        allowUpdate: undefined,
        allowcheckinout: undefined,
        allowesign: undefined,
        AudienceName: undefined,
        defaultDescription: undefined,
        eSignAccountId: undefined,
        eSignIntegrationKey: undefined,
        eSignPrivateKey: undefined,
        eSignProvider: undefined,
        eSignUrl: undefined,
        eSignUserId: undefined,
        emailMoniker: undefined,
        fileName: "",
        templateName: undefined
    }
    await Helper.getDocumentOnlyTemplateId(id.toString(), true).then((data) => {
        try {
            result = data
        } catch {
            throw new Error()
        }
    })
    return result
}


/**
 * load Property bag data
 * @param propertyBag
 */
export const loadPropertybag = async (propertyBag: string): Promise<IPropertyBag> => {

    let jsonBag: IPropertyBag = {
        DocumentDelivery: "",
        Clon: "",
        Culture: "",
        CultureName: "",
        Released: false,
        emailMoniker: "",
        AllowDocX: false,
        AllowSendMail: false,
        DocumentCreation: false,
        DocumentUpdate: false,
        DocumentDelete: false,
        DocumentShare: false,
        TemplateEdit: false,
        TemplateShare: false,
        TemplateDelete: false,
        DefaultDescription: "",
        attachment: {} as AttachmentSettings,
        SupressPreview: false,
        RedirectURL: ""
    }

    try {
        if (propertyBag !== null) jsonBag = JSON.parse(Buffer.from(propertyBag, 'base64').toString('utf8'))

    } catch (errors ){
        console.log(errors)
        throw new Error()
    }

    return jsonBag

}


const RenewalsAndAlertsService = {


    /**
     * get State management
     * @param request
     */
    getStatemanagement: async (request: RequestSettings): Promise<IClientResponse> => {
        let {data}: any = await HttpRequest.getWithSession('statemanagement', request)

        return new Promise<IClientResponse>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },

    /**
     * Create Template Definition
     * @param request
     */
    postAplicationRenewal: async (request: RequestCreateTemplateDefinition): Promise<HttpClientResponse> => {
        let {data}: any = await HttpRequest.postWithSession('DocumentOnlyAlertRenewal/CreateTemplateDefinition', request)

        return new Promise<HttpClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({data})
            resolve(data)
        })
    },

    /**
     * Update Template Definition
     * @param request
     */
    putAplicationRenewal: async (request: RequestUpdateTemplateDefinition): Promise<HttpClientResponse> => {
        let {data}: any = await HttpRequest.postWithSession('DocumentOnlyAlertRenewal/UpdateTemplateDefinition', request)

        return new Promise<HttpClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({data})
            resolve(data)
        })
    },

    /**
     * Get Context Template
     * @param request
     */
    getContextTemplate: async (request: RequestGetContextTemplate): Promise<HttpClientResponse> => {
        let {data}: any = await HttpRequest.getWithSession('DocumentOnlyAlertRenewal/GetContextTemplate', request)

        return new Promise<HttpClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({data})
            resolve(data)
        })
    },

    /**
     * Delete Template Definition
     * @param request
     */
    postDeleteTemplateDefinition: async (request: RequestDeleteTemplateDefinition): Promise<HttpClientResponse> => {
        let {data}: any = await HttpRequest.postWithSession('DocumentOnlyAlertRenewal/DeleteTemplateDefinition', request)

        return new Promise<HttpClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({data})
            resolve(data)
        })
    },

    /**
     * Get Context Record
     * @param request
     */
    getContextRecord: async (request: RequestGetContextRecord): Promise<HttpClientResponse> => {
        let {data}: any = await HttpRequest.getWithSession('DocumentOnlyAlertRenewal/GetContextRecord', request)

        return new Promise<HttpClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({data})
            resolve(data)
        })
    },

    /**
     * Create Record Definition
     * @param request
     */
    postCreateRecordDefinition: async (request: RequestCreateRecordDefinition): Promise<HttpClientResponse> => {
        let {data}: any = await HttpRequest.postWithSession('DocumentOnlyAlertRenewal/CreateRecordDefinition', request)

        return new Promise<HttpClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({data})
            resolve(data)
        })
    },

    /**
     * Delete Record Definition
     * @param request
     */
    postDeleteRecordDefinition: async (request: RequestDeleteRecordDefinition): Promise<HttpClientResponse> => {
        let {data}: any = await HttpRequest.postWithSession('DocumentOnlyAlertRenewal/DeleteRecordDefinition', request)

        return new Promise<HttpClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({data})
            resolve(data)
        })
    },

    /**
     * update Record Definition
     * @param request
     */
    postUpdateRecordDefinition: async (request: RequestUpdateRecordDefinition): Promise<HttpClientResponse> => {
        let {data}: any = await HttpRequest.postWithSession('DocumentOnlyAlertRenewal/updateRecordDefinition', request)

        return new Promise<HttpClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({data})
            resolve(data)
        })
    },


    /**
     * Get Renewal Count
     */
    getRenewalCount: async (): Promise<HttpClientResponse> => {
        let {data}: any = await HttpRequest.getWithSession('DocumentOnlyAlertRenewal/GetRenewalCount', {})

        return new Promise<HttpClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({data})
            resolve(data)
        })
    },

    /**
     * Get Context Renewals
     * @param request
     */
    getContextRenewals: async (request: RequestGetContextRenewals): Promise<HttpClientResponse> => {
        let {data}: any = await HttpRequest.getWithSession('DocumentOnlyAlertRenewal/GetContextRenewals', request)

        return new Promise<HttpClientResponse>((resolve, reject) => {
            if (data.success === undefined) reject({data})
            resolve(data)
        })
    },

}

interface IClientResponse {
    CoAuthor: string
    Layout: string
    Owner: string
    Pages: string
    Released: boolean
    TemplateId: string
}

interface HttpClientResponse {
    messageError: string;
    result: string;
    bag: string;
    success: boolean;
}


interface RequestSettings {
    templateId: number
    User: ''
}

interface RequestGetContextRecord {
    Id: number
}

interface RequestGetContextTemplate {
    Id: number
}

interface RequestDeleteRecordDefinition {
    Id: number
    TemplateId: number
    RecordId: number
}

interface RequestDeleteTemplateDefinition {
    Id: number
    TemplateId: number
}

interface RequestGetContextRenewals {
    TemplateId: number
}

export interface ResponseGetContextTemplate {
    Id: number,
    TemplateId: number,
    RenewalMonths: number,
    NotifyTriggerDaysBeforeRenewal: number,
    Name: string;
    Description: string;
    NotifyTriggerFrequency: string;
    Subject: string;
    Body: string;
    To: string;
}

export interface ResponseGetContextRenewals {
    Id: number
    DocumentRecordId: number
    TemplateId: number
    RenewalDate: string
    NotifyTriggerDaysBeforeRenewal: number
    LastNotificationDate: string
    LastNotificationCount: number
    TemplateName: string
    TemplateDescription: string
    NotifyTriggerFrequency: string
    EmailSubject: string
    EmailBody: string
    EmailTo: string
    RecordReference: string
    Subject: string
    RecordUpdated: string
    TemplateVersion: string
    UserName: string
    Category: string
    Description: string
    FullName: string
    AllowCheckInOut: boolean
    AllowESign: boolean
    AllowDocumentUpdate: boolean
    AllowNotification: boolean
    Owner: boolean
    CoOwner: boolean
    TemplatePropertyBag: string
    DisplayName: string
    AudienceName: string
    Executed: boolean
    RenewalMonths: number
    OnBehalfUserFullName: string | null
    OnBehalfUserDisplayName: string | null
    OnBehalfUser: string | null
    IsPending: boolean
    Suspended: boolean
    Closed: boolean
    CheckedOut: boolean
}

export interface ResponseGetContextRecord {
    Id: number
    RecordId: number
    TemplateId: number
    RenewalDate: string
    RenewalMonths: number
    NotifyTriggerDaysBeforeRenewal: number
    NotifyTriggerFrequency: string
    LastNotificationDate?: string
    LastNotificationCount?: number
    Active: boolean
    Category: string
    Description: string
    DisplayName: string
}

export interface ResponseRenewalCount {
    RenewalCount: number,
}

export interface IRecordRenewal {
    Id: number,
    TemplateId: number,
    RecordId: number,
    Name: string
}


interface RequestCreateTemplateDefinition {
    TemplateId: number
    Description: string
    Name: string
    Subject: string
    To: string
    Body: string
    NotifyTriggerFrequency: string
    NotifyTriggerDaysBeforeRenewal: number
    RenewalMonths: number
}

interface RequestCreateRecordDefinition {
    TemplateId: number
    RecordId: number
    NotifyTriggerFrequency: string
    NotifyTriggerDaysBeforeRenewal: number
    RenewalMonths: number
    RenewalDate: string
}

interface RequestUpdateTemplateDefinition {
    Id: number
    TemplateId: number,
    Description: string
    Name: string
    Subject: string
    To: string
    Body: string
    NotifyTriggerFrequency: string
    NotifyTriggerDaysBeforeRenewal: number
    RenewalMonths: number
}


interface RequestUpdateRecordDefinition {
    TemplateId: number
    RecordId: number
    NotifyTriggerFrequency: string
    NotifyTriggerDaysBeforeRenewal: number
    RenewalMonths: number
    RenewalDate: string
    Active: boolean
}

export default RenewalsAndAlertsService