import {DefaultButton, DialogFooter, IDialogFooterStyles, PrimaryButton, Stack, mergeStyleSets} from "@fluentui/react";
import React, {useEffect, useMemo, useReducer, useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import CustomPaginate, {PageItem} from "../../../share/CustomPaginate";
import {useCustomForm} from "./hooks/useCustomForm";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {useBranding} from "../../../hooks/useBranding";

type FormPaginateProps = {
    pages: number
    currentPage: number
    forcePage: number
    showPagination: boolean
    submitDisable: boolean
    setForcePage: React.Dispatch<React.SetStateAction<number>>
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    handleCloseModal: () => void
    onSave: (items?: PageItem[] | undefined) => void
    isWizardPreview: boolean
    data: any
}
export default function FormPaginate({
                                         pages, currentPage, forcePage, setForcePage, showPagination, handleCloseModal,
                                         submitDisable, onSave, isWizardPreview, data,
                                         setCurrentPage
                                     }: FormPaginateProps) {
    const {branding} = useBranding()
    const {getValues} = useFormContext()
    const {t} = useTranslation(["common", "wizard", "preview"]);
    const {layoutsRef, triggerPages} = useCustomForm()
    
    const [triggerRender, setTriggerRender] = useState(0)
    const [customPages, setCustomPages] = useState<PageItem[]>(() => {
        return Array.from(Array(pages).keys()).map((page) => {
            return {
                index: page,
                name: `${page + 1}`,
                disabled: triggerPages.get(`${page + 1}`) ?? false
            }
        }) as PageItem[]
    })    
    

    const contentStyles = mergeStyleSets({
        pagination: {
            padding: 0,
            selectors: {
                "ul": {
                    display: "inline-block"
                },
                "li": {
                    fontSize: "12pt",
                    color: branding.theme.palette.themeDarker,
                    display: "inline-block",
                    border: `solid 1px ${branding.theme.palette.themeDarker}`,
                    cursor: "pointer",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    borderCollapse: "collapse",
                    fontWeight: "500"
                }
            }
        },
        active: {
            backgroundColor: branding.theme.palette.themeDarker,
            selectors: {
                "span": {
                    color: branding.theme.palette.white
                }
            }
        }
    })
       
    /**
     * Force to enable/disable a page
     */
    useEffect(() => {
        if (triggerPages.size > 0) {
            const updatePages = customPages.map((page) => {
                return {
                    index: page.index,
                    name: page.name,
                    disabled: triggerPages.get(`${page.index + 1}`) ?? false
                }
            }) as PageItem[]
            
            setCustomPages(updatePages)
            
            const firstEnablePage = updatePages.find(x => !x.disabled)
            if(firstEnablePage !== undefined) {
                setTriggerRender(prev => prev + 1)
                setForcePage(firstEnablePage.index)
            }
            else {
                setForcePage(-1)
                setCurrentPage(-1)
            }
            
        }
    }, [triggerPages])

    

    const form = useFormContext()

    const onPageClick = (data: any, callback: (result: boolean | number) => void) => {
        if ((data.isPrevious && data.selected === 0) || (data.isNext && data.selected === pages - 1)) {
            callback(false);
        } else {
            validateInputs(data.nextSelectedPage, callback)
        }
    }

    const showSubmit = useMemo(() => {
        
        if (submitDisable) return true
        if (pages.toString() === currentPage.toString() || (data !== null && data !== "" && data !== undefined)) {
            return true;
        } else {
            const currentPages = [...customPages]
            const lastPage = currentPages.reverse().find(x => !x.disabled)
            return lastPage?.index === (currentPage - 1)
        }
    }, [currentPage, data, pages,  submitDisable, triggerRender])

    const validateInputs = (pagina: number, callback: (result: boolean | number) => void) => {
            form.handleSubmit(
                () => {
                    setForcePage(pagina);
                    setCurrentPage(pagina + 1);
                    callback(true); // Success
                },
                (error) => {
                    const currentPageKeys = Object.keys(error).filter(
                        (key) =>
                            layoutsRef.current
                                .filter((item) => +item.Page === currentPage && !item.Disabled)
                                .map((control) => control.Id)
                                .includes(key) || key === "_shortcutReference"
                    );
                    if (currentPageKeys.length > 0) {
                        document.getElementById(currentPageKeys[0])?.focus();
                    } else {
                        setForcePage(pagina);
                        setCurrentPage(pagina + 1);
                        callback(pagina); // Error
                    }
                }
            )()
    };
    const onSubmit = () => {
            onSave(customPages)
    }

    return <DialogFooter styles={dialogStyles}>
        <div>
            {showPagination ? (
                <Stack horizontal>
                    <Stack horizontal>
                        <CustomPaginate items={customPages} onClick={onPageClick}
                                        previousLabel={"<<"}
                                        nextLabel={">>"} breakLabel={"..."} breakClassName={"break-me"}
                                        pageCount={pages}
                                        marginPagesDisplayed={2} pageRangeDisplayed={5}
                                        containerClassName={contentStyles.pagination}
                                        activeClassName={contentStyles.active} pageLinkClassName={"indexTab"}
                                        previousLinkClassName={"indexTab"}
                                        nextLinkClassName={"indexTab"} forcePage={forcePage}/>
                    </Stack>
                </Stack>
            ) : null}
        </div>
        {!isWizardPreview ? (
            <div className={modalFooter.dialogBtns}>
                {showSubmit ? <PrimaryButton id="btnSubmit" onClick={onSubmit} disabled={!showSubmit} tabIndex={501}
                                             text={t("Common.Button.Submit", {ns: "common"})}
                                             title={t("title.btnSubmit", {ns: "wizard"})}/> : null} :
                {!isMobile && <DefaultButton
                    id="btnCancel"
                    tabIndex={502}
                    onBlur={() => {
                        document.getElementById("help")?.focus();
                    }}
                    onClick={handleCloseModal}
                    text={t(
                        "Common.Button.Cancel",
                        {ns: "common"}
                    )}
                    title={t(
                        "Common.Button.Cancel",
                        {ns: "common"}
                    )}
                />}
            </div>
        ) : (
            <></>
        )}
    </DialogFooter>


}
const dialogStyles: IDialogFooterStyles = {
    actions: {
        display: "flex",
        justifyContent: "space-between",
        position: isMobile ? "relative" : "absolute",
        width: "100%",
        bottom: 0,
        margin: 0,
    },
    actionsRight: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    action: {
        margin: "2px 20px",
    },
};
const modalFooter = mergeStyleSets({
    dialogBtns: {
        display: "flex",
        gap: "8px",
    },
});