import { getTheme, Icon, IDropdownOption, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFCheckBoxControl } from '../../../RHFControls/RHFChekBoxControl';
import { RHFDropDownListSingleSelectControl } from '../../../RHFControls/RHFDropDownListSingleSelectControl';
import { RHFSliderControl } from '../../../RHFControls/RHFSliderControl';
import { RHFTextBoxControl } from '../../../RHFControls/RHFTextBoxControl';
import { RHFToggleControl } from '../../../RHFControls/RHFToggleControl';
import { ILayout } from '../../CustomTemplate';
import { InputType } from '../../Validations';

const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
            ':disabled': {
                backgroundColor: theme.palette.white
            },
        },
    },
    
};

export const InputsList: IDropdownOption[] = [
    {
        key: 0,
        text: '',
    },
    {
        key: 14,
        text: 'Check Box',
    },
    {
        key: 8,
        text: 'Currency',
    },
    {
        key: 6,
        text: 'Date',
    },
    {
        key: 3,
        text: 'Drop Down List',
    },
    {
        key: 4,
        text: 'Drop Down (Multiple selection)',
    },
    {
        key: 16,
        text: 'Image'
    },
    {
        key: 7,
        text: 'Integer',
    },
    {
        key: 13,
        text: 'Rich Text Editor',
    },
    {
        key: 2,
        text: 'Text Area',
    },
    {
        key: 1,
        text: 'Text Box',
    },
    {
        key: 9,
        text: 'Line Break',
        disabled: true,
        hidden: true
    },
    {
        key: 10,
        text: 'Section Header',
        disabled: true,
        hidden: true
    },
    {
        key: 11,
        text: 'Grid List',
        disabled: true,
        hidden: true
    },
    {
        key: 12,
        text: 'Paragraph',
        disabled: true,
        hidden: true
    },
    {
        key: 15,
        text: 'Attachment',
        disabled: true,
        hidden: true
    },
    {
        key: 17,
        text: 'HelpLink',
        disabled: true,
        hidden: true
    },
    {
        key: 18,
        text: 'Integration',
        disabled: true,
        hidden: true,
    }
];

export interface CommonPropertiesProps {
    prefix: string;
    layout: ILayout;
    displayLock: boolean;
    displayAdminOnly: boolean;
    displayColumnSpan: boolean;
    displayReadOnly: boolean;
    displayRequired: boolean;
    disableFieldType: boolean;
    isIntegration?: boolean;
}

const CommonProperties = (props: CommonPropertiesProps) => {
    const { control, setValue, getValues, trigger } = useFormContext();

    const [lock, setLock] = useState(props.layout?.Layout.static)

    useEffect(() => {
        if (props.layout !== undefined) {
            loadCommonPropertiesValues()
        }
    }, [props.layout])

    const loadCommonPropertiesValues = () => {
        if (props.displayLock) {
            setValue(`${props.prefix}_Lock` as any, props.layout.Layout.static!);
        } else {
            setValue(`${props.prefix}_Lock` as any, true);
        }

        if (props.displayAdminOnly) {
            setValue(`${props.prefix}_AdminOnly` as any, props.layout.AdminOnly);
        } else {
            setValue(`${props.prefix}_AdminOnly` as any, false);
        }
        if (props.isIntegration) {
            setValue(`${props.prefix}_FieldKey` as any, props.layout.Id);
        }
        setValue(`${props.prefix}_FieldLabel` as any, props.layout.Label);
        setValue(`${props.prefix}_FieldType` as any, InputsList.find(f => f.key === props.layout.Type));
        setValue(`${props.prefix}_Placeholder` as any, props.layout.Placeholder);
        setValue(`${props.prefix}_ToolTip` as any, props.layout.Tooltip);
        setValue(`${props.prefix}_TooltipUrl` as any, props.layout.TooltipUrl);
        if (props.displayColumnSpan) {
            setValue(`${props.prefix}_ColumnSpan` as any, props.layout.Layout.w)
        } else {
            setValue(`${props.prefix}_ColumnSpan` as any, 1);
        }
        if (props.displayRequired) {
            setValue(`${props.prefix}_Required` as any, props.layout.Validations.Required)
        } else {
            setValue(`${props.prefix}_Required` as any, false)
        }
        if (props.displayReadOnly) {
            setValue(`${props.prefix}_ReadOnly` as any, props.layout.Validations.ReadOnly)
        } else {
            setValue(`${props.prefix}_ReadOnly` as any, false);
        }

    }
    return (
        <>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ paddingLeft: "0px" }}>
                        {props.displayLock ? <RHFToggleControl
                            id={`${props.prefix}_Lock`}
                            title={getValues(`${props.prefix}_Lock` as any) ? "Locked item location." : "Unlocked item location."}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            trigger={trigger}
                            onCallback={(event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => {
                                setLock(checked)
                            }}
                        /> : null}
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ paddingLeft: "0px" }}>
                        {props.displayLock ? <Icon
                            styles={iconButtonStyles}
                            iconName={lock ? "Lock" : "Unlock"}
                            title="Specify whether this control's location is locked." /> : null}
                    </div>
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">

                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ paddingRight: "0px" }}>
                        {props.displayAdminOnly ? <RHFToggleControl
                            id={`${props.prefix}_AdminOnly`}
                            title={getValues(`${props.prefix}_AdminOnly` as any) ? "Available to all authorized users." : "Only available to administrators."}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            trigger={trigger}
                        //checked={props.layout.AdminOnly}
                        /> : null}
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ paddingRight: "0px" }}>
                        {props.displayAdminOnly ? <Icon
                            styles={iconButtonStyles}
                            iconName={"PartyLeader"}
                            title="Specify whether this control is only visible to administrators." /> : null}
                    </div>
                </div>
            </div>
            {props.isIntegration ?
                <RHFTextBoxControl
                    id={`${props.prefix}_FieldKey`}
                    label={"Key"}
                    maxLength={getValues(`${props.prefix}_ColumnSpan` as any) * 100}
                    disabled={getValues(`${props.prefix}_FieldKey` as any) !== undefined ? getValues(`${props.prefix}_FieldKey` as any).key === InputType.GridList : props.layout.Type === InputType.GridList}
                    title={"Enter the name of the key parameter for the integration."}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                //defaultValue={props.layout.Label}
                />
                : null
            }
            
            
            <RHFTextBoxControl
                id={`${props.prefix}_FieldLabel`}
                label={"Field Label"}
                maxLength={getValues(`${props.prefix}_ColumnSpan` as any) * 100}
                disabled={getValues(`${props.prefix}_FieldType` as any) !== undefined ? getValues(`${props.prefix}_FieldType` as any).key === InputType.GridList : props.layout.Type === InputType.GridList}
                title={"Enter label that displays for this control on form wizard."}
                control={control}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
            //defaultValue={props.layout.Label}
            />
            <RHFDropDownListSingleSelectControl
                id={`${props.prefix}_FieldType`}
                label={"Field Type"}
                options={getValues(`${props.prefix}_FieldType` as any) !== undefined ? getValues(`${props.prefix}_FieldType` as any).key === InputType.GridList ? InputsList.filter((value: IDropdownOption, index) => value.key === InputType.Currency || value.key === InputType.DatePicker || value.key === InputType.DropDownList || value.key === InputType.Number || value.key === InputType.Textbox) : InputsList : InputsList}
                disabled={props.disableFieldType}
                title="Select control data input type."
                control={control}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
            />
            <RHFTextBoxControl
                id={`${props.prefix}_Placeholder`}
                label="Placeholder Text"
                title="Enter placeholder text for this control on form wizard."
                control={control}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
            //defaultValue={props.layout.Placeholder} 
            />
            <RHFTextBoxControl
                id={`${props.prefix}_ToolTip`}
                label={"Tooltip Text"}
                title={"Enter tooltip text for this control on form wizard."}
                control={control}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
            //defaultValue={props.layout.Tooltip} 
            />
            <RHFTextBoxControl
                id={`${props.prefix}_TooltipUrl`}
                label={"Tooltip Url"}
                title={"Enter the url to be redirected."}
                control={control}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
            //defaultValue={props.layout.TooltipUrl}
            />
            {props.displayColumnSpan ? <RHFSliderControl
                id={`${props.prefix}_ColumnSpan`}
                label={"Control Column Span"}
                control={control}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
                min={1}
                max={4}
                step={1}
            //defaultValue={props.layout.Layout.w} 
            /> : null}
            {props.displayRequired || props.displayReadOnly ? <Stack horizontal>
                {props.displayRequired ? <Stack.Item grow={1}>
                    <RHFCheckBoxControl
                        id={`${props.prefix}_Required`}
                        label={"Required"}
                        title={"Check mark when input is required for this control."}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                    />
                </Stack.Item> : null}
                {props.displayReadOnly ? <Stack.Item grow={1}>
                    <RHFCheckBoxControl
                        id={`${props.prefix}_ReadOnly`}
                        label={"Read-Only"}
                        title={"Check mark when input is Read Only for this control."}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                    />
                </Stack.Item> : null}
            </Stack> : null}
        </>
    )
}

export default CommonProperties