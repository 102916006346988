
import moment from 'moment-with-locales-es6';
import { CheckboxVisibility, DatePicker, DetailsList, DetailsListLayoutMode, FontIcon, IconButton, Label, PrimaryButton, SelectionMode, Separator, Spinner, SpinnerSize, Stack, TextField, TooltipHost } from "@fluentui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { IMyListOption } from '../../Helpers/Helper';
import React from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';
import AdminLogsService, { DEFAULT_RECORDSPERPAGE } from '../../services/adminlogs';
import { ApplicationLog, LogCatalog } from '../../interfaces/IAdminLog';
import InfiniteScroll from 'react-infinite-scroll-component';
import { decode } from 'base64-arraybuffer';
import { useScroll } from '../../hooks/useScroll';
import { ApplicationLogsColumns, cancelIcon, customDropdownStyles, ErrorLevel, iconButtonMobileStyles, separatorStyles, stackTokens } from './AdminLogs.data';
import {useBranding} from "../../hooks/useBranding";

type ApplicationLogsProps = {
    TemplateId: number
}

function ApplicationLogs(props: ApplicationLogsProps) {
    const { branding } = useBranding()
    const [dateCreatedFrom, setCreatedFrom] = useState<Date | null>();
    const [dateCreatedTo, setCreatedTo] = useState<Date | null>();
    const [section, setSection] = useState<IMyListOption | null>(null);
    const [sectionList, setSectionList] = useState<IMyListOption[]>([]);
    const [area, setArea] = useState<IMyListOption | null>(null);
    const [areaList, setAreaList] = useState<IMyListOption[]>([]);
    const [action, setAction] = useState<IMyListOption | null>(null);
    const [actionList, setActionList] = useState<IMyListOption[]>([]);
    const [level, setLevel] = useState<IMyListOption | null>(null);
    const [levelList, setLevelList] = useState<IMyListOption[]>([]);
    const [template, setTemplate] = useState<IMyListOption | null>(null);
    const [templateList, setTemplateList] = useState<IMyListOption[]>([]);
    const [identity, setIdentity] = useState("")
    const [logList, setLogList] = useState<ApplicationLog[]>([]);
    const [hasMore, setHasMore] = useState(true)
    const [numberOfRecords, setNumberOfRecords] = useState(0)
    const [notResultsMessage, setNotResultsMessage] = useState("")
    const pageNumber = useRef(1)
    const scrollDiv = useRef<HTMLDivElement>(null)
    const { toTop } = useScroll({ scrollDiv })

    const searchLogsCallback = useCallback(() => {
        return AdminLogsService.getApplicationLogs({
            TemplateId: props.TemplateId > 0 ? props.TemplateId : template !== null ? Number(template?.value) : -1,
            PageNumber: pageNumber.current,
            DateFrom: dateCreatedFrom!,
            DateTo: dateCreatedTo!,
            TargetUserName: identity,
            Section: section?.value,
            Area: area?.value,
            Action: action?.value,
            ErrorLevel: level?.value
        })
    }, [action, area, dateCreatedFrom, dateCreatedTo, identity, level, props.TemplateId, section, template])

    const onFormatDate = (date?: Date): string => {
        return date !== null ? moment.utc(date).local().format('LL') : null;
    };
    const cleanCreatedFrom = React.useCallback((): void => {
        setCreatedFrom(undefined);
    }, []);
    const cleanCreatedTo = React.useCallback((): void => {
        setCreatedTo(undefined);
    }, []);
    const onSectionChange = async (newValue: SingleValue<IMyListOption>, actionMeta: ActionMeta<IMyListOption>) => {
        if (newValue !== null) {
            setSection((prev) => newValue)
        } else {
            cleanSection()
        }
    };
    const cleanSection = () => {
        setSection(null)
    }
    const onAreaChange = async (newValue: SingleValue<IMyListOption>, actionMeta: ActionMeta<IMyListOption>) => {
        if (newValue !== null) {
            setArea(newValue)
        } else {
            cleanArea()
        }
    };
    const cleanArea = () => {
        setArea(null)
    }
    const onActionChange = async (newValue: SingleValue<IMyListOption>, actionMeta: ActionMeta<IMyListOption>) => {
        if (newValue !== null) {
            setAction(newValue)
        } else {
            cleanAction()
        }
    };
    const cleanAction = () => {
        setAction(null)
    }
    const onLevelChange = async (newValue: SingleValue<IMyListOption>, actionMeta: ActionMeta<IMyListOption>) => {
        if (newValue !== null) {
            setLevel(newValue)
        } else {
            cleanLevel()
        }
    };
    const cleanLevel = () => {
        setLevel(null)
    }
    const onTemplateChange = async (newValue: SingleValue<IMyListOption>, actionMeta: ActionMeta<IMyListOption>) => {
        if (newValue !== null) {
            setTemplate(newValue)
        } else {
            cleanTemplate()
        }
    };
    const cleanTemplate = () => {
        setTemplate(null)
    }
    const onIdentityChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        setIdentity(value || '');
    }

    const SearchLogs = async () => {
        pageNumber.current = 1
        setHasMore(true)
        searchLogsCallback().then(({ data }: any) => {
            if (!data?.Success) {
                let response = data as ApplicationLog[]
                setLogList(() => response)
                if (response.length > 0) {
                    setNumberOfRecords(() => response[0].RecordsAffected)
                    setNotResultsMessage("")
                }
                else {
                    setNumberOfRecords(() => 0)
                    setNotResultsMessage("Not Results Found!")
                }
                toTop()
            } else {
                console.log(data.ExceptionMessage)
            }
        })
    }

    useEffect(() => {
        AdminLogsService.getCatalog({
            DynamicValueListKey: 'Section'
        }).then((data: LogCatalog[]) => {
            setSectionList((prev) => dropdownFormat(data))
        })
        AdminLogsService.getCatalog({
            DynamicValueListKey: 'Action'
        }).then((data: LogCatalog[]) => {
            setActionList((prev) => dropdownFormat(data))
        })
        AdminLogsService.getCatalog({
            DynamicValueListKey: 'Area'
        }).then((data: LogCatalog[]) => {
            setAreaList((prev) => dropdownFormat(data))
        })
        setLevelList(ErrorLevel)
    }, [])

    useEffect(() => {
        AdminLogsService.getApplicationLogs({
            TemplateId: props.TemplateId,
            PageNumber: pageNumber.current
        }).then(({ data }: any) => {
            if (!data?.Success) {
                let response = data as ApplicationLog[]
                setLogList(() => response)
                if (response.length > 0) {
                    setNumberOfRecords(() => response[0].RecordsAffected)
                    setNotResultsMessage("")
                }
                else {
                    setNumberOfRecords(() => 0)
                    setNotResultsMessage("Not Results Found!")
                }
            } else {
                console.log(data.ExceptionMessage)
            }
        })
        if (props.TemplateId <= 0) {
            AdminLogsService.getDocumentOnlyTemplates().then((data: IMyListOption[]) => {
                setTemplateList((prev) => data)
            })
        }
    }, [props.TemplateId])

    const dropdownFormat = (array: LogCatalog[]): IMyListOption[] => {
        const dropdown: IMyListOption[] = array.map((e) => {
            return {
                value: e.Value,
                label: e.Value
            } as IMyListOption
        })
        return dropdown.sort((a, b) => {
            return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
        })
    }
    const downloadExcel = async () => {
        AdminLogsService.downloadApplicationLogs({
            TemplateId: props.TemplateId > 0 ? props.TemplateId : template !== null ? Number(template?.value) : -1,
            DateFrom: dateCreatedFrom!,
            DateTo: dateCreatedTo!,
            TargetUserName: identity,
            Section: section?.value,
            Area: area?.value,
            Action: action?.value,
            ErrorLevel: level?.value,
        }).then(({ data }: any) => {
            try {
                if (data.Success === undefined) {
                    const decoded = decode(data.File)
                    const fileName = `Data.xlsx`
                    const mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

                    const file = new Blob(
                        [decoded], { type: mimeType });
                    var a = document.createElement("a");
                    a.href = window.URL.createObjectURL(file);
                    a.download = fileName;
                    a.click();

                } else {
                    console.log(data.ExceptionMessage)
                }


            } catch (error) {
                console.log(error)
            }
        })
    }

    const fetchMoreData = () => {
        pageNumber.current += 1

        searchLogsCallback().then(({ data }: any) => {
            try {
                if (!data?.Success) {
                    let dataArray = data as ApplicationLog[]
                    setLogList((prev) => { return [...prev, ...dataArray] })

                    if (dataArray.length < DEFAULT_RECORDSPERPAGE) {
                        setHasMore(false)
                    }
                } else {
                    console.log(data.ExceptionMessage)
                }
            } catch (error) {
                console.log(error)
            }

        })
    }


    const getKey = (item: any, index?: number): string => {
        if (item !== undefined)
            return item.Id!.toString()
        return ""
    }

    return (
        <>
            <label className="ms-fontSize-18" style={{ paddingRight: '10px' }}>Application Logs</label>
            <IconButton className="tabIndexClose" disabled={numberOfRecords <= 0} styles={iconButtonMobileStyles(branding.theme)} iconProps={{ iconName: 'SaveTemplate' }} title="Download Excel" onClick={() => { downloadExcel() }} />
            <label className="ms-fontSize-10">{numberOfRecords} Records</label>
            <Stack styles={{ root: { paddingRight: "10px" } }} tokens={stackTokens}>
                <label />

                <div ref={scrollDiv} id="scrollableDiv" className="scrollVisibleLogs" style={{ overflow: "auto", overflowX: "auto", paddingRight: "10px", maxWidth: "100%", height: "53vh" }}>
                    <div className="ms-Grid-row">
                        {
                            props.TemplateId < 0 ?
                                <>

                                    <div className="ms-Grid-col ms-sm4">
                                        <TooltipHost content="Select Application.">
                                            <Label>{"Application"}</Label>
                                            <Select
                                                className="basic-single"
                                                id="LogTemplate"
                                                aria-label="LogTemplate"
                                                defaultValue={template}
                                                options={templateList}
                                                onChange={onTemplateChange}
                                                placeholder="Select Application"
                                                isClearable={true}
                                                isSearchable={false}
                                                styles={customDropdownStyles}
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                    DropdownIndicator: () => (
                                                        <FontIcon
                                                            iconName="ChevronDown"
                                                            style={{ paddingRight: "7px", fontSize: "12px" }}
                                                        />
                                                    ),
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: "#EDEBE9",
                                                    },
                                                })}
                                            />
                                        </TooltipHost>
                                    </div>
                                </>
                                :
                                null
                        }
                        <div className="ms-Grid-col ms-sm3">
                            <DatePicker maxDate={dateCreatedTo!} title="Select Date From." isMonthPickerVisible={true} id="logDateFrom" placeholder="Select Date" ariaLabel="Select a date" label="Date From" formatDate={onFormatDate} value={dateCreatedFrom!} onSelectDate={setCreatedFrom as (date: Date | null | undefined) => void} />
                        </div>
                        <div className="ms-Grid-col ms-sm1">
                            <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel="Clean" onClick={cleanCreatedFrom} title="Clean" id='CleanFromDateLog' />
                        </div>
                        <div className="ms-Grid-col ms-sm3">
                            <DatePicker minDate={dateCreatedFrom!} title="Select Date To." isMonthPickerVisible={true} id="logDateTo" placeholder="Select Date" ariaLabel="Select a date" label="Date To" formatDate={onFormatDate} value={dateCreatedTo!} onSelectDate={setCreatedTo as (date: Date | null | undefined) => void} />
                        </div>
                        <div className="ms-Grid-col ms-sm1">
                            <IconButton styles={{ root: { right: "15px", top: "30px" } }} iconProps={cancelIcon} ariaLabel="Clean" onClick={cleanCreatedTo} title="Clean" id='CleanToDateLog' />
                        </div>
                        {props.TemplateId < 0 ?
                            null
                            :
                            <div className="ms-Grid-col ms-sm4" >
                                <TextField acceptCharset='' label="User Email" maxLength={50} onChange={onIdentityChange} value={identity} placeholder="Enter User Email" title="Enter User Email." />
                            </div>
                        }

                    </div>
                    <div className="ms-Grid-row">
                        {props.TemplateId > 0 ?
                            null
                            :
                            <div className="ms-Grid-col ms-sm4" >
                                <TextField acceptCharset='' label="User Email" maxLength={50} onChange={onIdentityChange} value={identity} placeholder="Enter User Email" title="Enter User Email." />
                            </div>
                        }
                        <div className={props.TemplateId > 0 ? "ms-Grid-col ms-sm3" : "ms-Grid-col ms-sm2"}>
                            <TooltipHost content="Select Section.">
                                <Label>{"Section"}</Label>
                                <Select
                                    className="basic-single"
                                    id="LogSection"
                                    aria-label="LogSection"
                                    defaultValue={section}
                                    options={sectionList}
                                    onChange={onSectionChange}
                                    placeholder="Select Section"
                                    isClearable={true}
                                    isSearchable={false}
                                    styles={customDropdownStyles}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator: () => (
                                            <FontIcon
                                                iconName="ChevronDown"
                                                style={{ paddingRight: "7px", fontSize: "12px" }}
                                            />
                                        ),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#EDEBE9",
                                        },
                                    })}
                                />
                            </TooltipHost>
                        </div>
                        <div className={props.TemplateId > 0 ? "ms-Grid-col ms-sm3" : "ms-Grid-col ms-sm2"}>
                            <TooltipHost content="Select Area.">
                                <Label>{"Area"}</Label>
                                <Select
                                    className="basic-single"
                                    id="LogArea"
                                    aria-label="LogArea"
                                    defaultValue={area}
                                    options={areaList}
                                    onChange={onAreaChange}
                                    placeholder="Select Area"
                                    isClearable={true}
                                    isSearchable={false}
                                    styles={customDropdownStyles}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator: () => (
                                            <FontIcon
                                                iconName="ChevronDown"
                                                style={{ paddingRight: "7px", fontSize: "12px" }}
                                            />
                                        ),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#EDEBE9",
                                        },
                                    })}
                                />
                            </TooltipHost>
                        </div>
                        <div className={props.TemplateId > 0 ? "ms-Grid-col ms-sm3" : "ms-Grid-col ms-sm2"}>
                            <TooltipHost content="Select Action.">
                                <Label>{"Action"}</Label>
                                <Select
                                    className="basic-single"
                                    id="LogAction"
                                    aria-label="LogAction"
                                    defaultValue={action}
                                    options={actionList}
                                    onChange={onActionChange}
                                    placeholder="Select Action"
                                    isClearable={true}
                                    isSearchable={false}
                                    styles={customDropdownStyles}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator: () => (
                                            <FontIcon
                                                iconName="ChevronDown"
                                                style={{ paddingRight: "7px", fontSize: "12px" }}
                                            />
                                        ),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#EDEBE9",
                                        },
                                    })}
                                />
                            </TooltipHost>
                        </div>
                        <div className={props.TemplateId > 0 ? "ms-Grid-col ms-sm3" : "ms-Grid-col ms-sm2"}>
                            <TooltipHost content="Select Type.">
                                <Label>{"Type"}</Label>
                                <Select
                                    className="basic-single"
                                    id="LogType"
                                    aria-label="LogType"
                                    defaultValue={level}
                                    options={levelList}
                                    onChange={onLevelChange}
                                    placeholder="Select Type"
                                    isClearable={true}
                                    isSearchable={false}
                                    styles={customDropdownStyles}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator: () => (
                                            <FontIcon
                                                iconName="ChevronDown"
                                                style={{ paddingRight: "7px", fontSize: "12px" }}
                                            />
                                        ),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#EDEBE9",
                                        },
                                    })}
                                />
                            </TooltipHost>
                        </div>
                    </div>
                    <br />
                    <Separator alignContent="start" styles={separatorStyles}>
                        <PrimaryButton key="LogSearch" onClick={() => { SearchLogs() }} allowDisabledFocus title="Search." text="Search" />
                    </Separator>
                    <div style={numberOfRecords > 0 ? { display: 'inline-block' } : {}}>
                        {numberOfRecords > 0 ?
                            <InfiniteScroll
                                dataLength={logList.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={logList.length > 0 && hasMore === true ? <Spinner size={SpinnerSize.large} label="Loading" ariaLive="assertive" /> : null}
                                scrollableTarget="scrollableDiv"
                                scrollThreshold={0.9}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>End of Results!</b>
                                    </p>
                                }
                            >
                                <DetailsList
                                    items={logList}
                                    compact={false}
                                    columns={props.TemplateId < 0 ? ApplicationLogsColumns() : ApplicationLogsColumns().filter(x => x.key !== "column0")}
                                    getKey={getKey}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    selectionMode={SelectionMode.single}
                                    selectionPreservedOnEmptyClick={false}
                                    enterModalSelectionOnTouch={true}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                    onShouldVirtualize={() => {
                                        return false;
                                    }}
                                />
                            </InfiniteScroll>
                            :
                            <p style={{ textAlign: 'center' }}>
                                <b>{notResultsMessage}</b>
                            </p>
                        }
                    </div>
                </div>
            </Stack>
        </>
    )
}

export default ApplicationLogs