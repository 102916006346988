import HttpRequest from "../../services/HttpRequest";
import {
    IExternalLinksRequest,
    IExternalLinks,
} from "./ExternalLinkAccess.data";

export const ExternalLinkService = {

    /**
     * getExternalLinks
     * @param request
     */
    getExternalLinks: async (request: IExternalLinksRequest):  Promise<IExternalLinks[]> => {
        let { data }: any = await HttpRequest.postWithSession('ExternalLinks', request)

        return new Promise<IExternalLinks[]>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            try {
                if (data.success !== undefined && !data.success) reject({ error: data.ExceptionMessage ? data.ExceptionMessage : 'Internal Server error'})

                resolve(JSON.parse(data.result))
            } catch (error) {
                reject({error: error})
            }
        })
    }
}