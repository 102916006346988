import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {PrimaryButton, Spinner, SpinnerSize, Stack} from "@fluentui/react";
import {IGetAllManageClientPlansResponse} from "../../services/ManageClientPlans";
import PlanService, {IEvaluateRequest, IPlanResponse} from "../../services/Plan";
import Helper, {IRequestPayment} from "../../Helpers/Helper";
import CustomDialog from "../../share/CustomDialog";
import PaymentService, {IUpdateSubscriptionRequest} from "../../services/Payment";

type Properties = {
    currentBillingPlan: IGetAllManageClientPlansResponse
    currentPlan: IPlanResponse
    newPlan: IPlanResponse
    isDowngrade: boolean
    setShowModal: (status: boolean) => void
}
export const Payment = ({setShowModal, newPlan, isDowngrade, currentBillingPlan, currentPlan}: Properties) => {

    const {t} = useTranslation(['common', 'workspace'])
    const [isLoading, setIsLoading] = useState(false)
    const [showPlanUpdated, setShowPlanUpdated] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [dialogTitle, setDialogTitle] = useState("")
    const [showDialogActions, setShowDialogActions] = useState(false)
    const [message, setMessage] = useState("")

    useEffect(() => {
        setIsLoading(false)
        setShowDialog(false)
        setDialogTitle("")
        setShowDialogActions(false)
        setShowPlanUpdated(false)
    }, [])

    /**
     * Close modal
     */
    const closeModal = () => {
        setShowPlanUpdated(false)
        setShowModal(true)
    }

    /**
     * Get Default API Request
     */
    const getApiRequest = (): string => {
        const req: IRequestPayment = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: sessionStorage.getItem("site")!,

        }
        return JSON.stringify(req);
    }


    /**
     * Function to get the correct translations and plurals
     * @param value
     * @param count
     * @constructor
     */
    function TransactionPluralsUnit(value: string, count: number) {
        const unit: string = value.toLowerCase()
        switch (unit) {
            case "week":
                return t("TransactionUnitWeek", {ns: "mybillingplan", count: count})
            case "year":
                return t("TransactionUnitYear", {ns: "mybillingplan", count: count})
            case "month":
                return t("TransactionUnitMonth", {ns: "mybillingplan", count: count})
            default:
                return ""
        }
    }

    /**
     * Function to get the correct translations
     * @param value
     * @param count
     * @constructor
     */
    function TransactionUnit(value: string) {
        const unit: string = value.toLowerCase()
        switch (unit) {
            case "week":
                return t("label.week", {ns: "common"})
            case "year":
                return t("label.year", {ns: "common"})
            case "month":
                return t("label.month", {ns: "common"})
            default:
                return ""
        }
    }    

    /**
     * validatePlanDowngradeUpgrade
     * @param isDowngrade
     */
    async function validatePlanDowngradeUpgrade(isDowngrade: boolean) {

        setDialogTitle(isDowngrade ? t("downgradePlanTitle", {ns: "mybillingplan"}) : t("upgradePlanTitle", {ns: "mybillingplan"}))

        let messageCurrentPlant = ""
        let messageNewPlan = ""

        if (newPlan.TransactionAmount === 0) {
            messageNewPlan = "NextPlanCheckoutMessageFree"
        } else {
            if (newPlan.TransactionLength === 1) {
                messageNewPlan = "NextPlanCheckoutMessagePer"
            } else {
                messageNewPlan = "NextPlanCheckoutMessageEvery"
            }
        }

        messageNewPlan = t(messageNewPlan, {
            ns: "mybillingplan",
            Name: newPlan.Name,
            TransactionAmount: `${Helper.getCurrencySymbol()}${newPlan.TransactionAmount}`,
            TransactionUnit: TransactionUnit(newPlan.TransactionUnit.trim()),
            TransactionLength: TransactionPluralsUnit(newPlan.TransactionUnit.trim(), newPlan.TransactionLength)
        })


        if (currentPlan.TransactionAmount === 0) {
            messageCurrentPlant = "CurrentPlanCheckoutMessageFree"
        } else {
            if (currentPlan.TransactionLength === 1) {
                messageCurrentPlant = "CurrentPlanCheckoutMessagePer"
            } else {
                messageCurrentPlant = "CurrentPlanCheckoutMessageEvery"
            }
        }

        messageCurrentPlant = t(messageCurrentPlant, {
            ns: "mybillingplan",
            Name: currentPlan.Name,
            TransactionAmount: `${Helper.getCurrencySymbol()}${currentPlan.TransactionAmount}`,
            TransactionUnit: TransactionUnit(currentPlan.TransactionUnit.trim()),
            TransactionLength: TransactionPluralsUnit(currentPlan.TransactionUnit.trim(), currentPlan.TransactionLength),
            Operation: t(isDowngrade ? "Common.Button.Downgrade" : "Common.Button.Upgrade", {ns: "common"})
        })

        setMessage(`${messageNewPlan} ${messageCurrentPlant}`)

        if (!isDowngrade) {

            setShowDialogActions(true)
            setShowDialog(true)

        } else {
            setShowDialogActions(false)
            setIsLoading(true)
            setShowDialog(true)
            const request: IEvaluateRequest = {
                OwnerName: Helper.getUsername(),
                Id: newPlan.Id,
                Operation: "evaluate"
            }
            await PlanService.Evaluate(request)
                .then((data) => {
                    if (data.length > 0) {
                        if (data[0].DowngradeAllowed) {
                            setShowDialogActions(true)
                        } else {
                            setMessage(t("downgradeNotAllowed", {ns: "mybillingplan", Description: data[0].Response}))
                        }
                    } else {
                        setMessage(t("text.errorAndTry", {ns: "common"}))
                    }
                })
                .catch(() => {
                    setMessage(t("text.errorAndTry", {ns: "common"}))
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    /**
     * update Subscription
     */
    const updateSubscription = async () => {
        setIsLoading(true)
        const request: IUpdateSubscriptionRequest = {
            subscriptionId: currentBillingPlan.SubscriptionId,
            PriceId: newPlan.PaymentProviderProductId
        }
        await PaymentService.updateSubscription(request)
            .then(() => {
                setShowPlanUpdated(true)
            })
            .catch()
            .finally(() => {
                setIsLoading(false)
                setShowDialog(false)
            })

    }

    return (
        <>
            <Stack styles={{root: {alignItems: "center"}}}>

                {currentBillingPlan.SubscriptionId !== "-1" && currentBillingPlan.SubscriptionId.toLowerCase() != "na" ?
                    <>
                        {isDowngrade ?
                            <>
                                <PrimaryButton styles={{root: {width: "140px"}}} onClick={() => {
                                    validatePlanDowngradeUpgrade(true).then()
                                }} text={t("Common.Button.Downgrade", {ns: "common"})}
                                               title={t("Common.Button.Downgrade", {ns: "common"})}/>
                            </>
                            :
                            <>
                                <PrimaryButton styles={{root: {width: "140px"}}} onClick={() => {
                                    validatePlanDowngradeUpgrade(false).then()
                                }} text={t("Common.Button.Upgrade", {ns: "common"})}
                                               title={t("Common.Button.Upgrade", {ns: "common"})}/>
                            </>
                        }
                    </>
                    :
                    <>
                        <form action="/payment/create-checkout-session" method="POST">
                            <input type="hidden" name="priceId" value={newPlan.PaymentProviderProductId}/>
                            <input type="hidden" name="path" value={sessionStorage.getItem("site")!}/>
                            <input type="hidden" name="webSite" value={window.location.origin}/>
                            <input type="hidden" name="apiRequest" value={getApiRequest()}/>
                            <input type="hidden" name="OwnerName" value={currentBillingPlan.OwnerName}/>
                            <input type="hidden" name="BillingPlanId" value={newPlan.Id}/>
                            <input type="hidden" name="XIdentity" value={sessionStorage.getItem("x-identity")!}/>
                            <button disabled={false} id="pro-plan-btn" style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                width: "100px",
                                cursor: "pointer",
                                height: "40px",
                                border: "1px solid rgb(0, 120, 212)",
                                color: "rgb(255, 255, 255)",
                                backgroundColor: "rgb(0, 120, 212)"
                            }}>
                                {t("Common.Button.Subscribe", {ns: "common"})}
                            </button>
                        </form>

                    </>
                }
            </Stack>

            {/*region upgrade or donwgrade Dialog*/}
            <CustomDialog title={dialogTitle} titleId={`checkout`}
                          actionText={t('Common.Button.Continue', {ns: 'common'})}
                          confirm={updateSubscription}
                          close={() => {
                              setShowDialog(false)
                          }}
                          showAction={showDialogActions}
                          isDisabled={isLoading}
                          isOpen={showDialog}
            >
                {isLoading ?
                    <>
                        <Spinner size={SpinnerSize.medium} label={t('text.spinner', {ns: 'common'})}
                                 ariaLive="assertive"/>
                    </>
                    :
                    <>
                        <Trans>
                            {message}
                        </Trans>
                    </>
                }

            </CustomDialog>
            {/*endregion*/}


            {/*region upgrade or donwgrade successfull Dialog*/}
            <CustomDialog title={dialogTitle} titleId={`checkout`}
                          closeText={t('Common.Button.Close', {ns: 'common'})}
                          confirm={updateSubscription}
                          close={() => {
                              closeModal()
                          }}
                          showAction={false}
                          isDisabled={false}
                          isOpen={showPlanUpdated}
                          actionText={t('Common.Button.Accept', {ns: 'common'})}
            >
                <>
                    <Trans>
                        {t("planUpdated", {ns: "mybillingplan"})}
                    </Trans>
                </>
            </CustomDialog>
            {/*endregion*/}


        </>
    )
}

