import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

import {
    ActionButton, CommandBarButton,
    CommandButton,
    DefaultButton,
    DialogFooter,
    FontWeights,
    getTheme,
    IChoiceGroupOption,
    Icon,
    IconButton,
    IContextualMenuItem,
    IContextualMenuProps,
    IIconProps,
    INavLinkGroup,
    mergeStyleSets,
    Modal,
    Panel,
    PanelType,
    Selection,
    Separator,
    ShimmeredDetailsList,
    Spinner,
    SpinnerSize,
    StackItem,
    Text
} from '@fluentui/react';
import * as microsoftTeams from "@microsoft/teams-js";
import { useBoolean, useConstCallback } from '@uifabric/react-hooks';
import { customAppContext } from "../context/AppContext";
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { Document, IDocument } from '../App';
import Helper, { DocumentListRequest, IAdvanceSearch, IBaseRequest, IOrderRequest, ITemplateLayoutRequest, RegistrationStatus } from '../Helpers/Helper';
import AccountList from './AccountList/AccountList';
import DataPanel from './DataPanel/DataPanel';
import Disclaimer from './Disclaimer/Disclaimer';
import ErrorAuthCode from './ErrorScreen/ErrorAuthCode';
import ErrorScreen from './ErrorScreen/ErrorScreen';
import BulkDownloadModal from './Settings/BulkDownloadModal'
import Logout from './Main/Logout';
import useIdle from '../hooks/useIdle';
import useAuth from '../hooks/useAuth';
import { isInTeams } from '../Helpers/Utils';
import { Stack } from "@fluentui/react/lib/Stack";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Nav } from "@fluentui/react/lib/Nav";
import { navStyles, nonShrinkingStackItemStyles } from "./CustomTemplates/subforms/AutomaticallyGenerateSubrecords/Style";
import HelpComponentRelative from "./Help/HelpComponentRelative";
import StatusMessage from "../share/StatusMessage";
import { getColumns } from "./CustomTemplates/subforms/AutomaticallyGenerateSubrecords/AutomaticallySubrecords.data";
import { CheckboxVisibility, DetailsListLayoutMode, SelectionMode } from "office-ui-fabric-react/lib/DetailsList";
import { useSignalR } from '../hooks/useSignalR';
import { useBranding } from '../hooks/useBranding';
import { FontSizes } from "@fluentui/react/lib/Styling";
import { useDocument } from '../hooks/useDocument';
import AuthService from '../services/auth';
import { AppContext } from "./Provider";
import MyBillingPlan from "./MyBillingPlan";
import CustomerSupport from "./CustomerSupport";
import { MyActivity } from "./MyActivity/MyActivity";
import HttpRequest from "../services/HttpRequest";
import MyProfile from "./MyProfile";
import UserService, { IGetuserprofileRequest } from "../services/User";

const menuIcon: IIconProps = { iconName: 'GlobalNavButton' };
const theme = getTheme();

const iconButtonStyles = {
    root: {
        color: theme.palette.white,

    },
    rootHovered: {
        color: theme.palette.white,
    },
};
const iconButtonStylesClose = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    }

};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contactIcon: IIconProps = {
    iconName: 'Contact',
    style: { color: "white" },
};
const chevronIcon: IIconProps = { style: { color: "white" } };

let isUpdateInterval = true
export function Main(props: any) {
    const { branding } = useBranding()
    const version = "9.0.3"
    const navLinks: INavLinkGroup[] = []
    const docList: IDocument[] = []
    const navItemLinks: INavLinkGroup[] = [];

    const [haveError, setHaveError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isNewUser, setIsNewUser] = useState(false)
    const [userEmail, setUserEmail] = useState("")
    const [sessionKey, setSessionKey] = useState("")
    const [accountId, setAccountId] = useState("")
    const [registrationStep, setRegistrationStep] = useState("400")
    const [templateList, setTemplateList] = useState(navLinks)
    const [templateLoaded, setTemplateLoaded] = useState(false)
    const [documentList, setDocumentList] = useState(docList)
    const [documentsLoaded, setDocumentsLoaded] = useState(false)
    const [isOpenMenu, { toggle: setIsOpenMenu }] = useBoolean(false);
    const [authCode, setAuthCode] = useState(false)
    let _currentCategory: string | undefined = undefined
    const emptyTemplate = {
        "-1": {
            "key": "-1",
            "name": "",
            "url": "",
            "DocumentOnlyAudienceId": 0,
            "Owner": false,
            "CoOwner": false,
            "CreateDocument": false,
            "AllowCheckInOut": true,
            "allowSendMail": false
        }
    };
    const [customTemplateList, setCustomTemplateList] = useState(emptyTemplate);
    const emptyDoc = new Document();

    const [docSelected, setDocumentSelected] = useState(emptyDoc)
    const [subformItems, setSubformItems] = useState<IContextualMenuItem[]>([]);
    const [isDisabled, setIsDisabled] = useState(true)
    const [templateOnlyList, settemplateOnlyList] = useState(navItemLinks)
    const [expandCollapseIconName, setExpandCollapseIconName] = useState("ExploreContentSingle")
    let sessionOrderId = ""
    const [hideSubscriptionDialog, setHideSubscriptionDialog] = useState(true);
    const [displaySubscriptionRedirectionMessage, setDisplaySubscriptionRedirectionMessage] = useState(false);
    const [SubscriptionDisabled, setSubscriptionDisabled] = useState(false);
    const [isOwnerDocs, setIsOwnerDocs] = useState(false)
    const [isNewUserRegistered, setIsNewUserRegistered] = useState(false)
    let isFromAuth0 = false
    const [isFromAuth, setisFromAuth] = useState(false)
    const lastDocSelectedIsOwner = useConstCallback(() => {
        const doc = selectionState.getSelection()[0] as IDocument
        return doc.Owner
    })
    const cacheAudience = useRef<{ id: string, isInAudience: boolean }[]>([])
    const { setCategoryFocus } = useContext(customAppContext)
    const { setProfile } = useDocument()

    const { t, i18n } = useTranslation(['common', 'profile', 'workspace']);
    const { context } = useContext(AppContext)

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },

        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            width: '50vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyList: {
            width: '70vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyOrder: {
            width: '16vw',
            height: '11vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const [selectionState] = useState(new Selection({
        onSelectionChanged: async () => {
            const selectionCount = selectionState.getSelectedCount();
            switch (selectionCount) {
                case 0:
                    setDocumentSelected(emptyDoc)
                    break;
                case 1:
                    const doc = selectionState.getSelection()[0] as IDocument
                    var BillingActive = await getBillingActive()


                    if (BillingActive) {
                        if (doc.ActiveSubscription && sessionStorage.getItem("billingActive") === 'true') {
                            setHideSubscriptionDialog(true)
                        } else {
                            setHideSubscriptionDialog(false)
                        }
                    }
                    setSubformItems([])
                    setDocumentSelected(doc)
                    if (doc.Versions > 1) {
                        setIsDisabled(false)
                    } else {
                        setIsDisabled(true)
                    }
                    break;
                default:
                    setDocumentSelected(emptyDoc)
                    break;
            }
        },
    }))
    const [showCoachmarkC, setShowCoachmarkC] = useState(false)
    const [showCoachmarkT, setShowCoachmarkT] = useState(false)
    const [showCoachmarkA, setShowCoachmarkA] = useState(false)
    const [showCoachmarkS, setShowCoachmarkS] = useState(false)
    const [isloadComplet, setisloadComplet] = useState(false)
    const [applying, setApplying] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [isSearchActive, setisSearchActive] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const [showMyBillingPlan, setShowMyBillingPlan] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showActivity, setShowActivity] = useState(false)
    const [userFullName, setUserFullName] = useState("")

    const { loginWithRedirect, isAuthenticated, user, logout } = useAuth();

    const [displayDisclaimer, setDisplayDisclaimer] = useState(false)

    const [totalDocs, setTotalDocs] = useState(0)

    const [openContactUs, setOpenContactUs] = useState(false)
    const [openBulkDownload, setOpenBulkDownload] = useState(false)

    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [showOrderSucces, setShowOrderSucces] = useState(false)
    const [ResponsePaymentSuccess, setResponsePaymentSuccess] = useState("")

    const [searchBoxValue, setSearchBoxValue] = useState("");

    const [isAssignedChecked, setIsAssignedChecked] = useState(true);
    const firstUpdate = useRef(true);
    const firstRenderNotifications = useRef(true);

    const waitingTime = useRef(false)
    const waitingForRefresh = useRef(false)
    //const [waitingTime, setWaitingTime] = useState(false)
    //const [waitingForRefresh, setWaitingForRefresh] = useState(false)
    const signalR = useSignalR()


    /**
     * On Dismiss my billing plan
     */
    const onDismissMyBillingPlan = (status: boolean) => {
        setShowMyBillingPlan(status)
    }

    const onChangeAssigned = useCallback((ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        setIsAssignedChecked(!!checked);
    }, []);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            onSearchClear()
        }
    }, [isAssignedChecked]);


    const isCreateUpdate = useRef(false)

    const getMenuProps = (): IContextualMenuProps => {

        const menuOptions: IContextualMenuItem[] = []

        //bulkdownload
        menuOptions.push({
            key: 'bulkdownload',
            text: t('text.bulkdownload', { ns: 'workspace' }),
            iconProps: { iconName: 'Download' },
            onClick: () => {
                setOpenBulkDownload(true)
            },
        })

        if (context.ownAudiences > 0) {
            //myBillingPlan
            menuOptions.push({
                key: 'myBillingPlan',
                text: t('text.myBillingPlan', { ns: 'workspace' }),
                iconProps: { iconName: 'M365InvoicingLogo' },
                onClick: () => {
                    setShowMyBillingPlan(true)
                    // context.setShowSettings(25)
                },
            })
        }

        //profile
        menuOptions.push({
            key: 'profile',
            text: t('text.profile', { ns: 'workspace' }),
            iconProps: { iconName: 'AccountManagement' },
            onClick: () => {
                setShowProfile(true)
            },
        })

        //activity
        menuOptions.push({
            key: 'activity',
            text: t('text.activity', { ns: 'workspace' }),
            iconProps: { iconName: 'AccountActivity' },
            onClick: () => {
                setShowActivity(true)
            },
        })

        //privacy
        menuOptions.push({
            key: 'privacy',
            text: t('text.privacy', { ns: 'workspace' }),
            iconProps: { iconName: 'ProtectionCenterLogo32' },
            onClick: () => {
                window.open('/kim-document-privacy-policy-2021.pdf', '_blank');
            },
        })

        //terms
        menuOptions.push({
            key: 'terms',
            text: t('text.terms', { ns: 'workspace' }),
            iconProps: { iconName: 'Questionnaire' },
            onClick: () => {
                window.open('/Kim-Document-Terms-and-Conditions.pdf', '_blank');
            },
        })

        //terms
        menuOptions.push({
            key: 'data',
            text: t('text.data', { ns: 'workspace' }),
            iconProps: { iconName: 'ProtectedDocument' },
            onClick: () => {
                window.open('/Kim-Document-Data-Processing-Agreement-January-2022.pdf', '_blank');
            },
        })

        //terms
        menuOptions.push({
            key: 'contactus',
            text: t('text.contactus', { ns: 'workspace' }),
            iconProps: { iconName: 'ContactHeart' },
            onClick: () => {
                setOpenContactUs(true)
            },
        })

        //LogOut
        menuOptions.push({
            id: 'LogOut',
            key: 'logout',
            text: t('text.logout', { ns: 'workspace' }),
            iconProps: { iconName: 'SignOut' },
            onClick: () => {
                setShowConfirmationModal(true)
            },
        })

        return {

            items: menuOptions,
            directionalHintFixed: true,
        }
    }


    const menuPropsMobile: IContextualMenuProps = {
        items: [
            {
                key: 'profile',
                text: 'My Profile',
                iconProps: { iconName: 'AccountManagement' },
                onClick: () => {
                    setShowProfile(true)
                },
            },
            {
                key: 'activity',
                text: 'My Activity',
                iconProps: { iconName: 'AccountActivity' },
                onClick: () => {
                    setShowActivity(true)
                },
            },
            {
                key: 'privacy',
                text: 'Privacy Policy',
                iconProps: { iconName: 'ProtectionCenterLogo32' },
                onClick: () => {
                    window.open('/kim-document-privacy-policy-2021.pdf', '_blank');
                },
            },
            {
                key: 'terms',
                text: 'Terms and Conditions',
                iconProps: { iconName: 'Questionnaire' },
                onClick: () => {
                    window.open('/Kim-Document-Terms-and-Conditions.pdf', '_blank');
                },
            },
            {
                key: 'data',
                text: 'Data Processing Agreement',
                iconProps: { iconName: 'ProtectedDocument' },
                onClick: () => {
                    window.open('/Kim-Document-Data-Processing-Agreement-January-2022.pdf', '_blank');
                },
            },
            {
                key: 'contactus',
                text: 'Get Support/Contact Us',
                iconProps: { iconName: 'ContactHeart' },
                onClick: () => {
                    setOpenContactUs(true)
                },
            },
            {
                key: 'logout',
                text: 'Log Out',
                iconProps: { iconName: 'SignOut' },
                onClick: () => {
                    setShowConfirmationModal(true)
                },
            },
        ],
        directionalHintFixed: true,
    };

    const getMenu = () => {
        let menu = (isMobile && sessionStorage.getItem("entityId") !== "Not In Teams") ? menuPropsMobile : getMenuProps()
        return menu
    }
    const sites: IChoiceGroupOption[] = []
    const [siteSelected, setSiteSelected] = useState("")
    const { getAppSettings } = useBranding()

    const { isIdle } = useIdle({ onIdle: logout })
    useEffect(() => {
        const init = async () => {
            getAppSettings()
            var url_string = window.location.href

            var url = new URL(url_string);

            if (url.searchParams.has('order') && url.searchParams.get('order') !== "") {
                sessionOrderId = url.searchParams.get('order')!
            }
            else if (url.hash !== "" && window.location.hash.split("#")[1] != null && window.location.hash.split("#")[1] != undefined) {
                var tag = atob(window.location.hash.split("#")[1])
                isFromAuth0 = tag.includes('isFromAuth')
                // customHistory.push(window.location.pathname)
            }

            else { sessionOrderId = "" }

            if (user?.email !== undefined) {
                const newConnection = new HubConnectionBuilder()
                    .withUrl(`${window.location.origin}/records?username=${user?.email!}`, {
                        headers: await Helper.getHeaders()
                    })
                    .withAutomaticReconnect()
                    .build();

                signalR.setConnection(newConnection);
            }

            if (props.isLoggedIn || sessionOrderId !== "" || isFromAuth0) {
                await Helper.loadSites()

                var site = await Helper.getSite()!
                var username = Helper.getUsername()

                if (username === "null" || site === "null")
                    throw new Error("No User Found")

                await auth(username, site)
                setDisplayDisclaimer(getDisplayDisclaimer())
            }
            else {
                if (isAuthenticated) {
                    if (sessionStorage.getItem("username") != user?.email) {
                        sessionStorage.setItem("site", "");
                    }
                    sessionStorage.setItem("username", user?.email!)
                    sessionStorage.setItem("entityId", "Not In Teams")
                    if (sessionStorage.getItem("isNewuser") === "true") {
                        setIsNewUserRegistered(true)
                    }
                    const load = async () => {
                        await Helper.loadSites()
                        var site = await Helper.getSite()!
                        var username = Helper.getUsername()

                        if (username === "null" || site === "null")
                            throw new Error("No User Found")

                        await auth(username, site)
                    }
                    load()
                    setDisplayDisclaimer(getDisplayDisclaimer())
                }
                else {
                    loginWithRedirect(window.location.href)
                }
            }
        }
        if ((props.isCookieSetUp || !isInTeams()) && isAuthenticated !== undefined) {
            init().then(() => {

            }).catch(e => { console.log(e) })
        }


    }, [props.isCookieSetUp, isAuthenticated])

    const getDisplayDisclaimer = () => {
        var nameEQ = "KimDisclaimer" + Helper.getUsername() + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return false
        }
        return true;
    }

    const auth = useConstCallback(async (userName: string, site: string) => {
        const sessionKey = Helper.getSessionKey()

        var res = await Helper.authenticate(userName, site)


        const request: IGetuserprofileRequest = {
            UserName: Helper.getUsername(),
            Profile: Helper.getUsername()
        }
        UserService.GetUserProfile(request)
            .then((data) => {
                setProfile(data)
            })

        var auth0 = isFromAuth0

        if (res === undefined) {
            setErrorMessage('There was an error when loading the application')
            setHaveError(true)
            setApplying(false)
            throw new Error("Undefined Error while loading the application")
        }
        if (res.Status === 404 && (sessionKey != null && sessionKey !== "-1")) {
            window.location.replace('/logout')
            return
        }
        const email: string = Helper.getUsername()
        const key: string = res.SessionKey!
        const id: string = res.AccountId!
        const error: string = res.Message

        setUserEmail(email.toString())
        setSessionKey(key)
        setAccountId(id)

        sessionStorage.setItem("pageCount", "0")
        sessionStorage.setItem("documentsLoaded", "false")
        sessionStorage.setItem("tempDocs", "")
        sessionStorage.setItem("isSearchActive", "false")
        sessionStorage.setItem("tempAdvanceSearch", "")
        sessionStorage.setItem("SearchValue", "")
        setisloadComplet(false)
        sessionStorage.setItem("isSearchActive", "false")
        sessionStorage.setItem("documentsLoaded", "false")
        sessionStorage.removeItem("tagHelp")
        // sessionStorage.setItem("language", "en")


        switch (res.Status) {
            case RegistrationStatus.NotRegistered:
                setIsNewUser(true)
                setRegistrationStep("400")
                setApplying(false)
                break;
            case RegistrationStatus.ValidationNeeded:
                setIsNewUser(true)
                setRegistrationStep("405")
                setApplying(false)
                break;
            case RegistrationStatus.ToSNeeded:
                setIsNewUser(true)
                setRegistrationStep("400")
                setApplying(false)
                break;
            case RegistrationStatus.Registered:
                setIsNewUser(false)
                updateDocumentList(key, id, email)
                getDocumentOnly()
                if (sessionOrderId !== "") {
                    const orderRequest: IOrderRequest = {
                        AccountId: id,
                        ApiKey: "-1",
                        Autoprovision: true,
                        Path: site,
                        SessionId: sessionOrderId,
                        SK: key,
                        UserName: email
                    }
                    var resultOrder = await Helper.GetOrder(orderRequest)
                    if (resultOrder !== undefined && resultOrder !== null) {
                        setResponsePaymentSuccess(resultOrder.Message)
                        setShowOrderSucces(true)
                    }
                    else {
                        setShowOrderSucces(false)
                    }
                }


                const request: IGetuserprofileRequest = {
                    UserName: email,
                    Profile: email
                }
                await UserService.GetUserProfile(request)
                    .then(async (data) => {
                        let fullUsername = data.Email

                        if (data.FirstName !== undefined && data.FirstName.trim().length > 0 && data.FirstName.trim() !== data.Email.trim()) {
                            let _lastName = data.LastName !== undefined && data.LastName !== "undefined" && data.LastName.trim().length > 0 ? ` ${data.LastName}` : ''
                            fullUsername = `${data.FirstName}${_lastName}`
                        }

                        setUserFullName(fullUsername)

                        sessionStorage.setItem("userFullName", fullUsername)
                        sessionStorage.setItem("billingActive", String(data.Billing))
                        sessionStorage.setItem("userFirstName", data.FirstName)
                        sessionStorage.setItem("userLastName", data.LastName.toString().trim().length > 0 && data.LastName.toString().trim() !== "undefined" ? data.LastName.toString().trim() : "")
                        sessionStorage.setItem("language", data.Language)
                        await i18n.changeLanguage(data.Language)
                    })
                    .catch()
                    .finally()


                //  if(auth0){
                //      window.history.replaceState({}, "", '/app') 
                // }
                setisFromAuth(auth0)
                break;
            case RegistrationStatus.AuthCode:
                setErrorMessage(error)
                setAuthCode(true)
                setApplying(false)
                break;
            case RegistrationStatus.Error:
                setErrorMessage(error)
                setHaveError(true)
                setApplying(false)
                break;
            case RegistrationStatus.ValidationFailed:
                setApplying(false)
                return logout()
            default:
                setApplying(false)
                throw new Error("Please reload the application")
        }
    })

    const loadDocument = useConstCallback((res: any, page: number, pageSize: number) => {
        if (res.length !== undefined) {
            setTotalRecordCount(res.length == 0 ? 0 : res[0].TotalRecordCount)
            var docsArray
            var tempDocs = sessionStorage.getItem("tempDocs")!
            sessionStorage.setItem(" documentsLoaded", "false")

            if (page > 1) {

                if (tempDocs !== null && tempDocs !== "") {
                    docsArray = JSON.parse(tempDocs)
                    docsArray = docsArray.concat(res);
                    sessionStorage.setItem("tempDocs", JSON.stringify(docsArray))
                    setisloadComplet(true)
                }
                if (res.length < pageSize) {
                    setisloadComplet(true)
                    sessionStorage.setItem("documentsLoaded", "true")
                    sessionStorage.setItem("tempDocs", "")
                    sessionStorage.setItem("pageCount", "0")
                    sessionStorage.setItem("updateApp", "")
                }

            }
            else {
                docsArray = res
                if (res.length > 0) {
                    sessionStorage.setItem("tempDocs", JSON.stringify(docsArray))
                }
                else {
                    sessionStorage.setItem("tempDocs", JSON.stringify(docList))
                }

                setisloadComplet(true)
            }

            setDocumentList(docsArray)
            setTotalDocs(docsArray.length)
            setDocumentsLoaded(true)
            sessionStorage.setItem("updateApp", "")
            sessionStorage.setItem("totalDocsIntervals", docsArray.length.toString())
        }
    })

    /**
     * reloadApp
     * @param site
     * @param category to focus after reloading the left menu
     */
    const reloadApp = useConstCallback(async (site: string, category?: string) => {
        _currentCategory = category

        setCategoryFocus(_currentCategory)

        const reload = async (username: string, site: string) => {
            await auth(username, site)
        }

        const newSite = await Helper.setSite(site)
        const username = Helper.getUsername()
        reload(username, newSite)
    })

    const onSearch = useConstCallback((text: any, showLoading: boolean = false) => {
        selectionState.setAllSelected(false);
        console.log(`Searching for: ${text}`)

        const search = async () => {
            const sessionKey = Helper.getSessionKey()
            const accountId = Helper.getDefaultAccount()
            const userName = Helper.getUsername()

            var site = await Helper.getSite()!
            var pageSize = 20
            const pageCount = parseInt(sessionStorage.getItem("pageCount")!)

            var documentsLoaded = sessionStorage.getItem("documentsLoaded")!
            var isSearchActive = sessionStorage.getItem("isSearchActive")! === "true"
            setisloadComplet(false)

            let page = pageCount
            if (pageCount === 0) {
                page = 1
                sessionStorage.setItem("pageCount", page.toString())
                setisloadComplet(true)
            }
            if (documentsLoaded !== "true" && isSearchActive) {

                const _request = {
                    PageNo: page.toString(),
                    RecordsPerPage: pageSize,
                    Content: encodeURIComponent(text),
                }
                let res: any
                await HttpRequest.getWithSession("SearchFTI", _request)
                    .then((data: any) => {
                        res = data.data
                    })
                    .finally(() => {
                        setShowLoading(false)
                    })

                if (res !== undefined) {
                    if (res.Success !== undefined && res.Success === false) {
                        sessionStorage.setItem("documentsLoaded", "true")
                        sessionStorage.setItem("tempDocs", "")
                        sessionStorage.setItem("pageCount", "0")
                        sessionStorage.setItem("SearchValue", "")
                        if (page === 1 && (res.length === undefined || res.length === 0)) {
                            setDocumentList(docList)
                            setDocumentsLoaded(true)
                            setisloadComplet(true)

                        }
                    }
                    else {
                        setTotalRecordCount(res.length == 0 ? 0 : res[0].TotalRecordCount)
                        if (page === 1 && isSearchActive) {
                            sessionStorage.setItem("tempDocs", "")
                        }
                        sessionStorage.setItem("SearchValue", text)

                        loadDocument(res, page, pageSize)
                    }
                }

                else {
                    sessionStorage.setItem("pageCount", "0")
                    setDocumentList(docList)
                    setDocumentsLoaded(true)
                    sessionStorage.setItem("documentsLoaded", "true")
                    sessionStorage.setItem("tempDocs", "")
                    sessionStorage.setItem("pageCount", "0")
                    sessionStorage.setItem("SearchValue", "")
                    sessionStorage.setItem("tempDocs", "")
                    setisloadComplet(true)
                }
            }

            resetScroll()
        }
        search();
    })

    const onAdvanceSearch = useCallback((request: IAdvanceSearch, showLoading: boolean = false) => {
        selectionState.setAllSelected(false);
        var documentsLoaded = sessionStorage.getItem("documentsLoaded")!
        var isAdvanceSearch = (sessionStorage.getItem("tempAdvanceSearch") != "" && sessionStorage.getItem("tempAdvanceSearch") != null) ? true : false
        if (isCreateUpdate.current) {
            sessionStorage.setItem("pageCount", "1")
            request.PageNo = 1
        }
        setisloadComplet(false)
        isCreateUpdate.current = false
        if (documentsLoaded && isAdvanceSearch) {
            if (showLoading) {
                setShowLoading(true)
            }
            Helper.advanceSearch(request)
                .then(result => {
                    return result
                })
                .catch(error => {
                    console.log("error+", error)
                    setShowLoading(false)
                })
                .then((res: any) => {
                    if (res !== undefined) {
                        if (res.Success !== undefined && res.Success === false) {
                            sessionStorage.setItem("documentsLoaded", "true")
                            sessionStorage.setItem("tempDocs", "")
                            sessionStorage.setItem("pageCount", "0")
                            sessionStorage.setItem("SearchValue", "")
                            setisloadComplet(true)
                            if (request.PageNo === 1 && (res.length === undefined || res.length === 0)) {
                                setDocumentList(docList)
                                setDocumentsLoaded(true)
                                sessionStorage.setItem("tempAdvanceSearch", "")
                            }
                        }
                        else {
                            loadDocument(res, request.PageNo, request.RecordsPerPage)
                        }
                        resetScroll()
                    }
                    setShowLoading(false)
                })

        }
    }, [docList, loadDocument])


    const CleanDocument = useConstCallback(() => {
        sessionStorage.setItem("tempDocs", "")
        sessionStorage.setItem("pageCount", "0")
        sessionStorage.setItem("tempAdvanceSearch", "")
        sessionStorage.setItem("SearchValue", "")
        setisloadComplet(false)
        sessionStorage.setItem("isSearchActive", "false")
        sessionStorage.setItem("documentsLoaded", "false")
        sessionStorage.removeItem("tagHelp")
        setDocumentList(docList)
        setTotalDocs(0)

    })

    function resetScroll() {
        var el = document.getElementsByClassName("ms-ScrollablePane--contentContainer contentContainer-159");
        if (el !== undefined && el !== null && el.length > 2)
            el[2].scrollTop = 0;
    }

    const onExpandCollapse = () => {
        if (expandCollapseIconName == "ExploreContentSingle")
            setExpandCollapseIconName("CollapseContent")
        else
            setExpandCollapseIconName("ExploreContentSingle")

        onIsExpanded()
    }

    const onIsExpanded = () => {
        if (expandCollapseIconName == "CollapseContent") {
            templateOnlyList.forEach(group => {
                group.collapseByDefault = true
            })
        }
        else {
            templateOnlyList.forEach(group => {
                group.collapseByDefault = false
            })
        }

        settemplateOnlyList(templateOnlyList)
    }

    async function getDocumentOnly() {
        let site = await Helper.getSite()!
        if (site === "") {
            site = await Helper.getSite()

        }
        const request: ITemplateLayoutRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            AccountIds: Helper.getDefaultAccount(),
            Site: site,
            TemplateId: '',
            DocumentId: "-1"
        }
        await Helper.getDocumentOnlyTemplate(request).then(r => {
            if (r.length !== undefined) {
                r.forEach((itemDoc: any) => {
                    itemDoc.links.forEach((itemLink: any) => {
                        var itemSubscriptionStatusMessage = itemLink!.SubscriptionStatusMessage != null && itemLink!.SubscriptionStatusMessage != undefined && itemLink!.SubscriptionStatusMessage != "" ? itemLink!.SubscriptionStatusMessage : ""
                        itemLink.SubscriptionStatusMessage = sessionStorage.getItem("entityId") !== 'Not In Teams' ? "Document not available" : (itemLink.CoOwner ? itemSubscriptionStatusMessage + " Contact the owner of the application to upgrade plan." : itemLink.SubscriptionStatusMessage)
                    });
                    // TODO issue fixed for Fluent UI 8.0 https://github.com/microsoft/fluentui/pull/18347
                    if (_currentCategory !== undefined && itemDoc.name === _currentCategory) {
                        _currentCategory = undefined
                        itemDoc.isExpanded = true
                        itemDoc.collapseByDefault = false
                    }
                });
                var settingLinks: INavLinkGroup[] = [];
                settingLinks = r

                settemplateOnlyList(settingLinks)
            }
        })
        setApplying(false)
    }
    function showHelp(show: boolean) {
        setShowCoachmarkC(show)
        setShowCoachmarkT(show)
        setShowCoachmarkA(show)
        setShowCoachmarkS(show)
    }

    const toggleLoadComplet = useConstCallback((value: boolean) => {
        const site = Helper.getSiteSelected()
        setisloadComplet(value)


    })
    const toggleSearchActive = useConstCallback((value: boolean) => {
        const site = Helper.getSiteSelected()
        setisSearchActive(value)
    })
    function goToHelp() {
        window.open(`${branding.helpPath}#`, '_blank');
    }



    const onDocumentSelected = useConstCallback((document: IDocument) => {
        setDocumentSelected(document)
    })
    const hideActivity = () => {
        setShowActivity(false)
    }

    const hideContactUs = () => {
        setOpenContactUs(false)
    }

    const hideBulkDownload = () => {
        setOpenBulkDownload(false)
        updateAfterCreateDocument()
    }

    const showHelpTeams = useConstCallback((loc: string) => {

        sessionStorage.setItem("tagHelp", loc)
        microsoftTeams.getContext(async (context) => {

            let teamsID = await Helper.getTeamsId()

            // todo encodeURIComponent webUrl with hash
            if (teamsID !== undefined && teamsID !== "") {
                if (context !== undefined && context.userPrincipalName !== undefined) {
                    var host = window.location.origin
                    var encodedContext = encodeURI('{"subEntityId":help}')
                    var WebURL = `${host}${branding.helpPath}`
                    var baseurlhelp = "https://teams.microsoft.com/l/entity/" + teamsID + "/help-data?webUrl=" + WebURL + "&label=Help&context=" + encodedContext
                    sessionStorage.setItem("tagHelp", loc)
                    microsoftTeams.executeDeepLink(baseurlhelp)
                }
            }
        });
    })


    const changeWaitingTime = () => {
        setTimeout(() => {
            waitingTime.current = false
            if (waitingForRefresh.current) {
                waitingForRefresh.current = false
                updateDocumentsIntervals()
            }
        }, 5000);
    }


    const updateDocumentListIntervals = useCallback((async (sessionKey: string, accountId: string, userName: string): Promise<any> => {
        if (sessionStorage.getItem("tempAdvanceSearch") != "" && sessionStorage.getItem("tempAdvanceSearch") != null) {
            onAdvanceSearch(JSON.parse(sessionStorage.getItem("tempAdvanceSearch")!) as IAdvanceSearch)
        } else {

            var tdocs = Number(sessionStorage.getItem("totalDocsIntervals"))
            const pageSize = tdocs > 0 ? tdocs : 20
            var page = 1
            var documentsLoaded = sessionStorage.getItem("documentsLoaded")!
            setisloadComplet(true)

            if (documentsLoaded !== "true") {
                let req = new DocumentListRequest()
                req.UserName = userName
                req.SessionKey = sessionKey
                req.AccountId = accountId
                req.PageNo = page.toString()
                req.RecordsPerPage = pageSize.toString()
                req.Assigned = isAssignedChecked


                setCustomTemplateList(JSON.parse(sessionStorage.getItem("customTemplateList")!))
                await Helper.getDocumentList(req)
                    .then(result => {
                        result.forEach((item: any) => {
                            var itemSubscriptionStatusMessage = item!.SubscriptionStatusMessage != null && item!.SubscriptionStatusMessage != undefined && item!.SubscriptionStatusMessage != "" ? item!.SubscriptionStatusMessage : "Expired subscription"
                            item.SubscriptionStatusMessage = sessionStorage.getItem("entityId") !== 'Not In Teams' ? "Document not available" : (item.CoOwner ? itemSubscriptionStatusMessage + " Contact the owner of the application to upgrade plan." : item.SubscriptionStatusMessage)
                        });
                        return result
                    })
                    .catch(error => {
                        console.log("error", error)
                        changeWaitingTime()
                    })
                    .then(res => {
                        changeWaitingTime()
                        if (res !== undefined) {
                            if (res.Success !== undefined && res.Success === false) {
                                sessionStorage.setItem("documentsLoaded", "true")
                                sessionStorage.setItem("tempDocs", "")
                                sessionStorage.setItem("pageCount", "0")
                            }
                            else {
                                loadDocument(res, page, pageSize)
                            }
                        }
                    })
            }
        }
    }), [isAssignedChecked, loadDocument, onAdvanceSearch])

    const updateDocumentsIntervals = useCallback((() => {
        if (isUpdateInterval) {
            var serachActive = false
            var isSearchActive = sessionStorage.getItem("isSearchActive") === "true"
            var tempAdvanceSearch = sessionStorage.getItem("tempAdvanceSearch") !== "" && sessionStorage.getItem("tempAdvanceSearch") != null
            var SearchValue = sessionStorage.getItem("SearchValue") === "true"
            serachActive = (!isSearchActive && !tempAdvanceSearch && !SearchValue) ? true : false
            if (selectionState.getSelectedIndices().length !== 1 && serachActive) {
                const sessionKey = Helper.getSessionKey()
                console.log(`Searching Cleared for update intervals`)
                sessionStorage.setItem("tempDocs", "")
                sessionStorage.setItem("tempAdvanceSearch", "")
                sessionStorage.setItem("SearchValue", "")
                setisloadComplet(false)
                sessionStorage.setItem("isSearchActive", "false")
                sessionStorage.setItem("documentsLoaded", "false")
                sessionStorage.removeItem("tagHelp")
                const accountId = Helper.getDefaultAccount()
                const userName = Helper.getUsername()
                sessionStorage.setItem("updateApp", "true")
                updateDocumentListIntervals(sessionKey, accountId, userName)

            }
        }

    }), [selectionState, updateDocumentListIntervals])

    useEffect(() => {
        if (firstRenderNotifications.current) {
            firstRenderNotifications.current = false;
        } else {
            if (!waitingTime.current) {
                waitingTime.current = true
                updateDocumentsIntervals()
            } else {
                waitingForRefresh.current = true
            }
        }
    }, [signalR.CreateSuccessMessage, signalR.UpdateSuccessMessage, signalR.CreateFailedMessage, signalR.UpdateFailedMessage])

    useEffect(() => {
        if (!waitingTime.current && waitingForRefresh.current) {
            waitingForRefresh.current = false
            updateDocumentsIntervals()
        }

    }, [waitingTime, waitingForRefresh])


    const updateDocumentList = useCallback((async (sessionKey: string, accountId: string, userName: string, showLoading: boolean = false): Promise<any> => {
        var isSearchActive = sessionStorage.getItem("isSearchActive")!

        if (sessionStorage.getItem("tempAdvanceSearch") != "" && sessionStorage.getItem("tempAdvanceSearch") != null) {
            onAdvanceSearch(JSON.parse(sessionStorage.getItem("tempAdvanceSearch")!) as IAdvanceSearch, showLoading)
        } else if (isSearchActive === "true") {
            onSearch(sessionStorage.getItem("SearchValue"), showLoading)
            isCreateUpdate.current = false
        }
        else {
            const pageSize = 20
            var page = parseInt(sessionStorage.getItem("pageCount")!)
            var documentsLoaded = sessionStorage.getItem("documentsLoaded")!
            setisloadComplet(false)
            isCreateUpdate.current = false
            if (page === 0) {
                page = 1
                sessionStorage.setItem("pageCount", page.toString())
                setisloadComplet(true)

            }

            if (documentsLoaded !== "true") {
                let req = new DocumentListRequest()
                req.UserName = userName
                req.SessionKey = sessionKey
                req.AccountId = accountId
                req.PageNo = page.toString()
                req.RecordsPerPage = pageSize.toString()
                req.Assigned = isAssignedChecked

                setCustomTemplateList(JSON.parse(sessionStorage.getItem("customTemplateList")!))
                if (showLoading) {
                    setShowLoading(true)
                }
                await Helper.getDocumentList(req)
                    .then(result => {
                        result.forEach((item: any) => {
                            var itemSubscriptionStatusMessage = item!.SubscriptionStatusMessage != null && item!.SubscriptionStatusMessage != undefined && item!.SubscriptionStatusMessage != "" ? item!.SubscriptionStatusMessage : ""
                            item.SubscriptionStatusMessage = sessionStorage.getItem("entityId") !== 'Not In Teams' ? "Document not available" : (item.CoOwner ? itemSubscriptionStatusMessage + " Contact the owner of the application to upgrade plan." : item.SubscriptionStatusMessage)
                        });
                        return result
                    })
                    .catch(error => {
                        console.log("error", error)
                    })
                    .then(res => {
                        if (res !== undefined) {
                            if (res.Success !== undefined && res.Success === false) {
                                sessionStorage.setItem("documentsLoaded", "true")
                                sessionStorage.setItem("tempDocs", "")
                                sessionStorage.setItem("pageCount", "0")
                            }
                            else {
                                loadDocument(res, page, pageSize)
                            }
                        }
                    })
                    .finally(() => {
                        setShowLoading(false)
                    })
            }
        }
    }), [isAssignedChecked, loadDocument, onAdvanceSearch, onSearch])

    const updateAfterCreateDocument = useCallback(((callback?: Function) => {
        isCreateUpdate.current = true
        if (selectionState.getSelectedIndices().length >= 1)
            selectionState.toggleIndexSelected(selectionState.getSelectedIndices()[0]);

        var isAdvanceSearchActive = sessionStorage.getItem("tempAdvanceSearch") !== null && sessionStorage.getItem("tempAdvanceSearch") !== undefined && sessionStorage.getItem("tempAdvanceSearch") !== ''
        var isNormalSearchActive = sessionStorage.getItem("isSearchActive") === "true"
        if (!isAdvanceSearchActive && !isNormalSearchActive) {
            CleanDocument()
        }
        const sessionKey = Helper.getSessionKey()
        const accountId = Helper.getDefaultAccount()
        const userName = Helper.getUsername()
        sessionStorage.setItem("updateApp", "true")


        if (isAdvanceSearchActive || isNormalSearchActive) {
            setTimeout(function () {
                updateDocumentList(sessionKey, accountId, userName).then(() => {
                    callback?.()
                })
            }, 2000);
        }
        else {
            updateDocumentList(sessionKey, accountId, userName).then(() => {
                callback?.()
            })
        }
        cacheAudience.current = []
    }), [CleanDocument, selectionState, updateDocumentList])

    const onSearchClear = useCallback((() => {
        selectionState.setAllSelected(false);
        sessionStorage.setItem("tempDocs", "")
        sessionStorage.setItem("pageCount", "0")
        sessionStorage.setItem("tempAdvanceSearch", "")
        sessionStorage.setItem("SearchValue", "")
        setisloadComplet(false)
        sessionStorage.setItem("isSearchActive", "false")
        sessionStorage.setItem("documentsLoaded", "false")
        sessionStorage.setItem("pageU", "0")
        const sessionKey = Helper.getSessionKey()
        const accountId = Helper.getDefaultAccount()
        const userName = Helper.getUsername()
        setSearchBoxValue("")

        resetScroll()

        updateDocumentList(sessionKey, accountId, userName)
    }), [updateDocumentList])

    const hideOrdeSuccess = () => {
        setApplying(true)
        setShowOrderSucces(false)
        window.location.assign(window.location.origin + '/app');
    }

    const getBillingActive = async (): Promise<boolean> => {
        var site = await Helper.loadSites()
        return site !== null ? site.Billing : false
    }

    const onSubscriptionDisabled = useConstCallback((val: boolean, _isOwner?: boolean) => {
        setSubscriptionDisabled(false)
        var isWeb = (!isMobile && sessionStorage.getItem("entityId") === "Not In Teams")
        setIsOwnerDocs(_isOwner!)
        if (_isOwner) {
            if (isWeb) {
                setSubscriptionDisabled(val)

            }
            else {
                setSubscriptionDisabled(true)
            }
        }
    })

    const onOwnerDocument = useConstCallback((val: boolean) => {
        setIsOwnerDocs(val)
    })


    const getShowDisclaimerVGS = () => {
        var nameEQ = "KimDisclaimerVGS" + Helper.getUsername() + "=";
        var ca = document.cookie.split(';');
        var result = true
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].replace(" ", "");
            if (c == nameEQ) {
                result = false
                break
            }


        }
        return result;
    }

    const onUpdateInterval = useConstCallback((value: boolean) => { isUpdateInterval = value })
    if (haveError) {
        return (
            <ErrorScreen errorMessage={errorMessage} />
        );
    } else if (authCode) {
        return (
            <ErrorAuthCode
                errorMessage={errorMessage}
                reloadApp={reloadApp} />
        );
    } else {
        if (isNewUser) {
            var encodedUser = btoa(userEmail)
            window.location.assign("/registration?id=" + encodedUser + "&fh=true");
        }
        return (
            <>
                {!applying ?
                    <div className="ms-Grid" dir="ltr">
                        {displayDisclaimer ? <Disclaimer
                            setDisplayDisclaimer={setDisplayDisclaimer} /> : null}
                        {/* {NoDisplayVideoGS ? <DisclaimerGetstarted
                                    setNoDisplayVideoGS={setNoDisplayVideoGS} /> : null}  */}
                        {sessionStorage.getItem("entityId") === "Not In Teams" ?
                            <>
                                <Stack horizontal styles={{
                                    root: {
                                        margin: "0px -8px 0px -8px",
                                        backgroundColor: branding.theme.palette.themePrimary,
                                        color: branding.theme.palette.white,
                                        position: "sticky",
                                        top: "0px",
                                        zIndex: 100,
                                        height: "50px",
                                        alignItems: "center"

                                    }
                                }}>
                                    <div className="app-header">
                                        <div className="text-app">
                                            <div className="app-header-appname">
                                                <Text variant={"large"} styles={{
                                                    root: {
                                                        fontWeight: "bold",
                                                        marginLeft: 12
                                                    }
                                                }}>{branding.appName}</Text>
                                            </div>
                                            <div className="app-header-tagline">
                                                <Text variant={"large"}
                                                    className="ms-hiddenLgDown">&nbsp;-&nbsp;{branding.appTagline}
                                                </Text>
                                            </div>
                                        </div>
                                        <div className="user-menu-app">
                                            <Text variant={"large"} styles={{ root: { cursor: "pointer", fontWeight: "bold" } }} onClick={() => {
                                                goToHelp()
                                            }}
                                                className="ms-hiddenLgDown">{t("DashboardComponent.Button.Help", { ns: 'workspace' })}</Text>
                                            <Icon
                                                style={{ marginLeft: "4px", marginRight: "16px", fontSize: FontSizes.size16 }}
                                                styles={iconButtonStyles}
                                                iconName={"Headset"}
                                                onClick={() => {
                                                    goToHelp()
                                                }}
                                                title={`${branding.appName} help and videos.`} />

                                            <CommandButton
                                                id="UserMenu"
                                                menuProps={getMenu()}
                                                iconProps={contactIcon}
                                                styles={iconButtonStyles}
                                                title={t("DashboardComponent.Button.UserMenu", { ns: 'workspace' })}
                                                ariaLabel={t("DashboardComponent.Button.UserMenu", { ns: 'workspace' })}
                                                text={isMobile ? "" : userFullName?.substring(0, 20)}
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: "18px",
                                                    color: "white",
                                                }}
                                                menuIconProps={chevronIcon}
                                            />
                                        </div>
                                    </div>

                                </Stack>

                            </>
                            : null
                        }
                        <div className="ms-Grid-row">
                            <div className="ms-hiddenLgUp">
                                <div className="ms-Grid-col ms-sm1">
                                    <IconButton iconProps={menuIcon} title={t('title.myDocuments', { ns: 'workspace' })} onClick={setIsOpenMenu} />
                                    <Panel
                                        isBlocking={false}
                                        isOpen={isOpenMenu}
                                        onDismiss={setIsOpenMenu}
                                        closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common' })}
                                        type={PanelType.smallFixedNear}
                                    >
                                        <AccountList templateList={templateList}
                                            isTemplateLoaded={templateLoaded}
                                            update={updateAfterCreateDocument}
                                            reloadApp={reloadApp}
                                            version={version}
                                            selectionState={selectionState}
                                            templateOnlyList={templateOnlyList}
                                            showCoachmarkC={showCoachmarkC}
                                            showCoachmarkT={showCoachmarkT}
                                            showCoachmarkA={showCoachmarkA}
                                            showCoachmarkS={showCoachmarkS}
                                            onExpandCollapse={onExpandCollapse}
                                            expandCollapseIconName={expandCollapseIconName}
                                            CleanDocument={CleanDocument}
                                            showHelpTeams={showHelpTeams}
                                            SubscriptionDisabled={onSubscriptionDisabled}
                                            isSubscriptionDisabled={SubscriptionDisabled}
                                            isOwnerDocs={isOwnerDocs}
                                            onOwnerDocument={onOwnerDocument}
                                            onUpdateInterval={onUpdateInterval}
                                            isUpdateInterval={isUpdateInterval}
                                            isFromAuth0={isFromAuth}
                                        />

                                    </Panel>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DataPanel
                                        documentList={documentList}
                                        totalRecordCount={totalRecordCount}
                                        showLoading={showLoading}
                                        customTemplateList={customTemplateList}
                                        areDocumentsLoaded={documentsLoaded}
                                        update={updateAfterCreateDocument}
                                        selectionState={selectionState}
                                        docSelected={docSelected}
                                        templateOnlyList={templateOnlyList}
                                        isDisabled={isDisabled}
                                        onSearch={onSearch}
                                        cacheAudience={cacheAudience}
                                        onSearchClear={onSearchClear}
                                        onAdvanceSearch={onAdvanceSearch}
                                        updateDocumentList={updateDocumentList}
                                        showCoachmarkC={showHelp}
                                        showCoachmarkT={showHelp}
                                        showCoachmarkA={showHelp}
                                        showCoachmarkS={showHelp}
                                        isloadComplet={isloadComplet}
                                        toggleLoadComplet={toggleLoadComplet}
                                        isSearchActive={isSearchActive}
                                        toggleSearchActive={toggleSearchActive}
                                        CleanDocument={CleanDocument}
                                        onDocumentSelected={onDocumentSelected}
                                        showHelpTeams={showHelpTeams}
                                        totalDocs={totalDocs}
                                        updateInterval={updateDocumentsIntervals}
                                        hideSubscriptionDialog={hideSubscriptionDialog}
                                        setHideSubscriptionDialog={setHideSubscriptionDialog}
                                        setDisplaySubscriptionRedirectionMessage={setDisplaySubscriptionRedirectionMessage}
                                        displaySubscriptionRedirectionMessage={displaySubscriptionRedirectionMessage}
                                        SubscriptionDisabled={onSubscriptionDisabled}
                                        onUpdateInterval={onUpdateInterval}
                                        isUpdateInterval={isUpdateInterval}
                                        lastDocSelectedIsOwner={lastDocSelectedIsOwner}
                                        isAssignedChecked={isAssignedChecked}
                                        onChangeAssigned={onChangeAssigned}
                                        searchBoxValue={searchBoxValue}
                                        setSearchBoxValue={setSearchBoxValue}
                                        setSubformItems={setSubformItems}
                                        subformItems={subformItems}

                                    />
                                </div>
                            </div>
                            <div className="ms-hiddenMdDown">
                                <div className="customPanel ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                    <AccountList templateList={templateList}
                                        isTemplateLoaded={templateLoaded}
                                        update={updateAfterCreateDocument}
                                        reloadApp={reloadApp}
                                        version={version}
                                        selectionState={selectionState}
                                        templateOnlyList={templateOnlyList}
                                        showCoachmarkC={showCoachmarkC}
                                        showCoachmarkT={showCoachmarkT}
                                        showCoachmarkA={showCoachmarkA}
                                        showCoachmarkS={showCoachmarkS}
                                        setShowCoachmarkC={setShowCoachmarkC}
                                        setShowCoachmarkT={setShowCoachmarkT}
                                        setShowCoachmarkA={setShowCoachmarkA}
                                        setShowCoachmarkS={setShowCoachmarkS}
                                        onExpandCollapse={onExpandCollapse}
                                        expandCollapseIconName={expandCollapseIconName}
                                        CleanDocument={CleanDocument}
                                        showHelpTeams={showHelpTeams}
                                        SubscriptionDisabled={onSubscriptionDisabled}
                                        isSubscriptionDisabled={SubscriptionDisabled}
                                        isOwnerDocs={isOwnerDocs}
                                        onOwnerDocument={onOwnerDocument}
                                        onUpdateInterval={onUpdateInterval}
                                        isUpdateInterval={isUpdateInterval}
                                        isFromAuth0={isFromAuth}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                                    <DataPanel
                                        cacheAudience={cacheAudience}
                                        documentList={documentList}
                                        showLoading={showLoading}
                                        totalRecordCount={totalRecordCount}
                                        templateOnlyList={templateOnlyList}
                                        customTemplateList={customTemplateList}
                                        areDocumentsLoaded={documentsLoaded}
                                        update={updateAfterCreateDocument}
                                        selectionState={selectionState}
                                        docSelected={docSelected}
                                        isDisabled={isDisabled}
                                        onSearch={onSearch}
                                        onSearchClear={onSearchClear}
                                        onAdvanceSearch={onAdvanceSearch}
                                        updateDocumentList={updateDocumentList}
                                        showCoachmarkC={showHelp}
                                        showCoachmarkT={showHelp}
                                        showCoachmarkA={showHelp}
                                        showCoachmarkS={showHelp}
                                        isloadComplet={isloadComplet}
                                        toggleLoadComplet={toggleLoadComplet}
                                        isSearchActive={isSearchActive}
                                        toggleSearchActive={toggleSearchActive}
                                        CleanDocument={CleanDocument}
                                        onDocumentSelected={onDocumentSelected}
                                        showHelpTeams={showHelpTeams}
                                        totalDocs={totalDocs}
                                        updateInterval={updateDocumentsIntervals}
                                        hideSubscriptionDialog={hideSubscriptionDialog}
                                        setHideSubscriptionDialog={setHideSubscriptionDialog}
                                        setDisplaySubscriptionRedirectionMessage={setDisplaySubscriptionRedirectionMessage}
                                        displaySubscriptionRedirectionMessage={displaySubscriptionRedirectionMessage}
                                        SubscriptionDisabled={onSubscriptionDisabled}
                                        onUpdateInterval={onUpdateInterval}
                                        isUpdateInterval={isUpdateInterval}
                                        lastDocSelectedIsOwner={lastDocSelectedIsOwner}
                                        isAssignedChecked={isAssignedChecked}
                                        onChangeAssigned={onChangeAssigned}
                                        searchBoxValue={searchBoxValue}
                                        setSearchBoxValue={setSearchBoxValue}
                                        setSubformItems={setSubformItems}
                                        subformItems={subformItems}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>

                    :
                    <>
                        <Stack styles={{ root: { alignItems: "center", justifyContent: "center", height: "100%" } }}>
                            <Spinner size={SpinnerSize.medium} label={t('text.spinner', { ns: 'common' })} ariaLive="assertive" />
                        </Stack>
                    </>

                }



                {/*region Profile Modal */}
                {showProfile &&
                    <MyProfile showModal={showProfile} setShowModal={setShowProfile} />
                }
                {/*endregion*/}

                {/*region My Billing Plan*/}
                {showMyBillingPlan &&
                    <MyBillingPlan showModal={showMyBillingPlan} setShowModal={onDismissMyBillingPlan} />
                }
                {/*endregion*/}

                <Logout showConfirmationModal={showConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal} />

                {/*region My Activity*/}
                {showActivity &&
                    <MyActivity showModal={showActivity} setShowModal={setShowActivity} />
                }
                {/*endregion*/}

                <Modal
                    isOpen={showOrderSucces}
                    isBlocking={true}>
                    <div className={contentStyles.header}>
                        <span id="Checkout">Payment  successful </span>
                        <IconButton
                            styles={iconButtonStylesClose}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={hideOrdeSuccess}
                            title="Close."
                        />
                    </div>
                    <div className={contentStyles.bodyOrder}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" dangerouslySetInnerHTML={{ __html: ResponsePaymentSuccess }} >

                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <DialogFooter>
                                        <DefaultButton onClick={hideOrdeSuccess} text="Close" title="Close." />
                                    </DialogFooter>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/*region Customer Support*/}

                {openContactUs &&
                    <CustomerSupport showModal={openContactUs} setShowModal={hideContactUs} defaultSubject={"Help with configuration"} />
                }
                {/*endregion*/}

                <Modal isOpen={props.showModal} isBlocking={true}>

                </Modal>




                <BulkDownloadModal
                    showModal={openBulkDownload}
                    hideModal={hideBulkDownload}
                    userName={Helper.getUsername()}
                    fromHelp={false}
                    selectedOption={"Bulk download"}
                    update={updateAfterCreateDocument}
                />
            </>
        );
    }
}