import { ISliderProps, Slider } from "@fluentui/react";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { ReactHookFormControlProps } from "../CustomTemplates/CustomForm/Models/ReactHookFormControlProps";

export const RHFSliderControl: FC<ReactHookFormControlProps & ISliderProps> = (props) => {
    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error }
            }) => (
                <Slider
                        label={"Control Column Span"}
                        disabled={props.disabled}
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        value={value}
                        onChange={(newUpperValue, newRangeValue) => {
                            props.setValue(props.id, newUpperValue)
                            onChange(newUpperValue)
                        }}
                        showValue={true}
                        snapToStep={false}
                    />
            )}
        />
    );
};