import HttpRequest from "./HttpRequest";
import {IHttpClientResponseBag} from "../Helpers/Helper";

export interface IGetAllBillingStateOwnerHistoryRequest {
    ownerName: string
}

export interface IGetAllBillingStatebyOwnerNameRequest {
    ownerName: string
}

export interface IGetAllBillingStateOwnerHistoryResponse {
    Active: boolean
    BillingClientId: number
    BillingMerchantId: number
    BillingPlanId: number
    Id: number
    MaxApplicationAllowed: number
    NextDueDate?: string
    StorageLimit: number
    SubmissionLimit: number
    TransactionAmount: string
    TransactionCode: string
    TransactionDate: string
    TransactionDescription: string
    TransactionError: string
    TransactionId: string
    TransactionName: string
    UserLimit: number
    WorkspaceOnlyUsers: number
}



export interface ICreateUpdateOwnerNameRequest {
    Active: boolean,
    BillingClientId: number,
    BillingMerchantId: number,
    BillingPlanId: number,
    TransactionCode: string,
    TransactionDescription: string,
    TransactionError: string,
    TransactionId: string,
    TransactionName: string,
    Operation: string,
    NextDueDate?: Date,
    OwnerName?: string
}



const BillingStateService = {

    /**
     * GetAllBillingStateOwnerHistory
     * @param request
     * @constructor
     */
    GetAllBillingStateOwnerHistory: async (request: IGetAllBillingStateOwnerHistoryRequest): Promise<IGetAllBillingStateOwnerHistoryResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('BillingState/GetAllBillingStateOwnerHistory', request)

        return new Promise<IGetAllBillingStateOwnerHistoryResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject({err: 'Server error'})
            else resolve(data)
        })
    },

    /**
     * GetAllBillingStatebyOwnerName
     * @param request
     * @constructor
     */
    GetAllBillingStatebyOwnerName: async (request: IGetAllBillingStatebyOwnerNameRequest): Promise<any> => {
        let {data}: any = await HttpRequest.getWithSession('BillingState/GetAllBillingStatebyOwnerName', request)
        return data
    },

    /**
     * CreateUpdateOwnerName
     * @param request
     * @constructor
     */
    CreateUpdateOwnerName: async (request: ICreateUpdateOwnerNameRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('BillingState/CreateUpdateOwnerName', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject({err: 'Server error'})
            else resolve(data)
        })
    },

}

export default BillingStateService