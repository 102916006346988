import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { ReactHookFormControlProps } from "../CustomTemplates/CustomForm/Models/ReactHookFormControlProps";
import { IToggleProps, Toggle } from "@fluentui/react";



export const RHFToggleControl: FC<ReactHookFormControlProps & IToggleProps> = (props) => {
  return (
    <Controller
      name={props.id}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => (
        <Toggle
          id={props.id}
          checked={props.getValues(props.id)}
          disabled={props.disabled}
          onChange={(e, checked) => {
            props.setValue(props.id, checked || !checked ? checked : false)
            props.trigger(props.id)
            if (props.onCallback !== null && props.onCallback !== undefined) {
              props.onCallback(e, checked)
            }
          }}
          title={props.title}
          defaultChecked={props.defaultValue}
        />
      )}
    />
  );
};