import React, { useEffect, useState } from 'react';
import {INavLinkGroup, Text, Stack, Image, ImageFit, DefaultEffects, StackItem} from '@fluentui/react';
import { useConstCallback } from '@uifabric/react-hooks';
import Helper, { Registration } from '../../Helpers/Helper';
import '../../web.css';
import { Icon, IIconProps } from 'office-ui-fabric-react';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { Modal } from '@fluentui/react/lib/Modal';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { Separator } from '@fluentui/react/lib/Separator';
import { isMobile } from 'react-device-detect';
import { IconButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import RegistrationAuth0 from './RegistrationAuth0';
import { useTranslation } from "react-i18next";
import useAuth from '../../hooks/useAuth';
import { useBranding } from '../../hooks/useBranding';
import CustomerSupport from "../CustomerSupport";

const theme = getTheme();
const iconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
      selectors: {
        ':hover': {
          cursor: 'pointer',
        },
      },
    },
  };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const navLinks: INavLinkGroup[] = []
function WebRegistrationAuth0(props: any) {
    const { branding, getAppSettings} = useBranding()
    const [isOpen, setIsOpen] = React.useState(true);
    const [userLocal, setUser] = React.useState("");
    const [Step, setStep] = React.useState("405");
    const dismissPanel = useConstCallback(() => { setIsOpen(false); gotoHome() });
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const { isAuthenticated, logout, user } = useAuth();
    const [openContactUs, setOpenContactUs] = useState(false)
    const [logOutHidden, setLogOutHidden] = useState(false)
    const [firstName, setFirstName] = useState("NA")
    const [lastName, setLastName] = useState<string>("NA")
    const {t} = useTranslation(['common', 'registration']);

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        containerSaveModal: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: isMobile ? null : '30vw',
            height: '20vh',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        mainContainer: {
            backgroundColor: "#050a1e",
            height: "100%",
            width: "100%",
            alignItems: "center",
        },
        body: {
            width: '50vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyList: {
            width: '70vw',
            height: '75vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    function getParams() {
        var url_string = window.location.href
        var url = new URL(url_string);
        var id = url.searchParams.get("id");
        var decoded = atob(id!);
        sessionStorage.setItem("userRegistration", decoded!)
        setUser(decoded!)

        var rly = url.searchParams.get("rly");
        if (rly != null) {
            var decoded2 = atob(rly!);
            sessionStorage.setItem("relayRegistration", decoded2!)
        } else {
            sessionStorage.setItem("relayRegistration", "null")
        }
    }

    const registerUser = () => {
        const lastname = (user?.family_name! === undefined || user?.family_name! === null) ? user?.name?.split(" ")[1]! : user?.family_name!
        let reg = new Registration()
        reg.email = user?.email!;
        reg.title = "";
        reg.firstName = (user?.given_name! === undefined || user?.given_name! === null) ? user?.name?.split(" ")[0]! : user?.email! !== user?.given_name!? user?.given_name! : '';
        reg.middleName = "";
        reg.lastName = lastname === undefined ? '' : lastname === 'undefined'? '' : lastname
        reg.timeZone = "Eastern Standard Time";


        setFirstName(reg.firstName)
        setLastName(reg.lastName)

        Helper.setRegistration(reg).then(r => {
            if (r.ExceptionMessage === "Activation failed. Activation guid invalid.") {
                Helper.resendCode(user?.email!)
            } else if (r.UserStatus === "REGISTERED" && r.IsActive === true
                && (r.ActivatedDateTime !== null || r.ActivatedDateTime !== undefined || r.ActivatedDateTime !== "")) {
                window.location.assign(`${window.location.origin}/login`);
                //window.location.assign(window.location.origin)
            }

            setStep("405")
        })
    }

    useEffect(() => {
        window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
                console.log("User clicked on back button!");
                window.location.assign(window.location.origin + "/app");
            }
        });
        console.log("Enter to registration")
        getAppSettings()
        if (isAuthenticated) {
            getParams()
            registerUser()
        }
      }, [isAuthenticated])

      useEffect(() => {
      }, [branding.appLogo])
    
      const updateStep = useConstCallback((step) => setStep(step))


    const gotoHome = useConstCallback(() => {
        // window.history.replaceState({}, '','/');
        // window.onhashchange = function() {
        //     // window.history.replaceState({}, "", '/app') 
        //     // onClose()
            
        //     window.location.reload()
            
        // }
        var redirect = sessionStorage.getItem("relayRegistration")
        var tagFromAuth= btoa("isFromAuth")
        
        if (redirect === "null") {
        
            window.location.assign(`${window.location.origin}/app#${tagFromAuth}`);
        } else {
            window.location.assign(`${window.location.origin}${redirect}#${tagFromAuth}`);
        }
    })

    const hideConfirmationModal = () => {
        setShowConfirmationModal(false)
    }

    const _openSupportForm = () => {
        setOpenContactUs(true)
    }

    const hideContactUs = () => {
        setOpenContactUs(false)
    }

    const hideLogOut = useConstCallback((isHidden: boolean) => {
        setLogOutHidden(isHidden)
    })
    return (
        <>
            <Modal
                isOpen={showConfirmationModal}
                isBlocking={true}
                containerClassName={contentStyles.containerSaveModal}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.header}>
                                <span id="Confirmation">{t('text.logOutConfirmation', { ns: 'registration' })}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                    onClick={hideConfirmationModal}
                                    title={t('Common.Button.Close', { ns: 'common' })}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg12">
                            <div className={contentStyles.body}>
                                {t('text.msgLogOutConfirmation', { ns: 'registration' })}
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={logout} text={t('Common.Button.Yes', { ns: 'common' })} title={t('title.btnYes', { ns: 'registration' })} />
                                <DefaultButton onClick={hideConfirmationModal} text={t('Common.Button.No', { ns: 'common' })} title={t('Common.Button.No', { ns: 'common' })} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>

            <Stack horizontal className={contentStyles.mainContainer} styles={{root: {minHeight: "800px"}}}>
                <Stack grow={1}></Stack>
                <Stack grow={1}
                       styles={{
                           root: {
                               width: "800px",
                               maxWidth: "800px",
                               minWidth: "320px",
                               alignItems: "center"
                           }
                       }}>
                    <Stack styles={{root: {marginTop: "-120px", width: "100%"}}}>
                        <Stack tokens={{padding: 32}}>
                            <Image
                                src={branding.appLogo}
                                alt="Kim Technologies"
                                imageFit={ImageFit.contain}
                                height={64}
                            />
                        </Stack>
                        <Stack tokens={{padding: 32}}
                               styles={{
                                   root: {
                                       backgroundColor: "#FAFAFA",
                                       borderRadius: "8px",
                                       boxShadow: DefaultEffects.elevation16,
                                       
                                   }
                               }}>
                            <Stack horizontal styles={{root: {alignItems: "center"}}}>
                                <StackItem grow={1}>
                                    <h2 title={t('title.title', {ns: 'registration'})}>{t('title.title', {ns: 'registration'})}</h2>
                                </StackItem>
                                <StackItem hidden={logOutHidden} styles={{root: {textAlign: "right"}}}>
                                    <Text block variant={"small"}
                                          title={t('text.logOut', {ns: 'registration'})}>{t('text.logOut', {ns: 'registration'})}</Text>
                                    <Icon
                                        iconName={"SignOut"}
                                        styles={iconButtonStyles}
                                        onClick={() => {
                                            setShowConfirmationModal(true)
                                        }}
                                        title={t('title.logOut', {ns: 'registration'})}/>
                                </StackItem>
                            </Stack>
                            <Stack>
                                <RegistrationAuth0
                                    email={props.email != null ? props.email : userLocal}
                                    firstName={firstName}
                                    lastName={lastName}
                                    timeZone={"Eastern Standard Time"}
                                    culture={navigator.language.split("-")[0]}
                                    step={props.step != null ? props.step : Step}
                                    onStep={props.onStep != null ? props.onStep : updateStep}
                                    onClose={dismissPanel}
                                    authUser={user}
                                    hideLogOut={hideLogOut}
                                />

                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack grow={1}></Stack>
            </Stack>           
            
            {/*region Customer Support*/}
            {openContactUs && 
                <CustomerSupport showModal={openContactUs} setShowModal={hideContactUs} defaultSubject={"Help with registration"}/>
            }
            {/*endregion*/}

        </>
    );
}

export default WebRegistrationAuth0