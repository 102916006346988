import {
    DefaultEffects,
    FontWeights,
    getTheme,
    Icon,
    IStyleFunctionOrObject,
    IToggleStyleProps,
    IToggleStyles,
    mergeStyleSets,
    Stack,
    Text,
    Toggle
} from '@fluentui/react'
import React, {useEffect, useState} from 'react'
import {getTypeName, InputType} from '../Validations'
import {useFormAssembly} from './hooks/useFormAssembly';
import {ILayout} from '../CustomTemplate';
import {useBranding} from "../../../hooks/useBranding";
import {conditionColor, defaultCCColor, falseClauseColor, trueClauseColor} from '../../../interfaces/IFormAssembly';
import { Theme } from '@fluentui/react-theme-provider';

const theme = getTheme();

type WizardLayoutItemProps = {
    control: ILayout
    onChangeBlockCC: (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => void
    onTakeItem: (ev: React.MouseEvent<HTMLElement>) => void
    isOpen: boolean
    setIsOpen: Function
    status?: string[]
}


export default function WizardLayoutItem({ onChangeBlockCC, onTakeItem, status: _status = [], isOpen, setIsOpen, control }: WizardLayoutItemProps) {

    const {branding} = useBranding()
    const { editMode} = useFormAssembly()
    const [status, setStatus] = useState<string[]>([])
    const toggleStyle: IStyleFunctionOrObject<IToggleStyleProps, IToggleStyles> = {
        root: {},
        pill: { height: 10, width: 30, },
    };


    const statusStyles = mergeStyleSets({
        formAssembly: {
            backgroundColor: `${status[0]!} !important`
        },

        formAssemblyGradient: {
            background: `linear-gradient(to right, ${status.map((item) => {
                return item
            })})`,
        },
        isOpen: {
            filter: "brightness(80%)"
        }

    })


    useEffect(() => {

        setStatus([])
        if (editMode.type === 'RULES_CONDITION') {

            const isInCondition = editMode.rule?.conditions.find(condition => condition.field === control.Id)

            if (isInCondition) {
                setStatus(prevState => {
                    return [...prevState, conditionColor]
                })
            }

            const isInAction = editMode.rule?.actions.find(action => action.type === 'field' && action.value === control.Id)
            if (isInAction) {
                setStatus(prevState => {
                    return [...prevState, trueClauseColor]
                })
            }

            return
        }
    }, [control.Id, editMode])


    /**
     * Render a grid item for content.
     *
     * @param {Object} control - The control information for the grid item.
     * @param {Object} isOpen - Open state of the item.
     * @returns {Element} - The rendered grid item.
     */
    const ContentGridItem = ({control, isOpen}: { control: ILayout, isOpen: boolean }) => {
        return (
            <>
                <Stack horizontal styles={{
                    root: {
                        cursor: 'pointer',
                        maxWidth: '100%'
                    }
                }}>

                    <Stack grow={true} styles={{
                        root: {
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }
                    }}>
                        <Text block title={control.Label} variant={"medium"} styles={{
                            root: {
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                fontWeight: isOpen? FontWeights.semibold : FontWeights.regular
                            }
                        }}>
                            {control.Validations.Required &&
                                <>
                                    <span style={{color: branding.theme.semanticColors.errorText}}>*</span>
                                    {" "}
                                </>
                            }
                            {control.Label}
                            {control.Validations.ReadOnly &&
                                <>
                            <span style={{color: branding.theme.semanticColors.errorText}}>
                            {" "}(Read-only)                                
                            </span>
                                </>
                            }


                        </Text>
                    </Stack>
                    

                    <Stack styles={{root: {paddingLeft: 8}}}>
                        <Icon
                            styles={iconButtonItemStyles}
                            iconName="Settings"
                            title="View properties"
                            id={`C${control.Id}`}
                        />
                    </Stack>

                </Stack>
            </>
        )
    }

    return (
        <>
            <Stack id={`I${control.Id}`} grow={true} styles={{
                root: {
                    height: "100%",
                    margin: '0',
                    backgroundColor: status.length > 0 ? 'white' : `${branding.theme.palette.neutralLight}`,
                    borderWidth: control.Validations.Hide ? '1px' : status.length > 0 ? `1px` : 'none',
                    borderStyle: control.Validations.Hide ? 'dashed' : status.length > 0 ? 'solid' : 'none',
                    borderCollapse: 'collapse',
                    borderColor:  status.length > 0 ? `${branding.theme.palette.themeDark}` : control.Validations.Hide ? `${branding.theme.palette.neutralSecondary}` : 'none',
                    boxShadow: isOpen || status.length > 0 ? DefaultEffects.elevation4 : ''
                }
            }}
                   className={`${isOpen ? statusStyles.isOpen : ''}`}>

                <Stack horizontal>
                    <Stack horizontal wrap={false} tokens={{childrenGap: 4}}
                           styles={{root: {alignItems: 'baseline', padding: '4px 8px'}}}>
                        <Toggle
                            checked={control.Layout.static}
                            id={`B${control.Id}`}
                            styles={toggleStyle}
                            onChange={onChangeBlockCC}
                        />

                        <Icon
                            styles={iconButtonWithoutHoverStyles}
                            iconName={!control.Layout.static ? "Unlock" : "Lock"}
                            title={
                                !control.Layout.static
                                    ? "Unlocked item"
                                    : "Locked item"
                            }
                        />
                    </Stack>
                    <Stack grow={1} styles={{root: {width: 0}}}>
                        <Stack horizontal wrap={false} styles={{root: {height: 4}}}>
                            {status.map((item =>
                                    <Stack grow={1} styles={{
                                        root: {
                                            height: 4,
                                            marginLeft: 4,
                                            marginRight: 4,
                                            backgroundColor: item
                                        }
                                    }}></Stack>
                            ))}
                        </Stack>
                        {(control.Type === InputType.SectionHeader || control.Type === InputType.LineBreak) &&
                            <Stack grow={true} title={control.Label}
                                   styles={{
                                       root: {
                                           alignItems: 'center',
                                           justifyContent: 'center'
                                       }
                                   }}
                                   id={`L${control.Id}`} onClick={(event) => {
                                setIsOpen(event.currentTarget.id)
                            }}>
                                <ContentGridItem control={control} isOpen={isOpen}/>
                            </Stack>
                        }
                    </Stack>
                    <Stack horizontal styles={{root: {padding: 8}}}>
                        {control.Validations.Hide &&
                            <Icon
                                styles={iconHideButtonStyles(branding.theme)}
                                iconName={"Hide3"}
                                title="Hidden"
                                aria-label={"Hidden"}
                            />
                        }
                        <Icon
                            styles={iconButtonStyles}
                            iconName={"Cancel"}
                            id={control.Id}
                            onClick={onTakeItem}
                            title="Remove item"
                        />
                    </Stack>
                </Stack>
                {(control.Type !== InputType.SectionHeader && control.Type !== InputType.LineBreak) &&
                    <>
                        <Stack grow={true} styles={{
                            root: {
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: "100%",
                                paddingLeft: 8,
                                paddingRight: 8
                            }
                        }}
                               title={control.Label}
                               id={`L${control.Id}`}
                               onClick={(event) => {
                                   setIsOpen(event.currentTarget.id)
                               }}
                        >
                            <ContentGridItem control={control} isOpen={isOpen}/>

                        </Stack>
                        <Stack tokens={{padding: 4}}>
                            <Text variant={"xSmall"}
                                  title={`${getTypeName(control.Type)} - ${control.manualTag ? "(Manual tag)" : ""}`}
                                  styles={{
                                      root: {
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                      }
                                  }}>
                                {getTypeName(control.Type)}
                                <em>{control.manualTag ? " (Manual tag)" : ""}</em>
                            </Text>
                        </Stack>
                    </>
                }
            </Stack>
        </>
    )
}


const iconHideButtonStyles = (theme:Theme) => {
    return {
        root: {
            color: theme.semanticColors.errorText,
            marginLeft: "auto",
            marginTop: "4px",
            marginRight: "4px",
            selectors: {
                ":disabled": {
                    backgroundColor: theme.palette.white,
                },
            },
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    }
}


const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        selectors: {
            ":hover": {
                cursor: "pointer",
            },
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
}

const iconButtonItemStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginTop: "3px",
        marginRight: "1px",
        selectors: {
            ":hover": {
                cursor: "pointer",
            },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const iconButtonWithoutHoverStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        selectors: {
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};