import React, {useEffect, useReducer, useState} from 'react'
import {UseFormReturn} from 'react-hook-form';
import {ILayout, Validation} from '../../../CustomTemplate';
import {IFormAssemblyRule} from '../../../../../interfaces/IFormAssembly';
import CustomInput from './CustomInput';
import {useCustomForm} from '../../hooks/useCustomForm';
import {formAssemblyReducer} from '../../reducers/formAssemblyReducer';
import {InputType} from "../../../Validations";
import {IOutputMap} from "../../../../../interfaces/IOutbound";


type ControlComponent = {
    form: UseFormReturn<any, any>
    utils: any
    labelVisible: boolean
    formRules?: IFormAssemblyRule[]
    isInConditions: boolean
    AdminOnly: boolean
    currenPage: number
    val: ILayout
}


export const CustomElement = ({
                                  form,
                                  utils,
                                  isInConditions,
                                  AdminOnly,
                                  val, currenPage
                              }: ControlComponent) => {

    const {Id: id} = val
    const {
        layouts,
        validateRules,
        updateFieldsBeforeIntegration,
        getWatchedConditionsRulesById,
    } = useCustomForm()

    const [validations] = useState<Validation>(val.Validations)

    const [state, dispatch] = useReducer(formAssemblyReducer, {
        id,
        formRules: getWatchedConditionsRulesById(id),
        layouts: layouts,
    })


    // DONE add OnBlur another fields
    // DONE ActionType.Value to correct formats and fields
    // DONE Execute rules onInit when is new document or drafts
    // DONE check rules when enable or disabled current page / all pages
    // DONE check rules when enable or disabled current page and the page have required fields
    // todo what happens when a field is hide but is required?
    // DONE remove white space. 
    // DONE Check conditions comparison 
    // todo lock wizard with rules?
    // todo check document links: update, template, campaigns

    /**
     * Executes the specified callback function when the onBlur or onChange event occurs.
     *
     * @returns {void}
     */
    const OnBlurOrOnChange = (): void => {
        if (isInConditions) {
            validateRules(state.formRules, form, AdminOnly, false, 'OnManual', currenPage)
        }
    }

    /**
     * Updates the fields before integration.
     *
     * @param {IOutputMap[]} data - The data to be updated.
     */
    const updateBeforeIntegration = (data: IOutputMap[]) => {
        updateFieldsBeforeIntegration(data, form, AdminOnly, currenPage)
    }


    return (
        <>
            {val!.Validations.Hide ?
                <>
                </>
                :
                <>
                    <div
                        /*id={val.Id}*/
                        style={{
                            gridArea: val.Id
                        }}
                        className={`react-grid-item react-resizable-hide react-resizable ${InputType[val.Type]}`}
                        key={val.Id}>
                        <CustomInput layout={val} form={form} utils={utils}
                                     labelVisible={true} isRequired={validations.Required}
                                     readOnly={validations.ReadOnly}
                                     OnBlurOrOnChange={OnBlurOrOnChange}
                                     updateBeforeIntegration={updateBeforeIntegration}
                        />
                    </div>
                </>

            }
        </>
    )

}