import * as React from "react";
import {FC} from "react";
import {Controller} from "react-hook-form";
import RichTextEditor, {EditorValue, ToolbarConfig} from 'react-rte';

import "../../Custom.css"
import {ReactHookFormControlProps} from "../CustomTemplates/CustomForm/Models/ReactHookFormControlProps";
import {IComboBoxOption, Label, StackItem} from "@fluentui/react";
import ContentControls from "./SingleRHFRichTextComponents/ContentControls";
import {DraftDragType, DraftHandleValue, EditorState, getDefaultKeyBinding, KeyBindingUtil, Modifier, SelectionState} from "draft-js";


export interface IRichTextEditorProps_ {
    setRteValue: React.Dispatch<React.SetStateAction<EditorValue>>
    rteValue: EditorValue
    label: string
    placeholder: string
    required?: boolean
    onRenderOption: (option?: IComboBoxOption) => JSX.Element;
    options: IComboBoxOption[]
    toolbarConfig: ToolbarConfig
    disabled?: boolean
    isTextArea?: boolean
}

export const SingleRHFRichTextControl: FC<ReactHookFormControlProps & IRichTextEditorProps_> = (
    props
) => {

    const onChange = (editorValue: EditorValue) => {
        props.setRteValue(editorValue)
    }
    const {hasCommandModifier, isCtrlKeyCommand} = KeyBindingUtil;


    function handleDrop(selection: SelectionState, dataTransfer: Object, isInternal: DraftDragType) {
        if (props.isTextArea !== undefined && props.isTextArea) {
            
            return true
        }
        else {
            return false
        }
    }
    
    function handlePastedText(text: string, html: string, state: EditorState) {

        if (props.isTextArea !== undefined && props.isTextArea) {

            const newText = text.replace(/(\r\n|\n|\r)/gm, "")
            const newContent = Modifier.replaceText(
                state.getCurrentContent(),
                state.getSelection(),
                newText
            )
            props.setRteValue(EditorValue.createFromState(EditorState.push(state, newContent, 'insert-fragment')))
            return true
        } else
            return false

    }

    /**
     * handleReturn
     * @param event
     */
    function handleReturn(event: object) {
        if (props.isTextArea !== undefined && props.isTextArea) return true
        return false
    }

    function keyBindingFn(e: any): string | undefined {

        if (props.isTextArea) {

            if (hasCommandModifier(e)) {

                switch (e.keyCode) {
                    case 66: // B
                        return 'undefined'
                    case 73: // I
                        return 'undefined'
                    case 74: // J
                        return 'undefined'
                    case 85: // U
                        return 'undefined'
                    case 77: // M
                        return isCtrlKeyCommand(e) ? 'undefined' : 'undefined';
                    case 79: // O
                        return isCtrlKeyCommand(e) ? 'undefined' : 'undefined';
                    default:
                        return getDefaultKeyBinding(e)!
                }
            }
            return getDefaultKeyBinding(e)!
        } else {
            return getDefaultKeyBinding(e)!
        }
    }

    function handleKeyCommand(command: string): DraftHandleValue {
        return 'not-handled';
    }

    const RTEProps = {
        handlePastedText,
        keyBindingFn,
        handleReturn,
        handleDrop
    }


    return (
        <Controller
            control={props.control}
            rules={props.rules}
            name={props.id}
            defaultValue={props.rteValue}
            render={({formState: {errors}}) => (
                <StackItem id={props.id} style={{height: "200px"}}>
                    <Label required={props.rules !== undefined && props.rules.required !== undefined ? true : undefined}
                    >{props.label}
                    </Label>

                    {errors !== undefined && errors[props.id] !== undefined && errors[props.id]!.message!.toString().length > 0 ?
                        <span style={{
                            fontSize: "14px",
                            color: "#973434",
                            fontWeight: 600
                        }}>{errors[props.id]!.message}</span>
                        : null
                    }
                    <RichTextEditor
                        {...RTEProps}
                        toolbarConfig={props.toolbarConfig}
                        customControls={[
                            <ContentControls editorValue={props.rteValue} onChange={onChange}
                                             onRenderOption={props.onRenderOption} options={props.options}
                            />,
                        ]}
                        value={props.rteValue}
                        onChange={onChange}
                        placeholder={props.placeholder}
                        className={"richText"}
                        disabled={props.disabled}

                    />
                </StackItem>
            )}
        />
    );
};