import {useContext, useRef} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {IDropdownOption} from '@fluentui/react';
import {ILayout} from '../../CustomTemplate';
import {InputType} from '../../Validations';
import {IFormControl} from '../../../../Helpers/Helper';
import {DisplayType} from '../../../OutboundIntegration/Models/Enums';


import {cleanHtml,} from '../RHFControls/utils/functions';
import {getDateFromFormat, getISODateString} from '../../../../Helpers/DateUtils';
import {context} from '../context/CustomFormContext';
import {useDocument} from '../../../../hooks/useDocument';
import {IClausule, IFormAssemblyRule} from "../../../../interfaces/IFormAssembly";
import {evaluateConditionRules} from "../../CustomTemplate/formAssembly/utils";
import {evaluate} from 'mathjs';
import {IOutputMap} from "../../../../interfaces/IOutbound";
import DOMPurify from 'dompurify'

export const useCustomForm = () => {
    const { getTextboxValue } = useDocument()
    const { layouts, originalLayouts, setLayouts, data, user, applicationResponse, documentValues,
        integrationDropDownLists, isSubform, formRules, isAdmin, locale,
        customDropDownLists, triggerRules, setTriggerRules, triggerPages, setTriggerPages, gridAreaString, setGridAreaString } = useContext(context)

    const layoutsRef = useRef(layouts)

    const getLabel = (adminOnly: boolean, label: string): string => {
        if (adminOnly) {
            if (user.isOwner || user.isCoOwner) {
                return label;
            } else {
                return "";
            }
        } else {
            return label;
        }
    }
    const displayIntegration = (layout: ILayout) => {
        if (layout.Integration?.DisplayType === DisplayType.Always || (layout.Integration?.DisplayType === DisplayType.OnCreate && (data === null || data === "" || data === undefined)) || (layout.Integration?.DisplayType === DisplayType.OnUpdate && data !== null && data !== "" && data !== undefined)) {
            return true;
        } else {
            return false;
        }
    }
  
    const getDefaultValue = (layout: ILayout, form: UseFormReturn<any, any>): any => {
        switch (layout.Type) {
            case InputType.RichText:
                let richValue = "";
                if (isSubform) {
                    richValue = layout.Validations.DefaultValue;
                } else {
                    let documentRichTextValue = documentValues.find(
                        (info) => info.Key === layout.Id
                    );
                    richValue =
                        documentRichTextValue !== undefined
                            ? documentRichTextValue.Value
                            : layout.Validations.DefaultValue !== ""
                                ? layout.Validations.DefaultValue
                                : "";
                }
                return richValue;
            default:
                const currentValue = form.getValues(layout.Id);
                let tempCurrentValue = currentValue !== undefined ? currentValue : layout.Validations.DefaultValue;
                if (layout.Validations?.DefaultValue?.length > 0 && (currentValue === undefined || currentValue === "")) {
                    tempCurrentValue = layout.Validations?.DefaultValue?.trim();
                }
                let formatedValue = getFormatedValue(layout, {
                    Key: layout.Id,
                    Value: tempCurrentValue,
                });
                //form.setValue(layout.Id, formatedValue);

                let gridColumns = layouts.reduce((acc: ILayout[], el: ILayout) => acc.concat(el.Children), []);
                if (gridColumns.find((l) => l.Id === layout.Id) !== undefined) {
                    if (formatedValue === undefined) return undefined;
                    else if (layout.Type === InputType.DropDownList) return formatedValue.key;
                    else return formatedValue;
                }

                return tempCurrentValue;
        }
    }
    const getFormatedValue = (layout: ILayout, valueToFormat: IFormControl): any => {
        switch (layout.Type) {
            case InputType.DropDownList: {
                return valueToFormat.Value.length > 0
                    ? {
                        key:
                            layout.Integration !== undefined &&
                                layout.Integration !== null
                                ? integrationDropDownLists[
                                    layout.Integration.Id.toString()
                                ]
                                    ?.find(
                                        (item) =>
                                            item.text ===
                                            valueToFormat.Value
                                    )
                                    ?.key.toString()
                                : +layout.ListId > 0
                                    ? customDropDownLists[layout.ListId]
                                        ?.find(
                                            (item) =>
                                                item.text ===
                                                valueToFormat.Value
                                        )
                                        ?.key.toString()
                                    : valueToFormat?.Value,
                        text: valueToFormat?.Value,
                    }
                    : undefined;
            }
            case InputType.CheckBoxList: {
                return valueToFormat.Value.length > 0
                    ? valueToFormat.Value.split(",").map((text) => {
                        return {
                            key:
                                layout.Integration !== undefined &&
                                    layout.Integration !== null
                                    ? integrationDropDownLists[
                                        layout.Integration.Id.toString()
                                    ]
                                        ?.find((item) => item.text === text)
                                        ?.key.toString()
                                    : +layout.ListId > 0
                                        ? customDropDownLists[layout.ListId]
                                            ?.find((item) => item.text === text)
                                            ?.key.toString()
                                        : text,
                            text: text,
                            selected: true,
                        };
                    })
                    : undefined;
            }
            case InputType.Textbox: {
                return valueToFormat.Value != undefined &&
                    valueToFormat.Value.length > 0
                    ? getTextboxValue(valueToFormat.Value)
                    : undefined;
            }
            case InputType.TextArea: {
                return cleanHtml(valueToFormat.Value);
            }
            case InputType.CheckBox: {
                if (applicationResponse![0]?.CheckBoxCCs.find((item: string) => item === layout.Id) !== undefined) {
                    if (layout.Validations?.DefaultValue.length > 0) {
                        return "true";
                    } else {
                        return "";
                    }
                } else {
                    if (layout.Validations?.DefaultValue.length > 0) {
                        if (layout.Validations?.DefaultValue.toLowerCase() === "true") {
                            return "true"
                        } else {
                            return "false"
                        }
                    } else {
                        return "false"
                    }

                }

            }
            default: {
                return valueToFormat.Value;
            }
        }
    }
    const getFormControls = (data: any): IFormControl[] => {
        let keys = Object.keys(data);
        let values = Object.values(data);
        let formControls: IFormControl[] = [];

        keys.forEach((key, keyIndex) => {
            let internalLayouts = layouts.reduce((acc: ILayout[], el: ILayout) => acc.concat(el.Children.length > 0 ? el.Children : el), []);
            let currentLayout = internalLayouts.find((l) => l.Id === key);
            let formControl: IFormControl = { Key: key, Value: "" };
            switch (
            currentLayout === undefined
                ? InputType.Textbox
                : currentLayout.Type
            ) {
                case InputType.CheckBoxList:
                case InputType.DropDownList:
                    if (Array.isArray(values[keyIndex])) {
                        formControl.Value = values[keyIndex] === undefined ? "" : (values[keyIndex] as IDropdownOption[]).map((item) => item.text).join(",");
                        const dropdownObject = values[keyIndex] as IDropdownOption[];
                        formControl.CheckedValues = dropdownObject.map((x) => {
                            return { Key: x.key, Text: x.text };
                        })

                    } else if (values[keyIndex] instanceof Object) {
                        if (values[keyIndex] === undefined) {
                            formControl.Value = "";
                            break;
                        }
                        const dropdownObject = values[keyIndex] as IDropdownOption;
                        formControl.Value = dropdownObject.text;
                        formControl.CheckedValues = [];
                        formControl.CheckedValues = [
                            ...formControl.CheckedValues,
                            {
                                Key: dropdownObject.key,
                                Text: dropdownObject.text,
                            },
                        ];
                    } else {
                        formControl.Value = values[keyIndex] as string;
                    }
                    break;
                case InputType.DatePicker:
                    const dateFormated =
                        values[keyIndex] != undefined
                            ? getDateFromFormat(
                                (values[keyIndex] as string,
                                    currentLayout?.Validations.Regex ?? "")
                                    .toString()
                                    .trimEnd()
                            )
                            : "";
                    formControl.Value =
                        dateFormated === ""
                            ? getISODateString(values[keyIndex] as Date)
                            : dateFormated;
                    break;
                case InputType.CheckBox:
                    if (applicationResponse![0]?.CheckBoxCCs.find((item: string) => item === currentLayout?.Id) !== undefined) {
                        if (values[keyIndex] === undefined || values[keyIndex] === "false") {
                            formControl.Value = "";
                        } else if ((values[keyIndex] as string).toString() === "true") {
                            formControl.Value = "true"
                        } else {
                            formControl.Value = "";
                        }
                    } else {
                        formControl.Value = values[keyIndex] as string;
                    }
                    break;
                case InputType.Image:
                    formControl.Value = "";
                    break;
                case InputType.Textbox:
                    formControl.Value = values[keyIndex] != undefined ? (values[keyIndex] as string).toString().trimEnd() : "";
                    break;
                default:
                    formControl.Value = values[keyIndex] as string;
            }
            formControls.push(formControl);
        });
        return formControls;
    }

   
    /**
     * Returns an array of watched rules based on the given adminOnly flag.
     *
     * @returns {Array<IFormAssemblyRule>} - An array of watched rules.
     */
    const getWatchedConditionsRules = (): IFormAssemblyRule[] => {
        return formRules?.filter((rule) =>
            (rule.disabled === undefined || !rule.disabled) &&
            (rule.conditions.length > 0 && (rule.actions.length > 0))
        )
    }


    /**
     * Retrieves watched conditions rules by ID.
     *
     * @param {string} id - The ID of the field.
     * @returns {IFormAssemblyRule[]} - The array of watched conditions rules.
     */
    const getWatchedConditionsRulesById = (id: string): IFormAssemblyRule[] => {
        return formRules?.filter((rule) =>
            (rule.disabled === undefined || !rule.disabled) &&
            rule.conditions.some((r) => r.field === id) &&
            (rule.actions.length > 0)
        )
    }
    

    /**
     * Determines if a given field ID is present in the conditions of form rules.
     *
     * @param {string} id - The field ID to check.
     * @returns {boolean} - True if the field ID is present in the conditions, false otherwise.
     */
    const isPresentInConditions = (id: string): boolean => {
        const isPresent = formRules.filter((rule) =>
            (rule.disabled === undefined || !rule.disabled) &&
            rule.conditions.some((r) => r.field === id)
        )
        return isPresent.length > 0
    }
    
    
    /**
     * Checks if a given field ID is present in a rule action.
     *
     * @param {string} id - The ID of the field to check.
     * @returns {boolean} - Returns `true` if the field ID is present in a rule condition, `false` otherwise.
     */
    const isPresentInActions = (id: string): boolean => {
        const isPresent = formRules.filter((rule) =>
            (rule.disabled === undefined || !rule.disabled) &&
            (
                rule.conditions.some((r) => r.field === id) ||
                rule.actions.some((r) => r.value === id)                
            )
        )

        return isPresent.length > 0
    }


    /**
     * Parse and set value for a given layout element.
     * @param {ILayout} layout - The layout element.
     * @param {string} value - The value to set.
     * @param {<any, any>} form - The form object.
     */
    function parseAndSetValue(layout: ILayout, value: string | undefined, form: UseFormReturn<any, any>) {
        let fieldValue = value ?? ''
        fieldValue = DOMPurify.sanitize(fieldValue, {USE_PROFILES: {html: false}})

        switch (layout.Type) {
            case InputType.CheckBox:
                if (fieldValue.toLowerCase() === 'true' || fieldValue.toLowerCase() === 'false') {
                    form.setValue(`${layout.Id}` as const, fieldValue.trim().toLowerCase())
                }
                break
            case InputType.RichText:
                form.setValue(`${layout.Id}` as const, value)
                break
            case InputType.Textbox:
                form.setValue(`${layout.Id}` as const, value)
                break
            case InputType.TextArea:
                form.setValue(`${layout.Id}` as const, value)
                break
            case InputType.CheckBoxList:
                form.setValue(`${layout.Id}` as const, value)
                break
            case InputType.DropDownList:
                form.setValue(`${layout.Id}` as const, value)
                break            
            case InputType.Currency:
                if (!isNaN(parseFloat(fieldValue)) || !isNaN(Number(fieldValue))) {
                    form.setValue(`${layout.Id}` as const, fieldValue)
                }
                else {
                    form.setValue(`${layout.Id}` as const, '0')
                }
                break
            case InputType.Number:
                if (!isNaN(parseFloat(fieldValue)) || !isNaN(Number(fieldValue))) {
                    form.setValue(`${layout.Id}` as const, parseInt(fieldValue.replace(/,/g, '')).toString())
                } else {
                    form.setValue(`${layout.Id}` as const, '0')
                }
                break
            case InputType.DatePicker:
                const date = new Date(fieldValue);
                if (!isNaN(date.getTime())) {
                    const isoDate = getISODateString(date)
                    form.setValue(`${layout.Id}` as const, isoDate)
                } 
                break
            
            default:
                break
        }
    }


    /**
     * Updates fields before integration.
     *
     * @param {IOutputMap[]} data - The array of data to be updated.
     * @param {UseFormReturn<any, any>} form - The form object from the react-hook-form library.
     * @param adminOnly
     * @param currentPage
     * @returns {void} This function does not return a value.
     */
    const updateFieldsBeforeIntegration = (data: IOutputMap[], form: UseFormReturn<any, any>, adminOnly: boolean, currentPage:number): void => {
        data.forEach((item) => {

            form.setValue(item.TargetField, item.TargetValue ?? '')
            const triggerCount = triggerRules.get(item.TargetField) || 0

            if (isPresentInConditions(item.TargetField)) {
                const chainedRules = getWatchedConditionsRulesById(item.TargetField)
                validateRules(chainedRules, form, adminOnly, false, 'OnManual', currentPage)
            }

            setTriggerRules(prev => {
                const newMap = new Map(prev)
                newMap.set(item.TargetField, triggerCount + 1)
                return newMap
            })
        })
    }
    
    /**
     * Executes a series of actions based on the given expressions.
     *
     * @param {IClausule[]} expressions - The array of expressions with actions to execute.
     * @param form
     * @param AdminOnly
     * @param avoidExecuteSetValues
     * @param executionType
     * @param version
     * @param currentPage
     */
    const executeActions = (expressions: IClausule[], form: UseFormReturn<any, any>, AdminOnly: boolean, avoidExecuteSetValues: boolean, executionType: 'OnInit' | 'OnManual', version: number = 0, currentPage:number) => {

        let reCalculateGridAreas:boolean   = false
        expressions.forEach((expression) => {

            if (expression.type === 'field') {
                if (expression.value !== undefined) {

                    const layout = layouts.find((layout) => layout.Id === expression.value)

                    if (layout !== undefined) {
                        if (version > 1) {
                            // HIDE
                            if (expression.hide === true) {
                                reCalculateGridAreas = true
                                layout.Validations.Hide = true
                            }

                            //SHOW
                            if (expression.hide === false) {
                                reCalculateGridAreas = true
                                layout.Validations.Hide = undefined
                            }

                            // UNLOCK
                            if (expression.readonly === true) {
                                layout.Validations.ReadOnly = true
                            }

                            // LOCK
                            if (expression.readonly === false) {
                                layout.Validations.ReadOnly = false
                            }

                            // VALUE
                            if (expression.setValue === true) {
                                if (!avoidExecuteSetValues) {
                                    parseAndSetValue(layout, expression.fieldValue, form)
                                    
                                    
                                    if (isPresentInConditions(layout.Id)) {
                                        const chainedRules = getWatchedConditionsRulesById(layout.Id)
                                        validateRules(chainedRules, form, AdminOnly, avoidExecuteSetValues, executionType, currentPage)
                                    }
                                }
                            }                            

                            const triggerCount = triggerRules.get(layout.Id) || 0

                            setTriggerRules(prev => {
                                const newMap = new Map(prev);
                                newMap.set(layout.Id, triggerCount + 1);
                                return newMap;
                            })
                        }
                    }
                }
            }

            if (expression.type === 'tab' && expression.value !== undefined) {

                if (version > 2) {
                  
                    // LOCK
                    if (expression.readonly === true) {
                        layouts.forEach((item, index) => {
                            if (item.Page.toString() === expression.value) {
                                item.AvoidValidations = true
                            }
                        })
                        
                        setTriggerPages(prev => {
                            const newMap = new Map(prev)
                            newMap.set(expression.value ?? '', true)
                            return newMap
                        })
                    }

                    // UNLOCK
                    if (expression.readonly === false) {
                        layouts.forEach((item, index) => {
                            if (item.Page.toString() === expression.value) {
                                item.AvoidValidations = false
                            }
                        })
                        
                        setTriggerPages(prev => {
                            const newMap = new Map(prev)
                            newMap.set(expression.value ?? '', false)
                            return newMap
                        })
                    }
                }                
            }
        })
        
        if(reCalculateGridAreas) {
            CreateGridAreasStyle(currentPage)
        }
    }


    /**
     * Adjusts a list of actions by inverting certain characteristics based on the 'originalLayouts' configuration.
     *
     * This method processes each action, checking its type and adjusting its 'enable' and 'visible' properties
     * according to the corresponding original layout's validation settings. If the action type is 'field', it
     * retrieves the related original layout and sets 'enable' and 'visible' based on the layout's 'ReadOnly'
     * and 'Hide' validations respectively. If the original layout is not found, it leaves the action unchanged.
     * For non-'field' actions, it sets 'enable' to true.
     *
     * @param {IClausule[]} actions - An array of action objects to be processed. Each action should have a 'type'
     * property and, if 'type' is 'field', a 'value' property that correlates with an ID in 'originalLayouts'.
     * @returns {IClausule[]} A new array of actions with adjusted 'enable' and 'visible' properties based on
     * the original layout's validations.
     */
    const invertActions = (actions: IClausule[]): IClausule[] => {
        return actions.map((action) => {
            if (action.type === 'field') {
                const originalLayout = originalLayouts.find(item => item.Id === action.value)

                if (originalLayout !== undefined) {
                    return {
                        ...action,
                        readonly: originalLayout.Validations.ReadOnly === true,
                        hide: originalLayout.Validations.Hide === true,
                        setValue: false
                    }
                } else {
                    return action
                }
            } else {
                return {
                    ...action,
                    readonly: false
                }
            }
        })
    }
    
    
    /**
     * Validates the given rules and executes corresponding actions based on the evaluation result.
     *
     * @param {IFormAssemblyRule[]} rules - The array of rules to validate.
     * @param form
     * @param AdminOnly
     * @param avoidExecuteSetValues
     * @param executionType
     * @param currentPage
     * @returns {void}
     */
    const validateRules = (rules: IFormAssemblyRule[], form: UseFormReturn<any, any>, AdminOnly: boolean, avoidExecuteSetValues: boolean = false, executionType: 'OnInit' | 'OnManual', currentPage:number): void => {
        rules.map(rule => {
            rule.conditions[rule.conditions.length - 1].operatorGroup = ''
            const ruleToEvaluate = evaluateConditionRules(rule.conditions, form.getValues(), layouts, locale, AdminOnly)
            const ruleEvaluated = evaluate(ruleToEvaluate)
            const actions: IClausule[] = ruleEvaluated ? rule.actions : invertActions(rule.actions)
            executeActions(actions, form, AdminOnly, avoidExecuteSetValues, executionType, rule.version, currentPage)
        })
    }


    /**
     * Creates a bi-dimensional array representing the layout grid based on the input elements.
     *
     * @param {ILayout[]} elements - An array of layout objects. Each object contains layout properties such as coordinates and dimensions.
     * @return {Array<string[]>} A bi-dimensional array where each element represents the state of that position in the grid.
     */
    function CreateBidimensionalArray(elements: ILayout[]): Array<string[]> {
        let maxX = 4;
        let maxY = 0;
        const collapsableItems: number[] = [];

        // Determine grid dimensions
        elements.forEach(element => {
            maxY = Math.max(maxY, element.Layout.y + element.Layout.h)
        })

        // Initialize bi-dimensional array
        let elementsGridArea: Array<string[]> = Array.from({length: maxY}, () => Array(maxX).fill("."))

        // Place items in the grid
        elements.forEach((value) => {
            for (let i = 0; i < value.Layout.h; i++) {
                for (let j = 0; j < value.Layout.w; j++) {
                    if (value.Validations.Hide) {
                        elementsGridArea[value.Layout.y + i][value.Layout.x + j] = "..."
                    } else {
                        elementsGridArea[value.Layout.y + i][value.Layout.x + j] = value.Layout.i
                    }
                }
            }
        })

        // Identify collapsable rows
        elementsGridArea.forEach((row, index) => {
            let collapsable = true

            for (const col of row) {
                if (col !== "." && col !== "...") {
                    collapsable = false
                }
            }

            // Omit user empty rows
            if (row[0] === "." && row[1] === "." && row[2] === "." && row[3] === ".") {
                collapsable = false
            }

            if (collapsable) {
                collapsableItems.push(index)
            }
        })

        // Collapse rows
        for (const index of collapsableItems.reverse()) {
            elementsGridArea.splice(index, 1)
            // elementsGridArea.push(["...", "...", "...", "..."])
        }

        return elementsGridArea
    }

    function CreateGridAreas(elementsGridArea: string[][]): string {
        return elementsGridArea.map(subArray => '"' + subArray.join(' ') + '"').join(' ')
    }

    /**
     * Creates and applies a grid area style based on the layout of the provided page.
     *
     * @param {number} currentPage - The current page number used to filter the layouts.
     * @return {void} This function does not return a value.
     */
    function CreateGridAreasStyle(currentPage:number): void {
        const gridLayout: ILayout[] = layouts.filter((item) => +item.Page === currentPage)
        const result =  CreateBidimensionalArray(gridLayout)
        const style = CreateGridAreas(result)
        setGridAreaString(style)
    }
    
    const userIsAdmin = user.isOwner || user.isCoOwner


    return {
        isAdmin,
        userIsAdmin,
        layouts,
        setLayouts,
        getLabel,
        formRules,
        displayIntegration,
        getDefaultValue,
        //getFormatedValue,
        getFormControls,
        layoutsRef,
        isPresentInActions,
        isPresentInConditions,
        getWatchedConditionsRules,
        getWatchedConditionsRulesById,
        updateFieldsBeforeIntegration,
        validateRules,
        triggerRules,
        setTriggerRules,        
        triggerPages, setTriggerPages,
        gridAreaString, CreateGridAreasStyle
    }
}