import {
	ActionButton,
	ChoiceGroup,
	Dropdown,
	getTheme,
	IChoiceGroupOption,
	IDropdownOption,
	IDropdownStyles,
	IScrollablePaneStyles,
	mergeStyleSets,
	Spinner,
	SpinnerSize,
	TextField,
	Toggle,
	TooltipHost
} from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Text } from '@fluentui/react/lib/Text';
import { Stack } from '@fluentui/react/lib/Stack';
import React, { useEffect, useState } from 'react';
import Helper, {
	IDocumentOnlyAudienceDetail,
	ITemplateGeneral,
	ITemplateSignatureRequest
} from '../../../Helpers/Helper';
import { List } from "@fluentui/react/lib/List";
import { Separator } from "@fluentui/react/lib/Separator";
import { Icon } from "@fluentui/react/lib/Icon";
import { Label } from "@fluentui/react/lib/Label";
import { RHFTextBoxControl } from "../../RHFControls/RHFTextBoxControl";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";
import { IDocusignTagsRoles } from "../../DataPanel/Signers";
import { formatString } from '../../../Helpers/functions';
import { useBranding } from "../../../hooks/useBranding";
import {
	EmailTemplateNotificationsServices,
	EmailType, ICreateUpdateTemplateDefinitionRequest,
	IDeleteTemplateDefinitionRequest,
	IEmailTemplates, IGetTemplateStatusRequest, IGetTemplateStatusResponse
} from "../../EmailTemplate";
import { notificationStackItemStyles, stateEmailTemplatesDefault } from './index';
import { IUpdateTemplateStatusRequest } from "../../EmailTemplate/EmailTemplate.data";
import DocumentOnlyCustomUserSignatureService from "../../../services/DocumentOnlyCustomUserSignature";

export interface IParam {
	Name: string
	Value: string | boolean | undefined
}
export interface IParamSignature {
	Name: string
	Value: string | undefined
}
export interface ISignature {
	ClixSignatureTag: string
	DocumentOnlyCustomUserSignatureId: string
	Id: string
	GetBlobData: boolean
}

export interface SignaturesModel {
	audiences: IDocumentOnlyAudienceDetail[]
	signatures: ISignature[]
}
const stackTokens = { childrenGap: 10 };
const options: IDropdownOption[] = [
	{ key: 'None', text: 'None' },
	{ key: 'Docusign', text: 'Docusign' },
];

const classNamex = mergeStyleSets({
	pane: {
		maxWidth: '100%',
		height: '69vh',
		marginTop: '3vh'
	},
	badget: {
		borderRadius: 8,
		padding: '2px 4px',
		fontSize: "11px",
		backgroundColor: 'gainsboro',
		marginLeft: 4,
		whiteSpace: 'nowrap'
	},
});
const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNamex.pane };

const dropdownStyles: Partial<IDropdownStyles> = {
	dropdown: { width: 300 },
};


function TemplateFeatures(props: any) {
	const { t } = useTranslation(['common', 'recordsign']);
	const [committedFieldsToAdd, setCommittedFieldsToAdd] = React.useState(0);
	const [selectedSignatures, setSelectedSignatures] = useState<any>([]);
	const [signatures, setSignatures] = useState<IDropdownOption[]>([]);
	const [isnClixsignature, setisnClixsignature] = useState(false)
	const [showErrorProvider, setShowErrorProvider] = useState(false)
	const [OptionsAutosign, setOptionsAutosign] = useState<IDropdownOption[]>([]);
	const [loadingSigntures, setLoadingSignture] = useState(false)
	const [signatureResponse, setSignatureResponse] = useState<ISignature[]>([])
	const [hiddenAutosign, setHiddenAutosign] = useState(false)
	const [loading, setLoading] = useState(true)
	const [isLoadingEmailTemplates, setIsLoadingEmailTemplates] = useState(true)
	const [selectedSignature, setSelectedSignature] = useState<IDropdownOption>({ key: "0", text: "0" });

	const [documentCreationSaving, setDocumentCreationSaving] = useState(false)
	const [documentDeleteSaving, setDocumentDeleteSaving] = useState(false)
	const [documentShareSaving, setDocumentShareSaving] = useState(false)
	const [documentUpdateSaving, setDocumentUpdateSaving] = useState(false)
	const [wizardSubmissionCreateSaving, setWizardSubmissionCreateSaving] = useState(false)
	const [wizardSubmissionUpdateSaving, setWizardSubmissionUpdateSaving] = useState(false)

	const { branding } = useBranding()

	useEffect(() => {
		setOptionsAutosign([{ key: "0", text: "None" }])
		var result = loadtempTemplate()
		if (result !== undefined && result !== null) {
			props.setChekInCheckOut(result.allowcheckinout!)
			props.setSignatureChecked(result.allowesign!)
		}
		if (props.DocumentOnlyTemplateResponsebyId != undefined) {
			setLoadingSignture(true)
			GetOptionsAutosign()
			loadEmailTemplateStatus().then()
		}





		setLoading(false)

	}, [props.showTemplateFeatures])

	useEffect(() => {
		if (!loadingSigntures) {
			if (signatureResponse.length === 0) {
				setHiddenAutosign(true)
				props.MapingSignatures(signatureResponse)
				props.MapingSignaturesog(signatureResponse)
				getSignatures().then(response => { setSignatures(response); })
			}
			else {
				props.MapingSignatures(signatureResponse)
				props.MapingSignaturesog(signatureResponse)
				setHiddenAutosign(false)
				getSignatures().then(response => {
					setSignatures(response);
				})
				var defaultSignature: IDropdownOption = { key: signatureResponse.length.toString(), text: signatureResponse.length.toString() }
				setSelectedSignature(defaultSignature)
				props.GetSelectedSignature(defaultSignature.key)
				let mapSignature = signatureResponse.reduce((acc: any, item: any, index: number) => ({
					...acc,
					[index + 1]: item
				}), {})
				setSelectedSignatures(mapSignature)
				setCommittedFieldsToAdd((parseInt(signatureResponse.length.toString(), 10)))
				setLoadingSignture(false)
			}

		}
		else {

			GetTemplateSignature(props.DocumentOnlyTemplateResponsebyId.TemplateId)
		}



	}, [loadingSigntures])
	useEffect(() => {
		if (props.docusignTagsRoles !== undefined && props.docusignTagsRoles !== null && Array.isArray(props.docusignTagsRoles)) {

			props.docusignTagsRoles.map((item: IDocusignTagsRoles) => {
				props.methods.setValue("txtSignerRoleDescription_" + item.RecipientId, item.RoleDescription !== undefined ? item.RoleDescription : "")
			})
		}
	}, [])

	function _onChangeEsignature(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "allowesign" }
		loadtempTemplate(parameter)
		props.onChangeSignature(isChecked)
		props.setIsModified(true)
	}

	function _onChangeAllowUpdate(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "allowupdate" }
		loadtempTemplate(parameter)
		props.onChangeAllowUpdate(isChecked)
		props.setIsModified(true)
	}

	function _onChangeAllowDocx(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "allowDocx" }
		loadtempTemplate(parameter)
		props.onChangeAllowDocx(isChecked)
		props.setIsModified(true)
	}

	const _onChangeSSOProperties = (type: 'checkbox' | 'input') => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>) => {
		const input = (ev?.target as HTMLInputElement)
		props.setSSOProperties((prev: any) => {
			return { ...prev, [input.name]: type === 'checkbox' ? input.checked : input.value }
		})
		props.setIsModified(true)
	}
	function _onChangeAllowSendMail(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "allowSendMail" }
		loadtempTemplate(parameter)
		props.onChangeAllowSendMail(isChecked)
		props.setIsModified(true)
	}
	function _onChangeDocumentCreation(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "documentCreation" }
		loadtempTemplate(parameter)
		props.onChangeDocumentCreation(isChecked)
		props.setIsModified(true)
	}
	function _onChangeDocumentUpdate(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "documentUpdate" }
		loadtempTemplate(parameter)
		props.onChangeDocumentUpdateNotification(isChecked)
		props.setIsModified(true)
	}
	function _onChangeDocumentDelete(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "documentDelete" }
		loadtempTemplate(parameter)
		props.onChangeDocumentDelete(isChecked)
		props.setIsModified(true)
	}
	function _onChangeDocumentShare(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "documentShare" }
		loadtempTemplate(parameter)
		props.onChangeDocumentShare(isChecked)
		props.setIsModified(true)
	}
	function _onChangeTemplateEdit(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "templateEdit" }
		loadtempTemplate(parameter)
		props.onChangeTemplateEdit(isChecked)
		props.setIsModified(true)
	}
	function _onChangeTemplateDelete(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "templateDelete" }
		loadtempTemplate(parameter)
		props.onChangeTemplateDelete(isChecked)
		props.setIsModified(true)
	}
	function _onChangeTemplateShare(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "templateShare" }
		loadtempTemplate(parameter)
		props.onChangeTemplateShare(isChecked)
		props.setIsModified(true)
	}
	function _onChangeSupressPreview(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "supressPreview" }
		loadtempTemplate(parameter)
		props.onChangeSupressPreview(isChecked)
		props.setIsModified(true)
	}
	function _onChangeAllowNotification(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
		let parameter: IParam = { Value: isChecked, Name: "allownotification" }
		loadtempTemplate(parameter)
		if (!isChecked) {
			props.onChangeDocumentCreation(false)
			props.onChangeDocumentUpdateNotification(false)
			props.onChangeDocumentDelete(false)
			props.onChangeDocumentShare(false)
			props.onChangeTemplateEdit(false)
			props.onChangeTemplateDelete(false)
			props.onChangeTemplateRemove(false)
			props.onChangeTemplateShare(false)
		}
		props.onChangeAllowNotification(isChecked)
		props.setIsModified(true)
	}

	function _onChangeChekInCheckOut(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
		let parameter: IParam = { Value: isChecked, Name: "allowcheckinout" }
		loadtempTemplate(parameter)
		props.setChekInCheckOut(isChecked)
		props.setIsModified(true)
	}

	function _onChangeRedirectURL(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
		props.onChangeRedirectURL(newValue ?? "")
		props.setIsModified(true)
	}

	function _onChangeExternalPublish(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
		let parameter: IParam = { Value: isChecked, Name: "externalPublish" }
		loadtempTemplate(parameter)
		props.onChangeExternalPublish(isChecked)
		props.setIsModified(true)
	}

	function _onChangeExternalPublishContext(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
		props.onChangeExternalPublishContext(newValue ?? "")
		props.setIsModified(true)
	}

	function _onChangeAccessCodeInstructions(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
		props.onChangeAccessCodeInstructions(newValue ?? "")
		props.setIsModified(true)
	}

	const loadtempTemplate = (item?: IParam) => {

		if (sessionStorage.getItem("temTemplate") !== undefined) {
			let tempTemplate: ITemplateGeneral
			if (sessionStorage.getItem("temTemplate") !== "") {
				tempTemplate = JSON.parse(sessionStorage.getItem("temTemplate")!)// as ITemplateGeneral
			}
			else {
				tempTemplate = {
					templateName: "",
					Description: "",
					DisplayName: "",
					Tooltip: "",
					Category: "",
					Audience: "",
					fileName: "",
					mimeType: "",
					allowesign: false,
					allowcheckinout: false,
					file: undefined,
					eSignAccountId: "",
					eSignIntegrationKey: "",
					eSignPrivateKey: "",
					eSignUrl: "",
					eSignUserId: "",
					eSignProvider: "",
					PropertyBag: "",
					emailMoniker: "",
					allowNotification: false,
					allowUpdate: false,
					allowSendMail: false,
					defaultDescription: "",
					IsSubForm: false,
					DocumentOnlyAudienceId: undefined
				}
			}

			if (item !== undefined) {
				var value = item.Name
				try {
					switch (value) {
						case 'allowesign': {
							tempTemplate.allowesign = item.Value
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'allowcheckinout': {
							tempTemplate.allowcheckinout = item.Value
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'subform': {
							tempTemplate.IsSubForm = item.Value
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
					}
				} catch (e) {
					console.log(e)
				}
			}
		}
		else { return null }
	}

	const loadtempTemplateSignature = (item?: IParamSignature) => {

		if (sessionStorage.getItem("temTemplate") !== undefined) {
			let tempTemplate: ITemplateGeneral
			if (sessionStorage.getItem("temTemplate") !== "") {
				tempTemplate = JSON.parse(sessionStorage.getItem("temTemplate")!)// as ITemplateGeneral
			}
			else {
				tempTemplate = {
					templateName: "",
					Description: "",
					DisplayName: "",
					Tooltip: "",
					Category: "",
					Audience: "",
					fileName: "",
					mimeType: "",
					allowesign: false,
					allowcheckinout: false,
					file: undefined,
					eSignAccountId: "",
					eSignIntegrationKey: "",
					eSignPrivateKey: "",
					eSignUrl: "",
					eSignUserId: "",
					eSignProvider: "",
					PropertyBag: "",
					emailMoniker: "",
					allowNotification: false,
					allowUpdate: false,
					allowSendMail: false,
					defaultDescription: "",
					IsSubForm: false
				}
			}

			if (item !== undefined) {
				var value = item.Name
				try {
					switch (value) {
						case 'eSignAccountId': {
							tempTemplate.eSignAccountId = item.Value
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'eSignIntegrationKey': {
							tempTemplate.eSignIntegrationKey = item.Value
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'eSignPrivateKey': {
							tempTemplate.eSignPrivateKey = item.Value
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'eSignUrl': {
							tempTemplate.eSignUrl = item.Value
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'eSignUserId': {
							tempTemplate.eSignUserId = item.Value
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}
						case 'eSignProvider': {
							tempTemplate.eSignProvider = item.Value
							sessionStorage.setItem("temTemplate", JSON.stringify(tempTemplate))
							return tempTemplate
							break
						}

					}

				} catch (e) {
					console.log(e)
				}
			}
		}
		else { return null }
	}

	/**
	 * Set Notification Status
	 * @param emailType
	 * @param status
	 */
	function setIsSavingNofiticationStatus(emailType: EmailType, status: boolean) {
		switch (emailType) {
			case EmailType.DOCUMENTDELETE:
				setDocumentDeleteSaving(status)
				break;
			case EmailType.DOCUMENTCREATION:
				setDocumentCreationSaving(status)
				break;
			case EmailType.DOCUMENTSHARE:
				setDocumentShareSaving(status)
				break;
			case EmailType.DOCUMENTUPDATE:
				setDocumentUpdateSaving(status)
				break;
			case EmailType.WIZARDSUBMISSIONCREATE:
				setWizardSubmissionCreateSaving(status)
				break;
			case EmailType.WIZARDSUBMISSIONUPDATE:
				setWizardSubmissionUpdateSaving(status)
				break;
		}
	}

	/**
	 * Notification Status Onchange
	 * @param emailType
	 * @param event
	 * @param checked
	 */
	const notificationStatusOnchange = async (emailType: EmailType, event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
		if (checked !== undefined) {

			setIsSavingNofiticationStatus(emailType, true)

			const request: IUpdateTemplateStatusRequest = {
				TemplateId: props.DocumentOnlyTemplateResponsebyId.TemplateId.toString(),
				EmailType: emailType,
				Status: checked
			}

			await EmailTemplateNotificationsServices.UpdateTemplateStatus(request)
				.then((data) => {
					if (data.success) {

						switch (emailType) {
							case EmailType.DOCUMENTDELETE:
								props.setStateEmailTemplates({ ...props.stateEmailTemplates, DocumentDelete: checked })
								break;
							case EmailType.DOCUMENTCREATION:
								props.setStateEmailTemplates({ ...props.stateEmailTemplates, DocumentCreation: checked })
								break;
							case EmailType.DOCUMENTSHARE:
								props.setStateEmailTemplates({ ...props.stateEmailTemplates, DocumentShare: checked })
								break;
							case EmailType.DOCUMENTUPDATE:
								props.setStateEmailTemplates({ ...props.stateEmailTemplates, DocumentUpdate: checked })
								break;
							case EmailType.WIZARDSUBMISSIONCREATE:
								props.setStateEmailTemplates({
									...props.stateEmailTemplates,
									WizardSubmissionCreate: checked
								})
								break;
							case EmailType.WIZARDSUBMISSIONUPDATE:
								props.setStateEmailTemplates({
									...props.stateEmailTemplates,
									WizardSubmissionUpdate: checked
								})
								break;
						}

					}
				})
				.finally(() => {
					setIsSavingNofiticationStatus(emailType, false)
				})
		}
	}



	const onProviderChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
		let parameter: IParamSignature = { Value: option?.text!, Name: "eSignProvider" }
		if (props.DocumentOnlyTemplateResponsebyId.IsDocusign) {
			loadtempTemplateSignature(parameter)
			props.setProviderSelectedKey(option?.key)
			props.setSelectedProvider(option);
			props.setIsModified(true)
			if (option?.key !== 'None') {
				if (Number(selectedSignature.key) > 0) {

					let OptionDefault: IDropdownOption = { key: '0', text: 'None' };
					props.GetSelectedSignature(OptionDefault?.key)
					props.MapingSignatures([])
					setSelectedSignature(OptionDefault)
					props.UpdatedAutosign(true)
					setHiddenAutosign(true)
				}
				else {
					setShowErrorProvider(false)
					setShowErrorProvider(false)
					setShowErrorProvider(false)
				}
			}

		}
		else if (option?.key === 'None') {
			props.setProviderSelectedKey(option?.key)
			props.setSelectedProvider(option);
			setShowErrorProvider(false)
		}
		else if (GetErrorMessageSignatureProvider == "" && option?.key !== 'None') {
			setShowErrorProvider(true)

		}
		else {
			setShowErrorProvider(false)
		}
	};
	const onSignersChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): Promise<any> => {
		if (props.isClixsignature) {
			props.UpdatedAutosign(true)
			setSelectedSignature(option!)
			props.GetSelectedSignature(option?.key)
			props.setIsModified(true)
			setisnClixsignature(false)
			setHiddenAutosign(false)

			if (Number(option?.key) == 0) {
				setHiddenAutosign(true)
				props.MapingSignatures([])
			}
			else if (Number(option?.key) > 0) {
				let providerDefault: IDropdownOption = { key: 'None', text: 'None' };
				props.setProviderSelectedKey(providerDefault?.key)
				props.setSelectedProvider(providerDefault);
			}
		}
		else {
			setisnClixsignature(true)

		}
	};

	const onSignatureChange = async (option?: IDropdownOption, index?: number): Promise<any> => {
		if (selectedSignatures !== undefined && selectedSignatures !== null && Object.keys(selectedSignatures).length > 0) {
			var copy = selectedSignatures;
			delete copy[index!]
			copy[index!] = { AccountId: 0, ClixSignatureTag: "clixsignature" + index, DocumentOnlyCustomUserSignatureId: parseInt(option!.key.toString(), 10), Id: parseInt(option!.key.toString(), 10), TemplateId: 0 }

			setSelectedSignatures(copy);
			var countSignatures = (parseInt(selectedSignature.key.toString(), 10));
			var s = [];
			for (var i = 1; i <= countSignatures; i++) {
				let item = {
					DocumentOnlyCustomUserSignatureId: selectedSignatures[i].DocumentOnlyCustomUserSignatureId.toString(),
					ClixSignatureTag: selectedSignatures[i].ClixSignatureTag,
					Id: selectedSignatures[i].Id.toString(),
					GetBlobData: false
				}
				s.push(item)
			}
			props.MapingSignatures(s);
		}

	};

	const getSignatures = async () => {
		return await DocumentOnlyCustomUserSignatureService.GetAllSignaturesOptionList()
	}

	const AddDropdowns = async () => {
		var countSignatures = (parseInt(selectedSignature.key.toString(), 10));
		setCommittedFieldsToAdd(countSignatures)
		var tempSelectedSignatures = selectedSignatures;
		var selectedSignaturesContext = [];
		for (var i = 1; i <= countSignatures; i++) {
			if (tempSelectedSignatures[i])
				selectedSignaturesContext[i] = tempSelectedSignatures[i];
			else
				selectedSignaturesContext[i] = { AccountId: 0, ClixSignatureTag: "clixsignature" + i, DocumentOnlyCustomUserSignatureId: 0, Id: 0, TemplateId: 0 }
		}
		setSelectedSignatures(selectedSignaturesContext);
		var signatureContext = [];
		for (var i = 1; i <= countSignatures; i++) {
			signatureContext.push({
				DocumentOnlyCustomUserSignatureId: selectedSignaturesContext[i].DocumentOnlyCustomUserSignatureId.toString(),
				ClixSignatureTag: selectedSignaturesContext[i].ClixSignatureTag,
				Id: selectedSignaturesContext[i].Id.toString(),
				GetBlobData: false
			})
		}
		props.MapingSignatures(signatureContext);


	}
	function onCheckboxSubform(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
		let parameter: IParam = { Value: isChecked, Name: "subform" }
		loadtempTemplate(parameter)
		props._onChangesubFormChecked(isChecked)

	}

	function _onChangeTemplateRemove(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {

		let parameter: IParam = { Value: isChecked, Name: "templateRemove" }
		loadtempTemplate(parameter)
		props.onChangeTemplateRemove(isChecked)
		props.setIsModified(true)
	}

	/**
	 * Load Email template Status 
	 */
	const loadEmailTemplateStatus = async () => {

		setIsLoadingEmailTemplates(true)
		props.setStateEmailTemplates(stateEmailTemplatesDefault)

		const request: IGetTemplateStatusRequest = { TemplateId: props.DocumentOnlyTemplateResponsebyId.TemplateId }

		await EmailTemplateNotificationsServices.GetTemplateStatus(request)
			.then(data => {
				try {
					if (data.success) {
						if (data.result !== null) {
							const templates: IGetTemplateStatusResponse = JSON.parse(data.result) as IGetTemplateStatusResponse

							const newDefaultStatus = stateEmailTemplatesDefault

							templates.Templates.forEach(item => {

								switch (item.EmailType) {
									case EmailType.DOCUMENTDELETE:
										newDefaultStatus.DocumentDelete = item.Status
										break;
									case EmailType.DOCUMENTCREATION:
										newDefaultStatus.DocumentCreation = item.Status
										break;
									case EmailType.DOCUMENTSHARE:
										newDefaultStatus.DocumentShare = item.Status
										break;
									case EmailType.DOCUMENTUPDATE:
										newDefaultStatus.DocumentUpdate = item.Status
										break;
									case EmailType.WIZARDSUBMISSIONCREATE:
										newDefaultStatus.WizardSubmissionCreate = item.Status
										break;
									case EmailType.WIZARDSUBMISSIONUPDATE:
										newDefaultStatus.WizardSubmissionUpdate = item.Status
										break;
								}

								props.setStateEmailTemplates(newDefaultStatus)

							})
							setIsLoadingEmailTemplates(false)
						} else {
							console.log("Error trying to get the Email Templates")
						}
					} else {
						console.log("Error trying to get the Email Templates")
					}
				} catch (error: any) {
					console.log(error)
				}
			})
			.catch(error => {
				console.log(error)
			}
			)
	}

	const GetOptionsAutosign = () => {
		let OptionsAS: IDropdownOption[] = [{ key: '0', text: 'None' }];
		if (props.DocumentOnlyTemplateResponsebyId.TotalAutosign > 0) {
			for (let index = 0; index < props.DocumentOnlyTemplateResponsebyId.TotalAutosign; index++) {
				let item: IDropdownOption = { key: (index + 1).toString(), text: (index + 1).toString() }
				OptionsAS.push(item)
			}
			setOptionsAutosign(OptionsAS)
		}
	}
	const GetErrorMessageSignatureProvider =
		(props.signatureChecked != undefined && props.signatureChecked) ? (props.isDocusign != undefined && props.isDocusign) && props.providerSelectedKey?.key == "" ? "This field is required" :
			showErrorProvider ? "Please verify template configuration for this provider." : "" : ""
	const GetTemplateSignature = async (templateId: string) => {
		const getTemplateRequest: ITemplateSignatureRequest = {
			AccountId: Helper.getDefaultAccount(),
			ApiKey: "-1",
			Path: await Helper.getSite(),
			SessionKey: Helper.getSessionKey(),
			TemplateId: templateId,
			UserName: Helper.getUsername()
		}
		await Helper.GetTemplateSignature(getTemplateRequest).then(data => {
			if (data !== undefined) {
				setSignatureResponse(data);
				setLoadingSignture(false)
			}
			else {
				setSignatureResponse([]);
				setLoadingSignture(false)
			}
		})

	}
	const AddSignature = async () => {
		props.UpdatedAutosign(true)
		setHiddenAutosign(false);
		AddDropdowns()
	}
	const handleInput = (id: string, newValue: any) => {
		const value = formatString(newValue ?? '')
		props.methods.setValue(id, value)
	}

	function onRenderESignCell(properties: any) {
		return (
			<>
				<Label>
					<strong style={{ marginTop: 16 }}>
						{t('text.signerNoRole', { ns: 'recordsign', Name: properties.routingOrder })}</strong>
					{" "}
					{t('label.tags', { ns: 'recordsign' })}
					{properties.tags.sn != undefined ?
						<span className={classNamex.badget}>{t('label.signature', { ns: 'recordsign' })}</span> : ""}
					{properties.tags.in != undefined ?
						<span className={classNamex.badget}>{t('label.initials', { ns: 'recordsign' })}</span> : ""}
					{properties.tags.fln != undefined ?
						<span className={classNamex.badget}>{t('label.fullName', { ns: 'recordsign' })}</span> : ""}
					{properties.tags.fn != undefined ?
						<span className={classNamex.badget}>{t('label.firstName', { ns: 'recordsign' })}</span> : ""}
					{properties.tags.ln != undefined ?
						<span className={classNamex.badget}>{t('label.lastName', { ns: 'recordsign' })}</span> : ""}
					{properties.tags.ds != undefined ?
						<span className={classNamex.badget}>{t('label.dateSigned', { ns: 'recordsign' })}</span> : ""}
					{properties.tags.ti != undefined ?
						<span className={classNamex.badget}>{t('label.titleSign', { ns: 'recordsign' })}</span> : ""}
					{properties.tags.ea != undefined ?
						<span className={classNamex.badget}>{t('label.emailAddress', { ns: 'recordsign' })}</span> : ""}
				</Label>

				<RHFTextBoxControl
					disabled={props.isCreating}
					id={"txtSignerRoleDescription_" + properties.recipientId}
					label={t('label.role', { ns: 'recordsign' })}
					control={props.methods.control}
					getValues={props.methods.getValues}
					maxLength={64}
					placeholder={t('placeholder.roleDescription', { ns: 'recordsign' })}
					title={t('placeholder.roleDescription', { ns: 'recordsign' })}
					setValue={handleInput}
					cleanValue
					trigger={props.methods.trigger}
					shouldUnregister={true}
				>
				</RHFTextBoxControl>

			</>

		);
	};

	return (
		<>{!loading ?
			<><label className="ms-fontSize-18">Settings (applied to selected application)</label><Stack styles={{ root: { paddingRight: "10px" } }} tokens={stackTokens}>
				<label />
				<label />
				<label className="ms-fontSize-16">Enable Send Mail Shared Access</label>
				<Checkbox disabled={props.isCreating} title="Select to enable feature." label="By default, send mail is only available to creator and application administrators.  Enabling this feature allows shared users to send mail from the record. Note that they will also be able to send mail including document and attachments." checked={props.allowSendMail} onChange={_onChangeAllowSendMail} />
				<label />
				<label />
				<label className="ms-fontSize-16">Enable Form Wizard Shared Access</label>
				<Checkbox disabled={props.isCreating} title="Select to enable feature." label="By default, Form Wizard is only available to creator and application administrators. Enabling this feature allows shared users to update document using Form Wizard." checked={props.allowUpdate} onChange={_onChangeAllowUpdate} />
				<label />
				<label />
				<label className="ms-fontSize-16">Enable DocX Availability (not required for checkout)</label>
				<Checkbox disabled={props.isCreating} title="Select to enable feature." label="By default, DocX is not available. Enable this feature to select DocX from email and preview." checked={props.allowDocx} onChange={_onChangeAllowDocx} />
				<label />
				<label className="ms-fontSize-16">Enable SSO</label>
				<Checkbox disabled={props.isCreating} title="Select to enable feature." name="enabledSSO" label={`By default, SSO Authentication is not available. Enable this feature to allow users to access without access code once login succeeded.`} checked={props.ssoProperties.enabledSSO} onChange={_onChangeSSOProperties('checkbox')} />
				<label />
				<label className="ms-fontSize-16">Enable Captcha</label>
				<Checkbox disabled={props.isCreating} title="Select to enable feature." name="enabledCaptcha" label="By default, Captcha is not available. Enable this feature to bypass the authcode window on links." checked={props.ssoProperties.enabledCaptcha} onChange={_onChangeSSOProperties('checkbox')} />
				<label />
				<label className="ms-fontSize-16">Webform Link Document Delivery on Submit</label>
				<ChoiceGroup
					disabled={props.isCreating}
					styles={{ flexContainer: { display: "flex" } }}
					options={[
						{ key: "None", text: "None", styles: { choiceFieldWrapper: { display: 'inline-block', margin: '0 0 0 10px' } } },
						{ key: "PDF", text: "PDF", styles: { choiceFieldWrapper: { display: 'inline-block', margin: '0 0 0 10px' } } },
						{ key: "DocX", text: "DocX", styles: { choiceFieldWrapper: { display: 'inline-block', margin: '0 0 0 10px' } } },
						{ key: "DocXandPDF", text: "Both", styles: { choiceFieldWrapper: { display: 'inline-block', margin: '0 0 0 10px' } } }
					]}
					required={false}
					onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
						let parameter: IParam = { Value: option === undefined ? "None" : option.key, Name: "documentDelivery" }
						loadtempTemplate(parameter)
						props.onChangeDocumentDelivery(option)
						props.setIsModified(true)
					}}
					selectedKey={props.documentDelivery}
				/>

				<label />
				<label />
				<label className="ms-fontSize-16">Webform Submit Create and Update</label>
				<Text block variant={"medium"} styles={{ root: { paddingBottom: 4 } }}>
					Configured emails can be sent automatically to the user who submits and/or updates a form. Select 'email template' below to customize the email, including using tags from the document.
				</Text>

				<Stack styles={{
					root: {
						margin: "16px 0px",
						padding: 0,
						borderTop: `solid 2px ${branding.theme.palette.themePrimary}`,
						borderBottom: `solid 2px ${branding.theme.palette.themePrimary}`
					}
				}}>
					{/*region Wizard Submission Create notification*/}
					<Stack horizontal wrap
						styles={notificationStackItemStyles(branding.theme)}>
						<Stack grow={1} styles={{ root: { justifyContent: "center" } }}>
							<Text variant={"medium"} title={"Webform Submit Create"}>{"Webform Submit Create"}</Text>
						</Stack>
						<Stack horizontal>
							<Stack styles={{ root: { paddingRight: "8px", justifyContent: "center" } }}>
								<ActionButton iconProps={{ iconName: "EditMail" }} onClick={() => {
									props.callbackSetEmailTemplate(true, EmailType.WIZARDSUBMISSIONCREATE)
								}} disabled={props.isCreating || isLoadingEmailTemplates}>
									Email Template
								</ActionButton>
							</Stack>
							<Separator vertical />
							<Stack styles={{ root: { padding: "0px 8px", justifyContent: "center" } }}>
								<Toggle styles={{ root: { margin: 0 }, label: { wordBreak: "normal" } }} label={<>
									<Stack horizontal styles={{ root: { alignItems: "center", justifyContent: "center" } }}>
										{wizardSubmissionCreateSaving ?
											<Spinner size={SpinnerSize.small} />
											:
											<>
												<Text variant={"medium"}>Status</Text>
												<TooltipHost content="Active/Inactive Email Template"
													styles={{ root: { paddingLeft: 4, paddingTop: 4 } }}>
													<Icon iconName="Info" aria-label="Active/Inactive Email Template" />
												</TooltipHost>
											</>
										}
									</Stack>
								</>}
									checked={props.stateEmailTemplates.WizardSubmissionCreate === true}
									onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
										notificationStatusOnchange(EmailType.WIZARDSUBMISSIONCREATE, event, checked)
									}}
									inlineLabel onText="Active"
									offText="Inactive"
									disabled={props.isCreating || wizardSubmissionCreateSaving || props.stateEmailTemplates.WizardSubmissionCreate === undefined || isLoadingEmailTemplates} />
							</Stack>
						</Stack>
					</Stack>
					{/*endregion*/}

					{/*region Wizard Submission Update notification*/}
					<Stack horizontal wrap
						styles={notificationStackItemStyles(branding.theme)}>
						<Stack grow={1} styles={{ root: { justifyContent: "center" } }}>
							<Text variant={"medium"} title={"Webform Submit Update"}>{"Webform Submit Update"}</Text>
						</Stack>
						<Stack horizontal>
							<Stack styles={{ root: { paddingRight: "8px", justifyContent: "center" } }}>
								<ActionButton iconProps={{ iconName: "EditMail" }} onClick={() => {
									props.callbackSetEmailTemplate(true, EmailType.WIZARDSUBMISSIONUPDATE)
								}} disabled={props.isCreating || isLoadingEmailTemplates}>
									Email Template
								</ActionButton>
							</Stack>
							<Separator vertical />
							<Stack styles={{ root: { padding: "0px 8px", justifyContent: "center" } }}>
								<Toggle styles={{ root: { margin: 0 }, label: { wordBreak: "normal" } }} label={<>
									<Stack horizontal styles={{ root: { alignItems: "center", justifyContent: "center" } }}>
										{wizardSubmissionUpdateSaving ?
											<Spinner size={SpinnerSize.small} />
											:
											<>
												<Text variant={"medium"}>Status</Text>
												<TooltipHost content="Active/Inactive Email Template"
													styles={{ root: { paddingLeft: 4, paddingTop: 4 } }}>
													<Icon iconName="Info" aria-label="Active/Inactive Email Template" />
												</TooltipHost>
											</>
										}
									</Stack>
								</>}
									checked={props.stateEmailTemplates.WizardSubmissionUpdate === true}
									onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
										notificationStatusOnchange(EmailType.WIZARDSUBMISSIONUPDATE, event, checked)
									}}
									inlineLabel onText="Active"
									offText="Inactive"
									disabled={props.isCreating || wizardSubmissionUpdateSaving || props.stateEmailTemplates.WizardSubmissionUpdate === undefined || isLoadingEmailTemplates} />
							</Stack>
						</Stack>
					</Stack>
					{/*endregion*/}

				</Stack>


				<label />
				<label />
				<label className="ms-fontSize-16">Return URL for Publish Webform and Send Webform Update Link (Optional).</label>
				<TextField disabled={props.isCreating} id="linksRedirectURL" label={"Specify the URL:"} value={props.redirectURL} title="Redirect URL." onChange={_onChangeRedirectURL} />
				{/*<label />
				<label />
				<label className="ms-fontSize-16">Enable Checkout</label>
				<Checkbox title="Select to enable feature." label="Allow CheckIn/CheckOut. Allow user to download document, edit, and upload new version. Versions are recorded and managed." checked={props.selectChekInCheckOut} onChange={_onChangeChekInCheckOut} />
				*/}
				<label />
				<label className="ms-fontSize-16">Enable to suppress Document Preview after submit</label>
				<Checkbox disabled={props.isCreating} title="Select to enable feature" label="By default, a document preview is displayed when the user submit the form. Enable this option will suppress the document preview." checked={props.supressPreview} onChange={_onChangeSupressPreview} />
				{props.profile !== undefined && props.profile.AdminBilling ? <><label />
					<label />
					<label />
					<label className="ms-fontSize-16">Publish List - Select to allow this application listing to be available for external publishing to other systems including your private Large Language Model (LLM). Enter context to specify collective grouping of the search results and / or to be used as filters.</label>
					<div className="ms-Grid-row">
						<div style={{ paddingTop: "3px" }} className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" >
							<Checkbox disabled={props.isCreating} title="Select to enable feature" label="Enable" checked={props.externalPublish} onChange={_onChangeExternalPublish} />
						</div>
						<div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" hidden={props.imageInfo == ""}>
							<div style={{ float: "left", paddingTop: "3px" }}>Context Key &nbsp;&nbsp;</div><TextField disabled={props.isCreating} style={{ float: "right" }} id="externalPublishContext" maxLength={50} value={props.externalPublishContext} title="External Publish Context." onChange={_onChangeExternalPublishContext} />
						</div>
						<div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
						</div>
					</div></> : null}
				<label />
				<label />
				<label className="ms-fontSize-16">Access Code Login Instructions:</label>
				<TextField disabled={props.isCreating} id="accessCodeInstructions" value={props.accessCodeInstructions}
					maxLength={150}
					title="Access Code Login Instructions." onChange={_onChangeAccessCodeInstructions} />
				<label />
				<label />
				<label className="ms-fontSize-16">Enable Notifications</label>
				<Checkbox disabled={props.isCreating} title="Select to enable feature." label="Enable Notifications. Application audience owner will be notified each time the following selected events occur. For Document Update, the notifications will go to all members of the application audience and any additional users added to the record." checked={props.allowNotification} onChange={_onChangeAllowNotification} />
				<label style={{ fontSize: "small" }}>When an audience owner needs these notifications to go to more than just him or herself, the owner must setup an email rule to send to other users.</label>
				<label />


				<Stack styles={{
					root: {
						margin: "16px 0px",
						padding: 0,
						borderTop: `solid 2px ${branding.theme.palette.themePrimary}`,
						borderBottom: `solid 2px ${branding.theme.palette.themePrimary}`
					}
				}}>
					{/*region Document creation notification*/}
					<Stack horizontal wrap
						styles={notificationStackItemStyles(branding.theme)}>
						<Stack grow={1} styles={{ root: { justifyContent: "center" } }}>
							<Checkbox label="Document Creation" checked={props.documentCreation}
								onChange={_onChangeDocumentCreation}
								disabled={!props.allowNotification || props.isCreating} />
						</Stack>
						<Stack horizontal>
							<Stack styles={{ root: { paddingRight: "8px", justifyContent: "center" } }}>
								<ActionButton iconProps={{ iconName: "EditMail" }} onClick={() => {
									props.callbackSetEmailTemplate(true, EmailType.DOCUMENTCREATION)
								}}
									disabled={!props.allowNotification || props.isCreating || !props.documentCreation || isLoadingEmailTemplates}>
									Email Template
								</ActionButton>
							</Stack>
							<Separator vertical />
							<Stack styles={{ root: { padding: "0px 8px", justifyContent: "center" } }}>
								<Toggle styles={{ root: { margin: 0 }, label: { wordBreak: "normal" } }} label={<>
									<Stack horizontal styles={{ root: { alignItems: "center", justifyContent: "center" } }}>
										{documentCreationSaving ?
											<Spinner size={SpinnerSize.small} />
											:
											<>
												<Text variant={"medium"}>Status</Text>
												<TooltipHost content="Active/Inactive Email Template"
													styles={{ root: { paddingLeft: 4, paddingTop: 4 } }}>
													<Icon iconName="Info" aria-label="Active/Inactive Email Template" />
												</TooltipHost>
											</>
										}
									</Stack>
								</>}

									checked={props.stateEmailTemplates.DocumentCreation === true}
									onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
										notificationStatusOnchange(EmailType.DOCUMENTCREATION, event, checked)
									}}
									inlineLabel onText="Active"
									offText="Inactive"
									disabled={!props.allowNotification || props.isCreating || !props.documentCreation || documentCreationSaving || props.stateEmailTemplates.DocumentCreation === undefined || isLoadingEmailTemplates} />
							</Stack>
						</Stack>
					</Stack>
					{/*endregion*/}

					{/*region Document update notification*/}
					<Stack horizontal wrap
						styles={notificationStackItemStyles(branding.theme)}>
						<Stack grow={1} styles={{ root: { justifyContent: "center" } }}>
							<Checkbox label="Document Update" checked={props.documentUpdateNotification}
								onChange={_onChangeDocumentUpdate}
								disabled={!props.allowNotification || props.isCreating} />
						</Stack>
						<Stack horizontal>
							<Stack styles={{ root: { paddingRight: "8px", justifyContent: "center" } }}>
								<ActionButton iconProps={{ iconName: "EditMail" }} onClick={() => {
									props.callbackSetEmailTemplate(true, EmailType.DOCUMENTUPDATE)
								}}
									disabled={!props.allowNotification || props.isCreating || !props.documentUpdateNotification || isLoadingEmailTemplates}>
									Email Template
								</ActionButton>
							</Stack>
							<Separator vertical />
							<Stack styles={{ root: { padding: "0px 8px", justifyContent: "center" } }}>
								<Toggle styles={{ root: { margin: 0 }, label: { wordBreak: "normal" } }} label={<>
									<Stack horizontal styles={{ root: { alignItems: "center", justifyContent: "center" } }}>
										{documentUpdateSaving ?
											<Spinner size={SpinnerSize.small} />
											:
											<>
												<Text variant={"medium"}>Status</Text>
												<TooltipHost content="Active/Inactive Email Template"
													styles={{ root: { paddingLeft: 4, paddingTop: 4 } }}>
													<Icon iconName="Info" aria-label="Active/Inactive Email Template" />
												</TooltipHost>
											</>
										}
									</Stack>
								</>}
									inlineLabel onText="Active"
									offText="Inactive"
									onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
										notificationStatusOnchange(EmailType.DOCUMENTUPDATE, event, checked)
									}}
									checked={props.stateEmailTemplates.DocumentUpdate === true}
									disabled={!props.allowNotification || props.isCreating || !props.documentUpdateNotification || documentUpdateSaving || props.stateEmailTemplates.DocumentUpdate === undefined || isLoadingEmailTemplates} />
							</Stack>
						</Stack>
					</Stack>
					{/*endregion*/}

					{/*region Document delete notification*/}
					<Stack horizontal wrap
						styles={notificationStackItemStyles(branding.theme)}>
						<Stack grow={1} styles={{ root: { justifyContent: "center" } }}>
							<Checkbox label="Document Delete" checked={props.documentDelete}
								onChange={_onChangeDocumentDelete}
								disabled={!props.allowNotification || props.isCreating} />
						</Stack>
						<Stack horizontal>
							<Stack styles={{ root: { paddingRight: "8px", justifyContent: "center" } }}>
								<ActionButton iconProps={{ iconName: "EditMail" }} onClick={() => {
									props.callbackSetEmailTemplate(true, EmailType.DOCUMENTDELETE)
								}}
									disabled={!props.allowNotification || props.isCreating || !props.documentDelete || isLoadingEmailTemplates}>
									Email Template
								</ActionButton>
							</Stack>
							<Separator vertical />
							<Stack styles={{ root: { padding: "0px 8px", justifyContent: "center" } }}>
								<Toggle styles={{ root: { margin: 0 }, label: { wordBreak: "normal" } }} label={<>
									<Stack horizontal styles={{ root: { alignItems: "center", justifyContent: "center" } }}>
										{documentDeleteSaving ?
											<Spinner size={SpinnerSize.small} />
											:
											<>
												<Text variant={"medium"}>Status</Text>
												<TooltipHost content="Active/Inactive Email Template"
													styles={{ root: { paddingLeft: 4, paddingTop: 4 } }}>
													<Icon iconName="Info" aria-label="Active/Inactive Email Template" />
												</TooltipHost>
											</>
										}
									</Stack>
								</>}
									inlineLabel onText="Active"
									offText="Inactive"
									onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
										notificationStatusOnchange(EmailType.DOCUMENTDELETE, event, checked)
									}}
									checked={props.stateEmailTemplates.DocumentDelete === true}
									disabled={!props.allowNotification || props.isCreating || !props.documentDelete || documentDeleteSaving || props.stateEmailTemplates.DocumentDelete === undefined || isLoadingEmailTemplates} />
							</Stack>
						</Stack>
					</Stack>
					{/*endregion*/}

					{/*region Document share notification*/}
					<Stack horizontal wrap
						styles={notificationStackItemStyles(branding.theme)}>
						<Stack grow={1} styles={{ root: { justifyContent: "center" } }}>
							<Checkbox label="Document Share" checked={props.documentShare}
								onChange={_onChangeDocumentShare}
								disabled={!props.allowNotification || props.isCreating} />
						</Stack>
						<Stack horizontal>
							<Stack styles={{ root: { paddingRight: "8px", justifyContent: "center" } }}>
								<ActionButton iconProps={{ iconName: "EditMail" }} onClick={() => {
									props.callbackSetEmailTemplate(true, EmailType.DOCUMENTSHARE)
								}}
									disabled={!props.allowNotification || props.isCreating || !props.documentShare || isLoadingEmailTemplates}>
									Email Template
								</ActionButton>
							</Stack>
							<Separator vertical />
							<Stack styles={{ root: { padding: "0px 8px", justifyContent: "center" } }}>
								<Toggle styles={{ root: { margin: 0 }, label: { wordBreak: "normal" } }} label={<>
									<Stack horizontal styles={{ root: { alignItems: "center", justifyContent: "center" } }}>
										{documentShareSaving ?
											<Spinner size={SpinnerSize.small} />
											:
											<>
												<Text variant={"medium"}>Status</Text>
												<TooltipHost content="Active/Inactive Email Template"
													styles={{ root: { paddingLeft: 4, paddingTop: 4 } }}>
													<Icon iconName="Info" aria-label="Active/Inactive Email Template" />
												</TooltipHost>
											</>
										}
									</Stack>
								</>}
									inlineLabel onText="Active"
									offText="Inactive"
									onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
										notificationStatusOnchange(EmailType.DOCUMENTSHARE, event, checked)
									}}
									checked={props.stateEmailTemplates.DocumentShare === true}
									disabled={!props.allowNotification || props.isCreating || !props.documentShare || documentShareSaving || props.stateEmailTemplates.DocumentShare === undefined || isLoadingEmailTemplates} />
							</Stack>
						</Stack>
					</Stack>
					{/*endregion*/}

					{/*region Application edit notification*/}
					<Stack
						styles={notificationStackItemStyles(branding.theme)}>
						<Stack grow={1} styles={{ root: { justifyContent: "center", paddingLeft: 4 } }}>
							<Checkbox label="Application Edit" checked={props.templateEdit}
								onChange={_onChangeTemplateEdit}
								disabled={!props.allowNotification || props.isCreating} />
						</Stack>
					</Stack>
					{/*endregion*/}

					{/*region Application Delete notification*/}
					<Stack
						styles={notificationStackItemStyles(branding.theme)}>
						<Stack grow={1} styles={{ root: { justifyContent: "center", paddingLeft: 4 } }}>
							<Checkbox checked={props.templateDelete}
								label={"Application Delete (Owner)"}
								onChange={_onChangeTemplateDelete}
								disabled={!props.allowNotification || props.isCreating} />
						</Stack>
					</Stack>
					{/*endregion*/}

					{/*region Application remove notification*/}
					<Stack
						styles={notificationStackItemStyles(branding.theme)}>
						<Stack grow={1} styles={{ root: { justifyContent: "center", paddingLeft: 4 } }}>
							<Checkbox checked={props.templateRemove}
								label={"Application Remove (Co-Owner/Document Creation)"}
								onChange={_onChangeTemplateRemove}
								disabled={!props.allowNotification || props.isCreating} />
						</Stack>
					</Stack>
					{/*endregion*/}

					{/*region Application Share notification*/}
					<Stack
						styles={notificationStackItemStyles(branding.theme)}>
						<Stack grow={1} styles={{ root: { justifyContent: "center", paddingLeft: 4 } }}>
							<Checkbox label="Application Share" checked={props.templateShare}
								onChange={_onChangeTemplateShare}
								disabled={!props.allowNotification || props.isCreating} />
						</Stack>
					</Stack>
					{/*endregion*/}
				</Stack>


				<label />
				<label />

				<label className="ms-fontSize-16">Electronic Signature</label>
				<Checkbox disabled={props.isCreating} title="Select to enable signing feature." label="Select electronic signature to make sign action available from record menu." checked={props.signatureChecked} onChange={_onChangeEsignature} />
				{/* <label style={{ color: "#bf2c2c", fontSize: "small" }} hidden={!props.signatureChecked}>Only one type of signature is allowed</label> */}
				<Dropdown
					placeholder="Select a signature provider"
					label="Signature Provider"
					options={options}
					styles={dropdownStyles}
					selectedKey={props.providerSelectedKey}
					onChange={onProviderChange}
					errorMessage={GetErrorMessageSignatureProvider}
					disabled={props.isCreating || ((props.signatureChecked != undefined && props.signatureChecked) ? false : true)} />



				{props.providerSelectedKey === "Docusign" &&
					props.docuSignSigners !== undefined &&
					props.docuSignSigners !== null &&
					Array.isArray(props.docuSignSigners) &&
					(props.signatureChecked != undefined && props.signatureChecked!) &&

					<>
						<Stack tokens={{ padding: 16 }} styles={{ root: { backgroundColor: getTheme().semanticColors.listItemBackgroundHovered } }}>
							<Label>DocuSign signer roles</Label>
							<Text variant={"medium"}>Signers can sign documents and track the routing status of workflows
							in which they are identified as recipients. Please add a role description for each
								Signer.</Text>
							<FormProvider {...props.methods}>
								<form>
									<List items={props.docuSignSigners} onRenderCell={onRenderESignCell} />
								</form>
							</FormProvider>
							<Separator />
						</Stack>
					</>
				}


				<label />
				<label />
				<div key="grid" className="ms-Grid" dir="ltr">
					<div key="row" className="ms-Grid-row">
						<div key="col" className="ms-Grid-col ms-sm8">
							<Dropdown
								key="drop"
								placeholder="Select number of signers"
								label="Number of auto-signers on template"
								options={OptionsAutosign}
								onChange={onSignersChange}
								styles={dropdownStyles}
								disabled={!props.signatureChecked || props.isCreating}
								selectedKey={selectedSignature.key}
								errorMessage={isnClixsignature === true ? "Please verify template configuration for Autosign." : ""}
							/>
						</div>
						<div key="button" className="ms-Grid-col ms-sm2">
							{!hiddenAutosign ?
								<PrimaryButton key="add" disabled={props.isCreating || (!props.signatureChecked && (selectedSignature.key != undefined && selectedSignature.key > 0))} onClick={AddSignature} title="Add row." text="Add" style={{ float: 'left', marginTop: '3vh' }} /> :
								null
							}

						</div>
					</div>



					{!loadingSigntures ?







						<div id="AutosignersId">
							{[...Array(committedFieldsToAdd)].map(
								(value: undefined, index: number) => {

									return (!hiddenAutosign ?
										<div key={`GridRow${index}`} className="ms-Grid-row AutoSign" >

											<div key={`GridCol1${index}`} className="ms-Grid-col ms-sm4 ">
												<TextField key={`GridCol1Text${index}`} label={"Signature " + (index + 1)} value={"clixsignature" + (index + 1)} disabled={true} readOnly />
											</div>
											<div key={`GridCol2${index}`} className="ms-Grid-col ms-sm4 ">
												<Dropdown
													key={`GridCol2Drop${index}`}
													label="Signatures"
													options={signatures}
													disabled={!props.signatureChecked || props.isCreating}
													defaultSelectedKey={selectedSignatures[index + 1].DocumentOnlyCustomUserSignatureId.toString()}
													onChange={(e, o, i) => { onSignatureChange(o, index + 1) }}
													id={"clixsignature" + (index + 1)}
												/>
											</div>
										</div> :

										<div></div>
									)
								})}
						</div> :
						<div style={{ maxWidth: "100%", minWidth: "35vw", height: "70vh" }}>
							<>
								<Stack tokens={stackTokens} styles={{ root: { paddingRight: "10px" } }}>
									<label />

									<Spinner size={SpinnerSize.medium} label={"Loading signatures,please wait..."} ariaLive="assertive" />
								</Stack>
							</>
						</div>
					}


				</div>
				<label />
				<label />
				<label>Subform</label>
				<Checkbox disabled={props.isCreating} id="checkSubform" label="Application may be used as subform." checked={props.subform} onChange={onCheckboxSubform} />
			</Stack></>

			: <div style={{ maxWidth: "100%", minWidth: "35vw", height: "70vh" }}>
				<>
					<Stack tokens={stackTokens} styles={{ root: { paddingRight: "10px" } }}>
						<label />

						<Spinner size={SpinnerSize.medium} label={"Loading data,please wait..."} ariaLive="assertive" />
					</Stack>
				</>
			</div>

		}
		</>
	)
}

export default TemplateFeatures