import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IconButton, SelectionMode, Spinner, SpinnerSize, Stack } from "@fluentui/react"
import React, { } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { APIEventLogsColumns, iconButtonMobileStyles, stackTokens } from "../../AdminLogs.data"
import useFilterLogs from "../hooks/useFilterLogs"
import FiltersLog from "./Filters"
import ToggleLogs from "./ToggleLogs"
import {useBranding} from "../../../../hooks/useBranding";

const ApiLogsForm = () => {
    const { branding } = useBranding()
    const { scrollDiv, handleSearch, hasMore, numberOfRecords, logList, fetchMoreData, getKey, notResultsMessage, downloadExcel } = useFilterLogs()
    return (
        <>
            <label className="ms-fontSize-18" style={{ paddingRight: '10px' }}>Kim Platform API Logs</label>
            <IconButton className="tabIndexClose" disabled={numberOfRecords <= 0} styles={iconButtonMobileStyles(branding.theme)} iconProps={{ iconName: 'SaveTemplate' }} title="Download Excel" onClick={() => { downloadExcel() }} />
            <label className="ms-fontSize-10">{numberOfRecords} Records</label>
            <div style={{ display: 'inline-block', paddingLeft: '20px' }}>
                <ToggleLogs />
            </div>
            <Stack styles={{ root: { paddingRight: "10px" } }} tokens={stackTokens}>
                <label />
                <div ref={scrollDiv} id="scrollableDiv" className="scrollVisibleLogs" style={{ overflow: "auto", overflowX: "auto", paddingRight: "10px", maxWidth: "100%", height: "53vh" }}>
                    <FiltersLog handleSearch={handleSearch} />
                    <div style={numberOfRecords > 0 ? { display: 'inline-block' } : {}}>
                        {numberOfRecords > 0 ?
                            <InfiniteScroll
                                dataLength={logList.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={logList.length > 0 && hasMore === true ? <Spinner size={SpinnerSize.large} label="Loading" ariaLive="assertive" /> : null}
                                scrollableTarget="scrollableDiv"
                                scrollThreshold={0.9}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>End of Results!</b>
                                    </p>
                                }
                            >
                                <DetailsList
                                    items={logList}
                                    compact={false}
                                    columns={APIEventLogsColumns()}
                                    getKey={getKey}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    selectionMode={SelectionMode.single}
                                    selectionPreservedOnEmptyClick={false}
                                    enterModalSelectionOnTouch={true}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                    onShouldVirtualize={() => {
                                        return false;
                                    }}
                                />
                            </InfiniteScroll>
                            :
                            <>
                                <label />
                                <label />
                                <p style={{ textAlign: 'center' }}>
                                    <b>{notResultsMessage}</b>
                                </p>
                            </>

                        }
                    </div>
                </div>
            </Stack>
        </>
    )
}

export default ApiLogsForm