import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import '../../../Custom.css';
import {Stack, StackItem} from "@fluentui/react";
import {Text} from "@fluentui/react/lib/Text";
import {Separator} from "@fluentui/react/lib/Separator";
import {contentStyles, iconButtonStyles} from "../Style";
import {DefaultButton, IconButton} from "@fluentui/react/lib/Button";
import {Modal} from "@fluentui/react/lib/Modal";
import {IIconProps} from "@fluentui/react/lib/Icon";
import {EditorValue} from "react-rte";
import {removeHTMLTags} from "../RenewalsAndAlerts.data";
import StatusMessage from "../../../share/StatusMessage";
import {useBranding} from "../../../hooks/useBranding";


type Properties = {
    showModal: boolean,
    name: string,
    subjectRTE: EditorValue,
    bodyRTE: EditorValue,
    onDismissModal: () => void

}

function RenewalsEmailPreview({
                                  name,
                                  showModal,
                                  onDismissModal,
                                  subjectRTE,
                                  bodyRTE
                              }: Properties) {

    const {t,} = useTranslation(['renewalsalerts', 'common'])
    const { branding } = useBranding()
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")


    /**
     *
     */
    useEffect(() => {
        if (showModal) {

            setSubject(removeHTMLTags(subjectRTE.toString("html")))
            setBody(bodyRTE.toString("html"))
        }
    }, [showModal])


    /**
     * On Before dismiss
     */
    const onBeforeDismissRenewal = () => {
        onDismissModal()
    }

    return (

        <>

            <Modal
                isOpen={showModal}
                isBlocking={true}
                containerClassName={contentStyles(branding.theme).containerRecordPreview}
            >
                {/*-- START HEADER --*/}
                <Stack horizontal className={contentStyles(branding.theme).header}>
                    <StackItem grow={1}
                               styles={{root: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}}>
                        <Text variant={"xLarge"}
                              title={t('title.modalPreviewEmail', {ns: 'common', Name: `(${name})`})}>
                            {t('title.modalPreviewEmail', {ns: 'common', Name: `(${name})`})}
                        </Text>
                    </StackItem>
                    <Stack horizontal>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                            onClick={() => {
                                onBeforeDismissRenewal()
                            }}
                            title={t('Common.Button.Close', {ns: 'common'})}
                        />
                    </Stack>
                </Stack>
                <Separator/>

                {/*-- END HEADER --*/}

                {/*-- START BODY --*/}
                <Stack grow={1} tokens={{padding: 16}} style={{height: "40vh"}}
                >
                    <StackItem styles={{root: {marginBottom: 16}}}>

                        <StatusMessage status={'info'} hasTimer={false}
                                       truncated={false}
                                       isMultiline={true}
                                       dismiss={false}
                                       setShowMessage={true}>
                            <div>{t('text.previewEmailTags', {ns: 'renewalsalerts'})}</div>
                        </StatusMessage>

                    </StackItem>

                    <StackItem>
                        <Text block
                              variant={"large"}>{t('label.ctrlEmailSubject', {ns: 'common'})}: {subject}</Text>
                        <Text block variant={"medium"}
                              styles={{root: {fontWeight: "300"}}}>{t('text.to', {ns: 'renewalsalerts'})}</Text>
                    </StackItem>
                    <Separator title={t('label.ctrlEmailBody', {ns: 'common'})}/>
                    <StackItem grow={1} className="scrollVisible" data-is-scrollable="true">
                        <div dangerouslySetInnerHTML={{__html: body}}/>
                        <Text styles={{root: {marginTop: "16"}}} block variant={"smallPlus"}>
                            <p>_____</p>
                            <p>{t('message.disclaimeCconfidentialData', {ns: 'common'})}</p>
                        </Text>
                    </StackItem>
                </Stack>
                {/*-- END BODY --*/}

                {/*-- START FOOTER --*/}
                <Separator/>


                <Stack horizontal styles={{root: {height: 48, marginBottom: 8, alignItems: "center"}}}>
                    <StackItem grow={1}>&nbsp;</StackItem>

                    <StackItem tokens={{padding: 8}}>
                        <DefaultButton
                            onClick={() => {
                                onBeforeDismissRenewal()
                            }}
                            styles={{root: {marginRight: 8}}}
                            text={t('Common.Button.Close', {ns: 'common'})}
                            title={t('Common.Button.Close', {ns: 'common'})}/>
                    </StackItem>
                </Stack>
                {/*-- START FOOTER --*/}


            </Modal>
        </>
    )
}


export default RenewalsEmailPreview
