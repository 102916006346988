import HttpRequest from "./HttpRequest";
import Helper, {IHttpClientResponseBag} from "../Helpers/Helper";


export interface IGetListMasterByUserRequest {
    OwnerCoOwnerUser: string
}

export interface IGetListMasterByUserResponse {
    Description: string
    DocumentOnlyAudienceMaster: number
    Id: number
    Name: string
    NewId?: number
    UniqueId?: string
}

export interface IGetListsDetailsRequest {
    DocumentOnlyListMasterId: string
}

export interface IGetListsDetailsResponse {
    Key: string
    Value: string
    Id: number
    DocumentOnlyListMasterId: number
}

export interface ICreateListMasterRequest {
    UniqueId?: string
    DocumentOnlyAudienceMaster: string
    Name: string
    Description: string
}

export interface IUpdateListMasterRequest {
    DocumentOnlyAudienceMaster: string
    DocumentOnlyListMasterId: string
    Name: string
    Description: string
}

export interface IDeleteListMasterRequest {
    DocumentOnlyListMasterId: string
    DocumentOnlyAudienceMaster: string
    Name: string
}

export interface ICreateListDetailsRequest {
    DocumentOnlyListDetailId: string
    DocumentOnlyListMasterId: string
    Key: string
    Value: string
}

export interface IUpdateListDetailsRequest {
    DocumentOnlyListDetailId: string
    DocumentOnlyListMasterId: string
    Key: string
    Value: string
}

export interface IDeleteListDetailsRequest {
    DocumentOnlyListMasterId: string
    Key: string
}

export interface IAddListCSVRequest {
    DocumentOnlyListMasterId: string
    base64File: string
}

export interface IClonListMasterRequest {
    templateId: string,
    NewtemplateId: string,
    userEmail: string,
    IsExample: boolean,
    DocumentOnlyTemplateCloneResponse?: IDocumentOnlyTemplateCloneResponse
}

export interface IIntegrationsTemplateCloneResponse {
    OldTemplateId: number
    NewTemplateId: number
    OldEventId: number
    NewEventId: number
    IntegrationId: number
}
export interface IDocumentOnlyTemplateCloneResponse {
    clonedTemplateId: number
    integrationOldNewListIds: IIntegrationsTemplateCloneResponse[]
}

export interface IClonListMasterResponse {
    TemplateId: string
    Owner: string
    CoAuthor: string
    Users: string
    Layout: string
    FormRules: string
    Pages: string
    Released: boolean
}

const ListService = {

    /**
     * GetListMasterByUser
     * @param request
     * @constructor
     */
    getListMasterByUser: async (request: IGetListMasterByUserRequest): Promise<IGetListMasterByUserResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('GetListMasterByUser', request)

        return new Promise<IGetListMasterByUserResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * GetListsDetails
     * @param request
     * @constructor
     */
    GetListsDetails: async (request: IGetListsDetailsRequest): Promise<IGetListsDetailsResponse[]> => {
        let {data}: any = await HttpRequest.getWithSession('GetListsDetails', request)

        return new Promise<IGetListsDetailsResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * CreateListMaster
     * @param request
     * @constructor
     */
    CreateListMaster: async (request: ICreateListMasterRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('CreateListMaster', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * UpdateListMaster
     * @param request
     * @constructor
     */
    UpdateListMaster: async (request: IUpdateListMasterRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('UpdateListMaster', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * DeleteListMaster
     * @param request
     * @constructor
     */
    DeleteListMaster: async (request: IDeleteListMasterRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.getWithSession('DeleteListMaster', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * CreateListDetails
     * @param request
     * @constructor
     */
    CreateListDetails: async (request: ICreateListDetailsRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('CreateListDetails', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * UpdateListDetails
     * @param request
     * @constructor
     */
    UpdateListDetails: async (request: IUpdateListDetailsRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('UpdateListDetails', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * DeleteListDetails
     * @param request
     * @constructor
     */
    DeleteListDetails: async (request: IDeleteListDetailsRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.getWithSession('DeleteListDetails', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * AddListCSV
     * @param request
     * @constructor
     */
    AddListCSV: async (request: IAddListCSVRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('AddListCSV', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

    /**
     * ClonListMaster
     * @param request
     * @constructor
     */
    ClonListMaster: async (request: IClonListMasterRequest): Promise<IClonListMasterResponse> => {
        let {data}: any = await HttpRequest.postWithSession('ClonListMaster', request)

        return new Promise<IClonListMasterResponse>((resolve, reject) => {
            if (!data) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })
    },

}

export default ListService