import { ActionButton, DefaultButton, IButtonStyles, IconButton } from '@fluentui/react/lib/Button';
import { IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { Icon, IIconProps } from '@fluentui/react/lib/Icon';
import { Modal } from '@fluentui/react/lib/Modal';
import { Separator } from '@fluentui/react/lib/Separator';
import { Stack } from '@fluentui/react/lib/Stack';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { MarqueeSelection, Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { useConstCallback } from '@uifabric/react-hooks';
import Campaign from './Campaign';
import React, { useEffect, useMemo, useState } from 'react';
import Helper, { IApplicationGRAPHAPIServiceEmails, IApplicationSMTPServiceEmails, ISMTPEmailbyTemplateId, IServiceEmailRequest } from '../../Helpers/Helper';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import ReactPaginate from 'react-paginate';
import { isMobile } from 'react-device-detect';
import ConfirmationModal from '../../Helpers/Modals/ConfirmationModal';
import { ProgressIndicator } from 'office-ui-fabric-react';
import HelpComponent from '../Help/HelpComponent'
import { Checkbox, Link, TooltipHost } from '@fluentui/react';
import CampaignDelivery from './CampaignDelivery';
import { formatFromUTC, setGlobalDateLocale } from '../../Helpers/DateUtils';
import {useBranding} from "../../hooks/useBranding";
import ApplicationServiceEmailService, {IGetServiceEmailRequest} from "../../services/ApplicationServiceEmail";

const theme = getTheme();


const cancelIcon: IIconProps = { iconName: 'Cancel' };
const stackTokens = { childrenGap: 50 };
const iconButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };

export interface ICampaign {
    Id: number
    AccountId: number
    TemplateId: number
    Name: string
    Description: string
    DateCreated: string
    EmailOwner: string
    EmailSubject: string
    EmailBody: string
    CampaignStartDate: string
    CampaignEndDate: string
    Active: boolean
    ReminderEmailBody: string
    ReminderEmailSubject: string
    AcceptOrganizationalTerms: boolean
    PercentComplete: number
}

const emptyCampaign: ICampaign = {
    Id: -1,
    AccountId: -1,
    TemplateId: -1,
    Name: "",
    Description: "",
    DateCreated: "",
    EmailOwner: "",
    EmailSubject: "",
    EmailBody: "",
    CampaignStartDate: "",
    CampaignEndDate: "",
    Active: false,
    ReminderEmailBody: "",
    ReminderEmailSubject: "",
    AcceptOrganizationalTerms: false,
    PercentComplete: 0
}

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const emptyGraphMailService: IApplicationGRAPHAPIServiceEmails = {
    ServiceEmail: "",
    ClientId: "",
    ClientSecret: "",
    RedirectURL: "",
    AccessToken: "",
    RefreshToken: ""
}

const emptySMTPMailService: IApplicationSMTPServiceEmails = {
    Id: 0,
    TemplateId: 0,
    ServiceEmail: "",
    Host: "",
    Port: 0,
    Username: "",
    Pwd: ""
}

function CampaignList(props: any) {
    const { branding } = useBranding()
    const [campaign, setCampaign] = useState(emptyCampaign)
    const [showCampaign, setShowCampaign] = useState(false)
    const itemList: ICampaign[] = []
    const [listItems, setlistItems] = useState(itemList)
    const [isLoading, setIsLoading] = useState(false)
    const [isNewCampaign, setIsNewCampaign] = useState(true)
    const [pageCount, setPageCount] = useState(1);
    const [pageSelected, setPageSelected] = useState(1);
    const [showDeleteCampaignModal, setShowDeleteCampaignModal] = useState(false)
    const [showCampaignDelivery, setShowCampaignDelivery] = useState(false)
    const [graphMailService, setGraphMailService] = useState(emptyGraphMailService)
    const [smtpMailService, setSMTPMailService] = useState(emptySMTPMailService)
    const _deselect = () => {
        selection.setAllSelected(false);
    }

    const iconModalButtonStyles = {
        root: {
            color: branding.theme.palette.themePrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },

    };

    const contentStyles = mergeStyleSets({
        pagination: {
            padding: 0,
            selectors: {
                "ul": {
                    display: "inline-block"
                },
                "li": {
                    fontSize: "12pt",
                    color: branding.theme.palette.themeDarker,
                    display: "inline-block",
                    border: `solid 1px ${branding.theme.palette.themeDarker}`,
                    cursor: "pointer",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    borderCollapse: "collapse",
                    fontWeight: "500"
                }
            }
        },
        active: {
            backgroundColor: branding.theme.palette.themeDarker,
            selectors: {
                "a": {
                    color: branding.theme.palette.white
                }
            }
        },
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '60vw',
            height: '60vh',
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    const  serviceEmailIsConfigured = useMemo(() => {
        if (graphMailService.AccessToken !== "" || smtpMailService.ServiceEmail !== "") {
           return true;
        }
        else {
            return false;
        }
    }, [graphMailService.AccessToken, smtpMailService.ServiceEmail])


    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const getServiceGraph = async (templateId: string) => {

        const request: IGetServiceEmailRequest = {
            TemplateId: templateId
        }
        await ApplicationServiceEmailService.GetServiceEmail(request)
            .then((data) => {
                if (data.length > 0) {
                    const serviceModel: IApplicationGRAPHAPIServiceEmails = {
                        ServiceEmail: data[0].ServiceEmail,
                        ClientId: data[0].ClientId,
                        ClientSecret: data[0].ClientSecret,
                        RedirectURL: data[0].RedirectURL,
                        AccessToken: data[0].AccessToken,
                        RefreshToken: data[0].RefreshToken
                    }
                    setGraphMailService(serviceModel)
                } else {
                    setGraphMailService(emptyGraphMailService)
                }
            })
            .catch()
            .finally()
    }

    const getServiceSMTP = async (templateId: string) => {
        const rqst: ISMTPEmailbyTemplateId = {
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            TemplateId: templateId,
            UserName: Helper.getUsername(),
            Autoprovision: true,
        }
        var data = await Helper.GetSMTPEmailTemplateId(rqst)
        if (data.length > 0) {
            const serviceModel: IApplicationSMTPServiceEmails = {
                Id: data[0].Id,
                TemplateId: data[0].TemplateId,
                ServiceEmail: data[0].ServiceEmail,
                Host: data[0].Host,
                Port: data[0].Port,
                Username: data[0].Username,
                Pwd: data[0].Pwd,
            }
            setSMTPMailService(serviceModel)
        }
        else {
            setSMTPMailService(emptySMTPMailService)
        }
    }

    const deleteCampaign = useConstCallback(async (args: any[]) => {
        var request = {
            ApiKey: "-1",
            AccountId: Helper.getDefaultAccount(),
            SessionKey: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            Id: args[0],
            Operation: "delete"
        }
        var res = await Helper.deleteCampaign(request)
        setShowDeleteCampaignModal(false)
        loadCampaigns(args[1], pageSelected)
    })

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: 'Delete',
            title: 'Delete Campaign.',
            onClick: () => {
                setShowDeleteCampaignModal(true)
                _deselect()
            },
        },
        {
            key: 'edit',
            text: 'Edit/Review',
            title: 'Edit Campaign.',
            onClick: () => {
                setIsNewCampaign(false)
                setShowCampaign(true)
                setIsLoading(true)
                _deselect()
            },
            disabled: !serviceEmailIsConfigured
        },
    ]

    const menuProps: IContextualMenuProps = {
        onMenuOpened: () => {
        },
        shouldFocusOnMount: true,
        items: menuItems
    };

    const columnsWidth = () => {
        if (windowSize.innerWidth >= 2048) {
            return 140
        }
        if (windowSize.innerWidth >= 1920) {
            return 130
        }
        if (windowSize.innerWidth >= 1536) {
            return 100
        }
        if (windowSize.innerWidth >= 1440) {
            return 90
        }
        if (windowSize.innerWidth >= 1366) {
            return 80
        }
        if (windowSize.innerWidth >= 1280) {
            return 70
        }
        if (windowSize.innerWidth >= 1024) {
            return 60
        }
        return 50
    }

    const columns: IColumn[] = [
        {
            key: 'column0',
            name: ' Name',
            fieldName: 'Name',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'string',
            isPadded: false,
        },
        {
            key: 'column1',
            name: ' Description',
            fieldName: 'Description',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'string',
            isPadded: false,
        },
        {
            key: 'column2',
            name: ' Begin Date',
            fieldName: 'CampaignStartDate',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'string',
            isPadded: false,
            onRender: (item: ICampaign) => {
                return item.CampaignStartDate !== (undefined || null) ? formatFromUTC(item.CampaignStartDate, 'll') : "";
            },
        },
        {
            key: 'column3',
            name: 'End Date',
            fieldName: 'CampaignEndDate',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'string',
            isPadded: false,
            onRender: (item: ICampaign) => {
                return item.CampaignEndDate !== (undefined || null) ? formatFromUTC(item.CampaignEndDate, 'll') : "";
            },
        },
        {
            key: 'column4',
            name: ' Respondent %',
            fieldName: 'PercentComplete',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'string',
            isPadded: false,
            onRender: (item: ICampaign) => {
                return <ProgressIndicator label={Number(item.PercentComplete) + "%"} percentComplete={Number(item.PercentComplete)} />
            },
        },
        {
            key: 'column6',
            name: ' Active',
            fieldName: 'Active',
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            data: 'Boolean',
            isPadded: false,
            onRender: (item: ICampaign) => {
                return <div style={{ width: '50px', display: 'block', textAlign: 'right' }}>
                    <Checkbox id="chkActivateCampaign" checked={item.Active} disabled />
                </div>
            },
        },
        {
            key: 'column7',
            name: 'Date Created',
            fieldName: 'DateCreated',
            minWidth: columnsWidth(),
            maxWidth: columnsWidth(),
            isResizable: true,
            data: 'string',
            isPadded: false,
            onRender: (item: ICampaign) => {
                return formatFromUTC(item.DateCreated, 'll')
            },
        },
        {
            key: 'column5',
            name: 'Action',
            fieldName: 'Options',
            minWidth: 40,
            maxWidth: 40,
            isResizable: false,
            onRender: (item: any) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}>
                    <IconButton
                        menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424', textAlign: 'right' } }}
                        role="button"
                        aria-haspopup
                        aria-label="Show actions"
                        styles={iconButtonStyles}
                        menuProps={menuProps}
                        title={"Click to select from available actions."}
                        id="actionsCampaignListButton"
                    />
                </div>
            },
            isPadded: true,
        },
    ]

    const onSelectionItemChange = () => {
        const selectionCount = selection.getSelectedCount();
        switch (selectionCount) {
            case 0:
                setCampaign(emptyCampaign)
                break;
            case 1:
                setCampaign(selection.getSelection()[0] as ICampaign)
                break;
            default:
                setCampaign(emptyCampaign)
                break;
        }
    }

    const selection = new Selection({
        onSelectionChanged: onSelectionItemChange,
    });

    const hideCampaign = useConstCallback((templateId: string) => {
        var auxPage = parseInt(sessionStorage.getItem("page")!)
        loadCampaigns(templateId, auxPage).then(() => {
            setShowCampaign(false)
        })
    })

    const toggleCampaign = () => {
        setShowCampaign(true)
    }

    async function loadCampaigns(templateId: string, page: number) {
        setIsLoading(true)
        const response = await Helper.getCampaignListByTemplateId(templateId, page, 12)
        if (response !== undefined) {
            setlistItems(response)
            setIsLoading(false)
            if (response.length > 0) {
                var nPages = Math.ceil(response[0].Records / 12)
                setPageCount(nPages)
            }
            await getServiceGraph(templateId)
            await getServiceSMTP(templateId)
        }
    }

    useEffect(() => {
        if (props.showCampaignList || !showCampaignDelivery) {
            if (props.templateId === '') {
                return;
            }
            sessionStorage.setItem("page", "1")
            loadCampaigns(props.templateId, 1)
            setGlobalDateLocale(navigator.language)
        }
    }, [props.showCampaignList, showCampaignDelivery])

    const pageChange = (value: any) => {
        let selected = value.selected + 1;
        setPageSelected(selected);
        sessionStorage.setItem("page", selected)
        loadCampaigns(props.templateId, selected)
    }

    return (
        <>
            <Modal
                isOpen={props.showCampaignList}
                isBlocking={true}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id="CampaignList">Campaign List</span>
                                    <IconButton
                                        styles={iconModalButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={props.hideCampaignList}
                                        title="Close."
                                    />
                                    <HelpComponent
                                        headline="Campaign List"
                                        text="Campaigns are listed by the selected application in descending order by date created. This view displays name, description, active status, start and end date with percent completed. Multiple campaigns may run concurrently. Select Edit/Review from the Action menu to manage campaign and respondents."
                                        right="50px"
                                        top="15px"
                                        location="campaigns"
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-row" >
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TooltipHost content={serviceEmailIsConfigured ? "Create new campaign" : "Can't create a new campaign until there is a Service Email configured"}>
                                    <ActionButton id='newCampaignButton' disabled={!serviceEmailIsConfigured} text="New Campaign"
                                        iconProps={{ iconName: 'Add' }} onClick={() => { setIsNewCampaign(true); toggleCampaign() }} />
                                </TooltipHost>
                                <ActionButton title="Delivery Campaign Configuration" text="Delivery Campaign Configuration"
                                    iconProps={{ iconName: 'Add' }} onClick={() => { setShowCampaignDelivery(true); }} />
                                {serviceEmailIsConfigured ?
                                    <Icon iconName="SkypeCircleCheck" title="Service Email Configured" />
                                    :
                                    <Icon iconName="WarningSolid" title="Service Email Not Configured" />
                                }
                                <Link href="/Kim-Document-Email-Integration.pdf" target="_blank" title="Kim-Document-Email-Integration.pdf">{branding.appName} Email Integration</Link>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                {!isLoading ?
                                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "60vh" }}>
                                            <Stack tokens={stackTokens}>
                                                <MarqueeSelection selection={selection}>
                                                    <div className='DetailsListGrid'>
                                                        <DetailsList
                                                            items={listItems || []}
                                                            columns={columns}
                                                            compact={false}
                                                            setKey="none"
                                                            layoutMode={DetailsListLayoutMode.justified}
                                                            isHeaderVisible={true}
                                                            selection={selection}
                                                            selectionMode={SelectionMode.single}
                                                            selectionPreservedOnEmptyClick={false}
                                                            enterModalSelectionOnTouch={true}
                                                            checkboxVisibility={CheckboxVisibility.hidden}
                                                        />
                                                    </div>
                                                </MarqueeSelection>
                                            </Stack>
                                        </div>
                                    </div>
                                    :
                                    <Stack tokens={stackTokens}>
                                        <label />
                                        <label />
                                        <label />
                                        <Spinner size={SpinnerSize.large} label={props.spinnerText} />
                                    </Stack>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {pageCount > 1 ?
                                <div className="ms-Grid-row" >
                                    <Stack horizontal>
                                        <ReactPaginate
                                            previousLabel={!isMobile ? 'previous' : null}
                                            nextLabel={!isMobile ? 'next' : null}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={pageChange}
                                            containerClassName={contentStyles.pagination}
                                            activeClassName={contentStyles.active}
                                            pageLinkClassName={"indexTab"}
                                            previousLinkClassName={"indexTab"}
                                            nextLinkClassName={"indexTab"}
                                        />
                                    </Stack>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <DefaultButton onClick={props.hideCampaignList} text="Close" title="Close." />
                            </DialogFooter>
                        </div>
                    </div>
                    <br />
                </div>
            </Modal>
            {showCampaign && 
            <Campaign
                showCampaign={showCampaign}
                hideCampaign={hideCampaign}
                SMTPServiceEmail={smtpMailService}
                GRAPHAPIServiceEmail={graphMailService}
                isNew={isNewCampaign}
                campaignId={campaign.Id}
                templateId={props.templateId}
            />}
            {showCampaignDelivery && 
            <CampaignDelivery
                showCampaignDelivery={showCampaignDelivery}
                setShowCampaignDelivery={setShowCampaignDelivery}
                SMTPServiceEmail={smtpMailService}
                GRAPHAPIServiceEmail={graphMailService}
                TemplateId={props.templateId}
                setSMTPServiceEmail={setSMTPMailService}
                setGRAPHAPIServiceEmail={setGraphMailService}
                DocumentOnlyAudienceId={props.DocumentOnlyAudienceId}
                OwnerUser={props.OwnerUser}

            />}
            <ConfirmationModal
                showConfirmationModal={showDeleteCampaignModal}
                setShowConfirmationModal={setShowDeleteCampaignModal}
                callbackConfirmationModal={deleteCampaign}
                args={[campaign.Id, props.templateId]}
                title="Delete Campaign"
                message={`Are you sure you want to delete the Campaign ( ${campaign.Name} )?`}
                titleYesButton="Confirm that you want to delete the campaign."
            />
        </>
    )
}

export default CampaignList