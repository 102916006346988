import { FC } from "react";
import { Controller } from "react-hook-form";
import { ITextFieldProps, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { HookFormProps } from "./HookFormProps";

export const ControlledTextField: FC<HookFormProps & ITextFieldProps> = (
    props
) => {
    return (
        <Controller
            control={props.control}
            name={props.name}
            rules={props.rules}
            defaultValue={props.defaultValue || ""}
            shouldUnregister={props.shouldUnregister || false}
            render={({
                field: { onChange, onBlur, name: fieldName,value },
                fieldState: { error }
                
            }) => (
                <TextField
                    {...props}
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    name={fieldName}
                    id={fieldName}
                    placeholder={props.placeholder}
                    disabled={props.readOnly || false}
                    errorMessage={error && error.message}
                    defaultValue={props.defaultValue}
                    resizable={false}
                />
            )}
        />
    );
};
