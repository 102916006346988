import React from "react";
import ApiLogsForm from "./components/ApiLogsForm";
import { FilterProvider } from "./hooks/useFilterLogs";

function ApiLogs() {
    return (
        <FilterProvider>
            <ApiLogsForm />
        </FilterProvider >
    )
}

export default ApiLogs