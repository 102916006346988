import React, { useState, useEffect } from 'react';
import { Modal } from '@fluentui/react/lib/Modal';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { useId } from '@uifabric/react-hooks';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Separator } from '@fluentui/react/lib/Separator';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { MarqueeSelection, Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import '../../Custom.css';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import { IContextualMenuProps, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import Confirmation from '../Settings/Confirmation';
import Helper, { IDocumentVersionRequest, ITemplateVersionRequest } from '../../Helpers/Helper';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IconButton, IButtonStyles, DefaultButton } from '@fluentui/react/lib/Button';
import { isMobile } from 'react-device-detect';
import { IMessageBarStyles, MessageBar } from '@fluentui/react';
import PreviewLayout from '../CustomTemplates/PreviewLayout';
import { useConstCallback } from '@uifabric/react-hooks';
import { indexOf } from 'lodash';
import {useTranslation} from "react-i18next";
import { getRecordDescription } from '../../Helpers/functions';
import { setGlobalDateLocale } from '../../Helpers/DateUtils';
import {useBranding} from "../../hooks/useBranding";
import HttpRequest from "../../services/HttpRequest";


export interface ITemplateVersion {
    Id: number
    FullName: string
    DisplayName: string
    AccountId: number
    TemplateId: number
    Entity: string
    FormVersion: number
    FormVersionDate: string
    FormVersionUser: string
    TemplateVersion: number
    TemplateVersionDate: string
    TemplateVersionUser: string
    FormDesign: string
}

export class TemplateVersion implements ITemplateVersion {
    Id = -1
    FullName = ""
    DisplayName = ""
    AccountId = -1
    TemplateId = -1
    Entity = ""
    FormVersion = -1
    FormVersionDate = ""
    FormVersionUser = ""
    TemplateVersion = -1
    TemplateVersionDate = ""
    TemplateVersionUser = ""
    FormDesign = ""
}

const theme = getTheme();

const iconModalButtonStyles = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const iconButtonNoteModalStyles = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};

const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackTokens = { childrenGap: 50 };

function VersionTemplate(props: any) {
    const { branding } = useBranding()
    const emptyDoc = new TemplateVersion()
    const titleId = useId(props.modalId);
    const [showConfirm, setShowConfirm] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [deleteMessage, setDeleteMessage] = useState("")
    const [docSelected, setDocumentSelected] = useState(emptyDoc)
    const [modalId, setModalId] = useState(-1)
    const [modalTitle, setModalTitle] = useState("")
    const [spinnerText, setSpinnerText] = useState("")
    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [previewURL, setPreviewURL] = useState("")
    const [previewMime, setPreviewMime] = useState("")
    const [id, setId] = useState(-1)
    const [documentDescription, setDocumentDescription] = useState("")
    const [actionText, setActionText] = useState("")
    const [documentId, setDocumentId] = useState(-1)
    const [allowDocX, setAllowDocX] = useState(false)
    const [templateVersion, setTemplateVersion] = useState<ITemplateVersion[]>()
    const [showMessage, setShowMessage] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [message, setMessage] = useState("");
    const [showPreviewLayout, setShowPreviewLayout] = useState(false)
    const [data, setData] = useState("")
    const {t} = useTranslation(['common', 'versions']);
    useEffect(() => {
        if (props.showVersionTemplate) {
            setGlobalDateLocale(navigator.language)
            loadVersions()
        }

    }, [props.showVersionTemplate])

    const onSelectionChange = () => {
        const selectionCount = selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                setDocumentSelected(emptyDoc)
                break;
            case 1:
                setDocumentSelected(selection.getSelection()[0] as ITemplateVersion)
                break;
            default:
                setDocumentSelected(emptyDoc)
                break;
        }
    }

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
            },
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        hideMenu: {
            display: 'none'
        },
        showMenu: {
            display: 'inline'
        },
    });


    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: isMobile ? null : '60vw',
            height: '60vh',
            flex: '4 4 auto',
            //overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });


    const loadVersions = () => {
        const req: ITemplateVersionRequest = {
            AccountId: Helper.getDefaultAccount(),
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            TemplateId: props.TemplateId,
        }


        Helper.getTemplateVersion(req).then(res => {
            var auxList: ITemplateVersion[] = []
            if (res != null && res.length > 0) {
                auxList = res

            }

            setTemplateVersion(auxList)
            setLayoutLoaded(true)
            setSpinnerText("")

        })
    }

    const selection = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    const onItemInvoked = (item: ITemplateVersion) => {
        setId(item.Id)
        makeCurrentVersion(item.TemplateVersion)
    }

    const hideConfirm = () => {
        setShowConfirm(false)
        setLayoutLoaded(false)
        setSpinnerText(t('text.spinner', { ns: 'common'}))
        setModalId(-1)
        setModalTitle("")
        setDeleteMessage("")
        setLayoutLoaded(false)
    }

    const confirmDelete = (id: string) => {
        const deleteDoc = async () => {
            const req: IDocumentVersionRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id,
                IsDownload: false,
                IsPreview: false,
                IsDocx: false
            }
            var res = await Helper.deleteDocumentVersion(req)

            if (res.Success) {
                props.update()
                hideConfirm()
            }
        }

        deleteDoc()
    }

    const onItemContextMenu = (item?: ITemplateVersion, index?: number, ev?: Event) => {

    }

    const onColumnClick = () => {

    }

    const getKey = (item: any, index?: number): string => {
        if (item !== undefined)
            return item.Id

        return ""
    }

    const _deselect = () => {
        props.selectionState.setAllSelected(false);
    }


    const columns: IColumn[] = [
        // {
        //     key: 'column4',
        //     name: 'Form Wizard',
        //     className: classNames.fileIconCell,
        //     iconClassName: classNames.fileIconHeaderIcon,
        //     ariaLabel: 'Form Wizard',
        //     iconName: 'Page',
        //     isIconOnly: true,
        //     fieldName: 'name',
        //     minWidth: 20,
        //     maxWidth: 20,
        //     onRender: (item: ITemplateVersion) => {
        //         return <img
        //             style={{ cursor: 'pointer' }}
        //             key={`wizard ${item.TemplateId}`}
        //             onClick={() => {
        //                 var json = atob(item.FormDesign)
        //                 setData(json)
        //                 openPreviewLayout()
        //                 clearSelection()
        //             }}
        //             title={"Click to preview document form wizard."}
        //             src={"https://static2.sharepointonline.com/files/fabric/assets/item-types/16/form.svg"}
        //             className={classNames.fileIconImg} alt={'preview document form wizard'}
        //             />;
        //     },
        // },
        {
            key: 'column2',
            name: t('label.versionDate', { ns: 'versions'}),
            fieldName: 'TemplateVersionDate',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            onColumnClick: onColumnClick,
            data: 'date',
            onRender: (item: ITemplateVersion) => {
                return <span title={item.TemplateVersionDate !== null ? Helper.getLocateDate(item.TemplateVersionDate, 2): ""}>{item.TemplateVersionDate !== null ? Helper.getLocateDate(item.TemplateVersionDate, 3) : null}</span>;
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: t('label.username', { ns: 'versions'}),
            fieldName: 'DisplayName',
            minWidth: 125,
            maxWidth: 300,
            isRowHeader: true,
            onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column4',
            name: t('label.version', { ns: 'versions'}),
            fieldName: 'TemplateVersion',
            minWidth: 50,
            maxWidth: 50,
            isRowHeader: true,
            onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            onRender: (item: ITemplateVersion) => {
                return <span style={{ display: 'block', textAlign: 'right' }}>{item.TemplateVersion}</span>;
            },
        },
        {
            key: 'column6',
            name: t('label.action', { ns: 'versions'}),
            fieldName: 'Options',
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            onColumnClick: onColumnClick,
            onRender: (item: ITemplateVersion) => {
                return indexOf(templateVersion, item) === 0 ? t('label.currentVersion', { ns: 'versions'}) :
                    <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                        menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                        role="button"
                        aria-haspopup
                        title={t('title.action', { ns: 'versions'})}
                        aria-label={t('label.actionAria', { ns: 'versions'})}
                        styles={iconListButtonStyles}
                        menuProps={menuProps}
                    /></div>
            },
            isPadded: true,
        },
    ];

    const menuItems: IContextualMenuItem[] = [

        {
            key: 'restore',
            text: t('label.restore', { ns: 'versions'}),
            title: t('title.restore', { ns: 'versions'}),
            onClick: () => {
                onItemInvoked(docSelected)
            },
            //className: docSelected.Version === props.version ? classNames.hideMenu : classNames.showMenu
        },

    ];

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };

    async function makeCurrentVersion(version: number) {

        const request = {
            TemplateId: props.TemplateId,
            TemplateVersion: version.toString(),
            Description: "",
            TargetCulture: "",
            RefDocumentId: ""
        }

        let {data}: any = await HttpRequest.postWithSession('RestoreTemplateVersion', request)
        const response = data

        if (response != null) {
            if (response.Success)//ExceptionMessage
            {
                loadVersions()
                setShowSuccess(true)
                setMessage(response.ExceptionMessage)
                setTimeout(function () {
                    setShowSuccess(false)
                }, 5000);
            }
            else {
                setShowMessage(true)
                setMessage(response.ExceptionMessage)
                setTimeout(function () {
                    setShowMessage(false)
                }, 5000);
            }

        }
    }
    const closePreviewLayout = useConstCallback((open: boolean) => {
        setShowPreviewLayout(open)
    })
    function openPreviewLayout() {
        setShowPreviewLayout(true)
    }
    const clearSelection = useConstCallback(() => {
        if (selection.getSelectedIndices().length >= 1)
            selection.setAllSelected(false)

        if (selection.getSelectedIndices().length >= 1)
            selection.toggleIndexSelected(selection.getSelectedIndices()[0]);
    })
    return (
        <>

            <Confirmation
                showModal={showConfirm}
                hideModal={hideConfirm}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                actionText={actionText}
                id={deleteId}
                message={deleteMessage}
                confirmDelete={confirmDelete}
            />
            <PreviewLayout
                isOpen={showPreviewLayout}
                Close={closePreviewLayout}
                templateName={props.templateName}
                templateId={props.TemplateId}
                siteSelected={Helper.getSiteSelected()}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                data={data}
            />

            <Modal
                isOpen={props.showVersionTemplate}
                isBlocking={true}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id={titleId}>{getRecordDescription(props.modalTitle, t('text.versions', { ns: 'versions'}))}</span>
                                    <IconButton
                                        styles={iconModalButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel={t('Common.Button.Close', { ns: 'common'})}
                                        onClick={props.hideVersionTemplate}
                                        title={t('Common.Button.Close', { ns: 'common'})}
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                            {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                            <div className={contentStyles.body}>
                                {layoutLoaded ?
                                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", maxWidth: "100%", height: "50vh" }}>
                                            <Stack tokens={stackTokens}>
                                                <MarqueeSelection selection={selection}>
                                                    <DetailsList
                                                        items={templateVersion || []}
                                                        compact={false}
                                                        columns={columns}
                                                        getKey={getKey}
                                                        setKey="none"
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        isHeaderVisible={true}
                                                        onItemContextMenu={onItemContextMenu}
                                                        selection={selection}
                                                        selectionMode={SelectionMode.single}
                                                        selectionPreservedOnEmptyClick={false}
                                                        enterModalSelectionOnTouch={true}
                                                        checkboxVisibility={CheckboxVisibility.hidden}
                                                    />
                                                </MarqueeSelection>
                                            </Stack>
                                        </div>
                                    </div>
                                    :
                                    <Stack tokens={stackTokens}>
                                        <label />
                                        <label />
                                        <label />
                                        <Spinner size={SpinnerSize.large} label={props.spinnerText} />
                                    </Stack>
                                }
                            </div>
                        </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <DefaultButton onClick={props.hideVersionTemplate} text={t('Common.Button.Close', { ns: 'common'})} title={t('Common.Button.Close', { ns: 'common'})} />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    
                </div>
            </Modal>
        </>
    )
}

export default VersionTemplate