import React, { useCallback, useEffect, useState } from "react";
import { DefaultButton, FontWeights, getTheme, IChoiceGroupOption, Icon, IconButton, IIconProps, IStackStyles, IIconStyles, IMessageBarStyles, mergeStyleSets, MessageBar, Modal, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, StackItem, Text, TextField } from "@fluentui/react";
import { useConstCallback, useBoolean, useId } from "@uifabric/react-hooks";
import Helper, { IAudienceRequest, IDocumentOnlyAudience, IDocumentOnlyTemplate, IDocumentRequest, ISendMailRequest } from "../../Helpers/Helper";
import "../../web.css";
import { CustomForm } from "../CustomTemplates/CustomForm/CustomForm";
import PreviewScreen from "../Preview/PreviewScreen";
import { IPropertyBag } from "../DataPanel/DataPanel";
import { isMobile } from "react-device-detect";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import DraftServices from "../DraftDocuments/Services/DraftServices";
import { AttachmentSettings } from "../../interfaces/IPropertyBagSettings";
import WebFormCampaignService from "../../services/webFormCampaign";
import { useBranding } from "../../hooks/useBranding";
import { textFieldStyle } from "../CustomTemplates/CustomForm/RHFControls/Styles";
import { useSignalR } from "../../hooks/useSignalR";
import { useModal } from "../../hooks/useModal";
import { HubConnectionBuilder } from "@microsoft/signalr";
import QueueModal from "../QueueModal/QueueModal";
import DocumentService from "../../services/document";
import { useDocument } from "../../hooks/useDocument";
import AuthService from "../../services/auth";
import useAuth from "../../hooks/useAuth";
import UserService, { IGetuserprofileRequest } from "../../services/User";
import HttpRequest from "../../services/HttpRequest";
import { useSSO } from "../../hooks/useSSO";

var accessCode: string = "";
var campaignGuid: string = "";
var baseUrl: string = "";
var campaignUser: string = "";
var campaignId: number = -1;
var redirectURL: string = "";

const theme = getTheme();
export interface IDataLink {
    User: string;
    DocumentId: string;
    TargetUser: string;
    TemplateId: string;
    allowDocx: boolean;
    ownerUser: string;
    TemplateVersion: string;
    PrimaryId: string;
    IsSubform: boolean;
}
var datajson: IDataLink = {
    User: "",
    DocumentId: "",
    TargetUser: "",
    TemplateId: "",
    allowDocx: false,
    ownerUser: "",
    TemplateVersion: "",
    PrimaryId: "-1",
    IsSubform: false,
};
var jsonBag: IPropertyBag = {
    Clon: "",
    Culture: "",
    CultureName: "",
    Released: false,
    emailMoniker: "",
    AllowDocX: false,
    AllowSendMail: false,
    DocumentCreation: false,
    DocumentUpdate: false,
    DocumentDelete: false,
    DocumentShare: false,
    TemplateEdit: false,
    TemplateShare: false,
    TemplateDelete: false,
    DefaultDescription: "",
    attachment: {} as AttachmentSettings,
    SupressPreview: false,
    DocumentDelivery: "None",
    RedirectURL: ""
};
let templateLink = false;
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac",
    },
};

const nonShrinkingStackItemStyles: IStackStyles = {
    root: {
        display: 'flex',
        height: 40,
        overflow: 'hidden',
        alignItems: "center",
    },
};

export interface ITemplates {
    Id: number;
    AccountId: number;
    TemplateId: string;
    DocumentOnlyAudienceId: string;
    AllowEsign: boolean;
    AllowCheckInOut: boolean;
    eSignProvider: any;
    eSignUserId: any;
    eSignPassword: string;
    eSignIntegrationKey: any;
    eSignURL: string;
    PropertyBag: any;
    FileName: string;
    Category: string;
    Description: string;
    DisplayName: string;
    DisplayNameToolTip: string;
    TemplateContent: string;
    culture: string;
    released: boolean;
    emailMoniker: string;
    AllowDocumentUpdate: boolean;
    AllowNotification: boolean;
    AllowDocX: boolean;
    cultureName: string;
    AllowSendEmail: boolean;
    DocumentCreation: boolean;
    DocumentDelete: boolean;
    DocumentShare: boolean;
    TemplateEdit: boolean;
    TemplateShare: boolean;
    TemplateDelete: boolean;
    DefaultDescription: string;
    ActiveSubscription?: boolean;
}

const iconButtonStyles = {
    root: {
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },

};

const cancelIcon: IIconProps = { iconName: "Cancel" };
const dialogStyles = { main: { maxWidth: 500 } };

const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5",
    },
};
let Billing = false;

const companyIconStyle: IIconStyles = {
    root: {
        fontWeight: "100",
        fontSize: "55px",
        height: "50px",
        width: "50px",
        color: "white",
        paddingTop: "6px",
    },
};

function CampaignWebForm(props: any) {
    const { isAuthenticated, user, isAuthLoaded } = useAuth();
    const [isApply, setisApply] = React.useState(true);

    const [errorMessage, setErrorMessage] = useState("");

    const [showCustomForm, setShowCustomForm] = useState(true);
    const [siteSelected, setSiteSelected] = useState("");
    const [data, setData] = useState<IDataLink>();
    const [draftId, setDraftId] = useState<number>(-1);
    const [templateName, setTemplateName] = useState("");
    const [templateDescription, setTemplateDescription] = useState("");
    const [description, setDescription] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showDraftModal, setShowDraftModal] = useState(false);
    const [templateCategory, setTemplateCategory] = useState("");
    const [audienceName, setAudienceName] = useState("");

    const [waitToLoadCustomForm, setWaitToLoadCustomForm] = useState(false);
    const [waitToLoadProfile, setWaitToLoadProfile] = useState(false);
    const [layoutLoaded, setLayoutLoaded] = useState(false);
    const [spinnerText, setSpinnerText] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const [previewURL, setPreviewURL] = useState("");
    const [previewMime, setPreviewMime] = useState("");
    const [culture, setCulture] = useState("");

    const [propertyBag, setPropertyBag] = useState<IPropertyBag>();
    const [documentDescription, setDocumentDescription] = useState("");
    const [campaignDetailUserFullName, setCampaignDetailUserfullName] = useState("");
    const [id, setId] = useState("");
    const [isCheckedOut, setIsCheckedOut] = useState(false);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
    const labelId: string = useId("dialogLabel");
    const subTextId: string = useId("subTextLabel");
    const [showMessage, setShowMessage] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const { branding, getBrandingInformation } = useBranding();
    const [typeView, setTypeView] = useState("");

    const [ShowActiveSubcription, setShowActiveSubcription] = useState(false);
    const [hideDialogSub, { toggle: toggleHideDialogSub }] = useBoolean(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [dataAudienceTargetUser, seDataAudienceTargetUser] = useState<IDocumentOnlyAudience>();

    const [documentCreation, setDocumentCreation] = useState(false);
    const [documentUpdate, setDocumentUpdate] = useState(false);
    const [audienceMasterId, setAudienceMasterId] = useState<number>(0);
    const { setSSO, enabled: ssoEnabled, validateUser, validateSSO, isLoaded: _isSSOLoaded, isValid: isSSOValid } = useSSO()
    const headerStyles = mergeStyleSets({
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.backgroundColor}`,
                color: branding.theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
            },
        ],
    });
    const contentStyles = mergeStyleSets({
        modalContainer: {
            minWidth: 320,
            maxWidth: 425,
            width: 425,
        },
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        accessButton: {
            display: 'flex',
            justifyContent: 'right',
        },
        header2: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.backgroundColor}`,
                color: branding.theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 4px 12px",
                justifyContent: "center",
            },
        ],
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.backgroundColor}`,
                color: branding.theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
                justifyContent: "center",
            },
        ],
        headerLogo: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
            },
        ],
        bodyMail: {
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            overflowY: "hidden",
            minHeight: 500,
        },
        containerSaveModal: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
            width: isMobile ? null : "30vw",
            height: "20vh",
        },
        body: {
            width: "80vw",
            height: "45vh",
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
        modalBody: {
            padding: "10px",
        },
    });

    const dialogContentProps = {
        type: DialogType.normal,
        title: "Document Checkedout",
        closeButtonAriaLabel: "Close",
        subText: "Document (" + documentDescription + ")" + " is checked out and cannot be updated at this time.",
    };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: "subscriptionDialog",
            subtitleAriaId: "subscriptionSubText",
            isBlocking: true,
            styles: dialogStyles,
            className: "DialogBackgoundColor",
        }),
        [labelId, subTextId]
    );

    const [showAccessCode, setShowAccessCode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validatingAccessCode, setValidatingAccessCode] = useState(false);
    const [userAccessCode, setUserAccessCode] = useState("");
    const [hideAccessCode, { toggle: toggleAccessCodeDialog }] = useBoolean(false);
    const [showMessageError, setShowMessageError] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [isEmailDisable, setIsEmailDisable] = useState(false);
    const [isFirstNameDisable, setIsFirstNameDisable] = useState(false);
    const [isLastNameDisable, setIsLastNameDisable] = useState(false);

    const [isValidGuid, setIsValidGuid] = useState(true);
    const [draftBLOB, setDraftBLOB] = useState<string | undefined>(undefined);
    const [draftDescription, setDraftDescription] = useState("");
    const [DocumentOnlyAudienceId, setDocumentOnlyAudienceId] = useState("");
    const [isRecordUpdate, setIsRecordUpdate] = useState(false);
    const [isRecordError, setIsRecordError] = useState(false);
    const { connection, setConnection } = useSignalR()
    const QueueCustomModal = useModal()
    const { setProfile } = useDocument()
    const [campaignUserName, setCampaignUserName] = useState("");

    let dataTemplate: ITemplates = {
        AccountId: 0,
        AllowCheckInOut: false,
        AllowEsign: false,
        Category: "",
        Description: "",
        DisplayName: "",
        DisplayNameToolTip: "",
        DocumentOnlyAudienceId: "",
        FileName: "",
        Id: 0,
        PropertyBag: propertyBag!,
        TemplateContent: "",
        TemplateId: "",
        eSignIntegrationKey: "",
        eSignPassword: "",
        eSignProvider: "",
        eSignURL: "",
        eSignUserId: "",
        culture: "",
        released: false,
        emailMoniker: "",
        AllowDocumentUpdate: false,
        AllowNotification: false,
        AllowDocX: false,
        cultureName: "",
        AllowSendEmail: false,
        DocumentCreation: false,
        DocumentDelete: false,
        DocumentShare: false,
        TemplateEdit: false,
        TemplateShare: false,
        TemplateDelete: false,
        DefaultDescription: "",
    };

    async function getGuid() {
        var url_string = window.location.href;

        var url = new URL(url_string);
        const guid = url.searchParams.get("guid");

        if (guid !== null && guid !== "") {
            campaignGuid = guid;
            setShowAccessCode(true);
        } else {
            setErrorMessage("Something went wrong");
        }
    }

    async function getCampiagnUserInformationDetail(detailUsername: string, campaignMasterId: string, baseUrl: string) {
        var campaignDetailUserInformation = await WebFormCampaignService.getCampaignUserDetailInformationByUsernameCampaignId({ autoprovision: true, campaignMasterId, detailUsername, path: baseUrl });

        if (campaignDetailUserInformation.length >= 1) {
            var userInformation = campaignDetailUserInformation[0];
            sessionStorage.setItem("userFullName", `${userInformation.FirstName} ${userInformation.LastName}`);
            sessionStorage.setItem("userFirstName", userInformation.FirstName);
            sessionStorage.setItem("userLastName", userInformation.LastName);

            setFirstName(userInformation.FirstName);
            setLastName(userInformation.LastName);
            if (userInformation.FirstName !== undefined && userInformation.FirstName !== "") {
                setIsFirstNameDisable(true);
            }
            if (userInformation.LastName !== undefined && userInformation.LastName !== "") {
                setIsLastNameDisable(true);
            }
            setCampaignDetailUserfullName(userInformation.FirstName + " " + userInformation.LastName);
        } else {
            setIsValidGuid(false);
            setShowMessageError(true);
            setErrorMessage("GUID Not Found " + JSON.stringify(campaignDetailUserInformation));
        }
    }

    const campaignUserInformation = async () => {
        var urlParams = new URLSearchParams(document.location.search.substring(1));
        var guid = urlParams.get("guid");

        if (guid) {
            sessionStorage.removeItem("campaignTargetUser");
            await getDetails(guid, baseUrl);
        }
    };

    async function getCampaignDetails() {
        var res = await WebFormCampaignService.getCampaign({ AccountId: Helper.getDefaultAccount(), SessionKey: Helper.getSessionKey(), Id: sessionStorage.getItem("campaignId")!, UserName: datajson.ownerUser });
        if (res[0].ReturnURL !== "http" || res[0].ReturnURL !== "https") {
            sessionStorage.setItem("returnURL", res[0].ReturnURL);
            redirectURL = res[0].ReturnURL;
        } else {
            sessionStorage.setItem("returnURL", "https://www.kimdocument.com/");
            redirectURL = "https://www.kimdocument.com/";
        }
    }

    async function getDetails(guid: string, baseUrl: string) {
        var campaignDetails = await WebFormCampaignService.getCampaignUserDetails({ autoprovision: true, guid, path: baseUrl });

        if (campaignDetails.length >= 1) {
            var details = campaignDetails[0];
            var extra = details.Actions;
            campaignUser = details.Username;
            setEmailAddress(details.Username);
            setIsEmailDisable(true);
            getCampiagnUserInformationDetail(campaignUser, extra.CampaignMasterId, baseUrl);
            accessCode = details.AccessCode;
            datajson.ownerUser = extra.CampaignOwner;
            datajson.TargetUser = campaignUser;
            datajson.TemplateId = extra.TemplateId.toString();
            datajson.User = extra.CampaignOwner;
            datajson.DocumentId = "-1";
            campaignId = details.CampaignMasterId;

            sessionStorage.setItem("campaignId", details.CampaignMasterId);

            setCampaignUserName(details.Username)


            if (details.DocumentId === -1) {
                templateLink = true;
                setTypeView("Template");
                loadTemplate(extra.TemplateId);
            } else {
                getCampaignDetails();
                datajson.DocumentId = details.DocumentId;
                setTypeView("Template");
                loadTemplateUpdate(extra.TemplateId);
            }
        } else {
            setIsValidGuid(false);
            setShowMessageError(true);
            setErrorMessage("Campaign Details Not Found " + JSON.stringify(campaignDetails));
        }
    }
    const loadData = async () => {
        const id = datajson.DocumentId;
        const getData = async () => {
            const req: IDocumentRequest = {
                UserName: datajson.ownerUser,
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id.toString(),
                IsPreview: false,
                IsDownload: true,
                IsDocx: true,
            };
            var res = await Helper.getDocumentDownload(req);

            if (res.Success === undefined || !res.Success) {
                setTemplateName(res.Name);
                setDescription(res.Description);
                setData(res.Data);
                var culture = res.Culture;
                setCulture(culture);
                setWaitToLoadCustomForm(true)
            }
        };
        getData();
    };
    const loadTemplateUpdate = async (templateId: string) => {

        var site = await Helper.loadSites()
        if (site !== null) {
            // sessionStorage.setItem("site", site.Path);
            // sessionStorage.setItem("TeamsId", site.TeamsId);
            // sessionStorage.setItem("DefaultPlan", site.DefaultPlan);
            setSiteSelected(site.Path);
        }

        //var res = await Helper.authenticate(datajson.ownerUser, site);

        const request = {
            UserName: datajson.ownerUser,
            templateId: templateId,
            ignoreDocument: true
        }

        let { data }: any = await HttpRequest.getWithSession('getDocumentOnlyTemplateID', request)
        const response = data

        if (response !== undefined) {
            try {
                var template = response as IDocumentOnlyTemplate;
                var emailMoni = "";
                if (template.emailMoniker !== undefined && template.emailMoniker !== null) {
                    emailMoni = template.emailMoniker;
                }

                dataTemplate = response as ITemplates;
                setDocumentOnlyAudienceId(dataTemplate.DocumentOnlyAudienceId);
                setTemplateCategory(template.Category.toString());
                setTemplateName(dataTemplate.DisplayName);
                setAudienceName(response.AudienceName);
                setAudienceMasterId(response.DocumentOnlyAudienceId);
                jsonBag = JSON.parse(dataTemplate.PropertyBag) as IPropertyBag;
                setDocumentCreation(jsonBag.DocumentCreation);
                setDocumentUpdate(jsonBag.DocumentUpdate);
                setDocumentDescription(template.Description);

                await getBrandingInformation(datajson.ownerUser, dataTemplate.DocumentOnlyAudienceId);
                setisApply(false);
                await GetAudiencebyOwner(dataTemplate.DocumentOnlyAudienceId);
                setWaitToLoadCustomForm(true)
            } catch (e) {
                console.log(e);
                return null;
            }
        }
    };

    const loadTemplate = async (templateId: string) => {


        var site = await Helper.loadSites()
        if (site !== null) {
            // sessionStorage.setItem("site", site.Path);
            // sessionStorage.setItem("TeamsId", site.TeamsId);
            setSiteSelected(site.Path);
        }

        //var res = await Helper.authenticate(datajson.ownerUser, site);

        const request = {
            UserName: datajson.ownerUser,
            templateId: templateId,
            ignoreDocument: true
        }

        let { data }: any = await HttpRequest.getWithSession('getDocumentOnlyTemplateID', request)
        const response = data

        if (response !== undefined) {
            try {
                var template = response as IDocumentOnlyTemplate;
                var emailMoni = "";
                if (template.emailMoniker !== undefined && template.emailMoniker !== null) {
                    emailMoni = template.emailMoniker;
                }

                setTemplateDescription(template.Description.toString());
                dataTemplate = response as ITemplates;
                setDocumentOnlyAudienceId(dataTemplate.DocumentOnlyAudienceId);
                setTemplateCategory(template.Category.toString());
                setTemplateName(dataTemplate.DisplayName);
                setAudienceName(response.AudienceName);

                if (Billing) {
                    let flagSub = dataTemplate.ActiveSubscription;

                    if (flagSub) {
                        setShowActiveSubcription(false);
                        setShowAccessCode(true);
                    } else {
                        setShowActiveSubcription(true);
                        setShowAccessCode(false);
                    }
                } else {
                    setShowActiveSubcription(false);
                    setShowAccessCode(true);
                }

                jsonBag = JSON.parse(dataTemplate.PropertyBag) as IPropertyBag;
                setDocumentCreation(jsonBag.DocumentCreation);
                setDocumentUpdate(jsonBag.DocumentUpdate);

                await GetAudiencebyOwner(dataTemplate.DocumentOnlyAudienceId);
                await getBrandingInformation(datajson.ownerUser, dataTemplate.DocumentOnlyAudienceId);
                setisApply(false);
                getCampaignDetails();
                await loadDraftInformation();
            } catch (e) {
                console.log(e);
                return null;
            }
        }
    };

    const loadDraftInformation = async () => {
        DraftServices.GetDocumentDrafts({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            EndUserName: datajson.ownerUser,
            Id: 1,
            Path: await Helper.getSite()!,
            SK: Helper.getSessionKey(),
            UserName: datajson.ownerUser,
            CampaignUserGUID: campaignGuid,
            SessionKey: Helper.getSessionKey(),
        }).then((res) => {
            if (res.length > 0) {
                setDraftBLOB(res[0].BLOB!);
                setData(res[0].JsonBLOB as any);
                setDraftId(res[0].Id);
                setDraftDescription(res[0].Description);
            }
        });
    };


    useEffect(() => {
        const init = async () => {
            var site = await Helper.loadSites()
            Billing = site !== null ? site.Billing : false
            baseUrl = site !== null ? site.Path.trim() : ""
            getGuid();
            campaignUserInformation();
        };
        if (isAuthenticated) {
            init().catch((e) => {
                console.log(e);
            });
        }
    }, [isAuthenticated]);

    const toggleCustomForm = useConstCallback(async (open: boolean) => {
        const site = Helper.getSiteSelected();
        setSiteSelected(site);
        setShowCustomForm(open);

        if (open) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(`${window.location.origin}/records?username=${datajson.User}`, {
                    headers: await Helper.getHeaders()
                })
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
        }
    });
    const toggleClose = useConstCallback((open: boolean) => {
        const site = Helper.getSiteSelected();
        setSiteSelected(site);
        setShowCustomForm(open);
        if (!open) {
            window.location.assign(sessionStorage.getItem("returnURL") === "" || sessionStorage.getItem("returnURL") === null || sessionStorage.getItem("returnURL") === undefined ? "https://www.kimdocument.com/" : sessionStorage.getItem("returnURL")!);
        }
    });

    const toggleQueueModal = useCallback((open: boolean, isUpdate: boolean, isError: boolean) => {
        if (open) {
            QueueCustomModal.open(true)
            setIsRecordUpdate(isUpdate)
            setIsRecordError(isError)
        }
    }, [QueueCustomModal])

    useEffect(() => {
        if (!QueueCustomModal.isOpen && QueueCustomModal.data) {
            toggleClose(false)
        }
    }, [QueueCustomModal.isOpen])

    useEffect(() => {
        console.log("Clear Session Storage")
        sessionStorage.clear()
        const interval = setInterval(() => {
            if (datajson.ownerUser !== "") {

                const request: IGetuserprofileRequest = {
                    UserName: datajson.ownerUser,
                    Profile: datajson.ownerUser,
                }
                UserService.GetUserProfile(request).then()

            }
        }, 300000);


        return () => {
            clearInterval(interval);
        };

    }, [])

    const onCreateUpdate = async (id: string) => {
        setLayoutLoaded(false);
        setShowPreview(true);
        setLayoutLoaded(true);
        setSpinnerText("Loading content...");

        const reqPreview: IDocumentRequest = {
            UserName: datajson.User,
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            DocumentId: "-1",
            Id: id,
            IsPreview: true,
            IsDownload: false,
            IsDocx: false,
            context: 'campaign',
            code: userAccessCode,
            templateId: datajson.TemplateId
        };
        if (!jsonBag.SupressPreview) {
            await Helper.getDocumentPreview(reqPreview).then((res) => {
                if (res.Success) {
                    setLayoutLoaded(true);
                    setPreviewURL(res.UrlPath);
                    setPreviewMime(res.mimeType);
                }
            });
        } else {
            hidePreview();
        }
        setId(id);
    };

    const hidePreview = () => {
        setShowModal(true);

        setShowPreview(false);
        setLayoutLoaded(false);
        setShowModal(true);
        hideModal();
    };

    const hideModal = () => {
        setSpinnerText("Loading content...");
        setisApply(true);

        window.location.assign(sessionStorage.getItem("returnURL") === "" || sessionStorage.getItem("returnURL") === null || sessionStorage.getItem("returnURL") === undefined ? "https://www.kimdocument.com/" : sessionStorage.getItem("returnURL")!);
        setShowModal(false);
    };

    async function resendCode() {
        setIsLoading(true)
        var response = await WebFormCampaignService.campaignAccessCode({ AccessCode: "000000", Guid: campaignGuid, Id: "", Path: baseUrl, ResetCode: true });
        if (response === "A new access code has been sent to recipient email address.") {
            setShowSuccess(true);
            setIsLoading(false)
            setMessage(response);
            setTimeout(function () {
                setShowSuccess(false);
            }, 5000);
        } else {
            setShowMessage(true);
            setIsLoading(false)
            setMessage("Something wrong");
            setTimeout(function () {
                setShowMessage(false);
            }, 5000);
        }
    }


    async function validate() {
        setValidatingAccessCode(true);
        try {
            var response = await WebFormCampaignService.campaignAccessCode({ AccessCode: userAccessCode === "" ? "000000" : userAccessCode, Guid: campaignGuid, Id: "", Path: baseUrl, ResetCode: false });
        } catch (error) {
            console.log(error)
            setUserAccessCode("");
            setErrorMessage("Error validating Access Code.");
            setShowMessageError(true);
            setValidatingAccessCode(false);

            setTimeout(function () {
                setShowMessageError(false);
                setErrorMessage("");
            }, 7000);
            return
        }

        if (response === "Access code validated.") {
            if (!templateLink) {
                await loadData()
                setShowAccessCode(false);
                toggleAccessCodeDialog();
                setisApply(false);
                sessionStorage.setItem("userFullName", `${firstName} ${lastName}`);
                sessionStorage.setItem("userFirstName", firstName);
                sessionStorage.setItem("userLastName", lastName);
                if (typeView === "Template") {
                    setShowPreview(false);
                    setLayoutLoaded(false);
                    toggleCustomForm(true);
                } else if (typeView === "Document") {
                    setisApply(false);
                    toggleCustomForm(false);
                    LoadPreview(datajson.DocumentId);
                }
                setValidatingAccessCode(false);
            }
            else {

                const request: IGetuserprofileRequest = {
                    Profile: campaignUserName,
                    UserName: datajson.ownerUser,
                }
                await UserService.GetUserProfile(request)
                    .then((data) => {
                        setProfile(data)
                        sessionStorage.setItem("campaignTargetUser", campaignUser);
                        setWaitToLoadProfile(true)

                        setShowAccessCode(false);
                        toggleAccessCodeDialog();
                        setisApply(false);
                        sessionStorage.setItem("userFullName", `${firstName} ${lastName}`);
                        sessionStorage.setItem("userFirstName", firstName);
                        sessionStorage.setItem("userLastName", lastName);
                        if (typeView === "Template") {
                            setShowPreview(false);
                            setLayoutLoaded(false);
                            toggleCustomForm(true);
                        } else if (typeView === "Document") {
                            setisApply(false);
                            toggleCustomForm(false);
                            LoadPreview(datajson.DocumentId);
                        }
                        setValidatingAccessCode(false);
                    })
                    .catch((error) => {
                        setUserAccessCode("");
                        setErrorMessage(error);
                        setShowMessageError(true);
                        setValidatingAccessCode(false);

                        setTimeout(function () {
                            setShowMessageError(false);
                            setErrorMessage("");
                        }, 7000);
                    })
            }

        } else {
            setUserAccessCode("");
            setErrorMessage(response.ExceptionMessage);
            setShowMessageError(true);
            setValidatingAccessCode(false);

            setTimeout(function () {
                setShowMessageError(false);
                setErrorMessage("");
            }, 7000);
        }
    }

    useEffect(() => {
        if (campaignUserName === '') {
            return
        }
        const Guid = new URLSearchParams(window.location.href).get("guid") ?? '';
        AuthService.validateSSO({ TemplateId: datajson.TemplateId, UserName: datajson.User, Guid }).then((claims) => {
            setProfile(claims)
            setSSO({ enabled: true, isValid: true, isLoaded: true })
            setShowAccessCode(false)
        }).catch(() => {
            return
        })
    }, [setProfile, campaignUserName])

    const LoadPreview = async (id: string) => {
        setLayoutLoaded(false);
        setShowPreview(true);
        setSpinnerText("Loading content...");

        const preview = async () => {
            const req: IDocumentRequest = {
                UserName: datajson.User,
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id,
                IsPreview: true,
                IsDownload: false,
                IsDocx: false,
                context: 'campaign',
                code: userAccessCode,
                templateId: datajson.TemplateId
            };
            var res = await Helper.getDocumentPreview(req);

            if (res.Success) {
                setLayoutLoaded(true);
                setPreviewURL(res.UrlPath);
                setPreviewMime(res.mimeType);
                setId(id);
            }
        };
        await preview();
    };

    const toggleCloseSubcription = useConstCallback(async () => {
        const site = Helper.getSiteSelected();
        setSiteSelected(site);
        await sendMailActiveSubcription();
        toggleHideDialogSub();

        window.location.assign(sessionStorage.getItem("returnURL") === "" || sessionStorage.getItem("returnURL") === null || sessionStorage.getItem("returnURL") === undefined ? "https://www.kimdocument.com/" : sessionStorage.getItem("returnURL")!);
    });

    const dialogActiveSubContentProps = {
        type: DialogType.normal,
        title: "Active Subcription",
        closeButtonAriaLabel: "Close",
        subText: "The subscription of " + datajson.User + " has been disabled.",
    };
    const sendMailActiveSubcription = () => {
        const send = async () => {
            const site = await Helper.getSite();
            const username = datajson.ownerUser;
            const accountId = Helper.getDefaultAccount();
            const sessionKey = Helper.getSessionKey();

            const message = "<p>Dear user, </p><p>The subscription of " + datajson.User + " has been disabled.</p><p>Thank you, the " + branding.appName + " Team</p>";

            const subjet = "Subscription disabled";

            const req: ISendMailRequest = {
                Id: "-1",
                DocumentId: "-1",
                FromEmail: await Helper.getNotificationEmail(),
                ToEmail: datajson.TargetUser,
                EmailSubject: subjet,
                EmailBody: message,
                AttachmentIds: "",
                SendDocument: true,
                EmailMoniker: jsonBag.emailMoniker,
                TemplateId: "-101",
                DocumentDelivery: "none",
            };

            await Helper.SendMailHtml(req)
                .then(data => {

                })

        };
        send();
    };

    const createUpdateDraft = () => {
        setShowCustomForm(false);
        setShowDraftModal(true);
    };

    const hideDraftModal = () => {
        setSpinnerText("Loading content...");
        setisApply(true);

        window.location.assign(sessionStorage.getItem("returnURL") === "" || sessionStorage.getItem("returnURL") === null || sessionStorage.getItem("returnURL") === undefined ? "https://www.kimdocument.com/" : sessionStorage.getItem("returnURL")!);
        setShowDraftModal(false);
    };

    const GetAudiencebyOwner = async (audienceMasterId: string) => {
        const req: IAudienceRequest = {
            UserName: Helper.getUsername() === undefined || Helper.getUsername() === null ? user.email : Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            SK: Helper.getSessionKey(),
            Path: await Helper.getSite()!,
            AudienceMasterId: audienceMasterId,
            TargetUser: Helper.getUsername() === undefined || Helper.getUsername() === null ? user.email : Helper.getUsername(),
            Autoprovision: true,
        };
        var response = await Helper.GetAudiencebyOwner(req);
        let audienceDetail = response as IDocumentOnlyAudience;
        response != undefined && seDataAudienceTargetUser(audienceDetail);
        let isUserAdmin = audienceDetail != undefined ? (audienceDetail.CoOwner || audienceDetail.Owner ? true : false) : false;
        setIsAdmin(isUserAdmin);
    };

    return (
        <>
            {!isApply ? (
                <div style={{ width: "100%" }}>
                    <div style={{ backgroundColor: branding.backgroundColor, color: branding.fontColor, float: "left", width: "100%", height: "60px" }}>
                        <Icon iconName="GripperDotsVertical" styles={companyIconStyle} />
                        <label style={{ paddingTop: "12px", paddingLeft: "10px", position: "absolute" }}>{branding.companyName}</label>
                        <label style={{ paddingTop: "35px", paddingLeft: "10px", position: "absolute" }}>{templateName}</label>
                    </div>
                    <div style={{ float: "left", width: "100%", backgroundColor: "#F0F0F0", height: "100%" }}>
                        <div style={{ margin: "auto", padding: "10px" }}>
                            <>

                                {!ShowActiveSubcription ? null : (
                                    <Dialog hidden={hideDialogSub} onDismiss={toggleHideDialogSub} dialogContentProps={dialogActiveSubContentProps} modalProps={modalProps}>
                                        <DialogFooter>
                                            <PrimaryButton onClick={toggleCloseSubcription} text="Ok" />
                                        </DialogFooter>
                                    </Dialog>
                                )}
                                {templateLink ? (
                                    <CustomForm
                                        waitToLoadProfile={waitToLoadProfile}
                                        allowDrafts={true}
                                        isAccessCodeValid={!showAccessCode}
                                        draftId={draftId}
                                        setDraftId={setDraftId}
                                        draftBLOB={draftBLOB}
                                        createUpdateDraft={createUpdateDraft}
                                        showCustomForm={showCustomForm}
                                        toggleCustomForm={toggleCustomForm}
                                        siteSelected={siteSelected}
                                        accountId={Helper.getDefaultAccount()}
                                        targetUser={datajson.TargetUser}
                                        username={datajson.User}
                                        sessionKey={Helper.getSessionKey()}
                                        templateId={datajson.TemplateId}
                                        templateName={templateName}
                                        templateDescription={templateDescription}
                                        update={onCreateUpdate}
                                        isCreator={false}
                                        isOwner={true}
                                        isCoOwner={false}
                                        culture={culture}
                                        ownerName={datajson.User}
                                        ownerUser={datajson.ownerUser}
                                        templateCategory={templateCategory}
                                        audienceName={audienceName}
                                        emailMoniker={jsonBag !== null ? jsonBag.emailMoniker : ""}
                                        documentCreation={documentCreation}
                                        documentUpdate={documentUpdate}
                                        templateEdit={dataTemplate.TemplateEdit}
                                        toggleClose={toggleClose}
                                        sendlink={templateLink}
                                        defaultDescription={draftId > 1 ? draftDescription : jsonBag != null ? jsonBag.DefaultDescription : ""}
                                        documentDescription={description}
                                        onBehalf={campaignUser}
                                        isSubform={datajson.IsSubform}
                                        documentId={datajson.PrimaryId.toString()}
                                        isCampaign={true}
                                        accessCode={userAccessCode}
                                        campaignGuid={campaignGuid}
                                        data={data}
                                        templateVersion={datajson.TemplateVersion}
                                        isAdmin={isAdmin}
                                        audienceMasterId={audienceMasterId.toString()}
                                        toggleQueueModal={toggleQueueModal}
                                        notifyClient={true}
                                    >
                                        {showAccessCode ? (
                                            <AccesCodeModal contentStyles={contentStyles} campaignDetailUserFullName={campaignDetailUserFullName}
                                                showMessage={showMessage} showSuccess={showSuccess} showMessageError={showMessageError} message={message} errorMessage={errorMessage}
                                                isEmailDisable={isEmailDisable} emailAddress={emailAddress} datajson={datajson}
                                                setFirstName={setFirstName} setEmailAddress={setEmailAddress} isFirstNameDisable={isFirstNameDisable} firstName={firstName}
                                                isLastNameDisable={isLastNameDisable} lastName={lastName} setLastName={setLastName} resendCode={resendCode}
                                                userAccessCode={userAccessCode} validate={validate} validatingAccessCode={validatingAccessCode}
                                                isLoading={isLoading} isValidGuid={isValidGuid} setUserAccessCode={setUserAccessCode}
                                            />
                                        ) : null}
                                    </CustomForm>
                                )
                                    : isCheckedOut ? (
                                        <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                                            <DialogFooter>
                                                <PrimaryButton onClick={toggleHideDialog} text="Ok" />
                                            </DialogFooter>
                                        </Dialog>
                                    ) : (
                                            <CustomForm
                                                waitToLoadCustomForm={waitToLoadCustomForm}
                                                showCustomForm={showCustomForm}
                                                isAccessCodeValid={!showAccessCode}
                                                toggleCustomForm={toggleCustomForm}
                                                siteSelected={siteSelected}
                                                accountId={Helper.getDefaultAccount()}
                                                targetUser={datajson.TargetUser}
                                                username={datajson.User}
                                                sessionKey={Helper.getSessionKey()}
                                                templateId={datajson.TemplateId}
                                                templateName={templateName}
                                                templateDescription={templateDescription}
                                                templateCategory={templateCategory}
                                                documentId={datajson?.DocumentId.toString()}
                                                update={onCreateUpdate}
                                                culture={culture}
                                                sendlink={true}
                                                toggleClose={toggleClose}
                                                templateVersion={datajson?.TemplateVersion}
                                                onBehalf={campaignUser}
                                                data={data}
                                                isCreator={false}
                                                isOwner={true}
                                                isCoOwner={false}
                                                ownerName={datajson.User}
                                                ownerUser={datajson.ownerUser}
                                                audienceName={audienceName}
                                                emailMoniker={jsonBag !== null ? jsonBag.emailMoniker : ""}
                                                documentCreation={documentCreation}
                                                documentUpdate={documentUpdate}
                                                templateEdit={dataTemplate.TemplateEdit}
                                                defaultDescription={jsonBag != null ? jsonBag.DefaultDescription : ""}
                                                documentDescription={description}
                                                isSubform={datajson.IsSubform}
                                                isCampaign={true}
                                                accessCode={userAccessCode}
                                                campaignGuid={campaignGuid}
                                                isAdmin={isAdmin}
                                                audienceMasterId={audienceMasterId.toString()}
                                                toggleQueueModal={toggleQueueModal}
                                                notifyClient={true}
                                            >
                                                {showAccessCode ? (
                                                    <AccesCodeModal contentStyles={contentStyles} campaignDetailUserFullName={campaignDetailUserFullName}
                                                        showMessage={showMessage} showSuccess={showSuccess} showMessageError={showMessageError} message={message} errorMessage={errorMessage}
                                                        isEmailDisable={isEmailDisable} emailAddress={emailAddress} datajson={datajson}
                                                        setFirstName={setFirstName} setEmailAddress={setEmailAddress} isFirstNameDisable={isFirstNameDisable} firstName={firstName}
                                                        isLastNameDisable={isLastNameDisable} lastName={lastName} setLastName={setLastName} resendCode={resendCode}
                                                        userAccessCode={userAccessCode} validate={validate} validatingAccessCode={validatingAccessCode}
                                                        isLoading={isLoading} isValidGuid={isValidGuid} setUserAccessCode={setUserAccessCode}
                                                    />
                                                ) : null}
                                            </CustomForm>
                                        )}
                                <PreviewScreen showModal={showPreview} hideModal={hidePreview} modalTitle={templateName} documentDescription={description} documentId={datajson?.DocumentId} allowDocX={datajson.allowDocx} spinnerText={spinnerText} layoutLoaded={layoutLoaded} URLPath={previewURL} mimeType={previewMime} onClose={false} authUser={campaignUser} Id={id} />
                                <QueueModal Modal={QueueCustomModal.Modal} close={QueueCustomModal.close} isUpdate={isRecordUpdate} isLink={true} isError={isRecordError} />
                                <Modal className="DialogBackgoundColor" isOpen={showModal} isBlocking={true}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Stack>
                                                    <div className={contentStyles.header}>
                                                        <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={hideModal} title="Close." />
                                                    </div>
                                                    <Separator />
                                                </Stack>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className={contentStyles.body}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                            <br />
                                                            <div style={{ textAlign: "center" }}>
                                                                <p>Thank you for submitting your response.</p>
                                                                <p> You will receive an email confirming receipt with a copy of your response for your records.</p>
                                                                <br />
                                                                <br />
                                                                <div style={{ paddingBottom: "10px" }}>
                                                                    <DefaultButton onClick={hideModal} text="Close Form" title="Close Form." />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal className="DialogBackgoundColor" isOpen={showDraftModal} isBlocking={true}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Stack>
                                                    <div className={headerStyles.header}>
                                                        <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={hideDraftModal} title="Close." />
                                                    </div>
                                                    <Separator />
                                                </Stack>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className={contentStyles.body}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                            <br />
                                                            <div style={{ textAlign: "center" }}>
                                                                <p>You can continue filling this draft in another moment.</p>
                                                                <p>When you want to update your draft please return to the email you were sent and click on the link that provided you with initial access.</p>
                                                                <p>You will then be sent a new one-time access code that will allow you to re-open your draft and complete before submitting.</p>
                                                                <br />
                                                                <br />
                                                                <div style={{ paddingBottom: "10px" }}>
                                                                    <DefaultButton onClick={hideDraftModal} text="Close Form" title="Close Form." />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </>
                        </div>
                    </div>
                </div>
            ) : (
                    <Stack grow={1} styles={{ root: { height: "100%", alignItems: "center", justifyContent: "center" } }}>
                        <Spinner size={SpinnerSize.large} label="Loading data, please wait..." ariaLive="assertive" />
                    </Stack>
                )}
        </>
    );
}
type AccesCodeProps = {
    contentStyles: any; // Specify the type of contentStyles
    campaignDetailUserFullName: string;
    showMessage: boolean;
    showSuccess: boolean;
    showMessageError: boolean;
    message: string;
    errorMessage: string;
    isEmailDisable: boolean;
    emailAddress: string;
    setEmailAddress: React.Dispatch<React.SetStateAction<string>>
    setFirstName: React.Dispatch<React.SetStateAction<string>>
    isFirstNameDisable: boolean;
    firstName: string;
    isLastNameDisable: boolean;
    lastName: string;
    setLastName: React.Dispatch<React.SetStateAction<string>>
    resendCode: () => void;
    userAccessCode: string;
    validate: () => void;
    validatingAccessCode: boolean
    isLoading: boolean
    isValidGuid: boolean
    datajson: IDataLink
    setUserAccessCode: React.Dispatch<React.SetStateAction<string>>
};
const AccesCodeModal = ({ contentStyles, campaignDetailUserFullName, showMessage, showSuccess, showMessageError,
    message, errorMessage, isEmailDisable, emailAddress, setFirstName, setEmailAddress, isFirstNameDisable,
    firstName, isLastNameDisable, lastName, setLastName, resendCode, userAccessCode, validate, datajson,
    validatingAccessCode, isLoading, isValidGuid, setUserAccessCode }: AccesCodeProps) => {

    const [instructions, setInstructions] = useState('')
    const [redirectUrl, setRedirectUrl] = useState("")
    const { setSSO, enabled: ssoEnabled, validateUser, validateSSO, isLoaded: _isSSOLoaded, isValid: isSSOValid } = useSSO()
    const isSSOLoaded = _isSSOLoaded && !isSSOValid
    useEffect(() => {

        DocumentService.getDocumentMetadata({ TemplateId: datajson.TemplateId, UserName: datajson.User }).then(({ data }: any) => {
            setInstructions(data.accessCodeInstructions?.trim() ?? `(${campaignDetailUserFullName} please generate an access code.)`)
            setRedirectUrl(data.RedirectUrl)
        })

    }, [campaignDetailUserFullName, datajson.TemplateId, datajson.User]);

    const onClickCancel = () => {
        const redirect = redirectUrl === '' ? window.location.origin + "/app" : redirectUrl
        window.location.assign(redirect);
    };



    return <>
        <Modal
            isOpen={true}
            isBlocking={true}
            containerClassName={contentStyles.modalContainer}
        >
            {/*-- START HEADER --*/}
            <Stack>
                <Stack className={contentStyles.header2}>
                    <StackItem>
                        <Text variant={"xLarge"} id={"lblAuthCode"}
                            title={"User Access"}>
                            {"User Access"}
                        </Text>
                    </StackItem>
                    <StackItem styles={{ root: { paddingTop: "4px", textAlign: "center" } }}>
                        <Text variant={"medium"} id={"instructions"}
                            title={"Instructions"}>
                            {instructions}
                        </Text>
                    </StackItem>
                </Stack>
            </Stack>
            <Separator />

            {/*-- END HEADER --*/}

            {/*-- START BODY --*/}
            <Stack horizontal tokens={{ padding: 8 }} style={{ overflow: "hidden" }}>

                {/*-- START CONTENT --*/}
                <Stack grow={1} style={{ overflow: "hidden", padding: "0px 8px 8px 8px" }}>


                    <StackItem>
                        {showMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                        {showSuccess ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                        {showMessageError ?
                            <MessageBar styles={messageBarFailedStyles}>{errorMessage}</MessageBar> : null}
                    </StackItem>

                    <Stack grow={1} style={{ gap: '0.5rem' }}>

                        {!isValidGuid &&
                            <Stack grow={1} styles={{ root: { alignItems: "center", justifyContent: "center" } }}>
                                <Text variant={"medium"} id={"instructions"} styles={{ root: { paddingTop: "4px" } }}
                                    title={"Instructions"}>
                                    {"Invalid Campaign identifier."}
                                </Text>
                            </Stack>
                        }

                        {isValidGuid && (validatingAccessCode || isLoading) &&
                            <Stack grow={1} styles={{ root: { alignItems: "center", justifyContent: "center" } }}>
                                <Spinner size={SpinnerSize.medium}
                                    label={validatingAccessCode ? "Validating  data.." : "Sending Access Code.."}
                                    ariaLive="assertive" />
                            </Stack>
                        }

                        {isValidGuid && !validatingAccessCode && !isLoading &&
                            <>
                                <StackItem tokens={{ padding: "4px 0px" }}>
                                    <TextField
                                        id="emailAddress"
                                        label={"Email Address:"}
                                        maxLength={250}
                                        required
                                        disabled={isEmailDisable}
                                        value={emailAddress}
                                        styles={textFieldStyle}
                                        borderless
                                        title="Enter Email Address."
                                        onChange={(_, v) => {
                                            setEmailAddress(v!);
                                        }}
                                    />
                                </StackItem>
                                {!isSSOLoaded ? <div className="ms-Grid-row">
                                    <div className={`ms-Grid-col ms-sm12 ms-md12 ms-lg12 ${contentStyles.accessButton}`}>
                                        <PrimaryButton onClick={() => validateUser({
                                            onBehalf: emailAddress,
                                            owner: datajson.User,
                                            templateId: datajson.TemplateId
                                        })} text={"Access"} title={"Enter email to activate button."} disabled={emailAddress.length === 0} />
                                    </div>
                                </div> : <>
                                        <StackItem tokens={{ padding: "4px 0px" }}>
                                            <TextField
                                                id="firstName"
                                                label={"First Name:"}
                                                maxLength={250}
                                                required
                                                disabled={isFirstNameDisable}
                                                value={firstName}
                                                styles={textFieldStyle}
                                                borderless
                                                title="Enter First Name."
                                                onChange={(_, v) => {
                                                    setFirstName(v!);
                                                }}
                                            />
                                        </StackItem>
                                        <StackItem tokens={{ padding: "4px 0px" }}>
                                            <TextField
                                                id="lastName"
                                                label={"Last Name:"}
                                                maxLength={250}
                                                required
                                                disabled={isLastNameDisable}
                                                value={lastName}
                                                styles={textFieldStyle}
                                                borderless
                                                title="Enter Last Name."
                                                onChange={(_, v) => {
                                                    setLastName(v!);
                                                }}
                                            />
                                        </StackItem>
                                        <Separator />
                                        <StackItem>
                                            <PrimaryButton styles={{ root: { width: "100%" } }} onClick={resendCode}
                                                text={"Get code"}
                                                title={"Select get code to send an access code to your email address."} />
                                        </StackItem>

                                        <StackItem tokens={{ padding: "4px 0px" }}>
                                            <TextField
                                                id="txtCampaignAccessCode"
                                                label={"Enter Access Code:"}
                                                maxLength={500}
                                                required
                                                value={userAccessCode}
                                                onChange={(_, v) => {
                                                    setUserAccessCode(v!.trim());
                                                }}
                                                title="Please enter the access code sent to your email."
                                            />
                                        </StackItem>
                                    </>}
                            </>
                        }

                    </Stack>

                    {isSSOLoaded ?
                        <>
                            <Separator></Separator>
                            <Stack horizontal style={{ overflow: "hidden" }} styles={nonShrinkingStackItemStyles}>
                                <StackItem grow={1}>&nbsp;</StackItem>
                                <StackItem>
                                    <PrimaryButton onClick={validate} text="Submit" styles={{ root: { marginRight: 8 } }}
                                        title={userAccessCode === "Enter Access code to activate button." ? "" : "Submit."}
                                        disabled={userAccessCode === "" || !isValidGuid || validatingAccessCode} />
                                    <DefaultButton text={"Cancel"} title={"Cancel"}
                                        onClick={onClickCancel} />
                                </StackItem>
                            </Stack>
                        </>
                        : null}
                </Stack>
                {/*-- END CONTENT --*/}

            </Stack>
            {/*-- END BODY --*/}

        </Modal>
    </>

}
export default CampaignWebForm;
