import { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { ReactHookFormControlProps } from "../Models/ReactHookFormControlProps";
import { Checkbox, ICheckboxProps } from "@fluentui/react";

export const RHFCheckBoxControl: FC<ReactHookFormControlProps & ICheckboxProps> = (props) => {
    
    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error }
            }) => (
                <Checkbox
                    id={props.id}
                    name={fieldName}
                    label={props.label}
                    onRenderLabel={props.onRenderLabel}
                    disabled={props.readOnly}
                    title={props.title}
                    value={value}
                    onChange={(e, checked) => {
                        props.setValue(props.id, `${checked}`)
                        props.trigger(props.id)
                        if(props.OnBlurOrOnChange !== undefined) props.OnBlurOrOnChange()
                    }}
                    onBlur={onBlur}
                    defaultValue={undefined}
                    checked={value === 'true'}
                    inputProps={{ tabIndex: props.tabIndex }}
                />
            )}
        />
    );
};