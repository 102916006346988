import React, { useEffect, useState } from 'react'
import {useBranding} from "../../hooks/useBranding";
import GraphApiService, {IConsentRequest} from "../../services/GraphApi";
import {Stack, mergeStyleSets, ImageFit, Image, StackItem, DefaultEffects} from "@fluentui/react";

const contentStyles = mergeStyleSets({
    mainContainer: {
        backgroundColor: "#050a1e",
        alignItems: "center",
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        minHeight: '100%'
    },
    footer: {
        color: '#ffffff',
        width: '100vw',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: '#333333'
    },
    link: {
        color: '#478ac9'
    },
    message: {
        width: '50rem'
    },
    text: {
        whiteSpace: 'pre-wrap'
    }
})

function Permissions() {
    const { branding, getAppSettings } = useBranding()
    const [message, setMessage] = useState('Loading...')
    useEffect(() => {
        getAppSettings()
        setTimeout(function () {
            var url_string = window.location.href
            var url = new URL(url_string);
            const errorResponse = url.searchParams.get('error')
            if (errorResponse) {
                const errorDescription = url.searchParams.get('error_description')
                const errorSubcode = url.searchParams.get('error_subcode')

                if (errorSubcode === 'cancel') {
                    return setMessage(errorDescription ?? 'Operation cancelled.')
                }
                return setMessage(errorDescription ?? 'An error ocurred.')
            }
            saveGraphApiCode(url.searchParams)
        }, 1000);
    }, [])

    const saveGraphApiCode = async (params: URLSearchParams) => {
        if (params.has('code') && params.get('code') !== "") {
            GraphApiService.Consent({
                Code: params.get('code')!,
                State: params.get('state')!,
            }).then(() => {
                setMessage('Your email consent permissions have been propagated. \nYou may close this window. Thank you.')
            }).catch((error) => {
                setMessage(error.message)
            })
        } else {
            setMessage('This consent url has expired. Please verify and try again.')
        }
    }

    return (
        <div className={contentStyles.mainContainer}>
            <Stack className={contentStyles.message} grow={2}>
                <Stack tokens={{ padding: 32 }}>
                    <Image
                        src={branding.appLogo}
                        alt="Kim Technologies"
                        imageFit={ImageFit.contain}
                        height={64}
                    />
                </Stack>
                <Stack tokens={{ padding: 32 }}
                    styles={{
                        root: {
                            backgroundColor: "#FAFAFA",
                            borderRadius: "8px",
                            boxShadow: DefaultEffects.elevation16,

                        }
                    }}>
                    <Stack horizontal styles={{ root: { alignItems: "center" } }}>
                        <StackItem grow={1}>
                            <section className="u-align-center u-clearfix u-section-1" id="carousel_8db2">
                                <div className="u-align-left u-container-style u-expanded-width-lg u-expanded-width-md u-expanded-width-xl u-group u-image u-image-1" data-image-width="150" data-image-height="100">
                                    <div className="u-container-layout u-container-layout-1">
                                        <h2 className={contentStyles.text}>{message}</h2>
                                        <br />
                                        <div className="u-FlexInfoColumns-1">
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </StackItem>

                    </Stack>

                </Stack>

            </Stack>
            <Stack className={contentStyles.footer}>
                <footer id="sec-e947">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <p className="u-small-text u-text u-text-variant u-text-1">
                            {branding.appName} - Copyright {(new Date().getFullYear()).toString()}
                            <br />Kim Technologies <a href="https://www.ask.kim" className={contentStyles.link}>www.ask.kim</a>

                        </p>
                    </div>
                </footer>
            </Stack>
        </div>
    )
}

export default Permissions