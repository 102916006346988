import { ParamType } from './Enums';
import { MapInputHeader, MapProps, ParamsURL_Form_Param, SurrogateFilter } from './OutboundIntegrationModel';

export const Const_UrlParam: ParamsURL_Form_Param = {
    ParamName: "",
    ParamType: ParamType.URL,
    ParamValueSource: "",
    ParamValueType: "",
    InlineAttachment: false
}

export const Const_FormDataParam: ParamsURL_Form_Param = {
    ParamName: "",
    ParamType: ParamType.FORM,
    ParamValueSource: "",
    ParamValueType: "",
    InlineAttachment: false
}

export const Const_SurrogateFilter: SurrogateFilter = {
    FilterName: "",
    IntegrationId: -1,
    RecordFilter: "",
    SortOrder: "",
    SourceRecordId: -1,
    TemplateId: -1
}

export const Const_Header: MapInputHeader = {
    Key: "",
    Value: ""
}

export const Const_MapProps: MapProps = {
    Index: "0",
    Output: true,
    Path: "",
    TargetField: "",
    ValueType: "",
    IdentifierKeyName: "",
    IsHeader: false
}

export const ContentType = "content-type";
export const AcceptType = "accept";

export const SendAsTypeList: { [key: string]: string } = {}
SendAsTypeList["application/json"] = "JSON";
SendAsTypeList["application/xml"] = "XML";
SendAsTypeList["text/plain"] = "TextPlain";
SendAsTypeList["JSON"] = "application/json";
SendAsTypeList["XML"] = "application/xml";
SendAsTypeList["tTextPlain"] = "text/plain";

export const EncodeRequestTypeList: { [key: string]: string } = {}
EncodeRequestTypeList["application/xml"] = "XML";
EncodeRequestTypeList["text/plain"] = "Base64";
EncodeRequestTypeList["XML"] = "application/xml";
EncodeRequestTypeList["Base64"] = "text/plain";

export const BodyFields: string[] = [
    "IncludeXMLPayload",
    "IncludeAttachments",
    "IncludeCaseDocument",
    "DocumentType",
    "formDataParamsList",
    "Raw",
    "MapInputPayload",
    "MapInputScript",
    "MapInputUseParentSurrogateSubrecord",
    "MapInputFilterKeyName",
    "surrogateFiltersList"
];