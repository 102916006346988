import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import { diffDates } from '../Helpers/DateUtils';
import { isInTeams } from '../Helpers/Utils';

type UseIdleProps = {
    onIdle: any
    idleTime?: number
}

const DEFAULT_IDLE_TIME = 30
const useIdle = ({ onIdle, idleTime = DEFAULT_IDLE_TIME }: UseIdleProps) => {
    const [isIdle, setIsIdle] = useState(false);


    const handleOnIdle = () => {
        setIsIdle(true);
        onIdle()
    }

    const { getRemainingTime, getLastActiveTime, pause } = useIdleTimer({
        crossTab: true,
        timeout: (60 * 1000 * idleTime) + 1000,
        onIdle: handleOnIdle,
        debounce: 500,


    })
    useEffect(() => {
        if (isInTeams()) {
            pause()
        }
    }, [pause])

    return {
        getRemainingTime,
        getLastActiveTime,
        isIdle
    }
}
export const setIdleTime = () => {
    localStorage.setItem('inactive_idle', new Date().toISOString())
}
export const deleteIdleTime = () => {
    localStorage.removeItem('inactive_idle')
}

export const existIdle = ():boolean => {
    const storedTime = localStorage.getItem('inactive_idle')
    return storedTime != null ? true :  false
}


export const isValidIdleTime = (): boolean => {
    const storedTime = localStorage.getItem('inactive_idle')
    const time = storedTime ? new Date(storedTime) : undefined
    if (time) {
        const difference = diffDates(new Date(), time, 'minutes')
        return DEFAULT_IDLE_TIME < difference
    }
    return false
}
export const validateIdleTime = (callback: () => void) => {
    if (isValidIdleTime()) {
        callback()
    }
}
export default useIdle