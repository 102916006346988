import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import {
    DefaultButton, DetailsListLayoutMode, DialogFooter, FontWeights, getTheme,
    IconButton, IContextualMenuItem, IContextualMenuProps, IIconProps,
    IMessageBarStyles, Label, mergeStyleSets, MessageBar, Modal, Panel,
    PanelType, PrimaryButton, ScrollablePane, SelectionMode, Separator,
    Spinner, SpinnerSize, Stack, StackItem
} from '@fluentui/react';
import { decode } from 'base64-arraybuffer';
import { t } from 'i18next';
import { CheckboxVisibility, DetailsList } from 'office-ui-fabric-react/lib/DetailsList';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../hooks/useDialog';
import { useModal } from '../../hooks/useModal';
import AttachmentService from '../../services/attachments';
import StatusMessage, { StatusEnum } from '../../share/StatusMessage';
import HelpComponent from '../Help/HelpComponent';
import { getAttachmentColumns, getRuleAttachmentColumns } from './Attachment.data';
import AttachmentForm from './AttachmentForm';
import { AttachmentProvider } from './Context/AttachmentContext';
import { useAttachment } from './Context/useAttachment';
import '../../Custom.css';
import HelpComponentRelative from "../Help/HelpComponentRelative";
import { getRecordDescription } from '../../Helpers/functions';
import { useBranding } from '../../hooks/useBranding';

const MAX_ATTACHMENT_ALLOWED = 50

const theme = getTheme();

const cancelIcon: IIconProps = { iconName: 'Cancel' };
type AttachmentDocumentProps = {
    hideAttachament: any
    showAttachment: any
    documentDescription: any
    documentId: any
    documentDraftId?: any
    templateId: any
    activeSubscription: any
    subscriptionStatusMessage: any
    update: any
    showRules: boolean
    showAttachments: boolean
    validAttachments?: any
    setValidAttachments?: any
    createDraftAndAttachment?: Function | undefined
    docSource: string
    targetUser: string
    username: string

}
function AttachmentDocument(props: AttachmentDocumentProps) {
    const { branding } = useBranding()
    const { t } = useTranslation(['attachmentsComponent', 'common'])

    const hideAttachmentModal = () => {
        props.hideAttachament()
    }

    const panelHeader: HTMLAttributes<HTMLDivElement> = {
        style: {
            fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
            fontWeight: "bolder",
            color: branding.theme.palette.themePrimary,
            fontSize: 20
        }
    }

    const getRules = useCallback(() => {
        return AttachmentService.getRuleAttachments({
            DocumentId: props.documentId,
            TemplateId: props.templateId,
            DocSource: props.docSource,
            DocumentDraftId: props.documentDraftId,
            Username: props.username,
            EndUserName: props.targetUser,
        })
    }, [props.docSource, props.documentDraftId, props.documentId, props.targetUser, props.templateId, props.username])
    const getAttachments = useCallback(() => {
        return AttachmentService.GetDocumentOnlyAttachmentsByDocumentId(
            {
            DocumentId: props.documentId,
                TemplateId: props.templateId,
                DocSource: props.docSource,
                DocumentDraftId: props.documentDraftId,
                Username: props.username,
                EndUserName: props.targetUser,
            });
    }, [props.docSource, props.documentDraftId, props.documentId, props.targetUser, props.templateId, props.username])

    return (
        <AttachmentProvider
            docSource={props.docSource} documentId={props.documentId} documentDraftId={props.documentDraftId}
            templateId={props.templateId} update={props.update}
            endUserName={props.targetUser} username={props.username}
            billingActiveStr={(!props.activeSubscription && sessionStorage.getItem("billingActive") === 'true') ? props.subscriptionStatusMessage : ''}
        >
            {!isMobile ?
                <Desktop showAttachment={props.showAttachment} hideAttachmentModal={hideAttachmentModal}
                    documentDescription={props.documentDescription} >

                    {props.showRules ?
                        <DetailAttachments getColumns={getRuleAttachmentColumns} createDraftAndAttachment={props.createDraftAndAttachment}
                            fetchCall={getRules} validAttachments={props.validAttachments} /> : null}

                    {props.showAttachments ?
                        <DetailAttachments hasHeader={true} getColumns={getAttachmentColumns}
                            fetchCall={getAttachments} /> : null}
                </Desktop>
                :
                <Panel
                    headerText={getRecordDescription(t('title.modal', { ns: 'attachmentsComponent' }), props.documentDescription)}
                    headerTextProps={panelHeader} isBlocking={false} isOpen={props.showAttachment}
                    onDismiss={hideAttachmentModal} closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common' })} type={PanelType.smallFluid}>
                    <Stack>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div style={{ padding: "0px", position: "absolute", top: "-2px", right: "5px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <HelpComponent
                                        headline={t('modal.helpTitle')}
                                        text={t('modal.helpText')}
                                        right="50px"
                                        top="15px"
                                        location="records"
                                    />
                                </div>
                                {props.showRules ?
                                    <DetailAttachments getColumns={getRuleAttachmentColumns}
                                        createDraftAndAttachment={props.createDraftAndAttachment}
                                        fetchCall={getRules} validAttachments={props.validAttachments} /> : <></>}

                                {props.showAttachments ?
                                    <DetailAttachments hasHeader={true} getColumns={getAttachmentColumns}
                                        fetchCall={getAttachments} /> : <></>}
                            </div>
                        </div>
                    </Stack>
                </Panel>
            }
        </AttachmentProvider >
    )
}
const HeaderForm = ({ attachmentSize, setIsLoading, setSpinnerText }: { attachmentSize: number, setIsLoading: Function, setSpinnerText: Function }) => {

    const { billingActiveStr, showSuccessMessage, showErrorMessage, setShowErrorMessage, message } = useAttachment()

    const attachmentForm = useModal()
    return (
        <>
            {<div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mb-4">

                    {showErrorMessage && <StatusMessage setShowMessage={setShowErrorMessage} status={StatusEnum.Error}>
                        {message}
                    </StatusMessage>}
                    {showSuccessMessage ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                </div>
            </div>}
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <DefaultButton disabled={Boolean(billingActiveStr) || attachmentSize >= MAX_ATTACHMENT_ALLOWED}
                        title={Boolean(billingActiveStr) ? billingActiveStr :
                            attachmentSize >= MAX_ATTACHMENT_ALLOWED ? t('title.btnALimitAttachment', { ns: 'attachmentsComponent' }) : t('title.btnAddAttachment', { ns: 'attachmentsComponent' })} text={t('text.btnAddAttachment', { ns: 'attachmentsComponent' })}
                        onClick={attachmentForm.open} iconProps={{ iconName: 'Attach' }} />
                </div>
            </div>
            <AttachmentForm Modal={attachmentForm.Modal} setSpinnerText={setSpinnerText}
                close={attachmentForm.close} isAddAttachmentModalVisible={attachmentForm.isOpen}
                setIsAddAttachmentModalVisible={attachmentForm.open}
                setIsLoading={setIsLoading} item={{ rule: { id: -1 } }} />
        </>

    )
}
const Desktop = ({ children, showAttachment, hideAttachmentModal, documentDescription }: any) => {
    const { t } = useTranslation('attachmentsComponent')
    const { branding } = useBranding()
    const headerStyles = mergeStyleSets({
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: branding.theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
    })
    return (
        <Modal allowTouchBodyScroll={false} isOpen={showAttachment} containerClassName={contentStyles.container} isBlocking={true}>
            <Stack>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">

                        <Stack horizontal className={headerStyles.header}>
                            <StackItem grow={1}>
                                <Text variant={"xLarge"}>
                                    {getRecordDescription(t('title.modal', { ns: 'attachmentsComponent' }), documentDescription)}
                                </Text>

                            </StackItem>
                            <Stack horizontal>
                                <HelpComponentRelative
                                    headline={t('modal.helpTitle')}
                                    text={t('modal.helpText')}
                                    right="50px"
                                    top="15px"
                                    location="records"
                                />
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                    onClick={hideAttachmentModal}
                                    title={t('Common.Button.Close', { ns: 'common' })}
                                />
                            </Stack>
                        </Stack>
                    </div>

                    <Separator />
                    {children}
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <DefaultButton onClick={hideAttachmentModal} text={t('Common.Button.Close', { ns: 'common' })} title={t('Common.Button.Close', { ns: 'common' })} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Stack>
        </Modal >
    )
}
type DetailAttachmentsProps = {
    fetchCall: () => Promise<any>
    hasHeader?: boolean
    getColumns: Function
    validAttachments?: any
    createDraftAndAttachment?: Function | undefined
}
const DetailAttachments = ({ fetchCall, getColumns, hasHeader, validAttachments, createDraftAndAttachment }: DetailAttachmentsProps,) => {

    const [isLoading, setIsLoading] = useState(true)
    const [attachments, setAttachments] = useState([])
    const uploadRule = useModal()
    const [, setShowData] = useState(false)
    const [showMessage, setShowMessage] = useState<boolean>(validAttachments?.length > 0)
    const [spinnerText, setSpinnerText] = useState("")

    useEffect(() => {
        if (isLoading) {
            fetchCall().then(({ data }) => {
                if (data.showData) {
                    setShowData(data.showData)
                    setAttachments(data.attachments)
                }
                setIsLoading(false)
            })
        }
    }, [fetchCall, isLoading])

    return (
        <>
            {hasHeader && <HeaderForm setSpinnerText={setSpinnerText} attachmentSize={attachments.length} setIsLoading={setIsLoading} />}

            {showMessage && <StatusMessage setShowMessage={setShowMessage} status={StatusEnum.Error}>
                {validAttachments?.map((v: string, index: number) => (
                    <div key={index}>{v}</div>
                ))}
            </StatusMessage>}
            <PanelAttachment spinnerText={spinnerText} setSpinnerText={setSpinnerText} attachments={attachments} getColumns={getColumns}
                setIsLoading={setIsLoading} isLoading={isLoading} ButtonUpload={
                    ({ item }: any) => { return <PrimaryButton className={contentStyles.button} text={t('Common.Button.Upload', { ns: 'common' })} onClick={() => { return uploadRule.open(item) }} /> }
                } />
            <Separator />
            <AttachmentForm Modal={uploadRule.Modal} setSpinnerText={setSpinnerText}
                close={uploadRule.close} isAddAttachmentModalVisible={uploadRule.isOpen}
                setIsAddAttachmentModalVisible={uploadRule.open} item={uploadRule.data}
                setIsLoading={setIsLoading} createDraftAndAttachment={createDraftAndAttachment} />
        </>
    )
}

type PanelAttachmentProps = {
    setIsLoading: any
    isLoading: boolean
    attachments: any
    getColumns: Function
    ButtonUpload?: any
    spinnerText: string
    setSpinnerText: Function
}
const PanelAttachment = ({ setIsLoading, isLoading, spinnerText, setSpinnerText, attachments, getColumns, ButtonUpload }: PanelAttachmentProps) => {
    const deleteDialog = useDialog()
    const downloadDialog = useDialog()
    const { update, endUserName, username, docSource, setShowSuccessMessage, setShowErrorMessage, setMessage } = useAttachment()

    const getMenuProps = (item: any): IContextualMenuProps => {
        const menuItems: IContextualMenuItem[] = [
            {
                key: "makepublicprivate",
                text: item.Visibility == "" ? t('title.private', { ns: 'attachmentsComponent' }) : t('title.public', { ns: 'attachmentsComponent' }),
                onClick: () => {
                    makePublicPrivate(item)
                },
                hidden: true,
            },
            {
                key: 'delete',
                text: t('text.delete', { ns: 'attachmentsComponent' }),
                title: t('title.delete', { ns: 'attachmentsComponent' }),

                onClick: () => {
                    deleteDialog.open({ confirm: () => { return () => { deleteAttachment(item) } } })
                },
            },
            {
                key: 'download',
                text: t('text.download', { ns: 'attachmentsComponent' }),
                title: t('title.download', { ns: 'attachmentsComponent' }),
                onClick: () => {

                    downloadDialog.open({ confirm: () => { return () => DownloadDoc(item) } })
                },
            }
        ];
        let items = isMobile && sessionStorage.getItem("entityId") !== "Not In Teams" ? menuItems.filter(i => i.key !== 'dowload') : menuItems
        items = item?.DocumentDraftId ? menuItems.filter(i => i.key !== 'makepublicprivate') : items
        const menuProps: IContextualMenuProps = {
            shouldFocusOnMount: true,
            items: items,
        };
        return menuProps
    }
    const _columns = getColumns(getMenuProps, ButtonUpload)

    const [mQuery,] = React.useState<any>({
        matches: window.innerWidth > 639 ? true : false,
    });



    const deleteAttachment = async (item: any) => {
        setSpinnerText(t('text.spinnerDeleting', { ns: 'common' }))
        AttachmentService.deleteAttachment({
            Id: item?.Id, attachmentDraftId: item?.Id, EndUserName: endUserName, Username: username,
            DocumentDraftId: item.DocumentDraftId, DocSource: docSource
        }).then(({ data }: any) => {
            if (data.Success) {
                update(0)
            } else {
                setShowErrorMessage(true)
                setMessage(data.ExceptionMessage)
            }
            setIsLoading(true)
            deleteDialog.close()
        }).catch((err) => {
            setShowErrorMessage(true)
            setMessage(err.message)
        })
    }
    const makePublicPrivate = (item: any) => {
        setSpinnerText(t('text.spinnerUpdating', { ns: 'common' }))
        AttachmentService.makePublicPrivate({ Id: item?.Id, EndUserName: endUserName, Username: username, DocSource: docSource }).then(({ data }: any) => {
            if (data.Success) {
                setIsLoading(true)
                update(0)
            } else {
                setShowErrorMessage(true)
                setMessage(data.ExceptionMessage)
            }
        }).catch((error) => {

            setShowErrorMessage(true)
            setMessage(error?.message)
        })
    }

    useEffect(() => {
        console.log('isloading', isLoading)
    }, [isLoading])
    function DownloadDoc(item: any) {
        //setShowConfirmationModal(false)

        const download = async () => {
            AttachmentService.downloadAttachment({ Id: item.Id, EndUserName: endUserName, Username: username, DocumentDraftId: item.DocumentDraftId, DocSource: docSource }).then(({ data }: any) => {
                try {
                    const decoded = decode(data[0].Attachment);
                    const file = new Blob(
                        [decoded], { type: data[0].MimeType });
                    var a = document.createElement("a");
                    a.href = window.URL.createObjectURL(file);
                    a.download = data[0].Name.trim() + data[0].FileExt.trim();
                    a.click();
                    setShowSuccessMessage(true)
                    setMessage(t('message.success', { ns: 'attachmentsComponent' }))
                    downloadDialog.close()
                    setTimeout(function () {
                        setShowSuccessMessage(false)
                    }, 5000);
                } catch (error) {
                    setShowErrorMessage(true)
                    setMessage(t('message.error', { ns: 'common', Error: error }))
                    setTimeout(function () {
                        setShowErrorMessage(false)
                    }, 5000);
                }
            })

        }
        download()
    }

    return (
        <>

            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className={contentStyles.body}>
                        <ScrollablePane>
                            <div className="scrollVisible" style={{ overflow: "auto", overflowX: "hidden", marginRight: "5px", height: "50vh", paddingLeft: "10px" }}>
                                {!isLoading ?
                                    <>
                                        {
                                            <DetailsList
                                                items={attachments}
                                                compact={false}
                                                columns={isMobile ? _columns.filter((value: any) => value.name !== "Private" && value.name !== 'Required') : _columns}
                                                layoutMode={DetailsListLayoutMode.justified}
                                                isHeaderVisible={true}
                                                selectionMode={SelectionMode.single}
                                                selectionPreservedOnEmptyClick={false}
                                                enterModalSelectionOnTouch={mQuery.matches}
                                                checkboxVisibility={CheckboxVisibility.hidden}
                                            />
                                        }
                                    </>
                                    :
                                    <Stack>
                                        <Label />
                                        <Label />
                                        <Label />
                                        <Spinner size={SpinnerSize.medium} label={spinnerText} ariaLive="assertive" />
                                    </Stack>
                                }
                            </div>
                        </ScrollablePane>
                    </div>
                </div>
            </div>


            <deleteDialog.Dialog title={t('title.modalDelete', { ns: 'attachmentsComponent' })} titleId='deleteModal' actionText={t('Common.Button.Delete', { ns: 'common' })}>
                <div className={contentStyles.dialogMessage}>
                    {t('text.confirmDelete', { ns: 'attachmentsComponent' })}
                </div>
            </deleteDialog.Dialog>
            <downloadDialog.Dialog title={t('title.modalDownload', { ns: 'attachmentsComponent' })} titleId='Confirmation' actionText={t('Common.Button.Yes', { ns: 'common' })}>
                <div className={contentStyles.dialogMessage}>
                    {t('text.confirmDownload', { ns: 'attachmentsComponent' })}
                </div>
            </downloadDialog.Dialog>
        </>
    )
}
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    button: {
        height: '28px',
        maxWidth: '50px',
        fontSize: '12px'
    },

    dialogMessage: {
        padding: '0px 24px 12px 24px',
    },
    body: {
        width: isMobile ? null : '60vw',
        flex: '4 4 auto',
        padding: '0',
        height: '50vh',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
        transition: 'all .3s ease-out'
    },
    User: {
        width: isMobile ? null : '30vw',
        height: '30vh',
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
    bodyDelete: {
        width: isMobile ? null : '30vw',
        height: '15vh',
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
    callout: {
        width: 222,
        padding: '10px 10px',
    },
});
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },

};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};

export default AttachmentDocument