import {
    FontWeights,
    getTheme,
    IconButton,
    IIconProps,
    IMessageBarStyles,
    List,
    mergeStyleSets,
    MessageBar,
    Modal,
    ScrollablePane,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
} from "@fluentui/react";
import React, { useState, Dispatch, useEffect } from "react";
import {useBranding} from "../../hooks/useBranding";

const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

const stackTokens = { childrenGap: 50 };

const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac",
    },
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5",
    },
};

export interface TestIntegrationModalProps {
    content: any;
    showModal: boolean;
    hideModal: () => void;
    setModalContent: Dispatch<React.SetStateAction<any>>;
    setIsDisableApprove?: (checked: boolean) => void;
}

function TestIntegrationModal(props: TestIntegrationModalProps) {
    const { branding } = useBranding()
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [message, setMessage] = useState("");

    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 0px 24px",
            },
        ],
        body: {
            width: "35vw",
            height: "50vh",
            flex: "4 4 auto",
            padding: "0 24px 0px 24px",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
        bodyPanel: {
            width: "35vw",
            height: "70vh",
            flex: "4 4 auto",
            padding: "0 24px 0px 24px",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
    });


    useEffect(() => {
        if (props.showModal && props.content !== null) {
            if (
                Array.isArray(props.content) &&
                props.content.filter(
                    (f) => f.StatusCode > 399 || f.StatusCode === -1
                ).length > 0
            ) {
                if (props.setIsDisableApprove !== undefined) {
                    props.setIsDisableApprove(true);
                }
                showErrorMessage("Error when executing the Integration");
            } else if (
                Array.isArray(props.content) ||
                props.content.Success === true ||
                (props.content.StatusCode >= 200 &&
                    props.content.StatusCode <= 299)
            ) {
                if (props.setIsDisableApprove !== undefined) {
                    props.setIsDisableApprove(false);
                }
                showSuccessMessage("Integration executed successfully");
            } else {
                if (props.setIsDisableApprove !== undefined) {
                    props.setIsDisableApprove(true);
                }
                showErrorMessage("Error when executing the Integration");
            }
        }
    }, [props.content, props.showModal]);

    const onClose = () => {
        setLoading(false);
        props.hideModal();
        props.setModalContent(null);
    };

    const showSuccessMessage = (message: string) => {
        setShowSuccess(true);
        setMessage(message);
        setTimeout(function () {
            setShowSuccess(false);
        }, 5000);
    };

    const showErrorMessage = (message: string) => {
        setShowMessage(true);
        setMessage(message);
        setTimeout(function () {
            setShowMessage(false);
        }, 5000);
    };

    return (
        <Modal
            isOpen={props.showModal}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <Stack>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div
                            style={{ padding: "0px" }}
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                        >
                            <div className={contentStyles.header}>
                                <span id="releaseN">
                                    {"Outbound Integration"}
                                </span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={onClose}
                                    title="Close."
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {showMessage ? (
                                <MessageBar styles={messageBarFailedStyles}>
                                    {message}
                                </MessageBar>
                            ) : null}
                            {showSuccess ? (
                                <MessageBar styles={messageBarSuccessStyles}>
                                    {message}
                                </MessageBar>
                            ) : null}
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                {!loading ? (
                                    <ScrollablePane>
                                        <div
                                            className="scrollVisible"
                                            style={{
                                                overflow: "auto",
                                                overflowX: "hidden",
                                                marginRight: "5px",
                                                height: "51vh",
                                                paddingLeft: "10px",
                                            }}
                                        >
                                            <div>
                                                <pre
                                                    style={{
                                                        padding: "10px",
                                                        wordBreak: "break-word",
                                                        whiteSpace: "pre-wrap",
                                                    }}
                                                >
                                                    {Array.isArray(
                                                        props.content
                                                    ) &&
                                                    props.content.filter(
                                                        (f) =>
                                                            f.StatusCode >
                                                                399 ||
                                                            f.StatusCode === -1
                                                    ).length === 0
                                                        ? props.content[0]
                                                              .EventDescription
                                                        : JSON.stringify(
                                                              props.content,
                                                              null,
                                                              2
                                                          )}
                                                </pre>
                                            </div>
                                        </div>
                                    </ScrollablePane>
                                ) : (
                                    <Stack tokens={stackTokens}>
                                        <label />
                                        <label />
                                        <label />
                                        <Spinner
                                            size={SpinnerSize.large}
                                            label="Loading..."
                                        />
                                    </Stack>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <List />
                        </div>
                    </div>
                </div>
            </Stack>
        </Modal>
    );
}

export default TestIntegrationModal;
