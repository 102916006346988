import React from "react";
import { Callout, Checkbox, DelayedRender, IButtonStyles, IColumn, Icon, IconButton, IContextualMenuProps, mergeStyleSets, Text } from "@fluentui/react";
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { useBoolean } from '@uifabric/react-hooks';
import { isMobile } from 'react-device-detect';
import { FILE_ICONS } from '../../Helpers/Utils';
import Helper from "../../Helpers/Helper";
import i18n from "../../i18n/config"

const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
const contentStyles = mergeStyleSets({
    callout: {
        width: 222,
        padding: '10px 10px',
    },
});
const uploadIcon = {
    root: {
        fontSize: 16,
        color: '#0078d4'
    }
}
const getDateTimeDisplaySpan = (item: any) => {
    if (item.DateTime && item.DateTime !== null) {
        return isMobile ?
            <span title={Helper.getLocateDate(item.DateTime, 2)}>{Helper.getLocateDate(item.DateTime, 1)}</span>
            :
            <span title={Helper.getLocateDate(item.DateTime, 2)}>{Helper.getLocateDate(item.DateTime, 4)}</span>
    }
    return '-'
}

export const getAttachmentColumns = (getMenuProps: (item: any) => IContextualMenuProps, ButtonUpload?: any) => {
    const { t } = i18n
    const columns: IColumn[] = [
        {
            key: 'column0',
            name: t('label.fileType', { ns: 'attachmentsComponent'}),
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item) => {
                const _ext = item.Name.split('.').pop().trim()
                const ext = FILE_ICONS.find(e => e.key === _ext)?.icon ?? item.Description?.split('.').pop().trim()
                return (<Icon {...getFileTypeIconProps({ extension: ext, size: 16, imageFileType: 'svg' })} />)
            },
        },
        {
            key: 'column1',
            name: t('label.name', { ns: 'attachmentsComponent'}),
            fieldName: 'Description',
            minWidth: isMobile ? 200 : 150,
            maxWidth: isMobile ? 200 : 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'attachmentsComponent'}),
            sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'attachmentsComponent'}),
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <CalloutColumn item={item} getDateTimeDisplaySpan={getDateTimeDisplaySpan} />
            }
        },
        {
            key: 'column2',
            name: t('label.created', { ns: 'attachmentsComponent'}),
            fieldName: 'DateTime',
            minWidth: isMobile ? 0 : 100,
            maxWidth: isMobile ? 0 : 200,
            isCollapsible: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return getDateTimeDisplaySpan(item);
            },
        },
        {
            key: 'column3',
            name: t('label.username', { ns: 'attachmentsComponent'}),
            fieldName: 'DisplayName',
            minWidth: isMobile ? 0 : 150,
            maxWidth: isMobile ? 0 : 175,
            isCollapsible: true,
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <span title={item.DisplayName}>{item.DisplayName}</span>;
            }
        },
        {
            key: 'column4',
            name: t('label.description', { ns: 'attachmentsComponent'}),
            fieldName: 'Name',
            minWidth: isMobile ? 0 : 150,
            maxWidth: isMobile ? 0 : 300,
            isRowHeader: true,
            isCollapsible: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <span title={item.Name.trim()}>{item.Name.trim()}</span>
            }
        },
        {
            key: 'column5',
            name: t('label.private', { ns: 'attachmentsComponent'}),
            fieldName: 'Visibility',
            minWidth: isMobile ? 0 : 20,
            maxWidth: 20,
            isPadded: true,
            isResizable: false,
            onRender: (item: any) => {
                return <Checkbox disabled={true} checked={item.Visibility === "Private" ? true : false} />
            }
        },
        {
            key: 'column6',
            name: t('label.action', { ns: 'attachmentsComponent'}),
            fieldName: 'Actions',
            minWidth: 70,
            maxWidth: 70,
            isResizable: false,
            onRender: (item: any) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    title={t('label.showAction', { ns: 'attachmentsComponent'})}
                    data={item}
                    aria-haspopup
                    aria-label={t('label.showAction', { ns: 'attachmentsComponent'})}
                    styles={iconListButtonStyles}
                    menuProps={getMenuProps(item)}
                /></div>
            },
            isPadded: true
        },
    ]
    return columns

}
export const getRuleAttachmentColumns = (getMenuProps: (item: any) => IContextualMenuProps, ButtonUpload: any) => {
    const { t } = i18n

    const columns: IColumn[] = [
        {
            key: 'column0',
            name: t('title.fileType', { ns: 'attachmentsComponent'}),
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item) => {
                if (item.Id === -1) {
                    return <Icon styles={uploadIcon} iconName='Upload' />
                } else if (item.AttachmentRuleId !== -1) {
                    const _ext = item.Name.split('.').pop().trim()
                    const ext = FILE_ICONS.find(e => e.key === _ext)?.icon ?? item.Description?.split('.').pop().trim()
                    return (<Icon {...getFileTypeIconProps({ extension: ext, size: 16, imageFileType: 'svg' })} />)
                }
            },
        },
        {
            key: 'column1',
            name: t('label.name', { ns: 'attachmentsComponent'}),
            fieldName: 'Description',
            minWidth: isMobile ? 100 : 130,
            maxWidth: isMobile ? 100 : 180,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: t('label.sortedAsc', { ns: 'attachmentsComponent'}),
            sortDescendingAriaLabel: t('label.sortedDesc', { ns: 'attachmentsComponent'}),
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return item.id !== -1 ? <CalloutColumn item={item} getDateTimeDisplaySpan={getDateTimeDisplaySpan} /> : item.rule
            }
        },
        {
            key: 'column12',
            name: t('label.required', { ns: 'attachmentsComponent'}),
            minWidth: isMobile ? 0 : 70,
            isCollapsible: true,
            maxWidth: isMobile ? 0 : 70,
            
            onRender: (item: any) => {
                return <Checkbox disabled={true} checked={item.rule?.required} />
            }
        },
        {
            key: 'column2',
            name: t('label.created', { ns: 'attachmentsComponent'}),
            fieldName: 'DateTime',
            minWidth: isMobile ? 0 : 100,
            maxWidth: isMobile ? 0 : 200,
            isCollapsible: true,
            
            data: 'string',
            onRender: (item: any) => {
                return getDateTimeDisplaySpan(item);
            },
        },
        {
            key: 'column3',
            name: t('label.username', { ns: 'attachmentsComponent'}),
            fieldName: 'DisplayName',
            minWidth: isMobile ? 0 : 150,
            maxWidth: isMobile ? 0 : 175,
            isCollapsible: true,
            data: 'string',
            onRender: (item: any) => {
                return <span title={item?.DisplayName}>{item?.DisplayName}</span>;
            }
        },
        {
            key: 'column4',
            name: t('label.description', { ns: 'attachmentsComponent'}),
            fieldName: 'Name',
            minWidth: isMobile ? 0 : 150,
            maxWidth: isMobile ? 0 : 300,
            isRowHeader: true,
            isCollapsible: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return <span title={item.Name?.trim()}>{item.Name?.trim()}</span>
            }
        },
        {
            key: 'column5',
            name: t('label.private', { ns: 'attachmentsComponent'}),
            fieldName: 'Visibility',
            minWidth: isMobile ? 0 : 20,
            maxWidth: 20,
            isPadded: true,
            isResizable: false,
            onRender: (item: any) => {
                return <Checkbox disabled={true} checked={item.Visibility === "Private" ? true : false} />
            }
        },
        {
            key: 'column6',
            name: t('label.action', { ns: 'attachmentsComponent'}),
            minWidth: 90,
            maxWidth: 90,
            isResizable: false,
            onRender: (item: any) => {
                if (item.Id === -1) {
                    return <ButtonUpload item={item} />
                } else {
                    return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                        menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                        role="button"
                        title={t('label.showAction', { ns: 'attachmentsComponent'})}
                        data={item}
                        aria-haspopup
                        aria-label={t('label.showAction', { ns: 'attachmentsComponent'})}
                        styles={iconListButtonStyles}
                        menuProps={getMenuProps(item)}
                    /></div>
                }
            },
            isPadded: true
        }
    ]
    return columns

}
const CalloutColumn = ({ item, getDateTimeDisplaySpan }: any) => {
    const { t } = i18n
    const [isCalloutVisibleAttachmentName, { toggle: toggleisCalloutVisibleAttachmentName }] = useBoolean(false);
    var DisplayNamesplit2 = item.DisplayName?.split("(", 2)

    return isMobile ? <>
        <a id={`callout-${item.Id}`} onTouchEnd={toggleisCalloutVisibleAttachmentName}>
            <Text variant="medium">
                {item.Name ?? item?.Description + "\n "}
                {(DisplayNamesplit2?.[0] ?? '') + "\n "}

            </Text>
        </a>
        {isCalloutVisibleAttachmentName && (<Callout
            className={contentStyles.callout}
            target={`#callout-${item.Id}`}
            onDismiss={toggleisCalloutVisibleAttachmentName}
            role="status"
            aria-live="assertive"
        >
            <DelayedRender>
                <Text variant="medium">
                    {item.Name ?? item?.Description} <br />
                    {DisplayNamesplit2?.[0] ?? (item?.required ? <strong>{t('label.required', { ns: 'attachmentsComponent'})}</strong> : '')}<br />
                    {DisplayNamesplit2?.[1] ? `(${DisplayNamesplit2?.[1]})` : ''}<br />
                    {getDateTimeDisplaySpan(item)}
                </Text>

            </DelayedRender>
        </Callout>)}
    </> : <span title={item.Description.trim()}>{item.Description.trim()}</span>
}