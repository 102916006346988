import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import '../../Custom.css';
import {ActionButton, DefaultButton, IconButton} from "@fluentui/react/lib/Button";
import {isMobile} from "react-device-detect";
import RenewalsAndAlertsService, {ResponseRenewalCount} from "./RenewalsAndAlerts.service";
import {AppContext} from "../Provider";
import {contentStyles, iconButtonStyles, nonShrinkingStackItemStyles} from "./Style";
import {Stack, StackItem} from "@fluentui/react";
import {Text} from "@fluentui/react/lib/Text";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import {Separator} from "@fluentui/react/lib/Separator";
import {Modal} from "@fluentui/react/lib/Modal";
import {IIconProps} from "@fluentui/react/lib/Icon";
import RenewalsDetailList from "./Hooks/RenewalsDetailList";
import {useBranding} from '../../hooks/useBranding';
import AuthService from "../../services/auth";
import useAuth from "../../hooks/useAuth";

type Properties = {
    update: any
    selectionState: any
    onUpdateInterval: any
    templateOnlyList: any
    isUpdateInterval: any
}

function RenewalsAndAlertsWorkspace({
                                        onUpdateInterval,
                                        update,
                                        isUpdateInterval,
                                        templateOnlyList,
                                        selectionState
                                    }: Properties) {
    const {branding} = useBranding()
    const {t} = useTranslation(['renewalsalerts', 'common'])
    const [showModal, setShowModal] = useState(false)
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const {context} = useContext(AppContext)
    const { forceAuthenticated } = useAuth();

    useEffect(() => {

        const interval = setInterval(() => {
            GetRenewalCount();
        }, 300000);

        GetRenewalCount()

        return () => {
            clearInterval(interval);
        };

    }, [])

    useEffect(() => {
        if (context.refreshRenewals) {
            GetRenewalCount()
            context.setRefreshRenewals(false)
        }
    }, [context.refreshRenewals])

    /**
     * On Dismiss Renewal
     */
    const onDismiss = () => {
        setShowModal(false)
    }

    const GetRenewalCount = async () => {
        if (await AuthService.isAuthenticateExists()) {
            RenewalsAndAlertsService.getRenewalCount().then(data => {
                try {
                    if (data.success) {
                        const items: ResponseRenewalCount[] = JSON.parse(data.result)
                        if (Array.isArray(items) && items.length > 0) {
                            context.setCountRefreshRenewals(items[0].RenewalCount)

                        }
                    } else {
                        forceAuthenticated()
                    }
                } catch (error) {
                    console.log(error)

                }
            })
        }
    }

    return (
        <>
            {context.countRefreshRenewals > 0 && !isMobile &&

                <ActionButton onClick={() => {
                    setShowModal(true)
                }} style={{
                    backgroundColor: "#fff",
                    fontSize: "18px",
                    fontFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif"
                }} iconProps={{iconName: 'RecurringEvent'}}>{isMobile ? "" : t("title.renewals", {
                    count: context.countRefreshRenewals,
                    ns: 'renewalsalerts'
                })}</ActionButton>
            }

            <Modal
                isOpen={showModal}
                isBlocking={true}
                containerClassName={contentStyles(branding.theme).container}
            >
                {/*-- START HEADER --*/}
                <Stack horizontal className={contentStyles(branding.theme).header}>


                    <StackItem grow={1}
                               styles={{
                                   root: {
                                       textOverflow: "ellipsis",
                                       whiteSpace: "nowrap",
                                       overflow: "hidden"
                                   }
                               }}>
                        <Text variant={"xLarge"}
                              title={t('title.viewRenewal', {
                                  ns: 'renewalsalerts',
                                  count: context.countRefreshRenewals
                              })}>
                            {t('title.viewRenewal', {ns: 'renewalsalerts', count: context.countRefreshRenewals})}
                        </Text>
                    </StackItem>
                    <Stack horizontal styles={{root: {alignItems: "center"}}}>
                        <HelpComponentRelative
                            headline={t('title.help', {ns: 'renewalsalerts'})}
                            text={t('text.help', {ns: 'renewalsalerts', AppName: branding.appName})}
                            location=""
                        />
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                            onClick={() => {
                                setShowModal(false)
                            }}
                            title={t('Common.Button.Close', {ns: 'common'})}
                        />
                    </Stack>
                </Stack>
                {/*-- END HEADER --*/}

                {/*-- START BODY --*/}
                <Stack horizontal tokens={{padding: 8}} style={{overflow: "hidden"}}>

                    {/*-- START CONTENT --*/}
                    <Stack grow={1} tokens={{padding: 8}} style={{overflow: "hidden"}}>

                        <RenewalsDetailList
                            templateId={-1}
                            isUpdateInterval={isUpdateInterval}
                            onUpdateInterval={onUpdateInterval}
                            templateOnlyList={templateOnlyList}
                            update={update}
                            selectionState={selectionState}
                        />

                        {/*-- START FOOTER --*/}
                        <Separator></Separator>
                        <Stack horizontal style={{overflow: "hidden"}} styles={nonShrinkingStackItemStyles}>

                            <StackItem grow={1}>&nbsp;</StackItem>
                            <StackItem>
                                <DefaultButton onClick={onDismiss} style={{marginLeft: 8}}
                                               text={t('Common.Button.Close', {ns: 'common'})}
                                               title={t('Common.Button.Close', {ns: 'common'})}/>
                            </StackItem>
                        </Stack>
                        {/*-- START FOOTER --*/}

                    </Stack>
                    {/*-- END CONTENT --*/}

                </Stack>
                {/*-- END BODY --*/}

            </Modal>


        </>

    )
}

export default RenewalsAndAlertsWorkspace
