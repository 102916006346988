import { decode } from "base64-arraybuffer";

export const useDownload = () => {
    const downloadFile = (fileBase64: string, fileName: string, mimeType: string) => {
        const decoded = decode(fileBase64)

        const file = new Blob(
            [decoded], { type: mimeType });
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }
    return { downloadFile }
}