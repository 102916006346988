import Helper from "../Helpers/Helper"
import HttpRequest from "./HttpRequest";

const WebFormCampaignService = {

    getCampaignUserDetails: async (request: getCampaignUserDetailsRequest) => {

        const _request = {
            guid: request.guid
        }

        return await HttpRequest.getWithSession('GetCampaignDetailGUID', _request)
            .then((response: any) => {
                return response.data
            })
            .catch((error) => {
                throw new Error(Helper.GetErrorFromResponse(error))
            })
            .finally(() => {
            })
    },
    getCampaignUserDetailInformationByUsernameCampaignId: async (request: getCampaignUserDetailInformationByUsernameCampaignIdRequest) => {

        const _request = {
            DetailUsername: request.detailUsername,
            CampaignMasterId: request.campaignMasterId
        }

        return await HttpRequest.getWithSession('GetCampaignDetailInformationByUsernameCampaignId', _request)
            .then((response: any) => {
                return response.data
            })
            .catch((error) => {
                throw new Error(Helper.GetErrorFromResponse(error))
            })
            .finally(() => {
            })
    },
    getCampaign: async (request: getCampaignRequest) => {
        const _request = {
            Id: request.Id
        }

        let {data}: any = await HttpRequest.getWithSession('GetCampaignById', _request)
        return data
    },
    campaignAccessCode: async (request: campaignAccessCodeRequest) => {

        const _request = {
            AccessCode: request.AccessCode,
            Guid: request.Guid,
            Id: request.Id,
            UseCache: false,
            ResetCode: request.ResetCode
        }

        return await HttpRequest.getWithSession('CampaignAccessCode', _request)
            .then((response: any) => {
                return response.data
            })
            .catch((error) => {
                throw new Error(Helper.GetErrorFromResponse(error))
            })
            .finally(() => {
            })
    },
    
}

interface getCampaignUserDetailsRequest {
    path: string;
    guid: string;
    autoprovision: boolean;
}

interface getCampaignUserDetailInformationByUsernameCampaignIdRequest {
    path: string;
    autoprovision: boolean;
    detailUsername: string;
    campaignMasterId: string;
}

interface getCampaignRequest {
    UserName: string;
    SessionKey: string;
    AccountId: string;
    Id: string;
}

interface campaignAccessCodeRequest {
    Path: string;
    Guid: string;
    AccessCode: string;
    ResetCode: boolean;
    Id: number|string;
}

export default WebFormCampaignService