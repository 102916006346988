import React, { useEffect, useState } from 'react'
import { useModal } from '../../hooks/useModal'
import { DefaultButton, mergeStyleSets } from '@fluentui/react'
import { isMobile } from 'react-device-detect'
import { Sizes } from '../../share/CustomModal'
import { useTranslation } from 'react-i18next'

type QueueModalInterface = {
    close: Function,
    Modal: any,
    isUpdate: boolean,
    isLink: boolean,
    isError: boolean,
}

const QueueModal = (props: QueueModalInterface) => {
    const { close, Modal, isUpdate, isLink, isError } = props;
    const [ message, setMessage ] = useState("");
    const [ title, setTitle ] = useState("");
    const { t } = useTranslation(['queueModal']);

    const contentStyles = mergeStyleSets({
        body: {
            flex: "4 4 auto",
            overflowY: "hidden",
            width: "100%",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
    });

    useEffect(() => {
        if (isError) {
            setMessage("text.bodyError")
            setTitle("title.error")
            return
        }

        if (isUpdate) {
            setTitle("title.modalUpdate")
            if (isLink) {
                setMessage("text.bodyUpdateLink")
            return
            }
            setMessage("text.bodyUpdate")
            return
        } else {
            setTitle("title.modalCreate")
            if (isLink) {
                setMessage("text.bodyCreateLink")
                return 
            }
            setMessage("text.bodyCreate")
            return
        }
      
    }, [isUpdate, isLink, isError])
    


  return (
    <Modal title={t(title, { ns: "queueModal" })} titleId='' size={Sizes.sm}>
                        <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className={contentStyles.body}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <br />
                                                    <div style={{ textAlign: "center" }}>
                                                        <p>{t(message, { ns: "queueModal" })}</p>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ paddingBottom: "10px", textAlign: "center" }}>
                                            <DefaultButton onClick={() => close()} text={t("text.btnClose", { ns: "queueModal" })} title={t("title.btnClose", { ns: "queueModal" })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </Modal>
  )
}

export default QueueModal