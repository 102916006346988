import {
    ContextualMenuItemType,
    GroupHeader,
    IContextualMenuProps,
    IGroupHeaderProps,
    Stack,
    StackItem,
    Text
} from "@fluentui/react";
import * as React from "react";
import {Theme} from "@fluentui/react-theme-provider";
import {countStyles, groupHeaderStyles, onRenderCellStyles} from "./Style"
import {FontWeights} from "@fluentui/react/lib/Styling";
import i18n from 'i18next';
import {IRule} from "../CustomTemplates/CustomForm/RHFControls/RHFUploadRunTimeControl";
import {IFormControl} from "../../Helpers/Helper";

export interface IDocumentOnlyTemplateIDRequest {
    templateId: string
    ignoreDocument: boolean
    DocSource?: string
}

export interface IDocumentRequest {
    TemplateId: string
    TargetCulture: string;
    Content: IFormControl[];
    DocumentId: string;
    Lists: string;
    TemplateXML: string;
    Rules?: IRule[]
}

export interface IPreviewResponse {
    Success: boolean
    UrlPath: string
    mimeType: string
}

export interface ITemplateItem {
    AllowCheckInOut: boolean
    CoOwner: boolean
    CreateDocument: boolean
    DocumentOnlyAudienceId: number
    Owner: boolean
    SubscriptionStatusMessage: string
    activeSubscription: boolean
    audienceName: string
    category: string
    culture: string
    cultureName: string
    defaultDescription: string
    FormDesignObject: any,
    documentCreation: boolean
    documentDelete: boolean
    documentShare: boolean
    documentUpdate: boolean
    emailMoniker: string
    key: string
    name: string
    ownerFullName: string
    ownerUsername: string
    released: boolean
    templateDelete: boolean
    templateEdit: boolean
    templateRemove: boolean
    templateShare: boolean
    title: string
    url: string
    ImageInfo: string
}

/**
 *
 */
export interface IApplication {
    links: ITemplateItem[]
    name: string
}

export interface IExternalLinks {
    WebformLink : string
    AccountId: number
    TemplateId: number
    Category: string
    DisplayName: string
    Description: string 
}

export interface IExternalLinksRequest {
    Username: string
}

/**
 * Enum Sort
 */
export enum ESort {
    ascending,
    descending
}

export const normalizeString = (value: string) => {
    return value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
};

/**
 * On render Title
 * @param theme
 * @param props
 */
const onRenderTitle = (theme: Theme, props?: IGroupHeaderProps): JSX.Element | null => {
    return (
        <>
            <StackItem grow={1} styles={{
                root: {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "1px"
                }
            }}>
                <Text variant={'mediumPlus'} title={props?.group?.name}
                      styles={{root: {fontWeight: FontWeights.semibold}}}>
                    {props?.group?.name}
                </Text>
            </StackItem>
        </>
    )
}

/**
 * On render header
 * @param theme
 * @param _applications
 * @param propsGroup
 */
export const onRenderHeader = (theme: Theme, propsGroup?: IGroupHeaderProps): JSX.Element | null => {
    const onToggleSelectGroup = () => {
        propsGroup?.onToggleCollapse!(propsGroup.group!);
    }

    return (
        <GroupHeader
            key={propsGroup?.group?.name}
            styles={groupHeaderStyles(theme, propsGroup?.group)}
            onRenderTitle={props => {
                return onRenderTitle(theme, props)
            }} onGroupHeaderClick={onToggleSelectGroup}
            {...propsGroup}
            onToggleSelectGroup={onToggleSelectGroup}
        />
    )
}

/**
 * onRenderCell of the Group List
 * @param callbackItemClick
 * @param theme
 * @param nestingDepth
 * @param item
 */
export const onRenderCell = (
    callbackItemClick: (item: IExternalLinks) => void,
    theme: Theme,
    nestingDepth?: number,
    item?: IExternalLinks,
    _applications?: IExternalLinks[],
    _filter?: string
): React.ReactNode => {
    return item ? (
                        <Stack key={item.TemplateId} onClick={() => {
                            callbackItemClick(item)
                        }} styles={onRenderCellStyles(theme)}>
                            <Text variant={"mediumPlus"} styles={{ root: { color: theme.palette.black } }}
                                block>{item.DisplayName}</Text>
                            <Text variant={"medium"} block>{item.Description}</Text>
                        </Stack>
    )
    : null
}

/**
 * compare Function
 * @param property
 * @param sort
 */
export function compareFn<T extends Object>(
    property: keyof T,
    sort: ESort
) {
    return (a: T, b: T) => {
        const val1 = a[property];
        const val2 = b[property];
        const order = sort !== ESort.descending ? 1 : -1;

        switch (typeof val1) {
            case "number": {
                const valb = val2 as unknown as number;
                const result = val1 - valb;
                return result * order;
            }
            case "string": {
                const valb = val2 as unknown as string;
                const result = val1.localeCompare(valb);
                return result * order;
            }
            default:
                return 0;
        }
    };
}

/**
 * Command bar menu Props
 * @param sortCategories
 * @param sortCategoriesFn
 * @param sortApplications
 * @param sortApplicationsFn
 */
export const menuProps = (sortCategories: ESort, sortCategoriesFn: (sort: ESort) => void): IContextualMenuProps => {
    return {
        items: [
            {
                key: 'menu1',
                title: i18n.t("label.categories", {ns: "common"}),
                text: i18n.t("label.categories", {ns: "common"}),
                itemType: ContextualMenuItemType.Header
            },

            {
                key: 'ascending',
                text: i18n.t("label.ascending", {ns: "common"}),
                iconProps: {iconName: sortCategories === ESort.ascending ? "StatusCircleCheckmark" : undefined},
                onClick: (ev) => {
                    sortCategoriesFn(ESort.ascending)
                    ev?.preventDefault()
                }
            },
            {
                key: 'descending',
                text: i18n.t("label.descending", {ns: "common"}),
                iconProps: {iconName: sortCategories === ESort.descending ? "StatusCircleCheckmark" : undefined},
                onClick: (ev) => {
                    sortCategoriesFn(ESort.descending)
                    ev?.preventDefault()
                }
            },
        ]
    }
}

