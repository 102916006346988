import React, {Dispatch, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Sizes} from "../../share/CustomModal";
import {useModal} from "../../hooks/useModal";
import {DefaultButton, mergeStyleSets, Separator, Stack, StackItem} from "@fluentui/react";
import StatusMessage, {StatusEnum} from "../../share/StatusMessage";

type Properties = {
    showModal: boolean
    setShowModal: Dispatch<React.SetStateAction<boolean>>
    imageBase64: string | undefined
    imageProperties: any | undefined
}
const RHFUploadControlPreview = ({imageBase64, setShowModal, showModal, imageProperties}: Properties) => {

    const {t} = useTranslation(['common', 'emailtemplate'])
    const myModal = useModal()
    const myModalPreview = useModal()
    const [message, setMessage] = useState("")
    const [statusMessageEnum, setStatusMessageEnum] = useState<StatusEnum>(StatusEnum.Info)
    const [showMessage, setShowMessage] = useState(false)


    useEffect(() => {
        if (showModal) {
            myModal.open()
            onLoad().then()
        }
    }, [showModal])

    /**
     * Close modal
     */

    const closeModal = () => {
        myModal.close()
        setShowModal(false)
    }

    const onLoad = async () => {
        // await initLoad().then()
    }


    const onCLosePreview = () => {
        myModalPreview.close()
    }


    const _styles = mergeStyleSets({
        body: {
            flex: '4 4 auto',
            padding: 0,
        }
    })

    /**
     * show Status Message Pivot 1
     * @param message
     * @param status
     */
    const showStatusMessage = (message: string, status: StatusEnum) => {
        setMessage(message)
        setStatusMessageEnum(status)
        setShowMessage(true)
    }


    return (
        <>
            {/*region MODAL TEMPLATE*/}
            <myModal.Modal
                title={"Text modal"} titleId="Text modal" size={Sizes.sm}
                afterClose={() => {
                    closeModal()
                }}
                bodyClassName={_styles.body}
            >
                <>
                    {/*region MESSAGES*/}
                    <StackItem>
                        {showMessage &&
                            <>
                                <StatusMessage status={statusMessageEnum} hasTimer={false}
                                               truncated={true}
                                               isMultiline={true}
                                               dismiss={true}
                                               setShowMessage={setShowMessage}>
                                    <div>{message}</div>
                                </StatusMessage>
                            </>
                        }
                    </StackItem>
                    {/*endregion*/}


                    {/*region CONTENT*/}
                    <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                           style={{
                               overflow: "hidden",
                               overflowY: "auto",
                               height: "50vh",
                               padding: "16px 16px 24px 16px"
                           }}>


                        {/*region CONTENT FORM*/}

                        {/*endregion*/}
                    </Stack>
                    {/*endregion*/}

                    {/*region FOOTER*/}
                    <Separator styles={{root: {marginBottom: -8}}}></Separator>
                    <Stack horizontal style={{overflow: "hidden"}} tokens={{childrenGap: 8}} disableShrink styles={{
                        root: {
                            padding: 12,
                            overflow: 'hidden',
                            alignItems: "center",
                        },
                    }}>
                        <StackItem grow={1}>&nbsp;</StackItem>

                        <StackItem>

                            <DefaultButton
                                onClick={() => {
                                    closeModal()
                                }}
                                text={t('Common.Button.Close', {ns: 'common'})}
                                title={t('Common.Button.Close', {ns: 'common'})}/>
                        </StackItem>
                    </Stack>
                    {/*endregion*/}


                </>
            </myModal.Modal>
            {/*endregion*/}


        </>
    )
}

export default RHFUploadControlPreview