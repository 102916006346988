import { useCallback, useEffect, useState } from "react"
import { deleteIdleTime } from "./useIdle"
import { getTokenClaims, isInTeams } from "../Helpers/Utils"
import axios from "axios"
import HttpRequest from "../services/HttpRequest"
import { useHistory } from "react-router-dom"
import { useAppSourceContext } from "./useAppSourceContext"

const useAuth = () => {
    const [customAuth0, setCustomAuth0] = useState<{ loginWithRedirect: (redirectURI: string) => void }>({
        loginWithRedirect: (redirectURI: string) => { }
    })
    const { isAuthenticated, user, authenticateUser, isAuthLoaded, setIsAuthenticated, setIsAuthLoaded } = useAppSourceContext()

    const history = useHistory()

    useEffect(() => {
        if (!isAuthenticated) {
            loadIsAuthenticated()
        }
    }, [isAuthenticated])

    const loadIsAuthenticated = async () => {
        try {
            const { params, headers } = await getTokenHeaders()

            const { data: user } = await axios.get("isAuthenticated", {
                headers: headers
            })

            const loginWithRedirect = (redirectURI: string) => {
                redirectToLogin(redirectURI)
            }

            const isAuthenticated =
                params?.RequireAuth0 === "true"
                    ? user.email !== null
                    : user.email !== null || params.RequireAuth0 !== "true"
            if (user.token !== null) {
                sessionStorage.setItem("x-identity", user.token)
            }
            if (user.pkey !== null) {
                sessionStorage.setItem("pkey", user.pkey)
            }
            setCustomAuth0({ loginWithRedirect })
            //setIsAuthLoaded(isAuthenticated)
            authenticateUser(user)
            setIsAuthenticated(isAuthenticated)

        } catch (error) {
            if (window.location.href === window.location.origin + "/") {
                history.push("/")
                return
            }
            if (!isInTeams()) {
                redirectToLogin(window.location.href)
            } else {
                window.location.assign(`${window.location.origin}`)
            }
        } finally {
            setIsAuthLoaded(true)
        }
    }

    const forceAuthenticated = () => {
        loadIsAuthenticated()
    }

    const redirectToLogin = (redirectURI: string) => {
        window.location.assign(`${window.location.origin}/login?redirectURI=${redirectURI}`)
    }
    const getTokenHeaders = async () => {
        const url = new URL(window.location.href)
        const token = url.searchParams.get("token")
        const params = getTokenClaims(token!)
        const headers = token === null ? await HttpRequest.getAxiosHeaders() : await HttpRequest.getAxiosHeaders({ "Authorization": `Bearer ${token}` })
        return { params, headers }
    }

    const _logout = useCallback((redirectToKim: boolean) => {
        if (isInTeams())
            return

        const func = sessionStorage.getItem("func")
        const documentId = sessionStorage.getItem("documentId")
        const importExternalPackage = sessionStorage.getItem("importExternalPackage")
        const importExamples = sessionStorage.getItem("importExamples")

        sessionStorage.clear()

        if (func !== null && documentId !== null && Number(documentId) !== NaN) {
            sessionStorage.setItem("func", func)
            sessionStorage.setItem("documentId", documentId)
        }

        if (importExternalPackage !== null) {
            sessionStorage.setItem("importExternalPackage", importExternalPackage)
        }

        if (importExamples !== null) {
            sessionStorage.setItem("importExamples", importExamples)
        }

        deleteIdleTime()
        if (redirectToKim) {
            window.location.assign("https://kimdocument.com")
        } else {
            history.push(`/logout`)
        }

    }, [history])

    return {
        ...customAuth0, isAuthenticated, user, logout: () => _logout(false),
        isAuthLoaded, forceAuthenticated, logoutKimDocument: () => _logout(true)
    }
}
export default useAuth