import HttpRequest from "../../services/HttpRequest";
import {IDocumentOnlyAudienceDetailRequest, IHttpClientResponseBag} from "../../Helpers/Helper";

export const AudiencesService = {

    /**
     * GET AudienceRolesAvailabilityByNewUser
     * @param request
     */
    GetAudienceRolesAvailabilityByNewUser: async (request: IDocumentOnlyAudienceDetailRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.getWithSession('AudienceRolesAvailabilityByNewUser', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },

    /**
     * POST CreateDocumentOnlyAudienceDetail
     * @param request
     * @constructor
     */
    PostCreateDocumentOnlyAudienceDetail: async (request: IDocumentOnlyAudienceDetailRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.getWithSession('CreateDocumentOnlyAudienceDetail', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (data === undefined) reject({error: 'Internal Server error'})
            resolve(data)
        })
    },
    
}

