import {IStackStyles, mergeStyleSets} from "@fluentui/react";
import {Theme} from "@fluentui/react-theme-provider";
import {FontSizes} from "@fluentui/react/lib/Styling";

export const nonShrinkingStackItemStyles: IStackStyles = {
    root: {
        padding: 12,
        overflow: 'hidden',
        alignItems: "center",
    },
}

export const _styles = (theme:Theme) => {
    return mergeStyleSets({
        body: {
            flex: '4 4 auto',
            padding: 0,
        },
        activity: {
            padding: '8px',
            borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
            selectors: {
                '&:hover': {background: theme.semanticColors.listItemBackgroundHovered},
            },
            alignItems: "center"
        },
        icon: {
            fontSize: FontSizes.size18
        }
    })
}
