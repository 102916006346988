import React, { ComponentType, FC, useEffect, useState } from 'react'
import AuthCode from './AuthCode'

const WithAuthCode = <P extends any>(Component: ComponentType<P>): FC<P> => (props) => {
    const isAccessCodeValid = sessionStorage.getItem("isAccessCodeValid") as string
    //if (isAccessCodeValid) {
    //    return <Component {...props} />
    //} else {
    return (
        <>
            <AuthComponent isAccessCodeValid={isAccessCodeValid} Component={Component} {...props} />
        </>
    )
    //}
}

const AuthComponent: React.FC<any> = ({ Component, isAccessCodeValid, ...props }) => {

    const [authCode, setAuthCode] = useState(isAccessCodeValid);

    const [accessValid, setAccessValid] = useState(false)

    useEffect(() => {
        console.log('authCode')
    }, [])
    return <Component {...props} isAccessCodeValid={accessValid} setIsAccessCodeValid={setAccessValid} authCode={authCode} setAuthCode={setAuthCode}>
        {!accessValid && <AuthCode authCode={authCode} setAccessValid={setAccessValid} setAuthCode={setAuthCode} location={{
            state: {
                referrer: `${window.location.pathname}${window.location.search}`,
                data: window.location.search,
                href: window.location.href
            }
        }} />
        }
    </Component>
}
export default WithAuthCode