import { IDropdownOption } from '@fluentui/react';
import moment from 'moment-with-locales-es6';

const DEFAULT_LOCALE = "en-US"
const DEFAULT_FORMAT = 'LL'
export const DEFAULT_FORM_FORMAT = 'MMMM DD, YYYY'

/**
 * @deprecated since version 5.12.2
 */
const getCurrentDateString = (dateFormat: string): string => {
    return moment().format(dateFormat.length > 0 ? dateFormat : DEFAULT_FORMAT)
}
/**
 * @deprecated since version 5.12.2
 */
const getDateFormated = (dateString: string, format: string, locale: string = DEFAULT_LOCALE): string => {

    const tempDateString = moment(dateString).local().locale(locale).format(format.length > 0 ? format : DEFAULT_FORMAT)
    const tempDateDate = moment(tempDateString, format.length > 0 ? format : DEFAULT_FORMAT, locale)
    if (tempDateDate.isValid()) {
        return tempDateString
    } else {
        return ""
    }
}
const setGlobalDateLocale = (locale: string) => {
    moment.locale(locale)
}
const getDateFromFormat = (dateString: string, format: string = DEFAULT_FORMAT, locale: string = DEFAULT_LOCALE) => {
    format = format.trim()?.length === 0 ? DEFAULT_FORMAT : format
    const iso = Date.parse(dateString)
    if (!isNaN(iso))
        return moment(iso).local().locale(locale).format()

    const _date = moment(dateString, format, locale)
    if (_date.isValid()) {
        return _date.local().locale(locale).format()
    }
    return ''
}
const getDateWithFormat = (date: Date | undefined | string, format: string, locale: string = DEFAULT_LOCALE) => {
    if (date === undefined) {
        return ''
    }

    if ((format === null || format === "") && date !== undefined) {
        const tempDateString = moment(date).local().locale(locale).format(DEFAULT_FORMAT)
        const tempDateDate = moment(tempDateString, DEFAULT_FORMAT, locale)
        if (tempDateDate.isValid()) {
            return tempDateString
        } else {
            return ''
        }
    }

    const tempDateString = moment(date).local().locale(locale).format(format)
    const tempDateDate = moment(tempDateString, format, locale)
    if (tempDateDate.isValid()) {
        return tempDateString
    } else {
        return ''
    }
}

const getTodaysDate = (dateFormat: string, locale: string = DEFAULT_LOCALE): string => {
    if (datePickerFormats.find(f => f.key === dateFormat) === undefined) {
        return moment().local().locale(locale).format(DEFAULT_FORMAT)
    } else {
        return moment().local().locale(locale).format(dateFormat)
    }
}
const getTodaysISODate = (locale: string = DEFAULT_LOCALE): string => {
    return moment().local().locale(locale).format()
}
const getISODateString = (date: Date | null | undefined, locale: string = DEFAULT_LOCALE): string => {
    if (date === undefined || date === null)
        return ''
    if (!moment(date).isValid())
        return ''
    return moment(date).local().locale(locale).format()
}
const parseISOToDate = (date: string) => {
    const _date = moment(date)
    if (_date.isValid()) {
        return _date.toDate()
    }
    return undefined
}
const formatSimpleDate = (date: any, format = 'lll', locale?: string) => {
    if (locale) {
        return moment(date).local().locale(locale).format(format)
    }
    return moment(date).local().format(format)
}
const formatFromUTC = (date: any, format = 'lll', locale?: string) => {
    if (locale) {
        return moment.utc(date).local().locale(locale).format(format).toString()
    }
    return moment.utc(date).local().format(format).toString()
    //return moment.utc(item).local().format('lll')
    //moment.utc(Date.now()).local().format('lll').toString(),
    //moment.utc(item.DateCreated).local().format('lll')
}
type Units = 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds'
const diffDates = (firstDate: Date, secondDate: Date, unit: Units = 'days'): number => {
    return moment(firstDate).diff(moment(secondDate), unit)
}

const datePickerFormats: IDropdownOption[] = [
    { key: DEFAULT_FORM_FORMAT, text: moment().local().format(DEFAULT_FORM_FORMAT) },
    { key: "YYYY-MM-DD", text: moment().local().format("YYYY-MM-DD [e.g. year-month-day]") },
    { key: "YYYY MMMM", text: moment().local().format("YYYY MMMM") },
    { key: "YYYY MMM", text: moment().local().format("YYYY MMM") },
    { key: "YYYY", text: moment().local().format("YYYY") },
    { key: "MMMM YYYY", text: moment().local().format("MMMM YYYY") },
    { key: "MMM YYYY", text: moment().local().format("MMM YYYY") },
    { key: "MMMM", text: moment().local().format("MMMM") },
    { key: "MMM", text: moment().local().format("MMM") },
    { key: "dddd, DD MMMM YYYY", text: moment().local().format("dddd, DD MMMM YYYY") },
    { key: "dddd, DD MMM YYYY", text: moment().local().format("dddd, DD MMM YYYY") },
    { key: "ddd, DD MMM YYYY", text: moment().local().format("ddd, DD MMM YYYY") },
    { key: "dddd", text: moment().local().format("dddd") },
    { key: "ddd", text: moment().local().format("ddd") },
    { key: "DD MMMM YYYY", text: moment().local().format("DD MMMM YYYY") },
    { key: "DD MMM YYYY", text: moment().local().format("DD MMM YYYY") },
    { key: "DD MMMM", text: moment().local().format("DD MMMM") },
    { key: "DD MMM", text: moment().local().format("DD MMM") },
    { key: "MM/DD/YYYY", text: moment().local().format("MM/DD/YYYY [e.g month/day/year]") },
    { key: "MM-DD-YYYY", text: moment().local().format("MM-DD-YYYY [e.g month-day-year]") },
    { key: "DD/MM/YYYY", text: moment().local().format("DD/MM/YYYY [e.g day/month/year]") },
    { key: "DD-MM-YYYY", text: moment().local().format("DD-MM-YYYY [e.g day-month-year]") }
]

export {
    setGlobalDateLocale,
    getCurrentDateString,
    getDateFormated,
    getDateWithFormat,
    datePickerFormats,
    getTodaysDate,
    getTodaysISODate,
    getDateFromFormat,
    getISODateString,
    formatFromUTC,
    formatSimpleDate,
    parseISOToDate,
    diffDates
}