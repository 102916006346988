import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { IconButton, PrimaryButton, DefaultButton, IButtonStyles, ActionButton } from '@fluentui/react/lib/Button';
import HelpComponent from '../Help/HelpComponent'
import { Separator } from '@fluentui/react/lib/Separator';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { getTheme, mergeStyleSets, FontWeights } from '@fluentui/react/lib/Styling';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { TextField } from '@fluentui/react/lib/TextField';
import '../../Custom.css'
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import Helper, { IDocumentOnlyAudienceDetail, IDocumentOnlyAudienceDetailRequest, IDocumentOnlyAudienceRequest } from '../../Helpers/Helper';
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import {
    Checkbox,
    DetailsListLayoutMode,
    IContextualMenuItem,
    IContextualMenuProps,
    IMessageBarStyles,
    Label,
    MessageBar,
    MessageBarType,
    SelectionMode,
    Spinner,
    SpinnerSize,
    StackItem
} from '@fluentui/react';
import { MarqueeSelection, Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import { Modal } from "@fluentui/react/lib/Modal"
import { useConstCallback } from '@uifabric/react-hooks';
import Audiences from '../Settings/Audiences';
import NewAudience from '../Settings/NewAudience';
import ReassignRecords from '../Settings/ReassignRecords';
import { formatFromUTC, setGlobalDateLocale } from '../../Helpers/DateUtils';
import {useTranslation} from "react-i18next";
import {useBranding} from "../../hooks/useBranding";
import AudiencesAddUser from "../Audiences/";

const theme = getTheme();
const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
            class: 'ms-Icon ms-Icon--Mail'
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const stackTokens = { childrenGap: 50 };

function ShareTemplate(props: any) {
    const { branding } = useBranding()
    const {t} = useTranslation(['common'])
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [message, setMessage] = useState("");
    const [audiences, setAudiences] = useState<IDocumentOnlyAudienceDetail[]>([]);
    const [selectedAudience, setSelectedAudience] = useState<IDropdownOption>();
    const [ValueAudience, setValueAudience] = useState("");
    const [audienceOptions, setAudienceOptions] = useState<IDropdownOption[]>([]);
    const [audienceUsers, setAudienceUsers] = useState<IDocumentOnlyAudienceDetail[]>([]);
    const [selectedAudienceUser, setSelectedAudienceUser] = useState<IDocumentOnlyAudienceDetail>();
    const [selectionAudienceUser] = useState(new Selection({
        onSelectionChanged: () => {
            const selectionCount = selectionAudienceUser.getSelectedCount();
            switch (selectionCount) {
                case 1: {
                    setSelectedAudienceUser(selectionAudienceUser.getSelection()[0] as IDocumentOnlyAudienceDetail)
                }
            }
        }
    }))
    const [userEmail, setUserEmail] = useState("")
    const [isUserModalVisible, setIsUserModalVisible] = useState(false)
    const [isSavingEditUSer, setIsSavingEditUSer] = useState(false)
    const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(false)
    const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false)
    const [roleDocumentcreator, setRoleDocumentcreator] = useState(false)
    const [roleCoOwner, setRoleCoOwner] = useState(false)
    
    const [validForm, setValidForm] = useState(false)
    const [isChanging, setIsChanging] = useState(false)
    const [modalAudienceOpen, setModalAudienceOpen] = useState(false)

    const [audienceMasterIdUser, setaudienceMasterIdUser] = useState(0)
    const [showNewAudience, setShowNewAudience] = useState(false)
    const [modalTitle, setModalTitle] = useState("")

    const [isReassignRecordsVisible, setIsReassignRecordsVisible] = useState(false)
    const [messageDelete, setMessageDelete] = useState("");
    const [showMessageDelete, setShowMessageDelete] = useState(false)
    const [showMessageErrorDelete, setshowMessageErrorDelete] = useState(false)
    const [applying, setApplying] = useState(false);
   
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
        body: {
            width: '60vw',
            height: '60vh',
            flex: '4 4 auto',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },

        User: {
            width: '30vw',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyDelete: {
            width: '30vw',
            height: '15vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        bodyVWOverride: {
            width: '90%',
            height: '30vh',
            flex: '4 4 auto',
            padding: '0 0px 0px 18px',
        },
        bodyMail: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            minHeight: 500,

        },
        containerMail: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '50vw'
        },
    });


    const iconListButtonStyles: Partial<IButtonStyles> = { root: { float: 'right', height: 'inherit' } };
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            className: "ms-Icon ms-Icon--Contact",
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: '',
            iconName: 'ContactInfo',
            isIconOnly: true,
            isResizable: false,
            minWidth: 10,
            maxWidth: 10
        },
        {
            key: 'column2',
            name: 'Username',
            fieldName: 'UserName',
            minWidth: 300,
            maxWidth: 400,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return <span title={item.FullName.trim().length === 0 ? item.UserName : item.UserName + " (" + item.FullName + ")"}>{item.FullName.trim().length === 0 ? item.UserName : item.UserName + " (" + item.FullName + ")"}</span>;
            },

        },
        {
            key: 'column7',
            name: 'Date',
            fieldName: 'DateCreated',
            minWidth: 75,
            maxWidth: 145,
            isResizable: true,
            data: 'number',
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return <span title={getRecordDateTitle(item)}>{item.DateCreated !== null ? formatFromUTC(item.DateCreated) : null}</span>;
            },
            isPadded: true,

        },
        {
            key: 'column3',
            name: 'Owner',
            fieldName: 'Owner',
            minWidth: 40,
            maxWidth: 40,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return item.Owner ? <Checkbox checked disabled={true} /> : <Checkbox checked={false} disabled={true} />;
            },
        },
        {
            key: 'column4',
            name: 'Co-owner',
            fieldName: 'CoOwner',
            minWidth: 40,
            maxWidth: 40,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return item.CoOwner ? <Checkbox checked disabled={true} /> : <Checkbox checked={false} disabled={true} />;
            },
        },
        {
            key: 'column5',
            name: 'Create Document',
            fieldName: 'CreateDocument',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return item.CreateDocument ? <Checkbox disabled={true} checked title="Member has create document right granted." /> : <Checkbox disabled={true} checked={false} title="Member does not have create document right granted." />;
            },
        },
        {
            key: 'column6',
            name: 'Action',
            fieldName: 'Actions',
            minWidth: 20,
            maxWidth: 20,
            isResizable: false,
            onRender: (item: IDocumentOnlyAudienceDetail) => {
                return <div style={{ width: '40px', display: 'block', textAlign: 'right' }}><IconButton
                    menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 20, color: '#242424' } }}
                    role="button"
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconListButtonStyles}
                    menuProps={item.Owner === true ? menuFilterProps : menuProps}
                    title="Click to select from available actions."
                /></div>
            },
            isPadded: true
        },
    ]

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'delete',
            text: 'Delete',
            onClick: () => {
                setIsDeleteUserModalVisible(true);
            },
        },
        {
            key: 'edit',
            text: 'Edit',
            onClick: () => {
               
                setIsSavingEditUSer(false)
                setRoleDocumentcreator(false)
                setRoleCoOwner(false)
                setIsEditUserModalVisible(true);
                if (selectedAudienceUser?.CoOwner)
                    setRoleCoOwner(true)
                if (selectedAudienceUser?.CreateDocument)
                    setRoleDocumentcreator(true)
                setValidForm(true)
                if(selectedAudienceUser?.CoOwner || selectedAudienceUser?.CreateDocument) setValidForm(false)
                
                
            },
        },
        {
            key: 'reassignRecords',
            text: 'Reassign Records',
            onClick: () => {
                setIsReassignRecordsVisible(true);

            },
        },
    ];

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems,
    };
    const menuFilterProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems.filter(f => f.key != "delete")
    };
    const getRecordDateTitle = (item: IDocumentOnlyAudienceDetail) => {

        let result;

        item.DateCreated !== null ? result = "Created-" + formatFromUTC(item.DateCreated) : result = "Created-not available";

        return result;
    }

    const loadAudience = async () => {

        const req: IDocumentOnlyAudienceRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceMasterId: "-1",
            FromRegistration: false
        }
        await Helper.GetDocumentOnlyAudienceDetailByUserName(req).then(data => {
            setAudiences(data)
            setAudienceOptions(data.map<IDropdownOption>(item => {
                let textAudience = item.Name + " - " + item.Description
                return { key: item.DocumentOnlyAudienceMasterId, text: textAudience }
            }))
            let selected = data.filter(x => x.DocumentOnlyAudienceMasterId == props.DocumentOnlyAudienceId)
            if (selected != undefined && selected.length > 0) {
                let itemText = selected[0].Name
                if (selected[0].Name.trim().toLowerCase() === "default") {
                    if (selected[0].Owner) {
                        itemText = itemText + " (" + selected[0].UserName + "(" + selected[0].FullName + ")" + ")"
                    }
                    else {
                        itemText = itemText + (selected[0].OwnerFullName !== null ? " (" + selected[0].OwnerUser + "(" + selected[0].OwnerFullName + ")" + ")" : "")
                    }
                }
                else {
                    itemText = itemText + " (" + selected[0].Description + " )"
                }

                let sel: IDropdownOption = {
                    key: selected[0].DocumentOnlyAudienceMasterId,
                    text: itemText//selected[0].Name
                }
                loadUsers(sel)
            }
        })
    }

    const getKey = (item: IDocumentOnlyAudienceDetail, index?: number): string => {
        if (item !== undefined)
            return item.Id.toString()
        return ""
    }

    const loadUsers = async (option?: IDropdownOption) => {
        setSelectedAudience(option);
        setValueAudience(option?.text!)
        const req: IDocumentOnlyAudienceRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AvoidCache: true,
            AudienceMasterId: option?.key.toString() || "-1",
            FromRegistration: false
        }
        await Helper.GetDocumentOnlyAudienceDetailByAudienceMasterId(req).then(data => {
            setAudienceUsers(data)
        })
    }

    const onAudienceChange = async (event?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        loadUsers(option).then()
        setIsChanging(true)
    };

    const hideAudienceModal = () => {
        setAudiences([])
        setSelectedAudience({ key: "", text: "" })
        setAudienceOptions([])
        setAudienceUsers([])
        setSelectedAudienceUser(undefined)
        setUserEmail("")
        setIsUserModalVisible(false)
        setIsDeleteUserModalVisible(false)
        setIsEditUserModalVisible(false)
        setValidForm(false)
        setIsChanging(false)
        selectionAudienceUser.setAllSelected(false)
        props.hideModal()
    }


    /**
     * Let the parent to manage the IsUserModalVisible state
     * @param value
     */
    const onHideAddUserModal = (value:boolean) => {
        setIsUserModalVisible(value)
    }
    
    /**
     * on Dismiss Add user modal
     * @param update
     */
    const onDismissAddUser = async (update: boolean = false) => {
        if (update) {

            const _url = window.location.origin
            const site = await Helper.getSite()!
            const sessionKey = Helper.getSessionKey()
            const accountId = Helper.getDefaultAccount()
            const userName = Helper.getUsername()

            if (props.templateShare) {
                Helper.SendNotification({
                    ActionByEmailAddress: userName,
                    ActionDate: formatFromUTC(Date.now(), "ll", 'en'),
                    AudienceName: props.audienceName,
                    AudienceOwnerEmailAddress: props.ownerUser,
                    RecordDescription: props.templateName,
                    TargetOfActionEmailAddress: userEmail,
                    TemplateCategory: props.templateCategory,
                    TemplateName: props.templateName,
                    Type: "6",
                    Culture: props.culture,
                    UserName: userName,
                    SK: sessionKey,
                    AccountId: accountId,
                    Path: site,
                    Autoprovision: true,
                    ApiKey: "-1",
                    EmailMoniker: props.emailMoniker,
                    TargetSite: _url,
                    TemplateId: props.TemplateId.toString()
                }).then(()=> {
            
                })
               
            }
            
            onAudienceChange(undefined, selectedAudience)
        }
    }
    
    const showUserModal = () => {
        setIsUserModalVisible(true)
    }

    const hideDeleteModal = () => {
        selectionAudienceUser.setAllSelected(false)
        setShowMessageDelete(false)
        setshowMessageErrorDelete(false)
        setMessageDelete("")
        setIsDeleteUserModalVisible(false)
    }

    const hideEditModal = () => {
        setUserEmail("")
        setIsEditUserModalVisible(false)
        selectionAudienceUser.setAllSelected(false)
        setValidForm(false)
    }

    const editUser = async () => {
        setValidForm(true)
        setIsSavingEditUSer(true)
        const owner = audienceUsers.find(item => item.Owner)

        const req: IDocumentOnlyAudienceDetailRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceDetailId: selectedAudienceUser?.Id.toString(),
            AudienceMasterId: selectedAudienceUser?.DocumentOnlyAudienceMasterId.toString(),
            Coowner: roleCoOwner,
            TargetUserName: selectedAudienceUser?.UserName,
            CreateDocument: roleDocumentcreator,
            AudienceOwnerName: owner?.UserName,
            TemplateId: props.TemplateId.toString()
        }
        await Helper.UpdateDocumentOnlyAudienceDetail(req).then(data => {
            if (data.Success) {
                onAudienceChange(undefined, selectedAudience)
                hideEditModal()
            } else {
                setMessage(data.ExceptionMessage)
                setShowErrorMessage(true)
                setShowSuccessMessage(false)
                setTimeout(function () {
                    onAudienceChange(undefined, selectedAudience)
                    setMessage("")
                    setShowErrorMessage(false)
                    setShowSuccessMessage(false)
                    hideEditModal()
                }, 5000);
            }
        }).finally(() => {
            setIsSavingEditUSer(false)
        });
    }

    const deleteUser = async () => {
        setApplying(true)
        const req: IDocumentOnlyAudienceDetailRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            AudienceDetailId: selectedAudienceUser?.Id.toString(),
            AudienceMasterId: selectedAudienceUser?.DocumentOnlyAudienceMasterId.toString(),
            Coowner: selectedAudienceUser?.CoOwner,
            TargetUserName: selectedAudienceUser?.UserName,
            CreateDocument: selectedAudienceUser?.CreateDocument,
            TemplateId: props.TemplateId.toString()
        }
        await Helper.DeleteDocumentOnlyAudienceDetail(req).then(data => {
            if(data !== null) {
                if (data.Success) {
                    setShowMessageDelete(true)
                    setshowMessageErrorDelete(false)
                    setMessageDelete("User" + selectedAudienceUser?.UserName + " has been deleted.")

                    setTimeout(function () {
                        onAudienceChange(undefined, selectedAudience)
                        hideDeleteModal()
                    }, 3000);
                } else {
                    
                    setShowMessageDelete(false)
                    setshowMessageErrorDelete(true)
                    setMessageDelete(data.ExceptionMessage)


                }
                
            }
        }).finally(() => {
            setApplying(false)
            setTimeout(function () {
                hideDeleteModal()
            }, 3000);
        });
    }

    useEffect(() => {
        setGlobalDateLocale(navigator.language)
        if (props.showShare) {
            if (audienceOptions.length == 0) {
                loadAudience().then();

            }
        }
    }, [props.showShare])

    const toggleAudienceModal = useConstCallback((open: boolean) => {
        setModalAudienceOpen(open)
    })
    const closeNewAudience = async () => {
        setModalTitle("")
        setShowNewAudience(false)
    }
  
    function goToEditAudience() {
        setModalTitle("Edit")
        setaudienceMasterIdUser(selectedAudience?.key! as number)
        setShowNewAudience(true)
    }
    return (
        <>
            <NewAudience
                modalTitle={modalTitle}
                showNewAudience={showNewAudience}
                closeNewAudience={closeNewAudience}
                audienceMasterIdUser={audienceMasterIdUser}
            />
            <Modal allowTouchBodyScroll={false}
                isOpen={props.showModal}
                containerClassName={contentStyles.container}
                isBlocking={true}>
                <Stack tokens={stackTokens}>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.header}>
                                    <span>{props.modalTitle}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideAudienceModal}
                                        title="Close."
                                    />
                                    <HelpComponent
                                        headline="Application's Audience"
                                        text="Applications may be shared with other users. 
Shared application members are granted rights to manage applications and records and/or create documents and records.
To share an application, select Add User link and enter user's email address and send.
Shared application members are sent an email requesting their audience.
Shared application members may be removed from an application audience at anytime."
                                        right="50px"
                                        top="15px"
                                        location="applicationlibrary"
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{ left: "13px" }}>
                                <TextField label="Audience" required disabled value={ValueAudience} title={ValueAudience} />
                            </div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1" style={{ paddingTop: "20px", left: "10px", top: "5px" }}>
                                <ActionButton title="See branding information" iconProps={{ iconName: 'Info' }} onClick={goToEditAudience} />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ActionButton title="Add User" text="Add User" disabled={selectedAudience?.key == ""} onClick={showUserModal} iconProps={{ iconName: 'AddFriend' }} />
                            </div></div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.body}>

                                    <ScrollablePane>
                                        {
                                            <Stack tokens={stackTokens}>
                                                <MarqueeSelection selection={selectionAudienceUser}>
                                                    <DetailsList
                                                        items={audienceUsers}
                                                        compact={false}
                                                        columns={columns}
                                                        getKey={getKey}
                                                        setKey="set"
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        isHeaderVisible={true}
                                                        selection={selectionAudienceUser}
                                                        selectionMode={SelectionMode.single}
                                                        selectionPreservedOnEmptyClick={false}
                                                        enterModalSelectionOnTouch={true}
                                                        checkboxVisibility={CheckboxVisibility.hidden}
                                                    />
                                                </MarqueeSelection>
                                            </Stack>
                                        }
                                    </ScrollablePane>
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    {/*<PrimaryButton text="Save" disabled={!isChanging} />*/}
                                    <DefaultButton onClick={hideAudienceModal} text="Close" title="Close." />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal >
            
            <AudiencesAddUser
                showModal={isUserModalVisible}
                setShowModal={onHideAddUserModal}
                audienceMasterId={selectedAudience?.key.toString()}
                templateId={props.TemplateId.toString()}
                onDismissModal={onDismissAddUser}
            />
            
            <Modal
                isOpen={isDeleteUserModalVisible}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id="deleteModal">Delete User</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideDeleteModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.bodyDelete}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                {!applying ?
                                                    <> <div className="ms-Grid-row">
                                                        <Label>Are you sure you want to delete the User {selectedAudienceUser?.UserName}?</Label>
                                                    </div>
                                                        <div key="row" className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                {showMessageErrorDelete && <MessageBar messageBarType={MessageBarType.error}>{messageDelete} </MessageBar> }
                                                                {showMessageDelete && <MessageBar styles={messageBarSuccessStyles}>{messageDelete}</MessageBar> }
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <Spinner size={SpinnerSize.medium} label={t('text.spinnerUpdating', { ns: 'common'})} ariaLive="assertive" />}
                                                <DialogFooter>
                                                    <PrimaryButton onClick={deleteUser} text="Delete" title="Delete audience member." />
                                                    <DefaultButton onClick={hideDeleteModal} text="Cancel" title="Close." />
                                                </DialogFooter>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isEditUserModalVisible}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={contentStyles.header}>
                                    <span id="updateModal">Edit User</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={hideEditModal}
                                        title="Close."
                                    />
                                </div>
                                <Separator />
                            </Stack>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                {showSuccessMessage ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.User}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className="ms-Grid-row">
                                                    <TextField label="Email" defaultValue={selectedAudienceUser?.UserName} disabled maxLength={250} />
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <br />
                                                    <Checkbox id="coowner" checked={roleCoOwner} label="Co-owner"
                                                              onChange={(ev, checked) => {
                                                                  setRoleCoOwner(checked!)
                                                                  if (checked || roleDocumentcreator) setValidForm(false)
                                                                  else {
                                                                      setValidForm(true)
                                                                  }
                                                              }}
                                                              
                                                              title="Grant rights to manage application." />
                                                    <br />
                                                    <Checkbox id="documentcreator" checked={roleDocumentcreator} label="Create Document"
                                                              onChange={(ev, checked) => {
                                                                  setRoleDocumentcreator(checked!)
                                                                  if (checked || roleCoOwner) setValidForm(false)
                                                                  else {
                                                                      setValidForm(true)
                                                                  }
                                                              }}                                                              
                                                              title="Grant right to create documents and records." />
                                                </div>
                                                {isSavingEditUSer &&
                                                    <>
                                                        <StackItem>
                                                            <Spinner size={SpinnerSize.medium} labelPosition="left"
                                                                     label={t('text.spinnerUpdating', {ns: 'common'})}/>
                                                        </StackItem>
                                                    </>
                                                }
                                                <DialogFooter>                                                                                                  
                                                    <PrimaryButton onClick={editUser} disabled={validForm} text="Update" title="Update user selected rights." />
                                                    <DefaultButton onClick={hideEditModal} text="Cancel" title="Close." />
                                                </DialogFooter>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={modalAudienceOpen}
                isBlocking={true}
                containerClassName={contentStyles.containerMail}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.header}>
                            <span id="myAudience">My Audiences</span>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={() => {
                                    toggleAudienceModal(false)
                                }}
                                title="Close."
                            />
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={contentStyles.bodyMail}>
                            <div className="ms-Grid" >
                                <Audiences
                                    toggleSettings={props.toggleSettings}
                                    siteOptions={props.siteOptions}
                                    updateSiteSelected={props.updateSiteSelected}
                                    siteSelected={Helper.getSiteSelected()}
                                    accountId={Helper.getDefaultAccount()}
                                    username={Helper.getUsername()}
                                    sessionKey={Helper.getSessionKey()}
                                    fromModal={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <DialogFooter>
                            <DefaultButton onClick={() => { toggleAudienceModal(false) }} text="Close" title="Close." />
                        </DialogFooter>
                    </div>
                </div>
            </Modal>
            <ReassignRecords
                audiences={audienceUsers}
                selectedAudienceUser={selectedAudienceUser?.UserName}
                setIsReassignRecordsVisible={setIsReassignRecordsVisible}
                isReassignRecordsVisible={isReassignRecordsVisible}
                audienceId={props.DocumentOnlyAudienceId}
                audienceOptions={audienceUsers.map<IDropdownOption>(item => {
                    const textAudience = item.Name + " - " + item.UserName
                    return { key: item.UserName, text: textAudience }
                }).filter(f => f.key != selectedAudienceUser?.UserName)}
            />
        </>
    )
}

export default ShareTemplate