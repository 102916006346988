import {FontWeights, getTheme, mergeStyleSets} from "@fluentui/react/lib/Styling";
import {IIconProps} from "@fluentui/react/lib/Icon";
import {Theme} from "@fluentui/react-theme-provider";

const theme = getTheme();

export interface IContentStyles {
    container: string
    header: string
}

export const contentStyles = (theme: Theme): IContentStyles => {

    return mergeStyleSets({
        container: {
            display: 'block',
            maxWidth: 1024,
            width: 1024,
            minWidth: 320,
            height: "auto",
            minHeight: 259
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ]
    })
};

export const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};

export const cancelIcon: IIconProps = {iconName: 'Cancel'};