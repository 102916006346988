import { useContext } from "react";
import { EnumDictionary } from "../Components/CustomTemplates/CustomForm/Models/EnumDictionatyType";
import { FunctionType } from "../Components/CustomTemplates/CustomForm/Models/ValidationType";
import Helper from "../Helpers/Helper";
import { customAppContext } from "../context/AppContext";

export const useDocument = () => {
    const { setProfile, profile } = useContext(customAppContext)

    const GetDisplayName = (params: string) => {
        if (params !== "") {
            let valLC = params.toLowerCase();
            const includesGetDisplayName = valLC.includes("getdisplayname");
            try {
                let option = includesGetDisplayName
                    ? params
                        .toLowerCase()
                        .split("getdisplayname(")[1]
                        .split(")")[0]
                    : "";
                if (option !== undefined && option !== "") {
                    switch (option) {
                        case "full":
                            var fullName = "";
                            if (profile?.FirstName !== undefined) {
                                fullName = profile?.FirstName;
                            }

                            if (profile?.LastName !== undefined) {
                                fullName += " " + profile?.LastName;
                            }
                            return fullName.trim();
                        case "first":
                            if (profile?.FirstName !== undefined) {
                                return profile?.FirstName;
                            }
                            return "";
                        case "last":
                            if (profile?.LastName !== undefined) {
                                return profile?.LastName;
                            }
                            return "";
                        default:
                            if (profile?.FirstName !== undefined) {
                                return profile?.FirstName;
                            }
                            return "";
                    }
                } else {
                    if (profile?.FirstName !== undefined) {
                        return profile?.FirstName;
                    }
                    return "";
                }
            } catch (error) {
                return includesGetDisplayName
                    ? profile?.FirstName !== undefined ? profile?.FirstName : ""
                    : params;
            }
        }
    };
    const textboxFunctions = (ft: FunctionType, params: string): string => {
        const tFunctions: EnumDictionary<FunctionType, string> = {
            [FunctionType.GetUserName]: sessionStorage.getItem("campaignTargetUser") !== null ? sessionStorage.getItem("campaignTargetUser")! : profile?.Email ?? '',
            [FunctionType.GetDisplayName]: GetDisplayName(params)!,
        };
        return tFunctions[ft];
    };
    const getTextboxValue = (text: string): string => {
        let nameFunction = text.includes("DisplayName")
            ? text.split("(")[0]
            : text.replace("()", "");
        let typeValue = nameFunction as unknown as FunctionType;
        let ftKey =
            FunctionType[typeValue] !== undefined &&
                FunctionType[typeValue] !== null
                ? Number(FunctionType[typeValue])
                : -1;
        return ftKey !== undefined && ftKey > -1
            ? textboxFunctions(ftKey as FunctionType, text)
            : text;
    };
    return { GetDisplayName, textboxFunctions, getTextboxValue, profile, setProfile }
}