import { Stack } from '@fluentui/react';
import React from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFNumberControl } from '../../../RHFControls/RHFNumberControl';
import { ILayout } from '../../CustomTemplate';
import CommonProperties from './CommonProperties';

export interface TextboxPropertiesProps {
    prefix: string;
    layout: ILayout;
    isIntegration?: boolean
}

const TextBoxProperties = (props: TextboxPropertiesProps) => {

    const { control, setValue, getValues, trigger } = useFormContext();

    useEffect(() => {
        if (props.layout !== undefined) {
            loadOutboundIntegrationValues()
        }
    }, [props.layout])


    const loadOutboundIntegrationValues = () => {
        setValue(`${props.prefix}_MaxLength` as any, props.layout.Validations.MaxLength);
    }
    return (
        <>
            <CommonProperties
                layout={props.layout}
                prefix={props.prefix}
                displayAdminOnly={false}
                displayColumnSpan={false}
                displayLock={false}
                displayReadOnly={false}
                displayRequired={true}
                disableFieldType={true}
                isIntegration={props.isIntegration}
            />
            <Stack>
                <RHFNumberControl
                    id={`${props.prefix}_MaxLength`}
                    label={"Max Length"}
                    title={"Enter maximum number of characters allowed."}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                />
            </Stack>
        </>)
}

export default TextBoxProperties