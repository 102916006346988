import React, {useState} from "react";
import {EditorValue} from "react-rte";
import {
    Checkbox,
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IComboBoxStyles,
    IDropdownOption,
    Stack,
    StackItem
} from "@fluentui/react";
import {EditorState, Modifier} from "draft-js";
import {useTranslation} from "react-i18next";


export interface ContentControlsprops {
    onRenderOption?: (option?: IDropdownOption) => JSX.Element;
    onChange?: (EditorValue: EditorValue) => void;
    options?: IComboBoxOption[];
    editorValue: EditorValue;
}

function ContentControls({onRenderOption, onChange, editorValue, options}: ContentControlsprops) {

    const [selectedKey, setSelectedKey] = useState("0")
    const [includeText, setIncludeText] = useState(true)

    const {t} = useTranslation(['renewalsalerts', 'common'])

    const customStyles: Partial<IComboBoxStyles> = {

        root: {
            maxWidth: '640px',
        },
        input: {},
        callout: {},
        optionsContainerWrapper: {
            maxWidth: '640px',
        }
    };

    return (
        <>

            <Stack wrap horizontal styles={{root: {maxWidth: 640}}}>
                <StackItem>
                    <ComboBox
                        placeholder={t('placeholder.comboBoxInsertCC', {ns: 'renewalsalerts'})}
                        styles={customStyles}
                        options={options !== undefined ? options : []}
                        onRenderOption={onRenderOption}
                        selectedKey={selectedKey}
                        iconButtonProps={{iconProps: {iconName: "Add"}}}
                        allowFreeform={false}
                        autoComplete={'off'}
                        onItemClick={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
                            const editorState = editorValue.getEditorState()

                            const contentState = Modifier.replaceText(
                                editorState.getCurrentContent(),
                                editorState.getSelection(),
                                includeText ? `${option!.text.toString()}` : `${option!.key.toString()}`,
                                editorState.getCurrentInlineStyle()
                            );
                            setSelectedKey("0")
                            if (onChange) {

                                onChange(EditorValue.createFromState(EditorState.push(editorState, contentState, 'insert-characters')))
                            }
                        }}
                    />
                </StackItem>
                <StackItem styles={{root: {paddingLeft: 8, paddingTop: 8}}}>
                    <Checkbox checked={includeText}
                              onChange={(ev, checked) => {
                                  setIncludeText(checked!)
                              }}
                              styles={{root: {}}}
                              label={t('label.checkboxIncludetext', {ns: 'renewalsalerts'})}/>
                </StackItem>
            </Stack>


        </>
    )
}

export default ContentControls;
