import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Sizes} from "../../share/CustomModal";
import {useModal} from "../../hooks/useModal";
import {_styles, Content, nonShrinkingStackItemStyles} from "./index";
import {DefaultButton, Separator, Stack, StackItem} from "@fluentui/react";

type Properties = {
    showModal: boolean
    setShowModal: (status: boolean) => void
}
const MyBillingPlan = ({setShowModal, showModal}: Properties) => {

    const {t} = useTranslation(['common', 'workspace'])
    const myModal = useModal()

    useEffect(() => {
        if (showModal) {
            myModal.open()
        }
    }, [showModal])

    /**
     * Close modal
     */
    const closeModal = () => {
        myModal.close()
        setShowModal(false)
    }

    return (
        <>
            {/*region MODAL TEMPLATE*/}
            <myModal.Modal
                title={t('text.myBillingPlan', {ns: 'workspace'})} titleId="MyBillingPlan" size={Sizes.smPlus}
                afterClose={() => {
                    closeModal()
                }}
                bodyClassName={_styles.body}
                additionalHeader={undefined}
            >
                <>
                    {/*region CONTENT*/}
                    <Stack grow={1} className="scrollVisible" data-is-scrollable="true"
                           style={{
                               overflow: "hidden",
                               overflowY: "auto",
                               height: "60vh",
                               padding: "16px 16px 24px 16px"
                           }}>
                        <Content showContent={showModal}/>
                    </Stack>
                    {/*endregion*/}

                    {/*region FOOTER*/}
                    <Separator styles={{root: {marginBottom: -8}}}></Separator>
                    <Stack horizontal style={{overflow: "hidden"}} disableShrink styles={nonShrinkingStackItemStyles}>
                        <StackItem grow={1}>&nbsp;</StackItem>
                        <StackItem>
                            <DefaultButton onClick={() => {
                                closeModal()
                            }} style={{marginLeft: 8}}
                                           text={t('Common.Button.Close', {ns: 'common'})}
                                           title={t('Common.Button.Close', {ns: 'common'})}/>
                        </StackItem>
                    </Stack>
                    {/*endregion*/}


                </>
            </myModal.Modal>
            {/*endregion*/}
        </>
    )
}

export default MyBillingPlan