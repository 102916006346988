import React, {useContext, useEffect, useState } from 'react';
import { INavLink } from '@fluentui/react/lib/Nav';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ActionButton, IButtonStyles } from '@fluentui/react/lib/Button';
import Form from '../Form/Form';
import Helper, { IDocumentRequest, IContentControls } from '../../Helpers/Helper';
import FullSettings from '../Settings/FullSettings';
import CustomTemplate from '../CustomTemplates/CustomTemplate';
import '../../Custom.css'
import { IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { Label } from '@fluentui/react/lib/Label';
import PreviewScreen from '../Preview/PreviewScreen'
import TemplateList from '../TemplateList/TemplateList';
import { CommandButton } from '@fluentui/react/lib/Button';
import MyTemplate from '../CustomTemplates/MyTemplate';
import { Text } from '@fluentui/react/lib/Text';
import { store } from 'react-notifications-component';
import { useConstCallback } from '@uifabric/react-hooks';
import { Coachmark } from '@fluentui/react/lib/Coachmark';
import { TeachingBubbleContent } from '@fluentui/react/lib/TeachingBubble';
import { DirectionalHint } from 'office-ui-fabric-react';
import {
    IContextualMenuProps, Link,
    Stack,
    StackItem,
    Icon,
    Separator
} from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import ReleaseNotes from './ReleaseNotes';
import ImportTemplate from "../ImportTemplate/ImportTemplate";
import ImportExternalPackage from "../ImportExternalPackage/ImportExternalPackage";
import { useTranslation } from 'react-i18next';
import ShowCustomForm from "../CustomTemplates/CustomForm/ShowCustomForm";
import MultimediaVideo from "../MultimediaVideo";
import {useBranding} from "../../hooks/useBranding";
import {AppContext} from "../Provider";

const classNames = mergeStyleSets({
    wrapper: {
        height: window.innerHeight > 778 ? '80vh' : "70vh",
        position: 'relative',
        backgroundColor: 'white',
    },
    pane: {
        maxWidth: '98%',
    },
    settings: {
        selectors: {
            'div': {
                zIndex: 5000,
            }
        },
    },
    version: {
        float: 'right',
        marginTop: '5px',
        marginRight: '15px',
    },
    addButtonProps: {
        color: 'red'
    }
});


function AccountList(props: any) {
    const { branding } = useBranding()
    const sites: IChoiceGroupOption[] = []
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [modalId, setModalId] = useState("")
    const [layout, setLayout] = useState("")
    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const [spinnerText, setSpinnerText] = useState("")
    const [showSettings, setShowSettings] = useState(false)
    const [siteOptions, setSiteOptions] = useState(sites)
    const [siteSelected, setSiteSelected] = useState("")
    const [showPreview, setShowPreview] = useState(false)
    const [previewURL, setPreviewURL] = useState("")
    const [previewMime, setPreviewMime] = useState("")
    const [showFormBuilder, setShowFormBuilder] = useState(false)
    const [showUploadDocModal, setUploadDocModal] = useState(false)
    const [myTemplateOption, setMyTemplateOption] = useState("1")
    const [contentControls, setContentControls] = useState<IContentControls[]>()
    const [templateId, setTemplateId] = useState("")
    const [culture, setCulture] = useState("")
    const [templateName, setTemplateName] = useState("")
    const [selectedTemplate, setSelectedTemplate] = useState(undefined)
    const [isUpdate, setIsUpdate] = useState(false)
    const [showMyFolder, setshowMyFolder] = useState(false)
    const [reloadNav, setReloadNav] = useState(false)
    const [newTemplate, setNewTemplate] = useState(false)
    const [showImportTemplate, setShowImportTemplate] = useState(false)
    const [showImportExternalPackage, setShowImportExternalPackage] = useState(false)
    const [showRecordRenewal, setShowRecordRenewal] = useState(false)
    const [documentId, setDocumentId] = useState(0)


    const targetButtonC = React.useRef<HTMLDivElement>(null);
    const targetButtonT = React.useRef<HTMLDivElement>(null);
    const targetButtonA = React.useRef<HTMLDivElement>(null);
    const targetButtonS = React.useRef<HTMLDivElement>(null);

    const [showReleaseNotes, setShowReleaseNotes] = useState(false)
    const [profileEdited, setProfileEdited] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)

    const [showVideoModal, setShowVideoModal] = useState(false)

    const [IsOwnerTemplate, setIsOwnerTemplate] = useState(false)
    const { t } = useTranslation(['common', 'workspace']);
    const {context} = useContext(AppContext)

    const addButton: IButtonStyles = {
        textContainer: {
            display: 'inline-grid'
        },
        root: {
            width: '100%',
            marginTop: 16
        },
        label: {
            fontWeight: 'normal',
            fontSize: "18px",
            fonFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif",
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        flexContainer: {
        }
    }

    const addButtonProps: IButtonStyles = {
        label: {
            fontWeight: 'normal',
            fontSize: "18px",
            fonFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif",
            color: "#dfdfdf"
        },
        root: {
            marginTop: 16
        }
    }

    const menuCreateOptions: IContextualMenuProps = {
        items: [
            {
                key: 'importApplication',
                text: t("text.importApplication", { ns: 'workspace' }),
                iconProps: { iconName: 'Upload' },
                onClick: () => setShowImportTemplate(true),
                style: { fontSize: "16px", fontFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif" }
            }
        ],
        shouldFocusOnMount: false
    };


    /**
     * Use showSettings context to show the Settings window and specific ID menu from another component
     */
    useEffect(() => {
        if (context.showSettings !== undefined) {
            setShowSettings(true)
        }
    }, [context.showSettings])

    
    const onCreateUpdate = (id: string) => {
        setModalId(id)
        setLayoutLoaded(false)
        setShowPreview(true)
        setModalTitle(t('title.documentPreview', { ns: 'workspace' }))
        setSpinnerText(t('text.spinner', { ns: 'common' }))

        const preview = async () => {
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id,
                IsPreview: true,
                IsDownload: false,
                IsDocx: false
            }
            var res = await Helper.getDocumentPreview(req)

            if (res.Success) {
                setLayoutLoaded(true)
                setPreviewURL(res.UrlPath)
                setPreviewMime(res.mimeType)
            }
        }

        props.update()
        preview()
        if (props.selectionState.getSelectedIndices().length >= 1)
            props.selectionState.setAllSelected(false);
    }

    const hidePreview = () => {
        setShowPreview(false)
        setLayoutLoaded(false)
        setSpinnerText(t('text.spinner', { ns: 'common' }))
        setModalId("")
        setModalTitle("")
    }

    function showNotification(Nmessage: string, Ntype: string) {
        switch (Ntype) {
            case "success":
                store.addNotification({
                    title: t('title.success', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "warning":
                store.addNotification({
                    title: t('title.warning', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "error":
                store.addNotification({
                    title: t('title.error', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "info":
                store.addNotification({
                    title: t('title.information', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "successLeft":
                store.addNotification({
                    title: t('title.success', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            case "errorLeft":
                store.addNotification({
                    title: t('title.error', { ns: 'workspace' }),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                break;
            default:
                break;
        }
    }
    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            width: '100%',
            border: 'none',
            height: 30
        },
        textContainer: {
            display: "grid"
        },
        label: {
            color: branding.theme.palette.themePrimary,
            fontSize: "18px",
            fontFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        icon: {
            fontSize: "18px",
            color: branding.theme.palette.themePrimary,
            marginRight: 12
        }
    };

    const iconButton2Styles: Partial<IButtonStyles> = {
        root: {
            width: '100%',
            border: 'none',
            height: 30
        },
        textContainer: {
            display: "grid"
        },
        label: {
            color: "black",
            fontSize: "18px",
            fontFamily: "\"Segoe UI\", \"Segoe UI Web(West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'default'
        },
        icon: {
            fontSize: "18px",
            color: branding.theme.palette.black,
            marginRight: 12
        }
    };

    const hideModal = () => {
        setLayoutLoaded(false)
        setSpinnerText(t('text.spinner', { ns: 'common' }))
        setLayout("")
        setShowModal(false)
    }

    const hideReleaseNotesModal = () => {
        setShowReleaseNotes(false)
    }

    const toggleSettings = useConstCallback((open: boolean) => {

        if(!open) {
            context.setShowSettings(undefined)
        }
        
        const config = Helper.getConfig()
        const site = Helper.getSiteSelected()
        if(config !== null ) {
            const siteOption = []
            siteOption.push({
                key: config.Path,
                text: config.Site
            })
        
            setSiteSelected(site)
            setSiteOptions(siteOption)
            setShowSettings(open)
        
        }
    })

    function hideProfileModal(updateClicked: boolean) {
        if (profileEdited && !updateClicked) {
            setShowSaveModal(true)
        } else {
            setShowSettings(false)
            setShowSaveModal(false)
            setProfileEdited(false)
            toggleSettings(false)
        }
    }

    const toggleFormBuilder = useConstCallback((open: boolean) => {
        const site = Helper.getSiteSelected()
        setSiteSelected(site)
        setShowFormBuilder(open)
    })
    const toggleUploadModal = useConstCallback((open: boolean, item) => {
        const site = Helper.getSiteSelected()
        setSelectedTemplate(item)
        setSiteSelected(site)
        setUploadDocModal(open)
    })

    const hideImportTemplate = () => {
        setShowImportTemplate(false)
    }

    const hideImportExternalpackage = () => {
        setShowImportExternalPackage(false)
    }

    const togglenewtemplate = useConstCallback((open: boolean, item) => {
        setNewTemplate(open)

    })

    const getContentControls = useConstCallback((cc: IContentControls[]) => {
        setContentControls(cc)
    })

    const getTemplateId = useConstCallback((templateId: string) => {
        setTemplateId(templateId)
    })

    const updateSiteSelected = useConstCallback((site: string) => {
        setSiteSelected(site)
    })

    const switchSite = useConstCallback((site: string) => {

        props.reloadApp(site)
        toggleSettings(false)
        sessionStorage.setItem("updateApp", "true")
    })

    useEffect(() => {
        setShowImportExternalPackage(!!sessionStorage.getItem("importExternalPackage"))
    }, [showImportExternalPackage])

    useEffect(() => {

        if (props.isSubscriptionDisabled === true && (IsOwnerTemplate || props.isOwnerDocs == true)) {
            toggleSettings(true)
            props.onOwnerDocument(false)
        }

    }, [props.isSubscriptionDisabled])
 
    /**
     * useEffect set Show RecordRenewal
     */
    useEffect(() => {
        setshowMyFolder(true)
        const func = sessionStorage.getItem("func")
        const documentId = sessionStorage.getItem("documentId")
        let showCustomForm = false
        if(func !== null && !isNaN(Number(documentId))) {
            
            
            if(func === "alertrecord") {
                setShowRecordRenewal(true)
                setDocumentId(Number(documentId))
                showCustomForm = true
            }

            if(func === "documentcreation") {
                setShowRecordRenewal(true)
                setDocumentId(Number(documentId))
                showCustomForm = true
            }

        }

        // if (props.NoDisplayVideoGS == true && !showCustomForm) {
        //     setNoDisplayVideoGS(props.NoDisplayVideoGS)
        //     onVideoInvoke("717236233", t('title.getStarted', { ns: 'workspace' }))
        // }

    }, [])


  

    const openMyTemplate = useConstCallback((item: INavLink) => {
        setIsUpdate(true)
        toggleUploadModal(true, item)
        setNewTemplate(false)
    })

    function goToLoc(loc: string) {

        if (sessionStorage.getItem("entityId") === "Not In Teams") {
            window.open(`${branding.helpPath}#${loc}`, '_blank');
        }
        else {
            props.showHelpTeams(loc)
        }
    }
    function hideCoachmarkC() {
        props.setShowCoachmarkC(false)
    }
    function hideCoachmarkT() {
        props.setShowCoachmarkT(false)
    }
    function hideCoachmarkA() {
        props.setShowCoachmarkA(false)
    }
    function hideCoachmarkS() {
        props.setShowCoachmarkS(false)
    }

    const cancelSaveModal = (option: number) => {
        switch (option) {
            case 1:
                setShowSaveModal(false)
                toggleSettings(true)
                break;
            case 2:
                toggleSettings(false)
                setShowSaveModal(false)
                //setShowProfile(false)
                setProfileEdited(false)
                break;
        }
    }

    const reloadApp = useConstCallback(() => {
        const site = Helper.getSiteSelected()

        props.reloadApp(site)
        sessionStorage.setItem("updateApp", "true")
    })
    
    const hideVideoModal = () => {
        setShowVideoModal(false)
    }
    const onSubscriptionDisabled = () => {

        props.SubscriptionDisabled(false)
    }
    const onSubscriptionSettings = (_IsOwner: boolean) => {
        setIsOwnerTemplate(_IsOwner)
        props.SubscriptionDisabled(true, _IsOwner)
        if (_IsOwner) {
            toggleSettings(true)
        }

    }

    /**
     * onDismiss RecordRenewal
     */
    const onDismissRecordRenewal = (update:boolean = false) => {
        setShowRecordRenewal(false)
        props.update()
    }

    const onIsOwner = (val: boolean) => {
        setIsOwnerTemplate(val)

    }

    const openExamples = useConstCallback(() => {
        setMyTemplateOption("3")
        setIsUpdate(false)
        setNewTemplate(true)
        toggleUploadModal(true, undefined)
    })

    const resetExamples = useConstCallback(() => {
        setMyTemplateOption("1")
    })

    return (
        <>
            <PreviewScreen
                showModal={showPreview}
                hideModal={hidePreview}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                URLPath={previewURL}
                mimeType={previewMime}
            />
            <Form modalId={modalId}
                modalTitle={modalTitle}
                showModal={showModal}
                hideModal={hideModal}
                layout={layout}
                layoutLoaded={layoutLoaded}
                update={onCreateUpdate}
                spinnerText={spinnerText}
                setSpinnerText={setSpinnerText}
                setLayoutLoaded={setLayoutLoaded}
                documentId={-1}
            />
            <CustomTemplate showFormBuilder={showFormBuilder}
                toggleFormBuilder={toggleFormBuilder}
                siteOptions={siteOptions}
                siteSelected={siteSelected}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                reloadApp={switchSite}
                contentControls={contentControls}
                templateId={templateId}
                templateName={templateName}
                isUpdate={isUpdate}
                culture={culture}
            />

            <ReleaseNotes
                showModal={showReleaseNotes}
                hideModal={hideReleaseNotesModal}
            />
            <MultimediaVideo
                showModal={showVideoModal}
                onDismissModal={hideVideoModal}
                modalTitle={t('title.getStarted', {ns: 'workspace'})}
                videoId={"717236233"}
            />

            {props.showCoachmarkC && (
                <Coachmark
                    target={targetButtonC.current}
                    positioningContainerProps={{ directionalHint: DirectionalHint.rightTopEdge }}
                >
                    <TeachingBubbleContent
                        headline={t('title.applications', { ns: 'coachmarks' })}
                        hasCloseButton
                        closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common' })}
                        primaryButtonProps={{
                            children: t('Common.Button.See more info', { ns: 'common' }),
                            title: t('title.coachmarkButton', { ns: 'workspace' }),
                            onClick: () => { goToLoc("applicationlibrary"); },
                        }}
                        onDismiss={hideCoachmarkC}
                    >
                        {t('text.applications1', { ns: 'coachmarks' })}
                    </TeachingBubbleContent>
                </Coachmark>
            )}
            {props.showCoachmarkT && (
                <Coachmark
                    target={targetButtonT.current}
                    positioningContainerProps={{ directionalHint: DirectionalHint.rightTopEdge }}
                >
                    <TeachingBubbleContent
                        headline="Example Applications"
                        hasCloseButton
                        closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common' })}
                        primaryButtonProps={{
                            children: t('Common.Button.See more info', { ns: 'common' }),
                            onClick: () => { goToLoc("applicationlibrary"); },
                        }}
                        onDismiss={hideCoachmarkT}
                    >
                        Examples provided by Kim Technologies.
                    </TeachingBubbleContent>
                </Coachmark>
            )}
            {props.showCoachmarkA && (
                <Coachmark
                    target={targetButtonA.current}
                    positioningContainerProps={{ directionalHint: DirectionalHint.rightBottomEdge }}
                >
                    <TeachingBubbleContent
                        headline={t('title.newApplication', { ns: 'coachmarks' })}
                        hasCloseButton
                        closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common' })}
                        primaryButtonProps={{
                            children: t('Common.Button.See more info', { ns: 'common' }),
                            title: t('title.coachmarkButton', { ns: 'workspace' }),
                            onClick: () => { goToLoc("addnew"); },
                        }}
                        onDismiss={hideCoachmarkA}
                    >
                        {t('text.newApplication1', { ns: 'coachmarks' })}
                        <br /><br />
                        {t('text.newApplication2', { ns: 'coachmarks' })}
                    </TeachingBubbleContent>
                </Coachmark>
            )}
            {props.showCoachmarkS && (
                <Coachmark
                    target={targetButtonS.current}
                    positioningContainerProps={{ directionalHint: DirectionalHint.rightBottomEdge }}
                >
                    <TeachingBubbleContent
                        headline={t('title.settings', { ns: 'coachmarks' })}
                        hasCloseButton
                        closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common' })}
                        primaryButtonProps={{
                            children: t('Common.Button.See more info', { ns: 'common' }),
                            onClick: () => { goToLoc("settings"); },
                        }}
                        onDismiss={hideCoachmarkS}
                    >
                        {t('text.settings1', { ns: 'coachmarks' })}
                        <br /><br />
                        {t('text.settings2', { ns: 'coachmarks' })}
                    </TeachingBubbleContent>
                </Coachmark>
            )}

            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ padding: 0, margin: 0 }}>

                {/* BEGIN HEADER */}

                <Stack style={{ height: "calc(100vh - 50px)", flexDirection: 'column' }}>
                    <StackItem>
                        <div ref={targetButtonC}>
                            {/*DONE*/}
                            {!isMobile && 
                                <>
                                    {branding.showGetStarted &&
                                        <ActionButton style={{marginTop: 16}} iconProps={{iconName: "MSNVideos"}}
                                                      title={t('title.getStarted', {ns: 'workspace'})}
                                                      styles={iconButtonStyles} onClick={() => {
                                            setShowVideoModal(true)
                                        }}>
                                            {t('text.getStarted', {ns: 'workspace'})}
                                        </ActionButton>
                                    }
                                    {branding.showDefaultExamplesSection &&
                                        <ActionButton style={{}} iconProps={{iconName: "AddTo"}}
                                                      title={t('title.examples', {ns: 'workspace'})}
                                                      styles={iconButtonStyles} onClick={openExamples}>
                                            {t('text.examples', {ns: 'workspace'})}
                                        </ActionButton>
                                    }

                                    {(branding.showCustomExamplesLink || branding.showWebformUseCasesLink || branding.showFAQLink) &&

                                        <Stack styles={{root: {marginTop: branding.showGetStarted || branding.showDefaultExamplesSection? 0: 12, marginBottom: 4}}}>
                                            <div ref={targetButtonC}>
                                                {branding.showCustomExamplesLink &&
                                                    <ActionButton href={`${branding.customExamplesLink}`}
                                                                  target={`${branding.customExamplesLinkTarget}`}
                                                                  iconProps={{iconName: "AddTo"}}
                                                                  title={t('title.examples', {ns: 'workspace'})}
                                                                  styles={iconButtonStyles}>
                                                        {t('text.examples', {ns: 'workspace'})}
                                                    </ActionButton>
                                                }
                                                {branding.showWebformUseCasesLink &&
                                                    <ActionButton href={`${branding.webformUseCasesLink}`}
                                                                  target={`${branding.webformUseCasesLinkTarget}`}
                                                                  iconProps={{iconName: "Documentation"}}
                                                                  title={t('title.linkWebformUseCases', {ns: 'workspace'})}
                                                                  styles={iconButtonStyles}>
                                                        {t('text.linkWebformUseCases', {ns: 'workspace'})}
                                                    </ActionButton>
                                                }
                                                {branding.showFAQLink &&
                                                    <ActionButton href={`${branding.faqLink}`}
                                                                  target={`${branding.faqLinkTarget}`}
                                                                  iconProps={{iconName: "BookAnswers"}}
                                                                  title={t('title.linkFAQ', {ns: 'workspace'})}
                                                                  styles={iconButtonStyles}>
                                                        {t('text.linkFAQ', {ns: 'workspace'})}
                                                    </ActionButton>
                                                }
                                            </div>
                                        </Stack>
                                    }                                    
                                </>}
                        </div>

                        <ActionButton style={{}} iconProps={{ iconName: "FabricDocLibrary" }} title={t('title.applications', { ns: 'workspace' })} styles={iconButton2Styles} >
                            {t('text.applications', { ns: 'workspace' })}
                        </ActionButton>

                        <Text variant={"small"} style={{ margin: 8, marginTop: 0, marginBottom: 16 }} block>{t('text.indications', { ns: 'workspace' })}</Text>

                       
                       
                        
                    </StackItem>
                    {/* END HEADER */}


                    {/* BEGIN CATEGORIES LIST */}
                    <StackItem className="scrollVisible" styles={{ root: { flex: 1, overflow: "auto", overflowX: "hidden" } }}>

                        <TemplateList showMyFolder={showMyFolder}
                            siteOptions={siteOptions}
                            reloadNav={reloadNav}
                            contentControls={contentControls}
                            siteSelected={Helper.getSiteSelected()}
                            update={props.update}
                            openMyTemplate={openMyTemplate}
                            templateOnlyList={props.templateOnlyList}
                            reloadApp={reloadApp}
                            selectionState={props.selectionState}
                            isSubscriptionDisabled={props.isSubscriptionDisabled}
                            onSubscriptionSettings={onSubscriptionSettings}
                            onIsOwner={onIsOwner}
                            IsOwnerTemplate={IsOwnerTemplate}
                            onUpdateInterval={props.onUpdateInterval}
                            isUpdateInterval={props.isUpdateInterval}
                                      
                        />

                    </StackItem>
                    {/* END CATEGORIES LISTS */}

                    {/* BEGIN FOOTER */}
                    <StackItem styles={{ root: { backgroundColor: 'white' } }}>
                        <StackItem>
                            <div ref={targetButtonA} className={"ms-fontSize-14 " + classNames.settings}>

                                {isMobile ? null :
                                    <Stack horizontal>
                                        <StackItem grow={1} style={{ width: 100 }}>
                                            <ActionButton id="btnAddTemplate" title={isMobile ? t('title.newApplicationMobile', { ns: 'workspace' }) : t('title.newApplication', { ns: 'workspace' })} text={t('text.newApplication', { ns: 'workspace' })}
                                                iconProps={{ iconName: 'Add' }}
                                                disabled={isMobile}
                                                styles={addButton}
                                                onClick={() => {
                                                    setIsUpdate(false)
                                                    setNewTemplate(true)
                                                    toggleUploadModal(true, undefined)
                                                }} />
                                        </StackItem>
                                        <StackItem>
                                            <CommandButton styles={addButtonProps} text="|" menuProps={menuCreateOptions} disabled={isMobile} checked={undefined} />
                                        </StackItem>

                                    </Stack>
                                }

                                <ImportTemplate
                                    showImportTemplate={showImportTemplate}
                                    siteOptions={props.siteOptions}
                                    siteSelected={props.siteSelected}
                                    accountId={Helper.getDefaultAccount()}
                                    username={Helper.getUsername()}
                                    sessionKey={Helper.getSessionKey()}
                                    hideImportTemplate={hideImportTemplate}
                                    notificartions={showNotification}
                                    reloadApp={props.reloadApp}
                                />

                                <ImportExternalPackage
                                    showImportTemplate={showImportExternalPackage}
                                    siteOptions={props.siteOptions}
                                    siteSelected={props.siteSelected}
                                    accountId={Helper.getDefaultAccount()}
                                    username={Helper.getUsername()}
                                    sessionKey={Helper.getSessionKey()}
                                    hideImportExternalpackage={hideImportExternalpackage}
                                    notificartions={showNotification}
                                    reloadApp={props.reloadApp}
                                />

                                <ShowCustomForm
                                    documentId={documentId}
                                    showModal={showRecordRenewal}
                                    onDismissModal={onDismissRecordRenewal}
                                    isUpdateInterval={props.isUpdateInterval}
                                    onUpdateInterval={props.onUpdateInterval}
                                    update={props.update}
                                    selectionState={props.selectionState}
                                    templateOnlyList={props.templateOnlyList}
                                />

                                <MyTemplate
                                    showModal={showUploadDocModal}
                                    siteSelected={siteSelected}
                                    toggleUploadModal={toggleUploadModal}
                                    item={selectedTemplate}
                                    modalTitle={modalTitle}
                                    toggleFormBuilder={toggleFormBuilder}
                                    getContentControls={getContentControls}
                                    getTemplateId={getTemplateId}
                                    selectedTemplate={selectedTemplate}
                                    templateName={setTemplateName}
                                    templateId={templateId}
                                    reloadNav={setReloadNav}
                                    reloadApp={reloadApp}
                                    newTemplate={newTemplate}
                                    togglenewtemplate={togglenewtemplate}
                                    update={props.update}
                                    selectionState={props.selectionState}
                                    optionKey={myTemplateOption}
                                    resetExamples={resetExamples}
                                />
                            </div>
                        </StackItem>
                        <Stack horizontal>


                            <StackItem grow={1}>
                                <div ref={targetButtonS}>
                                    {isMobile ? null :
                                        <>
                                            <ActionButton title={isMobile ? t('title.settingsMobile', { ns: 'workspace' }) : t('title.settings', { ns: 'workspace' })} text={t('text.settings', { ns: 'workspace' })}
                                                styles={iconButtonStyles}
                                                iconProps={{ iconName: 'Settings' }}
                                                disabled={isMobile}
                                                onClick={() => { toggleSettings(true) }} />

                                        </>
                                    }
                                </div>
                                <FullSettings showSettings={showSettings}
                                    selectedOption={context.showSettings}          
                                    isSettings={true}
                                    cancelSaveModal={cancelSaveModal}
                                    showSaveModal={showSaveModal}
                                    setProfileEdited={setProfileEdited}
                                    toggleSettings={toggleSettings}
                                    hideProfileModal={hideProfileModal}
                                    siteOptions={siteOptions}
                                    siteSelected={siteSelected}
                                    updateSiteSelected={updateSiteSelected}
                                    switchSite={switchSite}
                                    username={Helper.getUsername()}
                                    reloadApp={reloadApp}
                                    isSubscriptionDisabled={props.isSubscriptionDisabled}
                                    onSubscriptionDisabled={onSubscriptionDisabled}
                                    update={props.update}
                                />
                            </StackItem>
                            <StackItem>
                                <Label style={{ cursor: "pointer", whiteSpace: 'nowrap', marginRight: 8 }} title={t('title.releaseNotes', { ns: 'workspace' })} onClick={() => { setShowReleaseNotes(true) }}>{props.version}</Label>
                            </StackItem>

                        </Stack>
                        <StackItem>
                            <Text variant={"tiny"} block style={{ marginTop: 16, marginLeft: 8, fontSize: "8px" }}>{`${branding.appName} - Copyright ${(new Date().getFullYear()).toString()}`}</Text>
                        </StackItem>
                    </StackItem>
                    {/* END FOOTER */}

                </Stack>
            </div>
        </>
    );
}

export default AccountList