import React, { useState, useEffect } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import Helper, {IMerchant, BillingStatebyOwnerNameRequest, IPlan} from '../../Helpers/Helper';
import {DetailsListLayoutMode, SelectionMode, MarqueeSelection, Checkbox, StackItem, Text} from '@fluentui/react';
import { CheckboxVisibility, DetailsList, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { Selection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { isMobile } from 'react-device-detect';
import { formatSimpleDate } from '../../Helpers/DateUtils';
import BillingStateService, {
    IGetAllBillingStateOwnerHistoryRequest,
    IGetAllBillingStateOwnerHistoryResponse
} from "../../services/BillingState";

const stackTokens = { childrenGap: 10 };

function BillingState(props: any) {

    const emptyBillingState: IGetAllBillingStateOwnerHistoryResponse = {
        Active: false,
        BillingClientId: 0,
        BillingMerchantId: 0,
        BillingPlanId: 0,
        Id: 0,
        MaxApplicationAllowed: 0,
        NextDueDate: undefined,
        StorageLimit: 0,
        SubmissionLimit: 0,
        TransactionAmount: "",
        TransactionCode: "",
        TransactionDate: "",
        TransactionDescription: "",
        TransactionError: "",
        TransactionId: "",
        TransactionName: "",
        UserLimit: 0,
        WorkspaceOnlyUsers: 0
    };

    const [selectedBillingState, setSelectedBillingState] = useState<IGetAllBillingStateOwnerHistoryResponse>(emptyBillingState);
    const [applying, setApplying] = useState(false);
    const [billingStateList, setbillingStateList] = useState<IGetAllBillingStateOwnerHistoryResponse[]>([]);

    const columns: IColumn[] = [
        {
            key: 'column2',
            name: 'Date',
            fieldName: 'TransactionDate',
            ariaLabel: "Transaction Date",
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: any) => {
                return getDateTimeDisplaySpan(item.TransactionDate);
            },
        },
        {
            key: 'column3',
            name: 'Transaction',
            fieldName: 'TransactionName',
            ariaLabel: "Transaction Name",
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true
        },

        {
            key: 'column5',
            name: 'Amount',
            fieldName: 'TransactionAmount',
            ariaLabel: "Transaction Amount",
            minWidth: 40,
            maxWidth: 40,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: any) => {
                return item.TransactionAmount ?
                    <a style={{ cursor: 'pointer' }}
                        title={item.ContactEmailAddress} >
                        <div style={{ width: "100%", height: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {item.TransactionAmount}</div></a>
                    : null;
            }
        },
        {
            key: 'column6',
            name: 'Next Due Date',
            fieldName: 'NextDueDate',
            ariaLabel: "Next Due Date",
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: any) => {
                return getDateTimeDisplaySpan(item.NextDueDate);
            },
        },
        {
            key: 'column7',
            name: 'Submission Limit',
            fieldName: 'SubmissionLimit',
            ariaLabel: "Submission Limit",
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IPlan) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "end",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text variant={"medium"}
                              title={Helper.FormatNumber(item.SubmissionLimit) + " submissions allowance per Billing Period. A submission is a new record created in the workspace from the form wizard."}>
                            {Helper.FormatNumber(item.SubmissionLimit)}
                        </Text>
                    </StackItem>
                )
            },
        },
        {
            key: 'column8',
            name: 'Storage Limit (GB)',
            fieldName: 'StorageLimit',
            ariaLabel: "Storage Limit",
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IPlan) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "end",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text variant={"medium"}
                              title={(Helper.FormatNumber(item.StorageLimit / 1000)) + " GB over the Plan's lifetime."}>
                            {Helper.FormatNumber(item.StorageLimit / 1000) + " GB"}
                        </Text>
                    </StackItem>
                )
            },
        },
        {
            key: 'column9',
            name: 'Maximum Applications',
            fieldName: 'MaxApplicationAllowed',
            minWidth: 120,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,

            onRender: (item: IPlan) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "end",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text variant={"medium"}
                              title={item.MaxApplicationAllowed! === -1 ? "Unlimited Applications" : Helper.FormatNumber(item.MaxApplicationAllowed) + " maximum Applications."}>
                            {item.MaxApplicationAllowed! === -1 ? "Unlimited" : Helper.FormatNumber(item.MaxApplicationAllowed)}
                        </Text>
                    </StackItem>
                )
            },
        },
        
        {
            key: 'column10',
            name: 'Users',
            fieldName: 'UserLimit',
            ariaLabel: "Users",
            minWidth: 120,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: IPlan) => {
                return (
                    <Stack>
                        <Text variant={"medium"} block
                              title={`${Helper.FormatNumber(item.UserLimit)} Super Users allowed per Plan. Application audience members are managed by selecting <<Share> from Application's menu or <<Settings>> from bottom page left.`}>
                            {`${Helper.FormatNumber(item.UserLimit)} Super Users`}
                        </Text>
                        <Text variant={"medium"} block
                              title={`${Helper.FormatNumber(item.WorkspaceOnlyUsers)} Workspace Users allowed per Plan. Application audience members are managed by selecting <<Share> from Application's menu or <<Settings>> from bottom page left.`}>
                            {`${Helper.FormatNumber(item.WorkspaceOnlyUsers)} Workspace Users`}
                        </Text>
                    </Stack>
                )
            },
        },
        {
            key: 'column11',
            name: 'Active',
            fieldName: 'Active',
            minWidth: 25,
            maxWidth: 25,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'boolean',
            isPadded: true,
            onRender: (item: any) => {
                return <Checkbox disabled={true} checked={item.Active} />
            },
        },
        {
            key: 'column12',
            name: 'Error',
            fieldName: 'TransactionError',
            ariaLabel: "Transaction Error",
            minWidth: 20,
            maxWidth: 20,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true
        },
    ]
    const getDateTimeDisplaySpan = (item: any) => {
        var result
        isMobile ?
            result = <span title={item !== null ? formatSimpleDate(item,'ll') : null}>{item !== null ? formatSimpleDate(item,'ll') : null}</span>
            :
            result = <span title={item !== null ? formatSimpleDate(item,'ll') : null}>{item !== null ? formatSimpleDate(item,'ll') : null}</span>
        return result
    }

    useEffect(() => {
        loadBillingState()
    }, [])

    const onSelectionChange = () => {
        const selectionCount = selectionManageClientPlant.getSelectedCount();

        switch (selectionCount) {
            case 0:
                setSelectedBillingState(emptyBillingState)
                break;
            case 1:
                var obj = selectionManageClientPlant.getSelection()[0]
                var objManage = obj as IGetAllBillingStateOwnerHistoryResponse
                setSelectedBillingState(objManage)
                break;
            default:
                setSelectedBillingState(emptyBillingState)
                break;
        }
    }

    const selectionManageClientPlant = new Selection({
        onSelectionChanged: onSelectionChange,
    });

    const loadBillingState = async () => {

        const request: IGetAllBillingStateOwnerHistoryRequest = {
            ownerName: Helper.getUsername()
        }

        await BillingStateService.GetAllBillingStateOwnerHistory(request)
            .then((data) => {
                setbillingStateList(data)
            })
            .catch()
            .finally()

    }
    const getKey = (item: IMerchant, index?: number): string => {
        if (item !== undefined)
            return item.Id!.toString()
        return ""
    }

    return (
        <>
            {!props.fromModal ?
                <div className="ms-Grid-row" >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenMdDown" style={{ left: "12px" }}>
                        <label className="ms-fontSize-18">Billing state</label><br />
                        <div>History of billings.</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp">
                        <label className="ms-fontSize-18">Billing state</label>
                    </div>
                    <br />
                </div>
                : null
            }

            <Stack tokens={stackTokens}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                    {!applying ?
                        <div className="scrollVisible" style={{ overflow: "auto", overflowX: "auto", paddingRight: "10px", height: "50vh" }}>
                            <MarqueeSelection selection={selectionManageClientPlant}>
                                <DetailsList
                                    styles={{ root: { width: "fit-content" } }}
                                    items={billingStateList}
                                    compact={false}
                                    columns={columns}
                                    getKey={getKey}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    selection={selectionManageClientPlant}
                                    selectionMode={SelectionMode.single}
                                    selectionPreservedOnEmptyClick={false}
                                    enterModalSelectionOnTouch={true}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                />
                            </MarqueeSelection>
                        </div>
                        : <Spinner size={SpinnerSize.medium} label="Loading data, please wait..." ariaLive="assertive" />
                    }
                </div>
            </Stack>
        </>
    );
}

export default BillingState