import React, { useEffect, useState } from 'react';
import { FontIcon } from '@fluentui/react';
import { useConstCallback } from '@uifabric/react-hooks';
import Helper from '../../Helpers/Helper';
import '../../web.css';
import RegistrationStepper from './RegistrationStepper';
import { isMobile } from 'react-device-detect';
import { isInTeams } from '../../Helpers/Utils';
import {useBranding} from "../../hooks/useBranding";
import CustomerSupport from "../CustomerSupport";

function WebRegistration(props: any) {
    const { branding,getAppSettings } = useBranding()
    const [userLocal, setUser] = React.useState("");
    const [Step, setStep] = React.useState("400");
    const dismissPanel = useConstCallback(() => { gotoHome() });
    const [openContactUs, setOpenContactUs] = useState(false)

    useEffect(() => {
        getAppSettings()
        if (props.email !== "null") {
            setUser(props.email)
        }
    }, [props.email])

    const updateStep = useConstCallback((step) => setStep(step))

    const gotoHome = useConstCallback(() => {
        if(isInTeams()) {
            window.location.assign(`${window.location.origin}/app`);    
        } else {
            window.location.assign(window.location.origin);
        }
    })

    const hideContactUs = () => {
        setOpenContactUs(false)
    }

    return (
        <>
            <div className="mainContainer ">
                <div className="ms-hiddenMdDown" hidden={isMobile}>
               
                              
                    <div className="signup__container">
                   
                        <div className="container__child signup__thumbnail">
                            <div className="thumbnail__content text-left" style={{height:"100%"}}>
                                <div className="ms-Grid" dir="ltr" style={{ backgroundColor: "#fff", overflow: "auto", height: "100%" }}>
                               
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <h2>{branding.appName}</h2>
                                            <text>
                                                The form, document and records management office productivity tool.
                                            </text>
                                            {sessionStorage.getItem("entityId") === "Not In Teams" ? <h3><span>Register now for</span><span style={{ color: "green" }} color="green"> FREE</span></h3> : <h3><span>Register now</span></h3>}
                                            <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Automate your documents</span><br />
                                            <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  In Minutes</span><br />
                                            <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  With no training</span><br />
                                            <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Collaborate internally/externally</span><br />
                                            <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Full records management and audit</span><br />
                                            <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Drive consistency and quality</span><br />
                                            <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Save time and money</span>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="signup__overlay"></div>
                        </div>
                        <div className="container__child signup__form">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <div className="ms-Grid-row scrollVisible" dir="ltr" style={{ backgroundColor: "#fff", overflow: "auto", height: "80vh", padding: "5px" }}>
                                        <h2>Registration Wizard</h2>
                                        <RegistrationStepper
                                            email={props.email != null ? props.email : userLocal}
                                            step={props.step != null ? props.step : Step}
                                            onStep={props.onStep != null ? props.onStep : updateStep}
                                            onClose={dismissPanel}
                                        />
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        
                    </div>

                   
                </div>
                <div className="ms-hiddenLgUp" hidden={isMobile}>
                    <div className="signup__containerMob">
                        <div >
                            <div className=" text-left">
                                <div className="ms-Grid">
                                    <div className="ms-Grid-row " dir="ltr" >
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <h2>{branding.appName}</h2>
                                                <text>
                                                    The form, document and records management office productivity tool.
                                                </text>
                                               
                                                {sessionStorage.getItem("entityId") === "Not In Teams" ? <h3><span>Register now for</span><span style={{ color: "green" }} color="green"> FREE</span></h3> : <h3><span>Register now</span></h3>}
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Automate your documents</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  In Minutes</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  With no training</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Collaborate internally/externally</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Full records management and audit</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Drive consistency and quality</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Save time and money</span>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row" style={{ backgroundColor: "black", height: "10px" }}></div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 container__child signup__form">
                                                <h2>Registration Wizard</h2>
                                                <RegistrationStepper
                                                    email={props.email != null ? props.email : userLocal}
                                                    step={props.step != null ? props.step : Step}
                                                    onStep={props.onStep != null ? props.onStep : updateStep}
                                                    onClose={dismissPanel}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div  className="containerMobile signup__form" hidden={!isMobile}>
                    <div className="containerMobile">
                        <div >
                            <div className="thumbnail__content text-left">
                                <div className="ms-Grid">
                                   
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 " >
                                                <h2>{branding.appName}</h2>
                                                
                                               
                                                    <span>The form, document and records management</span> <br/>
                                                    <span> office productivity tool.</span>
                                                
                                                {sessionStorage.getItem("entityId") === "Not In Teams" ? <h3><span>Register now for</span><span style={{ color: "green" }} color="green"> FREE</span></h3> : <h3><span>Register now</span></h3>}
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Automate your documents</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  In Minutes</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  With no training</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Collaborate internally/externally</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Full records management and audit</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Drive consistency and quality</span><br />
                                                <span><FontIcon style={{ color: "green" }} iconName="SkypeCheck" />  Save time and money</span>
                                            </div>
                                        </div>
                                        <hr/>
                                        {/* <div className="ms-Grid-row" style={{ backgroundColor: "black", height: "10px" }}></div> */}
                                        <div className="ms-Grid-row">
                                       
                                        <h2>Registration Wizard  </h2>
                                      
                                            <div className="ms-Grid-col signup__containerMobile  ">
                                            
                                                <div className="ms-Grid-row " >
                                                <div className="ms-Grid-col ms-sm12 " >
                                                    <RegistrationStepper
                                                        email={props.email != null ? props.email : userLocal}
                                                        step={props.step != null ? props.step : Step}
                                                        onStep={props.onStep != null ? props.onStep : updateStep}
                                                        onClose={dismissPanel}
                                                    />
                                                    <br/>
                                                 </div>
                                            
                                                </div>
                                              
                                            </div>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*region Customer Support*/}
            {openContactUs &&
                <CustomerSupport showModal={openContactUs} setShowModal={hideContactUs} defaultSubject={"Help with registration"}/>
            }
            {/*endregion*/}
        
        </>
    );
}

export default WebRegistration;