import HttpRequest from "./HttpRequest"

export interface IDocumentOnlyStatisticsRequest {
    TemplateId:string
}

export interface IDocumentOnlyStatisticsResponse {
    Audience_Closed_Documents:number
    Audience_Open_Documents:number
    Creator_Closed_Documents:number
    Creator_Open_Documents:number
    CurrentDateTime:string
    DisplayName:string
    FullName:string
    Share_Closed_Documents:number
    Share_Open_Documents:number
    Template_Co_Owner:number
    Template_Member:number
    Template_Owner:number
    UserName:number
}

const DocumentService = {
    getDocumentMetadata: async ({ TemplateId, UserName }: { TemplateId: string, UserName: string }) => {
        return await HttpRequest.getWithSession('GetTemplateMetadata', {
            TemplateId,
            TargetUser: UserName
        })
    },
    getDocument: async ({ UserName, DocumentId }: { DocumentId: string, UserName: string }) => {

        return await HttpRequest.getWithSession('GetDocument', {
            Id: DocumentId,
            TargetUser: UserName,
            DocumentId: -1,
            IsDocx: true,
            IsDownload: true,
            IsPreview: false
        })
    },

    /**
     * DocumentOnlyStatistics
     * @param request
     * @constructor
     */
    DocumentOnlyStatistics: async (request : IDocumentOnlyStatisticsRequest): Promise<IDocumentOnlyStatisticsResponse[]> => {
        const { data }: any = await HttpRequest.getWithSession("DocumentOnlyStatistics", request)
        return new Promise<IDocumentOnlyStatisticsResponse[]>((resolve, reject) => {
            if (!Array.isArray(data)) reject({err: 'Server error'})
            else resolve(data)
        })
    },
    
}

export default DocumentService