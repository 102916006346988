import React, { useState } from 'react'

type AttachmentContextType = {
    endUserName: string
    username:string
    docSource: string
    documentId: number
    documentDraftId: number
    templateId: number
    showErrorMessage: boolean
    setShowErrorMessage: React.Dispatch<React.SetStateAction<boolean>>
    showSuccessMessage: boolean
    setShowSuccessMessage: React.Dispatch<React.SetStateAction<boolean>>
    message: string
    setMessage: React.Dispatch<React.SetStateAction<string>>
    update: Function
    billingActiveStr: string
}
const AttachmentContext = React.createContext<AttachmentContextType>({} as AttachmentContextType)
type AttachmentProviderProps = {
    endUserName: string
    username:string
    docSource: string
    documentId: number
    documentDraftId: number
    templateId: number
    update: Function
    billingActiveStr: string
    children: any
}
export const AttachmentProvider = (props: AttachmentProviderProps) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [message, setMessage] = useState('');

    return <AttachmentContext.Provider value={{
        ...props, showErrorMessage,
        setShowErrorMessage, showSuccessMessage, setShowSuccessMessage, message, setMessage
    }} >
        {props.children}
    </AttachmentContext.Provider>
}
export default AttachmentContext