import HttpRequest from "../../../services/HttpRequest";
import IAuthCodeRequest from "../Interfaces/IAuthCodeRequest";
import ISendAuthCodeRequest from "../Interfaces/ISendAuthCodeRequest";
import Helper, {IHttpClientResponseBag} from "../../../Helpers/Helper";
import {IConsentUrlResponse} from "../../../services/GraphApi";

const AuthCodeService = {

    /**
     * Validate
     * @param request
     * @constructor
     */
    Validate: async (request: IAuthCodeRequest): Promise<IHttpClientResponseBag> => {
        let {data}: any = await HttpRequest.postWithSession('AuthCode/Validate', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })

    },

    /**
     * Send
     * @param request
     * @constructor
     */
    Send: async (request: ISendAuthCodeRequest): Promise<any> => {
        let {data}: any = await HttpRequest.postWithSession('AuthCode/Send', request)

        return new Promise<IHttpClientResponseBag>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            else resolve(data)
        })

    },
}

export default AuthCodeService