import { IRequest } from "../../../Helpers/Helper";
import { OutboundIntegrationModel } from "./OutboundIntegrationModel";

export interface EventRequest extends IRequest {
    TemplateId: number;
}

export interface CreateEventRequest extends IRequest {
    Name: string;
    Description: string;
    Order: number;
    ForDisplay: boolean;
    EventType: string;
    TemplateId: number;
    EventState: string;
}

export interface UpdateEventRequest extends IRequest {
    Id: number;
    Name: string;
    Description: string;
    Order: number;
    ForDisplay: boolean;
    TemplateId: number;
}

export interface DeleteEventRequest extends IRequest {
    TemplateId: number;
    Id: number;
}

export interface ManualEventRequest extends IRequest {
    TemplateId: number;
    DocumentId: number;
}

export interface OnClickEventRequest extends IRequest {
    TemplateId: number;
}

export interface EventModel {
    Integrations: OutboundIntegrationModel[];
    Id: number;
    TemplateId: number;
    Name: string;
    Description: string;
    Order: number;
    DateCreated?: string;
    UserCreated?: string;
    DateUpdated?: string;
    UserUpdated?: string;
    ForDisplay: boolean;
    EventType: string;
    EventState: string;
}

export enum EventType {
    OnCreate = "OnCreate",
    OnUpdate = "OnUpdate",
    OnClose = "OnClose",
    OnClick = "OnClick",
    OnManualInitial = "OnManualInitial",
    OnManualSubsequent = "OnManualSubsequent",
    //EmailSubtype = "EmailSubtype"
    OnLoad = "OnLoad",
    None = "None",
}

export enum EventState {
    Initial = "Initial",
    Subsequent = "Subsequent",
}
