import { useEffect } from 'react'

type Props = {
    title: string
    favicon: string
    description: string
}
const useSEO = ({ title, favicon, description }: Props) => {

    useEffect(() => {
        document.title = title

        const faviconLink = document.querySelector<HTMLLinkElement>("link[rel*='icon']")
        if (faviconLink && favicon) {
            faviconLink.href = favicon.includes('data:')? favicon: `data:image/x-icon;base64,${favicon}`
        }

        const descriptionMeta = document.querySelector<HTMLMetaElement>("meta[name='description']")
        if (descriptionMeta) {
            descriptionMeta.content = description
        } else {
            const newDescriptionMeta = document.createElement('meta')
            newDescriptionMeta.name = 'description'
            newDescriptionMeta.content = description
            document.head.appendChild(newDescriptionMeta)
        }
    }, [title, favicon, description])
}

export default useSEO
