import * as React from "react";
import {FC, useEffect, useRef, useState} from "react";
import {Controller} from "react-hook-form";
import {ReactHookFormControlProps} from "../CustomTemplates/CustomForm/Models/ReactHookFormControlProps";
import {
    DefaultButton,
    IconButton,
    IInputProps,
    Image,
    ImageFit,
    ITextFieldProps,
    Label,
    Stack,
    Text,
    TextField
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import {useBranding} from "../../hooks/useBranding";
import RHFUploadControlPreview from "./RHFUploadControlPreview";


export const RHFUploadControl: FC<ReactHookFormControlProps & ITextFieldProps & IInputProps> = (props) => {
    const {branding} = useBranding()
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [imagePreview, setImagePreview] = useState<string | undefined>(undefined)
    const [filename, setFilename] = useState("");
    const [imageUpdated, setImageUpdated] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const {t} = useTranslation(['common', 'wizard'])
    let image: any | null = null

    const onUploadFile = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.files != null && ev.target.files.length > 0) {

            let tempFileName = ev.target.files[0].name.split(".")
            setFilename(tempFileName.slice(0, tempFileName.length - 1).join("."));
            image = ev.target.files[0]

            props.setValue(props.id, image)
            const result = await props.trigger(props.id)
            if (result) {
                setImageUpdated(true)
                previewImage()
            } else {
                props.setValue(props.id, "")
                setImageUpdated(false)
                setImagePreview(undefined)
            }

        } else {

        }
    }

    useEffect(() => {
        if (props.defaultValue !== undefined && props.defaultValue !== null && props.defaultValue.trim().length > 0) {
            props.setValue(props.id, props.defaultValue)
            setImagePreview(`data:image/jpeg;base64, ${props.defaultValue}`)
            setImageUpdated(false)
        } else {
            props.setValue(props.id, "")
            setImageUpdated(true)
        }
    }, [props.defaultValue])

    useEffect(() => {
        image = null
        inputFileRef.current!.value = ""
        setImagePreview(undefined)
        setImageUpdated(false)
        setFilename("");

        if (props.defaultValue !== undefined && props.defaultValue !== null && props.defaultValue.trim().length > 0) {
            props.setValue(props.id, props.defaultValue)
            setImagePreview(`data:image/jpeg;base64, ${props.defaultValue}`)
            setImageUpdated(false)
        } else {
            props.setValue(props.id, "")
            setImageUpdated(true)
        }
    }, [props.id])

    const previewImage = () => {

        const reader = new FileReader();
        let imageString = ""

        reader.onload = (_) => {
            imageString = reader.result!.toString().split(',')[1]
        }

        reader.onloadend = () => {
            props.setValue(props.id, imageString)
            setImageUpdated(false)
            setImagePreview(`data:image/jpeg;base64, ${imageString}`)
        }

        reader.onerror = () => {
            setImageUpdated(false)
            props.setValue(props.id, "")
            setImagePreview(undefined)

        }

        if (image !== null) reader.readAsDataURL(image);
    }


    return (
        <>
            <Stack
                tokens={{childrenGap: 8}}
                styles={{
                    root: {
                        marginTop: 8,
                        marginBottom: 8,
                        height: 240,
                        justifyContent: "center",
                        alignItems: "center",
                        border: `solid 1px ${branding.theme.palette.neutralDark}`
                    }
                }}>


                {imagePreview === undefined ?
                    <>

                        <i className="ms-Icon ms-Icon--Photo2"
                           aria-hidden="true" style={{
                            fontSize: "64px",
                            color: branding.theme.palette.neutralLight
                        }}></i>


                        <Text variant={"medium"}
                              styles={{root: {color: branding.theme.palette.neutralDark}}}>
                            {"No Image"}
                        </Text>
                    </>
                    :
                    <>
                        <Image
                            src={imagePreview}
                            imageFit={ImageFit.centerContain}
                            width={"100%"}
                            height={"100%"}
                            styles={{
                                root: {
                                    // cursor: "zoom-in"
                                }
                            }}
                            // onClick={() => {
                            //     setShowPreview(true)
                            // }}
                        />
                    </>
                }
            </Stack>

            <RHFUploadControlPreview
                imageBase64={""}
                setShowModal={setShowPreview}
                showModal={showPreview}
                imageProperties={""}/>

            <Controller
                name={props.id}
                control={props.control}
                rules={props.rules}
                shouldUnregister={false}
                defaultValue={props.defaultValue}
                render={({
                             field: {name: fieldName},
                             fieldState: {error}
                         },
                ) => (
                    <>
                        <Stack>
                            {props.label &&
                                <Label htmlFor={props.id}>{props.label}</Label>
                            }
                            <Stack horizontal tokens={{childrenGap: 8}}>


                                <Stack grow={1}>
                                    <TextField
                                        id={props.id}
                                        name={fieldName}
                                        disabled={true}
                                        value={(props.defaultValue !== undefined && props.defaultValue !== "" && !imageUpdated) ? t('text.imageLoaded', {ns: 'wizard'}) : filename}
                                        title={props.title}
                                        placeholder={t('Common.Input.File', {ns: 'common'})}/>
                                </Stack>

                                <Stack>
                                    <DefaultButton htmlFor={"file"} onClick={() => {
                                        inputFileRef.current?.click()
                                    }} text="File" allowDisabledFocus/>
                                    <input
                                        accept=".bmp, .tiff, .jpg, .png, .jpeg, .gif, .svg,"
                                        className="fileButton"
                                        id="file"
                                        type="file"
                                        name="file"
                                        ref={inputFileRef}
                                        onChange={(event) => {
                                            onUploadFile(event).then()
                                        }}
                                        placeholder={t('Common.Input.File', {ns: 'common'})}
                                        title={props.title}/>
                                </Stack>

                                {!imageUpdated &&
                                    <Stack>

                                        <IconButton iconProps={{iconName: "Clear"}} title="Clear" ariaLabel="Clear"
                                                    styles={{
                                                        root: {border: `solid 1px ${branding.theme.palette.black}`},
                                                        icon: {color: branding.theme.palette.black}

                                                    }}
                                                    onClick={() => {
                                                        props.setValue(props.id, "")
                                                        inputFileRef.current!.value = ""
                                                        setImagePreview(undefined)
                                                        setFilename("");
                                                        setImageUpdated(true)
                                                    }}/>

                                    </Stack>
                                }

                            </Stack>
                            {(error && error.message) &&
                                <Stack styles={{root: {paddingTop: 8, paddingBottom: 8}}}>
                                    <Text variant={"smallPlus"}
                                          styles={{root: {color: branding.theme.semanticColors.errorText}}}>
                                        {error.message}
                                    </Text>
                                </Stack>
                            }
                        </Stack>
                    </>
                )}
            />
        </>
    );
};
